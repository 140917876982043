import { useQuery } from "@tanstack/react-query";
import parseISO from "date-fns/parseISO";

import { ExporterMetricsEndpoint } from "src/api/endpoints";
import {
  MetricsDecisionsResponse,
  MetricsErrorsResponse,
  MetricsLatencyResponse,
  MetricsSummaryResponse,
  SummaryBE,
} from "src/api/types";
import {
  ErrorsFilters,
  LatencyFunction,
  PerformanceFilters,
  Summary,
  SummaryResult,
} from "src/performance/types";
import {
  getIntervalForTimeWindow,
  intervalToSeconds,
} from "src/performance/utils";
import { StrictDateRange } from "src/utils/timeWindow";

const mapSummary = (summary: SummaryBE): Summary => ({
  decisions: summary.decisions,
  errors: summary.errors,
  errorRate: summary.decisions > 0 ? summary.errors / summary.decisions : 0,
  latencyP90: summary.latency_p90,
});

const transformSummaryResponse = (
  data: MetricsSummaryResponse,
): SummaryResult => ({
  currentPeriod: mapSummary(data.current_period),
  previousPeriod: mapSummary(data.previous_period),
});

const getISODatesFromTimeWindow = (
  timeWindow: StrictDateRange,
): { startDate: string; endDate: string } => {
  const startDate = timeWindow.from.toISOString();
  const endDate = timeWindow.to.toISOString();

  return { startDate, endDate };
};

const INITIAL_SUMMARY = { decisions: 0, errors: 0, latency_p90: 0 };
const ONE_MINUTE_IN_MS = 1000 * 60;

export const useAvailableVersions = (
  baseUrl: Nullable<string> = null,
  { versions, timeWindow }: PerformanceFilters,
) => {
  const { startDate, endDate } = getISODatesFromTimeWindow(timeWindow);
  return useQuery(
    ["metrics", "available_versions", startDate, endDate],
    () =>
      ExporterMetricsEndpoint.getAvailableVersions(baseUrl!, {
        versions,
        startDate,
        endDate,
      }),
    {
      enabled: Boolean(baseUrl) && versions.length > 0,
      staleTime: ONE_MINUTE_IN_MS,
    },
  );
};

export const useSummary = (
  baseUrl: Nullable<string> = null,
  { versions, timeWindow }: PerformanceFilters,
) => {
  const { startDate, endDate } = getISODatesFromTimeWindow(timeWindow);

  return useQuery(
    ["metrics", "summary", startDate, endDate, ...versions],
    () =>
      ExporterMetricsEndpoint.getSummary(baseUrl!, {
        versions,
        startDate,
        endDate,
      }),
    {
      enabled: Boolean(baseUrl) && versions.length > 0,
      staleTime: ONE_MINUTE_IN_MS,
      select: transformSummaryResponse,
      initialData: {
        current_period: INITIAL_SUMMARY,
        previous_period: INITIAL_SUMMARY,
      },
      initialDataUpdatedAt: 0,
    },
  );
};

type BinData = { bin: string };

const parseBinDate = <T extends BinData>(
  data: T[],
): (Omit<T, "bin"> & { bin: Date })[] =>
  data.map((d) => ({ ...d, bin: parseISO(d.bin) }));

const parseDecisions = (data: MetricsDecisionsResponse) =>
  parseBinDate(data.by_version);

export const useDecisions = (
  baseUrl: Nullable<string> = null,
  { versions, timeWindow }: PerformanceFilters,
) => {
  const { startDate, endDate } = getISODatesFromTimeWindow(timeWindow);

  const interval = getIntervalForTimeWindow(timeWindow);

  return useQuery(
    ["metrics", "decisions", startDate, endDate, ...versions],
    () =>
      ExporterMetricsEndpoint.getDecisions(baseUrl!, {
        versions,
        startDate,
        endDate,
        interval: intervalToSeconds(interval),
      }),
    {
      enabled: Boolean(baseUrl) && versions.length > 0,
      staleTime: ONE_MINUTE_IN_MS,
      select: parseDecisions,
    },
  );
};

const parseErrors = (data: MetricsErrorsResponse) => ({
  by_error: parseBinDate(data.by_error),
  error_rate: parseBinDate(data.error_rate),
});

export const useErrors = (
  baseUrl: Nullable<string> = null,
  { versions, errorCodes, timeWindow }: ErrorsFilters,
) => {
  const { startDate, endDate } = getISODatesFromTimeWindow(timeWindow);
  const interval = getIntervalForTimeWindow(timeWindow);

  return useQuery(
    ["metrics", "errors", startDate, endDate, ...versions, ...errorCodes],
    () =>
      ExporterMetricsEndpoint.getErrors(baseUrl!, {
        versions,
        startDate,
        endDate,
        interval: intervalToSeconds(interval),
        errorCodes,
      }),
    {
      enabled: Boolean(baseUrl) && versions.length > 0,
      staleTime: ONE_MINUTE_IN_MS,
      select: parseErrors,
    },
  );
};

const parseLatency = (data: MetricsLatencyResponse) => ({
  by_version: parseBinDate(data.by_version),
  total: parseBinDate(data.total),
});

type LatencyFilters = PerformanceFilters & {
  func: LatencyFunction;
};

export const useLatency = (
  baseUrl: Nullable<string> = null,
  { versions, timeWindow, func }: LatencyFilters,
) => {
  const { startDate, endDate } = getISODatesFromTimeWindow(timeWindow);
  const interval = getIntervalForTimeWindow(timeWindow);

  return useQuery(
    ["metrics", "latency", startDate, endDate, func, ...versions],
    () =>
      ExporterMetricsEndpoint.getLatency(baseUrl!, {
        versions,
        startDate,
        endDate,
        interval: intervalToSeconds(interval),
        func,
      }),
    {
      enabled: Boolean(baseUrl) && versions.length > 0,
      staleTime: ONE_MINUTE_IN_MS,
      select: parseLatency,
    },
  );
};
