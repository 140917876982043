import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Pill } from "src/base-components/Pill";
import { OPERATORS_WITH_IS_ANY, OPERATORS } from "src/constants/operators";

export type OperatorDropdownOperators = keyof typeof OPERATORS_WITH_IS_ANY;
export type OperatorValues =
  (typeof OPERATORS_WITH_IS_ANY)[OperatorDropdownOperators];

type OperatorDropdownItemProps = {
  value: OperatorValues;
  dataLoc: string;
};

const OperatorDropdownItem: React.FC<OperatorDropdownItemProps> = ({
  value,
  dataLoc,
}) => (
  <div className="flex w-66 cursor-pointer items-center justify-between gap-x-2 px-4 py-2 font-inter-normal-13px ui-active:bg-gray-50">
    {value.description}
    <Pill dataLoc={dataLoc} size="sm" variant="gray">
      <Pill.Text fontType="code">{value.code}</Pill.Text>
    </Pill>
  </div>
);

type OperatorDropdownProps = {
  disabled?: boolean;
  value: OperatorDropdownOperators;
  withIsAnyOperator: boolean;
  onChange: (operator: string) => void;
};

const ELEMENTS_IS_ANY = Object.entries(OPERATORS_WITH_IS_ANY).map(
  ([key, value]) => ({
    key,
    value,
  }),
);

const ELEMENTS = Object.entries(OPERATORS).map(([key, value]) => ({
  key,
  value,
}));

export const OperatorDropdown: React.FC<OperatorDropdownProps> = React.memo(
  ({ disabled = false, value, withIsAnyOperator, onChange }) => {
    return (
      <FixedPositionedDropdown
        buttonClassName="border-0 ring-0 p-0.5 ui-open:border-0 ui-open:ring-0 focus:border-0 focus:ring-0"
        className="inline-block"
        dataLoc="operator-dropdown"
        disabled={disabled}
        elements={withIsAnyOperator ? ELEMENTS_IS_ANY : ELEMENTS}
        itemsClassNames="overflow-y-auto h-80 decideScrollbar"
        placement="bottomLeft"
        renderButtonValue={(value) => (
          <Pill size="sm" variant="gray">
            <Pill.Text fontType="code">{value?.code}</Pill.Text>
            {!disabled && <Pill.Icon icon={faChevronDown} size="sm" />}
          </Pill>
        )}
        renderValue={(props) => (
          <OperatorDropdownItem
            dataLoc={`select-${props.value?.code}`}
            value={props.value}
          />
        )}
        selected={value}
        onSelect={onChange}
      />
    );
  },
);
