import React from "react";

type Props = {
  label: string;
  children: React.ReactNode;
};

export const CheckboxLabel: React.FC<Props> = ({ label, children }) => (
  <label className="flex items-center gap-x-2 font-inter-normal-12px">
    {children}
    {label}
  </label>
);
