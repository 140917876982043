import { useCallback, useMemo, useState } from "react";

import { FlowVersionT } from "src/api/flowTypes";
import { VersionManageModals } from "src/flow/types";

export type BuildOpenModalActionFn = (
  modal: VersionManageModals,
  version?: FlowVersionT | undefined,
) => () => void;

export const useFlowVersionModals = () => {
  const [currentModal, setCurrentModal] = useState<VersionManageModals>();
  const [selectedVersion, setSelectedVersion] = useState<FlowVersionT>();

  const buildOpenModalAction: BuildOpenModalActionFn = useCallback(
    (modal, version) => {
      return () => {
        setSelectedVersion(version);
        setCurrentModal(modal);
      };
    },
    [],
  );

  return useMemo(
    () => ({
      currentModal,
      selectedVersion,
      buildOpenModalAction,
      onOpenManageTrafficModal: buildOpenModalAction(
        "manage_traffic",
        selectedVersion,
      ),
      onAfterLeave: () => {
        setSelectedVersion(undefined);
      },
      onClose: () => {
        setCurrentModal(undefined);
      },
    }),
    [
      currentModal,
      selectedVersion,
      buildOpenModalAction,
      setSelectedVersion,
      setCurrentModal,
    ],
  );
};
