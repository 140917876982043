import { faCheck, faMultiply } from "@fortawesome/pro-regular-svg-icons";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { AnimatePresence, m } from "framer-motion";
import { useState } from "react";

import { CellContent } from "./types";
import { Pill } from "src/base-components/Pill";
import { ObjectDetailPane } from "src/dataTable/ObjectDetailPane";
import { RawCell } from "src/dataTable/RawCell";
import { speakPythonPrimitive } from "src/utils/speakPython";
import { isJsonObject } from "src/utils/stringUtils";

type CommonProps = {
  expectedOutput?: { value: string; isMatch: boolean };
  actualOutput?: { value: string; isMatch: boolean };
};

type ExpectedDataCellProps<T> = {
  cell: CellContent<T>;
  suffix?: React.ReactNode;
  className: string;
  classNameOverrides?: string;
} & CommonProps;

type ExpectedDataPopoverProps = {
  value?: any;
  children: React.ReactNode;
} & CommonProps;

const renderValueAccordingToItsType = (value: string) => {
  if (isJsonObject(value)) {
    return (
      <div className="mt-2">
        <ObjectDetailPane
          innerDimensionClass="max-h-64 max-w-164"
          jsonObject={JSON.parse(value)}
        />
      </div>
    );
  } else {
    return (
      <p className="mt-2 break-words text-gray-800 font-inter-normal-13px">
        {speakPythonPrimitive(value)}
      </p>
    );
  }
};

export const ExpectedDataPopover = ({
  expectedOutput,
  actualOutput,
  value,
  children,
}: ExpectedDataPopoverProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  return (
    <RadixTooltip.Root
      delayDuration={20}
      open={tooltipOpen}
      onOpenChange={setTooltipOpen}
    >
      <RadixTooltip.Trigger asChild>
        <div>{children}</div>
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal forceMount>
        <div>
          <AnimatePresence>
            {tooltipOpen && (
              <RadixTooltip.Content
                align="center"
                collisionPadding={16}
                side="top"
                sideOffset={8}
                asChild
              >
                <m.div
                  animate={{ opacity: 1, transition: { duration: 0.2 } }}
                  className="decideScrollbar z-50 max-h-150 min-w-[220px] max-w-128 overflow-auto rounded-lg bg-white px-3 pb-2 pt-3 shadow-lg"
                  data-loc="expected-data-info-popover"
                  exit={{ opacity: 0, transition: { duration: 0.1 } }}
                  initial={{ opacity: 0 }}
                >
                  <Pill size="sm" variant="gray">
                    <Pill.Text>Calculated Value</Pill.Text>
                  </Pill>
                  {renderValueAccordingToItsType(actualOutput?.value ?? value)}
                  <div className="mt-2 border-t border-gray-100" />
                  <div className="mt-2 flex items-center justify-between gap-x-3">
                    <Pill size="sm" variant="gray">
                      <Pill.Text>Expected Value</Pill.Text>
                    </Pill>
                    {actualOutput?.isMatch || expectedOutput?.isMatch ? (
                      <MatchPill />
                    ) : (
                      <MismatchPill />
                    )}
                  </div>
                  {renderValueAccordingToItsType(
                    expectedOutput?.value ?? value,
                  )}
                </m.div>
              </RadixTooltip.Content>
            )}
          </AnimatePresence>
        </div>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};

export const ExpectedDataCell = <T,>(props: ExpectedDataCellProps<T>) => {
  return (
    <ExpectedDataPopover value={props.cell.getValue()} {...props}>
      <RawCell {...props} />
    </ExpectedDataPopover>
  );
};

export const MatchPill = () => (
  <Pill dataLoc="field-match-status" size="sm" variant="green">
    <Pill.Text>Match</Pill.Text>
    <Pill.Icon icon={faCheck} />
  </Pill>
);

export const MismatchPill = () => (
  <Pill dataLoc="field-match-status" size="sm" variant="yellow">
    <Pill.Text>Mismatch</Pill.Text>
    <Pill.Icon icon={faMultiply} />
  </Pill>
);
