import { ChangeLogDb } from "src/clients/flow-api";

export type OpenDiffViewParams = {
  change: ChangeLogDb;
  againstChangeBefore?: "self" | ChangeLogDb;
  forNodeId?: string;
};
export const getChangeToCompareAgainst = ({
  change,
  againstChangeBefore = "self",
  flatHistoryData,
}: OpenDiffViewParams & { flatHistoryData: ChangeLogDb[] }) => {
  const onlyLogicChanges = flatHistoryData.filter(
    (change) => change.diff !== undefined,
  );
  const getPreviousChange = (change: ChangeLogDb) => {
    const changeIndex = onlyLogicChanges?.findIndex(
      (listedChange) => listedChange.etag === change.etag,
    );
    if (changeIndex === undefined) return;

    if (onlyLogicChanges.length === 1) {
      return onlyLogicChanges[changeIndex];
    }
    // List order descendingly
    if (onlyLogicChanges[0].etag > onlyLogicChanges[1].etag) {
      return (
        onlyLogicChanges?.[changeIndex + 1] ?? onlyLogicChanges?.[changeIndex]
      );
      // List ordered ascendingly
    } else {
      return onlyLogicChanges[changeIndex - 1] ?? onlyLogicChanges[changeIndex];
    }
  };

  if (againstChangeBefore === "self") {
    return getPreviousChange(change);
  } else {
    return getPreviousChange(againstChangeBefore);
  }
};
