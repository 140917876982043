import { AxiosInstance } from "axios";

import { isTaktileRequest, pushToErrorPage } from "src/api/middlewares/utils";
import { TokenWithUserAndKey } from "src/clients/taktile-api";
import { getSignUpUrlWithNext } from "src/router/urls";

const NO_REFRESH_TOKEN_ERROR_MESSAGE = "No refresh token";

export const add401ErrorsMiddleware = (
  axiosInstance: AxiosInstance,
  refresh: () => Promise<void | TokenWithUserAndKey>,
  resetAll: () => void,
) => {
  const interceptor: number = axiosInstance.interceptors.response.use(
    undefined,
    (error) => {
      // Errors found here from Backend
      if (
        isTaktileRequest(error.request?.responseURL) &&
        error.request.status === 401
      ) {
        // Remove the interceptor for this call
        axiosInstance.interceptors.response.eject(interceptor);
        return refresh()
          .then((response) => {
            if (response === undefined) {
              throw new Error(NO_REFRESH_TOKEN_ERROR_MESSAGE);
            }
            error.response.config.headers["Authorization"] =
              "Bearer " + response.id_token;
            return axiosInstance.request(error.response.config);
          })
          .catch((error) => {
            // If the user continues to 401, we redirect them to the sign up page
            // they can't be logged in and we redirect to register them
            if (
              error.message === NO_REFRESH_TOKEN_ERROR_MESSAGE ||
              error.request?.status === 401
            ) {
              const pathname = window.location.pathname;
              if (
                !pathname.startsWith("/sign-up") &&
                !pathname.startsWith("/callback")
              ) {
                window.location.href = getSignUpUrlWithNext();
                resetAll();
              }
              return Promise.reject(error);
            }

            // we let the error propagate for non-401
            return Promise.reject(error);
          })
          .finally(() =>
            add401ErrorsMiddleware(axiosInstance, refresh, resetAll),
          );
      }
      if (
        [500].includes(error.request?.status) &&
        !window.location.pathname.startsWith("/callback") &&
        !error.config.url.endsWith("/probe") // TODO: [INT-594 : Avoid interceptor redirect for probe requests](https://linear.app/taktile/issue/INT-594/avoid-interceptor-redirect-for-probe-requests)
      ) {
        pushToErrorPage();
      }
      return Promise.reject(error);
    },
  );

  return interceptor;
};
