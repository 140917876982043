import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import React, { useState } from "react";

import { SubHeader } from "src/flow/SubHeader";
import { useCapabilities } from "src/hooks/useCapabilities";
import { DashboardContent } from "src/layout/DashboardContent";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { AuthProviders } from "src/userManagement/AuthProviders";
import { CreateInviteModal } from "src/userManagement/CreateInviteModal/CreateInviteModal";
import { UserList } from "src/userManagement/UserList";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const UsersPermissionsPage: React.FC = () => {
  const { usersPermissions } = useCapabilities();
  const { orgId } = useParamsDecode<{ orgId: string }>();
  const [inviteUserModal, setInviteUserModal] = useState(false);

  return (
    <DashboardContent
      Header={
        <SubHeader title="User permissions">
          {usersPermissions.canAccess && (
            <SubHeader.Button
              icon={faPlus}
              tooltip="Add user"
              onClick={() => setInviteUserModal(true)}
            />
          )}
        </SubHeader>
      }
    >
      <UserList orgId={orgId} />
      {isFeatureFlagEnabled(FEATURE_FLAGS.powertools) && (
        <div className="mt-8 text-gray-500 font-inter-semibold-13px">
          <AuthProviders orgId={orgId} />
        </div>
      )}

      <CreateInviteModal
        open={inviteUserModal}
        organizationId={orgId}
        editableOrgRole
        onClose={() => setInviteUserModal(false)}
      />
    </DashboardContent>
  );
};
