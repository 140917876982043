import {
  faClose,
  faEdit,
  faEye,
  faPlus,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { InfiniteData } from "@tanstack/react-query";
import { m } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import { v4 } from "uuid";

import { FlowVersionT } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import {
  MENU_DIVIDER,
  OptionsDropdownElement,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import {
  TextEditor,
  TextEditorRef,
} from "src/base-components/TextEditor/TextEditor";
import { Toolbar } from "src/base-components/TextEditor/Toolbar";
import { ChangeHistoryList } from "src/changeHistory/ChangeHistoryList";
import { ChangeHistoryRow } from "src/changeHistory/ChangeHistoryRow";
import { DiffViewModal } from "src/changeHistory/DiffViewModal/DiffViewModal";
import {
  getChangeToCompareAgainst,
  OpenDiffViewParams,
} from "src/changeHistory/DiffViewModal/GetChangeToCompareAgainst";
import {
  canFetchNextPage,
  etag_including_comments,
  useFetchAllChanges,
  useLoadChangeHistory,
} from "src/changeHistory/queries";
import { useLatestSeenReviewEtag } from "src/changeHistory/useLatestSeenVersionEtag";
import {
  ChangeLogDb,
  CommentResourceType,
  FlowReviewConfiguration,
  FlowVersionStatus,
  ReviewCommentStatus,
  ReviewDb,
  ReviewStatus,
} from "src/clients/flow-api";
import { useCreateComment } from "src/comments/queries";
import { useDeleteComment } from "src/comments/useDeleteComment";
import { useModal } from "src/design-system/Modal";
import { rightSidePaneWidthWideClassname } from "src/flowContainer/RightPane";
import {
  useFocusAddReview,
  useSetShouldFocusAddReview,
  useShouldFocusAddReview,
} from "src/flowContainer/hooks/useFocusAddReviewStore";
import {
  canAddReview,
  canCancelReview,
  canEditReview,
  canRequestReview,
  canRerequestReview,
} from "src/flowContainer/versionActionConditions";
import { CancelReviewModal } from "src/flowReview/CancelReviewModal";
import { FlowVersionReviewModal } from "src/flowReview/FlowVersionReviewModal";
import { ReviewLogEntry } from "src/flowReview/ReviewLogEntry";
import { ReviewProcessPills } from "src/flowReview/ReviewStatusPills";
import { useFlowVersionReview } from "src/flowReview/api/queries";
import { useRerequestReview } from "src/flowReview/useRerequestReview";
import { reviewItemEtag, prepareChangeList } from "src/flowReview/utils";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useCurrentUserId } from "src/store/AuthStore";
import { toastError } from "src/utils/toastError";

type Props = {
  onClose: () => void;
};

export const ReviewProcessPane: React.FC<Props> = ({ onClose }) => {
  const userId = useCurrentUserId();
  const { flow, version } = useAuthoringContext();
  const { data: review } = useFlowVersionReview(version.id);
  const {
    isOpen: isReviewModalOpen,
    openModal: openReviewModal,
    closeModal: closeReviewModal,
  } = useModal();

  const {
    isOpen: isCancelReviewModalOpen,
    openModal: openCancelReviewModal,
    closeModal: closeCancelReviewModal,
  } = useModal();

  const { isLoading: isRerequesting, rerequestReview } = useRerequestReview();
  const { commentDeletionModal, onDelete: onDeleteComment } =
    useDeleteComment();

  const changeHistory = useLoadChangeHistory(
    version.id,
    etag_including_comments(version.etag),
    {
      includeReviewComments: true,
    },
  );
  useFetchAllChanges(changeHistory);

  const displayDataUntilEtag = review?.opened_at_flow_version_etag;

  const canFetchNextChanges = canFetchNextPage({
    hasNextPage: changeHistory.hasNextPage,
    isFetchingNextPage: changeHistory.isFetchingNextPage,
  });

  const [, saveLatestEtag] = useLatestSeenReviewEtag({
    userId: userId!,
    versionId: version.id,
  });

  const [diffViewOpen, setDiffViewOpen] = useState(false);
  const [diffedChange, setDiffedChange] = useState<ChangeLogDb>();
  const [isCancelledHistoryVisible, setCancelledHistory] = useState(false);
  const [compareAgainstChange, setCompareAgainstChange] =
    useState<ChangeLogDb>();
  const isDraft = version.status === FlowVersionStatus.DRAFT;
  const isCancelled = review?.review_status === ReviewStatus.CANCELLED;

  const focusAddReview = useFocusAddReview();

  const openDiffView = useCallback(
    ({ change, againstChangeBefore = "self" }: OpenDiffViewParams) => {
      // Danger, will this get the change before and not after because the order is reversed?
      const changeToCompareAgainst = getChangeToCompareAgainst({
        change: change,
        againstChangeBefore: againstChangeBefore,
        flatHistoryData: changeHistory.data?.pages.flat() ?? [],
      });
      if (changeToCompareAgainst === undefined) return;
      setCompareAgainstChange(changeToCompareAgainst);
      setDiffedChange(change);
      setDiffViewOpen(true);
    },
    [changeHistory.data],
  );

  const toggleViewReviewHistory = useCallback(() => {
    setCancelledHistory((value) => !value);
  }, [setCancelledHistory]);

  const handleRerequestReview = useCallback(() => {
    if (!review) {
      return;
    }
    const reviewerIds = Object.keys(review.reviewers_status ?? {}).filter(
      (reviewId) => reviewId !== userId,
    );

    rerequestReview({
      version,
      review,
      requestedFrom: reviewerIds,
    });
  }, [userId, review, version, rerequestReview]);

  const options: OptionsDropdownElement[] = (() => {
    const list: OptionsDropdownElement[] = [];

    if (canRequestReview(flow, version.status, review)) {
      list.push({
        key: "Request review",
        icon: faEye,
        action: openReviewModal,
      });
    }
    if (canEditReview(review, version.status)) {
      list.push({
        key: "Edit",
        icon: faEdit,
        action: openReviewModal,
      });
    }
    if (canAddReview(review, version.status)) {
      list.push({
        key: "Add review",
        icon: faPlus,
        action: focusAddReview,
      });
    }
    if (userId && canRerequestReview(review, version.status, userId)) {
      list.push({
        key: "Re-request review",
        icon: faEye,
        action: handleRerequestReview,
        disabled: isRerequesting,
      });
    }
    if (canCancelReview(review, version.status)) {
      list.push(MENU_DIVIDER);
      list.push({
        key: "Cancel review",
        icon: faClose,
        action: openCancelReviewModal,
      });
    }

    return list;
  })();

  useEffect(() => {
    if (userId && changeHistory.data && changeHistory.data.pages) {
      const lastSeenEtag = changeHistory.data.pages.at(0)?.at(0)?.etag;
      if (lastSeenEtag) {
        saveLatestEtag(lastSeenEtag);
      }
    }
  }, [changeHistory.data, flow.id, saveLatestEtag, userId, version.id]);

  return (
    <>
      <div
        className={twJoin(
          "flex max-h-full flex-col overflow-hidden bg-white py-4 pt-6 shadow-lg",
          rightSidePaneWidthWideClassname,
        )}
      >
        <div className="mx-5 flex justify-between gap-x-1 border-b border-gray-100 pb-4">
          <h2 className="text-gray-800 font-inter-semibold-16px">
            Review Process
            {review && !isCancelled && (
              <span className="pl-5">
                <ReviewProcessPills review={review} />
              </span>
            )}
          </h2>
          <div className="flex items-center gap-x-1.5">
            {isDraft && <EllipsisOptionsDropdown elements={options} />}
            <Icon
              color="text-gray-500 hover:text-gray-700"
              dataLoc="close-node-editor"
              icon={faXmark}
              size="xs"
              onClick={onClose}
            />
          </div>
        </div>
        <LoadingView
          queryResult={changeHistory}
          renderUpdated={(data: InfiniteData<ChangeLogDb[]>) => {
            const preparedList = prepareChangeList({
              data,
              review,
              displayDataUntilEtag,
              isCancelledHistoryVisible,
            });

            return (
              <>
                <ChangeHistoryList
                  canFetchNextChanges={canFetchNextChanges}
                  changes={preparedList}
                  containerClassName="flex-grow"
                  direction="ascending"
                  fetchNextChanges={changeHistory.fetchNextPage}
                  isFetchingNextPage={changeHistory.isFetchingNextPage}
                  openDiffView={openDiffView}
                  renderItem={(change, index) =>
                    change.etag.includes(reviewItemEtag) ? (
                      review && (
                        <ReviewLogEntry
                          hasNextRow={index !== 0}
                          isReopened={index !== preparedList.length - 1}
                          review={review}
                        />
                      )
                    ) : (
                      <ChangeHistoryRow
                        change={change}
                        hasNextRow={index !== 0}
                        openNodeDiffView={openDiffView}
                        reviewHistory={
                          isCancelled
                            ? {
                                isVisible: isCancelledHistoryVisible,
                                toggle: toggleViewReviewHistory,
                              }
                            : undefined
                        }
                        onDeleteComment={onDeleteComment}
                      />
                    )
                  }
                />
              </>
            );
          }}
        />
        {isDraft && !isCancelled && review && flow.review_configuration && (
          <CommentBox
            flowVersion={version}
            review={review}
            reviewConfig={flow.review_configuration}
          />
        )}
      </div>
      <DiffViewModal
        afterLeave={() => {
          setDiffedChange(undefined);
          setCompareAgainstChange(undefined);
        }}
        change={diffedChange}
        compareAgainst={compareAgainstChange}
        handleClose={() => {
          setDiffViewOpen(false);
        }}
        open={diffViewOpen}
      />
      <FlowVersionReviewModal
        flow={flow}
        flowVersion={version}
        isOpen={isReviewModalOpen}
        onClose={closeReviewModal}
        onCreate={() => setCancelledHistory(false)}
      />
      {review && (
        <CancelReviewModal
          isOpen={isCancelReviewModalOpen}
          review={review}
          onClose={closeCancelReviewModal}
          onConfirm={() => setCancelledHistory(false)}
        />
      )}
      {commentDeletionModal}
    </>
  );
};

export const RadioLabel: React.FC<{ label: string; description?: string }> = ({
  label,
  description,
}) => (
  <>
    <span
      className={twJoin(
        "pl-3",
        description
          ? "text-gray-800 font-inter-semibold-13px"
          : "text-gray-700 font-inter-normal-13px",
      )}
    >
      {label}
    </span>
    {description && (
      <>
        <br className="h-1" />
        <span className="block pl-3 text-gray-500 font-inter-normal-12px">
          {description}
        </span>
      </>
    )}
  </>
);

const ReviewOptions: React.FC<{
  value: ReviewCommentStatus | null;
  onChange: (value: ReviewCommentStatus) => void;
}> = ({ value, onChange }) => (
  <SimpleRadioGroup
    orientation="vertical"
    value={value ?? undefined}
    onValueChange={onChange as (value: string) => void}
  >
    <SimpleRadioGroup.Item
      label={<RadioLabel label="Comment without approval" />}
      value={ReviewCommentStatus.COMMENTED}
    />
    <SimpleRadioGroup.Item
      label={<RadioLabel label="Approve for publishing" />}
      value={ReviewCommentStatus.APPROVED}
    />
    <SimpleRadioGroup.Item
      label={<RadioLabel label="Request changes" />}
      value={ReviewCommentStatus.REQUESTED_CHANGES}
    />
  </SimpleRadioGroup>
);

const CommentBox: React.FC<{
  flowVersion: FlowVersionT;
  review: ReviewDb;
  reviewConfig: FlowReviewConfiguration;
}> = ({ flowVersion, reviewConfig, review }) => {
  const [isFocused, setFocused] = useState(false);
  const [blurTimeoutId, setBlurTimeoutId] = useState<NodeJS.Timeout>();

  const shouldFocusAddReview = useShouldFocusAddReview();
  const setShouldFocusAddReview = useSetShouldFocusAddReview();

  const textEditorRef = useRef<TextEditorRef>(null);
  const toolbarRef = useRef<HTMLUListElement>(null);

  const [reviewStatus, setReview] = useState<ReviewCommentStatus>(
    ReviewCommentStatus.COMMENTED,
  );
  const [comment, setComment] = useState<string>("");
  const { mutateAsync: createComment, isLoading } = useCreateComment();

  const isOpen = isFocused || comment !== "";
  const userId = useCurrentUserId();
  const isReviewer =
    (userId && reviewConfig.default_reviewer_list?.includes(userId)) ?? false;

  useEffect(() => {
    if (shouldFocusAddReview) {
      // The reference to the text editor is not set on the first render,
      // so we wait a moment to call focus on it.
      setTimeout(() => {
        textEditorRef.current?.focus();
        setShouldFocusAddReview(false);
      }, 30);
    }
  }, [setShouldFocusAddReview, shouldFocusAddReview]);

  const createCommentOnClick = async () => {
    try {
      const trimmedComment = comment.trim();
      if (!review)
        throw new Error("Review is required to create a review outcome");
      if (!userId)
        throw new Error("User ID is required to create a review outcome");
      if (!reviewStatus)
        throw new Error("Review status is required to create a review outcome");
      if (!flowVersion.etag)
        throw new Error(
          "Flow version etag is required to create a review outcome",
        );

      if (reviewStatus === ReviewCommentStatus.COMMENTED && !trimmedComment) {
        throw new Error("Comment is required");
      }

      setFocused(false);

      await createComment({
        id: v4(),
        userId: userId,
        commentCreate: {
          content: trimmedComment,
          resource_id: review.id,
          flow_version: flowVersion.id,
          resource_type: CommentResourceType.REVIEW,
          reviewer_status: reviewStatus,
          review_id: review.id,
          flow_version_etag: flowVersion.etag,
        },
      });
      setComment("");
      setReview(ReviewCommentStatus.COMMENTED);
      setFocused(false);
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className="mt-auto px-5 pt-4">
      <Divider />
      <div
        className="mt-auto flex flex-col gap-y-2 pb-4 pt-4"
        // When clicking between different childs in this component, blur is always called before focus.
        // This makes it appear like the focus got moved away for a moment. To prevent that illusion, we
        // only fire the onBlur action after a short delay.
        onBlur={() => {
          const blurTimeoutId = setTimeout(() => setFocused(false), 50);
          setBlurTimeoutId(blurTimeoutId);
        }}
      >
        {isReviewer && (
          <ReviewOptions value={reviewStatus} onChange={setReview} />
        )}
        <div
          className={twJoin(
            "relative z-10 rounded-lg border p-3",
            isFocused
              ? "border-indigo-400 ring-2 ring-indigo-500/25"
              : "border-gray-200",
          )}
        >
          <div className="absolute bottom-3 left-0 flex h-6 w-full items-center justify-between gap-x-1 bg-white px-3">
            <m.div
              animate={{ opacity: isOpen ? 1 : 0 }}
              transition={{ duration: 0.1 }}
            >
              <Toolbar ref={toolbarRef} exclude={["heading"]} />
            </m.div>
            <Button
              dataLoc="review-comment-submit"
              disabled={isLoading}
              loading={isLoading}
              size="sm"
              onClick={createCommentOnClick}
            >
              {isReviewer ? "Add review" : "Add comment"}
            </Button>
          </div>
          <m.div
            animate={{ height: isOpen ? "116px" : "24px" }}
            className={twJoin("pb-6", !isOpen && "max-w-[calc(100%-100px)]")}
            transition={{ duration: 0.1 }}
            layout
            onFocus={() => {
              clearTimeout(blurTimeoutId);
              setFocused(true);
            }}
          >
            <TextEditor
              ref={textEditorRef}
              dataLoc="review-comment-editor"
              maxHeight="max-h-[80px]"
              placeholder="Add a comment"
              toolbarRef={toolbarRef}
              value={comment}
              onChange={setComment}
            />
          </m.div>
        </div>
      </div>
    </div>
  );
};
