import { useMemo } from "react";

export const CM_CREATE_EVENT = "code-mirror-create-event";

export const useDispatchCreateEvent = () => {
  return useMemo(
    () => ({
      dispatchCreateEvent: () => {
        document.dispatchEvent(new CustomEvent(CM_CREATE_EVENT));
      },
    }),
    [],
  );
};
