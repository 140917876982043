import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { twJoin } from "tailwind-merge";

type TextAreaProps = Omit<
  React.ComponentPropsWithoutRef<"textarea">,
  "style" | "className"
> & {
  errored?: boolean;
  resizeable?: boolean;
  fullWidth?: boolean;
};

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ errored, resizeable = false, fullWidth, ...props }, ref) => {
    return (
      <div className={twJoin("relative", fullWidth && "w-full")}>
        <textarea
          ref={ref}
          className={twJoin(
            "rounded-lg border px-2.5 py-2 font-inter-normal-12px focus:outline-none",
            "decideScrollbar h-20 min-h-[80px] w-full",
            "placeholder:text-gray-500",
            props.disabled
              ? "bg-gray-50 text-gray-500"
              : "cursor-text bg-white text-gray-800",
            errored
              ? "border-red-400 pr-7.5"
              : "border-gray-200 focus:border-indigo-400 focus:ring-2 focus:ring-indigo-500/25",
            !resizeable && "resize-none",
            fullWidth && "w-full",
          )}
          {...props}
        />
        {errored && (
          <FontAwesomeIcon
            className="absolute right-4 top-3.5 text-xs text-red-400"
            icon={faWarning}
          />
        )}
      </div>
    );
  },
);
