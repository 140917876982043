import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  InputMappingGroupT,
  InputMappingT,
  IntegrationResourceT,
} from "src/integrationNode/types";

const getDefaultAddressFields = (): { [key: string]: InputMappingT } => ({
  address_line: {
    id: uuidV4(),
    type: "text",
    displayName: "Address line",
    assignedTo: "",
    hint: `The best delivery address used in the search process will be one that has been standardized. Examples of valid delivery lines include the following: “102A NW Main Blvd SW Suite 2045A”, “5235 Main Ave Box 33578”, “Highway Contract 5 Box 123”.`,
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    hint: `A two-letter code for the U.S. state or territory of the address (e.g., "CA" for California)`,
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    hint: `The company's 5 or 9-digit ZIP code`,
  },
});

const getDefaultAddress = (): InputMappingGroupT => ({
  getDefaultElements: getDefaultAddressFields,
  elements: getDefaultAddressFields(),
  displayName: "Address",
});

export const getDefaultEquifaxUSCommercialRiskCreditReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "equifax_us_commercial_risk",
      resource: "equifax_us_commercial_risk_credit_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        business_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Business name",
          assignedTo: "",
          rules: {
            required: true,
          },
          hint: "The legal name of the company inquired on. Include legal entity designators such as Inc, LLC, Ltd, PA, etc",
        },
        customer_reference: {
          id: uuidV4(),
          type: "text",
          displayName: "Customer reference",
          assignedTo: "",
          hint: "Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.",
        },
      },
      grouped: {
        address: getDefaultAddress(),
      },
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        commercial_insight_delinquency_score: {
          ...getNamedOutputMapping("Commercial insight delinquency score"),
          hint: (
            <>
              Predicts the likelihood of a business incurring severe delinquency
              (91 days or greater), charge-off, or bankruptcy on financial
              accounts within the next 12 months. The score ranges from 101 to
              999, a lower score indicating a higher risk of delinquency.
              <br /> A score of 0 indicates that the business has filed for
              bankruptcy.
            </>
          ),
        },
        business_delinquency_financial_score: {
          ...getNamedOutputMapping("Business delinquency financial score"),
          hint: (
            <>
              Predicts the likelihood of severe delinquency on financial
              accounts. The score ranges from 101 to 650, a lower score
              indicating a higher risk of delinquency.
              <br /> A score of 0 indicates that the business has filed for
              bankruptcy.
            </>
          ),
        },
        business_delinquency_score: {
          ...getNamedOutputMapping("Business delinquency score"),
          hint: (
            <>
              Predicts the likelihood of severe delinquency on a vendor or
              supplier account. The score ranges from 101 to 700, a lower score
              indicating a higher risk of delinquency.
              <br /> A score of 0 indicates that the business has filed for
              bankruptcy.
            </>
          ),
        },
        business_failure_score: {
          ...getNamedOutputMapping("Business failure score"),
          hint: `Predicts the likelihood of a company ceasing business in the next 12 months. The score ranges between 1000 and 1620. A lower score indicates a higher risk of a business failing. Formally bankrupt businesses will have a score of 0.`,
        },
        number_of_employees: {
          ...getNamedOutputMapping("Number of employees"),
        },
        years_in_business: {
          ...getNamedOutputMapping("Years in business"),
        },
        number_of_charge_offs: {
          ...getNamedOutputMapping("Number of charge offs"),
        },
        delinquent_accounts_count_60_days_past_due: {
          ...getNamedOutputMapping(
            "Delinquent accounts count 60 days past due",
          ),
        },
        delinquent_accounts_count_90_days_past_due: {
          ...getNamedOutputMapping(
            "Delinquent accounts count 90 days past due",
          ),
        },
        delinquent_accounts_count_120_days_past_due: {
          ...getNamedOutputMapping(
            "Delinquent accounts count 120 days past due",
          ),
        },
        delinquent_accounts_count_120_plus_days_past_due: {
          ...getNamedOutputMapping(
            "Delinquent accounts count over 120 days past due",
          ),
        },
        number_of_bankruptcies: {
          ...getNamedOutputMapping("Number of bankruptcies"),
        },
        recent_bankruptcy_date: {
          ...getNamedOutputMapping("Recent bankruptcy date"),
          hint: `The date of the most recent bankruptcy.`,
        },
        successful_inquiry: {
          ...getNamedOutputMapping("Successful inquiry"),
          hint: (
            <>
              Indicates whether an inquiry was successful on the given input
              (True/False). A False value is returned when:
              <ul>
                <li>No matching data was found.</li>
                <li>
                  The data was found, but could not be used in the scoring
                  algorithm because the business had the folder frozen or
                  blocked.
                </li>
                <li>
                  Due to system error, search process for the data was not
                  engaged.
                </li>
                <li>
                  The data was found, but could not be used in the scoring
                  algorithm because it did not meet the minimum criteria.
                </li>
              </ul>
            </>
          ),
        },
      },
    },
    config: defaultResourceConfig,
  });
