import {
  ExecutionResultTab,
  QueryResultT,
  ResultStatus,
} from "src/databaseDebugPopover/BaseDebugPopover";

export const ExecutionResult: React.FC<{
  status: ResultStatus;
  result: QueryResultT;
}> = ({ result, status }) => {
  return (
    <div className="flex max-h-full min-h-0 flex-1 flex-col py-2">
      <ExecutionResultTab result={result} status={status} />
    </div>
  );
};
