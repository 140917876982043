import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

import { FieldErrorsT } from "src/api/types";
import { Button } from "src/base-components/Button";
import { SplitBranch } from "src/clients/flow-api";
import { SplitNodeV2, SplitNodeV2DataT } from "src/constants/NodeDataTypes";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import { SplitBranchDeletionModal } from "src/splitNodeEditor/SplitBranchDeletionModal";
import { BranchCard } from "src/splitNodeV2Editor/BranchCard";
import { DefaultBranchCard } from "src/splitNodeV2Editor/DefaultBranchCard";
import { getDefaultBranch } from "src/splitNodeV2Editor/types";
import { useGraphStore } from "src/store/StoreProvider";
import { convertFieldErrorsBeToFe } from "src/utils/FieldErrorUtils";
import { useSubmitForm } from "src/utils/useSubmitForm";

const getDefaultValues = (node: SplitNodeV2) => {
  const branches = node.data.branches;
  if (branches.length > 0) {
    return {
      branches: branches.slice(0, -1),
      // The default branch is stored in the last branch
      defaultBranch: branches[branches.length - 1],
    };
  }
  return { branches: [], defaultBranch: getDefaultBranch() };
};

const formValuesToBE = (
  values: SplitNodeV2Form,
): Partial<SplitNodeV2DataT> => ({
  branches: [...values.branches, values.defaultBranch],
});

export type SplitNodeV2Form = {
  branches: SplitBranch[];
  defaultBranch: SplitBranch;
};

type PropsT = {
  selectedNode: SplitNodeV2;
} & NodeEditorBaseProps<SplitNodeV2DataT>;

export const SplitNodeV2Editor: React.FC<PropsT> = observer(
  ({ selectedNode, immutable, isReactive, displayedError, onUpdate }) => {
    const {
      addSplitBranchV2,
      deleteSplitBranchV2,
      getBranchNodesToDelete,
      getNodeToIncomingEdge,
      changeSplitBranchV2Order,
    } = useGraphStore();
    const formMethods = useForm<SplitNodeV2Form>({
      defaultValues: getDefaultValues(selectedNode),
      ...(isReactive && { values: getDefaultValues(selectedNode) }),
    });

    const { fields, append, remove, move } = useFieldArray({
      control: formMethods.control,
      name: "branches",
    });
    const [isAddingBranch, setIsAddingBranch] = useState<boolean>(false);
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] =
      useState<boolean>(false);
    const [branchToDelete, setBranchToDelete] = useState<SplitBranch>();

    useSubmitForm({
      onChange: (data: SplitNodeV2Form) => {
        onUpdate({ newData: formValuesToBE(data) });
      },
      disabled: isReactive,
      previousValues: getDefaultValues(selectedNode),
      watch: formMethods.watch,
    });

    const onAddSplitBranch = async () => {
      setIsAddingBranch(true);
      try {
        const newBranch = await addSplitBranchV2(fields.length);
        if (newBranch) {
          append(newBranch);
        }
      } catch (e) {
      } finally {
        setIsAddingBranch(false);
      }
    };

    const onConfirmDelete = async () => {
      if (branchToDelete) {
        try {
          await deleteSplitBranchV2(branchToDelete.edge_id);
          // Delete the branch from the form state
          fields.forEach((branch, index) => {
            if (branch.id === branchToDelete.id) {
              remove(index);
            }
          });
        } catch (e) {}
      }
      setConfirmationModalIsOpen(false);
    };

    const getBranchNodeToDelete = () => {
      if (branchToDelete) {
        return getNodeToIncomingEdge(branchToDelete.edge_id);
      }
    };

    const getNodesToDelete = () => {
      if (branchToDelete) {
        const branchNode = getBranchNodeToDelete();
        if (branchNode) {
          return getBranchNodesToDelete(branchNode.id);
        }
      }
      return [];
    };

    const handleDragEnd = async (event: DragEndEvent) => {
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const fieldToMove = fields.findIndex((field) => field.id === active.id);
        const fieldToInsert = fields.findIndex((field) => field.id === over.id);
        if (fieldToMove !== -1 && fieldToInsert !== -1) {
          move(fieldToMove, fieldToInsert);
          changeSplitBranchV2Order(fieldToMove, fieldToInsert);
        }
      }
    };
    const fieldErrors: FieldErrorsT | undefined = displayedError?.field_errors
      ? convertFieldErrorsBeToFe(displayedError.field_errors)
      : undefined;

    return (
      <>
        <FormProvider {...formMethods}>
          <form>
            <DndContext onDragEnd={handleDragEnd}>
              <SortableContext
                disabled={immutable}
                items={fields.map((branch) => branch.id)}
              >
                {fields.map((branch, index) => (
                  <BranchCard
                    key={branch.id}
                    branch={branch}
                    fieldErrors={fieldErrors}
                    immutable={immutable}
                    index={index}
                    onDeleteRequest={async () => {
                      setBranchToDelete(branch);
                      setConfirmationModalIsOpen(true);
                    }}
                  />
                ))}
              </SortableContext>
            </DndContext>
            <Button
              dataLoc="add-split-branch"
              disabled={immutable}
              iconLeft={faPlus}
              loading={isAddingBranch}
              size="sm"
              variant="secondary"
              onClick={onAddSplitBranch}
            >
              Add branch
            </Button>

            <DefaultBranchCard
              immutable={immutable}
              selectedNode={selectedNode}
            />
          </form>
        </FormProvider>
        <SplitBranchDeletionModal
          branchToDeleteName={getBranchNodeToDelete()?.data.label ?? ""}
          nodesToDelete={getNodesToDelete()}
          open={confirmationModalIsOpen}
          onClose={() => setConfirmationModalIsOpen(false)}
          onConfirmDelete={onConfirmDelete}
        />
      </>
    );
  },
);
