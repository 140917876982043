import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import React, { useCallback } from "react";
import {
  Controller,
  UseFieldArrayRemove,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { Button } from "src/base-components/Button";
import { Card } from "src/base-components/Card";
import { EditorTable } from "src/base-components/EditorTable/EditorTable";
import { EditorTableStoreProvider } from "src/base-components/EditorTable/StoreProvider";
import { Icon } from "src/base-components/Icon";
import { MonospacedInput } from "src/base-components/MonospacedInput";
import { columnGroups } from "src/scorecardNode/columnsConfig";
import { FactorContextProvider } from "src/scorecardNode/context";
import { getDefaultCase } from "src/scorecardNode/defaultValues";
import { FactorError, FormShape } from "src/scorecardNode/types";
import { transformToTableData } from "src/scorecardNode/utils";

type Props = {
  index: number;
  totalFactors: number;
  errors: Nullable<FactorError>;
  onRemove: UseFieldArrayRemove;
  readonly: boolean;
};

type AddCaseButtonProps = {
  factorIndex: number;
  readonly: boolean;
};
const AddCaseButton: React.FC<AddCaseButtonProps> = ({
  readonly,
  factorIndex,
}) => {
  const { control } = useFormContext<FormShape>();
  const { append: appendCase, fields: cases } = useFieldArray({
    control,
    name: `factors.${factorIndex}.cases`,
  });

  const handleAddCase = useCallback(() => {
    const lastCase = cases[cases.length - 1];
    appendCase(getDefaultCase({ operator: lastCase?.predicate?.operator }));
  }, [cases, appendCase]);

  return (
    <Button
      dataLoc={`scorecard-factor-${factorIndex}-add-case`}
      disabled={readonly}
      iconLeft={faPlus}
      size="sm"
      variant="secondary"
      onClick={handleAddCase}
    >
      Add case
    </Button>
  );
};

export const Factor: React.FC<Props> = ({
  totalFactors,
  errors,
  index,
  onRemove,
  readonly,
}) => {
  const { control, register, watch } = useFormContext<FormShape>();
  const equalWeights = useWatch<FormShape, "equal_weights">({
    name: "equal_weights",
  });
  const extractScores = useWatch<FormShape, "extract_scores">({
    name: "extract_scores",
  });

  const handleRemove = useCallback(() => {
    onRemove(index);
  }, [index, onRemove]);

  return (
    <Controller
      control={control}
      name={`factors.${index}`}
      render={({ field }) => {
        return (
          <Card key={field.value.id} data-loc={`scorecard-factor-${index}`}>
            <Card.Header className="mb-3">
              <Card.Title
                dataLoc={`scorecard-factor-${index}-title`}
                title={field.value.name}
              />
              <Icon
                color={twJoin(
                  !readonly && "text-gray-500 hover:text-gray-700",
                  readonly && "text-gray-400",
                )}
                disabled={readonly}
                icon={faTrashAlt}
                size="xs"
                onClick={handleRemove}
              />
            </Card.Header>
            <Card.Content>
              <FactorContextProvider
                equalWeights={equalWeights}
                field={field}
                index={index}
                readonly={readonly}
                totalFactors={totalFactors}
              >
                <div className="decideScrollbar w-full">
                  <EditorTableStoreProvider>
                    <EditorTable
                      columnGroups={columnGroups}
                      data={transformToTableData(watch(`factors.${index}`))}
                      dataLoc={`scorecard-factor-${index}-table`}
                      errors={errors?.tableErrors}
                      readonly={readonly}
                      readonlyCells={[
                        { col: "predicate", row: field.value.fallback.id },
                      ]}
                      hideHeaders
                    />
                  </EditorTableStoreProvider>
                </div>
              </FactorContextProvider>
              {extractScores && (
                <div className="flex w-full items-center gap-x-4">
                  <p className="text-gray-700 font-inter-normal-12px">
                    Assign unweighted {field.value.name} score to
                  </p>
                  <MonospacedInput
                    containerClassName="grow"
                    errored={Boolean(errors?.outputFieldError)}
                    formProps={register(`factors.${index}.output_field.value`)}
                    inputDataLoc="scorecard-assign-unweighted-score-input"
                    placeholder="scores.credit_score"
                    prefix="data."
                    readOnly={readonly}
                    codeColors
                  />
                </div>
              )}
              <AddCaseButton factorIndex={index} readonly={readonly} />
            </Card.Content>
          </Card>
        );
      }}
    />
  );
};
