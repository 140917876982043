import { faMessageLines, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { v4 } from "uuid";

import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { CommentDb, CommentResourceType } from "src/clients/flow-api";
import { CommentCardWithActions } from "src/comments/CommentCard";
import { CommentEditor } from "src/comments/CommentEditor";
import { useCreateComment } from "src/comments/queries";
import { useDeleteComment } from "src/comments/useDeleteComment";
import { tracker } from "src/instrumentation/customTrackingEvents";
import { trackingEvents } from "src/instrumentation/customTrackingEvents";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useCurrentUserId } from "src/store/AuthStore";

const NoCommentsInformation = () => (
  <>
    <div className="flex items-center justify-center rounded-lg bg-indigo-100 p-2">
      <Icon color="text-indigo-600" icon={faMessageLines} size="xl" />
    </div>
    <p className="text-gray-800 font-inter-semibold-13px">
      No open comments yet
    </p>
    <p className="w-44 text-center text-gray-500 font-inter-normal-12px">
      Open comments on this page will appear here
    </p>
  </>
);

type Props = {
  comments: CommentDb[];
  onLoadMore?: () => void;
  resourceId: string;
  resourceType: CommentResourceType;
  reviewId?: string;
};

export const CommentList: React.FC<Props> = ({
  comments,
  onLoadMore,
  resourceId,
  resourceType,
  reviewId,
}) => {
  const userId = useCurrentUserId();
  const { mutate: createComment, isLoading: isCreating } = useCreateComment();

  const authoring = useAuthoringContext();
  const [isCreatingComment, setCreatingComment] = useState(false);

  const { onDelete, commentDeletionModal } = useDeleteComment();

  if (comments.length === 0 && !isCreatingComment) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center gap-y-1">
        <NoCommentsInformation />
        <Button
          dataLoc="add-comment-button"
          iconLeft={faPlus}
          loading={isCreating}
          variant="secondary"
          onClick={() => setCreatingComment(true)}
        >
          Add new comment
        </Button>
      </div>
    );
  }

  return (
    <>
      <div className="border-b border-gray-200 py-4">
        <CommentEditor
          autoFocus={isCreatingComment}
          buttonText="Add comment"
          dataLoc="add-comment-editor"
          isSaving={isCreating}
          reviewId={reviewId}
          clearOnSave
          onSave={(value, review_id) => {
            if (value) {
              createComment({
                id: v4(),
                userId: userId!,
                commentCreate: {
                  content: value,
                  flow_version: authoring.version.id,
                  resource_id: resourceId,
                  resource_type: resourceType,
                  review_id,
                  flow_version_etag: authoring.version.etag,
                },
              });
              tracker.emit(
                trackingEvents.createNewComment({
                  flow_id: authoring.flow.id,
                  flow_version_id: authoring.version.id,
                  is_reply: false,
                  organization_id: authoring.orgId,
                  resource_id: resourceId,
                  resource_type: resourceType,
                }),
              );
            }
          }}
        />
      </div>
      <ul className="decideScrollbar mb-4 overflow-y-auto">
        {comments.map((comment) => (
          <li key={comment.id} className="mx-0.5 border-b border-gray-200 py-4">
            <CommentCardWithActions comment={comment} onDelete={onDelete} />
          </li>
        ))}
        {onLoadMore && (
          <li className="mx-0.5 py-4">
            <Button size="sm" variant="secondary" onClick={onLoadMore}>
              Load more
            </Button>
          </li>
        )}
      </ul>
      {commentDeletionModal}
    </>
  );
};
