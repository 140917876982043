import { useEffect, useState } from "react";

export const useIsContentOverflows = <T extends HTMLElement>(
  ref: React.RefObject<T>,
) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const el = ref.current;

    if (el) {
      setIsOverflowing(Boolean(el.scrollWidth > el.clientWidth));
    }
  }, [setIsOverflowing, ref]);

  return isOverflowing;
};
