import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { FlowVersionFlowChild } from "src/api/flowTypes";
import { useDeleteFlowVersion } from "src/api/flowVersionQueries";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { toastSuccess } from "src/base-components/Toast/utils";
import { RelatedFlowList } from "src/flow/modals/RelatedFlowList";
import { groupRelatedFlows } from "src/flow/modals/utils";
import { useFlowContext } from "src/router/routerContextHooks";
import { FlowPageParamsT, getFlowVersionsUrl } from "src/router/urls";
import * as logger from "src/utils/logger";
import { useParamsDecode } from "src/utils/useParamsDecode";

type PropsT = {
  isOpen: boolean;
  version?: FlowVersionFlowChild;
  onClose: () => void;
  afterLeave?: () => void;
};

export const DeleteVersionModal: React.FC<PropsT> = ({
  version,
  onClose,
  isOpen,
  afterLeave,
}) => {
  const { workspace } = useFlowContext();
  const { mutateAsync: deleteVersion } = useDeleteFlowVersion();
  const navigate = useNavigate();
  const params = useParamsDecode<FlowPageParamsT>();

  const [parentFlowIds, groupedParentFlows] = useMemo(
    () => groupRelatedFlows(version?.parent_flows ?? []),
    [version?.parent_flows],
  );

  const onModalConfirm = async () => {
    if (version) {
      try {
        await deleteVersion(version.id);
        toastSuccess({ title: "Successfully deleted version" });
      } catch {
        logger.error("Error while trying to delete flow version");
      }
    }
    onClose();
    navigate(getFlowVersionsUrl(params.orgId, params.wsId, params.flow_id));
  };

  return (
    <ConfirmationModal
      afterLeave={afterLeave}
      confirmationButtonClassname="w-[4.4rem]"
      confirmationButtonText="Delete"
      open={isOpen}
      title={`Delete version ${version?.name}`}
      onClose={onClose}
      onConfirm={onModalConfirm}
    >
      <div className="mb-6 mt-4 text-gray-500 font-inter-normal-12px">
        {parentFlowIds.length > 0 && (
          <span className="pr-0.5">
            Deleting this version will break the Connection with the Decision
            Flow versions listed below.
          </span>
        )}
        <span>
          Are you sure you want to delete this version? This action cannot be
          undone.
        </span>
      </div>
      <RelatedFlowList
        groupedRelatedFlows={groupedParentFlows}
        workspace={workspace}
      />
    </ConfirmationModal>
  );
};
