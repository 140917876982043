import {
  ConnectionResourceTemplate,
  makeConnectionTemplate,
} from "src/connections/types";

const proofOfInterestConfigKey: ConnectionResourceTemplate["configKeys"][number] =
  {
    key: "proof_of_interest",
    name: "Proof of interest",
    type: "simple_selection",
    required: true,
    elements: [
      { key: "ABB", value: "ABB" },
      { key: "ABD", value: "ABD" },
      { key: "ABF", value: "ABF" },
      { key: "ABI", value: "ABI" },
      { key: "ABK", value: "ABK" },
      { key: "ABL", value: "ABL" },
      { key: "ABV", value: "ABV" },
      { key: "ABW", value: "ABW" },
      { key: "ACK", value: "ACK" },
      { key: "AFK", value: "AFK" },
      { key: "AIK", value: "AIK" },
      { key: "BBV", value: "BBV" },
      { key: "BER", value: "BER" },
      { key: "BET", value: "BET" },
      { key: "BKA", value: "BKA" },
      { key: "BKE", value: "BKE" },
      { key: "BKO", value: "BKO" },
      { key: "BKV", value: "BKV" },
      { key: "BMO", value: "BMO" },
      { key: "BZV", value: "BZV" },
      { key: "GWP", value: "GWP" },
    ],
  };
export const CrifB2CTemplate = makeConnectionTemplate({
  provider: "crif_b2c",
  secrets: [
    { key: "client_id", name: "Client ID", required: true },
    { key: "client_secret", name: "Client secret", required: true },
  ],
  resources: {
    identity_check: {
      name: "Identity Check",
      configKeys: [proofOfInterestConfigKey],
    },
    credit_check: {
      name: "Credit Check",
      configKeys: [proofOfInterestConfigKey],
    },
  },
});
