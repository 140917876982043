import {
  RouterPresignedUrls,
  VersionNodeResultsPagePresignedUrlRequest,
} from "src/api/endpoints";

const fetchS3Url = async <T>(s3Url: string): Promise<T> => {
  try {
    const fetchResult = await fetch(s3Url);
    if (fetchResult.status !== 200) {
      throw new Error(
        `Error fetching results, status: ${
          fetchResult.status
        } ${await fetchResult.text()}`,
      );
    }
    const resultObject: Promise<T> = await fetchResult.json();
    return await resultObject;
  } catch (e) {
    throw new Error(`Error fetching results: ${e}`);
  }
};

export const fetchTestRunResultDataUrl = async <TRow>(
  workspaceBaseUrl: string,
  pageParam: VersionNodeResultsPagePresignedUrlRequest,
) => {
  const resultsUrlResponse = await RouterPresignedUrls.post(
    workspaceBaseUrl,
    pageParam.run_id,
    pageParam,
  );
  return await fetchS3Url<Record<string, TRow[]>>(resultsUrlResponse.data.url);
};
