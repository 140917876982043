import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faClose,
  faFilter,
  faMagnifyingGlass,
} from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { AnimatePresence, m } from "framer-motion";
import { debounce, fromPairs } from "lodash";
import { useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useOnClickOutside } from "usehooks-ts";

import { DecisionEnvironment } from "src/api/types";
import {
  DateRangePicker,
  DateRangePickerProps,
} from "src/base-components/DateRangePicker";
import { EnvironmentPill } from "src/base-components/EnvironmentPill";
import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { SearchBoxPropsT } from "src/base-components/SearchBox";
import { Tooltip } from "src/base-components/Tooltip";
import { HeaderBackLink } from "src/settings/SettingsPage";

type Props = {
  title: React.ReactNode;
  titleAction?: React.ReactNode;
  children: React.ReactNode;
  paddedParent?: boolean;
  backTo?: string;
};

type ButtonProps = {
  icon: IconProp;
  tooltip: string;
  disabled?: boolean;
  dataLoc?: string;
  spin?: boolean;
  onClick: () => void;
};

type DatePickerProps = Pick<
  DateRangePickerProps,
  "onChange" | "value" | "placeholder" | "rangeLimitInDays"
>;

type SearchBoxProps = Omit<SearchBoxPropsT, "value"> & { tooltip?: string };
type SandboxSwitchValue = "sandbox" | "prod";
type LiveSandboxSwitchProps = {
  value: SandboxSwitchValue;
  onChange: (value: SandboxSwitchValue) => void;
};

type ButtonType = React.FC<ButtonProps>;
type DatePickerType = React.FC<DatePickerProps>;
type SearchBoxType = React.FC<SearchBoxProps>;
type LiveSandboxSwitchType = React.FC<LiveSandboxSwitchProps>;

type SubComponents = {
  Button: ButtonType;
  DatePicker: DatePickerType;
  SearchBox: SearchBoxType;
  LiveSandboxSwitch: LiveSandboxSwitchType;
};

export const SubHeader: React.FC<Props> & SubComponents = ({
  title,
  titleAction,
  children,
  paddedParent,
  backTo,
}) => {
  /* ReviewQueueContent uses the height of this component to decide the height of its table */
  return (
    <div
      className={twJoin(
        "sticky z-10 flex h-12 items-center justify-between gap-x-3.5 border-x border-b border-gray-200 bg-white",
        paddedParent ? "-top-8 -mx-8 -mt-8 mb-8" : "top-0",
      )}
    >
      {backTo && (
        <div className="pl-4">
          <HeaderBackLink to={backTo} />
        </div>
      )}
      <h2 className={twJoin("font-inter-semibold-13px", !backTo && "pl-4")}>
        {title}
      </h2>
      {titleAction}
      <div className="ml-auto flex h-full items-center">{children}</div>
    </div>
  );
};

const SubHeaderButton: ButtonType = ({
  disabled,
  icon,
  tooltip,
  dataLoc,
  spin,
  onClick,
}) => {
  return (
    <Tooltip placement="bottom" title={tooltip} asChild>
      <button
        className={twJoin(
          "border-l border-gray-200 p-3.5 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500/25 disabled:cursor-not-allowed disabled:bg-gray-50",
        )}
        data-loc={dataLoc}
        disabled={disabled}
        type="button"
        onClick={onClick}
      >
        <Icon
          color={
            disabled ? "text-gray-300" : "text-gray-500 hover:text-gray-700"
          }
          icon={icon}
          size="xs"
          spin={spin}
        />
      </button>
    </Tooltip>
  );
};

const SubHeaderDatePicker: DatePickerType = ({
  onChange,
  placeholder,
  value,
}) => {
  return (
    <div
      className={twJoin(
        "flex h-full min-w-[15.5rem] items-center border-l border-gray-200 px-1.5",
        "[&:has(span[data-open])]:ring-2 [&:has(span[data-open])]:ring-inset [&:has(span[data-open])]:ring-indigo-500/25",
      )}
    >
      <DateRangePicker
        placeholder={placeholder}
        value={value}
        borderless
        suffixIcon
        onChange={onChange}
      />
    </div>
  );
};

const SearchBox: SearchBoxType = ({
  dataLoc,
  placeholder,
  defaultValue,
  tooltip,
  onChange,
}) => {
  const [hasFocus, setFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const isOpen =
    hasFocus || Boolean(defaultValue) || Boolean(inputRef.current?.value);
  const debouncedOnChange = debounce(onChange, 300);

  useOnClickOutside(
    containerRef,
    () => {
      setFocus(false);
    },
    "mouseup",
  );

  return (
    <Tooltip
      activated={Boolean(tooltip)}
      placement="bottom"
      title={tooltip}
      asChild
    >
      <div
        ref={containerRef}
        className={twJoin(
          "flex h-full cursor-pointer items-center overflow-hidden border-l border-gray-200 px-3.5 py-1",
          hasFocus && "ring-2 ring-inset ring-indigo-500/25",
        )}
        onClick={() => {
          setFocus(true);
          inputRef.current?.focus();
        }}
      >
        <Icon color="text-gray-500" icon={faMagnifyingGlass} size="xs" />
        <AnimatePresence>
          <m.div
            animate={isOpen ? "open" : "closed"}
            className="h-full"
            initial={isOpen ? "open" : "closed"}
            transition={{ type: "tween", ease: "easeOut" }}
            variants={{
              open: { width: "14rem" },
              closed: { width: "0" },
            }}
          >
            <input
              ref={inputRef}
              className="h-full w-full bg-white pl-2 font-inter-normal-12px placeholder:text-gray-500 focus:outline-none"
              data-loc={dataLoc}
              defaultValue={defaultValue}
              placeholder={placeholder}
              onChange={(event) => debouncedOnChange(event.target.value)}
              onFocus={() => setFocus(true)}
            />
          </m.div>
        </AnimatePresence>
      </div>
    </Tooltip>
  );
};

type FilterProps<T extends string> = {
  tooltip?: string;
  disabled?: boolean;
  dataLoc?: string;
  options: { key: T; value: string; disabled?: boolean; color?: string }[];
} & (
  | {
      onChange: (key: T | null) => void;
      selected: T | null;
      multiple?: false;
    }
  | {
      onChange: (key: T[] | null) => void;
      selected: T[] | null;
      multiple: true;
    }
);

const FilterLabel: React.FC<{
  label: string;
  color?: string;
  includeSeparator?: boolean;
}> = ({ label, color, includeSeparator }) => {
  return (
    <div className="flex items-center gap-x-1">
      {Boolean(color) && (
        <span style={{ color }}>
          <Icon icon={faCircle} size="3xs" />
        </span>
      )}
      <span>
        {label}
        {includeSeparator && ","}
      </span>
    </div>
  );
};

const FilterLabels: React.FC<{
  labels: string | string[];
  colors: Record<string, string | undefined>;
}> = ({ labels, colors }) => {
  const maxLabels = 3;
  const content = (() => {
    if (!Array.isArray(labels)) {
      return <FilterLabel color={colors[labels]} label={labels} />;
    }

    if (labels.length > maxLabels) {
      return (
        <>
          {labels.slice(0, maxLabels).map((label, i) => (
            <FilterLabel
              key={label}
              color={colors[label]}
              includeSeparator={i !== maxLabels - 1}
              label={label}
            />
          ))}
          <span className="pl-1 text-gray-500">
            +{labels.length - maxLabels} more
          </span>
        </>
      );
    } else {
      return labels.map((label, i) => (
        <FilterLabel
          key={label}
          color={colors[label]}
          includeSeparator={i !== labels.length - 1}
          label={label}
        />
      ));
    }
  })();

  return (
    <span className="flex min-w-[64px] items-center gap-x-1 text-left text-gray-700 font-inter-normal-12px">
      {content}
    </span>
  );
};

export const SubHeaderFilter = <T extends string>({
  tooltip,
  disabled,
  dataLoc,
  options,
  selected: selectedValue,
  multiple,
  onChange,
}: FilterProps<T>) => {
  const findColorByLabel = (label: string) =>
    options.find((o) => o.value === label)?.color;

  const renderButtonValue = (
    labels: string | string[] | undefined,
    _isOpen: boolean,
    close: () => void,
  ) => {
    const hasLabel = Array.isArray(labels)
      ? labels.length > 0
      : Boolean(labels);

    const labelsAsArray = Array.isArray(labels)
      ? labels
      : labels
        ? [labels]
        : [];
    const colors = fromPairs(
      labelsAsArray.map((label) => [label, findColorByLabel(label)]),
    );
    return (
      <Tooltip
        activated={Boolean(tooltip)}
        placement="bottom"
        title={tooltip}
        asChild
      >
        <div className="flex gap-x-1">
          <Icon
            color={
              disabled ? "text-gray-300" : "text-gray-500 hover:text-gray-700"
            }
            icon={faFilter}
            size="xs"
          />
          {!disabled && hasLabel && (
            <>
              <FilterLabels colors={colors} labels={labels!} />
              <Icon
                color="text-gray-500 hover:text-gray-700"
                icon={faClose}
                size="xs"
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(null);
                  close();
                }}
              />
            </>
          )}
        </div>
      </Tooltip>
    );
  };

  const renderValue = ({
    key,
    selected,
    value,
    color,
  }: {
    key: T;
    selected: boolean;
    value: string;
    color?: string;
  }) => {
    return (
      <div
        key={key}
        className="flex min-w-[14rem] items-center gap-x-1.5 px-4 py-2"
        data-loc={`${dataLoc}-${value}`}
      >
        {Boolean(color) && (
          <span style={{ color }}>
            <Icon icon={faCircle} size="3xs" />
          </span>
        )}
        <span>{value}</span>
        {selected && (
          <span className="ml-auto">
            <Icon color="text-indigo-600" icon={faCheck} size="sm" />
          </span>
        )}
      </div>
    );
  };

  const classNames = {
    buttonClassName: "border-0 rounded-none flex gap-x-1 p-3 min-w-[48px]",
    className: "h-full border-l border-gray-200",
    itemsClassNames: "min-w-[14rem]",
  };

  if (multiple) {
    return (
      <FixedPositionedDropdown<string, T>
        {...classNames}
        buttonDataLoc={dataLoc}
        disabled={disabled}
        elements={options}
        renderButtonValue={renderButtonValue}
        renderValue={renderValue}
        selected={selectedValue ?? undefined}
        multiple
        onSelect={onChange}
      />
    );
  } else {
    return (
      <FixedPositionedDropdown<string, T>
        {...classNames}
        buttonDataLoc={dataLoc}
        elements={options}
        renderButtonValue={renderButtonValue}
        renderValue={renderValue}
        selected={selectedValue ?? undefined}
        onSelect={onChange}
      />
    );
  }
};

/** @deprecated
 * Please use EnvironmentPill component directly instead
 */
const SubHeaderLiveSandboxSwitch: LiveSandboxSwitchType = ({
  value,
  onChange,
}) => (
  <EnvironmentPill
    value={
      value === "prod" ? DecisionEnvironment.LIVE : DecisionEnvironment.SANDBOX
    }
    onChange={(newValue) => {
      if (newValue === DecisionEnvironment.LIVE) {
        onChange("prod");
      } else {
        onChange("sandbox");
      }
    }}
  />
);

SubHeader.Button = SubHeaderButton;
SubHeader.DatePicker = SubHeaderDatePicker;
SubHeader.SearchBox = SearchBox;
SubHeader.LiveSandboxSwitch = SubHeaderLiveSandboxSwitch;
