import { faXmark, faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "src/base-components/Icon";
import { DOCS_FUNDAMENTALS_DECISION_HISTORY } from "src/constants/ExternalLinks";

export const Header = ({ onRequestClose }: { onRequestClose: () => void }) => (
  <div>
    <div className="mb-2 flex items-start justify-around px-6">
      <div className="grow">
        <div className="mt-0.5 text-gray-800 font-inter-semibold-16px">
          Decision history
        </div>
        <div className="mt-1 text-gray-500 font-inter-normal-12px">
          A record of every decision made by this particular version of the
          Decision Flow. Usually, new decisions can take a few seconds to
          appear.
        </div>
      </div>

      <div className="mt-1 flex flex-row items-center gap-x-2">
        <a
          href={DOCS_FUNDAMENTALS_DECISION_HISTORY}
          rel="noreferrer"
          target="_blank"
        >
          <Icon
            color="text-gray-500 hover:text-gray-700"
            icon={faQuestionCircle}
            size="xs"
          />
        </a>
        <Icon
          color="text-gray-500 hover:text-gray-700"
          dataLoc="close-decision-history-side-pane"
          icon={faXmark}
          size="xs"
          onClick={onRequestClose}
        />
      </div>
    </div>
    <div className="mb-1 mt-4 px-6">
      <div className="h-px w-full rounded-full bg-gray-100"></div>
    </div>
  </div>
);
