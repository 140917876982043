import { twMerge } from "tailwind-merge";

import { Card } from "src/base-components/Card";

type PropsT = {
  children: React.ReactNode;
  className?: string;
};

export const ConfigFieldsetCard: React.FC<PropsT> = ({
  children,
  className,
}) => {
  const classes = twMerge("mb-6", className);

  return (
    <Card className={classes}>
      <Card.Content>{children}</Card.Content>
    </Card>
  );
};
