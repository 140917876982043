import { NodeBET } from "src/api/flowTypes";
import { updateFlowVersion } from "src/api/flowVersionUpdateIndex";
import { FlowVersionUpdate } from "src/clients/flow-api";

/**
 * Applies queued node updates
 */
export const getOptimistacallyUpdatedNodes = (
  versionId: string,
  baseState: NodeBET[],
): NodeBET[] => {
  const nodesMap: Map<string, NodeBET> = new Map();
  baseState.forEach((node) => {
    nodesMap.set(node.id, node);
  });
  const queuedUpdates = updateFlowVersion.queueStoreHook.getState().queue;
  for (var i = 0; i < queuedUpdates.length; i++) {
    if (queuedUpdates[i].args[0] !== versionId) continue;
    const flowVersionUpdate: FlowVersionUpdate | undefined =
      queuedUpdates[i].args[1];
    const currentNodeUpdates = flowVersionUpdate?.graph?.nodes;
    if (!currentNodeUpdates) continue;
    Object.keys(currentNodeUpdates).forEach((nodeId) => {
      if (!nodesMap.has(nodeId) || !currentNodeUpdates[nodeId]) return;
      Object.keys(currentNodeUpdates[nodeId]).forEach(
        // Not sure how this can be typed correctly, but it won't break existing types under the condition
        // that nodesMap and currentNodeUpdates contain correct node types
        (updatedKey) =>
          //@ts-ignore
          (nodesMap.get(nodeId)[updatedKey] =
            //@ts-ignore
            currentNodeUpdates[nodeId][updatedKey]),
      );
    });
  }
  return Array.from(nodesMap.values());
};
