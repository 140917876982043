import {
  IconDefinition,
  faCheck,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";

import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { DecideDecisionError, DecideDecisionSuccess } from "src/api/types";
import { PillVariants } from "src/base-components/Pill";
import {
  OutgoingWebhookResponse,
  OutgoingWebhookTestDetailType,
} from "src/webhooks/queries";

// Aligned with our RetryPolicy of 24 hours of retries (see webhooks.py)
const RETRY_CUTTOFF_SECS = 60 * 60 * 24 - 60;

const statusIsError = (code: number): boolean => code >= 400;

export interface DeliveryStatus {
  tooltipTitle?: string;
  tooltipBody?: string;
  willRetry: boolean;
  canRetry: boolean;
  pillVariant: PillVariants;
  pillIcon: IconDefinition;
  // Usually the pillText is the status code
  // Except for InternalErrors
  pillText: string;
}

export const classifyDelivery = (
  attempt_time: Date | undefined,
  response: OutgoingWebhookResponse | undefined,
): DeliveryStatus => {
  if (response === undefined)
    return {
      tooltipTitle: "Webhook notification failed",
      tooltipBody:
        "Due to an internal error this notification could not be sent.",
      willRetry: false,
      canRetry: false,
      pillIcon: faWarning,
      pillVariant: "red",
      pillText: "Internal Error",
    };

  const isError = statusIsError(response.status);
  const pillText = String(response.status);
  const pillVariant = isError ? "red" : "green";
  const pillIcon = isError ? faWarning : faCheck;
  const willRetry = attempt_time
    ? willRetryCalc(attempt_time, response)
    : false;
  const canRetry = true;

  const { tooltipTitle = undefined, tooltipBody = undefined } =
    response["detail-type"] === OutgoingWebhookTestDetailType
      ? {
          tooltipTitle: "Test notification",
          tooltipBody: "This notification was sent as a test.",
        }
      : isError && willRetry
        ? {
            tooltipTitle: "Webhook notification failed",
            tooltipBody:
              "This webhook delivery has failed. Taktile will retry within 10 minutes.",
          }
        : isError && !willRetry && response["source"] === "browser"
          ? {
              tooltipTitle: "Webhook notification failed",
              tooltipBody:
                "This manual retry of webhook delivery has failed. Manual retries are not automatically retried.",
            }
          : isError && !willRetry && retryableCode(response.status)
            ? {
                tooltipTitle: "Webhook notification failed",
                tooltipBody:
                  "This webhook delivery has failed all automatic retries. You can choose to retry manually.",
              }
            : {};

  return {
    tooltipTitle,
    tooltipBody,
    canRetry,
    willRetry,
    pillIcon,
    pillVariant,
    pillText,
  };
};

// See
// See https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-api-destinations.html#eb-api-destination-error-codes
const retryableCode = (status: number): boolean =>
  status === 409 || status === 429 || status >= 500;

const willRetryCalc = (
  attempt_time: Date,
  response: OutgoingWebhookResponse | undefined,
) =>
  response !== undefined && // Internal errors not sure
  // Tests not retried
  response["detail-type"] !== OutgoingWebhookTestDetailType &&
  // Manual retries are not retried
  response["source"] !== "browser" &&
  // AWS retry policy
  retryableCode(response.status) &&
  // Effect of our RetryPolicy bounds (see RETRY_CUTTOFF_SECS comments above)
  attempt_time.getTime() - new Date(response?.request_body?.time).getTime() <
    RETRY_CUTTOFF_SECS * 1000;

export const webhookResponse = (
  datum:
    | DecisionHistoryRecordV2<OutgoingWebhookResponse>
    | DecideDecisionError
    | DecideDecisionSuccess<OutgoingWebhookResponse>
    | undefined,
): OutgoingWebhookResponse | undefined => {
  // Figure out if the data contains a valid webhook response,
  // otherwise return undefined
  if (!datum) return undefined;
  if ("is_error" in datum) {
    // DecisionHistoryRecordV2 case
    return datum.is_error
      ? typeof datum.response.detail.msg == "object"
        ? (datum.response.detail.msg as OutgoingWebhookResponse)
        : undefined
      : datum.response.data;
  } else if ("detail" in datum) {
    // DecideDecisionError case
    return typeof datum.detail.msg === "object"
      ? (datum.detail.msg as OutgoingWebhookResponse)
      : undefined;
  } else if ("data" in datum) {
    // DecideDecisionSuccess case
    return datum.data;
  } else {
    return undefined;
  }
};
