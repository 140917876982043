import {
  ConnectionResourceTemplate,
  makeConnectionTemplate,
} from "src/connections/types";

const configKeys: ConnectionResourceTemplate["configKeys"] = [
  {
    key: "config_key",
    name: "Config key",
    type: "text",
    required: false,
  },
];

export const CrsTemplate = makeConnectionTemplate({
  provider: "crs",
  secrets: [
    { key: "username", name: "Username", required: true },
    { key: "password", name: "Password", required: true },
  ],
  resources: {
    experian_business_profile_report: {
      name: "Experian Business Profile Report",
      configKeys,
    },
    experian_business_search: {
      name: "Experian Business Search",
      configKeys: [],
    },
    experian_credit_report: {
      name: "Experian Credit Report",
      configKeys,
    },
    experian_intelliscore_business_report: {
      name: "Experian Intelliscore Business Report",
      configKeys: [],
    },
    transunion_credit_report: {
      name: "Transunion Credit Report",
      configKeys,
    },
    transunion_imv_basic: {
      name: "TruValidate IMV Basic",
      configKeys,
    },
    lexisnexis_bridger_search: {
      name: "LexisNexis Bridger Search",
      configKeys,
    },
  },
});
