import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { FieldErrorsT } from "src/api/types";
import { Button } from "src/base-components/Button";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Icon } from "src/base-components/Icon";
import {
  CustomConnectionForm,
  getEmptyKeyValuePair,
} from "src/customConnectionNode/types";

type PropsT = {
  fieldName: "params" | "headers" | "body.value.key_values";
  addButtonText: string;
  immutable: boolean;
  runFieldErrors: FieldErrorsT | undefined;
};

export const KeyValueArrayEditor: React.FC<PropsT> = ({
  fieldName,
  addButtonText,
  immutable,
  runFieldErrors,
}) => {
  const { control } = useFormContext<CustomConnectionForm>();
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: fieldName,
  });
  return (
    <>
      {fields.length > 0 && (
        <div className="mb-1 flex text-gray-800 font-inter-normal-13px">
          <span className="w-1/2">Key</span>
          <span className="w-1/2">Value</span>
          {/* To match the possible delete icon in other fex rows we add a ghost div */}
          {!immutable && <div className="w-4.5" />}
        </div>
      )}
      {fields.map((field, index) => (
        <div
          key={field.id}
          className={twJoin(
            "flex gap-x-2",
            index !== fields.length - 1 && "mb-1",
            index === fields.length - 1 && !immutable && "mb-1.5",
          )}
        >
          <Controller
            control={control}
            name={`${fieldName}.${index}.key`}
            render={({ field: controlledField }) => (
              <AutocompleteCodeInput
                dataLoc={`cc-node-${fieldName}.${index}.key`}
                disabled={immutable}
                error={runFieldErrors?.[field.id_key]}
                placeholder="Enter key"
                value={controlledField.value}
                plaintext
                onChange={controlledField.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name={`${fieldName}.${index}.value`}
            render={({ field: controlledField }) => (
              <AutocompleteCodeInput
                dataLoc={`cc-node-${fieldName}.${index}.value`}
                disabled={immutable}
                error={runFieldErrors?.[field.id_value]}
                placeholder="data.field"
                value={controlledField.value}
                onChange={controlledField.onChange}
              />
            )}
          />
          {!immutable && (
            <div className="flex items-center justify-center">
              <Icon
                color="text-gray-500"
                icon={faTrashAlt}
                size="sm"
                onClick={() => remove(index)}
              />
            </div>
          )}
        </div>
      ))}
      {!immutable && (
        <Button
          disabled={immutable}
          iconLeft={faPlus}
          size="sm"
          variant="secondary"
          onClick={() => {
            append(getEmptyKeyValuePair());
          }}
        >
          {addButtonText}
        </Button>
      )}
    </>
  );
};
