import { faCompress } from "@fortawesome/pro-regular-svg-icons";
import { observer } from "mobx-react-lite";
import React from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { twJoin } from "tailwind-merge";

import { useProviderManifest } from "src/api/connectApi/queries";
import { Icon } from "src/base-components/Icon";
import { useCommentsSummary } from "src/comments/queries";
import { DEFAULT_UNTITLED_GROUP_NAME } from "src/constants/DefaultValues";
import {
  CustomConnectionNodeDataT,
  DecisionTableNodeDataT,
  AssignmentNodeDataT,
  IntegrationNodeDataT,
  MLNodeDataT,
  NodeBaseT,
  RuleNodeV2DataT,
  SimpleNodeDataT,
  SplitNodeV2DataT,
  ScorecardNodeDataT,
  NodeRunStateV2,
  GroupNodeDataT,
  BeMappedNode,
  GroupSeparatorNodeDataT,
  InboundWebhookConnectionNodeDataT,
  DatabaseConnectionNodeDataT,
  ManifestConnectionNodeDataT,
} from "src/constants/NodeDataTypes";
import { NodeIcon as getNodeIcon, GroupIcon } from "src/constants/NodeIcons";
import { getNodeTitle } from "src/constants/NodeProperties";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { useSelectedResultsRowIndex } from "src/flowContainer/AuthoringUIContext";
import {
  BaseNode,
  BaseNodeProps,
  handleClassName,
  renderHandles,
} from "src/flowGraph/BaseNode";
import { BranchNode } from "src/flowGraph/BranchNode";
import { CommentCount } from "src/flowGraph/CommentCount";
import { NodeRunIconV2, GroupNodeRunIcon } from "src/flowGraph/NodeRunIconV2";
import { ResultOverviewDonut } from "src/flowGraph/ResultOverviewDonut";
import { GroupControls } from "src/flowGraph/nodeControls/GroupControls";
import { NodeControls } from "src/flowGraph/nodeControls/NodeControls";
import { useBranchAnalytics } from "src/flowGraph/useBranchAnalytics";
import { getCreatableNodes } from "src/nodeAdding/PasteNodesCard";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { AuthorPageParamsT } from "src/router/urls";
import { useNodeHighlighting } from "src/store/NodeHighlighting";
import { useNodeValidations } from "src/store/NodeValidations";
import { useGraphStore } from "src/store/StoreProvider";
import {
  useFlowOuput,
  useNodeRunState,
  useNodeRunStates,
} from "src/store/runState/RunState";
import { pluralize } from "src/utils/stringUtils";
import { useOpenOnHover } from "src/utils/useOpenOnHover";
import { useParamsDecode } from "src/utils/useParamsDecode";

type InputOutputBaseDivPropsT = {
  selected: boolean;
  children: React.ReactNode;
  id: string;
  runStateV2: NodeRunStateV2 | undefined;
} & (
  | {
      nodeType: NODE_TYPE.INPUT_NODE;
    }
  | { nodeType: NODE_TYPE.OUTPUT_NODE; flowOutput?: NodeRunStateV2 }
);

type SimpleNodeProps = Pick<
  BaseNodeProps,
  "icon" | "title" | "selected" | "label"
> & {
  id: string;
  runStateV2?: NodeRunStateV2;
  withoutControls?: boolean;
};

const SimpleNode: React.FC<SimpleNodeProps> = ({
  icon,
  title,
  id,
  label,
  selected,
  runStateV2,
  withoutControls = false,
}) => {
  const { isMultiselecting } = useGraphStore();
  const isErrored =
    runStateV2?.type === "historical-error" ||
    runStateV2?.type === "test-error";
  const { version_id } = useParamsDecode<AuthorPageParamsT>();
  const { data: commentCount } = useCommentsSummary(version_id, id);
  const isInvalid = useNodeValidations((state) => state.isInvalid(id));

  const {
    data: { nodesGrayedOut },
  } = useNodeHighlighting();
  const grayedOut = Boolean(nodesGrayedOut[id]);

  const selectedRowIndex = useSelectedResultsRowIndex();

  return (
    <BaseNode
      commentCount={
        commentCount ? <CommentCount count={commentCount} /> : undefined
      }
      grayedOut={grayedOut}
      icon={icon}
      id={id}
      isErrored={isErrored}
      isInvalid={isInvalid}
      label={label}
      renderControls={
        !withoutControls
          ? ({ onMouseEnter, onMouseLeave, close }) => (
              <>
                {!isMultiselecting && (
                  <NodeControls
                    close={close}
                    id={id}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                  />
                )}
              </>
            )
          : undefined
      }
      runIcon={
        selectedRowIndex === null ? (
          <NodeRunIconV2 runStateV2={runStateV2} />
        ) : undefined
      }
      selected={selected}
      title={title}
    />
  );
};

const InputOutputBaseDiv: React.FC<InputOutputBaseDivPropsT> = ({
  selected,
  children,
  runStateV2,
  id,
  ...props
}) => {
  const { version_id } = useParamsDecode<AuthorPageParamsT>();
  const { data: commentCount } = useCommentsSummary(version_id, id);
  const {
    data: { nodesGrayedOut },
  } = useNodeHighlighting();
  const grayedOut = Boolean(nodesGrayedOut[id]);

  const selectedRowIndex = useSelectedResultsRowIndex();
  return (
    <>
      <div
        className={twJoin(
          "inline-flex h-11 min-w-[85px] items-center justify-center truncate rounded-3xl bg-white px-4 shadow-base font-inter-semibold-13px hover:cursor-pointer",
          grayedOut && "text-gray-500",
          !grayedOut && "text-gray-800",
          (runStateV2?.type === "historical-error" ||
            runStateV2?.type === "test-error") &&
            selected &&
            "border border-red-600",
          runStateV2?.type !== "historical-error" &&
            runStateV2?.type !== "test-error" &&
            selected &&
            "border border-indigo-400 outline-none ring-2 ring-indigo-500/25",
        )}
        data-loc={
          props.nodeType === NODE_TYPE.INPUT_NODE ? "input-node" : "output-node"
        }
      >
        {children}
        <div
          className={twJoin(
            "inline-flex items-center justify-center",
            grayedOut && "brightness-[1.02] grayscale",
          )}
        >
          {commentCount ? (
            <div className="ml-2">
              <CommentCount count={commentCount} />
            </div>
          ) : undefined}
          {props.nodeType === NODE_TYPE.INPUT_NODE &&
            selectedRowIndex === null && (
              <NodeRunIconV2 runStateV2={runStateV2} />
            )}
          {props.nodeType === NODE_TYPE.OUTPUT_NODE &&
            selectedRowIndex === null &&
            (props.flowOutput?.type === "test-run" ? (
              <ResultOverviewDonut
                nodeTestResult={props.flowOutput.testResult}
              />
            ) : (
              <NodeRunIconV2 runStateV2={runStateV2} />
            ))}
        </div>
      </div>
      {props.nodeType === NODE_TYPE.INPUT_NODE && (
        <Handle
          className={handleClassName}
          isConnectable={false}
          position={Position.Bottom}
          type="source"
        />
      )}
      {props.nodeType === NODE_TYPE.OUTPUT_NODE && (
        <Handle
          className={handleClassName}
          isConnectable={false}
          position={Position.Top}
          type="target"
        />
      )}
    </>
  );
};

const InputNode: React.FC<NodeProps<NodeBaseT>> = ({ selected, id }) => {
  const runState = useNodeRunState(id);
  return (
    <InputOutputBaseDiv
      id={id}
      nodeType={NODE_TYPE.INPUT_NODE}
      runStateV2={runState}
      selected={selected}
    >
      <div>Input</div>
    </InputOutputBaseDiv>
  );
};

const OutputNode: React.FC<NodeProps<NodeBaseT>> = ({ selected, id }) => {
  const runState = useNodeRunState(id);
  const flowOutput = useFlowOuput();
  return (
    <InputOutputBaseDiv
      flowOutput={flowOutput}
      id={id}
      nodeType={NODE_TYPE.OUTPUT_NODE}
      runStateV2={runState}
      selected={selected}
    >
      <div>Output</div>
    </InputOutputBaseDiv>
  );
};

const CodeNode: React.FC<NodeProps<SimpleNodeDataT>> = ({
  data,
  selected,
  id,
}) => {
  const runState = useNodeRunState(id);
  return (
    <SimpleNode
      icon={getNodeIcon({ nodeType: NODE_TYPE.CODE_NODE })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={getNodeTitle({ nodeType: NODE_TYPE.CODE_NODE })}
    />
  );
};

const SplitNodeV2: React.FC<NodeProps<SplitNodeV2DataT>> = ({
  data,
  selected,
  id,
}) => {
  const runState = useNodeRunState(id);
  return (
    <SimpleNode
      icon={getNodeIcon({ nodeType: NODE_TYPE.SPLIT_NODE_V2 })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={getNodeTitle({ nodeType: NODE_TYPE.SPLIT_NODE_V2 })}
    />
  );
};

const SplitBranchNode: React.FC<NodeProps<SimpleNodeDataT>> = ({
  data,
  selected,
  id,
}) => {
  const {
    data: { nodesGrayedOut },
  } = useNodeHighlighting();
  const analytics = useBranchAnalytics(id);

  return (
    <BranchNode
      analytics={analytics}
      grayedOut={Boolean(nodesGrayedOut[id])}
      label={data.label}
      selected={selected}
    />
  );
};

/**
 * The split merge node has the minumum width and height react flow supports (1px)
 */
const SplitMergeNode: React.FC<NodeProps<SimpleNodeDataT>> = observer(() => {
  return (
    <>
      <div
        className="nodrag h-[1px] w-[1px] items-center justify-center opacity-0"
        data-loc="split-merge"
      />
      {renderHandles({ sourceHandleClassName: "-bottom-[2px]" })}
    </>
  );
});

const RuleNodeV2: React.FC<NodeProps<RuleNodeV2DataT>> = ({
  data,
  selected,
  id,
}) => {
  const runState = useNodeRunState(id);
  return (
    <SimpleNode
      icon={getNodeIcon({ nodeType: NODE_TYPE.RULE_NODE_V2 })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={getNodeTitle({ nodeType: NODE_TYPE.RULE_NODE_V2 })}
    />
  );
};

const IntegrationNode: React.FC<NodeProps<IntegrationNodeDataT>> = ({
  data,
  selected,
  id,
}) => {
  const runState = useNodeRunState(id);
  return (
    <SimpleNode
      icon={getNodeIcon({
        nodeType: NODE_TYPE.INTEGRATION_NODE,
        provider: data.providerResource.provider,
        resource: data.providerResource.resource,
        size: "md",
      })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={getNodeTitle({
        nodeType: NODE_TYPE.INTEGRATION_NODE,
        provider: data.providerResource.provider,
      })}
    />
  );
};

const ManifestConnectionNode: React.FC<
  NodeProps<ManifestConnectionNodeDataT>
> = ({ data, selected, id }) => {
  const runState = useNodeRunState(id);
  const { workspace } = useWorkspaceContext();
  const baseUrl = workspace.base_url;
  const provider = data.providerResource.provider;
  const manifestVersion = data.providerResource.manifest_version;
  const manifest = useProviderManifest(baseUrl, provider, manifestVersion).data;
  return (
    <SimpleNode
      icon={getNodeIcon({
        nodeType: NODE_TYPE.MANIFEST_CONNECTION_NODE,
        provider: provider,
        size: "md",
      })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={manifest?.display_name || provider}
    />
  );
};

const MLNode: React.FC<NodeProps<MLNodeDataT>> = ({ data, selected, id }) => {
  const runState = useNodeRunState(id);
  return (
    <SimpleNode
      icon={getNodeIcon({ nodeType: NODE_TYPE.ML_NODE })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={getNodeTitle({ nodeType: NODE_TYPE.ML_NODE })}
    />
  );
};

const CustomConnectionNode: React.FC<NodeProps<CustomConnectionNodeDataT>> = ({
  data,
  selected,
  id,
}) => {
  const runState = useNodeRunState(id);
  return (
    <SimpleNode
      icon={getNodeIcon({
        nodeType: NODE_TYPE.CUSTOM_CONNECTION_NODE,
        provider: "custom",
        mediaKey: data.mediaKey,
        size: "md",
      })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={getNodeTitle({ nodeType: NODE_TYPE.CUSTOM_CONNECTION_NODE })}
    />
  );
};

const InboundWebhookConnectionNode: React.FC<
  NodeProps<InboundWebhookConnectionNodeDataT>
> = ({ data, selected, id }) => {
  const runState = useNodeRunState(id);
  return (
    <SimpleNode
      icon={getNodeIcon({
        nodeType: NODE_TYPE.WEBHOOK_CONNECTION_NODE,
        provider: "webhook",
        mediaKey: data.mediaKey,
        size: "md",
      })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={getNodeTitle({ nodeType: NODE_TYPE.WEBHOOK_CONNECTION_NODE })}
    />
  );
};

const DatabaseConnectionNode: React.FC<
  NodeProps<DatabaseConnectionNodeDataT>
> = ({ data, selected, id }) => {
  const runState = useNodeRunState(id);
  return (
    <SimpleNode
      icon={getNodeIcon({
        nodeType: NODE_TYPE.SQL_DATABASE_CONNECTION_NODE,
        provider: data.providerResource.provider,
        resource: data.providerResource.resource,
        size: "md",
      })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={getNodeTitle({
        nodeType: NODE_TYPE.SQL_DATABASE_CONNECTION_NODE,
        provider: data.providerResource.provider,
      })}
    />
  );
};

const DecisionTableNode: React.FC<NodeProps<DecisionTableNodeDataT>> = ({
  data,
  selected,
  id,
}) => {
  const runState = useNodeRunState(id);
  return (
    <SimpleNode
      icon={getNodeIcon({
        nodeType: NODE_TYPE.DECISION_TABLE_NODE,
      })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={getNodeTitle({ nodeType: NODE_TYPE.DECISION_TABLE_NODE })}
    />
  );
};

const AssignmentNode: React.FC<NodeProps<AssignmentNodeDataT>> = ({
  data,
  selected,
  id,
}) => {
  const runState = useNodeRunState(id);
  return (
    <SimpleNode
      icon={getNodeIcon({
        nodeType: NODE_TYPE.ASSIGNMENT_NODE,
      })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={getNodeTitle({ nodeType: NODE_TYPE.ASSIGNMENT_NODE })}
    />
  );
};

const ScorecardNode: React.FC<NodeProps<ScorecardNodeDataT>> = ({
  data,
  selected,
  id,
}) => {
  const runState = useNodeRunState(id);
  return (
    <SimpleNode
      icon={getNodeIcon({
        nodeType: NODE_TYPE.SCORECARD_NODE,
      })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={getNodeTitle({ nodeType: NODE_TYPE.SCORECARD_NODE })}
    />
  );
};

export const GroupNode: React.FC<NodeProps<GroupNodeDataT>> = observer(
  ({ data, selected, id }) => {
    const runStates = useNodeRunStates();
    const { toggleGroupNode, nodesArray, nodes, isMultiselecting, groups } =
      useGraphStore();
    const { isOpen, setIsOpen, onMouseEnter, onMouseLeave } = useOpenOnHover<
      HTMLDivElement,
      HTMLDivElement
    >(300);
    const childNodes = nodesArray.filter((node) =>
      data.childrenIDs.includes(node.id),
    );
    const { version_id } = useParamsDecode<AuthorPageParamsT>();
    const { data: commentCount } = useCommentsSummary(
      version_id,
      childNodes.map((n) => n.id),
    );
    const {
      data: { groupsGrayedOut },
    } = useNodeHighlighting();
    const grayedOut = Boolean(groupsGrayedOut[id]);

    if (data.uiState.expanded) {
      const groupNodeStyle = {
        minWidth: groups.get(id)?.width ?? undefined,
        minHeight: groups.get(id)?.height ?? undefined,
      };
      return (
        <div
          className="relative rounded-lg border border-gray-200 bg-gray-200 bg-opacity-30 px-3 pt-4.5"
          style={groupNodeStyle}
        >
          <div
            className={twJoin(
              "flex items-center overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-200 bg-white px-2 py-1 shadow-sm font-inter-semibold-13px",
              grayedOut ? "text-gray-500" : "text-gray-800",
              grayedOut ? "shadow-base" : "shadow-lg",
            )}
            data-loc={data.label}
            onClick={() => toggleGroupNode(id)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <div className={twJoin(grayedOut && "brightness-[1.02] grayscale")}>
              <GroupIcon groupId={id} size="sm" />
            </div>
            <div className="flex flex-grow items-center justify-center">
              <span className="max-w-44 truncate text-center">
                {data.label ? data.label : DEFAULT_UNTITLED_GROUP_NAME}
              </span>
            </div>
            <Icon
              color="text-gray-500"
              data-loc="toggle-group"
              icon={faCompress}
              size="2xs"
            />
          </div>
          {isOpen && !isMultiselecting && (
            <GroupControls
              close={() => setIsOpen(false)}
              id={id}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          )}
        </div>
      );
    }

    const childNodeRunState = data.childrenIDs.map((childId) =>
      runStates.get(childId),
    );
    return (
      <div data-loc="toggle-group" onClick={() => toggleGroupNode(id)}>
        <BaseNode
          commentCount={
            commentCount ? <CommentCount count={commentCount} /> : undefined
          }
          defaultName={DEFAULT_UNTITLED_GROUP_NAME}
          grayedOut={grayedOut}
          icon={getNodeIcon({ nodeType: NODE_TYPE.GROUP_NODE, groupId: id })}
          id={id}
          isErrored={false}
          label={data.label}
          renderControls={({ onMouseEnter, onMouseLeave, close }) => (
            <>
              {!isMultiselecting && (
                <GroupControls
                  close={close}
                  id={id}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
              )}
            </>
          )}
          runIcon={<GroupNodeRunIcon runStates={childNodeRunState} />}
          selected={selected}
          title={pluralize(
            getCreatableNodes(
              data.childrenIDs
                .map((childId) => nodes.get(childId))
                .filter((n) => n !== undefined) as BeMappedNode[],
            ).length,
            "node",
          )}
          withUnderlay
        />
      </div>
    );
  },
);

/**
 * A transparent node to keep space for the cosmetic node rendered by an open group node.
 */
const GroupPreSeparatorNode: React.FC<
  NodeProps<GroupSeparatorNodeDataT>
> = () => {
  return (
    <>
      <div className="h-[31px] w-1 bg-transparent" />
      {renderHandles()}
    </>
  );
};

const GroupPostSeparatorNode: React.FC<
  NodeProps<GroupSeparatorNodeDataT>
> = () => {
  return (
    <>
      <div className="nodrag flex h-[19px] w-1 items-center justify-center" />
      {renderHandles()}
    </>
  );
};

const FlowNode: React.FC<NodeProps> = ({ id, data, selected }) => {
  const runState = useNodeRunState(id);
  return (
    <SimpleNode
      icon={getNodeIcon({
        nodeType: NODE_TYPE.FLOW_NODE,
      })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={getNodeTitle({ nodeType: NODE_TYPE.FLOW_NODE })}
    />
  );
};

const LoopNode: React.FC<NodeProps> = ({ id, data, selected }) => {
  const runState = useNodeRunState(id);
  return (
    <SimpleNode
      icon={getNodeIcon({
        nodeType: NODE_TYPE.LOOP_NODE,
      })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={getNodeTitle({ nodeType: NODE_TYPE.LOOP_NODE })}
    />
  );
};

const ManualReviewNode: React.FC<NodeProps> = ({ id, data, selected }) => {
  const runState = useNodeRunState(id);
  return (
    <SimpleNode
      icon={getNodeIcon({ nodeType: NODE_TYPE.REVIEW_CONNECTION_NODE })}
      id={id}
      label={data.label}
      runStateV2={runState}
      selected={selected}
      title={getNodeTitle({ nodeType: NODE_TYPE.REVIEW_CONNECTION_NODE })}
    />
  );
};

export const NODES: Record<NODE_TYPE, React.ComponentType<NodeProps<any>>> = {
  input_node: InputNode,
  code_node: CodeNode,
  output_node: OutputNode,
  rule_node_v2: RuleNodeV2,
  split_node_v2: SplitNodeV2,
  split_branch_node_v2: SplitBranchNode,
  split_merge_node: SplitMergeNode,
  data_integration_node: IntegrationNode,
  manifest_connection_node: ManifestConnectionNode,
  ml_node: MLNode,
  decision_table_node: DecisionTableNode,
  assignment_node: AssignmentNode,
  custom_connection_node: CustomConnectionNode,
  webhook_connection_node: InboundWebhookConnectionNode,
  sql_database_connection_node: DatabaseConnectionNode,
  scorecard_node: ScorecardNode,
  group_node: GroupNode,
  group_pre_separator_node: GroupPreSeparatorNode,
  group_post_separator_node: GroupPostSeparatorNode,
  flow_node: FlowNode,
  review_connection_node: ManualReviewNode,
  loop_node: LoopNode,
};
