import { faInputPipe } from "@fortawesome/pro-regular-svg-icons";
import { AnimatePresence, m } from "framer-motion";
import { capitalize, times } from "lodash";
import { useRef, useState } from "react";
import ReactDOM from "react-dom";

import { ProviderT } from "src/api/connectApi/types";
import { GenericObjectT } from "src/api/flowTypes";
import {
  ReviewCaseExternalReport,
  ReviewCaseHighlightsElement,
} from "src/api/types";
import { EmptyState } from "src/base-components/EmptyState";
import { Tabs } from "src/base-components/Tabs";
import { mdService } from "src/base-components/TextEditor/MarkdownService";
import { Tooltip } from "src/base-components/Tooltip";
import { getProviderName } from "src/connections/ProviderResourceProperties";
import { ProviderIcon } from "src/connections/config/Icon";
import { isValidHTTPURL } from "src/connections/urls";
import { ObjectDetailPane } from "src/dataTable/ObjectDetailPane";
import { ObjectDetailPopover } from "src/dataTable/ObjectDetailPopover";
import { JSONValue } from "src/datasets/DatasetTable/types";
import { ExcludesFalse } from "src/flow/types";
import { SkeletonLine } from "src/manualReview/reviewCaseCommon/SkeletonLine";
import { formatNumber } from "src/utils/numbers";
import {
  speakPythonPrimitive,
  stringifiedObjectSpeakPython,
} from "src/utils/speakPython";
import { assertUnreachable } from "src/utils/typeUtils";

const CARD_VERTICAL_MARGIN = "32px";
const TABS_HEIGHT = "47px";

export const Card: React.FC<{
  isLoading?: boolean;
  highlights: ReviewCaseHighlightsElement[];
  inspectData?: GenericObjectT;
  externalData?: ReviewCaseExternalReport[];
  isPreview?: boolean;
}> = ({ isLoading, highlights, inspectData, externalData, isPreview }) => {
  const humanifyExternalReportName = (
    resource: string,
    provider?: ProviderT,
  ) => {
    const providerPart = provider ? getProviderName(provider) + " " : "";

    const resourcePart = resource.split("_").map(capitalize).join(" ");

    return providerPart + resourcePart;
  };
  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <div
        className="mx-auto min-h-[400px] w-[662px] overflow-hidden rounded-xl border border-gray-200 bg-white"
        style={{
          marginTop: CARD_VERTICAL_MARGIN,
          marginBottom: CARD_VERTICAL_MARGIN,
        }}
      >
        {!isLoading ? (
          <Tabs
            containerClassName="flex flex-col max-h-full"
            defaultActiveKey="highlights"
            panelsClassName="overflow-auto min-h-0 decideScrollbar"
            tabClassName="px-1 py-3 mr-2 flex-shrink-0"
            tabListClassName={`px-4 pb-0.5 border-b border-gray-200 flex overflow-auto flex-shrink-0 h-[${TABS_HEIGHT}]`}
            tabs={[
              {
                key: "highlights",
                label: "Highlights",
                content: (
                  <HighlightsDisplay
                    highlights={highlights}
                    isPreview={isPreview}
                  />
                ),
              },
              !!inspectData && {
                key: "inspect_data",
                label: "Inspect Data",
                content: <InspectDataDisplay data={inspectData} />,
              },
              ...(externalData
                ? externalData.map((report) => ({
                    key: report.node_name + report.provider,
                    label: (
                      <Tooltip
                        placement="top"
                        title={
                          report.connection_name ??
                          humanifyExternalReportName(
                            report.resource,
                            report.provider,
                          )
                        }
                        triggerClassName="block"
                      >
                        <div className="flex h-full items-center">
                          <ProviderIcon provider={report.provider} size="sm" />
                          <p className="ml-1.5">
                            {report.connection_name ??
                              humanifyExternalReportName(report.resource)}
                          </p>
                        </div>
                      </Tooltip>
                    ),
                    content: <ReportDisplay value={report.response} />,
                  }))
                : []),
            ].filter(Boolean as unknown as ExcludesFalse)}
          />
        ) : (
          <div className="mt-[46px] border-t border-gray-200 px-9 py-8">
            <SkeletonLine className="h-3 w-[117px]" />
            <SkeletonLine className="mt-4 h-3 w-[250px]" />
            {times(6, (index) => (
              <div key={index} className="mt-6 flex justify-between">
                <SkeletonLine className="h-3 w-[117px]" />
                <SkeletonLine className="h-3 w-[137px]" />
              </div>
            ))}
            <div className="mb-[26px] mt-9 border-t border-gray-100" />
            <SkeletonLine className="h-3 w-[117px]" />
            <SkeletonLine className="mt-4 h-3 w-[250px]" />
            {times(3, (index) => (
              <div key={index} className="mt-6 flex justify-between">
                <SkeletonLine className="h-3 w-[117px]" />
                <SkeletonLine className="h-3 w-[137px]" />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const InspectDataDisplay: React.FC<{ data: GenericObjectT }> = ({ data }) => {
  return (
    <div className="space-y-4 px-9 py-8">
      {Object.entries(data).map(([key, value]) => (
        <DataRow key={key} fieldName={key} value={value} />
      ))}
    </div>
  );
};

const HighlightsDisplay: React.FC<{
  highlights: ReviewCaseHighlightsElement[];
  isPreview?: boolean;
}> = ({ highlights, isPreview }) => {
  if (isPreview && highlights.length === 0) {
    return (
      <EmptyState
        description="Elements that should be highlighted for the reviewer will show up here"
        headline="No highlights exist yet"
        icon={faInputPipe}
      />
    );
  }
  return (
    <div className="space-y-4 px-9 py-8">
      {highlights.map((highlight, i) => {
        switch (highlight.type) {
          case "field":
            return (
              <DataRow
                key={i}
                fieldName={highlight.readable_name}
                isPreview={isPreview}
                value={highlight.value}
              />
            );
          case "divider":
            return (
              <div key={i} className="py-4">
                <div className="border-t border-gray-100" />
              </div>
            );
          case "description":
            return (
              <div
                //skipcq: JS-0440
                dangerouslySetInnerHTML={{
                  __html: mdService.toHtml(highlight.value),
                }}
                key={i}
                className="review-case-description"
              />
            );
          default:
            return assertUnreachable(highlight);
        }
      })}
    </div>
  );
};

const MAX_CONTENT_LENGTH = 280;

const DataRow: React.FC<{
  fieldName: string;
  value: JSONValue;
  isPreview?: boolean;
}> = ({ fieldName, value, isPreview }) => {
  const shouldRenderInPlainText = (value: any) => {
    return typeof value !== "object" || value === null;
  };

  return (
    <div className="flex items-baseline justify-between">
      <p className="min-w-0 flex-1 truncate text-gray-800 font-inter-medium-12px">
        {fieldName}
      </p>
      {isPreview ? (
        <span className="text-gray-500 font-inter-normal-12px">
          Value will be displayed here
        </span>
      ) : shouldRenderInPlainText(value) ? (
        <Tooltip
          activated={String(value).length > MAX_CONTENT_LENGTH}
          body={formatValue(value)}
          placement="top"
          title=""
          asChild
        >
          <p className="line-clamp-5 min-w-0 flex-1 break-words text-right text-gray-800 font-inter-normal-12px">
            {linkifyIfNeeded(formatValue(value))}
          </p>
        </Tooltip>
      ) : (
        <DataRowPopup content={value} />
      )}
    </div>
  );
};

const isEmail = (value: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
};

const isValidURL = (url: string) => {
  return isValidHTTPURL(url.startsWith("www.") ? `https://${url}` : url);
};

const formatValue = (value: any) => {
  if (typeof value === "number") {
    return formatNumber(value);
  }

  return speakPythonPrimitive(String(value));
};

const linkifyIfNeeded = (value: any) => {
  const isString = typeof value === "string";

  if (isString && isEmail(value)) {
    return (
      <a className="text-indigo-500" href={`mailto:${value}`}>
        {value}
      </a>
    );
  }

  if (isString && isValidURL(value)) {
    return (
      <a
        className="text-indigo-500"
        href={value.startsWith("http") ? value : `https://${value}`}
        rel="noreferrer"
        target="_blank"
      >
        {value}
      </a>
    );
  }

  return value;
};

const DataRowPopup: React.FC<{ content: any }> = ({ content }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [cellBoundingRect, setBoundingRect] = useState<DOMRect | undefined>(
    undefined,
  );
  const cellDivRef = useRef<HTMLDivElement>(null);

  const getObjectDetailPosition = () => {
    if (!cellBoundingRect) return;
    return {
      bottom: window.innerHeight - cellBoundingRect.top,
      left: cellBoundingRect.left,
    };
  };

  const onMouseEnter = () => {
    const cRect = cellDivRef.current?.getBoundingClientRect();
    setBoundingRect(cRect);
    setTooltipOpen(true);
  };

  const onMouseLeave = () => {
    setTooltipOpen(false);
  };

  return (
    <div
      ref={cellDivRef}
      className="min-w-0 flex-1 truncate text-right text-indigo-600 font-inter-normal-12px"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <p>{stringifiedObjectSpeakPython(JSON.stringify(content))}</p>
      {ReactDOM.createPortal(
        <AnimatePresence>
          {tooltipOpen && (
            <m.div
              animate={{ opacity: 1, transition: { duration: 0.2 } }}
              className="z-50 max-h-150 min-w-[220px] max-w-128 overflow-auto text-left"
              exit={{ opacity: 0, transition: { duration: 0.1 } }}
              initial={{ opacity: 0 }}
            >
              <ObjectDetailPopover
                objectToDisplay={content}
                position="top-start"
                positionStyles={getObjectDetailPosition()}
              />
            </m.div>
          )}
        </AnimatePresence>,
        document.body,
      )}
    </div>
  );
};

const ReportDisplay: React.FC<{ value: GenericObjectT }> = ({ value }) => {
  return (
    <div className="flex flex-col divide-y divide-gray-200">
      {value.insights && (
        <>
          {Object.entries(value.insights).map(([insightKey, insightValue]) => (
            <div className="flex items-center justify-between px-5 py-4 font-inter-medium-12px">
              <div>{insightKey}</div>
              <div className="font-inter-normal-12px">
                {speakPythonPrimitive(String(insightValue))}
              </div>
            </div>
          ))}
        </>
      )}
      <div className="review-case-report-display relative flex-1 px-5 py-4">
        <div className="flex min-h-full flex-col">
          {value.insights && (
            <div className="pb-4 font-inter-medium-12px">Raw Response</div>
          )}
          <div className="flex flex-1">
            <ObjectDetailPane
              defaultInspectDepth={10}
              innerDimensionClass="border-gray-200 overflow-visible min-h-full w-full"
              jsonObject={value}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
