import { times } from "lodash";
import { twJoin } from "tailwind-merge";

import { GRID_COLUMNS_CLASS } from "src/flowsOverview/FlowOverview";

const MovingElement: React.FC<{ className: string }> = ({ className }) => (
  <div
    className={twJoin(
      "relative overflow-hidden rounded bg-gray-100",
      className,
    )}
  >
    <div
      className="absolute inset-0 -translate-x-full transform bg-gradient-to-r from-gray-100 via-gray-50 to-gray-100"
      style={{ animation: "shimmer 2s infinite" }}
    />
  </div>
);

const FlowCardSkeleton: React.FC = () => {
  return (
    <div className="relative flex h-[224px] flex-col gap-y-5 rounded-lg bg-white px-6 py-5 shadow-sm">
      <MovingElement className="h-3 w-50" />
      <div className="flex flex-col gap-y-2">
        <MovingElement className="h-3 w-full" />
        <MovingElement className="h-3 w-full" />
        <MovingElement className="h-3 w-full" />
        <MovingElement className="h-3 w-40" />
      </div>
      <div className="mt-auto flex gap-x-3 py-1.5">
        <MovingElement className="h-3 w-20" />
        <MovingElement className="h-3 w-10" />
      </div>
    </div>
  );
};

export const FlowListSkeletonV2: React.FC<{
  skeletonCount?: number;
}> = ({ skeletonCount = 7 }) => {
  return (
    <div className={GRID_COLUMNS_CLASS}>
      {times(skeletonCount, (i) => (
        <FlowCardSkeleton key={i} />
      ))}
    </div>
  );
};
