import axios, { AxiosResponse } from "axios";

import { authHeader } from "src/api/authHeader";
import { GenericObjectT } from "src/api/flowTypes";
import { pathJoin } from "src/api/paths";

export enum ConnectApiVersions {
  V1 = "v1",
  V2 = "v2",
}

type ConnectApiOptions = {
  version?: ConnectApiVersions;
};

const getConnectApiUrl = (
  flowBaseUrl: string,
  url: string,
  options?: ConnectApiOptions,
): string =>
  pathJoin(
    "https://",
    flowBaseUrl,
    `/connect/api/${options?.version || ConnectApiVersions.V1}`,
    url,
  );

export const connectApiGet = <ResponseT>(
  baseUrl: string,
  url: string,
  params?: GenericObjectT,
  options?: ConnectApiOptions,
): Promise<AxiosResponse<ResponseT>> => {
  return axios.get<ResponseT, AxiosResponse<ResponseT>>(
    getConnectApiUrl(baseUrl, url, options),
    {
      headers: {
        "Content-Type": "application/json",
        "Content-Encoding": "gzip",
        ...authHeader(false),
      },
      params: params,
    },
  );
};

export const connectApiPost = <ResponseT>(
  baseUrl: string,
  url: string,
  data: GenericObjectT,
  options?: ConnectApiOptions,
): Promise<AxiosResponse<ResponseT>> => {
  return axios.post<ResponseT, AxiosResponse<ResponseT>>(
    getConnectApiUrl(baseUrl, url, options),
    data,
    {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(false),
      },
    },
  );
};

export const connectApiPatch = <ResponseT>(
  baseUrl: string,
  url: string,
  data: GenericObjectT,
): Promise<AxiosResponse<ResponseT>> => {
  return axios.patch<ResponseT, AxiosResponse<ResponseT>>(
    getConnectApiUrl(baseUrl, url),
    data,
    {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(false),
      },
    },
  );
};

export const connectApiPut = <ResponseT>(
  baseUrl: string,
  url: string,
  data: GenericObjectT,
  options?: ConnectApiOptions,
): Promise<AxiosResponse<ResponseT>> => {
  return axios.put<ResponseT, AxiosResponse<ResponseT>>(
    getConnectApiUrl(baseUrl, url, options),
    data,
    {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(false),
      },
    },
  );
};

export const connectApiDelete = <ResponseT>(
  baseUrl: string,
  url: string,
): Promise<AxiosResponse<ResponseT>> => {
  return axios.delete<ResponseT, AxiosResponse<ResponseT>>(
    getConnectApiUrl(baseUrl, url),
    {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(false),
      },
    },
  );
};
