import { Pill } from "src/base-components/Pill";

type VersionUpdatesProps = {
  totalUpdates: number;
};

export const VersionUpdates: React.FC<VersionUpdatesProps> = ({
  totalUpdates,
}) => {
  return (
    <div className="ml-2">
      <Pill variant="indigo-light">
        <Pill.Text>{totalUpdates >= 100 ? "99+" : totalUpdates}</Pill.Text>
      </Pill>
    </div>
  );
};
