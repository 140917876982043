import { faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useRef, useState } from "react";

import { FlowT } from "src/api/flowTypes";
import { AssignmentNodeEditor } from "src/assignmentNode/AssignmentNodeEditor";
import { MergeView } from "src/base-components/CodeInput/MergeView";
import { EditorBoundsProvider } from "src/base-components/CodeInput/useEditorBounds";
import { EmptyState } from "src/base-components/EmptyState";
import { DiffViewContext } from "src/changeHistory/DiffViewModal/DiffViewContext";
import { useDiffHighlighting } from "src/changeHistory/DiffViewModal/highlightDiffs";
import { WorkspaceDataplane } from "src/clients/flow-api";
import {
  AssignmentNode,
  BeMappedNode,
  CustomConnectionNode,
  DecisionTableNode,
  IntegrationNode,
  MLNode,
  RuleNodeV2,
  ScorecardNode,
  SimpleNode,
  SimpleNodeDataT,
  SplitNodeV2,
  InboundWebhookConnectionNode,
  ManualReviewNode,
  DatabaseConnectionNode,
  FlowNode,
  LoopNode,
} from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { CustomConnectionNodeEditor } from "src/customConnectionNode/Editor";
import { DatabaseConnecionNodeEditor } from "src/databaseConnectionNode/DatabaseConnecionNodeEditor";
import { DecisionTableNodeEditor } from "src/decisionTableNode/DecisionTableNodeEditor";
import { InboundWebhookNodeEditor } from "src/inboundWebhookNode/InboundWebhookNodeEditor";
import { IntegrationNodeEditor } from "src/integrationNode/IntegrationNodeEditor";
import { ManualReviewNodeEditor } from "src/manualReviewNode/ManualReviewNodeEditor";
import { MLNodeEditor } from "src/mlNode/MLNodeEditor";
import { CodeNodeEditor } from "src/nodeEditor/CodeNodeEditor";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import { FlowNodeEditor } from "src/parentFlowNodes/flowNode/FlowNodeEditor";
import { LoopNodeEditor } from "src/parentFlowNodes/loopNode/LoopNodeEditor";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { RuleV2Editor } from "src/ruleNodeV2Editor/RuleNodeV2Editor";
import { ScorecardNodeEditor } from "src/scorecardNode/ScorecardNodeEditor";
import { SplitNodeV2Editor } from "src/splitNodeV2Editor/SplitNodeV2Editor";
import { assertUnreachable } from "src/utils/typeUtils";

const immutableBaseProps: NodeEditorBaseProps<any> = {
  displayedError: undefined,
  immutable: true,
  isReactive: false,
  onUpdate: () => {},
};

export const editorWrapperClassName = "decideScrollbar max-h-[38rem] w-full";

const getImmutableNodeEditor = (
  node: BeMappedNode,
  flow: FlowT,
  workspace: WorkspaceDataplane,
) => {
  switch (node.type) {
    case NODE_TYPE.CODE_NODE:
      return (
        <CodeNodeEditor
          src={(node as SimpleNode).data?.src}
          {...immutableBaseProps}
          language="python"
          onChange={() => {}}
        />
      );
    case NODE_TYPE.RULE_NODE_V2:
      return (
        <RuleV2Editor
          selectedNode={node as RuleNodeV2}
          {...immutableBaseProps}
        />
      );
    case NODE_TYPE.SPLIT_NODE_V2:
      return (
        <SplitNodeV2Editor
          selectedNode={node as SplitNodeV2}
          {...immutableBaseProps}
        />
      );
    case NODE_TYPE.SCORECARD_NODE:
      return (
        <ScorecardNodeEditor
          selectedNode={node as ScorecardNode}
          {...immutableBaseProps}
        />
      );
    case NODE_TYPE.ASSIGNMENT_NODE:
      return (
        <AssignmentNodeEditor
          selectedNode={node as AssignmentNode}
          {...immutableBaseProps}
        />
      );
    case NODE_TYPE.DECISION_TABLE_NODE:
      return (
        <DecisionTableNodeEditor
          isFullscreen={false}
          selectedNode={node as DecisionTableNode}
          {...immutableBaseProps}
        />
      );
    case NODE_TYPE.INTEGRATION_NODE:
      return (
        <IntegrationNodeEditor
          node={node as IntegrationNode}
          workspaceId={workspace.id}
          {...immutableBaseProps}
        />
      );
    case NODE_TYPE.MANIFEST_CONNECTION_NODE:
      // TODO: Implement manifest connection node editor
      return null;
    case NODE_TYPE.ML_NODE:
      return (
        <MLNodeEditor
          flow={flow}
          selectedNode={node as MLNode}
          workspace={workspace}
          {...immutableBaseProps}
        />
      );
    case NODE_TYPE.CUSTOM_CONNECTION_NODE:
      return (
        <CustomConnectionNodeEditor
          node={node as CustomConnectionNode}
          workspaceId={workspace.id}
          {...immutableBaseProps}
        />
      );
    case NODE_TYPE.WEBHOOK_CONNECTION_NODE:
      return (
        <InboundWebhookNodeEditor
          node={node as InboundWebhookConnectionNode}
          workspaceId={workspace.id}
          {...immutableBaseProps}
        />
      );
    case NODE_TYPE.SQL_DATABASE_CONNECTION_NODE:
      return (
        <DatabaseConnecionNodeEditor
          node={node as DatabaseConnectionNode}
          workspaceId={workspace.id}
          {...immutableBaseProps}
        />
      );
    case NODE_TYPE.REVIEW_CONNECTION_NODE:
      return (
        <ManualReviewNodeEditor
          node={node as ManualReviewNode}
          {...immutableBaseProps}
        />
      );
    case NODE_TYPE.FLOW_NODE:
      return (
        <FlowNodeEditor
          selectedNode={node as FlowNode}
          {...immutableBaseProps}
        />
      );
    case NODE_TYPE.LOOP_NODE:
      return (
        <LoopNodeEditor
          selectedNode={node as LoopNode}
          {...immutableBaseProps}
        />
      );
    case NODE_TYPE.SPLIT_MERGE_NODE:
    case NODE_TYPE.INPUT_NODE:
    case NODE_TYPE.OUTPUT_NODE:
    case NODE_TYPE.SPLIT_BRANCH_NODE_V2:
      return <></>;
    default:
      assertUnreachable(node.type);
      return null;
  }
};

type Props = {
  originalNode: BeMappedNode | "notYetCreated";
  changedNode: BeMappedNode | "deleted";
};

const inputPadding = 10;

export const NodeDiffView: React.FC<Props> = ({
  originalNode,
  changedNode,
}) => {
  useDiffHighlighting("diff-view-before", "diff-view-after");
  const { workspace, flow } = useAuthoringContext();
  const prevContainerRef = useRef<HTMLDivElement>(null);
  const afterContainerRef = useRef<HTMLDivElement>(null);
  const maxXPrev = prevContainerRef.current
    ? prevContainerRef.current.getBoundingClientRect().x +
      prevContainerRef.current.clientWidth +
      inputPadding
    : null;
  const maxXAfter = afterContainerRef.current
    ? afterContainerRef.current.getBoundingClientRect().x +
      afterContainerRef.current.clientWidth +
      inputPadding
    : null;

  const [, forceRender] = useState(0);

  // force rendering is required due to field being used inside <Controller /> from react-hook-form
  // otherwise the controller component prevents re-rendering
  useEffect(() => {
    forceRender((_) => Math.random());
  }, [maxXPrev, maxXAfter]);

  return (
    <DiffViewContext.Provider
      value={{
        renderedInDiffView: true,
      }}
    >
      <div className="w-full px-6 pt-4">
        <div className="flex h-full w-full flex-row justify-between gap-x-6">
          {/*For code nodes we use the diff editor, for others we just render two editors*/}
          {originalNode !== "notYetCreated" &&
          originalNode.type === NODE_TYPE.CODE_NODE &&
          changedNode !== "deleted" &&
          changedNode.type === NODE_TYPE.CODE_NODE ? (
            <div className="h-150 w-full">
              <MergeView
                modified={(changedNode.data as SimpleNodeDataT).src}
                original={(originalNode.data as SimpleNodeDataT).src}
              />
            </div>
          ) : (
            <>
              <div
                ref={prevContainerRef}
                className={editorWrapperClassName}
                data-loc="node-diff-previous"
              >
                <div className="h-full pb-4" id="diff-view-before">
                  <EditorBoundsProvider maxX={maxXPrev}>
                    {originalNode === "notYetCreated" ? (
                      <EmptyState
                        description="This node was created and no changes have been applied"
                        headline="Node created"
                        icon={faPlus}
                      />
                    ) : (
                      getImmutableNodeEditor(originalNode, flow, workspace)
                    )}
                  </EditorBoundsProvider>
                </div>
              </div>
              <div
                ref={afterContainerRef}
                className={editorWrapperClassName}
                data-loc="node-diff-after"
              >
                <div className="h-full pb-4" id="diff-view-after">
                  <EditorBoundsProvider maxX={maxXAfter}>
                    {changedNode === "deleted" ? (
                      <EmptyState
                        description="This node was deleted, leaving no after state to display."
                        headline="Node deleted"
                        icon={faTrash}
                      />
                    ) : (
                      getImmutableNodeEditor(changedNode, flow, workspace)
                    )}
                  </EditorBoundsProvider>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </DiffViewContext.Provider>
  );
};
