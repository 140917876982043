import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { useFormContext, Controller } from "react-hook-form";

import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Icon } from "src/base-components/Icon";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import { Switch } from "src/base-components/Switch";
import { Textarea } from "src/base-components/Textarea";
import { Tooltip } from "src/base-components/Tooltip";
import { ConfigFieldsetCard } from "src/connections/config/FieldsetCard";
import {
  mTLSCertificatePlaceholder,
  mTLSKeyPlaceholder,
  mTLSCACertificatePlaceholder,
} from "src/connections/config/database/shared/MTLSConfig";
import {
  PostgreSQLConnectionConfigInputsT,
  PostgreSQLConnectionConfigT,
  Environment,
} from "src/connections/types";

type SecretPropsT = {
  secretConfigField: Exclude<
    keyof PostgreSQLConnectionConfigT["mTLSConfig"],
    "mode"
  >;
  secretName: string;
  placeholder: string;
  helpText: string;
  isRequired?: boolean;
  environment: Environment;
};

const PostgreSQLServiceMTLSConfigSecret: React.FC<SecretPropsT> = ({
  secretConfigField,
  secretName,
  placeholder,
  helpText,
  isRequired,
  environment,
}) => {
  const {
    formState: { errors },
    watch,
    setValue,
    register,
  } = useFormContext<PostgreSQLConnectionConfigInputsT>();

  const envPrefix = `${environment}Config` as const;
  const secretKey = `${envPrefix}.mTLSConfig.${secretConfigField}` as const;
  const fieldError =
    errors?.[envPrefix]?.["mTLSConfig"]?.[secretConfigField]?.value;

  const secretRecord = watch(secretKey);

  const enableSecret = () => {
    setValue(secretKey, { ...secretRecord, secret: false, value: null });
  };

  return (
    <FormItem
      description={helpText}
      gap="xxs"
      isRequired={isRequired}
      label={secretName}
    >
      {Boolean(fieldError) && <ErrorHint>{fieldError?.message}</ErrorHint>}
      <div className="flex w-full items-start gap-x-2">
        <div className="flex-1">
          <Textarea
            disabled={secretRecord.secret}
            errored={Boolean(fieldError)}
            {...register(`${secretKey}.value`, {
              required: isRequired ? `${secretName} is required` : false,
            })}
            placeholder={placeholder}
            fullWidth
          />
        </div>
        {secretRecord.secret && (
          <Tooltip
            align="center"
            placement="right"
            title={`Edit ${secretName.toLowerCase()}`}
            asChild
          >
            <Icon
              color="text-gray-500"
              icon={faEdit}
              size="xs"
              onClick={enableSecret}
            />
          </Tooltip>
        )}
      </div>
    </FormItem>
  );
};

const MTLSHelpText: React.FC = () => {
  return (
    <>
      <p>
        When enabled, you are required to provide your client SSL certificate
        and client SSL key into the corresponding text input areas. Connections
        made to the configured PostgreSQL instance will use these credentials
        for mTLS authentication.
        <br />
        <br />
        When disabled, connections established to the configured PostgreSQL
        instance will proceed using standard authentication, without requiring
        client SSL credentials.
      </p>
    </>
  );
};

type PropsT = {
  environment: Environment;
};

export const PostgreSQLConnectionMTLSConfigFields: React.FC<PropsT> = ({
  environment,
}) => {
  const { control, watch } =
    useFormContext<PostgreSQLConnectionConfigInputsT>();

  const hasMTLSEnabledKey = `${environment}Config.hasMTLSEnabled` as const;
  const hasMTLSEnabled = watch(hasMTLSEnabledKey);
  const mTLSMode = watch(`${environment}Config.mTLSConfig.mode` as const);
  // The below condition sounds counter-intuitive, but it is correct.
  // The CA certificate is required when the mode is "verify-ca" or "verify-full".
  // See https://www.postgresql.org/docs/current/libpq-ssl.html#LIBPQ-SSL-PROTECTION for more details.
  const isCACertificateRequired = mTLSMode !== "require";

  return (
    <div className="mb-6 last:mb-0">
      <FormItem
        className="flex flex-row items-center justify-between"
        description="Require client-side authentication credentials"
        gap="xxs"
        helpTooltip={<MTLSHelpText />}
        label="Enable mutual TLS"
      >
        <Controller
          control={control}
          name={hasMTLSEnabledKey}
          render={(props) => (
            <Switch
              enabled={props.field.value}
              onChange={() => {
                props.field.onChange(!props.field.value);
              }}
            />
          )}
        />
      </FormItem>
      {hasMTLSEnabled && (
        <ConfigFieldsetCard>
          <PostgreSQLServiceMTLSConfigSecret
            environment={environment}
            helpText="The client certificate used for authentication"
            placeholder={mTLSCertificatePlaceholder}
            secretConfigField="sslCertificate"
            secretName="Client certificate"
            isRequired
          />
          <PostgreSQLServiceMTLSConfigSecret
            environment={environment}
            helpText="The client key used for authentication"
            placeholder={mTLSKeyPlaceholder}
            secretConfigField="sslKey"
            secretName="Client key"
            isRequired
          />
          <PostgreSQLServiceMTLSConfigSecret
            environment={environment}
            helpText="The Certificate Authority (CA) certificate that verifies the client and server certificates"
            isRequired={isCACertificateRequired}
            placeholder={mTLSCACertificatePlaceholder}
            secretConfigField="sslCACertificate"
            secretName="CA certificate"
          />
          <FormItem gap="xxs" label="Verification mode" isRequired>
            <Controller
              control={control}
              name={`${environment}Config.mTLSConfig.mode`}
              render={(props) => (
                <SimpleRadioGroup
                  orientation="vertical"
                  value={String(props.field.value)}
                  onValueChange={props.field.onChange}
                >
                  <SimpleRadioGroup.Item
                    label="Full verification"
                    labelClassName="pl-2.5"
                    value="verify-full"
                  />
                  <SimpleRadioGroup.Item
                    label="Verify CA certificate"
                    labelClassName="pl-2.5"
                    value="verify-ca"
                  />
                  <SimpleRadioGroup.Item
                    label="Skip CA certificate verification"
                    labelClassName="pl-2.5"
                    value="require"
                  />
                </SimpleRadioGroup>
              )}
            />
          </FormItem>
        </ConfigFieldsetCard>
      )}
    </div>
  );
};
