import { ColumnDef } from "@tanstack/react-table";
import { twJoin } from "tailwind-merge";

import { TableComp as Table } from "src/base-components/Table";
import { HistoryDataRecord } from "src/datasets/api/types";

type PropsT = {
  data: HistoryDataRecord[];
  columns: ColumnDef<HistoryDataRecord>[];
};

export const AssembleDataTable: React.FC<PropsT> = ({ data, columns }) => {
  const hasRows = data.length;

  return (
    <Table
      columns={columns}
      data={data}
      frameClassName={twJoin(
        hasRows ? "decideScrollbar overflow-auto" : "h-16",
      )}
      noScroll
    />
  );
};
