import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { useArchiveFlowVersion } from "src/api/flowVersionQueries";
import { Button } from "src/base-components/Button";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { ExternalLink } from "src/base-components/ExternalLink";
import { Icon } from "src/base-components/Icon";
import { Modal } from "src/base-components/Modal";
import { TextConfirmationModal } from "src/base-components/TextConfirmationModal";
import {
  TAKTILE_TEAM_NOTIFIED,
  toastFailure,
  toastSuccess,
} from "src/base-components/Toast/utils";
import { useJobs } from "src/jobs/api/queries";
import { useFlowContext } from "src/router/routerContextHooks";
import { getBaseUrl, getUrlToJobPage } from "src/router/urls";
import { useUserData } from "src/store/AuthStore";
import * as logger from "src/utils/logger";

type ArchiveVersionModalProps = {
  open: boolean;
  onClose: () => void;
  flow: FlowT;
  flowVersion: FlowVersionT | undefined;
  afterLeave?: () => void;
  onOpenManageTrafficModal: () => void;
};
export const ArchiveVersionModal: React.FC<ArchiveVersionModalProps> = ({
  open,
  onClose,
  flow,
  flowVersion,
  afterLeave,
  onOpenManageTrafficModal,
}) => {
  const { orgId, workspace } = useFlowContext();
  const { mutateAsync: archiveVersion } = useArchiveFlowVersion();
  const { signed_in_user_id } = useUserData();
  const { data: jobs } = useJobs(workspace.base_url!, flow.id);

  const archiveFlowVersionHandler = async () => {
    if (!flowVersion) return;
    try {
      await archiveVersion({
        version: flowVersion,
        archived_by_id: signed_in_user_id ?? "",
        archived_at: new Date(Date.now()).toISOString(),
      });
      toastSuccess({
        title: "Version archived successfully",
        description: `Version ${flowVersion.name} of ${flow.name} was archived successfully.`,
      });
      onClose();
    } catch (error) {
      toastFailure({
        title: "Flow version archiving failed",
        description: TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(`Error when archiving flow: ${error}`);
    }
  };

  const trafficAllocation =
    flow.active_traffic_policy?.policy.flow_versions?.find(
      (version) => version.flow_version_id === flowVersion?.id,
    );

  const hasSandboxDefaultVersion =
    flow.default_sandbox_version === flowVersion?.id;
  const hasAnyDefaultVersion =
    hasSandboxDefaultVersion ||
    (!flow.active_traffic_policy && flow.default_version === flowVersion?.id);
  const activeJobs = jobs?.filter(
    (job) => job.flow_version_id === flowVersion?.id && job.status === "active",
  );
  const hasActiveJobs = !!activeJobs?.length;

  const determineModalState = () => {
    if (!!trafficAllocation) return "has_traffic";
    if (hasAnyDefaultVersion) return "has_default_version";
    if (hasActiveJobs) return "has_active_jobs";
    return "archive_version";
  };
  const modal = open && determineModalState();

  return (
    <>
      <ConfirmationModal
        afterLeave={afterLeave}
        confirmationButtonText="Change routing policy"
        open={modal === "has_traffic"}
        title={`Version ${flowVersion?.name} has active traffic`}
        onClose={onClose}
        onConfirm={onOpenManageTrafficModal}
      >
        <div className="my-3 py-2">
          <p className="mb-2 text-gray-500 font-inter-normal-12px">
            Version {flowVersion?.name} has{" "}
            <strong className="text-gray-800 font-inter-medium-12px">
              {trafficAllocation?.percentage}%
            </strong>{" "}
            active traffic allocation.
          </p>
          <p className="text-gray-500 font-inter-normal-12px">
            You cannot archive a version with active traffic. Please edit the
            routing policy to remove traffic from this version before archiving.
          </p>
        </div>
      </ConfirmationModal>
      <Modal
        afterLeave={afterLeave}
        className="w-110"
        open={modal === "has_default_version"}
        title={`Version ${flowVersion?.name} is set as default ${
          hasSandboxDefaultVersion ? "sandbox" : ""
        } version`}
        onClose={onClose}
      >
        <div className="mb-4 mt-3 px-6">
          <p className="mb-2 text-gray-500 font-inter-normal-12px">
            Version {flowVersion?.name} is set as default{" "}
            {hasSandboxDefaultVersion && "sandbox"} version.
          </p>
          <p className="text-gray-500 font-inter-normal-12px">
            You cannot archive the {hasSandboxDefaultVersion && "sandbox"}{" "}
            default version. Please set another version as default before
            archiving this flow version.
          </p>
        </div>
        <div className="mb-4 mt-3 flex justify-end gap-2 px-6">
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        afterLeave={afterLeave}
        className="w-110"
        open={modal === "has_active_jobs"}
        title="Cannot archive Version"
        onClose={onClose}
      >
        <div className="mb-4 mt-3 px-6">
          <p className="mb-2 text-gray-500 font-inter-normal-12px">
            This version cannot be archived as it is being used by these active
            jobs
          </p>
          <ul className="mb-2.5 ml-4 list-disc font-inter-normal-13px">
            {activeJobs?.map((job) => (
              <li key={job.id}>
                <ExternalLink
                  className="flex items-center gap-x-1 text-gray-800"
                  href={
                    getBaseUrl() +
                    getUrlToJobPage(orgId, workspace.id, job.flow_id, job.id)
                  }
                >
                  {job.name}
                  <Icon
                    color="text-gray-500"
                    icon={faExternalLink}
                    size="2xs"
                  />
                </ExternalLink>
              </li>
            ))}
          </ul>
          <p className="text-gray-500 font-inter-normal-12px">
            Please deactivate these jobs before archiving this version.
          </p>
        </div>
        <div className="mb-4 mt-3 flex justify-end gap-2 px-6">
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      </Modal>
      <TextConfirmationModal
        afterLeave={afterLeave}
        challengeText={flowVersion?.name}
        confirmButtonText="Archive version"
        description="Are you sure you want to archive this Decision Flow version? All API calls to this version will fail and the flow will be locked to prevent further edits. You can un-archive this version later."
        label="Please enter the version name to confirm"
        open={modal === "archive_version"}
        title={`Archive "${flowVersion?.name}" version`}
        onClose={onClose}
        onConfirm={archiveFlowVersionHandler}
      />
    </>
  );
};
