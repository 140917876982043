import {
  faEye,
  faInputPipe,
  faShareNodes,
  faUserCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import { ReactNode } from "react";
import { useForm } from "react-hook-form";

import { ReviewCaseResponseData } from "src/api/types";
import { Button } from "src/base-components/Button";
import { EmptyState } from "src/base-components/EmptyState";
import { Icon } from "src/base-components/Icon";
import { DetailHeader } from "src/layout/DetailHeader";
import { usePreviewData } from "src/manualReview/previewTabHooks";
import { Card } from "src/manualReview/reviewCaseCommon/Card";
import { Form } from "src/manualReview/reviewCaseCommon/Form";
import { FormSide } from "src/manualReview/reviewCaseCommon/FormSide";
import { SubHeader } from "src/manualReview/reviewCaseCommon/SubHeader";

const FakeDropdown: React.FC<{ children: ReactNode }> = ({ children }) => (
  <div className="flex cursor-default items-center gap-x-0.5">
    <div className="truncate">{children}</div>
    <Icon icon={faChevronDown} size="3xs" />
  </div>
);

export const ReviewCasePreviewContent = () => {
  const { data: previewData, hasOpener } = usePreviewData();

  const form = useForm<ReviewCaseResponseData>({
    defaultValues: undefined,
  });

  const highlights =
    previewData?.highlights.filter(
      (highlight) =>
        highlight.type !== "field" ||
        (highlight.readable_name && highlight.value),
    ) ?? [];

  if (!hasOpener) {
    return <NoOpenerContent />;
  }

  return (
    <div className="flex h-full flex-1 flex-col divide-y divide-gray-200">
      <DetailHeader
        actions={
          <div className="flex space-x-2">
            <Button iconLeft={faShareNodes} size="sm" disabled>
              Share
            </Button>
            <Button iconLeft={faEye} size="sm" disabled>
              Trace decision
            </Button>
          </div>
        }
        main={
          <div className="pl-1">
            You are previewing a manual review case as it will be presented to
            the reviewer
          </div>
        }
      />
      <div className="flex min-h-0 flex-1">
        <div className="flex flex-1 flex-col bg-gray-100">
          <SubHeader
            assignedTo={<FakeDropdown>Username</FakeDropdown>}
            requestTime={
              <p className="truncate">
                {format(new Date(), "dd/MM/yyyy HH:mm:ss")}
              </p>
            }
            status={<FakeDropdown>Needs manual review</FakeDropdown>}
            timeSinceRequest="few seconds ago"
            isPreview
          />
          <Card highlights={highlights} isPreview />
        </div>
        <FormSide
          description={previewData?.schema.description}
          nodeName={previewData?.nodeName}
        >
          {previewData?.schema &&
            (isEmpty(previewData.schema.properties) ? (
              <EmptyState
                description="Fields that the reviewer should to respond with will show up here"
                headline="No response form exists yet"
                icon={faInputPipe}
              />
            ) : (
              <Form
                disabled={false}
                form={form}
                schema={previewData?.schema}
                onChange={() => {}}
                onClickSubmit={() => {}}
              />
            ))}
        </FormSide>
      </div>
    </div>
  );
};

const NoOpenerContent: React.FC = () => {
  return (
    <div className="h-screen w-full">
      <EmptyState
        description={
          "To preview a case, click on the 'Preview' button on the corresponding manual review Node"
        }
        headline="Manual review preview not found"
        icon={faUserCircle}
      />
    </div>
  );
};
