import {
  ConnectionDataSources,
  ConnectionDataSourcesValues,
  EnvironmentsConfigT,
} from "src/baseConnectionNode/types";

export const getDefaultEnvironmentsConfig = (): EnvironmentsConfigT => ({
  [ConnectionDataSources.authoring_mode_data_source]:
    ConnectionDataSourcesValues.mock_data,
  [ConnectionDataSources.sandbox_mode_data_source]:
    ConnectionDataSourcesValues.mock_data,
  [ConnectionDataSources.live]: ConnectionDataSourcesValues.production,
});
