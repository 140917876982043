import { DecisionEnvironment } from "src/api/types";
import { PillToggle } from "src/base-components/PillToggle";

type EnvironmentPillProps = {
  value: DecisionEnvironment;
  onChange: (env: DecisionEnvironment) => void;
};

export const EnvironmentPill: React.FC<EnvironmentPillProps> = ({
  value,
  onChange,
}) => {
  return (
    <PillToggle value={value} onChange={onChange}>
      <PillToggle.Button value={DecisionEnvironment.LIVE}>
        Live
      </PillToggle.Button>
      <PillToggle.Button value={DecisionEnvironment.SANDBOX}>
        Sandbox
      </PillToggle.Button>
    </PillToggle>
  );
};
