import { observer } from "mobx-react-lite";
import React from "react";

import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { SplitNodeV2 } from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { SplitNodeDeletionModalContent } from "src/splitNodeEditor/SplitNodeDeletionModalContent";
import { useGraphStore } from "src/store/StoreProvider";

const renderSimpleNodeContent = () => (
  <div className="text-gray-500 font-inter-normal-12px">
    Are you sure you want to delete this node?
    <br /> This action cannot be undone.
  </div>
);

export const DeleteNodeModal: React.FC = observer(() => {
  const {
    nodeToDelete,
    deleteNode,
    setIsDeleteNodeModalVisible,
    setNodeToDelete,
    nodes,
    setSelectedNode,
    isDeleteNodeModalVisible,
  } = useGraphStore();
  const onConfirmDelete = async () => {
    if (nodeToDelete) {
      const node = nodes.get(nodeToDelete);
      if (node) {
        try {
          await deleteNode(node, false);
          setIsDeleteNodeModalVisible(false);
          setNodeToDelete(null);
          setSelectedNode(undefined);
        } catch (err) {
          setIsDeleteNodeModalVisible(false);
          setNodeToDelete(null);
        }
      }
    }
  };
  return (
    <ConfirmationModal
      confirmationButtonClassname="w-[6.475rem]"
      confirmationButtonText="Yes, delete"
      open={isDeleteNodeModalVisible}
      title="Delete Node"
      zIndex="z-40"
      onClose={() => setIsDeleteNodeModalVisible(false)}
      onConfirm={onConfirmDelete}
    >
      <div className="mb-6 mt-2">
        {nodeToDelete &&
        nodes.get(nodeToDelete) &&
        nodes.get(nodeToDelete)!.type === NODE_TYPE.SPLIT_NODE_V2 ? (
          <SplitNodeDeletionModalContent
            splitNode={nodes.get(nodeToDelete)! as SplitNodeV2}
          />
        ) : (
          renderSimpleNodeContent()
        )}
      </div>
    </ConfirmationModal>
  );
});
