import { makeConnectionTemplate } from "src/connections/types";

export const ExperianUSTemplate = makeConnectionTemplate({
  provider: "experian_us",
  secrets: [
    { key: "username", name: "Username", required: true },
    { key: "password", name: "Password", required: true },
    { key: "client_id", name: "Client ID", required: true },
    { key: "client_secret", name: "Client secret", required: true },
  ],
  resources: {
    experian_us_credit_report: {
      name: "Credit Report",
      configKeys: [
        {
          key: "subscriber_code",
          name: "Subscriber code",
          required: true,
          type: "text",
          hint: "Subscriber number assigned by Experian",
        },
        {
          key: "permissible_purpose_type",
          name: "Permissible purpose type",
          required: false,
          type: "text",
          hint: "Permissible purpose type code",
        },
        {
          key: "client_reference_id",
          name: "Client reference ID",
          required: false,
          type: "text",
          hint: "API client generated reference ID to uniquely identify the API request",
        },
      ],
    },
  },
});
