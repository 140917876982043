import { addSeconds, parseISO } from "date-fns";
import { useCallback, useEffect } from "react";
import { useInterval } from "usehooks-ts";
import { v4 as uuidV4 } from "uuid";

import { FlowVersionT } from "src/api/flowTypes";
import {
  useFlowVersion,
  usePatchResourceLocks,
} from "src/api/flowVersionQueries";
import { ResourceType } from "src/clients/flow-api";
import { CURRENT_SESSION_ID } from "src/constants/Session";
import { useCanAuthoringEditFlowVersion } from "src/hooks/useCanAuthoringEditFlowVersion";
import { AuthorPageParamsT } from "src/router/urls";
import { useCurrentUserId } from "src/store/AuthStore";
import * as logger from "src/utils/logger";
import { useParamsDecode } from "src/utils/useParamsDecode";

const LOCK_DURATION_SECONDS = 30 as const;
const LOCK_REACQUISITION_INTERVAL = 20 as const;

const getActiveResourceLock = (
  version: FlowVersionT,
  resourceType: ResourceType,
  resourceId: string,
) => {
  const matchingLocks = version.locks?.filter(
    (lock) =>
      lock.resource_id === resourceId && lock.resource_type === resourceType,
  );
  const activeLocks = matchingLocks?.filter(
    (lock) =>
      addSeconds(parseISO(lock.timestamp), lock.duration_secs) >= new Date(),
  );

  if (matchingLocks?.length && matchingLocks.length > 1) {
    logger.error("Conflicting resource locks");
  }
  return activeLocks?.[0]; // Only at most one lock should be active per session and per resource
};

const getOwnedExpiredLock = (
  version: FlowVersionT,
  resourceType: ResourceType,
  resourceId: string,
  userId: string | undefined,
  sessionId: string,
) => {
  const matchingOwnedLocks = version.locks?.filter(
    (lock) =>
      lock.resource_id === resourceId &&
      lock.resource_type === resourceType &&
      lock.owner === userId &&
      lock.session === sessionId,
  );
  const exipredOwnedLocks = matchingOwnedLocks?.filter(
    (lock) =>
      addSeconds(parseISO(lock.timestamp), lock.duration_secs) < new Date(),
  );

  return exipredOwnedLocks?.[0];
};

/**
 * Use information about current locks on a resource.
 *
 * @param resourceType
 * @param resourceId For resource types without an own id, pass the version id.
 * @returns
 */
export const useVersionResourceLock = (
  resourceType: ResourceType,
  resourceId?: string,
) => {
  const { version_id } = useParamsDecode<AuthorPageParamsT>();
  const { data: version } = useFlowVersion(version_id);
  const userId = useCurrentUserId();

  const activeLock =
    resourceId && version
      ? getActiveResourceLock(version, resourceType, resourceId)
      : undefined;

  const ownedExpiredLock =
    resourceId && version
      ? getOwnedExpiredLock(
          version,
          resourceType,
          resourceId,
          userId,
          CURRENT_SESSION_ID,
        )
      : undefined;

  const lockAcquired =
    activeLock?.owner === userId && activeLock?.session === CURRENT_SESSION_ID;

  const lockOwner = activeLock?.owner;

  const lockedByOtherUser = activeLock?.owner
    ? activeLock?.session !== CURRENT_SESSION_ID || activeLock?.owner !== userId
    : false;

  return {
    lockOwner,
    lockAcquired,
    lockedByOtherUser,
    activeLock,
    ownedExpiredLock,
  };
};

/**
 * Acquire, re-acquire and drop a resource lock. Also returns information about that resource lock.
 *
 * @param resourceType
 * @param resourceId For resource types without an own id, pass the version id.
 * @param reacquiringCondition While true, the lock is acquired and continously re-acquired. Setting to false drops the lock.
 * @returns
 */
export const useAcquireVersionResourceLock = (
  resourceType: ResourceType,
  resourceId: string | undefined,
  reacquiringCondition: boolean,
) => {
  const {
    lockOwner,
    lockAcquired,
    lockedByOtherUser,
    activeLock,
    ownedExpiredLock,
  } = useVersionResourceLock(resourceType, resourceId);
  const { version_id } = useParamsDecode<AuthorPageParamsT>();
  const userId = useCurrentUserId();
  const { mutateAsync: patchResourceLocks } = usePatchResourceLocks();
  const canEditFlowVersion = useCanAuthoringEditFlowVersion();

  const acquireResourceLock = useCallback(async () => {
    const resourceLockId =
      lockAcquired && activeLock?.id
        ? activeLock.id
        : // In case we have an expired lock, we want to re-acquire should the BE clock be out of sync with ours
          // the BE might not consider it expired yet.
          ownedExpiredLock
          ? ownedExpiredLock.id
          : uuidV4();
    if (
      resourceId !== undefined &&
      userId !== undefined &&
      !lockedByOtherUser
    ) {
      patchResourceLocks({
        flowVersionId: version_id,
        resourceLockPatch: {
          [resourceLockId]: {
            owner: userId,
            resource_type: resourceType,
            session: CURRENT_SESSION_ID,
            resource_id: resourceId,
            duration_secs: LOCK_DURATION_SECONDS,
          },
        },
      });
    }
  }, [
    lockAcquired,
    activeLock?.id,
    ownedExpiredLock,
    resourceId,
    userId,
    lockedByOtherUser,
    patchResourceLocks,
    version_id,
    resourceType,
  ]);

  const dropResourceLock = useCallback(async () => {
    if (activeLock?.id && lockAcquired) {
      patchResourceLocks({
        flowVersionId: version_id,
        resourceLockPatch: { [activeLock.id]: null },
      });
    }
  }, [activeLock?.id, patchResourceLocks, lockAcquired, version_id]);

  useInterval(
    () => {
      if (reacquiringCondition) {
        acquireResourceLock();
      }
    },
    /* delay */ reacquiringCondition && lockAcquired
      ? LOCK_REACQUISITION_INTERVAL * 1_000
      : null,
  );

  useEffect(() => {
    if (
      !lockedByOtherUser &&
      canEditFlowVersion &&
      reacquiringCondition !== undefined
    ) {
      if (reacquiringCondition && !lockAcquired) {
        acquireResourceLock();
      } else if (!reacquiringCondition && lockAcquired) {
        dropResourceLock();
      }
    }
  }, [
    reacquiringCondition,
    lockedByOtherUser,
    acquireResourceLock,
    dropResourceLock,
    lockAcquired,
    canEditFlowVersion,
  ]);

  return {
    lockOwner,
    lockAcquired,
    lockedByOtherUser,
    activeLock,
  };
};
