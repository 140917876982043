import { RefObject } from "react";
import { useEventListener } from "usehooks-ts";

const CUSTOM_WHEEL_EVENT_TYPE = "custom-wheel";

type CustomWheelEventDetails = {
  deltaX: number;
};

type CustomWheelEvent = CustomEvent<CustomWheelEventDetails>;

declare global {
  interface HTMLElementEventMap {
    [CUSTOM_WHEEL_EVENT_TYPE]: CustomWheelEvent;
  }
}

export const useCustomScrollReceiver = (
  tableContainerRef: RefObject<HTMLDivElement>,
) =>
  useEventListener(
    CUSTOM_WHEEL_EVENT_TYPE,
    (e) => {
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollLeft += e.detail.deltaX;
      }
    },
    tableContainerRef,
  );

type CustomWheelEventInterceptorOptions = {
  editing: boolean;
};
export const useCustomScrollEmitter = (
  inputRef: RefObject<HTMLInputElement>,
  cellRef: RefObject<HTMLDivElement>,
  options: CustomWheelEventInterceptorOptions,
) =>
  useEventListener(
    "wheel",
    (e) => {
      if (
        !options.editing &&
        e.target === inputRef.current &&
        // If an input has a horizontal scroll
        (e.target as HTMLInputElement).scrollWidth >
          (e.target as HTMLInputElement).clientWidth &&
        e.deltaX !== 0
      ) {
        e.preventDefault();
        e.stopPropagation();

        // Generate custom event to be handled by useCustomScroll
        // on the table container level
        const customWheelEvent = new CustomEvent<CustomWheelEventDetails>(
          CUSTOM_WHEEL_EVENT_TYPE,
          {
            bubbles: true,
            detail: { deltaX: e.deltaX },
          },
        );
        cellRef.current?.dispatchEvent(customWheelEvent);
      }
    },
    inputRef,
    { passive: false, capture: true },
  );
