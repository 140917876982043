import { useCallback, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";

import { TextEditor } from "src/base-components/TextEditor/TextEditor";
import { Toolbar } from "src/base-components/TextEditor/Toolbar";

export const ManualReviewDescriptionEditor: React.FC<{
  dataLoc?: string;
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
  placeholder: string;
}> = ({ dataLoc, value, onChange, disabled, placeholder }) => {
  const toolbarRef = useRef<HTMLUListElement>(null);
  const [isFocused, setFocused] = useState(false);

  const handleFocus = useCallback(() => setFocused(true), []);
  const handleBlur = useCallback(() => setFocused(false), []);

  return (
    <div
      className={twJoin(
        "flex flex-1 flex-col rounded border border-gray-200 p-3",
        isFocused && "border-indigo-600 ring-2 ring-indigo-500/25",
        disabled && "bg-gray-50",
        !disabled && "bg-white",
      )}
      data-loc={dataLoc}
    >
      <TextEditor
        placeholder={placeholder}
        readOnly={disabled}
        toolbarRef={toolbarRef}
        value={value}
        keepEmptyLines
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={!disabled ? handleFocus : undefined}
      />
      {!disabled && (
        <div className="flex justify-between gap-x-3 pt-3">
          <Toolbar ref={toolbarRef} exclude={["code"]} faded={!isFocused} />
        </div>
      )}
    </div>
  );
};
