import { QueryClient, UseQueryOptions } from "@tanstack/react-query";

const NO_REFETCH_OPTIONS: UseQueryOptions<any, any, any, any> = {
  refetchInterval: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: NO_REFETCH_OPTIONS,
  },
});
