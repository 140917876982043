export type DebouncedAndArgsSaveFunction<T extends (...args: any[]) => any> = {
  (...args: Parameters<T>): void;
  cancel: () => void;
  flush: () => void;
  pending: () => boolean;
  lastInvokedArgs: () => Parameters<T> | null;
};
/**
 * Creates a lodash debounce function of the passed function while additionaly saving and exposing the arguments of the latest invocation.
 * These can be accessed via .getLastInvokedArgs() of the function and are null before the first invocation.
 */
export const debounceAndSaveArgs = <T extends (...args: any[]) => any>(
  fun: T,
  delay: number,
): DebouncedAndArgsSaveFunction<T> => {
  let latestQueuedArgs: Parameters<T> | null = null;
  let timeoutId: NodeJS.Timeout | undefined;

  const debounced = function (this: unknown, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    latestQueuedArgs = args;
    timeoutId = setTimeout(() => {
      if (latestQueuedArgs) {
        fun.apply(this, latestQueuedArgs);
      }
      timeoutId = undefined;
    }, delay);
  };

  debounced.cancel = () => {
    clearTimeout(timeoutId);
    timeoutId = undefined;
  };

  debounced.flush = () => {
    if (timeoutId) {
      debounced.cancel();
      if (latestQueuedArgs !== null) {
        fun.apply(this, latestQueuedArgs);
      }
    }
  };

  debounced.pending = () => {
    return timeoutId !== undefined;
  };
  debounced.lastInvokedArgs = () => latestQueuedArgs;

  return debounced;
};
