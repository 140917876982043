import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Menu } from "@headlessui/react";
import { ReactNode } from "react";
import { twJoin, twMerge } from "tailwind-merge";

import { TransitionWrapper } from "src/base-components/TransitionWrapper";

export type DropDownElementT<T> = {
  key: string;
  value: T;
  disabled?: boolean;
  icon?: IconProp;
};

export type RenderValueFn<T> = (
  element: DropDownElementT<T> & { selected: boolean },
) => ReactNode;

export type DropDownPropsT<T> = {
  elements: DropDownElementT<T>[];
  renderButtonValue: (value?: T) => ReactNode;
  renderValue: RenderValueFn<T>;
  renderFooter?: () => ReactNode;
  renderHeader?: () => ReactNode;
  onSelect: (key: string) => void;
  selectedKey?: string;
  className?: string;
  buttonClassName?: string;
  placement?: "topRight" | "topLeft" | "bottomRight" | "bottomLeft";
  buttonDataLoc?: string;
  itemsClassNames?: string;
  disabled?: boolean;
  variant?: "light" | "transparent";
  dataLoc?: string;
};

export const DropDown = <T,>({
  disabled = false,
  onSelect,
  elements,
  renderButtonValue,
  renderValue,
  renderFooter,
  selectedKey,
  className,
  buttonClassName,
  placement = "bottomRight",
  buttonDataLoc,
  itemsClassNames,
  renderHeader,
  variant = "light",
  dataLoc,
}: DropDownPropsT<T>) => {
  const selected = elements.find((element) => element.key === selectedKey);

  const renderItems = () =>
    elements.map((element: DropDownElementT<T>) => (
      <Menu.Item key={element.key} disabled={element.disabled}>
        <span
          className="block cursor-pointer text-gray-800 font-inter-normal-13px ui-active:bg-gray-50"
          onClick={() => onSelect(element.key)}
        >
          {renderValue({
            ...element,
            selected: selected?.key === element.key,
          })}
        </span>
      </Menu.Item>
    ));

  const renderFooterAsItem = () =>
    renderFooter ? (
      <Menu.Item>
        <span className="block cursor-pointer text-xs text-gray-800 ui-active:bg-gray-50">
          {renderFooter()}
        </span>
      </Menu.Item>
    ) : undefined;

  return (
    <Menu
      as="div"
      className={twJoin(
        "relative text-left text-gray-800 font-inter-normal-13px",
        className,
      )}
      data-loc={dataLoc}
    >
      {({ open }) => (
        <>
          <Menu.Button
            className={twMerge(
              "h-full w-full justify-center rounded-lg font-inter-normal-12px",
              "focus:border-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500/25",
              selected?.value && "text-gray-500",
              elements.length === 0 && "cursor-default",
              open &&
                "border-indigo-400 outline-none ring-2 ring-indigo-500/25",
              variant === "light" &&
                !disabled &&
                "border border-gray-200 bg-white",
              variant === "light" &&
                disabled &&
                "border border-gray-200 bg-gray-100",
              buttonClassName,
            )}
            data-loc={buttonDataLoc}
            disabled={disabled || elements.length === 0}
          >
            {renderButtonValue(selected?.value)}
          </Menu.Button>
          <TransitionWrapper>
            <Menu.Items
              className={twMerge(
                "decideScrollbar absolute z-50 overflow-auto rounded-lg bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none",
                placement === "bottomRight" && "right-0 origin-top-right",
                placement === "bottomLeft" && "left-0 origin-top-right",
                placement === "topRight" && "bottom-[calc(100%+2px)] right-0",
                placement === "topLeft" && "bottom-[calc(100%+2px)] left-0",
                itemsClassNames,
              )}
            >
              {renderHeader?.()}
              {renderItems()}
              {renderFooterAsItem()}
            </Menu.Items>
          </TransitionWrapper>
        </>
      )}
    </Menu>
  );
};
