import { makeConnectionTemplate } from "src/connections/types";

export const BoniversumTemplate = makeConnectionTemplate({
  provider: "boniversum",
  secrets: [
    { key: "username", name: "Username", required: true },
    { key: "password", name: "Password", required: true },
    { key: "product_id", name: "Product ID", required: true },
  ],
  resources: {
    consumer_report: {
      name: "Consumer Report",
      configKeys: [
        {
          key: "boniversum_legitimate_interest",
          required: true,
          name: "Boniversum legitimate interest",
          type: "simple_selection",
          elements: [
            { key: 1, value: "Credit decision" },
            { key: 3, value: "Business initiation" },
            { key: 4, value: "Solvency check" },
            { key: 5, value: "Insurance contract" },
            { key: 6, value: "Share holding structure" },
            { key: 7, value: "Receivables overdue" },
            { key: 8, value: "Debt collection" },
            { key: 9, value: "Terms inquiry" },
          ],
        },
      ],
    },
  },
});
