import { useCallback, useEffect, useRef } from "react";
import { twJoin } from "tailwind-merge";

import { Spinner } from "src/base-components/Spinner";
import { ChangeHistoryRow } from "src/changeHistory/ChangeHistoryRow";
import { ChangeHistoryRowSkeleton } from "src/changeHistory/ChangeHistoryRowSkeleton";
import { OpenDiffViewParams } from "src/changeHistory/DiffViewModal/GetChangeToCompareAgainst";
import { ChangeLogDb } from "src/clients/flow-api";

type ChangeHistoryListProps = {
  direction?: "descending" | "ascending";
  changes: ChangeLogDb[] | undefined;
  latestSeenEtag?: string;
  canFetchNextChanges: boolean;
  fetchNextChanges: () => void;
  containerClassName?: string;
  openDiffView?: (params: OpenDiffViewParams) => void;
  isFetchingNextPage: boolean;
  renderItem?: (change: ChangeLogDb, index: number) => React.ReactNode;
};

const loadingIndicator = (
  <>
    <li>
      <ChangeHistoryRowSkeleton />
    </li>
    <li>
      <ChangeHistoryRowSkeleton />
    </li>
    <li>
      <ChangeHistoryRowSkeleton />
    </li>
    <li>
      <ChangeHistoryRowSkeleton />
    </li>
  </>
);

export const ChangeHistoryList: React.FC<ChangeHistoryListProps> = ({
  changes,
  latestSeenEtag,
  canFetchNextChanges,
  fetchNextChanges,
  containerClassName,
  openDiffView,
  isFetchingNextPage,
  direction = "descending",
  renderItem,
}) => {
  const changeHistoryListRef = useRef<HTMLUListElement>(null);

  const fetchMoreOnEdge = useCallback(
    (containerRefElement?: HTMLUListElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 1000px of the list, fetch more data if there is any
        if (
          canFetchNextChanges &&
          ((direction === "descending" &&
            scrollHeight - scrollTop - clientHeight < 1000) ||
            (direction === "ascending" && scrollTop < 1000))
        ) {
          fetchNextChanges();
        }
      }
    },
    [canFetchNextChanges, fetchNextChanges, direction],
  );

  useEffect(() => {
    const scrollable = document.querySelector("[data-loc=change-history-list]");
    if (!scrollable) return;
    if (direction === "ascending") {
      // Keep the scroll at the bottom near the latest stuff
      scrollable.scrollTop = scrollable.scrollHeight;
    }
  }, [changes, direction]);

  const applyDirection = <T,>(list: T[]): T[] =>
    direction === "ascending" ? list.reverse() : list;

  return (
    <ul
      ref={changeHistoryListRef}
      className={twJoin(
        "decideScrollbar max-h-full flex-row overflow-y-auto",
        containerClassName,
      )}
      data-loc="change-history-list"
      onScroll={(e) => {
        fetchMoreOnEdge(e.target as HTMLUListElement);
      }}
    >
      {changes !== undefined ? (
        <>
          {applyDirection(
            changes.map((change, index) => (
              <li key={change.etag}>
                {renderItem ? (
                  renderItem(change, index)
                ) : (
                  <ChangeHistoryRow
                    change={change}
                    hasNextRow={
                      direction === "ascending"
                        ? index !== 0
                        : index !== changes.length - 1
                    }
                    latestSeenEtag={latestSeenEtag}
                    openNodeDiffView={openDiffView}
                  />
                )}
              </li>
            )),
          )}
          {isFetchingNextPage && loadingIndicator}
        </>
      ) : (
        <Spinner />
      )}
    </ul>
  );
};
