import { useRef, useEffect } from "react";
import { twJoin } from "tailwind-merge";

import { EditableDiv } from "src/base-components/EditorTable/EditableDiv";
import { EditorCellProps } from "src/base-components/EditorTable/types";
import { focusContentEditable } from "src/base-components/EditorTable/utils";

type Props<T> = Omit<EditorCellProps<T, string>, "context"> & {
  alignCenter?: boolean;
  context?: EditorCellProps<T, string>["context"];
};

export const StringEditor = <T,>({
  value,
  state: { isEditing, isSelected, readonly },
  onChange,
  placeholder,
  alignCenter = true,
}: Props<T>) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && isSelected) {
      ref.current.focus();
    }
  }, [isSelected]);

  useEffect(() => {
    if (ref.current && isEditing) {
      focusContentEditable(ref.current);
    }
  }, [isEditing]);

  return (
    <EditableDiv
      ref={ref}
      className={twJoin(
        "break-words",
        "h-full w-full border-0 p-2 text-xs outline-0 ring-0 focus:outline-0 focus:ring-0",
        isEditing ? "bg-white" : "flex",
        alignCenter && "items-center",
      )}
      contentEditable={!readonly && isEditing}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};
