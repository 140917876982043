import { faDatabase } from "@fortawesome/pro-regular-svg-icons";
import { UseQueryResult } from "@tanstack/react-query";
import { times } from "lodash";
import React from "react";
import { useLocalStorage } from "usehooks-ts";

import {
  AssembleDatasetJob,
  Dataset,
  DuplicateDatasetJob,
} from "src/api/types";
import { EmptyState } from "src/base-components/EmptyState";
import { LoadingView } from "src/base-components/LoadingView";
import { Pill } from "src/base-components/Pill";
import { toastFailure, toastSuccess } from "src/base-components/Toast/utils";
import { AddDatasetDropdown } from "src/datasets/DatasetList/AddDatasetDropdown";
import { Item } from "src/datasets/DatasetList/Item";
import { ItemSkeleton } from "src/datasets/DatasetList/ItemSkeleton";
import { PendingItem } from "src/datasets/DatasetList/PendingItem";
import { VersionSchemas } from "src/datasets/DatasetTable/types";
import { PaneHeader } from "src/datasets/PaneHeader";
import { useDatasetJobs, useHideDatasetJob } from "src/datasets/api/queries";
import { useCreateDatasetFromScratch } from "src/datasets/hooks/useCreateDatasetFromScratch";
import { useThereIsDownloadPending } from "src/datasets/hooks/useThereIsDownloadPending";
import { useDatasetFileUpload as useDatasetUpload } from "src/datasets/useDatasetUpload";
import { isDatasetCompatible } from "src/datasets/utils";
import { useAuthoringContext } from "src/router/routerContextHooks";

type Props = {
  datasetsQuery: UseQueryResult<Dataset[], Error>;
  schemas: VersionSchemas;
  onAssemble: () => void;
  onDelete: (dataset: Dataset) => void;
  onSelect: (dataset: Dataset) => void;
  onRequestClose: () => void;
};

type Filter = "all" | "compatible";

const renderSkeleton = () => (
  <ul className="flex flex-col gap-y-4">
    {times(3).map((i) => (
      <ItemSkeleton key={i} />
    ))}
  </ul>
);
export const DatasetList: React.FC<Props> = ({
  onAssemble,
  schemas,
  onDelete,
  onSelect,
  onRequestClose,
  datasetsQuery,
}) => {
  const [filter, setFilter] = useLocalStorage<Filter>(
    "dataset_list_filter",
    "all",
  );

  const { workspace, flow, version } = useAuthoringContext();

  const { uploads, uploadDataset, deleteFromUploads } =
    useDatasetUpload(version);
  const createDatasetFromScratch = useCreateDatasetFromScratch();

  const datasetJobs = useDatasetJobs(workspace.base_url!, flow.id);

  const thereIsADownloadPending = useThereIsDownloadPending();

  const pendingOrFailedDatasetJobs = (datasetJobs.data ?? []).filter(
    (job) => job.status !== "COMPLETED",
  );

  const assembleAndDuplicateDatasetJobs = pendingOrFailedDatasetJobs.filter(
    (job): job is DuplicateDatasetJob | AssembleDatasetJob =>
      job.type === "duplicate" || job.type === "assemble",
  );
  const { mutateAsync: hideDatasetJob } = useHideDatasetJob(
    workspace.base_url!,
    flow.id,
  );

  const handleFilesDropped = async (files: File[], fileErrors: string[]) => {
    if (files.length > 0) {
      toastSuccess({ title: "Dataset uploading..." });
    }

    if (fileErrors.length > 0) {
      toastFailure({
        title: "Error uploading dataset files",
        description: fileErrors.join(", "),
      });
    }

    for (const file of files) {
      try {
        const datasetFileUpload = await uploadDataset({
          file,
          purpose: "test_run",
        });
        datasetFileUpload.finishedPromise.then((finishedUpload) => {
          if (finishedUpload.status === "COMPLETED") {
            toastSuccess({ title: "Dataset processed successfully." });
          } else if (finishedUpload.status === "FAILED") {
            toastFailure({ title: `Unable to process dataset!` });
          }
        });
      } catch (e) {
        toastFailure({ title: "Dataset upload failed!" });
      }
    }
  };

  return (
    <>
      <PaneHeader onClose={onRequestClose} />
      <div className="flex flex-col gap-y-6 px-6">
        <div className="flex items-center justify-between gap-x-1">
          <div className="space-x-1.5">
            <Pill
              variant={filter === "all" ? "indigo-light" : "outlined-white"}
              onClick={() => setFilter("all")}
            >
              <Pill.Text>All</Pill.Text>
            </Pill>
            <Pill
              variant={
                filter === "compatible" ? "indigo-light" : "outlined-white"
              }
              onClick={() => setFilter("compatible")}
            >
              <Pill.Text>Compatible</Pill.Text>
            </Pill>
          </div>
          <AddDatasetDropdown
            size="sm"
            onAssemble={onAssemble}
            onCreate={createDatasetFromScratch}
            onFilesDropped={handleFilesDropped}
          />
        </div>
        <LoadingView
          queryResult={datasetsQuery}
          renderUpdated={(datasets: Dataset[]) => {
            const testRunDatasets = datasets.filter(
              (d) => !d.purpose || d.purpose === "test_run",
            );

            const compatibilityDict = testRunDatasets.reduce<
              Record<string, boolean>
            >((acc, dataset) => {
              acc[dataset.id] = isDatasetCompatible(dataset, schemas.input);
              return acc;
            }, {});

            const filteredDatasets =
              filter === "all"
                ? testRunDatasets
                : testRunDatasets.filter((dataset) =>
                    Boolean(compatibilityDict[dataset.id]),
                  );

            return (
              <ul className="flex flex-col gap-y-4 pb-10">
                {filteredDatasets.length < 1 &&
                  uploads.length < 1 &&
                  assembleAndDuplicateDatasetJobs.length < 1 && (
                    <EmptyState
                      action={
                        <AddDatasetDropdown
                          placement="bottom"
                          onAssemble={onAssemble}
                          onCreate={createDatasetFromScratch}
                          onFilesDropped={handleFilesDropped}
                        />
                      }
                      description="No test dataset was found for this Decision Flow. Click below to add a new test dataset."
                      headline="No test dataset Found"
                      icon={faDatabase}
                    />
                  )}
                {assembleAndDuplicateDatasetJobs.map((job) => (
                  <PendingItem
                    key={job.id}
                    errorMsg={job.error?.message}
                    name={
                      job.type === "duplicate"
                        ? (job.request.new_dataset_name ?? "Duplicated dataset")
                        : job.request.name
                    }
                    status={job.status}
                    onDelete={() => hideDatasetJob(job.id)}
                  />
                ))}
                {uploads.map((datasetFileUpload) => (
                  <PendingItem
                    key={datasetFileUpload.id}
                    errorMsg={datasetFileUpload.error_metadata}
                    name={datasetFileUpload.file_name}
                    status={datasetFileUpload.status}
                    onDelete={() => deleteFromUploads(datasetFileUpload.id)}
                  />
                ))}
                {filteredDatasets.map((dataset) => (
                  <Item
                    key={dataset.id}
                    dataset={dataset}
                    disableDownloads={thereIsADownloadPending}
                    incompatible={!compatibilityDict[dataset.id]}
                    onClick={() => onSelect(dataset)}
                    onDelete={() => onDelete(dataset)}
                  />
                ))}
              </ul>
            );
          }}
          renderUpdating={renderSkeleton}
        />
      </div>
    </>
  );
};
