import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { createRoot } from "react-dom/client";

import { Icon } from "src/base-components/Icon";

export const OutdatedAutocompleteDataStatus = document.createElement("div");
OutdatedAutocompleteDataStatus.setAttribute("class", "order-last");
const OutdatedAutocompleteDataStatusRoot = createRoot(
  OutdatedAutocompleteDataStatus,
);
OutdatedAutocompleteDataStatusRoot.render(
  <div className="flex flex-row items-center border-t border-gray-50 px-2.5 pb-1 pt-2 text-gray-500 font-inter-normal-12px">
    <Icon
      color="text-gray-500 hover:text-gray-700"
      icon={faCircleInfo}
      size="xs"
    />
    Test run to see updated suggestions
  </div>,
);

export const NoAutocompleteDataStatus = document.createElement("div");
NoAutocompleteDataStatus.setAttribute("class", "order-last");
const NoAutocompleteDataStatusRoot = createRoot(NoAutocompleteDataStatus);
NoAutocompleteDataStatusRoot.render(
  <div
    className="flex flex-row items-center px-2.5 pb-1 pt-1 text-gray-500 font-inter-normal-12px"
    data-loc="autocomplete-info"
  >
    <Icon
      color="text-gray-500 hover:text-gray-700"
      icon={faCircleInfo}
      size="xs"
    />
    Test run to see autocomplete suggestions
  </div>,
);
