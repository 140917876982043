import { useFormContext } from "react-hook-form";

import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { MySQLSecretField } from "src/connections/config/database/mysql/MySQLSecretField";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import {
  Environment,
  MySQLConnectionConfigInputsT,
} from "src/connections/types";

type PropsT = {
  environment: Environment;
};

export const MySQLUsernamePasswordAuthFieldset: React.FC<PropsT> = ({
  environment,
}) => {
  const {
    formState: { errors },
  } = useFormContext<MySQLConnectionConfigInputsT>();
  const envErrors =
    errors?.[`${environment}Config`]?.["usernamePasswordConfig"];

  return (
    <FieldsetCard className="last:mb-0">
      <MySQLSecretField
        environment={environment}
        helpText="The username used to connect to the database"
        isErrored={isFieldErrored(envErrors, "username")}
        secretConfigField="usernamePasswordConfig.username"
        secretName="Username"
        isRequired
      />
      <MySQLSecretField
        environment={environment}
        helpText="The password used to connect to the database"
        isErrored={isFieldErrored(envErrors, "password")}
        secretConfigField="usernamePasswordConfig.password"
        secretName="Password"
        isRequired
      />
    </FieldsetCard>
  );
};
