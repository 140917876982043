import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { Content, Header, Item, Trigger } from "@radix-ui/react-accordion";
import React from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";

const contentChildBaseClassName = "flex flex-col gap-y-3";
const contentClassName =
  "radix-state-open:animate-accordionSlideDownAndShowOverflow radix-state-closed:animate-accordionSlideUpAndHideOverflow radix-state-open";

type Props = {
  value: string;
  title: React.ReactNode;
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  dataLoc?: string;
};

export const AccordionItem: React.FC<Props> = ({
  value,
  title,
  className,
  children,
  disabled,
  dataLoc,
}) => (
  <Item data-loc={dataLoc} disabled={disabled} value={value}>
    <Header className="mb-3.5 flex items-center gap-x-2 text-gray-800 font-inter-semibold-13px">
      <Trigger className="group" onClick={(e) => e.stopPropagation()}>
        <div className="group-radix-state-closed:-rotate-90 group-radix-state-closed:duration-300 group-radix-state-open:duration-300">
          <Icon
            color="text-gray-500 hover:text-gray-700"
            icon={faChevronDown}
            size="xs"
          />
        </div>
      </Trigger>
      {title}
    </Header>
    <Content className={contentClassName}>
      <div className={twJoin(contentChildBaseClassName, className)}>
        {children}
      </div>
    </Content>
  </Item>
);
