import {
  faMap,
  faExpand,
  faMinus,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import React, { MutableRefObject, useState } from "react";
import { MiniMap, ReactFlowInstance, useViewport } from "reactflow";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { Tooltip as DecideTooltip } from "src/base-components/Tooltip";
import { GroupNode, NodeT } from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";

const Divider: React.FC = () => (
  <div className="flex w-2 items-center justify-center py-2.5">
    <div className="h-4 w-px rounded-full bg-gray-200"></div>
  </div>
);

const getNodeMinimapColor = (node: NodeT) => {
  if (
    node.type === NODE_TYPE.INPUT_NODE ||
    node.type === NODE_TYPE.OUTPUT_NODE
  ) {
    return "#5946E5";
  } else if (
    node.type === NODE_TYPE.GROUP_NODE &&
    (node as GroupNode).data.uiState.expanded
  ) {
    return "#FEF3C7";
  }
  return "#D1D3DB";
};

export const GraphControls: React.FC<{
  flowInstance: MutableRefObject<ReactFlowInstance<any, any> | null>;
}> = ({ flowInstance }) => {
  const [minimapIsShown, setMinimapIsShown] = useState<boolean>(false);
  const { zoom } = useViewport();

  const zoomIn = () => {
    flowInstance.current?.zoomIn();
  };

  const zoomOut = () => {
    flowInstance.current?.zoomOut();
  };

  const zoomTo100 = () => {
    flowInstance.current?.zoomTo(1);
  };

  const fitView = () => {
    flowInstance.current?.fitView();
  };

  return (
    <div className="absolute bottom-1 right-0 z-10 mb-6 mr-21">
      <div
        className="relative flex gap-2.5 rounded-lg bg-white px-4 py-1 shadow-base"
        data-loc="zoom-control"
      >
        {minimapIsShown && (
          <MiniMap
            className="rounded-lg shadow-sm"
            maskColor="rgb(229, 230, 235, 0.3)"
            nodeBorderRadius={32}
            nodeColor={getNodeMinimapColor}
            style={{
              marginRight: "0rem",
              bottom: "100%",
              marginBottom: "0.75rem",
            }}
            pannable
            zoomable
          />
        )}
        <DecideTooltip placement="top" title="Minimap" asChild>
          <Icon
            color={twJoin(
              "aspect-auto text-gray-500 hover:text-gray-700",
              minimapIsShown && "text-indigo-500",
            )}
            icon={faMap}
            size="xs"
            onClick={() => setMinimapIsShown((old) => !old)}
          />
        </DecideTooltip>
        <DecideTooltip placement="top" title="Fit to view" asChild>
          <Icon
            color="text-gray-500 hover:text-gray-700"
            dataLoc="graph-fit-to-view"
            icon={faExpand}
            size="xs"
            onClick={fitView}
          />
        </DecideTooltip>
        <Divider />
        <DecideTooltip placement="top" title="Zoom out" asChild>
          <Icon
            color="text-gray-500 hover:text-gray-700"
            icon={faMinus}
            size="xs"
            onClick={zoomOut}
          />
        </DecideTooltip>

        <DecideTooltip placement="top" title="Zoom to 100%">
          <div
            className="mx-1 w-8 cursor-pointer select-none py-2.5 text-center text-indigo-600 font-inter-semibold-13px"
            onClick={zoomTo100}
          >
            {Math.round(zoom * 100)}%
          </div>
        </DecideTooltip>
        <DecideTooltip placement="top" title="Zoom in" asChild>
          <Icon
            color="text-gray-500 hover:text-gray-700"
            icon={faPlus}
            size="xs"
            onClick={zoomIn}
          />
        </DecideTooltip>
      </div>
    </div>
  );
};
