/**
 * Prefix marking Taktile internal resources. They will be hidden from queries for anyone not a taktile engineer.
 */
export const taktileInternalPrefix = "tktl_" as const;

export const startsWithInternalPrefix = (name: string) =>
  name.startsWith(taktileInternalPrefix);

export const validateNotStartsWithInternalPrefix = (errorMessage: string) => ({
  noInternalPrefix: (name: string) =>
    !startsWithInternalPrefix(name) || errorMessage,
});
