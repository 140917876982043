import { useContext } from "react";
import { useStore } from "zustand";

import { FloatingWindowsContext } from "src/base-components/FloatingWindow/context";

const useFloatingWindowsStore = () => {
  const store = useContext(FloatingWindowsContext);
  if (!store) {
    throw new Error("FloatingWindowsContext is not defined");
  }
  return store;
};

export const useFloatingWindowsActions = () => {
  const store = useFloatingWindowsStore();
  return useStore(store, (state) => state.actions);
};

export const useFloatingWindows = () => {
  const store = useFloatingWindowsStore();
  return useStore(store, (state) => state.windows);
};

export const useIsFloatingWindowPinned = (id: string) => {
  const store = useFloatingWindowsStore();
  return useStore(store, (state) => state.windows[id]?.isPinned);
};
