import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { useFormContext, useWatch } from "react-hook-form";

import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Tooltip } from "src/base-components/Tooltip";
import {
  OutgoingWebhookConnectionForm,
  OutgoingWebhookConnectionSecret,
} from "src/webhooks/editModal/EditOutgoingWebhookModal";

type Props = {
  dataLoc?: string;
  fieldName: `secrets.${number}`;
  name: string;
  subHeadline?: string;
  required?: boolean;
  placeholder?: string;
};

export const SecretField: React.FC<Props> = ({
  dataLoc,
  fieldName,
  name,
  subHeadline,
  required,
  placeholder,
}) => {
  const formMethods = useFormContext<OutgoingWebhookConnectionForm>();
  //TODO check if necessary @AUTH-2676
  useWatch({ control: formMethods.control, name: fieldName });
  const error = formMethods.getFieldState(fieldName).error;
  const secret: OutgoingWebhookConnectionSecret | undefined =
    formMethods.getValues(fieldName) as
      | OutgoingWebhookConnectionSecret
      | undefined;

  const makeSecretEditable = () => {
    formMethods.setValue(fieldName, {
      key: formMethods.getValues(`${fieldName}.key`),
      value: "",
      editable: true,
    });
    setTimeout(
      () =>
        formMethods.setFocus(`${fieldName}.value`, {
          shouldSelect: true,
        }),
      0,
    );
  };

  return (
    <FormItem description={subHeadline} isRequired={required} label={name}>
      {error && <ErrorHint>{error.message}</ErrorHint>}
      <div className="flex w-full items-center">
        <div className="flex-1">
          <Input
            data-loc={dataLoc}
            disabled={!secret?.editable}
            errored={!!error}
            {...formMethods.register(
              `${fieldName}.value`,
              required
                ? {
                    required: `${name} is required`,
                  }
                : {},
            )}
            placeholder={placeholder}
            type="password"
            fullWidth
          />
        </div>
        {!secret?.editable && (
          <Tooltip
            align="center"
            placement="right"
            title={`Edit ${name.toLowerCase()}`}
            asChild
          >
            <div className="ml-2">
              <Icon
                color="text-gray-500"
                icon={faEdit}
                size="xs"
                onClick={makeSecretEditable}
              />
            </div>
          </Tooltip>
        )}
      </div>
    </FormItem>
  );
};
