import { useFormContext } from "react-hook-form";

import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { SnowflakeSecretField } from "src/connections/config/database/snowflake/SnowflakeSecretField";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import {
  Environment,
  SnowflakeConnectionConfigInputsT,
} from "src/connections/types";

type PropsT = {
  environment: Environment;
};

export const SnowflakeBasicAuthFieldset: React.FC<PropsT> = ({
  environment,
}) => {
  const {
    formState: { errors },
  } = useFormContext<SnowflakeConnectionConfigInputsT>();
  const envErrors = errors?.[`${environment}Config`]?.["basicAuthConfig"];

  return (
    <FieldsetCard className="last:mb-0">
      <SnowflakeSecretField
        environment={environment}
        helpText="The username used to connect to the database"
        isErrored={isFieldErrored(envErrors, "basicAuthUsername")}
        secretConfigField="basicAuthConfig.basicAuthUsername"
        secretName="Username"
        isRequired
      />
      <SnowflakeSecretField
        environment={environment}
        helpText="The password used to connect to the database"
        isErrored={isFieldErrored(envErrors, "password")}
        secretConfigField="basicAuthConfig.password"
        secretName="Password"
        isRequired
      />
    </FieldsetCard>
  );
};
