import { Skeleton } from "src/base-components/Skeleton";

export const ItemSkeleton = () => (
  <li className="h-[72px] rounded-lg border border-gray-200 px-5 pt-3.5">
    <div className="flex items-center gap-x-2">
      <Skeleton height="h-4" variant="rounded" width="w-4" />
      <Skeleton height="h-3" variant="rounded" width="w-56" />
    </div>
    <div className="w- mt-2.5 pl-6">
      <Skeleton height="h-3" variant="rounded" width="w-80" />
    </div>
  </li>
);
