import React from "react";

import { EditorCellProps } from "src/base-components/EditorTable/types";
import { RowShape } from "src/decisionTableNode/utils";

type Props = EditorCellProps<RowShape, string>;

export const IndexRenderer: React.FC<Props> = React.memo(({ context }) => {
  if ("row" in context) {
    // make sure it is a cell context
    return (
      <div className="flex items-center break-words p-2">
        {context.row.index + 1}
      </div>
    );
  }
  return <></>;
});
