import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FieldErrorsT } from "src/api/types";
import { Card } from "src/base-components/Card";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Icon } from "src/base-components/Icon";
import { Switch } from "src/base-components/Switch";
import { Tooltip } from "src/base-components/Tooltip";
import { DatabaseConnectionForm } from "src/databaseConnectionNode/types";

type ResponseMappingPropsT = {
  runFieldErrors: FieldErrorsT | undefined;
  immutable: boolean;
};

const ExampleResponseTooltipBody: React.FC = () => (
  <div>
    <code className="whitespace-pre">
      {`{
  data: {
    result: [
      {
        column_1: value_1,
        column_2: value_2,
        ...
      },
      {
        column_1: value_1,
        column_2: value_2,
        ...
      },
      ...
    ]
  },
  ...
}`}
    </code>
  </div>
);

type InsightMappingInputPropsT = ResponseMappingPropsT & {
  fieldName: "default" | "num_records_affected";
  placeholder?: string;
};

const InputCard: React.FC<InsightMappingInputPropsT> = ({
  fieldName,
  placeholder,
  runFieldErrors,
  immutable,
}) => {
  const formProps = useFormContext<DatabaseConnectionForm>();

  const fieldPath = `response.${fieldName}` as const;

  if (!formProps.getValues(`${fieldPath}`).active) {
    return null;
  }

  const error = runFieldErrors?.[formProps.getValues(`${fieldPath}.id`)];

  return (
    <Card className="mt-3 flex w-full flex-row items-center gap-x-2">
      <div className="flex text-gray-700 font-inter-normal-12px">
        Output location
      </div>
      <Controller
        control={formProps.control}
        name={`${fieldPath}.mapped_to`}
        render={({ field: controlledField }) => (
          <AutocompleteCodeInput
            dataLoc={`dbc-node-editor-${fieldPath}.mappedTo`}
            disabled={immutable}
            error={error}
            placeholder={placeholder}
            prefix="data."
            value={controlledField.value}
            onChange={controlledField.onChange}
          />
        )}
      />
    </Card>
  );
};

export const ResponseMapping: React.FC<ResponseMappingPropsT> = ({
  runFieldErrors,
  immutable,
}) => {
  const formProps = useFormContext<DatabaseConnectionForm>();
  return (
    <>
      <div>
        <div className="flex w-full flex-row text-gray-500 font-inter-normal-12px">
          The data will be stored in the chosen field
          <Tooltip
            align="center"
            body={<ExampleResponseTooltipBody />}
            placement="top"
            title="Example response"
          >
            <Icon
              color="text-gray-500"
              cursorType="pointer"
              icon={faQuestionCircle}
              size="2xs"
            />
          </Tooltip>
        </div>
        <InputCard
          fieldName="default"
          immutable={immutable}
          placeholder="database_response"
          runFieldErrors={runFieldErrors}
        />
      </div>
      <div>
        <div className="mt-4 flex items-center justify-between">
          <div>
            <h3 className="text-gray-800 font-inter-medium-12px">
              Number of records affected
              <Tooltip
                align="center"
                body={
                  <div>
                    Indicates how many rows were impacted or modified by the
                    execution of the query. This is useful for understanding the
                    outcome of a data modification operation (e.g.,{" "}
                    <code>INSERT</code>, <code>UPDATE</code>,{" "}
                    <code>DELETE</code>). Returns <code>None</code> for
                    operations that do not affect any existing records.
                  </div>
                }
                placement="top"
                title=""
              >
                <Icon
                  color="text-gray-500"
                  cursorType="pointer"
                  icon={faQuestionCircle}
                  size="2xs"
                />
              </Tooltip>
            </h3>
          </div>
          <Controller
            control={formProps.control}
            name="response.num_records_affected.active"
            render={({ field }) => (
              <Switch
                dataLoc="dbc-node-editor-response.num_records_affected.active"
                disabled={immutable}
                enabled={field.value}
                onChange={() => {
                  field.onChange(!field.value);
                }}
              />
            )}
          />
        </div>
        <InputCard
          fieldName="num_records_affected"
          immutable={immutable}
          placeholder="number_of_records"
          runFieldErrors={runFieldErrors}
        />
      </div>
    </>
  );
};
