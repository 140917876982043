import { faCopy, faDiagramNext } from "@fortawesome/pro-regular-svg-icons";
import { faRotateRight, faWarning } from "@fortawesome/pro-solid-svg-icons";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { AnimatePresence, m } from "framer-motion";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useHover } from "usehooks-ts";

import { ProviderT } from "src/api/connectApi/types";
import { DatasetColumnGroups, DesiredType } from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { Tooltip, TooltipContentProps } from "src/base-components/Tooltip";
import { ProviderIcon } from "src/connections/config/Icon";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { ObjectDetailPane } from "src/dataTable/ObjectDetailPane";
import { ColumnMenu } from "src/datasets/DatasetTable/ColumnMenu";
import {
  useIsColumnHovered,
  useIsColumnHoveredLike,
  useIsEditingCell,
  useIsSelectedCell,
} from "src/datasets/DatasetTable/stores";
import { DatasetContext, JSONValue } from "src/datasets/DatasetTable/types";
import {
  CellId,
  isLoopIntegrationNode,
  isParentIntegrationNode,
  jsonToPon,
  parseCellId,
} from "src/datasets/DatasetTable/utils";
import { DESIRED_TYPE_ICONS, DatasetIntegrationNode } from "src/datasets/utils";
import { copyTextToClipboard } from "src/utils/clipboard";

export const getIcon = (type: DesiredType | undefined) =>
  DESIRED_TYPE_ICONS[type ?? "any"];

export type MockColumn = {
  name: string;
  provider: ProviderT | string | NODE_TYPE.FLOW_NODE | NODE_TYPE.LOOP_NODE;
  mediaKey: string | null;
  subMocks: Pick<MockColumn, "name" | "provider" | "mediaKey">[] | null;
};

export const ReadonlyCell: React.FC<{
  children: string | undefined;
  withPopup: boolean;
}> = ({ children, withPopup }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const wrapWithPopupIfNeeded = (mainElement: JSX.Element) => {
    if (withPopup && children) {
      return (
        <RadixTooltip.Root
          delayDuration={20}
          open={tooltipOpen}
          onOpenChange={setTooltipOpen}
        >
          <RadixTooltip.Trigger asChild>{mainElement}</RadixTooltip.Trigger>
          <RadixTooltip.Portal forceMount>
            <AnimatePresence>
              {tooltipOpen && (
                <RadixTooltip.Content
                  align="center"
                  collisionPadding={16}
                  side="top"
                  sideOffset={8}
                  asChild
                >
                  <m.div
                    animate={{ opacity: 1, transition: { duration: 0.2 } }}
                    className="decideScrollbar z-50 max-h-150 min-w-[220px] max-w-128 overflow-auto rounded-lg bg-white px-3 pb-2 pt-3 shadow-lg"
                    exit={{ opacity: 0, transition: { duration: 0.1 } }}
                    initial={{ opacity: 0 }}
                  >
                    <ObjectDetailPane
                      innerDimensionClass="max-h-64 max-w-164"
                      jsonObject={JSON.parse(children)}
                      speakPython
                    />
                  </m.div>
                </RadixTooltip.Content>
              )}
            </AnimatePresence>
          </RadixTooltip.Portal>
        </RadixTooltip.Root>
      );
    } else {
      return mainElement;
    }
  };

  return wrapWithPopupIfNeeded(
    <div className="max-w-40 truncate p-1.5 text-gray-800 font-inter-normal-12px">
      {children ? jsonToPon(children) : ""}
    </div>,
  );
};

type HeaderProps = {
  children: string;
  type?: DesiredType;
  cellId: CellId;
  mock: {
    mockFields: MockColumn[];
    integrationNodesExist: boolean;
  };
  onDelete: () => void;
  onRename: (newName: string) => void;
  onColumnAdd: (name: string, group: DatasetColumnGroups) => void;
  onAddSubMocks?: (
    subMockNames: string[],
    mockColumnName: string,
    desiredType: Extract<DesiredType, "object" | "any">,
  ) => void;
  onAddAdditionalColumn: () => void;
  output: { outputFields: string[]; schemaIsEmpty: boolean };
  input: { inputFields: string[]; schemaIsEmpty: boolean };
  readonly: boolean;
  warning?: Pick<TooltipContentProps, "title" | "body" | "action">;
  disabled?: boolean;
  typeChange?: {
    selectedType: DesiredType;
    compatibleType?: DesiredType;
    onChangeType: (selectedType: DesiredType) => void;
  };
  fillMockColumn?: {
    integrationNode: DatasetIntegrationNode;
    onFill: (value: JSONValue) => void;
  };
  context: DatasetContext;
};

export const Header: React.FC<HeaderProps> = ({
  children,
  type,
  cellId,
  onDelete,
  onRename,
  readonly,
  onColumnAdd,
  onAddSubMocks,
  warning,
  typeChange,
  mock,
  fillMockColumn,
  input,
  output,
  disabled,
  onAddAdditionalColumn,
  context,
}) => {
  const editorRef = useRef<HTMLInputElement>(null);
  const cellRef = useRef<HTMLDivElement>(null);
  const [localValue, setLocalValue] = React.useState(children);
  const [_, columnId] = parseCellId(cellId);
  const selected = useIsSelectedCell(cellId);
  const editing = useIsEditingCell(cellId);
  const isMenuVisible = useIsColumnHovered(columnId) || selected || editing;

  useEffect(() => {
    if (editing && editorRef.current) {
      editorRef.current.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (selected && !editing && cellRef.current) {
      cellRef.current.focus();
      cellRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
        block: "nearest",
      });
    }
  }, [selected, editing]);

  useEffect(() => {
    setLocalValue(children);
  }, [children]);

  return (
    <HeaderCell
      ref={cellRef}
      className={twJoin(
        "group gap-x-1.5",
        disabled
          ? "bg-gray-50 text-gray-500"
          : warning
            ? "bg-orange-50"
            : "bg-white",
        selected && "outline outline-1 outline-indigo-500",
      )}
      dataLoc={`dataset-header-${localValue}`}
    >
      {fillMockColumn ? (
        isParentIntegrationNode(fillMockColumn.integrationNode) ? (
          <Icon
            color="text-gray-400"
            icon={
              isLoopIntegrationNode(fillMockColumn.integrationNode)
                ? faRotateRight
                : faDiagramNext
            }
            padding={false}
            size="2xs"
          />
        ) : (
          <ProviderIcon
            mediaKey={fillMockColumn.integrationNode.mediaKey}
            provider={fillMockColumn.integrationNode.provider as ProviderT}
            size="sm"
          />
        )
      ) : (
        <Icon
          color="text-gray-400"
          icon={getIcon(type)}
          padding={false}
          size="2xs"
        />
      )}

      <Tooltip
        action={{
          icon: faCopy,
          onMouseDownCapture: () => {
            copyTextToClipboard(children);
          },
        }}
        placement="top"
        title={children}
        asChild
      >
        <input
          ref={editorRef}
          className={twJoin(
            "w-full bg-transparent outline-none [&:not(:focus)]:text-ellipsis",
            (readonly || disabled) && "cursor-default",
            disabled && "text-gray-500",
          )}
          data-loc="dataset-table-header-cell"
          readOnly={readonly || disabled}
          spellCheck={false}
          value={localValue}
          onBlur={() => {
            onRename(localValue);
          }}
          onChange={(e) => setLocalValue(e.target.value)}
        />
      </Tooltip>
      <div className="ml-auto flex items-center">
        {!readonly && (
          <ColumnMenu
            cellId={cellId}
            columnDisabled={disabled}
            context={context}
            fillMockColumn={fillMockColumn}
            input={input}
            mock={mock}
            output={output}
            typeChange={typeChange}
            visible={isMenuVisible}
            onAdd={onColumnAdd}
            onAddAdditionalColumn={onAddAdditionalColumn}
            onAddSubMocks={onAddSubMocks}
            onDelete={onDelete}
          />
        )}
        {warning && (
          <Tooltip align="center" placement="right" {...warning}>
            <Icon color="text-orange-500" icon={faWarning} size="2xs" />
          </Tooltip>
        )}
      </div>
    </HeaderCell>
  );
};

type SubGroupHeaderProps = {
  children: string;
  cellId: CellId;
  mock: {
    mockFields: MockColumn[];
    integrationNodesExist: boolean;
  };
  onDelete: () => void;
  onColumnAdd: (name: string, group: DatasetColumnGroups) => void;
  onDeleteSubMocks: () => void;
  onAddAdditionalColumn: () => void;
  onRename: (newName: string) => void;
  output: { outputFields: string[]; schemaIsEmpty: boolean };
  input: { inputFields: string[]; schemaIsEmpty: boolean };
  readonly: boolean;
  warning?: Pick<TooltipContentProps, "title" | "body" | "action">;
  disabled?: boolean;
  integrationNode: DatasetIntegrationNode | null;
  desiredType: DesiredType;
};

export const SubGroupHeader: React.FC<SubGroupHeaderProps> = ({
  children,
  cellId,
  integrationNode,
  onDelete,
  onDeleteSubMocks,
  readonly,
  onColumnAdd,
  onRename,
  warning,
  mock,
  input,
  output,
  disabled,
  desiredType,
  onAddAdditionalColumn,
}) => {
  const [_, columnId] = parseCellId(cellId);
  const editorRef = useRef<HTMLInputElement>(null);
  const cellRef = useRef<HTMLDivElement>(null);
  const isHovered = useHover(cellRef);
  const isColumnHovered = useIsColumnHoveredLike(columnId);
  const selected = useIsSelectedCell(cellId);
  const editing = useIsEditingCell(cellId);

  const isMenuVisible = Boolean(
    isHovered || isColumnHovered || selected || editing,
  );

  const [localValue, setLocalValue] = React.useState(children);

  useEffect(() => {
    if (editing && editorRef.current) {
      editorRef.current.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (selected && !editing && cellRef.current) {
      cellRef.current.focus();
      cellRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
        block: "nearest",
      });
    }
  }, [selected, editing]);

  useEffect(() => {
    setLocalValue(children);
  }, [children]);

  return (
    <HeaderCell
      ref={cellRef}
      className={twJoin(
        "group gap-x-1.5 border-b border-gray-100",
        disabled
          ? "bg-gray-50 text-gray-500"
          : warning
            ? "bg-orange-50"
            : "bg-white",
        selected && "outline outline-1 outline-indigo-500",
      )}
      dataLoc={`dataset-header-${localValue}`}
    >
      <Icon
        color="text-gray-400"
        icon={
          integrationNode
            ? integrationNode.provider === NODE_TYPE.LOOP_NODE
              ? faRotateRight
              : faDiagramNext
            : getIcon(desiredType)
        }
        padding={false}
        size="2xs"
      />
      <Tooltip
        action={{
          icon: faCopy,
          onMouseDownCapture: () => {
            copyTextToClipboard(children);
          },
        }}
        placement="top"
        title={children}
        asChild
      >
        <input
          ref={editorRef}
          className={twJoin(
            "w-full bg-transparent outline-none [&:not(:focus)]:text-ellipsis",
            (readonly || disabled) && "cursor-default",
            disabled && "text-gray-500",
          )}
          data-loc="dataset-table-header-cell"
          readOnly={readonly || disabled}
          spellCheck={false}
          value={localValue}
          onBlur={() => {
            onRename(localValue);
          }}
          onChange={(e) => setLocalValue(e.target.value)}
        />
      </Tooltip>
      <div className="ml-auto flex items-center">
        {!readonly && (
          <ColumnMenu
            cellId={cellId}
            columnDisabled={disabled}
            context="authoring"
            fillMockColumn={integrationNode ? { integrationNode } : undefined}
            input={input}
            mock={mock}
            output={output}
            visible={isMenuVisible}
            onAdd={onColumnAdd}
            onAddAdditionalColumn={onAddAdditionalColumn}
            onDelete={onDelete}
            onDeleteSubMocks={onDeleteSubMocks}
          />
        )}
        {warning && (
          <Tooltip align="center" placement="right" {...warning}>
            <Icon color="text-orange-500" icon={faWarning} size="2xs" />
          </Tooltip>
        )}
      </div>
    </HeaderCell>
  );
};

type GroupHeaderProps = {
  children: ReactNode;
  variant: "gray" | "indigo" | "dark-gray" | "green";
};

export const GroupHeader: React.FC<GroupHeaderProps> = ({
  children,
  variant,
}) => (
  <HeaderCell
    className={twJoin(
      variant === "gray" && "bg-gray-50",
      variant === "indigo" && "bg-indigo-50",
      variant === "dark-gray" && "bg-gray-200",
      variant === "green" && "bg-green-50",
    )}
  >
    {children}
  </HeaderCell>
);

const HeaderCell = React.forwardRef<
  HTMLDivElement,
  { children: ReactNode; className?: string; dataLoc?: string }
>(({ children, className, dataLoc }, ref) => (
  <div
    ref={ref}
    className={twJoin(
      "flex w-full items-center p-1.5 text-gray-800 font-inter-medium-12px",
      className,
    )}
    data-loc={dataLoc}
    tabIndex={-1}
  >
    {children}
  </div>
));
