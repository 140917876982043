import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faAsterisk,
  faBracketsSquare,
  faHashtag,
  faText,
  faToggleOff,
  IconDefinition,
  faBracketsCurly,
} from "@fortawesome/pro-regular-svg-icons";

import { SchemaTypesT } from "src/api/flowTypes";
import { TYPE_ICONS } from "src/utils/constants";

export const getIconFromPythonType = (type: string): IconDefinition | null => {
  //Taktile types
  if (["Dict", "ReadOnlyDict"].includes(type)) return faBracketsCurly;
  if (type === "List") return faBracketsSquare;
  if (type === "any") return faAsterisk;

  //Primitives
  if (type === "dict") return faBracketsCurly;
  if (["list", "tuple", "range"].includes(type)) return faBracketsSquare;
  if (type === "str") return faText;
  if (["int", "float", "complex"].includes(type)) return faHashtag;
  if (type === "bool") return faToggleOff;

  // Unhandled primitives
  /*Set Types:	set, frozenset
    Binary Types:	bytes, bytearray, memoryview
    None Type:	NoneType */

  return null;
};

export const SCHEMA_TYPE_ICONS: Record<SchemaTypesT, IconProp> = {
  ...TYPE_ICONS,
  "datetime-str": TYPE_ICONS["datetime"],
  "date-str": TYPE_ICONS["date"],
};
