import { maxBy } from "lodash";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { FlowT } from "src/api/flowTypes";
import { useFlow } from "src/api/queries";
import { FlowPageParamsT, getUrlToAuthoringPage } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

const findLatestVersion = (flow: FlowT) =>
  maxBy(flow.versions, "updated_at") || flow.versions[0];

export const NavigateToFlowVersion: React.FC = () => {
  const { flow_id, orgId, wsId } = useParamsDecode<FlowPageParamsT>();
  const [searchParams] = useSearchParams();
  const { data: flow, isSuccess, isError } = useFlow(flow_id);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      const versionId = findLatestVersion(flow).id;
      const page = getUrlToAuthoringPage(orgId, wsId, flow_id, versionId);

      navigate({ pathname: page, search: `?${searchParams.toString()}` });
    }

    if (isError) {
      // 401 and 403 handled by middleware
      // this redirect will happen only in all other cases
      navigate({
        pathname: "/error",
        search: `?${new URLSearchParams({
          error: "An error occured while loading flow data",
        }).toString()}`,
      });
    }
  }, [flow, orgId, wsId, flow_id, isSuccess, isError, searchParams, navigate]);

  return null;
};
