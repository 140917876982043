import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import {
  DropDown,
  DropDownElementT,
  RenderValueFn,
} from "src/base-components/DropDown";
import { Icon } from "src/base-components/Icon";
import {
  CustomConnectionForm,
  HttpVerbs,
} from "src/customConnectionNode/types";

const VerbElements: DropDownElementT<string>[] = HttpVerbs.map(
  (verb): DropDownElementT<string> => ({
    key: verb,
    value: verb.toUpperCase(),
  }),
);

type PropsT = { immutable: boolean };

const renderValue: RenderValueFn<string> = ({ value }) => (
  <div
    className="font-fira-code w-[5.4rem] px-4 py-2 text-gray-800"
    data-loc={`cc-verb-${value}`}
  >
    {value}
  </div>
);

const renderButtonValue = (value?: string) => (
  <div className="flex h-8 flex-grow items-center justify-between">
    <div className="font-fira-code mr-0.5 w-13 grow text-left text-gray-800">
      {value}
    </div>
    <Icon icon={faChevronDown} size="2xs" />
  </div>
);

export const VerbDropDown: React.FC<PropsT> = ({ immutable }) => {
  const formProps = useFormContext<CustomConnectionForm>();

  return (
    <Controller
      control={formProps.control}
      name="verb"
      render={(props) => (
        <DropDown
          buttonClassName={twJoin(
            "border-0 focus:outline-none",
            "flex items-center rounded-none rounded-l-lg py-1 pl-3 pr-2",
            "focus:border-0 focus:ring-0",
          )}
          buttonDataLoc="verb-dropdown-button"
          disabled={immutable}
          elements={VerbElements}
          renderButtonValue={renderButtonValue}
          renderValue={renderValue}
          selectedKey={props.field.value}
          onSelect={(selected) => props.field.onChange(selected)}
        />
      )}
    />
  );
};
