import {
  faArrowDownToLine,
  faCircleNotch,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";

import { useAllOrganizationUsers } from "src/api/taktile/queries";
import { useWorkspaceApiKeys } from "src/apiKeys/api/queries";
import { PAGE_SIZE } from "src/auditLogs/AuditLogsPage";
import { AuditEvent } from "src/auditLogs/queries";
import { downloadEventAsCSV } from "src/auditLogs/utils";
import { ColumnFilter } from "src/base-components/ColumnFilter";
import { Icon } from "src/base-components/Icon";
import { TableComp as Table } from "src/base-components/Table";
import { Tooltip } from "src/base-components/Tooltip";
import { CellWrapper, ColumnHeader } from "src/jobs/common/TableElements";
import { formatDate } from "src/utils/datetime";
import { useParamsDecode } from "src/utils/useParamsDecode";

const helper = createColumnHelper<AuditEvent>();

type AuditLogActor = {
  id: string;
  name: string;
  type: "user" | "api_key";
};

type GetColumnsArgs = {
  actors: AuditLogActor[] | null;
  onActorSelect: (id: string | null) => void;
  selectedActorId: string | null;
};

const getColumns = ({
  actors,
  selectedActorId,
  onActorSelect,
}: GetColumnsArgs) => {
  return [
    helper.accessor("created_at", {
      header: () => <ColumnHeader>Time</ColumnHeader>,
      cell: (info) => (
        <CellWrapper>
          {formatDate(info.getValue(), "MMM d, yyyy 'at' h:mmaaa")}
        </CellWrapper>
      ),
    }),
    helper.accessor("actor_type", {
      header: () => <ColumnHeader>Actor type </ColumnHeader>,
      cell: (info) => (
        <CellWrapper>
          {info.getValue() === "user" ? "User" : "API key"}
        </CellWrapper>
      ),
    }),
    helper.accessor("actor_id", {
      header: () => <ColumnHeader>Actor ID</ColumnHeader>,
      cell: (info) => {
        return <CellWrapper>{info.getValue()}</CellWrapper>;
      },
    }),
    helper.accessor("actor_name", {
      header: () => (
        <ColumnHeader>
          <span>Actor name</span>
          <span className="ml-1">
            {actors ? (
              <ColumnFilter
                description="Filter by Actor name"
                elements={actors.map((actor) => ({
                  key: actor.id,
                  value: actor.name,
                }))}
                isFiltering={selectedActorId !== null}
                placement="bottomLeft"
                selected={selectedActorId ?? undefined}
                onResetRequest={() => onActorSelect(null)}
                onSelect={onActorSelect}
              />
            ) : (
              <Icon icon={faCircleNotch} size="xs" spin />
            )}
          </span>
        </ColumnHeader>
      ),
      cell: (info) => {
        return <CellWrapper>{info.getValue()}</CellWrapper>;
      },
    }),

    helper.accessor("entity_type", {
      header: () => <ColumnHeader>Entity type</ColumnHeader>,
      cell: (info) => {
        return <CellWrapper>{info.getValue()}</CellWrapper>;
      },
    }),
    helper.accessor("entity_id", {
      header: () => <ColumnHeader>Entity ID</ColumnHeader>,
      cell: (info) => {
        return <CellWrapper>{info.getValue()}</CellWrapper>;
      },
    }),
    helper.accessor("auth_phase", {
      header: () => <ColumnHeader>Auth phase</ColumnHeader>,
      cell: (info) => {
        return <CellWrapper>{info.getValue()}</CellWrapper>;
      },
    }),
    helper.accessor("action", {
      header: () => <ColumnHeader>Event</ColumnHeader>,
      cell: (info) => {
        const tooltipBody = (
          <div className="mt-2 min-w-[268px] space-y-4">
            {info.row.original.details &&
              Object.entries(info.row.original.details).map(([key, value]) => (
                <div key={key}>
                  <p className="font-inter-semibold-13px">{key}</p>
                  <p className="text-gray-300 font-inter-normal-12px">
                    {value !== undefined && typeof value === "object"
                      ? JSON.stringify(value)
                      : String(value)}
                  </p>
                </div>
              ))}
            <button
              className="underline font-inter-semibold-13px"
              onClick={() => downloadEventAsCSV(info.row.original)}
            >
              Download event
            </button>
          </div>
        );
        return (
          <CellWrapper>
            <div className="flex flex-1 items-center">
              <span>{info.getValue()}</span>
              <div className="ml-auto flex opacity-0 group-hover:opacity-100">
                <Tooltip
                  body={tooltipBody}
                  placement="top"
                  title="Event details"
                >
                  <Icon
                    color="text-gray-500"
                    cursorType="default"
                    icon={faQuestionCircle}
                    size="sm"
                  />
                </Tooltip>
                <Icon
                  color="text-gray-500"
                  icon={faArrowDownToLine}
                  size="sm"
                  onClick={() => downloadEventAsCSV(info.row.original)}
                />
              </div>
            </div>
          </CellWrapper>
        );
      },
    }),
  ];
};

export const AuditLogsTable: React.FC<
  {
    data: AuditEvent[];
    isLoading: boolean;
    onScrolledToTheBottom: () => void;
    isFetchingNextPage: boolean;
  } & Omit<GetColumnsArgs, "actors">
> = ({
  data,
  isLoading,
  onScrolledToTheBottom,
  isFetchingNextPage,
  ...getColumnsArgs
}) => {
  const { orgId, wsId } = useParamsDecode<{ orgId: string; wsId: string }>();

  const { data: users } = useAllOrganizationUsers(orgId);
  const { data: apiKeys } = useWorkspaceApiKeys(orgId, wsId);

  const userActors: AuditLogActor[] | null =
    users?.pages.flatMap((page) =>
      page.map(({ id, email }) => ({ id, name: email ?? "", type: "user" })),
    ) ?? null;
  const apiKeyActors: AuditLogActor[] | null =
    apiKeys?.map(({ id, name }) => ({ id, name, type: "api_key" })) ?? null;

  const actors =
    userActors && apiKeyActors
      ? [...userActors, ...apiKeyActors].sort((a, b) =>
          a.name.localeCompare(b.name),
        )
      : null;

  return (
    <Table
      columns={getColumns({ ...getColumnsArgs, actors })}
      data={data}
      frameClassName="w-full px-3 pb-2"
      getRowId={(row) => row.id}
      isFetchingNextPage={isFetchingNextPage}
      isLoading={isLoading}
      loadingRowsCount={PAGE_SIZE}
      onScrolledToTheBottom={onScrolledToTheBottom}
    />
  );
};
