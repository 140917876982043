import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import { chartsApi } from "src/api/endpoints";
import {
  Chart,
  ChartCreateRequest,
  ChartDimensionReduceEnum,
  ChartMark,
  ChartPatch,
  ChartResourceType,
} from "src/clients/flow-api";
import { NODE_TYPE } from "src/constants/NodeTypes";

const chartsKeys = {
  all: (type: ChartResourceType, resourceId: string) => [
    "charts",
    type,
    resourceId,
  ],
};

const useCharts = (type: ChartResourceType, resourceId: string) => {
  return useQuery({
    queryKey: chartsKeys.all(type, resourceId),
    queryFn: async () =>
      (await chartsApi.getChartsApiV1ChartsGet(type, resourceId)).data,
  });
};

export const useFlowCharts = (flowId: string) =>
  useCharts(ChartResourceType.FLOW, flowId);

export const useNodeCharts = (nodeId: string) =>
  useCharts(ChartResourceType.NODE, nodeId);

const useReorderCharts = (type: ChartResourceType, resourceId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (chartIds: string[]) =>
      (
        await chartsApi.reorderChartsApiV1ChartsOrderPatch({
          resource_type: type,
          resource_id: resourceId,
          new_order: chartIds,
        })
      ).data,
    onSettled: () => {
      queryClient.invalidateQueries(chartsKeys.all(type, resourceId));
    },
  });
};

export const useReorderFlowCharts = (flowId: string) => {
  return useReorderCharts(ChartResourceType.FLOW, flowId);
};

export const useReorderNodeCharts = (nodeId: string) => {
  return useReorderCharts(ChartResourceType.NODE, nodeId);
};

const useCreateChart = (type: ChartResourceType) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (chart: Omit<ChartCreateRequest, "resource_type">) => {
      return chartsApi.createChartApiV1ChartsPost({
        ...chart,
        resource_type: type,
        resource_id: chart.resource_id,
      });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(
        chartsKeys.all(type, variables.resource_id),
      );
    },
  });
};

export const useCreateFlowChart = () => {
  return useCreateChart(ChartResourceType.FLOW);
};

export const useCreateNodeChart = () => {
  return useCreateChart(ChartResourceType.NODE);
};

const usePatchChart = (type: ChartResourceType, resourceId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      chartId,
      chart,
      etag,
    }: {
      chartId: string;
      chart: ChartPatch;
      etag?: string;
    }) => chartsApi.patchChartByIdApiV1ChartsIdPatch(chartId, chart, etag),
    onMutate: async ({ chartId, chart }) => {
      await queryClient.cancelQueries(chartsKeys.all(type, resourceId));
      const prevCharts = queryClient.getQueryData<Chart[]>(
        chartsKeys.all(type, resourceId),
      );
      queryClient.setQueryData(
        chartsKeys.all(type, resourceId),
        produce(prevCharts, (draft) => {
          if (draft) {
            const chartToUpdate = draft.find((c) => c.id === chartId);
            if (chartToUpdate) {
              chartToUpdate.title = chart.title ?? chartToUpdate.title;
              chartToUpdate.description =
                chart.description ?? chartToUpdate.description;
              chartToUpdate.mark = chart.mark ?? chartToUpdate.mark;
              chartToUpdate.dimensions =
                chart.dimensions ?? chartToUpdate.dimensions;
            }
          }
        }),
      );
      return { prevCharts };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        chartsKeys.all(type, resourceId),
        context?.prevCharts,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(chartsKeys.all(type, resourceId));
    },
  });
};

export const usePatchFlowChart = (flowId: string) => {
  return usePatchChart(ChartResourceType.FLOW, flowId);
};

export const usePatchNodeChart = (nodeId: string) => {
  return usePatchChart(ChartResourceType.NODE, nodeId);
};

const useDeleteChart = (type: ChartResourceType) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      chartId,
      resourceId: _,
    }: {
      chartId: string;
      resourceId: string;
    }) => chartsApi.deleteChartByIdApiV1ChartsIdDelete(chartId),
    onMutate: async ({ chartId, resourceId }) => {
      await queryClient.cancelQueries(chartsKeys.all(type, resourceId));
      const prevCharts = queryClient.getQueryData<Chart[]>(
        chartsKeys.all(type, resourceId),
      );
      queryClient.setQueryData(
        chartsKeys.all(type, resourceId),
        prevCharts?.filter((c) => c.id !== chartId),
      );
      return { prevCharts };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        chartsKeys.all(type, variables.resourceId),
        context?.prevCharts,
      );
    },
    onSettled: (_, error, variables) => {
      queryClient.invalidateQueries(chartsKeys.all(type, variables.resourceId));
    },
  });
};

export const useDeleteFlowChart = () => {
  return useDeleteChart(ChartResourceType.FLOW);
};

export const useDeleteNodeChart = () => {
  return useDeleteChart(ChartResourceType.NODE);
};

const DEFAULT_CHART_DIMENSIONS = {
  x: {
    value: undefined,
    reduce: ChartDimensionReduceEnum.COUNT,
  },
  y: {
    value: "meta.case_name",
    reduce: ChartDimensionReduceEnum.AUTO,
  },
  color: {
    value: undefined,
    reduce: undefined,
  },
  size: {
    value: undefined,
    reduce: undefined,
  },
  fx: {
    value: undefined,
    reduce: undefined,
  },
  fy: {
    value: undefined,
    reduce: undefined,
  },
};

const DEFAULT_SPLIT_NODE_CHART = {
  mark: ChartMark.BAR,
  title: "Rows per branch split",
  description: "",
  dimensions: DEFAULT_CHART_DIMENSIONS,
};

const DEFAULT_RULE_NODE_CHART = {
  mark: ChartMark.BAR,
  title: "Rows per rule",
  description: "",
  dimensions: DEFAULT_CHART_DIMENSIONS,
};

const DEFAULT_DECISION_TABLE_CHART = {
  mark: ChartMark.BAR,
  title: "Rows per case",
  description: "",
  dimensions: DEFAULT_CHART_DIMENSIONS,
};

export const useCreateDefaultNodeChart = () => {
  const { mutateAsync: createNodeChart } = useCreateNodeChart();

  return (nodeType: NODE_TYPE, nodeId: string) => {
    if (nodeType === NODE_TYPE.SPLIT_NODE_V2) {
      createNodeChart({
        ...DEFAULT_SPLIT_NODE_CHART,
        resource_id: nodeId,
      });
    }

    if (nodeType === NODE_TYPE.RULE_NODE_V2) {
      createNodeChart({
        ...DEFAULT_RULE_NODE_CHART,
        resource_id: nodeId,
      });
    }

    if (nodeType === NODE_TYPE.DECISION_TABLE_NODE) {
      createNodeChart({
        ...DEFAULT_DECISION_TABLE_CHART,
        resource_id: nodeId,
      });
    }
  };
};
