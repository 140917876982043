import { Button } from "src/base-components/Button";
import { Modal } from "src/design-system/Modal";
import { useDeactivateJob } from "src/jobs/api/queries";
import { Job } from "src/jobs/types";
import { useFlowContext } from "src/router/routerContextHooks";
import * as logger from "src/utils/logger";

type DeactivateJobModalProps = {
  isOpen: boolean;
  onClose: () => void;
  job?: Job;
};

export const DeactivateJobModal: React.FC<DeactivateJobModalProps> = ({
  isOpen,
  onClose,
  job,
}) => {
  const { workspace, flow } = useFlowContext();
  const jobDeactivateMutation = useDeactivateJob(workspace.base_url!, flow.id);

  const deactivateJob = async () => {
    try {
      if (!job) return;
      await jobDeactivateMutation.mutateAsync(job.id);
      onClose();
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>Are you sure?</Modal.Header>
      <Modal.Content>
        <div className="flex flex-col gap-y-2.5 text-gray-500 font-inter-normal-13px">
          <p>
            Are you sure you want to deactivate this Job? This will pause all
            future scheduled runs of this Job.
          </p>
          <p>You can always activate this version later.</p>
        </div>
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          // TODO: Make button work on Enter
          <Button
            dataLoc="Deactivate-job"
            htmlType="submit"
            loading={jobDeactivateMutation.isLoading}
            variant="primary"
            onClick={deactivateJob}
          >
            Deactivate Job
          </Button>
        }
      />
    </Modal>
  );
};
