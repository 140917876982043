import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { FormItem } from "src/base-components/FormItem";
import { groupClassName } from "src/integrationNode/editorComponents/InputMappings";
import { MultiSelectorCheckbox } from "src/integrationNode/editorComponents/MulitSelectorCheckbox";
import { InputMappingsT, MultiSelectorT } from "src/integrationNode/types";

type Props = {
  multiSelector: MultiSelectorT;
  multiSelectorName: string;
  formProps: UseFormReturn<InputMappingsT>;
  immutable: boolean;
};

export const InputMultiSelector: React.FC<Props> = ({
  multiSelector,
  multiSelectorName,
  formProps,
  immutable,
}) => {
  return (
    <div className={groupClassName}>
      <p className="mb-2 text-gray-800 font-inter-medium-12px">
        {multiSelector.displayName}
        {multiSelector.hint && <FormItem.Help body={multiSelector.hint} />}
      </p>
      <Controller
        control={formProps.control}
        name={`multiselectors.${multiSelectorName}.selected`}
        render={(props) => (
          // By mapping over indices we add filler divs into the flex box
          // For now this rendering only works for 4 to 6 options.
          <>
            <div className="flex w-full">
              {[0, 1, 2].map((index) => (
                <div key={index} className="flex-1">
                  {multiSelector.options[index] && (
                    <MultiSelectorCheckbox
                      controllerFieldProps={props.field}
                      immutable={immutable}
                      option={multiSelector.options[index]}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="flex w-full">
              {[3, 4, 5].map((index) => (
                <div key={index} className="flex-1">
                  {multiSelector.options[index] && (
                    <MultiSelectorCheckbox
                      controllerFieldProps={props.field}
                      immutable={immutable}
                      option={multiSelector.options[index]}
                    />
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      />
    </div>
  );
};
