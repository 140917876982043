import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { PopoverButton } from "@headlessui2/react";
import { map, isEmpty } from "lodash";

import { Button } from "src/base-components/Button";
import { CustomPopover } from "src/base-components/Popover";
import {
  InputMappingGroupT,
  InputMappingListT,
} from "src/integrationNode/types";

export const AddPopoverRow: React.FC<{
  title: string;
  provider: string;
  description?: string;
  handleAdd: () => void;
}> = ({ title, provider, description, handleAdd }) => {
  return (
    <PopoverButton
      as="button"
      className="h-12 px-4 py-2.5 hover:bg-gray-100"
      data-loc={`add-${provider}-${title.toLowerCase()}`}
      onClick={handleAdd}
    >
      <p className="text-left text-gray-800 font-inter-normal-13px">{title}</p>
      {description && (
        <p className="text-left text-gray-600 font-inter-normal-12px">
          {description}
        </p>
      )}
    </PopoverButton>
  );
};

export const AddOptionalField: React.FC<{
  label: string;
  provider: string;
  groupData?: { [key: string]: InputMappingGroupT };
  listData?: { [listName: string]: InputMappingListT };
  immutable: boolean;
  append: (type: "group" | "list", name: string) => void;
}> = ({ label, provider, groupData, listData, immutable, append }) => {
  const noAddableLists = isEmpty(listData);
  const noAddableGroups = Object.values(groupData || {}).every(
    (group) => !isEmpty(group.elements),
  );
  const isAddButtonDisabled = immutable || (noAddableLists && noAddableGroups);
  return (
    <div className="flex flex-row">
      <CustomPopover
        button={
          <Button
            dataLoc={`add-${provider}-element`}
            disabled={isAddButtonDisabled}
            iconLeft={faPlus}
            iconRight={faChevronDown}
            size="sm"
            variant="secondary"
          >
            {label}
          </Button>
        }
        className="max-h-80"
        placement="bottom-start"
      >
        <div className="flex w-66 flex-col py-2">
          {map(groupData, (group, groupName) => {
            if (!group.elements) {
              return (
                <AddPopoverRow
                  key={groupName}
                  handleAdd={() => append("group", groupName)}
                  provider={provider}
                  title={group.displayName || groupName}
                />
              );
            }
          })}
          {map(listData, (list, listName) => {
            return (
              <AddPopoverRow
                key={listName}
                handleAdd={() => append("list", listName)}
                provider={provider}
                title={list.displayName || listName}
              />
            );
          })}
        </div>
      </CustomPopover>
    </div>
  );
};
