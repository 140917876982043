import { FlowVersionStatus } from "src/clients/flow-api";
import { useCapabilities } from "src/hooks/useCapabilities";
import { useAuthoringContext } from "src/router/routerContextHooks";

/**
 * @returns whether the current version can be edited by checking permissions and version status.
 */
export const useCanAuthoringEditFlowVersion = () => {
  const { flowVersions } = useCapabilities();
  const { version, isRevision } = useAuthoringContext();

  return (
    flowVersions.canEdit &&
    version.status !== FlowVersionStatus.PUBLISHED &&
    version.status !== FlowVersionStatus.ARCHIVED &&
    !isRevision
  );
};
