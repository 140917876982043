import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle, faWarning } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";

export type InformationPillType =
  | "error"
  | "warning"
  | "info"
  | "success"
  | "neutral";

type InformationPillPropsT = {
  type: InformationPillType;
  children: React.ReactNode;
  className?: string;
  action?: {
    text: React.ReactNode;
    onClick: () => void;
  };
  fullWidth?: boolean;
  icon?: IconProp;
  dataLoc?: string;
  iconSpin?: boolean;
};

export const InformationPill: React.FC<InformationPillPropsT> = ({
  type,
  children,
  className,
  action,
  fullWidth,
  icon,
  dataLoc,
  iconSpin,
}) => {
  return (
    <div
      className={twJoin(
        "flex items-start justify-between rounded-lg border px-3 py-1.5 text-xs font-inter-normal-12px",
        type === "error" && "border-red-200 bg-red-100 text-red-600",
        (type === "info" || type === "warning") &&
          "border-orange-200 bg-orange-50 text-orange-600",
        type === "success" && "border-indigo-200 bg-indigo-50 text-indigo-600",
        fullWidth && "rounded-none border-x-0",
        type === "neutral" && "border-gray-200 bg-gray-50 text-gray-600",
        className,
      )}
      data-loc={dataLoc ?? `info-pill-${type}`}
    >
      <div className="flex max-w-full items-start">
        <div className="mr-1">
          <Icon
            color={twJoin(
              type === "error" && "text-red-400",
              type === "info" && "text-orange-400",
              type === "warning" && "text-orange-500",
              type === "success" && "text-indigo-400",
              type === "neutral" && "text-gray-500",
            )}
            icon={
              icon ??
              (type === "error" || type === "warning"
                ? faWarning
                : faInfoCircle)
            }
            size="xs"
            spin={iconSpin}
          />
        </div>
        {children}
      </div>
      {action && (
        <button
          className="ml-4 whitespace-nowrap underline font-inter-medium-13px"
          onClick={action.onClick}
        >
          {action.text}
        </button>
      )}
    </div>
  );
};
