import { ChartFormModal } from "src/analytics/ChartFormModal";
import { ComparativeAnalyticsData } from "src/analytics/api";
import { useCreateFlowChart, usePatchFlowChart } from "src/analytics/queries";
import { ChartState } from "src/analytics/types";
import { toastFailure } from "src/base-components/Toast/utils";
import { Chart } from "src/clients/flow-api";
import { useAuthoringContext } from "src/router/routerContextHooks";
import * as logger from "src/utils/logger";
import { isPreconditionError } from "src/utils/predicates";
import { toastError } from "src/utils/toastError";

type FlowChartModalProps = {
  isOpen: boolean;
  onClose: () => void;
  analytics?: ComparativeAnalyticsData;
  chart?: Chart;
  afterLeave?: () => void;
};

export const FlowChartModal: React.FC<FlowChartModalProps> = ({
  isOpen,
  onClose,
  analytics,
  chart,
  afterLeave,
}) => {
  const isEditing = !!chart;
  const { flow } = useAuthoringContext();
  const { mutateAsync: createChart } = useCreateFlowChart();
  const { mutateAsync: updateChart } = usePatchFlowChart(flow.id);

  const onSubmit = async (chartData: ChartState) => {
    try {
      if (isEditing) {
        await updateChart({
          chartId: chart.id,
          chart: chartData,
          etag: chart.etag,
        });
      } else {
        await createChart({
          ...chartData,
          resource_id: flow.id,
        });
      }
      onClose();
    } catch (e) {
      if (isPreconditionError(e)) {
        toastFailure({
          title: `Failed to save the chart`,
          description:
            "Someone made changes while you were editing. Please reload and try again.",
        });
        return;
      }
      logger.error(e);
      toastError(`Failed to ${isEditing ? "save" : "create"} chart`);
    }
  };

  return (
    <ChartFormModal
      afterLeave={afterLeave}
      analytics={analytics?.type === "success" ? analytics : undefined}
      chart={chart}
      isMultiversionTestRun={
        analytics?.type === "success" &&
        Object.keys(analytics.summary).length > 1
      }
      isOpen={isOpen}
      isFlowLevelAnalytics
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
