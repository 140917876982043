import ExperianIcon from "src/assets/ExperianIcon.svg?react";
import { getDefaultExperianGermanyBusinessInformationGateway } from "src/connections/connectionConfigs/experianGermany/experianGermanyResources";
import { ExperianGermanyTemplate } from "src/connections/connectionConfigs/experianGermany/experianGermanyTemplate";
import { ConnectionConfig } from "src/connections/connectionConfigs/types";

export const EXPERIAN_GERMANY_CONFIG: ConnectionConfig<"experian_germany"> = {
  icon: ExperianIcon,
  description:
    "Provider of consumer credit, business information, and marketing services in Germany",
  template: ExperianGermanyTemplate,
  name: "Experian (Germany)",
  resources: {
    experian_germany_business_information_gateway: {
      externalLink:
        "https://www.notion.so/taktile/Business-Information-Gateway-e08618eb524b4b0eb5ba580655752220?pvs=4",
      integrationResource: getDefaultExperianGermanyBusinessInformationGateway,
    },
  },
};
