import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBold,
  faCode,
  faItalic,
  faLink,
  faListUl,
  faListOl,
  faStrikethrough,
  faHeading,
} from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";

const ToolbarItem: React.FC<{
  icon: IconProp;
  type: string;
  value?: string;
  faded: boolean;
}> = ({ icon, type, value, faded }) => (
  <li>
    <button className={twJoin(type, "rounded")} value={value}>
      <Icon
        color={faded ? "text-gray-300" : "text-gray-500"}
        icon={icon}
        size="xs"
      />
    </button>
  </li>
);

type Props = {
  faded?: boolean;
  exclude: (
    | "heading"
    | "bold"
    | "italic"
    | "strikethrough"
    | "link"
    | "listUl"
    | "listOl"
    | "code"
  )[];
};

export const Toolbar = React.forwardRef<HTMLUListElement, Props>(
  ({ faded = false, exclude }, ref) => {
    return (
      <ul ref={ref} className="flex items-center gap-x-1">
        {!exclude.includes("heading") && (
          <ToolbarItem
            faded={faded}
            icon={faHeading}
            type="ql-header"
            value="1"
          />
        )}
        {!exclude.includes("bold") && (
          <ToolbarItem faded={faded} icon={faBold} type="ql-bold" />
        )}
        {!exclude.includes("italic") && (
          <ToolbarItem faded={faded} icon={faItalic} type="ql-italic" />
        )}
        {!exclude.includes("strikethrough") && (
          <ToolbarItem faded={faded} icon={faStrikethrough} type="ql-strike" />
        )}
        {!exclude.includes("link") && (
          <ToolbarItem faded={faded} icon={faLink} type="ql-link" />
        )}
        <li className="text-gray-200">|</li>
        {!exclude.includes("listUl") && (
          <ToolbarItem
            faded={faded}
            icon={faListUl}
            type="ql-list"
            value="bullet"
          />
        )}
        {!exclude.includes("listOl") && (
          <ToolbarItem
            faded={faded}
            icon={faListOl}
            type="ql-list"
            value="ordered"
          />
        )}
        {!exclude.includes("code") && (
          <ToolbarItem faded={faded} icon={faCode} type="ql-code" />
        )}
      </ul>
    );
  },
);
