import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { ConnectionT } from "src/api/connectApi/types";
import { DropDown } from "src/base-components/DropDown";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/base-components/Tooltip";
import { EnvironmentDropdownButton } from "src/baseConnectionNode/EnvironmentDropdownButton";
import {
  ConnectionDataSources,
  ConnectionDataSourcesValues,
  EnvironmentsConfigT,
} from "src/baseConnectionNode/types";
import {
  useEnvironmentAvailableConfigs,
  DropdownElement,
  PRODUCTION_OPTION,
} from "src/baseConnectionNode/useEnvironmentAvailableConfigs";

type NodeWithEnvConfigFormT = {
  config: {
    environments_config: EnvironmentsConfigT;
  };
};

type EnvironmentSelectPropsT = {
  title: string;
  description: React.ReactNode;
  options: DropdownElement[];
  disabled?: boolean;
  defaultOption?: string;
  tooltipTitle?: React.ReactNode;
  environment: ConnectionDataSources;

  // Used in order to display Live Testing instead of Production Credentials when this is false
  isSandboxEnabled?: boolean;
};

type EnvironmentConfigPropsT = {
  connection: ConnectionT;
  disabled: boolean;
  type?: "webhook" | "default";
};

const LIVE_CONNECTION_LABEL = "Live Connection";

const OPTIONS_LABEL_MAP: Record<ConnectionDataSourcesValues, string> = {
  [ConnectionDataSourcesValues.mock_data]: "Mock data",
  [ConnectionDataSourcesValues.empty_response]: "Empty response",
  [ConnectionDataSourcesValues.sandbox]: "Sandbox credentials",
  [ConnectionDataSourcesValues.production]: "Production credentials",
};

const getEnvironmentOptionLabel = (
  value: ConnectionDataSourcesValues | undefined,
  isSandboxEnabled: boolean,
) => {
  // When sandbox credentials are not enabled,
  // we want to display Live Testing instead of Production Credentials
  if (!value) return undefined;

  if (!isSandboxEnabled && value === ConnectionDataSourcesValues.production) {
    return LIVE_CONNECTION_LABEL;
  }

  return OPTIONS_LABEL_MAP[value];
};

const EnvironmentSelect: React.FC<EnvironmentSelectPropsT> = ({
  title,
  description,
  options,
  defaultOption,
  environment,
  disabled = false,
  tooltipTitle,
  isSandboxEnabled = false,
}) => {
  const { control, watch, setValue } = useFormContext<NodeWithEnvConfigFormT>();
  const fieldName = `config.environments_config.${environment}` as const;

  const selectedEnvironmentDataSource = watch(fieldName);

  useEffect(() => {
    // This checks whether the currently selected(that can come from the BE)
    // option for this environment
    // is still available in the options list
    // If not, it will set the value to the default one
    const optionStillAvailable = options.find(
      (option) => option.key === selectedEnvironmentDataSource,
    );

    if (!optionStillAvailable) {
      // @ts-ignore
      setValue(fieldName, defaultOption);
    }
  }, [
    options,
    selectedEnvironmentDataSource,
    setValue,
    fieldName,
    defaultOption,
  ]);

  return (
    <div className="mb-4 flex h-full w-full flex-row last:mb-0">
      <div className="flex h-full grow flex-col">
        <h3 className="text-gray-800 font-inter-medium-12px">{title}</h3>
        <span className="text-gray-500 font-inter-normal-12px">
          {description}
        </span>
      </div>
      <div className="max-w-50 grow">
        <Controller
          control={control}
          name={fieldName}
          render={({ field }) => (
            <Tooltip
              action={{
                label: "Read more",
                onClick: () =>
                  window.open(
                    "https://docs.taktile.com/third-party-connections/custom-connection-node/custom-connection#1.4-sandbox-credentials",
                    "_blank",
                  ),
              }}
              activated={disabled && Boolean(tooltipTitle)}
              align="end"
              placement="top"
              title={tooltipTitle}
              triggerAs="div"
              triggerClassName="w-full"
            >
              <DropDown<ConnectionDataSourcesValues>
                dataLoc={`cc-node-${fieldName}`}
                disabled={disabled}
                elements={options}
                itemsClassNames="w-full"
                renderButtonValue={(value) => (
                  <EnvironmentDropdownButton
                    value={getEnvironmentOptionLabel(value, isSandboxEnabled)}
                  />
                )}
                renderValue={({ key, value }) => (
                  <div
                    className={twJoin(
                      "flex items-center px-5 py-4",
                      value === ConnectionDataSourcesValues.mock_data &&
                        "border-b",
                    )}
                    data-loc={`dropdown-item-${key}`}
                  >
                    <span className="text inline-block">
                      {getEnvironmentOptionLabel(value, isSandboxEnabled)}
                    </span>
                  </div>
                )}
                selectedKey={field.value || defaultOption}
                onSelect={(selected) => field.onChange(selected)}
              />
            </Tooltip>
          )}
        />
      </div>
    </div>
  );
};

export const EnvironmentDataSourceConfig: React.FC<EnvironmentConfigPropsT> = ({
  connection,
  disabled,
  type = "default",
}) => {
  const { enable_non_prod_configs, non_prod_env_configs } = connection;
  const allowSandboxCredentials =
    enable_non_prod_configs && Boolean(non_prod_env_configs?.["sandbox"]);

  const { testRunSettings, sandboxSettings } = useEnvironmentAvailableConfigs(
    connection,
    type,
  );

  return (
    <div className="flex flex-col flex-wrap">
      <EnvironmentSelect
        description="Test runs using the button in the UI"
        environment={ConnectionDataSources.authoring_mode_data_source}
        isSandboxEnabled={allowSandboxCredentials}
        title="For test runs, use"
        {...testRunSettings}
        disabled={disabled || testRunSettings.disabled}
      />
      <EnvironmentSelect
        description={
          <>
            API calls using the{" "}
            <Pill size="sm" variant="gray">
              <Pill.Text>/sandbox/decide</Pill.Text>
            </Pill>{" "}
            endpoint
          </>
        }
        environment={ConnectionDataSources.sandbox_mode_data_source}
        isSandboxEnabled={allowSandboxCredentials}
        title="For sandbox API calls, use"
        {...sandboxSettings}
        disabled={disabled || sandboxSettings.disabled}
      />
      {/* The live setting remains the same no matter what */}
      <EnvironmentSelect
        defaultOption={PRODUCTION_OPTION["key"]}
        description={
          <>
            Production API calls using the{" "}
            <Pill size="sm" variant="gray">
              <Pill.Text>/decide</Pill.Text>
            </Pill>{" "}
            endpoint
          </>
        }
        environment={ConnectionDataSources.live}
        isSandboxEnabled={allowSandboxCredentials}
        options={[PRODUCTION_OPTION]}
        title="For live API calls, use"
        tooltipTitle="For live API calls to the /decide endpoint, Taktile always uses production credentials for external calls. If caching is enabled, Taktile will use cached responses for this node."
        disabled
      />
    </div>
  );
};
