import React from "react";
import { twMerge } from "tailwind-merge";

import { CellContent, isReactNode } from "./types";
import { Tooltip } from "src/base-components/Tooltip";
import { cellBaseClassName } from "src/dataTable/ResultCell";
import { speakPythonPrimitive } from "src/utils/speakPython";

type PropsT<T> = {
  cell: CellContent<T> | React.ReactElement;
  className?: string;
  classNameOverrides?: string;
  withTooltip?: boolean;
  suffix?: React.ReactNode;
};

export const RawCell = <T,>({
  cell,
  className = cellBaseClassName,
  classNameOverrides,
  suffix,
  withTooltip = false,
}: PropsT<T>) => {
  const value = isReactNode(cell)
    ? cell
    : speakPythonPrimitive(cell.getValue());
  const Wrapper = withTooltip
    ? ({ children }: { children: React.ReactNode }) => (
        <Tooltip
          activated={value !== undefined && value !== ""}
          placement="top"
          title={value}
          asChild
        >
          {children}
        </Tooltip>
      )
    : React.Fragment;

  return (
    <Wrapper>
      <div
        className={twMerge(
          "flex flex-row items-center justify-between gap-x-0.5 pl-2",
          className,
          classNameOverrides,
        )}
      >
        {Boolean(suffix) || typeof value === "string" ? (
          <>
            <div className="flex-1 truncate">{value}</div>
            {suffix}
          </>
        ) : (
          value
        )}
      </div>
    </Wrapper>
  );
};
