import {
  faArrowDown,
  faChevronDown,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";

import { Button } from "src/base-components/Button";
import { OptionsDropdown } from "src/base-components/OptionsDropdown/OptionsDropdown";
import { OptionsDropdownElement } from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { GetFlowsApiV1FlowsGetOrderByEnum } from "src/clients/flow-api";
import { CreateFlowFromTemplateModal } from "src/flowsOverview/CreateFlowFromTemplateModal";
import { GRID_COLUMNS_CLASS } from "src/flowsOverview/FlowOverview";
import { useOrderFlowsBy } from "src/flowsOverview/v2/useOrderFlowsBy";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";

const ORDER_BY_KEYS_TO_DISPLAY: {
  [key in GetFlowsApiV1FlowsGetOrderByEnum]: string;
} = {
  updated_at_traversed: "Last edited",
  name: "Name",
} as const;

type Props = {
  onCreate?: () => void;
};

export const FlowListHeader: React.FC<Props> = ({ onCreate }) => {
  const [orderFlowsBy, saveOrderFlowsBy] = useOrderFlowsBy();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const orderActionItems: OptionsDropdownElement[] = Object.entries(
    ORDER_BY_KEYS_TO_DISPLAY,
  ).map(([enumKey, value]) => ({
    key: value,
    action: () => {
      saveOrderFlowsBy(enumKey as GetFlowsApiV1FlowsGetOrderByEnum);
    },
  }));
  return (
    <>
      <div className={`mb-6 items-center ${GRID_COLUMNS_CLASS}`}>
        <p className="col-span-1 text-gray-800 font-inter-semibold-16px">
          Decision Flows
        </p>
        <div className="col-span-full flex items-center justify-end gap-x-2 lg:col-start-2 lg:-col-end-1">
          <OptionsDropdown
            elements={[
              { key: "header", header: "Sort by" },
              ...orderActionItems,
            ]}
          >
            <Button
              iconLeft={faArrowDown}
              iconRight={faChevronDown}
              size="base"
              variant="secondary"
            >
              {ORDER_BY_KEYS_TO_DISPLAY[orderFlowsBy]}
            </Button>
          </OptionsDropdown>
          {onCreate && (
            <>
              {isFeatureFlagEnabled(FEATURE_FLAGS.templates) ? (
                <OptionsDropdown
                  elements={[
                    {
                      key: "From scratch",
                      action: () => {
                        onCreate();
                      },
                    },
                    {
                      key: "From template",
                      action: () => {
                        setIsModalOpen(true);
                      },
                    },
                  ]}
                >
                  <Button
                    dataLoc="add-new-flow"
                    iconLeft={faPlus}
                    iconRight={faChevronDown}
                    size="base"
                    variant="primary"
                  >
                    Create Decision Flow
                  </Button>
                </OptionsDropdown>
              ) : (
                <Button
                  dataLoc="add-new-flow"
                  iconLeft={faPlus}
                  size="base"
                  variant="primary"
                  onClick={onCreate}
                >
                  Create Decision Flow
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      <CreateFlowFromTemplateModal
        handleClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />
    </>
  );
};
