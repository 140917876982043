import {
  faPlus,
  faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import React, { useCallback, useMemo } from "react";
import {
  useForm,
  useFieldArray,
  FormProvider,
  Controller,
} from "react-hook-form";

import { Button } from "src/base-components/Button";
import { Card } from "src/base-components/Card";
import { Checkbox } from "src/base-components/Checkbox";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Icon } from "src/base-components/Icon";
import { ScorecardNode, ScorecardNodeDataT } from "src/constants/NodeDataTypes";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import { CheckboxLabel } from "src/scorecardNode/CheckboxLabel";
import { Factor } from "src/scorecardNode/Factor";
import { FactorsBreakdown } from "src/scorecardNode/FactorsBreakdown";
import { getDefaultFactor } from "src/scorecardNode/defaultValues";
import { FormShape } from "src/scorecardNode/types";
import {
  getNewFactorName,
  mapFieldErrorsToScorecardErrors,
} from "src/scorecardNode/utils";
import { convertFieldErrorsBeToFe } from "src/utils/FieldErrorUtils";
import { useSubmitForm } from "src/utils/useSubmitForm";

type PropsT = {
  immutable: boolean;
  selectedNode: ScorecardNode;
} & NodeEditorBaseProps<ScorecardNodeDataT>;

export const ScorecardNodeEditor: React.FC<PropsT> = ({
  selectedNode,
  immutable,
  isReactive,
  onUpdate,
  displayedError,
}) => {
  const formMethods = useForm<FormShape>({
    defaultValues: selectedNode.data,
    ...(isReactive && { values: selectedNode.data }),
  });

  const { fields, append, remove } = useFieldArray({
    name: "factors",
    control: formMethods.control,
    keyName: "fieldId",
  });

  const handleAddFactor = useCallback(
    () => {
      const factors = formMethods.getValues("factors");
      append(getDefaultFactor({ name: getNewFactorName(factors) }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formMethods.getValues, append],
  );

  useSubmitForm({
    onChange: (data: FormShape) => {
      onUpdate({ newData: data });
    },
    disabled: isReactive,
    previousValues: selectedNode.data,
    watch: formMethods.watch,
  });

  const feRunFieldErrors = useMemo(
    () =>
      displayedError?.field_errors &&
      convertFieldErrorsBeToFe(displayedError.field_errors),
    [displayedError?.field_errors],
  );

  const errors = feRunFieldErrors
    ? mapFieldErrorsToScorecardErrors(
        selectedNode.id,
        selectedNode.data,
        feRunFieldErrors,
      )
    : null;

  return (
    <FormProvider {...formMethods}>
      <div className="space-y-4">
        <Card>
          <Card.Content>
            <div className="flex gap-x-4">
              <span className="pt-2 font-inter-normal-12px">
                Assign total score to
              </span>
              <div className="flex grow flex-col gap-y-2">
                <Controller
                  name="assign_field.value"
                  render={({ field }) => (
                    <AutocompleteCodeInput
                      dataLoc="scorecard-assign-field-input"
                      disabled={immutable}
                      error={Boolean(errors?.assignFieldError)}
                      prefix="data."
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <FactorsBreakdown />
                {errors?.totalWeightError && (
                  <div className="flex text-red-600 font-inter-normal-12px">
                    <Icon icon={faTriangleExclamation} />
                    <p>{errors.totalWeightError}</p>
                  </div>
                )}
              </div>
            </div>
            <hr className="border-gray-100" />
            <Controller
              control={formMethods.control}
              name="extract_scores"
              render={({ field }) => (
                <CheckboxLabel label="Also extract individual factor scores">
                  <Checkbox
                    checked={field.value}
                    data-loc="scorecard-extract-factors"
                    disabled={immutable}
                    onChange={field.onChange}
                  />
                </CheckboxLabel>
              )}
            />
            <Controller
              control={formMethods.control}
              name="equal_weights"
              render={({ field }) => (
                <CheckboxLabel label="Assign weight equally across factors">
                  <Checkbox
                    checked={field.value}
                    data-loc="scorecard-assign-weight-equally"
                    disabled={immutable}
                    onChange={field.onChange}
                  />
                </CheckboxLabel>
              )}
            />
          </Card.Content>
        </Card>
        {fields.map((field, index) => (
          <Factor
            key={field.id}
            errors={errors?.factorErrors?.[field.id] ?? null}
            index={index}
            readonly={immutable}
            totalFactors={fields.length}
            onRemove={remove}
          />
        ))}
        <Button
          dataLoc="scorecard-add-factor"
          disabled={immutable}
          iconLeft={faPlus}
          size="sm"
          variant="secondary"
          onClick={handleAddFactor}
        >
          Add factor
        </Button>
      </div>
    </FormProvider>
  );
};
