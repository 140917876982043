import { forEach, map } from "lodash";
import { v4 as uuidV4 } from "uuid";

import { getDefaultResource } from "src/integrationNode/integrationResources/DefaultResources";
import {
  DEFAULT_OUTPUT_BE_NAME,
  InputMappingT,
  IntegrationResourceBET,
  IntegrationResourceT,
  MultiSelectorT,
  SingleMappingBET,
} from "src/integrationNode/types";
import * as logger from "src/utils/logger";

export const integrationResourceBeToFe = (
  reportBE: IntegrationResourceBET,
): IntegrationResourceT => {
  const reportFE = getDefaultResource(
    reportBE.provider_resource,
    reportBE.connection_id,
    reportBE.resource_config_id,
  );

  const setFeInputMappingFields = (
    feMapping: InputMappingT,
    beMapping?: SingleMappingBET,
  ) => {
    if (beMapping) {
      feMapping.id = beMapping?.id;
      feMapping.assignedTo = beMapping.expression;
    }
  };

  //ungrouped
  forEach(reportFE.input.ungrouped, (inputMapping, beName) => {
    setFeInputMappingFields(inputMapping, reportBE.input.singles[beName]);
  });

  //grouped
  forEach(reportFE.input.grouped, (group, groupName) => {
    const beGroup = reportBE.input.groups[groupName];
    if (beGroup) {
      group.elements = group.getDefaultElements();
      forEach(group.elements, (inputMapping, beName) => {
        setFeInputMappingFields(inputMapping, beGroup.singles[beName]);
      });
    }
  });

  //list
  //for each list in the frontend
  forEach(reportFE.input.lists, (list, listName) => {
    list.elements = [];
    const beList = reportBE.input.lists[listName];
    //for each list element in the corresponding backend list,
    //create that element in the frontend list
    forEach(beList, (beListElement) => {
      const newElement = { ...list.getDefaultElement() };
      forEach(newElement, (inputMapping, beName) => {
        setFeInputMappingFields(inputMapping, beListElement.singles[beName]);
      });
      list.elements.push(newElement);
    });
  });

  //multiselect
  forEach(reportFE.input.multiselectors, (mulitSelectInput, beName) => {
    mulitSelectInput.id = reportBE.input.singles[beName].id;
    mulitSelectInput.selected = JSON.parse(
      reportBE.input.singles[beName].expression,
    );
  });

  //outputs
  forEach(reportBE.output, (mappingBE, beName) => {
    if (beName === DEFAULT_OUTPUT_BE_NAME) {
      reportFE.output[DEFAULT_OUTPUT_BE_NAME].id = mappingBE.id;
      reportFE.output[DEFAULT_OUTPUT_BE_NAME].assignedTo = mappingBE.mapped_to;
      reportFE.output[DEFAULT_OUTPUT_BE_NAME].selected = mappingBE.active;
    } else if (reportFE.output.insights[beName]) {
      reportFE.output.insights[beName].id = mappingBE.id;
      reportFE.output.insights[beName].assignedTo = mappingBE.mapped_to;
      reportFE.output.insights[beName].selected = mappingBE.active;
    } else {
      logger.error(`Unknown insight: ${beName}`);
    }
  });

  reportFE.config = {
    // Because this field was newly added we set an empty one if the BE config does not already include it
    tenant_id: {
      id: uuidV4(),
      expression: "",
    },
    timeout: {
      active: false,
      timeout_seconds: "15",
    },
    ...reportBE.config,
  };
  return reportFE;
};

export const inputMappingsFeToBe = (mappingsFe: {
  [key: string]: InputMappingT;
}): { [key: string]: SingleMappingBET } =>
  Object.fromEntries(
    map(mappingsFe, (inputMapping, beName) => [
      beName,
      { id: inputMapping.id, expression: inputMapping.assignedTo },
    ]),
  );

export const multiselectsFeToBe = (multiselectorsFe?: {
  [key: string]: MultiSelectorT;
}): { [key: string]: SingleMappingBET } =>
  Object.fromEntries(
    map(multiselectorsFe, (multiSelectorInput, beName) => [
      beName,
      {
        id: multiSelectorInput.id,
        expression: JSON.stringify(multiSelectorInput.selected),
      },
    ]),
  );

export const integrationResourceFeToBe = (
  resourceFE: IntegrationResourceT,
): IntegrationResourceBET => {
  return {
    provider_resource: resourceFE.providerResource,
    connection_id: resourceFE.connectionId,
    resource_config_id: resourceFE.resourceConfigId,
    input: {
      singles: {
        ...inputMappingsFeToBe(resourceFE.input.ungrouped),
        ...multiselectsFeToBe(resourceFE.input.multiselectors),
      },
      groups: Object.fromEntries(
        map(resourceFE.input.grouped, (group, groupName) => [
          groupName,
          group.elements
            ? {
                singles: inputMappingsFeToBe(group.elements),
                groups: {},
                lists: {},
              }
            : undefined,
        ]).filter(([, value]) => value),
      ),
      lists: Object.fromEntries(
        map(resourceFE.input.lists, (list, listName) => [
          listName,
          list.elements.map((element) => ({
            singles: inputMappingsFeToBe(element),
            groups: {},
            lists: {},
          })),
        ]),
      ),
    },
    output: {
      [DEFAULT_OUTPUT_BE_NAME]: {
        id: resourceFE.output[DEFAULT_OUTPUT_BE_NAME].id,
        mapped_to: resourceFE.output[DEFAULT_OUTPUT_BE_NAME].assignedTo,
        active: resourceFE.output[DEFAULT_OUTPUT_BE_NAME].selected,
      },
      ...Object.fromEntries(
        map(resourceFE.output.insights, (outputMapping, beName) => [
          beName,
          {
            id: outputMapping.id,
            mapped_to: outputMapping.assignedTo,
            active: outputMapping.selected,
          },
        ]),
      ),
    },
    config: resourceFE.config,
  };
};
