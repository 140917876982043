import React from "react";

import { useProviderManifest } from "src/api/connectApi/queries";
import CustomConnectionIcon from "src/assets/CustomConnectionIcon.svg?react";
import { useCurrentWorkspace } from "src/utils/useCurrentWorkspace";

type PropsT = {
  provider: string;
  manifestVersion?: string;
  className?: string;
};

export const ManifestIcon: React.FC<PropsT> = ({
  provider,
  manifestVersion,
  className,
}) => {
  const workspaceData = useCurrentWorkspace();
  const manifest = useProviderManifest(
    workspaceData.workspace?.base_url,
    provider,
    manifestVersion || "latest",
  ).data;

  // If there's no icon specified in the manifest, default to the CC default one
  if (!manifest?.icon_path) {
    return <CustomConnectionIcon className={className} />;
  }

  return (
    <div className={className}>
      <img
        alt={`${provider} Icon`}
        data-loc={`${provider}-icon`}
        src={manifest.icon_path}
      />
    </div>
  );
};
