import { Root } from "@radix-ui/react-accordion";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { useConnection } from "src/api/connectApi/queries";
import { ConnectionT } from "src/api/connectApi/types";
import { useWorkspace } from "src/api/queries";
import { FieldErrorsT } from "src/api/types";
import {
  EditorAccordionItem as AccordionItem,
  accordionRootClassName,
} from "src/base-components/EditorAccordionItem";
import { LoadingView } from "src/base-components/LoadingView";
import { useDiffViewContext } from "src/changeHistory/DiffViewModal/DiffViewContext";
import {
  DatabaseConnectionNode,
  DatabaseConnectionNodeDataT,
} from "src/constants/NodeDataTypes";
import { ConfigPane } from "src/databaseConnectionNode/editorComponents/ConfigPane";
import { QueryEditor } from "src/databaseConnectionNode/editorComponents/QueryEditor";
import { ResponseMapping } from "src/databaseConnectionNode/editorComponents/ResponseMapping";
import { VariablesMapping } from "src/databaseConnectionNode/editorComponents/VariablesMapping";
import { DatabaseConnectionForm } from "src/databaseConnectionNode/types";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import { convertFieldErrorsBeToFe } from "src/utils/FieldErrorUtils";
import { useSubmitForm } from "src/utils/useSubmitForm";

type WrapperPropsT = {
  node: DatabaseConnectionNode;
  workspaceId: string;
} & NodeEditorBaseProps<DatabaseConnectionNodeDataT>;

export const DatabaseConnecionNodeEditor: React.FC<WrapperPropsT> = ({
  immutable,
  node,
  workspaceId,
  displayedError,
  isReactive,
  onUpdate,
}) => {
  const workspace = useWorkspace(workspaceId);
  const connection = useConnection({
    baseUrl: workspace.data?.base_url,
    id: node.data.connectionId,
  });
  return (
    <LoadingView
      queryResult={connection}
      renderUpdated={(connection: ConnectionT) => (
        <DatabaseConnectionNodeEditorInternal
          connection={connection}
          displayedError={displayedError}
          immutable={immutable}
          isReactive={isReactive}
          node={node}
          onUpdate={onUpdate}
        />
      )}
    />
  );
};

type PropsT = {
  connection: ConnectionT;
  immutable: boolean;
  node: DatabaseConnectionNode;
} & NodeEditorBaseProps<DatabaseConnectionNodeDataT>;

const DatabaseConnectionNodeEditorInternal: React.FC<PropsT> = ({
  connection,
  immutable,
  node,
  displayedError,
  isReactive,
  onUpdate,
}) => {
  const { renderedInDiffView } = useDiffViewContext();
  const currentFormValues: DatabaseConnectionForm = {
    variables: node.data.variables,
    query: node.data.query,
    response: node.data.response,
    config: node.data.config,
  };

  const formProps = useForm<DatabaseConnectionForm>({
    defaultValues: currentFormValues,
    ...(isReactive && { values: currentFormValues }),
  });

  useSubmitForm({
    onChange: (data: DatabaseConnectionForm) => {
      onUpdate({ newData: data });
    },
    disabled: isReactive,
    previousValues: currentFormValues,
    watch: formProps.watch,
  });

  const runFieldErrors: FieldErrorsT | undefined = displayedError?.field_errors
    ? convertFieldErrorsBeToFe(displayedError.field_errors)
    : undefined;

  return (
    <FormProvider {...formProps}>
      <form>
        <Root
          className={accordionRootClassName}
          defaultValue={["variables", "query", "response", "config"]}
          type="multiple"
        >
          <AccordionItem
            className="pb-6 pr-6"
            disabled={immutable || renderedInDiffView}
            title="Variables"
            value="variables"
          >
            <VariablesMapping
              immutable={immutable}
              provider={node.data.providerResource.provider}
              runFieldErrors={runFieldErrors}
            />
          </AccordionItem>
          <AccordionItem
            className="pb-6 pr-6"
            disabled={immutable || renderedInDiffView}
            title="SQL query"
            value="query"
          >
            <QueryEditor
              displayedError={displayedError}
              immutable={immutable}
              isReactive={isReactive}
            />
          </AccordionItem>
          <AccordionItem
            className="pb-6 pr-6"
            disabled={immutable || renderedInDiffView}
            title="Configure SQL response"
            value="response"
          >
            <ResponseMapping
              immutable={immutable}
              runFieldErrors={runFieldErrors}
            />
          </AccordionItem>
          <AccordionItem
            className="pb-6 pr-6"
            disabled={immutable || renderedInDiffView}
            title="Advanced settings"
            value="config"
          >
            <ConfigPane connection={connection} immutable={immutable} />
          </AccordionItem>
        </Root>
      </form>
    </FormProvider>
  );
};
