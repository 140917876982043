import { useCallback, useRef, useState } from "react";

import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { CommentDb } from "src/clients/flow-api";
import { useDeleteComment as useDeleteCommentQuery } from "src/comments/queries";
import { toastError } from "src/utils/toastError";

export const useDeleteComment = () => {
  const deleteResolver = useRef<((x: boolean) => void) | null>(null);
  const { mutate: deleteComment, isLoading: isDeleting } =
    useDeleteCommentQuery();
  const [deletingComment, setDeletingComment] =
    useState<Nullable<CommentDb>>(null);

  const commentDeletionModal = (
    <ConfirmationModal
      open={Boolean(deletingComment)}
      title="Delete comment"
      onClose={() => setDeletingComment(null)}
      onConfirm={() => {
        if (deletingComment && !isDeleting) {
          try {
            deleteComment(deletingComment);
            setDeletingComment(null);
            deleteResolver.current?.(true);
            return;
          } catch (e) {
            toastError("Unable to delete comment");
            deleteResolver.current?.(false);
          }
        }
        deleteResolver.current?.(false);
      }}
    >
      <div className="text-gray-500 font-inter-normal-12px">
        Are you sure you want to delete this comment?
        <br /> This action cannot be undone.
      </div>
    </ConfirmationModal>
  );

  const onDelete = useCallback(
    (comment: CommentDb) => {
      const promise = new Promise<boolean>((resolve) => {
        deleteResolver.current = resolve;
      });
      setDeletingComment(comment);
      return promise;
    },
    [setDeletingComment],
  );

  return {
    commentDeletionModal,
    onDelete,
  };
};
