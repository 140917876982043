import { faSpinner, faWarning } from "@fortawesome/pro-regular-svg-icons";
import { m } from "framer-motion";

import { DatasetJob } from "src/api/types";
import { Pill } from "src/base-components/Pill";
import { DATASET_JOB_TIMEOUT } from "src/base-components/Toast/utils";
import { Tooltip } from "src/base-components/Tooltip";

const tooltipContent = {
  pending: {
    title: "Dataset Processing...",
    body: "This dataset is being processed. This might take a few minutes.",
  },
  failed: {
    title: "Dataset Processing Failed",
  },
} as const;

export const DatasetStatusPill = ({
  status,
  errorMsg,
}: {
  status: DatasetJob["status"];
  errorMsg?: string;
}) => {
  const isFailed = status === "FAILED";
  return (
    <m.div
      animate={{
        opacity: 1,
        transition: {
          type: "tween",
          ease: "easeOut",
          delay: 0.15,
        },
      }}
      initial={{ opacity: 0 }}
      transition={{ type: "tween", ease: "easeOut" }}
      layout
    >
      <Tooltip
        placement="top"
        {...(isFailed
          ? { ...tooltipContent.failed, body: errorMsg ?? DATASET_JOB_TIMEOUT }
          : tooltipContent.pending)}
      >
        <div>
          <Pill variant={isFailed ? "red" : "yellow"}>
            <Pill.Icon
              icon={isFailed ? faWarning : faSpinner}
              spin={!isFailed}
            />
            <Pill.Text>{isFailed ? "Failed" : "Processing"}</Pill.Text>
          </Pill>
        </div>
      </Tooltip>
    </m.div>
  );
};
