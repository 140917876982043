import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faClone, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { Menu } from "@headlessui/react";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

import { Icon } from "src/base-components/Icon";

type MenuItem = {
  icon: IconProp;
  key: string;
  label: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;

  onDeleteRow: () => void;
  onDuplicateRow: () => void;
  onCopyAsPayload?: () => void;

  resetSelection: () => void;
};

export const RowMenu: React.FC<Props> = ({
  isOpen,
  onClose,
  children,

  onDeleteRow,
  onDuplicateRow,
  onCopyAsPayload,
  resetSelection: onResetSelection,
}) => {
  const items: MenuItem[] = [
    // { key: "add-above", label: "Add a test case above" },
    // { key: "add-below", label: "Add a test case below" },
    { key: "duplicate", label: "Duplicate", icon: faClone },
    onCopyAsPayload && {
      key: "copy-as-payload",
      label: "Copy payload as JSON",
      icon: faClone,
    },
    { key: "delete", label: "Delete", icon: faTrashAlt },
  ].filter(Boolean) as MenuItem[];

  const [itemsRef, setItemsRef] = useState<Nullable<HTMLDivElement>>(null);
  const [buttonRef, setButtonRef] = useState<Nullable<HTMLSpanElement>>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    buttonRef,
    itemsRef,
    {
      strategy: "fixed",
      placement: "bottom-start",
      modifiers: [{ name: "offset", options: { offset: [-19, 6] } }],
    },
  );

  const onClick = async (key: string) => {
    if (key === "delete") {
      onDeleteRow();
      onResetSelection();
    }

    if (key === "duplicate") {
      onDuplicateRow();
      onResetSelection();
    }

    if (key === "copy-as-payload") {
      onCopyAsPayload?.();
    }

    onClose();
  };

  return (
    <Menu>
      <div ref={setButtonRef}>{children}</div>
      {isOpen &&
        ReactDOM.createPortal(
          <Menu.Items
            ref={setItemsRef}
            as="ul"
            className="z-50 w-66 overflow-y-auto rounded-lg bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none"
            {...popperAttributes.popper}
            style={popperStyles.popper}
            static
          >
            {items.map((item) => (
              <Menu.Item
                key={item.key}
                as="li"
                className="flex cursor-pointer items-center gap-x-2 px-4 py-2.5 font-medium text-gray-800 font-inter-normal-13px hover:bg-gray-50"
                data-loc={`dataset-table-${item.label.toLowerCase()}-action`}
                onMouseDownCapture={() => onClick(item.key)}
              >
                <Icon color="text-gray-500" icon={item.icon} size="xs" />
                {item.label}
              </Menu.Item>
            ))}
          </Menu.Items>,
          document.body,
        )}
    </Menu>
  );
};
