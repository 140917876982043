import { NodeT } from "src/constants/NodeDataTypes";
import { getNodeRunState } from "src/store/runState/RunState";
import { isHistoricalRunState } from "src/utils/predicates";

/**
 * @returns Whether the edge bounded by the two given nodes was on the path a currenty viewed historical decision
 */
export const getEdgeWasPartOfHistoricalDecision = (
  sourceNode: NodeT | undefined,
  targetNode: NodeT | undefined,
  versionId: string,
) => {
  const sourceRunState = sourceNode
    ? getNodeRunState(sourceNode.id, versionId)
    : undefined;
  const targetRunState = targetNode
    ? getNodeRunState(targetNode.id, versionId)
    : undefined;
  if (!sourceRunState || !targetRunState) {
    return false;
  }
  return (
    isHistoricalRunState(sourceRunState) && isHistoricalRunState(targetRunState)
  );
};
