import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { endOfToday, startOfToday, sub } from "date-fns";
import { intersection, keyBy } from "lodash";
import { useMemo, useState } from "react";
import { twJoin } from "tailwind-merge";

import { FlowVersionStatusT } from "src/api/flowTypes";
import { SubHeader, SubHeaderFilter } from "src/flow/SubHeader";
import { maxTableWidth } from "src/layout/constants";
import { useMetricsRefresh } from "src/performance/MetricsRefresh";
import { OverviewPane } from "src/performance/OverviewPane";
import { DecisionsChart } from "src/performance/charts/DecisionsChart";
import { ErrorsChart } from "src/performance/charts/ErrorsChart";
import { LatencyChart } from "src/performance/charts/LatencyChart";
import { useAvailableVersions } from "src/performance/queries";
import { useFlowContext } from "src/router/routerContextHooks";
import { formatDate } from "src/utils/datetime";
import { getVersionsColors } from "src/utils/getVersionsColors";
import { useTimeWindow } from "src/utils/timeWindow";

export const PerformanceContent: React.FC = () => {
  const { flow, workspace } = useFlowContext();
  const { refresh, refreshing, dataUpdatedAt } = useMetricsRefresh();

  const publishedVersionsById = useMemo(
    () =>
      keyBy(
        flow.versions.filter((v) => v.status === FlowVersionStatusT.PUBLISHED),
        "id",
      ),
    [flow.versions],
  );

  const publishedVersionsIds = Object.keys(publishedVersionsById);

  const { dateRangePickerValue, onDateRangePickerChange, timeWindow } =
    useTimeWindow(
      {
        from: sub(startOfToday(), { weeks: 1 }),
        to: endOfToday(),
      },
      "performance-time-window",
    );
  const [versions, setVersions] = useState<string[]>(() =>
    publishedVersionsIds.sort((a, b) =>
      publishedVersionsById[a].name.localeCompare(
        publishedVersionsById[b].name,
      ),
    ),
  );
  const availableVersions = useAvailableVersions(workspace.base_url, {
    versions: publishedVersionsIds,
    timeWindow,
  });

  const filters = useMemo(
    () => ({
      timeWindow,
      versions:
        availableVersions.isFetching || !availableVersions.data
          ? // Empty versions list disables getting chart data.
            // This is intendent, because we don't want to fetch data until we know available versions
            []
          : intersection(versions, availableVersions.data),
    }),
    [
      availableVersions.data,
      availableVersions.isFetching,
      timeWindow,
      versions,
    ],
  );

  const versionsById = keyBy(
    flow.versions.filter((v) => availableVersions.data?.includes(v.id)),
    "id",
  );

  const colors = getVersionsColors(Object.values(publishedVersionsById));
  const options =
    availableVersions.data?.map((versionId) => ({
      key: versionId,
      value: versionsById[versionId]?.name ?? versionId,
      color: colors[versionId]?.dark,
    })) ?? [];

  return (
    <>
      <SubHeader title="Performance" paddedParent>
        <SubHeader.DatePicker
          rangeLimitInDays={30}
          value={dateRangePickerValue}
          onChange={onDateRangePickerChange}
        />
        {options && (
          <SubHeaderFilter
            disabled={options.length < 1}
            options={options}
            selected={filters.versions}
            tooltip={
              options.length < 1
                ? "There is no data to filter for the selected dates"
                : ""
            }
            multiple
            onChange={(value) => {
              setVersions(value ?? []);
            }}
          />
        )}
        <SubHeader.Button
          disabled={refreshing}
          icon={faRefresh}
          spin={refreshing}
          tooltip={
            dataUpdatedAt
              ? `Last updated at ${formatDate(dataUpdatedAt, "h:mm aaa")}`
              : "Refetch"
          }
          onClick={refresh}
        />
      </SubHeader>
      <div
        className={twJoin(
          "mx-auto flex w-full min-w-[1000px] flex-col gap-6",
          maxTableWidth,
        )}
      >
        <OverviewPane filters={filters} />

        <DecisionsChart colors={colors} filters={filters} />

        <ErrorsChart filters={filters} />

        <LatencyChart colors={colors} filters={filters} />
      </div>
    </>
  );
};
