import { InformationPill } from "src/base-components/InformationPill";

export const DatabasePermissionsPill = () => {
  return (
    <InformationPill type="warning">
      <div>
        <span className="text-orange-700 font-inter-semibold-13px">
          Database permissions
        </span>
        <div>
          Taktile will execute the provided SQL queries as-is, with no
          additional permission checks. The security and control of data access
          should be managed in your database settings, following best practices
          to prevent unintended access or data modifications.
        </div>
      </div>
    </InformationPill>
  );
};
