import { times } from "lodash";
import { forwardRef } from "react";
import { twJoin } from "tailwind-merge";

import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";

const movingElement = (
  <div
    className="absolute inset-0 -translate-x-full transform bg-gradient-to-r from-white via-gray-50 to-white"
    style={{ animation: "shimmer 1.3s infinite" }}
  ></div>
);

export const ROW_HEIGHT = 32;

type RowSkeletonProps = {
  nCol: number;
};

export const RowSkeleton = forwardRef<HTMLTableRowElement, RowSkeletonProps>(
  (props, ref) => {
    return (
      <tr ref={ref}>
        {times(props.nCol).map((j) => (
          <td
            key={j}
            className={twJoin(
              "relative h-8 overflow-hidden border border-gray-100 p-0",
              j === 0 ? "sticky left-0 bg-gray-50" : "",
            )}
          >
            {j !== 0 ? (
              movingElement
            ) : (
              <div className="absolute inset-0 flex h-full items-center justify-center">
                <SkeletonPlaceholder height="h-3" width="w-3" />
              </div>
            )}
          </td>
        ))}
      </tr>
    );
  },
);

export const RowsSkeleton: React.FC<
  RowSkeletonProps & ({ nRow: number } | { heightToFill: number })
> = (props) => {
  const rows =
    "nRow" in props ? props.nRow : Math.floor(props.heightToFill / ROW_HEIGHT);
  return (
    <>
      {times(rows).map((i) => (
        <RowSkeleton key={i} nCol={props.nCol} />
      ))}
    </>
  );
};
