import SocureIcon from "src/assets/SocureIcon.svg?react";
import {
  getDefaultSocureComplianceBundle,
  getDefaultSocurePremiumBundle,
  getDefaultSocureStandardBundle,
} from "src/connections/connectionConfigs/socure/deprecatedSocureResources";
import {
  getDefaultSocureFraudBundle1,
  getDefaultSocureFraudBundle2,
  getDefaultSocureFraudBundle3,
  getDefaultSocureFraudBundle4,
  getDefaultSocureFraudBundle5,
  getDefaultSocureDocVPlusBundle,
  getDefaultSocureDigitalIntelligence,
} from "src/connections/connectionConfigs/socure/socureResources";
import { SocureTemplate } from "src/connections/connectionConfigs/socure/socureTemplate";
import { ConnectionConfig } from "src/connections/connectionConfigs/types";

export const SOCURE_CONFIG: ConnectionConfig<"socure"> = {
  icon: SocureIcon,
  description:
    "Accurate fraud detection and identity verification in real time",
  template: SocureTemplate,
  name: "Socure",
  resources: {
    // New bundles
    socure_fraud_bundle_1: {
      externalLink:
        "https://www.notion.so/taktile/Bundle-1-Full-Fraud-Suite-4b75435857d84e3b8acdf43ea35c09b6",
      integrationResource: getDefaultSocureFraudBundle1,
    },
    socure_fraud_bundle_2: {
      externalLink:
        "https://www.notion.so/taktile/Bundle-2-Full-Fraud-Suite-Address-KYC-2bb30515021e4e9286b0b371d18d19f4",
      integrationResource: getDefaultSocureFraudBundle2,
    },
    socure_fraud_bundle_3: {
      externalLink:
        "https://www.notion.so/taktile/Bundle-3-Full-Fraud-Suite-Address-KYC-Watchlist-Standard-66e1ea786755405d8cf3d7808d3e3f14",
      integrationResource: getDefaultSocureFraudBundle3,
    },
    socure_fraud_bundle_4: {
      externalLink:
        "https://www.notion.so/taktile/Bundle-4-Full-Fraud-Suite-Address-KYC-Watchlist-Plus-fa52536360b74235b390c3b8c3565f6b",
      integrationResource: getDefaultSocureFraudBundle4,
    },
    socure_fraud_bundle_5: {
      externalLink:
        "https://www.notion.so/taktile/Bundle-5-Full-Fraud-Suite-Address-KYC-Watchlist-Premier-73d1ce31db2d4b419cde9840c874d76b",
      integrationResource: getDefaultSocureFraudBundle5,
    },
    socure_doc_v_plus_bundle: {
      externalLink:
        "https://www.notion.so/taktile/Bundle-6-DocV-Plus-Document-Verification-Device-Risk-c42ab5c15ed848a09edf17db9dd05b5c",
      integrationResource: getDefaultSocureDocVPlusBundle,
    },
    socure_digital_intelligence: {
      externalLink:
        "https://www.notion.so/taktile/Digital-Intelligence-Report-9dfd7e7c782c48ac824a4e23ed54de3d?pvs=4",
      integrationResource: getDefaultSocureDigitalIntelligence,
    },
    // Deprecated bundles
    compliance_bundle: {
      externalLink:
        "https://www.notion.so/taktile/Compliance-Bundle-bf62b77a83cf47fcb69f5433a70f4efa?pvs=4",
      integrationResource: getDefaultSocureComplianceBundle,
    },
    standard_bundle: {
      externalLink:
        "https://www.notion.so/taktile/Standard-Bundle-6c69beae55e74f55a2876f61346c5e9f?pvs=4",
      integrationResource: getDefaultSocureStandardBundle,
    },
    premium_bundle: {
      externalLink:
        "https://www.notion.so/taktile/Premium-Bundle-602231ced56f47f5ad60b350d96377d1?pvs=4",
      integrationResource: getDefaultSocurePremiumBundle,
    },
  },
};
