import {
  ConnectionResourceTemplate,
  makeConnectionTemplate,
} from "src/connections/types";

const inquiryPurposeTypeConfigKey: ConnectionResourceTemplate["configKeys"][number] =
  {
    key: "inquiry_purpose_type",
    name: "Inquiry purpose type",
    type: "simple_selection",
    required: false,
    elements: [
      { key: undefined, value: "None" },
      { key: "AR", value: "AR: New credit" },
      { key: "AS", value: "AS: New credit soft" },
      { key: "RA", value: "RA: Account review soft" },
      { key: "RP", value: "RP: Consumer inquiry soft" },
      { key: "CL", value: "CL: Collection inquiry" },
      { key: "PC", value: "PR: Pre-check soft" },
      { key: "MS", value: "MS: Credit monitor soft" },
      { key: "CC", value: "CC: Check cash" },
      { key: "CS", value: "CS: Collection soft" },
      { key: "PS", value: "PS: Pre-screen soft" },
      { key: "IV", value: "IV: Item verification" },
      { key: "IS", value: "IS: Item verification soft" },
      { key: "EH", value: "EH: Employment" },
      { key: "ES", value: "ES: Employment soft" },
      { key: "LH", value: "LH: Lease" },
      { key: "LS", value: "LS: Lease soft" },
      { key: "WS", value: "WS: Written authorization soft" },
      { key: "WH", value: "WH: Written authorization hard" },
      { key: "PR", value: "PR: Portfolio review" },
      { key: "PA", value: "PA: Portfolio acquisition" },
      { key: "SP", value: "SP: Subpoena" },
      { key: "CA", value: "CA: Written instruction soft" },
      { key: "AO", value: "AO: Account opening soft" },
    ],
  };

export const ExperianUSClarityServicesTemplate = makeConnectionTemplate({
  provider: "experian_us_clarity_services",
  secrets: [
    { key: "username", name: "Username", required: true },
    { key: "password", name: "Password", required: true },
  ],
  resources: {
    experian_us_clarity_services_report: {
      name: "Clarity Services Report",
      configKeys: [
        {
          key: "group_id",
          name: "Group ID",
          type: "text",
          required: true,
          hint: "Group ID provided by Clarity Services. Reference the username notification email",
        },
        {
          key: "account_id",
          name: "Account ID",
          type: "text",
          required: true,
          hint: "Account ID provided by Clarity Services. Reference the username notification email",
        },
        {
          key: "location_id",
          name: "Location ID",
          required: true,
          type: "text",
          hint: "Location ID provided by Clarity Services. Reference the username notification email",
        },
        {
          key: "control_file_name",
          name: "Control file name",
          required: true,
          type: "text",
          hint: "Control file name provided by Clarity Services",
        },
        inquiryPurposeTypeConfigKey,
      ],
    },
  },
});
