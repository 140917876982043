import { useFormContext } from "react-hook-form";

import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { KeyValuePairField } from "src/connections/config/KeyValuePairField";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import {
  AvailableEnvironmentPrefixes,
  ConnectionConfigInputsT,
} from "src/connections/types";

type PropsT = {
  environmentPrefix: AvailableEnvironmentPrefixes;
};

export const ConfigAwsSignatureFieldset: React.FC<PropsT> = ({
  environmentPrefix,
}) => {
  const {
    formState: { errors: formErrors },
    register,
  } = useFormContext<ConnectionConfigInputsT>();

  // Change this once we support multiple environments
  const errors =
    environmentPrefix === ""
      ? formErrors?.awsSignatureConfig
      : formErrors.nonProdEnvConfigs?.sandbox?.awsSignatureConfig;

  return (
    <>
      <FieldsetCard>
        <FormItem gap="sm">
          <KeyValuePairField
            cta="Add secret"
            name={`${environmentPrefix}secrets`}
            showHeader={false}
            isSensitive
          />
        </FormItem>
      </FieldsetCard>
      <FieldsetCard>
        <FormItem gap="sm" label="AWS access key" isRequired>
          <Input
            errored={isFieldErrored(errors, "aws_access_key")}
            {...register(
              `${environmentPrefix}awsSignatureConfig.aws_access_key`,
              {
                required: true,
              },
            )}
            fullWidth
          />
        </FormItem>
        <FormItem gap="sm" label="AWS host" isRequired>
          <Input
            errored={isFieldErrored(errors, "aws_host")}
            {...register(`${environmentPrefix}awsSignatureConfig.aws_host`, {
              required: true,
            })}
            fullWidth
          />
        </FormItem>
        <FormItem gap="sm" label="AWS region" isRequired>
          <Input
            errored={isFieldErrored(errors, "aws_region")}
            {...register(`${environmentPrefix}awsSignatureConfig.aws_region`, {
              required: true,
            })}
            fullWidth
          />
        </FormItem>
        <FormItem gap="sm" label="AWS service" isRequired>
          <Input
            errored={isFieldErrored(errors, "aws_service")}
            {...register(`${environmentPrefix}awsSignatureConfig.aws_service`, {
              required: true,
            })}
            fullWidth
          />
        </FormItem>
      </FieldsetCard>
    </>
  );
};
