import React from "react";
import {
  DropzoneInputProps,
  DropzoneRootProps,
  FileRejection,
  useDropzone,
} from "react-dropzone";

import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import * as logger from "src/utils/logger";

const MAX_FILE_SIZE = 75 * 1024 * 1024; // 75 MB

type PropsT = {
  maxFiles?: number;
  onSubmitFiles(files: File[], fileErrors: string[]): Promise<void>;
  content({
    getRootProps,
    getInputProps,
  }: {
    getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
    getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  }): JSX.Element;
};

export const DecideDropzone: React.FC<PropsT> = ({
  onSubmitFiles,
  content,
  maxFiles,
}) => {
  const isNoLimitsEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.unlimitedUploadSize,
  );
  const handleOnDrop = (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
  ) => {
    const fileErrors = rejectedFiles.map(
      (fileRejection) =>
        `${fileRejection.file.name}: ${
          fileRejection.errors[0]?.code === "file-too-large"
            ? "File is larger than 75 MB"
            : fileRejection.errors[0]?.message
        }`,
    );
    onSubmitFiles(acceptedFiles, fileErrors);
    rejectedFiles.forEach((rejection) => {
      logger.error({
        message: rejection.errors[0]?.message,
        placement: "top",
        className: "bg-red-100",
      });
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleOnDrop,
    accept: {
      "text/csv": [".csv"],
      "application/json": [".json"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    useFsAccessApi: false,
    maxFiles: maxFiles,
    maxSize: !isNoLimitsEnabled ? MAX_FILE_SIZE : undefined,
  });

  return content({ getRootProps, getInputProps });
};
