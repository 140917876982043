import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

/**
 * Store whether the Review Process Pane should focus the add review input when open
 */
type ShouldFocusAddReviewState = {
  shouldFocusAddReview: boolean;
};

type ShouldFocusAddReviewActions = {
  focusAddReview: () => void;
  setShouldFocusAddReview: (shouldFocusAddReview: boolean) => void;
};

const useShouldFocusAddReviewStore = create(
  immer<ZustandStore<ShouldFocusAddReviewState, ShouldFocusAddReviewActions>>(
    (set) => ({
      shouldFocusAddReview: false,

      actions: {
        focusAddReview: () => set({ shouldFocusAddReview: true }),
        setShouldFocusAddReview: (shouldFocusAddReview) =>
          set({ shouldFocusAddReview }),
      },
    }),
  ),
);

export const useFocusAddReview = () =>
  useShouldFocusAddReviewStore((state) => state.actions.focusAddReview);
export const useSetShouldFocusAddReview = () =>
  useShouldFocusAddReviewStore(
    (state) => state.actions.setShouldFocusAddReview,
  );
export const useShouldFocusAddReview = () =>
  useShouldFocusAddReviewStore((state) => state.shouldFocusAddReview);
