import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { gitbookAuth } from "src/api/taktileApi";

export const useGithbookAuth = (
  gitbookPath: string,
  options: UseQueryOptions<any, any, string> = {},
) => {
  return useQuery<string, Error>(
    ["gitbookAuth", gitbookPath],
    async () =>
      (await gitbookAuth.gitbookAuthApiV1GitbookAuthPost({ path: gitbookPath }))
        .data.url,
    options,
  );
};
