import { defaults, omit, pickBy } from "lodash";

import { flowsApi, versionsApi, workspacesApi } from "src/api/endpoints";
import { FlowT, FlowVersionT, NodeBET } from "src/api/flowTypes";
import {
  FlowCreateT,
  FlowUpdateT,
  FlowVersionCreateT,
  FlowVersionDuplicateT,
} from "src/api/types";
import {
  FlowDb,
  FlowVersionDb,
  FlowVersionV2,
  GetFlowsApiV1FlowsGetOrderByEnum,
  WorkspaceDataplane,
} from "src/clients/flow-api";
import { defaultSchema } from "src/schema/utils";

export const flowDbToFlowT = (flow: FlowDb): FlowT => {
  return defaults(flow, {
    meta: { description: "" },
    flow_folder_id: null,
    versions: [],
    charts: [],
    chart_order: [],
  });
};

export const flowVersionDbToFlowVersionT = (
  version: FlowVersionDb,
): FlowVersionT => {
  const graph = version.graph
    ? defaults(version.graph, {
        nodes: [],
        edges: [],
        node_groups: [],
      })
    : undefined;
  const defaultsWithoutCorrectGraph = defaults(version, {
    meta: {},
    input_schema: defaultSchema,
    output_schema: defaultSchema,
    parameters: [],
  });
  return { ...defaultsWithoutCorrectGraph, graph };
};

// @AUTH-2118: Refactor
// Makes sure we convert revisions to FlowVersionT as well, the type
// for flow versions used across all components.
export const flowVersionV2ToFlowVersionT = (
  version: FlowVersionV2,
): FlowVersionT => {
  const graph = {
    created_at: version.created_at,
    updated_at: version.updated_at,
    meta: version.meta,
    id: version.graph_id ?? "",
    nodes: Object.values(version.graph.nodes) as NodeBET[],
    node_groups: Object.values(version.graph.node_groups),
    edges: Object.values(version.graph.edges),
  };

  const defaultsWithoutCorrectGraph = defaults(version, {
    meta: {},
    input_schema: defaultSchema,
    output_schema: defaultSchema,
    parameters: [],
  });

  return {
    ...defaultsWithoutCorrectGraph,
    graph,
    locks: version.locks ? Object.values(version.locks) : [],
  };
};

export const loadWorkspaces = async (): Promise<WorkspaceDataplane[]> => {
  const response = await workspacesApi.getWorkspacesApiV1WorkspacesGet();
  return response.data;
};

export const createWorkspace = async (
  name: string,
  slug: string,
  organizationId: string,
  awsRegion: string,
): Promise<WorkspaceDataplane> => {
  const response = await workspacesApi.createWorkspaceApiV1WorkspacesPost({
    name: name,
    slug: slug,
    organization_id: organizationId,
    aws_region: awsRegion,
  });
  return response.data;
};

export const editWorkspace = async ({
  id,
  ...workspace
}: Pick<
  WorkspaceDataplane,
  "id" | "name" | "flow_services_version" | "settings"
>): Promise<WorkspaceDataplane> => {
  const response =
    await workspacesApi.updateWorkspaceApiV1WorkspacesWorkspaceIdPatch(
      id,
      workspace,
    );
  return response.data;
};

export const loadWorkspace = async (
  workspaceId: string,
  includeSettings: boolean = false,
): Promise<WorkspaceDataplane> => {
  const response =
    await workspacesApi.getWorkspaceApiV1WorkspacesWorkspaceIdGet(
      workspaceId,
      includeSettings,
    );
  return response.data;
};

// mirrored in flow-api, need to be modified synchronously
export const NO_FOLDER_FILTER = "no_folder";
export const loadFlows = async (
  workspaceId?: string,
  folderId?: string,
  orderBy?: GetFlowsApiV1FlowsGetOrderByEnum,
  skip?: number,
  limit?: number,
): Promise<FlowT[]> => {
  const folderFilter = folderId;
  const response = await flowsApi.getFlowsApiV1FlowsGet(
    skip,
    limit,
    workspaceId,
    folderFilter,
    orderBy,
  );
  return response.data.map(flowDbToFlowT);
};

export const loadFlow = async (flowId: string): Promise<FlowT> => {
  const response = await flowsApi.getFlowApiV1FlowsIdGet(flowId);
  return flowDbToFlowT(response.data);
};

export const createFlow = async (newFlow: FlowCreateT): Promise<FlowT> => {
  const response = await flowsApi.createFlowApiV1FlowsPost({
    name: newFlow.name,
    meta: { description: newFlow.description },
    workspace_id: newFlow.wsId,
    slug: newFlow.slug,
    // openapi generator generates the wrong type for flow_folder_id (undefiend instead of null)
    flow_folder_id: newFlow.flow_folder_id as string | undefined,
    initial_version_name: newFlow.initial_version_name,
    initial_version_meta: {
      created_by_id: newFlow.created_by_id,
      release_note: newFlow.initial_version_description,
    },
    review_configuration: newFlow.review_configuration,
  });
  return flowDbToFlowT(response.data);
};

export const updateFlow = async (flowUpdate: FlowUpdateT): Promise<void> => {
  await flowsApi.updateFlowApiV1FlowsIdPatch(
    flowUpdate.flowId,
    pickBy(
      omit(flowUpdate, ["flowId", "old_folder_id"]),
      (v) => v !== undefined,
    ),
  );
};

export const deleteFlow = async (flowId: string): Promise<void> =>
  (await flowsApi.deleteFlowApiV1FlowsFlowIdDelete(flowId)).data;

export const loadVersion = async (
  versionId: string,
  options: {
    cached?: FlowVersionT;
  } = {},
): Promise<FlowVersionT> => {
  try {
    const response = await versionsApi.getFlowVersionApiV1FlowVersionsIdGet(
      versionId,
      /* IfMatch */ undefined,
      /* IfNoneMatch */ options?.cached?.etag,
    );
    return flowVersionDbToFlowVersionT(response.data);
  } catch (err: any) {
    // Axios treats 304 as an error https://github.com/axios/axios/issues/703
    if (err.response?.status === 304) {
      return options.cached!;
    } else {
      throw err;
    }
  }
};

// Override browser behaviour of including
// the If-None-Match header automatically
// to get the latest version of the children
export const loadVersionOverridingIfNoneMatch = async (
  versionId: string,
): Promise<FlowVersionT> => {
  const response = await versionsApi.getFlowVersionApiV1FlowVersionsIdGet(
    versionId,
    /* IfMatch */ undefined,
    /* IfNoneMatch */ "xxxxxxxx",
  );
  return flowVersionDbToFlowVersionT(response.data);
};

export const createFlowVersion = async (
  flowVersionCreate: FlowVersionCreateT,
): Promise<FlowVersionT> => {
  const response = await versionsApi.createFlowVersionApiV1FlowVersionsPost({
    name: flowVersionCreate.name,
    flow_id: flowVersionCreate.flowId,
    meta: {
      release_note: flowVersionCreate.release_note,
      created_by_id: flowVersionCreate.created_by_id,
    },
  });
  return flowVersionDbToFlowVersionT(response.data);
};

export const duplicateFlowVersion = async (
  flowVersionDuplicate: FlowVersionDuplicateT,
): Promise<FlowVersionT> => {
  const response = await versionsApi.duplicateApiV1FlowVersionsIdDuplicatePost(
    flowVersionDuplicate.flowVersionId,
    {
      name: flowVersionDuplicate.name,
      include_comments: flowVersionDuplicate.include_comments,
      meta: {
        release_note: flowVersionDuplicate.release_note,
        created_by_id: flowVersionDuplicate.created_by_id,
      },
    },
  );
  return flowVersionDbToFlowVersionT(response.data);
};
