import React from "react";

import {
  IntegrationNode,
  ManualReviewNode,
  BeMappedNode,
} from "src/constants/NodeDataTypes";
import { CREATABLE_NODE_PROPERTIES } from "src/constants/NodeProperties";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { getIntegrationNodeProperties } from "src/integrationNode/IntegrationNodeProperties";
import { HelpButton } from "src/nodeEditor/HelpButton";
import { ManifestConnectionNodeHelpButton } from "src/nodeEditor/ManifestConnectionNodeHelpButton";

type SidePaneHelpButtonProps = {
  selectedNode: BeMappedNode;
};

export const SidePaneHelpButton: React.FC<SidePaneHelpButtonProps> = ({
  selectedNode,
}) => {
  const getHelpButtonUrl = (): string | undefined => {
    if (
      [NODE_TYPE.INTEGRATION_NODE, NODE_TYPE.REVIEW_CONNECTION_NODE].includes(
        selectedNode.type,
      )
    ) {
      return getIntegrationNodeProperties(
        selectedNode as IntegrationNode | ManualReviewNode,
      ).docsUrl;
    }

    return CREATABLE_NODE_PROPERTIES[
      selectedNode.type as keyof typeof CREATABLE_NODE_PROPERTIES
    ]?.DOCS_HELP_BUTTON;
  };
  if (selectedNode.type === NODE_TYPE.MANIFEST_CONNECTION_NODE) {
    return <ManifestConnectionNodeHelpButton selectedNode={selectedNode} />;
  }

  const helpButtonUrl = getHelpButtonUrl();

  if (!helpButtonUrl) return null;
  return <HelpButton url={helpButtonUrl} />;
};
