import { v4 as uuidV4 } from "uuid";

import { SocureResourceT } from "src/api/connectApi/types";
import { ExternalLink } from "src/base-components/ExternalLink";
import {
  getSocureAddressFields,
  getDefaultSocureAddress,
  SOCURE_FIRST_NAME_HINT,
  SOCURE_LAST_NAME_HINT,
  SOCURE_DATE_OF_BIRTH_HINT,
  SOCURE_NATIONAL_ID_HINT,
  SOCURE_PHONE_NUMBER_HINT,
  SOCURE_EMAIL_HINT,
  SOCURE_IP_ADDRESS_HINT,
  SOCURE_DEVICE_SESSION_ID_HINT,
  SOCURE_USER_CONSENT_HINT,
  SOCURE_USER_CONSENT_TIMESTAMP_HINT,
  SOCURE_DRIVERS_LICENSE_HINT,
  SOCURE_DRIVERS_LICENSE_STATE_HINT,
  SOCURE_DOCUMENT_UUID_HINT,
  SOCURE_CUSTOMER_USER_ID_HINT,
} from "src/connections/connectionConfigs/socure/shared";
import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  InputMappingGroupT,
  InputMappingsT,
  IntegrationResourceT,
  OutputMappingT,
} from "src/integrationNode/types";

const ALL_OPTIONAL = false;

const ReasonCodesLink = ({ children }: { children: React.ReactNode }) => (
  <ExternalLink href="https://developer.socure.com/docs/advanced-concepts/reason-codes/">
    {children}
  </ExternalLink>
);

const DEVICE_RISK_REASON_CODES_HINT = (
  <span>
    A list of reason codes that provide detailed information to help you
    understand the Device Risk factors. See{" "}
    <ReasonCodesLink>Reason Codes in ID+</ReasonCodesLink> for more information.
  </span>
);

const getDefaultInputMapping = (): InputMappingsT => ({
  ungrouped: {
    first_name: {
      id: uuidV4(),
      type: "text",
      displayName: "First name",
      assignedTo: "",
      hint: SOCURE_FIRST_NAME_HINT,
      rules: {
        required: true,
      },
    },
    last_name: {
      id: uuidV4(),
      type: "text",
      displayName: "Last name",
      assignedTo: "",
      hint: SOCURE_LAST_NAME_HINT,
      rules: {
        required: true,
      },
    },
    date_of_birth: {
      id: uuidV4(),
      type: "text",
      displayName: "Date of birth",
      assignedTo: "",
      hint: SOCURE_DATE_OF_BIRTH_HINT,
    },
    national_id: {
      id: uuidV4(),
      type: "text",
      displayName: "National ID",
      assignedTo: "",
      hint: SOCURE_NATIONAL_ID_HINT,
      rules: {
        required: true,
      },
    },
    phone_number: {
      id: uuidV4(),
      type: "text",
      displayName: "Phone number",
      assignedTo: "",
      hint: SOCURE_PHONE_NUMBER_HINT,
      rules: {
        required: true,
      },
    },
    email: {
      id: uuidV4(),
      type: "text",
      displayName: "Email",
      assignedTo: "",
      hint: SOCURE_EMAIL_HINT,
      rules: {
        required: true,
      },
    },
    ip_address: {
      id: uuidV4(),
      type: "text",
      displayName: "IP address",
      assignedTo: "",
      hint: SOCURE_IP_ADDRESS_HINT,
    },
    device_session_id: {
      id: uuidV4(),
      type: "text",
      displayName: "Device session ID",
      assignedTo: "",
      hint: SOCURE_DEVICE_SESSION_ID_HINT,
    },
    user_consent: {
      id: uuidV4(),
      type: "text",
      displayName: "User consent",
      assignedTo: "",
      hint: SOCURE_USER_CONSENT_HINT,
    },
    consent_timestamp: {
      id: uuidV4(),
      type: "text",
      displayName: "Consent timestamp",
      assignedTo: "",
      hint: SOCURE_USER_CONSENT_TIMESTAMP_HINT,
    },
  },
  grouped: {
    address: getDefaultSocureAddress(),
  },
  lists: {},
});

const getDefaultInsights = () => ({
  provider_request_id: {
    ...getNamedOutputMapping("Provider request ID"),
    hint: "`reference_id` value from the Socure API response",
  },
  retrieval_datetime: {
    ...getNamedOutputMapping("Retrieval datetime"),
  },
});

const getBaseSocureFraudBundleInsights = () => ({
  email_risk_score: {
    ...getNamedOutputMapping("Email risk score"),
    hint: "A probabilistic value between 0.001 and 0.999 that represents the level of risk associated with an email address. A higher score indicates a greater likelihood of fraud. For example, a score of 0.800 means that the consumer is riskier than 80 percent of all consumers or less risky than 20 percent of consumers. In general, consumers with scores ≥ 0.900 are considered high risk.",
  },
  phone_risk_score: {
    ...getNamedOutputMapping("Phone risk score"),
    hint: "A probabilistic value between 0.001 and 0.999 that represents the level of risk associated with a phone number. A higher score indicates a greater likelihood of fraud. For example, a score of 0.800 means that the consumer is riskier than 80 percent of all consumers or less risky than 20 percent of consumers. In general, consumers with scores ≥ 0.900 are considered high risk.",
  },
  device_risk_reason_codes: {
    ...getNamedOutputMapping("Device risk reason codes"),
    hint: DEVICE_RISK_REASON_CODES_HINT,
  },
  is_on_alert_list: {
    ...getNamedOutputMapping("Is on alert list"),
    hint: `A Boolean (True/False) that indicates if any of the consumer's information was found on Socure's Alert List. This is a list of known fraudulent users and specific identifying traits, as reported by Socure and its customers. Submitted data is checked against that list according to variable matching logic and processing. If True, you can find more granular information under the "alert_list" section of the report.`,
  },
  alert_list_matches: {
    ...getNamedOutputMapping("Alert list matches"),
    hint: "An array of objects that contain detailed information about the consumer's information that matched the Socure's Alert List.",
  },
  alert_list_reason_codes: {
    ...getNamedOutputMapping("Alert list reason codes"),
    hint: (
      <span>
        A list of reason codes that provide detailed information to help you
        understand the factors that influenced the Alert list matches. See{" "}
        <ReasonCodesLink>Reason Codes in ID+</ReasonCodesLink> for more
        information.
      </span>
    ),
  },
  sigma_fraud_score: {
    ...getNamedOutputMapping("Sigma fraud score"),
    hint: "A probabilistic value between 0.001 and 0.999 that predicts the inherent fraud risk associated with the input PII. A higher score indicates a greater likelihood of fraud. For example, a score of 0.800 means that the consumer is riskier than 80 percent of all consumers or less risky than 20 percent of consumers. In general, consumers with scores ≥ 0.900 are considered high risk.",
  },
  synthetic_fraud_score: {
    ...getNamedOutputMapping("Synthetic fraud score"),
    hint: "A probabilistic value between 0.001 and 0.999 that predicts the probability that the combination of PII provided at the input corresponds to a fictitious person. A higher score indicates a greater likelihood of fraud. For example, a score of 0.800 means that the consumer is riskier than 80 percent of all consumers or less risky than 20 percent of consumers. In general, consumers with scores equal to or greater than 0.900 are considered high risk.",
  },
});

const getKYCSocureFraudBundleInsights = () => ({
  ...getBaseSocureFraudBundleInsights(),
  kyc_validations: {
    ...getNamedOutputMapping("KYC validations"),
    hint: `An object that maps consumer PII to Boolean values (True/False). The Booleans indicate whether the information provided by the consumer correlates with the best-matched entity. The object's (optional) keys are: "first_name", "sur_name", "physical_address", "city", "state", "zip", "mobile_number", "dob" (i.e., date of birth), and "ssn" (corresponding to the "national_id" input field).`,
  },
  kyc_reason_codes: {
    ...getNamedOutputMapping("KYC reason codes"),
    hint: (
      <span>
        A list of reason codes that provide detailed information to help you
        understand the factors that influenced the KYC validations. See{" "}
        <ReasonCodesLink>Reason Codes in ID+</ReasonCodesLink> for more
        information.
      </span>
    ),
  },
  address_risk_score: {
    ...getNamedOutputMapping("Address risk score"),
    hint: "A probabilistic value between 0.001 and 0.999 that represents the level of risk associated with a physical address. A higher score indicates a greater likelihood of fraud. For example, a score of 0.800 means that the consumer is riskier than 80 percent of all consumers or less risky than 20 percent of consumers. In general, consumers with scores ≥ 0.900 are considered high risk.",
  },
  address_risk_reason_codes: {
    ...getNamedOutputMapping("Address risk reason codes"),
    hint: (
      <span>
        A list of reason codes that provide detailed information to help you
        understand the factors that influenced the address risk score. See{" "}
        <ReasonCodesLink>Reason Codes in ID+</ReasonCodesLink> for more
        information.
      </span>
    ),
  },
});

const getWatchlistSocureFraudBundleInsights = () => ({
  ...getKYCSocureFraudBundleInsights(),
  is_on_global_watchlist: {
    ...getNamedOutputMapping("Is on global watchlist"),
    hint: `A Boolean (True/False) that indicates if any of the consumer's information was found on Socure's Global Watchlist - a collection of sanctions and enforcement lists, as well as Politically Exposed Persons (PEP) and adverse media registries worldwide. If True, you can find more granular information under the "global_watchlist" section of the report.`,
  },
  global_watchlist_matches: {
    ...getNamedOutputMapping("Global watchlist matches"),
    hint: `An object that maps watchlist names (e.g. "OFAC SDN List") to arrays of records, describing the potential matches of the consumer PII found in the corresponding watchlist. For each match, Socure also returns an "Entity correlation score" value, ranging from 1 to 100, which indicates the likelihood that a potential hit is a true match with the input PII.`,
  },
  global_watchlist_reason_codes: {
    ...getNamedOutputMapping("Global watchlist reason codes"),
    hint: (
      <span>
        A list of reason codes that provide detailed information to help you
        understand the factors that influenced the Global watchlist matches. See{" "}
        <ReasonCodesLink>Reason Codes in ID+</ReasonCodesLink> for more
        information.
      </span>
    ),
  },
});

const getDefaultSocureFraudBundle =
  (resource: SocureResourceT, insights: { [key: string]: OutputMappingT }) =>
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "socure",
      resource: resource,
    },
    connectionId: "",
    resourceConfigId: "",
    input: getDefaultInputMapping(),
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getDefaultInsights(),
        ...insights,
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultSocureFraudBundle1 = getDefaultSocureFraudBundle(
  "socure_fraud_bundle_1",
  getBaseSocureFraudBundleInsights(),
);
export const getDefaultSocureFraudBundle2 = getDefaultSocureFraudBundle(
  "socure_fraud_bundle_2",
  getKYCSocureFraudBundleInsights(),
);
export const getDefaultSocureFraudBundle3 = getDefaultSocureFraudBundle(
  "socure_fraud_bundle_3",
  getWatchlistSocureFraudBundleInsights(),
);
export const getDefaultSocureFraudBundle4 = getDefaultSocureFraudBundle(
  "socure_fraud_bundle_4",
  getWatchlistSocureFraudBundleInsights(),
);
export const getDefaultSocureFraudBundle5 = getDefaultSocureFraudBundle(
  "socure_fraud_bundle_5",
  getWatchlistSocureFraudBundleInsights(),
);

const getDefaultDocVAddress = (): InputMappingGroupT => ({
  displayName: "Address",
  getDefaultElements: () => getSocureAddressFields(ALL_OPTIONAL),
});

export const getDefaultSocureDocVPlusBundle = (): IntegrationResourceT => ({
  providerResource: {
    provider: "socure",
    resource: "socure_doc_v_plus_bundle",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      document_uuid: {
        id: uuidV4(),
        type: "text",
        displayName: "Document uuid",
        assignedTo: "",
        hint: SOCURE_DOCUMENT_UUID_HINT,
        rules: {
          required: true,
        },
      },
      first_name: {
        id: uuidV4(),
        type: "text",
        displayName: "First name",
        assignedTo: "",
        hint: SOCURE_FIRST_NAME_HINT,
      },
      last_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Last name",
        assignedTo: "",
        hint: SOCURE_LAST_NAME_HINT,
      },
      date_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Date of birth",
        assignedTo: "",
        hint: SOCURE_DATE_OF_BIRTH_HINT,
      },
      phone_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Phone number",
        assignedTo: "",
        hint: SOCURE_PHONE_NUMBER_HINT,
      },
      email: {
        id: uuidV4(),
        type: "text",
        displayName: "Email",
        assignedTo: "",
        hint: SOCURE_EMAIL_HINT,
      },
      drivers_license: {
        id: uuidV4(),
        type: "text",
        displayName: "Driver's license number",
        assignedTo: "",
        hint: SOCURE_DRIVERS_LICENSE_HINT,
      },
      drivers_license_state: {
        id: uuidV4(),
        type: "text",
        displayName: "Driver's license state",
        assignedTo: "",
        hint: SOCURE_DRIVERS_LICENSE_STATE_HINT,
      },
      ip_address: {
        id: uuidV4(),
        type: "text",
        displayName: "IP address",
        assignedTo: "",
        hint: SOCURE_IP_ADDRESS_HINT,
      },
      device_session_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Device session ID",
        assignedTo: "",
        hint: SOCURE_DEVICE_SESSION_ID_HINT,
      },
    },
    grouped: {
      address: getDefaultDocVAddress(),
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      provider_request_id: {
        ...getNamedOutputMapping("Provider request ID"),
        hint: "`reference_id` value from the Socure API response",
      },
      retrieval_datetime: {
        ...getNamedOutputMapping("Retrieval datetime"),
      },
      document_verification_decision: {
        ...getNamedOutputMapping("Document verification decision"),
        hint: `The result for the document verification. One of: "accept", "resubmit", "review", or "reject".`,
      },
      document_type: {
        ...getNamedOutputMapping("Document type"),
        hint: `The type of the document. One of: "Drivers License", "Identification Card", "Passport", "Passport Card", "Permanent Resident Card", "Unknown".`,
      },
      document_country: {
        ...getNamedOutputMapping("Document country"),
        hint: "The document's country of issue",
      },
      document_state: {
        ...getNamedOutputMapping("Document state"),
        hint: "The document's state of issue",
      },
      document_data: {
        ...getNamedOutputMapping("Document data"),
        hint: `An object that contains the information extracted from the ID using optical character recognition (OCR) or by scanning the barcode or machine-readable zone (MRZ)`,
      },
      document_verification_reason_codes: {
        ...getNamedOutputMapping("Document verification reason codes"),
        hint: (
          <span>
            A list of reason codes that provide detailed information to help you
            understand the factors that influenced the Document verification
            decision. See <ReasonCodesLink>Reason Codes in ID+</ReasonCodesLink>{" "}
            for more information.
          </span>
        ),
      },
      device_risk_reason_codes: {
        ...getNamedOutputMapping("Device risk reason codes"),
        hint: DEVICE_RISK_REASON_CODES_HINT,
      },
    },
  },
  config: defaultResourceConfig,
});
export const getDefaultSocureDigitalIntelligence =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "socure",
      resource: "socure_digital_intelligence",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        device_session_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Device session ID",
          assignedTo: "",
          hint: SOCURE_DEVICE_SESSION_ID_HINT,
          rules: {
            required: true,
          },
        },
        customer_user_id: {
          id: uuidV4(),
          type: "text",
          displayName: "User ID",
          assignedTo: "",
          hint: SOCURE_CUSTOMER_USER_ID_HINT,
          rules: {
            required: false,
          },
        },
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        provider_request_id: {
          ...getNamedOutputMapping("Provider request ID"),
          hint: "`reference_id` value from the Socure API response",
        },
        retrieval_datetime: {
          ...getNamedOutputMapping("Retrieval datetime"),
        },
        network_info: {
          ...getNamedOutputMapping("Network Information"),
          hint: "An object containing network data such as: IP address, VPN status, network location. Refer to docs for all properties.",
        },
        device_history: {
          ...getNamedOutputMapping("Device History"),
          hint: "An object containing device history such as: Previous IP addresses and locations associated with device. Refer to docs for all properties.",
        },
        behavioral_data: {
          ...getNamedOutputMapping("Behavioral Data"),
          hint: "An object containing behavioral data such as: Source URL, submission count, click count. Refer to docs for all properties.",
        },
      },
    },
    config: defaultResourceConfig,
  });
