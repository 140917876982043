import { faUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { AnimatePresence, m } from "framer-motion";
import React, { useRef } from "react";
import { twJoin } from "tailwind-merge";
import { useHover } from "usehooks-ts";

import { FlowVersionFlowChild } from "src/api/flowTypes";
import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/base-components/Tooltip";
import { FlowVersionStatus } from "src/clients/flow-api";
import { UserNameInfo } from "src/flowContainer/versionEditedInfo/UsernameInfo";
import { AccordionItem } from "src/nodeEditor/SelectFlowPane/AccordionItem";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { getBaseUrl, getUrlToAuthoringPage } from "src/router/urls";
import { formatDate } from "src/utils/datetime";
import { VersionStatusDotIndicator } from "src/utils/flowVersion";

type TitleProps = {
  version: FlowVersionFlowChild;
  flowId: string;
  hoverRef: React.MutableRefObject<HTMLDivElement | null>;
};

const Title: React.FC<TitleProps> = ({ version, flowId, hoverRef }) => {
  const { workspace } = useAuthoringContext();
  const isHover = useHover<HTMLDivElement>(hoverRef);

  const versionPageUrl =
    getBaseUrl() +
    getUrlToAuthoringPage(
      workspace.organization_id,
      workspace.id,
      flowId,
      version.id,
    );
  return (
    <div ref={hoverRef} className="flex w-full items-center justify-between">
      <div className="flex items-center gap-x-1">
        <VersionStatusDotIndicator size="3xs" version={version} />
        <span>{version.name}</span>
      </div>
      <div className="flex items-center gap-x-2">
        <AnimatePresence key={version.id} initial={false}>
          {isHover && (
            <m.div
              key={version.id}
              animate={{
                opacity: 1,
                transition: {
                  type: "tween",
                  ease: "easeOut",
                  delay: 0.15,
                },
              }}
              exit={{ opacity: 0, transition: { duration: 0.08 } }}
              initial={{ opacity: 0 }}
            >
              <Tooltip
                align="end"
                placement="bottom"
                title="Open in new tab"
                asChild
              >
                <a
                  href={`${versionPageUrl}`}
                  rel="noreferrer"
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Icon
                    color="text-gray-600"
                    icon={faUpRightFromSquare}
                    size="xs"
                  />
                </a>
              </Tooltip>
            </m.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

type Props = {
  version: FlowVersionFlowChild;
  flowId: string;
  selected?: boolean;
  onClick: () => void;
};

type FooterProps = {
  userId: string;
  isPublished?: boolean;
  date: string;
};

export const VersionCardFooter: React.FC<FooterProps> = ({
  date,
  userId,
  isPublished,
}) => {
  const formattedDate = date !== "" ? formatDate(date) : null;

  const editInfo = (
    <>
      <div className="flex text-gray-800">
        <UserNameInfo userId={userId || ""} withAvatar />
      </div>
      {formattedDate && (
        <>
          on
          <span className="text-gray-800">{formattedDate}</span>
        </>
      )}
    </>
  );

  return (
    <div
      className="flex flex-wrap items-center gap-1.5 text-gray-600 font-inter-normal-12px"
      data-loc="child-flow-editor-info"
    >
      {isPublished ? (
        <>published by {editInfo}</>
      ) : (
        <>last edited by {editInfo}</>
      )}
    </div>
  );
};

export const FlowVersionCard: React.FC<Props> = ({
  selected,
  version,
  flowId,
  onClick,
}) => {
  const hoverRef = useRef<HTMLDivElement>(null);
  const isPublished = version.status === FlowVersionStatus.PUBLISHED;
  const date = isPublished ? version.meta.published_at : version.updated_at;
  const userId = isPublished
    ? version.meta.published_by_id
    : version.meta.updated_by_id || version.meta.created_by_id;

  return (
    <div
      ref={hoverRef}
      className={twJoin(
        "cursor-pointer rounded-lg border p-4",
        selected ? "border-indigo-400" : "border-gray-200",
      )}
      data-loc={`child-flow-version-card-${version.name}`}
      onClick={onClick}
    >
      <AccordionItem
        key={version.id}
        title={
          <Title
            key={version.id}
            flowId={flowId}
            hoverRef={hoverRef}
            version={version}
          />
        }
        value={version.id}
      >
        <blockquote className="mb-4 ml-2 border-l border-gray-200 pl-2">
          <p
            className={twJoin(
              "leading-5 font-inter-normal-12px",
              version.meta.release_note ? "text-gray-500" : "text-gray-400",
            )}
          >
            {version.meta.release_note || "No description"}
          </p>
        </blockquote>
      </AccordionItem>
      <VersionCardFooter
        date={date || ""}
        isPublished={isPublished}
        userId={userId || ""}
      />
    </div>
  );
};
