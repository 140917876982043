import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { twJoin, twMerge } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { Tooltip, TooltipContentProps } from "src/base-components/Tooltip";

type CommonPropsT = {
  className?: string;
  description?: React.ReactNode;
  helpTooltip?: React.ReactNode;
  helpTooltipBody?: React.ReactNode;
  helpAction?: TooltipContentProps["action"];
  helpClassName?: string;
  isRequired?: boolean;
  dataLoc?: string;
};

type PropsT = {
  label?: string;
  children: React.ReactNode | React.ReactNode[];
  labelClassName?: string;
  gap?: "xxs" | "xs" | "sm" | "base" | "lg";
  id?: string;
} & CommonPropsT;

type LabelPropsT = {
  children: React.ReactNode | React.ReactNode[];
} & CommonPropsT;

type InfoPropsT = {
  title?: React.ReactNode;
  body?: React.ReactNode;
  action?: TooltipContentProps["action"];
  className?: string;
};

type SubComponents = {
  Label: React.FC<LabelPropsT>;
  Help: React.FC<InfoPropsT>;
};

export const FormItem: React.FC<PropsT> & SubComponents = ({
  children,
  label,
  className,
  labelClassName,
  helpClassName,
  gap = "base",
  id,
  dataLoc,
  ...props
}) => (
  <div
    className={twJoin(
      "last:mb-0",
      gap === "xxs" && "mb-2",
      gap === "xs" && "mb-4",
      gap === "sm" && "mb-6",
      gap === "base" && "mb-8",
      className,
    )}
    data-loc={dataLoc}
    id={id}
  >
    {Boolean(label) && (
      <FormItem.Label
        className={labelClassName}
        helpClassName={helpClassName}
        {...props}
      >
        {label}
      </FormItem.Label>
    )}
    {children}
  </div>
);

FormItem.Help = ({ title, body, action, className = "ml-1 mt-1" }) => {
  return title != null || body != null ? (
    <span className={twJoin("inline-block", className)}>
      <Tooltip
        action={action}
        align="center"
        body={body}
        placement="top"
        title={title}
        asChild
      >
        <div className="inline-block">
          <Icon
            color="text-gray-500"
            cursorType="pointer"
            icon={faCircleInfo}
            size="xs"
          />
        </div>
      </Tooltip>
    </span>
  ) : null;
};

FormItem.Label = ({
  className,
  children,
  description,
  helpTooltip,
  helpTooltipBody,
  helpAction,
  isRequired,
  helpClassName,
}) => {
  return (
    <label
      className={twMerge(
        "mb-2 inline-block text-gray-800 font-inter-semibold-13px",
        className,
      )}
    >
      {children} {isRequired && <span className="text-indigo-500">*</span>}{" "}
      <>
        {(helpTooltip || helpTooltipBody) && (
          <FormItem.Help
            action={helpAction}
            body={helpTooltipBody}
            className={helpClassName}
            title={helpTooltip}
          />
        )}
        {description && (
          <p className="mt-1 text-xs text-gray-500 font-inter-normal-12px">
            {description}
          </p>
        )}
      </>
    </label>
  );
};
