import { Controller, useFormContext } from "react-hook-form";

import { CreateConnectionGrid } from "src/connections/CreateConnectionGrid";
import { ProviderSelectInputsT } from "src/connections/types";

type PropsT = {
  onSubmit: (data: ProviderSelectInputsT) => void;
};

export const ProviderSelectForm: React.FC<PropsT> = ({ onSubmit }) => {
  const { control, handleSubmit, setValue } =
    useFormContext<ProviderSelectInputsT>();
  return (
    <form data-loc="select-provider-form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="provider"
        render={({ field: { onChange } }) => (
          <CreateConnectionGrid
            onProviderSelect={(provider, isManifestProvider) => {
              onChange(provider);
              setValue("isManifestProvider", isManifestProvider);
              handleSubmit(onSubmit)();
            }}
          />
        )}
      />
    </form>
  );
};
