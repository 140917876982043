import { faCheck, faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useMemo } from "react";
import { twJoin } from "tailwind-merge";

import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { WorkspaceSimpleRoleType } from "src/clients/taktile-api";

type Props = {
  nullable: boolean;
  workspaceName: string;
  value: Nullable<WorkspaceSimpleRoleType>;
  dataLoc: string;
  onChange: (key: WorkspaceSimpleRoleType) => void;
};

const roleOptions = [
  { key: null, value: "No Access" },
  { key: WorkspaceSimpleRoleType.ADMIN, value: "Admin" },
  { key: WorkspaceSimpleRoleType.EDITOR, value: "Editor" },
  { key: WorkspaceSimpleRoleType.VIEWER, value: "Viewer" },
  { key: WorkspaceSimpleRoleType.REVIEWER, value: "Reviewer" },
] as { key: WorkspaceSimpleRoleType; value: string }[];

const ButtonValue: React.FC<{ canOpen: boolean; value?: string }> = ({
  canOpen,
  value,
}) => {
  return (
    <div
      className={twJoin(
        "flex items-center justify-between py-1 pl-2 pr-2",
        "w-full gap-2 overflow-auto border-transparent",
      )}
    >
      <div className="min-w-0 grow text-left text-gray-800">
        {value ? value : "No Access"}
      </div>
      {canOpen && (
        <div className="flex h-auto items-center">
          <Icon color="text-gray-500" icon={faChevronDown} size="xs" />
        </div>
      )}
    </div>
  );
};

const ItemValue: React.FC<{ selected: boolean; value: string }> = ({
  selected,
  value,
}) => {
  return (
    <div
      className="flex w-56 justify-between px-4 py-2"
      data-loc={`select-${value}`}
    >
      <span>{value}</span>
      {selected && (
        <span>
          <Icon color="text-indigo-600" icon={faCheck} size="sm" />
        </span>
      )}
    </div>
  );
};

export const WorkspaceRoleSelect: React.FC<Props> = ({
  nullable,
  workspaceName,
  value,
  dataLoc,
  onChange,
}) => {
  const filteredOptions = useMemo(
    () => roleOptions.filter(({ key }) => (nullable ? true : key !== null)),
    [nullable],
  );

  useEffect(() => {
    if (!nullable && value === null) {
      onChange(WorkspaceSimpleRoleType.VIEWER);
    }
  }, [nullable, value, onChange]);

  return (
    <li className="flex items-center justify-between border-b border-gray-100 py-2">
      <span className="text-gray-800 font-inter-semibold-13px">
        {workspaceName}
      </span>
      <FixedPositionedDropdown
        buttonClassName="w-32"
        dataLoc={dataLoc}
        elements={filteredOptions}
        renderButtonValue={(value) => (
          <ButtonValue canOpen={filteredOptions.length > 0} value={value} />
        )}
        renderValue={({ selected, value }) => (
          <ItemValue selected={selected} value={value} />
        )}
        selected={value ?? undefined}
        onSelect={onChange}
      />
    </li>
  );
};
