import { ExporterDatasetJobsEndpoint } from "src/api/endpoints";
import {
  DownloadDatasetJob,
  ExportDecisionsDatasetJob,
  HistoryExportDatasetJob,
} from "src/api/types";
import {
  DATASET_JOB_TIMEOUT,
  toastFailure,
  toastLoading,
  toastSuccess,
} from "src/base-components/Toast/utils";

export const informPreparingDownload = (toastId: string) => {
  toastLoading({
    id: toastId,
    title: "Preparing your download...",
    description:
      "This could take a few minutes. The process will continue in the background even when this notification is dismissed.",
    duration: Infinity,
  });
};

export const pollAndNotifyDownloadSuccess = async (
  datasetJob:
    | DownloadDatasetJob
    | HistoryExportDatasetJob
    | ExportDecisionsDatasetJob,
  baseUrl: string,
  toastId: string,
  pollEndpoint: typeof ExporterDatasetJobsEndpoint.getDatasetJob = ExporterDatasetJobsEndpoint.getDatasetJob,
) => {
  while (datasetJob.status === "PENDING") {
    await new Promise((r) => setTimeout(r, 1000));
    datasetJob = (await pollEndpoint({
      baseURL: baseUrl,
      id: datasetJob.id,
    })) as typeof datasetJob;
  }
  if (datasetJob.status === "COMPLETED") {
    const s3Url = datasetJob.response?.s3_url;
    toastSuccess({
      id: toastId,
      duration: Infinity,
      title: "Download ready",
      description: `Your ${datasetJob.request.format.toUpperCase()} file is ready for download.`,
      actionText: "Click here to download",
      onActionClick: () => window.open(s3Url, "_blank"),
    });
  } else {
    toastFailure({
      id: toastId,
      duration: Infinity,
      title: "Download failed",
      description: datasetJob.error?.message ?? DATASET_JOB_TIMEOUT,
    });
  }
};
