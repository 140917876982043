import { useEffect, useState } from "react";

import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { SelectFlowAndVersion } from "src/nodeEditor/SelectFlowPane/SelectFlowPane";

export const FlowSelectionModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  handleSubmit: (version: FlowVersionT, flow: FlowT) => void;
}> = ({ open, handleClose, handleSubmit }) => {
  const [selectedFlow, setSelectedFlow] = useState<FlowT | null>(null);
  const [selectedVersion, setSelectedVersion] = useState<FlowVersionT | null>(
    null,
  );

  // Reset the modal state upon reopening
  useEffect(() => {
    if (open) {
      setSelectedFlow(null);
      setSelectedVersion(null);
    }
  }, [open]);

  return (
    <ConfirmationModal
      confirmationButtonDisabled={
        selectedFlow === null || selectedVersion === null
      }
      confirmationButtonText="Select a Decision Flow and version "
      open={open}
      size="large"
      title="Select a Decision Flow and version "
      closeIcon
      showTitleDivider
      onClose={handleClose}
      onConfirm={() => {
        if (selectedVersion && selectedFlow) {
          handleSubmit(selectedVersion, selectedFlow);
          handleClose();
        }
      }}
    >
      <div className="mb-2 flex h-[540px] flex-col pt-4">
        <SelectFlowAndVersion
          selectedFlow={selectedFlow}
          selectedVersionId={selectedVersion?.id}
          onFlowSelect={setSelectedFlow}
          onVersionSelect={setSelectedVersion}
        />
      </div>
    </ConfirmationModal>
  );
};
