import React, { useCallback, useContext, useMemo } from "react";

import { FactorFieldProps } from "src/scorecardNode/types";

type FactorContextValue = {
  onRemoveCase: (id: string) => void;
  canRemoveCase: boolean;
  fallbackId: string;
  equalWeights: boolean;
  totalFactors: number;
  field: FactorFieldProps | null;
  index: number;
};

const FactorContext = React.createContext<FactorContextValue>({
  onRemoveCase: () => {},
  canRemoveCase: false,
  fallbackId: "",
  equalWeights: false,
  totalFactors: 0,
  field: null,
  index: -1,
});

export const useFactorContext = () => useContext(FactorContext);

type Props = {
  children: React.ReactNode;
  field: FactorFieldProps;
  equalWeights: boolean;
  totalFactors: number;
  readonly: boolean;
  index: number;
};

export const FactorContextProvider: React.FC<Props> = ({
  field,
  equalWeights,
  totalFactors,
  children,
  readonly,
  index,
}) => {
  const handleRemoveCase = useCallback(
    (id: string) => {
      field.onChange({
        ...field.value,
        cases: field.value.cases.filter((factorCase) => factorCase.id !== id),
      });
    },
    [field],
  );

  const value = useMemo(
    () => ({
      fallbackId: field.value.fallback.id,
      canRemoveCase: field.value.cases.length > 1 && !readonly,
      equalWeights,
      totalFactors,
      field,
      index,
      onRemoveCase: handleRemoveCase,
    }),
    [equalWeights, totalFactors, field, handleRemoveCase, index, readonly],
  );

  return (
    <FactorContext.Provider value={value}>{children}</FactorContext.Provider>
  );
};
