import { ChartDimensionReduceEnum, ChartMark } from "src/clients/flow-api";

export type ChartMarkType = `${ChartMark}`;

export const CHART_MARKS = [
  ChartMark.AUTO,
  ChartMark.BAR,
  ChartMark.LINE,
  ChartMark.AREA,
  ChartMark.DOT,
] as const;

export const REDUCER_NAMES: [string, ChartDimensionReduceEnum | null][] = [
  ["—", null],
  ["Auto", ChartDimensionReduceEnum.AUTO],
  ["Count", ChartDimensionReduceEnum.COUNT],
  ["Distinct", ChartDimensionReduceEnum.DISTINCT],
  ["Sum", ChartDimensionReduceEnum.SUM],
  ["Min", ChartDimensionReduceEnum.MIN],
  ["Max", ChartDimensionReduceEnum.MAX],
  ["Mean", ChartDimensionReduceEnum.MEAN],
  ["Median", ChartDimensionReduceEnum.MEDIAN],
  ["Mode", ChartDimensionReduceEnum.MODE],
  ["Proportion", ChartDimensionReduceEnum.PROPORTION_FACET],
  ["Proportion (total)", ChartDimensionReduceEnum.PROPORTION],
];

export const DEFAULT_CHART_TITLE = "Untitled Chart";
