import {
  SnowflakeConnectionConfigurationBE,
  ConnectionConfiguration,
  ConnectionT,
  ConnectionCreateT,
  ConnectionConfigurationCreateT,
} from "src/api/connectApi/types";
import {
  findSecret,
  getDefaultSecret,
  setSecretEnv,
  filterUpdatedSecrets,
  markSecrets,
  filterSecretsByEnvironment,
} from "src/connections/model/common";
import {
  SnowflakeConnectionConfigT,
  SnowflakeConnectionConfigInputsT,
  KeyValuePairT,
} from "src/connections/types";

export const getSnowflakeConnectionConfigDefaultValues = (
  env: string | null,
): SnowflakeConnectionConfigT => ({
  accountIdentifier: "",
  warehouseName: "",
  databaseName: "",
  databaseSchema: "",
  userRole: "",
  allowTestInvocations: false,
  authMethod: "key_pair",
  basicAuthConfig: {
    basicAuthUsername: getDefaultSecret("basic_auth_username", env),
    password: getDefaultSecret("password", env),
  },
  keyPairAuthConfig: {
    username: getDefaultSecret("username", env),
    privateKey: getDefaultSecret("private_key", env),
    privateKeyPassphrase: getDefaultSecret("private_key_passphrase", env),
  },
});

export const getSnowflakeConnectionConfigInputsDefaultValues =
  (): SnowflakeConnectionConfigInputsT => ({
    name: "",
    dataRetention: {
      value: 0,
      unit: "days",
    },
    enableNonProdConfigs: false,
    productionConfig: getSnowflakeConnectionConfigDefaultValues(null),
    sandboxConfig: getSnowflakeConnectionConfigDefaultValues("sandbox"),
  });

const convertBEConnectionConfigurationToSnowflakeConnectionConfig = (
  config: ConnectionConfiguration,
  envSecrets: KeyValuePairT[],
  env: string | null,
): SnowflakeConnectionConfigT => {
  const snowflakeConfig = config as SnowflakeConnectionConfigurationBE;
  return {
    accountIdentifier: snowflakeConfig.account_identifier,
    warehouseName: snowflakeConfig.warehouse_name,
    databaseName: snowflakeConfig.database_name,
    databaseSchema: snowflakeConfig.database_schema ?? "",
    userRole: snowflakeConfig.user_role ?? "",
    allowTestInvocations: config.allow_test_invocations ?? false,
    authMethod: snowflakeConfig.auth_method,
    basicAuthConfig: {
      basicAuthUsername:
        findSecret(envSecrets, "basic_auth_username") ??
        getDefaultSecret("basic_auth_username", env),
      password:
        findSecret(envSecrets, "password") ?? getDefaultSecret("password", env),
    },
    keyPairAuthConfig: {
      username:
        findSecret(envSecrets, "username") ?? getDefaultSecret("username", env),
      privateKey:
        findSecret(envSecrets, "private_key") ??
        getDefaultSecret("private_key", env),
      privateKeyPassphrase:
        findSecret(envSecrets, "private_key_passphrase") ??
        getDefaultSecret("private_key_passphrase", env),
    },
  };
};

const convertBENonProdEnvConfigToSnowflakeConnectionConfigs = (
  nonProdEnvConfigs: Record<string, ConnectionConfiguration>,
  env: string,
  allSecrets: KeyValuePairT[],
): SnowflakeConnectionConfigT | null => {
  if (!nonProdEnvConfigs?.[env]) return null;
  const envSecrets = filterSecretsByEnvironment(allSecrets, env);
  return convertBEConnectionConfigurationToSnowflakeConnectionConfig(
    nonProdEnvConfigs[env],
    envSecrets,
    env,
  );
};

/**
 * Converts BE Snowflake connection configuration to FE format.
 * If the provider of the connection is not "snowflake", it throws an error.
 *
 * @param {ConnectionT} connection - The Snowflake connection object in BE format.
 * @returns {SnowflakeConnectionConfigInputsT} - The converted Snowflake connection object in FE format suitable to use with components.
 * @throws {Error} - Throws an error if the provider of the connection is not "snowflake".
 */
export const convertBEConnectionToSnowflakeConnectionConfigInputs = (
  connection: ConnectionT,
): SnowflakeConnectionConfigInputsT => {
  if (connection.provider !== "snowflake")
    throw new Error(
      `Invalid connection type: ${connection.provider} for Snowflake converter`,
    );

  const defaultValues = getSnowflakeConnectionConfigInputsDefaultValues();
  const nonProdEnvConfigs = connection.non_prod_env_configs;

  const allSecrets = markSecrets(connection.secrets) ?? [];
  const prodSecrets = filterSecretsByEnvironment(allSecrets, null);
  return {
    ...defaultValues,
    name: connection.name,
    dataRetention: connection.data_retention ?? defaultValues.dataRetention,
    enableNonProdConfigs: connection.enable_non_prod_configs,
    productionConfig:
      convertBEConnectionConfigurationToSnowflakeConnectionConfig(
        connection.configuration,
        prodSecrets,
        null,
      ),
    sandboxConfig:
      nonProdEnvConfigs && nonProdEnvConfigs["sandbox"]
        ? convertBENonProdEnvConfigToSnowflakeConnectionConfigs(
            nonProdEnvConfigs,
            "sandbox",
            allSecrets,
          )
        : getSnowflakeConnectionConfigDefaultValues("sandbox"),
  };
};

const extractSecretsFromSnowflakeConnectionConfig = (
  config: SnowflakeConnectionConfigT,
  env: string | null,
): KeyValuePairT[] => {
  if (config.authMethod === "basic_auth") {
    return [
      setSecretEnv(config.basicAuthConfig.basicAuthUsername, env),
      setSecretEnv(config.basicAuthConfig.password, env),
    ];
  } else if (config.authMethod === "key_pair") {
    return [
      setSecretEnv(config.keyPairAuthConfig.username, env),
      setSecretEnv(config.keyPairAuthConfig.privateKey, env),
      setSecretEnv(config.keyPairAuthConfig.privateKeyPassphrase, env),
    ];
  }
  return [];
};

const extractSecretsFromSnowflakeConnectionConfigInputs = (
  config: SnowflakeConnectionConfigInputsT,
): KeyValuePairT[] => {
  const secrets = extractSecretsFromSnowflakeConnectionConfig(
    config.productionConfig,
    null,
  );

  // Only include non-prod secrets if non-prod configs are enabled
  if (config.enableNonProdConfigs && config.sandboxConfig) {
    secrets.push(
      ...extractSecretsFromSnowflakeConnectionConfig(
        config.sandboxConfig,
        "sandbox",
      ),
    );
  }
  // Removing un-changed secrets, so that they are not sent to the backend
  return filterUpdatedSecrets(secrets);
};

const convertSnowflakeConnectionConfigToBEConnectionConfiguration = (
  config: SnowflakeConnectionConfigT,
): ConnectionConfigurationCreateT => {
  return {
    account_identifier: config.accountIdentifier,
    warehouse_name: config.warehouseName,
    database_name: config.databaseName,
    database_schema: config.databaseSchema || null,
    user_role: config.userRole || null,
    auth_method: config.authMethod,
    allow_test_invocations: config.allowTestInvocations,
  };
};

/**
 * Converts FE Snowflake connection object to BE format.
 *
 * @param {SnowflakeConnectionConfigInputsT} inputs - The Snowflake connection object in FE format.
 * @returns {ConnectionCreateT} - The Snowflake connection object in BE format suitable for sending POST request.
 */
export const convertSnowflakeConnectionConfigInputsToBEConnection = (
  inputs: SnowflakeConnectionConfigInputsT,
): ConnectionCreateT => {
  const secrets = extractSecretsFromSnowflakeConnectionConfigInputs(inputs);

  return {
    name: inputs.name,
    is_sandbox: false,
    provider: "snowflake",
    configuration: convertSnowflakeConnectionConfigToBEConnectionConfiguration(
      inputs.productionConfig,
    ),
    secrets,
    data_retention: inputs.dataRetention,
    enable_non_prod_configs: inputs.enableNonProdConfigs,
    non_prod_env_configs:
      inputs.enableNonProdConfigs && inputs.sandboxConfig
        ? {
            sandbox:
              convertSnowflakeConnectionConfigToBEConnectionConfiguration(
                inputs.sandboxConfig,
              ),
          }
        : {},
  };
};
