import { createContext, useContext, useMemo } from "react";

const context = createContext<{ maxX: number | null }>({ maxX: null });
const Provider = context.Provider;

type Props = {
  maxX: number | null;
  children: React.ReactNode;
};

export const EditorBoundsProvider: React.FC<Props> = ({ maxX, children }) => {
  const memoizedValue = useMemo(() => ({ maxX }), [maxX]);

  return <Provider value={memoizedValue}>{children}</Provider>;
};

export const useEditorBounds = () => {
  const { maxX } = useContext(context);

  return { maxX };
};
