import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/base-components/Tooltip";

type PropsT = {
  tooltipTitle: string;
  icon: IconProp;
  borderRight?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  dataLoc?: string;
};

export const NodeControlIcon: React.FC<PropsT> = ({
  tooltipTitle,
  icon,
  borderRight = false,
  onClick,
  dataLoc,
}) => (
  <Tooltip align="center" placement="top" title={tooltipTitle} asChild>
    <div
      className={twJoin(
        "flex items-center justify-center p-1.5 text-gray-500 transition-all hover:cursor-pointer hover:bg-indigo-600 hover:text-white",
        borderRight && "border-r border-gray-100",
      )}
      data-loc={dataLoc}
      onClick={onClick}
    >
      <Icon animate={false} icon={icon} size="2xs" />
    </div>
  </Tooltip>
);
