import { v4 as uuidV4 } from "uuid";

import type { EnvironmentsConfigT } from "src/baseConnectionNode/types";
import { DataRetentionUnit } from "src/connections/types";
import type {
  OutputMappingBET,
  SingleMappingBET,
} from "src/integrationNode/types";

export const HttpVerbs = [
  "get",
  "post",
  "patch",
  "delete",
  "options",
  "head",
  "put",
] as const;

export type HttpVerb = (typeof HttpVerbs)[number];

export type KeyValuePair = {
  id_key: string;
  id_value: string;
  key: string;
  value: string;
};

export const getEmptyKeyValuePair = (): KeyValuePair => ({
  id_key: uuidV4(),
  id_value: uuidV4(),
  key: "",
  value: "",
});

export type BodyOption = "key_values" | "raw";

export type BodyValue =
  | {
      selected: "raw";
      raw: string;
    }
  | {
      selected: "key_values";
      key_values: KeyValuePair[];
    };

export type CustomConnectionConfig = {
  environments_config?: EnvironmentsConfigT;
  testing: {
    use_cached_reports: boolean;
    // Deprecated, keeping for backwards compatibility
    use_fallback_live_connection: boolean;
  };
  caching: {
    active: boolean;
    max_age_seconds: string;
    // Used only for displaying in the UI
    // to properly convert seconds to the correct unit
    unit?: DataRetentionUnit;
  };
  timeout?: {
    active: boolean;
    timeout_seconds: string;
  };
  retry_config?: {
    active: boolean;
  };
  error: {
    allow_4xx: boolean;
    allow_5xx: boolean;
  };
  has_raw_requests_enabled_in_node: boolean;
};

export type CustomConnectionForm = {
  verb: HttpVerb;
  path: SingleMappingBET;
  params: KeyValuePair[];
  headers: KeyValuePair[];
  body: { id: string; value: BodyValue };
  response: OutputMappingBET;
  config: CustomConnectionConfig;
};
