import React from "react";

import { Pill } from "src/base-components/Pill";

export const PlaceholderPillRenderer: React.FC<{}> = React.memo(() => {
  return (
    <div className="flex h-full w-full items-center break-all border-0 p-2">
      <Pill size="sm" variant="gray">
        <Pill.Text fontType="code">is any</Pill.Text>
      </Pill>
    </div>
  );
});
