import { faWarning } from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "src/base-components/Icon";

type PropsT = {
  type: string;
  statusCode: string;
};

export const StatusColumn: React.FC<PropsT> = ({ type, statusCode }) => {
  if (type === "successful") {
    return <span className="capitalize">Successful</span>;
  }
  return (
    <div className="flex items-center">
      <Icon color="text-red-600" icon={faWarning} padding={false} size="xs" />
      <span className="ml-1.5 min-w-0 flex-1 whitespace-nowrap">
        Failed: Status code {statusCode}
      </span>
    </div>
  );
};
