import React from "react";

import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { FormItem } from "src/base-components/FormItem";

export type WebhookURLsT = {
  productionWebhookURL: string;
  sandboxWebhookURL: string | null;
};

const Labels = {
  single: {
    label: "Webhook URL",
    description:
      "Paste this URL when the provider asks for an endpoint that should receive the Inbound Webhook",
  },
  multiple: {
    label: "Webhook URLs",
    description:
      "Paste one of the URLs when the provider asks for an endpoint that should receive the Inbound Webhook",
  },
};

const FakeInput = ({ webhookURL }: { webhookURL: string }) => {
  return (
    <div className="flex w-full flex-row justify-between rounded-lg border border-gray-200 px-3 py-2 text-gray-800 font-inter-normal-12px">
      <span className="min-w-0 truncate">{webhookURL}</span>
      <CopyTextIcon value={webhookURL} preventDefault withSidebar />
    </div>
  );
};

const EnvURL = ({
  webhookURL,
  label,
}: {
  webhookURL: string;
  label: string;
}) => {
  return (
    <div className="flex w-full flex-row items-center justify-between gap-10">
      <span className="w-24 shrink-0 text-gray-800 font-inter-semibold-12px">
        {label}
      </span>
      <div className="w-96 min-w-0 flex-1">
        <FakeInput webhookURL={webhookURL} />
      </div>
    </div>
  );
};

export const CopyableURL: React.FC<WebhookURLsT> = ({
  productionWebhookURL,
  sandboxWebhookURL,
}) => {
  if (!sandboxWebhookURL) {
    return <FakeInput webhookURL={productionWebhookURL} />;
  }

  return (
    <div className="flex w-full flex-col gap-2 border-l-2 border-gray-200 pl-4">
      <EnvURL label="Production URL" webhookURL={productionWebhookURL} />
      <EnvURL label="Sandbox URL" webhookURL={sandboxWebhookURL} />
    </div>
  );
};

export const InboundWebhookURLField: React.FC<WebhookURLsT> = ({
  productionWebhookURL,
  sandboxWebhookURL,
}) => {
  const labels = sandboxWebhookURL ? Labels.multiple : Labels.single;

  return (
    <FormItem
      description={labels.description}
      gap="sm"
      label={labels.label}
      labelClassName="mb-4"
    >
      <CopyableURL
        productionWebhookURL={productionWebhookURL}
        sandboxWebhookURL={sandboxWebhookURL}
      />
    </FormItem>
  );
};
