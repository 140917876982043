import { HighlightStyle } from "@codemirror/language";
import { tags } from "@lezer/highlight";

import { theme } from "src/styles/theme";

const baseStyleOverwrites = {
  // Prevent outline when editor is focused
  "&.cm-editor.cm-focused": {
    outline: "none",
  },
  // Prevent that selections are shown when editor is not focused
  ".cm-selectionBackground": { background: "transparent" },
} as const;

const getAutocompleteStyles = {
  ".cm-tooltip-autocomplete": {
    "border-radius": "0.5rem",
    "padding-top": "0.5rem",
    "padding-bottom": "0.5rem",
    "border-color": theme.gray200,
    "background-color": theme.white,
    display: "flex",
    "flex-direction": "column",
  },

  ".cm-tooltip-autocomplete.cm-tooltip-below": {
    transform: "translateY(2px)",
  },

  ".cm-tooltip-autocomplete.cm-tooltip-above": {
    transform: "translateY(-12px)",
  },

  ".cm-tooltip-autocomplete ul li": {
    height: "2.25rem",
    display: "flex",
    "align-items": "center",
    "padding-left": "0.625rem !important",
    "padding-right": "0.625rem !important",
    "justify-content": "space-between",
    gap: "0.5rem",
    // Set the background to gray when the user hovers over the item
    "&:hover": {
      background: theme.gray50,
    },
  },

  ".cm-tooltip.cm-tooltip-autocomplete > ul": {
    "max-height": "360px",
  },

  ".cm-tooltip-autocomplete ul li[aria-selected]": {
    background: theme.gray50,
  },

  ".cm-completionMatchedText": {
    "text-decoration": "none",
    "font-weight": "bold",
  },
} as const;

export const CodeInputTheme = {
  ...baseStyleOverwrites,
  ...getAutocompleteStyles,
  ".cm-scroller": {
    "scrollbar-width": "none",
  },
  ".cm-content .cm-line": { padding: "7px 2px 7px 6px" },
  ".cm-content": { padding: "0" },
} as const;

export const TruncateLinesTheme = {
  "&.cm-editor": {
    "min-width": "0",
    "flex-grow": "1",
  },
  ".cm-scroller > div": {
    "max-width": "100%",
  },
  ".cm-line": {
    "max-width": "100%",
    overflow: "hidden",
    "text-overflow": "ellipsis",
  },
  ".cm-placeholder": {
    "max-width": "100%",
    overflow: "hidden",
    "text-overflow": "ellipsis",
  },
} as const;

export const CursorInvisibleTheme = {
  ".cm-cursor": { display: "none !important" },
};

export const CodeEditorTheme = {
  ...baseStyleOverwrites,
  ...getAutocompleteStyles,
  "&": { height: "100%", "background-color": "inherit" },
  ".cm-activeLine": { "background-color": `${theme.gray200}50` }, // We need to make the background slightly transparent to make the line selection visible
  ".cm-scroller": { "background-color": "inherit" },
  ".cm-activeLineGutter": { "background-color": "inherit" },
  ".cm-gutters": { border: "none", "background-color": "inherit" },
  ".cm-line": { "line-height": "20px" },
  ".cm-gutterElement": { "line-height": "20px" },
};

export const SQLSyntaxHighlighting = HighlightStyle.define([
  { tag: tags.keyword, class: "tok-sql-keyword" },
  { tag: tags.special(tags.name), class: "tok-sql-specialVar" },
]);
