import { Menu } from "@headlessui/react";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

import {
  DropdownElement,
  OptionsDropdownElement,
  OptionsDropdownItems,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";

type Props = {
  elements: OptionsDropdownElement[];
  timeoutDuration?: number;
  buttonDataLoc?: string;
  placement?: "bottom-start" | "bottom-end";
  renderMenuInPlace?: boolean;
  children: React.ReactNode;
};

const getOnElementClick = (element: DropdownElement) => () => {
  element.action();
};

export const OptionsDropdown: React.FC<Props> = ({
  elements,
  timeoutDuration: timeout = 500,
  buttonDataLoc,
  placement = "bottom-end",
  renderMenuInPlace = false,
  children,
}) => {
  const [ref, setRef] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const popper = usePopper(ref, popperElement, {
    placement,
    modifiers: [{ name: "offset", options: { offset: [0, 5] } }],
  });
  return (
    <Menu as="div">
      {({ open }) => {
        const renderedItems = (
          <OptionsDropdownItems
            ref={setPopperElement}
            elements={elements}
            getOnElementClick={getOnElementClick}
            open={open}
            popper={popper}
            timeout={timeout}
          />
        );
        return (
          <>
            <Menu.Button
              ref={setRef}
              as="div"
              className="flex justify-center"
              data-loc={buttonDataLoc}
            >
              {children}
            </Menu.Button>
            {renderMenuInPlace
              ? renderedItems
              : ReactDOM.createPortal(
                  renderedItems,
                  document.body as HTMLElement,
                )}
          </>
        );
      }}
    </Menu>
  );
};
