import { keyBy } from "lodash";

import { Card } from "src/performance/Card";
import { ErrorOverlay } from "src/performance/ErrorOverlay";
import { Legend } from "src/performance/Legend";
import { NoDataOverlay } from "src/performance/NoDataOverlay";
import { Plot } from "src/performance/Plot/Plot";
import { barY, tooltip } from "src/performance/Plot/marks";
import { useDecisions } from "src/performance/queries";
import { PerformanceFilters } from "src/performance/types";
import { useFlowContext } from "src/router/routerContextHooks";
import { VersionColors } from "src/utils/getVersionsColors";

export const DecisionsChart = ({
  filters,
  colors,
}: {
  filters: PerformanceFilters;
  colors: VersionColors;
}) => {
  const { flow, workspace } = useFlowContext();
  const { timeWindow } = filters;
  const versionsById = keyBy(flow.versions, "id");

  const decisions = useDecisions(workspace.base_url, filters);

  return (
    <Card>
      <Card.Header>Number of decisions over time</Card.Header>
      <Card.Body>
        {decisions.isError && <ErrorOverlay onRetry={decisions.refetch} />}
        {decisions.isSuccess && decisions.data?.length === 0 && (
          <NoDataOverlay />
        )}
        <Plot
          data={decisions.data ?? []}
          isLoading={decisions.isFetching}
          marks={[
            barY({
              x: "bin",
              y: "value",
              fill: (d) => colors[d.version_id]?.dark,
            }),
            tooltip(
              {
                x: "bin",
                y: "value",
                title: (d) => `${versionsById[d.version_id].name}: ${d.value}`,
              },
              { stacked: true },
            ),
          ]}
          timeWindow={timeWindow}
        />
        <Legend
          items={filters.versions.map((versionId) => ({
            color: colors[versionId]?.dark,
            name: versionsById[versionId].name,
            type: "circle",
          }))}
        />
      </Card.Body>
    </Card>
  );
};
