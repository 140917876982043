import { useFormContext } from "react-hook-form";

import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { RedshiftSecretField } from "src/connections/config/database/redshift/RedshiftSecretField";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import {
  Environment,
  RedshiftConnectionConfigInputsT,
} from "src/connections/types";

type PropsT = {
  environment: Environment;
};

export const RedshiftAwsIamAuthFieldset: React.FC<PropsT> = ({
  environment,
}) => {
  const {
    formState: { errors },
  } = useFormContext<RedshiftConnectionConfigInputsT>();
  const envErrors = errors?.[`${environment}Config`]?.["awsIamConfig"];

  return (
    <FieldsetCard className="last:mb-0">
      <RedshiftSecretField
        environment={environment}
        helpText="The name of the database user to connect as. This user must have the necessary permissions to perform the desired operations on the database."
        isErrored={isFieldErrored(envErrors, "databaseUsername")}
        secretConfigField="awsIamConfig.databaseUsername"
        secretName="Database username"
        isRequired
      />
      <RedshiftSecretField
        environment={environment}
        helpText="The IAM access key ID. Together with the secret key, this forms the IAM access key."
        isErrored={isFieldErrored(envErrors, "awsAccessKeyId")}
        secretConfigField="awsIamConfig.awsAccessKeyId"
        secretName="Access key ID"
        isRequired
      />
      <RedshiftSecretField
        environment={environment}
        helpText="The IAM secret key. Together with the access key ID, this forms the IAM access key."
        isErrored={isFieldErrored(envErrors, "awsSecretKey")}
        secretConfigField="awsIamConfig.awsSecretKey"
        secretName="Secret key"
        isRequired
      />
    </FieldsetCard>
  );
};
