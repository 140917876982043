import {
  faChevronDown,
  faUserCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { twJoin } from "tailwind-merge";

import { ReviewCaseStatus, ReviewCaseStatusPending } from "src/api/types";
import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";

const COMPLETED_STATUS: ReviewCaseStatus = "completed" as const;

type StatusPickerProps = {
  value: ReviewCaseStatus;
  onChange: (value: ReviewCaseStatusPending) => void;
};

export const StatusPicker: React.FC<StatusPickerProps> = ({
  value,
  onChange,
}) =>
  // We don't show the status picker for completed cases
  // This is here just to have fallback
  value === COMPLETED_STATUS ? (
    <Status status={COMPLETED_STATUS} value={COMPLETED_STATUS} />
  ) : (
    <FixedPositionedDropdown<string, ReviewCaseStatusPending>
      buttonClassName="border-0 ring-0 focus:ring-0"
      className="w-full"
      elements={STATUSES}
      placement="bottomLeft"
      renderButtonValue={(value) => {
        const key = STATUSES.find((status) => status.value === value)?.key;
        return (
          <div className="flex w-full items-center gap-x-1 text-gray-800 font-inter-normal-12px">
            <Status status={key!} value={value} />
            <div className="shrink-0">
              <Icon icon={faChevronDown} size="3xs" />
            </div>
          </div>
        );
      }}
      renderValue={({ key, value, disabled }) => (
        <div
          className={twJoin(
            "w-[240px] px-4 py-2.5",
            disabled && "cursor-default",
          )}
        >
          <Status disabled={disabled} status={key} value={value} />
        </div>
      )}
      selected={value}
      onSelect={onChange}
    />
  );

const STATUSES: {
  key: ReviewCaseStatusPending;
  value: string;
}[] = [
  {
    key: "needs_review",
    value: "Needs manual review",
  },
  {
    key: "in_progress",
    value: "Review in progress",
  },
];

const Status: React.FC<{
  disabled?: boolean;
  status: ReviewCaseStatus;
  value: string;
}> = ({ disabled, status, value }) => {
  return (
    <div
      className={twJoin(
        "flex shrink items-center gap-x-2 truncate text-gray-800 font-inter-normal-13px",
        disabled && "cursor-default",
      )}
    >
      <Icon
        color={status === "in_progress" ? "text-green-500" : "text-indigo-500"}
        icon={faUserCircle}
        size="xs"
      />
      <span
        className={twJoin("inline-block truncate", disabled && "text-gray-300")}
      >
        {value}
      </span>
    </div>
  );
};
