import { Dictionary, keyBy } from "lodash";
import { useMemo } from "react";

import { useWorkspaces } from "src/api/queries";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/base-components/Tooltip";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { WorkspaceSimpleRoleType } from "src/clients/taktile-api";
import { OrganizationUserWorkspaceRole } from "src/store/api/types";
import { CellRendererPropsT } from "src/userManagement/types";

const WORKSPACE_ROLES: WorkspaceSimpleRoleType[] = Object.values(
  WorkspaceSimpleRoleType,
);

const WORKSPACE_ROLES_NAMES: Record<WorkspaceSimpleRoleType, string> = {
  ADMIN: "Admin",
  EDITOR: "Editor",
  VIEWER: "Viewer",
  REVIEWER: "Reviewer",
};

const splitNames = (names: string[]) => {
  const [first, rest] = [names.slice(0, 3), names.slice(3)];

  return {
    all: names.join(", "),
    first: first.join(", "),
    rest: rest.join(", "),
    more: rest.length > 0 ? ` +${rest.length} more` : "",
  };
};

export const WorkspaceRolesRenderer = ({
  getValue,
  row,
}: CellRendererPropsT<OrganizationUserWorkspaceRole[]>) => {
  const workspaces = useWorkspaces();

  const userWorkspaceRoles = getValue();
  const workspacesById: Dictionary<WorkspaceDataplane> = useMemo(
    () => (workspaces.data ? keyBy(workspaces.data, "id") : {}),
    [workspaces],
  );

  const userWorkspacesByRole = useMemo(
    () =>
      (userWorkspaceRoles || []).reduce(
        (acc, { role, workspace_id }) => ({
          ...acc,
          [role]: [...(acc[role] || []), workspace_id],
        }),
        {} as Record<WorkspaceSimpleRoleType, string[]>,
      ),
    [userWorkspaceRoles],
  );

  if (row.original.status === "deactivated") {
    return (
      <span className="block py-2 pl-2 text-left text-xs font-medium text-gray-800">
        No Access
      </span>
    );
  }

  return (
    <div className="flex flex-wrap gap-1.5 pl-2 text-left">
      {WORKSPACE_ROLES.map((role) => {
        const workspaceIds = userWorkspacesByRole[role];

        if (workspaceIds) {
          const workspaceNames: string[] = workspaceIds
            .map((wsId) => workspacesById[wsId]?.name ?? "")
            .filter((name) => Boolean(name));

          if (workspaceNames.length > 0) {
            const names = splitNames(workspaceNames);
            return (
              <Tooltip
                key={role}
                placement="top"
                title={names.rest || names.all}
                asChild
              >
                <div>
                  <Pill
                    dataLoc={`workspaces-role-${role}-user-${row.original.username}`}
                    variant="gray"
                  >
                    <Pill.Text fontType="text">
                      <span className="text-2xs text-gray-500">
                        {WORKSPACE_ROLES_NAMES[role]} at
                      </span>
                      {` ${names.first + names.more}`}
                    </Pill.Text>
                  </Pill>
                </div>
              </Tooltip>
            );
          }
        }

        return null;
      })}
    </div>
  );
};
