import { create, StoreApi, UseBoundStore } from "zustand";

type AsyncFunction<A extends any[], R> = (...args: A) => Promise<R>;

export type QueuedInvaction<A extends any[], R> = {
  args: A;
  resolve: (value: R) => void;
  reject: (reason?: any) => void;
};

// Wrap an async function with a FIFO queue. The first element of the queue holds the current in flight invocation
// for optimistic update visibilty
// Inspired by https://www.ccdatalab.org/blog/queueing-javascript-promises
export const sequentialExecutionWrapper = <A extends any[], R>(
  fn: AsyncFunction<A, R>,
): AsyncFunction<A, R> & {
  queueStoreHook: UseBoundStore<StoreApi<{ queue: QueuedInvaction<A, R>[] }>>;
} => {
  let queue: QueuedInvaction<A, R>[] = [];
  const queueStore = create<{ queue: QueuedInvaction<A, R>[] }>(() => {
    return { queue };
  });
  const shiftQueue = () => {
    queue.shift();
    queueStore.setState({ queue: queue });
  };

  const pump = () => {
    const nextInvocation = queue.at(0);
    if (!nextInvocation) return;

    fn(...nextInvocation.args)
      .then((returnValue) => {
        nextInvocation.resolve(returnValue);
        shiftQueue();
        pump();
      })
      .catch((error) => {
        nextInvocation.reject(error);
        shiftQueue();
        pump();
      });
  };

  const enqueue = (...args: A) =>
    new Promise<R>((resolve, reject) => {
      const newInvoaction = {
        args: args,
        resolve: resolve,
        reject: reject,
      };
      queue = [...queue, newInvoaction];
      queueStore.setState({ queue });
      if (queue.length === 1) pump();
    });

  enqueue.queueStoreHook = queueStore;
  return enqueue;
};
