import { faWarning } from "@fortawesome/pro-light-svg-icons";
import React, { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";

type TabListPadding = "px-4" | "none";

export type SearchParamsComponentT = {
  searchParamValue: string;
  title: string;
  component: React.ReactNode;
  icon?: React.ReactNode;
  errored?: boolean;
  dataLoc?: string;
  suffix?: React.ReactNode;
};

export type PropsT = {
  tabs: SearchParamsComponentT[];
  searchParamKey: string;
  defaultValue: string;
  containerClassName?: string;
  selectedTabClassName?: string;
  inactiveTabClassName?: string;
  componentClassName?: string;
  tabListPadding?: TabListPadding;
};

export const SearchParamsTabView: React.FC<PropsT> = ({
  tabs,
  searchParamKey,
  defaultValue,
  containerClassName,
  selectedTabClassName,
  inactiveTabClassName,
  componentClassName,
  tabListPadding = "px-4",
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParamValue = useCallback(
    (newValue: string) => {
      setSearchParams((prevSearchParams) => {
        prevSearchParams.set(searchParamKey, newValue);
        return prevSearchParams;
      });
    },
    [setSearchParams, searchParamKey],
  );

  const rightPaneParamValue = searchParams.get(searchParamKey);
  const currentValue =
    tabs.find(
      (t: SearchParamsComponentT) => t.searchParamValue === rightPaneParamValue,
    )?.searchParamValue || defaultValue;

  useEffect(() => {
    if (!currentValue) {
      setParamValue(defaultValue);
    }
  }, [currentValue, defaultValue, setParamValue]);

  const getSelectedTab = () => {
    if (!tabs) return;
    const selectedTab = tabs.find(
      (t: SearchParamsComponentT) => t.searchParamValue === currentValue,
    );
    return selectedTab ? selectedTab : tabs[0];
  };

  const renderErrored = () => (
    <div className="ml-1.5 rounded-md bg-red-100 px-1.5 py-0.5">
      <Icon color="text-red-500" icon={faWarning} size="xs" />
    </div>
  );

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div
        className={!(tabListPadding === "none") ? tabListPadding : undefined}
      >
        <div
          className={twJoin(
            "flex flex-row items-center text-gray-800 font-inter-semibold-14px",
            containerClassName,
          )}
        >
          {tabs?.map((t: SearchParamsComponentT) => {
            const isActive = currentValue === t.searchParamValue;
            return (
              <div key={t.searchParamValue}>
                <div
                  key={t.title}
                  className={twJoin(
                    "flex h-full flex-row items-center px-2 py-2 font-inter-semibold-13px",
                    isActive ? selectedTabClassName : inactiveTabClassName,
                    !isActive && "cursor-pointer",
                  )}
                  data-loc={t.dataLoc || t.title}
                  onClick={
                    !isActive
                      ? () => {
                          setParamValue(t.searchParamValue);
                        }
                      : undefined
                  }
                >
                  {t.title}
                  {t.icon}
                  {t.errored && renderErrored()}
                  {t.suffix}
                </div>
                <div
                  className={twJoin(
                    "h-1 w-full rounded-full",
                    isActive ? "bg-indigo-600" : "bg-transparent",
                  )}
                ></div>
              </div>
            );
          })}
        </div>
        <div className="-mt-0.5 h-px bg-gray-200"></div>
      </div>
      <div
        className={twJoin(
          "decideScrollbar flex-1 overflow-y-auto",
          componentClassName,
        )}
      >
        {getSelectedTab()?.component}
      </div>
    </div>
  );
};
