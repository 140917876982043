import { arrayMove } from "@dnd-kit/sortable";
import { omit } from "lodash";

import { DecisionTableCase } from "src/clients/flow-api/api";
import { DecisionTableForm } from "src/decisionTableNode/DecisionTableNodeEditor";
import {
  getDefaultCase,
  getDefaultEffect,
  getDefaultField,
  getDefaultPredicate,
} from "src/decisionTableNode/defaultValues";
import { cloneDeepWithNewIds, insert } from "src/decisionTableNode/utils";

export enum DecisionTableActions {
  INSERT_CASE_ABOVE = "INSERT_CASE_ABOVE",
  INSERT_CASE_BELOW = "INSERT_CASE_BELOW",
  DUPLICATE_CASE = "DUPLICATE_CASE",
  DELETE_CASE = "DELETE_CASE",
  ADD_DEFAULT_CASE = "ADD_DEFAULT_CASE",
  INSERT_CONDITION_LEFT = "INSERT_CONDITION_LEFT",
  INSERT_CONDITION_RIGHT = "INSERT_CONDITION_RIGHT",
  DUPLICATE_CONDITION = "DUPLICATE_CONDITION",
  DELETE_CONDITION = "DELETE_CONDITION",
  ADD_DEFAULT_CONDITION = "ADD_DEFAULT_CONDITION",
  INSERT_RESULT_LEFT = "INSERT_RESULT_LEFT",
  INSERT_RESULT_RIGHT = "INSERT_RESULT_RIGHT",
  DUPLICATE_RESULT = "DUPLICATE_RESULT",
  DELETE_RESULT = "DELETE_RESULT",
  ADD_DEFAULT_RESULT = "ADD_DEFAULT_RESULT",
  MOVE_CASE = "MOVE_CASE",
}

/**
 * Inserts a new case with default values into given position
 *
 * @param nodeData
 * @param position position to insert the new case
 * @returns new node data object with the new case inserted
 */
const insertDefaultCase = (nodeData: DecisionTableForm, position: number) => {
  const {
    predicate_fields: predicateFields,
    effect_fields: effectFields,
    cases,
  } = nodeData;

  const newCase = getDefaultCase(predicateFields, effectFields);

  return {
    ...nodeData,
    cases: insert(cases, position, newCase),
  };
};

export type AddDefaultCaseAction = {
  type: DecisionTableActions.ADD_DEFAULT_CASE;
};
export const addDefaultCase = (
  nodeData: DecisionTableForm,
): DecisionTableForm => {
  return insertDefaultCase(nodeData, nodeData.cases.length);
};

export type DeleteCaseAction = {
  type: DecisionTableActions.DELETE_CASE;
  payload: number;
};
// Or if we want it can be an ID instead of index
export const deleteCase = (
  nodeData: DecisionTableForm,
  index: number,
): DecisionTableForm => ({
  ...nodeData,
  cases: nodeData.cases.filter((_, i) => i !== index),
});

export type DuplicateCaseAction = {
  type: DecisionTableActions.DUPLICATE_CASE;
  payload: number;
};
export const duplicateCase = (nodeData: DecisionTableForm, index: number) => {
  const caseToDuplicate = nodeData.cases[index];
  const duplicatedCase = cloneDeepWithNewIds(caseToDuplicate);
  duplicatedCase.name = `${caseToDuplicate.name} (copy)`;

  return {
    ...nodeData,
    cases: insert(nodeData.cases, index + 1, duplicatedCase),
  };
};

export type InsertCaseBelowAction = {
  type: DecisionTableActions.INSERT_CASE_BELOW;
  payload: number;
};
export const insertCaseBelow = (nodeData: DecisionTableForm, index: number) => {
  return insertDefaultCase(nodeData, index + 1);
};

export type InsertCaseAboveAction = {
  type: DecisionTableActions.INSERT_CASE_ABOVE;
  payload: number;
};
export const insertCaseAbove = (nodeData: DecisionTableForm, index: number) => {
  return insertDefaultCase(nodeData, index);
};

export type MoveCaseAction = {
  type: DecisionTableActions.MOVE_CASE;
  payload: { oldIndex: number; newIndex: number };
};
export const moveCase = (
  nodeData: DecisionTableForm,
  { oldIndex, newIndex }: { oldIndex: number; newIndex: number },
) => {
  const updatedData = { ...nodeData };
  updatedData.cases = arrayMove(updatedData.cases, oldIndex, newIndex);

  return updatedData;
};

/** Conditions (decision table columns) */

/**
 * Inserts a new Condition column with default values into given position
 *
 * @param nodeData
 * @param position position to insert the new condition
 * @returns new node data object with the new condition inserted
 */
const insertDefaultCondition = (
  nodeData: DecisionTableForm,
  position: number,
) => {
  const newField = getDefaultField();

  return {
    ...nodeData,
    predicate_fields: insert(nodeData.predicate_fields, position, newField),
    cases: nodeData.cases.map<DecisionTableCase>((nodeCase) => ({
      ...nodeCase,
      predicates: {
        ...nodeCase.predicates,
        [newField.id]: getDefaultPredicate(),
      },
    })),
  };
};

export type AddDefaultConditionAction = {
  type: DecisionTableActions.ADD_DEFAULT_CONDITION;
};
export const addDefaultCondition = (
  nodeData: DecisionTableForm,
): DecisionTableForm => {
  return insertDefaultCondition(nodeData, nodeData.predicate_fields.length);
};

export type DeleteConditionAction = {
  type: DecisionTableActions.DELETE_CONDITION;
  payload: number;
};
export const deleteCondition = (
  nodeData: DecisionTableForm,
  index: number,
): DecisionTableForm => {
  const predicateToDelete = nodeData.predicate_fields[index];

  return {
    ...nodeData,
    predicate_fields: nodeData.predicate_fields.filter((_, i) => i !== index),
    cases: nodeData.cases.map((nodeCase) => ({
      ...nodeCase,
      predicates: omit(nodeCase.predicates, predicateToDelete.id),
    })),
  };
};

export type DuplicateConditionAction = {
  type: DecisionTableActions.DUPLICATE_CONDITION;
  payload: number;
};
export const duplicateCondition = (
  nodeData: DecisionTableForm,
  index: number,
) => {
  const originalField = nodeData.predicate_fields[index];
  const clonedField = cloneDeepWithNewIds(originalField);

  return {
    ...nodeData,
    predicate_fields: insert(nodeData.predicate_fields, index + 1, clonedField),
    cases: nodeData.cases.map((nodeCase) => ({
      ...nodeCase,
      predicates: {
        ...nodeCase.predicates,
        [clonedField.id]: cloneDeepWithNewIds(
          nodeCase.predicates[originalField.id],
        ),
      },
    })),
  };
};

export type InsertConditionLeftAction = {
  type: DecisionTableActions.INSERT_CONDITION_LEFT;
  payload: number;
};
export const insertConditionLeft = (
  nodeData: DecisionTableForm,
  index: number,
) => {
  return insertDefaultCondition(nodeData, index);
};

export type InsertConditionRightAction = {
  type: DecisionTableActions.INSERT_CONDITION_RIGHT;
  payload: number;
};
export const insertConditionRight = (
  nodeData: DecisionTableForm,
  index: number,
) => {
  return insertDefaultCondition(nodeData, index + 1);
};

/** Results (decision table columns) */

/**
 * Inserts a new Result column with default values into given position
 *
 * @param nodeData
 * @param position position to insert the new result
 * @returns new node data object with the new result inserted
 */
const insertDefaultResult = (nodeData: DecisionTableForm, position: number) => {
  const newField = getDefaultField();

  return {
    ...nodeData,
    effect_fields: insert(nodeData.effect_fields, position, newField),
    cases: nodeData.cases.map<DecisionTableCase>((nodeCase) => ({
      ...nodeCase,
      effects: {
        ...nodeCase.effects,
        [newField.id]: getDefaultEffect(),
      },
    })),
    fallback: {
      ...nodeData.fallback,
      [newField.id]: getDefaultEffect({ value: "None" }),
    },
  };
};
export type AddDefaultResultAction = {
  type: DecisionTableActions.ADD_DEFAULT_RESULT;
};
export const addDefaultResult = (
  nodeData: DecisionTableForm,
): DecisionTableForm => {
  return insertDefaultResult(nodeData, nodeData.effect_fields.length);
};

export type DeleteResultAction = {
  type: DecisionTableActions.DELETE_RESULT;
  payload: number;
};
export const deleteResult = (nodeData: DecisionTableForm, index: number) => {
  const effectToDelete = nodeData.effect_fields[index];

  return {
    ...nodeData,
    effect_fields: nodeData.effect_fields.filter((_, i) => i !== index),
    cases: nodeData.cases.map((nodeCase) => ({
      ...nodeCase,
      effects: omit(nodeCase.effects, effectToDelete.id),
    })),
    fallback: omit(nodeData.fallback, effectToDelete.id),
  };
};

export type DuplicateResultAction = {
  type: DecisionTableActions.DUPLICATE_RESULT;
  payload: number;
};
export const duplicateResult = (nodeData: DecisionTableForm, index: number) => {
  const originalField = nodeData.effect_fields[index];
  const clonedField = cloneDeepWithNewIds(originalField);

  return {
    ...nodeData,
    effect_fields: insert(nodeData.effect_fields, index + 1, clonedField),
    cases: nodeData.cases.map((nodeCase) => ({
      ...nodeCase,
      effects: {
        ...nodeCase.effects,
        [clonedField.id]: cloneDeepWithNewIds(
          nodeCase.effects[originalField.id],
        ),
      },
    })),
    fallback: {
      ...nodeData.fallback,
      [clonedField.id]: cloneDeepWithNewIds(
        nodeData.fallback[originalField.id],
      ),
    },
  };
};

export type InsertResultLeftAction = {
  type: DecisionTableActions.INSERT_RESULT_LEFT;
  payload: number;
};
export const insertResultLeft = (
  nodeData: DecisionTableForm,
  index: number,
) => {
  return insertDefaultResult(nodeData, index);
};

export type InsertResultRightAction = {
  type: DecisionTableActions.INSERT_RESULT_RIGHT;
  payload: number;
};
export const insertResultRight = (
  nodeData: DecisionTableForm,
  index: number,
) => {
  return insertDefaultResult(nodeData, index + 1);
};
