import {
  QueryState,
  useIsFetching,
  useQueryClient,
} from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { ListReviewCasesResponse } from "src/api/types";

export const useReviewCasesRefresh = () => {
  const queryClient = useQueryClient();
  const fetchingMetrics = useIsFetching({
    queryKey: ["reviewCases"],
    exact: false,
  });
  const [query, setQuery] =
    useState<QueryState<ListReviewCasesResponse> | null>(null);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (!fetchingMetrics) {
      setRefreshing(false);
      setQuery(
        queryClient.getQueryState<ListReviewCasesResponse>(["reviewCases"], {
          exact: false,
          type: "active",
        }) ?? null,
      );
    }
  }, [fetchingMetrics, queryClient]);

  const refresh = () => {
    setRefreshing(true);

    queryClient.invalidateQueries(["reviewCases"]);
  };

  return { dataUpdatedAt: query?.dataUpdatedAt, refreshing, refresh };
};
