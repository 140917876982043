import { makeConnectionTemplate } from "src/connections/types";

export const CodatTemplate = makeConnectionTemplate({
  provider: "codat",
  secrets: [
    {
      name: "Authentication API key",
      key: "authentication_api_key",
      // Required for single-tenant connections, and it's the only type we currently support via the UI
      required: true,
    },
  ],
  resources: {
    company_info: { name: "Company Info", configKeys: [] },
    enhanced_balance_sheet_accounts: {
      name: "Enhanced Balance Sheet Accounts",
      configKeys: [],
    },
    enhanced_profit_and_loss_accounts: {
      name: "Enhanced Profit And Loss Accounts",
      configKeys: [],
    },
    aged_creditors_report: {
      name: "Aged Creditors Report",
      configKeys: [],
    },
    aged_debtors_report: {
      name: "Aged Debtors Report",
      configKeys: [],
    },
    company_identification: {
      name: "Company Identification",
      configKeys: [],
    },
  },
});
