import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { useIsFloatingWindowPinned } from "src/base-components/FloatingWindow/hooks";
import { DETAILED_VIEW_ID } from "src/dataTable/TableUtils";
import { useFocusAddReview } from "src/flowContainer/hooks/useFocusAddReviewStore";
import { NodeEditorOptions, URLKeys } from "src/router/SearchParams";
import { useGraphStore } from "src/store/StoreProvider";

export enum RightPanes {
  Comments = "comments",
  ChangeHistory = "changeHistory",
  ReviewProcess = "reviewProcess",
}

type RightPaneOptions = {
  focusAddReview: boolean;
};

type RightPaneValue = RightPanes | null;
type RightPaneTabValue = NodeEditorOptions | null;

export const useRightPane = () => {
  const { setSelectedNode, setClickedVisibleEdgeId } = useGraphStore();
  const isDetailedViewPinned = useIsFloatingWindowPinned(DETAILED_VIEW_ID);
  const focusAddReview = useFocusAddReview();

  const [params, setParams] = useSearchParams();
  const rightPane = (params.get(URLKeys.RightPane) ?? null) as RightPaneValue;
  const rightPaneTab = (params.get(URLKeys.RightPaneTab) ??
    null) as RightPaneTabValue;

  const setRightPane = useCallback(
    (value: RightPaneValue, options?: RightPaneOptions) => {
      if (value !== null) {
        setClickedVisibleEdgeId(null);
        if (!isDetailedViewPinned) {
          setSelectedNode(null);
        }
      }
      if (options?.focusAddReview && value === RightPanes.ReviewProcess) {
        focusAddReview();
      }
      setParams((prevParams) => {
        if (value) {
          prevParams.set(URLKeys.RightPane, value);
          prevParams.delete(URLKeys.RightPaneTab);
        } else {
          prevParams.delete(URLKeys.RightPane);
        }
        return prevParams;
      });
    },
    [
      focusAddReview,
      isDetailedViewPinned,
      setClickedVisibleEdgeId,
      setParams,
      setSelectedNode,
    ],
  );

  const setRightPaneTab = useCallback(
    (value: RightPaneTabValue) => {
      setParams((prevParams) => {
        if (value) {
          prevParams.set(URLKeys.RightPaneTab, value);
        } else {
          prevParams.delete(URLKeys.RightPaneTab);
        }
        return prevParams;
      });
    },
    [setParams],
  );

  return { rightPane, rightPaneTab, setRightPane, setRightPaneTab };
};
