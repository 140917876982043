import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FormItem } from "src/base-components/FormItem";
import { SimpleDropDown } from "src/base-components/SimpleDropDown";
import {
  OutgoingWebhookConnectionAuthMethod,
  OUTGOING_WEBHOOK_CONNECTION_AUTH_METHODS,
} from "src/connections/config/CCAuthMethodDropdownOptions";
import { OAuth2Fieldset as ConfigOAuthFieldset } from "src/connections/config/OAuth2Fieldset";
import { defaultAuthMethodSecrets } from "src/connections/model/model";
import { assertUnreachable } from "src/utils/typeUtils";
import { OutgoingWebhookConnectionForm } from "src/webhooks/editModal/EditOutgoingWebhookModal";
import { ApiKeyAuthFields } from "src/webhooks/editModal/authentication/ApiKeyAuthFields";

const renderAuthMethodFields = (
  authMethod: OutgoingWebhookConnectionAuthMethod,
) => {
  switch (authMethod) {
    case "api_key":
      return <ApiKeyAuthFields />;
    case "oauth2":
      return <ConfigOAuthFieldset environmentPrefix="" />;
    case "no_auth":
      return <></>;
    default:
      assertUnreachable(authMethod);
  }
};

export const AuthFields: React.FC = () => {
  const { control, setValue, getValues, watch } =
    useFormContext<OutgoingWebhookConnectionForm>();
  const authMethod = watch("authMethod");

  const setDefaultAuthMethodSecrets = useCallback(
    (authMethod: OutgoingWebhookConnectionAuthMethod) => {
      const authMethodSecrets = defaultAuthMethodSecrets[authMethod];
      const currentBackendSecrets = getValues("currentBackendSecrets");
      // In case secrets are already filled from the BE we want to display them as filled and uneditable
      const preFilledSecrets = authMethodSecrets.map((secret) => {
        const preFilledSecret = currentBackendSecrets.find(
          (beSecret) => beSecret.key === secret.key,
        );
        if (preFilledSecret) {
          return {
            key: secret.key,
            value: preFilledSecret.value,
            editable: false,
          };
        } else
          return {
            ...secret,
            editable: true,
          };
      });
      setValue("secrets", preFilledSecrets);
      setValue("authMethod", authMethod);
    },
    [getValues, setValue],
  );
  return (
    <>
      <FormItem
        description="The authentication mechanism required for the endpoint."
        gap="sm"
        label="The authentication mechanism required for the endpoint."
        isRequired
      >
        <Controller
          control={control}
          name="authMethod"
          render={(props) => (
            <SimpleDropDown
              buttonClassName="pl-3 font-inter-normal-12px"
              className="h-8 w-full"
              dataLoc="auth-method"
              elements={[...OUTGOING_WEBHOOK_CONNECTION_AUTH_METHODS]}
              itemsClassNames="w-full"
              itemsWidth="w-full"
              placeholder="Select Authentication Type"
              placement="bottomLeft"
              selectedKey={String(props.field.value)}
              onSelect={(value) => {
                setDefaultAuthMethodSecrets(
                  value as OutgoingWebhookConnectionAuthMethod,
                );
              }}
            />
          )}
        />
      </FormItem>
      {renderAuthMethodFields(authMethod)}
    </>
  );
};
