import {
  ConnectionResourceTemplateConfigKey,
  ConnectionResourceTemplateConfigKeyParent,
} from "src/connections/types";

export const business_report_sections_parent: ConnectionResourceTemplateConfigKeyParent =
  {
    key: "business_report_sections_parent",
    title: "Configure resource sections",
    subtitle:
      "Configure which Business Report sections to include in the response",
    required: false,
  };

export const business_report_sections: ConnectionResourceTemplateConfigKey[] = [
  {
    key: "business_overview_section",
    name: "Business overview section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "phone_number_section",
    name: "Phone number section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "business_same_address_section",
    name: "Business same address section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "people_same_address_section",
    name: "People same address section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "people_same_phone_section",
    name: "People same phone section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "business_same_phone_section",
    name: "Business same phone section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "fein_section",
    name: "FEIN section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "business_finder_section",
    name: "Business finder section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "fictitious_business_name_section",
    name: "Fictitious business name section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "executive_affiliation_section",
    name: "Executive affiliation section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "business_contact_records_section",
    name: "Business contact records section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "executive_profile_section",
    name: "Executive profile section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "company_profile_section",
    name: "Company profile section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "annual_financials_section",
    name: "Annual financials section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "fundamental_ratios_section",
    name: "Fundamental ratios section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "money_service_business_section",
    name: "Money service business section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "dun_bradstreet_section",
    name: "Dun & Bradstreet section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "dun_bradstreet_pci_section",
    name: "Dun & Bradstreet PCI section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "worldbase_section",
    name: "Worldbase section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "corporate_section",
    name: "Corporate section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "executive_officers_section",
    name: "Executive officers section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "business_profile_section",
    name: "Business profile section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "global_sanction_section",
    name: "Global sanction section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "arrest_section",
    name: "Arrest section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "criminal_section",
    name: "Criminal section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "professional_license_section",
    name: "Professional license section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "world_check_section",
    name: "World check section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "infraction_section",
    name: "Infraction section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "lawsuit_section",
    name: "Lawsuit section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "lien_judgment_section",
    name: "Lien judgment section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "docket_section",
    name: "Docket section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "federal_case_law_section",
    name: "Federal case law section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "state_case_law_section",
    name: "State case law section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "bankruptcy_section",
    name: "Bankruptcy section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "real_property_section",
    name: "Real property section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "pre_foreclosure_section",
    name: "Pre foreclosure section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "business_contact_section",
    name: "Business contact section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "ucc_section",
    name: "UCC section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "sec_filing_section",
    name: "SEC filing section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "related_sec_filing_record_section",
    name: "Related SEC filing record section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "other_security_filing_record_section",
    name: "Other security filing record section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "aircraft_section",
    name: "Aircraft section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "vehicle_section",
    name: "Vehicle section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "watercraft_section",
    name: "Watercraft section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "npi_section",
    name: "NPI section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "healthcare_sanction_section",
    name: "Healthcare sanction section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "excluded_party_section",
    name: "Excluded party section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "associate_analytics_chart_section",
    name: "Associate analytics chart section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "quick_analysis_flag_section",
    name: "Quick analysis flag section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "news_section",
    name: "News section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "web_analytics_section",
    name: "Web analytics section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "marijuana_related_businesses_section",
    name: "Marijuana related businesses section",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
  {
    key: "no_match_sections",
    name: "No Mmatch sections",
    required: false,
    type: "switch",
    parent: business_report_sections_parent,
  },
];
