import { Pill } from "src/base-components/Pill";
import { UserStatus } from "src/userManagement/types";
import { assertUnreachable } from "src/utils/typeUtils";

export const Status: React.FC<{ status: UserStatus; dataLoc: string }> = ({
  status,
  dataLoc,
}) => {
  const variant = (() => {
    switch (status) {
      case "active":
        return "green";

      case "deactivated":
        return "red";

      case "invited":
        return "gray";

      default:
        assertUnreachable(status);
    }
  })();

  return (
    <div className="text-left capitalize">
      <Pill dataLoc={dataLoc} variant={variant}>
        <Pill.Text fontType="text">{status}</Pill.Text>
      </Pill>
    </div>
  );
};
