import { ConnectionT, SQLDatabaseProviderT } from "src/api/connectApi/types";
import { BigQuerySQLConnectionConfigForm } from "src/connections/config/database/bigquery/BigQuerySQLConnectionConfigForm";
import { MySQLConnectionConfigForm } from "src/connections/config/database/mysql/MySQLConnectionConfigForm";
import { PostgreSQLConnectionConfigForm } from "src/connections/config/database/postgresql/PostgreSQLConnectionConfigForm";
import { RedshiftConnectionConfigForm } from "src/connections/config/database/redshift/RedshiftConnectionConfigForm";
import { SnowflakeConnectionConfigForm } from "src/connections/config/database/snowflake/SnowflakeConnectionConfigForm";

type PropsT = {
  provider: SQLDatabaseProviderT;
  onClose: () => void;
  workspaceUrl: string;
  connection?: ConnectionT;
};

const DB_CONNECTION_CONFIG_FORMS = {
  postgresql: PostgreSQLConnectionConfigForm,
  bigquery: BigQuerySQLConnectionConfigForm,
  snowflake: SnowflakeConnectionConfigForm,
  redshift: RedshiftConnectionConfigForm,
  mysql: MySQLConnectionConfigForm,
};

export const SQLDatabaseConnectionConfigForm: React.FC<PropsT> = ({
  provider,
  onClose,
  workspaceUrl,
  connection,
}) => {
  const ConnectionConfigForm = DB_CONNECTION_CONFIG_FORMS[provider];
  return (
    <ConnectionConfigForm
      connection={connection}
      workspaceUrl={workspaceUrl}
      onClose={onClose}
    />
  );
};
