import { Modal } from "src/base-components/Modal";

type PropsT = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

export const FullscreenModal: React.FC<PropsT> = ({
  open,
  onClose,
  children,
}) => (
  <Modal className="max-h-[80vh] w-[90vw]" open={open} onClose={onClose}>
    <div className="py-8">{children}</div>
  </Modal>
);
