import React from "react";
import { twMerge } from "tailwind-merge";

import { Tooltip } from "src/base-components/Tooltip";

type PropsT = {
  children?: React.ReactNode;
  truncateOff?: boolean;
  className?: string;
  dataLoc?: string;
  withTooltip?: boolean;
  classNameOverrides?: string;
};

const DEFAULT_CLASSNAMES = "max-w-40 py-2.5 pr-4";

export const HeaderCell: React.FC<PropsT> = ({
  children,
  className,
  classNameOverrides,
  truncateOff = false,
  withTooltip = false,
  dataLoc,
}) => {
  const tooltipWrapper = ({
    children: trigger,
  }: {
    children: React.ReactNode;
  }) => (
    <Tooltip contentMaxW="none" placement="top" title={children} asChild>
      {trigger}
    </Tooltip>
  );
  const Wrapper = withTooltip ? tooltipWrapper : React.Fragment;

  return (
    <Wrapper>
      <div
        className={twMerge(
          "text-left text-gray-800 font-inter-medium-12px",
          !truncateOff && "truncate",
          DEFAULT_CLASSNAMES,
          classNameOverrides,
          className,
        )}
        data-loc={dataLoc}
      >
        {children}
      </div>
    </Wrapper>
  );
};
