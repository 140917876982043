import { last } from "lodash";

import { FieldErrorsT } from "src/api/types";
import { DecisionTableOperators, ScorecardFactor } from "src/clients/flow-api";
import { ScorecardNodeDataT } from "src/constants/NodeDataTypes";
import {
  CaseRow,
  FactorError,
  RowShape,
  ScorecardErrors,
} from "src/scorecardNode/types";

const FACTOR_NAME_PREFIX = "Factor";

export const getMaxFactorNumber = (factors: ScorecardFactor[]) => {
  return factors.reduce((max, c) => {
    if (c.name.startsWith(FACTOR_NAME_PREFIX)) {
      const nameParts = c.name.split(" ");
      const number = parseInt(last(nameParts) ?? "");

      if (number > max) {
        return number;
      }
    }

    return max;
  }, factors.length);
};

export const getNewFactorName = (factors: ScorecardFactor[]): string => {
  return `${FACTOR_NAME_PREFIX} ${getMaxFactorNumber(factors) + 1}`;
};

export const formatWeight = (weight: string) =>
  `${Number((Number.parseFloat(weight) / 100.0).toFixed(7))}`;

export const isValidWeight = (weight: string) =>
  weight !== "" && !isNaN(Number(weight));

export const transformToTableData = (factor: ScorecardFactor): RowShape[] => {
  const { cases, ...common } = factor;

  const caseRows: CaseRow[] = cases.map((factorCase) => ({
    ...common,
    ...factorCase,
  }));

  const fallbackCase: CaseRow = {
    ...common,
    id: factor.fallback.id,
    predicate: {
      id: "-",
      operator: DecisionTableOperators.IS_ANY,
      value: "",
    },
    effect: {
      id: factor.fallback.id,
      value: factor.fallback.value,
    },
  };

  if (caseRows.length === 0) {
    return [];
  }

  return [
    {
      ...caseRows[0],
      subRows: [...caseRows.slice(1), fallbackCase],
    },
  ];
};

export const validateWeight = (userValue: string): string => {
  const parsedWeight = parseFloat(userValue);

  if (isNaN(parsedWeight) || parsedWeight <= 0) {
    return "";
  } else if (parsedWeight > 100) {
    return "100";
  }

  return `${Number(parsedWeight.toFixed(5))}`;
};

export const mapFieldErrorsToScorecardErrors = (
  nodeId: string,
  nodeData: ScorecardNodeDataT,
  runFieldErrors: FieldErrorsT,
): ScorecardErrors => {
  const factorErrors = nodeData.factors.reduce(
    (acc, factor) => ({
      ...acc,
      [factor.id]: { outputFieldError: null, tableErrors: [] },
    }),
    {} as Record<string, FactorError>,
  );
  const errors: ScorecardErrors = {
    assignFieldError: null,
    totalWeightError: null,
    factorErrors: factorErrors,
  };

  Object.entries(runFieldErrors).forEach(([errorId, errorValue]) => {
    if (errorId === nodeId) {
      errors.totalWeightError = errorValue;
      return;
    }

    if (errorId === nodeData.assign_field.id) {
      errors.assignFieldError = errorValue;
      return;
    }

    nodeData.factors.forEach((factor) => {
      const factorErrors = errors.factorErrors[factor.id];
      const firstCaseId = factor.cases[0]?.id ?? "";
      const pushTableError = (id: string, col: keyof RowShape) =>
        factorErrors.tableErrors.push({
          row: id,
          col,
          message: errorValue,
        });

      if (factor.input_field.id === errorId) {
        pushTableError(firstCaseId, "input_field");
      } else if (factor.weight.id === errorId) {
        pushTableError(firstCaseId, "weight");
      } else if (factor.fallback.id === errorId) {
        pushTableError(factor.fallback.id, "effect");
      } else if (factor.output_field.id === errorId) {
        factorErrors.outputFieldError = errorValue;
      } else {
        factor.cases.forEach((factorCase) => {
          if (factorCase.effect.id === errorId) {
            pushTableError(factorCase.id, "effect");
          } else if (factorCase.predicate.id === errorId) {
            pushTableError(factorCase.id, "predicate");
          }
        });
      }
    });
  });
  return errors;
};
