import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { useFieldArray } from "react-hook-form";

import { Card } from "src/base-components/Card";
import { AddHighlightPopover } from "src/manualReviewNode/AddHighlightPopover";
import { HighlightRow } from "src/manualReviewNode/HighlightRow";
import { ManualReviewFormT } from "src/manualReviewNode/ManualReviewNodeEditor";

export const HighlightsEditor: React.FC<{
  immutable: boolean;
}> = ({ immutable }) => {
  const { fields, append, remove, move } = useFieldArray<
    ManualReviewFormT,
    "highlights"
  >({
    name: "highlights",
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const fieldToMove = fields.findIndex(
        (field) => getHighlightId(field) === active.id,
      );
      const fieldToInsert = fields.findIndex(
        (field) => getHighlightId(field) === over.id,
      );
      if (fieldToMove !== -1 && fieldToInsert !== -1) {
        move(fieldToMove, fieldToInsert);
      }
    }
  };

  return (
    <Card className="mb-6 pt-4" data-loc="manual-review-highlights-editor">
      {fields.length > 0 && (
        <ol className="mb-4 flex flex-col gap-y-4 px-4">
          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext
              disabled={immutable}
              items={fields.map(getHighlightId)}
            >
              {fields.map((highlight, index) => {
                return (
                  <HighlightRow
                    key={getHighlightId(highlight)}
                    handleRemove={() => remove(index)}
                    highlight={highlight}
                    id={getHighlightId(highlight)}
                    immutable={immutable}
                    index={index}
                  />
                );
              })}
            </SortableContext>
          </DndContext>
        </ol>
      )}
      <AddHighlightPopover append={append} immutable={immutable} />
    </Card>
  );
};

// We do not have `id` property in highlights, but that property is set by react-hook-form
// And it is not stable, react-hook-form regenerates it when we reset data from server
// This function get stable id if it is available in highlight, or returns `id` as a fallback
// Separator has no stable id, but it's ok
const getHighlightId = (
  highlight: ManualReviewFormT["highlights"][number] & { id: string },
) => {
  return highlight.type === "divider" ? highlight.id : highlight.id_value;
};
