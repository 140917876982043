import { faCut, faCopy, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { faUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { observer } from "mobx-react-lite";
import React from "react";

import { useVersionResourceLock } from "src/authoringMultiplayerLock/useVersionResourceLock";
import { ExternalLink } from "src/base-components/ExternalLink";
import { toastFailure } from "src/base-components/Toast/utils";
import { toastSuccess } from "src/base-components/Toast/utils";
import { ResourceType } from "src/clients/flow-api";
import { ParentNode } from "src/constants/NodeDataTypes";
import { NodeControlIcon } from "src/flowGraph/NodeControlIcon";
import { useCanAuthoringEditFlowVersion } from "src/hooks/useCanAuthoringEditFlowVersion";
import { getCreatableNodes } from "src/nodeAdding/PasteNodesCard";
import { useGetChildVersionLink } from "src/parentFlowNodes/useGetChildVersionLink";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { AuthorPageParamsT } from "src/router/urls";
import { useGraphStore } from "src/store/StoreProvider";
import { Subgraph } from "src/utils/GraphUtils";
import { isParentNode } from "src/utils/predicates";
import { pluralize } from "src/utils/stringUtils";
import { useParamsDecode } from "src/utils/useParamsDecode";

type PropsT = {
  onMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave: React.MouseEventHandler<HTMLDivElement>;
  id: string;
  close: () => void;
};

const ChildFlowLink: React.FC<{ nodeData: ParentNode["data"] }> = ({
  nodeData,
}) => {
  const { orgId, workspace } = useAuthoringContext();
  const getChildVersionLink = useGetChildVersionLink();
  if (!nodeData.child_flow_id || !nodeData.child_flow_version_id) return null;
  return (
    <ExternalLink
      className="text-gray-500"
      href={getChildVersionLink({
        orgId,
        workspaceId: workspace.id,
        flowId: nodeData.child_flow_id,
        versionId: nodeData.child_flow_version_id,
      })}
    >
      <NodeControlIcon
        icon={faUpRightFromSquare}
        tooltipTitle="Open in new tab"
        borderRight
      />
    </ExternalLink>
  );
};

export const getCopySuccessMessage = (
  clipboard: Subgraph,
  isCutting?: boolean,
) =>
  `${pluralize(getCreatableNodes(clipboard.nodes).length, "node")} ${
    clipboard.groups.length > 0
      ? `and ${pluralize(clipboard.groups.length, "group")} `
      : ""
  }${isCutting ? "cut" : "copied"}`;

export const NodeControls: React.FC<PropsT> = observer(
  ({ onMouseEnter, onMouseLeave, close, id }) => {
    const {
      setNodeToDelete,
      setIsDeleteNodeModalVisible,
      copyToClipboard,
      nodes,
      deleteNode,
    } = useGraphStore();
    const { wsId } = useParamsDecode<AuthorPageParamsT>();

    const { lockedByOtherUser } = useVersionResourceLock(ResourceType.NODE, id);
    const canModifyGraph = useCanAuthoringEditFlowVersion();

    const onClickDeleteButton = () => {
      setNodeToDelete(id);
      close();
      setIsDeleteNodeModalVisible(true);
    };

    const handleCopyNodeToClipboard = async (isCutting?: boolean) => {
      const node = nodes.get(id);
      if (node) {
        const copiedClipboard = await copyToClipboard([node], wsId);
        if (copiedClipboard) {
          if (isCutting) {
            try {
              await deleteNode(node, true);
            } catch {
              return copiedClipboard;
            }
          }
          toastSuccess({
            title: getCopySuccessMessage(copiedClipboard, isCutting),
          });
        } else {
          toastFailure({
            title: "Copy to clipboard failed",
            description: "See the documentation for more information",
          });
        }
      }
    };

    const node = nodes.get(id);

    return (
      <div
        className="absolute -right-2.5 top-0 flex translate-x-full flex-row overflow-hidden rounded-lg bg-white shadow-base"
        onClick={(e) => e.stopPropagation()}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {node && isParentNode(node) && <ChildFlowLink nodeData={node.data} />}
        {canModifyGraph && !lockedByOtherUser && (
          <NodeControlIcon
            dataLoc="cut-node-button"
            icon={faCut}
            tooltipTitle="Cut"
            borderRight
            onClick={() => handleCopyNodeToClipboard(true)}
          />
        )}
        {!lockedByOtherUser && (
          <NodeControlIcon
            icon={faCopy}
            tooltipTitle="Copy"
            borderRight
            onClick={() => handleCopyNodeToClipboard()}
          />
        )}
        {canModifyGraph && !lockedByOtherUser && (
          <NodeControlIcon
            dataLoc="delete-node-button"
            icon={faTrashCan}
            tooltipTitle="Delete"
            onClick={onClickDeleteButton}
          />
        )}
      </div>
    );
  },
);
