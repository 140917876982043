import { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { useEffect, useRef } from "react";

import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { EditorCellState } from "src/base-components/EditorTable/types";

export const FactorCodeInput: React.FC<{
  value: string;
  onChange: (newVal: string) => void;
  placeholder: string | undefined;
  state: EditorCellState;
}> = ({ state: { isSelected, isEditing, readonly }, ...props }) => {
  const ref = useRef<ReactCodeMirrorRef>(null);

  useEffect(() => {
    if (ref.current && isSelected) {
      ref.current.view?.focus();
    }
  }, [isSelected]);

  useEffect(() => {
    if (ref.current && isEditing) {
      ref.current.view?.focus();
    }
  }, [isEditing]);

  return (
    <AutocompleteCodeInput
      {...props}
      ref={ref}
      disabled={readonly}
      variant="cell"
    />
  );
};
