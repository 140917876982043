import EquifaxCanadaIcon from "src/assets/EquifaxCanadaIcon.svg?react";
import { getDefaultEquifaxCanadaConsumerCreditFile } from "src/connections/connectionConfigs/equifaxCanada/equifaxCanadaResources";
import { EquifaxCanadaTemplate } from "src/connections/connectionConfigs/equifaxCanada/equifaxCanadaTemplate";
import { ConnectionConfig } from "src/connections/connectionConfigs/types";

export const EQUIFAX_CANADA_CONFIG: ConnectionConfig<"equifax_canada"> = {
  icon: EquifaxCanadaIcon,
  description:
    "Make more accurate credit decisions with robust consumer credit data",
  template: EquifaxCanadaTemplate,
  name: "Equifax (Canada)",
  resources: {
    equifax_canada_consumer_credit_file: {
      externalLink:
        "https://www.notion.so/taktile/Consumer-Credit-File-499ab817d431454b8446cef9d65126ce?pvs=4",
      integrationResource: getDefaultEquifaxCanadaConsumerCreditFile,
    },
  },
};
