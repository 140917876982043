import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowDbShallow, FlowVersionDbShallow } from "src/clients/flow-api";
import {
  SourceDetailType,
  SourceDetails,
} from "src/flow/decisionHistory/SourcePill";
import { Job } from "src/jobs/types";

export const getSourceDetails = (
  record: DecisionHistoryRecordV2,
  parentFlows: FlowDbShallow[],
  potentialParentVersions: FlowVersionDbShallow[],
  jobs: Job[],
): SourceDetails => {
  if (
    record.parent_flow_id &&
    record.parent_flow_version_id &&
    record.parent_decision_id
  ) {
    const parentFlow = parentFlows.find(
      (parentFlow) => parentFlow.id === record.parent_flow_id,
    );
    const parentVersion = potentialParentVersions?.find(
      (potentialParentVersion) =>
        potentialParentVersion?.id === record.parent_flow_version_id,
    );
    return {
      type: SourceDetailType.ParentFlow,
      parentFlow,
      parentVersion,
      parentDecisionId: record.parent_decision_id,
    };
  }
  if (record.response.metadata.job_run_id) {
    const job = jobs.find(
      (job) => job.id === record.response?.metadata?.job_id,
    );
    return {
      type: SourceDetailType.Job,
      job: job,
      runId: record.response.metadata.job_run_id,
    };
  } else return { type: SourceDetailType.ApiCall };
};
