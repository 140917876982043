import axios from "axios";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { FlowT } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Modal } from "src/base-components/Modal";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import {
  FolderDropdownSelection,
  SelectFolderDropdown,
} from "src/flowsOverview/v2/SelectFolderDropdown";
import { FOLDER_NAME_NOT_AVAILABLE_MESSAGE } from "src/flowsOverview/v2/folderModals/EditFolderModal";
import { useFolders } from "src/flowsOverview/v2/folderQueries";
import { wrapWithAxiosResponseErrorHandler } from "src/utils/toastError";

type Props = {
  open: boolean;
  workspaceId: string;
  flow?: FlowT;
  onClose: () => void;
  onConfirm: (data: Form) => Promise<void>;
  onAddFolder: () => void;
};

type Form = {
  folderSelection: FolderDropdownSelection;
};

export const MoveFlowModal: React.FC<Props> = ({
  open,
  workspaceId,
  flow,
  onClose,
  onConfirm,
}) => {
  const folders = useFolders({ workspaceId }).data?.folders;
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    reset,
  } = useForm<Form>({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    // Everytime the Modal is reopened with we need to reset the form
    if (open) {
      reset({
        folderSelection: {
          mode: "selectFolder",
          folderId: flow?.flow_folder_id,
        },
      });
    }
  }, [reset, open, flow?.flow_folder_id]);

  const onModalConfirm = async (data: Form) => {
    await onConfirm(data);
    onClose();
  };

  const onSubmit = handleSubmit(
    wrapWithAxiosResponseErrorHandler(async (data: Form) => {
      try {
        await onModalConfirm(data);
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 409) {
          setError(
            "folderSelection",
            { type: "notAvailable" },
            { shouldFocus: true },
          );
        } else {
          throw e;
        }
      }
    }),
  );

  const currentFolder = folders?.find(
    (folder) => folder.id === flow?.flow_folder_id,
  );

  return (
    <Modal
      className="w-120"
      open={open}
      subtitle="Select a folder to move your Decision Flow"
      title={`Move ${flow?.name} ${
        currentFolder !== undefined ? `from ${currentFolder.name}` : ""
      }`}
      closeIcon
      onClose={onClose}
    >
      <form onSubmit={onSubmit}>
        <div className="px-6 py-4">
          <div className="mb-1">
            <p className="text-gray-800 font-inter-semibold-13px">
              Folder name <RequiredAsterisk />
            </p>
            {errors.folderSelection && (
              <ErrorHint margin="none">
                {FOLDER_NAME_NOT_AVAILABLE_MESSAGE}
              </ErrorHint>
            )}
          </div>
          <Controller
            control={control}
            name="folderSelection"
            render={(fieldProps) => {
              return (
                <SelectFolderDropdown
                  folders={folders || []}
                  selected={fieldProps.field.value}
                  onSelect={(selection) => {
                    if (
                      selection.mode === "selectFolder" &&
                      fieldProps.field.value?.mode === "selectFolder" &&
                      fieldProps.field.value?.folderId === selection.folderId
                    ) {
                      fieldProps.field.onChange({
                        mode: "selectFolder",
                        folderId: null,
                      });
                    } else {
                      fieldProps.field.onChange(selection);
                    }
                  }}
                />
              );
            }}
          />
        </div>
        <div className="mb-6 flex flex-row items-center justify-end gap-x-2 pr-6 pt-6 text-right">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            dataLoc="move-flow-save"
            disabled={!isEmpty(errors)}
            htmlType="submit"
            loading={isSubmitting}
            variant="primary"
          >
            Move
          </Button>
        </div>
      </form>
    </Modal>
  );
};
