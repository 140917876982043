import { Button } from "src/base-components/Button";
import { ReviewDb, ReviewRequestStatus } from "src/clients/flow-api";
import { Modal } from "src/design-system/Modal";
import { useFlowVersionReviewPatch } from "src/flowReview/api/queries";
import { useAuthoringContext } from "src/router/routerContextHooks";

type Props = {
  review: ReviewDb;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const CancelReviewModal: React.FC<Props> = ({
  review,
  isOpen,
  onClose,
  onConfirm,
}) => {
  const { mutateAsync: updateReview, isLoading } = useFlowVersionReviewPatch();
  const { flow, version } = useAuthoringContext();

  const handleConfirm = async () => {
    if (review) {
      await updateReview({
        reviewId: review.id,
        data: {
          review_status: ReviewRequestStatus.CANCELLED,
          flow_version_etag: version.etag ?? "",
        },
        flowId: flow.id,
      });
      onClose();
      onConfirm();
    }
  };

  return (
    <Modal open={isOpen} variant="danger" onClose={onClose}>
      <Modal.Header>Cancel review request?</Modal.Header>
      <Modal.Content>
        <div className="flex flex-col gap-y-2.5 text-gray-500 font-inter-normal-13px">
          <p>
            Are you sure you want to cancel the review request? If you cancel:
          </p>
          <ul className="ml-6 list-disc">
            <li>No additional reviews can be added to this review process.</li>
            <li>The version status will be changed back to draft.</li>
          </ul>
          <p>
            You can still view the cancelled review process and start a new one
            at any time. Please confirm if you wish to cancel the request.
          </p>
        </div>
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            aria-label="Confirm cancellation"
            dataLoc="modal-primary-button"
            loading={isLoading}
            role="button"
            variant="warning"
            onClick={handleConfirm}
          >
            Confirm cancellation
          </Button>
        }
        secondaryButton="Cancel"
      />
    </Modal>
  );
};
