import {
  IconDefinition,
  faCircleNotch,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MouseEventHandler,
  ReactNode,
  ButtonHTMLAttributes,
  forwardRef,
} from "react";
import { twJoin, twMerge } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { assertUnreachable } from "src/utils/typeUtils";

export type ButtonVariant = "primary" | "secondary" | "warning" | "tertiary";

export type ButtonProps = {
  variant?: ButtonVariant;
  loading?: boolean;
  children?: ReactNode;
  iconLeft?: IconDefinition;
  iconRight?: IconDefinition;
  disabled?: boolean;
  size?: "sm" | "base";
  dataLoc?: string;
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  onClick?: MouseEventHandler<HTMLButtonElement>;
  fullWidth?: boolean;
  tabIndex?: number;
  id?: string;
  role?: "button" | "submit";
  "aria-label"?: string;
};

const baseClasses = "group gap-2 antialiased";
const sizeClasses = {
  sm: "px-2.5 py-1 h-6 font-inter-medium-12px rounded-md",
  base: "px-4 py-2 h-8 font-inter-medium-13px rounded-lg",
};
const solidDisableClasses =
  "bg-gray-100 text-gray-500 cursor-not-allowed shadow-none";
const outlineDisableClasses =
  "bg-white text-gray-400 cursor-not-allowed shadow-none border border-gray-300";
const iconBaseClass =
  "flex h-2 w-2 items-center text-center text-gray-600 text-[10px] cursor-pointer";

const resolveVariantClasses = (
  variantClasses: string,
  disabledClasses: string,
  disabled: boolean,
) => twJoin(!disabled && variantClasses, disabled && disabledClasses);

const resolveButtonClasses = (
  variant: "primary" | "secondary" | "warning" | "tertiary",
  disabled: boolean,
) => {
  switch (variant) {
    case "primary":
      return resolveVariantClasses(
        "bg-indigo-600 text-white shadow-sm hover:bg-indigo-700 hover:text-white shadow-sm",
        solidDisableClasses,
        disabled,
      );
    case "secondary":
      return resolveVariantClasses(
        "bg-white border border-gray-300 shadow-sm",
        outlineDisableClasses,
        disabled,
      );
    case "warning":
      return resolveVariantClasses(
        "bg-red-600 text-white hover:bg-red-700 shadow-sm",
        solidDisableClasses,
        disabled,
      );
    case "tertiary":
      return resolveVariantClasses(
        "text-indigo-700 border border-indigo-400 shadow-sm",
        outlineDisableClasses,
        disabled,
      );
    default:
      assertUnreachable(variant);
  }
};

/**
 * Icons of primary buttons have a different color when not on hover or when disabled.
 */
const resolveIconClasses = (
  variant: "primary" | "secondary" | "warning" | "tertiary",
  disabled: boolean,
) => {
  if (variant === "primary" || variant === "warning") {
    return twJoin(
      iconBaseClass,
      !disabled && "text-white group-hover:text-white",
      disabled && "text-gray-400",
    );
  } else if (variant === "tertiary") {
    return twJoin(
      iconBaseClass,
      !disabled && "text-indigo-700",
      disabled && "text-gray-400",
    );
  } else {
    return twMerge(iconBaseClass, disabled && "text-gray-400");
  }
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      dataLoc,
      disabled = false,
      fullWidth = false,
      htmlType = "button",
      iconLeft,
      iconRight,
      loading = false,
      onClick,
      size = "base",
      variant = "primary",
      tabIndex,
      id,
      role = "button",
      "aria-label": ariaLabel,
    },
    ref,
  ) => {
    const buttonSizeClasses = sizeClasses[size] || sizeClasses.base;
    const buttonTypeClasses = resolveButtonClasses(variant, disabled);
    const iconClasses = resolveIconClasses(variant, disabled);

    return (
      <button
        ref={ref}
        aria-label={ariaLabel}
        className={twJoin(
          fullWidth && "w-full",
          "relative flex items-center justify-center focus:border-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500/25",
          baseClasses,
          buttonSizeClasses,
          buttonTypeClasses,
        )}
        data-loc={dataLoc}
        disabled={disabled}
        id={id}
        role={role}
        tabIndex={tabIndex}
        type={htmlType}
        onClick={onClick}
      >
        {iconLeft && (
          <span className={twJoin(iconClasses, loading && "invisible")}>
            <FontAwesomeIcon icon={iconLeft} />
            {/* <Icon icon={iconLeft} size="xs" /> */}
          </span>
        )}
        <span className={twJoin(loading && "invisible")}>{children}</span>
        {iconRight && (
          <span className={twJoin(iconClasses, loading && "invisible")}>
            <FontAwesomeIcon icon={iconRight} />
          </span>
        )}
        {loading && (
          <div className="absolute">
            <Icon icon={faCircleNotch} size="xs" spin />
          </div>
        )}
      </button>
    );
  },
);
