import { DataRetentionUnit } from "src/clients/flow-api";

export const DATA_RETENTION_UNITS = [
  DataRetentionUnit.MINUTES,
  DataRetentionUnit.HOURS,
  DataRetentionUnit.DAYS,
  DataRetentionUnit.WEEKS,
  DataRetentionUnit.MONTHS,
  DataRetentionUnit.YEARS,
];
