import { create } from "zustand";

type OmniboxState = {
  isOmniboxVisible: boolean;
};
type OmniboxActions = {
  showOmnibox: () => void;
  hideOmnibox: () => void;
};
type OmniboxStore = ZustandStore<OmniboxState, OmniboxActions>;
const useOmniboxStore = create<OmniboxStore>((set) => ({
  isOmniboxVisible: false,
  actions: {
    showOmnibox: () => set({ isOmniboxVisible: true }),
    hideOmnibox: () => set({ isOmniboxVisible: false }),
  },
}));
export const useOmniboxActions = () =>
  useOmniboxStore((state) => state.actions);
export const useIsOmniboxVisible = () =>
  useOmniboxStore((state) => state.isOmniboxVisible);
