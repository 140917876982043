import { twJoin } from "tailwind-merge";

import { useOrganizationUser } from "src/api/taktile/queries";
import { Tooltip } from "src/base-components/Tooltip";
import { AuthorPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type CanvasLockProps = {
  lockOwner: string;
  resource: "sidebar" | "node";
};

export const CanvasLock: React.FC<CanvasLockProps> = ({
  lockOwner,
  resource,
}) => {
  const { orgId } = useParamsDecode<AuthorPageParamsT>();
  const editingUser = useOrganizationUser(orgId, lockOwner);

  return (
    <div
      className={twJoin(
        "absolute -z-10",
        resource === "sidebar" && "right-[-2.2rem] top-2",
        resource === "node" && "right-[-1.71rem] top-3",
      )}
    >
      <Tooltip
        align="center"
        body={`is currently editing this ${
          resource === "node" ? "Node" : "property"
        }...`}
        placement="top"
        title={editingUser.data?.username ?? "Someone else "}
      >
        <div className="flex h-[22px] w-[27px] items-center rounded-r-xl bg-white shadow-sm">
          {editingUser.data?.avatar_url && (
            <img
              alt=""
              className="ml-[7px] aspect-square w-4 rounded-full"
              src={editingUser.data?.avatar_url}
            />
          )}
        </div>
      </Tooltip>
    </div>
  );
};
