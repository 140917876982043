import { m } from "framer-motion";
import { twJoin } from "tailwind-merge";

const COLORS = {
  green: "bg-green-600",
  red: "bg-red-600",
  gray: "bg-gray-300",
  "light-gray": "bg-gray-100",
  neutral: "bg-gray-300",
  indigo: "bg-indigo-500",
  yellow: "bg-yellow-400",
};

type Props = {
  bars: {
    color: keyof typeof COLORS;
    count: number;
  }[];
  /**
   * Use color only for this bar, the rest
   * will be gray. If its undefined all
   * will have color
   */
  highlightOnly?: keyof typeof COLORS;
};

export const Breakdown: React.FC<Props> = ({ bars, highlightOnly }) => {
  const total = bars.reduce((acc, { count }) => acc + count, 0);

  // The > 0 checks are there so the gap doesn't exist if
  // there is no bar
  return (
    <div className="flex flex-1 gap-x-0.5" role="progressbar">
      {bars
        .filter((bar) => bar.count > 0)
        .map(({ color, count }) => (
          <m.div
            key={color}
            animate={{ width: `${getPercentage(count, total)}%` }}
            aria-valuemin={0}
            aria-valuenow={count}
            className={twJoin(
              "h-[3px] rounded-[100px]",
              !highlightOnly || highlightOnly === color
                ? COLORS[color]
                : "bg-gray-100",
            )}
            initial={false}
            role="meter"
          />
        ))}
    </div>
  );
};

const getPercentage = (count: number, total: number) => (count / total) * 100;
