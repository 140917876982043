import { useCallback, useEffect, useState } from "react";

import { FloatingEditorCodeInput } from "src/base-components/EditorTable/FloatingEditorCodeInput";
import { StringEditor } from "src/base-components/EditorTable/StringEditor";
import { EditorCellProps } from "src/base-components/EditorTable/types";

type Props<T> = EditorCellProps<T, string>;

export const CellStringEditorWithoutAutocomplete = <T,>(props: Props<T>) => (
  <StringEditor {...props} placeholder={props.columnDef.cell.placeholder} />
);

export const CellStringEditor = <T,>({ onChange, ...props }: Props<T>) => {
  const [userValue, setUserValue] = useState(props.value);

  const handleEditorChange = useCallback((value: string) => {
    setUserValue(value);
  }, []);

  const handleEditorBlur = useCallback(() => {
    onChange(userValue);
  }, [userValue, onChange]);

  useEffect(() => {
    if (!props.state.isEditing) {
      setUserValue(props.value);
    }
    // disabling exhaustive-deps rule because we want to trigger this effect only when props.value changes,
    // syncing props.value on isEditing change is not expected
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const placeholder = props.columnDef.cell.placeholder;

  return (
    <div className="flex h-full min-h-[41px] w-full items-center gap-x-1.5 py-1 pr-2">
      <FloatingEditorCodeInput
        bgColor={props.state.isHighlighted ? "bg-green-50" : undefined}
        cellId={props.cellId}
        cellRef={props.cellRef}
        disabled={props.state.readonly}
        isEditing={props.state.isEditing}
        isSelected={props.state.isSelected}
        placeholder={placeholder}
        value={userValue}
        onBlur={handleEditorBlur}
        onChange={handleEditorChange}
      />
    </div>
  );
};
