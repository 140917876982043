import { useDraggable } from "@dnd-kit/core";
import {
  faBook,
  faFolder,
  faFolderMinus,
  faLink,
  faPen,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import React, { useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useHover } from "usehooks-ts";

import { startsWithInternalPrefix } from "src/api/constants";
import { FlowT, FlowVersionStatusT } from "src/api/flowTypes";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import {
  MENU_DIVIDER,
  OptionsDropdownElement,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { Pill } from "src/base-components/Pill";
import { RelatedFlowsPill } from "src/flow/Pills";
import { UserTip } from "src/flowReview/ReviewStatusPills";
import { useFlowVersionReviews } from "src/flowReview/api/queries";
import { useCapabilities } from "src/hooks/useCapabilities";
import { DashboardPageParamsT } from "src/router/urls";
import { versionIsInReview } from "src/utils/flowVersion";
import { pluralize, sentanceList } from "src/utils/stringUtils";
import { useParamsDecode } from "src/utils/useParamsDecode";

export type FlowCardPropsT = {
  flow: FlowT;
  onViewFlow: (flow: FlowT) => void;
  onRename: (flow: FlowT) => void;
  onCopy: (flow: FlowT) => void;
  onDelete: (flow: FlowT) => void;
  onViewApiDocs: (flow: FlowT) => void;
  onMove?: (flow: FlowT) => void;
  onRemoveFromFolder?: (flow: FlowT) => void;
};

const ReviewPillBody: React.FC<{
  orgId: string;
  versionsInReviewIds: string[];
}> = ({ orgId, versionsInReviewIds }) => {
  const reviews = useFlowVersionReviews(versionsInReviewIds);
  const reviewers = reviews.flatMap((r) => {
    return Object.values(r.data?.reviewers_status || {});
  });
  const uniqueReviewerIds = [...new Set(reviewers.map((r) => r.reviewer_id))];
  const uniqueReviewers = uniqueReviewerIds.map((id) => {
    return reviewers.find((r) => r.reviewer_id === id);
  });
  const allFetched = reviews.every((r) => r.isSuccess);
  const errored = reviews.some((r) => r.isError);
  if (!allFetched || errored) return null;
  return (
    <div className="max-w-60">
      Review requested from{" "}
      {sentanceList(
        [...uniqueReviewers].map((r) => (
          <>
            {r && (
              <span className="whitespace-nowrap">
                <UserTip
                  key={r.reviewer_id}
                  orgId={orgId}
                  userId={r.reviewer_id}
                />
              </span>
            )}
          </>
        )),
      )}
    </div>
  );
};

const VersionStatusPills: React.FC<{
  publishedCount: number;
  draftCount: number;
  versionsInReviewIds: string[];
  orgId: string;
}> = ({ publishedCount, draftCount, versionsInReviewIds, orgId }) => {
  const reviewCount = versionsInReviewIds.length;
  return (
    <div className="flex items-center overflow-hidden">
      {publishedCount > 0 && (
        <Pill
          size="base"
          title={pluralize(publishedCount, "live version")}
          variant="outlined-white"
        >
          <Pill.Icon color="text-green-400" icon={faCircle} size="sm" />
          <Pill.Text>{publishedCount}</Pill.Text>
        </Pill>
      )}
      {reviewCount > 0 && (
        <div className={publishedCount > 0 ? "-ml-1.5" : ""}>
          <Pill
            body={
              <ReviewPillBody
                orgId={orgId}
                versionsInReviewIds={versionsInReviewIds}
              />
            }
            size="base"
            title={pluralize(reviewCount, "version") + " in review"}
            variant="outlined-white"
          >
            <Pill.Icon color="text-indigo-400" icon={faCircle} size="sm" />
            <Pill.Text>{reviewCount}</Pill.Text>
          </Pill>
        </div>
      )}
      {draftCount > 0 && (
        <div className={publishedCount > 0 || reviewCount > 0 ? "-ml-1.5" : ""}>
          <Pill
            size="base"
            title={pluralize(draftCount, "draft version")}
            variant="outlined-white"
          >
            <Pill.Icon color="text-yellow-400" icon={faCircle} size="sm" />
            <Pill.Text>{draftCount}</Pill.Text>
          </Pill>
        </div>
      )}
    </div>
  );
};

export const FlowCardV2: React.FC<FlowCardPropsT> = ({
  flow,
  onViewFlow,
  onRename,
  onCopy,
  onDelete,
  onViewApiDocs,
  onMove,
  onRemoveFromFolder,
}) => {
  const { orgId } = useParamsDecode<DashboardPageParamsT>();
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: flow.id,
  });
  const { flows } = useCapabilities();
  const hoverRef = useRef<HTMLDivElement | null>(null);
  const isHovered = useHover<HTMLDivElement>(hoverRef);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const isInternal = startsWithInternalPrefix(flow.slug);

  const publishedCount = flow.versions.filter(
    (v) => v.status === FlowVersionStatusT.PUBLISHED,
  ).length;

  const draftCount = flow.versions.filter(
    (v) => v.status === FlowVersionStatusT.DRAFT && !versionIsInReview(v),
  ).length;

  const versionsInReviewIds = flow.versions
    .filter((v) => versionIsInReview(v))
    .map((v) => v.id);

  const dropDownOptions: OptionsDropdownElement[] = (() => {
    const options: OptionsDropdownElement[] = flows.canEdit
      ? [{ key: "Edit details", icon: faPen, action: () => onRename(flow) }]
      : [];
    options.push({
      key: "Copy link",
      icon: faLink,
      action: () => onCopy(flow),
    });

    if (flows.canEdit) {
      if (onMove) {
        options.push({
          key: "Move to another folder",
          icon: faFolder,
          action: () => onMove(flow),
        });
      }
      if (flow.flow_folder_id && onRemoveFromFolder) {
        options.push({
          key: "Remove from folder",
          icon: faFolderMinus,
          action: () => onRemoveFromFolder(flow),
        });
      }
    }

    options.push({
      key: "View API docs",
      icon: faBook,
      action: () => onViewApiDocs(flow),
    });
    if (
      (publishedCount === 0 && flows.canDeleteUnpublished) ||
      (publishedCount > 0 && flows.canDeletePublished)
    ) {
      options.push(MENU_DIVIDER, {
        key: "Delete",
        icon: faTrashCan,
        action: () => onDelete(flow),
      });
    }

    return options;
  })();

  return (
    <div
      ref={(ref) => {
        hoverRef.current = ref;
        setNodeRef(ref);
      }}
      className="flex h-56 cursor-pointer flex-col gap-y-2 rounded-lg bg-white px-6 py-5 shadow-sm"
      data-loc={`${flow.name}-card`}
      onClick={() => onViewFlow(flow)}
      {...listeners}
      {...attributes}
    >
      <div data-loc={`${flow.name}-success`}>
        <h3
          className={twJoin(
            "mb-2 line-clamp-3 text-gray-800 font-inter-semibold-14px",
            isInternal && "flex justify-between",
          )}
          data-loc="flow-card-name"
        >
          {flow.name}
          {isInternal && (
            <Pill size="sm" variant="red">
              <Pill.Text>Internal</Pill.Text>
            </Pill>
          )}
        </h3>
        <p className="line-clamp-4 text-gray-500 font-inter-normal-12px">
          {!!flow.meta?.description
            ? flow.meta.description
            : "No description yet"}
        </p>
      </div>
      <div className="mt-auto flex items-center gap-x-3">
        <VersionStatusPills
          draftCount={draftCount}
          orgId={orgId}
          publishedCount={publishedCount}
          versionsInReviewIds={versionsInReviewIds}
        />
        {flow.child_flows &&
          flow.parent_flows &&
          (flow.child_flows.length > 0 || flow.parent_flows.length > 0) && (
            <div className="mt-[1px] flex gap-x-2">
              <RelatedFlowsPill
                flows={
                  flow.child_flows.length > 0
                    ? flow.child_flows
                    : flow.parent_flows
                }
                isParent={flow.child_flows.length > 0}
                countOnly
              />
            </div>
          )}
        <div
          className={twJoin(
            "ml-auto transition-opacity",
            isHovered || isMenuVisible ? "opacity-100" : "opacity-0",
          )}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <EllipsisOptionsDropdown
            buttonClassName="text-gray-500"
            buttonDataLoc={`${flow.name}-menu`}
            elements={dropDownOptions}
            placement="bottom-start"
            onOpenChanged={(isOpen) => setMenuVisible(isOpen)}
          />
        </div>
      </div>
    </div>
  );
};
