import { v4 as uuidV4 } from "uuid";

import { CRSResourceT } from "src/api/connectApi/types";
import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  InputMappingGroupT,
  InputMappingListT,
  InputMappingT,
  IntegrationResourceT,
} from "src/integrationNode/types";

const getDefaultCRSExperianCreditReportAddress = (): {
  [key: string]: InputMappingT;
} => ({
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `1-48 characters, only a-z, A-Z, 0-9, and -⎵./#`,
  },
  affix: {
    id: uuidV4(),
    type: "text",
    displayName: "Affix",
    assignedTo: "",
    hint: `The house number affix (e.g., "APT 6", "# 5"). 0-60 characters, only a-z, A-Z, 0-9, and -⎵./#`,
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `5-10 character postal or zip code`,
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `1-28 characters, only a-z, A-Z and spaces allowed`,
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `A two-letter code for the U.S. state or territory of the address. Note that some U.S. states require more data for identifying a consumer than others. For example, Rhode Island prohibits using only the SSN to identify a person.`,
  },
});

const getDefaultCRSExperianBusinessSearchAddress = (): {
  [key: string]: InputMappingT;
} => {
  const { street, postal_code, city, state } =
    getDefaultCRSExperianCreditReportAddress();

  street.rules = {
    ...street.rules,
    required: false,
  };
  postal_code.rules = {
    ...postal_code.rules,
    required: false,
  };

  city.hint = undefined;
  street.hint = undefined;

  return { street, postal_code, city, state };
};

const getDefaultCRSPhoneInput = (): InputMappingGroupT => ({
  getDefaultElements: () => ({
    number: {
      id: uuidV4(),
      type: "text",
      displayName: "Number",
      assignedTo: "",
      rules: {
        required: true,
      },
      hint: `10-digit phone number (e.g., "1112223334")`,
    },
  }),
});

const getDefaultCRSAddressList = (type: CRSResourceT): InputMappingListT => {
  const addressFunc =
    type === "experian_business_search"
      ? getDefaultCRSExperianBusinessSearchAddress
      : getDefaultCRSExperianCreditReportAddress;

  return {
    displayName: "address",
    rules: {
      required: true,
    },
    getDefaultElement: addressFunc,
    elements: [addressFunc()],
  };
};

export const getDefaultCRSExperianCreditReport = (): IntegrationResourceT => ({
  providerResource: {
    provider: "crs",
    resource: "experian_credit_report",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      first_name: {
        id: uuidV4(),
        type: "text",
        displayName: "First name",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: `1-20 characters`,
      },
      middle_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Middle name",
        assignedTo: "",
        hint: `1-20 characters, only one word allowed.`,
      },
      last_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Last name",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: `1-32 characters`,
      },
      name_suffix: {
        id: uuidV4(),
        type: "text",
        displayName: "Name suffix",
        assignedTo: "",
        hint: `One of: Jr, Sr, III, IV, V, VI, VII, VIII, IX, X`,
      },
      date_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Date of birth",
        assignedTo: "",
        hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20". Omitting this field may lead to a "weak match": Parts of the summaries section of the expected report response may be missing.`,
      },
      social_security_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Social security number",
        assignedTo: "",
        hint: `The individual's 9-digit social security number (SSN), formatted as a simple string of integers (e.g., "123456789").`,
      },
    },
    grouped: {
      phone: getDefaultCRSPhoneInput(),
    },
    lists: {
      addresses: getDefaultCRSAddressList("experian_credit_report"),
    },
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      fico_8_score: {
        ...getNamedOutputMapping("FICO 8 Score"),
        hint: `The FICO Score is a credit score that is widely used by lenders to assess a person's creditworthiness. The score ranges from 300 to 850 and is based on higher scores indicating better credit health.
      The main difference between FICO 8 and FICO 9 is how they treat medical debt collections and rent payments. In FICO 9, medical collections have less impact, and rent payments are taken into account, helping with thin-file credit assessment.
      Note that it depends on your agreement with CRS which of the scores will be part of the response.`,
      },
      fico_9_score: {
        ...getNamedOutputMapping("FICO 9 Score"),
        hint: `The FICO Score is a credit score that is widely used by lenders to assess a person's creditworthiness. The score ranges from 300 to 850 and is based on higher scores indicating better credit health.
        The main difference between FICO 8 and FICO 9 is how they treat medical debt collections and rent payments. In FICO 9, medical collections have less impact, and rent payments are taken into account, helping with thin-file credit assessment.
        Note that it depends on your agreement with CRS which of the scores will be part of the response.`,
      },
      vantage_4_score: {
        ...getNamedOutputMapping("Vantage 4 Score"),
        hint: `VantageScore is a credit score that ranges from 300 to 850 and is used to assess a person's creditworthiness. A higher VantageScore indicates a better credit history, with a score above 700 generally considered good, and scores over 800 excellent.`,
      },
      provider_request_id: getNamedOutputMapping("Provider request ID"),
      retrieval_datetime: getNamedOutputMapping("Retrieval date-time (UTC)"),
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultCRSExperianBusinessSearch =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "crs",
      resource: "experian_business_search",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        company_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Company name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        tax_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Tax ID",
          assignedTo: "",
          hint: `A federal tax identification number, also known as an Employer Identification Number (EIN), is utilized to identify a business entity, as well as estates and trusts that are required to report their income on Form 1041, U.S. Income Tax Return for Estates and Trusts.`,
        },
        minimum_reliability_code: {
          id: uuidV4(),
          type: "text",
          displayName: "Minimum reliability code",
          assignedTo: "",
          hint: `Entering a value here will filter out any result that has a reliability code below this value. The code ranges from 0 to 100.4, with a low-confidence score being below 80, a medium-confidence being 80-90, and high being 90-100.4. Adding more details of the company in your search will improve results.`,
        },
      },
      grouped: {
        phone: getDefaultCRSPhoneInput(),
      },
      lists: {
        addresses: getDefaultCRSAddressList("experian_business_search"),
      },
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        most_confident_bin: {
          ...getNamedOutputMapping("Most confident BIN"),
          hint: `The Business Identity Number or BIN is a unique identifier used to identify a business. This report returns all businesses and BINs that match the search parameters provided. This insight provides the BIN that the search identifies as the most likely match.`,
        },
        highest_reliability_code: {
          ...getNamedOutputMapping("Highest reliability code"),
          hint: `This code signifies the confidence with which Experian believes that the top result is in fact the company being searched for. The code ranges from 0 to 100.4, with a low-confidence score being below 80, a medium-confidence being 80-90, and high being 90-100.4. Adding more details of the company in your search will improve results.`,
        },
        provider_request_id: getNamedOutputMapping("Provider request ID"),
        retrieval_datetime: getNamedOutputMapping("Retrieval date-time (UTC)"),
      },
    },
    config: defaultResourceConfig,
  });

const getBinExperianInput = (): InputMappingT => ({
  id: uuidV4(),
  type: "text",
  displayName: "BIN",
  assignedTo: "",
  rules: {
    required: true,
  },
  hint: `A unique 9-digit business identification number assigned by Experian. This is not the same as a Tax ID.`,
});

export const getDefaultCRSBusinessProfileReport = (): IntegrationResourceT => ({
  providerResource: {
    provider: "crs",
    resource: "experian_business_profile_report",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      bin: getBinExperianInput(),
    },
    grouped: {},
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      provider_request_id: getNamedOutputMapping("Provider request ID"),
      retrieval_datetime: getNamedOutputMapping("Retrieval date-time (UTC)"),
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultCRSIntellisenseBusinessReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "crs",
      resource: "experian_intelliscore_business_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        bin: getBinExperianInput(),
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        provider_request_id: getNamedOutputMapping("Provider request ID"),
        retrieval_datetime: getNamedOutputMapping("Retrieval date-time (UTC)"),
        intelliscore: {
          ...getNamedOutputMapping("Intelliscore"),
          hint: `Predicts the likelihood of seriously derogatory payment within the next 12 months. Values range from 1 to 100, 1 indicating a high risk and 100 a low risk. There are also two exclusion scores: a 998 score is returned when there is bankruptcy within the last two years, a 999 score is returned when there is not enough information to score a business.`,
        },
      },
    },
    config: defaultResourceConfig,
  });

const getDefaultCrsTransunionAddress = (): Record<string, InputMappingT> => ({
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  house_number: {
    id: uuidV4(),
    type: "text",
    displayName: "House number",
    assignedTo: "",
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    hint: "The city is only required if the address cannot be identified via other given information (e.g., the postal code)",
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    hint: `5-10 characters`,
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    hint: `A two-letter code for the US state or territory of the address.`,
  },
});

export const getDefaultCrsTransunionCreditReport =
  (): IntegrationResourceT => ({
    providerResource: { provider: "crs", resource: "transunion_credit_report" },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        first_name: {
          id: uuidV4(),
          type: "text",
          displayName: "First name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        middle_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Middle name",
          assignedTo: "",
          hint: `Only one word allowed`,
        },
        last_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Last name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        social_security_number: {
          id: uuidV4(),
          type: "text",
          displayName: "Social security number",
          assignedTo: "",
          hint: `The individual's 9-digit social security number (SSN), formatted as a simple string of integers (e.g., "123456789").`,
        },
      },
      grouped: {},
      lists: {
        addresses: {
          displayName: "address",
          getDefaultElement: getDefaultCrsTransunionAddress,
          elements: [getDefaultCrsTransunionAddress()],
        },
      },
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        vantage_score: {
          ...getNamedOutputMapping("VantageScore"),
          hint: `VantageScore is a credit score that ranges from 300 to 850 and is used to assess a person's creditworthiness. A higher VantageScore indicates a better credit history, with a score above 700 generally considered good, and scores over 800 excellent.`,
        },
        number_of_inquiries: {
          ...getNamedOutputMapping("Number of inquiries"),
          hint: `The number of times a person's credit report has been accessed by potential lenders or creditors within a certain period.`,
        },
        number_of_trades: {
          ...getNamedOutputMapping("Number of trades"),
          hint: `Number of trades in credit reports refers to the total number of credit accounts or loans that a person has on their credit report.`,
        },
        months_of_oldest_tradeline: {
          ...getNamedOutputMapping("Months of oldest tradeline"),
          hint: `Trade lines are records of credit accounts that appear on a person's credit report. They show the credit history of the account, including payment history, credit limits, and balances.`,
        },
        utilization_ratio: {
          ...getNamedOutputMapping("Utilization ratio"),
          hint: `The credit utilization ratio is a significant factor in credit reporting that measures the amount of credit a person is using relative to their credit limit. It's calculated by dividing a person's credit card balances by their total credit limit, expressed as a percentage.`,
        },
        months_since_bankruptcy: getNamedOutputMapping(
          "Months since bankruptcy",
        ),
        months_since_charge_off: {
          ...getNamedOutputMapping("Months since charge-off"),
          hint: `Charge-offs are a negative item that can appear on a person's credit report when a lender or creditor writes off a debt as uncollectible. This occurs when a borrower fails to make payments for an extended period, typically 180 days or more.`,
        },
        derogatory_alert: {
          ...getNamedOutputMapping("Derogatory alert"),
          hint: `A derogatory alert is a negative item that appears on a person's credit report when there is a potential issue with their credit history. It can include information such as late payments, collections, charge-offs, bankruptcies, or other negative events.`,
        },
        max_number_months_delinquent: getNamedOutputMapping(
          "Max number months delinquent",
        ),
        credit_counselling_usage_count: {
          ...getNamedOutputMapping("Credit counselling usage count"),
          hint: `Credit counselling is a non-profit service that helps individuals manage their debt and improve their credit by negotiating with creditors to establish a debt management plan.`,
        },
        mortgage_delinquencies: getNamedOutputMapping("Mortgage delinquencies"),
        provider_request_id: getNamedOutputMapping("Provider request ID"),
        retrieval_datetime: getNamedOutputMapping("Retrieval date-time (UTC)"),
      },
    },
    config: defaultResourceConfig,
  });

const getCrsTransunionIMVBasicAddress = (): {
  [key: string]: InputMappingT;
} => ({
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `1-48 characters. The house number is also expected here (e.g., "123 Main St")`,
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `5-10 characters.`,
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `1-28 characters.`,
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `A two-letter code for the U.S. state or territory of the address. Note that some U.S. states require more data for identifying a consumer than others. For example, Rhode Island prohibits using only the SSN to identify a person.`,
  },
});

const getDefaultCrsTransunionIMVBasicAddress = (): InputMappingGroupT => ({
  getDefaultElements: () => getCrsTransunionIMVBasicAddress(),
  elements: getCrsTransunionIMVBasicAddress(),
  rules: {
    required: true,
  },
});

export const getDefaultCrsTransunionIMVBasic = (): IntegrationResourceT => ({
  providerResource: { provider: "crs", resource: "transunion_imv_basic" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      first_name: {
        id: uuidV4(),
        type: "text",
        displayName: "First name",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: `1-20 characters, only a-z, A-Z, 0-9, and -⎵./#`,
      },
      middle_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Middle name",
        assignedTo: "",
        hint: `1-20 characters, only a-z, A-Z, 0-9, and -⎵./#`,
      },
      last_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Last name",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: `1-32 characters, only a-z, A-Z, 0-9, and -⎵./#`,
      },
      name_suffix: {
        id: uuidV4(),
        type: "text",
        displayName: "Name suffix",
        assignedTo: "",
        hint: `One of: Jr, Sr, III, IV, V, VI, VII, VIII, IX, X`,
      },
      date_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Date of birth",
        assignedTo: "",
        hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20".`,
      },
      social_security_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Social security number",
        assignedTo: "",
        hint: `The individual's social security number (SSN), formatted as a simple string of integers (e.g., "123456789").`,
      },
      phone: {
        id: uuidV4(),
        type: "text",
        displayName: "Phone number",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: `10-digit phone number (e.g., "1112223334")`,
      },
    },
    grouped: {
      address: getDefaultCrsTransunionIMVBasicAddress(),
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {},
  },
  config: defaultResourceConfig,
});

const getDefaultCRSLexisNexisBridgerSearchAddress = (): InputMappingGroupT => ({
  getDefaultElements: () => ({
    street: {
      id: uuidV4(),
      type: "text",
      displayName: "Street",
      assignedTo: "",
    },
    postal_code: {
      id: uuidV4(),
      type: "text",
      displayName: "Postal code",
      assignedTo: "",
    },
    city: {
      id: uuidV4(),
      type: "text",
      displayName: "City",
      assignedTo: "",
    },
    country: {
      id: uuidV4(),
      type: "text",
      displayName: "Country",
      assignedTo: "",
      hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g, "DE" for Germany)`,
    },
  }),
});

export const getDefaultCRSLexisNexisBridgerSearch =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "crs",
      resource: "lexisnexis_bridger_search",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        first_name: {
          id: uuidV4(),
          type: "text",
          displayName: "First name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        last_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Last name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        search_type: {
          id: uuidV4(),
          type: "text",
          displayName: "Search type",
          assignedTo: "",
          rules: {
            required: true,
          },
          hint: `One of: "NMS_PEP_OFAC_SANC", "List Screening"`,
        },
        date_of_birth: {
          id: uuidV4(),
          type: "text",
          displayName: "Date of birth",
          assignedTo: "",
          hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20".`,
        },
        social_security_number: {
          id: uuidV4(),
          type: "text",
          displayName: "Social security number",
          assignedTo: "",
        },
        phone: {
          id: uuidV4(),
          type: "text",
          displayName: "Phone number",
          assignedTo: "",
        },
        glb_purpose: {
          id: uuidV4(),
          type: "text",
          displayName: "GLB purpose",
          assignedTo: "",
          rules: {
            required: true,
          },
          hint: `The purpose for which the information is being obtained accordingly to the Gramm-Leach-Bliley Act. Refer to our docs for further details. One of: "0", "1", "3", "5", "6", "7", "12"`,
        },
        dl_purpose: {
          id: uuidV4(),
          type: "text",
          displayName: "DL purpose",
          assignedTo: "",
          rules: {
            required: true,
          },
          hint: `The purpose for which the Driver's license data is being obtained. Refer to our docs for further details. One of: "0", "1", "2", "3", "4", "6"`,
        },
        minimum_entity_score: {
          id: uuidV4(),
          type: "text",
          displayName: "Minimum entity score",
          assignedTo: "",
          hint: `Indicates the minimum entity score required for watchlists in order to be included in the respective insights. Ranges from 0 - 100.`,
        },
      },
      grouped: {
        address: getDefaultCRSLexisNexisBridgerSearchAddress(),
      },
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        reasons_listed: getNamedOutputMapping(
          "Reasons listed",
          `Provides a list of all the reasons the person was part of a watchlist item. If "minimum entity score" is passed, only the items over the given score are taken into consideration. Some possible values are: "Adverse Media:Murder", "PEP:Associate", "Sanction List".`,
        ),
      },
    },
    config: defaultResourceConfig,
  });
