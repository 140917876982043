import { noop, reduce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";
import { v4 as uuidv4 } from "uuid";

import { DatasetRow } from "src/api/types";
import {
  TAKTILE_TEAM_NOTIFIED,
  toastFailure,
  toastSuccess,
} from "src/base-components/Toast/utils";
import { RowMenu } from "src/datasets/DatasetTable/RowMenu";
import {
  useDatasetEditTableActions,
  useIsRowSelected,
} from "src/datasets/DatasetTable/stores";
import { DatasetContext } from "src/datasets/DatasetTable/types";
import { MOCK_COL_SEPARATOR } from "src/datasets/DatasetTable/utils";
import { usePostRows } from "src/datasets/api/queries";
import { useDeleteRow } from "src/datasets/hooks/useDeleteRow";
import { useFlowContext } from "src/router/routerContextHooks";
import { copyTextToClipboard } from "src/utils/clipboard";
import * as logger from "src/utils/logger";

export const RowIndexCell = ({
  index,
  readonly,
  context,
  row,
  datasetId,
  versionName,
  subflowMockColumns,
  scrollToBottom,
}: {
  row: DatasetRow;
  index: number;
  readonly: boolean;
  context: DatasetContext;
  datasetId: string;
  versionName: string | undefined;
  scrollToBottom?: () => void;
  subflowMockColumns: string[];
}) => {
  const { selectCell, reset } = useDatasetEditTableActions();
  const isRowSelected = useIsRowSelected(index);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const duplicateRow = useDuplicateRow(datasetId);
  const onDeleteRow = useDeleteRow(datasetId, row);
  const onCopyAsPayload = async () => {
    const payload = JSON.stringify(
      {
        data: row.input_data,
        mock_data: reduce(
          row.mock_data,
          (acc, value, col) => {
            const [parent] = col.split(MOCK_COL_SEPARATOR);

            // excludes parent column from mock data
            if (subflowMockColumns.includes(col) && parent === col) {
              return acc;
            }

            // excludes child columns from mock data if parent column is not set to includ sub flow mocks
            if (
              !subflowMockColumns.includes(parent) &&
              col.startsWith(`${parent}${MOCK_COL_SEPARATOR}`)
            ) {
              return acc;
            }

            return {
              ...acc,
              [col]: value,
            };
          },
          {} as Record<string, unknown>,
        ),
        metadata: {
          version: versionName,
        },
        control: {
          execution_mode: "sync",
        },
      },
      null,
      2,
    );
    try {
      await copyTextToClipboard(payload);
      toastSuccess({
        title: "Copied payload to clipboard",
        description: "You can paste it in the request body to test the API",
      });
    } catch (e) {
      logger.error(e);
      toastFailure({ title: "Failed to copy payload to clipboard" });
    }
  };

  const onDuplicateRow = useCallback(async () => {
    await duplicateRow(row);
    scrollToBottom?.();
  }, [duplicateRow, row, scrollToBottom]);

  useEffect(() => {
    if (!isRowSelected) {
      setIsMenuOpen(false);
    }
  }, [isRowSelected]);

  return (
    <div
      className={twJoin(
        "group/checkbox relative flex h-8 w-full items-center justify-center px-1.5",
        isRowSelected && "bg-indigo-50",
      )}
      data-loc="dataset-table-row-index-cell"
      onClick={
        readonly
          ? noop
          : () => (isRowSelected ? reset() : selectCell(`${index}_`))
      }
      onContextMenu={(e) => {
        if (!readonly) {
          e.preventDefault();
          if (!isRowSelected) {
            selectCell(`${index}_`);
          }
          if (!isMenuOpen) {
            setIsMenuOpen(true);
          }
        }
      }}
    >
      <RowMenu
        key={row.id}
        isOpen={isRowSelected && isMenuOpen}
        resetSelection={reset}
        onClose={() => setIsMenuOpen(false)}
        onCopyAsPayload={context === "authoring" ? onCopyAsPayload : undefined}
        onDeleteRow={onDeleteRow}
        onDuplicateRow={onDuplicateRow}
      >
        <span
          className={twJoin(
            "text-gray-500 font-inter-medium-11px",
            // "group-hover/checkbox:hidden",
            // isRowSelected && "hidden"
          )}
        >
          {index + 1}
        </span>
      </RowMenu>
      {/* <div
        className={twMerge(
          "absolute inset-0 hidden items-center justify-center p-1.5 group-hover/checkbox:flex",
          isRowSelected && "flex",
          readonly && "hidden"
        )}
      >
          <Checkbox checked={!!isRowSelected} />
      </div> */}
    </div>
  );
};

const useDuplicateRow = (datasetId: string) => {
  const { workspace, flow } = useFlowContext();
  const { mutateAsync } = usePostRows(datasetId, flow.id, workspace?.base_url);

  return useCallback(
    async (row: DatasetRow) => {
      try {
        await mutateAsync({
          source: "row_id",
          row,
          new_row_ids: [uuidv4()],
        });
      } catch (e) {
        toastFailure({
          title: `Failed to add row`,
          description: TAKTILE_TEAM_NOTIFIED,
        });
        logger.error(e);
      }
    },
    [mutateAsync],
  );
};
