import { Edge } from "reactflow";

export enum EDGE_TYPE {
  FLOW_EDGE = "flow_edge",
  SPLIT_EDGE = "split_edge",
  GROUP_EDGE = "group_edge",
}
/**
 * @property originalEdgeId: This is needed for group edges. An edge to a collapsed group node, or from a collapsed group node, does not exist in the edges array. That is why we need to hold a reference in these virtual edges to the actual underlying edge so that it is possible to add nodes before and after groups
 */
export type GroupEdgeData = {
  originalEdgeId: string;
};

export type GroupEdge = Edge<GroupEdgeData>;
