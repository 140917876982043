import { reaction } from "mobx";
import { useEffect } from "react";

import { addMiddleware } from "src/api/requestMiddleware";
import { useAuthoringUIActions } from "src/flowContainer/AuthoringUIContext";
import { useAuthStore } from "src/store/AuthStore";
import { GraphStore } from "src/store/GraphStore";

export const graphStore = new GraphStore();

addMiddleware();
useAuthStore.getState().actions.setup();

export const useGraphStore = () => {
  return graphStore;
};

/**
 * We have some new state items in AuthoringUIContext now
 * so we need to sync the graph store with the AuthoringUIContext store sometimes,
 * here we can react on the graph store changes and update the store
 */
export const useSyncWithGraphStore = () => {
  const { setNodeEditorOpen } = useAuthoringUIActions();

  useEffect(() => {
    // When selectedNode has changed and set to a new value
    // we need to open the node editor
    reaction(
      () => graphStore.selectedNode?.id,
      (value, previousValue) => {
        if (value && value !== previousValue) {
          setNodeEditorOpen(true);
        }
      },
    );
  }, [setNodeEditorOpen]);
};
