import { useFormContext, Controller } from "react-hook-form";

import {
  ConnectionT,
  InboundWebhookConnectionAuthMethod,
} from "src/api/connectApi/types";
import { FormItem } from "src/base-components/FormItem";
import { SimpleDropDown } from "src/base-components/SimpleDropDown";
import { InboundWebhookApiKeyAuthFieldset } from "src/connections/config/inboundWebhook/InboundWebhookApiKeyAuthFieldset";
import { InboundWebhookBasicAuthFieldset } from "src/connections/config/inboundWebhook/InboundWebhookBasicAuthFieldset";
import { InboundWebhookHMACAuthFieldset } from "src/connections/config/inboundWebhook/InboundWebhookHMACAuthFieldset";
import {
  InboundWebhookConnectionConfigInputsT,
  Environment,
} from "src/connections/types";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { assertUnreachable } from "src/utils/typeUtils";

type ProviderWebhookAuthTypeElements = {
  key: InboundWebhookConnectionAuthMethod;
  value: string;
};

const baseAuthMethods: ProviderWebhookAuthTypeElements[] = [
  { key: "api_key", value: "API key authentication" },
  { key: "basic", value: "Basic authentication" },
  { key: "hmac", value: "HMAC authentication" },
];

const renderAuthFields = (
  authMethod: InboundWebhookConnectionAuthMethod,
  environment: Environment,
) => {
  switch (authMethod) {
    case "no_auth":
      return <></>;
    case "api_key":
      return <InboundWebhookApiKeyAuthFieldset environment={environment} />;
    case "basic":
      return <InboundWebhookBasicAuthFieldset environment={environment} />;
    case "hmac":
      return <InboundWebhookHMACAuthFieldset environment={environment} />;
    default:
      assertUnreachable(authMethod);
  }
};

type EnvironmentConfigPropsT = {
  environment: Environment;
  labelsPrefix: string;
  connection?: ConnectionT | undefined;
};

export const InboundWebhookConnectionEnvironmentConfig: React.FC<
  EnvironmentConfigPropsT
> = ({ environment, labelsPrefix, connection }) => {
  const { watch, control } =
    useFormContext<InboundWebhookConnectionConfigInputsT>();
  const envPrefix = `${environment}Config` as const;
  const webhookAuthMethod = watch(`${envPrefix}.webhookAuthMethod`);

  const isConnectionWithNoAuth =
    environment === "production"
      ? connection?.configuration.webhook_auth_settings?.auth_method ===
        "no_auth"
      : environment === "sandbox"
        ? connection?.non_prod_env_configs?.sandbox?.webhook_auth_settings
            ?.auth_method === "no_auth"
        : false;

  const authMethods = [...baseAuthMethods];
  if (
    isFeatureFlagEnabled(FEATURE_FLAGS.webhookNoAuthSetting) ||
    isConnectionWithNoAuth
  ) {
    authMethods.unshift({ key: "no_auth", value: "No authentication" });
  }
  return (
    <>
      <FormItem
        key={`${envPrefix}.authMethod`}
        description="The authentication mechanism used by the webhook provider"
        gap="sm"
        label={
          labelsPrefix
            ? `Select ${labelsPrefix.toLowerCase()} authentication type`
            : "Select authentication type"
        }
        isRequired
      >
        <Controller
          control={control}
          name={`${envPrefix}.webhookAuthMethod`}
          render={(props) => (
            <SimpleDropDown
              buttonClassName="pl-3"
              buttonDataLoc={`${environment}-webhook-connection-auth-type`}
              className="h-8 w-full"
              elements={authMethods}
              itemsClassNames="w-full"
              itemsWidth="w-full"
              placeholder="Select authentication type"
              placement="bottomLeft"
              selectedKey={String(props.field.value)}
              onSelect={(value) => {
                props.field.onChange(value);
              }}
            />
          )}
        ></Controller>
      </FormItem>
      {renderAuthFields(webhookAuthMethod, environment)}
    </>
  );
};
