import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FieldErrorsT } from "src/api/types";
import { Card } from "src/base-components/Card";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { CustomConnectionForm } from "src/customConnectionNode/types";

type PropsT = { runFieldErrors: FieldErrorsT | undefined; immutable: boolean };

export const ResponseMapping: React.FC<PropsT> = ({
  runFieldErrors,
  immutable,
}) => {
  const formProps = useFormContext<CustomConnectionForm>();
  const runFieldError: string | undefined =
    runFieldErrors?.[formProps.getValues("response.id")];
  return (
    <>
      <h3 className="text-gray-800 font-inter-medium-12px">
        Set output location
      </h3>
      <span className="text-gray-500 font-inter-normal-12px">
        Add the entire API response into the data object
      </span>
      <Card className="mt-2.5 flex w-full flex-row items-center">
        <div className="mr-2 flex text-gray-700 font-inter-normal-12px">
          Output location
        </div>
        <Controller
          control={formProps.control}
          name="response.mapped_to"
          render={({ field }) => (
            <AutocompleteCodeInput
              dataLoc="custom-connection-output-mapping"
              disabled={immutable}
              error={runFieldError}
              prefix="data."
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Card>
    </>
  );
};
