import React from "react";

import { Router } from "src/router/Router";

export const App: React.FC = () => {
  return (
    <div className="h-screen w-screen overflow-clip">
      <Router />
    </div>
  );
};
