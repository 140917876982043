import React from "react";

import { Card } from "src/base-components/Card";
import { MonospacedInput } from "src/base-components/MonospacedInput";
import { SplitNodeV2 } from "src/constants/NodeDataTypes";
import { useGraphStore } from "src/store/StoreProvider";

type PropsT = {
  selectedNode: SplitNodeV2;
  immutable: boolean;
};

export const DefaultBranchCard: React.FC<PropsT> = ({
  selectedNode,
  immutable,
}) => {
  const { getNodeToIncomingEdge, updateNode } = useGraphStore();

  const defaultBranchName = getNodeToIncomingEdge(
    selectedNode.data.default_edge_id,
  )?.data.label;

  const onDefaultBranchNameChange: React.FormEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const branchNodeToRename = getNodeToIncomingEdge(
      selectedNode.data.default_edge_id,
    );
    if (branchNodeToRename) {
      updateNode({
        newName: e.currentTarget.value,
        nodeId: branchNodeToRename.id,
      });
    }
  };

  return (
    <Card className="mt-5 space-y-3">
      <Card.Header>
        <Card.Title title="Default branch" />
      </Card.Header>
      <Card.Content>
        <div className="flex flex-row items-center border-t border-gray-200 pt-3">
          <div className="mr-2 w-24 text-gray-700 font-inter-medium-12px">
            Use branch
          </div>
          <MonospacedInput
            containerClassName="flex-grow"
            defaultValue={defaultBranchName}
            disabled={immutable}
            onChange={onDefaultBranchNameChange}
          />
        </div>
      </Card.Content>
    </Card>
  );
};
