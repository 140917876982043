export const unknownToString = (value: unknown): string => {
  switch (typeof value) {
    case "object":
      return value === null ? "" : JSON.stringify(value);
    case "string":
    case "bigint":
    case "boolean":
    case "function":
    case "symbol":
    case "number":
      return value.toString();
    default:
      return "";
  }
};
