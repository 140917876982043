import { ReactCodeMirrorRef } from "@uiw/react-codemirror";

import { CellId } from "src/base-components/EditorTable/stores";
import { CellIndex } from "src/base-components/EditorTable/types";

/**
 * Focuses element for editing with correct caret position
 *
 * @param element Any html element
 */
export const focusContentEditable = (element: HTMLElement) => {
  const selection = window.getSelection();

  const range = document.createRange();
  range.selectNodeContents(element);
  range.collapse(false);

  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);
  }

  element.focus();
};

export const coordinateToKey = <T>(
  row: CellIndex<T>["row"],
  col: CellIndex<T>["col"],
): CellId => `${row}_${String(col)}`;

export const dispatchSetEditorContents = (
  { view }: ReactCodeMirrorRef,
  value: string,
  focus: boolean = false,
) => {
  if (!view || !view.state) {
    return;
  }

  if (focus) {
    view.focus();
  }

  view.dispatch(
    view.state.update({
      changes: [
        {
          from: 0,
          to: view.state.doc.toString().length,
          insert: "",
        },
        {
          from: 0,
          insert: value,
        },
      ],
      selection: {
        anchor: value.length,
        head: value.length,
      },
    }),
  );
};

export const dispatchFocusEditor = (
  { view }: ReactCodeMirrorRef,
  value: string,
) => {
  if (!view || !view.state) {
    return;
  }

  view.focus();

  if (value) {
    view.dispatch(
      view.state.update({
        selection: {
          anchor: value.length,
          head: value.length,
        },
      }),
    );
  }
};
