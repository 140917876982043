import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import { Fragment } from "react";

import { InformationPill } from "src/base-components/InformationPill";
import { MOCK_COL_SEPARATOR } from "src/datasets/DatasetTable/utils";

const renderColumnName = (name: string) => {
  if (name.includes(MOCK_COL_SEPARATOR)) {
    const [parentName, childName] = name.split(MOCK_COL_SEPARATOR);
    return `Child Flow connection ${childName} (called by ${parentName})`;
  } else {
    return name;
  }
};

export const MissingMockDataColumnsCallout: React.FC<{
  columns: string[];
  onAddMissingColumns: () => void;
}> = ({ columns, onAddMissingColumns }) => {
  const manyColumns = columns.length > 1;

  return (
    <InformationPill
      action={{
        text: "Add Missing Mock Data Fields",
        onClick: onAddMissingColumns,
      }}
      icon={faWarning}
      type="info"
      fullWidth
    >
      <div>
        <span className="pr-1">
          Add missing mock data field{manyColumns && "s"}
        </span>
        {columns.map((column, index) => (
          <Fragment key={column}>
            {index > 0 && ", "}
            <strong className="font-inter-medium-12px">
              {renderColumnName(column)}
            </strong>
          </Fragment>
        ))}{" "}
        to this dataset to be able to test this version
      </div>
    </InformationPill>
  );
};
