import React, { useEffect, useState } from "react";

import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Input } from "src/base-components/Input";
import { Modal } from "src/design-system/Modal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
};

export const CreateDatasetModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (isOpen) {
      setName("");
      setError("");
    }
  }, [isOpen]);

  const handleSave = () => {
    if (!name) {
      setError("Name is required");
    } else {
      setError("");
      onSubmit(name);
    }
  };
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>Name this dataset</Modal.Header>
      <Modal.Content>
        <Input
          errored={!!error}
          placeholder="e.g. Jan_March_2023"
          value={name}
          fullWidth
          onChange={(e) => setName(e.target.value)}
        />
        {error && <ErrorHint className="h-7">{error}</ErrorHint>}
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button dataLoc="save" variant="primary" onClick={handleSave}>
            Save dataset
          </Button>
        }
      />
    </Modal>
  );
};
