import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Row } from "@tanstack/react-table";
import { twJoin } from "tailwind-merge";

import { useIsRowHovered } from "src/base-components/EditorTable/stores";
import {
  EditorTableProps,
  RowBase,
} from "src/base-components/EditorTable/types";

type Props<T extends RowBase<T>> = {
  row: Row<T>;
  totalRows: number;
  children: React.ReactNode;
  dispatch: EditorTableProps<T>["dispatch"];
  sortHandle: EditorTableProps<T>["rowSortHandle"];
};

export const SortableRow = <T extends RowBase<T>>({
  row,
  children,
  totalRows,
  sortHandle: SortHandle,
  dispatch,
}: Props<T>) => {
  const id = row.original.id;
  const isHovered = useIsRowHovered(id);
  const { setNodeRef, transform, transition, active, listeners, attributes } =
    useSortable({
      id,
      disabled: !SortHandle,
    });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  return (
    <tr
      ref={setNodeRef}
      className={twJoin(id === active?.id && "sticky z-50")}
      style={style}
    >
      {SortHandle && (
        <SortHandle
          {...attributes}
          {...listeners}
          dispatch={dispatch}
          row={row}
          show={isHovered}
          totalRows={totalRows}
        />
      )}
      {children}
    </tr>
  );
};
