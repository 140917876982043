import { ColumnDef, CellContext } from "@tanstack/react-table";

import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { HeaderCell } from "src/dataTable/HeaderCell";
import { RawCell } from "src/dataTable/RawCell";
import { ResultCell } from "src/dataTable/ResultCell";
import { StatusColumn } from "src/datasets/AssembleDatasetModal/StatusColumn";
import { unknownToString } from "src/datasets/api/apiUtils";
import { HistoryDataRecord } from "src/datasets/api/types";
import { formatDate } from "src/utils/datetime";

type Args = {
  requestColumnNames: string[];
};

export const TableColumns = ({
  requestColumnNames,
}: Args): ColumnDef<HistoryDataRecord>[] => {
  const getDateString = (datetime: string) => {
    return formatDate(datetime, "dd MMM yy HH:mm:ss");
  };

  return [
    {
      id: "decision_id",
      size: 50,
      header: () => (
        <HeaderCell className="pl-3">
          <span className="inline-block">ID</span>
        </HeaderCell>
      ),
      accessorFn: (row) => (
        <CopyTextIcon value={row.response.metadata["decision_id"]} />
      ),
      cell: ({ cell }) => (
        <RawCell cell={cell} classNameOverrides="w-10 pl-2" />
      ),
    },
    {
      id: "timestamp",
      header: () => <HeaderCell>Time</HeaderCell>,
      accessorFn: (row) => getDateString(row.response.metadata.timestamp),
      cell: ({ cell }) => <RawCell cell={cell} />,
    },
    {
      id: "status_code",
      header: () => (
        <HeaderCell>
          <span>Status</span>
        </HeaderCell>
      ),
      accessorFn: (row) => (
        <StatusColumn
          statusCode={row.response.metadata["status_code"]}
          type={row.response.metadata.type}
        />
      ),
      cell: ({ cell }: CellContext<HistoryDataRecord, any>) => {
        const message = (
          <div className="flex items-center justify-between">
            <span className="justify-start">{cell.getValue()}</span>
          </div>
        );

        return (
          <ResultCell
            cell={message}
            className="max-w-0 overflow-auto"
            objectDetailPosition="topRight"
          />
        );
      },
    },
    {
      id: "type",
      header: () => <HeaderCell>Type</HeaderCell>,
      accessorFn: (row: HistoryDataRecord) => row.response.metadata.type,
      cell: ({ cell }) => <RawCell cell={cell} />,
    },

    {
      id: "error",
      header: () => <HeaderCell>Error</HeaderCell>,
      accessorFn: (row: HistoryDataRecord) => row.response.metadata.error,
      cell: ({ cell }) => <RawCell cell={cell} />,
    },

    {
      id: "entity_id",
      header: () => <HeaderCell>Entity ID</HeaderCell>,
      accessorFn: (row: HistoryDataRecord) => row.response.metadata.entity_id,
      cell: ({ cell }) => <RawCell cell={cell} />,
    },
    ...requestColumnNames.map((column) => ({
      id: column,
      header: () => (
        <HeaderCell className="whitespace-nowrap">{column}</HeaderCell>
      ),
      accessorFn: (row: HistoryDataRecord) =>
        unknownToString(row.request.data[column]),
      cell: ({ cell }: CellContext<HistoryDataRecord, unknown>) => (
        <ResultCell cell={cell} objectDetailPosition="topLeft" />
      ),
    })),
    {
      id: "version",
      header: () => (
        <HeaderCell className="whitespace-nowrap">Flow version ID</HeaderCell>
      ),
      accessorFn: (row: HistoryDataRecord) => row.response.metadata.version,
      cell: ({ cell }) => <RawCell cell={cell} />,
    },
    {
      id: "traffic_policy_name",
      header: () => (
        <HeaderCell className="whitespace-nowrap">Traffic policy</HeaderCell>
      ),
      accessorFn: (row: HistoryDataRecord) =>
        row.response.metadata.traffic_policy_name,
      cell: ({ cell }: any) => <RawCell cell={cell} />,
    },
  ];
};
