import { faBox, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { Button } from "src/base-components/Button";
import { EmptyState } from "src/base-components/EmptyState";

export type NoDecisionFlowSelectedProps = {
  immutable: boolean;
  handleSelectDecisionFlow: () => void;
};

export const NoDecisionFlowSelected: React.FC<NoDecisionFlowSelectedProps> = ({
  immutable,
  handleSelectDecisionFlow,
}) => (
  <EmptyState
    action={
      <Button
        dataLoc="select-decision-flow-button"
        disabled={immutable}
        iconLeft={faPlus}
        variant="secondary"
        onClick={handleSelectDecisionFlow}
      >
        Select Decision Flow
      </Button>
    }
    description="Select a Decision Flow you want to apply to complete mapping"
    headline="No Decision Flow selected"
    icon={faBox}
  />
);
