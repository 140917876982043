import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { FormItem } from "src/base-components/FormItem";
import { Switch } from "src/base-components/Switch";
import {
  DEFAULT_OUTPUT_BE_NAME,
  OutputMappingsT,
} from "src/integrationNode/types";

type PropsT = {
  displayName: string;
  description?: string;
  formProps: UseFormReturn<OutputMappingsT>;
  // To be able to use the typed react hook forms controller in this component, we have to specify
  // which fields the component excepts
  fieldName: `${typeof DEFAULT_OUTPUT_BE_NAME}` | `insights.${string}`;
  runFieldError: string | undefined;
  immutable: boolean;
  hint?: string | React.ReactNode;
};

export const InsightMappingRow: React.FC<PropsT> = ({
  displayName,
  description,
  formProps,
  fieldName,
  runFieldError,
  immutable,
  hint,
}) => {
  return (
    <div className="mb-7">
      <div
        className="mb-2.5 flex items-center justify-between"
        data-loc={fieldName}
      >
        <div>
          <h3 className="text-gray-800 font-inter-medium-12px">
            {displayName}
            <FormItem.Help body={hint} />
          </h3>
          {description && (
            <span className="text-gray-500 font-inter-normal-12px">
              {description}
            </span>
          )}
        </div>
        <Controller
          control={formProps.control}
          name={`${fieldName}.selected`}
          render={(props) => (
            <Switch
              dataLoc={`${fieldName}.selected`}
              disabled={immutable}
              enabled={props.field.value}
              onChange={() => {
                props.field.onChange(!props.field.value);
              }}
            />
          )}
        />
      </div>
      {formProps.getValues(fieldName).selected && (
        <div className="mb-2 flex w-full flex-row items-center rounded-lg bg-gray-50 px-5 py-4">
          <div className="mr-2 flex text-gray-700 font-inter-normal-12px">
            Output location
          </div>
          <Controller
            control={formProps.control}
            name={`${fieldName}.assignedTo`}
            render={({ field }) => (
              <AutocompleteCodeInput
                dataLoc={`${fieldName}.assignedTo`}
                disabled={immutable}
                error={runFieldError}
                prefix="data."
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};
