import React from "react";

import { BeMappedNode } from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { DownloadDataButton } from "src/nodeEditor/DownloadDataButton";
import { useFlowOuput, useNodeRunState } from "src/store/runState/RunState";

type PropsT = {
  selectedNode: BeMappedNode;
  workspaceUrl: string;
};

export const DownloadDataIcon: React.FC<PropsT> = ({
  selectedNode,
  workspaceUrl,
}) => {
  let runState = useNodeRunState(selectedNode.id);
  const flowOutput = useFlowOuput();

  if (selectedNode.type === NODE_TYPE.OUTPUT_NODE && flowOutput) {
    runState = flowOutput;
  }

  if (runState?.type === "test-run") {
    return (
      <DownloadDataButton
        nodeName={selectedNode.data.label}
        results={runState.testResult}
        workspaceUrl={workspaceUrl}
      />
    );
  } else return <></>;
};
