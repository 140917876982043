import React from "react";

import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { DataRetentionTimedelta } from "src/clients/flow-api";

type ShorteningRetentionPolicyModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  dataRetention?: DataRetentionTimedelta;
  afterLeave?: () => void;
};

export const ShorteningRetentionPolicyModal: React.FC<
  ShorteningRetentionPolicyModalProps
> = ({ isOpen, onClose, onConfirm, dataRetention, afterLeave }) => (
  <ConfirmationModal
    afterLeave={afterLeave}
    confirmationButtonText="Update retention policy"
    confirmationButtonType="warning"
    open={isOpen}
    title="Are you sure?"
    onClose={onClose}
    onConfirm={onConfirm}
  >
    <p className="mb-2.5 text-gray-500 font-inter-normal-12px">
      By confirming, you will set a new retention period of{" "}
      <span className="text-gray-800">
        {dataRetention?.value} {dataRetention?.unit}
      </span>{" "}
      for all future decisions.
    </p>
    <p className="mb-6 text-gray-500 font-inter-normal-12px">
      Decisions made prior to this change will not be affected. They will be
      retained according to the policy that was in place at the time they were
      made. If you wish to alter the retention of past decisions, please reach
      out to our support team.
    </p>
  </ConfirmationModal>
);
