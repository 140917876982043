import { faCheck, faFilter } from "@fortawesome/pro-regular-svg-icons";
import { faFilter as faFilterSolid } from "@fortawesome/pro-solid-svg-icons";
import { twJoin } from "tailwind-merge";

import {
  FixedPositionedDropdown,
  FixedPositionedDropdownPropsT,
} from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";

type PropsT<T extends string> = {
  isFiltering: boolean;
  onResetRequest: () => void;
  description: string;
  selected: T | undefined;
  onSelect: (key: T) => void;
} & Pick<FixedPositionedDropdownPropsT<string, T>, "elements" | "placement">;

export const ColumnFilter = <T extends string>({
  isFiltering,
  onResetRequest,
  description,
  ...props
}: PropsT<T>) => (
  <FixedPositionedDropdown
    buttonClassName="border-0"
    elements={props.elements}
    multiple={false}
    placement={props.placement}
    renderButtonValue={() => (
      <Icon
        color={
          isFiltering ? "text-indigo-500" : "text-gray-500 hover:text-gray-700"
        }
        icon={isFiltering ? faFilterSolid : faFilter}
        size="2xs"
      />
    )}
    renderHeader={() => (
      <div className="flex w-[240px] cursor-default items-center justify-between px-5 py-3.5">
        <h3 className="font-inter-semibold-13px">{description}</h3>
        {isFiltering && (
          <button
            className="font-bold text-indigo-500"
            onClick={onResetRequest}
          >
            Reset
          </button>
        )}
      </div>
    )}
    renderValue={({ value, selected, disabled }) => {
      return (
        <div
          className={twJoin(
            "flex w-[240px] items-center justify-between py-2 pl-5 pr-6",
            disabled && "cursor-default",
          )}
        >
          <span className={twJoin("inline-block", disabled && "text-gray-300")}>
            {value}
          </span>
          {selected && (
            <div className="inline-block">
              <Icon color="text-indigo-500" icon={faCheck} size="sm" />
            </div>
          )}
        </div>
      );
    }}
    selected={props.selected}
    onSelect={props.onSelect}
  />
);
