import { faDash } from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

import { Icon } from "src/base-components/Icon";

export const DIVIDER = "divider" as const;

type LegendProps = {
  items: LegendItem[];
};

export type LegendItem =
  | {
      color: string;
      name: string;
      type: "circle" | "dash";
    }
  | typeof DIVIDER;

export const Legend: React.FC<LegendProps> = ({ items }) => (
  <div className="mb-6 mt-10 flex items-center gap-1 font-inter-medium-12px">
    {items.map((item) =>
      item === DIVIDER ? (
        <div className="h-4 w-px bg-gray-200" />
      ) : (
        <div key={item.name} className="flex items-center px-2 py-1">
          <span style={{ color: item.color }}>
            <Icon
              icon={item.type === "circle" ? faCircle : faDash}
              size="3xs"
            />
          </span>
          {item.name}
        </div>
      ),
    )}
  </div>
);
