export const ColumnHeader: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <div className="flex min-h-[48px] items-center p-1.5">{children}</div>;

export const CellWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className="flex min-h-[48px] items-center gap-x-1 p-1.5 text-gray-800 font-inter-normal-12px">
    {children}
  </div>
);
