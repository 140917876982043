import { Navigate, Outlet, useOutletContext } from "react-router-dom";

import { Spinner } from "src/base-components/Spinner";
import { useCapabilities } from "src/hooks/useCapabilities";
import { CapabilitiesT } from "src/hooks/useCapabilities/types";
import { ErrorPage } from "src/router/ErrorPage";

type Props = {
  redirectPath?: string;
  canAccess?: (capabilities: CapabilitiesT) => boolean;
};

export const ProtectedRoute: React.FC<Props> = ({
  redirectPath,
  canAccess,
}) => {
  const context = useOutletContext();
  const capabilities = useCapabilities();

  if (capabilities.isLoading) {
    return <Spinner />;
  }

  const haveAccess = canAccess
    ? canAccess(capabilities)
    : capabilities.workspaceRole !== null;

  if (!haveAccess) {
    if (redirectPath) {
      return <Navigate to={redirectPath} replace />;
    }

    return <ErrorPage message="You're not authorized to view this page." />;
  }

  return <Outlet context={context} />;
};
