import * as ObservablePlot from "@observablehq/plot";
import {
  RenderableMark,
  type PlotOptions,
  type Data,
  type RenderFunction,
} from "@observablehq/plot";
import { differenceInDays } from "date-fns";
import { useEffect, useRef } from "react";
import { twJoin } from "tailwind-merge";

import "./styles.css";
import { PLOT_OPTIONS } from "src/performance/Plot/defaults";
import { getDomain, getLoadingMarks } from "src/performance/Plot/utils";
import { getIntervalForTimeWindow } from "src/performance/utils";
import { formatDate } from "src/utils/datetime";
import { StrictDateRange } from "src/utils/timeWindow";

type Marks = (data: Data) => (RenderableMark | RenderFunction | null)[];

type PlotProps = {
  marks: Marks[];
  x?: PlotOptions["x"];
  y?: PlotOptions["y"];
  timeWindow: StrictDateRange;
  data: any;
  isLoading?: boolean;
  loadingStyle?: "line" | "bar";
};

const filtersToFormat = (timeWindow: StrictDateRange): string => {
  const timeWindowDifferenceInDays = differenceInDays(
    timeWindow.to,
    timeWindow.from,
  );
  if (timeWindowDifferenceInDays === 0) {
    return "HH:mm";
  } else if (timeWindowDifferenceInDays <= 7) {
    return "d EEE";
  } else {
    return "d";
  }
};

const usePlot = ({
  marks: renderMarks,
  x,
  y,
  timeWindow,
  data,
  isLoading,
  loadingStyle = "bar",
}: PlotProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const plot = useRef<ReturnType<typeof ObservablePlot.plot> | undefined>();

  useEffect(() => {
    if (containerRef.current) {
      const marks = isLoading
        ? getLoadingMarks(timeWindow, loadingStyle)
        : renderMarks.flatMap((mark) => mark(data));
      const domain = getDomain(timeWindow);
      const interval = getIntervalForTimeWindow(timeWindow);

      plot.current = ObservablePlot.plot({
        marginLeft: PLOT_OPTIONS.marginLeft,
        marginBottom: PLOT_OPTIONS.marginBottom,
        marginRight: PLOT_OPTIONS.marginRight,
        width: containerRef.current.clientWidth,
        height: containerRef.current.clientWidth / 3.4,

        style: PLOT_OPTIONS.style,
        y: {
          ...PLOT_OPTIONS.y,
          // Use auto domain if there is data, otherwise use [0, 1000]
          domain:
            isLoading || !data || data.length === 0 ? [0, 1000] : undefined,
          ...y,
        },
        x: {
          ...PLOT_OPTIONS.x,
          domain,
          padding: domain.length > 10 ? 0.35 : 0.5,
          tickFormat: (d, i) => {
            if (interval === "hour" && i % 4 !== 0) {
              // Show only every fourth for the hour interval
              return "";
            }
            return formatDate(d, filtersToFormat(timeWindow));
          },
          ...x,
        },
        marks: [ObservablePlot.ruleY([0], { stroke: ["#F3F4F6"] }), ...marks],
      });

      if (containerRef.current.firstChild) {
        containerRef.current.firstChild.replaceWith(plot.current);
      } else {
        containerRef.current.appendChild(plot.current);
      }
    }
  }, [renderMarks, x, y, data, isLoading, loadingStyle, timeWindow]);

  useEffect(() => {
    return () => plot.current?.remove();
  }, []);

  return containerRef;
};

export const Plot: React.FC<PlotProps> = ({
  marks = [],
  x,
  y,
  timeWindow,
  data,
  isLoading,
  loadingStyle,
}) => {
  const containerRef = usePlot({
    marks,
    x,
    y,
    timeWindow,
    data,
    isLoading,
    loadingStyle,
  });

  return (
    <div ref={containerRef} className={twJoin(isLoading && "animate-pulse")} />
  );
};
