import { v4 as uuidV4 } from "uuid";

import {
  DecisionTableField,
  DecisionTablePredicate,
  ScorecardCase,
  ScorecardFactor,
} from "src/clients/flow-api/api";
import {
  getDefaultEffect,
  getDefaultPredicate,
} from "src/decisionTableNode/defaultValues";

export const getDefaultCase = (
  predicateOptions?: Partial<DecisionTablePredicate>,
): ScorecardCase => ({
  id: uuidV4(),
  effect: getDefaultEffect(),
  predicate: getDefaultPredicate(predicateOptions),
});

const getDefaultField = (options?: Partial<DecisionTableField>) => ({
  id: uuidV4(),
  value: "",
  ...options,
});

export const getDefaultFactor = (
  options?: Partial<ScorecardFactor>,
): ScorecardFactor => ({
  id: uuidV4(),
  name: "",
  weight: getDefaultField(),
  input_field: getDefaultField(),
  output_field: getDefaultField(),
  fallback: getDefaultEffect({ value: "0" }),
  cases: [getDefaultCase()],
  ...options,
});
