import React, { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { EditorCellProps } from "src/base-components/EditorTable/types";
import { DecisionTablePredicate } from "src/clients/flow-api";
import { RowShape } from "src/decisionTableNode/utils";
import { ConditionEditor } from "src/scorecardNode/ConditionEditor";
import { useFactorContext } from "src/scorecardNode/context";
import { FormShape } from "src/scorecardNode/types";

type Props = EditorCellProps<RowShape, DecisionTablePredicate>;

export const ContextConditionEditor: React.FC<Props> = ({
  state,
  context,
  value: propValue,
  columnDef,
  cellRef,
  cellId,
}) => {
  const { index: factorIndex } = useFactorContext();
  const { watch, setValue } = useFormContext<FormShape>();
  const caseIndex = (() => {
    if (!("row" in context)) {
      return 0;
    }
    const index = context.row.index;
    return context.row.parentId ? index + 1 : index;
  })();

  const valueFieldName =
    `factors.${factorIndex}.cases.${caseIndex}.predicate.value` as const;
  const operatorFieldName =
    `factors.${factorIndex}.cases.${caseIndex}.predicate.operator` as const;

  const value = watch(valueFieldName);
  const operator = watch(operatorFieldName);

  const handleChange = useCallback(
    (predicate: DecisionTablePredicate | null) => {
      if (!predicate) {
        return;
      }
      setValue(valueFieldName, predicate.value);
      setValue(operatorFieldName, predicate.operator);
    },
    [setValue, valueFieldName, operatorFieldName],
  );

  const memoizedValue = useMemo(
    () => ({
      id: propValue?.id,
      value: value,
      operator: operator ?? "is_any",
    }),
    [propValue?.id, value, operator],
  );

  return (
    <ConditionEditor
      cellId={cellId}
      cellRef={cellRef}
      columnDef={columnDef}
      context={context}
      state={state}
      value={memoizedValue}
      onChange={handleChange}
    />
  );
};
