import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  InputMappingGroupT,
  InputMappingT,
  IntegrationResourceT,
} from "src/integrationNode/types";

const getDefaultAddressFields = (): { [key: string]: InputMappingT } => ({
  street1: {
    id: uuidV4(),
    type: "text",
    displayName: "Street address",
    assignedTo: "",
  },
  street2: {
    id: uuidV4(),
    type: "text",
    displayName: "Street address line 2",
    assignedTo: "",
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    hint: `A two-letter code for the U.S. state or territory of the address (e.g., "CA" for California)`,
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
  },
  country: {
    id: uuidV4(),
    type: "text",
    displayName: "Country",
    assignedTo: "",
    hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g, "US" for United States)`,
  },
});

const getDefaultAddress = (): InputMappingGroupT => ({
  displayName: "Address",
  getDefaultElements: getDefaultAddressFields,
  elements: getDefaultAddressFields(),
});

export const getDefaultSardineIdentityVerification =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "sardine",
      resource: "sardine_identity_verification",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        session_key: {
          id: uuidV4(),
          type: "text",
          displayName: "Session key",
          assignedTo: "",
          hint: `Leave empty if not using Sardine's Risk SDK. For more details, consult Sardine's Risk documentation or contact your Customer Success representative.`,
        },
        customer_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Customer ID",
          assignedTo: "",
          hint: `The customer's unique identifier in Sardine's system. Can be left empty, in which case a newly generated ID will be used. Using a customer ID is helpful for tracking all inquiries for a specific customer within the Sardine dashboard.`,
        },
        first_name: {
          id: uuidV4(),
          type: "text",
          displayName: "First name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        middle_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Middle name",
          assignedTo: "",
        },
        last_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Last name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        phone_number: {
          id: uuidV4(),
          type: "text",
          displayName: "Phone number",
          assignedTo: "",
          hint: `The consumer's phone number in ISO E.164 format (e.g., USA: +14151231234, UK: +442012341234)`,
        },
        email: {
          id: uuidV4(),
          type: "text",
          displayName: "Email",
          assignedTo: "",
        },
        tax_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Tax ID",
          assignedTo: "",
          hint: `The consumer's SSN or ITIN. Valid formats include: "123-45-6789" or "123456789". Do not send an empty string.`,
        },
        date_of_birth: {
          id: uuidV4(),
          type: "text",
          displayName: "Date of birth",
          assignedTo: "",
          hint: `The date of birth in format "YYYY-MM-DD"`,
        },
      },
      grouped: {
        address: getDefaultAddress(),
      },
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        risk_score: {
          ...getNamedOutputMapping("Risk score"),
          hint: `ML Risk Score(from 1 to 100) for the current consumer. The ML model used to predict the score is trained on velocity and aggregation features, phone, email, bank, tax, card, IP, location, device and behavior intelligence signals. The score can be used once feedback for historical transactions has been provided.`,
        },
        risk_level: {
          ...getNamedOutputMapping("Risk level"),
          hint: `Riskiness of the consumer committing identity fraud. Possible values are: "very_high", "high", "medium", "low". This field is based on the signals sent in the response.`,
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultSardineFeedback = (): IntegrationResourceT => ({
  providerResource: {
    provider: "sardine",
    resource: "sardine_feedback",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      session_key: {
        id: uuidV4(),
        type: "text",
        displayName: "Session key",
        assignedTo: "",
        hint: `Leave empty if not using Sardine's Risk SDK. For more details, consult Sardine's Risk documentation or contact your Customer Success representative.`,
      },
      kind: {
        id: uuidV4(),
        type: "text",
        displayName: "Kind",
        assignedTo: "",
        hint: `Determines the type of event the feedback is for. One of: "flow", "transaction", "issuing", "challenge", or "review".`,
      },
      flow_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Flow name",
        assignedTo: "",
        hint: `The name sent in flow.name in the original flow event`,
      },
      flow_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Flow ID",
        assignedTo: "",
        hint: `The unique identifier sent in flow.id in the original flow event`,
      },
      customer_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Customer ID",
        assignedTo: "",
        hint: `The customer's unique identifier in Sardine's system`,
      },
      device_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Device ID",
        assignedTo: "",
        hint: `ID of the device given by a specified source`,
      },
      device_ip: {
        id: uuidV4(),
        type: "text",
        displayName: "Device IP",
        assignedTo: "",
        hint: `IP address with which the device is associated. Can be either IPv4 or IPv6.`,
      },
      device_source: {
        id: uuidV4(),
        type: "text",
        displayName: "Device source",
        assignedTo: "",
        hint: `Source from where the Device ID was created, e.g., Sardine, Merchant A, or other. Note: if the provided source is not 'Sardine', IP-related details will be returned in the place of device intelligence features.`,
      },
      device_status: {
        id: uuidV4(),
        type: "text",
        displayName: "Device status",
        assignedTo: "",
        hint: `Indicating if device is blocked by merchant's system`,
      },
      device_fingerprint: {
        id: uuidV4(),
        type: "text",
        displayName: "Device fingerprint",
        assignedTo: "",
        hint: ``,
      },
      device_user_agent: {
        id: uuidV4(),
        type: "text",
        displayName: "Device user agent",
        assignedTo: "",
        hint: ``,
      },
      device_language: {
        id: uuidV4(),
        type: "text",
        displayName: "Device language",
        assignedTo: "",
        hint: `The accept-language HTTP header, e.g., 'en-EN' or 'de-DE'`,
      },
      device_created_at_milliseconds: {
        id: uuidV4(),
        type: "text",
        displayName: "Device created time",
        assignedTo: "",
        hint: `An integer representing the time the device was seen in your system as a Unix timestamp in milliseconds`,
      },
      transaction_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Transaction ID",
        assignedTo: "",
        hint: `Merchant defined transaction ID (aka order number)`,
      },
      feedback_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Feedback ID",
        assignedTo: "",
        hint: `Unique identifier for this feedback event. For example, in the case of multiple partial chargebacks, this would be the id of a particular chargeback.`,
      },
      feedback_scope: {
        id: uuidV4(),
        type: "text",
        displayName: "Feedback scope",
        assignedTo: "",
        hint: `Scope of the Feedback. Must be either "user" or "session". Pass "session" in cases of ATO where the user is still good, but session is fraudulent OR pass "user" in case the user is fraudulent. Default scope is "user".`,
      },
      feedback_type: {
        id: uuidV4(),
        type: "text",
        displayName: "Feedback type",
        assignedTo: "",
        hint: `The feedback type, e.g., "signup", "onboarding", "offboarding", "login", etc. Visit our help center for all accepted values.`,
      },
      feedback_status: {
        id: uuidV4(),
        type: "text",
        displayName: "Feedback status",
        assignedTo: "",
        hint: `Status of feedback, dependent on Feedback type from above. Refer to the mapping table in Sardine's documentation for details.`,
      },
      feedback_reason: {
        id: uuidV4(),
        type: "text",
        displayName: "Feedback reason",
        assignedTo: "",
        hint: `Reason code that defines the reason for feedback, such as ACH code (R10), processor declined code, or chargeback code`,
      },
      feedback_dispute_status: {
        id: uuidV4(),
        type: "text",
        displayName: "Feedback dispute status",
        assignedTo: "",
        hint: `Status of chargeback dispute from the end customer's perspective, i.e., not the merchant's or bank's perspective`,
      },
      feedback_description: {
        id: uuidV4(),
        type: "text",
        displayName: "Feedback description",
        assignedTo: "",
        hint: `Free-form text to explain the feedback`,
      },
      feedback_processor: {
        id: uuidV4(),
        type: "text",
        displayName: "Feedback processor",
        assignedTo: "",
        hint: `Processor name, e.g., 'Stripe', or 'WorldPay'`,
      },
      feedback_time_milliseconds: {
        id: uuidV4(),
        type: "text",
        displayName: "Feedback time",
        assignedTo: "",
        hint: `An integer representing he time the merchant knew about the chargeback status, or the time the authorization was sent, as a Unix timestamp in milliseconds`,
      },
      feedback_reviewed_by: {
        id: uuidV4(),
        type: "text",
        displayName: "Feedback reviewed by",
        assignedTo: "",
        hint: `The user ID or email of the analyst who manually reviewed this transaction. Used only when Feedback type is set to "manual_review".`,
      },
    },
    grouped: {},
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {},
  },
  config: defaultResourceConfig,
});
