import { faClose, faKey, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { useState } from "react";

import {
  useOrganizationAuthSources,
  useDeleteOrganizationAuthSource,
  useCreateOrganizationAuthSource,
} from "src/api/taktile/queries";
import { Button } from "src/base-components/Button";
import { Card } from "src/base-components/Card";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { EmptyState } from "src/base-components/EmptyState";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { LoadingView } from "src/base-components/LoadingView";
import { TableComp as Table } from "src/base-components/Table";
import {
  OrganizationAuthSourceCreateNamed,
  OrganizationAuthSourceResponse,
  TaktileApiSchemasOrganizationAvailableAuthSources,
} from "src/clients/taktile-api";
import { HeaderCell } from "src/dataTable/HeaderCell";

const Actions: React.FC<{ orgId: string; authSourceId: string }> = ({
  orgId,
  authSourceId,
}) => {
  const { mutateAsync: deleteAuthSource } = useDeleteOrganizationAuthSource(
    orgId,
    authSourceId,
  );
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDelete = () => {
    setShowConfirmation(false);
    deleteAuthSource();
  };

  return (
    <div className="flex flex-row-reverse">
      <Icon icon={faClose} onClick={() => setShowConfirmation(true)} />
      <ConfirmationModal
        open={showConfirmation}
        title="Are you sure you want to delete this auth source?"
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleDelete}
      />
    </div>
  );
};

const AddAuthProviderModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onConfirm: (authSource: OrganizationAuthSourceCreateNamed) => void;
}> = ({ open, onClose, onConfirm }) => {
  const [authSource, setAuthSource] =
    useState<OrganizationAuthSourceCreateNamed>({
      source_name: TaktileApiSchemasOrganizationAvailableAuthSources.GOOGLE,
      domain_name: "",
      client_id: "",
      client_secret: "",
    });

  const handleConfirm = () => {
    onConfirm(authSource);
  };

  return (
    <ConfirmationModal
      open={open}
      title="Add auth provider"
      onClose={onClose}
      onConfirm={handleConfirm}
    >
      <div className="space-y-3">
        <div className="flex flex-col">
          <label className="text-left">Auth provider name</label>
          <select
            className="input border-gray-200"
            value={authSource.source_name}
            required
            onChange={(e) =>
              setAuthSource({
                ...authSource,
                source_name: e.target
                  .value as TaktileApiSchemasOrganizationAvailableAuthSources,
              })
            }
          >
            <option value="google">Google</option>
            <option value="microsoft">Microsoft</option>
            <option value="okta">Okta</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label className="text-left">Linked domain</label>
          <Input
            value={authSource.domain_name}
            required
            onChange={(e) =>
              setAuthSource({
                ...authSource,
                domain_name: e.target.value,
              })
            }
          />
        </div>
        {authSource.source_name === "okta" && (
          <>
            <div className="flex flex-col">
              <label className="text-left">Well Known URL</label>
              <Input
                required={authSource.source_name === "okta"}
                value={authSource.well_known_url}
                onChange={(e) =>
                  setAuthSource({
                    ...authSource,
                    well_known_url: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex flex-col">
              <label className="text-left">Client ID</label>
              <Input
                required={authSource.source_name === "okta"}
                value={authSource.client_id}
                onChange={(e) =>
                  setAuthSource({
                    ...authSource,
                    client_id: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex flex-col">
              <label className="text-left">Client Secret</label>
              <Input
                required={authSource.source_name === "okta"}
                value={authSource.client_secret}
                onChange={(e) =>
                  setAuthSource({
                    ...authSource,
                    client_secret: e.target.value,
                  })
                }
              />
            </div>
          </>
        )}
      </div>
    </ConfirmationModal>
  );
};

const AddAuthProvider: React.FC<{ orgId: string }> = ({ orgId }) => {
  const { mutateAsync: createAuthSource } =
    useCreateOrganizationAuthSource(orgId);

  const [showAddModal, setShowAddModal] = useState(false);

  const handleAdd = async (authSource: OrganizationAuthSourceCreateNamed) => {
    await createAuthSource(authSource);
    setShowAddModal(false);
  };

  return (
    <div className="flex justify-end">
      <Button
        iconLeft={faPlus}
        size="sm"
        variant="secondary"
        onClick={() => setShowAddModal(true)}
      >
        Add auth provider
      </Button>
      <AddAuthProviderModal
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        onConfirm={handleAdd}
      />
    </div>
  );
};

const getColumns = (
  orgId: string,
): ColumnDef<OrganizationAuthSourceResponse, string>[] => {
  const cellSpanClassName =
    "font-inter-normal-12px block max-w-90 truncate py-2 text-left";

  return [
    {
      header: () => <HeaderCell>Auth provider</HeaderCell>,
      accessorKey: "user_auth_source_name",
      cell: (info) => (
        <span className={cellSpanClassName}>
          {info.row.original.user_auth_source_name}
        </span>
      ),
    },
    {
      header: () => <HeaderCell>Linked domain</HeaderCell>,
      accessorKey: "domain_name",
      cell: (info) => (
        <span className={cellSpanClassName}>
          {info.row.original.domain_name}
        </span>
      ),
    },
    {
      header: () => <AddAuthProvider orgId={orgId} />,
      accessorKey: "x",
      cell: (info) => (
        <Actions
          authSourceId={info.row.original.id}
          orgId={info.row.original.organization_id}
        />
      ),
    },
  ];
};

const AuthSourcesTable: React.FC<{
  orgId: string;
  authSources: OrganizationAuthSourceResponse[];
}> = ({ authSources, orgId }) => {
  return (
    <>
      <Card
        className="w-100 border-1 mb-auto space-y-3 rounded-lg border-gray-100 border-opacity-50 p-4.5 pt-0"
        shadow={false}
        variant="inverted"
      >
        {authSources.length > 0 ? (
          <Card.Content className="text-center">
            <Table
              columns={getColumns(orgId)}
              data={authSources}
              headerPropsGetter={() => ({
                classNameOverrides: "first:relative",
              })}
              noScroll
            />
          </Card.Content>
        ) : (
          <Card.Content className="flex h-[228px] items-center justify-center">
            <EmptyState
              action={<AddAuthProvider orgId={orgId} />}
              description="There are currently no authentication sources configured for this organization."
              headline="No Authentication Sources"
              icon={faKey}
            />
          </Card.Content>
        )}
      </Card>
    </>
  );
};

export const AuthProviders: React.FC<{ orgId: string }> = ({ orgId }) => {
  const authSourcesResult = useOrganizationAuthSources(orgId);

  const renderUpdated = (
    authSourcesResult: OrganizationAuthSourceResponse[],
  ) => <AuthSourcesTable authSources={authSourcesResult} orgId={orgId} />;

  return (
    <LoadingView
      queryResult={authSourcesResult}
      renderUpdated={renderUpdated}
    />
  );
};
