import { useMemo } from "react";

import { ConnectionT } from "src/api/connectApi/types";
import { TableComp as Table } from "src/base-components/Table";
import { columns as tableColumns } from "src/connections/columns";

export const ConnectionList: React.FC<{
  connections: ConnectionT[];
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onTest: (id: string) => void;
  testingConnection?: ConnectionT;
}> = ({ onEdit, onDelete, onTest, testingConnection, connections }) => {
  const columns = useMemo(
    () =>
      tableColumns({
        onDelete,
        onEdit,
        onTest,
        testingConnection,
      }),
    [onDelete, onEdit, onTest, testingConnection],
  );
  return (
    <div className="flex flex-row justify-center">
      <Table
        columns={columns}
        data={connections}
        frameClassName="px-4 shadow-sm rounded-lg bg-white w-full pb-2"
        rowClassName="hover:bg-gray-50 last:border-b-0 cursor-pointer"
        rowPropsGetter={(rowData) => ({
          "data-loc": `connection-row-${rowData.original.name}-${rowData.original.id}}`,
          onClick: () => onEdit(rowData.original.id),
        })}
      />
    </div>
  );
};
