import React, { useMemo } from "react";

import { Button } from "src/base-components/Button";
import { Modal } from "src/base-components/Modal";
import { FlowDbShallow } from "src/clients/flow-api";
import { RelatedFlowList } from "src/flow/modals/RelatedFlowList";
import { groupRelatedFlows } from "src/flow/modals/utils";
import { useFlowContext } from "src/router/routerContextHooks";

export type Props = {
  open: boolean;
  onClose: () => void;
  afterLeave: () => void;
  title: string;
  subtitle: string;
  relatedFlows: FlowDbShallow[] | undefined;
};

export const RelatedFlowsModal: React.FC<Props> = ({
  open,
  onClose,
  afterLeave,
  title,
  subtitle,
  relatedFlows,
}) => {
  const { workspace } = useFlowContext();
  const [_, groupedChildFlows] = useMemo(
    () => groupRelatedFlows(relatedFlows ?? []),
    [relatedFlows],
  );

  return (
    <Modal
      afterLeave={afterLeave}
      className="w-120"
      open={open}
      subtitle={subtitle}
      title={title}
      onClose={onClose}
    >
      <div className="p-6 pt-4">
        <RelatedFlowList
          groupedRelatedFlows={groupedChildFlows}
          workspace={workspace}
        />
      </div>
      <div className="flex flex-row items-center justify-end px-6 pb-4 pt-3">
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
