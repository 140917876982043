import { FlowAnalyzeParametersResultBET } from "src/api/types";

/**
 * Gets in which node each parameter is used from the analysis data
 * @returns object with parameter names as keys and array of node ids where the parameter is used
 */
export const getParametersUsageFromAnalysisData = (
  analysisData: FlowAnalyzeParametersResultBET,
  parameterNames: string[],
) => {
  const parametersUsage: Record<string, string[]> = Object.fromEntries(
    parameterNames.map((parameterName) => [parameterName, []]),
  );
  Object.entries(analysisData).forEach(([key, value]) => {
    value.forEach((paramName: string) => {
      if (parametersUsage[paramName]) {
        parametersUsage[paramName].push(key);
      } else {
        parametersUsage[paramName] = [key];
      }
    });
  });
  return parametersUsage;
};
