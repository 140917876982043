import React from "react";
import { twMerge } from "tailwind-merge";

type PropsT = React.HTMLProps<HTMLAnchorElement> & {
  href: string;
};

export const ExternalLink = React.forwardRef<HTMLAnchorElement, PropsT>(
  ({ className, href, children, ...props }, ref) => (
    <a
      ref={ref}
      className={twMerge("text-indigo-600", className)}
      href={href}
      rel="noreferrer noopener"
      target="_blank"
      {...props}
    >
      {children}
    </a>
  ),
);
