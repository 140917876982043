import { JSONSchema7TypeName } from "json-schema";
import { isEqual } from "lodash";

import { ManifestJSONSchema } from "src/api/connectApi/manifestTypes";

export const isFieldOfNullableType = (
  field: ManifestJSONSchema,
  type: JSONSchema7TypeName,
): boolean => {
  return field.type === type || isEqual(field.type, [type, "null"]);
};
