import {
  faCheck,
  faChevronDown,
  faList,
} from "@fortawesome/pro-regular-svg-icons";
import { capitalize, isEqual, pick } from "lodash";
import React from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { AnalyticsChartBase } from "src/analytics/AnalyticsChart";
import { DimensionsCombobox } from "src/analytics/DimensionsCombobox";
import BarIcon from "src/analytics/chart_icons/bar.svg?react";
import DotIcon from "src/analytics/chart_icons/dot.svg?react";
import LineIcon from "src/analytics/chart_icons/line.svg?react";
import {
  CHART_MARKS,
  ChartMarkType,
  DEFAULT_CHART_TITLE,
  REDUCER_NAMES,
} from "src/analytics/constants";
import { useAnalyticsPlotSpec } from "src/analytics/hooks";
import { ChartState, DimensionReducer } from "src/analytics/types";
import {
  AnalyticsKey,
  SectionKey,
  VERSION_KEY,
  getActualKey,
  getDisplayKey,
  mapFormStateToPlotSpec,
} from "src/analytics/utils";
import { GenericObjectT } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { Divider } from "src/base-components/Divider";
import { EmptyState } from "src/base-components/EmptyState";
import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { InformationPill } from "src/base-components/InformationPill";
import { Pill } from "src/base-components/Pill";
import { Select } from "src/base-components/Select";
import { TitleEditor } from "src/base-components/TitleEditor";
import { Tooltip } from "src/base-components/Tooltip";
import {
  Chart,
  ChartDimensionReduceEnum,
  ChartMark,
} from "src/clients/flow-api";
import { DOCS_COMPARATIVE_ANALYTICS } from "src/constants/ExternalLinks";
import { Modal } from "src/design-system/Modal";

type ChartFormModalProps = {
  isOpen: boolean;
  onClose: () => void;
  analytics?: { data: GenericObjectT[]; keys: AnalyticsKey[] };
  chart?: Chart;
  afterLeave?: () => void;
  isMultiversionTestRun?: boolean;
  onSubmit: (chartData: ChartState) => void;
  headerPill?: React.ReactNode;
  isFlowLevelAnalytics?: boolean;
};

const BASE_CHART_STATE: ChartState = {
  mark: ChartMark.AUTO,
  title: DEFAULT_CHART_TITLE,
  description: "",
  dimensions: {
    x: { value: undefined, reduce: ChartDimensionReduceEnum.AUTO },
    y: { value: undefined, reduce: ChartDimensionReduceEnum.AUTO },
    color: { value: undefined, reduce: undefined },
    fx: { value: undefined },

    // We don't use these, but still need to pass them to the API
    size: { value: undefined },
    fy: { value: undefined },
  },
};

const DIMENSION_FIELDS = ["x", "y", "color", "fx"] as const;

const FIELDS_TO_COMPARE = [
  "mark",
  "dimensions.x",
  "dimensions.y",
  "dimensions.color",
  "dimensions.fx",
];
const isDefaultChartState = ([mark, dimensions]: [
  ChartState["mark"],
  ChartState["dimensions"],
]) =>
  isEqual(
    pick({ mark, dimensions }, FIELDS_TO_COMPARE),
    pick(BASE_CHART_STATE, FIELDS_TO_COMPARE),
  );

// Make sure we keep combobox in controlled state
const forceNullValue = (value: string | undefined) => value ?? null;

export const ChartFormModal = ({
  isOpen,
  onClose,
  analytics = { data: [], keys: [] },
  chart,
  afterLeave,
  isMultiversionTestRun,
  onSubmit,
  headerPill,
  isFlowLevelAnalytics = false,
}: ChartFormModalProps) => {
  const isEditing = !!chart;
  const formMethods = useForm<ChartState>({
    values: isEditing
      ? pick(chart, ["title", "description", "dimensions", "mark"])
      : BASE_CHART_STATE,
    defaultValues: BASE_CHART_STATE,
  });
  const dimensionOptions = analytics.keys.map((key) => ({
    key: key.displayKey,
    value: (
      <DimensionOption
        disabled={key.disabled}
        sectionKey={key.sectionKey}
        value={key.displayKey}
      />
    ),
    sectionKey: key.sectionKey,
    disabled: key.disabled,
  }));

  const form = useWatch<ChartState, ["mark", "dimensions"]>({
    name: ["mark", "dimensions"],
    control: formMethods.control,
  });

  const plotSpec = useAnalyticsPlotSpec({
    data: analytics.data,
    options: mapFormStateToPlotSpec(form, analytics.keys ?? []),
  });

  const thereIsNoVersionUsage = !DIMENSION_FIELDS.some(
    (dimension) => form[1][dimension].value === VERSION_KEY,
  );

  return (
    <Modal
      afterLeave={() => {
        formMethods.reset();
        afterLeave?.();
      }}
      open={isOpen}
      size="lg"
      onClose={onClose}
    >
      <Modal.Header>
        <div className="flex items-center justify-between">
          {isEditing ? "Edit chart" : "Create chart"}
          {headerPill}
        </div>
      </Modal.Header>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Modal.Content>
            <div className="flex h-[541px] gap-x-4">
              <div className="flex w-70 flex-col gap-y-3">
                <FormField label="Chart type">
                  <Controller
                    name="mark"
                    render={({ field }) => (
                      <Select
                        options={CHART_MARKS.map((mark) => ({
                          key: mark,
                          value: (
                            <ChartTypeOption
                              autoMark={
                                field.value === ChartMark.AUTO &&
                                field.value === mark
                                  ? plotSpec?.mark
                                  : undefined
                              }
                              value={mark}
                            />
                          ),
                        }))}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </FormField>

                <Divider />

                <FormField
                  label="X Axis"
                  reducer={
                    <Controller
                      name="dimensions.x.reduce"
                      render={({ field }) => (
                        <ReducerDropdown
                          autoValue={plotSpec.xReducer}
                          dataLoc="dimensions-x-reduce"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  }
                >
                  <Controller
                    name="dimensions.x.value"
                    render={({ field }) => (
                      <DimensionsCombobox
                        dataLoc="dimensions-x-picker"
                        isFlowLevelAnalytics={isFlowLevelAnalytics}
                        options={dimensionOptions}
                        placeholder="Select field"
                        value={forceNullValue(
                          getDisplayKey({ value: field.value }, analytics.keys),
                        )}
                        onChange={(v) =>
                          field.onChange(getActualKey(v, analytics.keys))
                        }
                      />
                    )}
                  />
                </FormField>

                <FormField
                  label="Y Axis"
                  reducer={
                    <Controller
                      name="dimensions.y.reduce"
                      render={({ field }) => (
                        <ReducerDropdown
                          autoValue={plotSpec.yReducer}
                          dataLoc="dimensions-y-reduce"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  }
                >
                  <Controller
                    name="dimensions.y.value"
                    render={({ field }) => (
                      <DimensionsCombobox
                        dataLoc="dimensions-y-picker"
                        isFlowLevelAnalytics={isFlowLevelAnalytics}
                        options={dimensionOptions}
                        placeholder="Select field"
                        value={forceNullValue(
                          getDisplayKey({ value: field.value }, analytics.keys),
                        )}
                        onChange={(v) =>
                          field.onChange(getActualKey(v, analytics.keys))
                        }
                      />
                    )}
                  />
                </FormField>

                <FormField label="Break down by">
                  <Controller
                    name="dimensions.color.value"
                    render={({ field }) => (
                      <DimensionsCombobox
                        dataLoc="dimensions-color-picker"
                        isFlowLevelAnalytics={isFlowLevelAnalytics}
                        options={dimensionOptions}
                        placeholder="Select field"
                        value={forceNullValue(
                          getDisplayKey({ value: field.value }, analytics.keys),
                        )}
                        onChange={(v) =>
                          field.onChange(getActualKey(v, analytics.keys))
                        }
                      />
                    )}
                  />
                </FormField>

                <FormField label="Compare by">
                  <Controller
                    name="dimensions.fx.value"
                    render={({ field }) => (
                      <DimensionsCombobox
                        dataLoc="dimensions-fx-picker"
                        isFlowLevelAnalytics={isFlowLevelAnalytics}
                        options={dimensionOptions}
                        placeholder="Select field"
                        value={forceNullValue(
                          getDisplayKey({ value: field.value }, analytics.keys),
                        )}
                        onChange={(v) =>
                          field.onChange(getActualKey(v, analytics.keys))
                        }
                      />
                    )}
                  />
                </FormField>
              </div>
              <div className="min-w-0 flex-1">
                <div className="flex h-full flex-col gap-y-4 rounded-lg border border-gray-200 px-4 py-3">
                  <div className="flex gap-x-4">
                    <div className="flex min-w-0 flex-1 flex-col">
                      <Controller
                        name="title"
                        render={({ field }) => (
                          <TitleEditor
                            className="-mx-1.5 rounded px-1.5 text-gray-800 outline-0 font-inter-semibold-13px hover:bg-gray-100 focus:bg-white"
                            dataLoc="chart-modal-title"
                            placeholder={DEFAULT_CHART_TITLE}
                            value={field.value}
                            onSubmit={field.onChange}
                          />
                        )}
                      />
                      <Controller
                        name="description"
                        render={({ field }) => (
                          <TitleEditor
                            className="-mx-1.5 rounded px-1.5 italic text-gray-500 outline-0 font-inter-normal-12px hover:bg-gray-100 focus:bg-white"
                            dataLoc="chart-modal-description"
                            placeholder="Add chart description"
                            value={field.value || ""}
                            onSubmit={field.onChange}
                          />
                        )}
                      />
                    </div>
                    {isMultiversionTestRun &&
                      thereIsNoVersionUsage &&
                      !isDefaultChartState(form) && (
                        <div>
                          <InformationPill
                            action={{
                              text: "Learn more",
                              onClick: () => {
                                window.open(
                                  DOCS_COMPARATIVE_ANALYTICS,
                                  "_blank",
                                );
                              },
                            }}
                            type="warning"
                          >
                            Chart data is from multiple versions with no version
                            comparison or breakdown.
                          </InformationPill>
                        </div>
                      )}
                  </div>
                  <div
                    className="flex min-h-0 flex-1 items-center justify-center"
                    data-loc="chart-modal-chart"
                  >
                    {isDefaultChartState(form) ? (
                      <EmptyState
                        description="Add the relevant information for us to configure the chart"
                        headline="Add chart configuration"
                        icon={faList}
                      />
                    ) : (
                      <AnalyticsChartBase
                        data={analytics.data ?? []}
                        options={mapFormStateToPlotSpec(
                          form,
                          analytics.keys ?? [],
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Footer
            primaryButton={
              <Button
                dataLoc="chart-form-modal-submit"
                disabled={!formMethods.formState.isDirty}
                htmlType="submit"
                loading={formMethods.formState.isSubmitting}
              >
                Save
              </Button>
            }
          ></Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

const FormField = ({
  label,
  children,
  reducer,
}: {
  children: React.ReactNode;
  label: string;
  reducer?: React.ReactNode;
}) => (
  <div>
    <div className="flex items-center justify-between">
      <div className="mb-1 text-gray-500 font-inter-normal-12px">{label}</div>
      <div>{reducer}</div>
    </div>
    {children}
  </div>
);

const ReducerDropdown: React.FC<{
  value: Exclude<DimensionReducer, null>;
  onChange: (value: DimensionReducer) => void;
  autoValue?: Nullable<string>;
  dataLoc?: string;
}> = ({ value, onChange, autoValue, dataLoc }) => (
  <FixedPositionedDropdown<string, DimensionReducer>
    buttonClassName="border-0 ring-0 p-0.5 focus:border-0 focus:ring-0"
    dataLoc={dataLoc}
    elements={REDUCER_NAMES.map(([value, key]) => ({
      key,
      value,
    }))}
    itemsClassNames="max-h-60 overflow-y-auto min-w-[10rem]"
    listMatchesButtonWidth={false}
    renderButtonValue={(value) => {
      const isAuto = value === "auto";
      const hasAutoReducer = isAuto && autoValue !== undefined;
      return (
        <Pill size="sm" variant="gray">
          <Pill.Text fontType="code">
            {value ?? "—"} {hasAutoReducer ? `- ${autoValue}` : ""}
          </Pill.Text>
          <Pill.Icon icon={faChevronDown} />
        </Pill>
      );
    }}
    renderValue={(element) => (
      <div className="flex justify-between px-4 py-3.5 text-xs-sm text-gray-800 hover:bg-gray-50">
        {element.value}
        {element.selected && (
          <Icon color="text-indigo-600" icon={faCheck} size="2xs" />
        )}
      </div>
    )}
    selected={value}
    onSelect={onChange}
  />
);

const DimensionOption: React.FC<{
  value: string;
  sectionKey: SectionKey;
  disabled: boolean;
}> = ({ value, sectionKey, disabled }) => {
  if (sectionKey === SectionKey.other || sectionKey === SectionKey.node_logic) {
    return <div className="truncate text-gray-800">{value}</div>;
  }
  const [prefixOrEntireValue, ...rest] = value.split(".");

  const displayValue =
    rest.length === 0 ? (
      <span>{prefixOrEntireValue}</span>
    ) : (
      <>
        <span className="text-green-600">{prefixOrEntireValue}.</span>
        {rest.join(".")}
      </>
    );

  if (disabled) {
    return (
      <Tooltip
        align="center"
        placement="right"
        placementOffset={36}
        title="Plotting charts with Arrays or Objects is not supported yet."
        asChild
      >
        <div className="w-full truncate text-indigo-700 opacity-40">
          {displayValue}
        </div>
      </Tooltip>
    );
  }
  return (
    <div
      className={twJoin(
        "truncate text-indigo-700 font-code-13",
        disabled && "opacity-40",
      )}
    >
      {displayValue}
    </div>
  );
};

const CHART_ICONS: Record<ChartMark, typeof LineIcon> = {
  [ChartMark.AUTO]: DotIcon,
  [ChartMark.LINE]: LineIcon,
  [ChartMark.BAR]: BarIcon,
  [ChartMark.AREA]: LineIcon,
  [ChartMark.DOT]: DotIcon,
  [ChartMark.RULE]: LineIcon,
};

const ChartTypeOption: React.FC<{
  value: ChartMarkType;
  autoMark: Exclude<ChartMarkType, "auto"> | undefined;
}> = ({ value, autoMark }) => {
  const actualMark = value === ChartMark.AUTO && autoMark ? autoMark : value;
  const Icon = CHART_ICONS[actualMark];

  return (
    <div className="flex items-center gap-x-2">
      <Icon className="h-4.5 w-6.5 rounded-sm border border-gray-300 px-px py-0.5" />
      {capitalize(value)}
      {autoMark && " - " + capitalize(autoMark)}
    </div>
  );
};
