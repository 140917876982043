import { AxiosError } from "axios";

import {
  CustomConnectionNode,
  IntegrationNode,
  InboundWebhookConnectionNode,
  NodeT,
  ManualReviewNode,
  DatabaseConnectionNode,
  FlowNode,
  DatasetMockableNodes,
  IntegrationNodes,
  LoopNode,
  ParentNode,
  NodeRunStateV2,
  NodeHistoricalRunStateV2,
  ManifestConnectionNode,
} from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";

export const isIntegrationNode = (node: NodeT): node is IntegrationNode =>
  node.type === NODE_TYPE.INTEGRATION_NODE;

export const isInboundWebhookConnectionNode = (
  node: NodeT,
): node is InboundWebhookConnectionNode =>
  node.type === NODE_TYPE.WEBHOOK_CONNECTION_NODE;

export const isDatabaseConnectionNode = (
  node: NodeT,
): node is DatabaseConnectionNode =>
  node.type === NODE_TYPE.SQL_DATABASE_CONNECTION_NODE;

export const isCustomConnectionNode = (
  node: NodeT,
): node is CustomConnectionNode =>
  node.type === NODE_TYPE.CUSTOM_CONNECTION_NODE;

export const isPreconditionError = (error: any) => {
  const response = (error as AxiosError<any>)?.response;
  return (
    response?.status === 412 && response?.data?.detail === "Precondition Failed"
  );
};

export const isConflictError = (error: any) => {
  const response = (error as AxiosError<any>)?.response;
  return response?.status === 409;
};

export const isBadRequest = (error: any) => {
  const response = (error as AxiosError<any>)?.response;
  return response?.status === 400;
};

export const isManualReviewConnectionNode = (
  node: NodeT,
): node is ManualReviewNode => node.type === NODE_TYPE.REVIEW_CONNECTION_NODE;

export const isManifestConnectionNode = (
  node: NodeT,
): node is ManifestConnectionNode =>
  node.type === NODE_TYPE.MANIFEST_CONNECTION_NODE;

export const isFlowNode = (node: NodeT): node is FlowNode =>
  node.type === NODE_TYPE.FLOW_NODE;

export const isLoopNode = (node: NodeT): node is LoopNode =>
  node.type === NODE_TYPE.LOOP_NODE;

export const isParentNode = (node: NodeT): node is ParentNode =>
  isFlowNode(node) || isLoopNode(node);

export const isIntegrationNodes = (node: NodeT): node is IntegrationNodes => {
  return (
    isIntegrationNode(node) ||
    isCustomConnectionNode(node) ||
    isInboundWebhookConnectionNode(node) ||
    isDatabaseConnectionNode(node) ||
    isManualReviewConnectionNode(node) ||
    isManifestConnectionNode(node)
  );
};

export const isDatasetMockableNode = (
  node: NodeT,
): node is DatasetMockableNodes => {
  return isFlowNode(node) || isIntegrationNodes(node) || isLoopNode(node);
};

export const isHistoricalRunState = (
  runState: NodeRunStateV2,
): runState is NodeHistoricalRunStateV2 => {
  return (
    runState.type === "historical-data" ||
    runState.type === "historical-error" ||
    runState.type === "historical-pending"
  );
};
