import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { BeMappedNode } from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";

export enum HashKeys {
  Edit = "#edit", // Edit modal is open for a resource
}

export enum URLKeys {
  LeftPane = "left-pane",
  Schema = "schema",
  RightPane = "right-pane",
  RightPaneTab = "right-pane-tab",
  EditDatasetId = "edit-dataset-id",
  SelectedNodeId = "node",
  DecisionId = "decision-id",
  FolderId = "folder-id",
  EntityId = "entity-id",
}

export enum DecisionHistoryKeys {
  JobId = "job-id",
  JobRunId = "job-run-id",
  DecisionHistoryView = "decision-view",
  DecisionId = "decision-id",
  From = "from",
  To = "to",
}

export enum LeftPaneOptions {
  Datasets = "datasets",
  Schema = "schema",
  DecisionHistory = "decision-history",
  Parameters = "parameters",
  Debug = "debug",
  Analytics = "analytics",
}

export enum SchemaOptions {
  Input = "input",
  Output = "output",
}

export enum NodeEditorOptions {
  Data = "data",
  Logic = "logic",
  Comments = "comments",
  Iterations = "iterations",
  ExecutedQuery = "executed-query",
  SqlResponse = "sql-response",
  Insights = "insights",
}

export const getCurrentLeftPane = (
  searchParams: URLSearchParams,
): LeftPaneOptions | null => {
  const currentSidePane = searchParams.get(URLKeys.LeftPane);
  if (
    currentSidePane &&
    (Object.values(LeftPaneOptions) as string[]).includes(currentSidePane)
  ) {
    return currentSidePane as LeftPaneOptions;
  }
  return null;
};

export const useToggleLeftSidePaneOption = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (option: LeftPaneOptions) => {
    const currentOpenPane = getCurrentLeftPane(searchParams);
    setSearchParams((previousSearchParams) => {
      if (currentOpenPane === option) {
        previousSearchParams.delete(URLKeys.LeftPane);
        // Always remove decision-id when closing left pane
        previousSearchParams.delete(URLKeys.DecisionId);
        return previousSearchParams;
      } else {
        previousSearchParams.set(URLKeys.LeftPane, option);
        return previousSearchParams;
      }
    });
  };
};

export const useOpenSchemaEditor = () => {
  const [_, setSearchParams] = useSearchParams();
  return useCallback(
    (erroredNode: BeMappedNode) => {
      setSearchParams((previousSearchParams) => {
        previousSearchParams.set(URLKeys.LeftPane, LeftPaneOptions.Schema);
        previousSearchParams.set(
          URLKeys.Schema,
          erroredNode.type === NODE_TYPE.INPUT_NODE
            ? SchemaOptions.Input
            : SchemaOptions.Output,
        );
        return previousSearchParams;
      });
    },
    [setSearchParams],
  );
};

export const useSetEditDatasetId = () => {
  const [_, setSearchParams] = useSearchParams();
  return useCallback(
    (newValue: string | null, openAddColumnMenu?: boolean) => {
      setSearchParams((searchParams) => {
        if (newValue) {
          searchParams.set(URLKeys.EditDatasetId, newValue);
          if (openAddColumnMenu) {
            searchParams.set("openAddColumnMenu", "true");
          }
        } else {
          searchParams.delete(URLKeys.EditDatasetId);
          searchParams.delete("openAddColumnMenu");
        }

        return searchParams;
      });
    },
    [setSearchParams],
  );
};

export const useFolderIdSearchParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setFolderId = useCallback(
    (newValue: string | undefined) => {
      setSearchParams((searchParams) => {
        if (newValue) {
          searchParams.set(URLKeys.FolderId, newValue);
        } else {
          searchParams.delete(URLKeys.FolderId);
        }

        return searchParams;
      });
    },
    [setSearchParams],
  );
  return [
    searchParams.get(URLKeys.FolderId) || undefined,
    setFolderId,
  ] as const;
};
