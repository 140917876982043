import { InfiniteData } from "@tanstack/react-query";

import {
  ChangeLogDb,
  ReviewCommentStatus,
  ReviewDb,
  ReviewStatus,
} from "src/clients/flow-api";

export const reviewItemEtag = "REVIEW_ITEM" as const;
export const buildInitialItem = (
  review: ReviewDb | null,
  suffix?: string,
): ChangeLogDb => ({
  created_at: review?.created_at ?? "",
  updated_at: review?.created_at ?? "",
  flow_version_id: "",
  etag: `${reviewItemEtag}${suffix}`,
  authored_by: "initialItem",
});

type PrepareChangeListArgs = {
  data: InfiniteData<ChangeLogDb[]>;
  review?: ReviewDb | null;
  displayDataUntilEtag?: string;
  isCancelledHistoryVisible: boolean;
};

export const prepareChangeList = ({
  data,
  review,
  displayDataUntilEtag,
  isCancelledHistoryVisible,
}: PrepareChangeListArgs): ChangeLogDb[] => {
  if (!data.pages || !review) {
    return [];
  }

  // filters the list until the last etag that needs to be displayed
  const list = data.pages.flat();
  const filteredList = list.filter(
    (change) =>
      displayDataUntilEtag !== undefined && change.etag > displayDataUntilEtag,
  );

  // pushes the review item as last since the order is reversed
  filteredList.push(buildInitialItem(review));

  const isCancelled = review.review_status === ReviewStatus.CANCELLED;
  const cancelledItemIndex = filteredList.findIndex(
    (change) =>
      change.comment?.reviewer_status === ReviewCommentStatus.CANCELLED,
  );

  if (!isCancelled && cancelledItemIndex > -1) {
    return filteredList.toSpliced(
      cancelledItemIndex,
      0,
      buildInitialItem(review, "_REOPEN"),
    );
  }

  if (isCancelled) {
    return isCancelledHistoryVisible ? filteredList : [filteredList[0]];
  }

  return filteredList;
};
