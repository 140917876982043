import { faQuestionCircle, faXmark } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { Icon } from "src/base-components/Icon";
import { DOCS_FUNDAMENTALS_DATASET_TESTING } from "src/constants/ExternalLinks";

type Props = {
  onClose: () => void;
};

export const PaneHeader: React.FC<Props> = ({ onClose }) => (
  <div>
    <div className="flex items-start justify-around px-6 pt-2">
      <div className="grow">
        <div className="text-gray-800 font-inter-semibold-16px">Test data</div>
        <div className="mt-1 text-gray-500 font-inter-normal-12px">
          Upload and manage data to test this Decision Flow
        </div>
      </div>
      <div className="mt-0.5 flex flex-row items-center gap-x-2">
        <a
          href={DOCS_FUNDAMENTALS_DATASET_TESTING}
          rel="noreferrer"
          target="_blank"
        >
          <Icon
            color="text-gray-500 hover:text-gray-700"
            icon={faQuestionCircle}
            size="xs"
          />
        </a>
        <div className="cursor-pointer">
          <Icon
            color="text-gray-500 hover:text-gray-700"
            dataLoc="close-datasets-side-pane"
            icon={faXmark}
            size="xs"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
    <div className="mb-5 mt-4 px-6">
      <div className="h-px w-full rounded-full bg-gray-100"></div>
    </div>
  </div>
);
