import { faBolt } from "@fortawesome/pro-regular-svg-icons";
import { Menu } from "@headlessui/react";
import { AnimatePresence, m } from "framer-motion";
import { useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

import { ExternalLink } from "src/base-components/ExternalLink";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { Job } from "src/jobs/types";
import {
  DashboardPageParamsT,
  getBaseUrl,
  getUrlToJobPage,
} from "src/router/urls";
import { pluralize } from "src/utils/stringUtils";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const JobsPill: React.FC<{
  jobs?: Job[];
}> = ({ jobs }) => {
  const { wsId, orgId } = useParamsDecode<DashboardPageParamsT>();
  const [isOpen, setOpen] = useState<boolean>(false);

  const [ref, setRef] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(ref, popperElement, {
    strategy: "absolute",
    modifiers: [{ name: "offset", options: { offset: [0, 8] } }],
    placement: "bottom-start",
  });

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  if (!jobs)
    return (
      <SkeletonPlaceholder
        dataLoc="jobs-pill-skeleton"
        height="h-5"
        width="w-4/5"
      />
    );

  if (jobs.length === 0) return null;

  return (
    <Menu
      as="div"
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Menu.Button
        ref={setRef}
        as="div"
        className="cursor-default"
        onClick={stopPropagation}
      >
        <Pill variant="outlined-white">
          <Pill.Icon icon={faBolt} />
          <Pill.Text>Used by {pluralize(jobs.length, "Job")}</Pill.Text>
        </Pill>
      </Menu.Button>
      {ReactDOM.createPortal(
        <AnimatePresence initial={false}>
          {isOpen && (
            <div
              ref={setPopperElement}
              className="z-50"
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    type: "tween",
                    ease: "easeOut",
                    duration: 0.15,
                  },
                }}
                as={m.ul}
                className="w-62 cursor-default rounded-lg bg-white py-2 text-gray-800 opacity-100 shadow-lg transition-opacity"
                exit={{
                  opacity: 0,
                  scale: 0.95,
                  transition: { delay: 0.3, duration: 0.08 },
                }}
                initial={{ opacity: 0, scale: 0.95 }}
                static
              >
                <li className="h-12 px-4 py-2.5 text-left font-inter-semibold-13px">
                  This Version is used by
                </li>
                {jobs.map((job) => (
                  <Menu.Item
                    key={job.id}
                    as="li"
                    className="cursor-pointer text-left hover:bg-gray-50"
                    onClick={stopPropagation}
                  >
                    <ExternalLink
                      className="flex items-center gap-x-2 px-4 py-2.5 text-gray-800 font-inter-normal-13px"
                      href={
                        getBaseUrl() +
                        getUrlToJobPage(orgId, wsId, job.flow_id, job.id)
                      }
                    >
                      <Icon color="text-gray-500" icon={faBolt} size="2xs" />
                      {job.name}
                    </ExternalLink>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </div>
          )}
        </AnimatePresence>,
        document.body,
      )}
    </Menu>
  );
};
