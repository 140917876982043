import {
  faEdit,
  faPlugCircleBolt,
  faPlus,
  faTrashAlt,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { Root } from "@radix-ui/react-accordion";
import { AxiosError } from "axios";
import { useState } from "react";
import { Controller, FieldError, FormProvider, useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";

import { isResourceAvailableInAwsRegion } from "../CreateConnectionGrid";
import { ConnectionsEndpoint } from "src/api/connectApi/endpoints";
import {
  useCreateConnectionAndResources,
  useEditConnectionAndResources,
} from "src/api/connectApi/queries";
import {
  ConnectionT,
  IntegrationProviderResourceMap,
  IntegrationProviderT,
  ResourceConfigT,
  ResourceTypesForIntegrationProvider,
  ConfigurationValueT,
  IntegrationProviders,
  LegacyIntegrationProviderT,
  ResourceT,
  RawRequestsProviders,
} from "src/api/connectApi/types";
import {
  taktileInternalPrefix,
  validateNotStartsWithInternalPrefix,
} from "src/api/constants";
import { WorkspaceWithSettings } from "src/api/types";
import { Button } from "src/base-components/Button";
import { EditorAccordionItem as AccordionItem } from "src/base-components/EditorAccordionItem";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Icon } from "src/base-components/Icon";
import { InformationPill } from "src/base-components/InformationPill";
import { Input } from "src/base-components/Input";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { Select } from "src/base-components/Select";
import { Switch } from "src/base-components/Switch";
import { Textarea } from "src/base-components/Textarea";
import {
  dismissToast,
  toastFailure,
  toastSuccess,
} from "src/base-components/Toast/utils";
import { Tooltip } from "src/base-components/Tooltip";
import { getProviderName } from "src/connections/ProviderResourceProperties";
import { getTestableStatus } from "src/connections/columns";
import {
  DataRetentionFields,
  DISABLED_RETENTION_VALUE,
} from "src/connections/config/DataRetentionFields";
import { BoniversumTemplate } from "src/connections/connection-templates/boniversum-template";
import { CodatTemplate } from "src/connections/connection-templates/codat-template";
import { CreditsafeTemplate } from "src/connections/connection-templates/creditsafe-template";
import { CRIFB2BTemplate } from "src/connections/connection-templates/crif-b2b-template";
import { CrifB2CTemplate } from "src/connections/connection-templates/crif-b2c-template";
import { CRIFHighMarkTemplate } from "src/connections/connection-templates/crif-high-mark-template";
import { DataXTemplate } from "src/connections/connection-templates/data-x-template";
import { DnbTemplate } from "src/connections/connection-templates/dnb-template";
import { EquifaxUKTemplate } from "src/connections/connection-templates/equifax-uk-template";
import { EquifaxUsCommercialRiskTemplate } from "src/connections/connection-templates/equifax-us-commercial-risk-template";
import { EquifaxUSTemplate } from "src/connections/connection-templates/equifax-us-template";
import { ExperianUSB2BTemplate } from "src/connections/connection-templates/experian-us-b2b-template";
import { ExperianUSClarityServicesTemplate } from "src/connections/connection-templates/experian-us-clarity-services-template";
import { ExperianUSTemplate } from "src/connections/connection-templates/experian-us-template";
import { SchufaTemplate } from "src/connections/connection-templates/schufa-template";
import {
  CONNECTIONS_MAP,
  providerInConfigMap,
} from "src/connections/connectionConfigs";
import { useConnectionManagementActions } from "src/connections/store/connectionManagementStore";
import {
  ConnectionResourceTemplate,
  ConnectionResourceTemplateConfigKey,
  ConnectionTemplate,
} from "src/connections/types";
import { Modal } from "src/design-system/Modal";
import {
  hasResourceCreationEnabled,
  isTemporarilyDisabledResource,
} from "src/router/featureFlags";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { assertUnreachable } from "src/utils/typeUtils";

type ResourceConfigNarrowedForProvider<
  IntegrationProvider extends IntegrationProviderT,
> = Omit<ResourceConfigT, "resource"> & {
  resource: ResourceTypesForIntegrationProvider<IntegrationProvider>;
};

export type IntegrationProviderConnection<
  IntegrationProvider extends LegacyIntegrationProviderT,
> = Omit<ConnectionT, "provider" | "resource_configs"> & {
  provider: IntegrationProvider;
} & {
  resource_configs: ResourceConfigNarrowedForProvider<IntegrationProvider>[];
};

type FormStatus<IntegrationProvider extends LegacyIntegrationProviderT> =
  | {
      type: "create";
      provider: IntegrationProvider;
    }
  | {
      type: "update";
      connection: IntegrationProviderConnection<IntegrationProvider>;
    };

type Props<IntegrationProvider extends LegacyIntegrationProviderT> = {
  onCancelClick: () => void;
  onClose: () => void;
  workspace: WorkspaceWithSettings;
} & FormStatus<IntegrationProvider>;

type FormType<IntegrationProvider extends LegacyIntegrationProviderT> = Pick<
  IntegrationProviderConnection<IntegrationProvider>,
  "resource_configs" | "data_retention"
> & {
  name: string;
  is_sandbox: boolean;
  secrets: { key: string; value: string; editable: boolean }[];
};

const DeprecatedResourceOption = ({ resource }: { resource: string }) => {
  return (
    <Tooltip
      placement="top"
      title="This resource is deprecated and will be removed soon"
      activated
      asChild
    >
      <span>{resource}</span>
    </Tooltip>
  );
};

const getProbeResources = (connection: ConnectionT) => {
  return connection.resource_configs.filter(
    (resourceConfig) => resourceConfig.resource === "probe",
  );
};
const getResourcesCreatableByUser = <
  IntegrationProvider extends LegacyIntegrationProviderT,
>(
  connection: IntegrationProviderConnection<IntegrationProvider>,
  AWSRegion: string | undefined,
) => {
  return connection.resource_configs.filter(
    (resourceConfig) =>
      // @ts-ignore
      resourceConfig.resource !== "probe" &&
      !isTemporarilyDisabledResource(
        connection.provider,
        resourceConfig.resource,
      ) &&
      isResourceAvailableInAwsRegion(
        connection.provider,
        resourceConfig.resource,
        AWSRegion,
      ),
  );
};

export const isLegacyIntegrationProvider = (
  provider: IntegrationProviderT | string,
): provider is LegacyIntegrationProviderT => {
  return IntegrationProviders.includes(provider as LegacyIntegrationProviderT);
};

const getTemplateForProvider = <
  IntegrationProvider extends IntegrationProviderT,
>(
  provider: IntegrationProvider,
): ConnectionTemplate<IntegrationProvider> => {
  // Union generics can't be narrowed: https://github.com/microsoft/TypeScript/issues/13995

  if (!isLegacyIntegrationProvider(provider)) {
    throw new Error("This is not a legacy provider");
  }

  if (providerInConfigMap(provider)) {
    return CONNECTIONS_MAP[provider]
      .template as ConnectionTemplate<IntegrationProvider>;
  }

  switch (provider) {
    case "boniversum":
      return BoniversumTemplate as ConnectionTemplate<IntegrationProvider>;
    case "codat":
      return CodatTemplate as ConnectionTemplate<IntegrationProvider>;
    case "schufa":
      return SchufaTemplate as ConnectionTemplate<IntegrationProvider>;
    case "data_x":
      return DataXTemplate as ConnectionTemplate<IntegrationProvider>;
    case "dnb":
      return DnbTemplate as ConnectionTemplate<IntegrationProvider>;
    case "crif_b2b":
      return CRIFB2BTemplate as ConnectionTemplate<IntegrationProvider>;
    case "crif_b2c":
      return CrifB2CTemplate as ConnectionTemplate<IntegrationProvider>;
    case "crif_high_mark":
      return CRIFHighMarkTemplate as ConnectionTemplate<IntegrationProvider>;
    case "equifax_uk":
      return EquifaxUKTemplate as ConnectionTemplate<IntegrationProvider>;
    case "equifax_us":
      return EquifaxUSTemplate as ConnectionTemplate<IntegrationProvider>;
    case "equifax_us_commercial_risk":
      return EquifaxUsCommercialRiskTemplate as ConnectionTemplate<IntegrationProvider>;
    case "experian_us_b2b":
      return ExperianUSB2BTemplate as ConnectionTemplate<IntegrationProvider>;
    case "experian_us":
      return ExperianUSTemplate as ConnectionTemplate<IntegrationProvider>;
    case "experian_us_clarity_services":
      return ExperianUSClarityServicesTemplate as ConnectionTemplate<IntegrationProvider>;
    case "creditsafe":
      return CreditsafeTemplate as ConnectionTemplate<IntegrationProvider>;
    default:
      return assertUnreachable(provider);
  }
};

const getConnectionNameForProvider = (provider: LegacyIntegrationProviderT) => {
  return `${getProviderName(provider)} connection`;
};

const getFormSecretsFromConnectionAndTemplate = <
  IntegrationProvider extends LegacyIntegrationProviderT,
>(
  connection: IntegrationProviderConnection<IntegrationProvider>,
  template: ConnectionTemplate<IntegrationProvider>,
) => {
  return template.secrets.map((secret) => {
    const secretFoundInConnection = connection.secrets.find(
      (connectionSecret) => secret.key === connectionSecret.key,
    );
    const secretFoundInConnectionValue = secretFoundInConnection?.value ?? "";
    return {
      key: secret.key,
      value: secretFoundInConnectionValue,
      editable: !secretFoundInConnectionValue,
    };
  });
};

export const ProviderConfigForm = <
  IntegrationProvider extends LegacyIntegrationProviderT,
>(
  props: Props<IntegrationProvider>,
) => {
  const { workspace, onCancelClick, onClose, ...initialFormStatus } = props;
  const [formStatus] =
    useState<FormStatus<IntegrationProvider>>(initialFormStatus);
  const { setConnectionToEdit, setModalOpen } =
    useConnectionManagementActions();
  const AWSRegion = workspace.aws_region;
  const provider =
    props.type === "create" ? props.provider : props.connection.provider;
  if (!isLegacyIntegrationProvider(provider)) {
    throw new Error("This is not a legacy provider");
  }

  const template = getTemplateForProvider(provider);

  const createResource = (
    resourceType: ResourceTypesForIntegrationProvider<IntegrationProvider>,
  ): ResourceConfigNarrowedForProvider<IntegrationProvider> => {
    const resourceTemplate = template.resources[resourceType];
    const configuration: ResourceConfigT["configuration"] = {};

    for (const configKey of resourceTemplate.configKeys) {
      let defaultValue: (typeof configuration)[number] = "";
      switch (configKey.type) {
        case "simple_selection":
          defaultValue = configKey.elements[0].key;
          break;
        case "multiple_selection":
          defaultValue = [];
          break;
        case "text":
          defaultValue = "";
          break;
        case "switch":
          defaultValue = false;
          break;
        case "number":
          defaultValue = null;
          break;
        case "inline_number":
          defaultValue = null;
          break;
        default:
          assertUnreachable(configKey);
      }

      // We parse the flat `parent.child` keys
      // and store values in the nested `{ parent: { child: value } }`
      // structure
      let keyConfig = configuration;
      let [currentKey, ...remainingPath] = configKey.key.split(".");

      while (remainingPath.length > 0) {
        if (!(currentKey in keyConfig)) {
          keyConfig[currentKey] = {};
        }
        keyConfig = keyConfig[currentKey] as {
          [key: string]: ConfigurationValueT;
        };
        [currentKey, ...remainingPath] = remainingPath;
      }

      keyConfig[currentKey] = defaultValue;
    }

    return {
      id: uuid(),
      resource: resourceType,
      configuration,
      has_raw_response_enabled: false,
      has_raw_requests_enabled_in_resource: false,
      name: resourceTemplate.name,
      created_at: "",
      updated_at: "",
    };
  };

  const defaultResource = IntegrationProviderResourceMap[
    provider
  ][0] as ResourceTypesForIntegrationProvider<IntegrationProvider>;

  const formMethods = useForm<FormType<IntegrationProvider>>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      name:
        props.type === "update"
          ? props.connection.name
          : getConnectionNameForProvider(provider),
      is_sandbox: props.type === "update" ? props.connection.is_sandbox : false,
      secrets:
        props.type === "update"
          ? getFormSecretsFromConnectionAndTemplate(props.connection, template)
          : template.secrets.map((secret) => ({
              key: secret.key,
              value: "",
              editable: true,
            })),
      resource_configs:
        props.type === "update"
          ? getResourcesCreatableByUser(props.connection, AWSRegion)
          : [createResource(defaultResource)],
      data_retention:
        props.type === "update"
          ? (props.connection.data_retention ?? DISABLED_RETENTION_VALUE)
          : DISABLED_RETENTION_VALUE,
    },
  });

  const {
    register: formRegister,
    handleSubmit: handleFormSubmit,
    formState: { errors: formErrors, isSubmitting: formIsSubmitting },
    watch,
    register,
    setValue: setFormValue,
    setFocus: setFormFocus,
    control: formControl,
  } = formMethods;

  const formValue = watch();

  const makeSecretEditable = (secretIndex: number) => {
    setFormValue(`secrets.${secretIndex}.editable`, true);
    setFormValue(`secrets.${secretIndex}.value`, "");
    setTimeout(
      () =>
        setFormFocus(`secrets.${secretIndex}.value`, { shouldSelect: true }),
      0,
    );
  };

  const handleResourceRemoval = (resourceIdToRemove: string) => {
    setFormValue(
      "resource_configs",
      formValue.resource_configs.filter(
        (resource) => resource.id !== resourceIdToRemove,
      ),
    );
  };

  const handleAddResource = () => {
    const newResource = createResource(
      defaultResource as ResourceTypesForIntegrationProvider<IntegrationProvider>,
    );
    setFormValue("resource_configs", [
      ...formValue.resource_configs,
      newResource,
    ]);
  };

  const createConnectionMutation = useCreateConnectionAndResources(
    workspace.base_url!,
  );
  const updateConnectionMutation = useEditConnectionAndResources(
    workspace.base_url!,
  );

  const onSubmit = async (submittedValue: FormType<IntegrationProvider>) => {
    const secrets = submittedValue.secrets.filter((secret) => secret.editable);
    let upsertedConnection:
      | IntegrationProviderConnection<IntegrationProvider>
      | undefined;

    const connectionConfiguration = {};

    try {
      if (formStatus.type === "create") {
        upsertedConnection = (await createConnectionMutation.mutateAsync({
          name: submittedValue.name,
          provider,
          resource_configs: submittedValue.resource_configs,
          secrets,
          is_sandbox: submittedValue.is_sandbox,
          configuration: connectionConfiguration,
          data_retention: submittedValue.data_retention,
        })) as IntegrationProviderConnection<IntegrationProvider>;
      } else if (formStatus.type === "update") {
        const probeResources = getProbeResources(formStatus.connection);
        upsertedConnection = (await updateConnectionMutation.mutateAsync({
          id: formStatus.connection.id,
          payload: {
            name: submittedValue.name,
            is_sandbox: submittedValue.is_sandbox,
            configuration: connectionConfiguration,
            provider,
            resource_configs: [
              ...submittedValue.resource_configs,
              ...probeResources,
            ],
            secrets,
            data_retention: submittedValue.data_retention,
          },
        })) as IntegrationProviderConnection<IntegrationProvider>;
      }

      if (upsertedConnection) {
        const action = formStatus.type === "create" ? "created" : "updated";
        const toastId = toastSuccess({
          title: `Connection ${action}`,
          description: `Connection ${submittedValue.name} has been ${action}`,
          withSidebar: true,
          actionText: "View connection",
          onActionClick: () => {
            setConnectionToEdit(upsertedConnection);
            setModalOpen(true);
            dismissToast(toastId);
          },
        });

        onClose();
      }
    } catch (err) {
      // Default error message
      let description = "We couldn't save your connection";

      // Show error message from backend for Plaid connections
      if (
        provider === "plaid" &&
        err instanceof AxiosError &&
        err.response &&
        err.response.data["detail"]
      ) {
        if (typeof err.response.data["detail"] == "string") {
          description = err.response.data["detail"];
        }
        if (Array.isArray(err.response.data["detail"])) {
          description = err.response.data["detail"][0]["msg"];
        }
      }

      toastFailure({
        title: "Error",
        description: `${description}`,
      });
    }
  };

  const transformResource = (
    currentResource: ResourceConfigNarrowedForProvider<IntegrationProvider>,
    newType: ResourceTypesForIntegrationProvider<IntegrationProvider>,
  ): ResourceConfigNarrowedForProvider<IntegrationProvider> => {
    const newResource = createResource(newType);
    const currentResourceDefaultName =
      template.resources[currentResource.resource].name;
    const userHasChangedDefaultName =
      currentResource.name !== currentResourceDefaultName;
    newResource.name = userHasChangedDefaultName
      ? currentResource.name
      : template.resources[newType].name;
    newResource.id = currentResource.id;

    return newResource;
  };

  const onResourceTypeSelect = (
    currentResourceConfig: ResourceConfigNarrowedForProvider<IntegrationProvider>,
    newType: ResourceTypesForIntegrationProvider<IntegrationProvider>,
  ) => {
    const newResourceConfig = transformResource(currentResourceConfig, newType);

    const newResourceConfigArray = [...formValue.resource_configs];

    const oldResourceIndex = newResourceConfigArray.findIndex(
      (element) => element.id === currentResourceConfig.id,
    );

    newResourceConfigArray[oldResourceIndex] = newResourceConfig;

    setFormValue("resource_configs", newResourceConfigArray);
  };

  const [connectionTestingStatus, setConnectionTestingStatus] = useState<
    | { type: "ready" }
    | { type: "loading" }
    | { type: "successful" }
    | { type: "error"; details: { statusCode: string } }
  >({ type: "ready" });

  const testConnection = async () => {
    if (formStatus.type === "create") return;
    setConnectionTestingStatus({ type: "loading" });
    try {
      await ConnectionsEndpoint.test(
        workspace.base_url,
        formStatus.connection.id,
      );
      setConnectionTestingStatus({ type: "successful" });
    } catch (err) {
      if (err instanceof AxiosError) {
        setConnectionTestingStatus({
          type: "error",
          details: { statusCode: err.response?.status.toString() ?? "Unknown" },
        });
      } else {
        setConnectionTestingStatus({
          type: "error",
          details: { statusCode: "Unknown" },
        });
      }
    }
  };

  const groupByParent = (configKeys: ConnectionResourceTemplateConfigKey[]) => {
    const groups: Record<string, ConnectionResourceTemplateConfigKey[]> = {
      noParent: [],
    };
    configKeys.forEach((configKey) => {
      if (configKey.parent) {
        const parentKey: string = configKey.parent.key;
        if (!groups[parentKey]) {
          groups[parentKey] = [];
        }
        groups[parentKey].push(configKey);
      } else {
        groups.noParent.push(configKey);
      }
    });
    return groups;
  };

  const renderConfigItems = (
    configKeys: ConnectionResourceTemplateConfigKey[],
    i: number,
    resourceConfig: ResourceConfigNarrowedForProvider<IntegrationProvider>,
    resourceConfigurationErrors?: Record<string, FieldError | undefined>,
  ) =>
    configKeys.map((configurationKey) => {
      const configurationTemplate = template.resources[
        resourceConfig.resource
      ].configKeys.find((configKey) => configKey.key === configurationKey.key);
      if (!configurationTemplate) return null;

      const isHidden = configurationTemplate.isHidden?.(resourceConfig);
      if (isHidden) return null;

      const configurationKeyFormKey =
        `resource_configs.${i}.configuration.${configurationKey.key}` as const;
      const configurationKeyError =
        resourceConfigurationErrors?.[configurationKey.key];

      switch (configurationTemplate.type) {
        case "simple_selection":
          return (
            <FormItem
              key={configurationKeyFormKey}
              description={configurationTemplate.hint}
              isRequired={configurationTemplate.required}
              label={configurationTemplate.name}
            >
              {configurationKeyError && (
                <ErrorHint>{configurationKeyError.message}</ErrorHint>
              )}
              <Controller
                control={formControl}
                name={configurationKeyFormKey}
                render={({ field: { value, onChange } }) => (
                  <Select
                    dataLoc={`${configurationKeyFormKey}-select`}
                    options={
                      configurationTemplate.elements as {
                        key: string;
                        value: string;
                      }[]
                    }
                    placeholder={`Select ${configurationTemplate.name}`}
                    placement="bottom"
                    value={value as string}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required:
                    configurationTemplate.required &&
                    `${configurationTemplate.name} is required`,
                }}
              />
            </FormItem>
          );

        case "multiple_selection":
          return (
            <FormItem
              key={configurationKeyFormKey}
              description={configurationTemplate.hint}
              isRequired={configurationTemplate.required}
              label={configurationTemplate.name}
            >
              {configurationKeyError && (
                <ErrorHint>
                  {configurationKeyError?.type === "required"
                    ? `${configurationTemplate.name} is required`
                    : configurationKeyError?.type === "minItems"
                      ? `${configurationTemplate.name} must have at least ${configurationTemplate.minItems} items selected`
                      : configurationKeyError?.type === "maxItems"
                        ? `${configurationTemplate.name} can have up to ${configurationTemplate.maxItems} items selected`
                        : null}
                </ErrorHint>
              )}
              <Controller
                control={formControl}
                name={configurationKeyFormKey}
                render={({ field: { value, onChange } }) => (
                  <Select
                    dataLoc={`${configurationKeyFormKey}-select`}
                    options={
                      configurationTemplate.elements as {
                        key: string;
                        value: string;
                      }[]
                    }
                    placeholder={`Select ${configurationTemplate.name}`}
                    placement="bottom"
                    value={value as string[]}
                    multiple
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: configurationTemplate.required,
                  validate: {
                    minItems: (v) =>
                      configurationTemplate.minItems !== undefined
                        ? Array.isArray(v) &&
                          v.length >= configurationTemplate.minItems
                        : true,
                    maxItems: (v) =>
                      configurationTemplate.maxItems !== undefined
                        ? Array.isArray(v) &&
                          v.length <= configurationTemplate.maxItems
                        : true,
                  },
                }}
              />
            </FormItem>
          );

        case "text":
          return (
            <FormItem
              key={configurationKeyFormKey}
              description={configurationTemplate.hint}
              isRequired={configurationTemplate.required}
              label={configurationTemplate.name}
            >
              {configurationKeyError && (
                <ErrorHint>{configurationKeyError.message}</ErrorHint>
              )}
              <div className="flex w-full">
                <div className="flex-1">
                  <Input
                    data-loc={`${configurationKeyFormKey}-input`}
                    errored={!!configurationKeyError}
                    placeholder={
                      configurationTemplate.placeholder ||
                      `Enter ${configurationTemplate.name.toLowerCase()}`
                    }
                    suffixIcon={
                      !!configurationKeyError ? { icon: faWarning } : undefined
                    }
                    fullWidth
                    {...formRegister(configurationKeyFormKey, {
                      required:
                        configurationTemplate.required &&
                        `${configurationTemplate.name} is required`,
                    })}
                  />
                </div>
              </div>
            </FormItem>
          );

        case "number":
          return (
            <FormItem
              key={configurationKeyFormKey}
              description={configurationTemplate.hint}
              isRequired={configurationTemplate.required}
              label={configurationTemplate.name}
            >
              {configurationKeyError && (
                <ErrorHint>{configurationKeyError.message}</ErrorHint>
              )}
              <div className="flex w-full">
                <div className="flex-1">
                  <Input
                    data-loc={`${configurationKeyFormKey}-input`}
                    errored={!!configurationKeyError}
                    suffixIcon={
                      !!configurationKeyError ? { icon: faWarning } : undefined
                    }
                    type="number"
                    fullWidth
                    {...formRegister(configurationKeyFormKey, {
                      valueAsNumber: true,
                      required:
                        configurationTemplate.required &&
                        `${configurationTemplate.name} is required`,
                    })}
                  />
                </div>
              </div>
            </FormItem>
          );
        case "inline_number":
          return (
            <FormItem
              key={configurationKeyFormKey}
              className="flex flex-row justify-between"
              description={configurationTemplate.hint}
              isRequired={configurationTemplate.required}
              label={configurationTemplate.name}
            >
              <div>
                {configurationKeyError && (
                  <ErrorHint>{configurationKeyError.message}</ErrorHint>
                )}
              </div>
              <div className="ml-12">
                <Input
                  data-loc={`${configurationKeyFormKey}-input`}
                  errored={!!configurationKeyError}
                  placeholder={
                    configurationTemplate.placeholder ||
                    `Enter ${configurationTemplate.name.toLowerCase()}`
                  }
                  suffixIcon={
                    !!configurationKeyError ? { icon: faWarning } : undefined
                  }
                  type="number"
                  fullWidth
                  {...formRegister(configurationKeyFormKey, {
                    valueAsNumber: true,
                    required:
                      configurationTemplate.required &&
                      `${configurationTemplate.name} is required`,
                  })}
                />
              </div>
            </FormItem>
          );

        case "switch":
          return (
            <FormItem
              key={configurationKeyFormKey}
              className="flex items-center justify-between"
              description={configurationTemplate.hint}
              isRequired={configurationTemplate.required}
              label={configurationTemplate.name}
            >
              {configurationKeyError && (
                <ErrorHint>{configurationKeyError.message}</ErrorHint>
              )}
              <Controller
                control={formControl}
                name={configurationKeyFormKey}
                render={({ field: { value, onChange } }) => (
                  <div className="ml-1">
                    <Switch
                      dataLoc={`${configurationKeyFormKey}-switch`}
                      enabled={value as boolean}
                      onChange={onChange}
                    />
                  </div>
                )}
                rules={{
                  validate: (value) => {
                    if (configurationTemplate.required && value == null) {
                      return `${configurationTemplate.name} is required`;
                    }
                    return true;
                  },
                }}
              />
            </FormItem>
          );

        default:
          assertUnreachable(configurationTemplate);
          return null;
      }
    });
  const connectionNameErrors = formErrors.name;
  const isSandboxErrors = formErrors.is_sandbox;
  const isPowerToolsEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.powertools);

  interface EditSecretTooltipProps {
    alignment: "start" | "center";
    secretName: string;
    index: number;
  }

  const EditSecretTooltip: React.FC<EditSecretTooltipProps> = ({
    alignment,
    secretName,
    index,
  }) => (
    <Tooltip
      align={alignment}
      placement="right"
      title={`Edit ${secretName.toLowerCase()}`}
      asChild
    >
      <div
        className={`${alignment === "start" ? "items-start" : ""} ml-2 flex`}
      >
        <Icon
          color="text-gray-500"
          icon={faEdit}
          size="xs"
          onClick={() => makeSecretEditable(index)}
        />
      </div>
    </Tooltip>
  );

  return (
    <FormProvider {...formMethods}>
      <form
        data-loc={`provider-config-form-${props.type}`}
        onSubmit={handleFormSubmit(onSubmit)}
      >
        <Modal.Content>
          {connectionTestingStatus.type === "successful" && (
            <InformationPill className="mx-10 mb-8" type="success">
              Connection test successful
            </InformationPill>
          )}
          {connectionTestingStatus.type === "error" && (
            <InformationPill className="mx-10 mb-8" type="error">
              Connection test failed (status code:{" "}
              {connectionTestingStatus.details.statusCode})
            </InformationPill>
          )}

          {isPowerToolsEnabled && (
            <FormItem label="Connection name" isRequired>
              {connectionNameErrors && (
                <ErrorHint>{connectionNameErrors.message}</ErrorHint>
              )}
              <Input
                errored={!!connectionNameErrors}
                fullWidth
                {...formRegister(`name`, {
                  required: "Connection name is required",
                  validate: validateNotStartsWithInternalPrefix(
                    `Connection name cannot start with ${taktileInternalPrefix}`,
                  ),
                })}
                suffixIcon={
                  !!connectionNameErrors ? { icon: faWarning } : undefined
                }
              />
            </FormItem>
          )}
          {isPowerToolsEnabled && (
            <FormItem
              className="flex items-center justify-between"
              label="Is sandbox connection"
            >
              {isSandboxErrors && (
                <ErrorHint>{isSandboxErrors.message}</ErrorHint>
              )}
              <Controller
                control={formControl}
                name="is_sandbox"
                render={({ field: { value, onChange } }) => (
                  <div className="ml-1">
                    <Switch enabled={value as boolean} onChange={onChange} />
                  </div>
                )}
              />
            </FormItem>
          )}
          {formValue.secrets.map((secret, i) => {
            const secretFormKey = `secrets.${i}.value` as const;
            const error = formErrors.secrets?.[i]?.value;

            const secretTemplate = template.secrets.find(
              (s) => s.key === secret.key,
            )!;
            const secretName = secretTemplate.name;
            const type = secretTemplate.type;
            const commonSecretInputProps = {
              disabled: !secret.editable,
              errored: !!error,
              placeholder: `Enter ${secretName}`,
            };

            return (
              <FormItem
                key={secret.key}
                description={secretTemplate?.hint}
                isRequired={secretTemplate.required}
                label={secretName}
              >
                {error && <ErrorHint>{error.message}</ErrorHint>}
                <div className="flex w-full">
                  <div className="flex-1">
                    {type === "multiline" ? (
                      !commonSecretInputProps.disabled ? (
                        <Textarea
                          {...commonSecretInputProps}
                          {...register(
                            `${secretFormKey}`,
                            secretTemplate.required
                              ? {
                                  required: `${secretName} is required`,
                                }
                              : {},
                          )}
                          data-loc={`provider-secret-${secret.key}`}
                          resizeable
                        />
                      ) : (
                        <Input
                          data-loc={`provider-secret-${secret.key}`}
                          defaultValue="**********"
                          type="password"
                          disabled
                          fullWidth
                        />
                      )
                    ) : (
                      <Input
                        {...commonSecretInputProps}
                        data-loc={`provider-secret-${secret.key}`}
                        fullWidth
                        {...formRegister(
                          secretFormKey,
                          secretTemplate.required
                            ? {
                                required: `${secretName} is required`,
                              }
                            : {},
                        )}
                        suffixIcon={
                          commonSecretInputProps.errored
                            ? { icon: faWarning }
                            : undefined
                        }
                        type="password"
                      />
                    )}
                  </div>
                  {!secret.editable && (
                    <EditSecretTooltip
                      alignment="center"
                      index={i}
                      secretName={secretName}
                    />
                  )}
                </div>
              </FormItem>
            );
          })}
          {formValue.resource_configs.map((resourceConfig, i) => {
            const resourceConfigTemplate =
              template.resources[resourceConfig.resource];
            if (!resourceConfigTemplate) return null;

            const resourceNameErrors = formErrors.resource_configs?.[i]?.name;
            const resourceConfigurationErrors = formErrors.resource_configs?.[i]
              ?.configuration as
              | Record<string, FieldError | undefined>
              | undefined;

            const resourceOptions = Object.entries<ConnectionResourceTemplate>(
              template.resources,
            )
              .filter(
                ([key]) =>
                  hasResourceCreationEnabled(provider, key) &&
                  isResourceAvailableInAwsRegion(
                    provider,
                    key as ResourceT,
                    AWSRegion,
                  ),
              )
              .map(([key, resource]) => ({
                key,
                value: resource.deprecated ? (
                  <DeprecatedResourceOption resource={resource.name} />
                ) : (
                  resource.name
                ),
                disabled: resource.deprecated,
              }));
            const configGroups = groupByParent(
              resourceConfigTemplate.configKeys,
            );

            const accordionTitle = (
              configKey: ConnectionResourceTemplateConfigKey,
            ) => {
              const required = configKey.parent?.required;
              return (
                <div className="flex flex-col items-start">
                  <div className="flex">
                    <span>
                      {configKey.parent?.title}{" "}
                      {required && <RequiredAsterisk />}
                    </span>
                  </div>
                  {configKey.parent?.subtitle && (
                    <span className="text-xs text-gray-500 font-inter-normal-12px">
                      {configKey.parent.subtitle}
                    </span>
                  )}
                </div>
              );
            };

            return (
              <div
                key={resourceConfig.id}
                className="mb-4 rounded-lg bg-gray-50 px-4 pb-9 pt-4"
              >
                <div className="flex items-center justify-between">
                  <h3 className="text-xs uppercase text-gray-600">
                    Resource {i + 1}
                  </h3>
                  <Tooltip
                    activated={formValue.resource_configs.length === 1}
                    data_loc="resource-delete-button-tooltip"
                    placement="top"
                    title="At least one resource is required"
                    asChild
                  >
                    <div>
                      <Button
                        dataLoc="resource-delete-button"
                        disabled={formValue.resource_configs.length === 1}
                        iconLeft={faTrashAlt}
                        size="sm"
                        variant="secondary"
                        onClick={() => handleResourceRemoval(resourceConfig.id)}
                      >
                        Delete resource
                      </Button>
                    </div>
                  </Tooltip>
                </div>
                <FormItem className="mt-4" label="Type">
                  <Select
                    options={resourceOptions}
                    placement="bottom"
                    value={resourceConfig.resource}
                    onChange={(newType) =>
                      onResourceTypeSelect(
                        resourceConfig,
                        newType as ResourceTypesForIntegrationProvider<IntegrationProvider>,
                      )
                    }
                  />
                </FormItem>
                <FormItem
                  description="A name that describes the resource"
                  label="Resource name"
                  isRequired
                >
                  {resourceNameErrors && (
                    <ErrorHint>{resourceNameErrors.message}</ErrorHint>
                  )}
                  <Input
                    errored={!!resourceNameErrors}
                    fullWidth
                    {...formRegister(`resource_configs.${i}.name`, {
                      required: "Resource name is required",
                    })}
                    suffixIcon={
                      !!resourceNameErrors ? { icon: faWarning } : undefined
                    }
                  />
                </FormItem>
                {renderConfigItems(
                  configGroups.noParent,
                  i,
                  resourceConfig,
                  resourceConfigurationErrors,
                )}
                <Root className="ml-0 divide-y divide-gray-200" type="multiple">
                  {Object.entries(configGroups)
                    .filter(([parentKey]) => parentKey !== "noParent")
                    .map(([parentKey, configKeys]) => (
                      <AccordionItem
                        className="items-center last:mb-4"
                        headerClassName="items-center my-4 "
                        title={accordionTitle(configKeys[0])}
                        value={parentKey}
                      >
                        {renderConfigItems(
                          configKeys,
                          i,
                          resourceConfig,
                          resourceConfigurationErrors,
                        )}
                      </AccordionItem>
                    ))}
                </Root>
                <div className="flex items-center justify-between">
                  <div className="flex flex-col">
                    <h3 className="inline-block text-gray-800 font-inter-semibold-13px">
                      Include raw provider responses
                      <FormItem.Help body="With this enabled, the untouched API response from this resource will be returned alongside published Flows' output. The API response will only be included on Flow Runs that successfully fetch an API response, and not when using a cached response. Taktile never stores API responses." />
                    </h3>
                    <span className="mt-1 text-xs text-gray-500 font-inter-normal-12px">
                      Include the raw API response for this resource in
                      published Flows' API responses. Note that this may expose
                      sensitive data in the API response.
                    </span>
                  </div>
                  <div className="ml-1">
                    <Controller
                      control={formControl}
                      name={`resource_configs.${i}.has_raw_response_enabled`}
                      render={({ field: { value, onChange } }) => (
                        <Switch
                          enabled={value as boolean}
                          onChange={() => onChange(!value)}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="mt-4 flex items-center justify-between">
                  <div className="flex flex-col">
                    <h3 className="inline-block text-gray-800 font-inter-semibold-13px">
                      Include raw requests made to the provider API
                      <FormItem.Help body="With this enabled, the raw HTTP request to the provider will be returned with the Taktile API response alongside published Flows' output. The request will not be included when using a cached response. Taktile never stores raw requests." />
                    </h3>
                    <span className="mt-1 text-xs text-gray-500 font-inter-normal-12px">
                      Include the raw request sent to the provider in the
                      published Flows' API responses. Note that this may expose
                      sensitive data in the API response.
                    </span>
                  </div>
                  <div className="ml-2">
                    <Controller
                      control={formControl}
                      name={`resource_configs.${i}.has_raw_requests_enabled_in_resource`}
                      render={({ field: { value, onChange } }) =>
                        !RawRequestsProviders.includes(provider) ? (
                          <Tooltip
                            align="center"
                            placement="top"
                            title="This provider does not have raw requests available"
                            triggerAs="div"
                          >
                            <Switch
                              disabled={true}
                              enabled={false}
                              onChange={() => {}}
                            />
                          </Tooltip>
                        ) : (
                          <Switch
                            enabled={value as boolean}
                            onChange={() => onChange(!value)}
                          />
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <div>
            <Button
              iconLeft={faPlus}
              size="sm"
              variant="secondary"
              onClick={handleAddResource}
            >
              Add resource
            </Button>
          </div>
          <hr className="my-8 h-px w-full bg-gray-100" />
          <DataRetentionFields
            fieldName="data_retention"
            workspace={workspace}
          />
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              htmlType="submit"
              loading={formIsSubmitting}
              size="base"
              variant="primary"
            >
              Save
            </Button>
          }
          secondaryButton={
            <Button
              disabled={formIsSubmitting}
              size="base"
              variant="secondary"
              onClick={onCancelClick}
            >
              Cancel
            </Button>
          }
        >
          {formStatus.type === "update" ? (
            <Button
              disabled={getTestableStatus(formStatus.connection) !== "testable"}
              iconLeft={faPlugCircleBolt}
              loading={connectionTestingStatus.type === "loading"}
              size="base"
              variant="secondary"
              onClick={testConnection}
            >
              Test connection
            </Button>
          ) : null}
        </Modal.Footer>
      </form>
    </FormProvider>
  );
};
