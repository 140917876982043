import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { StringEditor } from "src/base-components/EditorTable/StringEditor";
import { EditorCellProps } from "src/base-components/EditorTable/types";
import { Icon } from "src/base-components/Icon";
import { FactorCodeInput } from "src/scorecardNode/FactorCodeInput";
import { useFactorContext } from "src/scorecardNode/context";
import { FormShape } from "src/scorecardNode/types";

type Props<T> = EditorCellProps<T, { id: string; value: string }> & {
  alignCenter?: boolean;
  removable?: boolean;
  prefix: "input_field" | "effect";
};

export const CellStringEditor = <T,>({
  prefix,
  context,
  state,
  alignCenter = true,
  removable = true,
  ...props
}: Props<T>) => {
  const { watch, setValue } = useFormContext<FormShape>();
  const {
    canRemoveCase,
    fallbackId,
    onRemoveCase,
    index: factorIndex,
  } = useFactorContext();
  const caseIndex = (() => {
    if (!("row" in context)) {
      return 0;
    }
    const index = context.row.index;
    return context.row.parentId ? index + 1 : index;
  })();

  const fieldName = (() => {
    if ("row" in context && context.row.id === fallbackId) {
      return `factors.${factorIndex}.fallback.value` as const;
    }

    return prefix === "input_field"
      ? (`factors.${factorIndex}.input_field.value` as const)
      : (`factors.${factorIndex}.cases.${caseIndex}.effect.value` as const);
  })();

  const value = watch(fieldName);

  const handleStringChange = useCallback(
    (updatedStringValue: string) => {
      setValue(fieldName, updatedStringValue);
    },
    [setValue, fieldName],
  );

  return (
    <div className="flex w-full items-center">
      {prefix === "input_field" ? (
        <FactorCodeInput
          placeholder={props.columnDef.cell.placeholder}
          state={state}
          value={value}
          onChange={handleStringChange}
        />
      ) : (
        <StringEditor
          {...props}
          alignCenter={alignCenter}
          placeholder={props.columnDef.cell.placeholder}
          state={state}
          value={value}
          onChange={handleStringChange}
        />
      )}

      {removable &&
        canRemoveCase &&
        "row" in context &&
        context.row.id !== fallbackId && (
          <span
            className={twMerge(
              "absolute right-[-15px] z-50 cursor-pointer",
              "rounded border border-gray-300 bg-white shadow-sm",
              "opacity-0 transition-opacity duration-300 hover:opacity-100",
              state.isRowHovered && "opacity-100",
            )}
          >
            <Icon
              color="text-gray-600"
              icon={faTrashAlt}
              size="xs"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onRemoveCase(context.row.id);
              }}
            />
          </span>
        )}
    </div>
  );
};
