import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  taktileInternalPrefix,
  validateNotStartsWithInternalPrefix,
} from "src/api/constants";
import { KeyTypeSelector } from "src/apiKeys/KeyTypeSelector";
import { useCreateWorkspaceApiKey } from "src/apiKeys/api/queries";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Input } from "src/base-components/Input";
import { Modal } from "src/base-components/Modal";
import {
  ApiKeyType,
  WorkspaceServiceAccountWithKey,
} from "src/clients/taktile-api";
import * as logger from "src/utils/logger";

type ApiKeyInputs = {
  name: string;
  keyTypes: ApiKeyType[];
};

type PropsT = {
  open: boolean;
  organizationId: string;
  workspaceId: string;
  onClose: () => void;
  onApiKey: (apiKey: WorkspaceServiceAccountWithKey) => void;
};

export const CreateApiKeyModal: React.FC<PropsT> = ({
  open,
  organizationId: orgId,
  workspaceId: wsId,
  onClose,
  onApiKey,
}) => {
  const createWorkspaceApiKey = useCreateWorkspaceApiKey(orgId, wsId);

  // This state disables the confirm button before the form is first changed.
  // Afterwards the form submit behaviour handles the button disabling.
  const [initialSubmitDisabled, setInitialSubmitDisabled] = useState(true);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<ApiKeyInputs>({
    mode: "onChange",
    defaultValues: { name: "", keyTypes: [ApiKeyType.SANDBOX] },
  });

  // When the modal is opened, reset it and disable the submit button
  useEffect(() => {
    if (open) {
      reset();
      setInitialSubmitDisabled(true);
    }
  }, [open, reset]);

  const submitApiKey = handleSubmit(async (input: ApiKeyInputs) => {
    if (createWorkspaceApiKey.isLoading) {
      return;
    }

    try {
      const createdKey = (
        await createWorkspaceApiKey.mutateAsync({
          key_types: input.keyTypes,
          name: input.name,
        })
      ).data;
      onApiKey(createdKey);
    } catch (error) {
      logger.error(`Error when creating ApiKey: ${error}`);
    }
    onClose();
  });
  return (
    <Modal
      className="w-120"
      open={open}
      title="Create API key"
      closeIcon
      onClose={onClose}
    >
      <form className="flex flex-col gap-y-5 px-6 pb-6" onSubmit={submitApiKey}>
        <div className="flex flex-col gap-2 pt-4">
          <label className="text-sm text-gray-600">Key name</label>
          {Boolean(errors.name?.message) && (
            <ErrorHint>{errors.name?.message}</ErrorHint>
          )}
          <Input
            placeholder="Name your API key"
            fullWidth
            {...register("name", {
              required: true,
              validate: validateNotStartsWithInternalPrefix(
                `API key name cannot start with ${taktileInternalPrefix}`,
              ),
              onChange: () => {
                setInitialSubmitDisabled(false);
              },
            })}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-sm text-gray-600">Key permissions</label>
          <Controller
            control={control}
            name="keyTypes"
            render={({ field }) => (
              <KeyTypeSelector value={field.value} onChange={field.onChange} />
            )}
            rules={{ required: true }}
          />
        </div>

        <div className="mt-3 flex items-center justify-end gap-2 text-right">
          <Button
            variant="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={initialSubmitDisabled || !isEmpty(errors) || isSubmitting}
            htmlType="submit"
            loading={isSubmitting}
            variant="primary"
            onClick={submitApiKey}
          >
            Create API key
          </Button>
        </div>
      </form>
    </Modal>
  );
};
