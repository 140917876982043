import React from "react";

import { Dataset } from "src/api/types";
import { Spinner } from "src/base-components/Spinner";
import { toastFailure, toastSuccess } from "src/base-components/Toast/utils";
import { DatasetsDropdown } from "src/datasets/DatasetsDropdown";
import { useDeleteDataset } from "src/datasets/api/queries";
import { useDatasetFileUpload as useDatasetUpload } from "src/datasets/useDatasetUpload";
import { useAuthoringContext } from "src/router/routerContextHooks";
import * as logger from "src/utils/logger";

type DatasetSelectorProps = {
  datasets: Dataset[];
  selectedDatasetId: string | null;
  onSelectData: (datasetId: string | null) => void;
  isLoading: boolean;
};

export const DatasetSelector: React.FC<DatasetSelectorProps> = ({
  datasets,
  selectedDatasetId,
  onSelectData,
  isLoading,
}) => {
  const { workspace, version } = useAuthoringContext();
  const { uploads, uploadDataset } = useDatasetUpload(version);
  const deleteDataset = useDeleteDataset(workspace?.base_url);

  const onDelete = async (datasetId: string) => {
    try {
      await deleteDataset.mutateAsync(datasetId);
    } catch (err) {
      logger.error("Error deleting test data.", err);
    }
  };

  const onSubmitFiles = async (files: File[]) => {
    toastSuccess({ title: "Dataset uploading..." });

    for (const file of files) {
      try {
        const datasetFileUpload = await uploadDataset({
          file,
          purpose: "test_run",
        });
        datasetFileUpload.finishedPromise.then((finishedUpload) => {
          if (finishedUpload.status === "COMPLETED") {
            toastSuccess({ title: "Dataset processed successfully." });
          } else if (finishedUpload.status === "FAILED") {
            toastFailure({ title: `Unable to process dataset!` });
          }
        });
      } catch (err) {
        toastFailure({ title: "Dataset upload failed!" });
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <DatasetsDropdown
          dataLoc="data-dropdown"
          datasets={datasets}
          selectedId={selectedDatasetId}
          setSelected={onSelectData}
          uploads={uploads}
          onFileDelete={onDelete}
          onSubmitFiles={onSubmitFiles}
        />
      )}
    </>
  );
};
