import {
  faCopy,
  faCut,
  faObjectUngroup,
  faPen,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import { observer } from "mobx-react-lite";
import React from "react";

import { toastFailure, toastSuccess } from "src/base-components/Toast/utils";
import { NodeControlIcon } from "src/flowGraph/NodeControlIcon";
import { useCanAuthoringEditFlowVersion } from "src/hooks/useCanAuthoringEditFlowVersion";
import { getCreatableNodes } from "src/nodeAdding/PasteNodesCard";
import { AuthorPageParamsT } from "src/router/urls";
import { useGraphStore } from "src/store/StoreProvider";
import { pluralize } from "src/utils/stringUtils";
import { useParamsDecode } from "src/utils/useParamsDecode";

type PropsT = {
  onMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave: React.MouseEventHandler<HTMLDivElement>;
  id: string;
  close: () => void;
};

export const GroupControls: React.FC<PropsT> = observer(
  ({ onMouseEnter, onMouseLeave, close, id }) => {
    const {
      setIsUngroupModalVisible,
      setIsRenameNodeModalVisible,
      setGroupToChangeId,
      setIsDeleteGroupModalVisible,
      copyToClipboard,
      groups,
      setSelectedNode,
      deleteNode,
    } = useGraphStore();
    const { wsId } = useParamsDecode<AuthorPageParamsT>();

    const canModifyGraph = useCanAuthoringEditFlowVersion();

    const group = groups.get(id);

    const copyGroupToClipboard = async (isCutting?: boolean) => {
      if (group) {
        const copiedClipboard = await copyToClipboard([group], wsId);
        if (copiedClipboard) {
          if (isCutting) {
            await deleteNode(group, true);
            setSelectedNode(undefined);
          }
          toastSuccess({
            title: `${pluralize(
              getCreatableNodes(copiedClipboard.nodes).length,
              "node",
            )} ${isCutting ? "cut" : "copied"}`,
          });
        } else {
          toastFailure({
            title: "Copy to clipboard failed",
            description: "See the documentation for more information",
          });
        }
      }
    };

    return (
      <div
        className="absolute -right-2.5 top-0 flex translate-x-full flex-row overflow-hidden rounded-lg bg-white shadow-base"
        data-loc="group-controls"
        onClick={(e) => e.stopPropagation()}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {canModifyGraph && (
          <>
            <NodeControlIcon
              icon={faPen}
              tooltipTitle="Rename"
              borderRight
              onClick={() => {
                setGroupToChangeId(id);
                close();
                setIsRenameNodeModalVisible(true);
              }}
            />
            <NodeControlIcon
              dataLoc="cut-group"
              icon={faCut}
              tooltipTitle="Cut"
              borderRight
              onClick={() => copyGroupToClipboard(true)}
            />
          </>
        )}
        <NodeControlIcon
          dataLoc="copy-group"
          icon={faCopy}
          tooltipTitle="Copy"
          borderRight
          onClick={() => copyGroupToClipboard(false)}
        />
        {canModifyGraph && (
          <>
            <NodeControlIcon
              dataLoc="ungroup-node-group"
              icon={faObjectUngroup}
              tooltipTitle="Ungroup"
              borderRight
              onClick={() => {
                setGroupToChangeId(id);
                close();
                setIsUngroupModalVisible(true);
              }}
            />
            <NodeControlIcon
              dataLoc="delete-node-group"
              icon={faTrashCan}
              tooltipTitle="Delete"
              onClick={() => {
                setGroupToChangeId(id);
                close();
                setIsDeleteGroupModalVisible(true);
              }}
            />
          </>
        )}
      </div>
    );
  },
);
