import { toPairs, fromPairs } from "lodash";
import { useParams } from "react-router-dom";

type StringObject = {
  [key: string]: string | undefined;
};

export const useParamsDecode = <T extends StringObject>(): T => {
  const params = useParams();

  return fromPairs(
    toPairs(params).map(([k, v]) => [
      k,
      v !== undefined ? decodeURIComponent(v) : v,
    ]),
  ) as T;
};
