import React from "react";

import { useDeleteWorkspaceApiKey } from "src/apiKeys/api/queries";
import { TextConfirmationModal } from "src/base-components/TextConfirmationModal";
import { WorkspaceServiceAccount } from "src/clients/taktile-api";
import * as logger from "src/utils/logger";

type PropsT = {
  keyToDelete?: WorkspaceServiceAccount;
  organizationId: string;
  workspaceId: string;
  onClose: () => void;
};

export const DeleteApiKeyModal: React.FC<PropsT> = ({
  keyToDelete,
  organizationId: orgId,
  workspaceId: wsId,
  onClose,
}) => {
  const deleteWorkspaceApiKey = useDeleteWorkspaceApiKey(
    orgId,
    wsId,
    keyToDelete?.id ?? "",
  );

  const handleConfirm = async () => {
    if (!keyToDelete) {
      return;
    }

    try {
      await deleteWorkspaceApiKey.mutateAsync();
    } catch (error) {
      logger.error(`Error when deleting ApiKey: ${error}`);
    }
    onClose();
  };

  return (
    <TextConfirmationModal
      challengeText={keyToDelete?.name}
      description="Are you sure you'd like to delete this API key? This is an irreversible action, and might affect your existing API integrations."
      label="Type API key Name"
      open={keyToDelete !== undefined}
      title="Confirm Delete API key"
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  );
};
