import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { Icon } from "src/base-components/Icon";

export const EmptySearchResult = () => (
  <>
    <div className="flex h-full flex-col items-center justify-center">
      <div className="mb-4 rounded bg-indigo-100 p-3">
        <Icon color="text-indigo-600" icon={faSearch} size="lg" />
      </div>
      <div className="mb-2 text-gray-800 font-inter-semibold-13px">
        No results found
      </div>
      <div className="mx-28 justify-center text-center text-gray-500 font-inter-normal-12px">
        Make sure your spelling is correct or search for a different keyword
      </div>
    </div>
  </>
);
