import {
  ENVIRONMENT_MISSMATCH_IS_PROD,
  ENVIRONMENT_MISSMATCH_IS_SANDBOX,
  SingleDecisionQueryErrorReason,
  NO_DECISION_FOUND,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";

export const getHistoryEmptyStateWording = (
  reason: SingleDecisionQueryErrorReason | "emptyData",
): { headline: string; description: string } => {
  const options = {
    [ENVIRONMENT_MISSMATCH_IS_PROD]: {
      headline: "Environment missmatch",
      description: "The decision you searched for is a live decision",
    },
    [ENVIRONMENT_MISSMATCH_IS_SANDBOX]: {
      headline: "Environment missmatch",
      description: "The decision you searched for is a sandbox decision",
    },
    [NO_DECISION_FOUND]: {
      headline: "No decision found",
      description: "Make sure your input is correct or search a different ID",
    },
    emptyData: {
      headline: "No results found",
      description:
        "Try adjusting the search or filter to find what you are looking for",
    },
  } as const;
  return options[reason];
};
