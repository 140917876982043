import axios from "axios";

import { add401ErrorsMiddleware } from "src/api/middlewares/add401ErrorsMiddleware";
import { addAuthorizationHeaderMiddleware } from "src/api/middlewares/addAuthorizationHeader";
import { addHandleForbiddenMiddleware } from "src/api/middlewares/addHandleForbiddenMiddleware";
import { useAuthStore } from "src/store/AuthStore";

export const addMiddleware = () => {
  // Add the ID token to the requests
  addAuthorizationHeaderMiddleware(axios);

  // Handle 403s
  addHandleForbiddenMiddleware(axios);

  // Request a new ID token if we get a 401 response
  add401ErrorsMiddleware(
    axios,
    useAuthStore.getState().actions.refresh,
    useAuthStore.getState().actions.resetAll,
  );
};
