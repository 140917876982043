import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";

import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { useFlows } from "src/api/queries";
import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { dismissToast } from "src/base-components/Toast/utils";
import { BeMappedNode } from "src/constants/NodeDataTypes";
import { rightSidePaneWidthClassname } from "src/flowContainer/RightPane";
import { FlowList } from "src/nodeEditor/SelectFlowPane/FlowList";
import { FlowVersionList } from "src/nodeEditor/SelectFlowPane/FlowVersionList";
import { useAuthoringContext } from "src/router/routerContextHooks";

type SelectFlowProps = {
  selectedFlow: FlowT | null;
  onFlowSelect: (flow: FlowT) => void;
  selectedVersionId?: string;
  onVersionSelect: (version: FlowVersionT) => void;
};

export const SelectFlowAndVersion: React.FC<SelectFlowProps> = ({
  selectedFlow,
  onVersionSelect,
  selectedVersionId,
  onFlowSelect,
}) => {
  const { workspace } = useAuthoringContext();
  const flowsQuery = useFlows({
    workspaceId: workspace.id,
  });

  return (
    <>
      {selectedFlow ? (
        <FlowVersionList
          flowId={selectedFlow.id}
          flowVersions={selectedFlow.versions}
          selectedVersionId={selectedVersionId}
          onSelect={onVersionSelect}
        />
      ) : (
        <LoadingView
          queryResult={flowsQuery}
          renderUpdated={(flows: FlowT[]) => (
            <FlowList flows={flows} onSelect={onFlowSelect} />
          )}
        />
      )}
    </>
  );
};

type SelectFlowPaneProps = {
  node: BeMappedNode;
  onClose: () => void;
  onSelect: (flow: FlowT, version: FlowVersionT) => void;
};

export const SelectFlowPane: React.FC<SelectFlowPaneProps> = ({
  node,
  onClose,
  onSelect,
}) => {
  const [selectedFlow, setSelectedFlow] = useState<FlowT | null>(null);

  useEffect(() => {
    dismissToast(node.id);
  }, [node.id]);

  const handleBack = () => {
    setSelectedFlow(null);
  };

  return (
    <div
      className={twJoin(
        "flex max-h-full flex-col overflow-hidden bg-white",
        rightSidePaneWidthClassname,
      )}
    >
      <div className="flex flex-row items-center justify-between px-6 pb-2 pt-4 text-gray-800">
        <div className="flex">
          {selectedFlow && (
            <Icon
              color="text-gray-500"
              icon={faArrowLeft}
              size="xs"
              onClick={handleBack}
            />
          )}
          <h3 className="ml-2 font-inter-semibold-16px">
            {selectedFlow ? "Select a version" : "Select a Decision Flow"}
          </h3>
        </div>
        <Icon
          color="text-gray-500 hover:cursor-pointer"
          icon={faXmark}
          size="xl"
          onClick={onClose}
        />
      </div>
      <div className="overflow-hidden px-6 pb-4 pt-2">
        <SelectFlowAndVersion
          selectedFlow={selectedFlow}
          onFlowSelect={setSelectedFlow}
          onVersionSelect={(version) => {
            if (selectedFlow) {
              onSelect(selectedFlow, version);
            }
          }}
        />
      </div>
    </div>
  );
};
