import {
  faArrowLeft,
  faArrowRight,
  faClone,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { findIndex } from "lodash";
import React, { Dispatch } from "react";

import { EditorCellProps } from "src/base-components/EditorTable/types";
import { CellStringEditor } from "src/decisionTableNode/CellStringEditor";
import { ContextMenu } from "src/decisionTableNode/ContextMenu";
import { DecisionTableActions } from "src/decisionTableNode/actions";
import { DecisionTableAction } from "src/decisionTableNode/useDecisionTableActionsReducer";
import { RowShape } from "src/decisionTableNode/utils";

const ConditionContextMenu: React.FC<{
  index: number;
  show: boolean;
  dispatch?: Dispatch<DecisionTableAction>;
  isLastItem: boolean;
}> = React.memo(({ index, show, dispatch, isLastItem }) => (
  <div className="absolute left-1/2 h-fit -translate-x-1/2 -translate-y-1/2">
    <ContextMenu button="horizontal" show={show}>
      <ContextMenu.Item
        icon={faArrowLeft}
        onClick={() =>
          dispatch?.({
            type: DecisionTableActions.INSERT_CONDITION_LEFT,
            payload: index,
          })
        }
      >
        Insert Condition Left
      </ContextMenu.Item>
      <ContextMenu.Item
        icon={faArrowRight}
        onClick={() =>
          dispatch?.({
            type: DecisionTableActions.INSERT_CONDITION_RIGHT,
            payload: index,
          })
        }
      >
        Insert Condition Right
      </ContextMenu.Item>
      <ContextMenu.Item
        icon={faClone}
        onClick={() =>
          dispatch?.({
            type: DecisionTableActions.DUPLICATE_CONDITION,
            payload: index,
          })
        }
      >
        Duplicate
      </ContextMenu.Item>
      {!isLastItem && (
        <ContextMenu.Item
          icon={faTrashAlt}
          onClick={() =>
            dispatch?.({
              type: DecisionTableActions.DELETE_CONDITION,
              payload: index,
            })
          }
        >
          Delete Condition
        </ContextMenu.Item>
      )}
    </ContextMenu>
  </div>
));

export const ConditionFieldCell: React.FC<EditorCellProps<RowShape>> =
  React.memo(({ dispatch, state, ...props }) => {
    const isLastItem = (props.context.column.parent?.columns.length || 0) <= 1;

    const index = findIndex(
      props.context.column.parent?.columns,
      (c) => c.id === props.context.column.id,
    );

    return (
      <div className="w-full flex-col items-center">
        {!state.readonly && (
          <ConditionContextMenu
            dispatch={dispatch}
            index={index}
            isLastItem={isLastItem}
            show={state.isColumnHovered}
          />
        )}
        <CellStringEditor
          {...props}
          placeholder={props.columnDef.header.placeholder}
          state={state}
        />
      </div>
    );
  });
