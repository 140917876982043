import { orderBy, uniq } from "lodash";

import { taktileInternalPrefix } from "src/api/constants";
import {
  FlowVersionFlowChild,
  FlowVersionStatusT,
  FlowVersionT,
} from "src/api/flowTypes";
import { FlowDbShallow } from "src/clients/flow-api";
import * as logger from "src/utils/logger";

const getSortedPublishedVersions = (versions: FlowVersionT[]) => {
  const releasedVersions = versions.filter(
    (version) => version.status === FlowVersionStatusT.PUBLISHED,
  );
  return orderBy(releasedVersions, ["meta.published_at"], ["desc"]);
};
const getSortedDraftVersions = (versions: FlowVersionT[]) => {
  const draftVersions = versions.filter(
    (version) => version.status === FlowVersionStatusT.DRAFT,
  );
  return orderBy(draftVersions, ["created_at"], ["desc"]);
};
const getSortedArchivedVersions = (versions: FlowVersionT[]) => {
  const archivedVersions = versions.filter(
    (version) => version.status === FlowVersionStatusT.ARCHIVED,
  );
  return orderBy(archivedVersions, ["meta.archived_at"], ["desc"]);
};

export const isLastPublishedVersion = (
  versions: FlowVersionT[],
  version: FlowVersionT,
) => {
  const sortedVersions = getSortedPublishedVersions(versions);
  return version.id === sortedVersions.at(-1)?.id;
};

export const isLastDraftVersion = (
  versions: FlowVersionT[],
  version: FlowVersionT,
) => {
  const sortedVersions = getSortedDraftVersions(versions);
  return version.id === sortedVersions.at(-1)?.id;
};

export const getSortedVersions = (versions: FlowVersionT[]) => {
  const sortedDrafts = getSortedDraftVersions(versions);
  const sortedReleased = getSortedPublishedVersions(versions);
  const sortedArchived = getSortedArchivedVersions(versions);

  return [...sortedReleased, ...sortedDrafts, ...sortedArchived];
};

export const getLatestReleaseVersion = (versions: FlowVersionT[]) => {
  const sortedVersions = getSortedPublishedVersions(versions);
  if (sortedVersions.length > 0) {
    return sortedVersions[0];
  }
};

export const getNameErrorMessage = (type: string): string => {
  if (type === "required") {
    return "Decision Flow version name cannot be empty";
  } else if (type === "notAvailable") {
    return "Decision Flow version name already in use";
  } else if (type === "noLeadingTrailingSpaces") {
    return "Decision Flow version name cannot have leading or trailing spaces";
  } else if (type === "disallowedCharacters") {
    return "Please only use letters, digits, dashes, underscores, spaces and dots.";
  } else {
    return "";
  }
};

// Allowed characters: letters, digits, dashes, underscores, dots, spaces, brackets, colons, square brackets.
const VERSION_NAME_ALLOWED_CHARS = /^[A-Za-z0-9\-_.\s[\]()]*$/;

export const nameValidations = {
  noLeadingTrailingSpaces: (name: string) => name === name.trim(),
  disallowedCharacters: (name: string) => VERSION_NAME_ALLOWED_CHARS.test(name),
};

export const getSlugErrorMessage = (type: string): string => {
  if (type === "minLength") {
    return "URL Identifier has to be at least 4 characters long";
  } else if (type === "maxLength") {
    return "URL Identifier has to be shorter than 20 characters";
  } else if (type === "required") {
    return "URL Identifier cannot be empty";
  } else if (type === "notAvailable") {
    return "URL Identifier already in use";
  } else if (type === "slug") {
    return "URL Identifier not valid. Please only use lowercase letters, digits and dashes.";
  } else if (type === "noInternalPrefix")
    return `URL Identifier cannot start with ${taktileInternalPrefix}`;
  else {
    return "";
  }
};

export type GroupedRelatedFlows = Record<
  string,
  { name: string; versions: FlowDbShallow["versions"] }
>;

export const groupRelatedFlows = (
  flows: FlowDbShallow[],
): [string[], GroupedRelatedFlows] => {
  return [
    uniq(flows.map((flow) => flow.id)),
    flows.reduce((acc, flow) => {
      if (acc[flow.id]) {
        acc[flow.id].versions = [
          ...(acc[flow.id].versions ?? []),
          ...(flow.versions ?? []),
        ];
      } else {
        acc[flow.id] = {
          name: flow.name,
          versions: flow.versions ?? [],
        };
      }
      return acc;
    }, {} as GroupedRelatedFlows),
  ];
};

export const groupRelatedFlowsForMultipleVersions = (
  versions: FlowVersionFlowChild[],
  mode: "parents" | "children",
) => {
  // extract parent versions from each flow version
  const groupedParentFlows: GroupedRelatedFlows = {};
  for (const version of versions) {
    const modeSelectedRelatedFlows =
      mode === "parents" ? version.parent_flows : version.child_flows;
    if (!modeSelectedRelatedFlows) {
      logger.error("No information about parent flows");
      return null;
    }
    const [_, currentParentFlows] = groupRelatedFlows(modeSelectedRelatedFlows);
    for (const [parentFlowId, group] of Object.entries(currentParentFlows)) {
      if (group.versions === undefined) {
        logger.error("No information about parent flows");
        return null;
      }
      if (!(parentFlowId in Object.keys(groupedParentFlows))) {
        groupedParentFlows[parentFlowId] = group;
      } else {
        groupedParentFlows[parentFlowId].versions?.concat(group.versions);
      }
    }
  }

  for (const group of Object.values(groupedParentFlows)) {
    group.versions?.sort(
      (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
    );
  }
  return groupedParentFlows;
};

export const getFitleredGroupedRelatedFlows = (
  groupedRelatedFlows: GroupedRelatedFlows,
  statusFilter?: FlowVersionStatusT,
) =>
  Object.fromEntries(
    Object.entries(groupedRelatedFlows)
      .map(([parentFlowId, group]) => {
        const filteredGroup = {
          name: group.name,
          versions: group.versions?.filter(
            (version) => version.status === statusFilter,
          ),
        };
        return [parentFlowId, filteredGroup] as const;
      })
      .filter(
        ([_, group]) => group.versions?.length && group.versions.length > 0,
      ),
  );
