import { DndContext, DragOverEvent } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import {
  getExpandedRowModel,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  RowData,
  CellContext,
  HeaderContext,
  flexRender,
} from "@tanstack/react-table";
import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
  Dispatch,
} from "react";

import { FloatingCodeInputEditorProvider } from "src/base-components/EditorTable/FloatingEditorCodeInput";
import { HeaderCell } from "src/base-components/EditorTable/HeaderCell";
import { SortableRow } from "src/base-components/EditorTable/SortableRow";
import { TableCell } from "src/base-components/EditorTable/TableCell";
import { useColumnOrder } from "src/base-components/EditorTable/hooks/useColumnOrder";
import { useSelectionHandler } from "src/base-components/EditorTable/hooks/useSelectionHandler";
import { useTableNavActions } from "src/base-components/EditorTable/stores";
import {
  EditorColumnDef,
  EditorColumnGroup,
  EditorTableProps,
  RowBase,
} from "src/base-components/EditorTable/types";
import { coordinateToKey } from "src/base-components/EditorTable/utils";
import { DecisionTableAction } from "src/decisionTableNode/useDecisionTableActionsReducer";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line unused-imports/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    customColumn?: EditorColumnDef<TData>;
    isGroupHeader?: boolean;
    resizable?: boolean;
  }
}

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    onChange?: (rowIndex: number, row: TData) => void;
    dispatch?: Dispatch<DecisionTableAction>;
    colOrder?: string[];
    readonly?: boolean;
  }
}

type TableCellContext<T, V> = CellContext<T, V> & HeaderContext<T, V>;

export const EditorTable = <T extends RowBase<T>>({
  dataLoc,
  data,
  columnGroups,
  hideHeaders,
  hideAllHeaders,
  readonlyCells,
  rowSortHandle,
  readonly,
  onReorder,
  onChange,
  onHeaderChange,
  dispatch,
  onColumnSizingChange,
  columnSizing,
  errors: errorsArray,
  enableCodeInput,
}: EditorTableProps<T>): JSX.Element => {
  const columnHelper = useMemo(() => createColumnHelper<T>(), []);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { setReadonlyCellIds, setErrors, setHoveredCellIndex } =
    useTableNavActions();

  useEffect(() => {
    const cells = readonlyCells || [];
    const fromReadOnlyCells = cells.map(({ row, col }) =>
      coordinateToKey(row, col),
    );
    const fromColConfigs = columnGroups
      .flatMap((group) => group.columns)
      .filter((column) => Boolean(column.readonly))
      .flatMap((column) => {
        return data.map((row) => coordinateToKey(row.id, column.key));
      });

    setReadonlyCellIds([...fromReadOnlyCells, ...fromColConfigs]);
  }, [readonlyCells, columnGroups, data, setReadonlyCellIds]);

  // Update zustand state when errorsArray changes
  useEffect(() => {
    if (errorsArray) {
      setErrors(errorsArray);
    }
  }, [errorsArray, setErrors]);

  const handleTableMouseOut = useCallback(() => {
    setHoveredCellIndex(null, null);
  }, [setHoveredCellIndex]);

  const handleDragEnd = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event;
      if (onReorder && over) {
        const oldIndex = data.findIndex((d) => d.id === active.id);
        const newIndex = data.findIndex((d) => d.id === over.id);
        onReorder(oldIndex, newIndex);
      }
    },
    [onReorder, data],
  );

  const columns = useMemo(
    () =>
      columnGroups.map((group) =>
        columnHelper.group({
          header: group.name,
          meta: { customColumn: group.columns[0], isGroupHeader: true },
          columns: group.columns.map((column) =>
            columnHelper.accessor((row) => row[column.key], {
              id: column.key as string,
              header: column.header.value,
              cell: (info) => {
                const columnDef = info.column.columnDef.meta!.customColumn!;
                const colIndex =
                  info.table.options.meta?.colOrder?.indexOf(
                    String(column.key),
                  ) ?? String(column.key);
                const rowIndex =
                  info.row.index + (info.row.getParentRow() ? 1 : 0);
                return (
                  <TableCell<T, unknown>
                    columnDef={columnDef}
                    context={info}
                    dataLoc={`dt-cell-${rowIndex}-${colIndex}`}
                    readonly={
                      Boolean(info.table.options.meta?.readonly) ||
                      Boolean(columnDef.readonly)
                    }
                    renderer={columnDef.cell.renderer}
                    value={info.getValue()}
                    width={info.column.getSize()}
                  />
                );
              },
              meta: { customColumn: column, resizable: column.resizable },
              size: column.width ?? 150,
              minSize: column.minWidth ?? 150,
              maxSize: column.maxWidth ?? 320,
            }),
          ),
        }),
      ),
    [columnGroups, columnHelper],
  );

  const colOrder = useColumnOrder(columnGroups);

  const table = useReactTable<T>({
    data,
    columns,
    state: { expanded: true },
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowId: (row, index) => {
      if (row.id) {
        return row.id;
      }

      return String(index);
    },
    onColumnSizingChange: (updater) => {
      if (typeof updater === "function") {
        const oldSizing = table.getState().columnSizing;
        const newSizing = updater(oldSizing);

        table.setState((state) => ({
          ...state,
          columnSizing: newSizing,
        }));

        onColumnSizingChange?.(newSizing);
      }
    },
    meta: {
      onChange,
      dispatch,
      colOrder,
      readonly,
    },
  });

  useEffect(() => {
    if (columnSizing) {
      table.setState((oldState) => {
        return {
          ...oldState,
          columnSizing: { ...columnSizing },
        };
      });
    }
  }, [columnSizing, table]);

  const rows = table.getRowModel().rows;
  const rowIds = useMemo(() => data.map((row) => row.id), [data]);

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext items={rowIds}>
        <div
          ref={tableContainerRef}
          className="relative focus-within:z-10"
          data-loc={dataLoc}
        >
          <FloatingCodeInputEditorProvider
            dataLocPrefix={dataLoc}
            enabled={enableCodeInput}
          >
            {/* "h-1" is a css hack, allows div inside td to have full-height */}
            <table
              className="relative isolate h-1 min-w-full table-auto border-collapse border-spacing-0"
              style={{
                width: table.getCenterTotalSize(),
              }}
              onMouseOut={handleTableMouseOut}
            >
              <thead className="sticky top-0 z-10 bg-white">
                {!hideAllHeaders &&
                  table
                    .getHeaderGroups()
                    .slice(0, hideHeaders ? 1 : undefined)
                    .map((headerGroup) => (
                      <tr key={headerGroup.id} className="bg-white">
                        {!readonly && rowSortHandle && (
                          <th
                            className="p-0"
                            style={{ width: 1, minWidth: 1, maxWidth: 1 }}
                          />
                        )}
                        {headerGroup.headers.map((header) => {
                          const context =
                            header.getContext() as TableCellContext<T, string>;
                          const isGroupHeader =
                            context.column.columnDef.meta!.isGroupHeader;
                          const customColumn =
                            context.column.columnDef.meta!.customColumn!;

                          if (isGroupHeader) {
                            return (
                              <HeaderCell
                                key={header.id}
                                header={header}
                                isHighlighted={customColumn.isHighlighted}
                                isResizing={header.column.getIsResizing()}
                                resizable={header.subHeaders.length === 1}
                                resizeHandler={header.getResizeHandler()}
                                width={header.getSize()}
                              />
                            );
                          }

                          return (
                            <TableCell<T, string>
                              key={header.id}
                              columnDef={customColumn}
                              context={context}
                              dataLoc={`dt-column-header-${header.index}`}
                              isResizing={header.column.getIsResizing()}
                              readonly={Boolean(readonly)}
                              renderer={customColumn.header.renderer}
                              resizable={
                                header.column.columnDef.meta!.resizable
                              }
                              resizeHandler={header.getResizeHandler()}
                              value={customColumn.header.value}
                              width={header.getSize()}
                              onHeaderChange={onHeaderChange}
                            />
                          );
                        })}
                      </tr>
                    ))}
              </thead>
              <tbody>
                {rows.map((row) => {
                  return (
                    <SortableRow
                      key={row.id}
                      dispatch={dispatch}
                      row={row}
                      sortHandle={readonly ? undefined : rowSortHandle}
                      totalRows={rows.length}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <React.Fragment key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </React.Fragment>
                        );
                      })}
                    </SortableRow>
                  );
                })}
              </tbody>
            </table>
          </FloatingCodeInputEditorProvider>
        </div>
      </SortableContext>
      {!readonly && (
        <KeyboardNavController
          columnGroups={columnGroups}
          data={data}
          hiddenHeaders={Boolean(hideHeaders || hideAllHeaders)}
          tableContainerRef={tableContainerRef}
        />
      )}
    </DndContext>
  );
};

type KeyboardNavControllerProps<T> = {
  tableContainerRef: React.RefObject<HTMLDivElement>;
  columnGroups: EditorColumnGroup<T>[];
  data: T[];
  hiddenHeaders: boolean;
};

const KeyboardNavController = <T extends RowBase<T>>({
  tableContainerRef,
  data,
  columnGroups,
  hiddenHeaders,
}: KeyboardNavControllerProps<T>) => {
  useSelectionHandler<T>({
    tableContainerRef,
    data,
    columnGroups,
    hiddenHeaders,
  });

  return null;
};
