import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { faHome } from "@fortawesome/pro-solid-svg-icons";
import { ReactNode, Component } from "react";

import { Button } from "src/base-components/Button";
import { EmptyState } from "src/base-components/EmptyState";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { parseUrl } from "src/router/urls";
import * as logger from "src/utils/logger";

type Props = { children: ReactNode };

export class ErrorBoundary extends Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    logger.error("Error boundary caught an error", { error, errorInfo });
    const { orgId } = parseUrl(window.location.href);
    tracker.emit(
      trackingEvents.clientRuntimeError({
        error_message: error.message,
        error_stack: error.stack,
        error_component_stack: errorInfo.componentStack,
        organization_id: orgId,
      }),
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex h-full items-center justify-center">
          <EmptyState
            action={
              <Button
                iconLeft={faHome}
                variant="secondary"
                onClick={() => window.location.assign("/")}
              >
                Go to home page
              </Button>
            }
            description="Oops, something went wrong."
            headline="Error"
            icon={faExclamationCircle}
            variant="error"
          />
        </div>
      );
    }

    return this.props.children;
  }
}
