import React from "react";
import { useSearchParams, Link } from "react-router-dom";

import ErrorPageIcon from "src/assets/ErrorPageIcon.svg?react";
import { Button } from "src/base-components/Button";
import { toggleThenaWidget } from "src/utils/useBootTheena";

export const ErrorContent: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="mx-auto flex h-full items-center justify-center">
      <ErrorPageIcon className="pr-10" />
      <div className="max-w-[22.5rem]">
        <h1 className="align-left text-gray-800 font-inter-semibold-16px">
          Error
        </h1>
        <p className="my-3 font-inter-normal-13px" data-loc="error-text">
          {message}
        </p>
        <div className="flex flex-row items-center">
          <Link to="/">
            <span className="mr-3 inline-block">
              <Button dataLoc="back-to-home-button" variant="secondary">
                Go to home page
              </Button>
            </span>
          </Link>
          <Button variant="secondary" onClick={toggleThenaWidget}>
            Contact us
          </Button>
        </div>
      </div>
    </div>
  );
};

type PropsT = { message?: string };

export const ErrorPage: React.FC<PropsT> = ({ message }) => {
  const searchParams = useSearchParams()[0];
  const additionalInformation =
    searchParams.get("error") ??
    message ??
    "Looks like something went wrong! We're looking into it.";

  return (
    <div className="flex h-full w-full items-center">
      <ErrorContent message={additionalInformation} />
    </div>
  );
};
