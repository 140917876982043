import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";

export const DashboardLayout: React.FC = () => {
  const context = useOutletContext();
  return (
    <div className="flex h-full w-full">
      <Outlet context={context} />
    </div>
  );
};
