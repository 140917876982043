import { useDatasetJobs } from "src/datasets/api/queries";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useCurrentUserId } from "src/store/AuthStore";

export const useThereIsDownloadPending = () => {
  const { workspace, flow } = useAuthoringContext();
  const datasetJobs = useDatasetJobs(workspace.base_url!, flow.id);

  const userId = useCurrentUserId();

  return (datasetJobs.data ?? []).some(
    (job) =>
      job.status === "PENDING" &&
      job.type === "download" &&
      job.created_by === userId,
  );
};
