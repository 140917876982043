import {
  ConnectionResourceTemplate,
  makeConnectionTemplate,
} from "src/connections/types";

const configKeys: ConnectionResourceTemplate["configKeys"] = [
  {
    key: "report_language",
    required: true,
    name: "Report language",
    type: "simple_selection",
    elements: [
      { key: "DE", value: "DE" },
      { key: "LU", value: "LU" },
      { key: "AT", value: "AT" },
      { key: "BE", value: "BE" },
      { key: "CH", value: "CH" },
      { key: "CZ", value: "CZ" },
      { key: "ES", value: "ES" },
      { key: "FR", value: "FR" },
      { key: "HR", value: "HR" },
      { key: "IE", value: "IE" },
      { key: "LV", value: "LV" },
      { key: "PL", value: "PL" },
      { key: "SK", value: "SK" },
      { key: "TR", value: "TR" },
      { key: "EN", value: "EN" },
      { key: "IT", value: "IT" },
    ],
  },
  {
    key: "creditreform_legitimate_interest",
    required: true,
    name: "Creditreform legitimate interest",
    type: "simple_selection",
    elements: [
      { key: "LEIN-100", value: "Loan decision" },
      { key: "LEIN-101", value: "Solvency check" },
      { key: "LEIN-102", value: "Business initiation" },
      { key: "LEIN-103", value: "Accounts receivable" },
      { key: "LEIN-104", value: "Accounts receivable overdue" },
      { key: "LEIN-105", value: "Insurance contract" },
      { key: "LEIN-106", value: "Holdings" },
      { key: "LEIN-107", value: "Debt collection" },
    ],
  },
];
const mailboxDirectoryConfigKeys: ConnectionResourceTemplate["configKeys"] = [
  {
    key: "processing_statuses",
    required: true,
    name: "Processing statuses",
    type: "multiple_selection",
    elements: [
      { key: "open_orders", value: "Open orders" },
      { key: "entries_read", value: "Read entries" },
      { key: "entries_unread", value: "Unread entries" },
    ],
  },
  {
    key: "delivery_types",
    required: false,
    name: "Delivery types",
    type: "multiple_selection",
    elements: [
      { key: "update", value: "Update (Aktualisierung)" },
      { key: "report", value: "Report (Auskunft)" },
      { key: "status_reply", value: "Status reply (Statusantwort)" },
      { key: "supplement", value: "Supplement (Nachtrag)" },
      { key: "stock_delivery", value: "Stock delivery (Bestandslieferung)" },
    ],
  },
  {
    key: "number_of_entries",
    required: false,
    name: "Number of entries",
    hint: "An integer (1-500) for the number of entries to be returned. If omitted, the full mailbobx up to 500 entries will be returned.",
    type: "number",
  },
];
export const CreditreformTemplate = makeConnectionTemplate({
  provider: "creditreform",
  secrets: [
    { key: "account_id", name: "Account ID", required: true },
    { key: "general_password", name: "General password", required: true },
    { key: "individual_password", name: "Individual password", required: true },
  ],
  resources: {
    search_request: {
      name: "Search Request",
      configKeys: [],
    },
    brief_report: {
      name: "Brief Report",
      configKeys,
    },
    commercial_report: {
      name: "Commercial Report",
      configKeys,
    },
    creditreform_mailbox_directory: {
      name: "Commercial Report - Mailbox Directory",
      configKeys: mailboxDirectoryConfigKeys,
    },
    creditreform_mailbox_entry: {
      name: "Commercial Report - Mailbox Entry",
      configKeys: [],
    },
    compact_report: {
      name: "Compact Report",
      configKeys,
    },
    ecrefo_report: {
      name: "E-Crefo Report",
      configKeys,
    },
    identification_report: {
      name: "Identification Report",
      configKeys,
    },
    risk_check_report: {
      name: "Risk Check Report",
      configKeys,
    },
    traffic_light_report: {
      name: "Traffic Light Report",
      configKeys,
    },
  },
});
