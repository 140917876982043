import { createContext, useContext } from "react";

type ContextValue = {
  renderedInDiffView: boolean;
};

export const DiffViewContext = createContext<ContextValue>({
  renderedInDiffView: false,
});

export const useDiffViewContext = () => useContext(DiffViewContext);
