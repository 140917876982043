import {
  faArrowLeft,
  faCheck,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { Menu } from "@headlessui/react";
import { AnimatePresence, m } from "framer-motion";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import { twJoin, twMerge } from "tailwind-merge";

import { FlowT } from "src/api/flowTypes";
import { Icon } from "src/base-components/Icon";
import { FlowVersionStatus } from "src/clients/flow-api";

type Value =
  | { id: string | undefined; versionId: string | undefined }
  | undefined;

type Props = {
  selected: Value;
  onSelect: (value: Value) => void;
  flows: FlowT[];
};

type MenuMode = { type: "flow" } | { type: "version"; flow: FlowT };
const ELEMENTS_MAX_HEIGHT = "max-h-[200px]";

export const FlowAndVersionDropdown: React.FC<Props> = ({
  flows,
  onSelect,
  selected,
}) => {
  const [menuMode, setMenuMode] = useState<MenuMode>({ type: "flow" });

  const [menuRef, setMenuRef] = useState<Nullable<HTMLElement>>(null);
  const [itemsRef, setItemsRef] = useState<Nullable<HTMLDivElement>>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    menuRef,
    itemsRef,
    {
      strategy: "absolute",
      placement: "bottom-start",
      modifiers: [{ name: "offset", options: { offset: [0, 2] } }],
    },
  );
  const selectedFlow = flows.find((flow) => flow.id === selected?.id);
  const selectedVersion = selectedFlow?.versions?.find(
    (version) => version.id === selected?.versionId,
  );
  return (
    <Menu ref={setMenuRef} as="div" className="w-full max-w-full">
      {({ open }) => (
        <>
          <Menu.Button
            className={twMerge(
              "font-inter-normal-12px",
              "flex h-8 w-full items-center justify-center rounded-lg",
              "border border-gray-200 bg-white",
              "ring-inset focus:border-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500/25",
              (!selectedFlow || !selectedVersion) && "text-gray-500",
              open &&
                "border-indigo-400 outline-none ring-2 ring-indigo-500/25",
            )}
            data-loc="flow-and-version-dropdown"
          >
            <div className="flex w-full items-center gap-x-1 py-1.5 pl-3 pr-2">
              <div>
                {!selectedFlow || !selectedVersion ? (
                  "Select Decision Flow and version"
                ) : (
                  <span className="flex items-center gap-x-1">
                    <Icon
                      color={
                        selectedVersion.status === FlowVersionStatus.PUBLISHED
                          ? "text-green-400"
                          : "text-yellow-400"
                      }
                      icon={faCircle}
                      size="2xs"
                    />
                    {`${selectedFlow?.name}, ${selectedVersion?.name}`}
                  </span>
                )}
              </div>
              <div className="ml-auto flex h-auto items-center">
                <Icon color="text-gray-500" icon={faChevronDown} size="xs" />
              </div>
            </div>
          </Menu.Button>
          <AnimatePresence>
            {open && (
              <m.div
                key="flow-and-version-dropdown"
                ref={setItemsRef}
                data-loc="items"
                style={{
                  ...popperStyles.popper,
                  maxHeight: menuRef
                    ? `calc(100vh - ${menuRef.scrollHeight}px)`
                    : undefined,
                }}
                {...popperAttributes.popper}
                animate={{ opacity: 1, transition: { duration: 0.2 } }}
                className="z-50 w-[27rem] rounded-lg bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none"
                exit={{ opacity: 0, transition: { duration: 0.1 } }}
                initial={{ opacity: 0 }}
              >
                <Menu.Items
                  className={twJoin(
                    "overflow-y-auto text-gray-800 font-inter-normal-13px",
                    ELEMENTS_MAX_HEIGHT,
                  )}
                  static
                >
                  {menuMode.type === "flow" && (
                    <>
                      {flows.length !== 0 && (
                        <>
                          {flows.map((flow) => (
                            <Menu.Item
                              key={flow.id}
                              as="div"
                              className="group flex h-12 cursor-pointer items-center justify-between px-4 hover:bg-gray-50"
                              data-loc={`select-${flow.id}`}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setMenuMode({
                                  type: "version",
                                  flow: flow,
                                });
                              }}
                            >
                              {flow.name}
                              <div className="flex gap-x-2">
                                {selected?.id === flow.id && (
                                  <Icon
                                    color="text-indigo-500"
                                    icon={faCheck}
                                    size="xs"
                                  />
                                )}
                                <div className="hidden group-hover:block">
                                  <Icon
                                    color="text-gray-500"
                                    icon={faChevronRight}
                                    size="xs"
                                  />
                                </div>
                              </div>
                            </Menu.Item>
                          ))}
                        </>
                      )}
                    </>
                  )}
                  {menuMode.type === "version" && (
                    <FlowSection
                      flow={menuMode.flow}
                      selected={selected}
                      onExit={() => setMenuMode({ type: "flow" })}
                      onSelect={onSelect}
                    />
                  )}
                </Menu.Items>
              </m.div>
            )}
          </AnimatePresence>
        </>
      )}
    </Menu>
  );
};

const FlowSection: React.FC<{
  flow: FlowT;
  selected: Value;
  onSelect: (value: Value) => void;
  onExit: () => void;
}> = ({ onExit, onSelect, selected, flow }) => {
  return (
    <div
      className={twJoin(
        "flex min-h-0 flex-col overflow-hidden",
        ELEMENTS_MAX_HEIGHT,
      )}
    >
      <div className="flex min-h-[3rem] items-center pl-4 text-gray-800 font-inter-medium-13px">
        <Icon
          color="text-gray-500"
          icon={faArrowLeft}
          size="sm"
          onClick={onExit}
        />
        <span className="ml-2">{flow.name}</span>
      </div>
      <div className="grow overflow-y-auto">
        {flow.versions?.map((version) => {
          const isSelected = selected?.versionId === version.id;
          return (
            <Menu.Item
              key={version.id}
              as="div"
              className="group flex h-12 cursor-pointer items-center justify-between px-4 font-inter-normal-13px hover:bg-gray-50"
              data-loc={`select-${version.id}`}
              onClick={() => {
                onSelect({
                  id: flow.id,
                  versionId: version.id,
                });
              }}
            >
              <span className="flex items-center gap-x-1">
                <Icon
                  color={
                    version.status === FlowVersionStatus.PUBLISHED
                      ? "text-green-400"
                      : "text-yellow-400"
                  }
                  icon={faCircle}
                  size="2xs"
                />
                {version.name}
              </span>
              {isSelected && (
                <Icon color="text-indigo-500" icon={faCheck} size="xs" />
              )}
            </Menu.Item>
          );
        })}
      </div>
    </div>
  );
};
