import { add, isBefore } from "date-fns";

import { barY, lineY } from "src/performance/Plot/marks";
import { getIntervalForTimeWindow } from "src/performance/utils";
import { StrictDateRange } from "src/utils/timeWindow";

/**
 * Generate a domain for the x axis based on the range and interval
 * @param range
 * @param interval
 * @returns Date[]
 */
export const getDomain = (timeWindow: StrictDateRange) => {
  const interval = getIntervalForTimeWindow(timeWindow);

  const addOneTickBasedOnInterval = (value: Date) => {
    return add(value, {
      hours: interval === "day" ? 24 : 1,
    });
  };

  const result = [timeWindow.from];
  let newDate = addOneTickBasedOnInterval(timeWindow.from);
  while (isBefore(newDate, timeWindow.to)) {
    result.push(newDate);
    newDate = addOneTickBasedOnInterval(newDate);
  }

  return result;
};

/**
 * Loading state related logic
 */
const loadingBars = barY({ x: "x", y: "y", fill: "#F3F4F6" });
const loadingLines = lineY({
  x: "x",
  y: "y",
  stroke: "#F3F4F6",
  strokeWidth: 4,
});

const BAR_FAKE_VALUES = [900, 800];
const LINE_FAKE_VALUES = [
  369, 392, 658, 381, 154, 261, 752, 853, 318, 598, 172, 227, 511, 465, 565,
];

export const getLoadingMarks = (
  timeWindow: StrictDateRange,
  type: "line" | "bar",
) => {
  const domain = getDomain(timeWindow);
  const values = type === "line" ? LINE_FAKE_VALUES : BAR_FAKE_VALUES;
  const data = domain.map((d, i) => ({
    x: d,
    y: values[i % values.length],
  }));

  return type === "line" ? loadingLines(data) : loadingBars(data);
};
