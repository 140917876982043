import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import React from "react";

import { Icon } from "src/base-components/Icon";

export type SearchBoxPropsT = {
  placeholder: string;
  onChange: (input: string) => void;
  defaultValue?: string;
  value?: string;
  dataLoc?: string;
};

export const SearchBox: React.FC<SearchBoxPropsT> = ({
  placeholder,
  onChange,
  defaultValue,
  value,
  dataLoc,
}) => (
  <div className="flex h-8 w-full items-center rounded-lg border border-gray-200 bg-white px-2 py-1">
    <div className="mr-1">
      <Icon color="text-gray-500" icon={faMagnifyingGlass} size="2xs" />
    </div>
    <input
      className="w-full bg-white font-inter-normal-12px placeholder:text-gray-500 focus:outline-none"
      data-loc={dataLoc}
      defaultValue={defaultValue}
      placeholder={placeholder}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    />
  </div>
);
