import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { Icon } from "src/base-components/Icon";

type ButtonPropsT = {
  value?: string;
};

export const EnvironmentDropdownButton: React.FC<ButtonPropsT> = ({
  value,
}) => {
  return (
    <div className="flex items-center justify-between py-2 pl-4 pr-3">
      {value ? (
        <span>{value}</span>
      ) : (
        <span className="text-gray-500">Select credentials</span>
      )}
      <div className="flex h-auto items-center">
        <Icon color="text-gray-500" icon={faChevronDown} size="xs" />
      </div>
    </div>
  );
};
