import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button } from "src/base-components/Button";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { Textarea } from "src/base-components/Textarea";
import { toastFailure } from "src/base-components/Toast/utils";
import { Modal } from "src/design-system/Modal";
import {
  useCreateJob,
  useDuplicateJob,
  useUpdateJob,
} from "src/jobs/api/queries";
import { Job } from "src/jobs/types";
import { queryClient } from "src/queryClient";
import { useFlowContext } from "src/router/routerContextHooks";
import { getUrlToJobPage } from "src/router/urls";
import { isPreconditionError } from "src/utils/predicates";
import { assertUnreachable } from "src/utils/typeUtils";

type ManageJobModalForm = Pick<Job, "name" | "description">;
type ModalAction = ManageJobModalProps["action"];

type ManageJobModalProps = {
  open: boolean;
  afterLeave?: () => void;
  onClose: () => void;
} & (
  | { action: "create"; job?: never }
  | { action: "update" | "duplicate"; job?: Job }
);

export const ManageJobModal: React.FC<ManageJobModalProps> = ({
  open,
  onClose,
  afterLeave,
  ...props
}) => {
  const { workspace, flow } = useFlowContext();
  const createJobMutation = useCreateJob(workspace.base_url!, flow.id);
  const duplicateJobMutation = useDuplicateJob(workspace.base_url!, props.job);
  const updateJobMutation = useUpdateJob(workspace.base_url!, props.job);
  const navigate = useNavigate();

  const { register, handleSubmit, formState } = useForm<ManageJobModalForm>();

  const onSubmit = async (data: ManageJobModalForm) => {
    try {
      switch (props.action) {
        case "create":
          const job = await createJobMutation.mutateAsync(data);
          onClose();
          navigate(
            getUrlToJobPage(
              workspace.organization_id,
              workspace.id,
              flow.id,
              job.id,
            ),
          );
          break;
        case "update":
          await updateJobMutation.mutateAsync({
            ...data,
            etag: props.job!.etag,
          });
          onClose();
          break;
        case "duplicate":
          const newJob = (await duplicateJobMutation.mutateAsync(data)).data;
          onClose();
          navigate(
            getUrlToJobPage(
              workspace.organization_id,
              workspace.id,
              flow.id,
              newJob.id,
            ),
          );
          break;
        default:
          assertUnreachable(props);
      }
    } catch (error) {
      if (isPreconditionError(error) && props.job) {
        await queryClient.invalidateQueries([
          "job",
          workspace.base_url,
          props.job?.id,
        ]);
        toastFailure({
          title: `Failed to ${props.action} the Job`,
          description:
            "Somebody has updated the Job, check updated configuration and try again",
        });
      }

      toastFailure({
        title: `Failed to ${props.action} the Job`,
        description: "Something went wrong. Please reload and try again.",
      });
    }
  };

  return (
    <Modal afterLeave={afterLeave} open={open} onClose={onClose}>
      <Modal.Header description={getSubtitle(props.action)}>
        {getTitle(props.action)}
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <div className="flex flex-col gap-y-6">
            <div>
              <Label required>Name</Label>
              <Input
                data-loc="job-name-input"
                placeholder="Enter Job name"
                fullWidth
                {...register("name", { required: true })}
                defaultValue={
                  props.action === "duplicate"
                    ? `${props.job?.name} (copy)`
                    : props.job?.name
                }
              />
            </div>
            <div>
              <Label>Description</Label>
              <Textarea
                placeholder="Enter description"
                {...register("description")}
                defaultValue={props.job?.description}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc={`${props.action}-job`}
              disabled={formState.isSubmitting || !formState.isValid}
              htmlType="submit"
              loading={formState.isSubmitting}
              variant="primary"
            >
              {getButtonCopy(props.action)}
            </Button>
          }
        />
      </form>
    </Modal>
  );
};

const getTitle = (action: ModalAction) => {
  switch (action) {
    case "create":
      return "Create new Job";
    case "duplicate":
      return "Duplicate Job";
    case "update":
      return "Edit Job";
  }
};

const getSubtitle = (action: ModalAction) => {
  switch (action) {
    case "create":
      return "Jobs on Taktile help you automate batch decisions for efficient and scalable decisioning.";
    case "duplicate":
      return "Add the details for a new Job";
    case "update":
      return "Edit the Job details.";
  }
};

const getButtonCopy = (action: ModalAction) => {
  switch (action) {
    case "create":
      return "Create Job";
    case "duplicate":
      return "Duplicate Job";
    case "update":
      return "Update Job";
  }
};
