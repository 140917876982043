import { faChartSimple } from "@fortawesome/pro-regular-svg-icons";
import { useMemo } from "react";

import { AnalyticsChart } from "src/analytics/AnalyticsChart";
import { NodeChartModal } from "src/analytics/NodeChartModal";
import { NodeAnalyticsData, useNodeAnalyticsData } from "src/analytics/api";
import { AddChartButton } from "src/analytics/common/AddChartButton";
import { SortableWrapper } from "src/analytics/common/SortableWrapper";
import {
  useDeleteNodeChart,
  useNodeCharts,
  useReorderNodeCharts,
} from "src/analytics/queries";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { EmptyState } from "src/base-components/EmptyState";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { Chart } from "src/clients/flow-api";
import { BeMappedNode } from "src/constants/NodeDataTypes";
import { useModal } from "src/design-system/Modal";
import { useCapabilities } from "src/hooks/useCapabilities";
import * as logger from "src/utils/logger";
import { toastError } from "src/utils/toastError";

export const InsightsPane: React.FC<{ node: BeMappedNode }> = ({ node }) => {
  const { flowCharts } = useCapabilities();
  const { data: charts, isLoading: isChartsLoading } = useNodeCharts(node.id);

  const {
    data: analytics,
    isLoading,
    isFetching,
  } = useNodeAnalyticsData(node.id);

  const disabledAddChartButton = analytics?.type !== "success";

  const {
    isOpen: isChartFormModalOpen,
    data: chart,
    openModal: openChartFormModal,
    closeModal: closeChartFormModal,
    afterLeave: afterLeaveChartFormModal,
  } = useModal<Chart>();

  const {
    isOpen: isDeleteChartModalOpen,
    data: chartToDelete,
    openModal: openDeleteChartModal,
    closeModal: closeDeleteChartModal,
    afterLeave: afterLeaveDeleteChartModal,
  } = useModal<Chart>();

  return (
    <div className="h-full p-6" data-loc="insights-pane">
      {isChartsLoading ? (
        <ChartPlaceholder />
      ) : !isChartsLoading && charts?.length === 0 ? (
        <EmptyState
          action={
            flowCharts.canCreate && (
              <AddChartButton
                key="add-chart-button"
                disabled={disabledAddChartButton}
                loading={isFetching && isLoading}
                onClick={() => openChartFormModal()}
              />
            )
          }
          dataLoc="node-analytics-empty-state"
          description="Create charts to visualize node data from this Decision Flow. Charts you create will be added to this pane."
          headline="No charts created yet"
          icon={faChartSimple}
        />
      ) : (
        <div className="flex flex-col gap-y-4">
          <Charts
            analytics={analytics}
            charts={charts || []}
            isLoading={isFetching && isLoading}
            nodeId={node.id}
            onDeleteClick={openDeleteChartModal}
            onEditClick={openChartFormModal}
          />
          <AddChartButton
            disabled={disabledAddChartButton}
            loading={isFetching && isLoading}
            size="sm"
            onClick={() => openChartFormModal()}
          />
        </div>
      )}
      <NodeChartModal
        afterLeave={afterLeaveChartFormModal}
        analytics={analytics}
        chart={chart}
        isOpen={isChartFormModalOpen}
        nodeId={node.id}
        nodeName={node.data.label}
        onClose={closeChartFormModal}
      />
      <DeleteChartModal
        afterLeave={afterLeaveDeleteChartModal}
        chart={chartToDelete}
        isOpen={isDeleteChartModalOpen}
        onClose={closeDeleteChartModal}
      />
    </div>
  );
};

const DeleteChartModal: React.FC<{
  isOpen: boolean;
  chart?: Nullable<Chart>;
  onClose: () => void;
  afterLeave: () => void;
}> = ({ chart, isOpen, onClose, afterLeave }) => {
  const { mutateAsync: deleteChart } = useDeleteNodeChart();

  const handleDelete = async () => {
    if (!chart) return;

    try {
      await deleteChart({ chartId: chart.id, resourceId: chart.resource_id });
      onClose();
    } catch (e) {
      logger.log(e);
      toastError("Failed to delete chart");
    }
  };

  return (
    <ConfirmationModal
      afterLeave={afterLeave}
      confirmationButtonText="Delete chart"
      confirmationButtonType="warning"
      open={isOpen}
      title={`Delete the "${chart?.title}" chart?`}
      onClose={onClose}
      onConfirm={handleDelete}
    >
      <div className="mb-6 mt-4 text-gray-500 font-inter-normal-12px">
        Are you sure you want to delete the "{chart?.title}" chart? This will
        delete it from all versions of this flow. This action cannot be undone.
      </div>
    </ConfirmationModal>
  );
};

const Charts = ({
  analytics,
  charts,
  isLoading,
  onEditClick,
  onDeleteClick,
  nodeId,
}: {
  analytics: NodeAnalyticsData | undefined;
  charts: Chart[];
  isLoading: boolean;
  onEditClick: () => void;
  onDeleteClick: () => void;
  nodeId: string;
}) => {
  const reorderCharts = useReorderNodeCharts(nodeId);

  const order = useMemo(() => {
    return charts.map((c) => c.id);
  }, [charts]);

  const isSuccess = analytics?.type === "success";

  return (
    <SortableWrapper order={order} onChangeOrder={reorderCharts.mutateAsync}>
      {(chartsOrder) => {
        return (
          <div className="flex flex-col gap-y-4">
            {chartsOrder.map((chartId, i) => {
              const chart = charts.find((c) => c.id === chartId);

              return chart ? (
                <AnalyticsChart
                  key={chart.id}
                  chart={chart}
                  data={isSuccess ? analytics.data : undefined}
                  dataLoc={`analytics-chart-${i}`}
                  isLoading={isLoading}
                  keys={isSuccess ? analytics.keys : []}
                  onDelete={onDeleteClick}
                  onEdit={onEditClick}
                />
              ) : null;
            })}
          </div>
        );
      }}
    </SortableWrapper>
  );
};

const ChartPlaceholder = () => (
  <div className="flex flex-col gap-y-2.5 rounded-lg border border-gray-200 bg-white px-4 pb-2.5 pt-3">
    <SkeletonPlaceholder height="h-5" width="w-3/4" />
    <div className="aspect-video w-full">
      <SkeletonPlaceholder height="h-full" width="w-full" />
    </div>
  </div>
);
