import React, { forwardRef, useImperativeHandle, useEffect } from "react";
import { twJoin } from "tailwind-merge";
import { useEventListener } from "usehooks-ts";

type Props = Pick<
  React.HTMLProps<HTMLDivElement>,
  "contentEditable" | "className" | "onBlur"
> & {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  minHeight?: number;
  tabSize?: number;
};

export const EditableDiv = React.memo(
  forwardRef<HTMLDivElement, Props>(
    (
      {
        value: propsValue,
        onChange: propsOnChange,
        className,
        contentEditable,
        onBlur,
        placeholder,
        minHeight,
        tabSize,
      },
      ref,
    ) => {
      const [currentValue, setCurrentValue] = React.useState(propsValue);
      const divRef = React.useRef<HTMLDivElement>(null);
      const prevContentEditable = React.useRef<boolean>(
        Boolean(contentEditable),
      );

      const handleInput = (event: Event) => {
        if (event.target) {
          setCurrentValue((event.target as HTMLDivElement).innerText || "");
        }
      };

      useEventListener("input", handleInput, divRef);

      useEffect(() => {
        if (!divRef.current || divRef.current === document.activeElement) {
          return;
        }

        setCurrentValue(propsValue);
        divRef.current.textContent = propsValue;
      }, [propsValue]);

      useEffect(() => {
        const contentEditableBoolean = Boolean(contentEditable);
        // When contentEditable changes from tru to false
        // We force the value to be updated
        if (
          prevContentEditable.current !== contentEditableBoolean &&
          contentEditableBoolean === false &&
          currentValue !== propsValue
        ) {
          propsOnChange(currentValue);
        }
        prevContentEditable.current = contentEditableBoolean;
      }, [contentEditable, propsOnChange, propsValue, currentValue]);

      useImperativeHandle<Nullable<HTMLDivElement>, Nullable<HTMLDivElement>>(
        ref,
        () => divRef.current,
      );

      return (
        <div
          ref={divRef}
          className={twJoin(
            "focusable",
            !currentValue &&
              "before:text-gray-400 before:content-[attr(data-placeholder)]",
            className,
          )}
          contentEditable={contentEditable}
          data-placeholder={placeholder}
          spellCheck="false"
          style={{ wordBreak: "break-word", minHeight, tabSize }}
          tabIndex={-1}
          onBlur={onBlur}
        />
      );
    },
  ),
);
