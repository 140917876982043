import {
  faCircleInfo,
  faClock,
  faList,
  faRotateRight,
} from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { Modal } from "src/base-components/Modal";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/base-components/Tooltip";
import { ObjectDetailPane } from "src/dataTable/ObjectDetailPane";
import { classifyDelivery } from "src/webhooks/HistorySidebar/webhookUtils";
import {
  OutgoingWebhookTestDetailType,
  OutgoingWebhookResponse,
} from "src/webhooks/queries";

type Props = {
  response: OutgoingWebhookResponse | undefined;
  attempt_time?: Date;
  isOpen: boolean;
  onClose: () => void;
  retryWebhook?: (webhook: OutgoingWebhookResponse) => void;
  setSearchTerm?: (term: string) => void;
};

export const HistoryDetailModal: React.FC<Props> = ({
  isOpen,
  response,
  attempt_time,
  onClose,
  retryWebhook,
  setSearchTerm,
}) => {
  const requestType =
    response?.request_body?.type === OutgoingWebhookTestDetailType
      ? "Test"
      : response?.request_body?.event?.environment === "sandbox"
        ? "Sandbox"
        : "Live";
  const responseCode = response ? response.status : 500;
  const status = classifyDelivery(
    attempt_time ? new Date(attempt_time) : undefined,
    response,
  );
  return (
    <Modal
      className="w-[81rem]"
      headerClassName="pb-3 border-b border-gray-100"
      open={isOpen}
      title="Log details"
      closeIcon
      onClose={onClose}
    >
      <div className="flex gap-x-6 px-6 pb-6 pt-4">
        <div className="flex w-full flex-col gap-y-4">
          <h4 className="font-inter-semibold-13px">
            <span className="pr-2">Request</span>
            <Pill variant="outlined-white">
              <Pill.Text>{requestType}</Pill.Text>
            </Pill>
          </h4>

          <h5 className="flex items-center gap-x-2 font-inter-medium-12px">
            Headers
            <Tooltip
              placement="bottom"
              title="Auth-related headers are omitted."
              asChild
            >
              <span>
                <Icon color="text-gray-500" icon={faCircleInfo} />
              </span>
            </Tooltip>
          </h5>
          <ObjectDetailPane
            dataLoc="request-headers"
            innerDimensionClass="h-52"
            jsonObject={response?.request_headers || {}}
          />

          <h5 className="font-inter-medium-12px">Body</h5>
          <ObjectDetailPane
            dataLoc="request-body"
            innerDimensionClass="h-52"
            jsonObject={response?.request_body || {}}
          />
        </div>

        <div className="flex w-full flex-col gap-y-4">
          <h4 className="font-inter-semibold-13px">
            <span className="pr-2">Response</span>

            <Tooltip
              activated={status.tooltipBody !== undefined}
              body={status.tooltipBody}
              placement="top"
              title={status.tooltipTitle}
            >
              <Pill
                dataLoc={`status-code-${responseCode}`}
                variant={status.pillVariant}
              >
                <Pill.Icon icon={status.pillIcon}></Pill.Icon>
                <Pill.Text>{status.pillText}</Pill.Text>
                {status.willRetry && <Pill.Icon icon={faClock}></Pill.Icon>}
              </Pill>
            </Tooltip>
          </h4>

          <h5 className="flex h-6 items-center font-inter-medium-12px">
            Headers
          </h5>
          <ObjectDetailPane
            innerDimensionClass="h-52"
            jsonObject={response?.response_headers ?? {}}
          />

          <h5 className="font-inter-medium-12px">Body</h5>
          <ObjectDetailPane
            innerDimensionClass="h-52"
            jsonObject={response?.response_body ?? {}}
          />
        </div>
      </div>
      <div className="flex items-center justify-between border-t border-gray-100 bg-white px-6 py-4">
        {retryWebhook && response && (
          <Button
            iconLeft={faRotateRight}
            variant="secondary"
            onClick={() => {
              onClose();
              retryWebhook(response);
            }}
          >
            Retry
          </Button>
        )}
        <span className="w-4"></span>
        {setSearchTerm && response?.request_body?.event?.decision_id && (
          <Button
            iconLeft={faList}
            variant="secondary"
            onClick={() => {
              onClose();
              setSearchTerm(response?.request_body?.event?.decision_id);
            }}
          >
            View related deliveries
          </Button>
        )}
        <span className="mx-auto"></span>

        <Button variant="primary" onClick={onClose}>
          Done
        </Button>
      </div>
    </Modal>
  );
};
