import { faCube } from "@fortawesome/pro-regular-svg-icons";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { Link, Outlet, useMatch } from "react-router-dom";

import { FlowT } from "src/api/flowTypes";
import { useFlow, useWorkspace } from "src/api/queries";
import { Button } from "src/base-components/Button";
import { EmptyState } from "src/base-components/EmptyState";
import { LoadingView } from "src/base-components/LoadingView";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { NavSidebar } from "src/flow/NavSidebar";
import { DashboardContent } from "src/layout/DashboardContent";
import { LayoutHeader } from "src/layout/Header";
import {
  FlowPageParamsT,
  getFlowVersionsUrl,
  getUrlToWsDashboard,
} from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

const HeaderV2: React.FC<{ flow?: FlowT }> = ({ flow }) => {
  const { orgId, wsId } = useParamsDecode<FlowPageParamsT>();

  return (
    <LayoutHeader
      breadcrumbs={[
        {
          key: "flow-name",
          breadcrumb: (
            <LayoutHeader.Breadcrumb
              dataLoc="flow-name"
              href={getFlowVersionsUrl(orgId, wsId, flow?.id ?? "")}
              label={flow?.name ?? ""}
            />
          ),
        },
      ]}
    />
  );
};

export type FlowPageContext = {
  flow: FlowT;
  workspace: WorkspaceDataplane;
  orgId: string;
};

export type FlowPageContextNoData = Partial<FlowPageContext>;

export const FlowPage: React.FC = () => {
  const { flow_id, wsId, orgId } = useParamsDecode<FlowPageParamsT>();
  const flow = useFlow(flow_id, {
    refetchOnMount: true,
  });
  const workspace = useWorkspace(wsId);
  const isVersionsPage = useMatch("/org/:orgId/ws/:wsId/flows/:flow_id");

  const isReviewCasePage = useMatch(
    "/org/:orgId/ws/:wsId/flows/:flow_id/review-queue/:case_id",
  );
  const isJobPage = useMatch(
    "/org/:orgId/ws/:wsId/flows/:flow_id/jobs/:job_id",
  );

  if (flow.isError) {
    return (
      <div className="flex w-full items-center justify-center">
        <EmptyState
          action={
            <Link to={getUrlToWsDashboard({ orgId, wsId, page: "flows" })}>
              <Button iconLeft={faArrowLeft} variant="secondary">
                Go to Home Page
              </Button>
            </Link>
          }
          description="To see all Decision Flows, please go to the Home page."
          headline="This Decision Flow was not found"
          icon={faCube}
        />
      </div>
    );
  }

  const isNoDashboardPage = isReviewCasePage || isJobPage;

  return isNoDashboardPage ? (
    <LoadingView
      queryResult={[flow, workspace]}
      renderUpdated={([flow, workspace]: [FlowT, WorkspaceDataplane]) => (
        <Outlet context={{ flow, workspace, orgId }} />
      )}
    />
  ) : (
    <DashboardContent
      Header={<HeaderV2 flow={flow.data} />}
      sidebar={<NavSidebar flow={flow.data} />}
    >
      <LoadingView
        queryResult={[flow, workspace]}
        renderUpdated={([flow, workspace]: [FlowT, WorkspaceDataplane]) => (
          <Outlet context={{ flow, workspace, orgId }} />
        )}
        renderUpdating={() =>
          isVersionsPage ? <Outlet context={{}} /> : <></>
        }
      />
    </DashboardContent>
  );
};
