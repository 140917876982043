import { endOfDay, startOfDay } from "date-fns";
import { useState } from "react";
import { DateRange } from "react-day-picker";
import { useSessionStorage } from "usehooks-ts";

export type StrictDateRange = {
  from: Date;
  to: Date;
};

/*
This is a helper to make the state management of the
date range picker easier. With that component its possible
to have an "incomplete" state (the user has selected the start but not the end i.e.)
which is unconvenient for the query consuming the state.

In this hook we manage two states:
1. Picker value: The state of the UI component
2. Time window: What the query consumes, always of type StrictDateRange
*/
export const useTimeWindow = (
  defaultTimeWindow: StrictDateRange,
  sessionStorageKey: string,
) => {
  const [timeWindow, _setTimeWindow] = useSessionStorage<StrictDateRange>(
    sessionStorageKey,
    defaultTimeWindow,
    {
      serializer: (value) => JSON.stringify(value),
      deserializer: (value) => {
        const parsedObject = JSON.parse(value);
        return {
          from: new Date(parsedObject.from),
          to: new Date(parsedObject.to),
        };
      },
    },
  );

  const [dateRangePickerValue, _setPickerValue] = useState<
    DateRange | undefined
  >(timeWindow);

  const onDateRangePickerChange = (value?: DateRange) => {
    // Always update the UI state because the component
    // allows incomplete states
    _setPickerValue(value);

    // If the state is complete we change time window too
    if (!!value && !!value.from && !!value.to)
      _setTimeWindow({ from: startOfDay(value.from), to: endOfDay(value.to) });
  };

  return { timeWindow, dateRangePickerValue, onDateRangePickerChange };
};
