import { cloneDeep } from "lodash";

import { FlowT } from "src/api/flowTypes";
import { FlowDbShallow } from "src/clients/flow-api";
import { ItemsNonUndefined } from "src/utils/typeUtils";

export const singleFlowIsChildFlow = (flow: FlowT) => {
  return flow.versions.some((version) => {
    if (version.parent_flows === undefined)
      throw Error("Checking the wrong flow type");
    return version.parent_flows.length > 0;
  });
};

export const singleFlowGetParentFlows = (flow: FlowT) => {
  return flow.versions.flatMap((version) => {
    if (version.parent_flows === undefined)
      throw Error("Checking the wrong flow type");
    return version.parent_flows;
  });
};

export const singleFlowGetPotentialParentVersions = (flow: FlowT) => {
  return flow.versions.flatMap((version) => {
    if (version.parent_flows === undefined)
      throw Error("Checking the wrong flow type");
    const versionsIncludingUndefined = version.parent_flows.flatMap(
      (parentFlow) => parentFlow.versions,
    );
    return versionsIncludingUndefined.filter(
      (version) => version !== undefined,
    ) as ItemsNonUndefined<typeof versionsIncludingUndefined>;
  });
};

// Merge parent_flows to get a more convenient
// structure
export const getParentFlowsWithVersions = (flow: FlowT) => {
  const parentFlows: Record<string, FlowDbShallow> = {};
  for (const version of flow.versions) {
    if (version.parent_flows === undefined)
      throw Error("Checking the wrong flow type");

    for (const flow of version.parent_flows) {
      if (flow.id in parentFlows && flow.versions) {
        parentFlows[flow.id].versions?.push(flow.versions[0]);
      } else if (flow.versions) {
        parentFlows[flow.id] = cloneDeep(flow);
      }
    }
  }

  return Object.values(parentFlows);
};
