import { observer } from "mobx-react-lite";
import React, { MouseEvent, useState } from "react";
import { EdgeProps } from "reactflow";
import { twJoin } from "tailwind-merge";

import {
  getFlowEdgeCenter,
  getFlowEdgePath,
  getSplitEdgePath,
} from "../utils/EdgeUtils";
import AddEdgeIcon from "src/assets/AddEdgeIcon.svg?react";
import {
  HistoricalRunHighlightMarkerEndId,
  NodeAddingHighlightMarkerEndId,
} from "src/constants/DefaultValues";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { useEdgeIsPartOfSelectedResultsRow } from "src/flowContainer/AuthoringUIContext";
import { EDGE_TYPE, GroupEdgeData } from "src/flowGraph/EdgeTypes";
import { useCanAuthoringEditFlowVersion } from "src/hooks/useCanAuthoringEditFlowVersion";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useGraphStore } from "src/store/StoreProvider";
import { getEdgeWasPartOfHistoricalDecision } from "src/versionDecisionHistory/getEdgeWasPartOfHistoricalDecision";

const EDGE_BUTTON_SIZE: number = 28;

// This component is used for "standard" edges and group edges
const FlowEdge: React.FC<EdgeProps<GroupEdgeData>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  target,
  source,
  data,
  style = {},
  markerEnd,
}) => {
  const {
    clickedVisibleEdgeId,
    setClickedVisibleEdgeId,
    nodes,
    edges,
    readClipboard,
  } = useGraphStore();
  const { version } = useAuthoringContext();
  const canAddNode = useCanAuthoringEditFlowVersion();

  const [isHovered, setIsHovered] = useState(false);
  // If this is a group edge, we need the originalEdge
  const originalEdge =
    data?.originalEdgeId !== undefined
      ? edges.get(data?.originalEdgeId)
      : undefined;
  const targetNode = nodes.get(target);
  const edgeWasPartOfHistoricalRun = getEdgeWasPartOfHistoricalDecision(
    originalEdge ? nodes.get(originalEdge.source) : nodes.get(source),
    originalEdge ? nodes.get(originalEdge.target) : targetNode,
    version.id,
  );

  const edgeIsPartOfSelectedResultsRow = useEdgeIsPartOfSelectedResultsRow(
    originalEdge ? nodes.get(originalEdge.source)?.id : nodes.get(source)?.id,
    originalEdge ? nodes.get(originalEdge.target)?.id : targetNode?.id,
  );

  const edgeWasTraversed =
    edgeWasPartOfHistoricalRun || edgeIsPartOfSelectedResultsRow;

  const handleMouseEnter = () => {
    if (canAddNode) setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const edgePath = getFlowEdgePath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const [edgeCenterX, edgeCenterY] = getFlowEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const onEdgeClick = (e: MouseEvent) => {
    if (canAddNode) {
      setClickedVisibleEdgeId(id);
      // Read the clipboard to correctly display the preview but dont prompt the user if no permissions are given
      readClipboard({ promptUser: false });
      e.stopPropagation();
    }
  };

  const highlightedForNodeAdding = id === clickedVisibleEdgeId || isHovered;

  const renderAddNodeButton = () => {
    if (highlightedForNodeAdding) {
      return (
        <foreignObject
          height={EDGE_BUTTON_SIZE}
          requiredExtensions="http://www.w3.org/1999/xhtml"
          width={EDGE_BUTTON_SIZE}
          x={edgeCenterX - EDGE_BUTTON_SIZE / 2}
          y={edgeCenterY - EDGE_BUTTON_SIZE / 2}
        >
          <div
            className="flex h-full w-full cursor-pointer"
            data-loc="add-node-button"
            onClick={onEdgeClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <AddEdgeIcon height={EDGE_BUTTON_SIZE} width={EDGE_BUTTON_SIZE} />
          </div>
        </foreignObject>
      );
    }
  };

  const finalMarkerEnd =
    targetNode?.type === NODE_TYPE.SPLIT_MERGE_NODE
      ? undefined
      : highlightedForNodeAdding
        ? `url(#${NodeAddingHighlightMarkerEndId})`
        : edgeWasTraversed
          ? `url(#${HistoricalRunHighlightMarkerEndId})`
          : markerEnd;

  return (
    <>
      {/* Shadow behind edges highlighted for node adding */}
      {highlightedForNodeAdding && (
        <path
          className="react-flow__edge-path !stroke-indigo-200"
          d={edgePath.verticalPath}
          id={id}
          style={{
            strokeWidth: 3,
          }}
        />
      )}
      {/* Vertical edge path (highlighted for node adding) */}
      <path
        className={twJoin(
          "react-flow__edge-path pointer-events-none stroke-1",
          !edgeWasTraversed && !highlightedForNodeAdding && "!stroke-gray-300",
          (edgeWasTraversed || highlightedForNodeAdding) &&
            "!stroke-indigo-500",
          !canAddNode && "cursor-grab",
        )}
        d={edgePath.verticalPath}
        data-loc={`visual-edge-${id}`}
        id={id}
        markerEnd={finalMarkerEnd}
        style={{
          ...style,
        }}
      />
      {/* Horizontal edge path (NOT highlighted for node adding) */}
      {edgePath.horizontalPath && (
        <path
          className={twJoin(
            "react-flow__edge-path pointer-events-none cursor-grab stroke-1",
            edgeWasTraversed ? "!stroke-indigo-500" : "!stroke-gray-300",
          )}
          d={edgePath.horizontalPath}
          data-loc={`visual-edge-${id}`}
          id={id}
          markerEnd={finalMarkerEnd}
          style={{
            ...style,
          }}
        />
      )}
      {/* Wider hover area following the horizontal edge. Triggers hover and click interactions*/}
      {canAddNode && (
        <path
          className="react-flow__edge-interaction"
          d={edgePath.verticalPath}
          data-loc={`edge-${id}`}
          fill="none"
          style={{
            // We have to set a color otherwise this does not work on safari
            stroke: "white",
            strokeWidth: 24,
            opacity: 0,
          }}
          onClick={onEdgeClick}
          onMouseEnter={handleMouseEnter}
          onMouseOut={handleMouseLeave}
        />
      )}
      {renderAddNodeButton()}
    </>
  );
};

const SplitEdge: React.FC<EdgeProps> = ({
  id,
  source,
  sourceX,
  sourceY,
  targetX,
  targetY,
  target,
  style = {},
}) => {
  const { selectedNode, nodes } = useGraphStore();
  const { version } = useAuthoringContext();
  const edgePath = getSplitEdgePath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });
  const isSelected = selectedNode?.id === source;
  const edgeWasPartOfHistoricalRun = getEdgeWasPartOfHistoricalDecision(
    nodes.get(source),
    nodes.get(target),
    version.id,
  );
  const edgeIsPartOfSelectedResultsRow = useEdgeIsPartOfSelectedResultsRow(
    nodes.get(source)?.id,
    nodes.get(target)?.id,
  );

  const edgeWasTraversed =
    edgeWasPartOfHistoricalRun || edgeIsPartOfSelectedResultsRow;

  return (
    <path
      className={twJoin(
        "react-flow__edge-path pointer-events-none cursor-grab stroke-1",
        !isSelected && !edgeWasTraversed && "!stroke-gray-300",
        (isSelected || edgeWasTraversed) && "!stroke-indigo-500",
      )}
      d={edgePath}
      data-loc={`visual-edge-${id}`}
      id={id}
      style={{ ...style }}
    />
  );
};

export const EDGES: Record<EDGE_TYPE, React.ComponentType<EdgeProps<any>>> = {
  flow_edge: observer(FlowEdge),
  split_edge: observer(SplitEdge),
  group_edge: observer(FlowEdge),
};
