import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/base-components/Tooltip";

type HelpButtonProps = {
  url: string;
};

export const HelpButton: React.FC<HelpButtonProps> = ({ url }) => {
  return (
    <Tooltip align="center" placement="top" title="View documentation">
      <a href={url} rel="noreferrer" target="_blank">
        <Icon
          color="text-gray-500 hover:text-gray-700"
          icon={faQuestionCircle}
          size="xs"
        />
      </a>
    </Tooltip>
  );
};
