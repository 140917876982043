import { useFormContext } from "react-hook-form";

import { SecretField } from "src/connections/config/shared/SecretField";
import {
  PostgreSQLConnectionConfigInputsT,
  PostgreSQLConnectionAuthConfigFieldT,
  Environment,
} from "src/connections/types";

type SecretPropsT = {
  secretConfigField: PostgreSQLConnectionAuthConfigFieldT;
  secretName: string;
  helpText: string;
  isRequired?: boolean;
  isErrored: boolean;
  environment: Environment;
};

export const PostgreSQLSecretField: React.FC<SecretPropsT> = ({
  secretConfigField,
  secretName,
  helpText,
  isRequired,
  isErrored,
  environment,
}) => {
  const { watch, setValue, register } =
    useFormContext<PostgreSQLConnectionConfigInputsT>();

  const secretKey = `${environment}Config.${secretConfigField}` as const;

  const secretRecord = watch(secretKey);

  const enableSecret = () => {
    setValue(secretKey, { ...secretRecord, secret: false, value: null });
  };

  return (
    <SecretField
      enableSecret={enableSecret}
      helpText={helpText}
      isErrored={isErrored}
      isRequired={isRequired}
      isSecret={Boolean(secretRecord.secret)}
      register={register}
      secretKey={`${secretKey}.value`}
      secretName={secretName}
    />
  );
};
