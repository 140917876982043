import { DiffRevisionProps } from "src/changeHistory/DiffViewModal/DiffViewModal";
import { editorWrapperClassName } from "src/changeHistory/DiffViewModal/NodeDiffView";
import { useDiffHighlighting } from "src/changeHistory/DiffViewModal/highlightDiffs";
import { ParametersList } from "src/globalParameters/GlobalParametersList";

export const ParamsDiffView: React.FC<DiffRevisionProps> = ({
  change,
  previouseRevision,
}) => {
  useDiffHighlighting("params-diff-previous", "params-diff-after");
  const originalParams = previouseRevision.parameters;
  const changedParams = change?.diff?.parameters;

  return (
    <div className="w-full px-6 pt-4">
      <div className="flex h-full w-full flex-row justify-between gap-x-6">
        <div
          className={editorWrapperClassName}
          data-loc="params-diff-previous"
          id="params-diff-previous"
        >
          {originalParams && (
            <div className="h-full pb-4">
              <ParametersList
                analyzeUsage={undefined}
                params={originalParams}
                versionId={change.flow_version_id}
                isReadonly
              />
            </div>
          )}
        </div>
        <div
          className={editorWrapperClassName}
          data-loc="params-diff-after"
          id="params-diff-after"
        >
          {changedParams && (
            <div className="h-full pb-4">
              <ParametersList
                analyzeUsage={undefined}
                params={changedParams}
                versionId={change.flow_version_id}
                isReadonly
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
