import { makeConnectionTemplate } from "src/connections/types";

export const CRIFHighMarkTemplate = makeConnectionTemplate({
  provider: "crif_high_mark",
  secrets: [
    { key: "user_id", name: "User ID", required: true },
    { key: "password", name: "Password", required: true },
  ],
  resources: {
    credit_report: {
      name: "Credit Report",
      configKeys: [
        {
          key: "merchant_id",
          name: "Merchant ID",
          type: "text",
          required: true,
        },
        {
          key: "product_id",
          name: "Product ID",
          type: "text",
          required: true,
        },
        {
          key: "app_id",
          name: "App ID",
          type: "text",
          required: true,
        },
      ],
    },
  },
});
