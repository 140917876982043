import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import {
  DropDown,
  DropDownElementT,
  RenderValueFn,
} from "src/base-components/DropDown";
import { Icon } from "src/base-components/Icon";

const generateDropDownElements = (min: number, max: number) => {
  const elements: DropDownElementT<string>[] = [];
  for (let i = min; i <= max; i++) {
    let suffix = "seconds";
    if (i === 1) {
      suffix = "second";
    }
    elements.push({ key: `${i}`, value: `${i} ${suffix}` });
  }
  return elements;
};

// When changing these values be sure to check the default values of nodes and keep backward compability in mind!
const timeoutDropDownElements: DropDownElementT<string>[] =
  generateDropDownElements(1, 30);

type PropsT = {
  dataLoc: string;
  timeout_seconds: string;
  setTimeoutSeconds: (timeout_seconds: string) => void;
  immutable: boolean;
};

const renderValue: RenderValueFn<string> = ({ value }) => (
  <div className="w-44 px-4 py-2">{value}</div>
);

const renderButtonValue = (value?: string) => (
  <div className="flex flex-grow items-center justify-between py-2.5 pl-4 pr-3">
    <div className="mr-0.5 grow text-left text-gray-800">{value}</div>
    <div className="flex h-auto items-center">
      <Icon color="text-gray-500" icon={faChevronDown} size="xs" />
    </div>
  </div>
);

export const TimeoutDropDown: React.FC<PropsT> = ({
  dataLoc,
  timeout_seconds,
  setTimeoutSeconds,
  immutable,
}) => {
  return (
    <DropDown
      className="w-96"
      dataLoc={dataLoc}
      disabled={immutable}
      elements={timeoutDropDownElements}
      itemsClassNames="w-full overflow-y-auto max-h-80 decideScrollbar"
      renderButtonValue={renderButtonValue}
      renderValue={renderValue}
      selectedKey={timeout_seconds}
      onSelect={setTimeoutSeconds}
    />
  );
};
