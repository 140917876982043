import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/base-components/Tooltip";

type Props = {
  children: React.ReactNode;
  error?: string;
  className?: string;
  dataLoc?: string;
};

export const TableCellWrapper: React.FC<Props> = ({
  className,
  children,
  error,
  dataLoc,
}) => (
  <div
    className={twJoin("relative flex h-full w-full", className)}
    data-loc={dataLoc}
  >
    {children}
    {error && (
      <Tooltip placement="top" title={error}>
        <div className="mr-1">
          <Icon color="text-red-600" icon={faWarning} />
        </div>
      </Tooltip>
    )}
  </div>
);
