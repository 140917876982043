import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { ErrorBaseInfo } from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { InformationPill } from "src/base-components/InformationPill";
import { Tooltip } from "src/base-components/Tooltip";
import { DatabaseConnectionForm } from "src/databaseConnectionNode/types";
import { CodeNodeEditor } from "src/nodeEditor/CodeNodeEditor";

type PropsT = {
  immutable: boolean;
  isReactive: boolean;
  displayedError?: ErrorBaseInfo;
};

// TODO: INT-1714 use SQL parser to determine if query is dangerous
// For now using a ChatGPT-generated list of dangerous keywords
const DangerousKeywords = [
  "alter",
  "create",
  "drop",
  "truncate",
  "delete",
  "update",
  "replace",
  "grant",
  "revoke",
  "rename",
  "call",
  "merge",
  "set",
  "start transaction",
  "commit",
  "rollback",
  "savepoint",
  "release",
  "copy",
  "vacuum",
  "analyze",
  "cluster",
  "reindex",
  "load",
  "unload",
  "backup",
  "restore",
];

export const isDangerousQuery = (query: string) => {
  return DangerousKeywords.some((keyword) =>
    query?.toLowerCase().includes(keyword),
  );
};

const ExampleQueriesTooltipBody: React.FC = () => (
  <div>
    Once you have introduced a variable (e.g., <code>$name</code>) in the
    Variables section above, you can reference it within the SQL query using the
    Dollar sign (<code>$</code>) prefix:
    <br />
    <br />
    <code className="whitespace-pre">
      {`SELECT *
FROM my_table
WHERE name = $name;`}
    </code>
  </div>
);

export const QueryEditor: React.FC<PropsT> = ({
  immutable,
  isReactive,
  displayedError,
}) => {
  const { setValue, watch } = useFormContext<DatabaseConnectionForm>();
  const src = watch("query.expression");

  const isDangerous = useMemo(() => isDangerousQuery(src), [src]);

  return (
    <>
      <span className="text-gray-500 font-inter-normal-12px">
        You can embed variables within your SQL query using{" "}
        <span className="font-fira-code rounded bg-gray-100 px-1">$</span>{" "}
        notation
        <Tooltip
          align="center"
          body={<ExampleQueriesTooltipBody />}
          placement="top"
          title=""
        >
          <Icon
            color="text-gray-500"
            cursorType="pointer"
            icon={faQuestionCircle}
            size="xs"
          />
        </Tooltip>
      </span>
      <div className="mt-3 h-80">
        <CodeNodeEditor
          displayedError={displayedError}
          immutable={immutable}
          isReactive={isReactive}
          language="sql"
          src={src}
          onChange={(value) => {
            setValue("query.expression", value || "");
          }}
        />
      </div>
      {isDangerous && (
        <InformationPill className="mt-3" type="warning">
          Warning: This query could modify or delete data, or alter the
          database. Please verify its intent and ensure you have necessary
          backups to prevent unintended effects.
        </InformationPill>
      )}
    </>
  );
};
