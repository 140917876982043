import { Column } from "@tanstack/react-table";
import React, { forwardRef } from "react";

import { Checkbox } from "src/base-components/Checkbox";
import { Pill } from "src/base-components/Pill";
import {
  INTERMEDIATE_TABLE_PREFIXES,
  RESULT_COLUMN_PREFIX,
} from "src/dataTable/TablePrefixes";
import { ResultDataAndAuxRowV2 } from "src/dataTable/types";
import { useAuthoringContext } from "src/router/routerContextHooks";

const getNameFromColumnId = (id: string) => {
  const prefix = INTERMEDIATE_TABLE_PREFIXES.find((prefix) =>
    id.startsWith(prefix),
  );

  return prefix ? id.slice(prefix.length) : id;
};

const HighlightedText = ({
  text,
  highlight,
}: {
  text: string;
  highlight: string;
}) => {
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {parts.map((part, i) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={i} className="font-inter-semibold-13px">
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </span>
  );
};

type PropsT = {
  column: Column<ResultDataAndAuxRowV2>;
  query: string;
};

export const ResultColumnSelectorRow = forwardRef<HTMLLabelElement, PropsT>(
  ({ column, query }, ref) => {
    const { version } = useAuthoringContext();

    const columnName = getNameFromColumnId(column.id);

    const isResultColumn = column.id.startsWith(RESULT_COLUMN_PREFIX);
    const isInputSchemaColumn =
      isResultColumn &&
      Object.keys(version.input_schema?.properties ?? {}).includes(columnName);

    return (
      <label
        ref={ref}
        className="flex h-12 max-w-90 cursor-pointer flex-row items-center gap-x-2 px-4 py-2.5 hover:bg-gray-50"
        data-loc={`select-${columnName}`}
      >
        <Checkbox
          checked={column.getIsVisible()}
          onChange={() => column.toggleVisibility()}
        />
        <p className="truncate text-gray-800 font-inter-normal-13px">
          <HighlightedText highlight={query} text={columnName} />
        </p>
        {isInputSchemaColumn && (
          <Pill size="sm" variant="gray">
            <Pill.Text fontType="code">{"{}"}</Pill.Text>
          </Pill>
        )}
      </label>
    );
  },
);
