import { Plugin, Context } from "@segment/analytics-next";
import { PostHog, Properties } from "posthog-js";

import { DEMO_ORG_ID, TAKTILE_ORG_ID } from "src/constants/OrgIds";

// see https://github.com/PostHog/posthog-js/blob/master/src/extensions/segment-integration.ts
// and https://github.com/segmentio/analytics-next/tree/master/packages/browser#-plugins
export const createPosthogPlugin = (posthog: PostHog): Plugin => {
  const enrichEvent = (ctx: Context, eventName: string): Context => {
    if (
      !ctx.event.userId &&
      ctx.event.anonymousId !== posthog.get_distinct_id()
    ) {
      // This is our only way of detecting that segment's analytics.reset() has been called so we also call it
      posthog.reset();
    }
    if (ctx.event.userId && ctx.event.userId !== posthog.get_distinct_id()) {
      posthog.register({
        distinct_id: ctx.event.userId,
      });
      posthog.reloadFeatureFlags();
    }

    if (
      eventName === "$groupidentify" &&
      ctx.event.groupId &&
      ctx.event.traits
    ) {
      // send separate grouping event for posthog to pick
      // up the same user properties as analytics js
      posthog.group(ctx.event.traits.type || "unknown", ctx.event.groupId, {
        name: ctx.event.traits.name,
      });

      // Add a flag to the user event whenever
      // the user is part of the Taktile org
      const is_tktl_user = ctx.event.groupId
        ? [TAKTILE_ORG_ID, DEMO_ORG_ID].includes(ctx.event.groupId)
        : false;

      if (is_tktl_user) {
        posthog.people.set_once({
          is_tktl_user: true,
        });
      }
    }

    const additionalProperties = posthog._calculate_event_properties(
      // adds e.g. session-id to each event
      eventName,
      ctx.event.properties as Properties,
    );
    ctx.event.properties = Object.assign(
      {},
      additionalProperties,
      ctx.event.properties,
    );

    return ctx;
  };

  return {
    name: "PostHog JS",
    type: "enrichment",
    version: "1.0.0",
    isLoaded: () => true,
    load: () => Promise.resolve(),
    track: (ctx) => enrichEvent(ctx, "$track"),
    page: (ctx) => enrichEvent(ctx, "$pageview"),
    identify: (ctx) => enrichEvent(ctx, "$identify"),
    screen: (ctx) => enrichEvent(ctx, "$screen"),
    group: (ctx) => enrichEvent(ctx, "$groupidentify"),
  };
};
