import React from "react";

import { EditorCellProps } from "src/base-components/EditorTable/types";

type Props<T> = EditorCellProps<T, string>;

const StringRendererComp = <T,>({ value }: Props<T>) => {
  return (
    <div className="flex h-full w-full items-center break-all border-0 p-2">
      {value}
    </div>
  );
};

export const StringRenderer = React.memo(
  StringRendererComp,
) as typeof StringRendererComp;
