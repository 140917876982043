import { useSearchParams } from "react-router-dom";

import { DecisionEnvironment } from "src/api/types";
import { FlowVersionStatus } from "src/clients/flow-api";
import { DecisionHistoryKeys } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";

export const useDecisionHistoryPaneEnv = () => {
  const { version } = useAuthoringContext();
  const [searchParams] = useSearchParams();

  const defaultEnv =
    version.status === FlowVersionStatus.PUBLISHED
      ? DecisionEnvironment.LIVE
      : DecisionEnvironment.SANDBOX;
  const selectedTab =
    searchParams.get(DecisionHistoryKeys.DecisionHistoryView) || defaultEnv;

  return {
    selectedEnv:
      selectedTab === DecisionEnvironment.SANDBOX
        ? DecisionEnvironment.SANDBOX
        : DecisionEnvironment.LIVE,
    defaultEnv,
  };
};
