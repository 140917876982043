import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { useWorkspaceUsers } from "src/api/taktile/queries";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { Select } from "src/base-components/Select";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import { Switch } from "src/base-components/Switch";
import { UserAvatar } from "src/base-components/UserAvatar/UserAvatar";
import { RadioLabel } from "src/flowReview/ReviewProcessPane";
import { DecisionFlowInputs } from "src/flowsOverview/types";
import { DashboardPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";
import { getUserName } from "src/utils/user";

type ConfigOptionProps = {
  title: string;
  description: string;
  onChange: (value: boolean) => void;
  value: boolean;
};

const ConfigOption: React.FC<ConfigOptionProps> = ({
  title,
  description,
  onChange,
  value,
}) => {
  return (
    <div className="flex gap-x-2">
      <div>
        <h3 className="mb-0.5 text-gray-800 font-inter-semibold-13px">
          {title}
        </h3>
        <p className="text-gray-500 font-inter-normal-12px">{description}</p>
      </div>
      <Switch
        dataLoc="require-review-toggle"
        enabled={value}
        onChange={onChange}
      />
    </div>
  );
};

export const FlowReviewConfigEditor: React.FC = () => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const { data: users } = useWorkspaceUsers(orgId, wsId, {
    include_deactivated: false,
  });
  const { watch, trigger } = useFormContext<DecisionFlowInputs>();

  const usersOptions =
    users?.map((user) => ({
      key: user.id,
      searchText: getUserName(user),
      value: (
        <span className="flex items-center gap-x-2">
          <UserAvatar size="xs" user={user} />
          {getUserName(user)}
        </span>
      ),
    })) ?? [];

  const defaultReviewers = watch("reviewConfig.default_reviewer_list");
  const requiresReview = watch("reviewConfig.requires_review");

  // Trigger validation on reviewers when requiresReview is enabled - need to be done manually
  useEffect(() => {
    if (requiresReview) {
      trigger("reviewConfig.default_reviewer_list");
    }
  }, [requiresReview, trigger]);

  return (
    <div className="mt-4 flex flex-col gap-y-4 border-t border-t-gray-100 pt-4">
      <Controller
        defaultValue={false}
        name="reviewConfig.requires_review"
        render={({ field }) => (
          <ConfigOption
            description="When enabled, versions of this Decision Flow will require approval from reviewers before they can be published."
            title="Require review before publishing"
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      {requiresReview && (
        <div className="flex flex-col">
          <h3 className="mb-1 text-gray-800 font-inter-semibold-13px">
            Select reviewers <RequiredAsterisk />
          </h3>
          <p className="mb-2 text-gray-500 font-inter-normal-12px">
            Approval from selected reviewer(s) is needed to publish the
            versions.
          </p>
          <Controller
            defaultValue={[] as string[]}
            name="reviewConfig.default_reviewer_list"
            render={(props) => (
              <Select
                dataLoc="reviewers-select"
                errored={
                  props.fieldState.isDirty && Boolean(props.fieldState.error)
                }
                options={usersOptions}
                placeholder="Select reviewer(s)"
                searchPlaceholder="Search user..."
                value={props.field.value}
                clearAfterClose
                multiple
                searchable
                onChange={props.field.onChange}
              />
            )}
            rules={{ required: true, minLength: 1 }}
          />
        </div>
      )}
      {requiresReview && defaultReviewers && defaultReviewers.length > 1 && (
        <Controller
          defaultValue={true}
          name="reviewConfig.require_all_reviewers"
          render={({ field }) => (
            <SimpleRadioGroup
              orientation="vertical"
              value={`${field.value}`}
              onValueChange={(value) => field.onChange(value === "true")}
            >
              <SimpleRadioGroup.Item
                label={
                  <RadioLabel
                    description="Allow users to publish versions only if all selected reviewers have given their approval."
                    label="All reviewers"
                  />
                }
                value="true"
                alignTop
              />
              <SimpleRadioGroup.Item
                label={
                  <RadioLabel
                    description="Allow users to publish versions if approval is received from any of the selected reviewers."
                    label="At least one reviewer"
                  />
                }
                value="false"
                alignTop
              />
            </SimpleRadioGroup>
          )}
        />
      )}
    </div>
  );
};
