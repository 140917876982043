import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { useNodeHighlighting } from "src/store/NodeHighlighting";
import { pluralize } from "src/utils/stringUtils";

type Props = {
  usageData: string[];
  parameterName: string;
};

export const HighlightParametersToggleButton: React.FC<Props> = observer(
  ({ usageData, parameterName }) => {
    const {
      data: { highlightedParam },
      clearHighlighting,
      highlightNodes,
    } = useNodeHighlighting();

    const isHighlighted = highlightedParam === parameterName;

    // When the Parameter name changes we want to clear the highlighting
    useEffect(() => {
      if (isHighlighted) {
        clearHighlighting();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to run this when the Parameter name changes
    }, [parameterName, clearHighlighting]);

    // When the Parameter is deleted we want to clear the highlighting
    useEffect(() => {
      return clearHighlighting;
    }, [clearHighlighting]);

    const toggleHighlighting = () => {
      if (isHighlighted) {
        clearHighlighting();
      } else {
        highlightNodes(usageData, parameterName);
      }
    };

    return (
      <button
        className={twJoin(
          "mr-3 font-inter-normal-12px",
          isHighlighted ? "text-indigo-600" : "text-gray-500",
        )}
        data-loc={`highlight-${parameterName}-toggle-button`}
        disabled={usageData.length < 1}
        onClick={toggleHighlighting}
      >
        <div className="flex items-center">
          <div className="mr-2">
            <Icon
              color={!isHighlighted ? "text-gray-300" : "text-indigo-500"}
              icon={faEye}
              size="sm"
            />
          </div>
          {pluralize(usageData.length, "node")}
        </div>
      </button>
    );
  },
);
