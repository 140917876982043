import { ProviderT } from "src/api/connectApi/types";
import { getProviderName } from "src/connections/ProviderResourceProperties";
import {
  SIMPLE_CREATABLE_NODE_TYPES,
  NamedNodeTypeT,
  NODE_TYPE,
  ConnectNodeTypeT,
} from "src/constants/NodeTypes";
import { assertUnreachable } from "src/utils/typeUtils";

type NodePropertyT = {
  ADD_BUTTON_DESCRIPTION: string;
  DOCS_HELP_BUTTON?: string;
  IS_BETA?: boolean;
};

export const CREATABLE_NODE_PROPERTIES: {
  [key in (typeof SIMPLE_CREATABLE_NODE_TYPES)[number]]: NodePropertyT;
} = {
  code_node: {
    ADD_BUTTON_DESCRIPTION: "Transform data with Python code",
  },
  split_node_v2: {
    ADD_BUTTON_DESCRIPTION: "Split data based on conditions",
  },
  rule_node_v2: {
    ADD_BUTTON_DESCRIPTION: "Set values based on conditions",
  },
  ml_node: {
    ADD_BUTTON_DESCRIPTION: "Make predictions using machine learning",
  },
  decision_table_node: {
    ADD_BUTTON_DESCRIPTION: "Express complex decisions in a tabular format",
    DOCS_HELP_BUTTON:
      "https://docs.taktile.com/fundamentals/node-types/decision-table-node",
  },
  assignment_node: {
    ADD_BUTTON_DESCRIPTION: "Assign, update, or calculate values",
  },
  scorecard_node: {
    ADD_BUTTON_DESCRIPTION: "Calculate score from weighted factors",
    DOCS_HELP_BUTTON:
      "https://docs.taktile.com/fundamentals/node-types/scorecard-node",
  },
  flow_node: {
    ADD_BUTTON_DESCRIPTION: "Call a Decision Flow version in your workspace",
  },
  loop_node: {
    ADD_BUTTON_DESCRIPTION: "Apply a Decision Flow to every item in a list",
  },
} as const;

type GetNodeTitleParamsT =
  | {
      nodeType: Exclude<
        NamedNodeTypeT,
        | NODE_TYPE.INTEGRATION_NODE
        | NODE_TYPE.MANIFEST_CONNECTION_NODE
        | NODE_TYPE.SQL_DATABASE_CONNECTION_NODE
      >;
    }
  | {
      nodeType: ConnectNodeTypeT;
      provider: ProviderT;
    };

export const getNodeTitle = (params: GetNodeTitleParamsT): string => {
  switch (params.nodeType) {
    case NODE_TYPE.SQL_DATABASE_CONNECTION_NODE:
    case NODE_TYPE.INTEGRATION_NODE: {
      return getProviderName(params.provider);
    }
    case NODE_TYPE.INPUT_NODE: {
      return "Input";
    }
    case NODE_TYPE.CODE_NODE: {
      return "Code";
    }
    case NODE_TYPE.OUTPUT_NODE: {
      return "Output";
    }
    case NODE_TYPE.SPLIT_BRANCH_NODE_V2: {
      return "Branch";
    }
    case NODE_TYPE.SPLIT_NODE_V2: {
      return "Split";
    }
    case NODE_TYPE.RULE_NODE_V2: {
      return "Rule";
    }
    case NODE_TYPE.ML_NODE: {
      return "Machine Learning";
    }
    case NODE_TYPE.CUSTOM_CONNECTION_NODE: {
      return "Custom Connection";
    }
    case NODE_TYPE.WEBHOOK_CONNECTION_NODE: {
      return "Inbound Webhook";
    }
    case NODE_TYPE.DECISION_TABLE_NODE: {
      return "Decision Table";
    }
    case NODE_TYPE.ASSIGNMENT_NODE: {
      return "Assignment";
    }
    case NODE_TYPE.SCORECARD_NODE: {
      return "Scorecard";
    }
    case NODE_TYPE.FLOW_NODE: {
      return "Decision Flow";
    }
    case NODE_TYPE.REVIEW_CONNECTION_NODE: {
      return "Manual Review";
    }
    case NODE_TYPE.LOOP_NODE: {
      return "Loop";
    }
    default:
      assertUnreachable(params);
      return "";
  }
};
