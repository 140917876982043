import { Switch as HeadlessSwitch } from "@headlessui2/react";
import React from "react";
import { twJoin } from "tailwind-merge";

type PropsT = {
  dataLoc?: string;
  enabled: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
};

export const Switch: React.FC<PropsT> = ({
  dataLoc,
  enabled,
  onChange,
  disabled = false,
}) => (
  <HeadlessSwitch
    checked={enabled}
    className={twJoin(
      "group relative inline-flex shrink-0 cursor-pointer items-center rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75",
      "bg-gray-200 data-[checked]:bg-indigo-600",
      "data-[disabled]:cursor-not-allowed",
      "h-4 w-7",
    )}
    data-loc={dataLoc}
    disabled={disabled}
    onChange={onChange}
  >
    <span
      className={twJoin(
        "inline-block transform rounded-full bg-white shadow-base transition-transform",
        "h-3 w-3",
        "group-data-[disabled]:bg-opacity-40",
        "translate-x-0.5 group-data-[checked]:translate-x-3.5",
      )}
    />
  </HeadlessSwitch>
);
