import { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
  useMemo,
  MutableRefObject,
  RefObject,
  useCallback,
  useLayoutEffect,
} from "react";
import { twJoin } from "tailwind-merge";
import { useDebouncedCallback } from "use-debounce";
import { useEventListener } from "usehooks-ts";

import { CodeInputProps } from "src/base-components/CodeInput/CodeInput";
import {
  AutocompleteCodeInput,
  AutocompleteCodeInputProps,
} from "src/base-components/CodeInput/EditorCodeInput";
import { EditableDiv } from "src/base-components/EditorTable/EditableDiv";
import { HEADER_ROW_ID } from "src/base-components/EditorTable/hooks/useSelectionHandler";
import {
  CellId,
  useCellIsSelected,
} from "src/base-components/EditorTable/stores";
import { dispatchSetEditorContents } from "src/base-components/EditorTable/utils";

type Rect = { width: number; height: number; top: number; left: number };
type InputProps = Pick<
  AutocompleteCodeInputProps,
  "disabled" | "value" | "bgColor" | "children" | "placeholder"
>;
type SetInputProps = InputProps &
  Pick<AutocompleteCodeInputProps, "onChange"> & { onBlur: () => void };

type AttachProps = {
  newCellId: CellId;
  cellElement: HTMLElement;
  currentValue: string;
  placeholder: AutocompleteCodeInputProps["placeholder"];
  bgColor: AutocompleteCodeInputProps["bgColor"];
  children: React.ReactNode;
  focus: boolean;
  fixedPosition: boolean;
  onChange: AutocompleteCodeInputProps["onChange"];
  onBlur: VoidFunction;
};

type ContextValue = {
  cellId: MutableRefObject<CellId | null>;
  focusEditor: (value: string) => void;
  attach: (props: AttachProps) => void;
  detach: VoidFunction;
  setPlaceholder: (
    placeholder: AutocompleteCodeInputProps["placeholder"],
  ) => void;
  setBgColor: (bgColor: AutocompleteCodeInputProps["bgColor"]) => void;
  setChildren: (children: AutocompleteCodeInputProps["children"]) => void;
};

const FloatingCodeEditorInputContext = createContext<ContextValue>({
  cellId: { current: null },
  attach: () => {},
  detach: () => {},
  setPlaceholder: () => {},
  setBgColor: () => {},
  setChildren: () => {},
  focusEditor: () => {},
});

const useDebouncedWindowEvents = ({
  onResize,
  onScroll,
  scrollElement,
}: {
  onResize: VoidFunction;
  onScroll: VoidFunction;
  scrollElement: RefObject<HTMLElement>;
}) => {
  const debouncedOnResize = useDebouncedCallback(onResize, 100);
  useEventListener("resize", debouncedOnResize);

  const debouncedSetScrollParentTop = useDebouncedCallback(onScroll, 250);

  useEventListener(
    "scroll",
    debouncedSetScrollParentTop,
    { current: scrollElement.current },
    true,
  );
};

export const FloatingCodeInputEditorProvider: React.FC<{
  dataLocPrefix: string;
  children: React.ReactNode;
  enabled?: boolean;
}> = ({ dataLocPrefix, enabled = false, children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollParentRef = useRef<HTMLElement | null>(null);
  const cellIdRef = useRef<CellId | null>(null);
  const cellElementRef = useRef<HTMLElement | null>(null);
  const isActive = useCellIsSelected(cellIdRef.current ?? "none_none");
  const onChangeRef = useRef<AutocompleteCodeInputProps["onChange"]>(() => {});
  const onBlurRef = useRef<VoidFunction>(() => {});

  const [scrollParentTop, setScrollParentTop] = useState(0);
  const [fixedPosition, setFixedPosition] = useState(false);
  const [boundingRect, setBoundingRect] = useState<Rect | null>(null);
  const [placeholder, setPlaceholder] =
    useState<AutocompleteCodeInputProps["placeholder"]>();
  const [value, setValue] = useState<AutocompleteCodeInputProps["value"]>("");
  const [bgColor, setBgColor] =
    useState<AutocompleteCodeInputProps["bgColor"]>();
  const [inputChildren, setInputChildren] =
    useState<AutocompleteCodeInputProps["children"]>();

  const editorRef = useRef<ReactCodeMirrorRef>(null);

  const setBounds = useCallback(() => {
    if (!cellElementRef.current || !containerRef.current) {
      return;
    }
    const rect = cellElementRef.current.getBoundingClientRect();
    setBoundingRect({
      width: rect.width,
      height: rect.height,
      top: cellElementRef.current.offsetTop - 4,
      left: cellElementRef.current.offsetLeft,
    });
  }, []);

  const memoizedContextValue: ContextValue = useMemo(
    () => ({
      cellId: cellIdRef,
      setPlaceholder,
      setBgColor,
      setChildren: setInputChildren,
      setBounds,
      focusEditor: (value: string) => {
        if (!editorRef.current?.view) {
          return;
        }
        if (!editorRef.current.view.hasFocus) {
          // dispatchFocusEditor(editorRef.current, value);
          dispatchSetEditorContents(editorRef.current, value, true);
        }
      },
      attach: ({
        newCellId,
        cellElement,
        currentValue,
        children,
        focus,
        placeholder,
        fixedPosition,
        bgColor,
        onChange,
        onBlur,
      }) => {
        onBlurRef.current?.();

        if (cellIdRef.current === newCellId) {
          return;
        }

        cellIdRef.current = newCellId;
        cellElementRef.current = cellElement;
        onBlurRef.current = onBlur;
        onChangeRef.current = onChange;

        setValue(currentValue);
        setInputChildren(children);
        setPlaceholder(placeholder);
        setBgColor(bgColor);
        setFixedPosition(fixedPosition);

        setBounds();

        setTimeout(() => {
          if (editorRef.current) {
            dispatchSetEditorContents(editorRef.current, currentValue, focus);

            if (!focus) {
              editorRef.current.view?.contentDOM.blur();
              containerRef.current?.focus();
            }
          }
        });
      },
      detach: () => {
        onBlurRef.current();

        cellIdRef.current = null;
        cellElementRef.current = null;
        onBlurRef.current = () => {};
        onChangeRef.current = () => {};

        setValue("");
        setPlaceholder(undefined);
        setBgColor(undefined);
        setInputChildren(undefined);
        setFixedPosition(false);

        if (editorRef.current) {
          dispatchSetEditorContents(editorRef.current, "", false);
          editorRef.current.view?.contentDOM.blur();
        }
      },
    }),
    [setBounds],
  );

  const handleChange = useCallback((value: string) => {
    onChangeRef.current?.(value);
  }, []);

  const handleBlur = useCallback(() => {
    onBlurRef.current?.();
  }, []);

  // Scroll parent needs to be set once everything is rendered
  // and the containerRef is available
  useLayoutEffect(() => {
    scrollParentRef.current =
      containerRef.current?.closest(".decideScrollbar") ?? null;
  }, []);

  // Attach debounced window events
  useDebouncedWindowEvents({
    scrollElement: scrollParentRef, // scroll event is attached to this element
    onResize: setBounds,
    onScroll: () => {
      if (!scrollParentRef.current) {
        return;
      }
      // need to track scrollParentTop to adjust the position of the floating editor
      // when the header is decision table header is sticky
      setScrollParentTop(scrollParentRef.current.scrollTop);
    },
  });

  if (!enabled) {
    return children;
  }

  return (
    <FloatingCodeEditorInputContext.Provider value={memoizedContextValue}>
      <div
        ref={containerRef}
        className={twJoin(
          "absolute flex items-center focus:outline-none",
          isActive ? "opacity-100" : "opacity-0",
          isActive ? (fixedPosition ? "z-[11]" : "z-10") : "-z-10",
        )}
        style={
          isActive && boundingRect
            ? {
                paddingLeft: 1,
                paddingTop: 1,
                paddingBottom: 1,
                paddingRight: 2,
                top:
                  boundingRect.top +
                  (fixedPosition ? (scrollParentTop ?? 0) : 0),
                left: boundingRect.left + 0.5,
                width: boundingRect.width,
                minHeight: boundingRect.height,
              }
            : { padding: 1, left: 0, top: 0, width: 150, height: 33 }
        }
        tabIndex={-1}
      >
        <div className="h-full min-h-[41px] w-full py-1 pr-2">
          <div className="py-1">
            <AutocompleteCodeInput
              ref={editorRef}
              bgColor={bgColor}
              containerDataLoc={`${dataLocPrefix}-cell-editor-container`}
              dataLoc={`${dataLocPrefix}-cell-editor`}
              placeholder={placeholder}
              plaintext={!isActive}
              value={value}
              variant="cell"
              onBlur={handleBlur}
              onChange={handleChange}
            >
              {inputChildren}
            </AutocompleteCodeInput>
          </div>
        </div>
      </div>
      {children}
    </FloatingCodeEditorInputContext.Provider>
  );
};

const newlinesMatcher = /\r\n|\r|\n/g;
const sanitizeValue = (value: string | undefined) => {
  return (value ?? "").replaceAll(newlinesMatcher, "");
};

export const FloatingEditorCodeInput: React.FC<
  Pick<
    CodeInputProps,
    "bgColor" | "children" | "disabled" | "placeholder" | "value" | "onChange"
  > & {
    cellId: CellId;
    cellRef: RefObject<HTMLElement>;
    isEditing: boolean;
    isSelected: boolean;
    hidden?: boolean;
    onBlur: SetInputProps["onBlur"];
  }
> = ({ isEditing, isSelected, cellId, cellRef, hidden = false, ...props }) => {
  const { value, disabled, onChange, onBlur, children, placeholder, bgColor } =
    props;
  const {
    cellId: editorCellId,
    attach,
    detach,
    focusEditor,
    setChildren,
    setBgColor,
    setPlaceholder,
  } = useContext(FloatingCodeEditorInputContext);
  const divRef = useRef<HTMLDivElement>(null);
  const [minHeight, setMinHeight] = useState<number | undefined>(undefined);
  const sanitizedValue = sanitizeValue(value);

  const onChangeRef = useRef(onChange);
  const onBlurRef = useRef(onBlur);

  const adjustMinHeight = useCallback(() => {
    if (!divRef.current) {
      return;
    }
    if (isSelected) {
      const { height } = divRef.current.getBoundingClientRect();
      setMinHeight(height);
    } else {
      return setMinHeight(undefined);
    }
  }, [isSelected]);

  useEffect(() => {
    onChangeRef.current = onChange;
    onBlurRef.current = onBlur;
  }, [onChange, onBlur]);

  const handleChange = useCallback(
    (newValue: string) => {
      if (cellId === editorCellId.current) {
        onChangeRef.current(sanitizeValue(newValue));
      }
    },
    [cellId, editorCellId],
  );

  const handleBlur = useCallback(() => {
    if (cellId === editorCellId.current) {
      onBlurRef.current();
    }
  }, [cellId, editorCellId]);

  useEffect(() => {
    if (!cellRef.current || editorCellId.current === cellId || disabled) {
      return;
    }

    if (isSelected) {
      attach({
        placeholder,
        bgColor,
        newCellId: cellId,
        cellElement: cellRef.current,
        currentValue: sanitizedValue,
        children,
        fixedPosition: cellId.includes(HEADER_ROW_ID),
        focus: isEditing,
        onChange: handleChange,
        onBlur: handleBlur,
      });
    }
  }, [
    cellId,
    cellRef,
    editorCellId,
    disabled,
    isSelected,
    isEditing,
    sanitizedValue,
    children,
    placeholder,
    bgColor,
    attach,
    handleBlur,
    handleChange,
  ]);

  useEffect(() => {
    adjustMinHeight();
  }, [isSelected, adjustMinHeight]);

  useEffect(() => {
    if (cellId === editorCellId.current) {
      setChildren(children);
    }
  }, [cellId, editorCellId, children, setChildren]);

  useEffect(() => {
    if (cellId === editorCellId.current) {
      setBgColor(bgColor);
    }
  }, [cellId, editorCellId, bgColor, setBgColor]);

  useEffect(() => {
    if (cellId === editorCellId.current) {
      setPlaceholder(placeholder);
    }
  }, [cellId, editorCellId, placeholder, setPlaceholder]);

  useEffect(() => {
    if (isEditing && editorCellId.current === cellId) {
      focusEditor(sanitizedValue);
    }
  }, [editorCellId, sanitizedValue, cellId, isEditing, focusEditor]);

  useEffect(() => {
    if (cellId === editorCellId.current && hidden) {
      detach();
    }
  }, [editorCellId, cellId, hidden, detach]);

  useEventListener("resize", adjustMinHeight);

  return (
    <div
      ref={divRef}
      className="box-border flex h-full w-full items-center rounded-lg py-1 pl-2"
      style={{
        minHeight: isSelected ? minHeight : undefined,
      }}
    >
      {children}
      <EditableDiv
        className="box-border whitespace-break-spaces py-1 pl-1.5 pr-0.5 font-code-12 focus:outline-0"
        tabSize={4}
        value={sanitizedValue}
        onChange={() => {}}
      />
    </div>
  );
};
