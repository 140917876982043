import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { twJoin, twMerge } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";

export type ModalProps = {
  open: boolean;
  onClose(): void;
  title?: React.ReactNode;
  headerClassName?: string;
  subtitle?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
  afterLeave?: () => void;
  closeIcon?: boolean;
  zIndex?: `z-${number}`;
  showTitleDivider?: boolean;
  withOverlay?: boolean;
};

/**
 * @deprecated Use Modal from src/design-system/Modal.tsx instead
 */
export const Modal: React.FC<ModalProps> = ({
  children,
  title,
  headerClassName: titleClassName,
  subtitle,
  open,
  onClose = () => {},
  className,
  afterLeave,
  closeIcon = false,
  showTitleDivider = false,
  // Above the 50 of drop down elements
  zIndex = "z-20",
  withOverlay = true,
}) => {
  const renderContent = () => {
    if (title === undefined) {
      return children;
    }
    return (
      <>
        <Dialog.Title
          className={twMerge(
            "px-6 pb-3 pt-4 text-gray-800 font-inter-semibold-16px",
            showTitleDivider &&
              "sticky top-0 z-10 border-b border-gray-100 bg-white",
            titleClassName,
          )}
        >
          <div className="items-top flex justify-between">
            <div className="min-w-0 flex-1">{title}</div>
            {closeIcon && (
              <div className="flex-shrink-0">
                <Icon
                  dataLoc="close-modal"
                  icon={faClose}
                  padding={false}
                  size="sm"
                  onClick={onClose}
                />
              </div>
            )}
          </div>
          <div className="text-gray-500 font-inter-normal-13px">{subtitle}</div>
        </Dialog.Title>
        {children}
      </>
    );
  };

  return (
    <Transition
      afterLeave={afterLeave}
      enter="transition duration-100 ease-out"
      enterFrom="relative scale-95 opacity-0"
      enterTo="relative scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="relative scale-100 opacity-100"
      leaveTo="relative scale-95 opacity-0"
      show={open}
    >
      <Dialog
        className={twJoin(
          "fixed inset-0 flex items-center justify-center",
          zIndex,
        )}
        onClose={onClose}
      >
        <div
          className={twMerge(
            "flex max-h-screen flex-col overflow-y-auto rounded-xl scrollbar-hide",
            className,
          )}
          data-loc="modal"
        >
          {withOverlay && (
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 z-10 bg-modal bg-opacity-70" />
            </Transition.Child>
          )}

          <Transition.Child
            className={twJoin(
              "max-w-s z-20 rounded-lg bg-white",
              zIndex,
              !withOverlay && "shadow-xl",
            )}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {renderContent()}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
