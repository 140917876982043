import { ErroredRow, IgnoredRow, IndexedDataRow } from "src/api/types";
import { ResultDataAndAuxRowV2 } from "src/dataTable/types";
import { errorMessage } from "src/utils/stringUtils";

const getSuccesRowResultMapper =
  (type: "success" | "success_match" | "success_mismatch") =>
  (successRow: IndexedDataRow): ResultDataAndAuxRowV2 => ({
    index: successRow.index,
    type: type,
    data: successRow.data,
    auxData: successRow.aux_data,
    expectedOutputData: successRow.expected_output_data,
    expectedOutputKeysMismatch: successRow.expected_output_keys_mismatch,
    nodeExecutionMetadata: successRow.node_execution_metadata,
  });

export const mergeDataToTableDate = (
  successData: IndexedDataRow[],
  successMatchData: IndexedDataRow[],
  successMismatchData: IndexedDataRow[],
  ignoredData: IgnoredRow[],
  failureData: ErroredRow[],
): ResultDataAndAuxRowV2[] => {
  const failureRows: ResultDataAndAuxRowV2[] = failureData.map((failure) => ({
    index: failure.index,
    type: "failure",
    errorMessage: errorMessage(failure.msg),
    errorOriginNodeId: failure.node_id,
    data: failure.data,
    auxData: failure.aux_data,
    expectedOutputData: failure.expected_output_data,
    nodeExecutionMetadata: failure.node_execution_metadata,
    provenance: failure.provenance,
  }));
  const mergedData = failureRows;

  const successfulRows: ResultDataAndAuxRowV2[] = successData.map(
    getSuccesRowResultMapper("success"),
  );
  mergedData.push(...successfulRows);

  const successfulMatchRows: ResultDataAndAuxRowV2[] = successMatchData.map(
    getSuccesRowResultMapper("success_match"),
  );
  mergedData.push(...successfulMatchRows);

  const successfulMismatchRows: ResultDataAndAuxRowV2[] =
    successMismatchData.map(getSuccesRowResultMapper("success_mismatch"));
  mergedData.push(...successfulMismatchRows);

  const ignoredRows: ResultDataAndAuxRowV2[] = ignoredData.map((ignored) => ({
    index: ignored.index,
    type: "ignored",
    data: ignored.data,
    auxData: ignored.aux_data,
    expectedOutputData: ignored.expected_output_data,
    nodeExecutionMetadata: ignored.node_execution_metadata,
    provenance: ignored.provenance,
  }));
  mergedData.push(...ignoredRows);

  const orderByIndexComparator = (
    a: ResultDataAndAuxRowV2,
    b: ResultDataAndAuxRowV2,
  ) => a.index - b.index;

  mergedData.sort(orderByIndexComparator);

  return mergedData;
};
