import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import Fuse from "fuse.js";
import React, { useState } from "react";
import { twJoin } from "tailwind-merge";

import { ManifestIntegrationProvider } from "src/api/connectApi/manifestTypes";
import {
  useConnections,
  useProviderManifest,
} from "src/api/connectApi/queries";
import {
  ConnectionT,
  CustomResourceT,
  DatabaseConnectionResourceT,
  ManualReviewProviderResourceT,
  ProviderT,
  Providers,
  isSQLDatabaseProvider,
} from "src/api/connectApi/types";
import { FlowT } from "src/api/flowTypes";
import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { SearchBox } from "src/base-components/SearchBox";
import { WorkspaceDataplane } from "src/clients/flow-api/api";
import { isBetaConnection } from "src/connections/CreateConnectionGrid";
import { getProviderDescription } from "src/connections/ProviderResourceProperties";
import { isManualReviewNodeConnection } from "src/connections/model/inboundWebhook";
import { NodeT } from "src/constants/NodeDataTypes";
import { NodeIcon as getNodeIcon } from "src/constants/NodeIcons";
import {
  CREATABLE_NODE_PROPERTIES,
  getNodeTitle,
} from "src/constants/NodeProperties";
import {
  SIMPLE_CREATABLE_NODE_TYPES,
  INACTIVE_NODE_TYPES,
  NODE_TYPE,
  PARENT_FLOW_NODE_TYPES,
} from "src/constants/NodeTypes";
import { singleFlowIsChildFlow } from "src/flow/Model";
import { rightSidePaneWidthClassname } from "src/flowContainer/RightPane";
import { AddNodeCard, DisabledDetails } from "src/nodeAdding/AddNodeCard";
import { AddNodeCardSkeleton } from "src/nodeAdding/AddNodeCardSkeleton";
import {
  AddResourcePane,
  AddResourcePanePropsT,
} from "src/nodeAdding/AddResourcePane";
import { EmptySearchResult } from "src/nodeAdding/EmptySearchResult";
import { PasteNodesCard } from "src/nodeAdding/PasteNodesCard";
import { AddNodeParamsT, MAXIMUM_PARENT_NODES } from "src/store/GraphStore";
import { useCurrentWorkspace } from "src/utils/useCurrentWorkspace";

type AddNodePanePropsT = {
  addNode: (params: AddNodeParamsT) => void;
  onCloseButtonClick: () => void;
  workspace: WorkspaceDataplane;
  flow: FlowT;
  nodes: NodeT[];
};

const creatableNodeTypes = SIMPLE_CREATABLE_NODE_TYPES.filter((type) => {
  return !INACTIVE_NODE_TYPES.includes(type);
});
// A list of connections that are not meant to be used as a node in the flow.
const excludedConnections = ["retool"];
export const AddNodePane: React.FC<AddNodePanePropsT> = ({
  addNode,
  onCloseButtonClick,
  workspace,
  flow,
  nodes,
}) => {
  const [filterTerm, setFilterTerm] = useState<string>("");
  const [selectedConnection, setSelectedConnection] = useState<ConnectionT>();
  const [selectedManifest, setSelectedManifest] =
    useState<ManifestIntegrationProvider>();
  const connectionResult = useConnections(workspace.base_url!);

  const getDeactivatedInfo = (nodeType: NODE_TYPE): DisabledDetails => {
    if (PARENT_FLOW_NODE_TYPES.includes(nodeType)) {
      if (singleFlowIsChildFlow(flow)) {
        return {
          disabled: true,
          disabledInfo: {
            title: "Unable to use this node",
            body: "This Decision Flow is already called by another Decision Flow.",
          },
        };
      } else if (
        nodes.filter(
          (n) => n.type && PARENT_FLOW_NODE_TYPES.includes(n.type as NODE_TYPE),
        ).length >= MAXIMUM_PARENT_NODES
      ) {
        return {
          disabled: true,
          disabledInfo: {
            title: "Unable to use this node",
            body: "You can add up to twenty Decision Flow and Loop nodes on your canvas.",
          },
        };
      }
    }

    return { disabled: false };
  };

  const getFilteredNodeTypes = () => {
    if (filterTerm === "") {
      return creatableNodeTypes;
    }
    const fuse = new Fuse(creatableNodeTypes, {
      getFn: (nodeType) => getNodeTitle({ nodeType: nodeType }),
      threshold: 0.3,
    });
    return fuse.search(filterTerm).map((result) => result.item);
  };

  const getNameFilteredConnections = (
    connections: ConnectionT[],
  ): ConnectionT[] => {
    if (filterTerm === "") {
      return connections;
    }
    const fuse = new Fuse(connections, {
      keys: ["name"],
      threshold: 0.3,
    });
    return fuse.search(filterTerm).map((result) => result.item);
  };

  const filteredNodeTypes = getFilteredNodeTypes();
  const filteredConnections = getNameFilteredConnections(
    connectionResult.data || [],
  );

  const noSearchResultsFound =
    filteredNodeTypes.length === 0 && filteredConnections.length === 0;

  const onSearchBarInputChanged = (input: string) => setFilterTerm(input);

  const renderConnectApiError = () => (
    <div className="mt-9 flex w-full justify-center text-gray-600 font-inter-semibold-14px">
      Connect-API not available
    </div>
  );

  const renderLoadingConnections = () => (
    <>
      <AddNodeCardSkeleton />
      <AddNodeCardSkeleton />
    </>
  );

  const onConnectionCardClick = (
    connection: ConnectionT,
    connectionManifest?: ManifestIntegrationProvider,
  ) => {
    if (connection.provider === "custom") {
      const nonProbingConnection = connection.resource_configs.filter(
        (resourceConfig) => resourceConfig.resource !== "probe",
      )[0];
      addNode({
        nodeType: NODE_TYPE.CUSTOM_CONNECTION_NODE,
        providerResource: {
          provider: "custom",
          resource: nonProbingConnection.resource as CustomResourceT,
        },
        connectionId: connection.id,
        resourceConfigId: nonProbingConnection.id,
        mediaKey: connection.configuration.media_key as string,
      });
    } else if (isSQLDatabaseProvider(connection.provider)) {
      const nonProbingConnection = connection.resource_configs.filter(
        (resourceConfig) => resourceConfig.resource !== "probe",
      )[0];
      addNode({
        nodeType: NODE_TYPE.SQL_DATABASE_CONNECTION_NODE,
        providerResource: {
          provider: connection.provider,
          resource:
            nonProbingConnection.resource as DatabaseConnectionResourceT,
        },
        connectionId: connection.id,
        resourceConfigId: nonProbingConnection.id,
      });
    } else if (isManualReviewNodeConnection(connection)) {
      addNode({
        nodeType: NODE_TYPE.REVIEW_CONNECTION_NODE,
        providerResource: {
          provider: connection.provider,
          resource: connection.resource_configs[0].resource,
        } as ManualReviewProviderResourceT,
        connectionId: connection.id,
        resourceConfigId: connection.resource_configs[0].id,
      });
    } else {
      if (connectionManifest) {
        setSelectedManifest(connectionManifest);
      }
      // Both webhook and "standard" integrations are handled here
      setSelectedConnection(connection);
    }
  };

  const getNodeIconFromConnection = (connection: ConnectionT) => {
    if (isManualReviewNodeConnection(connection)) {
      return getNodeIcon({
        nodeType: NODE_TYPE.REVIEW_CONNECTION_NODE,
      });
    }

    return getNodeIcon({
      nodeType: NODE_TYPE.INTEGRATION_NODE,
      provider: connection.provider,
      mediaKey: connection.configuration.media_key as string,
      size: "md",
    });
  };

  const LegacyProviderConnectionNodeCard = ({
    connection,
  }: {
    connection: ConnectionT;
  }) => {
    return (
      <AddNodeCard
        dataLoc={`add-${connection.provider}-${connection.id}`}
        description={getProviderDescription(connection)}
        isBeta={isBetaConnection(connection.provider)}
        nodeIcon={getNodeIconFromConnection(connection)}
        title={connection.name}
        onClick={() => onConnectionCardClick(connection)}
      />
    );
  };

  const ManifestProviderConnectionNodeCard = ({
    connection,
  }: {
    connection: ConnectionT;
  }) => {
    const workspaceData = useCurrentWorkspace();
    const baseUrl = workspaceData.workspace?.base_url;
    const { provider, manifest_version } = connection;
    const manifest = useProviderManifest(
      baseUrl,
      provider,
      manifest_version,
    ).data;
    const description =
      manifest?.description || `Add a ${manifest?.display_name} connection`;
    return (
      <AddNodeCard
        dataLoc={`add-${connection.provider}-${connection.id}`}
        description={description}
        isBeta={isBetaConnection(connection.provider)}
        nodeIcon={getNodeIconFromConnection(connection)}
        title={connection.name}
        onClick={() => onConnectionCardClick(connection, manifest)}
      />
    );
  };

  const renderConnectionCards = (connections: ConnectionT[]) => {
    const legacyProviderConnections = connections.filter(
      (conn) => !conn.manifest_version,
    );
    const manifestProviderConnections = connections.filter(
      (conn) => conn.manifest_version,
    );
    return (
      <div>
        {getNameFilteredConnections(legacyProviderConnections)
          .filter(
            (connection) =>
              !excludedConnections.includes(connection.provider) &&
              Providers.includes(connection.provider as ProviderT),
          )
          // If the connection is a custom connection it should only be used with exactly one none-probing resource configured.
          .filter(
            (connection) =>
              connection.provider !== "custom" ||
              connection.resource_configs.length === 2,
          )
          .map((connection) => (
            <LegacyProviderConnectionNodeCard
              key={connection.id}
              connection={connection}
            />
          ))}
        {getNameFilteredConnections(manifestProviderConnections).map(
          (connection) => (
            <ManifestProviderConnectionNodeCard
              key={connection.id}
              connection={connection}
            />
          ),
        )}
      </div>
    );
  };

  if (selectedConnection) {
    const addResourcePaneProps: AddResourcePanePropsT = {
      addNode,
      connection: selectedConnection,
      onBackButtonClick: () => {
        setSelectedConnection(undefined);
        setSelectedManifest(undefined);
      },
      onCloseButtonClick,
    };

    if (selectedManifest) {
      addResourcePaneProps.manifest = selectedManifest;
    }

    return <AddResourcePane {...addResourcePaneProps} />;
  }

  return (
    <div
      className={twJoin(
        "flex max-h-full flex-col overflow-hidden bg-white pt-6 shadow-lg",
        rightSidePaneWidthClassname,
      )}
    >
      <div className="px-6">
        <div className="mb-4 flex flex-row items-center justify-between">
          <div className="font-inter-semibold-16px">Add a node</div>
          <Icon
            color="text-gray-500 hover:text-gray-700"
            icon={faXmark}
            size="xs"
            onClick={onCloseButtonClick}
          />
        </div>
        <SearchBox
          placeholder="Search Node types..."
          onChange={onSearchBarInputChanged}
        />
      </div>
      {noSearchResultsFound ? (
        <EmptySearchResult />
      ) : (
        <div className="shrink overflow-y-auto px-6">
          <div>
            <PasteNodesCard />
            {filteredNodeTypes.map((nodeType) => (
              <AddNodeCard
                key={nodeType}
                dataLoc={`add-${nodeType}`}
                description={
                  CREATABLE_NODE_PROPERTIES[nodeType].ADD_BUTTON_DESCRIPTION
                }
                isBeta={CREATABLE_NODE_PROPERTIES[nodeType].IS_BETA}
                nodeIcon={getNodeIcon({
                  nodeType: nodeType,
                  disabled: getDeactivatedInfo(nodeType).disabled,
                })}
                title={getNodeTitle({ nodeType: nodeType })}
                onClick={() => addNode({ nodeType })}
                {...getDeactivatedInfo(nodeType)}
              />
            ))}
          </div>
          <LoadingView
            queryResult={connectionResult}
            renderErrored={renderConnectApiError}
            renderUpdated={renderConnectionCards}
            renderUpdating={renderLoadingConnections}
          />
        </div>
      )}
    </div>
  );
};
