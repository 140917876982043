import { v4 as uuid } from "uuid";

import {
  ConnectionCreateT,
  ConnectionT,
  ResourceConfigT,
} from "src/api/connectApi/types";
import {
  filterUpdatedSecrets,
  findSecret,
  getDefaultSecret,
  markSecrets,
} from "src/connections/model/common";
import { RetoolConnectionConfigInputsT } from "src/connections/types";

export const env = "production";

export const getDefaultRetoolConnectionConfigInputs =
  (): RetoolConnectionConfigInputsT => {
    return {
      name: "",
      dataRetention: {
        value: 0,
        unit: "days",
      },
      enableNonProdConfigs: false,
      sandboxConfig: null,
      productionConfig: {
        url: "",
        apiKey: getDefaultSecret("api_key", env),
        appUUID: "",
        groupID: "",
      },
    };
  };

const createRetoolEmbedResourceConfig = (
  inputs: RetoolConnectionConfigInputsT,
): ResourceConfigT => {
  return {
    id: uuid(),
    name: inputs.name,
    configuration: {
      app_uuid: inputs.productionConfig.appUUID,
      group_ids: [inputs.productionConfig.groupID],
    },
    resource: "retool_embed",
    has_raw_response_enabled: false,
    created_at: "",
    updated_at: "",
  };
};

export const retoolConnectionConfigInputsToBEConnection = (
  inputs: RetoolConnectionConfigInputsT,
  existingConnection?: Partial<ConnectionT>,
): ConnectionCreateT => {
  const secrets = filterUpdatedSecrets([inputs.productionConfig.apiKey]);
  const resourceConfigs = existingConnection?.resource_configs?.map(
    (resource_config) =>
      resource_config.resource === "retool_embed"
        ? {
            ...resource_config,
            name: inputs.name,
            configuration: {
              app_uuid: inputs.productionConfig.appUUID,
              group_ids: [inputs.productionConfig.groupID],
            },
          }
        : resource_config,
  ) || [createRetoolEmbedResourceConfig(inputs)];

  return {
    name: inputs.name,
    is_sandbox: false,
    provider: "retool",
    secrets,
    data_retention: inputs.dataRetention,
    configuration: {
      base_url: inputs.productionConfig.url,
    },
    enable_non_prod_configs: inputs.enableNonProdConfigs,
    non_prod_env_configs: {},
    resource_configs: resourceConfigs,
  };
};

export const retoolConnectionToRetoolConnectionConfigInputs = (
  connection: ConnectionT,
): RetoolConnectionConfigInputsT => {
  const config = connection.resource_configs.find(
    (resource_config) => resource_config.resource === "retool_embed",
  )?.configuration;
  if (!config) {
    return getDefaultRetoolConnectionConfigInputs();
  }
  const baseUrl = connection.configuration.base_url;
  const allSecrets = markSecrets(connection.secrets) ?? [];
  const apiKey =
    findSecret(allSecrets, "api_key") ?? getDefaultSecret("api_key", env);

  return {
    name: connection.name,
    dataRetention: connection.data_retention
      ? connection.data_retention
      : {
          value: 0,
          unit: "days",
        },
    enableNonProdConfigs: false, // we don't support non-prod configs yet
    sandboxConfig: null,
    productionConfig: {
      url: typeof baseUrl === "string" ? baseUrl : "",
      apiKey,
      appUUID: typeof config?.app_uuid === "string" ? config?.app_uuid : "",
      groupID:
        Array.isArray(config?.group_ids) &&
        typeof config?.group_ids[0] === "string"
          ? config?.group_ids[0]
          : "",
    },
  };
};
