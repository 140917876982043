import {
  useAllUsers,
  useCreateRole,
  useDeleteRole,
} from "src/adminPanel/queries";
import { Checkbox } from "src/base-components/Checkbox";
import { LoadingView } from "src/base-components/LoadingView";
import { Modal } from "src/base-components/Modal";
import { User } from "src/clients/taktile-api/api";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedOrg: string;
};

export const AddUserModal: React.FC<Props> = ({
  isOpen,
  onClose,
  selectedOrg,
}) => {
  const allUsers = useAllUsers();
  const createRole = useCreateRole();
  const deleteRole = useDeleteRole();
  return (
    <Modal
      open={isOpen}
      title="Add user with fwd role to the organisation"
      onClose={() => onClose()}
    >
      <ul className="h-110 overflow-y-auto p-4">
        <LoadingView
          queryResult={allUsers}
          renderUpdated={(users: User[]) => (
            <table className="border-spacing-2">
              <thead>
                <th className="px-4 py-2">Username</th>
                <th className="px-4 py-2">Email</th>
                <th className="py-2">fwd_d</th>
                <th className="px-2 py-2">fwd_viewer</th>
              </thead>
              <tbody>
                {users
                  .filter((user) => user.email?.endsWith("@taktile.com"))
                  .map((user) => (
                    <tr key={user.id}>
                      <td key={user.id} className="px-4">
                        {user.username}
                      </td>
                      <td key={user.id} className="px-4">
                        {user.email}
                      </td>
                      <td>
                        {/* fwd_d */}
                        <Checkbox
                          checked={user.roles?.some(
                            (role) =>
                              role.name === "fwd_d" &&
                              role.arguments?.org_id === selectedOrg,
                          )}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            if (e.target.checked) {
                              createRole.mutate({
                                user_id: user.id,
                                arguments: {
                                  org_id: selectedOrg,
                                },
                                name: "fwd_d",
                              });
                            } else {
                              const role = user.roles?.find(
                                (role) =>
                                  role.name === "fwd_d" &&
                                  role.arguments?.org_id === selectedOrg,
                              );
                              if (role) {
                                deleteRole.mutate(role.id);
                              }
                            }
                          }}
                        />
                      </td>
                      <td>
                        {/* fwd_viewer */}
                        <Checkbox
                          checked={user.roles?.some(
                            (role) =>
                              role.name === "fwd_viewer" &&
                              role.arguments?.org_id === selectedOrg,
                          )}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            if (e.target.checked) {
                              createRole.mutate({
                                user_id: user.id,
                                arguments: {
                                  org_id: selectedOrg,
                                },
                                name: "fwd_viewer",
                              });
                            } else {
                              const role = user.roles?.find(
                                (role) =>
                                  role.name === "fwd_viewer" &&
                                  role.arguments?.org_id === selectedOrg,
                              );
                              if (role) {
                                deleteRole.mutate(role.id);
                              }
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        />
      </ul>
    </Modal>
  );
};
