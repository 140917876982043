import {
  UseQueryOptions,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import {
  VersionNodeResultsPagePresignedUrlRequest,
  typingsApi,
} from "src/api/endpoints";
import { NEVER_REFETCH_OPTIONS } from "src/api/queries";
import {
  ErroredRowGroup,
  ErroredRow,
  IndexedDataRow,
  NodeTestRunResult,
  IgnoredRow,
} from "src/api/types";
import { ResultDataAndAuxRowV2 } from "src/dataTable/types";
import { queryClient } from "src/queryClient";
import { fetchTestRunResultDataUrl } from "src/utils/testRun";

type PageEnvelope<TRow extends {} & object> = {
  data: TRow[];
  nextPageParam: string | undefined | VersionNodeResultsPagePresignedUrlRequest;
};

const QUERY_OPTIONS: UseQueryOptions<any, any, any, any> = {
  ...NEVER_REFETCH_OPTIONS,
  retry: (failureCount, error) => {
    // If the status is 404 the page might not be there because its still
    // being proccessed by the stitcher (e.g. the dataset was big)
    // so we give it 2 minutes (60 retries, one each 2 seconds) before displaying an error
    if (error instanceof AxiosError && error.response?.status === 404) {
      return failureCount < 59;
      // Else we keep the default 4 retries
    } else {
      return failureCount < 3;
    }
  },
  retryDelay: 2000,
};

const usePaginatedNodeRunQuery = <TRow extends {}>(
  rowType: ResultDataAndAuxRowV2["type"],
  testResult: NodeTestRunResult,
  workspaceUrl: string,
  options: { enabled?: boolean } = {},
) => {
  const urls = testResult[`${rowType}_pages_urls`];

  const pageCount = urls.length;

  const firstPageParam: PageEnvelope<TRow>["nextPageParam"] =
    pageCount === 0
      ? undefined
      : {
          node_id: testResult.node_id,
          page: 0,
          run_id: testResult.test_run_id,
          status: rowType,
          type: "node_results_page",
          version_id: testResult.version_id,
        };

  return useInfiniteQuery<PageEnvelope<TRow>, Error>({
    queryKey: [
      "NodeRunPages",
      rowType,
      testResult.test_run_id,
      testResult.node_id,
    ],
    queryFn: ({ pageParam = firstPageParam }) => {
      return fetchPage(pageParam, pageCount, rowType, workspaceUrl);
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPageParam;
    },
    ...QUERY_OPTIONS,
    ...options,
  });
};

export const usePaginatedNodeRunSuccessData = (
  testResult: NodeTestRunResult,
  workspaceUrl: string,
  archetypeTesting: boolean,
) =>
  usePaginatedNodeRunQuery<IndexedDataRow>(
    "success",
    testResult,
    workspaceUrl,
    { enabled: !archetypeTesting },
  );

export const usePaginatedNodeRunSuccessMatchData = (
  testResult: NodeTestRunResult,
  workspaceUrl: string,
  archetypeTesting: boolean,
) =>
  usePaginatedNodeRunQuery<IndexedDataRow>(
    "success_match",
    testResult,
    workspaceUrl,
    { enabled: archetypeTesting },
  );

export const usePaginatedNodeRunSuccessMismatchData = (
  testResult: NodeTestRunResult,
  workspaceUrl: string,
  archetypeTesting: boolean,
) =>
  usePaginatedNodeRunQuery<IndexedDataRow>(
    "success_mismatch",
    testResult,
    workspaceUrl,
    { enabled: archetypeTesting },
  );

export const usePaginatedNodeRunIgnoredData = (
  testResult: NodeTestRunResult,
  workspaceUrl: string,
) => usePaginatedNodeRunQuery<IgnoredRow>("ignored", testResult, workspaceUrl);

export const usePaginatedNodeRunFailureData = (
  testResult: NodeTestRunResult,
  workspaceUrl: string,
) => usePaginatedNodeRunQuery<ErroredRow>("failure", testResult, workspaceUrl);

export const usePaginatedNodeRunErrorData = (
  testResult: NodeTestRunResult,
  workspaceUrl: string,
) => {
  const pageUrls = testResult.errors_pages_urls;

  const pageCount = pageUrls.length;

  const firstPageParam: PageEnvelope<ErroredRowGroup>["nextPageParam"] =
    pageCount === 0
      ? undefined
      : {
          node_id: testResult.node_id,
          page: 0,
          run_id: testResult.test_run_id,
          status: "errors",
          type: "node_results_page",
          version_id: testResult.version_id,
        };

  return useInfiniteQuery<PageEnvelope<ErroredRowGroup>, Error>({
    queryKey: [
      "ErrorRowGroupPages",
      testResult.test_run_id,
      testResult.node_id,
    ],
    queryFn: async ({ pageParam = firstPageParam }) => {
      return fetchPage(pageParam, pageCount, "errors", workspaceUrl);
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPageParam;
    },
    ...QUERY_OPTIONS,
  });
};

const fetchPage = async <TRow extends {} & object>(
  currentPageParam: VersionNodeResultsPagePresignedUrlRequest | undefined,
  pageCount: number,
  dataAccessor: string,
  workspaceUrl: string,
): Promise<PageEnvelope<TRow>> => {
  if (currentPageParam === undefined) {
    return { data: [], nextPageParam: undefined };
  }

  const isLastPage = currentPageParam.page === pageCount - 1;

  const nextPageParam = !isLastPage
    ? { ...currentPageParam, page: currentPageParam.page + 1 }
    : undefined;

  const data = (
    await fetchTestRunResultDataUrl<TRow>(workspaceUrl, currentPageParam)
  )[dataAccessor];

  return { data, nextPageParam };
};

export const fetchSingleErrorPage = async ({
  workspaceUrl,
  result,
}: {
  workspaceUrl: string;
  result: NodeTestRunResult;
}) => {
  const data = await fetchTestRunResultDataUrl<ErroredRowGroup>(workspaceUrl, {
    ...result,
    page: 0,
    status: "errors",
    type: "node_results_page",
    run_id: result.test_run_id,
  });
  return data["errors"];
};

export const completionQueryKeys = {
  all: ["autocompletion-data"] as const,
  flowVersion: (versionId: string) =>
    [...completionQueryKeys.all, versionId] as const,
  node: (versionId: string, nodeId: string | undefined) =>
    [...completionQueryKeys.all, versionId, nodeId] as const,
};

export const getCompletionData = (
  versionId: string,
  nodeId: string | undefined,
) =>
  queryClient.getQueryData<ReturnType<typeof useNodeCompletionData>["data"]>(
    completionQueryKeys.node(versionId, nodeId),
  );

export const invalidateCompletionData = (versionId: string) => {
  queryClient.invalidateQueries(completionQueryKeys.flowVersion(versionId));
};

export const NoCompletionDataAvailable = "NoCompletionDataAvailable" as const;

export const useNodeCompletionData = (
  flowVersionId: string,
  nodeId: string | undefined,
  isLoopNode: boolean | undefined = false,
) => {
  return useQuery({
    queryKey: completionQueryKeys.node(flowVersionId, nodeId),
    queryFn: async () => {
      try {
        return (
          await typingsApi.getNodeTypesApiV1TypesFlowVersionsFlowVersionIdNodesNodeIdGet(
            flowVersionId,
            nodeId!,
            isLoopNode,
          )
        ).data;
      } catch (e: any) {
        if ((e as AxiosError).response?.status === 404) {
          return NoCompletionDataAvailable;
        } else throw e;
      }
    },
    enabled: !!nodeId,
    refetchInterval: 60 * 1_000,
  });
};
