import { business_report_sections } from "src/connections/connectionConfigs/thomsonReuters/businessReportConfigurationElements";
import {
  person_report_options,
  person_report_sections,
} from "src/connections/connectionConfigs/thomsonReuters/personReportConfigurationElements";
import {
  ConnectionResourceTemplateConfigKey,
  ConnectionResourceTemplateConfigKeyParent,
  makeConnectionTemplate,
} from "src/connections/types";

export const permissible_purpose_parent: ConnectionResourceTemplateConfigKeyParent =
  {
    key: "permissible_purpose",
    title: "Permissible purpose codes",
    subtitle: "Configure the DPPA, GLB, and voter permissible purpose codes",
    required: true,
  };

const permissiblePurposeConfigurationElements: ConnectionResourceTemplateConfigKey[] =
  [
    {
      key: "permissible_purpose_dppa",
      name: "DPPA",
      required: true,
      type: "simple_selection",
      elements: [
        { key: "0", value: "0: No permitted use" },
        {
          key: "1",
          value:
            "1: For official use by a court, law enforcement agency, or other government agency",
        },
        {
          key: "3",
          value:
            "3: To verify or correct information provided to you by a person in order to prevent fraud, pursue legal remedies or recover a debt; skip tracing",
        },
        {
          key: "4",
          value:
            "4: For use in connection with a civil, criminal or arbitral legal proceeding or legal research",
        },
        {
          key: "6",
          value:
            "6: For use in connection with an insurance claims investigation or insurance antifraud activities",
        },
      ],
      hint: "This code is required to maintain compliance with the Driver's Privacy Protection Act. See Appendix A in the Thomson Reuters developer portal for information on available values. Use code 0 (zero) for no permitted use.",
      parent: permissible_purpose_parent,
    },
    {
      key: "permissible_purpose_glb",
      name: "GLB",
      required: true,
      type: "simple_selection",
      elements: [
        {
          key: "C",
          value:
            "C: For use by a person holding a legal or beneficial interest relating to the consumer",
        },
        {
          key: "A",
          value:
            "A: For use in complying with federal, state, or local laws, rules, and other applicable legal requirements",
        },
        {
          key: "I",
          value:
            "I: For use as necessary to effect, administer or enforce a transaction that a consumer requests or authorize",
        },
        {
          key: "J",
          value:
            "J: For use in complying with a properly authorized civil, criminal or regulatory investigation, or subpoena or summons by federal, state, or local authority",
        },
        {
          key: "B",
          value:
            "B: For use to protect against or prevent actual or potential fraud, unauthorized transactions, claims or other liability",
        },
        {
          key: "L",
          value:
            "L: For use by a law enforcement agency, self-regulatory organizations or for an investigation on a matter related to public safety",
        },
        {
          key: "Q",
          value: "Q: With the consent or at the direction of the consumer",
        },
        {
          key: "H",
          value:
            "H: To persons acting in a fiduciary or representative capacity on behalf of the consumer",
        },
        {
          key: "K",
          value:
            "K: For required institutional risk control or for resolving consumer disputes or inquiries",
        },
      ],
      hint: "This code is required to maintain compliance with the privacy provisions of the federal Gramm-Leach-Bliley (GLB) Act. See Appendix A in the Thomson Reuters developer portal for information on available values.",
      parent: permissible_purpose_parent,
    },
    {
      key: "permissible_purpose_voter",
      name: "Voter",
      required: true,
      type: "simple_selection",
      elements: [
        { key: "7", value: "7: No permitted use" },
        {
          key: "2",
          value:
            "2: Use in connection with a permissible election-related purpose",
        },
        {
          key: "5",
          value: "5: Use in connection with a non-commercial purpose",
        },
      ],
      hint: "This code is required to maintain compliance with voter privacy restrictions imposed by state laws. See Appendix A in the Thomson Reuters developer portal for information on available values. Use code 7 for no permitted use.",
      parent: permissible_purpose_parent,
    },
  ];

const clearSearchConfigurationElements: ConnectionResourceTemplateConfigKey[] =
  [
    {
      key: "eidv_name",
      name: "CLEAR definition name",
      required: true,
      type: "text",
      hint: "The CLEAR definition name provided by Thomson Reuters alongside credentials",
    },
    {
      key: "eidv_version",
      name: "CLEAR definition version",
      required: false,
      type: "text",
      hint: "The CLEAR definition version provided by Thomson Reuters alongside credentials",
    },
  ];

const riskSearchConfigurationElements: ConnectionResourceTemplateConfigKey[] = [
  {
    key: "def_name",
    name: "Risk Inform definition name",
    required: true,
    type: "text",
  },
  {
    key: "def_version",
    name: "Risk Inform definition version",
    required: false,
    type: "text",
  },
];

const mtls_help_text =
  "For information on how to obtain your mTLS certificate and mTLS key from a .pfx file, please visit our documentation";

export const ThomsonReutersTemplate = makeConnectionTemplate({
  provider: "thomson_reuters",
  secrets: [
    { key: "user_id", name: "User ID", required: true },
    { key: "password", name: "Password", required: true },
    {
      key: "mtls_certificate",
      name: "mTLS certificate",
      required: true,
      hint: mtls_help_text,
      type: "multiline",
    },
    {
      key: "mtls_key",
      name: "mTLS private key",
      required: true,
      hint: mtls_help_text,
      type: "multiline",
    },
  ],
  resources: {
    thomson_reuters_clear_person_search: {
      name: "Clear ID Confirm Person Search",
      configKeys: [
        ...clearSearchConfigurationElements,
        ...permissiblePurposeConfigurationElements,
      ],
    },
    thomson_reuters_clear_business_search: {
      name: "Clear ID Confirm Business Search",
      configKeys: [
        ...clearSearchConfigurationElements,
        ...permissiblePurposeConfigurationElements,
      ],
    },
    thomson_reuters_risk_person_search: {
      name: "Risk Inform Person Search",
      configKeys: [
        ...riskSearchConfigurationElements,
        ...permissiblePurposeConfigurationElements,
        {
          key: "use_web_analytics_search",
          name: "Enable web analytics search",
          required: false,
          type: "switch",
          hint: "Toggle to enable or disable web analytics search. Disabled by False.",
        },
      ],
    },
    thomson_reuters_risk_business_search: {
      name: "Risk Inform Business Search",
      configKeys: [
        ...riskSearchConfigurationElements,
        ...permissiblePurposeConfigurationElements,
        {
          key: "use_web_analytics_search",
          name: "Enable web analytics search",
          required: false,
          type: "switch",
          hint: "Toggle to enable or disable web analytics search. Disabled by False.",
        },
      ],
    },
    thomson_reuters_person_report: {
      name: "Person Report",
      configKeys: [
        ...person_report_options,
        ...person_report_sections,
        ...permissiblePurposeConfigurationElements,
      ],
    },
    thomson_reuters_business_report: {
      name: "Business Report",
      configKeys: [
        ...business_report_sections,
        ...permissiblePurposeConfigurationElements,
      ],
    },
  },
});
