import {
  IconDefinition,
  faCalendar,
  faClock,
} from "@fortawesome/pro-regular-svg-icons";
import { times } from "lodash";
import { ReactNode } from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { SkeletonLine } from "src/manualReview/reviewCaseCommon/SkeletonLine";

type SubHeaderProps =
  | {
      requestTime: ReactNode;
      timeSinceRequest: ReactNode;
      assignedTo: ReactNode;
      status: ReactNode;
      isLoading?: false;
      isPreview?: boolean;
    }
  | {
      isLoading: true;
    };

const SUBHEADER_HEIGHT = "75px";
export const SubHeader: React.FC<SubHeaderProps> = (props) => {
  return (
    <div
      className="flex divide-x divide-gray-200 border-b border-l border-gray-200 bg-white"
      style={{ height: SUBHEADER_HEIGHT }}
    >
      {!props.isLoading ? (
        <>
          <SubHeaderSection
            body={props.requestTime}
            icon={faCalendar}
            isPreview={props.isPreview}
            title="Request time"
          />
          <SubHeaderSection
            body={props.timeSinceRequest}
            icon={faClock}
            isPreview={props.isPreview}
            title="Time since request"
          />
          <SubHeaderSection
            body={props.assignedTo}
            isPreview={props.isPreview}
            title="Assigned to"
          />
          <SubHeaderSection
            body={props.status}
            isPreview={props.isPreview}
            title="Status"
          />
        </>
      ) : (
        times(4, (index) => <SubHeaderSection key={index} loading />)
      )}
    </div>
  );
};

const SubHeaderSection: React.FC<
  | { loading: true }
  | {
      icon?: IconDefinition;
      title: string;
      body: ReactNode;
      loading?: false;
      isPreview?: boolean;
    }
> = (props) => {
  return (
    <div
      className="flex h-full min-w-0 flex-1 items-center px-5"
      data-loc="manual-review-subheader-section"
    >
      <div className="min-w-0 flex-col gap-y-0.5">
        {props.loading ? (
          <SkeletonLine className="h-3 w-20" />
        ) : (
          <p
            className={twJoin(
              "truncate font-inter-semibold-13px",
              props.isPreview ? "text-gray-500" : "text-gray-800",
            )}
          >
            {props.title}
          </p>
        )}
        {props.loading ? (
          <SkeletonLine className="mt-2 h-3 w-[134px]" />
        ) : (
          <div
            className={twJoin(
              "flex items-center gap-x-1 font-inter-normal-12px",
              props.isPreview ? "text-gray-500" : "text-gray-800",
            )}
          >
            {props.icon && <Icon icon={props.icon} size="xs" />}
            {props.body}
          </div>
        )}
      </div>
    </div>
  );
};
