import { faPen, faSpinner } from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "src/base-components/Icon";
import { Label } from "src/base-components/Label";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { ModelDropzoneController as ModelUploadController } from "src/mlNode/ModelUploadController";

type ModelFilePropsT = {
  modelName: string;
  onFileSelect: (file: File) => void;
  isUploading: boolean;
  workspace: WorkspaceDataplane;
};

export const ModelFile: React.FC<ModelFilePropsT> = ({
  modelName,
  onFileSelect,
  isUploading,
  workspace,
}) => {
  return (
    <div className="mb-4" data-loc="model-file">
      <Label required>Model File</Label>
      <div className="flex w-full items-center justify-between rounded-lg border border-gray-300 py-2.5 pl-4 pr-3.5">
        <span className="text-gray-800 font-inter-normal-13px">
          {modelName}
        </span>
        <ModelUploadController
          disabled={isUploading}
          workspace={workspace}
          onFileSelect={onFileSelect}
        >
          <Icon
            color="text-gray-500"
            icon={isUploading ? faSpinner : faPen}
            size="xs"
            spin={isUploading}
          />
        </ModelUploadController>
      </div>
    </div>
  );
};
