import { tooltipWrapperClass } from "src/base-components/Tooltip";

/**
 * Returns true if we determined that styling this element's background does not give the desired visual effect.
 */
const elementAndItsChildrenCannotBeBackgroundStyled = (element: Element) => {
  return (
    // is a checkbox for which background color does not take effect
    (element instanceof HTMLInputElement && element.type === "checkbox") ||
    // is a headlessui switch. Also can't style the children here
    element.id.startsWith("headlessui-switch") ||
    // radio groups. Also can't style the children here
    (element.tagName === "BUTTON" && element.role === "radio") ||
    // fontawesome icons
    element.tagName === "svg"
  );
};

type HighlightReason = "changed" | "added" | "removed";
export const diffViewAppliedProperties = {
  changed: {
    background: "rgb(224, 229, 255)",
    className: "diff-view-changed",
  },
  added: {
    background: "rgb(209, 250, 229)",
    className: "diff-view-added",
  },
  removed: {
    background: "rgb(254, 226, 226)",
    className: "diff-view-removed",
  },
} as const;

const applyVisuals = (element: HTMLElement, reason: HighlightReason) => {
  const { background, className } = diffViewAppliedProperties[reason];
  element.style.background = background;
  element.classList.add(className);
};

const styleParent = (element: Element, reason: HighlightReason) => {
  const parent = element.parentElement;
  if (
    parent instanceof HTMLElement &&
    !parent.classList.contains(tooltipWrapperClass)
  ) {
    applyVisuals(parent, reason);
  } else {
    if (parent) {
      styleParent(parent, reason);
    }
  }
};

export const highlightElement = ({
  element,
  reason,
  alpha = 0.1,
  highlightChildren = false,
}: {
  element: Element;
  reason: HighlightReason;
  alpha?: number;
  highlightChildren?: boolean;
}) => {
  if (
    element instanceof HTMLElement &&
    !elementAndItsChildrenCannotBeBackgroundStyled(element)
  ) {
    applyVisuals(element, reason);
    if (highlightChildren) {
      Array.from(element.children).forEach((child) => {
        highlightElement({ element: child, reason, alpha, highlightChildren });
      });
    }
  }
  if (elementAndItsChildrenCannotBeBackgroundStyled(element)) {
    styleParent(element, reason);
  }
};
