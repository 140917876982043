import { ResourceConfigT } from "src/api/connectApi/types";
import {
  makeConnectionTemplate,
  ConnectionResourceTemplate,
} from "src/connections/types";

const scoresAndCharacteristicDataGroupKey = "scoreAndCharacteristicRequests";
const amlEnhancedWatchlistCheckProfileKey = "amlEnhancedWatchlistCheckProfile";
const creditSearchOperationKeys = ["creditSearch", "creditQuotationSearch"];

const MATCH_CRITERIA_TYPES = [
  { key: "subject", value: "Applicant" },
  { key: "associate", value: "Associate" },
  { key: "potentialAssociate", value: "Potential associate" },
  { key: "family", value: "Family" },
  { key: "attributable", value: "Attributable" },
];

const isScoresAndCharacteristicHidden = (
  resourceConfig: ResourceConfigT,
): boolean => {
  // Check if `scoreAndCharacteristicRequests` is in `requested_data_groups`.
  // If not, hide the config field.
  if (!("requested_data_groups" in resourceConfig.configuration)) return true;

  const requestedDataGroups =
    resourceConfig.configuration["requested_data_groups"];
  return !(requestedDataGroups as string[]).includes(
    scoresAndCharacteristicDataGroupKey,
  );
};

const isAMLEnhancedWatchlistCheckProfileHidden = (
  resourceConfig: ResourceConfigT,
): boolean => {
  // Check if `amlEnhancedWatchlistCheckProfileKey` is in `requested_data_groups`.
  // If not, hide the config field.
  if (!("requested_data_groups" in resourceConfig.configuration)) return true;

  const requestedDataGroups =
    resourceConfig.configuration["requested_data_groups"];
  return !(requestedDataGroups as string[]).includes(
    amlEnhancedWatchlistCheckProfileKey,
  );
};

const isCreditSearchOptInHidden = (
  resourceConfig: ResourceConfigT,
): boolean => {
  // Check if `operation` is one of credit search operations.
  // If not, hide the config field.
  if (!("operation" in resourceConfig.configuration)) return true;

  const operation = resourceConfig.configuration["operation"] as string;
  return !creditSearchOperationKeys.includes(operation);
};

const scoreAndCharacteristicRequestConfigKeys: ConnectionResourceTemplate["configKeys"][number][] =
  [
    {
      key: "score_and_characteristic_request.employ_same_company_insight",
      name: "Employ same company insight",
      type: "switch",
      hint: "Include information from your company in scores and characteristics calculation",
      required: false,
      isHidden: isScoresAndCharacteristicHidden,
    },
    {
      key: "score_and_characteristic_request.score_labels",
      name: "Requested scores",
      type: "multiple_selection",
      required: false,
      elements: [
        {
          key: "RNILF05",
          value:
            "All Markets RN 5 Full RN Opt in subject and associate with links (RNILF05)",
        },
        {
          key: "CTISF53",
          value: "Cato Affordability Index - Non Tier1 Banding 3 (CTISF53)",
        },
      ],
      isHidden: isScoresAndCharacteristicHidden,
    },
    {
      key: "score_and_characteristic_request.attribute_request_index",
      name: "Attribute request (QCB) index",
      type: "number",
      hint: `Specify the index of the attributes block (QCB) to include in the Equifax response. Together with the Equifax team, you can define a set of characteristics that are important for your use case. Those characteristics are then calculated by Equifax and included in the returned credit report.`,
      required: false,
      isHidden: isScoresAndCharacteristicHidden,
    },
  ];

const amlEnhancedWatchlistCheckProfileConfigKeys: ConnectionResourceTemplate["configKeys"][number][] =
  [
    {
      key: "aml_enhanced_watchlist_check_profile.profile",
      name: "AML Enhanced Watchlist Check Profile",
      type: "simple_selection",
      hint: `Select which of the pre-configured profiles should be used when screening the customer`,
      required: false,
      elements: [
        {
          key: "UKSTD",
          value: "UK Standard",
        },
        {
          key: "UKPLUS",
          value: "UK Plus",
        },
        {
          key: "GLOBAL",
          value: "Global",
        },
      ],
      isHidden: isAMLEnhancedWatchlistCheckProfileHidden,
    },
  ];

export const EquifaxUKTemplate = makeConnectionTemplate({
  provider: "equifax_uk",
  secrets: [
    { key: "client_id", name: "Client ID", required: true },
    { key: "client_secret", name: "Client secret", required: true },
  ],
  resources: {
    gateway: {
      name: "Equifax Gateway (XML Consumer)",
      configKeys: [
        {
          key: "operation",
          name: "Credit search purpose",
          hint: `Specify the purpose of the credit "search". This value is logged at Equifax during inquiry processing. DPA legislation mandates that this value must accurately reflect the purpose of the inquiry.`,
          required: true,
          type: "simple_selection",
          elements: [
            { key: "creditSearch", value: "Credit search (SR)" },
            {
              key: "creditQuotationSearch",
              value: "Credit quotation search (SQ)",
            },
            {
              key: "antiMoneyLaunderingCheck",
              value: "Anti money laundering (ML)",
            },
            { key: "verifyIdentityCheck", value: "Verify identity check (ID)" },
          ],
        },
        {
          key: "use_legacy_urls",
          name: "Connect to Equifax using legacy URLs",
          hint: `The Equifax Gateway product has multiple active versions that are using different URLs. 
            If you are an existing customer, you may need to use the legacy URLs to connect to Equifax. 
            If your URLs are in the format of "https://services.uk.equifax.com/..." then you should select this option. 
            If your URLs are in the format of "https://api.equifax.co.uk/..." then you should not select this option.`,
          required: false,
          type: "switch",
        },
        {
          key: "credit_search_config_opt_in",
          name: "Credit search config opt-in",
          hint: "Use third-party data (family or financial associates) on inquiries. This setting needs to be consistent with other details requested (e.g., it will not be possible to opt out and request a household override score).",
          required: false,
          type: "switch",
          isHidden: isCreditSearchOptInHidden,
        },
        {
          key: "match_criteria",
          name: "Name match levels",
          hint: `Choose allowed name match levels for raw data records in the Equifax response. In addition to raw data records for the "applicant", Equifax can include data for (potential) associates of the applicant, family members, and people living at the same address.`,
          required: true,
          type: "multiple_selection",
          elements: MATCH_CRITERIA_TYPES,
          isHidden: isCreditSearchOptInHidden,
        },
        {
          key: "requested_data_groups",
          name: "Requested data groups",
          hint: `Equifax provides consumer data taken from a wide range of sources to enable its users to make informed decisions. You can select which data groups you would like to be returned in the response.`,
          required: false,
          type: "multiple_selection",
          elements: [
            {
              key: "courtAndInsolvencyInformationRequest",
              value: "Court and insolvency information",
            },
            { key: "outputAddressRequest", value: "Output address" },
            { key: "electoralRollRequest", value: "Electoral roll" },
            { key: "searchesRequest", value: "Searches" },
            { key: "insightRequest", value: "Insights" },
            {
              key: "noticeofCorrectionOrDisputeRequest",
              value: "Notice of correction or dispute",
            },
            { key: "rollingRegisterRequest", value: "Rolling register" },
            {
              key: scoresAndCharacteristicDataGroupKey,
              value: "Score and characteristic",
            },
            {
              key: amlEnhancedWatchlistCheckProfileKey,
              value: "Watchlist check profile",
            },
            {
              key: "amlEnhancedWatchlistCheckSummary",
              value: "Watchlist check summary",
            },
            {
              key: "amlEnhancedWatchlistCheckRequest",
              value: "Watchlist check request",
            },
          ],
        },
        ...scoreAndCharacteristicRequestConfigKeys,
        ...amlEnhancedWatchlistCheckProfileConfigKeys,
      ],
    },
  },
});
