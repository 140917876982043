/**
 * This file consist of pre-defined marks wrappers for Plot.
 *
 * The purpose of this file is to provide a way to define marks
 * styled in a consistent way across the application.
 */
import {
  BarYOptions,
  Data,
  LineYOptions,
  barY as plotBarY,
  lineY as plotLineY,
  plot,
  pointer,
  RenderFunction,
  RenderableMark,
  dotY,
  tip,
  stackY,
  TipOptions,
} from "@observablehq/plot";
import { identity } from "lodash";

import { ExcludesFalse } from "src/flow/types";
import { PLOT_OPTIONS } from "src/performance/Plot/defaults";

const BAR_STYLES = {
  rx: 2,
  insetTop: 2,
};

const TOOLTIP_STYLES = {
  anchor: "bottom",
  strokeWidth: 0,
  pathFilter:
    "drop-shadow(0px 15px 25px rgba(31, 37, 55, 0.10)) drop-shadow(0px 5px 10px rgba(31, 37, 55, 0.03))",
  textPadding: 12,
  currentColor: "#1F2537",
  color: "#1F2537",
  stroke: "#1F2537",
  fontWeight: 500,
  lineWidth: 11,
};

type Marks = (data: Data) => (RenderableMark | RenderFunction)[];

type Options = {
  select?: (data: any) => Data;
  stacked?: boolean;
};

const DEFAULT = {
  select: identity,
};

const mergeOptions = (options?: Options) => ({
  ...DEFAULT,
  ...options,
});

export const barY =
  (markOptions: BarYOptions, opts?: Options) => (data: Data) => {
    const options = mergeOptions(opts);
    return [
      plotBarY(options.select(data), {
        ...BAR_STYLES,
        ...markOptions,
      }),
      // Render pointer bars that have stroke on hover
      plotBarY(
        options.select(data),
        pointer({
          maxRadius: 80,
          stroke: "#F3F4F6",
          strokeWidth: 2,
          ...BAR_STYLES,
          ...markOptions,
        }),
      ),
    ];
  };

export const lineY =
  (markOptions: LineYOptions, opts?: Options) => (data: Data) => {
    const options = mergeOptions(opts);
    return [
      plotLineY(options.select(data), {
        strokeWidth: 2,
        ...markOptions,
      }),
      dotY(
        options.select(data),
        pointer({
          ...markOptions,
          symbol: "circle",
          r: 5,
          fill: markOptions.stroke,
          fillOpacity: 0.5,
          strokeWidth: 0,
        }),
      ),
      dotY(
        options.select(data),
        pointer({
          ...markOptions,
          symbol: "circle",
          r: 2.5,
          fill: "white",
          stroke: markOptions.stroke,
          strokeWidth: 1,
        }),
      ),
    ];
  };

export const tooltip =
  (tipOptions: TipOptions, opts?: Options) => (data: Data) => {
    const options = mergeOptions(opts);
    const transform = options.stacked ? stackY : identity;
    return [
      tip(
        options.select(data),
        // @ts-ignore
        transform(
          pointer({
            ...TOOLTIP_STYLES,
            ...tipOptions,
          }),
        ),
      ),
    ];
  };

/**
 * Takes passed marks and render them on a separate layer of the chart.
 * Handy for rendering another chart over the existing with different y scale.
 * https://observablehq.com/@observablehq/dual-axis-bar-line-chart
 */
export const layer =
  (marksArr: (Marks | null)[]) =>
  (data: Data): (RenderFunction | null)[] => [
    (_, { x, y }, __, dimensions) => {
      const marks = marksArr
        .flatMap((mark) => mark?.(data))
        .filter(Boolean as any as ExcludesFalse);
      const { ticks, domain } = syncYAxis(
        marks as (RenderableMark | RenderFunction)[],
        y,
      );

      return plot({
        ...dimensions,
        style: PLOT_OPTIONS.style,
        marks,
        x: {
          type: "identity",
          // @ts-ignore - Plot has not full types for scales, `x` here.
          transform: (v) => x(v) + x.bandwidth() / 2,
          axis: null,
        },
        y: {
          ...PLOT_OPTIONS.y,
          axis: "right",
          tickFormat: ".0%",
          ticks,
          domain,
          zero: true,
          grid: false,
        },

        // Have to re-define output types to make it work
        // This works, just make sure we don't request
        // legend or caption in this plot.
      }) as unknown as SVGElement | null;
    },
  ];

/**
 * Magic sync function. Probably hard to read.
 * Essentially takes left axis domain and ticks,
 * then calculates the last tick position on the chart.
 * Then collect and returns the ticks for the right axis
 * for the same positions with domain.
 */
const syncYAxis = (marks: (RenderableMark | RenderFunction)[], y: any) => {
  // Take first mark. It assumes that all marks have the same y scale.
  // This works for the current needs, but might not work in the future.
  const mark = marks[0];
  // @ts-ignore - Plot doesn't provide correct types for marks.
  if (mark.channels?.y && Array.isArray(mark.data)) {
    // @ts-ignore
    const yChannel = mark.channels.y.value;
    // @ts-ignore
    const maxDomainValue = Math.max(...mark.data.map((d) => d[yChannel]));

    const [, leftDomainUpperValue] = y.domain();
    const leftYTicks = y.ticks(PLOT_OPTIONS.y?.ticks);
    const leftLastTickPosition =
      leftYTicks[leftYTicks.length - 1] / leftDomainUpperValue;
    const ticks = leftYTicks.map((_: any, i: number) => {
      return (maxDomainValue / (leftYTicks.length - 1)) * i;
    });

    return {
      ticks,
      domain: [0, maxDomainValue / leftLastTickPosition],
    };
  }

  // Return default options if we can't sync y axis
  return {
    ticks: PLOT_OPTIONS.y?.ticks,
    // auto domain
    domain: undefined,
  };
};
