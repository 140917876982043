import { useMutation, useQuery } from "@tanstack/react-query";

import { organizationWsServiceAccountApi } from "src/api/taktileApi";
import {
  WorkspaceServiceAccount,
  WorkspaceServiceAccountPatch,
  WorkspaceServiceAccountPost,
} from "src/clients/taktile-api";
import { queryClient } from "src/queryClient";

const workspaceApiKeyKeys = {
  all: ["workspaceApiKeys"] as const,
  detail: (id: string) => [...workspaceApiKeyKeys.all, id] as const,
};

export const useWorkspaceApiKeys = (orgId: string, wsId: string) => {
  return useQuery<WorkspaceServiceAccount[], Error>(
    workspaceApiKeyKeys.all,
    async () =>
      (
        await organizationWsServiceAccountApi.getAllServiceAccountsApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysGet(
          orgId,
          wsId,
        )
      ).data,
  );
};

export const useCreateWorkspaceApiKey = (orgId: string, wsId: string) => {
  return useMutation(
    (payload: WorkspaceServiceAccountPost) =>
      organizationWsServiceAccountApi.createServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysPost(
        wsId,
        orgId,
        payload,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(workspaceApiKeyKeys.all);
      },
    },
  );
};

export const useUpdateWorkspaceApiKey = (
  orgId: string,
  wsId: string,
  keyId: string,
) => {
  return useMutation(
    (payload: WorkspaceServiceAccountPatch) =>
      organizationWsServiceAccountApi.patchServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdPatch(
        orgId,
        wsId,
        keyId,
        payload,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(workspaceApiKeyKeys.all);
      },
    },
  );
};

export const useDeleteWorkspaceApiKey = (
  orgId: string,
  wsId: string,
  keyId: string,
) => {
  return useMutation(
    () =>
      organizationWsServiceAccountApi.deleteServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdDelete(
        orgId,
        wsId,
        keyId,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(workspaceApiKeyKeys.all);
      },
    },
  );
};
