import { Table } from "@tanstack/react-table";

import { ResultDataAndAuxRowV2 } from "src/dataTable/types";
import { IntermediateTableColumnPicker } from "src/nodeEditor/IntermediateTableColumnPicker";
import { RunInformationPill } from "src/nodeEditor/RunInformationPill";

export const TableHeaderWithColumnPicker: React.FC<{
  tableInstance: Table<ResultDataAndAuxRowV2>;
}> = ({ tableInstance }) => {
  return (
    <div className="mb-3 flex items-start gap-x-2.5 pr-6">
      <div className="flex-1">
        <RunInformationPill />
      </div>
      <IntermediateTableColumnPicker tableInstance={tableInstance} />
    </div>
  );
};
