import { useSortable } from "@dnd-kit/sortable";
import { CSS, Transform } from "@dnd-kit/utilities";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import {
  FieldArrayWithId,
  FieldErrorsImpl,
  FieldError,
  Merge,
  useFormContext,
  useWatch,
} from "react-hook-form";

import { Icon } from "src/base-components/Icon";
import { ReorderHandle } from "src/base-components/ReorderHandle";
import { SchemaOptions } from "src/router/SearchParams";
import { SchemaAdvancedOptions } from "src/schema/SchemaAdvancedOptions";
import { SchemaEnumRows } from "src/schema/SchemaEnumRows";
import { SchemaNameInput } from "src/schema/SchemaNameInput";
import { SchemaSensitiveToggle } from "src/schema/SchemaSensitiveToggle";
import { SchemaTypeSelector } from "src/schema/SchemaTypeSelector";
import { PropertyUIT, SchemaUIT } from "src/schema/utils";

type PropsT = {
  property: FieldArrayWithId<
    {
      schemaFields: PropertyUIT[];
    },
    "schemaFields",
    "id"
  >;
  errorStatus?: Merge<FieldError, FieldErrorsImpl<PropertyUIT>>;
  onDelete: () => void;
  fieldIndex: number;
  checkNameUnique: (name: string) => boolean;
  readonly?: boolean;
  displaySensitiveToggle: boolean;
  type: SchemaOptions;
  id: string;
};

export const SchemaEditRow: React.FC<PropsT> = ({
  readonly = false,
  errorStatus,
  onDelete,
  fieldIndex,
  checkNameUnique,
  displaySensitiveToggle,
  type,
  id,
}) => {
  const { register, control } = useFormContext<SchemaUIT>();

  const { listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({
      id: id,
    });

  const style = {
    transform: CSS.Transform.toString(
      transform ? ({ ...transform, scaleY: 1 } as Transform) : null,
    ),
    transition,
  };

  const propertyEnum = useWatch({
    control,
    name: `properties.${fieldIndex}.enum`,
  });

  return (
    <div
      ref={setNodeRef}
      className="mt-3"
      data-loc={`schema-edit-row-${fieldIndex}`}
      style={style}
    >
      <div className="flex w-full flex-row items-center gap-x-2">
        <ReorderHandle
          color="text-gray-500"
          immutable={readonly}
          listeners={listeners}
        />
        <div className="grow">
          <SchemaNameInput
            disabled={readonly}
            errorStatus={errorStatus}
            formProps={register(`properties.${fieldIndex}.fieldName`, {
              required: true,
              //match anything not containing a '"' or a '\'
              pattern: /^[^\\"]*$/,
              validate: {
                unique: checkNameUnique,
              },
            })}
          />
        </div>
        <div className="w-[140px]">
          <SchemaTypeSelector
            disabled={readonly}
            index={fieldIndex}
            rowName={`properties.${fieldIndex}`}
            type={type}
          />
        </div>
        <div className="flex w-auto items-center gap-x-2">
          {displaySensitiveToggle && (
            <SchemaSensitiveToggle
              disabled={readonly}
              rowName={`properties.${fieldIndex}`}
            />
          )}
          <SchemaAdvancedOptions
            disabled={readonly}
            rowName={`properties.${fieldIndex}`}
          />
          <Icon
            color="text-gray-500 hover:text-red-600"
            dataLoc="schema-field-delete"
            disabled={readonly}
            icon={faTrashAlt}
            size="xs"
            onClick={onDelete}
          />
        </div>
      </div>
      {Array.isArray(propertyEnum) && !isDragging && (
        <SchemaEnumRows
          enumFormLocation={`properties.${fieldIndex}.enum`}
          errorStatus={errorStatus?.enum}
          immutable={readonly}
          withValidation
        />
      )}
    </div>
  );
};
