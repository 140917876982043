import { faRefresh, faSliders } from "@fortawesome/pro-regular-svg-icons";
import { Root as AccordionRoot } from "@radix-ui/react-accordion";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { DecisionEnvironment } from "src/api/types";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { EditorAccordionItem } from "src/base-components/EditorAccordionItem";
import { EnvironmentPill } from "src/base-components/EnvironmentPill";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { mockDecisions, mockFlow } from "src/decisionsOverview/mocks";
import { SubHeader } from "src/flow/SubHeader";
import { DecisionHistoryTable } from "src/flow/decisionHistory/TableWrapper";
import { DashboardContent } from "src/layout/DashboardContent";
import { URLKeys } from "src/router/SearchParams";
import { DashboardPageParamsT, getUrlToWsDashboard } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

const useQueryParams = () => {
  const [searchParams] = useSearchParams();

  return {
    entityId: searchParams.get(URLKeys.EntityId),
    decisionId: searchParams.get(URLKeys.DecisionId),
  };
};

const RightContent: React.FC<{ count: number }> = ({ count }) => (
  <div
    className={twJoin(
      "flex h-13 items-center gap-x-2 border-b border-b-gray-200 px-6",
    )}
  >
    <Pill size="sm" variant="gray">
      <Pill.Text>{count} Decisions</Pill.Text>
    </Pill>
    <Icon color="text-gray-500" icon={faSliders} size="xs" />
  </div>
);

type ItemProps = {
  title: string;
  count: number;
};

const FlowAccordionItem: React.FC<ItemProps> = ({ title, count }) => (
  <EditorAccordionItem
    className="border-b border-b-gray-200"
    headerClassName="border-b-gray-200 border-b flex-grow"
    headerContent={<RightContent count={count} />}
    title={title}
    value={`flow-${title}`}
  >
    <DecisionHistoryTable
      canFetchNextPage={false}
      changeFilterFields={() => {}}
      decisionHistory={mockDecisions}
      env={DecisionEnvironment.LIVE}
      fetchNextPage={() => {}}
      filterFields={{}}
      flow={mockFlow}
      isFetching={false}
    />
  </EditorAccordionItem>
);

const FlowAccordion: React.FC = () => {
  const mockData = [
    { title: "Consumer Load Underwriting", count: 4 },
    { title: "KYC", count: 6 },
    { title: "Onboarding", count: 1 },
  ] as const;
  return (
    <AccordionRoot defaultValue={[]} type="multiple">
      {mockData.map((item) => (
        <FlowAccordionItem
          key={item.title}
          count={item.count}
          title={item.title}
        />
      ))}
    </AccordionRoot>
  );
};

export const DecisionsOverview: React.FC = () => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const { entityId, decisionId } = useQueryParams();
  const [decisionEnv, setDecisionEnv] = useState<DecisionEnvironment>(
    DecisionEnvironment.LIVE,
  );

  return (
    <DashboardContent
      Header={
        <SubHeader
          backTo={getUrlToWsDashboard({ orgId, wsId })}
          title={
            <>
              <span className="whitespace-nowrap align-middle">
                {decisionId
                  ? "Decision ID "
                  : entityId
                    ? "Decisions for Entity ID "
                    : "Decisions "}
              </span>
              <div className="inline-block max-w-60 overflow-hidden align-middle">
                <Pill size="sm" variant="gray" maxWidth>
                  <Pill.Text fontType="code">
                    {decisionId ?? entityId ?? "-"}
                  </Pill.Text>
                </Pill>
              </div>
            </>
          }
          titleAction={
            <>
              <CopyTextIcon
                tooltip="Copy ID"
                value={decisionId ?? entityId ?? ""}
              />
              <EnvironmentPill value={decisionEnv} onChange={setDecisionEnv} />
            </>
          }
        >
          <SubHeader.SearchBox
            placeholder="Search by Deicision ID or Entity ID ..."
            onChange={() => {}}
          />
          <SubHeader.DatePicker
            placeholder="All time"
            value={undefined}
            onChange={() => {}}
          />
          <SubHeader.Button
            dataLoc="refresh-decisions"
            icon={faRefresh}
            tooltip="Refresh"
            onClick={() => {}}
          />
        </SubHeader>
      }
    >
      <div className="mx-7 overflow-hidden rounded-2xl border border-gray-200 bg-white">
        <FlowAccordion />
      </div>
    </DashboardContent>
  );
};
