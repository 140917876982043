import { PlotOptions } from "@observablehq/plot";

export const PLOT_OPTIONS: PlotOptions = {
  // Space for the left and bottom axis labels
  marginLeft: 60,
  marginBottom: 60,
  marginRight: 45,

  style: {
    // Gray/400
    color: "9C9FAF",
    // font size of any text inside the chart
    fontSize: "12px",
  },

  y: {
    // Gray/100
    grid: "F3F4F6",
    // 4 ticks on the y axis + 1 one the zero level
    ticks: 4,
    // Hide ticks but not the tick labels
    tickSize: 0,
    // Hide axis label
    label: null,
    zero: true,
  },

  x: {
    type: "band",
    tickSize: 0,
    label: null,
    // Space between the chart and tick labels
    tickPadding: 38,
  },
} as const;
