import { faEdit, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { MouseEventHandler } from "react";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/base-components/Tooltip";
import { RawCell } from "src/dataTable/RawCell";

type PropsT = {
  onEditClick: MouseEventHandler;
  onDeleteClick: MouseEventHandler;
};

export const ApiKeyActions: React.FC<PropsT> = ({
  onEditClick,
  onDeleteClick,
}) => {
  const actions = (
    <>
      <Tooltip align="center" placement="top" title="Rename" asChild>
        <div className="mx-1">
          <Icon
            color="text-gray-500 hover:text-gray-700"
            icon={faEdit}
            size="xs"
            onClick={onEditClick}
          />
        </div>
      </Tooltip>
      <Tooltip align="center" placement="top" title="Delete" asChild>
        <div className="ml-1">
          <Icon
            color="text-gray-500 hover:text-gray-700"
            icon={faTrashCan}
            size="xs"
            onClick={onDeleteClick}
          />
        </div>
      </Tooltip>
    </>
  );

  return (
    <RawCell
      cell={actions}
      classNameOverrides="invisible flex items-right justify-end group-hover:visible max-w-0"
    />
  );
};
