import { useCallback, useMemo } from "react";

import { NodeTestRunResult } from "src/api/types";
import { StatusFilterKey } from "src/dataTable/TableUtils";
import { mergeDataToTableDate } from "src/dataTable/utils";
import {
  usePaginatedNodeRunFailureData,
  usePaginatedNodeRunIgnoredData,
  usePaginatedNodeRunSuccessData,
  usePaginatedNodeRunSuccessMatchData,
  usePaginatedNodeRunSuccessMismatchData,
} from "src/nodeEditor/api/queries";
import { useAuthoringContext } from "src/router/routerContextHooks";

const MINIMUM_UNDISPLAYED_RECORDS_FOR_NOT_REFETCHING = 50;

export const usePaginatedResults = (
  testResult: NodeTestRunResult,
  isOutputNode: boolean,
  statusFilter: StatusFilterKey,
) => {
  const { workspace } = useAuthoringContext();

  const archetypeTesting = !!(
    isOutputNode && testResult.expected_output_columns?.length
  );

  const successfulRowsQuery = usePaginatedNodeRunSuccessData(
    testResult,
    workspace.base_url!,
    archetypeTesting,
  );
  const successfulMatchRowsQuery = usePaginatedNodeRunSuccessMatchData(
    testResult,
    workspace.base_url!,
    archetypeTesting,
  );
  const successfulMismatchRowsQuery = usePaginatedNodeRunSuccessMismatchData(
    testResult,
    workspace.base_url!,
    archetypeTesting,
  );
  const ignoredRowsQuery = usePaginatedNodeRunIgnoredData(
    testResult,
    workspace.base_url!,
  );
  const failureRowsQuery = usePaginatedNodeRunFailureData(
    testResult,
    workspace.base_url!,
  );

  const allSuccessPagesFetched = !successfulRowsQuery.hasNextPage;
  const allSuccessMatchPagesFetched = !successfulMatchRowsQuery.hasNextPage;
  const allSuccessMismatchPagesFetched =
    !successfulMismatchRowsQuery.hasNextPage;
  const allIgnoredPagesFetched = !ignoredRowsQuery.hasNextPage;
  const allFailurePagesFetched = !failureRowsQuery.hasNextPage;

  const allPagesFetched =
    allSuccessPagesFetched &&
    allIgnoredPagesFetched &&
    allFailurePagesFetched &&
    allSuccessMatchPagesFetched &&
    allSuccessMismatchPagesFetched;

  const isFetching =
    successfulRowsQuery.isFetching ||
    successfulMatchRowsQuery.isFetching ||
    successfulMismatchRowsQuery.isFetching ||
    ignoredRowsQuery.isFetching ||
    failureRowsQuery.isFetching;

  const additionalDataCanBeFetched = !isFetching && !allPagesFetched;

  const successIsExcluded =
    statusFilter !== "all" && statusFilter !== "success";
  const successMatchIsExcluded =
    statusFilter !== "all" && statusFilter !== "success_match";
  const successMismatchIsExcluded =
    statusFilter !== "all" && statusFilter !== "success_mismatch";
  const ignoreIsExcluded = statusFilter !== "all" && statusFilter !== "ignored";
  const failureIsExcluded =
    statusFilter !== "all" && statusFilter !== "failure";

  const firstPagesData = useMemo(() => {
    if (isOutputNode) {
      const firstSuccessfulMatchPageData =
        successfulMatchRowsQuery.data?.pages.at(0)?.data;
      const firstSuccessfulMismatchPageData =
        successfulMismatchRowsQuery.data?.pages.at(0)?.data;
      const firstFailurePageData = failureRowsQuery.data?.pages.at(0)?.data;
      const firstIgnoredPageData = ignoredRowsQuery.data?.pages.at(0)?.data;
      const firstPagesLoaded =
        firstSuccessfulMatchPageData &&
        firstSuccessfulMismatchPageData &&
        firstFailurePageData &&
        firstIgnoredPageData;

      return firstPagesLoaded
        ? [
            ...firstSuccessfulMatchPageData,
            ...firstSuccessfulMismatchPageData,
            ...firstFailurePageData,
            ...firstIgnoredPageData,
          ].sort((a, b) => a.index - b.index)
        : undefined;
    } else {
      const firstSuccessfulPageData =
        successfulRowsQuery.data?.pages.at(0)?.data;
      const firstFailurePageData = failureRowsQuery.data?.pages.at(0)?.data;
      const firstIgnoredPageData = ignoredRowsQuery.data?.pages.at(0)?.data;
      const firstPagesLoaded =
        firstSuccessfulPageData && firstFailurePageData && firstIgnoredPageData;
      return firstPagesLoaded
        ? [
            ...firstSuccessfulPageData,
            ...firstFailurePageData,
            ...firstIgnoredPageData,
          ].sort((a, b) => a.index - b.index)
        : undefined;
    }
  }, [
    successfulMatchRowsQuery.data?.pages,
    successfulMismatchRowsQuery.data?.pages,
    successfulRowsQuery.data?.pages,
    failureRowsQuery.data?.pages,
    ignoredRowsQuery.data?.pages,
    isOutputNode,
  ]);

  const resultsToDisplay = useMemo(() => {
    const successDataFlatened = successfulRowsQuery.data?.pages.flatMap(
      (page) => page.data,
    );
    const successMatchDataFlatened =
      successfulMatchRowsQuery.data?.pages.flatMap((page) => page.data);
    const successMismatchDataFlatened =
      successfulMismatchRowsQuery.data?.pages.flatMap((page) => page.data);
    const ignoredDataFlatened = ignoredRowsQuery.data?.pages.flatMap(
      (page) => page.data,
    );
    const failureDataFlatened = failureRowsQuery.data?.pages.flatMap(
      (page) => page.data,
    );

    if (
      !(
        (successDataFlatened ||
          (successMatchDataFlatened && successMismatchDataFlatened)) &&
        ignoredDataFlatened &&
        failureDataFlatened
      )
    )
      return [];
    else {
      // We calculate what the last original data index is until which we are guaranteed to already have fetched the data from all three queries.
      // Only data until that index will be displayed.
      const guaranteedGaplessIndex = Math.min(
        allSuccessPagesFetched || successIsExcluded
          ? Infinity
          : (successDataFlatened?.at(-1)?.index ?? Infinity),
        allSuccessMatchPagesFetched || successMatchIsExcluded
          ? Infinity
          : (successMatchDataFlatened?.at(-1)?.index ?? Infinity),
        allSuccessMismatchPagesFetched || successMismatchIsExcluded
          ? Infinity
          : (successMismatchDataFlatened?.at(-1)?.index ?? Infinity),
        allIgnoredPagesFetched || ignoreIsExcluded
          ? Infinity
          : (ignoredDataFlatened.at(-1)?.index ?? Infinity),
        allFailurePagesFetched || failureIsExcluded
          ? Infinity
          : (failureDataFlatened.at(-1)?.index ?? Infinity),
      );
      return mergeDataToTableDate(
        successDataFlatened ?? [],
        successMatchDataFlatened ?? [],
        successMismatchDataFlatened ?? [],
        ignoredDataFlatened,
        failureDataFlatened,
      )
        .slice(0, guaranteedGaplessIndex)
        .filter((row) => row.type === statusFilter || statusFilter === "all");
    }
  }, [
    successfulRowsQuery.data?.pages,
    successfulMatchRowsQuery.data?.pages,
    successfulMismatchRowsQuery.data?.pages,
    ignoredRowsQuery.data?.pages,
    failureRowsQuery.data?.pages,
    allSuccessPagesFetched,
    allSuccessMatchPagesFetched,
    allSuccessMismatchPagesFetched,
    allIgnoredPagesFetched,
    allFailurePagesFetched,
    successIsExcluded,
    successMatchIsExcluded,
    successMismatchIsExcluded,
    ignoreIsExcluded,
    failureIsExcluded,
    statusFilter,
  ]);

  const fetchAdditionalData = useCallback(() => {
    const lastDisplayedIndex = resultsToDisplay.at(-1)?.index ?? 0;
    const refetchIndexThreshold =
      lastDisplayedIndex + MINIMUM_UNDISPLAYED_RECORDS_FOR_NOT_REFETCHING;

    const queries = [
      successfulRowsQuery,
      successfulMatchRowsQuery,
      successfulMismatchRowsQuery,
      ignoredRowsQuery,
      failureRowsQuery,
    ] as const;

    if (queries.every((query) => !query.data)) return;
    if (queries.some((query) => query.isFetching)) return;

    // fetch next pages for queries that are not yet depleeted and whos last content is or is almost being displayed
    const lastFetchedSuccessfulIndex = successfulRowsQuery.data?.pages
      .at(-1)
      ?.data.at(-1)?.index;
    if (
      !(allSuccessPagesFetched || successIsExcluded) &&
      lastFetchedSuccessfulIndex &&
      lastFetchedSuccessfulIndex < refetchIndexThreshold
    ) {
      successfulRowsQuery.fetchNextPage();
    }
    const lastFetchedSuccessfulMatchIndex = successfulMatchRowsQuery.data?.pages
      .at(-1)
      ?.data.at(-1)?.index;
    if (
      !(allSuccessMatchPagesFetched || successMatchIsExcluded) &&
      lastFetchedSuccessfulMatchIndex &&
      lastFetchedSuccessfulMatchIndex < refetchIndexThreshold
    ) {
      successfulMatchRowsQuery.fetchNextPage();
    }
    const lastFetchedSuccessfulMismatchIndex =
      successfulMismatchRowsQuery.data?.pages.at(-1)?.data.at(-1)?.index;
    if (
      !(allSuccessMismatchPagesFetched || successMismatchIsExcluded) &&
      lastFetchedSuccessfulMismatchIndex &&
      lastFetchedSuccessfulMismatchIndex < refetchIndexThreshold
    ) {
      successfulMismatchRowsQuery.fetchNextPage();
    }

    const lastFetchedIgnoredIndex = ignoredRowsQuery.data?.pages
      .at(-1)
      ?.data.at(-1)?.index;
    if (
      !(allIgnoredPagesFetched || ignoreIsExcluded) &&
      lastFetchedIgnoredIndex &&
      lastFetchedIgnoredIndex < refetchIndexThreshold
    ) {
      ignoredRowsQuery.fetchNextPage();
    }

    const lastFetchedFailureIndex = failureRowsQuery.data?.pages
      .at(-1)
      ?.data.at(-1)?.index;
    if (
      !(allFailurePagesFetched || failureIsExcluded) &&
      lastFetchedFailureIndex &&
      lastFetchedFailureIndex < refetchIndexThreshold
    ) {
      failureRowsQuery.fetchNextPage();
    }
  }, [
    allSuccessPagesFetched,
    allSuccessMatchPagesFetched,
    allSuccessMismatchPagesFetched,
    allIgnoredPagesFetched,
    allFailurePagesFetched,
    successfulRowsQuery,
    successfulMatchRowsQuery,
    successfulMismatchRowsQuery,
    ignoredRowsQuery,
    failureRowsQuery,
    resultsToDisplay,
    successIsExcluded,
    successMatchIsExcluded,
    successMismatchIsExcluded,
    ignoreIsExcluded,
    failureIsExcluded,
  ]);

  return {
    resultsToDisplay,
    firstPagesData,
    fetchAdditionalData,
    isFetching,
    additionalDataCanBeFetched,
    ignoredRowsQuery,
    failureRowsQuery,
  } as const;
};
