import { useEffect, useState } from "react";

/**
 * Exposes the document.visibilityState in hook form. https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilityState
 * @returns whether the document is visible
 */
export const usePageVisibility = () => {
  const [pageVisible, setPageVisible] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setPageVisible(document.visibilityState === "visible");
    };
    window.addEventListener("visibilitychange", handleVisibilityChange, true);
    return () => {
      window.removeEventListener(
        "visibilitychange",
        handleVisibilityChange,
        true,
      );
    };
  }, []);
  return pageVisible;
};
