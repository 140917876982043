import { v4 } from "uuid";

import { Operators, Junctions, SplitBranch } from "src/clients/flow-api";

export const getNewSplitBranch = (edge_id: string = ""): SplitBranch => ({
  id: v4(),
  junction: Junctions.AND,
  clauses: [
    {
      id_left: v4(),
      id_right: v4(),
      left: "",
      right: "",
      operator: Operators.EQ,
    },
  ],
  edge_id: edge_id,
});

export const getDefaultBranch = (edge_id: string = ""): SplitBranch => ({
  id: v4(),
  junction: Junctions.AND,
  edge_id: edge_id,
  clauses: [],
});
