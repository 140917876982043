import { v4 as uuidV4 } from "uuid";

import { ConfigT, OutputMappingT } from "src/integrationNode/types";

export const defaultResourceConfig: ConfigT = {
  caching: { active: false, max_age_seconds: "86400" },
  timeout: { active: false, timeout_seconds: "15" },
  error: { allow_4xx: false, allow_5xx: false },
  tenant_id: {
    id: uuidV4(),
    expression: "",
  },
  raw_requests: { has_raw_requests_enabled_in_node: false },
};

export const getDefaultOutputMapping = (): OutputMappingT => ({
  id: uuidV4(),
  displayName: "Bundle API response",
  assignedTo: "",
  selected: false,
});

export const getNamedOutputMapping = (
  name: string,
  hint?: string,
): OutputMappingT => ({
  id: uuidV4(),
  displayName: name,
  assignedTo: "",
  selected: false,
  hint,
});
