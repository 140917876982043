import { Row, Table as TableT } from "@tanstack/react-table";
import { CanceledError } from "axios";
import { isBefore } from "date-fns";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import {
  DecisionHistoryRecordV2,
  getHistoryDecision,
  SingleDecisionQueryErrorReason,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { DecisionEnvironment } from "src/api/types";
import { getHistoryEmptyStateWording } from "src/flow/decisionHistory/SharedEmptyStateWording";
import {
  DecisionStatusCode,
  getDecisionStatus,
} from "src/flow/decisionHistory/SharedStatusColumn";
import { useAuthoringUIActions } from "src/flowContainer/AuthoringUIContext";
import { useJobs } from "src/jobs/api/queries";
import { usePowertools, useXrayTraceURL } from "src/powertools/hooks";
import { URLKeys } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { AuthorPageParamsT } from "src/router/urls";
import { useNodeHighlighting } from "src/store/NodeHighlighting";
import { useGraphStore } from "src/store/StoreProvider";
import { getNodeRunState } from "src/store/runState/RunState";
import * as logger from "src/utils/logger";
import { isHistoricalRunState } from "src/utils/predicates";
import { useParamsDecode } from "src/utils/useParamsDecode";
import { TableComp } from "src/versionDecisionHistory/BaseTable";
import {
  getVersionDecisionHistoryColumns,
  CONTROL_COLUMN_ID,
} from "src/versionDecisionHistory/Columns";
import { DecisionHistoryEmpty } from "src/versionDecisionHistory/Empty";
import {
  useHistorySelectedDecision,
  useSetHistorySelectedDecision,
} from "src/versionDecisionHistory/historySelectedDecisionStore";

const renderEmptyState = (
  singleDecisionError: SingleDecisionQueryErrorReason | undefined,
  emptyData: boolean,
) => {
  if (singleDecisionError || emptyData) {
    const wording = getHistoryEmptyStateWording(
      singleDecisionError ?? "emptyData",
    );
    return <DecisionHistoryEmpty {...wording} />;
  }
};

type PropsT = {
  statusCodeFilter: DecisionStatusCode | undefined;
  setStatusCodeFilter: (
    statusCodeFilter: DecisionStatusCode | undefined,
  ) => void;
  decisionHistory: DecisionHistoryRecordV2[] | undefined;
  workspaceUrl: string;
  isDraft: boolean;
  type: DecisionEnvironment;
  disableRowsBeforeDate?: string;
  disableRowsWithEtagSmallerThan?: string;
  onAddTestCase: (decisionId: string) => void;
  canFetchNextPage: boolean;
  fetchNextPage: () => void;
  isFetching: boolean;
  singleDecisionError?: SingleDecisionQueryErrorReason;
};

export const getDecisionDisabledReason = (
  decision: DecisionHistoryRecordV2,
  disableRowsBeforeEtag: string | undefined,
  disableRowsBeforeDate: string | undefined,
): "outdated" | "internal_error" | false => {
  if (decision.status_code === "500") {
    return "internal_error";
  }
  if (!decision.is_sandbox) {
    return false;
  }

  if (decision.flow_version_etag && disableRowsBeforeEtag) {
    return decision.flow_version_etag < disableRowsBeforeEtag
      ? "outdated"
      : false;
  }

  if (disableRowsBeforeDate) {
    return isBefore(
      new Date(decision.start_time),
      new Date(disableRowsBeforeDate),
    )
      ? "outdated"
      : false;
  }

  return false;
};

export const DecisionHistoryTable: React.FC<PropsT> = ({
  statusCodeFilter,
  setStatusCodeFilter,
  decisionHistory,
  workspaceUrl,
  isDraft,
  type,
  disableRowsBeforeDate,
  disableRowsWithEtagSmallerThan,
  onAddTestCase,
  canFetchNextPage,
  fetchNextPage,
  isFetching,
  singleDecisionError,
}) => {
  const [_, setSearchParams] = useSearchParams();
  const {
    clearHistoryRunState,
    setSelectedNode,
    setHistoryRunState,
    selectErroredNode,
  } = useGraphStore();
  const [selectionStatus, setSelectionStatus] = useState<
    | { tag: "ready" | "active" }
    | { tag: "loading"; abortController: AbortController }
  >({ tag: "ready" });
  const [xrayTraceID, setXrayTraceID] = useState<string | null>(null);

  const { orgId, wsId } = useParamsDecode<AuthorPageParamsT>();
  const { flow, version, workspace } = useAuthoringContext();
  const { data: jobs, isInitialLoading: jobsQueryIsInitialLoading } = useJobs(
    workspace.base_url!,
    flow.id,
  );
  const { highlightNodes, clearHighlighting } = useNodeHighlighting();
  const isPowertoolsEnabled = usePowertools();
  const xrayTraceURL = useXrayTraceURL(xrayTraceID);

  const currentDecision = useHistorySelectedDecision();
  const setCurrentDecision = useSetHistorySelectedDecision();

  const handleRowClick = (
    row: Row<DecisionHistoryRecordV2>,
    table: TableT<DecisionHistoryRecordV2>,
  ) => {
    if (selectionStatus.tag === "loading") {
      selectionStatus.abortController.abort();
    }

    const wasAlreadySelected = row.getIsSelected();
    // Clear run state
    if (wasAlreadySelected) {
      row.toggleSelected(false);
      setSelectionStatus({ tag: "ready" });
      setXrayTraceID(null);
      clearHistoryRunState();
      clearHighlighting();
      setSelectedNode(null);
      setCurrentDecision(null);
      setSearchParams((prevParams) => {
        prevParams.delete(URLKeys.DecisionId);
        return prevParams;
      });
      // handle the selection and load decision
    } else {
      table
        .getSelectedRowModel()
        .flatRows.forEach((row) => row.toggleSelected(false));
      row.toggleSelected(true);

      const abortController = new AbortController();
      setSelectionStatus({ tag: "loading", abortController });
      handleRowSelection(row.original, abortController.signal);
    }
  };

  const highlightNodesWithRunState = useCallback(
    (runState: DecisionHistoryRecordV2) => {
      if ("node_results" in runState) {
        const filteredNodes = version.graph?.nodes.filter((node) => {
          const runState = getNodeRunState(node.id, version.id);
          return runState && isHistoricalRunState(runState);
        });

        if (filteredNodes) {
          highlightNodes(filteredNodes?.map((node) => node.id));
        }
      }
    },
    [version, highlightNodes],
  );

  const { setSelectedResultsRow } = useAuthoringUIActions();

  const handleRowSelection = async (
    row: DecisionHistoryRecordV2,
    signal: AbortSignal,
  ) => {
    try {
      const decision = (
        await getHistoryDecision({
          baseUrl: workspaceUrl,
          decisionId: row.id,
          // Only include additional fields when powertools are active
          includeExtraFields: isPowertoolsEnabled,
          signal,
          includeNodeResultsData: false,
        })
      ).data;
      if (decision != null) {
        setCurrentDecision(decision);
        setHistoryRunState(decision);
        setSelectionStatus({ tag: "active" });
        setXrayTraceID(decision?.xray_trace_id ?? null);
        // When a decision history run is being shown, we want to highlight the nodes which were run
        highlightNodesWithRunState(decision);
        setSelectedResultsRow(null);
        setSearchParams((prevParams) => {
          prevParams.set(URLKeys.DecisionId, decision.id);
          return prevParams;
        });
      }
      if (getDecisionStatus(decision.status_code) === "success") {
        setSelectedNode(null);
      } else if (getDecisionStatus(decision.status_code) === "error") {
        selectErroredNode();
      }
    } catch (err) {
      setCurrentDecision(null);
      if (!(err instanceof CanceledError)) {
        logger.error(err);
      }
    }
  };

  const shouldRowBeDisabled = (
    record: DecisionHistoryRecordV2,
  ): "outdated" | "internal_error" | false => {
    return getDecisionDisabledReason(
      record,
      disableRowsWithEtagSmallerThan,
      disableRowsBeforeDate,
    );
  };

  if (type === "live" && isDraft) {
    return (
      <DecisionHistoryEmpty
        description="A record of decisions made by this particular version will show up here once the version is published."
        headline="This is a draft version and has no decision history yet."
      />
    );
  }
  const emptyData = Boolean(
    !isFetching && decisionHistory && decisionHistory.length === 0,
  );
  const showingGenericNotFound = emptyData && !isFetching;

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <div
        className={twJoin(
          !showingGenericNotFound && !singleDecisionError && "min-h-0 flex-1",
        )}
      >
        <TableComp
          canFetchNextPage={canFetchNextPage}
          cellPropsGetter={(cell, row) => {
            const isSelected = row.getIsSelected();
            const isDisabled = shouldRowBeDisabled(row.original);
            const backgroundGrayed = isSelected || isDisabled;

            return {
              classNameOverrides:
                cell.column.id === CONTROL_COLUMN_ID
                  ? twJoin(
                      "sticky right-0 z-0 box-border border-r-6",
                      isSelected
                        ? "border-r-indigo-400 bg-gray-50"
                        : "border-r-transparent",
                      backgroundGrayed
                        ? "group-hover/row:bg-gray-50"
                        : "group-hover/row:bg-white",
                    )
                  : undefined,
            };
          }}
          columns={getVersionDecisionHistoryColumns({
            orgId,
            wsId,
            flow,
            jobs: jobs ?? [],
            version,
            env: type,
            statusCodeFilter,
            setStatusCodeFilter,
            selectedRowIsLoading: selectionStatus.tag === "loading",
            shouldRowBeDisabled,
            onAddTestCase,
            isPowertoolsEnabled,
            xrayTraceURL,
          })}
          currentDecision={currentDecision}
          // Show the table only after we have fetched the jobs
          data={(!jobsQueryIsInitialLoading && decisionHistory) || []}
          dataLoc="decision-history-table"
          fetchNextPage={fetchNextPage}
          frameClassName={twJoin(
            "overflow-auto border-t border-gray-200",
            emptyData && "h-16",
          )}
          handleRowClick={handleRowClick}
          headerCellPropsGetter={(column) => {
            return {
              classNameOverrides:
                column.id === CONTROL_COLUMN_ID
                  ? "sticky right-0 top-0 bg-white"
                  : undefined,
            };
          }}
          isFetching={isFetching || jobsQueryIsInitialLoading}
          rowPropsGetter={(row, table) => {
            const isSelected = row.getIsSelected();
            const isDisabled = shouldRowBeDisabled(row.original);

            return {
              "data-loc": `decision-history-row-${row.original.status_code}`,
              onClick: !isDisabled
                ? () => handleRowClick(row, table)
                : undefined,
              classNameOverrides: twJoin(
                "group/row",
                isSelected && "group/row-selected",
                !isDisabled && "cursor-pointer",
                (isSelected || isDisabled) && "bg-gray-50",
              ),
            };
          }}
          skeletonRowsClassnames={[
            "w-[35px] pl-3.5",
            "w-[90px]",
            "w-[100px]",
            "w-[150px]",
          ]}
        />
      </div>
      {renderEmptyState(singleDecisionError, emptyData)}
    </div>
  );
};
