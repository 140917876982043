import React from "react";

const movingElement = (
  <div
    className="absolute inset-0 -translate-x-full transform bg-gradient-to-r from-gray-100 via-white to-gray-100"
    style={{ animation: "shimmer 1s infinite" }}
  />
);

export const AddNodeCardSkeleton: React.FC = () => {
  return (
    <div className="my-4 w-full rounded-lg border border-gray-200 px-2 py-2">
      <div className="flex">
        <div className="relative h-10 w-10 overflow-hidden rounded-lg bg-gray-100">
          {movingElement}
        </div>
        <div className="ml-2.5 flex flex-col justify-center">
          <div className="relative h-4 w-14 overflow-hidden rounded bg-gray-100">
            {movingElement}
          </div>
          <div className="relative mt-2 h-4 w-46 overflow-hidden rounded bg-gray-100">
            {movingElement}
          </div>
        </div>
      </div>
    </div>
  );
};
