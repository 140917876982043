import { makeConnectionTemplate } from "src/connections/types";

const INQUIRY_REASON_OPTIONS = [
  {
    key: "ABD",
    value: "ABD: Credit check inquiry before concluding a service contract",
  },
  {
    key: "ABF",
    value:
      "ABF: Credit check inquiry before taking over/buying a claim or assuming a del credere risk",
  },
  {
    key: "ABI",
    value:
      "ABI: Credit check inquiry before or in connection with initiating or conducting debt collection measures",
  },
  {
    key: "ABK",
    value:
      "ABK: Credit check inquiry before concluding a purchase contract, especially invoice or installment purchase",
  },
  {
    key: "ABL",
    value:
      "ABL: Credit check inquiry before concluding a leasing or rental contract (movables)",
  },
  {
    key: "ABV",
    value: "ABV: Credit check inquiry before concluding an insurance contract",
  },
  {
    key: "ABW",
    value: "ABW: Credit check inquiry before concluding a contract for work",
  },
  {
    key: "BBS",
    value: "BBS: Credit check before concluding a home savings contract",
  },
  {
    key: "BBV",
    value:
      "BBV: Credit check for fraud prevention in the case of insurance claims",
  },
  {
    key: "BER",
    value: "BER: Credit check due to an investigation order (-> credit agency)",
  },
  {
    key: "BFT",
    value:
      "BFT: Credit check before opening/setting up a fixed-line telecommunications account",
  },
  {
    key: "BFV",
    value: "BFV: Credit check before concluding a franchise agreement",
  },
  {
    key: "BKA",
    value: "BKA: Credit check due to a credit or customer card application",
  },
  { key: "BKE", value: "BKE: Credit check before opening an account" },
  { key: "BKK", value: "BKK: Condition inquiry credit" },
  { key: "BKV", value: "BKV: Credit check before credit grant" },
  {
    key: "BMT",
    value:
      "BMT: Credit check before opening/setting up a mobile telecommunications account",
  },
  {
    key: "BMV",
    value:
      "BMV: Credit check before concluding a rental contract (real estate)",
  },
  { key: "BSE", value: "BSE: Credit check with existing written consent" },
  {
    key: "BZV",
    value:
      "BZV: Credit check in the course of verifying the reliability of an insurance intermediary or an employee of the insurance external service",
  },
  {
    key: "PEG",
    value:
      "PEG: Testing of postal accessibility within a business relationship",
  },
];

const REQUESTED_SERVICES_OPTIONS = [
  { key: "Score", value: "Score" },
  { key: "PersonCreditCheck", value: "PersonCreditCheck" },
  { key: "PersonFraudCheck", value: "PersonFraudCheck" },
  { key: "PersonIdentCheck", value: "PersonIdentCheck" },
  { key: "PersonIdentAddress", value: "PersonIdentAddress" },
  { key: "PersonIdentPhone", value: "PersonIdentPhone" },
  { key: "PersonIdentNewCustomerCheck", value: "PersonIdentNewCustomerCheck" },
  { key: "PersonIdentFeatureList", value: "PersonIdentFeatureList" },
  { key: "BankAccountCreditCheck", value: "BankAccountCreditCheck" },
  { key: "BankAccountFraudCheck", value: "BankAccountFraudCheck" },
  { key: "BankAccountIdentCheck", value: "BankAccountIdentCheck" },
  { key: "BankAccountIdentFeatureList", value: "BankAccountIdentFeatureList" },
  { key: "EmailFraudCheck", value: "EmailFraudCheck" },
  { key: "EmailIdentFeatureList", value: "EmailIdentFeatureList" },
];

export const ExperianGermanyTemplate = makeConnectionTemplate({
  provider: "experian_germany",
  secrets: [
    { key: "client_id", name: "Client ID", required: true },
    {
      key: "certificate",
      name: "SSL certificate",
      required: true,
      type: "multiline",
      hint: 'Please include the "BEGIN" and "END" lines',
    },
    {
      key: "private_key",
      name: "SSL private key",
      required: true,
      type: "multiline",
      hint: 'Please include the "BEGIN" and "END" lines',
    },
  ],
  resources: {
    experian_germany_business_information_gateway: {
      name: "Business Information Gateway",
      configKeys: [
        {
          key: "reason_of_inquiry",
          required: true,
          name: "Inquiry reason",
          type: "simple_selection",
          elements: INQUIRY_REASON_OPTIONS,
          hint: "The legitimate interest of the inquiry as defined in the BDSG [Federal Data Protection Act]",
        },
        {
          key: "requested_services",
          required: true,
          name: "Requested services",
          type: "multiple_selection",
          elements: REQUESTED_SERVICES_OPTIONS,
          hint: "The services you want to request from Experian Germany taking into consideration the contract you have with them",
        },
      ],
    },
  },
});
