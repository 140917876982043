import {
  faCompress,
  faExpand,
  faThumbTack,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faThumbTack as faThumbTackSolid,
  faGripDotsVertical,
} from "@fortawesome/pro-solid-svg-icons";
import { Popover, PopoverPanel } from "@headlessui2/react";
import { Placement } from "@popperjs/core";
import { AnimatePresence, m } from "framer-motion";
import { clamp } from "lodash";
import mergeRefs from "merge-refs";
import { ReactNode, forwardRef, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { twJoin } from "tailwind-merge";
import { useEventListener } from "usehooks-ts";

import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/base-components/Tooltip";

type FloatingWindowProps = {
  button: React.ReactNode;
  children: React.ReactNode;
  dataLoc?: string;
  isOpen?: boolean;
  placement?: Placement;
  offset?: [number, number];

  title: React.ReactNode;
  onClose?: () => void;
  onCopy: () => void;

  pinnable?: boolean;
  draggable?: boolean;
  maximizable?: boolean;
};

export const FLOATING_WINDOW_CLASS = "decide-floating-window";

export const FloatingWindow: React.FC<FloatingWindowProps> = ({
  children,
  button,
  dataLoc,
  isOpen,
  placement = "bottom-start",
  offset = [0, 4],
  title,
  onClose,
  onCopy,
  pinnable = true,
  draggable = true,
  maximizable = true,
}) => {
  const [refElement, setRefElement] = useState<Nullable<HTMLElement>>(null);
  const [popperElement, setPopperElement] =
    useState<Nullable<HTMLElement>>(null);

  const modifiers: Record<string, string | object>[] = [
    { name: "offset", options: { offset } },
  ];

  const { styles } = usePopper(refElement, popperElement, {
    strategy: "absolute",
    placement: placement,
    modifiers: modifiers,
  });

  return (
    <Popover className="h-full">
      {({ close }) => (
        <>
          <Popover.Button ref={setRefElement} as="span">
            {button}
          </Popover.Button>
          <FloatingWindowController
            ref={setPopperElement}
            dataLoc={dataLoc}
            draggable={draggable}
            isOpen={!!isOpen}
            maximizable={maximizable}
            pinnable={pinnable}
            style={styles.popper}
            title={title}
            onClose={() => {
              close();
              onClose?.();
            }}
            onCopy={onCopy}
          >
            {children}
          </FloatingWindowController>
        </>
      )}
    </Popover>
  );
};

export const FloatingWindowController = forwardRef<
  HTMLDivElement,
  {
    title: ReactNode;
    dataLoc?: string;
    onClose: () => void;
    children: ReactNode;
    onCopy?: () => void;
    isOpen: boolean;
    style: React.CSSProperties | undefined;
    pinnable: boolean;
    draggable: boolean;
    maximizable: boolean;
  }
>(
  (
    {
      title,
      dataLoc,
      onClose,
      children,
      onCopy,
      isOpen,
      style,
      pinnable,
      draggable,
      maximizable,
    },
    ref,
  ) => {
    const [isPinned, setIsPinned] = useState(false);

    return ReactDOM.createPortal(
      <AnimatePresence initial={false}>
        {(isPinned || isOpen) && (
          <FloatingWindowInternal
            ref={ref}
            dataLoc={dataLoc}
            draggable={draggable}
            isPinned={isPinned}
            maximizable={maximizable}
            style={style}
            title={title}
            onClose={onClose}
            onCopy={onCopy}
            onPin={pinnable ? () => setIsPinned(!isPinned) : undefined}
          >
            {children}
          </FloatingWindowInternal>
        )}
      </AnimatePresence>,
      document.body,
    );
  },
);

const offset = 3;
const useFloating = ({
  enabled,
  floatingWindowRef,
}: {
  enabled: boolean;
  floatingWindowRef: React.RefObject<HTMLDivElement>;
}) => {
  const grabberRef = useRef<HTMLDivElement | null>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState<
    { x: number; y: number } | undefined
  >(undefined);
  const deltaRef = useRef({ x: 0, y: 0, width: 0, height: 0 });

  useEventListener(
    "mousedown",
    (event: MouseEvent) => {
      if (
        floatingWindowRef.current &&
        enabled &&
        event.target instanceof HTMLElement &&
        event.target.dataset.grabbable
      ) {
        event.preventDefault();
        const { top, left, width, height } =
          floatingWindowRef.current.getBoundingClientRect();
        const x = event.clientX - left;
        const y = event.clientY - top;
        deltaRef.current = { x, y, width, height };
        setPosition({ x: left, y: top });
        setIsDragging(true);
      }
    },
    grabberRef,
  );
  useEventListener("mousemove", (event: MouseEvent) => {
    if (floatingWindowRef.current && isDragging && enabled) {
      const x = clamp(
        event.clientX - deltaRef.current.x,
        offset,
        window.innerWidth - deltaRef.current.width / 3 - offset,
      );
      const y = clamp(
        event.clientY - deltaRef.current.y,
        offset,
        window.innerHeight - deltaRef.current.height / 3 - offset,
      );
      setPosition({ x, y });
    }
  });
  useEventListener("mouseup", () => {
    setIsDragging(false);
  });

  return {
    isDragging,
    position,
    floatingWindowRef: floatingWindowRef,
    grabberRef,
  };
};

const parseTailwindSize = (size: `w-${number}` | `h-${number}`) => {
  return parseInt(size.split("-")[1]) * 4;
};

const useWindowResize = ({
  floatingWindowRef,
  initialWidth,
  initialHeight,
  enabled,
}: {
  floatingWindowRef: React.RefObject<HTMLDivElement>;
  enabled: boolean;
  initialWidth: number;
  initialHeight: number;
}) => {
  const [{ width, height }, setDimensions] = useState({
    width: initialWidth,
    height: initialHeight,
  });

  const resizeHandle = useRef<HTMLButtonElement | null>(null);

  const [isResizing, setIsResizing] = useState(false);
  const deltaRef = useRef({ top: 0, left: 0, deltaX: 0, deltaY: 0 });

  useEventListener(
    "mousedown",
    (event: MouseEvent) => {
      if (floatingWindowRef.current && enabled) {
        const { top, left, width, height } =
          floatingWindowRef.current.getBoundingClientRect();
        deltaRef.current = {
          top,
          left,
          deltaY: top + height - event.clientY,
          deltaX: left + width - event.clientX,
        };
        setIsResizing(true);
      }
    },
    resizeHandle,
  );

  useEventListener("mousemove", (event: MouseEvent) => {
    if (floatingWindowRef.current && isResizing && enabled) {
      setDimensions({
        width: clamp(
          event.clientX - deltaRef.current.left + deltaRef.current.deltaX,
          380,
          window.innerWidth - deltaRef.current.left - 3,
        ),
        height: clamp(
          event.clientY - deltaRef.current.top + deltaRef.current.deltaY,
          300,
          window.innerHeight - deltaRef.current.top - 3,
        ),
      });
    }
  });

  useEventListener("mouseup", () => {
    setIsResizing(false);
  });

  return {
    width,
    height,
    resizeHandle,
    isResizing,
  };
};

/**
 * This code extracted to a separate component, because of specifity
 * of `useEventListener` hook, which doesn't work properly if ref appears
 * later than at the first render.
 */
export const FloatingWindowInternal = forwardRef<
  HTMLDivElement,
  {
    isPinned: boolean;
    title: ReactNode;
    dataLoc?: string;
    onClose: () => void;
    children: ReactNode;
    onCopy?: () => void;
    onPin?: () => void;
    draggable: boolean;
    style: React.CSSProperties | undefined;
    maximizable: boolean;
    fullBodyWidth?: boolean;
    titleRight?: ReactNode;
    isMaximized?: boolean;
    width?: `w-${number}`;
    height?: `h-${number}`;
    resizeable?: boolean;
  }
>(
  (
    {
      title,
      dataLoc,
      onClose,
      children,
      onCopy,
      onPin,
      isPinned,
      style: initialStyle,
      fullBodyWidth = false,
      draggable,
      maximizable,
      resizeable = false,
      titleRight: right,
      isMaximized: maximized = false,
      width: _width = "w-134",
      height: _height = "h-96",
    },
    ref,
  ) => {
    const [isMaximized, setIsMaximized] = useState(maximized);

    const floatingWindowRef = useRef<HTMLDivElement | null>(null);
    const { isDragging, position, grabberRef } = useFloating({
      enabled: draggable,
      floatingWindowRef,
    });

    const { width, height, resizeHandle, isResizing } = useWindowResize({
      floatingWindowRef,
      initialWidth: parseTailwindSize(_width),
      initialHeight: parseTailwindSize(_height),
      enabled: resizeable,
    });

    const style = position
      ? {
          inset: "0px auto auto 0px",
          transform: `translate3d(${position.x}px, ${position.y}px, 0)`,
        }
      : initialStyle;

    const mainRef = mergeRefs<HTMLDivElement>(ref, floatingWindowRef);

    useEffect(() => {
      if (window.document.activeElement !== floatingWindowRef.current) {
        floatingWindowRef.current?.focus();
      }
    }, [floatingWindowRef]);

    return (
      <PopoverPanel
        ref={mainRef}
        as="div"
        className={twJoin(
          "absolute z-30 focus-within:z-50",
          FLOATING_WINDOW_CLASS,
        )}
        style={
          isMaximized
            ? {
                transform: "translate3d(26px, 22px, 0)",
                width: "calc(100% - 52px)",
                height: "calc(100% - 44px)",
                inset: 0,
              }
            : { ...style, width: `${width}px`, height: `${height}px` }
        }
        static
      >
        <m.div
          animate="visible"
          className="relative h-full overflow-clip rounded-md border border-gray-200 bg-white shadow-xl"
          data-loc={dataLoc}
          exit="hidden"
          initial="hidden"
          transition={{
            type: "tween",
            ease: "easeOut",
            duration: 0.15,
          }}
          variants={{
            visible: {
              opacity: 1,
              scale: 1,
            },
            hidden: {
              opacity: 0,
              scale: 0.95,
            },
          }}
        >
          <div className="flex h-full flex-col">
            <div
              ref={grabberRef}
              className="flex items-start justify-between gap-x-4 px-2 py-2"
              data-loc="floating-window-drag-handler"
              data-grabbable
            >
              <div
                className="item flex min-w-0 items-center text-gray-500 font-inter-normal-12px"
                data-grabbable
              >
                {draggable && (
                  <button
                    className={twJoin(
                      isDragging ? "cursor-grabbing" : "cursor-grab",
                    )}
                    data-grabbable
                  >
                    <span className="pointer-events-none">
                      <Icon
                        color="text-gray-4000"
                        icon={faGripDotsVertical}
                        size="xs"
                        data-grabbable
                      />
                    </span>
                  </button>
                )}
                {title}
              </div>
              <div className="flex items-center space-x-1" data-grabbable>
                {right}
                {onPin && (
                  <Tooltip
                    align="center"
                    delayDuration={500}
                    placement="top"
                    title={`${isPinned ? "Unpin" : "Pin"} window`}
                    asChild
                  >
                    <Icon
                      color={
                        isPinned
                          ? "text-indigo-500"
                          : "text-gray-500 hover:text-gray-800"
                      }
                      dataLoc="pin-floating-window"
                      icon={isPinned ? faThumbTackSolid : faThumbTack}
                      size="xs"
                      onClick={onPin}
                    />
                  </Tooltip>
                )}
                {onCopy && (
                  <Tooltip
                    align="center"
                    delayDuration={500}
                    placement="top"
                    title="Copy JSON"
                    asChild
                  >
                    <CopyTextIcon
                      feedback="inline"
                      size="2xs"
                      onClick={onCopy}
                    />
                  </Tooltip>
                )}
                {maximizable && (
                  <Tooltip
                    align="center"
                    delayDuration={500}
                    placement="top"
                    title={`${isMaximized ? "Minimize" : "Maximize"} window`}
                  >
                    <Icon
                      color="text-gray-500 hover:text-gray-800"
                      icon={isMaximized ? faCompress : faExpand}
                      size="xs"
                      onClick={() => setIsMaximized(!isMaximized)}
                    />
                  </Tooltip>
                )}
                <Icon
                  color="text-gray-500 hover:text-gray-800"
                  dataLoc="close-floating-window"
                  icon={faTimes}
                  size="xs"
                  onClick={() => {
                    onClose?.();
                    isPinned && onPin?.();
                    setIsMaximized(false);
                  }}
                />
              </div>
            </div>
            <div
              className={twJoin(
                "min-h-0 w-full flex-1",
                "border-t border-gray-100",
                !fullBodyWidth && "p-2 pt-0",
              )}
            >
              {children}
            </div>
          </div>
          {resizeable && (
            <ResizeHandle ref={resizeHandle} isResizing={isResizing} />
          )}
        </m.div>
      </PopoverPanel>
    );
  },
);

const ResizeHandle = forwardRef<
  HTMLButtonElement,
  {
    isResizing: boolean;
  }
>(({ isResizing }, ref) => (
  <button
    ref={ref}
    className="group/resize absolute bottom-0 right-0 z-10 h-4 w-4 cursor-se-resize"
  >
    <span
      className={twJoin(
        "absolute right-0 top-0 block h-4 w-1 rounded-md bg-indigo-500 transition-opacity group-hover/resize:opacity-100",
        isResizing ? "opacity-100" : "opacity-0",
      )}
    />
    <span
      className={twJoin(
        "absolute bottom-0 left-0 block h-1 w-4 rounded-md bg-indigo-500 transition-opacity group-hover/resize:opacity-100",
        isResizing ? "opacity-100" : "opacity-0",
      )}
    />
    <span
      className={twJoin(
        "absolute left-2 top-2 block h-2 w-2 rounded-br-md border-b-4 border-r-4 border-indigo-500 transition-opacity group-hover/resize:opacity-100",
        isResizing ? "opacity-100" : "opacity-0",
      )}
    />
  </button>
));
