import { useCallback } from "react";

import { FlowVersionT } from "src/api/flowTypes";
import { useFlow } from "src/api/queries";
import { FlowVersionStatus } from "src/clients/flow-api";
import { BuildOpenModalActionFn } from "src/flow/useFlowVersionModals";

type Args = {
  flowId?: string;
  buildOpenModalAction: BuildOpenModalActionFn;
};

export const usePublishFlowVersionCallback = ({
  flowId,
  buildOpenModalAction,
}: Args) => {
  const { refetch: refetchFlow } = useFlow(flowId, {
    refetchOnMount: false,
    enabled: false,
  });

  return useCallback(
    async (version: FlowVersionT) => {
      const { data: refetchedFlow } = await refetchFlow();
      if (!refetchedFlow) return;

      const versionWithChildFlows = refetchedFlow.versions.find(
        (v) => v.id === version.id,
      );

      const hasUnpublishedChildFlowVersions =
        versionWithChildFlows?.child_flows?.some((childFlow) =>
          childFlow.versions?.some(
            (version) => version.status !== FlowVersionStatus.PUBLISHED,
          ),
        ) ?? false;

      if (hasUnpublishedChildFlowVersions) {
        buildOpenModalAction(
          "unpublished_child_flows",
          versionWithChildFlows,
        )();
      } else {
        buildOpenModalAction("publish_version", versionWithChildFlows)();
      }
    },
    [buildOpenModalAction, refetchFlow],
  );
};
