import { useState } from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";

import { WorkspaceWithSettings } from "src/api/types";
import { FormItem } from "src/base-components/FormItem";
import { InformationPill } from "src/base-components/InformationPill";
import { Input } from "src/base-components/Input";
import { Switch } from "src/base-components/Switch";
import { Tooltip } from "src/base-components/Tooltip";
import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { DOCS_RETENTION_POLICIES } from "src/constants/ExternalLinks";
import { DataRetentionUnitDropdown } from "src/layout/WorkspaceFormModal/DataRetentionUnitDropdown";
import { retentionPeriodToMinutes } from "src/layout/WorkspaceFormModal/utils";
import * as logger from "src/utils/logger";

export const DISABLED_RETENTION_VALUE = {
  value: 0,
  unit: "days",
} as const;

type DataRetentionFieldsProps = {
  workspace: WorkspaceWithSettings;
  fieldName?: // Custom Connection field
  | "dataRetention"
    // Integration provider field
    | "data_retention";
};

export const DataRetentionFields: React.FC<DataRetentionFieldsProps> = ({
  workspace,
  fieldName = "dataRetention",
}) => {
  const isDisabled = workspace.settings.data_retention.value === 0;

  const {
    formState: { errors },
    control,
    register,
    setValue,
    getValues,
  } = useFormContext();

  const [enabled, setEnabled] = useState<boolean>(
    getValues(`${fieldName}.value`) > 0,
  );

  const fieldErrors = errors[fieldName] as FieldValues;

  return (
    <>
      <FormItem
        className="mb-0 flex flex-row items-center justify-between"
        description="This increases performance and reduces live calls, potentially saving money"
        gap="xxs"
        helpAction={{
          label: "Read more",
          onClick: () => window.open(DOCS_RETENTION_POLICIES, "_blank"),
        }}
        helpTooltip="Response Caching"
        helpTooltipBody={
          "Allows you to make use of Taktile's caching system. With caching, responses that were fetched within the window you specify can be used instead of reaching out to the external Connection for fresh data. This can help cut costs and improve the node's runtime (latency). Caching happens for both production and sandbox API calls."
        }
        label="Cache responses"
      >
        <Tooltip
          activated={isDisabled}
          align="center"
          placement="top"
          title="Your workspace does not have caching enabled. Contact your workspace admin to enable caching for this Connection."
          asChild
        >
          <span className="ml-2">
            <Switch
              dataLoc="data-retention-switch"
              disabled={isDisabled}
              enabled={enabled}
              onChange={(checked) => {
                setEnabled(checked);
                if (checked) {
                  setValue(fieldName, workspace.settings.data_retention);
                } else {
                  setValue(fieldName, DISABLED_RETENTION_VALUE);
                }
              }}
            />
          </span>
        </Tooltip>
      </FormItem>
      {enabled && (
        <FieldsetCard>
          <div className="flex items-center gap-x-2">
            <div className="shrink-0 pr-2 text-gray-800 font-inter-medium-12px">
              Delete cached reports older than
            </div>
            <Input
              data-loc="data-retention-value"
              errored={Boolean(fieldErrors?.value)}
              type="number"
              fullWidth
              {...register(`${fieldName}.value`, {
                required: true,
                min: 1,
                valueAsNumber: true,
                validate: {
                  timePeriod: (_value, formValues) => {
                    if (workspace.settings.data_retention) {
                      const workspaceRetentionPeriod = retentionPeriodToMinutes(
                        workspace.settings.data_retention,
                      );
                      const connectRetentionPeriod = retentionPeriodToMinutes(
                        formValues[fieldName],
                      );
                      if (connectRetentionPeriod > workspaceRetentionPeriod) {
                        return "Larger time period than specified in the workspace retention policy";
                      }
                    } else {
                      logger.error("Workspace settings not found");
                      return false;
                    }
                    return true;
                  },
                },
              })}
            />
            <div className="w-1/4 shrink-0">
              <Controller
                control={control}
                name={`${fieldName}.unit`}
                render={({ field: { value, onChange } }) => (
                  <DataRetentionUnitDropdown
                    value={value}
                    onChange={onChange}
                  />
                )}
                rules={{
                  deps: [`${fieldName}.value`],
                }}
              />
            </div>
          </div>
          {fieldErrors?.value?.type === "timePeriod" && (
            <InformationPill
              action={{
                text: "Read more",
                onClick: () => window.open(DOCS_RETENTION_POLICIES, "_blank"),
              }}
              className="mt-3"
              type="warning"
            >
              <div>
                You've selected a larger time period than specified in your
                workspace retention policy (
                <span className="font-inter-medium-12px">
                  {workspace.settings.data_retention.value}{" "}
                  {workspace.settings.data_retention.unit}
                </span>
                ).
              </div>
            </InformationPill>
          )}
        </FieldsetCard>
      )}
    </>
  );
};
