import { makeConnectionTemplate } from "src/connections/types";

export const PlaidTemplate = makeConnectionTemplate({
  provider: "plaid",
  secrets: [
    {
      key: "client_id",
      name: "Client ID",
      required: false,
      hint: "Required for Plaid Check (CRA) resources, optional otherwise",
    },
    {
      key: "client_secret",
      name: "Client secret",
      required: false,
      hint: "Required for Plaid Check (CRA) resources, optional otherwise",
    },
  ],
  resources: {
    asset_report: {
      name: "Assets - Asset Report",
      configKeys: [
        {
          key: "include_financial_insights",
          name: "Include financial insights",
          required: false,
          type: "switch",
          hint: "Include financial insights to access detailed information on risk and affordability across consumers' connected accounts",
        },
      ],
    },
    financial_insights_report: {
      name: "Assets - Financial Insights Report",
      configKeys: [],
    },
    plaid_cra_base_report: {
      name: "Check (CRA) - Base Report",
      configKeys: [],
    },
    plaid_cra_income_insights_report: {
      name: "Check (CRA) - Income Insights Report",
      configKeys: [],
    },
    plaid_cra_partner_insights_report: {
      name: "Check (CRA) - Partner Insights Report",
      configKeys: [
        {
          key: "products",
          required: true,
          name: "Partner products",
          type: "multiple_selection",
          hint: "The partner products to be included in the response",
          elements: [
            { key: "scores", value: "Prism Data CashScore® + FirstDetect" },
            { key: "insights", value: "Prism Data Insights" },
          ],
        },
      ],
    },
  },
});
