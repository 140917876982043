export const getCurrentUnixTimestamp = () =>
  Math.floor(new Date().getTime() / 1000);

/**
 *
 * @param accessHeader
 * @returns The expiration time as unix timestamp (in seconds)
 */
export const calculateTokenExpiration = (accessHeader: {
  Authorization: string;
}) => {
  return JSON.parse(
    atob(accessHeader.Authorization.replace("Bearer ", "").split(".")[1]),
  ).exp;
};
