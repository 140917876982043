import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import React, { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  taktileInternalPrefix,
  validateNotStartsWithInternalPrefix,
} from "src/api/constants";
import { WorkspaceWithSettings } from "src/api/types";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { Switch } from "src/base-components/Switch";
import { Tabs } from "src/base-components/Tabs";
import { Tooltip } from "src/base-components/Tooltip";
import { DataRetentionFields } from "src/connections/config/DataRetentionFields";
import { EnableNonProdConfigsField } from "src/connections/config/EnableNonProdConfigsField";
import { EnvironmentCredentials } from "src/connections/config/EnvironmentCredentials";
import { UploadIcon } from "src/connections/config/UploadIcon";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { connectionConfigDefaultValues } from "src/connections/model/model";
import { ConnectionConfigInputsT } from "src/connections/types";
import { Modal } from "src/design-system/Modal";

type PropsT = {
  onSubmit: (data: ConnectionConfigInputsT) => void;
  workspace: WorkspaceWithSettings;
};

export const CustomConnectionConfigForm: React.FC<PropsT> = ({
  onSubmit,
  workspace,
}) => {
  const [_, setIconUploadInProgress] = useState(false);
  const { formState, getValues, setValue, watch, control, ...formMethods } =
    useFormContext<ConnectionConfigInputsT>();
  const { errors } = formState;
  const enableNonProdConfigs = watch("enableNonProdConfigs");
  const hasIcon = Boolean(watch("mediaKey"));

  useEffect(() => {
    const nonProdEnvConfigs = getValues("nonProdEnvConfigs");
    // Sets the default for a sandbox environment, if no state is present
    const sandboxConfigExists = nonProdEnvConfigs && nonProdEnvConfigs.sandbox;

    if (enableNonProdConfigs && !sandboxConfigExists) {
      setValue("nonProdEnvConfigs", {
        ...nonProdEnvConfigs,
        sandbox: {
          ...connectionConfigDefaultValues(),
          url: "",
          probeUrl: "",
        },
      });
    }
  }, [setValue, getValues, enableNonProdConfigs]);

  return (
    <form
      data-loc="custom-connection-config-form"
      onSubmit={formMethods.handleSubmit(onSubmit)}
    >
      <Modal.Content>
        <div className="mb-6 flex items-center space-x-2">
          <div className="flex-shrink-0">
            <Tooltip
              activated={!hasIcon}
              align="center"
              body="Use a square image under 100 KB in size. Supported formats are SVG, PNG, JPG, and GIF."
              placement="top"
              title="Upload logo"
            >
              <UploadIcon
                mediaKey={getValues("mediaKey")}
                onFileRemoved={() => {
                  setValue("mediaKey", null);
                }}
                onUploadFinished={(success, mediaKey) => {
                  if (success && mediaKey) {
                    setValue("mediaKey", mediaKey);
                  }
                  setIconUploadInProgress(false);
                }}
                onUploadStarted={() => setIconUploadInProgress(true)}
              />
            </Tooltip>
          </div>
          <div className="flex-grow">
            <FormItem label="Connection name" isRequired>
              {Boolean(errors.name) && (
                <ErrorHint>{errors.name?.message}</ErrorHint>
              )}
              <Input
                data-loc="custom-connection-name"
                errored={isFieldErrored(errors, "name")}
                {...formMethods.register("name", {
                  required: "Connection name is required",
                  validate: validateNotStartsWithInternalPrefix(
                    `Connection name cannot start with ${taktileInternalPrefix}`,
                  ),
                })}
                placeholder="e.g. Bloomcredit"
                suffixIcon={
                  !!isFieldErrored(errors, "name")
                    ? { icon: faWarning }
                    : undefined
                }
                fullWidth
              />
            </FormItem>
          </div>
        </div>

        <EnableNonProdConfigsField<ConnectionConfigInputsT>
          dataLoc="custom-connection-env-aware-switch"
          subtitle="You can set different credentials for non-production API calls (i.e. test runs and sandbox API calls)"
          title="Use separate credentials for non-production API calls"
        />

        {!enableNonProdConfigs && (
          <EnvironmentCredentials environmentPrefix="" />
        )}
        {enableNonProdConfigs && (
          <Tabs
            containerClassName="flex-1 flex flex-col mb-8"
            defaultActiveKey="prod"
            panelClassName="h-full flex flex-col"
            panelsClassName="flex-1"
            tabClassName="px-0 mr-4"
            tabListClassName="border-b border-gray-200 mb-6 sticky -top-5 bg-white z-10"
            tabs={[
              {
                key: "prod",
                label: "Production credentials",
                content: (
                  <EnvironmentCredentials
                    environmentPrefix=""
                    labelsPrefix="Production"
                  />
                ),
              },
              {
                key: "sandbox",
                label: "Sandbox credentials",
                content: (
                  <EnvironmentCredentials
                    environmentPrefix="nonProdEnvConfigs.sandbox."
                    labelsPrefix="Sandbox"
                  />
                ),
              },
            ]}
          />
        )}
        <hr className="mb-8 h-px w-full bg-gray-100" />
        <DataRetentionFields workspace={workspace} />
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <h3 className="inline-block text-gray-800 font-inter-semibold-13px">
              Include raw provider responses
              <FormItem.Help body="With this enabled, the untouched API response from this connection will be returned alongside published Flows' output. The API response will only be included on Flow Runs that successfully fetch an API response, and not when using a cached response. Taktile never stores API responses." />
            </h3>
            <span className="mt-1 text-xs text-gray-500 font-inter-normal-12px">
              Include the raw API response for this connection in published
              Flows' API responses. Note that this may expose sensitive data in
              the API response.
            </span>
          </div>
          <div className="ml-1">
            <Controller
              control={control}
              name="hasRawProviderResponseEnabled"
              render={({ field: { value, onChange } }) => (
                <Switch
                  enabled={value as boolean}
                  onChange={() => onChange(!value)}
                />
              )}
            />
          </div>
        </div>
        <div className="mt-4 flex items-center justify-between">
          <div className="flex flex-col">
            <h3 className="inline-block text-gray-800 font-inter-semibold-13px">
              Include raw requests made to the provider API
              <FormItem.Help body="With this enabled, the raw HTTP request to the provider will be returned with the Taktile API response alongside published Flows' output. The request will not be included when using a cached response. Taktile never stores raw requests." />
            </h3>
            <span className="mt-1 text-xs text-gray-500 font-inter-normal-12px">
              Include the raw request sent to the provider in the published
              Flows' API responses. Note that this may expose sensitive data in
              the API response.
            </span>
          </div>
          <div className="ml-2">
            <Controller
              control={control}
              name="hasRawProviderRequestEnabled"
              render={({ field: { value, onChange } }) => (
                <Switch
                  enabled={value as boolean}
                  onChange={() => onChange(!value)}
                />
              )}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="custom-connection-save"
            disabled={formState.isSubmitting}
            htmlType="submit"
            loading={formState.isSubmitting}
            variant="primary"
          >
            Save
          </Button>
        }
      />
    </form>
  );
};
