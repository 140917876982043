import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import axios from "axios";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import slugify from "slugify";

import { WorkspaceWithSettings } from "src/api/types";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { Modal } from "src/base-components/Modal";
import { toastFailure } from "src/base-components/Toast/utils";
import { Tooltip } from "src/base-components/Tooltip";
import { WorkspaceSettings } from "src/clients/flow-api";
import { AwsRegionTypeT, AWS_REGIONS } from "src/constants/AwsRegions";
import { DOCS_RETENTION_POLICIES } from "src/constants/ExternalLinks";
import { DataRetentionUnitDropdown } from "src/layout/WorkspaceFormModal/DataRetentionUnitDropdown";
import { RegionComboBox } from "src/layout/WorkspaceFormModal/RegionComboBox";
import { FORM_DEFAULT_VALUES } from "src/layout/WorkspaceFormModal/constants";
import {
  getNameErrorMessage,
  slugValidations,
  nameValidations,
} from "src/layout/WorkspaceFormModal/utils";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";

type WorkspaceSettingsModalProps = {
  variant: "Create" | "Edit";
  isOpen: boolean;
  workspace?: WorkspaceWithSettings;
  wsId?: string;
  onClose: () => void;
  onSubmit: (data: CreateWorkspaceForm) => void;
};

export type CreateWorkspaceForm = {
  name: string;
  slug: string;
  region: AwsRegionTypeT;
  flow_services_version?: string;
  settings?: WorkspaceSettings;
};

export const WorkspaceSettingsModal: React.FC<WorkspaceSettingsModalProps> = ({
  variant,
  isOpen,
  workspace: currentWorkspace,
  onClose,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<CreateWorkspaceForm>({
    defaultValues: FORM_DEFAULT_VALUES,
    shouldFocusError: true,
    mode: "onChange",
  });

  useEffect(() => {
    const currentWorkspaceRegion = AWS_REGIONS.find(
      (region) => region.identifier === currentWorkspace?.aws_region,
    );
    const currentWorkspaceName = currentWorkspace?.name;

    if (currentWorkspaceRegion && currentWorkspaceName) {
      reset({
        name: variant === "Edit" ? currentWorkspaceName : "",
        slug: "",
        region: currentWorkspaceRegion,
        settings: {
          data_retention: currentWorkspace?.settings.data_retention,
        },
        ...(isFeatureFlagEnabled(FEATURE_FLAGS.powertools) && {
          flow_services_version: currentWorkspace?.flow_services_version,
        }),
      });
    }
  }, [currentWorkspace, reset, variant]);

  const handleModalClose = () => {
    reset();
    onClose();
  };

  const onFormConfirm = handleSubmit(async (data: CreateWorkspaceForm) => {
    try {
      await onSubmit(data);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 422) {
        setError("slug", { type: "notAvailableSlug" }, { shouldFocus: true });
      } else {
        toastFailure({
          title: "Error submitting workspace configuration",
          description: "Please try again later.",
        });
      }
    }
  });

  return (
    <Modal
      className="relative w-134"
      open={isOpen}
      title={`${variant} workspace`}
      closeIcon
      onClose={handleModalClose}
    >
      <hr className="mb-3 h-px w-full border-none bg-gray-100" />
      <form onSubmit={onFormConfirm}>
        <div className="flex flex-col gap-6 px-5 pb-3 pt-2">
          <div>
            <Label required>Workspace name</Label>
            <Input
              fullWidth
              {...register("name", {
                required: true,
                validate: nameValidations,
                onChange: (e) => {
                  if (variant === "Create") {
                    setValue(
                      "slug",
                      slugify(e.target.value, { strict: true, lower: true }),
                      {
                        shouldValidate: true,
                      },
                    );
                  }
                },
              })}
            />
            {errors.name && (
              <ErrorHint height="h-2.5">
                {getNameErrorMessage(errors.name.type)}
              </ErrorHint>
            )}
          </div>
          {variant === "Create" && (
            <div>
              <Label required>Slug</Label>
              <Input
                fullWidth
                {...register("slug", {
                  required: true,
                  validate: slugValidations,
                })}
              />
              {errors.slug && (
                <ErrorHint height="h-2.5">
                  {getNameErrorMessage(errors.slug.type)}
                </ErrorHint>
              )}
            </div>
          )}
          <div>
            <Label required>Region</Label>
            <Controller
              control={control}
              name="region"
              render={({ field: { onChange, value, ref } }) => (
                <RegionComboBox
                  disabled={variant === "Edit"}
                  inputRef={ref}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div>
            <Label mb="mb-1" required>
              Retention policy
            </Label>
            <Tooltip
              action={{
                label: "Read more",
                onClick: () => window.open(DOCS_RETENTION_POLICIES, "_blank"),
              }}
              body="Setting a data retention period will automatically delete Decision Flow history after the specified time period. This might impact analytics, backtesting, and other features."
              placement="top"
              title="Retention policy"
            >
              <Icon color="text-gray-500" icon={faInfoCircle} size="2xs" />
            </Tooltip>
            <div className="mb-2 text-gray-500 font-inter-normal-12px">
              Automatically delete decision history older than
            </div>

            <div>
              <div className="flex items-center gap-x-2">
                <Input
                  type="number"
                  fullWidth
                  {...register("settings.data_retention.value", {
                    required: "This field cannot be empty",
                    min: {
                      value: 0,
                      message: "The value must be a positive integer",
                    },
                    valueAsNumber: true,
                  })}
                  errored={!!errors.settings?.data_retention?.value}
                />
                <div className="w-32 shrink-0">
                  <Controller
                    control={control}
                    name="settings.data_retention.unit"
                    render={({ field: { value, onChange } }) => (
                      <DataRetentionUnitDropdown
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
              </div>

              {errors.settings?.data_retention?.value && (
                <ErrorHint>
                  {errors.settings?.data_retention?.value?.message}
                </ErrorHint>
              )}
            </div>
          </div>
          {isFeatureFlagEnabled(FEATURE_FLAGS.powertools) && (
            <div className="mt-8 text-gray-500">
              <div className="mb-1 text-gray-800 font-inter-semibold-13px">
                [⚡ POWERTOOL] Workspace services version
              </div>
              <Input
                fullWidth
                {...register("flow_services_version", {
                  required: false,
                  validate: (value) =>
                    value?.length === 0 || value?.includes("/"),
                })}
              />
            </div>
          )}
        </div>
        <hr className="my-3 h-px w-full border-none bg-gray-100" />
        <div className="mx-6 mb-4 mt-6 flex items-center justify-end gap-2">
          <Button
            disabled={isSubmitting}
            variant="secondary"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            disabled={!isValid || isSubmitting}
            htmlType="submit"
            loading={isSubmitting}
            variant="primary"
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
