import { codeToFlag } from "src/layout/WorkspaceFormModal/utils";

export type AwsRegionTypeT = {
  identifier: string;
  city: string;
  region: string;
  flag: keyof typeof codeToFlag;
};

export const AWS_REGIONS: AwsRegionTypeT[] = [
  {
    identifier: "sa-east-1",
    city: "São Paulo",
    region: "South America",
    flag: "br",
  },
  {
    identifier: "me-south-1",
    city: "Bahrain",
    region: "Middle East",
    flag: "bh",
  },
  {
    identifier: "eu-central-1",
    city: "Frankfurt",
    region: "Europe",
    flag: "de",
  },
  {
    identifier: "eu-west-1",
    city: "Ireland",
    region: "Europe",
    flag: "ie",
  },
  {
    identifier: "eu-west-2",
    city: "London",
    region: "Europe",
    flag: "gb",
  },
  {
    identifier: "eu-south-1",
    city: "Milan",
    region: "Europe",
    flag: "it",
  },
  {
    identifier: "eu-west-3",
    city: "Paris",
    region: "Europe",
    flag: "fr",
  },
  {
    identifier: "eu-north-1",
    city: "Stockholm",
    region: "Europe",
    flag: "se",
  },
  {
    identifier: "ca-central-1",
    city: "Central",
    region: "Canada",
    flag: "ca",
  },
  {
    identifier: "ap-east-1",
    city: "Hong Kong",
    region: "Asia Pacific",
    flag: "hk",
  },
  {
    identifier: "ap-southeast-3",
    city: "Jarkata",
    region: "Asia Pacific",
    flag: "id",
  },
  {
    identifier: "ap-south-1",
    city: "Mumbai",
    region: "Asia Pacific",
    flag: "in",
  },
  {
    identifier: "ap-northeast-3",
    city: "Osaka",
    region: "Asia Pacific",
    flag: "jp",
  },
  {
    identifier: "ap-northeast-2",
    city: "Seoul",
    region: "Asia Pacific",
    flag: "kr",
  },
  {
    identifier: "ap-southeast-1",
    city: "Singapore",
    region: "Asia Pacific",
    flag: "sg",
  },
  {
    identifier: "ap-southeast-2",
    city: "Sydney",
    region: "Asia Pacific",
    flag: "au",
  },
  {
    identifier: "ap-northeast-1",
    city: "Tokyo",
    region: "Asia Pacific",
    flag: "jp",
  },
  {
    identifier: "af-south-1",
    city: "Cape Town",
    region: "Asia Pacific",
    flag: "za",
  },
  {
    identifier: "us-west-2",
    city: "Oregon",
    region: "US West",
    flag: "us",
  },
  {
    identifier: "us-west-1",
    city: "N. California",
    region: "US West",
    flag: "us",
  },
  {
    identifier: "us-east-1",
    city: "N. Virginia",
    region: "US East",
    flag: "us",
  },
  {
    identifier: "us-east-2",
    city: "Ohio",
    region: "US East",
    flag: "us",
  },
];

export const DEFAULT_AWS_REGION = AWS_REGIONS[2];
