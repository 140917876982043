import { faMinus } from "@fortawesome/pro-solid-svg-icons";
import { Updater, VisibilityState } from "@tanstack/react-table";
import React from "react";

import { Checkbox } from "src/base-components/Checkbox";
import { Icon } from "src/base-components/Icon";

type ResultAllColumnsSelectorRowProps = {
  toggleColumnsVisibility: (updater: Updater<VisibilityState>) => void;
  resultsColumns: string[];
  visibleColumnsCount: number;
};

export const ResultAllColumnsSelectorRow: React.FC<
  ResultAllColumnsSelectorRowProps
> = ({ toggleColumnsVisibility, visibleColumnsCount, resultsColumns }) => {
  const checked = visibleColumnsCount > 0;
  return (
    <label
      className="flex h-12 max-w-90 cursor-pointer items-center gap-x-2 px-4 py-2.5 hover:bg-gray-50"
      data-loc="select-all-toggle"
    >
      <div className="relative inline-flex overflow-clip rounded-sm">
        <Checkbox
          checked={checked}
          onChange={() =>
            toggleColumnsVisibility((state) => ({
              ...state,
              ...Object.fromEntries(
                resultsColumns.map((column) => [column, !checked]),
              ),
            }))
          }
        />
        {checked && (
          <div className="pointer-events-none absolute inset-0 flex items-center justify-center bg-indigo-600">
            <Icon color="text-white" icon={faMinus} size="3xs" />
          </div>
        )}
      </div>
      <p className="flex-1 truncate text-gray-800 font-inter-semibold-13px">
        {checked ? "Deselect all" : "Select all"}
      </p>
      <span className="text-gray-500 font-inter-normal-12px">
        {visibleColumnsCount} / {resultsColumns.length}
      </span>
    </label>
  );
};
