import { UseFormRegisterReturn } from "react-hook-form";

import { Input } from "src/base-components/Input";

type PropsT = {
  dataLoc?: string;
  immutable: boolean;
  formProps: UseFormRegisterReturn;
};

const handleNumericInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
  // Allow only numbers and special set of keys for the timeout input
  const validKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];

  if (!/[0-9]/.test(event.key) && !validKeys.includes(event.key)) {
    event.preventDefault();
  }
};

export const TimeoutInput = ({ dataLoc, immutable, formProps }: PropsT) => {
  return (
    <Input
      data-loc={dataLoc}
      disabled={immutable}
      placeholder="Enter timeout in seconds"
      onKeyDown={handleNumericInput}
      {...formProps}
    />
  );
};
