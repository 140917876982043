import { Root } from "@radix-ui/react-accordion";
import React, { useState } from "react";

import { FlowVersionFlowChild, FlowVersionT } from "src/api/flowTypes";
import { SearchBox } from "src/base-components/SearchBox";
import { FlowVersionCard } from "src/nodeEditor/SelectFlowPane/FlowVersionCard";
import {
  FlowVersionFilter,
  VersionStatusFilterValue,
} from "src/nodeEditor/SelectFlowPane/FlowVersionFilter";
import { matchesFilter } from "src/utils/flowVersion";
import { useFuseSearch } from "src/utils/useFuseSearch";

type Props = {
  flowId: string;
  flowVersions: FlowVersionFlowChild[];
  selectedVersionId?: string;
  onSelect: (flowVersion: FlowVersionT) => void;
};

export const FlowVersionList: React.FC<Props> = ({
  flowVersions,
  flowId,
  onSelect,
  selectedVersionId,
}) => {
  const [filter, setFilter] = useState<VersionStatusFilterValue>("all");
  const [filterTerm, setFilterTerm] = useState("");
  const search = useFuseSearch(
    flowVersions.filter(
      (version) => filter === "all" || matchesFilter(version, filter),
    ),
    { keys: ["name"] },
  );

  return (
    <div className="flex h-full flex-col">
      <Root
        className="flex h-full flex-col gap-y-3"
        defaultValue={[]}
        type="multiple"
      >
        <div className="border-b border-gray-100 pb-2">
          <SearchBox placeholder="Search a version" onChange={setFilterTerm} />
        </div>
        <FlowVersionFilter
          value={filter}
          onChange={(value) => setFilter(value)}
        />

        <ul className="decideScrollbar -mr-4 flex flex-col gap-y-3 !overflow-y-scroll">
          {search(filterTerm)
            .sort((a, b) => +new Date(b.updated_at) - +new Date(a.updated_at))
            .map((version) => (
              <FlowVersionCard
                key={version.id}
                flowId={flowId}
                selected={version.id === selectedVersionId}
                version={version}
                onClick={() => onSelect(version)}
              />
            ))}
        </ul>
      </Root>
    </div>
  );
};
