import { Root } from "@radix-ui/react-accordion";
import React from "react";

import { useConnection } from "src/api/connectApi/queries";
import { ConnectionT } from "src/api/connectApi/types";
import { useWorkspace } from "src/api/queries";
import { FieldErrorsT } from "src/api/types";
import {
  EditorAccordionItem as AccordionItem,
  accordionRootClassName,
} from "src/base-components/EditorAccordionItem";
import { LoadingView } from "src/base-components/LoadingView";
import { useDiffViewContext } from "src/changeHistory/DiffViewModal/DiffViewContext";
import {
  IntegrationNode,
  IntegrationNodeDataT,
} from "src/constants/NodeDataTypes";
import { ConfigPane } from "src/integrationNode/editorComponents/ConfigPane";
import { InputMappings } from "src/integrationNode/editorComponents/InputMappings";
import { OutputMappings } from "src/integrationNode/editorComponents/OutputMappings";
import {
  ConfigT,
  InputMappingsT,
  OutputMappingsT,
} from "src/integrationNode/types";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import { convertFieldErrorsBeToFe } from "src/utils/FieldErrorUtils";

type PropsT = {
  node: IntegrationNode;
  connection: ConnectionT;
} & NodeEditorBaseProps<IntegrationNodeDataT>;

type WrapperPropsT = {
  node: IntegrationNode;
  workspaceId: string;
} & NodeEditorBaseProps<IntegrationNodeDataT>;

export type SubFormBaseProps = Pick<
  NodeEditorBaseProps<IntegrationNodeDataT>,
  "immutable" | "isReactive"
>;

export const IntegrationNodeEditor: React.FC<WrapperPropsT> = ({
  immutable,
  node,
  workspaceId,
  displayedError,
  isReactive,
  onUpdate,
}) => {
  const workspace = useWorkspace(workspaceId);
  const connection = useConnection({
    baseUrl: workspace.data?.base_url,
    id: node.data.connectionId,
  });

  return (
    <LoadingView
      queryResult={connection}
      renderUpdated={(connection: ConnectionT) => (
        <IntegrationNodeEditorInternal
          connection={connection}
          displayedError={displayedError}
          immutable={immutable}
          isReactive={isReactive}
          node={node}
          onUpdate={onUpdate}
        />
      )}
    />
  );
};

const IntegrationNodeEditorInternal: React.FC<PropsT> = ({
  immutable,
  node,
  connection,
  displayedError,
  isReactive,
  onUpdate,
}) => {
  const { renderedInDiffView } = useDiffViewContext();
  const updateMappingInput = (inputMapping: InputMappingsT) => {
    onUpdate({ newData: { input: inputMapping } });
  };

  const updateMappingOutput = (outputMapping: OutputMappingsT) => {
    onUpdate({
      newData: { output: outputMapping },
    });
  };

  const updateConfig = (updatedConfig: ConfigT) => {
    // This check gives a Typescript error when the config property is not present on integration nodes.
    if (node.data.config)
      onUpdate({
        newData: { config: updatedConfig },
      });
  };

  const multiTenantConnection = Boolean(connection.configuration.multi_tenant);

  const runFieldErrors: FieldErrorsT | undefined = displayedError?.field_errors
    ? convertFieldErrorsBeToFe(displayedError.field_errors)
    : undefined;

  const hasRawProviderRequestEnabled = connection.resource_configs.find(
    (resource) => resource.id === node.data.resourceConfigId,
  )?.has_raw_requests_enabled_in_resource;

  return (
    <Root
      className={accordionRootClassName}
      defaultValue={["input", "output", "config"]}
      type="multiple"
    >
      <AccordionItem
        className="pb-3"
        disabled={renderedInDiffView}
        title="Configure API request"
        value="input"
      >
        <InputMappings
          immutable={immutable}
          integrationResource={node.data}
          isReactive={isReactive}
          runFieldErrors={runFieldErrors}
          updateInputMapping={updateMappingInput}
        />
      </AccordionItem>
      <AccordionItem
        className="pb-1"
        disabled={renderedInDiffView}
        title="Configure API response"
        value="output"
      >
        <OutputMappings
          immutable={immutable}
          integrationResource={node.data}
          isReactive={isReactive}
          runFieldErrors={runFieldErrors}
          updateMappingOutput={updateMappingOutput}
        />
      </AccordionItem>
      <AccordionItem
        className="pb-7"
        disabled={renderedInDiffView}
        title="Advanced settings"
        value="config"
      >
        <ConfigPane
          config={node.data.config}
          dataRetention={connection.data_retention}
          hasRawProviderRequestEnabled={hasRawProviderRequestEnabled}
          immutable={immutable}
          isReactive={isReactive}
          providerResource={node.data.providerResource}
          showTenantIDField={multiTenantConnection}
          updateConfig={updateConfig}
        />
      </AccordionItem>
    </Root>
  );
};
