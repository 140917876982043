import { useFormContext, Controller } from "react-hook-form";

import {
  BigQuerySQLConnectionAuthMethod,
  BigQuerySQLConnectionAuthScopes,
  BigQuerySQLConnectionAuthScopeT,
} from "src/api/connectApi/types";
import { ErrorHint } from "src/base-components/ErrorHint";
import { ExternalLink } from "src/base-components/ExternalLink";
import { FormItem } from "src/base-components/FormItem";
import { Select } from "src/base-components/Select";
import { SimpleDropDown } from "src/base-components/SimpleDropDown";
import { BigQuerySQLServiceAccountAuthFieldset } from "src/connections/config/database/bigquery/BigQuerySQLServiceAccountAuthFieldset";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { TestInvocationToggle } from "src/connections/config/shared/TestInvocationToggle";
import {
  BigQuerySQLConnectionConfigInputsT,
  Environment,
} from "src/connections/types";

const GOOGLE_OAUTH_SCOPES_URL =
  "https://developers.google.com/identity/protocols/oauth2/scopes#bigquery";

type BigQuerySQLAuthTypeSelectElement = {
  key: BigQuerySQLConnectionAuthMethod;
  value: string;
};

const authMethods: BigQuerySQLAuthTypeSelectElement[] = [
  { key: "service_account", value: "Service account" },
];

type BigQueryAuthScopesSelectElement = {
  key: BigQuerySQLConnectionAuthScopeT;
  value: BigQuerySQLConnectionAuthScopeT;
};

const authScopes: BigQueryAuthScopesSelectElement[] =
  BigQuerySQLConnectionAuthScopes.map((scope) => ({
    key: scope,
    value: scope,
  }));

const renderAuthFields = (
  authMethod: BigQuerySQLConnectionAuthMethod,
  environment: Environment,
) => {
  if (authMethod === "service_account") {
    return <BigQuerySQLServiceAccountAuthFieldset environment={environment} />;
  }
};

type EnvironmentConfigPropsT = {
  environment: Environment;
  labelsPrefix: string;
};

export const EnvironmentConfig: React.FC<EnvironmentConfigPropsT> = ({
  environment,
  labelsPrefix,
}) => {
  const {
    formState: { errors },
    watch,
    control,
  } = useFormContext<BigQuerySQLConnectionConfigInputsT>();
  const envPrefix = `${environment}Config` as const;
  const envErrors = errors[envPrefix];
  const enableNonProdConfigs = watch("enableNonProdConfigs");
  const authMethod = watch(`${envPrefix}.authMethod`);
  return (
    <>
      <FormItem
        description={
          <span>
            Scopes govern what database operations can be performed by the
            Connection's Database Nodes. For details refer to Google Cloud{" "}
            <ExternalLink href={GOOGLE_OAUTH_SCOPES_URL}>
              documentation
            </ExternalLink>
            .
          </span>
        }
        gap="sm"
        label={labelsPrefix ? `${labelsPrefix} scopes` : "Scopes"}
        isRequired
      >
        {isFieldErrored(envErrors, "scopes") && (
          <ErrorHint>{envErrors?.scopes?.message}</ErrorHint>
        )}
        <Controller
          control={control}
          name={`${envPrefix}.scopes`}
          render={({ field: { value, onChange } }) => (
            <Select
              errored={isFieldErrored(envErrors, "scopes")}
              options={authScopes}
              placeholder="Select scopes"
              placement="bottom"
              value={value as string[]}
              multiple
              onChange={onChange}
            />
          )}
          rules={{
            required: `At least 1 scope is required`,
          }}
        />
      </FormItem>
      <FormItem
        description="The authentication mechanism required for the database"
        gap="sm"
        label="Select authentication type"
        isRequired
      >
        <Controller
          control={control}
          name={`${envPrefix}.authMethod`}
          render={(props) => (
            <SimpleDropDown
              buttonClassName="pl-3"
              buttonDataLoc={`${environment}-bigquery-connection-auth-type`}
              className="h-8 w-full"
              elements={authMethods}
              itemsClassNames="w-full"
              itemsWidth="w-full"
              placeholder="Select authentication type"
              placement="bottomLeft"
              selectedKey={String(props.field.value)}
              onSelect={(value) => {
                props.field.onChange(value);
              }}
            />
          )}
        />
      </FormItem>
      {renderAuthFields(authMethod, environment)}
      {environment === "production" && (
        <TestInvocationToggle<BigQuerySQLConnectionConfigInputsT>
          control={control}
          enableNonProdConfigs={enableNonProdConfigs}
          type="database"
        />
      )}
    </>
  );
};
