import { useFormContext } from "react-hook-form";

import { SecretField } from "src/connections/config/shared/SecretField";
import {
  InboundWebhookConnectionConfigInputsT,
  Environment,
} from "src/connections/types";

type SecretConfigFieldT =
  | "webhookBasicAuthConfig.login"
  | "webhookBasicAuthConfig.password"
  | "webhookApiKeyConfig.apiKey"
  | "webhookHMACConfig.signingKey";

type SecretPropsT = {
  dataLoc?: string;
  secretConfigField: SecretConfigFieldT;
  secretName: string;
  helpText: string;
  isRequired?: boolean;
  isErrored: boolean;
  environment: Environment;
};

export const InboundWebhookSecretField: React.FC<SecretPropsT> = ({
  dataLoc,
  secretConfigField,
  secretName,
  helpText,
  isRequired,
  isErrored,
  environment,
}) => {
  const { watch, setValue, register } =
    useFormContext<InboundWebhookConnectionConfigInputsT>();

  const secretKey = `${environment}Config.${secretConfigField}` as const;

  const secretRecord = watch(secretKey);

  const enableSecret = () => {
    setValue(secretKey, { ...secretRecord, secret: false, value: null });
  };

  return (
    <SecretField
      dataLoc={dataLoc}
      enableSecret={enableSecret}
      helpText={helpText}
      isErrored={isErrored}
      isRequired={isRequired}
      isSecret={Boolean(secretRecord.secret)}
      register={register}
      secretKey={`${secretKey}.value`}
      secretName={secretName}
    />
  );
};
