export const authHeader = (
  refresh: boolean,
): { Authorization: string } | {} => {
  if (refresh) {
    return localStorage.getItem("refresh_token")
      ? {
          Authorization: "Bearer " + localStorage.getItem("refresh_token"),
        }
      : {};
  }
  return localStorage.getItem("id_token")
    ? {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      }
    : {};
};
