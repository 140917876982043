import {
  faArrowDown,
  faArrowUp,
  faClone,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { Row } from "@tanstack/react-table";
import React, { Dispatch } from "react";

import { ContextMenu } from "src/decisionTableNode/ContextMenu";
import { DecisionTableActions } from "src/decisionTableNode/actions";
import { DecisionTableAction } from "src/decisionTableNode/useDecisionTableActionsReducer";
import { RowShape } from "src/decisionTableNode/utils";

type Props = {
  row: Row<RowShape>;
  totalRows: number;
  show: boolean;
  dispatch?: Dispatch<DecisionTableAction>;
};

export const RowContextMenu: React.FC<Props> = React.memo(
  ({ row, totalRows, show, dispatch, ...rest }) => {
    const index = row.index;
    const isLastItem = totalRows <= 1;

    return (
      <td
        className="relative overflow-visible p-0"
        style={{ width: 1, minWidth: 1, maxWidth: 1 }}
      >
        <div className="-ml-1.5">
          <ContextMenu button="vertical" buttonProps={rest} show={show}>
            <ContextMenu.Item
              icon={faArrowUp}
              onClick={() =>
                dispatch?.({
                  type: DecisionTableActions.INSERT_CASE_ABOVE,
                  payload: index,
                })
              }
            >
              Insert Case Above
            </ContextMenu.Item>
            <ContextMenu.Item
              icon={faArrowDown}
              onClick={() =>
                dispatch?.({
                  type: DecisionTableActions.INSERT_CASE_BELOW,
                  payload: index,
                })
              }
            >
              Insert Case Below
            </ContextMenu.Item>
            <ContextMenu.Item
              icon={faClone}
              onClick={() =>
                dispatch?.({
                  type: DecisionTableActions.DUPLICATE_CASE,
                  payload: index,
                })
              }
            >
              Duplicate
            </ContextMenu.Item>
            {!isLastItem && (
              <ContextMenu.Item
                icon={faTrashAlt}
                onClick={() =>
                  dispatch?.({
                    type: DecisionTableActions.DELETE_CASE,
                    payload: index,
                  })
                }
              >
                Delete Case
              </ContextMenu.Item>
            )}
          </ContextMenu>
        </div>
      </td>
    );
  },
);
