import CreditreformIcon from "src/assets/CreditreformIcon.svg?react";
import {
  getDefaultCreditreformBriefReport,
  getDefaultCreditreformCommercialReport,
  getDefaultCreditreformCompactReport,
  getDefaultCreditreformEcrefoReport,
  getDefaultCreditreformIdentificationReport,
  getDefaultCreditreformRiskCheckReport,
  getDefaultCreditreformSearchRequest,
  getDefaultCreditreformTrafficLightReport,
  getDefaultCreditreformMailboxDirectory,
  getDefaultCreditreformMailboxEntry,
} from "src/connections/connectionConfigs/creditreform/creditreformResources";
import { CreditreformTemplate } from "src/connections/connectionConfigs/creditreform/creditreformTemplate";
import { ConnectionConfig } from "src/connections/connectionConfigs/types";

export const CREDITREFORM_CONFIG: ConnectionConfig<"creditreform"> = {
  icon: CreditreformIcon,
  template: CreditreformTemplate,
  description: "Providing B2B credit ratings and balance sheets",
  name: "Creditreform",
  resources: {
    brief_report: {
      externalLink:
        "https://www.notion.so/taktile/Brief-Report-Kurzauskunft-4769098abda14a188a36243d7363ebd3?pvs=4",
      integrationResource: getDefaultCreditreformBriefReport,
    },
    commercial_report: {
      externalLink:
        "https://www.notion.so/taktile/Commercial-Report-Wirtschaftsauskunft-88cf27c432de4f5082920c317a062b63",
      integrationResource: getDefaultCreditreformCommercialReport,
    },
    compact_report: {
      externalLink:
        "https://www.notion.so/taktile/Compact-Report-Kompaktauskunft-9b00afba7f474f1d9f5ca9648679caa3?pvs=4",
      integrationResource: getDefaultCreditreformCompactReport,
    },
    risk_check_report: {
      externalLink:
        "https://www.notion.so/taktile/Risk-Check-RisikoCheck-9dea4bffa60348f393d98f84768337c7?pvs=4",
      integrationResource: getDefaultCreditreformRiskCheckReport,
    },
    ecrefo_report: {
      externalLink:
        "https://www.notion.so/taktile/eCrefo-Report-80cc5944f74b47f8a21f141ac0096c37?pvs=4",
      integrationResource: getDefaultCreditreformEcrefoReport,
    },
    identification_report: {
      externalLink:
        "https://www.notion.so/taktile/Searching-for-a-company-ebb9ba5b876c4367a47d6edb6b8879af?pvs=4",
      integrationResource: getDefaultCreditreformIdentificationReport,
    },
    search_request: {
      externalLink:
        "https://www.notion.so/taktile/Searching-for-a-company-ebb9ba5b876c4367a47d6edb6b8879af?pvs=4",
      integrationResource: getDefaultCreditreformSearchRequest,
    },
    traffic_light_report: {
      externalLink:
        "https://www.notion.so/taktile/Traffic-Light-Report-Ampelauskunft-7ab3b22d4d084b1f9c0a2c3b837f5449?pvs=4",
      integrationResource: getDefaultCreditreformTrafficLightReport,
    },
    creditreform_mailbox_directory: {
      externalLink:
        "https://www.notion.so/taktile/Commercial-Report-Mailbox-Directory-b5f306bbd34d405b8c8c7a2bfa3de216",
      integrationResource: getDefaultCreditreformMailboxDirectory,
    },
    creditreform_mailbox_entry: {
      externalLink:
        "https://www.notion.so/taktile/Commercial-Report-Mailbox-Entry-705d2208d4d7475fa61cfcda9e7e1faa",
      integrationResource: getDefaultCreditreformMailboxEntry,
    },
  },
};
