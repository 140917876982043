import {
  ColumnDef,
  HeaderGroup,
  Row,
  useReactTable,
  flexRender,
  getCoreRowModel,
} from "@tanstack/react-table";
import { times } from "lodash";
import { ReactNode, forwardRef, useCallback, useEffect, useRef } from "react";
import {
  defaultRangeExtractor,
  Range,
  useVirtual,
  VirtualItem,
} from "react-virtual";
import { twJoin } from "tailwind-merge";
import { useSessionStorage } from "usehooks-ts";

import { NodeTestRunResult } from "src/api/types";
import { Tooltip } from "src/base-components/Tooltip";
import {
  AUX_DATA_COLUMN_PREFIX,
  EXPECTED_DATA_COLUMN_PREFIX,
  INDEX_COLUMN_ID,
  RESULT_COLUMN_PREFIX,
  STATUS_COLUMN_ID,
} from "src/dataTable/TablePrefixes";
import { ResultDataAndAuxRowV2 } from "src/dataTable/types";
import {
  useIsSelectedResultsRow,
  useSelectedResultsRowIndex,
} from "src/flowContainer/AuthoringUIContext";
import { TableHeaderWithColumnPicker } from "src/nodeEditor/TableHeaderWithColumnPicker";
import { usePersistedVisibleColumns } from "src/nodeEditor/usePersistedVisibleColumns";

const RESULT_COLUMN_WIDTH = 142;
const INDEX_COLUMN_WIDTHS = { MIN: 40, MAX: 100 };
export const RESULT_SIZE_OVERWRITE =
  "max-w-[142px] min-w-[142px] max-h-[32px] min-h-[32px] py-1.5";

type PropsT = {
  testResult: NodeTestRunResult;
  data: ResultDataAndAuxRowV2[];
  columns: ColumnDef<ResultDataAndAuxRowV2, string>[];
  lastRunDatasetID?: string;
  canFetchNextBatch: boolean;
  fetchNextBatch: () => void;
  onErrorClick: (errorIndex: number) => void;
  onIgnoredTooltipClick: () => void;
  onIgnoredClick: (ignoredIndex: number) => void;
  displayingOutputNode: boolean;
  getNodeName: (nodeId: string) => string | undefined;
  isFetching: boolean;
  rowCountInfo: ReactNode;
  statusFilter: string;
};

const HeaderGroupDisplay: React.FC<{
  headerGroup: HeaderGroup<ResultDataAndAuxRowV2>;
  virtualColumns: VirtualItem[];
  paddingLeft: number;
  paddingRight: number;
}> = ({ headerGroup, virtualColumns, paddingLeft, paddingRight }) => (
  <tr className="sticky top-0 z-20 bg-white bg-opacity-100">
    {paddingLeft > 0 && (
      <th style={{ minWidth: paddingLeft, maxWidth: paddingLeft }}></th>
    )}
    {virtualColumns.map((virtualColumn) => {
      const column = headerGroup.headers[virtualColumn.index];
      const isIndexColumn = column.id === INDEX_COLUMN_ID;
      return (
        <th
          key={column.id}
          className={twJoin(
            "p-0",
            (isIndexColumn || column.id.startsWith(AUX_DATA_COLUMN_PREFIX)) &&
              "bg-gray-50",
            isIndexColumn && "sticky left-0",
          )}
          style={{
            minWidth: isIndexColumn
              ? INDEX_COLUMN_WIDTHS.MIN
              : RESULT_COLUMN_WIDTH,
            maxWidth: isIndexColumn
              ? INDEX_COLUMN_WIDTHS.MAX
              : RESULT_COLUMN_WIDTH,
          }}
        >
          {flexRender(column.column.columnDef.header, column.getContext())}
        </th>
      );
    })}
    {paddingRight > 0 && (
      <th>
        <div style={{ minWidth: paddingRight, maxWidth: paddingRight }} />
      </th>
    )}
  </tr>
);

const RowDisplay = forwardRef<
  HTMLTableRowElement,
  {
    row: Row<ResultDataAndAuxRowV2>;
    onErrorClick: (errorIndex: number) => void;
    onIgnoredClick: (ignoredIndex: number) => void;
    onIgnoredTooltipClick: () => void;
    displayingOutputNode: boolean;
    getNodeName: (nodeId: string) => string | undefined;
    virtualColumns: VirtualItem[];
    paddingLeft: number;
    paddingRight: number;
  }
>(
  (
    {
      row,
      onErrorClick,
      displayingOutputNode,
      getNodeName,
      virtualColumns,
      paddingLeft,
      paddingRight,
      onIgnoredClick,
      onIgnoredTooltipClick,
    },
    ref,
  ) => {
    const isSelectedResultsRow = useIsSelectedResultsRow(row.original.index);
    return (
      <tr
        ref={ref}
        className={twJoin(
          "border-y border-gray-100",
          row.original.type === "failure" && "bg-red-50",
          row.original.type === "ignored" && "bg-gray-50",
          "group/row",
          isSelectedResultsRow && "outline outline-1 outline-indigo-500",
        )}
      >
        {paddingLeft > 0 && (
          <td
            style={{
              width: `${paddingLeft}px`,
            }}
          />
        )}
        {virtualColumns.map((virtualColumn) => {
          const cell = row.getVisibleCells()[virtualColumn.index];

          const isAuxData = cell.column.id.startsWith(AUX_DATA_COLUMN_PREFIX);
          const isIndexColumn =
            cell.column.id === INDEX_COLUMN_ID &&
            row.original.type !== "failure";

          const isStatusCellOfAMismatchedRow =
            cell.column.id === STATUS_COLUMN_ID &&
            cell.row.original.type === "success_mismatch";

          const expectedOutputKeysWithPrefix =
            (cell.row.original.type === "success_match" ||
              cell.row.original.type === "success_mismatch") &&
            cell.row.original.expectedOutputData
              ? Object.keys(cell.row.original.expectedOutputData).flatMap(
                  (key) => [
                    RESULT_COLUMN_PREFIX + key,
                    EXPECTED_DATA_COLUMN_PREFIX + key,
                  ],
                )
              : [];

          const mismatchedKeysWithPrefix =
            cell.row.original.type === "success_mismatch" &&
            cell.row.original.expectedOutputKeysMismatch
              ? cell.row.original.expectedOutputKeysMismatch.flatMap((key) => [
                  RESULT_COLUMN_PREFIX + key,
                  EXPECTED_DATA_COLUMN_PREFIX + key,
                ])
              : [];

          const isAMatchingExpectedDataCell =
            expectedOutputKeysWithPrefix.includes(cell.column.id) &&
            !mismatchedKeysWithPrefix.includes(cell.column.id);

          const isAMismatchingExpectedDataCell =
            expectedOutputKeysWithPrefix.includes(cell.column.id) &&
            mismatchedKeysWithPrefix.includes(cell.column.id);

          return (
            <td
              key={cell.id}
              className={twJoin(
                "p-0",
                (isAuxData || isIndexColumn) && "bg-gray-50",
                (isStatusCellOfAMismatchedRow ||
                  isAMismatchingExpectedDataCell) &&
                  "bg-yellow-50",
                isAMatchingExpectedDataCell && "bg-green-50",
                row.original.type === "failure" && "bg-red-50",
                row.original.type === "ignored" && "bg-gray-50",
                cell.column.id === INDEX_COLUMN_ID
                  ? "sticky left-0 z-10"
                  : "relative z-0",
              )}
            >
              {cell.column.id === STATUS_COLUMN_ID ? (
                row.original.type === "failure" ? (
                  <Tooltip
                    activated={displayingOutputNode}
                    placement="top"
                    title={`Error on Node: ${getNodeName(
                      row.original.type === "failure"
                        ? (row.original.errorOriginNodeId ?? "")
                        : "",
                    )}`}
                  >
                    <div onClick={() => onErrorClick(row.original.index)}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </div>
                  </Tooltip>
                ) : row.original.type === "ignored" ? (
                  <Tooltip
                    action={{
                      label: "Add mock data",
                      onClick: onIgnoredTooltipClick,
                    }}
                    body="To test this Node with mock data, please edit the test dataset to add mock data columns."
                    placement="top"
                    title="Row ignored due to missing mock data"
                  >
                    <div onClick={() => onIgnoredClick(row.original.index)}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </div>
                  </Tooltip>
                ) : (
                  flexRender(cell.column.columnDef.cell, cell.getContext())
                )
              ) : (
                flexRender(cell.column.columnDef.cell, cell.getContext())
              )}
            </td>
          );
        })}
        {paddingRight > 0 && <td style={{ width: `${paddingRight}px` }} />}
      </tr>
    );
  },
);

const getPaddingLeft = (virtualColumns: VirtualItem[]) => {
  if (virtualColumns.length > 0) {
    // First column is always the index column
    const firstColumn = virtualColumns?.[0];
    // Second column might be any column depending on virtualization
    const secondColumn = virtualColumns?.[1];
    // if the second column is the real second column, just return default value
    if (secondColumn?.index === 1) {
      return firstColumn.start;
    }
    return secondColumn?.start ?? 0;
  }

  return 0;
};

const ROW_HEIGHT_PX = 33;

const useScrollToSelectedRow = (
  data: ResultDataAndAuxRowV2[],
  scrollToIndex: ReturnType<typeof useVirtual>["scrollToIndex"],
) => {
  const SCROLL_ITEMS_OFFSET = 5;
  const selectedRowIndex = useSelectedResultsRowIndex();
  // We want to scroll only once after table mount
  // And only if the selectedRowIndex exists from the start
  const isScrolled = useRef(!selectedRowIndex);

  useEffect(() => {
    if (selectedRowIndex && !isScrolled.current) {
      const index = data.findIndex((row) => row.index === selectedRowIndex);

      if (index !== -1) {
        scrollToIndex(index - SCROLL_ITEMS_OFFSET, {
          align: "start",
        });
        isScrolled.current = true;
      }
    }
  }, [data, scrollToIndex, selectedRowIndex]);
};
export const IntermediateResultsTableV2: React.FC<PropsT> = ({
  data,
  columns,
  lastRunDatasetID,
  canFetchNextBatch,
  fetchNextBatch,
  onErrorClick,
  displayingOutputNode,
  getNodeName,
  isFetching,
  rowCountInfo,
  onIgnoredClick,
  onIgnoredTooltipClick,
  testResult,
  statusFilter,
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const fetchMoreOnCloseToBottom = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 1000px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 1000 &&
          canFetchNextBatch
        ) {
          fetchNextBatch();
        }
      }
    },
    [fetchNextBatch, canFetchNextBatch],
  );

  //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnCloseToBottom(tableContainerRef.current);
  }, [fetchMoreOnCloseToBottom]);

  const selectedRowIndex = useSelectedResultsRowIndex();
  useEffect(() => {
    // Fetch more data if there is selectedRow and we are not have it in the data yet
    if (
      selectedRowIndex &&
      canFetchNextBatch &&
      !data.some((row) => row.index >= selectedRowIndex)
    ) {
      fetchNextBatch();
    }
  }, [canFetchNextBatch, data, fetchNextBatch, selectedRowIndex]);

  const [columnVisibility, setVisibleColumns] = usePersistedVisibleColumns(
    columns,
    lastRunDatasetID ?? "",
  );

  const tableInstance = useReactTable({
    columns,
    data,
    state: {
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setVisibleColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const headerGroups = tableInstance.getHeaderGroups();
  const rows = tableInstance.getRowModel().rows;
  const visibleColumns = tableInstance.getVisibleFlatColumns();

  // Save the current first visible index so we can restore
  // the scroll when the user switches between nodes
  const [currentFirstVisibleIndex, setCurrentFirstVisibleIndex] =
    useSessionStorage<number>(
      `current-first-visible-index-${testResult.test_run_id}-${testResult.node_id}-${statusFilter}`,
      0,
    );

  const ROW_VIRTUALIZATION_OVERSCAN = 10;

  // Row virtualization implemented as in the react-rable example:
  // https://tanstack.com/table/v8/docs/examples/react/virtualized-rows
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: ROW_VIRTUALIZATION_OVERSCAN,
    estimateSize: useCallback(() => ROW_HEIGHT_PX, []),
  });
  const {
    virtualItems: virtualRows,
    totalSize: totalRowSize,
    scrollToIndex,
  } = rowVirtualizer;
  // Dynamic top and botton padding is added to the table content to maintain the correct scroll bar size and position
  const paddingTop = virtualRows?.[0]?.start || 0;
  const paddingBottom =
    totalRowSize - (virtualRows?.[virtualRows.length - 1]?.end || 0);
  const firstVisibleIndex = virtualRows.at(0)?.index;

  useEffect(() => {
    setCurrentFirstVisibleIndex(firstVisibleIndex ?? 0);
  }, [firstVisibleIndex, setCurrentFirstVisibleIndex]);

  useEffect(() => {
    scrollToIndex(currentFirstVisibleIndex + ROW_VIRTUALIZATION_OVERSCAN, {
      align: "start",
    });
    // Only execute on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useScrollToSelectedRow(data, scrollToIndex);

  const columnVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: visibleColumns.length,
    overscan: 4,
    horizontal: true,
    estimateSize: useCallback(() => RESULT_COLUMN_WIDTH, []),
    rangeExtractor: useCallback((range: Range) => {
      const defaultRange = defaultRangeExtractor(range);
      if (defaultRange[0] === 0) {
        return defaultRange;
      }
      // Include the index column and render it, because it is sticky
      return [0, ...defaultRangeExtractor(range)];
    }, []),
  });
  const { virtualItems: virtualColumns, totalSize: totalColumnSize } =
    columnVirtualizer;
  // Dynamic left and right padding is added to the table content to maintain the correct scroll bar size and position
  const paddingLeft = getPaddingLeft(virtualColumns);
  const paddingRight =
    virtualColumns.length > 0
      ? totalColumnSize -
        (virtualColumns?.[virtualColumns.length - 1]?.end || 0)
      : 0;

  const nextPageSkeleton = times(data.length === 0 ? 20 : 3, (index) => (
    <tr
      key={`table-page-skeleton-${index}`}
      className="border-y border-gray-100"
    >
      {paddingLeft > 0 && (
        <td
          style={{
            width: `${paddingLeft}px`,
          }}
        />
      )}
      {virtualColumns.map((_, index) => (
        <td key={index} className="border-r border-gray-100 pl-2">
          <div className="flex h-8 items-center py-1.5">
            <div className="relative h-3.5 w-1/2 overflow-hidden rounded bg-gray-100">
              <div
                className="absolute inset-0 -translate-x-full transform bg-gradient-to-r from-gray-100 via-gray-50 to-gray-100"
                style={{ animation: "shimmer 1s infinite" }}
              ></div>
            </div>
          </div>
        </td>
      ))}
      {paddingRight > 0 && <td style={{ width: `${paddingRight}px` }} />}
    </tr>
  ));

  return (
    <>
      <TableHeaderWithColumnPicker tableInstance={tableInstance} />
      <div className="mb-3 pr-6">{rowCountInfo}</div>
      <div
        ref={tableContainerRef}
        className="decideScrollbar relative isolate h-full min-h-0 flex-1 overflow-auto border border-gray-100"
        data-loc="intermediate-table-container"
        onScroll={(e) => fetchMoreOnCloseToBottom(e.target as HTMLDivElement)}
      >
        <table
          className="table-auto border-collapse border-spacing-0"
          data-loc="intermediate-table"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <HeaderGroupDisplay
                key={headerGroup.id}
                headerGroup={headerGroup}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                virtualColumns={virtualColumns}
              />
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<ResultDataAndAuxRowV2>;
              return (
                <RowDisplay
                  key={row.id}
                  ref={virtualRow.measureRef}
                  displayingOutputNode={displayingOutputNode}
                  getNodeName={getNodeName}
                  paddingLeft={paddingLeft}
                  paddingRight={paddingRight}
                  row={row}
                  virtualColumns={virtualColumns}
                  onErrorClick={onErrorClick}
                  onIgnoredClick={onIgnoredClick}
                  onIgnoredTooltipClick={onIgnoredTooltipClick}
                />
              );
            })}
            {isFetching && nextPageSkeleton}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
