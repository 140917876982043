import { useEffect } from "react";

import { FlowNodeDataT, LoopNodeDataT } from "src/constants/NodeDataTypes";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";

export const useUpdateKnownEtag = ({
  nodeId,
  nodeData,
  immutable,
  isReactive,
  onUpdate,
  etag,
}: {
  nodeId: string;
  nodeData: FlowNodeDataT | LoopNodeDataT;
  immutable: boolean;
  isReactive: boolean;
  onUpdate: NodeEditorBaseProps<LoopNodeDataT | FlowNodeDataT>["onUpdate"];
  etag: string | undefined;
}) => {
  /**
   * This function ensures that the child_flow_version_known_etag stored in the node meta data
   * matches the latest etag of the child version the editor has "seen".
   * Does not trigger updates when the version is immutable or reactive.
   */
  useEffect(() => {
    if (
      etag &&
      nodeData.child_flow_version_known_etag !== etag &&
      !isReactive &&
      !immutable
    ) {
      onUpdate({
        nodeId: nodeId,
        newData: {
          ...nodeData,
          child_flow_version_known_etag: etag,
        },
      });
    }
  }, [etag, onUpdate, nodeData, nodeId, isReactive, immutable]);
};
