import { faOctagonCheck } from "@fortawesome/pro-regular-svg-icons";

import { Overlay } from "src/performance/Overlay";

export const NoErrorsOverlay: React.FC = () => (
  <Overlay
    description="There were no errors in the filtered range."
    icon={faOctagonCheck}
    message="No errors"
  />
);
