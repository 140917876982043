import { faUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";

import { ExternalLink } from "src/base-components/ExternalLink";
import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/base-components/Tooltip";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { VersionStatusPill } from "src/flow/Pills";
import { GroupedRelatedFlows } from "src/flow/modals/utils";
import { getBaseUrl, getUrlToAuthoringPage } from "src/router/urls";

type RelatedFlowListProps = {
  groupedRelatedFlows: GroupedRelatedFlows;
  workspace: WorkspaceDataplane;
};

export const RelatedFlowList: React.FC<RelatedFlowListProps> = ({
  groupedRelatedFlows,
  workspace,
}) => {
  const buildRelatedVersionPageUrl = (flowId: string, versionId: string) =>
    getBaseUrl() +
    getUrlToAuthoringPage(
      workspace.organization_id,
      workspace.id,
      flowId,
      versionId,
    );
  const relatedFlowIDs = Object.keys(groupedRelatedFlows);
  return (
    <>
      {relatedFlowIDs.map((id) => (
        <ul key={id} className="[&:not(:last-child)]:pb-3">
          {[
            <li key={id} className="text-gray-800 font-inter-semibold-13px">
              {groupedRelatedFlows[id]?.name}
            </li>,
            groupedRelatedFlows[id]?.versions?.map((version) => (
              <li
                key={version.id}
                className="flex items-center gap-x-2 border-b border-b-transparent py-3 [&:not(:last-child)]:border-gray-200"
              >
                <span className="mr-auto text-gray-500 font-inter-normal-13px">
                  {version.name}
                </span>
                <VersionStatusPill status={version.status} />
                <Tooltip
                  align="end"
                  placement="bottom"
                  title="Open in new tab"
                  asChild
                >
                  <ExternalLink
                    href={buildRelatedVersionPageUrl(id, version.id)}
                  >
                    <Icon
                      color="text-gray-500"
                      icon={faUpRightFromSquare}
                      size="xs"
                    />
                  </ExternalLink>
                </Tooltip>
              </li>
            )),
          ]}
        </ul>
      ))}
    </>
  );
};
