import { faBolt, faPlus } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { Button } from "src/base-components/Button";
import { EmptyState } from "src/base-components/EmptyState";
import { useModal } from "src/design-system/Modal";
import { SubHeader, SubHeaderFilter } from "src/flow/SubHeader";
import { useCapabilities } from "src/hooks/useCapabilities";
import { JobsTable } from "src/jobs/JobsTable";
import { useJobsWithVersionsUsed } from "src/jobs/api/queries";
import { ManageJobModal } from "src/jobs/common/ManageJobModal";
import { WhitePane } from "src/jobs/common/WhitePane";
import { JobStatus, JobWithVersionsUsed } from "src/jobs/types";
import { useFlowContext } from "src/router/routerContextHooks";

export const JobsContent: React.FC = () => {
  const { workspace, flow } = useFlowContext();
  const [statusFilter, setStatusFilter] =
    React.useState<Nullable<JobStatus>>(null);
  const { data, isLoading } = useJobsWithVersionsUsed(
    workspace.base_url!,
    flow,
    statusFilter,
  );

  const thereAreNoJobs = !isLoading && data?.length === 0;

  const { isOpen, openModal, closeModal } = useModal();

  const { jobs } = useCapabilities();

  return (
    <>
      <SubHeader title="Jobs" paddedParent>
        <SubHeaderFilter
          options={[
            { key: "active", value: "Active" },
            { key: "inactive", value: "Inactive" },
          ]}
          selected={statusFilter}
          onChange={setStatusFilter}
        />
        {jobs.canCreate && (
          <SubHeader.Button
            icon={faPlus}
            tooltip="Create Job"
            onClick={openModal}
          />
        )}
      </SubHeader>
      <div
        className="m-auto flex w-full flex-col gap-y-5.5"
        data-loc="jobs-content"
      >
        <div className="flex justify-between">
          <WhitePane>
            {thereAreNoJobs ? (
              statusFilter === null ? (
                <EmptyState
                  action={
                    <Button
                      dataLoc="create-job-button"
                      iconLeft={faPlus}
                      variant="secondary"
                      onClick={openModal}
                    >
                      Create a Job
                    </Button>
                  }
                  description="You can add jobs associated with this Decision Flow. The Job can be manually run, or can be setup to automatically run based on a schedule."
                  headline="No jobs added"
                  icon={faBolt}
                />
              ) : (
                <EmptyState
                  description="No records match your current filter criteria. Try to adjust your filter settings or reset the filter."
                  headline="No jobs found"
                  icon={faBolt}
                />
              )
            ) : (
              <JobsTable
                data={(data as JobWithVersionsUsed[]) ?? []}
                isLoading={isLoading}
              />
            )}
          </WhitePane>
        </div>
      </div>
      <ManageJobModal action="create" open={isOpen} onClose={closeModal} />
    </>
  );
};
