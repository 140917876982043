import { DataType, JsonViewer, defineEasyType } from "@textea/json-viewer";
import { capitalize } from "lodash";
import React from "react";
import { twMerge } from "tailwind-merge";

import { GenericObjectT } from "src/api/flowTypes";
import { copyTextToClipboard } from "src/utils/clipboard";
import { jsonStringSpeakPython } from "src/utils/speakPython";

type PropsT = {
  dataLoc?: string;
  containerRef?:
    | React.RefObject<HTMLDivElement>
    | ((div: HTMLDivElement) => void);
  jsonObject: GenericObjectT;
  speakPython?: boolean;
  innerDimensionClass?: string;
  defaultInspectDepth?: number;
  enableClipboard?: boolean;
};

const THEME = {
  base00: "#00000000", // Transparent BG
  base01: "#f59e0b",
  base02: "#E5E6EB", // Side line expanded objects
  base03: "#000000",
  base04: "#000000",
  base05: "#000000",
  base06: "#000000",
  base07: "#000000", // Base text color
  base08: "#0000ff", // Null text
  base09: "#a31515", // Strings
  base0A: "#0000ff",
  base0B: "#098658", // Float numbers
  base0C: "#098658", // Array indexes
  base0D: "#0000ff", // Arrow
  base0E: "#0000ff", // Booleans
  base0F: "#098658", // Numbers
} as const;

const jsonAtomicsToPythonMapperArray: DataType<any>[] = [
  defineEasyType<any>({
    is: (value: any) => value === true || value === false,
    type: "boolean",
    colorKey: "base0E",
    Renderer: (props: any) => <span>{capitalize(String(props.value))}</span>,
  }),
  defineEasyType<any>({
    is: (value: any) => value === null,
    type: "null",
    colorKey: "base08",
    Renderer: () => <span>None</span>,
  }),
];

const copySpeakPythonToClipboard = (_path: any, value: any) => {
  copyTextToClipboard(jsonStringSpeakPython(JSON.stringify(value)));
};

export const ObjectDetailPane: React.FC<PropsT> = ({
  dataLoc,
  containerRef,
  jsonObject,
  speakPython = true,
  innerDimensionClass,
  defaultInspectDepth = 3,
  enableClipboard = true,
}) => {
  return (
    <div
      ref={containerRef}
      className={twMerge(
        "decideScrollbar overflow-auto rounded-lg border border-gray-200 bg-gray-50 bg-opacity-50 p-2 font-code-12",
        innerDimensionClass,
      )}
      data-loc={dataLoc}
    >
      <JsonViewer
        defaultInspectDepth={defaultInspectDepth}
        displayDataTypes={false}
        displaySize={false}
        enableClipboard={enableClipboard}
        rootName={false}
        theme={THEME}
        value={jsonObject}
        valueTypes={speakPython ? jsonAtomicsToPythonMapperArray : undefined}
        onCopy={copySpeakPythonToClipboard}
      />
    </div>
  );
};
