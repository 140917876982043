import { useOrganizationUser } from "src/api/taktile/queries";
import { LoadingView } from "src/base-components/LoadingView";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import {
  UserAvatar,
  UserAvatarLoading,
} from "src/base-components/UserAvatar/UserAvatar";
import { OrganizationUser } from "src/clients/taktile-api";
import { DashboardPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type UserProps = {
  name: string;
  avatar?: string;
};

export const User: React.FC<UserProps> = ({ name, avatar }) => (
  <div className="flex max-w-[15rem] items-center gap-2" data-loc="user-info">
    <UserAvatar
      size="sm"
      user={{ full_name: name, avatar_url: avatar, username: name }}
    />
    <div className="flex-1 truncate" data-loc="user-name">
      {name}
    </div>
  </div>
);

const UserSkeleton: React.FC = () => (
  <div
    className="flex max-w-[15rem] items-center gap-2"
    data-loc="user-skeleton"
  >
    <UserAvatarLoading size="sm" />
    <SkeletonPlaceholder height="h-3" />
  </div>
);

type UserFromIdProps = {
  userId: string;
  preferedIdentifier?: "full_name" | "email";
};

export const UserFromId: React.FC<UserFromIdProps> = ({
  userId,
  preferedIdentifier = "email",
}) => {
  const { orgId } = useParamsDecode<DashboardPageParamsT>();
  const userResult = useOrganizationUser(orgId, userId);
  return (
    <LoadingView
      queryResult={userResult}
      renderErrored={() => <>Data not available</>}
      renderUpdated={(user: OrganizationUser) => {
        const displayName =
          preferedIdentifier === "full_name"
            ? user?.full_name || user?.email || user?.username
            : user?.email || user?.full_name || user?.username;
        return <User avatar={user?.avatar_url} name={displayName || ""} />;
      }}
      renderUpdating={() => <UserSkeleton />}
    />
  );
};
