import { createContext } from "react";

import { createFloatingWindowsStore } from "src/base-components/FloatingWindow/store";

export type FloatingWindowsContextType = ReturnType<
  typeof createFloatingWindowsStore
>;

export const FloatingWindowsContext =
  createContext<FloatingWindowsContextType | null>(null);
