import { makeConnectionTemplate } from "src/connections/types";

export const SentiLinkTemplate = makeConnectionTemplate({
  provider: "sentilink",
  secrets: [
    { key: "account_id", name: "Account ID", required: true },
    { key: "token", name: "Token", required: true },
  ],
  resources: {
    sentilink_synthetic_fraud_score: {
      name: "Synthetic Fraud Score",
      configKeys: [],
    },
    sentilink_identity_theft_score: {
      name: "Identity Theft Score",
      configKeys: [],
    },
    sentilink_facets: {
      name: "Facets",
      configKeys: [
        {
          key: "products",
          required: true,
          name: "Products",
          type: "multiple_selection",
          elements: [
            { key: "all_attributes", value: "All attributes" },
            { key: "applicant_history", value: "Applicant history" },
            { key: "network_intelligence", value: "Network intelligence" },
            { key: "phone_intelligence", value: "Phone intelligence" },
            { key: "email_intelligence", value: "Email intelligence" },
            { key: "fpd_lending", value: "FPD lending" },
          ],
        },
      ],
    },
    sentilink_id_complete: {
      name: "ID Complete",
      configKeys: [],
    },
    sentilink_insights_report: {
      name: "Insights Report",
      configKeys: [],
    },
  },
});
