import { faText, fa00, faHashtag } from "@fortawesome/pro-solid-svg-icons";

import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";

const getTypeIcon = (type: string) => {
  switch (type) {
    case "tensor(int64)":
      return faHashtag;
    case "tensor(float)":
    case "tensor(double)":
      return fa00;
    case "tensor(string)":
    case "tensor(boolean)":
      return faText;
    default:
      return faText;
  }
};

type TensorPropsT = {
  dataLoc?: string;
  name: string;
  shape: (number | null)[];
  dtype: string;
};

export const Tensor: React.FC<TensorPropsT> = ({
  name,
  shape,
  dtype,
  dataLoc,
}) => (
  <div className="flex items-center gap-1" data-loc={dataLoc}>
    <Icon color="text-gray-500" icon={getTypeIcon(dtype)} size="xs" />
    <div className="flex-1 text-gray-800 font-inter-normal-13px">{name}</div>
    <div className="flex gap-1">
      <Pill size="sm" variant="gray">
        <Pill.Text>{dtype}</Pill.Text>
      </Pill>
      <Pill size="sm" variant="gray">
        <Pill.Text>
          [{shape.map((v) => (v === null ? "None" : v)).join(", ")}]
        </Pill.Text>
      </Pill>
    </div>
  </div>
);
