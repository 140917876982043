import { faChartMixed } from "@fortawesome/pro-regular-svg-icons";

import { Overlay } from "src/performance/Overlay";

export const NoDataOverlay: React.FC = () => (
  <Overlay
    description="No data was found for the selected filters."
    icon={faChartMixed}
    message="No data available"
  />
);
