import { observer } from "mobx-react-lite";
import React from "react";

import { DEFAULT_UNTITLED_NODE_NAME } from "../constants/DefaultValues";
import { LOCK_NODE_EDITOR_CLASSNAME } from "src/authoringMultiplayerLock/constants";
import { useVersionResourceLock } from "src/authoringMultiplayerLock/useVersionResourceLock";
import { TitleEditor } from "src/base-components/TitleEditor";
import { ResourceType } from "src/clients/flow-api";
import { NodeT } from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { useCanAuthoringEditFlowVersion } from "src/hooks/useCanAuthoringEditFlowVersion";
import { useGraphStore } from "src/store/StoreProvider";

type Props = {};

const getValue = (selectedNode: NodeT | undefined) => {
  if (selectedNode?.data.label === DEFAULT_UNTITLED_NODE_NAME) {
    return undefined;
  }
  return selectedNode?.data.label;
};

export const getInputOutputNodeTitle = (selectedNode: NodeT) => {
  if (selectedNode?.type === NODE_TYPE.INPUT_NODE) {
    return "Overall Input";
  } else if (selectedNode?.type === NODE_TYPE.OUTPUT_NODE) {
    return "Overall Output";
  }

  return undefined;
};

export const NodeTitleEditor: React.FC<Props> = observer(() => {
  const { selectedNode, updateNode } = useGraphStore();

  const { lockedByOtherUser } = useVersionResourceLock(
    ResourceType.NODE,
    selectedNode?.id,
  );

  const canEditFlowVersion = useCanAuthoringEditFlowVersion();

  const isNotEditableNodeTitle =
    selectedNode?.type === NODE_TYPE.INPUT_NODE ||
    selectedNode?.type === NODE_TYPE.OUTPUT_NODE;

  const isDisabled = !canEditFlowVersion || lockedByOtherUser;

  if (isNotEditableNodeTitle) {
    return (
      <span className="w-full text-gray-800 font-inter-semibold-16px">
        {getInputOutputNodeTitle(selectedNode)}
      </span>
    );
  }

  return (
    // Keep -translate, it compensates 1px border of input inside editor
    <div className="-ml-1.5 -translate-x-px">
      <TitleEditor
        className={LOCK_NODE_EDITOR_CLASSNAME}
        dataLoc="node-title-editor"
        disabled={isDisabled}
        placeholder={DEFAULT_UNTITLED_NODE_NAME}
        value={getValue(selectedNode)}
        onSubmit={(val) => updateNode({ newName: val })}
      />
    </div>
  );
});
