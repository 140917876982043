import { isEqual } from "lodash";

import {
  AuthSettings,
  ConnectionCreateT,
  ConnectionT,
} from "src/api/connectApi/types";
import { taktileInternalPrefix } from "src/api/constants";
import { WorkspaceWithSettings } from "src/api/types";
import {
  EventAttributeEnv,
  EventAttributeStatus,
  EventFilter,
  EventType,
  JobRunEventAttributeStatus,
  ResourceType,
  Webhook,
} from "src/clients/flow-api";
import {
  isOutgoingWebhookConnectionAuthMethod,
  OutgoingWebhookConnectionAuthMethod,
} from "src/connections/config/CCAuthMethodDropdownOptions";
import {
  Oauth2SettingsToOauthInputs,
  authSettingsFromConfig,
  connectionConfigAuthMethodsDefaultValues,
} from "src/connections/model/model";
import { ConnectionConfigAuthMethods } from "src/connections/types";
import * as logger from "src/utils/logger";
import { getRandomIntFromInterval } from "src/utils/mathUtils";
import { assertUnreachable } from "src/utils/typeUtils";
import {
  OutgoingWebhookConnectionForm,
  OutgoingWebhookConnectionSecret,
  TriggerSelection,
} from "src/webhooks/editModal/EditOutgoingWebhookModal";

const getSecretsForPatch = (
  secrets: OutgoingWebhookConnectionSecret[],
  currentBackendSecrets: { key: string; value: string }[],
) => {
  const deletedSecrets: { key: string; value: null }[] = currentBackendSecrets
    .filter((beSecret) =>
      secrets.every((feSecret) => feSecret.key !== beSecret.key),
    )
    .map((beSecret) => ({ key: beSecret.key, value: null }));
  const editedScrets = secrets
    .filter((secret) => secret.editable)
    .map((secret) => ({
      key: secret.key,
      value: secret.value,
    }));
  return [...editedScrets, ...deletedSecrets];
};

export const outgoingWebhookInputsToCreateConnection = (
  formData: OutgoingWebhookConnectionForm,
  resourceId: string,
  webhookIndex: number,
  workspace: WorkspaceWithSettings,
): ConnectionCreateT => {
  const { url, secrets, currentBackendSecrets } = formData;
  const authSettings = authSettingsFromConfig({
    ...formData,
    enableSSL: false,
  });

  return {
    name: `${taktileInternalPrefix}WH_${resourceId}_${webhookIndex}`,
    is_sandbox: false,
    configuration: {
      base_url: url,
      probe_url: url,
      allow_test_invocations: true,
      auth_settings: authSettings,
    },
    secrets: getSecretsForPatch(secrets, currentBackendSecrets),
    provider: "custom",
    enable_non_prod_configs: false,
    non_prod_env_configs: {},
    ...(workspace.settings.data_retention && {
      data_retention: workspace.settings.data_retention,
    }),
  };
};

const getWebhookKey = (
  params: Pick<Webhook, "resource_type" | "resource_id"> & {
    index: number;
  },
) => `CM_${params.resource_type}_${params.resource_id}_${params.index}`;
export const getIndexFromOutgoingWebhookKey = (key: string) => {
  const subParts = key.split("_");
  return Number(subParts[subParts.length - 1]);
};

/**
 * As the next index generates a number that is bigger than the current max index.
 * The maximum index scales with the number of existing webhooks to prevent the "remaing range"
 * random indexes can fall in from becoming small.
 */
export const getNextIndexForCreation = (params: {
  currentMaxIndex: number | undefined;
  currentWebhookCount: number | undefined;
}) => {
  return getRandomIntFromInterval(
    (params.currentMaxIndex ?? 0) + 1,
    10000 * (1 + (params.currentWebhookCount ?? 0)),
  );
};

export const outgoingWebhookInputsToWebhookPayload = (params: {
  formData: OutgoingWebhookConnectionForm;
  workspaceId: string;
  flowId: string;
  connectionId: string;
  resourceConfigId: string;
  index: number;
}): Webhook => {
  const { formData, workspaceId, flowId, connectionId, resourceConfigId } =
    params;

  const key = getWebhookKey({
    resource_type: ResourceType.FLOW,
    resource_id: flowId,
    index: params.index,
  });
  return {
    key,
    name: formData.name,
    workspace_id: workspaceId,
    resource_type: ResourceType.FLOW,
    resource_id: flowId,
    connection_id: connectionId,
    resource_config_id: resourceConfigId,
    pattern: triggerSelectionToEventFilter(formData, flowId),
    active: formData.active,
  };
};

export const triggerSelectionToEventFilter = (
  inputs: TriggerSelection,
  flowId: string,
): EventFilter[] => {
  const result: EventFilter[] = [];
  if (inputs.trigger.decisionOutcome.live.errored)
    result.push({
      type: EventType.DECISION_OUTCOME,
      condition: {
        decision_env: [EventAttributeEnv.LIVE],
        status: [EventAttributeStatus.ERROR],
        flow_id: [flowId],
        is_async: [true],
      },
    });
  if (inputs.trigger.decisionOutcome.live.successful)
    result.push({
      type: EventType.DECISION_OUTCOME,
      condition: {
        decision_env: [EventAttributeEnv.LIVE],
        status: [EventAttributeStatus.SUCCESS],
        flow_id: [flowId],
        is_async: [true],
      },
    });
  if (inputs.trigger.decisionOutcome.sandbox.errored)
    result.push({
      type: EventType.DECISION_OUTCOME,
      condition: {
        decision_env: [EventAttributeEnv.SANDBOX],
        status: [EventAttributeStatus.ERROR],
        flow_id: [flowId],
        is_async: [true],
      },
    });
  if (inputs.trigger.decisionOutcome.sandbox.successful)
    result.push({
      type: EventType.DECISION_OUTCOME,
      condition: {
        decision_env: [EventAttributeEnv.SANDBOX],
        status: [EventAttributeStatus.SUCCESS],
        flow_id: [flowId],
        is_async: [true],
      },
    });
  if (inputs.trigger.jobRunExecution.live.completed)
    result.push({
      type: EventType.JOB_RUN_EXECUTION,
      condition: {
        decision_env: [EventAttributeEnv.LIVE],
        status: [JobRunEventAttributeStatus.COMPLETED],
        flow_id: [flowId],
      },
    });
  if (inputs.trigger.jobRunExecution.live.failed)
    result.push({
      type: EventType.JOB_RUN_EXECUTION,
      condition: {
        decision_env: [EventAttributeEnv.LIVE],
        status: [JobRunEventAttributeStatus.FAILED],
        flow_id: [flowId],
      },
    });
  if (inputs.trigger.jobRunExecution.sandbox.completed)
    result.push({
      type: EventType.JOB_RUN_EXECUTION,
      condition: {
        decision_env: [EventAttributeEnv.SANDBOX],
        status: [JobRunEventAttributeStatus.COMPLETED],
        flow_id: [flowId],
      },
    });
  if (inputs.trigger.jobRunExecution.sandbox.failed)
    result.push({
      type: EventType.JOB_RUN_EXECUTION,
      condition: {
        decision_env: [EventAttributeEnv.SANDBOX],
        status: [JobRunEventAttributeStatus.FAILED],
        flow_id: [flowId],
      },
    });
  if (inputs.trigger.manualReviewCaseCreation.live)
    result.push({
      type: EventType.MANUAL_REVIEW_CASE_CREATION,
      condition: {
        decision_env: [EventAttributeEnv.LIVE],
        status: [EventAttributeStatus.SUCCESS],
        flow_id: [flowId],
        is_async: [true],
      },
    });
  if (inputs.trigger.manualReviewCaseCreation.sandbox)
    result.push({
      type: EventType.MANUAL_REVIEW_CASE_CREATION,
      condition: {
        decision_env: [EventAttributeEnv.SANDBOX],
        status: [EventAttributeStatus.SUCCESS],
        flow_id: [flowId],
        is_async: [true],
      },
    });
  return result;
};

const eventFilterToTriggerSelection = (
  fitlers: EventFilter[],
): TriggerSelection => {
  // Defining the object explicitely instead of importing a default to force a typescript error
  // at this location should the type be extended.
  // In case of extension handle the mapping here and in triggerSelectionToEventFilter!
  const triggerSelection = {
    decisionOutcome: {
      live: {
        successful: false,
        errored: false,
      },
      sandbox: {
        successful: false,
        errored: false,
      },
    },
    manualReviewCaseCreation: {
      live: false,
      sandbox: false,
    },
    jobRunExecution: {
      live: {
        completed: false,
        failed: false,
      },
      sandbox: {
        completed: false,
        failed: false,
      },
    },
  };
  fitlers.forEach((filter) => {
    if (filter.type === EventType.DECISION_OUTCOME) {
      if (isEqual(filter.condition.decision_env, [EventAttributeEnv.LIVE])) {
        if (isEqual(filter.condition.status, [EventAttributeStatus.SUCCESS])) {
          triggerSelection.decisionOutcome.live.successful = true;
        }
        if (isEqual(filter.condition.status, [EventAttributeStatus.ERROR])) {
          triggerSelection.decisionOutcome.live.errored = true;
        }
      }
      if (isEqual(filter.condition.decision_env, [EventAttributeEnv.SANDBOX])) {
        if (isEqual(filter.condition.status, [EventAttributeStatus.SUCCESS])) {
          triggerSelection.decisionOutcome.sandbox.successful = true;
        }
        if (isEqual(filter.condition.status, [EventAttributeStatus.ERROR])) {
          triggerSelection.decisionOutcome.sandbox.errored = true;
        }
      }
    }
    if (filter.type === EventType.JOB_RUN_EXECUTION) {
      if (isEqual(filter.condition.decision_env, [EventAttributeEnv.LIVE])) {
        if (
          isEqual(filter.condition.status, [
            JobRunEventAttributeStatus.COMPLETED,
          ])
        ) {
          triggerSelection.jobRunExecution.live.completed = true;
        }
        if (
          isEqual(filter.condition.status, [JobRunEventAttributeStatus.FAILED])
        ) {
          triggerSelection.jobRunExecution.live.failed = true;
        }
      }
      if (isEqual(filter.condition.decision_env, [EventAttributeEnv.SANDBOX])) {
        if (
          isEqual(filter.condition.status, [
            JobRunEventAttributeStatus.COMPLETED,
          ])
        ) {
          triggerSelection.jobRunExecution.sandbox.completed = true;
        }
        if (
          isEqual(filter.condition.status, [JobRunEventAttributeStatus.FAILED])
        ) {
          triggerSelection.jobRunExecution.sandbox.failed = true;
        }
      }
    }
    if (filter.type === EventType.MANUAL_REVIEW_CASE_CREATION) {
      if (isEqual(filter.condition.decision_env, [EventAttributeEnv.LIVE])) {
        triggerSelection.manualReviewCaseCreation.live = true;
      }
      if (isEqual(filter.condition.decision_env, [EventAttributeEnv.SANDBOX])) {
        triggerSelection.manualReviewCaseCreation.sandbox = true;
      }
    }
  });
  return { trigger: triggerSelection };
};

const connectionAuthToFormAuth = (
  settings: AuthSettings | undefined,
): ConnectionConfigAuthMethods & {
  authMethod: OutgoingWebhookConnectionAuthMethod;
} => {
  const defaultAuthConfig = {
    ...connectionConfigAuthMethodsDefaultValues(),
    authMethod: "no_auth" as const,
  };
  if (settings === undefined) return defaultAuthConfig;
  if (isOutgoingWebhookConnectionAuthMethod(settings.auth_method)) {
    switch (settings.auth_method) {
      case "no_auth":
        return {
          ...defaultAuthConfig,
          authMethod: settings.auth_method,
          noAuthConfig: {
            auth_method: settings.auth_method,
          },
        };
      case "api_key":
        return {
          ...defaultAuthConfig,
          authMethod: settings.auth_method,
          apiKeyConfig: {
            auth_method: settings.auth_method,
            header: settings.header,
            prefix: settings.prefix,
          },
        };
      case "oauth2":
        return {
          ...defaultAuthConfig,
          authMethod: settings.auth_method,
          oauth2Config: Oauth2SettingsToOauthInputs(settings),
        };
      default:
        assertUnreachable(settings.auth_method);
    }
  }
  logger.warn("Auth method not supported for outgoing webhooks.");
  return defaultAuthConfig;
};

export const outgoingWebhookToEditFormInputs = (toEdit: {
  webhook: Webhook;
  connection: ConnectionT;
}): OutgoingWebhookConnectionForm => {
  const { webhook, connection } = toEdit;

  const secrets: OutgoingWebhookConnectionSecret[] = connection.secrets.map(
    (secret) => ({ key: secret.key, value: secret.value, editable: false }),
  );

  const currentBackendSecrets = secrets.map((secret) => ({
    key: secret.key,
    value: secret.value,
  }));

  const authSettings = connection.configuration.auth_settings;
  const authFields = connectionAuthToFormAuth(authSettings);

  const formValues: OutgoingWebhookConnectionForm = {
    name: webhook.name,
    url: connection.configuration.base_url ?? "",
    secrets,
    currentBackendSecrets,
    ...authFields,
    ...eventFilterToTriggerSelection(webhook.pattern),
    active: webhook.active,
  };

  return formValues;
};
