import { noop } from "lodash";
import React, { ChangeEvent } from "react";
import { twJoin } from "tailwind-merge";

type InputPropsT = React.HTMLProps<HTMLInputElement> & {
  id?: string;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  dataLoc?: string;
};

export const Checkbox = React.forwardRef<HTMLInputElement, InputPropsT>(
  ({ id, checked, onChange, className, dataLoc, ...props }, ref) => {
    return (
      <input
        ref={ref}
        checked={checked}
        className={twJoin(
          "h-3 w-3 border border-gray-400 text-gray-0 accent-indigo-600",
          props.disabled && "cursor-not-allowed",
          className,
        )}
        data-loc={dataLoc}
        id={id}
        type="checkbox"
        onChange={props.readOnly ? noop : onChange}
        {...props}
      />
    );
  },
);
