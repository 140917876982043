import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Tabs } from "src/base-components/Tabs";

const TabLabel: React.FC<{ label: string; isErrored: boolean }> = ({
  label,
  isErrored,
}): JSX.Element => (
  <>
    {label}
    {isErrored && (
      <FontAwesomeIcon
        className="flex-shrink-0 pl-1 pr-1 text-red-400"
        icon={faWarning}
        width={12}
      />
    )}
  </>
);

type EnvironmentTabsPropsT = {
  prodContent: React.ReactNode;
  prodError: boolean;
  sandboxContent: React.ReactNode;
  sandboxError: boolean;
};

export const EnvironmentTabs: React.FC<EnvironmentTabsPropsT> = ({
  prodContent,
  prodError,
  sandboxContent,
  sandboxError,
}) => {
  return (
    <Tabs
      containerClassName="flex-1 flex flex-col mb-6"
      defaultActiveKey="production-environment-tab"
      panelClassName="h-full flex flex-col"
      panelsClassName="flex-1"
      tabClassName="px-0 mr-4"
      tabListClassName="border-b border-gray-200 mb-6 sticky -top-5 bg-white z-10"
      tabs={[
        {
          key: "production-environment-tab",
          label: (
            <TabLabel isErrored={prodError} label="Production credentials" />
          ),
          content: prodContent,
        },
        {
          key: "sandbox-environment-tab",
          label: (
            <TabLabel isErrored={sandboxError} label="Sandbox credentials" />
          ),
          content: sandboxContent,
        },
      ]}
      unmountPanels={false} // Making sure the form state is not lost when switching tabs
    />
  );
};
