export const ChangeHistoryRowSkeleton: React.FC = () => {
  const movingElement = (
    <div
      className="absolute inset-0 -translate-x-full transform bg-gradient-to-r from-gray-100 via-white to-gray-100"
      style={{ animation: "shimmer 1s infinite" }}
    ></div>
  );
  return (
    <div className="flex w-full flex-row items-center gap-x-2.5 px-6">
      <div className="relative my-3 h-6 w-6 overflow-hidden rounded-lg bg-gray-100">
        {movingElement}
      </div>
      <div className="flex h-full w-full flex-row items-center justify-between border-b border-gray-100 py-3">
        <div className="relative h-5 w-60 overflow-hidden rounded bg-gray-100">
          {movingElement}
        </div>
      </div>
    </div>
  );
};
