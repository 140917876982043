export const BOOLEAN_ERROR_MESSAGE = {
  message: "Invalid boolean value",
  description: "Must be `True` or `False`.",
};
export const STRING_ERROR_MESSAGE = {
  message: "Invalid string value",
  description: "Must be a string",
};
export const DATE_ERROR_MESSAGE = {
  message: "Invalid date value",
  description: `Must be a valid date in ISO 8601 format. For example: "2020-01-01"`,
};
export const DATETIME_ERROR_MESSAGE = {
  message: "Invalid datetime value",
  description: `Must be a valid datetime in ISO 8601 format. For example: "2020-01-01T00:00:00"`,
};
export const ARRAY_ERROR_MESSAGE = {
  message: "Invalid array value",
  description: "Must be an array",
};
export const OBJECT_ERROR_MESSAGE = {
  message: "Invalid object value",
  description: "Must be an object",
};
export const INTEGER_ERROR_MESSAGE = {
  message: "Invalid integer value",
  description: "Must be an integer",
};
export const NUMBER_ERROR_MESSAGE = {
  message: "Invalid number value",
  description: "Must be a number",
};
export const REQUIRED_ERROR_MESSAGE = {
  message: "Required",
  description: "This field is required",
};
