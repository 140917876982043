import { Header, flexRender } from "@tanstack/react-table";
import { twJoin } from "tailwind-merge";

type Props<T> = {
  header: Header<T, unknown>;
  isHighlighted?: boolean;
  resizable?: boolean;
  resizeHandler?: (event: unknown) => void;
  width?: number;
  isResizing?: boolean;
};

export const HeaderCell = <T,>({
  header,
  isHighlighted,
  resizable,
  resizeHandler,
  width,
  isResizing,
}: Props<T>) => (
  <th
    className="p-0"
    colSpan={header.colSpan}
    data-loc={`dt-group-header-${header.column.id}`}
    style={{ width }}
  >
    <div
      className={twJoin(
        "min-w-40 relative h-full border p-2 text-left font-inter-normal-12px",
        isHighlighted
          ? "border-green-100 bg-green-50"
          : "border-gray-100 bg-gray-50",
      )}
    >
      {header.isPlaceholder
        ? null
        : flexRender(header.column.columnDef.header, header.getContext())}
      {resizable && (
        <div
          className={twJoin(
            "transform-colors absolute -bottom-px -top-px right-0 w-1.5 translate-x-1/2",
            "cursor-col-resize select-none bg-indigo-500 opacity-0 duration-150 hover:opacity-100",
            isResizing && "opacity-100",
          )}
          onMouseDown={resizeHandler}
          onTouchStart={resizeHandler}
        />
      )}
    </div>
  </th>
);
