import { useEffect, useRef, useState } from "react";

export const useOpenOnHover = <U extends HTMLElement, V extends HTMLElement>(
  timeoutMillis: number = 1000,
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);

  const triggeringElement = useRef<U>(null);
  const openedElement = useRef<V>(null);

  const onMouseEnter = () => {
    clearTimeout(timeout.current);
    setIsOpen(true);
  };

  const onMouseLeave = () => {
    timeout.current = setTimeout(() => setIsOpen(false), timeoutMillis);
  };

  useEffect(() => {
    const node = triggeringElement.current;
    if (node) {
      node.addEventListener("mouseenter", onMouseEnter);
      node.addEventListener("mouseleave", onMouseLeave);
    }
    return () => {
      if (node) {
        node.removeEventListener("mouseenter", onMouseEnter);
        node.removeEventListener("mouseleave", onMouseLeave);
      }
    };
  }, [triggeringElement.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const node = openedElement.current;
    if (node) {
      node.addEventListener("mouseenter", onMouseEnter);
      node.addEventListener("mouseleave", onMouseLeave);
    }
    return () => {
      if (node) {
        node.removeEventListener("mouseenter", onMouseEnter);
        node.removeEventListener("mouseleave", onMouseLeave);
      }
    };
  }, [openedElement.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    triggeringElement,
    openedElement,
    isOpen,
    setIsOpen,
    onMouseEnter,
    onMouseLeave,
  };
};
