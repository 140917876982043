import React from "react";
import { useFormContext } from "react-hook-form";

import { FieldErrorsT } from "src/api/types";
import { CustomConnectionForm } from "src/customConnectionNode/types";
import { CodeNodeEditor } from "src/nodeEditor/CodeNodeEditor";

type PropsT = { immutable: boolean; runFieldErrors: FieldErrorsT | undefined };

export const RawBodyEditor: React.FC<PropsT> = ({
  immutable,
  runFieldErrors,
}) => {
  const formProps = useFormContext<CustomConnectionForm>();

  const bodyRawValue = formProps.getValues("body.value.raw");

  const bodyId = formProps.getValues("body.id");

  const errorMessage = runFieldErrors && runFieldErrors[bodyId];

  return (
    <div className="h-56">
      <CodeNodeEditor
        displayedError={undefined}
        immutable={immutable}
        isReactive={false}
        language={undefined}
        src={bodyRawValue}
        onChange={(content) => {
          if (content !== undefined) {
            formProps.setValue("body.value.raw", content);
          }
        }}
      />
      {errorMessage ? (
        <p className="mt-2 text-xs-sm text-red-500">{errorMessage}</p>
      ) : (
        <div className="h-7"></div>
      )}
    </div>
  );
};
