import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { groupBy, orderBy, times } from "lodash";
import React, { useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { FlowT, FlowVersionFlowChild, FlowVersionT } from "src/api/flowTypes";
import { FlowVersionStatus } from "src/clients/flow-api/api";
import {
  ArchivedVersionsTable,
  ArchivedVersionRow,
} from "src/flow/ArchivedVersionsTable";
import {
  DraftVersionsTable,
  DraftVersionRow,
  DraftVersionRowSkeleton,
} from "src/flow/DraftVersionsTable";
import { FlowPageContextNoData } from "src/flow/FlowPage";
import { FlowVersionControlModals } from "src/flow/FlowVersionControlModals";
import {
  InReviewVersionRow,
  InReviewVersionRowSkeleton,
  InReviewVersionsTable,
} from "src/flow/InReviewVersionTable";
import {
  PublishedVersionsTable,
  PublishedVersionRow,
  PublishedVersionRowSkeleton,
} from "src/flow/PublishedVersionsTable";
import { SubHeader, SubHeaderFilter } from "src/flow/SubHeader";
import {
  BuildOpenModalActionFn,
  useFlowVersionModals,
} from "src/flow/useFlowVersionModals";
import { usePublishFlowVersionCallback } from "src/flow/usePublishFlowVersionCallback";
import { useCapabilities } from "src/hooks/useCapabilities";
import { maxTableWidth } from "src/layout/constants";
import {
  FeVersionStatusFilterOptions,
  FeVersionStatusFilterValue,
  versionIsInReview,
} from "src/utils/flowVersion";

const versionUpdatedAtComparator = (version: FlowVersionT) =>
  new Date(version.updated_at).valueOf();
const versionPublishedAtComparator = (version: FlowVersionT) =>
  new Date(version.meta.published_at!).valueOf();
const versionArchivedAtComparator = (version: FlowVersionT) =>
  new Date(version.meta.archived_at!).valueOf();

const PublishedVersions: React.FC<{
  flow: FlowT | undefined;
  versions?: FlowVersionFlowChild[];
  showManageTrafficOption: boolean;
  getOpenModalAction: BuildOpenModalActionFn;
}> = ({ versions, flow, showManageTrafficOption, getOpenModalAction }) => {
  const isLoading = !flow;
  const hasLiveParentFlows = useMemo(
    () =>
      versions
        ? versions.reduce(
            (acc, version) => ({
              ...acc,
              [version.id]:
                version.parent_flows?.some((parentFlow) =>
                  parentFlow.versions?.some(
                    (version) => version.status === FlowVersionStatus.PUBLISHED,
                  ),
                ) ?? false,
            }),
            {} as Record<string, boolean>,
          )
        : {},
    [versions],
  );

  if (!isLoading && !versions) return null;

  return (
    <PublishedVersionsTable animate={!isLoading}>
      {isLoading
        ? times(3, (i) => <PublishedVersionRowSkeleton key={i} />)
        : orderBy(versions, versionPublishedAtComparator, "desc").map(
            (version) => {
              const flowTrafficPolicyPercentage =
                flow.active_traffic_policy?.policy?.flow_versions?.find(
                  (v) => v.flow_version_id === version.id,
                )?.percentage;
              const trafficAllocation = flowTrafficPolicyPercentage
                ? `${flowTrafficPolicyPercentage}%`
                : "-";
              return (
                <PublishedVersionRow
                  key={version.id}
                  isDefault={flow.default_version === version.id}
                  isDefaultSandbox={flow.default_sandbox_version === version.id}
                  showManageTrafficOption={showManageTrafficOption}
                  trafficAllocation={trafficAllocation}
                  usingTrafficPolicy={!!flow.active_traffic_policy}
                  version={version}
                  onArchive={
                    hasLiveParentFlows[version.id]
                      ? getOpenModalAction(
                          "archive_version_live_parents",
                          version,
                        )
                      : getOpenModalAction("archive_version", version)
                  }
                  onDuplicate={getOpenModalAction("add_version", version)}
                  onManageTraffic={getOpenModalAction(
                    "manage_traffic",
                    version,
                  )}
                  onSetDefaultSandbox={getOpenModalAction(
                    "default_sandbox_version",
                    version,
                  )}
                />
              );
            },
          )}
    </PublishedVersionsTable>
  );
};

const InReviewVersions: React.FC<{
  flow: FlowT | undefined;
  versions?: FlowVersionFlowChild[];
  getOpenModalAction: BuildOpenModalActionFn;
}> = ({ versions, flow, getOpenModalAction }) => {
  const { flowVersions } = useCapabilities();
  const handlePublish = usePublishFlowVersionCallback({
    flowId: flow?.id,
    buildOpenModalAction: getOpenModalAction,
  });

  const isLoading = !flow;
  if (!isLoading && (!versions || versions?.length === 0)) return null;

  return (
    <InReviewVersionsTable animate={!isLoading}>
      {isLoading
        ? times(3, (i) => <InReviewVersionRowSkeleton key={i} />)
        : orderBy(versions, versionUpdatedAtComparator, "desc").map(
            (version) => (
              <InReviewVersionRow
                key={version.id}
                canDelete={
                  flow.versions.length > 1 && flowVersions.canDeleteDraft
                }
                isDefaultSandbox={flow.default_sandbox_version === version.id}
                version={version}
                onDelete={getOpenModalAction("delete_version", version)}
                onDuplicate={getOpenModalAction("add_version", version)}
                onEdit={getOpenModalAction("edit_version", version)}
                onPublish={() => handlePublish(version)}
                onSetDefaultSandbox={getOpenModalAction(
                  "default_sandbox_version",
                  version,
                )}
              />
            ),
          )}
    </InReviewVersionsTable>
  );
};

const DraftVersions: React.FC<{
  flow: FlowT | undefined;
  versions?: FlowVersionFlowChild[];
  getOpenModalAction: BuildOpenModalActionFn;
}> = ({ versions, flow, getOpenModalAction }) => {
  const { flowVersions } = useCapabilities();
  const handlePublish = usePublishFlowVersionCallback({
    flowId: flow?.id,
    buildOpenModalAction: getOpenModalAction,
  });

  const isLoading = !flow;

  if (!isLoading && (!versions || versions?.length === 0)) return null;

  return (
    <DraftVersionsTable animate={!isLoading}>
      {isLoading
        ? times(3, (i) => <DraftVersionRowSkeleton key={i} />)
        : orderBy(versions, versionUpdatedAtComparator, "desc").map(
            (version) => (
              <DraftVersionRow
                key={version.id}
                canDelete={
                  flow.versions.length > 1 && flowVersions.canDeleteDraft
                }
                isDefaultSandbox={flow.default_sandbox_version === version.id}
                version={version}
                onDelete={getOpenModalAction("delete_version", version)}
                onDuplicate={getOpenModalAction("add_version", version)}
                onEdit={getOpenModalAction("edit_version", version)}
                onPublish={() => handlePublish(version)}
                onSetDefaultSandbox={getOpenModalAction(
                  "default_sandbox_version",
                  version,
                )}
              />
            ),
          )}
    </DraftVersionsTable>
  );
};

const ArchivedVersions: React.FC<{
  versions?: FlowVersionFlowChild[];
  flow: FlowT;
  getOpenModalAction: BuildOpenModalActionFn;
}> = ({ flow, versions, getOpenModalAction }) => {
  const hasUnpublishedChildFlows = useMemo(
    () =>
      versions
        ? versions.reduce(
            (acc, version) => ({
              ...acc,
              [version.id]:
                version.child_flows?.some((childFlow) =>
                  childFlow.versions?.some(
                    (version) => version.status !== FlowVersionStatus.PUBLISHED,
                  ),
                ) ?? false,
            }),
            {} as Record<string, boolean>,
          )
        : {},
    [versions],
  );

  return (
    <ArchivedVersionsTable>
      {orderBy(versions, versionArchivedAtComparator, "desc").map((version) => (
        <ArchivedVersionRow
          key={version.id}
          flow={flow}
          version={version}
          onDuplicate={getOpenModalAction("add_version", version)}
          onRepublish={
            hasUnpublishedChildFlows[version.id]
              ? getOpenModalAction("republish_unpublished_child_flows", version)
              : getOpenModalAction("republish_version", version)
          }
        />
      ))}
    </ArchivedVersionsTable>
  );
};

const filterOptions = [
  FeVersionStatusFilterOptions.published,
  FeVersionStatusFilterOptions.inReview,
  FeVersionStatusFilterOptions.drafts,
];

const filterOptionsWithArchived = [
  ...filterOptions,
  FeVersionStatusFilterOptions.archived,
];

export const FlowVersionsContent: React.FC = () => {
  const { flow } = useOutletContext<FlowPageContextNoData>();
  const [filter, setFilter] = useState<FeVersionStatusFilterValue | null>(null);

  const { flowVersions } = useCapabilities();

  const {
    PUBLISHED,
    DRAFT: allDrafts,
    ARCHIVED,
  } = groupBy(flow?.versions, "status");

  const drafts = allDrafts?.filter((v) => !versionIsInReview(v));
  const inReviews = allDrafts?.filter((v) => versionIsInReview(v));

  const isTableVisible = (table: FeVersionStatusFilterValue) =>
    filter === null || filter === table;

  const {
    currentModal,
    selectedVersion,
    buildOpenModalAction,
    onClose,
    onAfterLeave,
    onOpenManageTrafficModal,
  } = useFlowVersionModals();

  const atLeastTwoVersionsPublished = flow
    ? flow.versions.filter((v) => v.status === FlowVersionStatus.PUBLISHED)
        .length >= 2
    : false;

  return (
    <>
      <SubHeader title="Versions" paddedParent>
        <SubHeaderFilter
          dataLoc="filter-versions"
          options={(ARCHIVED?.length > 0
            ? filterOptionsWithArchived
            : filterOptions
          ).map((option) => ({
            key: option.value,
            value: option.label,
          }))}
          selected={filter}
          onChange={setFilter}
        />
        {flowVersions.canCreate && (
          <SubHeader.Button
            dataLoc="create-version-button"
            icon={faPlus}
            tooltip="Create version"
            onClick={buildOpenModalAction("add_version")}
          />
        )}
      </SubHeader>
      <div
        className={twJoin(
          "mx-auto flex w-full flex-col gap-y-5.5",
          maxTableWidth,
        )}
      >
        {isTableVisible(FlowVersionStatus.PUBLISHED) && (
          <PublishedVersions
            flow={flow}
            getOpenModalAction={buildOpenModalAction}
            showManageTrafficOption={
              flowVersions.canSetDefault && atLeastTwoVersionsPublished
            }
            versions={PUBLISHED}
          />
        )}
        {isTableVisible("InReview") && (
          <InReviewVersions
            flow={flow}
            getOpenModalAction={buildOpenModalAction}
            versions={inReviews}
          />
        )}
        {isTableVisible("DraftsNoReview") && (
          <DraftVersions
            flow={flow}
            getOpenModalAction={buildOpenModalAction}
            versions={drafts}
          />
        )}
        {flow && filter === FlowVersionStatus.ARCHIVED && (
          <ArchivedVersions
            flow={flow}
            getOpenModalAction={buildOpenModalAction}
            versions={ARCHIVED}
          />
        )}
        {flow && (
          <FlowVersionControlModals
            afterLeave={onAfterLeave}
            flow={flow}
            modal={currentModal}
            openManageTrafficModal={onOpenManageTrafficModal}
            setFilter={setFilter}
            version={selectedVersion}
            onClose={onClose}
          />
        )}
      </div>
    </>
  );
};
