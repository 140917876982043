import {
  FieldError,
  FieldErrors,
  FieldErrorsImpl,
  FieldValues,
  Merge,
} from "react-hook-form";

export const isFieldErrored = <T extends FieldValues>(
  errors: Merge<FieldError, FieldErrorsImpl<T>> | FieldErrors<T> | undefined,
  field: string,
): boolean => {
  return errors !== undefined && errors[field] !== undefined;
};
