import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";

type HistorySelectedDecisionStore = {
  decision: Nullable<DecisionHistoryRecordV2>;
};

type HistorySelectedDecisionActions = {
  setDecision: (decision: Nullable<DecisionHistoryRecordV2>) => void;
};

const useHistorySelectedDecisionStore = create(
  immer<
    ZustandStore<HistorySelectedDecisionStore, HistorySelectedDecisionActions>
  >((set) => ({
    decision: null,

    actions: {
      setDecision: (decision) => set({ decision }),
    },
  })),
);

export const useHistorySelectedDecision = () =>
  useHistorySelectedDecisionStore((state) => state.decision);
export const useSetHistorySelectedDecision = () =>
  useHistorySelectedDecisionStore((state) => state.actions.setDecision);
