import {
  PostgreSQLConnectionConfigurationBE,
  ConnectionConfiguration,
  ConnectionT,
  ConnectionCreateT,
  ConnectionConfigurationCreateT,
} from "src/api/connectApi/types";
import {
  findSecret,
  getDefaultSecret,
  setSecretDeleted,
  setSecretEnv,
  filterUpdatedSecrets,
  markSecrets,
  filterSecretsByEnvironment,
} from "src/connections/model/common";
import {
  PostgreSQLConnectionConfigT,
  PostgreSQLConnectionConfigInputsT,
  KeyValuePairT,
} from "src/connections/types";

export const getPostgreSQLConnectionConfigDefaultValues = (
  env: string | null,
): PostgreSQLConnectionConfigT => ({
  databaseName: "",
  host: "",
  port: null,
  allowTestInvocations: false,
  authMethod: "username_password",
  hasMTLSEnabled: false,
  mTLSConfig: {
    mode: "verify-full",
    sslCertificate: getDefaultSecret("ssl_certificate", env),
    sslKey: getDefaultSecret("ssl_key", env),
    sslCACertificate: getDefaultSecret("ssl_ca_certificate", env),
  },
  usernamePasswordConfig: {
    username: getDefaultSecret("username", env),
    password: getDefaultSecret("password", env),
  },
});

export const getPostgreSQLConnectionConfigInputsDefaultValues =
  (): PostgreSQLConnectionConfigInputsT => ({
    name: "",
    dataRetention: {
      value: 0,
      unit: "days",
    },
    enableNonProdConfigs: false,
    productionConfig: getPostgreSQLConnectionConfigDefaultValues(null),
    sandboxConfig: getPostgreSQLConnectionConfigDefaultValues("sandbox"),
  });

const convertBEConnectionConfigurationToPostgreSQLConnectionConfig = (
  config: ConnectionConfiguration,
  envSecrets: KeyValuePairT[],
  env: string | null,
): PostgreSQLConnectionConfigT => {
  const postgresConfig = config as PostgreSQLConnectionConfigurationBE;
  return {
    databaseName: postgresConfig.database_name,
    host: postgresConfig.host,
    port: postgresConfig.port,
    allowTestInvocations: config.allow_test_invocations ?? false,
    hasMTLSEnabled: postgresConfig.has_mtls_enabled ?? false,
    mTLSConfig: {
      mode: postgresConfig.mtls_mode ?? "verify-full",
      sslCertificate:
        findSecret(envSecrets, "ssl_certificate") ??
        getDefaultSecret("ssl_certificate", env),
      sslKey:
        findSecret(envSecrets, "ssl_key") ?? getDefaultSecret("ssl_key", env),
      sslCACertificate:
        findSecret(envSecrets, "ssl_ca_certificate") ??
        getDefaultSecret("ssl_ca_certificate", env),
    },
    authMethod: postgresConfig.auth_method,
    usernamePasswordConfig: {
      username:
        findSecret(envSecrets, "username") ?? getDefaultSecret("username", env),
      password:
        findSecret(envSecrets, "password") ?? getDefaultSecret("password", env),
    },
  };
};

const convertBENonProdEnvConfigToPostgreSQLConnectionConfigs = (
  nonProdEnvConfigs: Record<string, ConnectionConfiguration>,
  env: string,
  allSecrets: KeyValuePairT[],
): PostgreSQLConnectionConfigT | null => {
  if (!nonProdEnvConfigs?.[env]) return null;
  const envSecrets = filterSecretsByEnvironment(allSecrets, env);
  return convertBEConnectionConfigurationToPostgreSQLConnectionConfig(
    nonProdEnvConfigs[env],
    envSecrets,
    env,
  );
};

/**
 * Converts BE PostgreSQL connection configuration to FE format.
 * If the provider of the connection is not "postgresql", it throws an error.
 *
 * @param {ConnectionT} connection - The PostgreSQL connection object in BE format.
 * @returns {PostgreSQLConnectionConfigInputsT} - The converted PostgreSQL connection object in FE format suitable to use with components.
 * @throws {Error} - Throws an error if the provider of the connection is not "postgresql".
 */
export const convertBEConnectionToPostgreSQLConnectionConfigInputs = (
  connection: ConnectionT,
): PostgreSQLConnectionConfigInputsT => {
  if (connection.provider !== "postgresql")
    throw new Error(
      `Invalid connection type: ${connection.provider} for PostgreSQL converter`,
    );

  const defaultValues = getPostgreSQLConnectionConfigInputsDefaultValues();
  const nonProdEnvConfigs = connection.non_prod_env_configs;

  const allSecrets = markSecrets(connection.secrets) ?? [];
  // TODO: convert "null" to "production" value
  const prodSecrets = filterSecretsByEnvironment(allSecrets, null);
  return {
    ...defaultValues,
    name: connection.name,
    dataRetention: connection.data_retention ?? defaultValues.dataRetention,
    enableNonProdConfigs: connection.enable_non_prod_configs,
    productionConfig:
      convertBEConnectionConfigurationToPostgreSQLConnectionConfig(
        connection.configuration,
        prodSecrets,
        null,
      ),
    sandboxConfig:
      nonProdEnvConfigs && nonProdEnvConfigs["sandbox"]
        ? convertBENonProdEnvConfigToPostgreSQLConnectionConfigs(
            nonProdEnvConfigs,
            "sandbox",
            allSecrets,
          )
        : getPostgreSQLConnectionConfigDefaultValues("sandbox"),
  };
};

const extractSecretsFromPostgreSQLConnectionConfig = (
  config: PostgreSQLConnectionConfigT,
  env: string | null,
): KeyValuePairT[] => {
  if (config.authMethod === "username_password") {
    return [
      setSecretEnv(config.usernamePasswordConfig.username, env),
      setSecretEnv(config.usernamePasswordConfig.password, env),
    ];
  }
  return [];
};

const extractSecretsFromPostgreSQLConnectionMTLSConfig = (
  config: PostgreSQLConnectionConfigT,
  env: string | null,
): KeyValuePairT[] => {
  if (config.hasMTLSEnabled) {
    const mTLSSecrets = [
      setSecretEnv(config.mTLSConfig.sslCertificate, env),
      setSecretEnv(config.mTLSConfig.sslKey, env),
    ];
    // Only send CA certificate if it is set
    if (config.mTLSConfig.sslCACertificate.value !== "") {
      mTLSSecrets.push(setSecretEnv(config.mTLSConfig.sslCACertificate, env));
    }
    return mTLSSecrets;
  } else {
    // If mTLS is disabled, we need to delete the secrets that are present
    return [
      config.mTLSConfig.sslCertificate,
      config.mTLSConfig.sslKey,
      config.mTLSConfig.sslCACertificate,
    ]
      .filter((secret) => secret.value !== "")
      .map((s) => setSecretEnv(s, env))
      .map(setSecretDeleted);
  }
};

const extractSecretsFromPostgreSQLConnectionConfigInputs = (
  config: PostgreSQLConnectionConfigInputsT,
): KeyValuePairT[] => {
  const secrets = extractSecretsFromPostgreSQLConnectionConfig(
    config.productionConfig,
    null,
  );
  secrets.push(
    ...extractSecretsFromPostgreSQLConnectionMTLSConfig(
      config.productionConfig,
      null,
    ),
  );

  // Only include non-prod secrets if non-prod configs are enabled
  if (config.enableNonProdConfigs && config.sandboxConfig) {
    secrets.push(
      ...extractSecretsFromPostgreSQLConnectionConfig(
        config.sandboxConfig,
        "sandbox",
      ),
    );
    secrets.push(
      ...extractSecretsFromPostgreSQLConnectionMTLSConfig(
        config.sandboxConfig,
        "sandbox",
      ),
    );
  }
  // Removing un-changed secrets, so that they are not sent to the backend
  return filterUpdatedSecrets(secrets);
};

const convertPostgreSQLConnectionConfigToBEConnectionConfiguration = (
  config: PostgreSQLConnectionConfigT,
): ConnectionConfigurationCreateT => {
  return {
    database_name: config.databaseName,
    host: config.host,
    port: config.port || null, // Replace "" with null when port is deleted
    auth_method: config.authMethod,
    allow_test_invocations: config.allowTestInvocations,
    has_mtls_enabled: config.hasMTLSEnabled,
    mtls_mode: config.mTLSConfig.mode,
  };
};

/**
 * Converts FE PostgreSQL connection object to BE format.
 *
 * @param {PostgreSQLConnectionConfigInputsT} inputs - The PostgreSQL connection object in FE format.
 * @returns {ConnectionCreateT} - The PostgreSQL connection object in BE format suitable for sending POST request.
 */
export const convertPostgreSQLConnectionConfigInputsToBEConnection = (
  inputs: PostgreSQLConnectionConfigInputsT,
): ConnectionCreateT => {
  const secrets = extractSecretsFromPostgreSQLConnectionConfigInputs(inputs);

  return {
    name: inputs.name,
    is_sandbox: false,
    provider: "postgresql",
    configuration: convertPostgreSQLConnectionConfigToBEConnectionConfiguration(
      inputs.productionConfig,
    ),
    secrets,
    data_retention: inputs.dataRetention,
    enable_non_prod_configs: inputs.enableNonProdConfigs,
    non_prod_env_configs:
      inputs.enableNonProdConfigs && inputs.sandboxConfig
        ? {
            sandbox:
              convertPostgreSQLConnectionConfigToBEConnectionConfiguration(
                inputs.sandboxConfig,
              ),
          }
        : {},
  };
};
