import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";

type Props = {
  immutable: boolean;
  listeners: SyntheticListenerMap | undefined;
  className?: string;
  color?: string;
  size?: "xs" | "2xs";
};

export const ReorderHandle: React.FC<Props> = ({
  immutable,
  listeners,
  className,
  color = "text-gray-500",
  size = "xs",
}) => (
  <button
    {...listeners}
    className={twJoin(
      immutable && "cursor-not-allowed",
      !immutable && "cursor-grab",
      className,
    )}
    type="button"
  >
    <Icon color={color} icon={faGripDotsVertical} size={size} />
  </button>
);
