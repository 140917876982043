import { DataRetentionUnit } from "src/clients/flow-api";
import { DEFAULT_AWS_REGION } from "src/constants/AwsRegions";

export const FORM_DEFAULT_VALUES = {
  name: "",
  slug: "",
  region: DEFAULT_AWS_REGION,
  settings: {
    data_retention: {
      value: 5,
      unit: DataRetentionUnit.YEARS,
    },
  },
};
export const MOCK_DEFAULT_WS_ID = "c60396a8-a47a-4521-a4a2-60b321278db8";
export const MOCK_ORG_ID = "11111111-1111-1111-1111-111111111111";
export const MOCK_DEFAULT_RESOURCE_ID = "e4af8620-146d-4a96-83ba-b7b5583da52c";
export const MOCK_DEFAULT_US_WS_ID = "c60396a8-a47a-4521-a4a2-60b321278db9";
