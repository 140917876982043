import React, { useRef } from "react";

import { Pill } from "src/base-components/Pill";
import { TextEditor } from "src/base-components/TextEditor/TextEditor";
import {
  CommentDb,
  ReviewCommentStatus,
  ReviewStatus,
} from "src/clients/flow-api";
import { CommentActions } from "src/comments/CommentActions";
import { CommentEditor } from "src/comments/CommentEditor";
import { CommentReplies } from "src/comments/CommentReplies";
import { useCommentActions } from "src/comments/useCommentActions";
import { UserNameInfo } from "src/flowContainer/versionEditedInfo/UsernameInfo";
import { ShortDateFromNow } from "src/flowReview/ReviewLogEntry";
import { useFlowVersionReview } from "src/flowReview/api/queries";
import { useOnScreen } from "src/hooks/useOnScreen";
import { AuthorPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type BaseProps = {
  comment: CommentDb;
  isReply?: boolean;
  hideHeader?: boolean;
  onDelete: (comment: CommentDb) => Promise<boolean | void>;
  actions: ReturnType<typeof useCommentActions>;
};

type ActionsIncludedProps = Omit<BaseProps, "actions">;

export const CommentCardWithActions: React.FC<ActionsIncludedProps> = (
  props,
) => {
  const commentActions = useCommentActions(props.comment, props.onDelete);
  return <CommentCard {...props} actions={commentActions} />;
};

export const CommentCard: React.FC<BaseProps> = ({
  comment,
  isReply,
  hideHeader,
  actions,
  onDelete,
}) => {
  const { version_id } = useParamsDecode<AuthorPageParamsT>();
  const ref = useRef<HTMLDivElement | null>(null);

  const isVisible = useOnScreen(ref);
  const { data: review } = useFlowVersionReview(version_id);

  return (
    <div
      ref={ref}
      className="flex flex-col gap-y-1.5"
      data-loc={`comment${isReply ? "-reply" : ""}-card-${comment.id}`}
    >
      {!hideHeader && !actions.isEditing && (
        <div className="flex items-center gap-x-1">
          <div className="flex items-center gap-x-1.5 text-gray-600 font-inter-medium-12px">
            <UserNameInfo userId={comment.author} withAvatar />
            {comment.review_id &&
              !isReply &&
              (review?.review_status !== ReviewStatus.CANCELLED ? (
                <Pill size="sm" variant="indigo-light">
                  Review
                </Pill>
              ) : (
                <Pill size="sm" variant="gray">
                  Cancelled
                </Pill>
              ))}
          </div>
          <span className="flex-grow" />
          <p className="text-gray-600 font-inter-medium-12px">
            <ShortDateFromNow date={comment.created_at} />
          </p>
          <CommentActions
            comment={comment}
            {...actions}
            onResolve={isReply ? undefined : actions.onResolve}
          />
        </div>
      )}
      {actions.isEditing ? (
        <CommentEditor
          comment={comment}
          dataLoc="update-comment-editor"
          autoFocus
          onCancel={actions.onCancelEdit}
          onSave={actions.onSave}
        />
      ) : (
        comment.reviewer_status !== ReviewCommentStatus.REVIEW_REREQUESTED &&
        comment.content && (
          <TextEditor
            dataLoc="comment-content"
            value={comment.content}
            readOnly
          />
        )
      )}
      {!actions.isEditing &&
        !isReply &&
        comment.reviewer_status !== ReviewCommentStatus.REVIEW_REREQUESTED && (
          <CommentReplies
            comment={comment}
            isVisible={isVisible}
            review={review || undefined}
            onDelete={onDelete}
          />
        )}
    </div>
  );
};
