import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { produce } from "immer";
import { noop } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";
import { twJoin } from "tailwind-merge";

import { Button } from "src/base-components/Button";
import { EditorTable } from "src/base-components/EditorTable/EditorTable";
import { EditorTableStoreProvider } from "src/base-components/EditorTable/StoreProvider";
import { HEADER_ROW_ID } from "src/base-components/EditorTable/hooks/useSelectionHandler";
import { CellIndex } from "src/base-components/EditorTable/types";
import {
  DecisionTableNodeDataT,
  DecisionTableNode,
} from "src/constants/NodeDataTypes";
import { RowContextMenu } from "src/decisionTableNode/RowContextMenu";
import { DecisionTableActions } from "src/decisionTableNode/actions";
import { useDecisionTableActionsReducer } from "src/decisionTableNode/useDecisionTableActionsReducer";
import {
  RowShape,
  transformToTableData,
  transformToTableGroups,
  transformToFallbackData,
  applyFallbackChange,
  applyHeaderChange,
  buildNonEditableFallbackCellList,
  mapErrorsToCoordinates,
  applyTableChange,
  buildTableGroupsKey,
  getFormValues,
} from "src/decisionTableNode/utils";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import { convertFieldErrorsBeToFe } from "src/utils/FieldErrorUtils";

export type DecisionTableForm = Pick<
  DecisionTableNodeDataT,
  "predicate_fields" | "effect_fields" | "cases" | "fallback"
>;

type PropsT = {
  selectedNode: DecisionTableNode;
  isFullscreen: boolean;
} & NodeEditorBaseProps<DecisionTableNodeDataT>;

const readOnlyCells = [
  { row: HEADER_ROW_ID, col: "caseName" },
  { row: HEADER_ROW_ID, col: "caseIndex" },
] as CellIndex<RowShape>[];

export const DecisionTableNodeEditor: React.FC<PropsT> = ({
  onUpdate,
  selectedNode,
  immutable,
  displayedError,
  isReactive,
  isFullscreen,
}) => {
  const [, startTransition] = useTransition();

  // Using a simple useState instead of a react hook form because it fulfills our needs and does not require us to
  // refactor the complex table handling yet. We could consider switching to a hook form in the future.
  const [formState, setFormState] = useState<DecisionTableForm>(
    getFormValues(selectedNode.data),
  );
  const formStateRef = useRef(formState);
  formStateRef.current = formState;

  const [columnSizes, setColumnSizes] = useState<Record<string, number> | null>(
    null,
  );

  useEffect(() => {
    if (isReactive) {
      setFormState(getFormValues(selectedNode.data));
    }
  }, [isReactive, selectedNode.data]);

  useEffect(() => {
    if (!immutable && !isReactive) {
      onUpdate({ newData: formState });
    }
  }, [formState, immutable, isReactive, onUpdate]);

  const dispatch = useDecisionTableActionsReducer(formState, setFormState);
  const tableGroupsKey = buildTableGroupsKey(formState);
  const columnGroups = useMemo(
    () => transformToTableGroups(formState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableGroupsKey],
  );
  const fallbackColumnGroups = useMemo(
    () => transformToTableGroups(formState, true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableGroupsKey],
  );

  const [tableDataState, setTableDataState] = useState(() =>
    transformToTableData(formState),
  );
  const tableDataStateRef = useRef(tableDataState);
  tableDataStateRef.current = tableDataState;

  useEffect(() => {
    startTransition(() => {
      setTableDataState(transformToTableData(formState));
    });
  }, [formState]);

  const fallbackData = transformToFallbackData(formState);
  const nonEditableFallbackCells = buildNonEditableFallbackCellList(formState);

  const handleAddCase = () =>
    dispatch({ type: DecisionTableActions.ADD_DEFAULT_CASE });
  const handleAddCondition = () =>
    dispatch({ type: DecisionTableActions.ADD_DEFAULT_CONDITION });
  const handleAddResult = () =>
    dispatch({ type: DecisionTableActions.ADD_DEFAULT_RESULT });

  const handleTableChange = useCallback(
    (rowIndex: number, row: RowShape) => {
      setTableDataState(
        produce(tableDataStateRef.current, (draft) => {
          draft[rowIndex] = row;
        }),
      );

      const updatedData = applyTableChange(formStateRef.current, rowIndex, row);
      dispatch({ type: "reset", payload: updatedData });
    },
    [dispatch],
  );

  const handleTableHeaderChange = useCallback(
    (key: keyof RowShape, value: string) => {
      const updatedData = applyHeaderChange(formState, key, value);
      dispatch({ type: "reset", payload: updatedData });
    },
    [dispatch, formState],
  );

  const handleFallbackChange = useCallback(
    (_rowIndex: number, row: RowShape) => {
      const updatedData = applyFallbackChange(formState, row);
      dispatch({ type: "reset", payload: updatedData });
    },
    [formState, dispatch],
  );

  const handleReorder = useCallback(
    (oldIndex: number, newIndex: number) => {
      dispatch({
        type: DecisionTableActions.MOVE_CASE,
        payload: { oldIndex, newIndex },
      });
    },
    [dispatch],
  );

  const [errors, fallbackErrors] = useMemo(() => {
    return mapErrorsToCoordinates(
      displayedError?.field_errors
        ? convertFieldErrorsBeToFe(displayedError?.field_errors)
        : undefined,
      formState,
    );
    // Don't update when the node data changes so that the errored fields can be cleared while editing the table
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedError?.field_errors]);

  return (
    <div className="flex h-full flex-col gap-y-4">
      <div
        className={twJoin(
          "decideScrollbar -ml-3 mt-2 flex flex-col gap-y-4 overflow-auto pb-2 pl-3 pr-2",
          isFullscreen && "h-[calc(80vh-292px)]",
        )}
      >
        <EditorTableStoreProvider>
          <EditorTable
            columnGroups={columnGroups}
            data={tableDataState}
            dataLoc="decision-table"
            dispatch={dispatch}
            errors={errors}
            readonly={immutable}
            readonlyCells={readOnlyCells}
            rowSortHandle={RowContextMenu}
            enableCodeInput
            onChange={handleTableChange}
            onColumnSizingChange={(sizes) => setColumnSizes(sizes)}
            onHeaderChange={handleTableHeaderChange}
            onReorder={handleReorder}
          />
        </EditorTableStoreProvider>
        <EditorTableStoreProvider>
          <EditorTable
            columnGroups={fallbackColumnGroups}
            columnSizing={columnSizes ?? undefined}
            data={fallbackData}
            dataLoc="fallback-table"
            errors={fallbackErrors}
            readonly={immutable}
            readonlyCells={nonEditableFallbackCells}
            enableCodeInput
            hideAllHeaders
            onChange={handleFallbackChange}
            onHeaderChange={noop}
          />
        </EditorTableStoreProvider>
      </div>
      <div className="flex items-center gap-x-2">
        <Button
          dataLoc="dt-add-case"
          disabled={immutable}
          iconLeft={faPlus}
          size="sm"
          variant="secondary"
          onClick={handleAddCase}
        >
          Add case
        </Button>
        <div className="h-5 w-px bg-gray-100"></div>
        <Button
          dataLoc="dt-add-condition"
          disabled={immutable}
          iconLeft={faPlus}
          size="sm"
          variant="secondary"
          onClick={handleAddCondition}
        >
          Add condition
        </Button>
        <Button
          dataLoc="dt-add-result"
          disabled={immutable}
          iconLeft={faPlus}
          size="sm"
          variant="secondary"
          onClick={handleAddResult}
        >
          Add result
        </Button>
      </div>
    </div>
  );
};
