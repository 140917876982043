import { Pill } from "src/base-components/Pill";
import { FeVersionStatusFilterOptions } from "src/utils/flowVersion";

type FilterPillProps = {
  text: string;
  isActive: boolean;
  onClick: () => void;
};

const FilterPill: React.FC<FilterPillProps> = ({ isActive, text, onClick }) => (
  <Pill
    variant={isActive ? "indigo" : "outlined-white"}
    onClick={isActive ? undefined : onClick}
  >
    <Pill.Text>{text}</Pill.Text>
  </Pill>
);

const { published, inReview, drafts } = FeVersionStatusFilterOptions;
const filterOptions = [
  { label: "All", value: "all" },
  published,
  inReview,
  drafts,
] as const;
export type VersionStatusFilterValue = (typeof filterOptions)[number]["value"];
type Props = {
  value: VersionStatusFilterValue;
  onChange: (value: VersionStatusFilterValue) => void;
};

export const FlowVersionFilter: React.FC<Props> = ({ value, onChange }) => (
  <div className="flex gap-x-2">
    {filterOptions.map((option) => (
      <FilterPill
        key={option.value}
        isActive={value === option.value}
        text={option.label}
        onClick={() => onChange(option.value)}
      />
    ))}
  </div>
);
