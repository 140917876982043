import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { ConnectionT } from "src/api/connectApi/types";
import { Divider } from "src/base-components/Divider";
import { Switch } from "src/base-components/Switch";
import { EnvironmentDataSourceConfig } from "src/baseConnectionNode/EnvironmentDataSourceConfig";
import { TimeoutInput } from "src/baseConnectionNode/TimeoutInput";
import { DatabaseConnectionForm } from "src/databaseConnectionNode/types";

type ConfigPanePropsT = {
  immutable: boolean;
  connection: ConnectionT;
};

export const ConfigPane: React.FC<ConfigPanePropsT> = ({
  immutable,
  connection,
}) => {
  const formProps = useFormContext<DatabaseConnectionForm>();

  const isTimeoutActive = formProps.getValues("config.timeout.active");

  return (
    <>
      <EnvironmentDataSourceConfig
        connection={connection}
        disabled={immutable}
      />
      <Divider spacing="my-4" />
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-gray-800 font-inter-medium-12px">
            Use custom request timeout
          </h3>
          <span className="text-gray-500 font-inter-normal-12px">
            Enter the maximum number of seconds before timing out the request
          </span>
        </div>
        <Controller
          control={formProps.control}
          name="config.timeout.active"
          render={(props) => (
            <Switch
              dataLoc="dbc-node-config.timeout.active"
              disabled={immutable}
              enabled={props.field.value}
              onChange={() => {
                props.field.onChange(!props.field.value);
              }}
            />
          )}
        />
      </div>
      {isTimeoutActive && (
        <div className="flex w-full flex-row items-center justify-between rounded-lg bg-gray-50 px-5 py-4">
          <div className="mr-2 flex text-gray-700 font-inter-normal-12px">
            Time out after (seconds)
          </div>
          <TimeoutInput
            dataLoc="dbc-node-config.timeout.timeout_seconds"
            formProps={formProps.register("config.timeout.timeout_seconds")}
            immutable={immutable}
          />
        </div>
      )}
      <div className="mt-4 flex items-center justify-between">
        <div>
          <h3 className="text-gray-800 font-inter-medium-12px">
            Do not error with client errors
          </h3>
          <span className="text-gray-500 font-inter-normal-12px">
            In case of client errors, the flow will continue executing
          </span>
        </div>
        <Controller
          control={formProps.control}
          name="config.error.allow_client_errors"
          render={(props) => (
            <Switch
              dataLoc="dbc-node-config.error.allow_client_errors"
              disabled={immutable}
              enabled={props.field.value}
              onChange={() => {
                props.field.onChange(!props.field.value);
              }}
            />
          )}
        />
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div>
          <h3 className="text-gray-800 font-inter-medium-12px">
            Do not error with server errors
          </h3>
          <span className="text-gray-500 font-inter-normal-12px">
            In case of server errors, the flow will continue executing
          </span>
        </div>
        <Controller
          control={formProps.control}
          name="config.error.allow_server_errors"
          render={(props) => (
            <Switch
              dataLoc="dbc-node-config.error.allow_server_errors"
              disabled={immutable}
              enabled={props.field.value}
              onChange={() => {
                props.field.onChange(!props.field.value);
              }}
            />
          )}
        />
      </div>
    </>
  );
};
