import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { useFormContext } from "react-hook-form";

import { FormItem } from "src/base-components/FormItem";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Tooltip } from "src/base-components/Tooltip";
import { errorProps } from "src/connections/config/shared/errorProps";
import {
  RedshiftConnectionConfigInputsT,
  RedshiftConnectionAuthConfigFieldT,
  Environment,
} from "src/connections/types";

type SecretPropsT = {
  secretConfigField: RedshiftConnectionAuthConfigFieldT;
  secretName: string;
  helpText: string;
  isRequired?: boolean;
  isErrored: boolean;
  environment: Environment;
};

export const RedshiftSecretField: React.FC<SecretPropsT> = ({
  secretConfigField,
  secretName,
  helpText,
  isRequired,
  isErrored,
  environment,
}) => {
  const { watch, setValue, register } =
    useFormContext<RedshiftConnectionConfigInputsT>();

  const secretKey = `${environment}Config.${secretConfigField}` as const;

  const secretRecord = watch(secretKey);

  const enableSecret = () => {
    setValue(secretKey, { ...secretRecord, secret: false, value: null });
  };

  return (
    <FormItem
      gap="xs"
      helpClassName="mt-1"
      helpTooltip={helpText}
      isRequired={isRequired}
      label={secretName}
    >
      <div className="flex w-full items-start gap-x-2">
        <div className="flex-1">
          <Input
            disabled={secretRecord.secret}
            {...errorProps(isErrored)}
            {...register(`${secretKey}.value`, {
              required: isRequired ? `${secretName} is required` : false,
            })}
            placeholder={secretName}
            type="password"
            fullWidth
          />
        </div>
        {secretRecord.secret && (
          <Tooltip
            align="center"
            placement="right"
            title={`Edit ${secretName.toLowerCase()}`}
            asChild
          >
            <Icon
              color="text-gray-500"
              icon={faEdit}
              size="xs"
              onClick={enableSecret}
            />
          </Tooltip>
        )}
      </div>
    </FormItem>
  );
};
