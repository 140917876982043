import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState, useCallback } from "react";

import {
  organizationSimpleRolesApi,
  organizationsApi,
} from "src/api/taktileApi";
import {
  SimpleRoleType,
  WorkspaceSimpleRoleType,
} from "src/clients/taktile-api";
import { isWorkspaceRoleAtLeast } from "src/hooks/useCapabilities";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { DashboardPageParamsT } from "src/router/urls";
import { useCurrentUserId } from "src/store/AuthStore";
import { useParamsDecode } from "src/utils/useParamsDecode";

type SimpleRoleProviderT = {
  role: SimpleRoleType | null;
  workspaceRole: WorkspaceSimpleRoleType | null;
  isLoading: boolean;
  hasError: boolean;
};

const initialState: SimpleRoleProviderT = {
  role: null,
  workspaceRole: null,
  isLoading: false,
  hasError: false,
};
const SimpleRoleContext =
  React.createContext<SimpleRoleProviderT>(initialState);

export const useSimpleRole = () =>
  React.useContext<SimpleRoleProviderT>(SimpleRoleContext);

const buildRoleKey = (orgId: string, userId: string) => `${userId}-${orgId}`;
const buildWorkspaceRoleKey = (
  orgId: string,
  workspaceId: string,
  userId: string,
) => `${userId}-${orgId}-${workspaceId}`;

type RoleState = { key: string; role: SimpleRoleType };
type WorkspaceRoleState = { key: string; wsRole: WorkspaceSimpleRoleType };
type PropsT = { children: React.ReactNode };

export const SimpleRoleProvider: React.FC<PropsT> = observer(({ children }) => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const userId = useCurrentUserId();

  const [initialized, setInitialized] = useState(false);
  const [roleState, setRoleState] = useState<Nullable<RoleState>>(null);
  const [workspaceRoleState, setWorkspaceRoleState] =
    useState<Nullable<WorkspaceRoleState>>(null);
  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [hasError, setError] = useState(initialState.hasError);

  const value = useMemo<SimpleRoleProviderT>(
    () => ({
      isLoading,
      role: roleState?.role ?? null,
      workspaceRole: workspaceRoleState?.wsRole ?? null,
      hasError,
    }),
    [roleState, isLoading, hasError, workspaceRoleState],
  );

  const catchOrgUnauthorized = useCallback(() => {
    setInitialized(true);
    setLoading(false);
    setRoleState(null);
    setWorkspaceRoleState(null);
    setError(true);
  }, []);

  const catchWsUnauthorized = useCallback(() => {
    setInitialized(true);
    setLoading(false);
    setWorkspaceRoleState(null);
    setError(true);
  }, []);

  useEffect(() => {
    if (!userId || !orgId || isLoading || hasError) {
      return;
    }

    const roleKey = buildRoleKey(orgId, userId);
    const workspaceRoleKey = buildWorkspaceRoleKey(orgId, wsId, userId);
    const shouldFetchRole = roleKey !== roleState?.key;
    const shouldFetchWorkspaceRole =
      wsId && workspaceRoleKey !== workspaceRoleState?.key;

    if (shouldFetchRole || shouldFetchWorkspaceRole) {
      setLoading(true);

      const orgPromise = shouldFetchRole
        ? organizationSimpleRolesApi.getMeApiV1OrganizationsOrganizationIdSimpleRolesMeGet(
            orgId,
          )
        : Promise.resolve();
      orgPromise
        .then((response) => {
          if (response) {
            setRoleState({
              key: roleKey,
              role: response.data.role,
            });
          }
        })
        .catch(catchOrgUnauthorized);

      const wsPromise = shouldFetchWorkspaceRole
        ? organizationsApi.getMeWsRolesApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdWsRolesMeGet(
            orgId,
            wsId,
          )
        : Promise.resolve();
      wsPromise
        .then((response) => {
          if (response) {
            // If the user is at least a viewer and has the asViewer feature flag set, they are treated as a viewer.
            const designatedWsRole =
              isFeatureFlagEnabled(FEATURE_FLAGS.asWsViewer) &&
              isWorkspaceRoleAtLeast(
                WorkspaceSimpleRoleType.VIEWER,
                response.data.role,
              )
                ? WorkspaceSimpleRoleType.VIEWER
                : response.data.role;
            setWorkspaceRoleState({
              key: workspaceRoleKey,
              wsRole: designatedWsRole,
            });
          }
        })
        .catch(catchWsUnauthorized);

      Promise.all([orgPromise, wsPromise])
        .then(() => {
          setLoading(false);
          setInitialized(true);
        })
        .catch(() => {});
    }
  }, [
    orgId,
    userId,
    wsId,
    isLoading,
    roleState,
    workspaceRoleState,
    hasError,
    catchOrgUnauthorized,
    catchWsUnauthorized,
  ]);

  return (
    <SimpleRoleContext.Provider value={value}>
      {initialized ? children : false}
    </SimpleRoleContext.Provider>
  );
});
