import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { FlowVersionT } from "src/api/flowTypes";
import { useEditFlow } from "src/api/queries";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { ExternalLink } from "src/base-components/ExternalLink";
import { Icon } from "src/base-components/Icon";
import { DOCS_FUNDAMENTALS_VERSIONS_AND_PUBLISHING } from "src/constants/ExternalLinks";
import { DashboardPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type SetSandboxDefaultVersionModalPropsT = {
  isOpen: boolean;
  onClose: () => void;
  afterLeave?: () => void;
  flowId: string;
  version?: FlowVersionT;
};

export const SetSandboxDefaultVersionModal: React.FC<
  SetSandboxDefaultVersionModalPropsT
> = ({ isOpen, onClose, flowId, version, afterLeave }) => {
  const { wsId } = useParamsDecode<DashboardPageParamsT>();
  const updateDefaultVersion = useEditFlow(wsId);

  const onConfirm = async () => {
    if (version) {
      await updateDefaultVersion.mutateAsync({
        flowId: flowId,
        default_sandbox_version: version.id,
      });
      onClose();
    }
  };

  return (
    <ConfirmationModal
      afterLeave={afterLeave}
      confirmationButtonText="Yes, set as Sandbox Default version"
      open={isOpen}
      title={`Do you want to set <${version?.name}> as your Sandbox Default version?`}
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <div className="mb-4 mt-4 text-gray-500 font-inter-normal-13px">
        All requests to the flow's sandbox endpoint will be routed to the
        default version, unless otherwise specified.
      </div>
      <ExternalLink
        className="mb-8 flex flex-row text-indigo-500 font-inter-normal-13px"
        href={DOCS_FUNDAMENTALS_VERSIONS_AND_PUBLISHING}
      >
        Learn about default versions
        <Icon icon={faArrowUpRightFromSquare} size="xs" />
      </ExternalLink>
    </ConfirmationModal>
  );
};
