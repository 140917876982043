import React, { useEffect } from "react";

import { ErroredRow, NodeTestRunResult } from "src/api/types";
import { ErrorSelector } from "src/base-components/ErrorSelector";
import { TableComp as Table } from "src/base-components/Table";
import { getColumns } from "src/dataTable/TableUtils";
import { usePaginatedNodeRunErrorData } from "src/nodeEditor/api/queries";
import { errorMessage } from "src/utils/stringUtils";

type Props = {
  selectedNodeId: string;
  testResult: NodeTestRunResult;
  displayedFailure: ErroredRow | undefined;
  setDisplayedFailure: (failure: ErroredRow | undefined) => void;
  resultsOutdated: boolean;
  workspaceUrl: string;
  action?: {
    text: string;
    onClick: () => void;
  };
};

export const TestRunErrorSelector: React.FC<Props> = ({
  selectedNodeId,
  testResult,
  displayedFailure,
  setDisplayedFailure,
  resultsOutdated,
  workspaceUrl,
  action,
}) => {
  const errorQuery = usePaginatedNodeRunErrorData(testResult, workspaceUrl);

  const errorList = errorQuery.data?.pages.flatMap((page) => page.data);
  /**
   * This value is not the same as displayedError.index, as displayedError.index reflects the datas index in the whole
   *  test dataset, while this variable reflects its index in the failures of the current Node.
   */
  const displayedFailureArrayIndex = errorList?.findIndex(
    (error) => error.error_hash === displayedFailure?.error_hash,
  );

  useEffect(() => {
    if (selectedNodeId !== displayedFailure?.node_id) {
      setDisplayedFailure(errorList?.[0]?.example_failure);
      return;
    }
    // In case a not yet fetched error is selected (e.g. by clicking on the error in the output table),
    // we need to fetch it.
    if (
      displayedFailureArrayIndex === -1 &&
      errorList &&
      testResult.errors_count > 0 &&
      selectedNodeId === displayedFailure?.node_id &&
      !errorQuery.isFetchingNextPage
    ) {
      errorQuery.fetchNextPage();
    }
  }, [
    displayedFailure?.node_id,
    displayedFailureArrayIndex,
    errorList,
    errorQuery,
    selectedNodeId,
    setDisplayedFailure,
    testResult.errors_count,
  ]);

  const setPreviousError = () => {
    if (
      !displayedFailureArrayIndex ||
      displayedFailureArrayIndex < 1 ||
      !errorList
    )
      return;
    else
      setDisplayedFailure(
        errorList[displayedFailureArrayIndex - 1].example_failure,
      );
  };

  const setNextError = () => {
    if (
      displayedFailureArrayIndex === testResult.errors_count - 1 ||
      displayedFailureArrayIndex === undefined ||
      !errorList
    )
      return;
    if (
      displayedFailureArrayIndex > errorList.length - 10 &&
      errorList.length !== testResult.errors_count &&
      !errorQuery.isFetchingNextPage
    ) {
      errorQuery.fetchNextPage();
    }
    setDisplayedFailure(
      errorList[displayedFailureArrayIndex + 1].example_failure,
    );
  };

  if (!displayedFailure || displayedFailureArrayIndex === undefined)
    return <></>;

  const failureCount = errorList?.[displayedFailureArrayIndex]?.failure_count;
  return (
    <div className="mt-6 px-7">
      <ErrorSelector
        action={action}
        displayedFailureArrayIndex={displayedFailureArrayIndex}
        displayedMessage={errorMessage(displayedFailure.msg)}
        errors_count={testResult.errors_count}
        failureCount={failureCount}
        resultsOutdated={resultsOutdated}
        setNextError={setNextError}
        setPreviousError={setPreviousError}
      />
      <div className="mt-6">
        <div className="mb-4 text-gray-800 font-inter-semibold-13px">
          Failing Node input:
        </div>
        <Table
          columns={getColumns([displayedFailure.data], "topLeft")}
          data={[displayedFailure.data]}
          dataLoc="error-selector-table"
          frameClassName="px-4 border border-gray-200 rounded-lg"
          rowClassName="border-t"
        />
      </div>
    </div>
  );
};
