import { faWarning } from "@fortawesome/pro-regular-svg-icons";

/**
 * Helper function to define the error props for the InputV2 component
 * based on the form errors
 * @param isErrored boolean indicating if there is a form error for a field
 * @returns object with the error props for the InputV2 component
 */
export const errorProps = (isErrored: boolean) => ({
  errored: isErrored,
  suffixIcon: isErrored
    ? { icon: faWarning, variant: "danger" as const }
    : undefined,
});
