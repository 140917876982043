import { useSearchParams } from "react-router-dom";

import { DecisionEnvironment } from "src/api/types";

export const useEnvironmentFilterAsQueryParam = (
  initialValue: DecisionEnvironment = DecisionEnvironment.LIVE,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return [
    (searchParams.get("environment") ?? initialValue) as DecisionEnvironment,
    (newValue: DecisionEnvironment) =>
      setSearchParams((current) => {
        current.set("environment", newValue);
        return current;
      }),
  ] as const;
};
