import { faPage, faTag } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { Dataset } from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/base-components/Tooltip";

type Props = {
  source: Dataset["source"];
  hideTooltip?: boolean;
  size?: "2xs" | "xs";
  color?: string;
};

export const SourceIcon: React.FC<Props> = ({
  source,
  hideTooltip = false,
  size = "xs",
  color = "text-indigo-500",
}) => {
  const icon = (
    <Icon
      color={color}
      icon={source === "history" ? faTag : faPage}
      size={size}
    />
  );

  if (hideTooltip) {
    return icon;
  }

  return (
    <Tooltip
      align="center"
      placement="top"
      title={
        source === "history"
          ? "This dataset includes a Decision ID"
          : "No Decision ID found in this dataset"
      }
      asChild
    >
      {icon}
    </Tooltip>
  );
};
