import { makeConnectionTemplate } from "src/connections/types";

export const SocureTemplate = makeConnectionTemplate({
  provider: "socure",
  secrets: [{ key: "api_key", name: "API key", required: true }],
  resources: {
    // New bundles
    socure_fraud_bundle_1: {
      // TODO: add a hint somewhere about what full fraud suite includes
      name: "Bundle 1: Full Fraud Suite",
      configKeys: [],
    },
    socure_fraud_bundle_2: {
      name: "Bundle 2: Full Fraud Suite + Address + KYC",
      configKeys: [],
    },
    socure_fraud_bundle_3: {
      name: "Bundle 3: Full Fraud Suite + Address + KYC + Watchlist Standard",
      configKeys: [],
    },
    socure_fraud_bundle_4: {
      name: "Bundle 4: Full Fraud Suite + Address + KYC + Watchlist Plus",
      configKeys: [],
    },
    socure_fraud_bundle_5: {
      name: "Bundle 5: Full Fraud Suite + Address + KYC + Watchlist Premier",
      configKeys: [],
    },
    socure_doc_v_plus_bundle: {
      name: "Bundle 6 (DocV Plus): Document Verification + Device Risk",
      configKeys: [],
    },
    socure_digital_intelligence: {
      name: "Digital Intelligence",
      configKeys: [],
    },
    // Deprecated bundles
    compliance_bundle: {
      name: "Compliance Bundle",
      deprecated: true,
      configKeys: [],
    },
    standard_bundle: {
      name: "Standard Bundle",
      deprecated: true,
      configKeys: [],
    },
    premium_bundle: {
      name: "Premium Bundle",
      deprecated: true,
      configKeys: [],
    },
  },
});
