import { faEdit } from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/base-components/Tooltip";

type EditSecretButtonProps = {
  alignment: "start" | "center";
  secretName: string;
  secretKey: string;
  toggleEditable: (key: string) => void;
};

export const EditSecretButton: React.FC<EditSecretButtonProps> = ({
  alignment,
  secretName,
  secretKey,
  toggleEditable,
}) => (
  <Tooltip
    align={alignment}
    placement="right"
    title={`Edit ${secretName.toLowerCase()}`}
    asChild
  >
    <div className={`${alignment === "start" ? "items-start" : ""} ml-2 flex`}>
      <Icon
        color="text-gray-500"
        dataLoc={`edit-provider-secret-${secretKey}`}
        icon={faEdit}
        size="xs"
        onClick={() => toggleEditable(secretKey)}
      />
    </div>
  </Tooltip>
);
