import { times } from "lodash";
import { twJoin } from "tailwind-merge";

import { ReviewSummaryResponse } from "src/api/types";
import { Skeleton } from "src/base-components/Skeleton";

const SummaryItem: React.FC<{
  value?: string | number;
  description: string;
  color?: "indigo" | "orange";
  dataLoc?: string;
}> = ({ value, description, color = "indigo", dataLoc }) => (
  <div className="flex-1 space-y-0.5 pb-1 pl-5.5 pr-3 pt-2" data-loc={dataLoc}>
    <div
      className={twJoin(
        "font-inter-semibold-14px",
        color === "indigo" && "text-indigo-600",
        color === "orange" && "text-orange-600",
      )}
    >
      {value ?? 0}
    </div>
    <div className="text-gray-800 font-inter-medium-12px">{description}</div>
  </div>
);

const SummarySkeleton: React.FC = () => (
  <div className="flex-1 space-y-0.5 pb-1 pl-5.5 pr-3 pt-2">
    <div className="font-inter-semibold-14px">
      <Skeleton width="w-6" />
    </div>
    <div className="font-inter-medium-12px">
      <Skeleton width="w-3/4" />
    </div>
  </div>
);

export const SummaryPane: React.FC<{ summary?: ReviewSummaryResponse }> = ({
  summary,
}) => (
  <div className="mb-4 flex divide-x divide-gray-200 rounded-lg border border-gray-200 bg-white pb-3 pl-1 pr-5 pt-2">
    {summary ? (
      <>
        <SummaryItem
          dataLoc="manual-review-need-review"
          description="Need manual review"
          value={`${summary?.open_count} decisions`}
        />
        <SummaryItem
          dataLoc="manual-review-assigned-to-me"
          description="Assigned to me"
          value={summary?.open_and_assigned_to_me_count}
        />
        <SummaryItem
          dataLoc="manual-review-in-progress"
          description="In progress"
          value={summary?.in_progress_count}
        />
        <SummaryItem
          color="orange"
          dataLoc="manual-review-long-time-open"
          description="Pending since more than 24 hrs"
          value={summary?.long_time_open_count}
        />
        <SummaryItem
          dataLoc="manual-review-completed-recently"
          description="Completed in the last 24 hours"
          value={summary?.completed_recently_count}
        />
      </>
    ) : (
      times(5, (i) => <SummarySkeleton key={i} />)
    )}
  </div>
);
