import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { keyBy } from "lodash";

import { useConnections } from "src/api/connectApi/queries";
import { Icon } from "src/base-components/Icon";
import { ProviderIcon } from "src/connections/config/Icon";
import { useWorkspaceContext } from "src/router/routerContextHooks";

export const ConnectionIcon: React.FC<{
  connectionId: string | undefined;
  size?: "sm" | "md";
  fallbackIcon: IconProp;
}> = ({ connectionId, size, fallbackIcon }) => {
  const { workspace } = useWorkspaceContext();
  const connections = useConnections(workspace.base_url);

  const connectionsById = keyBy(connections.data ?? [], "id");

  if (connectionId && connectionsById[connectionId]) {
    const connection = connectionsById[connectionId];

    const mappedSize = size === "md" ? "sm" : "xs";
    return <ProviderIcon provider={connection.provider} size={mappedSize} />;
  }

  const mappedSize = size === "md" ? "xs" : "2xs";
  return <Icon color="text-gray-500" icon={fallbackIcon} size={mappedSize} />;
};
