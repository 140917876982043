import { AnimatePresence } from "framer-motion";
import { useRef } from "react";

import {
  FloatingWindowsContext,
  FloatingWindowsContextType,
} from "src/base-components/FloatingWindow/context";
import { useFloatingWindows } from "src/base-components/FloatingWindow/hooks";
import { createFloatingWindowsStore } from "src/base-components/FloatingWindow/store";
import { DetailedView } from "src/dataTable/DetailedView/DetailedView";

export const FloatingWindowsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const storeRef = useRef<FloatingWindowsContextType>();

  if (!storeRef.current) {
    storeRef.current = createFloatingWindowsStore();
  }

  return (
    <FloatingWindowsContext.Provider value={storeRef.current}>
      {children}
      <FloatingWindows />
    </FloatingWindowsContext.Provider>
  );
};

const FloatingWindows: React.FC = () => {
  const windows = useFloatingWindows();

  return (
    <AnimatePresence initial={false}>
      {Object.entries(windows).map(([id, window]) => (
        <DetailedView
          key={id}
          computePosition={window.computePosition}
          data={window.data}
          id={id}
        />
      ))}
    </AnimatePresence>
  );
};
