import { KeyValuePairT } from "src/connections/types";

// TODO: document how we handle secrets in FE: `secret`, `required` field,
// different envs + what we send/not send to update/delete secrets
export const findSecret = (secrets: KeyValuePairT[], key: string) => {
  return secrets.find((s) => s.key === key);
};

export const getDefaultSecret = (
  key: string,
  env: string | null,
): KeyValuePairT => ({
  key,
  value: "",
  required: true,
  secret: false,
  environment: env,
});

export const setSecretEnv = (
  secret: KeyValuePairT,
  env: string | null,
): KeyValuePairT => {
  return { ...secret, environment: env };
};

export const setSecretDeleted = (secret: KeyValuePairT): KeyValuePairT => {
  return { ...secret, value: null };
};

export const filterUpdatedSecrets = (pairs: KeyValuePairT[] = []) => {
  // remove secrets for update but keep them for delete.
  return pairs.filter(
    (pair) => pair.key.trim() !== "" && (!pair.secret || pair.value === null),
  );
};

export const markSecrets = (secrets: KeyValuePairT[] = []) =>
  secrets.map((pair) => ({ ...pair, secret: true }));

export const filterSecretsByEnvironment = (
  secrets: KeyValuePairT[] = [],
  environment: string | null,
): KeyValuePairT[] => secrets.filter((s) => s.environment === environment);
