import { uniqBy } from "lodash";

import {
  TAKTILE_TEAM_NOTIFIED,
  toastFailure,
} from "src/base-components/Toast/utils";
import { useAvailableIntegrationNodes } from "src/datasets/DatasetTable/hooks";
import { getDesiredType } from "src/datasets/DatasetTable/utils";
import { useCreateDatasetFromScratchMutation } from "src/datasets/api/queries";
import { DEFAULT_DATASET_NAME } from "src/datasets/constants";
import { useInputColumnsFromSchema } from "src/datasets/utils";
import { useSetEditDatasetId } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";

export const useCreateDatasetFromScratch = () => {
  const { workspace, flow, version } = useAuthoringContext();
  const setEditDatasetId = useSetEditDatasetId();
  const integrationNodes = useAvailableIntegrationNodes(version);
  const inputColumns = useInputColumnsFromSchema(version);

  const createDataset = useCreateDatasetFromScratchMutation(
    workspace?.base_url,
  );

  return async () => {
    try {
      const dataset = await createDataset.mutateAsync({
        name: DEFAULT_DATASET_NAME,
        flow_id: flow.id,
        input_columns: inputColumns,
        mock_columns: uniqBy(integrationNodes, "name").map((node) => ({
          name: node.name,
          desired_type: getDesiredType(node),
          use_subflow_mocks: false,
        })),
        purpose: "test_run",
      });

      setEditDatasetId(dataset.id);
    } catch (e) {
      toastFailure({
        title: "Dataset creation failed",
        description: TAKTILE_TEAM_NOTIFIED,
      });
    }
  };
};
