import { faQuestionCircle, faXmark } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { useVersionResourceLock } from "src/authoringMultiplayerLock/useVersionResourceLock";
import { Icon } from "src/base-components/Icon";
import { ResourceType } from "src/clients/flow-api";
import { DOCS_FUNDAMENTALS_SCHEMAS } from "src/constants/ExternalLinks";
import { EditorLock } from "src/multiplayer/EditorLock";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { SchemaErrorInformationV2 } from "src/schema/SchemaErrorInformationV2/SchemaErrorInformationV2";
import { SchemaTabView } from "src/schema/SchemaTabView";

type PropsT = {
  setSchemaInvalid: (errored: boolean) => void;
  onRequestClose: () => void;
};

export const SchemaEditSidePane: React.FC<PropsT> = ({
  setSchemaInvalid,
  onRequestClose,
}) => {
  const { version, workspace } = useAuthoringContext();
  const { lockOwner, lockedByOtherUser } = useVersionResourceLock(
    ResourceType.SCHEMA,
    version.id,
  );
  const renderHeader = () => (
    <div>
      <div className="px-6">
        <div className="flex w-full flex-row items-center">
          <div className="flex h-[27px] w-full justify-between text-gray-800 font-inter-semibold-16px">
            Schema
            <div className="max-w-80">
              {lockOwner && lockedByOtherUser && (
                <EditorLock lockOwner={lockOwner} />
              )}
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-2">
            <a
              href={DOCS_FUNDAMENTALS_SCHEMAS}
              rel="noreferrer"
              target="_blank"
            >
              <Icon
                color="text-gray-500 hover:text-gray-700"
                icon={faQuestionCircle}
                size="xs"
              />
            </a>
            <Icon
              color="text-gray-500 hover:text-gray-700"
              icon={faXmark}
              size="xs"
              onClick={onRequestClose}
            />
          </div>
        </div>
        <div className="mt-1">
          <p className="text-gray-500 font-inter-normal-12px">
            Configure the input and output fields for this Decision Flow.
          </p>
        </div>
      </div>
      <div className="mb-1 mt-4 px-6">
        <div className="h-px w-full rounded-full bg-gray-100"></div>
      </div>
    </div>
  );

  return (
    <div className="flex h-full w-128 flex-col border-l border-gray-200 bg-white py-6">
      {renderHeader()}
      <SchemaErrorInformationV2 workspaceUrl={workspace.base_url!} />
      <div className="min-h-0 flex-1">
        <SchemaTabView
          flowVersion={version}
          setSchemaInvalid={setSchemaInvalid}
        />
      </div>
    </div>
  );
};
