import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";

import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { Modal } from "src/base-components/Modal";
import { toastSuccess } from "src/base-components/Toast/utils";
import { Tooltip } from "src/base-components/Tooltip";
import { WorkspaceServiceAccountWithKey } from "src/clients/taktile-api";
import { copyTextToClipboard } from "src/utils/clipboard";

type PropsT = {
  createdApiKey?: WorkspaceServiceAccountWithKey;
  onUnmount: () => void;
};

export const ShowApiKeyModal: React.FC<PropsT> = ({
  createdApiKey,
  onUnmount,
}) => {
  // The open state of this modal is controlled by the "createdApiKey" prop.
  // Whenever it is set away from undefined, the modal opens.
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (createdApiKey !== undefined) {
      setOpen(true);
    }
  }, [createdApiKey]);

  useEffect(() => {
    return onUnmount;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const closeModal = () => {
    setOpen(false);
  };

  const copyKeyToClipboard = () => {
    if (createdApiKey) {
      copyTextToClipboard(createdApiKey.api_key);
      toastSuccess({ title: "Copied to clipboard!" });
    }
  };

  const renderInfoBlock = () => (
    <>
      <div className="mb-4 text-gray-500 font-inter-normal-12px">
        Please copy your API key now. You won't be able to see it again. Save
        and store it in a secure place, such as a password manager or secret
        store.
      </div>
      <div className="mb-8 flex w-full flex-row justify-between rounded-lg border border-gray-300 py-2 pl-2 pr-3 text-gray-800 font-inter-normal-13px">
        {createdApiKey?.api_key}
        <Tooltip align="center" placement="top" title="Copy" asChild>
          <Icon
            color="text-gray-500"
            icon={faCopy}
            size="xs"
            onClick={copyKeyToClipboard}
          />
        </Tooltip>
      </div>
    </>
  );

  return (
    <Modal
      className="w-120"
      open={open}
      title="Your new API key"
      onClose={closeModal}
    >
      <div className="px-6 pb-6 pt-2">
        {renderInfoBlock()}
        <div className="flex flex-row items-center justify-end text-right">
          <Button variant="secondary" onClick={closeModal}>
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};
