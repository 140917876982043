import { useGraphStore } from "src/store/StoreProvider";
import { useNodeRunState } from "src/store/runState/RunState";

export const useInputSchemaError = (): boolean => {
  const { inputNode } = useGraphStore();
  const runStateCache = useNodeRunState(inputNode?.id ?? "");
  const inputRunState = inputNode ? runStateCache : undefined;
  return (
    inputRunState?.type === "historical-error" ||
    (inputRunState?.type === "test-run" &&
      inputRunState?.testResult.failure_count > 0)
  );
};

export const useOutputSchemaError = (): boolean => {
  const { outputNode } = useGraphStore();
  const runStateCache = useNodeRunState(outputNode?.id ?? "");
  const outputRunState = outputNode ? runStateCache : undefined;
  return (
    outputRunState?.type === "historical-error" ||
    (outputRunState?.type === "test-run" &&
      outputRunState?.testResult.failure_count > 0)
  );
};
