import { useFormContext } from "react-hook-form";

import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { RedshiftSecretField } from "src/connections/config/database/redshift/RedshiftSecretField";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import {
  Environment,
  RedshiftConnectionConfigInputsT,
} from "src/connections/types";

type PropsT = {
  environment: Environment;
};

export const RedshiftUsernamePasswordAuthFieldset: React.FC<PropsT> = ({
  environment,
}) => {
  const {
    formState: { errors },
  } = useFormContext<RedshiftConnectionConfigInputsT>();
  const envErrors =
    errors?.[`${environment}Config`]?.["usernamePasswordConfig"];

  return (
    <FieldsetCard className="last:mb-0">
      <RedshiftSecretField
        environment={environment}
        helpText="The name of the Redshift database user"
        isErrored={isFieldErrored(envErrors, "username")}
        secretConfigField="usernamePasswordConfig.username"
        secretName="Database username"
        isRequired
      />
      <RedshiftSecretField
        environment={environment}
        helpText="The password of the Redshift database user"
        isErrored={isFieldErrored(envErrors, "password")}
        secretConfigField="usernamePasswordConfig.password"
        secretName="Password"
        isRequired
      />
    </FieldsetCard>
  );
};
