import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import { SimpleRoleType } from "src/clients/taktile-api";

type Props = {
  disabled: boolean;
  value: SimpleRoleType;
  onChange: (value: SimpleRoleType) => void;
};

export const OrgRoleSelector: React.FC<Props> = ({
  disabled,
  value,
  onChange,
}) => (
  <SimpleRadioGroup
    disabled={disabled}
    orientation="vertical"
    value={value}
    onValueChange={onChange as (value: string) => void}
  >
    <div>
      <SimpleRadioGroup.Item
        dataLoc="org-role-admin"
        label="Organization admin"
        labelClassName="pl-2 text-sm"
        value={SimpleRoleType.ADMIN}
        boldLabel
      />
      <div className="pl-6 text-gray-600 font-inter-normal-12px">
        Admins can add new team members and edit permissions.
      </div>
    </div>
    <div>
      <SimpleRadioGroup.Item
        dataLoc="org-role-member"
        label="Organization member"
        labelClassName="pl-2 text-sm"
        value={SimpleRoleType.MEMBER}
        boldLabel
      />
      <div className="pl-6 text-gray-600 font-inter-normal-12px">
        Organization members can join Taktile.
      </div>
    </div>
  </SimpleRadioGroup>
);
