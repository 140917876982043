import { capitalize } from "lodash";

import { Pill } from "src/base-components/Pill";
import { JobStatus } from "src/jobs/types";

export const JobStatusPill = ({ status }: { status: JobStatus }) => (
  <Pill variant={status === "active" ? "green" : "gray"}>
    <Pill.Text>{capitalize(status)}</Pill.Text>
  </Pill>
);
