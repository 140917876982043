import { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import React, { useRef, useEffect, useCallback, useState } from "react";

import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { EditorCellProps } from "src/base-components/EditorTable/types";
import { focusContentEditable } from "src/base-components/EditorTable/utils";
import { OperatorDropdown } from "src/base-components/OperatorDropdown";
import {
  DecisionTableOperators,
  DecisionTablePredicate,
} from "src/clients/flow-api";
import { isUnaryOperator } from "src/decisionTableNode/ConditionEditor/utils";
import { RowShape } from "src/decisionTableNode/utils";

type Props = EditorCellProps<RowShape, DecisionTablePredicate | null>;

export const ConditionEditor: React.FC<Props> = React.memo(
  ({ value, state, onChange }) => {
    const safeValue: DecisionTablePredicate = value ?? {
      id: "",
      operator: DecisionTableOperators.IS_ANY,
      value: "",
    };
    const hasUnaryOperator = isUnaryOperator(safeValue.operator);
    const editorRef = useRef<ReactCodeMirrorRef>(null);
    const ref = useRef<HTMLDivElement>(null);
    const [userValue, setUserValue] = useState(safeValue.value);

    const handleStringChange = useCallback(
      (predicateValue: string) => {
        if (hasUnaryOperator || !value?.id) {
          return;
        }

        if (value.value !== predicateValue) {
          onChange({
            id: value.id,
            operator: value.operator,
            value: predicateValue,
          });
        }
      },
      [hasUnaryOperator, onChange, value?.id, value?.operator, value?.value],
    );

    const handleOperatorChange = useCallback(
      (predicateOperator: string) => {
        if (!value?.id) {
          return;
        }
        const updatedOperator: DecisionTableOperators =
          predicateOperator as unknown as DecisionTableOperators;
        const stringValue = isUnaryOperator(updatedOperator) ? "" : value.value;
        onChange({
          id: value.id,
          value: stringValue,
          operator: updatedOperator,
        });
      },
      [onChange, value?.value, value?.id],
    );

    const handleEditorBlur = useCallback(() => {
      handleStringChange(userValue);
    }, [userValue, handleStringChange]);

    useEffect(() => {
      if (ref.current && state.isSelected) {
        ref.current.focus();
      }
      if (editorRef.current && state.isSelected) {
        editorRef.current.view?.focus();
      }
    }, [state.isSelected]);

    useEffect(() => {
      if (ref.current && state.isEditing) {
        focusContentEditable(ref.current);
      }
      if (editorRef.current && state.isEditing) {
        editorRef.current.view?.focus();
      }
    }, [state.isEditing]);

    return (
      <div className="flex h-full min-h-[41px] w-full items-center gap-x-1.5 p-2">
        <AutocompleteCodeInput
          ref={editorRef}
          dataLoc="cell-editor"
          disabled={hasUnaryOperator ? true : state.readonly}
          value={userValue}
          variant="cell"
          transparentBackground
          onBlur={handleEditorBlur}
          onChange={setUserValue}
        >
          <OperatorDropdown
            disabled={state.readonly}
            value={safeValue.operator}
            withIsAnyOperator={true}
            onChange={handleOperatorChange}
          />
        </AutocompleteCodeInput>
      </div>
    );
  },
);
