import { AuditEvent } from "src/clients/taktile-api";

export const downloadEventAsCSV = async (event: AuditEvent) => {
  const escapeValue = (value: any): string => {
    if (value === null || value === undefined) {
      return "";
    }
    if (typeof value === "object") {
      return `"${JSON.stringify(value).replace(/"/g, '""')}"`;
    }
    if (typeof value === "string") {
      return `"${value.replace(/"/g, '""')}"`;
    }
    return String(value);
  };

  const keys = Object.keys(event) as (keyof AuditEvent)[];
  const csvHeader = keys.join(",");
  const csvRow = keys.map((key) => escapeValue(event[key])).join(",");

  const csv = `${csvHeader}\n${csvRow}`;

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `event_${event.id}.csv`;
  link.click();
  URL.revokeObjectURL(url);
};
