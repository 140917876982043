import { differenceInHours } from "date-fns";

import { Interval } from "src/performance/types";
import { formatNumber } from "src/utils/numbers";
import { StrictDateRange } from "src/utils/timeWindow";

const INTERVALS_IN_SECONDS = {
  hour: 60 * 60,
  day: 60 * 60 * 24,
} as const;

export const getIntervalForTimeWindow = (
  timeWindow: StrictDateRange,
): Interval => {
  const windowDifference = differenceInHours(timeWindow.to, timeWindow.from);

  return windowDifference > 24 ? "day" : "hour";
};

export const intervalToSeconds = (interval: Interval) =>
  INTERVALS_IN_SECONDS[interval];

/**
 * Returns the difference between previous and current numbers.
 * The value is returned as a number that represents the percentage difference.
 *
 * @example getPercentageDiff(100, 30) // -0.7 => -70%
 * @example getPercentageDiff(100, 130) // 0.3 => 30%
 * @example getPercentageDiff(0, 100) // NaN
 */
export const getPercentageDiff = (previous: number, current: number) => {
  if (previous === 0) return NaN;
  if (current === 0) return -1;

  return ((current * 100) / previous - 100) / 100;
};

export const displayLatency = (milliseconds: number) => {
  const seconds = milliseconds / 1000;
  const minutes = seconds / 60;
  if (minutes >= 1) {
    return formatNumber(minutes, { maximumFractionDigits: 1 }) + " m";
  } else if (seconds >= 1) {
    return formatNumber(seconds, { maximumFractionDigits: 1 }) + " s";
  } else {
    return formatNumber(milliseconds, { maximumFractionDigits: 1 }) + " ms";
  }
};
