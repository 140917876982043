import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { useFormContext } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { truncateUrl } from "../truncateUrl";
import { ConnectionT } from "src/api/connectApi/types";
import { FieldErrorsT } from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/base-components/Tooltip";
import { VerbDropDown } from "src/customConnectionNode/editorComponents/VerbDropDown";
import { CustomConnectionForm } from "src/customConnectionNode/types";

const textStyles = "text-xs font-mono";
const focusClassName =
  "focus-within:outline-none focus-within:ring-1 focus-within:ring-indigo-400";
const maxBaseUrlChars = 24;
type PropsT = {
  immutable: boolean;
  connection: ConnectionT;
  runFieldErrors: FieldErrorsT | undefined;
};

export const VerbPathSelector: React.FC<PropsT> = ({
  immutable,
  connection,
  runFieldErrors,
}) => {
  const formProps = useFormContext<CustomConnectionForm>();
  const pathFieldError = runFieldErrors?.[formProps.getValues("path.id")];

  const containerStyles =
    "relative flex bg-white items-center border rounded-lg border-gray-200 mb-6";

  const prefixStyles = textStyles;

  const inputStyles = twJoin(
    "h-10 flex-1 rounded-lg py-1",
    pathFieldError ? "pr-7" : "pr-2",
    textStyles,
    "bg-white text-gray-800",
    "focus:outline-none",
  );

  const prefixProps = twJoin(
    "pl-4",
    "max-w-48 flex-none",
    textStyles,
    "text-gray-400",
  );

  const truncatedBaseURL = truncateUrl(
    connection.configuration?.base_url,
    maxBaseUrlChars,
  );
  const sandboxAvailable =
    connection.enable_non_prod_configs &&
    Boolean(connection.non_prod_env_configs?.["sandbox"]);

  const TooltipTitle = (
    <div className="w-full">
      <div className="w-full text-gray-500 font-inter-normal-12px">
        Production environment base URL
      </div>
      <div className="text-white-800 mb-1 w-full break-all font-inter-semibold-12px">
        {connection.configuration?.base_url}
      </div>

      {sandboxAvailable && (
        <>
          <div className="w-full text-gray-500 font-inter-normal-12px">
            Sandbox environment base URL
          </div>
          <div className="text-white-800 w-full break-all font-inter-semibold-12px">
            {connection.non_prod_env_configs?.["sandbox"]?.["base_url"]}
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className={containerStyles}>
      <div className={prefixStyles}>
        <VerbDropDown immutable={immutable} />
      </div>
      <Tooltip
        activated={!!pathFieldError}
        align="center"
        body={pathFieldError}
        placement="top"
        title="Invalid input"
        triggerClassName="w-full"
      >
        <div
          className={twJoin(
            "z-1 ml-[1px] flex items-center rounded-r-lg bg-white",
            pathFieldError && "ring-1 ring-red-500",
            focusClassName,
          )}
        >
          <Tooltip
            activated={
              sandboxAvailable ||
              truncatedBaseURL !== connection.configuration?.base_url
            }
            placement="bottom"
            title={TooltipTitle}
          >
            <span className={prefixProps}>{truncatedBaseURL}</span>
          </Tooltip>
          <input
            autoComplete="off"
            className={inputStyles}
            data-loc="custom-connection-path-expression"
            {...formProps.register("path.expression")}
            disabled={immutable}
          ></input>
          {pathFieldError && (
            <div className="absolute right-3">
              <Icon
                color="text-red-400"
                cursorType="text"
                icon={faWarning}
                size="xs"
              />
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
