import {
  faCopy,
  faCut,
  faObjectGroup,
  faTrashAlt,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { observer } from "mobx-react-lite";
import React from "react";

import { useSelectedNodesLockedByOtherUser } from "src/authoringMultiplayerLock/useSelectedNodesLockedByOtherUser";
import { toastSuccess } from "src/base-components/Toast/utils";
import { MultiSelectOptionsIcon } from "src/flowGraph/multiSelectOptions/MultiSelectOptionsIcon";
import { getCopySuccessMessage } from "src/flowGraph/nodeControls/NodeControls";
import { useCanAuthoringEditFlowVersion } from "src/hooks/useCanAuthoringEditFlowVersion";
import { getCreatableNodes } from "src/nodeAdding/PasteNodesCard";
import { AuthorPageParamsT } from "src/router/urls";
import { useGraphStore } from "src/store/StoreProvider";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const InvalidCopySelectionReasonMessages = {
  NotEnoughNodes: "less than 2 nodes",
  NoInputOutput: "selection with input / output nodes",
  NotConnected: "disconnected nodes",
  NotAllSplitNodeChildren: "incomplete split nodes",
} as const;
export const InvalidGroupReasonMessages = {
  ...InvalidCopySelectionReasonMessages,
  NoGroups: "selection with Node groups or nodes that are already in a group",
} as const;

export type InvalidCopySelectionReason =
  keyof typeof InvalidCopySelectionReasonMessages;
export type InvalidGroupReason = keyof typeof InvalidGroupReasonMessages;

export const MultiSelectOptions: React.FC = observer(() => {
  const {
    multiSelectionSelectedNodes,
    isValidGroup,
    setIsCreateGroupModalVisible,
    setSelectedNode,
    copySelectedNodesToClipboard,
    isValidCopyDeleteSelection,
    setIsDeleteNodesModalVisible,
  } = useGraphStore();
  const selectedNodesLockedByOtherUser = useSelectedNodesLockedByOtherUser();
  const { wsId } = useParamsDecode<AuthorPageParamsT>();
  const canModifyGraph = useCanAuthoringEditFlowVersion();

  if (multiSelectionSelectedNodes === undefined) return <></>;

  const handleCopyNodesToClipboard = async (isCutting?: boolean) => {
    const clipboard = await copySelectedNodesToClipboard(wsId, isCutting);
    if (clipboard) {
      toastSuccess({
        title: getCopySuccessMessage(clipboard, isCutting),
      });
    }
  };

  return (
    <div
      className="absolute bottom-1 left-0 z-20 mb-6 ml-6"
      data-loc="multi-select-options"
    >
      <div className="relative flex gap-2 rounded-lg bg-white px-3 shadow-base">
        <h3 className="border-r border-gray-200 py-3.5 pr-3 text-gray-600 font-inter-medium-13px">
          {getCreatableNodes(multiSelectionSelectedNodes).length} selected nodes
        </h3>
        {canModifyGraph && (
          <>
            <MultiSelectOptionsIcon
              dataLoc="group-nodes"
              deactivated={
                !isValidGroup.valid || selectedNodesLockedByOtherUser!
              }
              icon={faObjectGroup}
              tooltipTitle={
                selectedNodesLockedByOtherUser
                  ? "Cannot group locked Nodes"
                  : isValidGroup.valid
                    ? "Group Nodes"
                    : `Cannot group ${
                        InvalidGroupReasonMessages[isValidGroup.reason]
                      }`
              }
              borderRight
              onClick={() => setIsCreateGroupModalVisible(true)}
            />
            <MultiSelectOptionsIcon
              deactivated={
                !isValidCopyDeleteSelection.valid ||
                selectedNodesLockedByOtherUser!
              }
              icon={faCut}
              tooltipTitle={
                selectedNodesLockedByOtherUser
                  ? "Cannot cut locked Nodes"
                  : isValidCopyDeleteSelection.valid
                    ? "Cut nodes"
                    : `Cannot cut ${
                        InvalidCopySelectionReasonMessages[
                          isValidCopyDeleteSelection.reason
                        ]
                      }`
              }
              borderRight
              onClick={() => handleCopyNodesToClipboard(true)}
            />
          </>
        )}
        <MultiSelectOptionsIcon
          dataLoc="copy-nodes"
          deactivated={
            !isValidCopyDeleteSelection.valid || selectedNodesLockedByOtherUser!
          }
          icon={faCopy}
          tooltipTitle={
            selectedNodesLockedByOtherUser
              ? "Cannot copy locked Nodes"
              : isValidCopyDeleteSelection.valid
                ? "Copy nodes"
                : `Cannot copy ${
                    InvalidCopySelectionReasonMessages[
                      isValidCopyDeleteSelection.reason
                    ]
                  }`
          }
          borderRight
          onClick={() => handleCopyNodesToClipboard(false)}
        />
        {canModifyGraph && (
          <MultiSelectOptionsIcon
            deactivated={
              !isValidCopyDeleteSelection.valid ||
              selectedNodesLockedByOtherUser!
            }
            icon={faTrashAlt}
            tooltipTitle={
              selectedNodesLockedByOtherUser
                ? "Cannot delete locked Nodes"
                : isValidCopyDeleteSelection.valid
                  ? "Delete nodes"
                  : `Cannot delete ${
                      InvalidCopySelectionReasonMessages[
                        isValidCopyDeleteSelection.reason
                      ]
                    }`
            }
            borderRight
            onClick={() => {
              setIsDeleteNodesModalVisible(true);
            }}
          />
        )}
        <MultiSelectOptionsIcon
          deactivated={false}
          icon={faXmark}
          tooltipTitle="Deselect all Nodes"
          onClick={() => setSelectedNode(null)}
        />
      </div>
    </div>
  );
});
