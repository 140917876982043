import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { UserNameInfo } from "src/flowContainer/versionEditedInfo/UsernameInfo";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { formatDate } from "src/utils/datetime";

type RevisionHeaderProps = {
  changedBy: string | undefined;
  includeBreadcrumb?: boolean;
};

export const RevisionHeader: React.FC<RevisionHeaderProps> = ({
  changedBy,
  includeBreadcrumb = false,
}) => {
  const { version, flow } = useAuthoringContext();

  return (
    <div
      className={twJoin(
        "flex flex-row items-center gap-x-1 text-lg",
        includeBreadcrumb && "ml-3",
      )}
    >
      {includeBreadcrumb && (
        <>
          <div className="text-gray-800 font-inter-semibold-14px">
            {flow.name}
          </div>
          <div className="ml-1.5 mr-0.5">
            <Icon
              color="text-gray-500 hover:text-gray-700"
              icon={faChevronRight}
              size="3xs"
            />
          </div>
        </>
      )}
      <div className="text-gray-800 font-inter-semibold-13px">
        {version.name}
      </div>
      <div className="text-gray-500 font-inter-normal-13px">at</div>

      <div className="text-gray-800 font-inter-semibold-13px">
        {formatDate(version.updated_at, "dd MMM yyyy HH:mmaaa")}
      </div>
      <div className="text-gray-500 font-inter-normal-13px">by</div>
      {changedBy !== undefined && (
        <div className="flex text-gray-800 font-inter-semibold-13px">
          <UserNameInfo userId={changedBy} withAvatar={true} />
        </div>
      )}
    </div>
  );
};
