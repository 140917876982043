import { faPaste } from "@fortawesome/pro-regular-svg-icons";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";
import { v4 } from "uuid";

import { Icon } from "src/base-components/Icon";
import { toastSuccess } from "src/base-components/Toast/utils";
import { Tooltip } from "src/base-components/Tooltip";
import { NodeT } from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";
import {
  CreatableNodeTypeT,
  CREATABLE_NODE_TYPES,
} from "src/constants/NodeTypes";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { AuthorPageParamsT } from "src/router/urls";
import { useGraphStore } from "src/store/StoreProvider";
import { Subgraph } from "src/utils/GraphUtils";
import { getClipboardReadPermission } from "src/utils/decideClipboard";
import { pluralize } from "src/utils/stringUtils";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const getCreatableNodes = (nodes: NodeT[]) =>
  nodes.filter((node) =>
    CREATABLE_NODE_TYPES.includes(node.type as CreatableNodeTypeT),
  );

export const pasteSuccessMessage = (result: Subgraph | undefined) => {
  if (result) {
    toastSuccess({
      title: `${pluralize(getCreatableNodes(result.nodes).length, "node")} ${
        result.groups.length > 0
          ? `and ${pluralize(result.groups.length, "group")} `
          : ""
      }added`,
    });
  }
};

export const trackPastingEvents = (
  result: Subgraph | undefined,
  version_id: string,
  flow_id: string,
  orgId: string,
) => {
  if (result) {
    getCreatableNodes(result.nodes).forEach((node) => {
      const pasteOperationId = v4();
      tracker.emit(
        trackingEvents.pasteExistingNode({
          flow_version_id: version_id,
          flow_id,
          organization_id: orgId,
          node_type: node.type as NODE_TYPE,
          provider:
            "providerResource" in node.data
              ? node.data.providerResource.provider
              : undefined,
          resource:
            "providerResource" in node.data
              ? node.data.providerResource.resource
              : undefined,
          paste_operation_id: pasteOperationId,
        }),
      );
    });
  }
};

export const PasteNodesCard: React.FC = observer(() => {
  const { pasteClipboard, canPasteClipboard, clipboard } = useGraphStore();
  const { version_id, flow_id, orgId, wsId } =
    useParamsDecode<AuthorPageParamsT>();
  const [clipboardPermission, setClipboardPermission] = useState<
    PermissionState | undefined
  >();

  // We are only interested in the count of nodes that are creatable by the user
  const clipboardNodeSize = getCreatableNodes(clipboard.nodes).length;

  useEffect(() => {
    const setPermission = async () => {
      // This can be undefined in some browser/unsecure contexts
      const permission = await getClipboardReadPermission();
      setClipboardPermission(permission?.state);
    };
    setPermission();
  }, [setClipboardPermission]);

  // If the user has not granted clipboard access we want to show the card anyways so the user gets prompted for clipboard access on clicking this card
  if (
    (clipboardNodeSize === 0 && clipboardPermission === "granted") ||
    clipboardPermission === "denied"
  ) {
    return <></>;
  }

  const onClick = async () => {
    const result = await pasteClipboard(wsId);
    if (result) {
      pasteSuccessMessage(result);
      trackPastingEvents(result, version_id, flow_id, orgId);
    }
  };

  const canPaste = canPasteClipboard(wsId);
  return (
    <Tooltip
      activated={!canPaste.canPaste && !!canPaste.reason}
      align="center"
      contentMaxW="sm"
      placement="top"
      title={canPaste.reason}
      asChild
    >
      <div
        className={twJoin(
          "my-4 h-full w-full rounded-lg border px-4 py-3",

          canPaste.canPaste &&
            "border-gray-200 text-gray-800 hover:cursor-pointer hover:border-indigo-500",
          !canPaste.canPaste && "disabled border-gray-100 text-gray-400",
        )}
        data-loc="paste-nodes-card"
        onClick={onClick}
      >
        <div className="flex">
          <div className="flex h-7 w-7 items-center justify-center rounded-lg bg-gray-100">
            <Icon
              color="text-gray-500 hover:text-gray-700"
              icon={faPaste}
              size="2xs"
            />
          </div>
          <div className="ml-4 flex flex-col justify-center">
            <div className="font-inter-semibold-13px">Paste from clipboard</div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
});
