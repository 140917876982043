import { useLocalStorage } from "usehooks-ts";

import { GetFlowsApiV1FlowsGetOrderByEnum } from "src/clients/flow-api";

const orderFlowsByStorageKey = "orderFlowsBy";

export const useOrderFlowsBy = () => {
  const [orderFlowsBy, saveOrderFlowsBy] =
    useLocalStorage<GetFlowsApiV1FlowsGetOrderByEnum>(
      orderFlowsByStorageKey,
      GetFlowsApiV1FlowsGetOrderByEnum.UPDATED_AT_TRAVERSED,
    );

  return [orderFlowsBy, saveOrderFlowsBy] as const;
};
