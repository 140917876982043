import React, { useEffect, useState } from "react";

import { Checkbox } from "src/base-components/Checkbox";
import { ApiKeyType } from "src/clients/taktile-api";

type Props = {
  value: Nullable<ApiKeyType>[];
  onChange: (value: Nullable<ApiKeyType>[]) => void;
};

const Label: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <label className="flex gap-x-2 text-sm font-bold text-gray-700">
    {children}
  </label>
);

export const KeyTypeSelector: React.FC<Props> = ({ value, onChange }) => {
  const [prod, setProd] = useState(false);
  const [sandbox, setSandbox] = useState(false);
  const [jobAdmin, setJobAdmin] = useState(false);
  const [historyAdmin, setHistoryAdmin] = useState(false);

  useEffect(() => {
    setProd(value!.includes(ApiKeyType.LIVE));
    setSandbox(value!.includes(ApiKeyType.SANDBOX));
    setJobAdmin(value!.includes(ApiKeyType.JOB_ADMIN));
    setHistoryAdmin(value!.includes(ApiKeyType.HISTORY_ADMIN));
  }, [value]);

  useEffect(() => {
    const selectedTypes: Nullable<ApiKeyType>[] = [];
    if (prod) {
      selectedTypes.push(ApiKeyType.LIVE);
    }
    if (sandbox) {
      selectedTypes.push(ApiKeyType.SANDBOX);
    }
    if (jobAdmin) {
      selectedTypes.push(ApiKeyType.JOB_ADMIN);
    }
    if (historyAdmin) {
      selectedTypes.push(ApiKeyType.HISTORY_ADMIN);
    }
    onChange(selectedTypes);
  }, [prod, sandbox, jobAdmin, historyAdmin, onChange]);

  return (
    <div className="flex flex-col gap-3">
      <div>
        <Label>
          <Checkbox
            checked={sandbox}
            name="sandbox"
            onChange={() => setSandbox(!sandbox)}
          />
          Sandbox decisions
        </Label>
        <p className="pl-5 text-gray-600 font-inter-normal-12px">
          Can make
          <span className="font-fira-code mx-1 rounded bg-gray-100 px-1">
            /run/.../sandbox/decide
          </span>
          calls to either draft or published flow versions, and run Jobs in the
          sandbox environment.
        </p>
      </div>

      <div>
        <Label>
          <Checkbox
            checked={prod}
            name="production"
            onChange={() => setProd(!prod)}
          />
          Live decisions
        </Label>
        <p className="pl-5 text-gray-600 font-inter-normal-12px">
          Can make
          <span className="font-fira-code mx-1 rounded bg-gray-100 px-1">
            /run/.../decide
          </span>
          calls to published flow versions, and run Jobs in the live
          environment.
        </p>
      </div>

      <div>
        <Label>
          <Checkbox
            checked={jobAdmin}
            name="jobs"
            onChange={() => setJobAdmin(!jobAdmin)}
          />
          Job management
        </Label>
        <p className="pl-5 text-gray-600 font-inter-normal-12px">
          Can manage Jobs and make calls to the{" "}
          <span className="font-fira-code mx-1 rounded bg-gray-100 px-1">
            /jobs
          </span>{" "}
          API. Note that in order to run Jobs, this API key must also have the
          sandbox or live decisions key permission.
        </p>
      </div>

      <div>
        <Label>
          <Checkbox
            checked={historyAdmin}
            name="history"
            onChange={() => setHistoryAdmin(!historyAdmin)}
          />
          Decision history management
        </Label>
        <p className="pl-5 text-gray-600 font-inter-normal-12px">
          Can manage decision history and make calls to the{" "}
          <span className="font-fira-code mx-1 rounded bg-gray-100 px-1">
            /history
          </span>{" "}
          API.
        </p>
      </div>
    </div>
  );
};
