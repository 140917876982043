import {
  faBracketsCurly,
  faUpRightFromSquare,
} from "@fortawesome/pro-regular-svg-icons";
import { twJoin } from "tailwind-merge";

import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { buildSchemaTabUrl } from "src/parentFlowNodes/utils";
import { SchemaOptions } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";

type Props = {
  isOutput: boolean;
  flowName: string;
  childFlowId: string;
  childVersionId: string;
  warn: boolean;
};

export const MissingSchema: React.FC<Props> = ({
  isOutput,
  flowName,
  childFlowId,
  childVersionId,
  warn,
}) => {
  const { orgId, workspace } = useAuthoringContext();
  const url = buildSchemaTabUrl(
    orgId,
    workspace.id,
    childFlowId,
    childVersionId,
    isOutput ? SchemaOptions.Output : SchemaOptions.Input,
  );

  return (
    <div className="flex flex-col items-center px-16 py-12">
      <div
        className={twJoin(
          "mb-4 rounded-lg p-4",
          warn ? "bg-orange-100" : "bg-indigo-100",
        )}
      >
        <Icon
          color={warn ? "text-orange-600" : "text-indigo-600"}
          icon={faBracketsCurly}
          size="md"
        />
      </div>
      <p className="mb-2 text-gray-800 font-inter-semibold-13px">
        Missing Schema
      </p>
      <p className="mb-6 text-center text-gray-500 font-inter-normal-12px">
        Define a schema for <span className="">{flowName}</span>
        <br />
        to complete mapping.
      </p>
      <a href={url} rel="noreferrer" target="_blank">
        <Button iconLeft={faUpRightFromSquare} size="base" variant="secondary">
          Define Schema
        </Button>
      </a>
    </div>
  );
};
