import { TextConfirmationModal } from "src/base-components/TextConfirmationModal";
import { FlowFolderListItem } from "src/clients/flow-api";

type Props = {
  isOpen: boolean;
  folder: FlowFolderListItem | undefined;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  afterLeave: () => void;
};

export const DeleteFolderModal = ({
  isOpen,
  folder,
  onClose,
  onConfirm,
  afterLeave,
}: Props) => {
  const description =
    "Are you sure you want to delete this folder? This action is not reversible." +
    (folder?.flow_count && folder.flow_count > 0
      ? " Decision Flow(s) in this folder will be moved to No folder section."
      : "");
  return (
    <TextConfirmationModal
      afterLeave={afterLeave}
      challengeText={folder?.name}
      description={description}
      label="Enter folder name to confirm."
      open={isOpen}
      title={`Delete ${folder?.name || ""}`}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};
