import React from "react";
import { ControllerRenderProps } from "react-hook-form";

import { Checkbox } from "src/base-components/Checkbox";
import { InputMappingsT } from "src/integrationNode/types";

type Props = {
  option: { key: string; value: string };
  controllerFieldProps: ControllerRenderProps<
    InputMappingsT,
    `multiselectors.${string}.selected`
  >;
  immutable: boolean;
};

export const MultiSelectorCheckbox: React.FC<Props> = ({
  option,
  controllerFieldProps,
  immutable,
}) => {
  const optionIsChecked = !!controllerFieldProps.value.find(
    (ticked) => ticked === option.key,
  );
  return (
    <label
      className="flex text-gray-800 font-inter-normal-12px"
      htmlFor={option.value}
    >
      <Checkbox
        checked={optionIsChecked}
        className="mr-1.5"
        disabled={immutable}
        id={option.value}
        onChange={() => {
          if (optionIsChecked) {
            controllerFieldProps.onChange(
              controllerFieldProps.value.filter(
                (ticked) => ticked !== option.key,
              ),
            );
          } else {
            controllerFieldProps.onChange([
              ...controllerFieldProps.value,
              option.key,
            ]);
          }
        }}
      />
      {option.value}
    </label>
  );
};
