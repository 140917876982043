import React, { useMemo } from "react";

import { useWorkspaceUsers } from "src/api/taktile/queries";
import { InformationPill } from "src/base-components/InformationPill";
import { UserAvatar } from "src/base-components/UserAvatar/UserAvatar";
import { FlowReviewConfiguration } from "src/clients/flow-api";
import { OrganizationUser } from "src/clients/taktile-api";
import { DashboardPageParamsT } from "src/router/urls";
import { sentanceList } from "src/utils/stringUtils";
import { useParamsDecode } from "src/utils/useParamsDecode";
import { getUserName } from "src/utils/user";

type Props = {
  isEditingFlow: boolean;
  reviewConfig?: FlowReviewConfiguration;
};

export const UserAvatarWithName: React.FC<{ user: OrganizationUser }> = ({
  user,
}) => (
  <div className="flex items-center gap-x-1">
    <UserAvatar size="xs" user={user} inline />
    <span className="text-gray-800 font-inter-medium-12px">
      {getUserName(user)}
    </span>
  </div>
);

export const UserSentenceList: React.FC<{ users: OrganizationUser[] }> = ({
  users,
}) =>
  sentanceList(
    users.map((user) => (
      <div key={user?.id} className="inline-block align-top">
        {user && <UserAvatarWithName user={user} />}
      </div>
    )),
  );

export const FlowReviewConfigPreview: React.FC<Props> = ({
  isEditingFlow,
  reviewConfig,
}) => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const { data: users } = useWorkspaceUsers(orgId, wsId, {
    include_deactivated: false,
  });
  const usersById = useMemo(
    () =>
      (users ?? []).reduce(
        (acc, user) => {
          acc[user.id] = user;
          return acc;
        },
        {} as Record<string, OrganizationUser>,
      ),
    [users],
  );

  if (!isEditingFlow) {
    return (
      <div className="mt-3.5">
        <InformationPill type="neutral">
          This Decision Flow will be created without a mandatory review process.
          Contact your workspace admin to enable reviews.
        </InformationPill>
      </div>
    );
  }

  if (isEditingFlow && reviewConfig) {
    const reviewerIds = reviewConfig.default_reviewer_list ?? [];
    const reviewers = reviewerIds.map((userId) => {
      return usersById[userId];
    });

    return (
      <div className="mt-3.5">
        <InformationPill type="neutral">
          <div className="w-full flex-grow">
            {"Approval from "}
            <UserSentenceList users={reviewers} />
            {" is required to publish versions of this Decision Flow."}
          </div>
        </InformationPill>
      </div>
    );
  }

  return null;
};
