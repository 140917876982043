import {
  faFlagAlt,
  faCube,
  faUserCircle,
  faKey,
  faPlug,
  faFolder,
} from "@fortawesome/pro-regular-svg-icons";
import { capitalize } from "lodash";
import { useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEventListener } from "usehooks-ts";

import { useFlows, useUserOrganizations } from "src/api/queries";
import { Icon } from "src/base-components/Icon";
import { TAKTILE_ORG_ID } from "src/constants/OrgIds";
import { ExcludesFalse } from "src/flow/types";
import { useFolders } from "src/flowsOverview/v2/folderQueries";
import { useCapabilities } from "src/hooks/useCapabilities";
import { OmniboxBase } from "src/omnibox/OmniboxBase";
import { MenuItem } from "src/omnibox/OmniboxBase";
import {
  useOmniboxActions,
  useIsOmniboxVisible,
} from "src/omnibox/OmniboxStore";
import { FEATURE_FLAGS } from "src/router/featureFlags";
import {
  DashboardPageParamsT,
  getFlowVersionsUrl,
  getUrlToSettingsPage,
  getUrlToWsDashboard,
} from "src/router/urls";
import * as logger from "src/utils/logger";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const OmniboxOther = () => {
  const isOmniboxVisible = useIsOmniboxVisible();
  const { showOmnibox, hideOmnibox } = useOmniboxActions();
  const [_, setSearchParams] = useSearchParams();
  const capabilities = useCapabilities();

  const orgs = useUserOrganizations();
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const isTaktileUser =
    orgs.data?.some((org) => org.id === TAKTILE_ORG_ID) ?? false;

  const document = useRef(window.document);
  const navigate = useNavigate();

  const handleKeydown = (e: KeyboardEvent) => {
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyK") {
      showOmnibox();
    }
    if (isOmniboxVisible && e.key === "Escape") {
      hideOmnibox();
    }
  };
  useEventListener("keydown", handleKeydown, document);

  // Fetch the relevant data for the omnibox items: list of actions & feature flags
  const folders = useFolders({ workspaceId: wsId });
  const flows = useFlows({ workspaceId: wsId });
  const actionOptions = [
    capabilities.connections.canAccess && {
      key: "connections",
      text: "Go to Connections",
      icon: <Icon color="text-gray-500" icon={faPlug} size="xs" />,
    },
    capabilities.apiKeys.canAccess && {
      key: "api-keys",
      text: "Go to API keys",
      icon: <Icon color="text-gray-500" icon={faKey} size="xs" />,
    },
    capabilities.usersPermissions.canAccess && {
      key: "users",
      text: "Go to Users & permissions",
      icon: <Icon color="text-gray-500" icon={faUserCircle} size="xs" />,
    },
  ].filter(Boolean as unknown as ExcludesFalse);

  // Create omnibox items list for actions, feature flags and decision flows
  const folderItems: MenuItem[] =
    folders.data?.folders.map(({ name, id }) => ({
      label: name,
      icon: (
        <div className="flex h-6 w-6 items-center justify-center rounded-md bg-gray-200 bg-opacity-50">
          <Icon color="text-gray-500" icon={faFolder} size="xs" />
        </div>
      ),
      type: "Folder",
      details: "",
      value: id,
      onSelect: () => selectItem(id, "Folder"),
    })) ?? [];
  const decisionFlowItems: MenuItem[] =
    flows.data?.map(({ name, id }) => ({
      label: name,
      icon: (
        <div className="h-6 w-6 items-center justify-center rounded-md bg-indigo-200 bg-opacity-50 pl-px pt-0.5">
          <Icon color="text-indigo-500" icon={faCube} size="xs" />
        </div>
      ),
      type: "Decision Flow",
      details: "",
      value: id,
      onSelect: () => selectItem(id, "Decision Flow"),
    })) ?? [];
  const actionItems: MenuItem[] = actionOptions.map(({ key, icon, text }) => ({
    label: text,
    icon: (
      <div className="h-6 w-6 items-center justify-center rounded-md bg-gray-200 bg-opacity-50 pl-px pt-0.5">
        {icon}
      </div>
    ),
    type: "Action",
    details: "",
    value: key,
    onSelect: () => selectItem(key, "Action"),
  }));
  const featureFlagItems: MenuItem[] = Object.entries(FEATURE_FLAGS).map(
    ([key, value]) => ({
      label: capitalize(value).replace("-", " "),
      icon: (
        <div className="flex h-6 w-6 items-center justify-center rounded-md bg-gray-200 bg-opacity-50">
          <Icon color="text-gray-500" icon={faFlagAlt} size="xs" />
        </div>
      ),
      type: "Feature Flag",
      details: "",
      value: key,
      onSelect: () => selectItem(value, "Feature Flag"),
    }),
  );

  const selectItem = (
    value: string,
    type: "Action" | "Feature Flag" | "Decision Flow" | "Folder",
  ) => {
    try {
      switch (type) {
        case "Feature Flag":
          setSearchParams((params) => {
            if (params.has(value)) {
              params.delete(value);
            } else {
              params.append(value, "true");
            }
            return params;
          });
          break;
        case "Decision Flow":
          navigate(getFlowVersionsUrl(orgId, wsId, value));
          break;
        case "Action":
          navigate(getUrlToSettingsPage(orgId, wsId, value));
          break;
        case "Folder":
          navigate(getUrlToWsDashboard({ orgId, wsId, folderId: value }));
          break;
      }
    } catch (error) {
      logger.error("Error selecting item:", error);
    }
  };

  const menuItems = isTaktileUser
    ? [
        ...folderItems,
        ...decisionFlowItems,
        ...actionItems,
        ...featureFlagItems,
      ]
    : [...folderItems, ...decisionFlowItems, ...actionItems];

  const currentUrl = window.location.href;
  const versionRegex = /\/version\//;
  const isVersionPage = versionRegex.test(currentUrl);

  return (
    !isVersionPage && (
      <OmniboxBase
        data-loc="omnibox-canvas"
        menuItems={menuItems}
        open={isOmniboxVisible}
        placeholder="Search for decision flows or actions..."
        onClose={hideOmnibox}
      />
    )
  );
};
