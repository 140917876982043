import { useOrganizationUser } from "src/api/taktile/queries";
import { Pill } from "src/base-components/Pill";
import { AuthorPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type EditorLockProps = {
  lockOwner: string;
};

export const EditorLock: React.FC<EditorLockProps> = ({ lockOwner }) => {
  const { orgId } = useParamsDecode<AuthorPageParamsT>();
  const editingUser = useOrganizationUser(orgId, lockOwner);
  return (
    <Pill variant="gray" maxWidth>
      {editingUser.data?.avatar_url && (
        <Pill.Image src={editingUser.data?.avatar_url} />
      )}
      <Pill.Text fontType="text">{`${editingUser.data?.full_name} is editing...`}</Pill.Text>
    </Pill>
  );
};
