import { m } from "framer-motion";
import { twJoin } from "tailwind-merge";

import { Cell } from "src/flow/VersionsTable/VersionsTable";

export const DescriptionRow: React.FC<{
  description?: string;
}> = ({ description }) => (
  <Cell colSpan={6} data-loc="version-description-row">
    <m.div
      key="description"
      animate="open"
      className={twJoin(
        "-mt-4.5 ml-3 translate-x-px whitespace-pre-line border-l border-gray-200 pl-5",
        !description && "text-gray-400",
      )}
      initial="closed"
      transition={{ type: "tween", ease: "easeOut", duration: 0.1 }}
      variants={{
        open: { opacity: 1, height: "auto" },
        closed: { opacity: 0, height: 0 },
      }}
    >
      {description ? description : "No description"}
    </m.div>
  </Cell>
);
