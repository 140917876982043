import React from "react";
import { twMerge } from "tailwind-merge";

import { Card } from "src/base-components/Card";
import { InformationPill } from "src/base-components/InformationPill";
import { MissingSchema } from "src/parentFlowNodes/MissingSchema";
import {
  SCHEMA_MAPPING_ARROW_WIDTH,
  SchemaProperty,
} from "src/parentFlowNodes/SchemaProperty";
import { SchemaError } from "src/parentFlowNodes/flowNode/types";
import { buildSchemaTabUrl } from "src/parentFlowNodes/utils";
import { SchemaOptions } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { SchemaUIT } from "src/schema/utils";

type Props = {
  title: string;
  description?: React.ReactNode;
  childFlowName: string;
  schema?: SchemaUIT;
  isOutput?: boolean;
  childFlowId: string;
  childFlowSlug: string;
  childVersionId: string;
  isNodeStale: boolean;
  readonly?: boolean;
  hasError?: boolean;
  fieldMappingErrors: Record<string, SchemaError>;
  isLoopNode?: boolean;
};

export const DataMap: React.FC<Props> = ({
  schema,
  title,
  description,
  childFlowName,
  childFlowId,
  childVersionId,
  isOutput = false,
  readonly = false,
  hasError = false,
  fieldMappingErrors,
  isNodeStale,
  isLoopNode = false,
}) => {
  const { orgId, workspace } = useAuthoringContext();
  return (
    <div className="mb-4 flex flex-col gap-y-3">
      {hasError && isNodeStale && (
        <InformationPill type="info">
          Map required fields to complete configuration
        </InformationPill>
      )}
      <span className="text-gray-800 font-inter-semibold-13px">{title}</span>
      {description && (
        <p className="text-gray-500 font-inter-normal-12px">{description}</p>
      )}
      <Card variant={schema ? "default" : "inverted"}>
        <Card.Content>
          <div className="flex flex-col gap-y-2">
            {!schema ? (
              <MissingSchema
                childFlowId={childFlowId}
                childVersionId={childVersionId}
                flowName={childFlowName}
                isOutput={isOutput}
                warn={isNodeStale}
              />
            ) : (
              <>
                <div
                  className={twMerge(
                    "mb-1 flex w-full gap-x-3",
                    isOutput && "flex-row-reverse",
                  )}
                >
                  <p className="w-1/2 text-gray-500 font-inter-normal-12px">
                    Current Decision Flow
                  </p>
                  <div className={SCHEMA_MAPPING_ARROW_WIDTH} />
                  <p className="w-1/2 text-gray-500 font-inter-normal-12px">
                    {childFlowName}
                  </p>
                </div>
                {schema.properties.map((field) => (
                  <SchemaProperty
                    key={field.fieldName}
                    field={field}
                    fieldError={!!fieldMappingErrors[field.fieldName]}
                    isLoopNode={isLoopNode}
                    isOutput={isOutput}
                    readonly={readonly}
                    schemaTabUrl={buildSchemaTabUrl(
                      orgId,
                      workspace.id,
                      childFlowId,
                      childVersionId,
                      isOutput ? SchemaOptions.Output : SchemaOptions.Input,
                    )}
                    shouldDisplayError={isNodeStale}
                  />
                ))}
              </>
            )}
          </div>
        </Card.Content>
      </Card>
    </div>
  );
};
