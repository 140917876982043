import {
  InfiniteData,
  QueryClient,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { organizationUsersKey } from "src/api/taktile/queries";
import { organizationUsersApi, organizationsApi } from "src/api/taktileApi";
import {
  OrganizationUser,
  UserOrganizationInviteData,
} from "src/clients/taktile-api";
import {
  OrganizationUserUpdatePayload,
  OrganizationUserWorkspacesRolesUpdatePayload,
} from "src/store/api/types";

type OrgUsersListCacheEntry = InfiniteData<OrganizationUser[]>;

const buildOnMutate =
  (queryClient: QueryClient, orgId: string, userId: string) =>
  (
    newUser:
      | OrganizationUserUpdatePayload
      | OrganizationUserWorkspacesRolesUpdatePayload,
  ) => {
    const queryKey = organizationUsersKey.list(orgId);
    const previousUsersPages =
      queryClient.getQueryData<OrgUsersListCacheEntry>(queryKey);

    queryClient.setQueryData<OrgUsersListCacheEntry>(
      queryKey,
      (previous: OrgUsersListCacheEntry | undefined) =>
        previous && {
          pages: previous?.pages.map((page) =>
            page.map((user: OrganizationUser) =>
              user.id === userId ? { ...user, ...newUser } : user,
            ),
          ),
          pageParams: previous?.pageParams,
        },
    );

    queryClient.invalidateQueries({
      queryKey: organizationUsersKey.list(orgId),
      refetchType: "none",
    });

    return { previousUsers: previousUsersPages?.pages.flat() };
  };

const buildOnError =
  (queryClient: QueryClient, orgId: string) =>
  (
    _err: unknown,
    _newUser: unknown,
    context?: { previousUsers?: OrganizationUser[] },
  ) => {
    const queryKey = organizationUsersKey.list(orgId);
    if (context?.previousUsers) {
      queryClient.setQueryData(queryKey, context.previousUsers);
    }
  };

export const useUpdateOrganizationUser = (orgId: string, userId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: OrganizationUserUpdatePayload) =>
      (
        await organizationUsersApi.updateUserApiV1OrganizationsOrgIdUsersUserIdPatch(
          orgId,
          userId,
          data,
        )
      ).data,
    {
      onMutate: buildOnMutate(queryClient, orgId, userId),
      onError: buildOnError(queryClient, orgId),
    },
  );
};

export const useUpdateOrganizationUserWorkspacesRoles = (
  orgId: string,
  userId: string,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: OrganizationUserWorkspacesRolesUpdatePayload) =>
      (
        await organizationUsersApi.updateUserWsRolesApiV1OrganizationsOrgIdUsersUserIdWsRolesPatch(
          orgId,
          userId,
          data,
        )
      ).data,
    {
      onMutate: buildOnMutate(queryClient, orgId, userId),
      onError: buildOnError(queryClient, orgId),
    },
  );
};

export const useDeleteOrganizationInvite = (
  orgId: string,
  inviteId: string,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      organizationsApi.deleteOrganizationInviteApiV1OrganizationsOrganizationIdInvitesInviteIdDelete(
        orgId,
        inviteId,
      ),
    {
      onMutate: () => {
        const queryKey = organizationUsersKey.list(orgId);
        const previousUsers =
          queryClient.getQueryData<OrgUsersListCacheEntry>(queryKey);

        queryClient.setQueryData<OrgUsersListCacheEntry>(
          queryKey,
          (previous) =>
            previous && {
              pages: previous?.pages.map((page) =>
                page.filter((user) => user.invite_id !== inviteId),
              ),
              pageParams: previous?.pageParams,
            },
        );

        queryClient.invalidateQueries({
          queryKey: organizationUsersKey.list(orgId),
          refetchType: "none",
        });

        return { previousUsers: previousUsers?.pages.flat() };
      },
      onError: buildOnError(queryClient, orgId),
    },
  );
};

export const useCreateOrganizationInvite = (orgId: string) => {
  const queryClient = useQueryClient();
  const queryKey = organizationUsersKey.list(orgId);

  return useMutation(
    (payload: UserOrganizationInviteData) =>
      organizationsApi.postOrganizationInviteApiV1OrganizationsOrganizationIdInvitesPost(
        orgId,
        payload,
      ),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData<OrgUsersListCacheEntry>(
          queryKey,
          (previous) =>
            previous && {
              pages: [
                ...previous?.pages,
                [{ ...data.user, invite_id: data.id }],
              ],
              pageParams: previous?.pageParams,
            },
        );

        queryClient.invalidateQueries({
          queryKey: organizationUsersKey.list(orgId),
          refetchType: "none",
        });
      },
      onMutate: () => ({
        previousUsers: queryClient
          .getQueryData<OrgUsersListCacheEntry>(queryKey)
          ?.pages?.flat(),
      }),
      onError: buildOnError(queryClient, orgId),
    },
  );
};
