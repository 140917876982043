import { AnimatePresence, m } from "framer-motion";
import { ComponentPropsWithoutRef, useState } from "react";
import { To, useNavigate } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { CellExpandable } from "src/flow/VersionName";
import {
  VersionsTableProps,
  VersionCellProps,
} from "src/flow/VersionsTable/types";

export const VersionsTable: React.FC<VersionsTableProps> = ({
  children,
  columns,
  dataLoc,
  animate = true,
}) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <div className="rounded-lg border border-gray-200">
      <table className="w-full rounded-lg bg-white" data-loc={dataLoc}>
        <thead className="text-left font-inter-medium-12px">
          <tr>
            {columns
              .filter((_, index) => expanded || index === 0)
              .map((column, index) => (
                <th
                  key={column.id}
                  className={twJoin(
                    "whitespace-nowrap px-2 py-3.5 pt-4.5 first:pl-6 last:pr-6",
                    column.width,
                    index === 0
                      ? "font-inter-semibold-13px"
                      : "font-inter-normal-12px",
                  )}
                >
                  {index === 0 ? (
                    <CellExpandable
                      expanded={expanded}
                      title={column.name}
                      onClickExpand={() => setExpanded(!expanded)}
                    />
                  ) : (
                    column.name
                  )}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {expanded &&
            (animate ? (
              <AnimatePresence initial={false}>{children}</AnimatePresence>
            ) : (
              children
            ))}
        </tbody>
      </table>
    </div>
  );
};

export const VersionCell: React.FC<
  VersionCellProps & ComponentPropsWithoutRef<"td">
> = ({
  children,
  align = "left",
  font = "inter-normal-12px",
  paddingY = "py-4.5",
  className,
  ...props
}) => (
  <td
    className={twJoin(
      "px-2 first:pl-6 last:pr-6",
      "group-last:first:rounded-bl-2xl group-last:last:rounded-br-2xl",
      "bg-inherit",
      align === "right" && "text-right",
      font === "inter-normal-12px" && "text-gray-500 font-inter-normal-12px",
      font === "inter-medium-13px" && "font-inter-medium-13px",
      paddingY !== "none" && paddingY,
      className,
    )}
    {...props}
  >
    {children}
  </td>
);

type VersionRowProps = {
  children: React.ReactNode;
  to?: To;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export const VersionRow: React.FC<VersionRowProps> = ({
  children,
  to,
  className,
  onMouseEnter,
  onMouseLeave,
}) => {
  const navigate = useNavigate();
  return (
    <m.tr
      animate="in"
      className={twJoin(
        "group border-t border-gray-100 transition-colors duration-150",
        className,
      )}
      data-loc="version-row"
      exit="out"
      initial="initial"
      role="link"
      tabIndex={0}
      transition={{ duration: 0.15, type: "tween" }}
      variants={{
        initial: {
          opacity: 1,
          backgroundColor: "#FFFBEB",
        },
        in: {
          opacity: 1,
          backgroundColor: "#ffffff",
          transition: { duration: 1.5, delay: 1 },
        },
        out: { opacity: 0 },
      }}
      layout
      onClick={() => to && navigate(to)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </m.tr>
  );
};

export { VersionsTable as Table, VersionCell as Cell, VersionRow as Row };
