import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import { InputMappingT, IntegrationResourceT } from "src/integrationNode/types";

const addressElements = (): { [key: string]: InputMappingT } => ({
  street_address: {
    id: uuidV4(),
    type: "text",
    displayName: "Street address",
    assignedTo: "",
    hint: `Consumer's primary address. If the mailing address is a P.O. Box or Rural Route, include Box or Route followed by the number (e.g., P.O. Box 100). Do not report both a street address and a P.O. Box. 
    If the mailing address is a private mailbox (PMB), the street address should be reported here (e.g.,  5678 Main Street). The PMB number should be reported in 'Street address line 2' below (e.g., PMB 1234).
    `,
    rules: {
      required: true,
    },
  },
  street_address_2: {
    id: uuidV4(),
    type: "text",
    displayName: "Street address line 2",
    assignedTo: "",
    hint: "Consumer's additional address information",
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    hint: "A two-letter code for the US state or territory of the address",
    rules: {
      required: true,
    },
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    hint: "5 or 9 character postal code",
    rules: {
      required: true,
    },
  },
  housing_status: {
    id: uuidV4(),
    type: "text",
    displayName: "Housing status",
    assignedTo: "",
    hint: "One of 'Own', 'Rent', 'Family Friend', or 'Other' (case sensitive)",
  },
  months_at_address: {
    id: uuidV4(),
    type: "text",
    displayName: "Months at address",
    assignedTo: "",
    hint: "An integer for the total months the consumer has lived at the current address",
  },
});

const driversLicenseElements = (): { [key: string]: InputMappingT } => ({
  number: {
    id: uuidV4(),
    type: "text",
    displayName: "Drivers license number",
    assignedTo: "",
    hint: "2-20 character drivers license number",
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "Street address",
    assignedTo: "",
    hint: "A two-letter code for the US state or territory of the address",
  },
});

const bankingInformationElements = (): { [key: string]: InputMappingT } => ({
  bank_routing_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Bank routing number",
    assignedTo: "",
    hint: "9 character bank routing number",
  },
  bank_account_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Bank account number",
    assignedTo: "",
    hint: "String of characters with no dashes or spaces",
  },
  bank_account_type: {
    id: uuidV4(),
    type: "text",
    displayName: "Bank account type",
    assignedTo: "",
    hint: "One of 'Checking', 'Savings', 'Money Market', 'Debit Card', or 'Other' (case sensitive)",
  },
});

const phoneNumbersElements = (): { [key: string]: InputMappingT } => ({
  home_phone: {
    id: uuidV4(),
    type: "text",
    displayName: "Home phone",
    assignedTo: "",
    hint: "10 digit home phone number with no dashes, spaces, or leading 1s (e.g., '1112223333')",
  },
  cell_phone: {
    id: uuidV4(),
    type: "text",
    displayName: "Cell phone",
    assignedTo: "",
    hint: "10 digit cell phone number with no dashes, spaces, or leading 1s (e.g., '1112223333')",
  },
  work_phone: {
    id: uuidV4(),
    type: "text",
    displayName: "Work phone",
    assignedTo: "",
    hint: "10 digit work phone number with no dashes, spaces, or leading 1s (e.g., '1112223333')",
  },
});

const employmentElements = (): { [key: string]: InputMappingT } => ({
  occupation_type: {
    id: uuidV4(),
    type: "text",
    displayName: "Occupation type",
    assignedTo: "",
    hint: "Occupation type code. Use the Bureau of Labor Statistics standard occupational classifications.",
  },
  net_monthly_income: {
    id: uuidV4(),
    type: "text",
    displayName: "Net monthly income",
    assignedTo: "",
    hint: "An integer for the individual's total take-home monthly income (e.g., 2000)",
  },
  employer_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Employer name",
    assignedTo: "",
  },
  employer_address: {
    id: uuidV4(),
    type: "text",
    displayName: "Employer address",
    assignedTo: "",
    hint: "Employer's full street address",
  },
  employer_city: {
    id: uuidV4(),
    type: "text",
    displayName: "Employer city",
    assignedTo: "",
  },
  employer_state: {
    id: uuidV4(),
    type: "text",
    displayName: "Employer state",
    assignedTo: "",
    hint: "A two-letter code for the US state or territory of the address",
  },
});

const sourceIdentificationElements = (): { [key: string]: InputMappingT } => ({
  leadgen: {
    id: uuidV4(),
    type: "text",
    displayName: "Lead generation",
    assignedTo: "",
    hint: "If using a lead generator, this field is used to display the name of the lead generator from which the lead being inquired on was purchased",
  },
  origination_ip_address: {
    id: uuidV4(),
    type: "text",
    displayName: "Origination IP address",
    assignedTo: "",
    hint: "The IP address collected by the lender when the consumer is on their landing page",
  },
});

export const getDefaultExperianUSClarityServicesReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "experian_us_clarity_services",
      resource: "experian_us_clarity_services_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        first_name: {
          id: uuidV4(),
          type: "text",
          displayName: "First name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        middle_initital: {
          id: uuidV4(),
          type: "text",
          displayName: "Middle initial",
          assignedTo: "",
          hint: "Single character middle initial",
        },
        last_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Last name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        generation_code: {
          id: uuidV4(),
          type: "text",
          displayName: "Generation code",
          assignedTo: "",
          hint: "One of: 'Jr', 'Sr', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX' (case sensitive)",
        },
        social_security_number: {
          id: uuidV4(),
          type: "text",
          displayName: "Social security number",
          assignedTo: "",
          hint: "9-digit social security number, formatted as a string of 9 integers (e.g., '123456789')",
          rules: {
            required: true,
          },
        },
        date_of_birth: {
          id: uuidV4(),
          type: "text",
          displayName: "Date of birth",
          assignedTo: "",
          hint: "Must adhere to ISO format 'YYYY-MM-DD'",
        },
        email_address: {
          id: uuidV4(),
          type: "text",
          displayName: "Email address",
          assignedTo: "",
        },
        inquiry_tradeline_type: {
          id: uuidV4(),
          type: "text",
          displayName: "Inquiry tradeline type",
          assignedTo: "",
          hint: "Some examples are: 'C1' for internet payday loan, or 'C2' for storefront payday loan",
        },
      },
      grouped: {
        address: {
          getDefaultElements: addressElements,
          elements: addressElements(),
          displayName: "Address",
          rules: {
            required: true,
          },
        },

        drivers_license: {
          getDefaultElements: driversLicenseElements,
          displayName: "Drivers License",
          rules: {
            required: false,
          },
        },
        banking_information: {
          getDefaultElements: bankingInformationElements,
          displayName: "Banking Information",
          rules: {
            required: false,
          },
        },
        phone_numbers: {
          getDefaultElements: phoneNumbersElements,
          displayName: "Phone Numbers",
          rules: {
            required: false,
          },
        },
        employment: {
          getDefaultElements: employmentElements,
          displayName: "Employment Information",
          rules: {
            required: false,
          },
        },
        source_identification: {
          getDefaultElements: sourceIdentificationElements,
          displayName: "Source Identification",
          rules: {
            required: false,
          },
        },
      },
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        provider_request_id: getNamedOutputMapping("Provider request ID"),
      },
    },
    config: defaultResourceConfig,
    groupedGroupsAndLists: true,
  });
