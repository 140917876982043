import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  InputMappingGroupT,
  InputMappingT,
  IntegrationResourceT,
  OutputMappingT,
} from "src/integrationNode/types";

const getDefaultApplicantFields = (): { [key: string]: InputMappingT } => ({
  first_name: {
    id: uuidV4(),
    type: "text",
    displayName: "First name",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `1-15 characters`,
  },
  middle_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Middle name",
    assignedTo: "",
    hint: `1-15 characters`,
  },
  last_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Last name",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `1-25 characters`,
  },
  date_of_birth: {
    id: uuidV4(),
    type: "text",
    displayName: "Date of birth",
    assignedTo: "",
    hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20". Mandatory if One Score model is requested.`,
  },
  social_security_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Social security number",
    assignedTo: "",
    hint: `The individual's 9-digit social security number (SSN), formatted as a simple string of integers (e.g., “123456789”). Mandatory, if One Score model is requested.`,
  },
});

const getDefaultApplicant = (): InputMappingGroupT => ({
  getDefaultElements: getDefaultApplicantFields,
  elements: getDefaultApplicantFields(),
  rules: {
    required: true,
  },
  displayName: "Applicant",
});

const getDefaultAddressFields = (): { [key: string]: InputMappingT } => ({
  house_number: {
    id: uuidV4(),
    type: "text",
    displayName: "House number",
    assignedTo: "",
    hint: `1-10 characters`,
  },
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
    hint: `The address components are segmented into distinct fields. However, it is supported to submit the house number, street, and street type together in the "street" field. Mandatory if One Score model is requested. 1-26 characters.`,
  },
  street_type: {
    id: uuidV4(),
    type: "text",
    displayName: "Street type",
    assignedTo: "",
    hint: `Street name suffix describing the type of the road (ST, AV, RD) or direction (N, W, SE)`,
  },
  affix: {
    id: uuidV4(),
    type: "text",
    displayName: "Apartment number",
    assignedTo: "",
    hint: `Apartment/condo/unit number. 1-5 characters.`,
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `1-20 characters`,
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `A two-letter code for the U.S. state or territory of the address (e.g., "CA" for California)`,
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    hint: `The consumer's 5 or 9-digit ZIP code. Mandatory, if One Score model is requested.`,
  },
});

const getDefaultAddress = (): InputMappingGroupT => ({
  getDefaultElements: getDefaultAddressFields,
  elements: getDefaultAddressFields(),
  rules: {
    required: true,
  },
  displayName: "Address",
});

const getEquifaxUSInsights = (): { [key: string]: OutputMappingT } => ({
  retrieval_datetime: getNamedOutputMapping("Retrieval date-time (UTC)"),
  provider_request_id: getNamedOutputMapping("Provider request ID"),
});

const getEquifaxUSMLAReportInsights = (): {
  [key: string]: OutputMappingT;
} => ({
  is_borrower_covered: getNamedOutputMapping(
    "Is borrower covered",
    `Boolean value, indicating whether the consumer is covered by the MLA. A "False" value indicates that the consumer is either not covered or that the provided data was insufficient to determine the coverage status.`,
  ),
  is_insufficient_data: getNamedOutputMapping(
    "Is insufficient data",
    `Boolean value, indicating if the consumer data provided was not sufficient to determine if the consumer is covered by the MLA`,
  ),
  is_mla_report_error: getNamedOutputMapping(
    "Is MLA report error",
    `Boolean value, indicating whether there was a communication problem or an error with the Equifax request for MLA data`,
  ),
  status: getNamedOutputMapping(
    "Report status",
    `The status of the Equifax request, including any related transaction comments. When the status is "completed," it signifies that the request has been successfully processed.`,
  ),
});

const getEquifaxUSOneViewReportInsights = (): {
  [key: string]: OutputMappingT;
} => ({
  hit_code: getNamedOutputMapping(
    "Hit code",
    `A one-letter string that describes the outcome of a credit inquiry or credit report request made by a lender or authorized entity`,
  ),
  vantage_4_score: {
    ...getNamedOutputMapping("VantageScore 4.0 (model 05402)"),
    hint: `VantageScore 4.0 is a credit score that ranges from 300 to 850 and is used to assess a person's creditworthiness. A higher VantageScore indicates a better credit history, with a score above 700 generally considered good, and scores over 800 excellent.`,
  },
  vantage_4_score_05483: {
    ...getNamedOutputMapping("VantageScore 4.0 (model 05483)"),
    hint: `VantageScore 4.0 is a credit score that ranges from 300 to 850 and is used to assess a person's creditworthiness. A higher VantageScore indicates a better credit history, with a score above 700 generally considered good, and scores over 800 excellent.`,
  },
  vantage_3_score: {
    ...getNamedOutputMapping("VantageScore 3.0"),
    hint: `VantageScore 3.0 is an older version of the credit scoring model. It ranges from 300 to 850 and is used to assess a person's creditworthiness. A higher VantageScore indicates a better credit history, with a score above 700 generally considered good, and scores over 800 excellent. Note that VantageScore 3.0, while still in use, is not provided to new customers.`,
  },
  fraud_victim_indicator: getNamedOutputMapping(
    "Fraud victim indicator",
    `A one-letter string that specifies the presence and type of fraud alert set on the consumer's file. When no fraud alert is present, it is set to "None".
    Consumers or their representatives may request that an Initial Fraud Alert, Extended Fraud Alert, or, if the consumer is a member of the military on active duty, an Active Duty Alert be placed on their credit file. 
    A lender receiving a report containing an Initial Fraud Alert or Active Duty Alert may not grant credit without first determining the identity of the person making the request.`,
  ),
});

export const getDefaultEquifaxUSScoresAndAttributes =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "equifax_us",
      resource: "scores_and_attributes",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        customer_reference: {
          id: uuidV4(),
          type: "text",
          displayName: "Customer reference",
          assignedTo: "",
          hint: "Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.",
        },
      },
      grouped: {
        applicant: getDefaultApplicant(),
        applicant_address: getDefaultAddress(),
      },
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        one_score: {
          ...getNamedOutputMapping("OneScore"),
          hint: `Integer value for the OneScore multidata model (Equifax product code 5860)`,
        },
        credit_report: {
          ...getNamedOutputMapping("Credit report"),
          hint: `The list of credit models returned in addition to the multidata models when requested in the resource configuration`,
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultEquifaxUSMLAReport = (): IntegrationResourceT => ({
  providerResource: {
    provider: "equifax_us",
    resource: "mla_report",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      customer_reference: {
        id: uuidV4(),
        type: "text",
        displayName: "Customer reference",
        assignedTo: "",
        hint: "Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.",
      },
    },
    grouped: {
      applicant: getDefaultApplicant(),
      applicant_address: getDefaultAddress(),
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: { ...getEquifaxUSInsights(), ...getEquifaxUSMLAReportInsights() },
  },
  config: defaultResourceConfig,
});

export const getDefaultEquifaxUSOneViewReport = (): IntegrationResourceT => ({
  providerResource: {
    provider: "equifax_us",
    resource: "one_view_report",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      customer_reference: {
        id: uuidV4(),
        type: "text",
        displayName: "Customer reference",
        assignedTo: "",
        hint: "Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.",
      },
    },
    grouped: {
      applicant: getDefaultApplicant(),
      applicant_address: getDefaultAddress(),
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getEquifaxUSInsights(),
      ...getEquifaxUSOneViewReportInsights(),
    },
  },
  config: defaultResourceConfig,
});
