import { useFormContext } from "react-hook-form";

import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { SnowflakePrivateKey } from "src/connections/config/database/snowflake/SnowflakePrivateKeyField";
import { SnowflakeSecretField } from "src/connections/config/database/snowflake/SnowflakeSecretField";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import {
  Environment,
  SnowflakeConnectionConfigInputsT,
} from "src/connections/types";

type PropsT = {
  environment: Environment;
};

const privateKeyPlaceholder = `-----BEGIN PRIVATE KEY-----
XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
-----END PRIVATE KEY-----`;

export const SnowflakeKeyPairAuthFieldset: React.FC<PropsT> = ({
  environment,
}) => {
  const {
    formState: { errors },
  } = useFormContext<SnowflakeConnectionConfigInputsT>();
  const envErrors = errors?.[`${environment}Config`]?.["keyPairAuthConfig"];

  return (
    <FieldsetCard className="last:mb-0">
      <SnowflakeSecretField
        environment={environment}
        helpText="The username used to connect to the database"
        isErrored={isFieldErrored(envErrors, "username")}
        secretConfigField="keyPairAuthConfig.username"
        secretName="Username"
        isRequired
      />
      <SnowflakePrivateKey
        environment={environment}
        helpText="The private key used to connect to the database"
        placeholder={privateKeyPlaceholder}
        secretConfigField="keyPairAuthConfig.privateKey"
        secretName="Private key"
        isRequired
      />
      <SnowflakeSecretField
        environment={environment}
        helpText="The passphrase to decrypt the private key (if the key is encrypted)"
        isErrored={isFieldErrored(envErrors, "password")}
        secretConfigField="keyPairAuthConfig.privateKeyPassphrase"
        secretName="Private key passphrase"
      />
    </FieldsetCard>
  );
};
