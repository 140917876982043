export const MIN_MEANINGFUL_PATH_LENGTH = 5;
export const truncateMiddle = (str: string, len: number) => {
  if (str.length <= len) {
    return str;
  }
  const partSize = Math.floor((len - 1) / 2);
  const left = partSize;
  const right = len % 2 === 0 ? partSize + 1 : partSize;
  return str.length > len ? str.slice(0, left) + "…" + str.slice(-right) : str;
};

export const truncateUrl = (
  str: string | undefined,
  maxLength: number = 50,
) => {
  if (str == null) {
    return "";
  }
  const parsedUrl = new URL(str);
  const { hostname, pathname } = parsedUrl;

  // Ignore the root trailing slash if not provided in the original URL.
  // Note that for a base url without path and without trailing slash
  // (e.g., https://website.com), we have pathname=="/".
  let path = str.endsWith(pathname) ? pathname : "";
  let url = `${hostname}${path}`;

  if (url.length <= maxLength) {
    return url;
  }

  // min meaningful path length /a…b/
  if (pathname.length >= MIN_MEANINGFUL_PATH_LENGTH) {
    // truncate as minimum as possible to fall under maxLength
    const overflow = url.length - maxLength;
    const pathLength = pathname.length - overflow;
    path = truncateMiddle(pathname, Math.max(5, pathLength));
    url = `${hostname}${path}`;
  }

  // if still too long, truncate the middle of the hostname
  if (url.length > maxLength) {
    const toRemove = url.length - maxLength;
    const hostLength = hostname.length - toRemove;
    url = `${truncateMiddle(hostname, Math.max(5, hostLength))}${path}`;
  }
  return url;
};
