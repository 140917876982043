import {
  BigQuerySQLConnectionConfigurationBE,
  ConnectionConfiguration,
  ConnectionT,
  ConnectionCreateT,
  ConnectionConfigurationCreateT,
  BigQuerySQLConnectionDefaultAuthScope,
} from "src/api/connectApi/types";
import {
  findSecret,
  getDefaultSecret,
  setSecretEnv,
  filterUpdatedSecrets,
  markSecrets,
  filterSecretsByEnvironment,
} from "src/connections/model/common";
import {
  BigQuerySQLConnectionConfigT,
  BigQuerySQLConnectionConfigInputsT,
  KeyValuePairT,
} from "src/connections/types";

export const getBigQuerySQLConnectionConfigDefaultValues = (
  env: string | null,
): BigQuerySQLConnectionConfigT => ({
  scopes: [BigQuerySQLConnectionDefaultAuthScope],
  allowTestInvocations: false,
  authMethod: "service_account",
  serviceAccountConfig: {
    service_account_key: getDefaultSecret("service_account_key", env),
  },
});

export const getBigQuerySQLConnectionConfigInputsDefaultValues =
  (): BigQuerySQLConnectionConfigInputsT => ({
    name: "",
    enableNonProdConfigs: false,
    dataRetention: {
      value: 0,
      unit: "days",
    },
    productionConfig: getBigQuerySQLConnectionConfigDefaultValues(null),
    sandboxConfig: getBigQuerySQLConnectionConfigDefaultValues("sandbox"),
  });

const convertBEConnectionConfigurationToBigQuerySQLConnectionConfig = (
  config: ConnectionConfiguration,
  envSecrets: KeyValuePairT[],
  env: string | null,
): BigQuerySQLConnectionConfigT => {
  const BigQuerysConfig = config as BigQuerySQLConnectionConfigurationBE;
  return {
    scopes: BigQuerysConfig.scopes,
    allowTestInvocations: config.allow_test_invocations ?? false,
    authMethod: BigQuerysConfig.auth_method,
    serviceAccountConfig: {
      service_account_key:
        findSecret(envSecrets, "service_account_key") ??
        getDefaultSecret("service_account_key", env),
    },
  };
};

const convertBENonProdEnvConfigToBigQuerySQLConnectionConfigs = (
  nonProdEnvConfigs: Record<string, ConnectionConfiguration>,
  env: string,
  allSecrets: KeyValuePairT[],
): BigQuerySQLConnectionConfigT | null => {
  if (!nonProdEnvConfigs?.[env]) return null;
  const envSecrets = filterSecretsByEnvironment(allSecrets, env);
  return convertBEConnectionConfigurationToBigQuerySQLConnectionConfig(
    nonProdEnvConfigs[env],
    envSecrets,
    env,
  );
};

/**
 * Converts BE BigQuery connection configuration to FE format.
 * If the provider of the connection is not "bigquery", it throws an error.
 *
 * @param {ConnectionT} connection - The BigQuerySQL connection object in BE format.
 * @returns {BigQuerySQLConnectionConfigInputsT} - The converted BigQuerySQL connection object in FE format suitable to use with components.
 * @throws {Error} - Throws an error if the provider of the connection is not "bigquery".
 */
export const convertBEConnectionToBigQuerySQLConnectionConfigInputs = (
  connection: ConnectionT,
): BigQuerySQLConnectionConfigInputsT => {
  if (connection.provider !== "bigquery")
    throw new Error(
      `Invalid connection type: ${connection.provider} for BigQuery converter`,
    );

  const defaultValues = getBigQuerySQLConnectionConfigInputsDefaultValues();
  const nonProdEnvConfigs = connection.non_prod_env_configs;

  const allSecrets = markSecrets(connection.secrets) ?? [];
  // TODO: convert "null" to "production" value
  const prodSecrets = filterSecretsByEnvironment(allSecrets, null);
  return {
    ...defaultValues,
    name: connection.name,
    enableNonProdConfigs: connection.enable_non_prod_configs,
    dataRetention: connection.data_retention ?? defaultValues.dataRetention,
    productionConfig:
      convertBEConnectionConfigurationToBigQuerySQLConnectionConfig(
        connection.configuration,
        prodSecrets,
        null,
      ),
    sandboxConfig:
      nonProdEnvConfigs && nonProdEnvConfigs["sandbox"]
        ? convertBENonProdEnvConfigToBigQuerySQLConnectionConfigs(
            nonProdEnvConfigs,
            "sandbox",
            allSecrets,
          )
        : getBigQuerySQLConnectionConfigDefaultValues("sandbox"),
  };
};

const extractSecretsFromBigQuerySQLConnectionConfig = (
  config: BigQuerySQLConnectionConfigT,
  env: string | null,
): KeyValuePairT[] => {
  if (config.authMethod === "service_account") {
    return [setSecretEnv(config.serviceAccountConfig.service_account_key, env)];
  }
  return [];
};

const extractSecretsFromBigQuerySQLConnectionConfigInputs = (
  config: BigQuerySQLConnectionConfigInputsT,
): KeyValuePairT[] => {
  const secrets = extractSecretsFromBigQuerySQLConnectionConfig(
    config.productionConfig,
    null,
  );
  // Only include non-prod secrets if non-prod configs are enabled
  if (config.enableNonProdConfigs && config.sandboxConfig) {
    secrets.push(
      ...extractSecretsFromBigQuerySQLConnectionConfig(
        config.sandboxConfig,
        "sandbox",
      ),
    );
  }
  // Removing un-changed secrets, so that they are not sent to the backend
  return filterUpdatedSecrets(secrets);
};

const convertBigQuerySQLConnectionConfigToBEConnectionConfiguration = (
  config: BigQuerySQLConnectionConfigT,
): ConnectionConfigurationCreateT => {
  return {
    scopes: config.scopes,
    auth_method: config.authMethod,
    allow_test_invocations: config.allowTestInvocations,
  };
};

/**
 * Converts FE BigQuery connection object to BE format.
 *
 * @param {BigQuerySQLConnectionConfigInputsT} inputs - The BigQuery connection object in FE format.
 * @returns {ConnectionCreateT} - The BigQuery connection object in BE format suitable for sending POST request.
 */
export const convertBigQuerySQLConnectionConfigInputsToBEConnection = (
  inputs: BigQuerySQLConnectionConfigInputsT,
): ConnectionCreateT => {
  const secrets = extractSecretsFromBigQuerySQLConnectionConfigInputs(inputs);

  return {
    name: inputs.name,
    is_sandbox: false,
    provider: "bigquery",
    configuration:
      convertBigQuerySQLConnectionConfigToBEConnectionConfiguration(
        inputs.productionConfig,
      ),
    secrets,
    data_retention: inputs.dataRetention,
    enable_non_prod_configs: inputs.enableNonProdConfigs,
    non_prod_env_configs:
      inputs.enableNonProdConfigs && inputs.sandboxConfig
        ? {
            sandbox:
              convertBigQuerySQLConnectionConfigToBEConnectionConfiguration(
                inputs.sandboxConfig,
              ),
          }
        : {},
  };
};
