import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { ReactNode } from "react";
import { twJoin } from "tailwind-merge";

import {
  FixedPositionedDropdown,
  FixedPositionedDropdownPropsT,
  RenderValueFn,
} from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";

export const CardDropdown = ({
  elements,
  selected,
  onSelect,
  renderValue,
  width,
}: Pick<FixedPositionedDropdownPropsT<string>, "elements"> & {
  renderValue?: RenderValueFn<string>;
  width?: string;
  selected?: string;
  onSelect?: (key: string) => void;
}) => (
  <FixedPositionedDropdown
    elements={elements}
    placement="bottomLeft"
    renderButtonValue={(value) => (
      <div
        className={twJoin(
          "flex h-8 items-center justify-between gap-2 pl-4 pr-3 text-gray-800",
          width,
        )}
      >
        {value} <Icon color="text-gray-500" icon={faChevronDown} size="xs" />
      </div>
    )}
    renderValue={(item, close) => (
      <CardDropdownItem>
        {renderValue?.(item, close) ?? item.value}
      </CardDropdownItem>
    )}
    selected={selected}
    onSelect={onSelect}
  />
);

const CardDropdownItem: React.FC<{ children: ReactNode }> = ({ children }) => (
  <div className="px-4 py-3 font-inter-normal-13px">{children}</div>
);
