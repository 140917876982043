import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FieldErrorsT } from "src/api/types";
import { ClauseBlock, ClauseFormT } from "src/clauseBlock/ClauseBlock";
import { Junctions } from "src/clients/flow-api";
import {
  InboundWebhookConnectionConfigInputsT,
  ConnectionCreationFieldError,
} from "src/connections/types";
import { convertFieldErrorsConnectToFe } from "src/utils/FieldErrorUtils";

type PropsT = {
  resourceIndex: number;
  fieldErrors?: ConnectionCreationFieldError[];
};

export const InboundWebhookActivationConditionForm: React.FC<PropsT> = ({
  resourceIndex,
  fieldErrors,
}) => {
  const { control, setValue } =
    useFormContext<InboundWebhookConnectionConfigInputsT>();

  const key = `resourceConfigs.${resourceIndex}.activation` as const;

  const handleChange = (value: ClauseFormT) => {
    setValue(key, { clauses: value.clauses, junction: value.junction });
  };

  const convertedFieldErrors: FieldErrorsT | undefined = fieldErrors
    ? convertFieldErrorsConnectToFe(fieldErrors)
    : undefined;

  return (
    <Controller
      control={control}
      name={key}
      render={({ field }) => (
        <ClauseBlock
          clauses={field.value?.clauses || []}
          fieldErrors={convertedFieldErrors}
          fieldPlaceholder="e.g. webhook.body.status"
          immutable={false}
          junction={field.value?.junction || Junctions.AND}
          junctionWidth="w-16"
          prefixClassName="w-20"
          valuePlaceholder='e.g. "Successful"'
          allowDeletingAllClauses
          onChange={handleChange}
        />
      )}
    />
  );
};
