import SentiLinkIcon from "src/assets/SentiLinkIcon.svg?react";
import {
  getDefaultSentiLinkFacets,
  getDefaultSentiLinkIDComplete,
  getDefaultSentiLinkIdentityTheftScore,
  getDefaultSentiLinkInsightsReport,
  getDefaultSentiLinkSyntheticFraudScore,
} from "src/connections/connectionConfigs/sentiLink/sentiLinkResources";
import { SentiLinkTemplate } from "src/connections/connectionConfigs/sentiLink/sentiLinkTemplate";
import { ConnectionConfig } from "src/connections/connectionConfigs/types";

export const SENTILINK_CONFIG: ConnectionConfig<"sentilink"> = {
  icon: SentiLinkIcon,
  description: "Your partner in solving emerging threats",
  template: SentiLinkTemplate,
  name: "SentiLink",
  resources: {
    sentilink_synthetic_fraud_score: {
      externalLink:
        "https://www.notion.so/taktile/Synthetic-Fraud-Score-69036e2ef8874db7991cb303a0e1e7d8?pvs=4",
      integrationResource: getDefaultSentiLinkSyntheticFraudScore,
    },
    sentilink_identity_theft_score: {
      externalLink:
        "https://www.notion.so/taktile/Identity-Theft-Score-b9af9fd5c70343b9ae343fbec32c8c5e?pvs=4",
      integrationResource: getDefaultSentiLinkIdentityTheftScore,
    },
    sentilink_facets: {
      externalLink:
        "https://www.notion.so/taktile/Facets-7b587c9ffbb84ef1905c7c0dd1304e6a?pvs=4",
      integrationResource: getDefaultSentiLinkFacets,
    },
    sentilink_id_complete: {
      externalLink:
        "https://www.notion.so/taktile/ID-Complete-253d3d323a7347288ee3fd5c01ffadb7?pvs=4",
      integrationResource: getDefaultSentiLinkIDComplete,
    },
    sentilink_insights_report: {
      externalLink:
        "https://www.notion.so/taktile/Insights-Report-2cb9b0f0e92f42739008086c1b5b5223?pvs=4",
      integrationResource: getDefaultSentiLinkInsightsReport,
    },
  },
};
