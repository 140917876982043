import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";

import {
  createFlowVersion,
  duplicateFlowVersion,
  flowVersionV2ToFlowVersionT,
  loadVersion,
} from "src/api";
import { versionsApi } from "src/api/endpoints";
import { FlowVersionT, NodeBET, SchemaT } from "src/api/flowTypes";
import {
  archiveFlowVersion,
  DEFAULT_VERSION,
  FlowVersionGraphUpdate,
  notifyUI,
  Operation,
  publishFlowVersion,
  updateFlowVersion,
  updateFlowVersionGraph,
  updateSchema,
  upsertResourceLock,
  UpsertResourceLockArgs,
} from "src/api/flowVersionUpdateIndex";
import { flowKeys } from "src/api/queries";
import { NodeDb, Parameter } from "src/clients/flow-api";
import { DatasetMockableNodes } from "src/constants/NodeDataTypes";
import { queryClient } from "src/queryClient";
import { GraphTransform } from "src/utils/GraphUtils";

export const flowVersionKeys = {
  all: ["flowVersions"] as const,
  detail: (id: string) => [...flowVersionKeys.all, id] as const,
  mockableNodes: (id: string, nodeId: string) =>
    [...flowVersionKeys.detail(id), "mockableNodes", nodeId] as const,
};

export const useFlowVersion = (
  flowVersionId?: string,
  refetchOnMount: boolean = false,
) => {
  return useQuery<FlowVersionT, AxiosError>(
    flowVersionKeys.detail(flowVersionId!),
    async () => {
      // When  we get a new version, we check our cache don't already contain a
      // more recent version
      // We do this to enforce monotonic updates only. When the server is under load
      // some requests may be delayed, which can an old poll might be received
      // after a newer one. We use etag to ensure our cache has the latest known state
      const cachedVersion: FlowVersionT | undefined = queryClient.getQueryData(
        flowVersionKeys.detail(flowVersionId!),
      );
      const remoteVersion = await loadVersion(flowVersionId!, {
        cached: cachedVersion,
      });
      if (
        !cachedVersion ||
        (remoteVersion.etag ?? DEFAULT_VERSION) >
          (cachedVersion.etag ?? DEFAULT_VERSION)
      ) {
        return remoteVersion;
      } else {
        return cachedVersion;
      }
    },
    {
      refetchInterval: 5 * 1_000,
      refetchOnMount,
      // with background polling on we don't want to cause change
      // notifications on "isFetching" status changes
      notifyOnChangeProps: ["data", "error"],
      enabled: !!flowVersionId,
    },
  );
};

export const useFlowVersionMockableNodes = (
  flowVersionIdByNodeIds: { nodeId: string; flowVersionId: string }[],
) => {
  const [stableData, setStableData] = useState<
    Record<string, DatasetMockableNodes[]>
  >({});

  useQueries({
    queries: flowVersionIdByNodeIds.map(({ nodeId, flowVersionId }) => ({
      queryKey: flowVersionKeys.mockableNodes(flowVersionId, nodeId),
      queryFn: async () => {
        const response =
          await versionsApi.getFlowVersionMockableNodesApiV2FlowVersionsFlowVersionIdMockableNodesGet(
            flowVersionId,
          );
        return response.data;
      },
      onSuccess: (data: NodeDb[]) => {
        setStableData((prev) => ({
          ...prev,
          [nodeId]: data.map(
            (nodeDb) =>
              GraphTransform.nodeMapper(
                nodeDb as NodeBET,
              ) as DatasetMockableNodes,
          ),
        }));
      },
    })),
  });

  return stableData;
};

export const useAddFlowVersion = () => {
  const queryClient = useQueryClient();
  return useMutation(createFlowVersion, {
    onSuccess: () => {
      queryClient.invalidateQueries(flowKeys.all);
    },
  });
};

export const useDuplicateFlowVersion = () => {
  const queryClient = useQueryClient();
  return useMutation(duplicateFlowVersion, {
    onSuccess: () => {
      queryClient.invalidateQueries(flowKeys.all);
    },
  });
};

export const useDeleteFlowVersion = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (flowVersionId: string) =>
      versionsApi.deleteFlowVersionApiV1FlowVersionsIdDelete(flowVersionId),
    {
      onSuccess: async (_, versionId) => {
        await queryClient.invalidateQueries(flowKeys.all);
        await queryClient.invalidateQueries(flowVersionKeys.all);
        queryClient.removeQueries(flowVersionKeys.detail(versionId));
      },
    },
  );
};

export type EditFlowVersionT = {
  version: FlowVersionT;
  name?: string;
  description?: string;
};

export type PublishFlowVersionT = {
  version: FlowVersionT;
  name: string;
  release_note: string;
  published_by_id: string;
  published_at: string;
};

export const usePublishFlowVersion = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      publishArgs,
      enableTrafficPolicies,
    }: {
      publishArgs: PublishFlowVersionT;
      enableTrafficPolicies: boolean;
    }) => publishFlowVersion(publishArgs, enableTrafficPolicies),
    {
      onSuccess: (returnData) => {
        queryClient.invalidateQueries(flowKeys.all);
        queryClient.setQueryData(
          flowVersionKeys.detail(returnData?.id),
          returnData,
        );
      },
    },
  );
};

export type ArchiveFlowVersionT = {
  version: FlowVersionT;
  archived_by_id: string;
  archived_at: string;
};

export const useArchiveFlowVersion = () => {
  const queryClient = useQueryClient();
  return useMutation(archiveFlowVersion, {
    onSuccess: (returnData) => {
      queryClient.invalidateQueries(flowKeys.all);
      queryClient.setQueryData(
        flowVersionKeys.detail(returnData?.id),
        returnData,
      );
    },
  });
};

export const useEditFlowVersion = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (editVersion: EditFlowVersionT) =>
      updateFlowVersion(editVersion.version.id, {
        ...(editVersion.name !== undefined && { name: editVersion.name }),
        ...(editVersion.description !== undefined && {
          meta: {
            ...editVersion.version.meta,
            release_note: editVersion.description,
          },
        }),
        operation: Operation.VERSION_METADATA_EDITED,
      }),
    {
      onSuccess: (returnData) => {
        queryClient.invalidateQueries(flowKeys.all);
        queryClient.setQueryData(
          flowVersionKeys.detail(returnData.id),
          returnData,
        );
      },
    },
  );
};

export type RestoreFlowVersionProps = {
  versionId: string;
  etag: string;
};

export const useRestoreFlowVersion = () => {
  return useMutation(
    (restoreFlowVersionParams: RestoreFlowVersionProps) =>
      versionsApi.putFlowVersionApiV2FlowVersionsIdPut(
        restoreFlowVersionParams.versionId,
        restoreFlowVersionParams.etag,
      ),
    {
      onSuccess: (flowVersion) => {
        const flowVersionT: FlowVersionT = flowVersionV2ToFlowVersionT(
          flowVersion.data,
        );
        notifyUI(flowVersionT);
      },
    },
  );
};

export const useUpdateParameters = (
  flowVersionId: string,
  onSuccess: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (parameters: Parameter[]) =>
      updateFlowVersion(flowVersionId, {
        parameters: parameters,
        operation: Operation.EDIT_PARAMETERS,
      }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(flowVersionKeys.detail(flowVersionId), data);
        onSuccess();
      },
    },
  );
};

export type SchemaUpdateT = {
  schema: SchemaT;
  type: "input" | "output";
};

export const useUpdateSchema = (
  flowVersionId: string,
  onSuccess: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (schemaUpdate: SchemaUpdateT) => updateSchema(flowVersionId, schemaUpdate),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(flowVersionKeys.detail(flowVersionId), data);
        onSuccess();
      },
    },
  );
};

export const useUpdateFlowVersionGraph = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (update: FlowVersionGraphUpdate) => updateFlowVersionGraph(update),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(flowVersionKeys.detail(data.id), data);
      },
    },
  );
};

export const usePatchResourceLocks = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (args: UpsertResourceLockArgs) => upsertResourceLock(args),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(flowVersionKeys.detail(data.id), data);
      },
    },
  );
};
