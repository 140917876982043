import { useMemo } from "react";

import { ErroredRow } from "src/api/types";
import { NodeRunStateV2 } from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { ResultDataAndAuxRowV2 } from "src/dataTable/types";
import { TestRunDebugRowDataFailure } from "src/databaseDebugPopover/types";
import { useGraphStore } from "src/store/StoreProvider";
import { useNodeRunStates } from "src/store/runState/RunState";
import { isLoopNode } from "src/utils/predicates";

export const displayedErroredRowToRowData = (
  displayedErroredRow: ErroredRow,
): TestRunDebugRowDataFailure => ({
  type: "failure",
  nodeExecutionMetadata: displayedErroredRow.node_execution_metadata,
  data: displayedErroredRow.data,
  errorOriginNodeId: displayedErroredRow.node_id,
  errorMessage: "",
  index: displayedErroredRow.index,
  provenance: displayedErroredRow.provenance,
});

export const displayedErroredRowToResultData = (
  displayedErroredRow: ErroredRow,
): ResultDataAndAuxRowV2 => {
  const rowData = displayedErroredRowToRowData(displayedErroredRow);

  return {
    ...rowData,
    errorOriginNodeId: rowData.errorOriginNodeId ?? undefined,
    auxData: displayedErroredRow.aux_data,
  };
};

const getNodeDataColumns = (
  runState: NodeRunStateV2 | undefined,
  nodeId: string,
) => {
  if (runState?.type === "test-run") {
    return runState.testResult.data_columns;
  }

  return runState?.nodeExecutionMetadata?.[nodeId]?.updated_fields ?? [];
};

export const useFieldToSourceNodeMappings = () => {
  const { databaseNodes, getParents, selectedNode } = useGraphStore();

  const allRunStates = useNodeRunStates();

  const { fieldNameToSourceNode, sourceNodeToFieldNames } = useMemo(() => {
    const fieldNameToSourceNode: Map<string, { id: string; type: NODE_TYPE }> =
      new Map();
    const sourceNodeToFieldNames: Map<string, string[]> = new Map();

    if (databaseNodes.length > 0) {
      for (const dbNode of databaseNodes) {
        const runState = allRunStates.get(dbNode.id);
        const dataColumns = new Set<string>(
          getNodeDataColumns(runState, dbNode.id),
        );
        const parentNodes = getParents(dbNode.id) || [];
        parentNodes.forEach((parentNode) => {
          const parentNodeRunState = allRunStates.get(parentNode.id);
          getNodeDataColumns(parentNodeRunState, parentNode.id).forEach((col) =>
            dataColumns.delete(col),
          );
        });

        dataColumns.forEach((col) => {
          fieldNameToSourceNode.set(col, { id: dbNode.id, type: dbNode.type });
        });

        sourceNodeToFieldNames.set(
          dbNode.id,
          Array.from(dataColumns) as string[],
        );
      }
    }

    if (selectedNode && isLoopNode(selectedNode)) {
      const { data } = selectedNode;
      if (data.output_destination_list_expression?.value) {
        fieldNameToSourceNode.set(
          data.output_destination_list_expression.value,
          { id: selectedNode.id, type: selectedNode.type },
        );
        sourceNodeToFieldNames.set(selectedNode.id, [
          data.output_destination_list_expression.value,
        ]);
      }
    }

    return { fieldNameToSourceNode, sourceNodeToFieldNames };
  }, [databaseNodes, selectedNode, allRunStates, getParents]);
  return { fieldNameToSourceNode, sourceNodeToFieldNames };
};
