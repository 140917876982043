import { FlowVersionT } from "src/api/flowTypes";
import { COLORS, COLORS_LIGHT } from "src/utils/constants";

type ColorObject = { dark: string; light: string };
export type VersionColors = Record<string, ColorObject>;

export const getVersionsColors = (versions: FlowVersionT[]) => {
  return versions
    .sort(
      (a, b) =>
        new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf(),
    )
    .reduce((acc, v, i) => {
      acc[v.id] = {
        dark: COLORS[i % COLORS.length],
        light: COLORS_LIGHT[i % COLORS_LIGHT.length],
      };
      return acc;
    }, {} as VersionColors);
};
