import { ColumnDef, OnChangeFn, VisibilityState } from "@tanstack/react-table";
import { isFunction } from "lodash";
import { useCallback } from "react";
import { useLocalStorage } from "usehooks-ts";

import { RESULT_COLUMN_PREFIX } from "src/dataTable/TablePrefixes";
import { ResultDataAndAuxRowV2 } from "src/dataTable/types";
import { useAuthoringContext } from "src/router/routerContextHooks";

type Columns = ColumnDef<ResultDataAndAuxRowV2, string>[];

const getDefaultColumnVisibility = (columns: Columns) =>
  Object.fromEntries(
    columns
      .filter((column) => column.enableHiding !== false)
      .map((column) => [
        column.id,
        column.id?.startsWith(RESULT_COLUMN_PREFIX),
      ]),
  );

export const usePersistedVisibleColumns = (
  columns: Columns,
  datasetId: string,
) => {
  const { version } = useAuthoringContext();
  const [visibleColumns, setVisibleColumns] = useLocalStorage<
    Record<string, VisibilityState>
  >("columnVisibility", {});
  const identifier = `${version.id}|${datasetId}`;

  const columnVisibility =
    visibleColumns[identifier] ?? getDefaultColumnVisibility(columns);

  const setColumnVisibility: OnChangeFn<VisibilityState> = useCallback(
    (stateOrUpdater) => {
      setVisibleColumns((prev) => ({
        ...prev,
        [identifier]: isFunction(stateOrUpdater)
          ? stateOrUpdater(columnVisibility)
          : stateOrUpdater,
      }));
    },
    [setVisibleColumns, identifier, columnVisibility],
  );

  return [columnVisibility, setColumnVisibility] as const;
};
