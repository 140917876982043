import { ReactNode } from "react";

import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";

export const Label: React.FC<{
  children?: ReactNode;
  required?: boolean;
  mb?: `mb-${number}`;
}> = ({ children, required, mb = "mb-2" }) => (
  <label
    className={`inline-block text-gray-800 font-inter-semibold-13px ${mb}`}
  >
    {children} {required && <RequiredAsterisk />}
  </label>
);
