import {
  ConnectionResourceTemplateConfigKey,
  makeConnectionTemplate,
} from "src/connections/types";

const getDefaultExperianColombiaResourceConfigKeys =
  (): ConnectionResourceTemplateConfigKey[] => [
    {
      key: "query_key",
      name: "Product key (clave)",
      required: true,
      type: "text",
      hint: "Provided by DataCrédito (Experian Colombia)",
    },
    {
      key: "client_id",
      name: "Subscriber code (usuario)",
      required: true,
      type: "text",
      hint: "Provided by DataCrédito (Experian Colombia)",
    },
  ];

export const ExperianColombiaTemplate = makeConnectionTemplate({
  provider: "experian_colombia",
  secrets: [
    {
      key: "certificate",
      name: "SSL certificate",
      required: true,
      type: "multiline",
      hint: 'Generated by the customer, and shared with DataCrédito (Experian Colombia). Please include the "BEGIN" and "END" lines.',
    },
    {
      key: "private_key",
      name: "SSL private key",
      required: true,
      type: "multiline",
      hint: 'Generated by the customer, and shared with DataCrédito (Experian Colombia). Please include the "BEGIN" and "END" lines.',
    },
    {
      key: "username",
      name: "Username",
      required: true,
      hint: "Provided by DataCrédito (Experian Colombia)",
    },
    {
      key: "password",
      name: "Password",
      required: true,
      hint: "Provided by DataCrédito (Experian Colombia)",
    },
  ],
  resources: {
    experian_colombia_credit_history_for_legal_entities: {
      name: "Credit History for Legal Entities (Historia de Crédito+ PJ)",
      configKeys: getDefaultExperianColombiaResourceConfigKeys(),
    },
    experian_colombia_chamber_of_commerce: {
      name: "Chamber of Commerce (Cámara de Comercio)",
      configKeys: getDefaultExperianColombiaResourceConfigKeys(),
    },
  },
});
