import React from "react";
import hotToast from "react-hot-toast";
import reactToText from "react-to-text";

import { Toast } from "src/base-components/Toast/Toast";
import {
  NotificationVariant,
  ToastParams,
} from "src/base-components/Toast/types";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const CONTAINER_CLASS_NAMES = "ml-12 mb-12";
export const DEFAULT_TOAST_DURATION_MS = 4000;

type TrackNotificationArgs = {
  variant: NotificationVariant;
  title: string;
  description?: React.ReactNode;
};

export const useTrackNotification = ({
  variant,
  title,
  description,
}: TrackNotificationArgs) => {
  const { orgId } = useParamsDecode<{ orgId?: string }>();

  React.useEffect(() => {
    const popUpDescription = reactToText(description);
    tracker.emit(
      trackingEvents.authoringPopupNotification({
        organization_id: orgId ? orgId : "unknown-org",
        type: variant,
        message: popUpDescription,
        title: title,
      }),
    );
  }, [orgId, variant, description, title]);
};

export const createToast =
  (variant: NotificationVariant) =>
  ({ id, duration, withSidebar, ...params }: ToastParams): string =>
    hotToast.custom(
      (t) => <Toast toastInstance={t} variant={variant} {...params} />,
      {
        id: id,
        duration:
          duration === null || params.dismissible === false
            ? Infinity
            : (duration ?? DEFAULT_TOAST_DURATION_MS),
        position: "bottom-left",
        className: withSidebar ? "ml-[166px]" : "",
      },
    );

export const toastSuccess = createToast("success");
export const toastFailure = createToast("error");
export const toastLoading = createToast("loading");
export const toast = createToast("default");

export const dismissToast = (id: string) => hotToast.dismiss(id);

export const TAKTILE_TEAM_NOTIFIED = "The Taktile team has been notified.";
export const DATASET_JOB_TIMEOUT =
  "We couldn't process this dataset under 15 minutes. Please try again.";
