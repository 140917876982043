import { useFormContext, Controller } from "react-hook-form";

import { MySQLConnectionAuthMethod } from "src/api/connectApi/types";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { SimpleDropDown } from "src/base-components/SimpleDropDown";
import { MySQLUsernamePasswordAuthFieldset } from "src/connections/config/database/mysql/MySQLUsernamePasswordAuthFieldset";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { TestInvocationToggle } from "src/connections/config/shared/TestInvocationToggle";
import { errorProps } from "src/connections/config/shared/errorProps";
import {
  MySQLConnectionConfigInputsT,
  Environment,
} from "src/connections/types";

type MySQLAuthTypeSelectElement = {
  key: MySQLConnectionAuthMethod;
  value: string;
};

const authMethods: MySQLAuthTypeSelectElement[] = [
  { key: "username_password", value: "Username + Password" },
];

const renderAuthFields = (
  authMethod: MySQLConnectionAuthMethod,
  environment: Environment,
) => {
  if (authMethod === "username_password") {
    return <MySQLUsernamePasswordAuthFieldset environment={environment} />;
  }
};

type EnvironmentConfigPropsT = {
  environment: Environment;
  labelsPrefix: string;
};

export const MySQLEnvironmentConfig: React.FC<EnvironmentConfigPropsT> = ({
  environment,
  labelsPrefix,
}) => {
  const {
    formState: { errors },
    register,
    watch,
    control,
  } = useFormContext<MySQLConnectionConfigInputsT>();
  const envPrefix = `${environment}Config` as const;
  const envErrors = errors[envPrefix];
  const enableNonProdConfigs = watch("enableNonProdConfigs");
  const authMethod = watch(`${envPrefix}.authMethod`);
  return (
    <>
      <FormItem
        gap="sm"
        helpTooltip={
          <div>
            The name of the MySQL database to connect to. When in doubt, ask
            your organization's database administrator.
          </div>
        }
        label={labelsPrefix ? `${labelsPrefix} database name` : "Database name"}
        isRequired
      >
        {isFieldErrored(envErrors, "databaseName") && (
          <ErrorHint>{envErrors?.databaseName?.message}</ErrorHint>
        )}
        <Input
          data-loc={`${environment}-mysql-connection-database-name`}
          placeholder="e.g. mysql"
          fullWidth
          {...errorProps(isFieldErrored(envErrors, "databaseName"))}
          {...register(`${envPrefix}.databaseName`, {
            required: "Database name is required",
          })}
        />
      </FormItem>
      <FormItem
        gap="sm"
        helpTooltip={
          <div>
            The address (hostname or IP) of the server where your MySQL database
            is hosted. When in doubt, ask your organization's database
            administrator.
          </div>
        }
        label={labelsPrefix ? `${labelsPrefix} host` : "Host"}
        isRequired
      >
        {isFieldErrored(envErrors, "host") && (
          <ErrorHint>{envErrors?.host?.message}</ErrorHint>
        )}
        <Input
          data-loc={`${environment}-mysql-connection-host`}
          placeholder="e.g. mycluster.cluster-123456789012.us-east-1.rds.amazonaws.com"
          fullWidth
          {...errorProps(isFieldErrored(envErrors, "host"))}
          {...register(`${envPrefix}.host`, {
            required: "Database host is required",
          })}
        />
      </FormItem>
      <FormItem
        gap="sm"
        helpTooltip={
          <div>
            The port number through which the Database Connector will
            communicate with the MySQL database. If left blank, this setting
            defaults to 3306.
          </div>
        }
        label={labelsPrefix ? `${labelsPrefix} port` : "Port"}
      >
        {isFieldErrored(envErrors, "port") && (
          <ErrorHint>{envErrors?.port?.message}</ErrorHint>
        )}
        <Input
          data-loc={`${environment}-mysql-connection-port`}
          placeholder="e.g. 3306"
          type="number"
          fullWidth
          {...errorProps(isFieldErrored(envErrors, "port"))}
          {...register(`${envPrefix}.port`)}
        />
      </FormItem>
      <FormItem
        description="The authentication mechanism required for the database"
        gap="sm"
        label="Select authentication type"
        isRequired
      >
        <Controller
          control={control}
          name={`${envPrefix}.authMethod`}
          render={(props) => (
            <SimpleDropDown
              buttonClassName="pl-3"
              buttonDataLoc={`${environment}-mysql-connection-auth-type`}
              className="h-8 w-full"
              elements={authMethods}
              itemsClassNames="w-full"
              itemsWidth="w-full"
              placeholder="Select authentication type"
              placement="bottomLeft"
              selectedKey={String(props.field.value)}
              onSelect={(value) => {
                props.field.onChange(value);
              }}
            />
          )}
        />
      </FormItem>
      {renderAuthFields(authMethod, environment)}
      {environment === "production" && (
        <TestInvocationToggle<MySQLConnectionConfigInputsT>
          control={control}
          enableNonProdConfigs={enableNonProdConfigs}
          type="database"
        />
      )}
    </>
  );
};
