import { LOCK_NODE_EDITOR_CLASSNAME } from "src/authoringMultiplayerLock/constants";
import { Select } from "src/base-components/Select";
import { DATA_RETENTION_UNITS } from "src/constants/workspace";

export const DataRetentionUnitDropdown: React.FC<{
  dataLoc?: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}> = ({ value, onChange, disabled, dataLoc }) => (
  <Select
    dataLoc={dataLoc}
    disabled={disabled}
    dropdownClassName={LOCK_NODE_EDITOR_CLASSNAME}
    options={DATA_RETENTION_UNITS.map((v) => ({
      key: v,
      value: v,
    }))}
    value={value}
    onChange={onChange}
  />
);
