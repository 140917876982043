import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import React, { useCallback, useState } from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { CommentDb, CommentResourceType } from "src/clients/flow-api";
import { CommentFilterBar } from "src/comments/CommentFilterBar";
import { CommentList } from "src/comments/CommentList";
import { useComments } from "src/comments/queries";
import { rightSidePaneWidthClassname } from "src/flowContainer/RightPane";
import { AuthorPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

export type CommentFiltersNode = {
  show: "all" | "open" | "resolved";
  filterTerm: string;
};

export type CommentFilters = CommentFiltersNode & {
  includeNodeLevel?: boolean;
};

export type OnFilterChange = (filters: Partial<CommentFilters>) => void;

type Props = {
  onClose: () => void;
};

type ContentProps = Props & {
  commentsResult: UseInfiniteQueryResult<CommentDb[], Error>;
  filters: CommentFilters;
  onFilterChange: OnFilterChange;
};

const Content: React.FC<ContentProps> = ({
  commentsResult,
  onClose,
  filters,
  onFilterChange,
}) => {
  const { version_id } = useParamsDecode<AuthorPageParamsT>();

  return (
    <div
      className={twJoin(
        "flex max-h-full flex-col overflow-hidden bg-white px-6 py-4 pt-6 shadow-lg",
        rightSidePaneWidthClassname,
      )}
    >
      <div className="flex justify-between gap-x-1 border-b border-gray-200 pb-4">
        <h2
          className="text-gray-800 font-inter-semibold-16px"
          data-loc="comments-heading"
        >
          Comments
        </h2>
        <Icon
          color="text-gray-500 hover:text-gray-700"
          dataLoc="close-node-editor"
          icon={faXmark}
          size="xs"
          onClick={onClose}
        />
      </div>
      <CommentFilterBar filters={filters} onFilterChange={onFilterChange} />
      <LoadingView
        queryResult={commentsResult}
        renderUpdated={(data: InfiniteData<CommentDb[]>) => (
          <CommentList
            comments={data.pages.flatMap((page) => page)}
            resourceId={version_id}
            resourceType={CommentResourceType.FLOW_VERSION}
            onLoadMore={
              commentsResult.hasNextPage
                ? () => commentsResult.fetchNextPage()
                : undefined
            }
          />
        )}
      />
    </div>
  );
};

export const getResolved = (filters: CommentFilters) => {
  return filters.show === "open"
    ? false
    : filters.show === "resolved"
      ? true
      : undefined;
};

export const getSearch = (filters: CommentFilters) => {
  return filters.filterTerm.length <= 2 ? undefined : filters.filterTerm;
};

export const CommentsPane: React.FC<Props> = ({ onClose }) => {
  const { version_id } = useParamsDecode<AuthorPageParamsT>();
  const [filters, setFilters] = useState<CommentFilters>({
    show: "open",
    filterTerm: "",
    includeNodeLevel: false,
  });
  const commentsResult = useComments(
    version_id,
    filters.includeNodeLevel ? undefined : CommentResourceType.FLOW_VERSION,
    filters.includeNodeLevel ? undefined : version_id,
    getResolved(filters),
    getSearch(filters),
  );

  const onFilterChange: OnFilterChange = useCallback(
    (filters) => {
      setFilters((oldFilters) => ({ ...oldFilters, ...filters }));
    },
    [setFilters],
  );

  return (
    <>
      <Content
        commentsResult={commentsResult}
        filters={filters}
        onClose={onClose}
        onFilterChange={onFilterChange}
      />
    </>
  );
};
