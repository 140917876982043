import { useAuthoringContext } from "src/router/routerContextHooks";
import { useCurrentUserId } from "src/store/AuthStore";
import { useGraphStore } from "src/store/StoreProvider";

/**
 * Check if any of the current selected nodes is locked by another user.
 * @returns true if lock is on one or more of the selected nodes
 */
export const useSelectedNodesLockedByOtherUser = () => {
  const { version } = useAuthoringContext();
  const { selectedNodes } = useGraphStore();
  const userId = useCurrentUserId();

  const nodeIds = selectedNodes.map((node) => node.id);
  return version.locks?.some(
    (lock) => lock.owner !== userId && nodeIds.includes(lock.resource_id),
  );
};
