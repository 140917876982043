import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { v4 } from "uuid";

import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/base-components/Tooltip";
import { RuleNodeV2FormT } from "src/ruleNodeV2Editor/types";

type PropsT = {
  branchIndex: number;
  index: number;
  name: string;
  immutable: boolean;
  fieldError: string | undefined;
};

export const BranchEffect: React.FC<PropsT> = ({
  branchIndex,
  index,
  name,
  immutable,
  fieldError,
}) => {
  const { control, register } = useFormContext<RuleNodeV2FormT>();

  return (
    <div className="flex items-center gap-x-2 text-xs text-gray-700">
      <span className="font-inter-medium-12px">Then set value of</span>
      <Tooltip placement="top" title={`data.${name}`}>
        <div className="max-w-50">
          <Pill variant="green" fullWidth>
            <Pill.Text>data.{name}</Pill.Text>
          </Pill>
        </div>
      </Tooltip>
      <span className="font-inter-medium-12px">to</span>
      <input
        defaultValue={v4()}
        type="hidden"
        {...register(`branches.${branchIndex}.effects.${index}.id`)}
      />
      <Controller
        control={control}
        defaultValue=""
        name={`branches.${branchIndex}.effects.${index}.value`}
        render={({ field }) => (
          <AutocompleteCodeInput
            dataLoc={`rule-node-v2-branches-${branchIndex}-effects-${index}-value`}
            disabled={immutable}
            error={fieldError}
            placeholder='Strings should be quoted, e.g. "Decide"'
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </div>
  );
};
