import { useFormContext } from "react-hook-form";

import { Checkbox } from "src/base-components/Checkbox";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { MonospacedInput } from "src/base-components/MonospacedInput";
import { Textarea } from "src/base-components/Textarea";
import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { KeyValuePairField } from "src/connections/config/KeyValuePairField";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { JSONPretty } from "src/connections/model/model";
import {
  AvailableEnvironmentPrefixes,
  ConnectionConfigInputsT,
} from "src/connections/types";

const { Label } = FormItem;

const authorizeBodyPlaceholder = `e.g.: ${JSONPretty({
  client_id: "$client_id",
  client_secret: "$client_secret",
  grant_type: "client_credentials",
})}`;

const refreshBodyPlaceholder = `e.g.: ${JSONPretty({
  refresh_token: "$refresh_token",
  grant_type: "client_credentials",
})}`;

type PropsT = {
  environmentPrefix: AvailableEnvironmentPrefixes;
};

const TokenPathExtractionHelpText: React.FC = () => {
  return (
    <>
      <div>A JSON path to retrieve the access token from the API response.</div>
      <p>
        For example, if the authorization response is:
        <div>
          <code className="whitespace-pre">
            {`{
  "body": {
    "token": "xyz"
  }
}`}
          </code>
        </div>{" "}
        Then your path should be <code className="whitespace-pre">token</code>.
      </p>
      <div>
        The path <code className="whitespace-pre">access_token</code> is used if
        not specified.
      </div>
    </>
  );
};

const ExpirationPathExtractionHelpText: React.FC = () => {
  return (
    <>
      <div>
        A JSON path to extract the token's expiration time from the API
        response.
      </div>
      <div>
        For example, if the authorization response is:
        <div>
          <code className="whitespace-pre">
            {`{
  "body": {
    "expiration_time": 3600
  }
}`}
          </code>
        </div>{" "}
        Then your path should be{" "}
        <code className="whitespace-pre">expiration_time</code>.
      </div>
      <div>
        The path <code className="whitespace-pre">expires_in</code> is used if
        not specified.
      </div>
      <div>
        The field should contain the lifetime of the access token in seconds.
      </div>
    </>
  );
};

export const OAuth2Fieldset: React.FC<PropsT> = ({ environmentPrefix }) => {
  const {
    formState: { errors: formErrors },
    register,
    watch,
  } = useFormContext<ConnectionConfigInputsT>();

  const usesBasicAuth = watch(
    `${environmentPrefix}oauth2Config.uses_basic_auth`,
  );

  // Change this once we support multiple environments
  const errors =
    environmentPrefix === ""
      ? formErrors?.oauth2Config
      : formErrors.nonProdEnvConfigs?.sandbox?.oauth2Config;

  return (
    <>
      <FieldsetCard>
        <label className="mb-2 flex text-gray-700 font-inter-normal-13px">
          <Checkbox
            className="mr-2"
            {...register(`${environmentPrefix}oauth2Config.uses_basic_auth`)}
            checked={usesBasicAuth}
          />
          Send credentials in Basic Auth header
        </label>

        {usesBasicAuth && (
          <>
            <FormItem
              description="The header used to present the Basic Auth credentials to the service"
              gap="xs"
              label="Basic Auth header"
            >
              <Input
                errored={isFieldErrored(errors, "basic_auth_header")}
                fullWidth
                {...register(
                  `${environmentPrefix}oauth2Config.basic_auth_header`,
                  {
                    required: usesBasicAuth,
                  },
                )}
                placeholder="e.g. Authorization"
              />
            </FormItem>
            <FormItem
              description="A prefix to send along with the Basic Auth credentials in the Basic Auth header"
              gap="xs"
              label="Basic Auth prefix"
            >
              <Input
                errored={isFieldErrored(errors, "basic_auth_prefix")}
                fullWidth
                {...register(
                  `${environmentPrefix}oauth2Config.basic_auth_prefix`,
                )}
                placeholder="e.g. Basic"
              />
            </FormItem>
          </>
        )}

        <FormItem gap="xs">
          <KeyValuePairField
            cta="Add secret"
            name={`${environmentPrefix}secrets`}
            showHeader={false}
            isSensitive
          />
        </FormItem>
      </FieldsetCard>

      <FieldsetCard>
        <FormItem
          description="The URL of the service responsible for returning an access token"
          gap="xs"
          label="Authorization URL"
          isRequired
        >
          {Boolean(errors?.authorize_url) && (
            <ErrorHint>{errors?.authorize_url?.message}</ErrorHint>
          )}
          <Input
            data-loc="oauth2-config-authorize-url"
            errored={isFieldErrored(errors, "authorize_url")}
            placeholder="e.g. https://example.com/oauth/token"
            fullWidth
            {...register(`${environmentPrefix}oauth2Config.authorize_url`, {
              required: true,
            })}
          />
        </FormItem>
        <FormItem
          description="The header used to present the authorization token to the service"
          gap="xs"
          label="Transport header"
        >
          <Input
            errored={isFieldErrored(errors, "header")}
            fullWidth
            {...register(`${environmentPrefix}oauth2Config.header`)}
            placeholder="e.g. Authorization"
          />
        </FormItem>
        <FormItem
          description="A prefix to send along with the token in the transport header"
          gap="xs"
          label="Token prefix"
        >
          <Input
            errored={isFieldErrored(errors, "prefix")}
            fullWidth
            {...register(`${environmentPrefix}oauth2Config.prefix`)}
            placeholder="e.g. Bearer"
          />
        </FormItem>
        <FormItem
          description="A list with scopes to request"
          gap="xs"
          label="Scopes"
        >
          <Input
            errored={isFieldErrored(errors, "scopes")}
            fullWidth
            {...register(`${environmentPrefix}oauth2Config.scopes`)}
            placeholder="e.g. admin.read,admin.write,reminders.read"
          />
        </FormItem>
        <FormItem
          description="The character used as a separator for the scopes sent to the service"
          gap="xs"
          label="Scope delimiter"
        >
          <Input
            errored={isFieldErrored(errors, "scope_delimiter")}
            fullWidth
            {...register(`${environmentPrefix}oauth2Config.scope_delimiter`)}
            placeholder="e.g. -"
          />
        </FormItem>
        <div className="mb-3 h-[1px] w-full bg-gray-200"></div>
        <FormItem gap="xs">
          <Label
            className="mb-4"
            description="A list of headers and their values to enrich the authentication request"
          >
            Authorization headers
          </Label>
          <FieldsetCard className="mb-4 bg-gray-100">
            <KeyValuePairField
              cta="Add header"
              keyPlaceholder="Key"
              name={`${environmentPrefix}oauth2Config.authorize_headers`}
              valuePlaceholder="Value"
            />
          </FieldsetCard>
        </FormItem>
        <FormItem gap="xs">
          <Label
            className="mb-4"
            description="A list of query params and their values to enrich the authentication request"
          >
            Authorization query params
          </Label>
          <FieldsetCard className="mb-4 bg-gray-100">
            <KeyValuePairField
              cta="Add parameter"
              keyPlaceholder="Key"
              name={`${environmentPrefix}oauth2Config.authorize_query_params`}
              valuePlaceholder="Value"
            />
          </FieldsetCard>
        </FormItem>
        <FormItem
          description="The authorization body used to obtain the access token"
          gap="xs"
          label="Authorization body"
        >
          <Textarea
            errored={isFieldErrored(errors, "authorize_body")}
            placeholder={authorizeBodyPlaceholder}
            resizeable
            {...register(`${environmentPrefix}oauth2Config.authorize_body`)}
          />
        </FormItem>
        <FormItem
          gap="xs"
          helpTooltip={<TokenPathExtractionHelpText />}
          label="Token extraction path"
        >
          <MonospacedInput
            containerClassName="flex-grow"
            errored={isFieldErrored(errors, "authorize_body_token_path")}
            formProps={register(
              `${environmentPrefix}oauth2Config.authorize_body_token_path`,
            )}
            placeholder="e.g. access_token"
            prefix="body."
            codeColors
            errorIconOnError
          />
        </FormItem>
        <FormItem
          gap="xs"
          helpTooltipBody={<ExpirationPathExtractionHelpText />}
          label="Expiration time extraction path"
        >
          <MonospacedInput
            containerClassName="flex-grow"
            errored={isFieldErrored(errors, "authorize_body_expires_path")}
            formProps={register(
              `${environmentPrefix}oauth2Config.authorize_body_expires_path`,
            )}
            placeholder="e.g. expires_in"
            prefix="body."
            codeColors
            errorIconOnError
          />
        </FormItem>
      </FieldsetCard>

      <FieldsetCard>
        <FormItem
          description="The URL of the service responsible for refreshing the access token"
          gap="xs"
          label="Refresh token URL"
          isRequired
        >
          {Boolean(errors?.refresh_url) && (
            <ErrorHint>{errors?.refresh_url?.message}</ErrorHint>
          )}
          <Input
            data-loc="oauth2-config-refresh-url"
            errored={isFieldErrored(errors, "refresh_url")}
            fullWidth
            {...register(`${environmentPrefix}oauth2Config.refresh_url`, {
              required: true,
            })}
            placeholder="e.g. https://example.com/oauth/refresh"
          />
        </FormItem>
        <div className="mb-3 h-[1px] w-full bg-gray-200"></div>
        <FormItem gap="xs">
          <Label
            className="mb-4"
            description="A list of headers and their values to enrich the refresh token request"
          >
            Refresh headers
          </Label>
          <FieldsetCard className="mb-4 bg-gray-100">
            <KeyValuePairField
              cta="Add header"
              keyPlaceholder="Key"
              name={`${environmentPrefix}oauth2Config.refresh_headers`}
              valuePlaceholder="Value"
            />
          </FieldsetCard>
        </FormItem>
        <FormItem gap="xs">
          <Label
            className="mb-4"
            description="A list of query params and their values to enrich the refresh token request"
          >
            Refresh query params
          </Label>
          <FieldsetCard className="mb-4 bg-gray-100">
            <KeyValuePairField
              cta="Add parameter"
              name={`${environmentPrefix}oauth2Config.refresh_query_params`}
            />
          </FieldsetCard>
        </FormItem>
        <FormItem
          description="The authorization body used to obtain the access token"
          gap="xs"
          label="Refresh body"
        >
          <Textarea
            errored={isFieldErrored(errors, "refresh_body")}
            placeholder={refreshBodyPlaceholder}
            resizeable
            {...register(`${environmentPrefix}oauth2Config.refresh_body`)}
          />
        </FormItem>
        <FormItem
          description="The interval (in hours) to refresh the access token"
          gap="xs"
          label="Token refresh interval"
        >
          <Input
            errored={isFieldErrored(errors, "token_refresh_interval")}
            fullWidth
            {...register(
              `${environmentPrefix}oauth2Config.token_refresh_interval`,
            )}
            placeholder="e.g. 1 hour"
          />
        </FormItem>
      </FieldsetCard>
    </>
  );
};
