export const isValidHTTPURL = (url: string): boolean => {
  const isValidScheme = url.startsWith("http://") || url.startsWith("https://");
  const isValidLength = url.length <= 2083; // Matches IE limit and pydantic HttpUrl type
  return isValidScheme && isValidLength && isValidURL(url);
};

export const isValidURL = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};
