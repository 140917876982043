import { observer } from "mobx-react-lite";
import React from "react";

import { GenericObjectT } from "src/api/flowTypes";
import { InformationPill } from "src/base-components/InformationPill";
import { TableComp as Table } from "src/base-components/Table";
import { getColumns } from "src/dataTable/TableUtils";

type PropsT = {
  failingInput: GenericObjectT | undefined;
};

export const SchemaHistoricalErrorInformation: React.FC<PropsT> = observer(
  ({ failingInput }) => {
    return (
      <div className="mx-5 mt-4 flex flex-col">
        <InformationPill className="mb-4" type="error">
          Schema error: decision failed - Schema validation failed
        </InformationPill>
        {failingInput && Object.keys(failingInput).length !== 0 && (
          <>
            <div className="mb-4 font-inter-medium-12px">Failing data:</div>
            <div className="min-h-0 flex-1">
              <Table
                columns={getColumns([failingInput], "topRight")}
                data={[failingInput]}
                frameClassName="px-2 border border-gray-200 rounded-lg h-min"
                rowClassName="border-t"
              />
            </div>
          </>
        )}
      </div>
    );
  },
);
