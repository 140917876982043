import { useRef } from "react";
import { twJoin } from "tailwind-merge";

import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/base-components/Tooltip";
import { useSelectedResultsRowIndex } from "src/flowContainer/AuthoringUIContext";
import { renderHandles } from "src/flowGraph/BaseNode";
import { type BranchAnalytics } from "src/flowGraph/useBranchAnalytics";
import {
  formatNumber,
  formatNumberWithDynamicPrecision,
} from "src/utils/numbers";
import { useIsContentOverflows } from "src/utils/useIsContentOverflows";

type BranchNodeProps = {
  label: string;
  selected: boolean;
  grayedOut?: boolean;
  analytics?: BranchAnalytics;
};

export const BranchNode: React.FC<BranchNodeProps> = ({
  label,
  selected,
  grayedOut,
  analytics,
}) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const isOverflowing = useIsContentOverflows(ref);

  const selectedRowIndex = useSelectedResultsRowIndex();
  const thereIsASelectedRow = selectedRowIndex !== null;

  return (
    <>
      <div
        className={twJoin(
          "box-border flex w-48 flex-row items-center rounded-3xl border bg-white px-4 py-2 text-center transition-shadow font-inter-semibold-13px hover:cursor-pointer",
          selected &&
            "border-indigo-400 outline-none ring-2 ring-indigo-500/25",
          !selected && "border-white",
          !grayedOut && "text-gray-800 shadow-base",
          grayedOut && "text-gray-500 shadow-sm",
        )}
        data-loc={label}
      >
        <Tooltip
          activated={isOverflowing}
          placement="top"
          title={label}
          asChild
        >
          <p ref={ref} className="grow truncate">
            {label}
          </p>
        </Tooltip>
        {analytics && !thereIsASelectedRow && (
          <AnalyticsPill analytics={analytics} />
        )}
      </div>
      {renderHandles()}
    </>
  );
};

const getTooltipTitle = (analytics: BranchAnalytics) =>
  analytics.processed === 0
    ? `This branch processed 0 rows, representing 0% of the traffic.`
    : `This branch processed ${formatNumber(analytics.processed)} row${
        analytics.processed > 1 ? "s" : ""
      }, representing ${formatNumberWithDynamicPrecision(
        analytics.relativePercentage,
      )}% of the rows at this split node and ${formatNumberWithDynamicPrecision(
        analytics.absolutePercentage,
      )}% of the overall dataset.`;

const AnalyticsPill: React.FC<{ analytics: BranchAnalytics }> = ({
  analytics,
}) => (
  <div className="ml-1" data-loc="canvas-analytics-percentage">
    <Tooltip
      align="center"
      data_loc="canvas-analytics-tooltip"
      placement="top"
      placementOffset={10}
      title={getTooltipTitle(analytics)}
    >
      <Pill size="sm" variant="gray">
        <Pill.Text>
          {formatNumber(analytics.relativePercentage, {
            maximumFractionDigits: 1,
          })}
          %
        </Pill.Text>
      </Pill>
    </Tooltip>
  </div>
);
