import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { lowerCase } from "lodash";

import { FlowVersionFlowChild, FlowVersionStatusT } from "src/api/flowTypes";
import { Icon, IconSizes } from "src/base-components/Icon";
import { FlowVersionStatus, ReviewRequestStatus } from "src/clients/flow-api";
import { assertUnreachable } from "src/utils/typeUtils";

export type FeVersionStatusFilterValue =
  (typeof FeVersionStatusFilterOptions)[keyof typeof FeVersionStatusFilterOptions]["value"];
export const FeVersionStatusFilterOptions = {
  published: {
    label: "Published",
    value: FlowVersionStatusT.PUBLISHED,
  } as const,
  inReview: { label: "In review", value: "InReview" } as const,
  drafts: { label: "Drafts", value: "DraftsNoReview" } as const,
  archived: { label: "Archived", value: FlowVersionStatusT.ARCHIVED } as const,
};
export const matchesFilter = (
  version: FlowVersionFlowChild,
  filter: FeVersionStatusFilterValue,
) => {
  if (filter === "InReview") {
    return versionIsInReview(version);
  }
  if (filter === "DraftsNoReview") {
    return (
      version.status === FlowVersionStatusT.DRAFT && !versionIsInReview(version)
    );
  }
  return version.status === filter;
};

export const applyFEFlowVersionStatusFilter = (
  versions: FlowVersionFlowChild[],
  filter: FeVersionStatusFilterValue,
) => {
  return versions.filter((version) => matchesFilter(version, filter));
};

export const getActiveVersions = (
  versions: FlowVersionFlowChild[],
  requiredVersionId?: string,
) =>
  versions.filter(
    (version) =>
      version.status !== FlowVersionStatus.ARCHIVED ||
      version.id === requiredVersionId,
  );

export const versionIsInReview = (
  versionPicks: Pick<FlowVersionFlowChild, "status" | "review">,
) =>
  versionPicks.status === FlowVersionStatusT.DRAFT &&
  versionPicks.review &&
  versionPicks.review?.request_status !== ReviewRequestStatus.CANCELLED;

export const getVersionIconColor = (
  version: Pick<FlowVersionFlowChild, "status" | "review">,
) => {
  switch (version.status) {
    case FlowVersionStatusT.PUBLISHED:
      return "text-green-400";
    case FlowVersionStatusT.ARCHIVED:
      return "text-gray-400";
    case FlowVersionStatusT.DRAFT:
      if (versionIsInReview(version)) {
        return "text-indigo-400";
      }
      return "text-yellow-400";
    default:
      assertUnreachable(version.status);
  }
};

type VersionStatusDotIndicatorProps = {
  version: Pick<FlowVersionFlowChild, "status" | "review">;
  size?: IconSizes;
};

export const VersionStatusDotIndicator: React.FC<
  VersionStatusDotIndicatorProps
> = ({ size = "3xs", version }) => {
  const dataLocEnding =
    version.status !== FlowVersionStatusT.DRAFT
      ? lowerCase(version.status)
      : versionIsInReview(version)
        ? "inreview"
        : lowerCase(version.status);
  return (
    <Icon
      color={getVersionIconColor(version)}
      dataLoc={`version-status-${dataLocEnding}`}
      icon={faCircle}
      size={size}
    />
  );
};
