import axios from "axios";

export type S3PreSignedUrlDataT = {
  url: string;
  fields: {
    [key: string]: string;
  };
};

export const uploadFile = (
  { url, fields }: S3PreSignedUrlDataT,
  file: File,
) => {
  const formData = new FormData();

  Object.entries(fields).forEach(([field, fieldValue]) => {
    formData.append(field, fieldValue);
  });

  formData.append("file", file);

  return axios.post<{}>(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
