import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";

import { useFlow } from "src/api/queries";
import { ExternalLink } from "src/base-components/ExternalLink";
import { Icon } from "src/base-components/Icon";
import { TextConfirmationModal } from "src/base-components/TextConfirmationModal";
import { FlowVersionStatus, WorkspaceDataplane } from "src/clients/flow-api";
import { Modal } from "src/design-system/Modal";
import { RelatedFlowList } from "src/flow/modals/RelatedFlowList";
import {
  getFitleredGroupedRelatedFlows,
  groupRelatedFlowsForMultipleVersions,
} from "src/flow/modals/utils";
import { useCapabilities } from "src/hooks/useCapabilities";
import { useJobs } from "src/jobs/api/queries";
import {
  DashboardPageParamsT,
  getBaseUrl,
  getUrlToJobPage,
} from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type CannotDeleteFlowModalProps = {
  isOpen: boolean;
  workspace?: WorkspaceDataplane;
  flowId?: string;
  onClose: () => void;
  onConfirm: () => Promise<void>;
};

export const DeleteFlowModal = ({
  isOpen,
  workspace,
  flowId,
  onClose,
  onConfirm,
}: CannotDeleteFlowModalProps) => {
  const { orgId } = useParamsDecode<DashboardPageParamsT>();
  const { flows: flowCapabilities } = useCapabilities();
  const { data: activeJobs } = useJobs(workspace?.base_url!, flowId, "active");
  const { data: flow } = useFlow(flowId);

  if (!workspace || !flow || !activeJobs) {
    return null;
  }
  // make sure we use the right format of flow versions

  const groupedParentFlows = groupRelatedFlowsForMultipleVersions(
    flow.versions,
    "parents",
  );

  if (groupedParentFlows === null) {
    return null;
  }
  const groupedPublishedParentFlows = getFitleredGroupedRelatedFlows(
    groupedParentFlows,
    FlowVersionStatus.PUBLISHED,
  );

  const parentFlowIds = Object.keys(groupedParentFlows);
  const publishedParentFlowIds = Object.keys(groupedPublishedParentFlows);

  const hasLiveVersions = flow.versions.some(
    (version) => version.status === FlowVersionStatus.PUBLISHED,
  );

  const cantBeDeletedReasons = {
    hasPublishedVersions:
      hasLiveVersions && !flowCapabilities.canDeletePublished,
    hasPublishedParentVersions: publishedParentFlowIds.length > 0,
    hasActiveJobs: activeJobs.length > 0,
  };
  const cantBeDeletedReason = Object.entries(cantBeDeletedReasons).filter(
    ([_, value]) => value,
  )[0]?.[0] as undefined | keyof typeof cantBeDeletedReasons;

  const cantBeDeletedModalDescription: {
    [k in keyof typeof cantBeDeletedReasons]: string;
  } = {
    hasActiveJobs:
      "This flow cannot be deleted as it is being used by these active jobs:",
    hasPublishedParentVersions:
      "This flow cannot be deleted as it is being used by these published parent flows:",
    hasPublishedVersions:
      "This flow cannot be deleted as it has published versions",
  };

  return (
    <>
      <Modal open={isOpen && !!cantBeDeletedReason} onClose={onClose}>
        <Modal.Header
          description={
            cantBeDeletedReason
              ? cantBeDeletedModalDescription[cantBeDeletedReason]
              : undefined
          }
        >
          {`Cannot delete ${flow.name}`}{" "}
        </Modal.Header>
        <Modal.Content>
          {cantBeDeletedReason === "hasActiveJobs" && (
            <>
              <ul className="mb-2.5 list-disc pl-4">
                {activeJobs.map((job) => (
                  <li key={job.id} className="mb-1 font-inter-medium-13px">
                    <ExternalLink
                      className="flex items-center text-gray-800"
                      href={
                        getBaseUrl() +
                        getUrlToJobPage(orgId, workspace.id, flow.id, job.id)
                      }
                    >
                      {job.name}
                      <Icon icon={faExternalLink} size="2xs" />
                    </ExternalLink>
                  </li>
                ))}
              </ul>
              <p className="text-gray-500 font-inter-normal-13px">
                Please deactivate these jobs before deleting this Decision Flow.
              </p>
            </>
          )}
          {cantBeDeletedReason === "hasPublishedVersions" && (
            <p className="text-gray-500 font-inter-normal-13px">
              This flow can only be deleted by an admin as it has published
              versions.
            </p>
          )}
          {cantBeDeletedReason === "hasPublishedParentVersions" && (
            <RelatedFlowList
              groupedRelatedFlows={groupedPublishedParentFlows}
              workspace={workspace}
            />
          )}
        </Modal.Content>
        <Modal.Footer primaryButton={false} />
      </Modal>

      <TextConfirmationModal
        additionalContent={
          parentFlowIds.length > 0 ? (
            <RelatedFlowList
              groupedRelatedFlows={groupedParentFlows}
              workspace={workspace}
            />
          ) : undefined
        }
        calloutPill={
          hasLiveVersions
            ? {
                type: "error",
                text: "This Decision Flow contains published versions. Deleting these will remove all live data and any currently active processes relying on these versions.",
              }
            : undefined
        }
        challengeText={flow.name}
        description={
          (parentFlowIds.length > 0
            ? "Deleting this version will break the connection with the Decision Flow versions listed below."
            : "") +
          "Are you sure you want to delete this version? This action cannot be undone."
        }
        label="Please enter the Flow's name to confirm."
        open={isOpen && !cantBeDeletedReason}
        title={`Delete ${flow.name || ""}`}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    </>
  );
};
