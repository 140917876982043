import { Navigate } from "react-router-dom";

import { useCapabilities } from "src/hooks/useCapabilities";
import { DashboardPageParamsT, getUrlToSettingsPage } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const SettingsPageRedirect: React.FC = () => {
  const { connections, apiKeys, usersPermissions } = useCapabilities();
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();

  if (connections.canAccess) {
    return <Navigate to={getUrlToSettingsPage(orgId, wsId, "connections")} />;
  }

  if (apiKeys.canAccess) {
    return <Navigate to={getUrlToSettingsPage(orgId, wsId, "api-keys")} />;
  }

  if (usersPermissions.canAccess) {
    return <Navigate to={getUrlToSettingsPage(orgId, wsId, "users")} />;
  }

  return null;
};
