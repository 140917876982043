import { ReactCodeMirrorRef, StateField } from "@uiw/react-codemirror";
import { forwardRef, memo } from "react";

import {
  CodeInput,
  CodeInputProps,
} from "src/base-components/CodeInput/CodeInput";
import { NodeEditorCompletionContext } from "src/nodeEditor/NodeEditor";

export type AutocompleteCodeInputProps = Omit<
  CodeInputProps,
  "completionExtension"
> & { isFieldInLoopIteration?: boolean };

/**
 * This field is present to determine if the input is corresponding to a loop iteration
 */
export const isFieldInLoopIteration = StateField.define({
  create: () => {
    return true;
  },
  update: (value) => value,
});

export const AutocompleteCodeInput = memo(
  forwardRef<ReactCodeMirrorRef, AutocompleteCodeInputProps>((props, ref) => {
    return (
      <NodeEditorCompletionContext.Consumer>
        {({ defaultCompletion }) => {
          return (
            <CodeInput
              {...props}
              ref={ref}
              completionExtension={
                props.plaintext || props.prefix === "$"
                  ? undefined
                  : props.isFieldInLoopIteration
                    ? defaultCompletion === undefined
                      ? undefined
                      : [defaultCompletion, isFieldInLoopIteration]
                    : defaultCompletion
              }
            />
          );
        }}
      </NodeEditorCompletionContext.Consumer>
    );
  }),
);
