import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { EditorCellProps } from "src/base-components/EditorTable/types";
import { DecisionTableField } from "src/clients/flow-api";
import { useFactorContext } from "src/scorecardNode/context";
import { FormShape } from "src/scorecardNode/types";
import { validateWeight } from "src/scorecardNode/utils";

type Props<T> = EditorCellProps<T, DecisionTableField>;

const FLOAT_PATTERN = /^([0-9]+(\.[0-9]*)?|\.[0-9]+)$/;

export const WeightEditor = <T,>({ state }: Props<T>) => {
  const { equalWeights, totalFactors, index: factorIndex } = useFactorContext();
  const { watch, setValue } = useFormContext<FormShape>();
  const fieldName = `factors.${factorIndex}.weight.value` as const;
  const value = watch(fieldName);
  const ref = useRef<HTMLInputElement>(null);
  const [userValue, setUserValue] = useState(value);

  useEffect(() => {
    if (ref.current && (state.isEditing || state.isSelected)) {
      ref.current.focus();
    }
  }, [state]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;

    if (value.length === 0 || FLOAT_PATTERN.test(e.target.value)) {
      setUserValue(e.target.value);
    }
  };

  const handleBlur = () => {
    const validatedWeight = validateWeight(userValue);
    setUserValue(validatedWeight);
    if (validatedWeight !== value) {
      setValue(fieldName, validatedWeight);
    }
  };

  if (equalWeights) {
    return (
      <p className="font-fira-code w-full px-2 pt-3 text-gray-500">
        1/{totalFactors}
      </p>
    );
  }

  return (
    <>
      <input
        ref={ref}
        className={twJoin(
          "ml-[1px] h-fit border-0 p-2",
          "font-fira-code w-full text-xs outline-0 ring-0 focus:outline-0 focus:ring-0",
          state.readonly && "cursor-not-allowed bg-gray-100",
        )}
        name="weight"
        placeholder="e.g. 5"
        readOnly={state.readonly}
        type="text"
        value={userValue}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <span className="font-fira-code h-fit py-2 pr-2 text-gray-400">%</span>
    </>
  );
};
