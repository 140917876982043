import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";

type SummaryProps = {
  children?: React.ReactNode;
};

export const Summary: React.FC<SummaryProps> & {
  Cell: React.FC<CellProps>;
} = ({ children }) => {
  return (
    <div className="flex divide-x divide-gray-200 border-b border-l border-gray-200 bg-white">
      {children}
    </div>
  );
};

type CellProps = {
  children?: React.ReactNode;
  title: string;
  icon?: IconProp;
  truncate?: boolean;
};
const Cell: React.FC<CellProps> = ({
  title,
  icon,
  children,
  truncate = true,
}) => {
  return (
    <div className="min-w-0 flex-1 gap-y-0.5 px-5 py-4 text-gray-800">
      <div className="font-inter-semibold-13px">{title}</div>
      <div
        className={twJoin(
          "flex items-center font-inter-medium-12px",
          truncate && "truncate",
        )}
      >
        {icon && (
          <div className="mr-1">
            <Icon color="text-gray-500" icon={icon} size="xs" />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

Summary.Cell = Cell;
