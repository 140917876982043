import { observer } from "mobx-react-lite";
import React from "react";

import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { useGraphStore } from "src/store/StoreProvider";

export const UngroupModal: React.FC<{}> = observer(() => {
  const {
    groups,
    deleteGroup,
    setIsUngroupModalVisible,
    setGroupToChangeId,
    groupToChangeId,
    isUngroupModalVisible,
  } = useGraphStore();
  const onClose = () => {
    setIsUngroupModalVisible(false);
    setGroupToChangeId(null);
  };
  const groupName = groupToChangeId && groups.get(groupToChangeId)?.data.label;
  const onConfirmDelete = async () => {
    if (groupToChangeId) {
      await deleteGroup(groupToChangeId);
      onClose();
    }
  };
  return (
    <ConfirmationModal
      confirmationButtonClassname="w-[10.453rem]"
      confirmationButtonText="Yes, ungroup Node"
      open={isUngroupModalVisible}
      title="Ungroup all Nodes in this group?"
      onClose={onClose}
      onConfirm={onConfirmDelete}
    >
      <div className="mb-6 mt-4 text-gray-500 font-inter-normal-12px">
        Are you sure you want to ungroup all the nodes of the {groupName} group?
        This action cannot be undone.
      </div>
    </ConfirmationModal>
  );
});
