import { RefObject } from "react";
import { createStore } from "zustand";
import { immer } from "zustand/middleware/immer";

import { GenericObjectT } from "src/api/flowTypes";
import {
  ComputePositionFn,
  ComputePositionOptions,
  curryComputePosition,
} from "src/base-components/FloatingWindow/positioning";

type FloatingWindowOptions = {
  id: string;
  target: RefObject<HTMLDivElement> | undefined;
  positionOptions?: ComputePositionOptions;
};

type FloatingWindow<D = GenericObjectT> = {
  id: string;
  computePosition: ComputePositionFn;
  isPinned: boolean;
  data: D;
};

type FloatingWindowsState = {
  windows: Record<string, FloatingWindow>;
};

type FloatingWindowsActions = {
  open: (window: FloatingWindowOptions, data?: GenericObjectT) => void;
  close: (id: string) => void;
  setPin: (id: string, isPinned: boolean) => void;
};

type FloatingWindowsStore = ZustandStore<
  FloatingWindowsState,
  FloatingWindowsActions
>;

export const createFloatingWindowsStore = () => {
  return createStore(
    immer<FloatingWindowsStore>((set) => ({
      windows: {},
      actions: {
        open: ({ target, positionOptions = {}, ...window }, data = {}) => {
          return set((state) => {
            state.windows[window.id] = {
              ...window,
              isPinned: false,
              computePosition: curryComputePosition(target, positionOptions),
              data,
            };
          });
        },
        close: (id) =>
          set((state) => {
            delete state.windows[id];
          }),
        setPin: (id, isPinned) =>
          set((state) => {
            if (state.windows[id]) {
              state.windows[id].isPinned = isPinned;
            }
          }),
      },
    })),
  );
};
