import { useFormContext } from "react-hook-form";

import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { ConnectionConfigInputsT } from "src/connections/types";
import { SecretField } from "src/webhooks/editModal/authentication/SecretField";

export const ApiKeyAuthFields: React.FC = () => {
  const {
    formState: { errors: formErrors },
    register,
  } = useFormContext<ConnectionConfigInputsT>();

  const errors = formErrors?.apiKeyConfig;
  return (
    <FieldsetCard>
      <FormItem
        description="The name of the header used to transport the auth token."
        gap="xs"
        label="Transport header"
      >
        <Input
          data-loc="auth-header"
          errored={isFieldErrored(errors, "header")}
          placeholder="e.g. x-api-key"
          fullWidth
          {...register("apiKeyConfig.header")}
        />
      </FormItem>
      <FormItem
        description="A prefix to send along with the token in the transport header."
        gap="xs"
        label="Prefix"
      >
        <Input
          data-loc="auth-prefix"
          errored={isFieldErrored(errors, "prefix")}
          placeholder="e.g. Bearer"
          fullWidth
          {...register("apiKeyConfig.prefix")}
        />
      </FormItem>
      <SecretField
        dataLoc="auth-api-key"
        fieldName="secrets.0"
        name="API key"
        placeholder="Enter the secret key you generated here"
        subHeadline="The value you generated to ensure authentication"
      />
    </FieldsetCard>
  );
};
