import {
  intervalToDuration,
  formatDuration as dateFnsFormatDuration,
  Locale,
} from "date-fns";
import { inRange } from "lodash";

export const formatNumber = (
  value: number,
  options?: Intl.NumberFormatOptions,
) => value.toLocaleString("en-US", options ?? {});

const FORMAT_DISTANCE_SHORT_LOCALE = {
  xSeconds: "s",
  xMinutes: "m",
  xHours: "h",
  xDays: "d",
};
const shortEnLocale: Locale = {
  formatDistance: (token: keyof typeof FORMAT_DISTANCE_SHORT_LOCALE, count) =>
    `${count}${FORMAT_DISTANCE_SHORT_LOCALE[token]}`,
};

export const formatDuration = (durationInMs: number) => {
  if (durationInMs < 1000) {
    return `${durationInMs}ms`;
  }

  const duration = intervalToDuration({
    start: 0,
    end: durationInMs,
  });
  return (
    dateFnsFormatDuration(duration, {
      format: ["days", "hours", "minutes", "seconds"],
      locale: shortEnLocale,
    })
      // Keep only the first two units
      .replace(/(\S+\s+\S+).*/, "$1")
  );
};

const getFractionDigits = (n: number) => {
  if (n >= 1) return 1;

  if (inRange(n, 0.01, 0)) {
    return 3;
  } else if (inRange(n, 0.1, 0.01)) {
    return 2;
  } else {
    return 1;
  }
};

/**
 * For numbers bigger than 1, it will format with 1 fraction digit.
 * For numbers between 0 and 1:
 * 0.00001 -> 0
 * 0.00006 -> 0.001
 * 0.001 -> 0.001
 * 0.01 -> 0.01
 * 0.1 -> 0.1
 *
 * Take a look at the tests for more examples.
 */
export const formatNumberWithDynamicPrecision = (number: number) =>
  formatNumber(number, {
    maximumFractionDigits: getFractionDigits(number),
  });

export const formatOrdinalNumber = (n: number) => {
  if (n >= 11 && n <= 13) {
    return `${n}th`;
  }
  const lastDigit = n % 10;

  switch (lastDigit) {
    case 1:
      return `${n}st`;
    case 2:
      return `${n}nd`;
    case 3:
      return `${n}rd`;
    default:
      return `${n}th`;
  }
};
