import SardineIcon from "src/assets/SardineIcon.svg?react";
import {
  getDefaultSardineIdentityVerification,
  getDefaultSardineFeedback,
} from "src/connections/connectionConfigs/sardine/sardineResources";
import { SardineTemplate } from "src/connections/connectionConfigs/sardine/sardineTemplate";
import { ConnectionConfig } from "src/connections/connectionConfigs/types";

export const SARDINE_CONFIG: ConnectionConfig<"sardine"> = {
  icon: SardineIcon,
  template: SardineTemplate,
  description: "The smartest platform for fraud prevention",
  name: "Sardine",
  resources: {
    sardine_identity_verification: {
      externalLink:
        "https://www.notion.so/taktile/Identity-Verification-9fa13776919745ea8dbb960398a330fe?pvs=4",
      integrationResource: getDefaultSardineIdentityVerification,
    },
    sardine_feedback: {
      externalLink:
        "https://www.notion.so/taktile/Feedback-389c4e6691e24a6a91751318deba62d0",
      integrationResource: getDefaultSardineFeedback,
    },
  },
};
