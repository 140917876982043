import { faCloudArrowUp } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { ModelDropzoneController as ModelUploadController } from "src/mlNode/ModelUploadController";

type PropsT = {
  onFileSelect: (file: File) => void;
  isUploading: boolean;
  workspace: WorkspaceDataplane;
};

export const ModelDropzone: React.FC<PropsT> = ({
  onFileSelect,
  isUploading,
  workspace,
}) => {
  return (
    <ModelUploadController
      className="relative flex grow flex-col items-center justify-center rounded-xl border border-gray-200 bg-gray-50"
      disabled={isUploading}
      workspace={workspace}
      onFileSelect={onFileSelect}
    >
      <div className="flex items-center justify-center rounded-full border-2 border-indigo-200 p-1.5">
        <Icon color="text-indigo-500" icon={faCloudArrowUp} size="2xl" />
      </div>
      <p className="mt-5 select-none text-gray-800 font-inter-semibold-13px">
        Upload Model File Here
      </p>
      <p className="mt-2 max-w-[245px] select-none px-1 text-center text-gray-500 font-inter-normal-12px">
        Drag an .onnx file here or upload from your computer
      </p>
      <div className="absolute bottom-0 mb-10 w-full px-10">
        <Button
          dataLoc="model-upload-button"
          disabled={isUploading}
          loading={isUploading}
          variant="primary"
          fullWidth
        >
          Upload a file
        </Button>
      </div>
    </ModelUploadController>
  );
};
