import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { PopoverButton } from "@headlessui2/react";
import { UseFieldArrayAppend } from "react-hook-form";
import { v4 } from "uuid";

import { Button } from "src/base-components/Button";
import { CustomPopover } from "src/base-components/Popover";
import {
  ManualReviewDescriptionTypeEnum,
  ManualReviewDividerTypeEnum,
  ManualReviewFieldTypeEnum,
} from "src/clients/flow-api";
import { ManualReviewFormT } from "src/manualReviewNode/ManualReviewNodeEditor";

const AddHighlightPopoverRow: React.FC<{
  title: string;
  description: string;
  handleAdd: () => void;
}> = ({ title, description, handleAdd }) => {
  return (
    <PopoverButton
      as="button"
      className="px-4 py-2.5 hover:bg-gray-100"
      data-loc={`add-manual-review-${title.toLowerCase()}`}
      onClick={handleAdd}
    >
      <p className="mb-2 text-left text-gray-800 font-inter-semibold-13px">
        {title}
      </p>
      <p className="text-left text-gray-600 font-inter-normal-12px">
        {description}
      </p>
    </PopoverButton>
  );
};

export const AddHighlightPopover: React.FC<{
  immutable: boolean;
  append: UseFieldArrayAppend<ManualReviewFormT, "highlights">;
}> = ({ immutable, append }) => {
  return (
    <div className="flex flex-row">
      <CustomPopover
        button={
          <Button
            dataLoc="add-manual-review-element"
            disabled={immutable}
            iconLeft={faPlus}
            iconRight={faChevronDown}
            size="sm"
            variant="secondary"
          >
            Add element
          </Button>
        }
        placement="bottom-start"
      >
        <div className="flex w-66 flex-col py-2">
          <AddHighlightPopoverRow
            description="Add fields from the data object that should be highlighted to the reviewer"
            handleAdd={() => {
              append({
                type: ManualReviewFieldTypeEnum.FIELD,
                id_value: v4(),
                value: "",
                readable_name: "",
                id_readable_name: v4(),
              });
            }}
            title="Field"
          />
          <AddHighlightPopoverRow
            description="Write descriptions and help text to guide the reviewer"
            handleAdd={() => {
              append({
                type: ManualReviewDescriptionTypeEnum.DESCRIPTION,
                id_value: v4(),
                value: "",
              });
            }}
            title="Description"
          />
          <AddHighlightPopoverRow
            description="Use separators to group and organise elements"
            handleAdd={() => {
              append({ type: ManualReviewDividerTypeEnum.DIVIDER });
            }}
            title="Separator"
          />
        </div>
      </CustomPopover>
    </div>
  );
};
