import { makeConnectionTemplate } from "src/connections/types";

export const SardineTemplate = makeConnectionTemplate({
  provider: "sardine",
  secrets: [
    { key: "client_id", name: "Client ID", required: true },
    { key: "client_secret", name: "Client secret", required: true },
  ],
  resources: {
    sardine_identity_verification: {
      name: "Identity Verification",
      configKeys: [],
    },
    sardine_feedback: {
      name: "Feedback",
      configKeys: [],
    },
  },
});
