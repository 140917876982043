import { format } from "date-fns";
import { AnimatePresence, m } from "framer-motion";
import React, { useRef } from "react";
import { useHover } from "usehooks-ts";

import { useWorkspaceUsers } from "src/api/taktile/queries";
import { Dataset } from "src/api/types";
import { Tooltip } from "src/base-components/Tooltip";
import { UserAvatar } from "src/base-components/UserAvatar/UserAvatar";
import { IncompatibleLabel } from "src/datasets/DatasetList/IncompatibleLabel";
import { ItemDropdown } from "src/datasets/DatasetList/ItemDropdown";
import { SourceIcon } from "src/datasets/DatasetList/SourceIcon";
import { separateFilenameExtension } from "src/datasets/utils";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { dateFromNow } from "src/utils/datetime";
import { formatNumber } from "src/utils/numbers";

type Props = {
  dataset: Dataset;
  incompatible: boolean;
  onDelete: () => void;
  onClick: () => void;
  disableDownloads: boolean;
};

export const Item: React.FC<Props> = ({
  dataset,
  incompatible,
  onClick,
  onDelete,
  disableDownloads,
}) => {
  const { name: fileName } = separateFilenameExtension(dataset.name);
  const hoverRef = useRef<HTMLLIElement | null>(null);
  const isHover = useHover<HTMLLIElement>(hoverRef);
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);

  const wasCreatedThisYear =
    new Date(dataset.created_at).getFullYear() === new Date().getFullYear();

  const { orgId, workspace } = useAuthoringContext();
  const users = useWorkspaceUsers(orgId, workspace.id, {
    include_deactivated: true,
  });
  const lastEditedByUser =
    users.data && users.data.find((u) => u.id === dataset.updated_by_id);

  return (
    <m.li
      ref={hoverRef}
      className="group cursor-pointer rounded-lg border border-gray-100 bg-white px-4 pb-3 pt-2 shadow-sm"
      data-loc={`dataset-entry-${dataset.name}`}
      layout
      onClick={onClick}
    >
      <div className="flex items-center justify-between gap-x-2">
        <div className="flex h-7.5 flex-1 items-center gap-x-1 border border-transparent">
          <SourceIcon source={dataset.source} />
          <p className="line-clamp-1 text-gray-800 font-inter-semibold-13px">
            {fileName}
          </p>
        </div>
        <div className="flex items-center gap-x-2">
          <AnimatePresence initial={false}>
            {incompatible && (
              <IncompatibleLabel
                datasetId={dataset.id}
                onOpenTooltip={setTooltipIsOpen}
              />
            )}
            <ItemDropdown
              key="actions"
              dataset={dataset}
              disableDownloads={disableDownloads}
              shouldShowEllipsis={isHover || tooltipIsOpen}
              onDelete={onDelete}
            />
          </AnimatePresence>
        </div>
      </div>
      <p className="ml-6 flex items-center gap-x-2 truncate text-gray-500 font-inter-normal-12px">
        {typeof dataset.row_count === "number" ? (
          <span>
            {formatNumber(dataset.row_count)} test{" "}
            {dataset.row_count === 1 ? "case" : "cases"}
          </span>
        ) : null}
        <span className="text-2xl">·</span>{" "}
        <Tooltip
          activated={!!lastEditedByUser}
          body={
            <div>
              <span className="text-gray-400">
                Last edited {dateFromNow(dataset.updated_at)} by{" "}
              </span>
              {lastEditedByUser && (
                <div className="mt-1 flex items-center gap-x-2">
                  <UserAvatar size="sm" user={lastEditedByUser} />
                  <p className="text-white font-inter-semibold-13px">
                    {lastEditedByUser.full_name}
                  </p>
                </div>
              )}
            </div>
          }
          placement="bottom"
          title=""
        >
          <span>Last edited {dateFromNow(dataset.updated_at)} </span>
        </Tooltip>
        <span className="text-2xl">·</span>{" "}
        {dataset.source === "file" ? "Uploaded" : "Created"} on{" "}
        {format(
          new Date(dataset.created_at),
          wasCreatedThisYear ? "d MMM" : "d MMM yy",
        )}
      </p>
    </m.li>
  );
};
