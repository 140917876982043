import { observer } from "mobx-react-lite";
import React from "react";
import { useToggle } from "usehooks-ts";

import { useIsNodeEditorOpen } from "src/flowContainer/AuthoringUIContext";
import { FullscreenModal } from "src/nodeEditor/FullscreenModal";
import { useGraphStore } from "src/store/StoreProvider";

export type SidePaneNodeEditorChildrenFuncArgs = {
  toggleFullscreen: () => void;
  isFullscreen: boolean;
  wrapperRef: React.RefObject<HTMLDivElement>;
};

type Props = {
  children: (args: SidePaneNodeEditorChildrenFuncArgs) => React.ReactElement;
  sidePaneRef: React.RefObject<HTMLDivElement>;
  fullScreenRef: React.RefObject<HTMLDivElement>;
};

export const SidePaneNodeEditorContainer: React.FC<Props> = observer(
  ({ children, sidePaneRef, fullScreenRef }) => {
    const { selectedNode } = useGraphStore();

    const isNodeEditorOpen = useIsNodeEditorOpen();
    const [fullscreen, toggleFullscreen] = useToggle();

    if (!isNodeEditorOpen || !selectedNode) {
      return <></>;
    }

    return (
      <div
        className="relative flex h-full w-[150ch] min-w-[464px] flex-col bg-white pt-6 shadow-lg"
        data-loc="node-editor-pane"
      >
        {!fullscreen &&
          children({
            toggleFullscreen,
            isFullscreen: false,
            wrapperRef: sidePaneRef,
          })}
        <FullscreenModal open={fullscreen} onClose={toggleFullscreen}>
          {children({
            toggleFullscreen,
            isFullscreen: true,
            wrapperRef: fullScreenRef,
          })}
        </FullscreenModal>
      </div>
    );
  },
);
