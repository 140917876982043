import { useOutletContext } from "react-router-dom";

import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { ReviewDb, WorkspaceDataplane } from "src/clients/flow-api";

export type AuthoringPageContext = {
  flow: FlowT;
  version: FlowVersionT;
  workspace: WorkspaceDataplane;
  orgId: string;
  isRevision: boolean;
  review: ReviewDb;
};

export const useAuthoringContext = () => {
  const context = useOutletContext<AuthoringPageContext>();
  if (
    !context ||
    !context.flow ||
    !context.version ||
    !context.workspace ||
    context.isRevision === undefined
  ) {
    throw new Error(
      "`useAuthoringContext` hook must be used within an authoring page route",
    );
  }

  return context;
};

type FlowPagesContext = {
  flow: FlowT;
  workspace: WorkspaceDataplane;
  orgId: string;
};

export const useFlowContext = () => {
  const context = useOutletContext<FlowPagesContext>();
  if (!context || !context.flow || !context.workspace) {
    throw new Error(
      "`useFlowContext` hook must be used within a flow pages route",
    );
  }
  return context;
};

type WorkspacePagesContext = {
  workspace: WorkspaceDataplane;
  orgId: string;
};

export const useWorkspaceContext = () => {
  const context = useOutletContext<WorkspacePagesContext>();
  if (!context || !context.workspace) {
    throw new Error(
      "`useWorkspaceContext` hook must be used within a workspace pages route",
    );
  }

  return context;
};
