import { useSortable } from "@dnd-kit/sortable";
import { CSS, Transform } from "@dnd-kit/utilities";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { Controller, useFormContext } from "react-hook-form";

import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { ReorderHandle } from "src/base-components/ReorderHandle";
import {
  ManualReviewDescriptionTypeEnum,
  ManualReviewDividerTypeEnum,
  ManualReviewField,
  ManualReviewFieldTypeEnum,
} from "src/clients/flow-api";
import { ManualReviewDescriptionEditor } from "src/manualReviewNode/ManualReviewDescriptionEditor";
import { ManualReviewFormT } from "src/manualReviewNode/ManualReviewNodeEditor";
import { HighlightsInner } from "src/manualReviewNode/types";
import { assertUnreachable } from "src/utils/typeUtils";

type Props = {
  highlight: HighlightsInner;
  id: string;
  immutable: boolean;
  handleRemove: () => void;
  index: number;
};

type InnerProps = Pick<Props, "index" | "immutable">;

const Divider: React.FC = () => {
  return (
    <div className="flex h-[30px] w-full items-center">
      <div className="h-[1px] w-full bg-gray-200" />
    </div>
  );
};

const Description: React.FC<InnerProps> = ({ index, immutable }) => {
  const { control } = useFormContext<ManualReviewFormT>();
  return (
    <Controller
      control={control}
      name={`highlights.${index}.value`}
      render={({ field }) => (
        <ManualReviewDescriptionEditor
          dataLoc={`manual-review-highlight-${index}-description`}
          disabled={immutable}
          placeholder="Write something for the reviewer"
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  );
};

const Field: React.FC<InnerProps> = ({ index, immutable }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ManualReviewFormT>();
  const fieldErrors = errors?.highlights?.[index] as ManualReviewField;

  return (
    <div className="flex w-full items-center gap-x-4">
      <div className="w-1/2">
        <Input
          placeholder="Name the field"
          fullWidth
          {...register(`highlights.${index}.readable_name`)}
          data-loc={`manual-review-highlight-${index}-name`}
          disabled={immutable}
          errored={!!fieldErrors?.readable_name}
        />
      </div>
      <div className="w-1/2">
        <Controller
          control={control}
          name={`highlights.${index}.value`}
          render={({ field }) => (
            <AutocompleteCodeInput
              dataLoc={`manual-review-highlight-${index}-value`}
              disabled={immutable}
              error={fieldErrors?.value}
              placeholder="data.field"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </div>
    </div>
  );
};

export const HighlightRow: React.FC<Props> = ({
  highlight,
  id,
  immutable,
  handleRemove,
  index,
}) => {
  const { listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });
  const style = {
    transform: CSS.Transform.toString(
      transform ? ({ ...transform, scaleY: 1 } as Transform) : null,
    ),
    transition,
  };

  return (
    <li
      ref={setNodeRef}
      className="flex w-full flex-row items-start gap-x-4"
      style={style}
    >
      <ReorderHandle
        className="mt-1.5"
        immutable={immutable}
        listeners={listeners}
      />
      <HighlightEditor
        highlight={highlight}
        immutable={immutable}
        index={index}
      />
      <div className="mt-1.5">
        <Icon
          color="text-gray-400"
          icon={faTrashCan}
          padding={false}
          size="xs"
          onClick={!immutable ? handleRemove : undefined}
        />
      </div>
    </li>
  );
};

const HighlightEditor: React.FC<
  Pick<Props, "index" | "immutable" | "highlight">
> = ({ highlight, index, immutable }) => {
  if (highlight.type === ManualReviewDescriptionTypeEnum.DESCRIPTION) {
    return <Description immutable={immutable} index={index} />;
  } else if (highlight.type === ManualReviewDividerTypeEnum.DIVIDER) {
    return <Divider />;
  } else if (highlight.type === ManualReviewFieldTypeEnum.FIELD) {
    return <Field immutable={immutable} index={index} />;
  } else {
    assertUnreachable(highlight);
    return <></>;
  }
};
