import { BeMappedNode } from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";

export const isInputOrOutputNode = (
  node: BeMappedNode,
): node is BeMappedNode & {
  type: NODE_TYPE.INPUT_NODE | NODE_TYPE.OUTPUT_NODE;
} => {
  return (
    node.type === NODE_TYPE.INPUT_NODE || node.type === NODE_TYPE.OUTPUT_NODE
  );
};
