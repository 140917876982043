import { Controller, useFormContext, Path } from "react-hook-form";

import { Switch } from "src/base-components/Switch";

type NonProdEnvConfig = {
  enableNonProdConfigs: boolean;
};

type PropsT = {
  dataLoc: string;
  title: string;
  subtitle: string;
};

export const EnableNonProdConfigsField = <T extends NonProdEnvConfig>({
  dataLoc,
  title,
  subtitle,
}: PropsT): JSX.Element => {
  const { control } = useFormContext<T>();

  return (
    <div className="my-6 flex items-center justify-between">
      <div>
        <h3 className="text-gray-800 font-inter-semibold-13px">{title}</h3>
        <span className="text-gray-500 font-inter-normal-12px">{subtitle}</span>
      </div>
      <Controller
        control={control}
        name={"enableNonProdConfigs" as Path<T>}
        render={(props) => (
          <Switch
            dataLoc={dataLoc}
            enabled={props.field.value}
            onChange={() => {
              props.field.onChange(!props.field.value);
            }}
          />
        )}
      />
    </div>
  );
};
