import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";

export type EmptyProps = {
  dataLoc?: string;
  icon: IconProp;
  headline: React.ReactNode;
  description: React.ReactNode;
  action?: React.ReactNode;
  variant?: "default" | "error";
  size?: "sm" | "md";
};

export const EmptyState: React.FC<EmptyProps> = ({
  dataLoc,
  icon,
  headline,
  description,
  action,
  variant = "default",
  size = "md",
}) => (
  <div
    className={twJoin(
      "flex h-full flex-col items-center justify-center text-center",
      size === "md" && "py-16",
      size === "sm" && "py-4",
    )}
    data-loc={dataLoc}
  >
    <div
      className={twJoin(
        "mb-4 inline-block rounded-lg p-3",
        variant === "default" && "bg-indigo-100 text-indigo-600",
        variant === "error" && "bg-red-100 text-red-600",
      )}
    >
      <Icon color="" icon={icon} />
    </div>
    <h1 className="mb-2 inline-block max-w-60 text-gray-800 font-inter-semibold-13px">
      {headline}
    </h1>
    <p
      className={twJoin(
        "inline-block min-h-0 max-w-60 overflow-hidden break-words text-gray-500 font-inter-normal-12px",
        action && "mb-6",
      )}
    >
      {description}
    </p>
    {action}
  </div>
);
