import { ProviderT } from "src/api/connectApi/types";

// These are FLAGS that we want to keep when navigating to a new page
export enum FEATURE_FLAGS {
  powertools = "power-tools",
  unlimitedUploadSize = "no-limits", // Remove limit of uploads 🤘
  performance = "performance",
  asWsViewer = "as-ws-viewer",
  strictIncompatibilityCheck = "strict-incompatibility-check",
  postgresMTLSConfig = "postgres-mtls-config",
  crifHighMark = "crif_high_mark",
  learnSchema = "learn-schema",
  templates = "templates",
  sqlEntityId = "sql_entity_id",
  integrationVelocity = "integration_velocity",
  auditLogs = "audit-logs",
  mysql = "mysql",
  jobDestination = "job-destination",
  jobTrafficPolicies = "job-traffic-policies",
  jobS3BucketSourceAndDestination = "job-s3-bucket-source-and-destination",
  nodeAnalytics = "node-analytics",
  webhookNoAuthSetting = "webhook-no-auth-setting",
  entitiesView = "entities-view",
}

export const BETA_CONNECTIONS: ProviderT | string[] = [
  "thomson_reuters",
  "sentilink",
  "trans_union",
];

export const CONNECTION_FEATURE_FLAGS: Array<ProviderT> = [
  FEATURE_FLAGS.crifHighMark,
  FEATURE_FLAGS.mysql,
];

export const RESOURCES_FEATURE_FLAGS: {
  [provider in ProviderT]?: Array<string>;
} = {};

const TEMPORARILLY_DISABLED_RESOURCES: {
  [provider in ProviderT | string]?: Array<string>;
} = {
  // Hiding the Equifax US resources until we get full access to the API
  equifax_us: ["mla_report"],
};

// Use for quickly enabling features for all users
const ENABLED_FEATURE_FLAGS: FEATURE_FLAGS[] = [
  FEATURE_FLAGS.sqlEntityId,
  FEATURE_FLAGS.nodeAnalytics,
];

export const isFeatureFlagEnabled = (flag: FEATURE_FLAGS | string): boolean => {
  if (
    flag === FEATURE_FLAGS.powertools &&
    localStorage.getItem("power-tools")
  ) {
    return true;
  }

  // eslint-disable-next-line sonarjs/no-empty-collection
  if (ENABLED_FEATURE_FLAGS.includes(flag as FEATURE_FLAGS)) {
    return true;
  }

  return new URLSearchParams(document.location.search).has(flag);
};

export const hasConnectionCreationEnabled = (provider: ProviderT): boolean => {
  if (CONNECTION_FEATURE_FLAGS.includes(provider)) {
    return isFeatureFlagEnabled(provider as FEATURE_FLAGS);
  }
  return true;
};

export const isTemporarilyDisabledResource = (
  provider: ProviderT | string,
  resource: string,
): boolean => {
  return !!TEMPORARILLY_DISABLED_RESOURCES[provider]?.includes(resource);
};

export const hasResourceCreationEnabled = (
  provider: ProviderT,
  resource: string,
): boolean => {
  if (isTemporarilyDisabledResource(provider, resource)) {
    return false;
  }

  if (!RESOURCES_FEATURE_FLAGS[provider]?.includes(resource)) {
    return true;
  }

  const url_search_params = new URLSearchParams(document.location.search);
  return url_search_params.has(resource);
};

/**
 * Checks the current window URL parameters for feature flags, and appends them
 * to the given relative path.
 * @param path the base path to modify
 * @param additionalParams extra URL parameters you wish appended
 * @returns a new string with query parameters appended
 */
export const appendCurrentFlags = (
  path: string,
  additionalParams: Record<string, string> | string = {},
) => {
  const currentParams = new URLSearchParams(window.location.search);
  const newParams = [...currentParams.entries()].reduce(
    (newParams, [key, value]) => {
      if ((Object as any).values(FEATURE_FLAGS).includes(key))
        newParams.set(key, value);
      return newParams;
    },
    new URLSearchParams(additionalParams),
  );
  // To be able to construct a URL object, we add a dummy domain.
  // TODO @AUTH-2414 Consider handling all URLs as URL objects
  const urlObject = new URL(path, "http://localhost:3000");
  for (const paramEntry of newParams.entries()) {
    urlObject.searchParams.append(paramEntry[0], paramEntry[1]);
  }
  return urlObject.pathname + urlObject.search;
};
