import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faBolt,
  faBracketsCurly,
  faCheck,
  faChevronDown,
  faDiagramNext,
  faPlus,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { faRotateRight } from "@fortawesome/pro-solid-svg-icons";
import { Menu } from "@headlessui/react";
import { AnimatePresence, m } from "framer-motion";
import React, { ReactNode, useState } from "react";
import { usePopper } from "react-popper";
import { twJoin } from "tailwind-merge";

import { DatasetColumnGroups, DesiredType } from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import { Spinner } from "src/base-components/Spinner";
import { Tooltip } from "src/base-components/Tooltip";
import { ProviderIcon } from "src/connections/config/Icon";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { type MockColumn } from "src/datasets/DatasetTable/cells";
import { DatasetContext, JSONValue } from "src/datasets/DatasetTable/types";
import {
  CellId,
  MOCK_COLUMN_DISABLED_TOOLTIP,
  MOCK_COL_SEPARATOR,
  getDesiredType,
  isParentIntegrationNode,
  parseCellId,
} from "src/datasets/DatasetTable/utils";
import { useResourceSample } from "src/datasets/api/queries";
import { DESIRED_TYPE_ICONS, DatasetIntegrationNode } from "src/datasets/utils";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { assertUnreachable } from "src/utils/typeUtils";

export enum ParentNodeMockMode {
  OverallNodeOutput = "overall-node-mock",
  IndividualExternalResponses = "individual-node-mock",
}

const MenuItem: React.FC<{
  id: string;
  children: ReactNode;
  disabledReason?: string;
  isHeader?: boolean;
  onClick: (key: string, event: React.MouseEvent<HTMLDivElement>) => void;
  dataLoc?: string;
}> = (props) => (
  <Menu.Item
    as="div"
    data-loc={props.dataLoc}
    disabled={!!props.disabledReason || props.isHeader}
  >
    {({ disabled }) => (
      <Tooltip
        activated={!!props.disabledReason}
        placement="left"
        title={props.disabledReason}
        triggerClassName="w-full text-left"
      >
        <div
          className={twJoin(
            "px-4 py-2.5 font-medium font-inter-normal-13px",
            !!props.disabledReason || props.isHeader
              ? "cursor-default"
              : "cursor-pointer hover:bg-gray-50",
            !!props.disabledReason ? "text-gray-500" : "text-gray-800",
          )}
          onClick={(event) =>
            !disabled ? props.onClick(props.id, event) : undefined
          }
        >
          {props.children}
        </div>
      </Tooltip>
    )}
  </Menu.Item>
);

type Props = {
  visible: boolean;
  mock: {
    mockFields: MockColumn[];
    integrationNodesExist: boolean;
  };
  input: { inputFields: string[]; schemaIsEmpty: boolean };
  output: { outputFields: string[]; schemaIsEmpty: boolean };
  onDelete?: () => void;
  onDeleteSubMocks?: () => void;
  onAdd: (
    key: string,
    group: DatasetColumnGroups,
    desiredType?: DesiredType,
  ) => void;
  onAddSubMocks?: (
    subMockNames: string[],
    mockColumnName: string,
    desiredType: Extract<DesiredType, "object" | "any">,
  ) => void;
  onAddAdditionalColumn: () => void;
  typeChange?: {
    selectedType: DesiredType;
    compatibleType?: DesiredType;
    onChangeType: (selectedType: DesiredType) => void;
  };
  fillMockColumn?: {
    onFill?: (value: JSONValue) => void;
    integrationNode: DatasetIntegrationNode;
  };
  columnDisabled?: boolean;
  button?: ReactNode;
  placement?: "bottom-start" | "bottom-end";
  buttonAs?: React.ElementType;
  context: DatasetContext;
  cellId?: CellId;
};

type MenuMode =
  | "main"
  | "add-fields-input"
  | "add-fields-mock"
  | "change-type"
  | "add-fields-output"
  | "fill-mock-column";

const menuModeLabelMap: Record<Exclude<MenuMode, "main">, string> = {
  "add-fields-input": "Add input data field",
  "add-fields-mock": "Add mock external data field",
  "add-fields-output": "Add expected output field",
  "change-type": "Change field type",
  "fill-mock-column": "Fill with sample report",
};

const divider = <div className="my-2.5 border-t border-gray-200" />;
const MenuHeader: React.FC<{ title: string }> = ({ title }) => (
  <div className="py-2 pl-5 font-inter-normal-12px">{title}</div>
);

export const ColumnMenu: React.FC<Props> = ({
  cellId,
  visible,
  input,
  output,
  mock,
  onDelete,
  onDeleteSubMocks,
  onAdd,
  onAddSubMocks,
  typeChange,
  fillMockColumn,
  columnDisabled,
  placement = "bottom-start",
  button = (
    <Icon
      color="text-gray-500"
      dataLoc="expand-column-menu-button"
      icon={faChevronDown}
      size="2xs"
    />
  ),
  onAddAdditionalColumn,
  buttonAs: as,
  context,
}) => {
  const [menuMode, setMenuMode] = useState<MenuMode>("main");
  const isAuthoringContext = context === "authoring";

  const typeItems = typeChange
    ? Object.entries(DESIRED_TYPE_ICONS).map(([name, icon]) => (
        <MenuItem
          key={name}
          id={name}
          onClick={(id) => typeChange.onChangeType(id as DesiredType)}
        >
          <div className="flex items-center">
            <Icon color="text-gray-500" icon={icon} size="xs" />
            <span className="px-2 capitalize">{name}</span>
            {typeChange.selectedType !== name &&
              typeChange.compatibleType === name && (
                <Pill size="base" variant="gray">
                  <Pill.Text>Schema type</Pill.Text>
                </Pill>
              )}
            {typeChange.selectedType === name && (
              <>
                <div className="ml-auto" />
                <Icon color="text-indigo-500" icon={faCheck} size="sm" />
              </>
            )}
          </div>
        </MenuItem>
      ))
    : [];

  const inputItems = input.inputFields.map((col) => (
    <MenuItem
      key={col}
      id={col}
      onClick={(id) => {
        setMenuMode("main");
        onAdd(id, "input_columns");
      }}
    >
      {col}
    </MenuItem>
  ));

  const mockItems = mock.mockFields.map(
    ({ name, provider, mediaKey, subMocks }) => {
      const subMocksRendered = subMocks?.map((subMock) => (
        <MenuItem
          key={`${name}-${subMock.name}`}
          id={[name, subMock.name].join(MOCK_COL_SEPARATOR)}
          onClick={(id) => {
            onAdd(id, "mock_columns");
            setMenuMode("main");
          }}
        >
          <div className="flex items-center gap-x-2 pl-7.5">
            {subMock.provider !== NODE_TYPE.FLOW_NODE &&
              subMock.provider !== NODE_TYPE.LOOP_NODE && (
                <ProviderIcon
                  mediaKey={subMock.mediaKey}
                  provider={subMock.provider}
                />
              )}
            {subMock.name}
          </div>
        </MenuItem>
      ));

      return [
        <MenuItem
          key={name}
          dataLoc={`add-fields-mock-${name}`}
          id={name}
          isHeader={Boolean(subMocks)}
          onClick={(id) => {
            if (subMocks) {
              return;
            }
            setMenuMode("main");
            if (provider === NODE_TYPE.LOOP_NODE) {
              onAdd(id, "mock_columns", "any");
            } else {
              onAdd(id, "mock_columns");
            }
          }}
        >
          <div className="flex items-center gap-x-2">
            {provider === NODE_TYPE.FLOW_NODE ||
            provider === NODE_TYPE.LOOP_NODE ? (
              <div className="inline-flex h-6 w-6 items-center justify-center rounded border border-gray-200">
                <Icon
                  icon={
                    provider === NODE_TYPE.FLOW_NODE
                      ? faDiagramNext
                      : faRotateRight
                  }
                  padding={false}
                  size="2xs"
                />
              </div>
            ) : (
              <ProviderIcon mediaKey={mediaKey} provider={provider} />
            )}
            {name}
          </div>
        </MenuItem>,
        subMocksRendered,
      ];
    },
  );

  const expectedOutputItems = output.outputFields.map((col) => (
    <MenuItem
      key={col}
      id={col}
      onClick={(id) => {
        setMenuMode("main");
        onAdd(id, "output_columns");
      }}
    >
      {col}
    </MenuItem>
  ));

  const MainItem = ({
    icon,
    name,
    disabledReason,
    dataLoc,
    ...props
  }: {
    icon: IconProp;
    name: string;
    disabledReason?: string;
    dataLoc?: string;
  } & (
    | { setMode: true; id: MenuMode }
    | { onClick: (key: string) => void; setMode?: false; id: string }
  )) => (
    <MenuItem
      dataLoc={dataLoc}
      disabledReason={disabledReason}
      id={props.id}
      onClick={
        props.setMode
          ? (mode, e) => {
              e.preventDefault();
              setMenuMode(mode as MenuMode);
            }
          : props.onClick
      }
    >
      <div className="flex items-center">
        <Icon color="text-gray-500" icon={icon} size="xs" />
        <span className="px-2">{name}</span>
      </div>
    </MenuItem>
  );

  const hasChildConnectionNodes =
    (fillMockColumn?.integrationNode.mockableChildNodes?.length ?? 0) > 0;

  const isFlowNode =
    fillMockColumn?.integrationNode &&
    isParentIntegrationNode(fillMockColumn?.integrationNode);

  const [parentNodeMockMode, setParentNodeMockMode] =
    useState<ParentNodeMockMode | null>(
      onAddSubMocks || onDeleteSubMocks
        ? onAddSubMocks
          ? ParentNodeMockMode.OverallNodeOutput
          : ParentNodeMockMode.IndividualExternalResponses
        : null,
    );

  const handleParentNodeMockModeChange = (value: string) => {
    setParentNodeMockMode(value as ParentNodeMockMode);

    if (!cellId || !fillMockColumn) {
      return;
    }
    const [, prefixedColumnName] = parseCellId(cellId);
    const columnName = prefixedColumnName.replace("mock_data.", "");

    if (value === ParentNodeMockMode.IndividualExternalResponses) {
      const subMockColumnNames =
        fillMockColumn.integrationNode.mockableChildNodes?.map(
          (childNode) => childNode.name,
        ) ?? [];

      onAddSubMocks?.(
        subMockColumnNames,
        columnName,
        getDesiredType(fillMockColumn.integrationNode),
      );
    } else {
      onDeleteSubMocks?.();
    }
  };

  const mainItems = (
    <>
      {isAuthoringContext && fillMockColumn && fillMockColumn.onFill && (
        <MainItem
          disabledReason={
            columnDisabled
              ? MOCK_COLUMN_DISABLED_TOOLTIP.title
              : parentNodeMockMode ===
                  ParentNodeMockMode.IndividualExternalResponses
                ? "Only accessed from child flow mocks"
                : undefined
          }
          icon={faBolt}
          id="fill-mock-column"
          name={menuModeLabelMap["fill-mock-column"]}
          setMode
        />
      )}
      {typeChange && (
        <MainItem
          icon={faBracketsCurly}
          id="change-type"
          name={menuModeLabelMap["change-type"]}
          setMode
        />
      )}
      {onDelete && (
        <MainItem
          icon={faTrashAlt}
          id="delete"
          name="Delete field"
          onClick={onDelete}
        />
      )}
      {isAuthoringContext &&
        isFlowNode &&
        parentNodeMockMode &&
        hasChildConnectionNodes && (
          <>
            {divider}
            <MenuHeader title="What should be mocked for this node?" />
            <div className="px-4 pb-2">
              <SimpleRadioGroup
                orientation="vertical"
                value={parentNodeMockMode}
                onValueChange={handleParentNodeMockModeChange}
              >
                <SimpleRadioGroup.Item
                  dataLoc="overall-mock-mode-radio"
                  label="Overall node output"
                  labelClassName="pl-2 w-full"
                  value={ParentNodeMockMode.OverallNodeOutput}
                />
                <SimpleRadioGroup.Item
                  dataLoc="individual-node-mock-mode-radio"
                  label="Individual external responses within the Child Flow"
                  labelClassName="pl-2 w-full"
                  value={ParentNodeMockMode.IndividualExternalResponses}
                />
              </SimpleRadioGroup>
            </div>
          </>
        )}
      {(fillMockColumn || typeChange || onDelete) && divider}
      <MenuHeader title="Add fields" />
      <MainItem
        disabledReason={
          inputItems.length === 0
            ? input.schemaIsEmpty
              ? "The input schema has no fields yet"
              : "All the input schema fields are already present as columns in the table"
            : undefined
        }
        icon={faPlus}
        id="add-fields-input"
        name={menuModeLabelMap["add-fields-input"]}
        setMode
      />
      {isAuthoringContext && (
        <MainItem
          dataLoc="add-fields-mock"
          disabledReason={
            mockItems.length === 0
              ? mock.integrationNodesExist
                ? "All the Integration Nodes are already present as columns in the table"
                : "There are no Integration Nodes in this flow version"
              : undefined
          }
          icon={faPlus}
          id="add-fields-mock"
          name={menuModeLabelMap["add-fields-mock"]}
          setMode
        />
      )}
      {isAuthoringContext && (
        <MainItem
          disabledReason={
            expectedOutputItems.length === 0
              ? output.schemaIsEmpty
                ? "The output schema has no fields yet"
                : "All the output schema fields are already present as columns in the table"
              : undefined
          }
          icon={faPlus}
          id="add-fields-output"
          name={menuModeLabelMap["add-fields-output"]}
          setMode
        />
      )}
      <MainItem
        icon={faPlus}
        id="add-fields-aux"
        name="Add additional column"
        onClick={onAddAdditionalColumn}
      />
    </>
  );

  const [menuRef, setMenuRef] = useState<Nullable<HTMLElement>>(null);
  const [itemsRef, setItemsRef] = useState<Nullable<HTMLDivElement>>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    menuRef,
    itemsRef,
    {
      strategy: "fixed",
      placement,
      modifiers: [{ name: "offset", options: { offset: [8, 4] } }],
    },
  );

  const renderItems = (close: () => void) => {
    switch (menuMode) {
      case "add-fields-input":
        return inputItems;
      case "add-fields-mock":
        return mockItems;
      case "add-fields-output":
        return expectedOutputItems;
      case "change-type":
        return typeItems;
      case "main":
        return mainItems;
      case "fill-mock-column":
        return (
          fillMockColumn &&
          fillMockColumn.onFill && (
            <MockFillOptions
              {...fillMockColumn}
              onFill={(value) => {
                fillMockColumn.onFill?.(value);
                close();
                setMenuMode("main");
              }}
            />
          )
        );
      default:
        assertUnreachable(menuMode);
    }
  };

  const renderHeader = () => {
    if (menuMode !== "main") {
      const currentLabel = menuModeLabelMap[menuMode];
      return (
        <div className="flex gap-x-2 px-4 py-2.5 text-gray-800 font-inter-semibold-13px">
          <Icon
            color="text-gray-500"
            icon={faArrowLeft}
            size="xs"
            onClick={() => setMenuMode("main")}
          />
          <span>{currentLabel}</span>
        </div>
      );
    }
  };

  return (
    <Menu ref={setMenuRef} as="div" className="ml-auto">
      {({ open, close }) => (
        <>
          <Menu.Button
            as={as}
            className={visible || open ? "visible" : "invisible"}
            data-loc={`column-menu_${cellId}`}
          >
            {button}
          </Menu.Button>
          <AnimatePresence>
            {open && (
              <m.div
                key="column_menu"
                ref={setItemsRef}
                style={{
                  ...popperStyles.popper,
                  maxHeight: menuRef
                    ? `calc(100vh - ${menuRef.scrollHeight}px)`
                    : undefined,
                }}
                {...popperAttributes.popper}
                animate={{ opacity: 1, transition: { duration: 0.2 } }}
                className="z-50 min-h-[136px] w-[273px] overflow-y-auto rounded-lg bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none"
                data-loc="column-menu-panel"
                exit={{ opacity: 0, transition: { duration: 0.1 } }}
                initial={{ opacity: 0 }}
              >
                <Menu.Items static>
                  {renderHeader()}
                  {renderItems(close)}
                </Menu.Items>
              </m.div>
            )}
          </AnimatePresence>
        </>
      )}
    </Menu>
  );
};

const MockFillOptions = ({
  onFill,
  integrationNode,
}: {
  integrationNode: DatasetIntegrationNode;
  onFill: (value: JSONValue) => void;
}) => {
  const { workspace } = useAuthoringContext();
  const resourceSample = useResourceSample({
    baseUrl: workspace.base_url!,
    integrationNode,
  });

  return resourceSample.data ? (
    <>
      {resourceSample.data.map((sample) => (
        <MenuItem
          key={sample.name}
          id={sample.name}
          onClick={() => onFill(sample.sample)}
        >
          {sample.name}
        </MenuItem>
      ))}
    </>
  ) : (
    <Spinner />
  );
};
