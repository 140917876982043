import { faExpand } from "@fortawesome/pro-regular-svg-icons";
import { Popover } from "@headlessui2/react";
import React, { useState } from "react";
import ReactDOM from "react-dom";

import { GenericObjectT } from "src/api/flowTypes";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { Icon } from "src/base-components/Icon";
import { ObjectDetailPane } from "src/dataTable/ObjectDetailPane";
import { FloatingWindowInternal } from "src/datasets/DatasetTable/FloatingWindow";
import { copyTextToClipboard } from "src/utils/clipboard";

type PropsT = {
  dataLoc?: string;
  value: GenericObjectT;
  field: string;
};

export const JSONValueViewer: React.FC<PropsT> = ({
  dataLoc,
  value,
  field,
}) => {
  const [showExpanded, setShowExpanded] = useState(false);
  return (
    <div className="relative w-full">
      <div className="absolute right-5 top-2 z-10 flex items-center justify-end gap-x-1">
        <CopyTextIcon
          feedback="inline"
          size="2xs"
          value={() => JSON.stringify(value)}
        />
        <Icon
          color="text-gray-500 hover:text-gray-800"
          icon={faExpand}
          size="2xs"
          onClick={() => setShowExpanded(!showExpanded)}
        />
        {showExpanded &&
          ReactDOM.createPortal(
            <Popover>
              <FloatingWindowInternal
                draggable={false}
                isMaximized={true}
                isPinned={false}
                maximizable={false}
                style={undefined}
                title={field}
                onClose={() => setShowExpanded(false)}
                onCopy={() => copyTextToClipboard(JSON.stringify(value))}
              >
                <ObjectDetailPane
                  dataLoc={dataLoc}
                  defaultInspectDepth={3}
                  enableClipboard={false}
                  innerDimensionClass="h-full"
                  jsonObject={value}
                />
              </FloatingWindowInternal>
            </Popover>,
            document.body,
          )}
      </div>
      <ObjectDetailPane
        dataLoc={dataLoc}
        defaultInspectDepth={3}
        enableClipboard={false}
        innerDimensionClass="max-h-44"
        jsonObject={value}
      />
    </div>
  );
};
