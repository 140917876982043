import { faXmark, faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import React, { useMemo } from "react";

import { useVersionResourceLock } from "src/authoringMultiplayerLock/useVersionResourceLock";
import { Icon } from "src/base-components/Icon";
import { ResourceType } from "src/clients/flow-api";
import { DOCS_FUNDAMENTALS_PARAMETERS } from "src/constants/ExternalLinks";
import { ParametersList } from "src/globalParameters/GlobalParametersList";
import { useCanAuthoringEditFlowVersion } from "src/hooks/useCanAuthoringEditFlowVersion";
import { EditorLock } from "src/multiplayer/EditorLock";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { AuthorPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type PropsT = {
  onRequestClose: () => void;
};

type GlobalParametersSidePaneHeaderT = {
  onRequestClose: () => void;
};

const GlobalParametersSidePaneHeader: React.FC<
  GlobalParametersSidePaneHeaderT
> = ({ onRequestClose }) => {
  const { version_id } = useParamsDecode<AuthorPageParamsT>();

  const { lockOwner, lockedByOtherUser } = useVersionResourceLock(
    ResourceType.PARAMETER,
    version_id,
  );

  return (
    <div>
      <div className="px-6">
        <div className="flex w-full flex-row items-center">
          <div className="flex h-[27px] w-full items-center justify-between text-gray-800 font-inter-semibold-16px">
            <div className="shrink-0">Parameters</div>
            <div className="max-w-66">
              {lockOwner && lockedByOtherUser && (
                <EditorLock lockOwner={lockOwner} />
              )}
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-2">
            <a
              href={DOCS_FUNDAMENTALS_PARAMETERS}
              rel="noreferrer"
              target="_blank"
            >
              <Icon
                color="text-gray-500 hover:text-gray-700"
                icon={faQuestionCircle}
                size="xs"
              />
            </a>
            <Icon
              color="text-gray-500 hover:text-gray-700"
              dataLoc="close-version-side-pane"
              icon={faXmark}
              size="xs"
              onClick={onRequestClose}
            />
          </div>
        </div>
      </div>
      <div className="mt-1 flex grow px-6">
        <p className="text-gray-500 font-inter-normal-12px">
          Create and edit Parameters for this Decision Flow version.
        </p>
      </div>
      <div className="mb-1 mt-4 px-6">
        <div className="h-px w-full rounded-full bg-gray-100"></div>
      </div>
    </div>
  );
};

export const GlobalParametersSidePane: React.FC<PropsT> = ({
  onRequestClose,
}) => {
  const { version, workspace, flow } = useAuthoringContext();
  const canEditParameters = useCanAuthoringEditFlowVersion();

  // This is used as dependency for a useEffect in the ParametersList
  const analyzeUsage = useMemo(
    () => ({ baseUrl: workspace.base_url!, flowSlug: flow.slug }),
    [flow.slug, workspace.base_url],
  );

  return (
    <div className="flex h-full w-128 flex-col border-l border-gray-200 bg-white pt-6">
      <GlobalParametersSidePaneHeader onRequestClose={onRequestClose} />
      <div className="decideScrollbar mt-5 flex h-full flex-col overflow-y-auto px-6">
        <ParametersList
          analyzeUsage={analyzeUsage}
          isReadonly={!canEditParameters}
          params={version.parameters}
          versionId={version.id}
        />
      </div>
    </div>
  );
};
