import { faWebhook } from "@fortawesome/pro-regular-svg-icons";
import { AxiosError } from "axios";
import React, { useCallback, useMemo, useState } from "react";
import { twJoin } from "tailwind-merge";

import { DecideDecisionError } from "src/api/types";
import { EmptyState } from "src/base-components/EmptyState";
import { TableComp as Table } from "src/base-components/Table";
import { toastFailure } from "src/base-components/Toast/utils";
import { Webhook } from "src/clients/flow-api";
import { useFlowContext } from "src/router/routerContextHooks";
import { HistoryDetailModal } from "src/webhooks/HistoryDetailModal";
import { webhookResponse } from "src/webhooks/HistorySidebar/webhookUtils";
import { WebhooksFilter } from "src/webhooks/WebhooksPage";
import { getOutgoingWebhooksColumns } from "src/webhooks/WebhooksTable/tableConfig";
import {
  useTestOutgoingWebhook,
  OutgoingWebhookResponse,
} from "src/webhooks/queries";

const failedTestTitle = "Webhook test failed";

type Props = {
  webhooks: Webhook[];
  selectedWebhook: Webhook | null;
  filter: WebhooksFilter | null;
  onDelete: (toEdit: Webhook) => void;
  onEdit: (toEdit: Webhook) => void;
  onSelect: (webhook: Webhook) => void;
};

export const OutgoingWebhooksTable: React.FC<Props> = ({
  webhooks,
  filter,
  onDelete,
  onEdit,
  onSelect,
  selectedWebhook,
}) => {
  const { workspace } = useFlowContext();
  const [webhookKeyBeingTested, setWebhookKeyBeingTested] = useState<string>();
  const [testWebhookResponse, setTestWebhookResponse] =
    useState<OutgoingWebhookResponse>();
  const filteredWebhooks = useMemo(() => {
    switch (filter) {
      case "active":
        return webhooks.filter((webhook) => webhook.active);

      case "inactive":
        return webhooks.filter((webhook) => !webhook.active);

      default:
        return webhooks;
    }
  }, [filter, webhooks]);

  const hasNoFilteredResults =
    webhooks.length > 0 && filteredWebhooks.length === 0;

  const testWebhooksQuery = useTestOutgoingWebhook();

  const onTestWebhook = useCallback(
    async (key: string) => {
      setWebhookKeyBeingTested(key);
      try {
        const testResponse = await testWebhooksQuery.mutateAsync({
          baseUrl: workspace.base_url,
          webhookKey: key,
          workspaceSlug: workspace.slug,
        });
        setTestWebhookResponse(webhookResponse(testResponse));
      } catch (err) {
        const error: AxiosError = err as AxiosError;
        if (error?.isAxiosError) {
          if (error.response?.status === 401) {
            toastFailure({
              title: failedTestTitle,
              description: "Unauthorized to test webhook",
            });
          } else {
            const castedError = error.response?.data as DecideDecisionError;
            const response = webhookResponse(castedError);
            if (!response) {
              toastFailure({
                title: failedTestTitle,
              });
            } else {
              setTestWebhookResponse(response);
            }
          }
        } else {
          toastFailure({
            title: failedTestTitle,
          });
        }
      }
      setWebhookKeyBeingTested(undefined);
    },
    [testWebhooksQuery, workspace.base_url, workspace.slug],
  );

  const columns = useMemo(
    () =>
      getOutgoingWebhooksColumns(
        onDelete,
        onEdit,
        onTestWebhook,
        webhookKeyBeingTested,
      ),
    [onDelete, onEdit, onTestWebhook, webhookKeyBeingTested],
  );

  return (
    <>
      <div className="flex flex-col gap-y-5">
        <div
          className={twJoin(
            "overflow-hidden rounded-lg border border-gray-200 bg-white",
            hasNoFilteredResults && "h-66",
          )}
        >
          {filteredWebhooks.length > 0 && (
            <Table
              columns={columns}
              data={filteredWebhooks}
              dataLoc="webhooks-table"
              rowPropsGetter={(row) => ({
                "data-loc": `webhook-${row.original.name}`,
                classNameOverrides: twJoin(
                  "group h-px cursor-pointer border-b border-b-gray-100 p-0 transition-colors last:border-b-0 hover:bg-gray-50",
                  selectedWebhook?.key === row.original.key && "is-selected",
                ),
                onClick: () => onSelect(row.original),
              })}
            />
          )}
          {hasNoFilteredResults && (
            <EmptyState
              description={`${
                filter === "active" ? "Active" : "Inactive"
              } webhooks will appear here`}
              headline={`No ${filter} Webhooks yet`}
              icon={faWebhook}
            />
          )}
        </div>
      </div>
      <HistoryDetailModal
        isOpen={testWebhookResponse !== undefined}
        response={testWebhookResponse}
        onClose={() => {
          setTestWebhookResponse(undefined);
        }}
      />
    </>
  );
};
