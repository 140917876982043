import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Transition } from "@headlessui/react";
import { Arrow, Content, Portal, Root, Trigger } from "@radix-ui/react-tooltip";
import React, { useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";

export const tooltipWrapperClass = "tktl-tooltip-wrapper" as const;
type IconAction = {
  icon: IconProp;
  label?: never;
  onClick?: (e: React.MouseEvent) => void;
  onMouseDownCapture?: (e: React.MouseEvent) => void;
};

type LabelAction = {
  icon?: never;
  label: string;
  onClick: (e: React.MouseEvent) => void;
};

export const TooltipCode = ({ children }: { children: React.ReactNode }) => {
  return (
    <code className="inline-block h-[20px] rounded-md bg-gray-700 px-1 text-3xs">
      {children}
    </code>
  );
};

export type TooltipContentProps = {
  placement: "top" | "right" | "bottom" | "left";
  placementOffset?: number;
  align?: "start" | "center" | "end";
  contentMaxW?: "xs" | "sm" | "none";
  forceBreak?: boolean;
  title?: React.ReactNode;
  body?: React.ReactNode;
  data_loc?: string;
  alignOffset?: number;
  action?: LabelAction | IconAction;
};
export const TooltipContent: React.FC<TooltipContentProps> = ({
  placement,
  placementOffset = 1,
  align,
  body,
  title,
  alignOffset = -6,
  data_loc,
  contentMaxW,
  action,
  forceBreak = false,
}) => (
  <Portal>
    <Content
      align={align}
      alignOffset={alignOffset}
      className={twMerge(
        "z-50 bg-gray-800 fill-gray-800 text-white",
        body ? "rounded-xl px-4 py-4" : "rounded-lg px-3 py-2",
        contentMaxW === "xs" && "max-w-xs",
        contentMaxW === "sm" && "max-w-sm",
        forceBreak && "break-all",
      )}
      data-loc={data_loc}
      side={placement}
      sideOffset={placementOffset}
    >
      {title !== undefined && (
        <div className="flex items-center justify-between gap-x-1">
          <div className="break-words font-inter-semibold-13px">{title}</div>
          {action?.icon !== undefined && (
            <Icon
              icon={action.icon}
              size="xs"
              onClick={action.onClick}
              onMouseDownCapture={action.onMouseDownCapture}
            />
          )}
        </div>
      )}
      {body !== undefined && (
        <div
          className={twMerge(
            "break-words font-inter-normal-12px",
            Boolean(title) && "mt-1",
          )}
        >
          {body}
        </div>
      )}
      {action?.label !== undefined && (
        <button
          className="mt-2 underline font-inter-semibold-13px"
          onClick={action.onClick}
        >
          {action.label}
        </button>
      )}
      <Arrow />
    </Content>
  </Portal>
);

export type TooltipPropsT = {
  children: React.ReactNode;
  activated?: boolean;
  animate?: boolean;
  asChild?: boolean;
  triggerAs?: "button" | "div";
  triggerClassName?: string;
  delayDuration?: number;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
};

export const Tooltip: React.FC<TooltipPropsT & TooltipContentProps> = ({
  title,
  body,
  placement,
  placementOffset,
  data_loc,
  children,
  align = "start",
  alignOffset = undefined,
  animate = false,
  activated = true,
  asChild = false,
  triggerAs = "button",
  contentMaxW = "xs",
  forceBreak = false,
  triggerClassName,
  delayDuration,
  action,
  onOpenChange,
  open = undefined,
}) => {
  const [isShowing, setIsShowing] = useState(false);

  return (
    <Root
      delayDuration={delayDuration}
      open={activated ? open : false}
      onOpenChange={(isOpen) => {
        setIsShowing(isOpen);
        onOpenChange?.(isOpen);
      }}
    >
      <Trigger
        asChild={asChild || triggerAs !== "button"}
        className={twJoin(triggerClassName, !asChild && tooltipWrapperClass)}
        tabIndex={-1}
        type={
          asChild || triggerAs !== "button" ? undefined : "button"
        } /* avoid the button accidentally becoming a submit if placed within a React Form */
      >
        {triggerAs === "div" && !asChild ? <div>{children} </div> : children}
      </Trigger>

      {animate ? (
        <Transition
          className="z-20"
          enter="transition ease-out"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          show={isShowing}
          /* Fix: Transition does not animate without unmount=false */
          unmount={false}
        >
          <TooltipContent
            action={action}
            align={align}
            alignOffset={alignOffset}
            body={body}
            contentMaxW={contentMaxW}
            data_loc={data_loc}
            forceBreak={forceBreak}
            placement={placement}
            placementOffset={placementOffset}
            title={title}
          />
        </Transition>
      ) : (
        <TooltipContent
          action={action}
          align={align}
          alignOffset={alignOffset}
          body={body}
          contentMaxW={contentMaxW}
          data_loc={data_loc}
          forceBreak={forceBreak}
          placement={placement}
          placementOffset={placementOffset}
          title={title}
        />
      )}
    </Root>
  );
};
