import { faPlus, faWebhook } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import React from "react";
import { twJoin } from "tailwind-merge";

import { useDeleteConnection } from "src/api/connectApi/queries";
import { Button } from "src/base-components/Button";
import { EmptyState } from "src/base-components/EmptyState";
import { LoadingView } from "src/base-components/LoadingView";
import { TextConfirmationModal } from "src/base-components/TextConfirmationModal";
import { toastFailure, toastSuccess } from "src/base-components/Toast/utils";
import { Webhook } from "src/clients/flow-api";
import { SubHeader, SubHeaderFilter } from "src/flow/SubHeader";
import { maxTableWidth } from "src/layout/constants";
import { useFlowContext } from "src/router/routerContextHooks";
import { HistorySidebar } from "src/webhooks/HistorySidebar/HistorySidebar";
import { OutgoingWebhooksTable } from "src/webhooks/WebhooksTable/WebhooksTable";
import { EditOutgoingWebhookModal } from "src/webhooks/editModal/EditOutgoingWebhookModal";
import {
  useDeleteOutgoingWebhook,
  useFlowOutgoingWebhooks,
} from "src/webhooks/queries";

export type WebhooksFilter = "active" | "inactive";
export const MAX_WEBHOOKS = 10;

export const OutgoingWebhooksPage: React.FC = () => {
  const { flow, workspace } = useFlowContext();
  const webhooksQuery = useFlowOutgoingWebhooks(flow.id);
  const [webhookToEdit, setWebhookToEdit] = useState<Webhook | null>(null);
  const [webhookToDelete, setWebhookToDelete] = useState<Webhook | null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [selectedWebhook, selectWebhook] = useState<Webhook | null>(null);
  const [filter, setFilter] = useState<WebhooksFilter | null>(null);
  const hasLimitReached = (webhooksQuery.data?.length ?? 0) >= MAX_WEBHOOKS;

  const deleteWebhookQuery = useDeleteOutgoingWebhook();
  const deleteConnectionQuery = useDeleteConnection(workspace.base_url);

  const handleSelect = (webhook: Webhook) => {
    if (webhook !== selectedWebhook) {
      selectWebhook(webhook);
    } else {
      selectWebhook(null);
    }
  };

  const deleteWebhook = async (webhook: Webhook) => {
    let webhookDeleted = false;
    try {
      await deleteWebhookQuery.mutateAsync(webhook);
      webhookDeleted = true;
      await deleteConnectionQuery.mutateAsync(webhook.connection_id);
    } finally {
      if (!webhookDeleted) {
        toastFailure({
          title: "Failed to delete webhook",
          description: "Webhook could not be deleted.",
          withSidebar: true,
        });
      } else {
        toastSuccess({
          title: "Webhook deleted",
          description: "Webhook was deleted successfully.",
          withSidebar: true,
        });
      }
    }
  };

  return (
    <>
      <SubHeader title="Webhooks" paddedParent>
        <SubHeaderFilter
          options={[
            { key: "active", value: "Active" },
            { key: "inactive", value: "Inactive" },
          ]}
          selected={filter}
          onChange={setFilter}
        />
        <SubHeader.Button
          dataLoc="create-webhook"
          disabled={webhooksQuery.isLoading || hasLimitReached}
          icon={faPlus}
          tooltip={
            hasLimitReached
              ? `You can create up to ${MAX_WEBHOOKS} webhooks under the same Decision Flow.`
              : "Create webhook"
          }
          onClick={() => {
            setEditModalOpen(true);
          }}
        />
      </SubHeader>
      <div className="flex">
        <div
          className={twJoin(
            "relative mx-auto max-h-full flex-grow",
            maxTableWidth,
          )}
        >
          <LoadingView
            queryResult={webhooksQuery}
            renderUpdated={(webhooks: Webhook[]) =>
              webhooks.length === 0 ? (
                <div className="h-118 rounded-lg border border-gray-200 bg-white">
                  <EmptyState
                    action={
                      <Button
                        dataLoc="create-webhook"
                        iconLeft={faPlus}
                        variant="secondary"
                        onClick={() => {
                          setEditModalOpen(true);
                        }}
                      >
                        Create webhook
                      </Button>
                    }
                    description="Webhooks notify your services of specific events. Create one to stay informed."
                    headline="No webhooks yet"
                    icon={faWebhook}
                  />
                </div>
              ) : (
                <OutgoingWebhooksTable
                  filter={filter}
                  selectedWebhook={selectedWebhook}
                  webhooks={webhooks}
                  onDelete={(webhook) => setWebhookToDelete(webhook)}
                  onEdit={(toEdit: Webhook) => {
                    setWebhookToEdit(toEdit);
                    setEditModalOpen(true);
                  }}
                  onSelect={(webhook) => handleSelect(webhook)}
                />
              )
            }
          />
          <EditOutgoingWebhookModal
            open={editModalOpen}
            webhookToEdit={webhookToEdit}
            onClose={() => {
              setEditModalOpen(false);
              // Let the transition finish
              setTimeout(() => {
                setWebhookToEdit(null);
              }, 500);
            }}
          />
        </div>
        {Boolean(selectedWebhook) && <div className="ml-8 w-[447px]" />}
      </div>
      <HistorySidebar
        isOpen={Boolean(selectedWebhook)}
        webhook={selectedWebhook}
        onClose={() => selectWebhook(null)}
      />
      <TextConfirmationModal
        challengeText={webhookToDelete?.name}
        description="Are you sure you want to delete this webhook? This action is notreversible."
        label="Type webhook name"
        open={!!webhookToDelete}
        title={`Confirm delete ${webhookToDelete?.name}`}
        onClose={() => {
          setWebhookToDelete(null);
        }}
        onConfirm={async () => {
          if (webhookToDelete) {
            await deleteWebhook(webhookToDelete);
          }
        }}
      />
    </>
  );
};
