import { useEffect } from "react";

import { FlowVersionT } from "src/api/flowTypes";
import {
  etag_including_comments,
  useLoadChangeHistory,
} from "src/changeHistory/queries";

export const useLatestReviewCommentFlowVersionEtag = (
  version: FlowVersionT,
  userId: string | undefined,
) => {
  const {
    data: reviewCommentPages,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useLoadChangeHistory(version.id, etag_including_comments(version.etag), {
    includeLogic: false,
    includeReviewComments: true,
  });

  const latestedReviewComment = reviewCommentPages?.pages
    .flat()
    .filter((change) => change.comment && change.comment.author === userId)
    .at(0);

  useEffect(() => {
    if (
      latestedReviewComment === undefined &&
      !isFetchingNextPage &&
      hasNextPage
    )
      fetchNextPage();
  }, [
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    latestedReviewComment,
    reviewCommentPages,
  ]);
  return latestedReviewComment?.etag;
};
