import Avatar from "boring-avatars";
import React, { useState } from "react";
import { twJoin } from "tailwind-merge";

import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { OrganizationUser } from "src/clients/taktile-api";
import { assertUnreachable } from "src/utils/typeUtils";

const FALLBACK_COLORS = ["#5946E5", "#6366F1", "#A7F3D0", "#22D3EE", "#493FC1"];

type UserAvatarSize = "xs" | "sm" | "md" | "lg" | "base";
export type BorderColor = "border-white";

type Props = {
  user: Pick<OrganizationUser, "full_name" | "avatar_url" | "username">;
  size: UserAvatarSize;
  borderColor?: BorderColor;
  inline?: boolean;
};

const getSize = (
  size: UserAvatarSize,
): {
  height: `h-${number}`;
  width: `w-${number}`;
  classname: string;
  pixels: number;
} => {
  switch (size) {
    case "xs":
      return {
        height: "h-4",
        width: "w-4",
        classname: "h-4 w-4",
        pixels: 16,
      };

    case "sm":
      return {
        height: "h-4.5",
        width: "w-4.5",
        classname: "h-4.5 w-4.5",
        pixels: 18,
      };

    case "base":
      return {
        height: "h-7",
        width: "w-7",
        classname: "h-7 w-7",
        pixels: 28,
      };

    case "md":
      return {
        height: "h-8",
        width: "w-8",
        classname: "h-8 w-8",
        pixels: 32,
      };

    case "lg":
      return {
        height: "h-32",
        width: "w-32",
        classname: "h-32 w-32",
        pixels: 128,
      };

    default:
      return assertUnreachable(size);
  }
};

export const UserAvatarLoading: React.FC<{ size: UserAvatarSize }> = ({
  size,
}) => {
  const sizeInfo = getSize(size);
  return (
    <SkeletonPlaceholder
      height={sizeInfo.height}
      rounded="rounded-full"
      width={sizeInfo.width}
      shrink0
    />
  );
};

export const UserAvatar: React.FC<Props> = ({
  user,
  size,
  borderColor,
  inline,
}) => {
  const [isFailed, setIsFailed] = useState(false);
  const initialLetter = (user.full_name || user.username || "").substring(0, 1);
  if (user.avatar_url && !isFailed) {
    return (
      <div
        className={twJoin(
          "flex shrink-0 justify-center overflow-hidden rounded-full",
          borderColor && [borderColor, "border-2"],
          inline && "inline-block align-middle",
        )}
      >
        <img
          alt={user.full_name || user.username}
          className={twJoin("object-cover", getSize(size).classname)}
          src={user.avatar_url}
          onError={() => setIsFailed(true)}
        />
      </div>
    );
  }

  return (
    <div className="relative flex shrink-0 items-center justify-center overflow-hidden rounded-full">
      <Avatar
        colors={FALLBACK_COLORS}
        name={user.full_name || user.username}
        size={getSize(size).pixels}
        variant="marble"
      />
      <span
        className={twJoin(
          "absolute uppercase text-white text-shadow-sm",
          size === "lg" && "scale-[2.5] font-inter-semibold-16px",
          size === "md" && "font-inter-semibold-14px",
          size === "sm" && "font-inter-medium-12px",
        )}
      >
        {initialLetter}
      </span>
    </div>
  );
};
