import { makeConnectionTemplate } from "src/connections/types";

const permissiblePurposeElements: { key: string; value: string }[] = [
  { key: "AR", value: "AR" },
  { key: "BN", value: "BN" },
  { key: "CF", value: "CF" },
  { key: "CI", value: "CI" },
  { key: "CL", value: "CL" },
  { key: "CP", value: "CP" },
  { key: "CR", value: "CR" },
  { key: "CS", value: "CS" },
  { key: "CT", value: "CT" },
  { key: "EF", value: "EF" },
  { key: "EP", value: "EP" },
  { key: "GF", value: "GF" },
  { key: "GR", value: "GR" },
  { key: "IM", value: "IM" },
  { key: "IN", value: "IN" },
  { key: "IS", value: "IS" },
  { key: "MS", value: "MS" },
  { key: "PR", value: "PR" },
  { key: "RA", value: "RA" },
  { key: "RP", value: "RP" },
  { key: "SI", value: "SI" },
  { key: "TS", value: "TS" },
];

export const TransUnionTemplate = makeConnectionTemplate({
  provider: "trans_union",
  secrets: [
    {
      key: "subscriber_code_password",
      name: "Subscriber code password",
      required: true,
      hint: "The subscriber code password is provided directly by TransUnion",
    },
    {
      key: "certificate",
      name: "Certificate",
      required: true,
      hint: "The chain of certificates should be in PEM format and start with the leaf certificate",
    },
    {
      key: "root_ca_certificate",
      name: "Root CA certificate",
      required: true,
      hint: "The root CA certificate should be in PEM format",
    },
    {
      key: "private_key",
      name: "Private key",
      required: true,
      hint: "The private key should be in PEM format",
    },
  ],
  resources: {
    tru_vision_model_report: {
      name: "TruVision Model Report",
      configKeys: [
        {
          key: "permissible_purpose",
          required: true,
          name: "Permissible purpose",
          type: "simple_selection",
          hint: `You are legally required to provide a permissible purpose for obtaining the requested report. Please select the purpose that best describes your reason for accessing the report. Refer to our docs to understand the meaning of the codes.`,
          elements: permissiblePurposeElements,
        },
        {
          key: "subscriber_member_code",
          required: true,
          name: "Subscriber member code",
          type: "text",
          hint: `The member code is part of your organization's subscriber code, which is provided directly by TransUnion`,
        },
        {
          key: "subscriber_industry_code",
          required: true,
          name: "Subscriber industry code",
          type: "text",
          hint: `The industry code is part of your organization's subscriber code, which is provided directly by TransUnion`,
        },
        {
          key: "subscriber_prefix_code",
          required: true,
          name: "Subscriber prefix code",
          type: "text",
          hint: `The prefix code is part of your organization's subscriber code, which is provided directly by TransUnion`,
        },
      ],
    },
  },
});
