import {
  faArrowLeft,
  faCheck,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { Menu } from "@headlessui/react";
import { AnimatePresence, m } from "framer-motion";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import { twJoin, twMerge } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { OrgWithWorkspaces } from "src/layout/types";

type Value =
  | { orgId: string | undefined; workspaceId: string | undefined }
  | undefined;

type Props = {
  selected: Value;
  onSelect: (value: Value) => void;
  orgs: OrgWithWorkspaces[];
};

type MenuMode = { type: "org" } | { type: "workspace"; org: OrgWithWorkspaces };
const ELEMENTS_MAX_HEIGHT = "max-h-[200px]";

export const OrgAndWorkspaceDropdown: React.FC<Props> = ({
  orgs,
  onSelect,
  selected,
}) => {
  const [menuMode, setMenuMode] = useState<MenuMode>({ type: "org" });

  const [menuRef, setMenuRef] = useState<Nullable<HTMLElement>>(null);
  const [itemsRef, setItemsRef] = useState<Nullable<HTMLDivElement>>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    menuRef,
    itemsRef,
    {
      strategy: "absolute",
      placement: "bottom-start",
      modifiers: [{ name: "offset", options: { offset: [0, 2] } }],
    },
  );
  const selectedOrg = orgs.find((org) => org.id === selected?.orgId);
  const selectedWorkspace = selectedOrg?.workspaces?.find(
    (workspace) => workspace.id === selected?.workspaceId,
  );
  return (
    <Menu ref={setMenuRef} as="div" className="w-full max-w-full">
      {({ open }) => (
        <>
          <Menu.Button
            className={twMerge(
              "font-inter-normal-12px",
              "flex h-8 w-full items-center justify-center rounded-lg",
              "border border-gray-200 bg-white",
              "ring-inset focus:border-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500/25",
              (!selectedOrg || !selectedWorkspace) && "text-gray-500",
              open &&
                "border-indigo-400 outline-none ring-2 ring-indigo-500/25",
            )}
            data-loc="org-workspace-dropdown"
          >
            <div className="flex w-full items-center gap-x-1 py-1.5 pl-3 pr-2">
              <div>
                {!selectedOrg || !selectedWorkspace ? (
                  "Select Organization and Workspace"
                ) : (
                  <span className="flex items-center gap-x-1">
                    {`${selectedOrg?.name}, ${selectedWorkspace?.name}`}
                  </span>
                )}
              </div>
              <div className="ml-auto flex h-auto items-center">
                <Icon color="text-gray-500" icon={faChevronDown} size="xs" />
              </div>
            </div>
          </Menu.Button>
          <AnimatePresence>
            {open && (
              <m.div
                key="org-and-workspace-dropdown"
                ref={setItemsRef}
                data-loc="items"
                style={{
                  ...popperStyles.popper,
                  maxHeight: menuRef
                    ? `calc(100vh - ${menuRef.scrollHeight}px)`
                    : undefined,
                }}
                {...popperAttributes.popper}
                animate={{ opacity: 1, transition: { duration: 0.2 } }}
                className="z-50 w-[27rem] rounded-lg bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none"
                exit={{ opacity: 0, transition: { duration: 0.1 } }}
                initial={{ opacity: 0 }}
              >
                <Menu.Items
                  className={twJoin(
                    "overflow-y-auto text-gray-800 font-inter-normal-13px",
                    ELEMENTS_MAX_HEIGHT,
                  )}
                  static
                >
                  {menuMode.type === "org" && (
                    <>
                      {orgs.length !== 0 && (
                        <>
                          {orgs.map((org) => (
                            <Menu.Item
                              key={org.id}
                              as="div"
                              className="group flex h-12 cursor-pointer items-center justify-between px-4 hover:bg-gray-50"
                              data-loc={`select-${org.name}`}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setMenuMode({
                                  type: "workspace",
                                  org: org,
                                });
                              }}
                            >
                              {org.name}
                              <div className="flex gap-x-2">
                                {selected?.orgId === org.id && (
                                  <Icon
                                    color="text-indigo-500"
                                    icon={faCheck}
                                    size="xs"
                                  />
                                )}
                                <div className="hidden group-hover:block">
                                  <Icon
                                    color="text-gray-500"
                                    icon={faChevronRight}
                                    size="xs"
                                  />
                                </div>
                              </div>
                            </Menu.Item>
                          ))}
                        </>
                      )}
                    </>
                  )}
                  {menuMode.type === "workspace" && (
                    <WorkspaceSection
                      org={menuMode.org}
                      selected={selected}
                      onExit={() => setMenuMode({ type: "org" })}
                      onSelect={onSelect}
                    />
                  )}
                </Menu.Items>
              </m.div>
            )}
          </AnimatePresence>
        </>
      )}
    </Menu>
  );
};

const WorkspaceSection: React.FC<{
  org: OrgWithWorkspaces;
  selected: Value;
  onSelect: (value: Value) => void;
  onExit: () => void;
}> = ({ onExit, onSelect, selected, org }) => {
  return (
    <div
      className={twJoin(
        "flex min-h-0 flex-col overflow-hidden",
        ELEMENTS_MAX_HEIGHT,
      )}
    >
      <div className="flex min-h-[3rem] items-center pl-4 text-gray-800 font-inter-medium-13px">
        <Icon
          color="text-gray-500"
          icon={faArrowLeft}
          size="sm"
          onClick={onExit}
        />
        <span className="ml-2">{org.name}</span>
      </div>
      <div className="grow overflow-y-auto">
        {org.workspaces?.map((workspace) => {
          const isSelected = selected?.workspaceId === workspace.id;
          return (
            <Menu.Item
              key={workspace.id}
              as="div"
              className="group flex h-12 cursor-pointer items-center justify-between px-4 font-inter-normal-13px hover:bg-gray-50"
              data-loc={`select-${workspace.name}`}
              onClick={() => {
                onSelect({
                  orgId: org.id,
                  workspaceId: workspace.id,
                });
              }}
            >
              <span className="flex items-center gap-x-1">
                {workspace.name}
              </span>
              {isSelected && (
                <Icon color="text-indigo-500" icon={faCheck} size="xs" />
              )}
            </Menu.Item>
          );
        })}
      </div>
    </div>
  );
};
