import { AxiosError, AxiosInstance } from "axios";

import { isTaktileRequest } from "src/api/middlewares/utils";
import { toastError } from "src/utils/toastError";

export const addHandleForbiddenMiddleware = (axiosInstance: AxiosInstance) => {
  return axiosInstance.interceptors.response.use(
    undefined,
    (error: AxiosError) => {
      if (
        isTaktileRequest(error.request?.responseURL) &&
        error.response?.status === 403 &&
        !["GET", "get"].includes(error.response?.config.method || "")
      ) {
        toastError(error);
      }

      return Promise.reject(error);
    },
  );
};
