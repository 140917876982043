import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { m } from "framer-motion";

import { FlowVersionFlowChild } from "src/api/flowTypes";
import { Icon } from "src/base-components/Icon";
import { VersionStatusDotIndicator } from "src/utils/flowVersion";

type CellExpandableProps = {
  title: React.ReactNode;
  expanded: boolean;
  onClickExpand: () => void;
};

export const CellExpandable: React.FC<CellExpandableProps> = ({
  title,
  expanded,
  onClickExpand,
}) => (
  <div className="flex max-w-[15rem] items-center gap-1.5">
    <m.button
      animate={expanded ? "expanded" : "collapsed"}
      className="p-1"
      data-loc="version-expand"
      variants={{
        expanded: { rotate: 90 },
        collapsed: { rotate: 0 },
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClickExpand();
      }}
    >
      <Icon
        color="text-gray-500 hover:text-gray-700"
        icon={faChevronRight}
        size="2xs"
      />
    </m.button>

    <div className="truncate">{title}</div>
  </div>
);

type VersionNameProps = {
  version: FlowVersionFlowChild;
} & Omit<CellExpandableProps, "title">;

export const VersionNameExpandable: React.FC<VersionNameProps> = ({
  version,
  ...restOfProps
}) => (
  <CellExpandable
    title={
      <div className="flex items-center gap-1">
        <VersionStatusDotIndicator version={version} />
        {version.name}
      </div>
    }
    {...restOfProps}
  />
);
