import { faCheck, faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { debounce } from "lodash";
import { useCallback } from "react";

import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { Icon } from "src/base-components/Icon";
import { CustomPopover } from "src/base-components/Popover";
import { SearchBox } from "src/base-components/SearchBox";
import { CommentFilters, OnFilterChange } from "src/comments/CommentPane";

type Props = {
  filters: CommentFilters;
  onFilterChange: OnFilterChange;
};

type CommentFilterBarOptionProps = {
  dataLoc?: string;
  description?: string;
  onSelect: () => void;
  selected: boolean;
  option: string;
};

const CommentFilterBarOption: React.FC<CommentFilterBarOptionProps> = ({
  dataLoc,
  description,
  onSelect,
  selected,
  option,
}) => {
  return (
    <button
      className="px-3 py-2 hover:bg-gray-100"
      data-loc={dataLoc}
      onClick={onSelect}
    >
      <div className="flex flex-row items-center justify-between">
        <p className="text-gray-800 font-inter-semibold-13px">{option}</p>
        {selected && <Icon color="text-indigo-600" icon={faCheck} size="sm" />}
      </div>
      {description && (
        <p className="text-start text-gray-600 font-inter-normal-12px">
          {description}
        </p>
      )}
    </button>
  );
};

export const CommentFilterBar: React.FC<Props> = ({
  onFilterChange,
  filters,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- Eslint is confused because onFilterChange is not defined inline
  const debouncedOnFilterChange = useCallback(
    debounce(
      (input: string) =>
        onFilterChange({
          filterTerm: input,
        }),
      250,
    ),
    [onFilterChange],
  );

  return (
    <div className="flex flex-row items-center border-b border-gray-200 py-4">
      <div className="mr-2 flex-1">
        <SearchBox
          defaultValue={filters.filterTerm}
          placeholder="Search comments..."
          onChange={debouncedOnFilterChange}
        />
      </div>
      <CustomPopover
        button={
          <Button
            dataLoc="comments-filter-button"
            iconRight={faChevronDown}
            variant="secondary"
          >
            <span className="capitalize">{filters.show}</span>
          </Button>
        }
        placement="bottom-end"
      >
        <div className="flex max-h-66 w-80 flex-col py-2">
          <CommentFilterBarOption
            dataLoc="comments-filter-all"
            option="All"
            selected={filters.show === "all"}
            onSelect={() =>
              onFilterChange({
                show: "all",
              })
            }
          />
          <CommentFilterBarOption
            description="Show only comments that are pending resolution."
            option="Open"
            selected={filters.show === "open"}
            onSelect={() =>
              onFilterChange({
                show: "open",
              })
            }
          />
          <CommentFilterBarOption
            description="Show comments that are already resolved."
            option="Resolved"
            selected={filters.show === "resolved"}
            onSelect={() =>
              onFilterChange({
                show: "resolved",
              })
            }
          />
          {filters.includeNodeLevel !== undefined && (
            <button
              className="flex flex-row items-center gap-x-2 border-t border-gray-100 px-3 py-2 text-gray-800 font-inter-semibold-13px"
              onClick={() =>
                onFilterChange({
                  includeNodeLevel: !filters.includeNodeLevel,
                })
              }
            >
              <Checkbox checked={!!filters.includeNodeLevel} />
              Show node level comments
            </button>
          )}
        </div>
      </CustomPopover>
    </div>
  );
};
