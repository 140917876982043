import {
  ColumnDef,
  HeaderGroup,
  Row,
  Table,
  getCoreRowModel,
  useReactTable,
  flexRender,
  RowData,
  TableOptions,
} from "@tanstack/react-table";
import { noop, omit, times } from "lodash";
import React, { useRef } from "react";
import { VirtualItem, useVirtual } from "react-virtual";
import { twJoin, twMerge } from "tailwind-merge";
import { useDebouncedCallback } from "use-debounce";

import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";

type TableActions = Record<string, (...args: any[]) => void>;

declare module "@tanstack/table-core" {
  // eslint-disable-next-line unused-imports/no-unused-vars
  interface TableMeta<TData extends RowData> {
    actions?: TableActions;
  }
}

type RowProps = {
  classNameOverrides?: string;
  [key: string]: any;
};
type HeaderGroupProps = {
  classNameOverrides?: string;
  [key: string]: any;
};

type PropsT<T extends object, A extends TableActions> = {
  data: T[];
  columns: ColumnDef<T, any>[];
  frameClassName?: string;
  rowClassName?: string;
  rowPropsGetter?: (rowData: Row<T>) => RowProps;
  headerPropsGetter?: (headerProps: HeaderGroup<T>) => HeaderGroupProps;
  renderTableHeader?: (tableInstance: Table<T>) => React.ReactNode;
  noScroll?: boolean;
  dataLoc?: string;
  isLoading?: boolean;
  actions?: A;
  getRowId?: TableOptions<T>["getRowId"];
  loadingRowsCount?: number;
  onScrolledToTheBottom?: () => void;
  isFetchingNextPage?: boolean;
};

const ROW_CLASS_NAMES = "[&:not(:last-child)]:border-b group border-gray-100";
const HEADER_CLASS_NAMES =
  "sticky top-0 bg-opacity-100 bg-white border-b border-gray-100 font-inter-medium-12px text-left";

export const TableComp = <T extends object, A extends TableActions>({
  data,
  columns,
  frameClassName,
  rowClassName,
  rowPropsGetter,
  headerPropsGetter,
  noScroll = false,
  isLoading = false,
  loadingRowsCount = 10,
  dataLoc,
  actions,
  getRowId,
  onScrolledToTheBottom,
  isFetchingNextPage,
}: PropsT<T, A>) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const tableInstance = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: { size: undefined },
    meta: { actions },
    getRowId,
  });
  const { getHeaderGroups } = tableInstance;

  const headerGroups = getHeaderGroups();
  const rows = tableInstance.getRowModel().rows;

  const renderHeaderGroup = (headerGroup: HeaderGroup<T>) => {
    const headerProps = headerPropsGetter?.(headerGroup);

    return (
      <tr
        key={headerGroup.id}
        className={twMerge(HEADER_CLASS_NAMES, headerProps?.classNameOverrides)}
        {...omit(headerProps, "classNameOverrides")}
      >
        {headerGroup.headers.map((column) => {
          const ctx = column.getContext();

          return (
            <th
              key={column.id}
              className="p-0"
              colSpan={column.colSpan}
              style={{
                minWidth: ctx.column.columnDef.minSize,
                maxWidth: ctx.column.columnDef.maxSize,
                width: ctx.column.columnDef.size ? column.getSize() : undefined,
              }}
            >
              {flexRender(column.column.columnDef.header, column.getContext())}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderRow = (row: Row<T>, virtualRow: VirtualItem) => {
    const rowProps = rowPropsGetter?.(row);
    return (
      <tr
        key={row.id}
        ref={virtualRow.measureRef}
        className={twMerge(
          ROW_CLASS_NAMES,
          rowClassName,
          rowProps?.classNameOverrides,
        )}
        {...omit(rowProps, "classNameOverrides")}
      >
        {row.getVisibleCells().map((cell) => {
          return (
            // h-[inherit] & p-0 allow the content inside td to take full width/height
            <td key={cell.id} className="h-[inherit] p-0">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          );
        })}
      </tr>
    );
  };

  const renderLoadingRow = (index: number) => {
    return (
      <tr key={index} className={twMerge(ROW_CLASS_NAMES, rowClassName)}>
        {columns.map((column, i) => {
          return (
            <td key={`${column.id}_${i}`} className="relative h-[inherit] p-0">
              <div className="flex min-h-[36px] items-center p-1.5">
                <SkeletonPlaceholder height="h-4" width="w-4/5" />
              </div>
            </td>
          );
        })}
      </tr>
    );
  };

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize: totalRowSize } = rowVirtualizer;
  // Dynamic top and botton padding is added to the table content to maintain the correct scroll bar size and position
  const paddingTop = virtualRows?.[0]?.start || 0;
  const paddingBottom =
    totalRowSize - (virtualRows?.[virtualRows.length - 1]?.end || 0);

  const debouncedOnScrolledToTheBottom = useDebouncedCallback(
    onScrolledToTheBottom ?? noop,
    1000,
    {
      leading: true,
      trailing: false,
    },
  );

  const onScroll = (containerRefElement?: HTMLDivElement | null) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      if (scrollHeight - scrollTop - clientHeight < 1000) {
        debouncedOnScrolledToTheBottom();
      }
    }
  };

  return (
    <div
      ref={tableContainerRef}
      className={twJoin(
        "h-full",
        !noScroll && "decideScrollbar overflow-auto",
        frameClassName,
      )}
      data-loc={dataLoc}
      onScroll={(e) =>
        onScrolledToTheBottom ? onScroll(e.target as HTMLDivElement) : undefined
      }
    >
      <table className="w-full table-auto border-collapse border-spacing-0 text-gray-800">
        <thead>{headerGroups.map(renderHeaderGroup)}</thead>
        <tbody>
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {isLoading
            ? times(loadingRowsCount, (i) => renderLoadingRow(i))
            : virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index];
                return renderRow(row, virtualRow);
              })}
          {isFetchingNextPage &&
            times(loadingRowsCount, (i) => renderLoadingRow(i))}
          {!isLoading && paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
