import { observer } from "mobx-react-lite";
import React from "react";

import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { useGraphStore } from "src/store/StoreProvider";

export const DeleteGroupModal: React.FC<{}> = observer(() => {
  const {
    groups,
    deleteNode,
    setIsDeleteGroupModalVisible,
    groupToChangeId,
    setGroupToChangeId,
    isDeleteGroupModalVisible,
  } = useGraphStore();
  const onClose = () => {
    setIsDeleteGroupModalVisible(false);
    setGroupToChangeId(null);
  };
  const groupName = groupToChangeId && groups.get(groupToChangeId)?.data.label;
  const onConfirmDelete = async () => {
    if (groupToChangeId) {
      const group = groups.get(groupToChangeId);
      if (group) {
        await deleteNode(group, false);
        onClose();
      }
    }
  };
  return (
    <ConfirmationModal
      confirmationButtonClassname="w-[10.925rem]"
      confirmationButtonText="Delete all grouped Nodes"
      open={isDeleteGroupModalVisible}
      title="Delete all nodes of this group?"
      onClose={onClose}
      onConfirm={onConfirmDelete}
    >
      <div className="mb-6 mt-4 text-gray-500 font-inter-normal-12px">
        Are you sure you want to delete the {groupName} group with all its
        Nodes? This action cannot be undone.
      </div>
    </ConfirmationModal>
  );
});
