import React from "react";

import { FlowVersionT } from "src/api/flowTypes";
import {
  DropDown,
  DropDownElementT,
  RenderValueFn,
} from "src/base-components/DropDown";
import { FlowVersionStatus } from "src/clients/flow-api";
import { VersionPickerDisplay } from "src/flow/modals/VersionPickerDisplay";
import {
  getSortedVersions,
  isLastDraftVersion,
  isLastPublishedVersion,
} from "src/flow/modals/utils";

export const FromScratchElement = {
  key: "From scratch",
  value: "from-scratch",
} as const;

export type DuplicateVersionOptionsT =
  | FlowVersionT
  | typeof FromScratchElement.value;

export type VersionPickerPropsT = {
  onChange: (value: string | undefined) => void;
  selected?: string;
  data_loc?: string;
  versions: FlowVersionT[];
};

export const VersionPicker: React.FC<VersionPickerPropsT> = ({
  onChange,
  selected,
  data_loc,
  versions,
}) => {
  const sortedVersions = getSortedVersions(versions);

  const versionElements = sortedVersions.map((version) => ({
    key: version.id,
    value: version,
  }));

  const menuElements: DropDownElementT<DuplicateVersionOptionsT>[] = [
    FromScratchElement,
    ...versionElements,
  ];

  const getSelectedKey = () => {
    if (selected === FromScratchElement.key) {
      return selected;
    }
    const selectedVersions = versions.filter(
      (version) => version.id === selected,
    );
    if (selectedVersions.length > 0) {
      return selectedVersions[0].id;
    }
  };

  const renderButton = (version?: DuplicateVersionOptionsT) => {
    if (!version) {
      return <></>;
    }
    return (
      <VersionPickerDisplay
        duplicateVersion={version}
        selected={false}
        button
      />
    );
  };

  const includesArchivedVersion = versions.some(
    (version) => version.status === FlowVersionStatus.ARCHIVED,
  );

  const renderValue: RenderValueFn<DuplicateVersionOptionsT> = ({
    value: duplicateVersion,
    selected,
  }) => {
    return (
      <VersionPickerDisplay
        borderBottom={
          duplicateVersion === FromScratchElement.value ||
          isLastPublishedVersion(versions, duplicateVersion) ||
          (includesArchivedVersion &&
            isLastDraftVersion(versions, duplicateVersion))
        }
        duplicateVersion={duplicateVersion}
        selected={selected}
      />
    );
  };

  return (
    <DropDown<DuplicateVersionOptionsT>
      buttonDataLoc={data_loc}
      elements={menuElements}
      itemsClassNames="w-full overflow-y-auto max-h-80 decideScrollbar"
      renderButtonValue={renderButton}
      renderValue={renderValue}
      selectedKey={getSelectedKey()}
      onSelect={(selectedKey: string) => onChange(selectedKey)}
    />
  );
};
