import { useForm } from "react-hook-form";
import { v4 } from "uuid";

import { useCreateOrganization } from "src/adminPanel/queries";
import { Button } from "src/base-components/Button";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { Modal } from "src/base-components/Modal";
import { OrganizationCreate } from "src/clients/taktile-api";

export const CreateOrganizationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const uuid4 = v4().split("-")[0];
  const form = useForm<Pick<OrganizationCreate, "name" | "principal">>({
    defaultValues: { principal: uuid4 },
  });
  const createOrganization = useCreateOrganization();
  return (
    <Modal open={isOpen} title="Create an Organization" onClose={onClose}>
      <form
        onSubmit={form.handleSubmit((data) => {
          createOrganization.mutate(data);
        })}
      >
        <div className="flex flex-col gap-2 p-5">
          <div className="flex flex-row items-center justify-between gap-2">
            <Label>Organization Display Name</Label>
            <Input
              {...form.register("name", { required: true })}
              placeholder="Organization name"
            />
          </div>
          <div className="flex flex-row items-center justify-between gap-2">
            <Label>
              <div>Organization principal</div>
              <div>
                Note that this should not contain any revealing information
                about the organization.
              </div>
            </Label>
            <Input
              {...form.register("principal", { required: true })}
              placeholder="Organization principal"
            />
          </div>
          <Button htmlType="submit" loading={createOrganization.isLoading}>
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};
