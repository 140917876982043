import {
  faInfoCircle,
  faSync,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { isEmpty, keyBy } from "lodash";
import React, { useMemo } from "react";

import { FlowVersionT } from "src/api/flowTypes";
import { Dataset } from "src/api/types";
import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { InformationPill } from "src/base-components/InformationPill";
import { Modal } from "src/base-components/Modal";
import { Pill } from "src/base-components/Pill";
import { TitleEditor } from "src/base-components/TitleEditor";
import {
  TAKTILE_TEAM_NOTIFIED,
  toastFailure,
} from "src/base-components/Toast/utils";
import { DatasetControlButtons } from "src/datasets/DatasetControlButtons";
import {
  DatasetEditTable,
  type DatasetEditTableRef,
} from "src/datasets/DatasetTable/DatasetEditTable";
import { MissingColumnsCallout } from "src/datasets/DatasetTable/MissingColumnsCallout";
import { MissingMockDataColumnsCallout } from "src/datasets/DatasetTable/MissingMockDataColumnsCallout";
import { useAvailableIntegrationNodes } from "src/datasets/DatasetTable/hooks";
import {
  DatasetContext,
  VersionSchemas,
} from "src/datasets/DatasetTable/types";
import {
  MOCK_COL_SEPARATOR,
  isLoopIntegrationNode,
  isParentIntegrationNode,
} from "src/datasets/DatasetTable/utils";
import {
  PutColumnPayload,
  usePatchDataset,
  usePutColumns,
} from "src/datasets/api/queries";
import { DEFAULT_DATASET_NAME } from "src/datasets/constants";
import {
  DatasetIssue,
  findCompatibilityIssues,
  isDatasetEmpty,
  isIntegrationNodeWithLiveConnection,
  separateFilenameExtension,
} from "src/datasets/utils";
import { useFlowContext } from "src/router/routerContextHooks";
import * as logger from "src/utils/logger";
import { formatNumber } from "src/utils/numbers";

type Props = {
  dataset?: Dataset;
  open: boolean;
  onClose: () => void;
  schemas: VersionSchemas;
  version?: FlowVersionT;
  context?: DatasetContext;
  onOpenConnectVersionModal?: () => void;
  onNameUpdate?: ({ name }: { name: string }) => Promise<void>;
};

export const EditModal: React.FC<Props> = ({
  open,
  onClose,
  dataset,
  schemas,
  version,
  context = "authoring",
  onOpenConnectVersionModal,
  onNameUpdate,
}) => {
  const { workspace, flow } = useFlowContext();
  const isAuthoringDatasetContext = context === "authoring";
  const putColumns = usePutColumns(
    dataset ? dataset.id : "",
    flow.id,
    workspace?.base_url,
  );
  const editTableRef = React.useRef<DatasetEditTableRef>(null);
  const patchDataset = usePatchDataset(flow.id, workspace?.base_url);

  const datasetNameAndExtension = dataset
    ? separateFilenameExtension(dataset.name)
    : undefined;

  const updateDatasetName = async (name: string) => {
    if (!dataset) {
      logger.log("No dataset to update");
      return;
    }

    const completeName = isEmpty(name)
      ? DEFAULT_DATASET_NAME
      : datasetNameAndExtension?.extension
        ? `${name}.${datasetNameAndExtension?.extension}`
        : name;

    try {
      await patchDataset.mutateAsync({
        id: dataset.id,
        patch: { name: completeName },
      });
      await onNameUpdate?.({ name: completeName });
    } catch (e) {
      toastFailure({
        title: "Failed to update dataset name",
        description: TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(e);
    }
  };

  const issues = useMemo(
    () => (dataset ? findCompatibilityIssues(dataset, schemas.input) : {}),
    [dataset, schemas.input],
  );

  const integrationNodes = useAvailableIntegrationNodes(version);
  const integratinNodesByName = keyBy(integrationNodes, "name");

  const missingMockDataColumns = useMemo(() => {
    if (!dataset) return [];
    const columnsByName = keyBy(dataset.mock_columns, "name");

    const allNodes = integrationNodes.flatMap((node) => [
      node,
      ...(columnsByName[node.name]?.use_subflow_mocks
        ? (node.mockableChildNodes?.map((childNode) => ({
            ...childNode,
            name: [node.name, childNode.name].join(MOCK_COL_SEPARATOR),
          })) ?? [])
        : []),
    ]);

    const nodeNames = allNodes
      .filter(
        (node) =>
          !isParentIntegrationNode(node) &&
          !isIntegrationNodeWithLiveConnection(node),
      )
      .map((node) => node.name);
    const datasetColumns = dataset.mock_columns.map((column) => column.name);
    return nodeNames.filter((nodeName) => !datasetColumns.includes(nodeName));
  }, [dataset, integrationNodes]);

  const missingColumns = Object.entries(issues).filter(
    (
      datasetIssue,
    ): datasetIssue is [
      string,
      Extract<DatasetIssue, { issueType: "missing-column" }>,
    ] => datasetIssue?.[1]?.issueType === "missing-column",
  );

  const addMissingColumnsHandler = async () => {
    if (missingColumns.length === 0) return;

    const columns: PutColumnPayload[] = missingColumns.map(([name, issue]) => ({
      name,
      group: "input_columns",
      desiredType: issue.schemaType,
      hasSubflowMocks: false,
    }));

    try {
      await putColumns.mutateAsync(columns);
    } catch (e) {
      toastFailure({
        title: `Failed to add columns ${columns
          .map((column) => `"${column.name}"`)
          .join(", ")}`,
        description: TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(e);
    }
  };

  const handleAddMissingMockDataColumns = async () => {
    if (missingMockDataColumns.length < 1) return;

    const columns: PutColumnPayload[] = missingMockDataColumns.map((name) => {
      const [parentName] = name.split(MOCK_COL_SEPARATOR);
      const integrationNode = integratinNodesByName[parentName];
      return {
        name,
        group: "mock_columns",
        desiredType: isLoopIntegrationNode(integrationNode) ? "any" : "object",
        hasSubflowMocks: false,
      };
    });

    try {
      await putColumns.mutateAsync(columns);
    } catch (e) {
      toastFailure({
        title: `Failed to add columns ${columns
          .map((column) => `"${column.name}"`)
          .join(", ")}`,
        description: TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(e);
    }
  };

  const hasName = datasetNameAndExtension?.name !== DEFAULT_DATASET_NAME;

  const title = (
    <div className="flex w-full items-center gap-x-2">
      <h2 className="line-clamp-1 max-w-[25%] text-gray-800 font-inter-semibold-14px">
        <TitleEditor
          autofocus={!hasName}
          dataLoc="dataset-name-editor"
          placeholder={DEFAULT_DATASET_NAME}
          value={hasName ? datasetNameAndExtension?.name : ""}
          onSubmit={updateDatasetName}
        />
      </h2>
      {typeof dataset?.row_count === "number" ? (
        <Pill variant="indigo-light">
          <Pill.Text>
            {formatNumber(dataset.row_count)} test{" "}
            {dataset.row_count === 1 ? "case" : "cases"}
          </Pill.Text>
        </Pill>
      ) : null}
      <div className="ml-auto flex gap-x-2">
        {isAuthoringDatasetContext && (
          <>
            <Pill variant="gray">
              <Pill.Icon icon={faSync} />
              <Pill.Text>
                Changes to this dataset are synced across all versions
              </Pill.Text>
            </Pill>
            <a
              href="https://docs.taktile.com/decision-design/datasets-and-testing"
              rel="noreferrer"
              target="_blank"
            >
              <Icon color="text-gray-500" icon={faInfoCircle} size="xs" />
            </a>
          </>
        )}
      </div>
    </div>
  );

  return (
    <Modal
      className="w-[85vw] overflow-visible"
      headerClassName="pb-6 border-b border-gray-100"
      open={open}
      title={title}
      zIndex="z-30"
      closeIcon
      onClose={onClose}
    >
      <div className="flex h-[75vh] flex-col">
        {missingColumns.length > 0 && (
          <MissingColumnsCallout
            columns={missingColumns.map((issue) => issue[0])}
            context={context}
            onAddMissingColumns={addMissingColumnsHandler}
          />
        )}
        {isAuthoringDatasetContext && missingMockDataColumns.length > 0 && (
          <MissingMockDataColumnsCallout
            columns={missingMockDataColumns}
            onAddMissingColumns={handleAddMissingMockDataColumns}
          />
        )}
        {!isAuthoringDatasetContext &&
          !version &&
          onOpenConnectVersionModal && (
            <MissingVersionCallout
              onOpenConnectVersionModal={onOpenConnectVersionModal}
            />
          )}
        <div className="relative min-h-0 flex-1">
          {dataset && (
            <DatasetEditTable
              ref={editTableRef}
              context={context}
              dataset={dataset}
              issues={issues}
              schemas={schemas}
              version={version}
            />
          )}
        </div>
        <div className="mt-auto flex items-center justify-between gap-x-2 border-t border-gray-100 px-8 py-4">
          <div className="flex gap-x-3">
            {dataset && !isDatasetEmpty(dataset) && (
              <DatasetControlButtons
                context={context}
                dataset={dataset}
                editTableRef={editTableRef}
                schemas={schemas}
                version={version}
              />
            )}
          </div>
          <Button variant="primary" onClick={onClose}>
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const MissingVersionCallout: React.FC<{
  onOpenConnectVersionModal: () => void;
}> = ({ onOpenConnectVersionModal }) => {
  return (
    <InformationPill
      action={{
        text: "Add Decision Flow Version",
        onClick: onOpenConnectVersionModal,
      }}
      icon={faWarning}
      type="info"
      fullWidth
    >
      Connect decision flow version with the job to check your dataset columns
      against the decision flow input schema.
    </InformationPill>
  );
};
