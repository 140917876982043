import { partition } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";

import { Button } from "src/base-components/Button";
import { InformationPill } from "src/base-components/InformationPill";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { Select } from "src/base-components/Select";
import {
  toastFailure,
  TAKTILE_TEAM_NOTIFIED,
} from "src/base-components/Toast/utils";
import { FlowVersionStatus } from "src/clients/flow-api";
import { Modal } from "src/design-system/Modal";
import { FlowPageContext } from "src/flow/FlowPage";
import { useUpdateJob } from "src/jobs/api/queries";
import { handlePreconditionError } from "src/jobs/jobUtils";
import { Job } from "src/jobs/types";
import {
  getActiveVersions,
  VersionStatusDotIndicator,
} from "src/utils/flowVersion";
import * as logger from "src/utils/logger";

type ConnectDecisionFlowForm = Pick<Job, "flow_version_id">;

export const SelectVersionModal: React.FC<{
  isOpen: boolean;
  closeModal: () => void;
  job: Job;
}> = ({ isOpen, closeModal, job }) => {
  const { flow, workspace } = useOutletContext<FlowPageContext>();
  const { handleSubmit, control, formState, reset, watch } =
    useForm<ConnectDecisionFlowForm>({
      values: { flow_version_id: job.flow_version_id },
    });
  const flowVersionId = watch("flow_version_id");

  const jobUpdateMutation = useUpdateJob(workspace.base_url!, job);

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const onSubmit = async ({ flow_version_id }: ConnectDecisionFlowForm) => {
    try {
      await jobUpdateMutation.mutateAsync({
        flow_version_id,
        etag: job.etag,
      });
      closeModal();
    } catch (error) {
      logger.error(error);
      const isPreconditionError = await handlePreconditionError(
        workspace,
        job,
        error,
      );

      if (!isPreconditionError)
        toastFailure({
          title: "Failed to connect decision flow",
          description: TAKTILE_TEAM_NOTIFIED,
        });
    }
  };

  const isSelectedVersionDraft =
    flow.versions.find((v) => v.id === flowVersionId)?.status ===
    FlowVersionStatus.DRAFT;

  const versions = partition(
    getActiveVersions(flow.versions).sort(
      (a, b) =>
        new Date(b.meta?.published_at ?? b.created_at).getTime() -
        new Date(a.meta?.published_at ?? a.created_at).getTime(),
    ),
    (version) => version.status === FlowVersionStatus.PUBLISHED,
  ).flatMap((versions) =>
    versions.map((version) => ({
      key: version.id!,
      value: (
        <div className="flex max-h-5 items-center gap-x-2">
          <VersionStatusDotIndicator version={version} />
          {version.name}
        </div>
      ),
    })),
  );

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Modal.Header description="Link a Decision Flow to enable automated decision-making for your Job.">
        Connect Decision Flow
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <div className="flex flex-col gap-y-6">
            <div>
              <Label required>Decision Flow</Label>
              <Input value={flow.name} disabled fullWidth />
            </div>
            <div>
              <Label required>Version</Label>
              <Controller
                control={control}
                name="flow_version_id"
                render={({ field: { value, onChange } }) => (
                  <Select
                    dataLoc="select-version-dropdown"
                    options={versions}
                    placeholder="Select a version"
                    value={value}
                    onChange={onChange}
                  />
                )}
                rules={{ required: true }}
              />
            </div>
            {isSelectedVersionDraft && (
              <InformationPill type="warning">
                The version you choose must be in a "Published" state before
                this Job can be activated
              </InformationPill>
            )}
          </div>
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="connect-flow-version"
              disabled={!formState.isValid || jobUpdateMutation.isLoading}
              htmlType="submit"
              loading={jobUpdateMutation.isLoading}
              variant="primary"
            >
              Connect
            </Button>
          }
        />
      </form>
    </Modal>
  );
};
