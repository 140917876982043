import Avatar from "boring-avatars";
import React, { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";

import { OrganizationT } from "src/api/flowTypes";
import { useUserOrganizationLogo } from "src/api/queries";

const FALLBACK_COLORS = ["#5946E5", "#6366F1", "#A7F3D0", "#22D3EE", "#493FC1"];

type Props = {
  org: OrganizationT;
  size: "small" | "medium";
};

const getFallbackSrc = (org: OrganizationT) => {
  if (org.github_id) {
    return `https://avatars.githubusercontent.com/u/${org.github_id}?s=40&v=4`;
  }
  return null;
};

export const OrgLogo: React.FC<Props> = ({ org, size }) => {
  const [imgSrc, setImgSrc] = useState<Nullable<string>>(null);
  const { data } = useUserOrganizationLogo(org.id);
  const fallbackImgSrc = getFallbackSrc(org);

  useEffect(() => {
    if (data) {
      try {
        setImgSrc(URL.createObjectURL(data));
      } catch {
        setImgSrc(null);
      }
    } else {
      setImgSrc(null);
    }
  }, [data]);

  if (imgSrc || fallbackImgSrc) {
    return (
      <img
        alt="logo"
        className={twJoin(
          "rounded-md border border-gray-200 object-cover",
          size === "medium" && "h-7 w-7",
          size === "small" && "h-4 w-4",
        )}
        src={(imgSrc || fallbackImgSrc)!}
      />
    );
  }

  return (
    <div className="flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
      <Avatar
        colors={FALLBACK_COLORS}
        name={org.name}
        size={size === "medium" ? 26 : 14}
        variant="marble"
        square
      />
    </div>
  );
};
