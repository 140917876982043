import { Pill } from "src/base-components/Pill";

export const JobVersionsPills = ({
  versionsUsed,
}: {
  versionsUsed: { versionName: string; percentage: number }[];
}) => {
  if (versionsUsed.length === 0) return "-";
  if (versionsUsed.length === 1) {
    return (
      <Pill key={versionsUsed[0].versionName} variant="gray">
        <Pill.Text>{versionsUsed[0].versionName}</Pill.Text>
      </Pill>
    );
  }
  return (
    <div className="flex items-center gap-x-1">
      {versionsUsed.slice(0, 2).map((v) => (
        <Pill key={v.versionName} variant="gray">
          <p className="text-gray-500 font-inter-normal-12px">
            {v.percentage}%
          </p>
          <Pill.Text>
            <p className="max-w-10 truncate">{v.versionName}</p>
          </Pill.Text>
        </Pill>
      ))}
      {versionsUsed.length > 2 && (
        <p className="text-gray-500 font-inter-medium-11px">
          + {versionsUsed.length - 2} more
        </p>
      )}
    </div>
  );
};
