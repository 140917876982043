import {
  ConnectionResourceTemplateConfigKey,
  ConnectionResourceTemplateConfigKeyParent,
} from "src/connections/types";

export const person_report_options_parent: ConnectionResourceTemplateConfigKeyParent =
  {
    key: "person_report_options_parent",
    title: "Configure resource",
    subtitle: "Configure the Person Report",
    required: false,
  };

export const person_report_sections_parent: ConnectionResourceTemplateConfigKeyParent =
  {
    key: "person_report_sections_parent",
    title: "Configure resource sections",
    subtitle:
      "Configure which Person Report sections to include in the response",
    required: false,
  };

export const person_report_options: ConnectionResourceTemplateConfigKey[] = [
  {
    key: "report_options.associates_section_address_option",
    name: "Associates section address option",
    required: false,
    type: "simple_selection",
    elements: [
      { key: "A", value: "A: All addresses" },
      { key: "3", value: "3: Three most current addresses" },
    ],
    hint: "Option to determine the number of subject addresses to display for associates. One of: 'A' for all addresses, or '3' for three most current addresses.",
    parent: person_report_options_parent,
  },
  {
    key: "report_options.associates_section_timeframe_option",
    name: "Associates section timeframe option",
    required: false,
    type: "simple_selection",
    elements: [
      { key: "-1", value: "-1: No timeframe limit" },
      { key: "0", value: "0: Same timeframe as subject" },
      { key: "2", value: "2: Same timeframe as subject +/- two years" },
    ],
    hint: "Option to display associates based on subject address timeframe. One of: '-1' for no limit, '0' for same timeframe, or '2' for two years.",
    parent: person_report_options_parent,
  },
  {
    key: "report_options.associate_section_display_option",
    name: "Associate section display option",
    required: false,
    type: "simple_selection",
    elements: [
      { key: "F", value: "F: Full detail" },
      { key: "S", value: "S: Summary detail" },
    ],
    hint: "Option to display full detail or summary detail of each associate. One of: 'F' for full detail, 'S' for summary detail.",
    parent: person_report_options_parent,
  },
  {
    key: "report_options.neighbors_type_option",
    name: "Neighbors type option",
    required: false,
    type: "simple_selection",
    elements: [
      { key: "A", value: "A: Display neighbors for all subject addresses" },
      {
        key: "3",
        value: "3: Display neighbors for three most current subject addresses",
      },
    ],
    hint: "Option to determine the number of subject addresses to display for neighbors. One of: 'A' for all, '3' for three.",
    parent: person_report_options_parent,
  },
  {
    key: "report_options.neighbors_timeframe_option",
    name: "Neighbors timeframe option",
    required: false,
    type: "simple_selection",
    elements: [
      { key: "-1", value: "-1: No timeframe limit" },
      { key: "0", value: "0: Same timeframe as subject" },
      { key: "2", value: "2: Same timeframe as subject +/- two years" },
    ],
    hint: "Option to display neighbors based on subject address timeframe. One of: '-1' for no limit, '0' for same timeframe, or '2' for same timeframe +/- two years.",
    parent: person_report_options_parent,
  },
  {
    key: "report_options.relatives_section_degree_option",
    name: "Relatives section degree option",
    required: false,
    type: "simple_selection",
    elements: [
      { key: "1", value: "1: First degree" },
      { key: "2", value: "2: Second degree" },
      { key: "3", value: "3: Third degree" },
    ],
    hint: "Option to display degree of separation for relatives. One of: '1' for first degree, '2' for second degree, '3' for third degree.",
    parent: person_report_options_parent,
  },
  {
    key: "report_options.relatives_section_display_option",
    name: "Relatives section display option",
    required: false,
    type: "simple_selection",
    elements: [
      { key: "F", value: "F: Full detail" },
      { key: "S", value: "S: Summary detail" },
    ],
    hint: "Option to display full detail or summary detail of each relative. One of: 'F' for full detail, 'S' for summary detail.",
    parent: person_report_options_parent,
  },
  {
    key: "report_options.relatives_section_include_akas_option",
    name: "Relatives section: Include AKAs option",
    required: false,
    type: "switch",
    hint: "Option to include 'also known as' (AKA) as well as the primary last name when compiling relatives",
    parent: person_report_options_parent,
  },
  {
    key: "report_options.vehicles_section_limit_assoc_option",
    required: false,
    type: "switch",
    hint: "Option to limit the current owners for each vehicle registered at the subject's address",
    parent: person_report_options_parent,
    name: "Vehicles section: Limit association option",
  },
  {
    key: "report_options.vehicles_section_limit_year_option",
    required: false,
    type: "switch",
    hint: "Option to limit vehicles registered to the subject within the last two years",
    parent: person_report_options_parent,
    name: "Vehicles section: Limit Year option",
  },
  {
    key: "report_options.address_section_address_option",
    required: false,
    type: "simple_selection",
    elements: [
      { key: "A", value: "A: All previous addresses" },
      { key: "3", value: "3: Three most current addresses" },
    ],
    hint: "Option to determine the number of addresses associated with the subject to display. One of: 'A' for all previous addresses, or '3' for three most current addresses.",
    parent: person_report_options_parent,
    name: "Address section: Address option",
  },
  {
    key: "report_options.business_at_subject_address_section_address_option",
    required: false,
    type: "simple_selection",
    elements: [
      { key: "A", value: "A: Businesses registered at all previous addresses" },
      {
        key: "3",
        value: "3: Businesses registered at three most current addresses",
      },
    ],
    hint: "Option to determine the number of business registered at the subject addresses to display. One of: 'A' for businesses registered at all previous addresses, or '3' for businesses registered at three most current addresses.",
    parent: person_report_options_parent,
    name: "Address section: Businesses at subject address option",
  },
  {
    key: "report_options.license_section_address_option",
    required: false,
    type: "simple_selection",
    elements: [
      { key: "A", value: "A: Licensed drivers for all previous addresses" },
      {
        key: "3",
        value: "3: Licensed drivers for the three most current addresses",
      },
    ],
    hint: "Option to determine the number of licensed drivers at subject's address to display. One of: 'A' for licensed drivers for all previous addresses, or '3' for licensed drivers for the three most current addresses.",
    parent: person_report_options_parent,
    name: "License section: Address option",
  },
  {
    key: "report_options.license_section_timeframe_option",
    required: false,
    type: "simple_selection",
    elements: [
      { key: "-1", value: "-1: No timeframe limit" },
      { key: "0", value: "0: Same timeframe as subject" },
      { key: "2", value: "2: Same timeframe as subject +/- two years" },
    ],
    hint: "Option to display licensed drivers based on subject address timeframe. One of: '-1' for no timeframe limit, '0' for same timeframe as subject, or '2' for same timeframe as subject +/- two years.",
    parent: person_report_options_parent,
    name: "License section: Address option",
  },
  {
    key: "report_options.property_owner_section_address_option",
    name: "Property owner section: Address option",
    required: false,
    type: "simple_selection",
    elements: [
      { key: "A", value: "A: Property owners for all previous addresses" },
      {
        key: "3",
        value: "3: Property owners for three most current addresses",
      },
    ],
    hint: "Option to determine the number of property owners at subject's address to display. One of: 'A' for property owners for all previous addresses, or '3' for property owners for three most current addresses.",
    parent: person_report_options_parent,
  },
  {
    key: "report_options.vehicles_at_subject_address_section_address_option",
    required: false,
    type: "simple_selection",
    elements: [
      { key: "A", value: "A: Vehicles registered at all previous addresses" },
      {
        key: "3",
        value: "3: Vehicles registered at three most current addresses",
      },
    ],
    hint: "Option to determine the number of subject's previous addresses from which to display registered vehicles. One of 'A' for vehicles registered at all previous addresses, or '3' for vehicles registered at three most current addresses.",
    parent: person_report_options_parent,
    name: "Vehicles at subject address section: Address option",
  },
  {
    key: "report_options.vehicles_at_subject_address_section_timeframe_option",
    required: false,
    type: "simple_selection",
    elements: [
      { key: "-1", value: "-1: No timeframe limit" },
      { key: "0", value: "0: Same timeframe as subject" },
      { key: "2", value: "2: Same timeframe as subject +/- two years" },
    ],
    hint: "Option to display vehicles registered based on subject address timeframe. One of '-1' for no timeframe limit, or '0' same timeframe as subject, or '2' for same timeframe as subject +/- two years.",
    parent: person_report_options_parent,
    name: "Vehicles at subject address section: Address option",
  },
  {
    key: "report_options.property_owner_section_limit_assoc_option",
    required: false,
    type: "switch",
    hint: "Option to display only current property owners at each of the subject's addresses",
    parent: person_report_options_parent,
    name: "Property owner section: Limit association option",
  },
  {
    key: "report_options.relatives_minimum_strength_of_association",
    required: false,
    type: "inline_number",
    hint: "Defines the minimum association strength that should be returned in the result for relatives",
    parent: person_report_options_parent,
    name: "Relatives minimum strength of association",
    placeholder: "E.g. 1",
  },
  {
    key: "report_options.associate_minimum_strength_of_association",
    required: false,
    type: "inline_number",
    hint: "Defines the minimum association strength that should be returned in the result for associates",
    parent: person_report_options_parent,
    name: "Associates minimum strength of association",
    placeholder: "E.g. 1",
  },
  {
    key: "report_options.associate_shared_addresses",
    required: false,
    type: "switch",
    hint: "Option to include shared address associations in the result",
    parent: person_report_options_parent,
    name: "Associate shared addresses",
  },
  {
    key: "report_options.associate_shared_phone_numbers",
    required: false,
    type: "switch",
    hint: "Option to include shared phone number associations in the result",
    parent: person_report_options_parent,
    name: "Associate shared phone numbers",
  },
  {
    key: "report_options.associate_business_relationships",
    required: false,
    type: "switch",
    hint: "Option to include business relationship associations in the result",
    parent: person_report_options_parent,
    name: "Associate business relationships",
  },
  {
    key: "report_options.associate_possible_spouse",
    required: false,
    type: "switch",
    hint: "Option to include possible spousse associations in the result",
    parent: person_report_options_parent,
    name: "Associate possible spouse",
  },
  {
    key: "report_options.associate_shared_vehicles",
    required: false,
    type: "switch",
    hint: "Option to include shared vehicle associations in the result",
    parent: person_report_options_parent,
    name: "Associate shared vehicles",
  },
  {
    key: "report_options.associate_party_to_same_vehicle_transactions",
    required: false,
    type: "switch",
    hint: "Option to include party to same vehicle transaction associations in the result",
    parent: person_report_options_parent,
    name: "Associate party to same vehicle transactions",
  },
  {
    key: "report_options.associate_party_to_same_real_property_transactions",
    required: false,
    type: "switch",
    hint: "Option to include party to same real property transaction associations in the result.",
    parent: person_report_options_parent,
    name: "Associate party to same property transactions",
  },
  {
    key: "report_options.associate_possible_world_check_related_individual",
    required: false,
    type: "switch",
    hint: "Option to include possible WorldCheck individual associations in the result",
    parent: person_report_options_parent,
    name: "Include possible WorldCheck associations",
  },
];

export const person_report_sections: ConnectionResourceTemplateConfigKey[] = [
  {
    key: "report_sections.address_section",
    name: "Address section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.death_section",
    name: "Death section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.work_affiliation_section",
    name: "Work affiliation section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.utility_section",
    name: "Utility section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.other_ssn_section",
    name: "Other SSN section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.other_namesfor_ssn_section",
    name: "Other names for SSN section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.phone_number_section",
    name: "Phone number section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.phone_listing_section",
    name: "Phone listing section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.canadian_phone_section",
    name: "Canadian phone section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.email_section",
    name: "Email section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.driver_license_section",
    name: "Driver license section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.marriage_section",
    name: "Marriage section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.divorce_section",
    name: "Divorce section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.license_section",
    name: "License section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.healthcare_license_section",
    name: "Healthcare license section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.military_section",
    name: "Military section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.political_donor_section",
    name: "Political donor section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.voter_registration_section",
    name: "Voter registration section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.drivers_at_subject_address_section",
    name: "Drivers at subject address section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.global_sanction_section",
    name: "Global sanction section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.healthcare_sanction_section",
    name: "Healthcare sanction section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.excluded_party_section",
    name: "Excluded party section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.world_check_section",
    name: "World check section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.infraction_section",
    name: "Infraction section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.criminal_section",
    name: "Criminal section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.real_time_arrest_section",
    name: "Real time arrest section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.arrest_section",
    name: "Arrest section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.executive_affiliation_section",
    name: "Executive affiliation section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.dun_bradstreet_section",
    name: "Dun & Bradstreet section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.shareholder_section",
    name: "Shareholder section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.business_at_subject_address_section",
    name: "Business at subject address section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.lien_judgment_section",
    name: "Lien judgment section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.bankruptcy_section",
    name: "Bankruptcy section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.lawsuit_section",
    name: "Lawsuit section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.docket_section",
    name: "Docket section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.corporate_section",
    name: "Corporate section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.real_property_section",
    name: "Real property section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.property_owner_section",
    name: "Property owner section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.pre_foreclosure_section",
    name: "Pre foreclosure section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.real_time_vehicle_section",
    name: "Real time vehicle section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.vehicle_section",
    name: "Vehicle section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.vehicles_at_subject_address_section",
    name: "Vehicles at subject address section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.watercraft_section",
    name: "Watercraft section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.aircraft_section",
    name: "Aircraft section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.unclaimed_asset_section",
    name: "Unclaimed asset section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.relative_section",
    name: "Relative section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.associate_section",
    name: "Associate section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.neighbor_section",
    name: "Neighbor section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.quick_analysis_flag_section",
    name: "Quick analysis flag section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.associate_analytics_chart_section",
    name: "Associate analytics chart section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.obituaries_news_section",
    name: "Obituaries news section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_analytics_section",
    name: "Web analytics section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.motor_vehicle_service_and_warranty_records_section",
    name: "Motor vehicle service and warranty records section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.marijuana_related_businesses_section",
    name: "Marijuana related businesses section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.permissible_purpose_section",
    name: "Permissible purpose section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_person_overview_section",
    name: "Web and social media person overview section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_phone_numbers_section",
    name: "Web and social media phone numbers section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_email_addresses_section",
    name: "Web and social media email addresses section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_usernames_section",
    name: "Web and social media usernames section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_jobs_section",
    name: "Web and social media jobs section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_education_section",
    name: "Web and social media education section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_interests_section",
    name: "Web and social media interests section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_social_network_profiles_details_section",
    name: "Web and social media social network profiles details section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_geo_social_networks_details_section",
    name: "Web and social media geo social networks details section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },

  {
    key: "report_sections.web_and_social_media_news_media_details_section",
    name: "Web and social media News media Details section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_blogging_forums_details_section",
    name: "Web and social media Blogging Forums Details section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_micro_blogging_details_section",
    name: "Web and social media Micro Blogging Details section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_picture_video_sharing_details_section",
    name: "Web and social media picture video sharing details section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_online_commerce_details_section",
    name: "Web and social media online commerce details section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_music_details_section",
    name: "Web and social media music details section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.web_and_social_media_other_details_section",
    name: "Web and social media other details section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.business_contact_records_section",
    name: "Business contact records section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.no_match_sections",
    name: "No match sections",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.npi_section",
    name: "NPI section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.ssn_address_fraud_section",
    name: "SSN address fraud section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
  {
    key: "report_sections.ucc_section",
    name: "UCC section",
    required: false,
    type: "switch",
    parent: person_report_sections_parent,
  },
];
