import { useEventListener } from "usehooks-ts";

export enum NavKey {
  UP = "ArrowUp",
  DOWN = "ArrowDown",
  LEFT = "ArrowLeft",
  RIGHT = "ArrowRight",
  ESC = "Escape",
  ENTER = "Enter",
  TAB = "Tab",
}

type Args = {
  callback: (e: KeyboardEvent, direction: Nullable<NavKey>) => void;
  ref: React.RefObject<HTMLElement>;
};

const keyActions: Record<string, NavKey> = {
  ArrowUp: NavKey.UP,
  ArrowDown: NavKey.DOWN,
  ArrowLeft: NavKey.LEFT,
  ArrowRight: NavKey.RIGHT,
  Escape: NavKey.ESC,
  Tab: NavKey.TAB,
  Enter: NavKey.ENTER,
};

export const useKeyboardNav = ({ ref, callback }: Args) => {
  const handler = (event: KeyboardEvent) => {
    if (!event.defaultPrevented) {
      const keyAction = keyActions[event.key];
      callback(event, keyAction ? keyAction : null);
    }
  };

  useEventListener("keydown", handler, ref);
};
