import { useFormContext, Controller } from "react-hook-form";

import {
  InboundWebhookHMACHashAlgorithm,
  InboundWebhookHMACSignatureEncoding,
} from "src/api/connectApi/types";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { SimpleDropDown } from "src/base-components/SimpleDropDown";
import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { InboundWebhookSecretField } from "src/connections/config/inboundWebhook/InboundWebhookSecretField";
import { AuthMethodPropsT } from "src/connections/config/inboundWebhook/types";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { errorProps } from "src/connections/config/shared/errorProps";
import { InboundWebhookConnectionConfigInputsT } from "src/connections/types";

type SelectOptions<T> = {
  key: T;
  value: string;
};

const hashAlgorithms: SelectOptions<InboundWebhookHMACHashAlgorithm>[] = [
  { key: "sha1", value: "SHA-1" },
  { key: "sha256", value: "SHA-256" },
  { key: "sha512", value: "SHA-512" },
];

const signatureEncodings: SelectOptions<InboundWebhookHMACSignatureEncoding>[] =
  [
    { key: "base64", value: "base64" },
    { key: "hex", value: "hex" },
  ];

export const InboundWebhookHMACAuthFieldset: React.FC<AuthMethodPropsT> = ({
  environment,
}) => {
  const {
    formState: { errors },
    register,
    control,
  } = useFormContext<InboundWebhookConnectionConfigInputsT>();
  const configField = `${environment}Config` as const;

  const settingsErrors = errors?.[configField]?.webhookHMACConfig;
  return (
    <FieldsetCard className="last:mb-0">
      <FormItem
        gap="xs"
        helpClassName="mt-1"
        helpTooltip="The algorithm used to generate the HMAC signature"
        label="Hash algorithm"
        isRequired
      >
        <Controller
          control={control}
          name={`${configField}.webhookHMACConfig.hashAlgorithm`}
          render={(props) => (
            <SimpleDropDown
              buttonClassName="pl-3"
              buttonDataLoc={`${environment}-webhook-hmac-hash-algorithm`}
              className="h-8 w-full"
              elements={hashAlgorithms}
              itemsClassNames="w-full"
              itemsWidth="w-full"
              placeholder="Select hash algorithm"
              placement="bottomLeft"
              selectedKey={String(props.field.value)}
              onSelect={(value) => {
                props.field.onChange(value);
              }}
            />
          )}
        ></Controller>
      </FormItem>
      <FormItem
        gap="xs"
        helpClassName="mt-1"
        helpTooltip="The encoding used to generate the HMAC signature"
        label="Signature encoding"
        isRequired
      >
        <Controller
          control={control}
          name={`${configField}.webhookHMACConfig.signatureEncoding`}
          render={(props) => (
            <SimpleDropDown
              buttonClassName="pl-3"
              buttonDataLoc={`${environment}-webhook-hmac-signature-encoding`}
              className="h-8 w-full"
              elements={signatureEncodings}
              itemsClassNames="w-full"
              itemsWidth="w-full"
              placeholder="Select signature encoding"
              placement="bottomLeft"
              selectedKey={String(props.field.value)}
              onSelect={(value) => {
                props.field.onChange(value);
              }}
            />
          )}
        ></Controller>
      </FormItem>
      <FormItem
        gap="xs"
        helpClassName="mt-1"
        helpTooltip="The header within the webhook request that contains the HMAC signature"
        label="Signature header"
        isRequired
      >
        <Input
          data-loc={`${environment}-webhook-hmac-header`}
          {...errorProps(isFieldErrored(settingsErrors, "header"))}
          {...register(`${configField}.webhookHMACConfig.header`, {
            required: "Signature header is required",
          })}
          placeholder="e.g. X-Signature"
          fullWidth
        />
      </FormItem>
      <InboundWebhookSecretField
        dataLoc={`${environment}-webhook-hmac-signing-key`}
        environment={environment}
        helpText="The secret key used to generate the HMAC signature. Ensure it matches the value set in the data provider's system. Depending on the provider, you might need to generate the secret key on your own."
        isErrored={isFieldErrored(settingsErrors?.signingKey, "value")}
        secretConfigField="webhookHMACConfig.signingKey"
        secretName="Signing key"
        isRequired
      />
    </FieldsetCard>
  );
};
