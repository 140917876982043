import { FlowT } from "src/api/flowTypes";
import {
  FlowVersionStatus,
  ReviewDb,
  ReviewStatus,
} from "src/clients/flow-api";

type OptionalReviewDb = ReviewDb | undefined | null;

const isActive = (
  review: OptionalReviewDb,
  flowVersionStatus: FlowVersionStatus,
) =>
  !!review &&
  review.review_status !== ReviewStatus.CANCELLED &&
  flowVersionStatus === FlowVersionStatus.DRAFT;

const isCancelled = (review: OptionalReviewDb) =>
  review?.review_status === ReviewStatus.CANCELLED;

const isReviewRequired = (flow: FlowT) =>
  flow.review_configuration?.requires_review ?? false;

export const canRequestReview = (
  flow: FlowT,
  flowVersionStatus: FlowVersionStatus,
  review: OptionalReviewDb,
) =>
  flowVersionStatus === FlowVersionStatus.DRAFT &&
  ((review === null && isReviewRequired(flow)) || isCancelled(review));

export const canEditReview = (
  review: OptionalReviewDb,
  flowVersionStatus: FlowVersionStatus,
) => isActive(review, flowVersionStatus);

export const canAddReview = (
  review: OptionalReviewDb,
  flowVersionStatus: FlowVersionStatus,
) => isActive(review, flowVersionStatus);

export const canRerequestReview = (
  review: OptionalReviewDb,
  flowVersionStatus: FlowVersionStatus,
  currentUserId: string,
) => {
  const reviewerIds = Object.keys(review?.reviewers_status ?? {});

  return (
    isActive(review, flowVersionStatus) &&
    !(reviewerIds.length === 1 && reviewerIds[0] === currentUserId)
  );
};

export const canCancelReview = (
  review: OptionalReviewDb,
  flowVersionStatus: FlowVersionStatus,
) => isActive(review, flowVersionStatus);

export const publishingBlockedReason = (
  flow: FlowT,
  review: OptionalReviewDb,
) => {
  if (!isReviewRequired(flow)) {
    return false;
  }
  if (review === undefined) {
    return "Fetching review data";
  }

  // if review is null or has other status than approved, we should block publishing
  if (review?.review_status !== ReviewStatus.APPROVED) {
    return "Unable to publish version without the required approvals";
  }

  return false;
};
