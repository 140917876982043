import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { Input } from "src/base-components/Input";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { useGraphStore } from "src/store/StoreProvider";

type NameInput = { name: string };

export const RenameGroupModal: React.FC<{}> = observer(() => {
  const {
    updateGroup,
    isRenameNodeModalVisible,
    setIsRenameNodeModalVisible,
    groups,
    groupToChangeId,
    setGroupToChangeId,
  } = useGraphStore();
  const { register, reset, handleSubmit } = useForm<NameInput>({
    defaultValues: {
      name: groupToChangeId ? groups.get(groupToChangeId)?.data.label : "",
    },
  });

  // Set the initial group name
  useEffect(() => {
    if (groupToChangeId) {
      reset({ name: groups.get(groupToChangeId)?.data.label });
    }
  }, [groupToChangeId, groups, reset]);

  const onClose = () => {
    setIsRenameNodeModalVisible(false);
    setGroupToChangeId(null);
  };

  const submitHandler = handleSubmit(async (values) => {
    if (groupToChangeId) {
      await updateGroup({
        groupId: groupToChangeId,
        newName: values.name,
      });
    }
    onClose();
  });

  return (
    <ConfirmationModal
      confirmationButtonClassname="w-[6.4875rem]"
      confirmationButtonText="Save group"
      open={isRenameNodeModalVisible}
      title="Edit group details"
      onClose={onClose}
      onConfirm={submitHandler}
    >
      <form className="pb-6" onSubmit={submitHandler}>
        <div className="mb-2 text-gray-800 font-inter-semibold-13px">
          Edit group name <RequiredAsterisk />
        </div>
        <Input placeholder="Untitled group" fullWidth {...register("name")} />
      </form>
    </ConfirmationModal>
  );
});
