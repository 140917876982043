import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { OperatorDropdown } from "src/base-components/OperatorDropdown";
import { SimpleDropDown } from "src/base-components/SimpleDropDown";
import { ClauseFormT } from "src/clauseBlock/ClauseBlock";
import { Operators, Junctions } from "src/clients/flow-api";

type PropsT = {
  length: number;
  index: number;
  onDeleteClause: () => void;
  control: Control<ClauseFormT, any>;
  immutable: boolean;
  setValue: UseFormSetValue<ClauseFormT>;
  // Prefix is supposed to be 4 units wider than the junction
  // For example, if junction is w-20, prefix should be w-24
  prefixClassName: string;
  junctionWidth: `w-${number}`;
  fieldPlaceholder: string;
  valuePlaceholder: string;
  allowDeletingAllClauses?: boolean;
  errorLeft?: string;
  errorRight?: string;
};

const junctionDropdownOptions = Object.values(Junctions).map((val) => ({
  key: val,
  value: val,
}));

const getJunctionDataLoc = (index: number) => `clause-${index}-junction`;

export const ClauseRow: React.FC<PropsT> = ({
  index,
  onDeleteClause,
  length,
  control,
  immutable,
  setValue,
  prefixClassName,
  junctionWidth,
  fieldPlaceholder,
  valuePlaceholder,
  allowDeletingAllClauses,
  errorLeft,
  errorRight,
}) => {
  const renderJunction = () => {
    if (index === 0) {
      return (
        <div
          className={twMerge(
            "text-gray-700 font-inter-medium-12px",
            prefixClassName,
          )}
        >
          If
        </div>
      );
    }
    return (
      <Controller
        control={control}
        name="junction"
        render={(props) => {
          if (index !== 1) {
            return (
              <div
                className={junctionWidth}
                data-loc={getJunctionDataLoc(index)}
              >
                <Input value={props.field.value} disabled fullWidth />
              </div>
            );
          }
          return (
            <SimpleDropDown
              buttonClassName="font-inter-medium-12px"
              className={twMerge("h-8 shrink-0", junctionWidth)}
              dataLoc={getJunctionDataLoc(index)}
              disabled={immutable}
              elements={junctionDropdownOptions}
              itemsWidth={junctionWidth}
              placeholder=""
              placement="bottomLeft"
              selectedKey={props.field.value}
              onSelect={props.field.onChange}
            />
          );
        }}
      />
    );
  };

  const operator = useWatch({ name: `clauses.${index}.operator`, control });
  const isUnaryOperator = [Operators.IS_NONE, Operators.IS_NOT_NONE].some(
    (x) => x === operator,
  );

  useEffect(() => {
    if (isUnaryOperator) {
      setValue(`clauses.${index}.right`, "");
    }
  }, [isUnaryOperator, setValue, index]);

  return (
    <div className="my-2 flex grow-0 items-center gap-x-2">
      {renderJunction()}
      <div className="flex w-full gap-x-2">
        <Controller
          control={control}
          name={`clauses.${index}.left`}
          render={({ field }) => (
            <AutocompleteCodeInput
              dataLoc={`clause-${index}-left`}
              disabled={immutable}
              error={errorLeft}
              placeholder={fieldPlaceholder}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name={`clauses.${index}.right`}
          render={({ field }) => (
            <AutocompleteCodeInput
              dataLoc={`clause-${index}-right`}
              disabled={immutable}
              error={errorRight}
              placeholder={!isUnaryOperator ? valuePlaceholder : ""}
              readOnly={isUnaryOperator}
              value={field.value}
              onChange={field.onChange}
            >
              <Controller
                control={control}
                name={`clauses.${index}.operator`}
                render={(props) => (
                  <OperatorDropdown
                    disabled={immutable}
                    value={operator}
                    withIsAnyOperator={false}
                    onChange={props.field.onChange}
                  />
                )}
              />
            </AutocompleteCodeInput>
          )}
        />
        {(length !== 1 || allowDeletingAllClauses) && (
          <Icon
            color={
              immutable ? "text-gray-400" : "text-gray-500 hover:text-gray-700"
            }
            disabled={immutable}
            icon={faTrashAlt}
            size="xs"
            onClick={onDeleteClause}
          />
        )}
      </div>
    </div>
  );
};
