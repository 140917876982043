import { useFormContext } from "react-hook-form";

import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { InboundWebhookSecretField } from "src/connections/config/inboundWebhook/InboundWebhookSecretField";
import { AuthMethodPropsT } from "src/connections/config/inboundWebhook/types";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { errorProps } from "src/connections/config/shared/errorProps";
import { InboundWebhookConnectionConfigInputsT } from "src/connections/types";

export const InboundWebhookApiKeyAuthFieldset: React.FC<AuthMethodPropsT> = ({
  environment,
}) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<InboundWebhookConnectionConfigInputsT>();
  const configField = `${environment}Config` as const;

  const settingsErrors = errors?.[configField]?.webhookApiKeyConfig;
  return (
    <FieldsetCard className="last:mb-0">
      <InboundWebhookSecretField
        dataLoc={`${environment}-webhook-api-key`}
        environment={environment}
        helpText="The API key for webhook authentication as specified by the provider that sends the webhook"
        isErrored={isFieldErrored(settingsErrors?.apiKey, "value")}
        secretConfigField="webhookApiKeyConfig.apiKey"
        secretName="API Key"
        isRequired
      />
      <FormItem
        gap="xs"
        helpClassName="mt-1"
        helpTooltip="The header within the webhook request that contains the API key"
        label="Header"
        isRequired
      >
        <Input
          data-loc={`${environment}-webhook-api-key-header`}
          {...errorProps(isFieldErrored(settingsErrors, "header"))}
          {...register(`${configField}.webhookApiKeyConfig.header`, {
            required: true,
          })}
          placeholder="e.g. Authorization"
          fullWidth
        />
      </FormItem>
      <FormItem
        gap="xs"
        helpClassName="mt-1"
        helpTooltip="The prefix that is sent with the API key"
        label="Prefix"
      >
        <Input
          data-loc={`${environment}-webhook-api-key-prefix`}
          {...errorProps(isFieldErrored(settingsErrors, "prefix"))}
          {...register(`${configField}.webhookApiKeyConfig.prefix`)}
          placeholder="e.g. Bearer"
          fullWidth
        />
      </FormItem>
    </FieldsetCard>
  );
};
