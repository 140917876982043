import { difference, isEmpty } from "lodash";

import { EnumOptionsBET } from "src/api/flowTypes";
import { Dataset, DatasetRow, DesiredType } from "src/api/types";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { VersionSchemas } from "src/datasets/DatasetTable/types";
import { REQUIRED_ERROR_MESSAGE } from "src/datasets/DatasetTable/validationErrorMessages";
import {
  ValidationResult,
  validateAny,
  validateArray,
  validateBoolean,
  validateDate,
  validateDatetime,
  validateEnum,
  validateInteger,
  validateNumber,
  validateObject,
  validateString,
} from "src/datasets/DatasetTable/validators";
import { DatasetIntegrationNode } from "src/datasets/utils";
import { assertUnreachable } from "src/utils/typeUtils";

export type CellId = `${number}_${string}`;

export const MOCK_COL_SEPARATOR = "##" as const;

export const parseCellId = (cellId: CellId): [number, string] => {
  const [rowId, colId] = cellId.split(/_(.*)/s, 2);
  return [parseInt(rowId), colId];
};

const getSchemaFields = (
  schemaData?: VersionSchemas["input"] | VersionSchemas["output"],
) => schemaData?.properties.map((prop) => prop.fieldName) ?? [];

export const getAvailableColumns = (
  dataset: Dataset,
  schemas: VersionSchemas,
  integrationNodes: DatasetIntegrationNode[],
) => {
  const inputSchemaFields = getSchemaFields(schemas.input);
  const outputSchemaFields = getSchemaFields(schemas.output);

  return {
    outputAvailable: difference(
      outputSchemaFields,
      dataset.output_columns.map((column) => column.name),
    ),
    inputAvailable: difference(
      inputSchemaFields,
      dataset.input_columns.map((column) => column.name),
    ),
    mockAvailable: integrationNodes
      .map((integrationNode) => {
        const matchingMockColumn = dataset.mock_columns.find(
          (column) => column.name === integrationNode.name,
        );

        if (matchingMockColumn && integrationNode.mockableChildNodes) {
          if (!matchingMockColumn.use_subflow_mocks) {
            return null;
          }

          const prefix = `${matchingMockColumn.name}${MOCK_COL_SEPARATOR}`;
          const definedSubMocks = dataset.mock_columns
            .filter((col) => col.name.startsWith(prefix))
            .map((subMock) => subMock.name.replace(prefix, ""));
          const possibleSubMocks = integrationNode.mockableChildNodes.map(
            (childNode) => childNode.name,
          );
          const diff = difference(possibleSubMocks, definedSubMocks);

          return diff.length > 0
            ? {
                name: integrationNode.name,
                subMocks: diff,
              }
            : null;
        }

        return matchingMockColumn
          ? null
          : { name: integrationNode.name, subMocks: null };
      })
      .filter((mock): mock is Exclude<typeof mock, null | undefined> =>
        Boolean(mock),
      ),
  };
};

export const getGroupColumns = (dataset: Dataset, schemas: VersionSchemas) => {
  const inputSchemaFields = getSchemaFields(schemas.input);

  const inputColumnsNames = dataset.input_columns.map((column) => column.name);
  const outputColumnsNames = dataset.output_columns.map(
    (column) => column.name,
  );
  const mockColumnsNames = dataset.mock_columns.map((column) => column.name);

  const input = inputColumnsNames.filter((name) =>
    inputSchemaFields.includes(name),
  );
  const auxiliary = inputColumnsNames.filter(
    (column) => !inputSchemaFields.includes(column),
  );

  return {
    input,
    output: outputColumnsNames,
    mock: mockColumnsNames,
    auxiliary,
  };
};

export const validateCellValue = (
  value: string,
  {
    type,
    nullable,
    required,
    enumValues,
  }: {
    type: DesiredType;
    nullable: boolean;
    required: boolean;
    enumValues?: EnumOptionsBET | null;
  },
): ValidationResult => {
  if (value.trim() === "") {
    if (required) {
      return REQUIRED_ERROR_MESSAGE;
    }

    return undefined;
  }

  if (nullable) {
    const trimmedValue = value.trim();

    if (trimmedValue === "null") {
      return undefined;
    }
  }

  switch (type) {
    case "boolean":
      return validateBoolean(value);
    case "integer":
      return validateInteger(value);
    case "number":
      return validateNumber(value);
    case "array":
      return validateArray(value);
    case "object":
      return validateObject(value);
    case "any":
      return validateAny(value);
    case "date":
      return validateDate(value);
    case "datetime":
      return validateDatetime(value);
    case "string":
      return validateString(value);
    case "enum":
      return validateEnum(value, enumValues);
    default:
      assertUnreachable(type);
      return undefined;
  }
};

export const isRowEmpty = (row: DatasetRow) =>
  isEmpty(row.mock_data) && isEmpty(row.output_data) && isEmpty(row.input_data);

export const MOCK_COLUMN_DISABLED_TOOLTIP = {
  title:
    'This Node is set to use the live Connection during test runs. If you would like to use mock external data instead adjust this setting under the "Advanced settings" tab on the Node.',
  action: {
    label: "Read more",
    onClick: () =>
      window.open(
        "https://docs.taktile.com/decision-design/datasets-and-testing/editing-datasets-and-resolving-incompatibility#mock-external-data",
        "_blank",
      ),
  },
};

const replaceAllKeywords = (
  value: string,
  keywords: Record<string, string>,
): string => {
  const val = value.trim();
  if (keywords[val]) {
    return value.replace(val, keywords[val]);
  }

  /**
   * The first part of the regex (\\\\"|"(?:\\\\"|[^"])*"|) neutralizes parts of the string surrounded
   * by quotes as explained in https://stackoverflow.com/a/23667311
   * The second one (:\\s*(${keyword})\\s*[,}]|) looks for the keyword in the values of a json object.
   * We account for the possible whitespace before and after and we look for the comma or the closing
   * bracket after it.
   * The third one ([\\[,]\\s*(${keyword})\\s*(?=[,\\]])) looks for the keyword in a json array.
   * We start looking for a comma or a opening bracket, then we account for the whitespace before and after
   * and finally look for the comma or the closing bracket after it. That last step is done in lookahead
   * to allow consecutive matches to be done.
   */
  const keywordsRegexString = Object.keys(keywords).join("|");
  const regex = new RegExp(
    `\\\\"|"(?:\\\\"|[^"])*"|:\\s*(${keywordsRegexString})\\s*[,}]|[\\[,]\\s*(${keywordsRegexString})\\s*(?=[,\\]])`,
    "g",
  );

  return value.replace(regex, (match, group1, group2) => {
    // The first part of the regex can match but has no groups
    if (!group1 && !group2) return match;
    // If group1 is not undefined the second part must have matched
    // then we take the match and replace the keyword
    else {
      const group = group1 ?? group2;
      return match.replace(group, keywords[group]);
    }
  });
};

export const jsonToPon = (json: string): string => {
  const keywords = {
    true: "True",
    false: "False",
    null: "None",
  };

  return replaceAllKeywords(json, keywords);
};

export const ponToJson = (pon: string): string => {
  const keywords = {
    True: "true",
    False: "false",
    None: "null",
  };

  return replaceAllKeywords(pon, keywords);
};

export const isLoopIntegrationNode = (node: DatasetIntegrationNode) => {
  return node.provider === NODE_TYPE.LOOP_NODE;
};

export const isFlowIntegrationNode = (node: DatasetIntegrationNode) => {
  return node.provider === NODE_TYPE.FLOW_NODE;
};

export const isParentIntegrationNode = (node: DatasetIntegrationNode) => {
  return isLoopIntegrationNode(node) || isFlowIntegrationNode(node);
};

export const getDesiredType = (node: DatasetIntegrationNode) =>
  isLoopIntegrationNode(node) ? "any" : "object";

/**
 * Tries to parse the value as JSON and returns the result.
 * If the value is not valid JSON, it returns the value as is.
 *
 * @returns The parsed value or the original value if the parsing fails.
 */
export const parseJsonOrReturnAsIs = (value: string): any => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};
