import { twJoin } from "tailwind-merge";

type HorizontalProps = {
  orientation?: "horizontal";
  spacing?: `my-${number}`;
  width?: `w-${number}`;
};

type VerticalProps = {
  orientation?: "vertical";
  spacing?: `mx-${number}`;
  height?: `h-${number}`;
};

const isHorizontal = (
  props: HorizontalProps | VerticalProps,
): props is HorizontalProps => {
  return !props.orientation || props.orientation === "horizontal";
};

export const Divider: React.FC<HorizontalProps | VerticalProps> = (props) => (
  <hr
    className={twJoin(
      "rounded-full bg-gray-100",
      isHorizontal(props) ? "h-px" : "w-px",
      isHorizontal(props)
        ? (props.width ?? "w-full")
        : (props.height ?? "h-full"),
      props.spacing,
    )}
  />
);
