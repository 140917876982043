import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { NO_FOLDER_FILTER } from "src/api";
import { foldersApi } from "src/api/endpoints";
import { flowKeys } from "src/api/queries";
import {
  FlowFolderCreate,
  FlowFolderListResponse,
  FlowFolderUpdate,
} from "src/clients/flow-api";

// Flows hooks
export const folderKeys = {
  all: ["folders"] as const,
  workspaceList: (workspaceId: string | undefined) =>
    [...folderKeys.all, workspaceId] as const,
};

export const useFolders = ({ workspaceId }: { workspaceId?: string }) => {
  return useQuery<FlowFolderListResponse, Error>(
    folderKeys.workspaceList(workspaceId),
    async () => {
      if (workspaceId === undefined) {
        return {
          folders: [],
          flows_outside_folders_count: 0,
        };
      } else {
        return (
          await foldersApi.getFoldersApiV1FoldersWorkspaceIdGet(workspaceId)
        ).data;
      }
    },
    { enabled: !!workspaceId },
  );
};

export const useCreateFolder = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (para: FlowFolderCreate) => {
      return await foldersApi.createFolderApiV1FoldersPost(para);
    },
    {
      onSuccess: (_, args) => {
        queryClient.invalidateQueries(
          folderKeys.workspaceList(args.workspace_id),
        );
      },
    },
  );
};

export const useEditFolder = (workspaceId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      folderId,
      flowFolderUpdate,
    }: {
      folderId: string;
      flowFolderUpdate: FlowFolderUpdate;
    }) => {
      return await foldersApi.updateFolderApiV1FoldersFolderIdPatch(
        folderId,
        flowFolderUpdate,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(folderKeys.workspaceList(workspaceId));
      },
    },
  );
};

export const useDeleteFolder = (workspaceId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (folderId: string) => {
      return await foldersApi.deleteFolderApiV1FoldersFolderIdDelete(folderId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(folderKeys.workspaceList(workspaceId));
        // Flows in deleted folder end up outside a folder
        queryClient.refetchQueries(
          flowKeys.workspaceListFilteredOrdered(workspaceId, NO_FOLDER_FILTER),
        );
      },
    },
  );
};
