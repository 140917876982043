import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import { commentsApi } from "src/api/endpoints";
import { changeHistoryKeys } from "src/changeHistory/queries";
import {
  CommentCreate,
  CommentDb,
  CommentResourceType,
  CommentSummary,
  CommentUpdate,
} from "src/clients/flow-api";
import { queryClient } from "src/queryClient";

const commentsKeys = {
  all: ["comments"] as const,
  specific: (
    flowVersionId: string,
    resourceType?: CommentResourceType,
    resourceId?: string,
    resolved?: boolean,
    search?: string,
  ) =>
    [
      ...commentsKeys.all,
      { flowVersionId, resourceType, resourceId, resolved, search },
    ] as const,
  replies: (commentId: string) =>
    [...commentsKeys.all, commentId, "replies"] as const,
};

type PaginationParams = { limit: number; skip: number };
const defaultPageParams = { limit: 100, skip: 0 };

export const useComments = (
  flowVersionId: string,
  resourceType?: CommentResourceType,
  resourceId?: string,
  resolved?: boolean,
  search?: string,
) => {
  return useInfiniteQuery<CommentDb[], Error>({
    getNextPageParam: (lastPage, allPages): PaginationParams | undefined => {
      if (lastPage.length === defaultPageParams.limit) {
        return {
          limit: defaultPageParams.limit,
          skip: defaultPageParams.limit * allPages.length,
        };
      }
      return undefined;
    },
    keepPreviousData: true,
    queryFn: async ({ pageParam = defaultPageParams }) =>
      (
        await commentsApi.getCommentsApiV1CommentsGet(
          flowVersionId,
          resourceType,
          resourceId,
          undefined,
          resolved,
          search,
          pageParam.skip,
          pageParam.limit,
        )
      ).data,
    queryKey: commentsKeys.specific(
      flowVersionId,
      resourceType,
      resourceId,
      resolved,
      search,
    ),
  });
};

export const useCommentReplies = (commentId: string, enabled: boolean) => {
  return useInfiniteQuery<CommentDb[], Error>({
    enabled,
    keepPreviousData: true,
    queryKey: commentsKeys.replies(commentId),
    queryFn: async ({ pageParam = defaultPageParams }) =>
      (
        await commentsApi.getRepliesApiV1CommentsCommentIdRepliesGet(
          commentId,
          pageParam.skip,
          pageParam.limit,
        )
      ).data,
    getNextPageParam: (lastPage, allPages): PaginationParams | undefined => {
      if (lastPage.length === defaultPageParams.limit) {
        return {
          limit: defaultPageParams.limit,
          skip: defaultPageParams.limit * allPages.length,
        };
      }
      return undefined;
    },
  });
};

export const useCreateComment = () => {
  return useMutation({
    mutationFn: (comment: {
      commentCreate: CommentCreate;
      id: string;
      userId: string;
    }) =>
      commentsApi.createCommentApiV1CommentsCommentIdPut(
        comment.id,
        comment.commentCreate,
      ),
    onMutate: ({ commentCreate, id, userId }) => {
      if (commentCreate.parent) {
        const now = new Date().toISOString();
        const newComment: CommentDb = {
          ...commentCreate,
          id,
          author: userId,
          created_at: now,
          reply_count: 0,
          resolved: false,
          updated_at: now,
        };

        const key = commentsKeys.replies(commentCreate.parent);
        const { pages = [[]], pageParams = [] } =
          queryClient.getQueryData<InfiniteData<CommentDb[]>>(key) ||
          ({} as const);
        const [lastPage] = pages?.slice(-1) || [[]];
        const isLastPageFull = lastPage.length === defaultPageParams.limit;
        const updatedLastPage = isLastPageFull
          ? [newComment]
          : [...lastPage, newComment];
        const updatedPages = isLastPageFull
          ? [...pages, updatedLastPage]
          : [...pages.slice(0, -1), updatedLastPage];
        const fixedPageParams =
          pages.length === 1 ? [{ ...defaultPageParams }] : pageParams;
        const updatedPageParams = isLastPageFull
          ? [
              ...fixedPageParams,
              {
                ...defaultPageParams,
                skip: (pages.length + 1) * defaultPageParams.skip,
              },
            ]
          : pageParams;

        queryClient.setQueryData(key, {
          pages: updatedPages,
          pageParams: updatedPageParams,
        });

        return { previousData: { pages, pageParams } };
      }
    },
    onError: (_, { commentCreate }, context) => {
      if (commentCreate.parent && context) {
        const key = commentsKeys.replies(commentCreate.parent);
        queryClient.setQueryData(key, context.previousData);
      }
    },
    onSettled: (_, __, { commentCreate }) => {
      if (commentCreate.parent) {
        queryClient.invalidateQueries({
          queryKey: commentsKeys.all,
          refetchType: "none",
        });
      } else {
        queryClient.invalidateQueries(commentsKeys.all);
      }
    },
  });
};

export const useUpdateComment = (commentId: string) => {
  return useMutation({
    mutationFn: (commentUpdate: CommentUpdate) =>
      commentsApi.updateCommentApiV1CommentsCommentIdPatch(
        commentId,
        commentUpdate,
      ),
    onSettled: (response) => {
      if (!response?.data) {
        return;
      }

      if (response.data.parent) {
        queryClient.invalidateQueries(
          commentsKeys.replies(response.data.parent),
        );
      } else {
        queryClient.invalidateQueries(commentsKeys.all);
        queryClient.invalidateQueries(changeHistoryKeys.all);
      }
    },
  });
};

export const useDeleteComment = () => {
  return useMutation({
    mutationFn: ({ id }: CommentDb) =>
      commentsApi.deleteCommentApiV1CommentsCommentIdDelete(id),
    onSettled: () => {
      queryClient.invalidateQueries(commentsKeys.all);
      queryClient.invalidateQueries(changeHistoryKeys.all);
    },
  });
};

/**
 * Mapping flowVersionId to the last seen etag of data put into the query chache.
 */
const commentSummaryLastEtags: Map<string, string> = new Map();

export const getCommentSummaryKey = (flowVersionId: string | undefined) =>
  ["comments", "summary", flowVersionId] as const;

export const useCommentsSummary = (
  flowVersionId: string | undefined,
  nodeIds?: string | string[],
) => {
  return useQuery<CommentSummary, Error, number>({
    queryFn: async () => {
      const cachedData: CommentSummary | undefined = queryClient.getQueryData(
        getCommentSummaryKey(flowVersionId),
      );
      if (!flowVersionId) {
        return {
          flow_version_count: 0,
          review_comment_count: 0,
          nodes_comment_summary: [],
        };
      }
      try {
        const lastSeenEtag = commentSummaryLastEtags.get(flowVersionId);
        const response =
          await commentsApi.getCommentsSummaryApiV1CommentsSummaryFlowVersionIdGet(
            flowVersionId,
            lastSeenEtag,
          );
        commentSummaryLastEtags.set(flowVersionId, response.headers.etag);
        return response.data;
      } catch (err: any) {
        if (err.response?.status === 304 && cachedData) {
          return cachedData;
        } else {
          throw err;
        }
      }
    },
    queryKey: getCommentSummaryKey(flowVersionId),
    refetchInterval: 1000 * 5, // Refetch every 5 seconds
    select: (commentsSummary): number => {
      if (Array.isArray(nodeIds)) {
        return commentsSummary.nodes_comment_summary.reduce((acc, counts) => {
          return acc + (nodeIds.includes(counts.node_id) ? counts.comments : 0);
        }, 0);
      } else if (typeof nodeIds == "string") {
        return (
          commentsSummary.nodes_comment_summary.find(
            (nodeSummary) => nodeSummary.node_id === nodeIds,
          )?.comments ?? 0
        );
      }

      // Return total comments in the system
      return commentsSummary.nodes_comment_summary.reduce((acc, counts) => {
        return acc + counts.comments;
      }, commentsSummary.flow_version_count + commentsSummary.review_comment_count);
    },
  });
};
