import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import toast, { ToastBar } from "react-hot-toast";
import hotToast from "react-hot-toast";

import { Icon } from "src/base-components/Icon";
import {
  getIcon,
  RESET_TOASTBAR_STYLES,
} from "src/base-components/Toast/Toast";
import { DEFAULT_TOAST_DURATION_MS } from "src/base-components/Toast/utils";
import {
  ToastMultiItemsParams,
  ToastMultiItemsProps,
} from "src/base-components/ToastMultiState/types";

/**
 * Toast component
 * NOTE: Avoid using this component directly, use helpers from utils
 */
const ToastMultiState: React.FC<ToastMultiItemsProps> = ({
  dismissible = true,
  title,
  items,
  toastInstance,
}) => {
  return (
    <ToastBar style={RESET_TOASTBAR_STYLES} toast={toastInstance}>
      {() => (
        <div className="w-[365px] flex-col gap-x-2 rounded-2xl bg-white p-5 shadow-lg">
          <div className="mb-2.5 flex flex-grow flex-col gap-y-1.5">
            <div className="mb-0.5 flex justify-between">
              <p className="text-gray-800 font-inter-semibold-16px">{title}</p>
              {dismissible && (
                <Icon
                  color="text-gray-500 hover:text-gray-700"
                  dataLoc="notification-close"
                  icon={faXmark}
                  size="base"
                  onClick={() => toast.dismiss(toastInstance.id)}
                />
              )}
            </div>
          </div>
          {items.map((item) => {
            return (
              <div className="flex items-center gap-x-2 text-gray-500 font-inter-normal-13px not-last:mb-3">
                <span className="self-start">{getIcon(item.variant)}</span>{" "}
                {item.description}
              </div>
            );
          })}
        </div>
      )}
    </ToastBar>
  );
};

export const toastMultiItems = ({
  id,
  duration,
  withSidebar,
  items,
  ...params
}: ToastMultiItemsParams): string =>
  hotToast.custom(
    (t) => <ToastMultiState items={items} toastInstance={t} {...params} />,
    {
      id: id,
      duration:
        duration === null || params.dismissible === false
          ? Infinity
          : (duration ?? DEFAULT_TOAST_DURATION_MS),
      position: "bottom-left",
      className: withSidebar ? "ml-[166px]" : "",
    },
  );
