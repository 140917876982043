import { useCallback } from "react";

import { FieldErrorsT, NodeExecutionMetadata } from "src/api/types";
import { DatabaseConnectionNode } from "src/constants/NodeDataTypes";
import {
  QueryConfigTab,
  getEvaluatedVariables,
} from "src/databaseDebugPopover/BaseDebugPopover";
import { useRightPane } from "src/flowContainer/hooks/useRightPane";
import { NodeEditorOptions } from "src/router/SearchParams";
import { useGraphStore } from "src/store/StoreProvider";

const useOpenEditor = () => {
  const { setSelectedNode } = useGraphStore();
  const { setRightPaneTab } = useRightPane();
  return useCallback(
    (nodeId: string) => {
      setSelectedNode(nodeId);
      setRightPaneTab(NodeEditorOptions.Logic);
    },
    [setRightPaneTab, setSelectedNode],
  );
};

export const ExecutedQuery: React.FC<{
  node: DatabaseConnectionNode;
  fieldErrors: FieldErrorsT | undefined;
  nodeExecutionMetadata?: NodeExecutionMetadata;
  onClose?: () => void;
  isFetching?: boolean;
}> = ({ node, fieldErrors, nodeExecutionMetadata, onClose, isFetching }) => {
  const openEditor = useOpenEditor();

  const evaluatedVariables = getEvaluatedVariables(
    node.id,
    node.data.variables,
    nodeExecutionMetadata,
  );
  const sqlQuery = node.data.query.expression;

  const switchToEditor = () => {
    openEditor(node.id);
    onClose?.();
  };

  return (
    <div className="py-2">
      <QueryConfigTab
        fieldErrors={fieldErrors}
        isFetching={isFetching}
        sqlQuery={sqlQuery}
        switchToEditor={switchToEditor}
        variables={evaluatedVariables}
      />
    </div>
  );
};
