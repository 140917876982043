import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Modal } from "src/base-components/Modal";
import { MonospacedInput } from "src/base-components/MonospacedInput";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { Textarea } from "src/base-components/Textarea";
import { toastSuccess } from "src/base-components/Toast/utils";
import { Parameter } from "src/clients/flow-api";
import { ParameterForm } from "src/globalParameters/GlobalParametersList";

type PropsT = {
  editParameter: (param: ParameterForm) => Promise<void>;
  onClose: () => void;
  isOpen: boolean;
  isAdding: boolean;
  defaultValues: Partial<Parameter>;
  expressionFocused?: boolean;
  currentParameterNames: string[];
  focusValueInput?: boolean;
};

const PYTHON_DICT_KEY_REGEX = /^[a-zA-Z_][a-zA-Z0-9_]*$/;

const validateValueInput = (value?: string) => {
  if (!value) {
    return "Parameter value cannot be empty";
  }
  return true;
};

export const EditParameterModal: React.FC<PropsT> = ({
  editParameter,
  onClose,
  isOpen,
  isAdding,
  defaultValues,
  currentParameterNames,
  focusValueInput = false,
}) => {
  const { reset, handleSubmit, register, formState, setError, setFocus } =
    useForm<ParameterForm>({
      defaultValues,
      mode: "onChange",
    });
  const [title, setTitle] = useState("Add Parameter");

  useEffect(() => {
    if (isOpen) {
      if (isAdding) {
        setTitle("Add Parameter");
      } else {
        setTitle("Edit Parameter");
      }
    }
  }, [isAdding, isOpen]);

  useEffect(() => {
    // Reset the modal every time it is opened
    if (isOpen) {
      reset(defaultValues);
    }
  }, [reset, defaultValues, isOpen]);

  useEffect(() => {
    if (isOpen && focusValueInput) {
      // We need to set a timeout so that this gets executed after the form is initialized
      setTimeout(() => setFocus("expr"), 0);
    }
  }, [focusValueInput, setFocus, isOpen]);

  const onModalConfirm = handleSubmit(async (parameter) => {
    try {
      await editParameter(parameter);
      toastSuccess({ title: "Successfully edited Parameter" });
      onClose();
    } catch (error: any) {
      //TODO AUTH-1950 type this correctly?
      if (error["detail"] === "Invalid expression")
        setError(
          "expr",
          {
            type: "invalidExpression",
            message:
              "Parameter value must be a basic python value. See the docs for details.",
          },
          { shouldFocus: true },
        );
      else {
        onClose();
      }
    }
  });

  const validateNameInput = (name: string) => {
    if (!name) {
      return "Parameter name cannot be empty";
    } else if (
      currentParameterNames
        .filter((p) => p !== defaultValues.name)
        .includes(name)
    ) {
      return "Parameter name already exists";
    } else if (!PYTHON_DICT_KEY_REGEX.test(name)) {
      return "Parameter name must begin with a letter, and may only include letters, numbers, and underscores.";
    }
    return true;
  };

  return (
    <Modal className="w-120" open={isOpen} title={title} onClose={onClose}>
      <div className="px-6 pb-2 pt-6">
        <p className="mb-2 font-inter-semibold-13px">
          Name <RequiredAsterisk />
        </p>
        {formState.errors.name && (
          <ErrorHint>{formState.errors.name.message}</ErrorHint>
        )}
        <MonospacedInput
          errored={!!formState.errors.name}
          formProps={register("name", {
            validate: validateNameInput,
          })}
          inputDataLoc="name-input"
          placeholder="name"
          prefix="params."
          codeColors
        />
        <p className="mb-2 mt-4 font-inter-semibold-13px">Description</p>
        <Textarea
          data-loc="description-input"
          placeholder="Add description here..."
          {...register("description")}
        />
        <p className="mb-2 mt-4 font-inter-semibold-13px">
          Value <RequiredAsterisk />
        </p>
        {formState.errors.expr && (
          <ErrorHint>{formState.errors.expr.message}</ErrorHint>
        )}
        <MonospacedInput
          containerClassName="mb-4"
          errored={!!formState.errors.expr}
          formProps={register("expr", {
            validate: validateValueInput,
          })}
          inputDataLoc="value-input"
          placeholder="Enter Parameter value here..."
          codeColors
        />
      </div>
      <div className="mb-6 flex items-center justify-end gap-2 border-t border-gray-200 pr-6 pt-6 text-right">
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <span className="inline-block w-[4.4rem]">
          <Button
            dataLoc="confirm"
            disabled={!isEmpty(formState.errors)}
            loading={formState.isSubmitting}
            variant="primary"
            onClick={async () => {
              await onModalConfirm();
            }}
          >
            Save
          </Button>
        </span>
      </div>
    </Modal>
  );
};
