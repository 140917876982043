import React, { forwardRef } from "react";
import { twJoin } from "tailwind-merge";

type CardVariantT = "default" | "inverted";

type CommonPropsT = { className?: string };
type PropsT = {
  dataLoc?: string;
  variant?: CardVariantT;
  children: React.ReactNode;
  shadow?: boolean;
} & Omit<React.ComponentPropsWithoutRef<"div">, "className"> &
  CommonPropsT;
type HeaderPropsT = { children: React.ReactNode } & CommonPropsT;
type TitlePropsT = { title: string; dataLoc?: string } & CommonPropsT;
type ContentPropsT = {
  children: React.ReactNode | React.ReactNode[];
} & CommonPropsT;

type CardComponent = React.ForwardRefExoticComponent<
  PropsT & React.RefAttributes<HTMLDivElement>
> & {
  Title: React.FC<TitlePropsT>;
  Header: React.FC<HeaderPropsT>;
  Content: React.FC<ContentPropsT>;
};

export const Card = forwardRef<HTMLDivElement, PropsT>(
  (
    {
      dataLoc,
      variant = "default",
      shadow = false,
      className,
      children,
      ...restOfProps
    },
    ref,
  ) => (
    <div
      ref={ref}
      className={twJoin(
        "rounded-md border px-4 pb-4 pt-3",
        variant === "default" && "border-transparent bg-gray-50",
        variant === "inverted" && "border-gray-200 bg-white",
        shadow && "shadow-sm",
        className,
      )}
      data-loc={dataLoc}
      {...restOfProps}
    >
      {children}
    </div>
  ),
) as CardComponent;

Card.Title = ({ className, title, dataLoc }) => (
  <span
    className={twJoin("text-gray-600 font-inter-medium-12px", className)}
    data-loc={dataLoc}
  >
    {title}
  </span>
);

Card.Header = ({ className, children }) => (
  <div
    className={twJoin("flex items-center justify-between space-x-3", className)}
  >
    {children}
  </div>
);

Card.Content = ({ className, children }) => (
  <div className={twJoin("space-y-3", className)}>{children}</div>
);
