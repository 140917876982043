import {
  makeConnectionTemplate,
  ConnectionResourceTemplateConfigKey,
} from "src/connections/types";

const creditReportModels: { key: string; value: string }[] = [
  { key: "05402", value: "VantageScore 4.0 (05402)" },
  { key: "05483", value: "VantageScore 4.0 (05483)" },
  { key: "05143", value: "VantageScore 3.0 (05143)" },
  { key: "05728", value: "Core v2 Essential attributes (05728)" },
  { key: "05705", value: "Core v2 Credit card attributes (05705)" },
  { key: "04129", value: "Ability to Pay v1 attributes (04129)" },
  { key: "05234", value: "ACRO report attributes (05234)" },
];

const defaultConfigKeys: ConnectionResourceTemplateConfigKey[] = [
  {
    key: "member_number",
    name: "Member number",
    hint: `Your unique Equifax Customer number, a 10-character alphanumeric identifier that is used for access and invoicing purposes`,
    required: true,
    type: "text",
  },
  {
    key: "security_code",
    name: "Security code",
    hint: `Your unique 3-character identifier assigned by Equifax before the go-live`,
    required: true,
    type: "text",
  },
  {
    key: "customer_code",
    name: "Customer code",
    hint: `Assigned customer code. Use "IAPI" unless directed otherwise. Up to 4 characters, defaults to "IAPI".`,
    required: false,
    type: "text",
  },
];

export const EquifaxUSTemplate = makeConnectionTemplate({
  provider: "equifax_us",
  secrets: [
    { key: "client_id", name: "Client ID", required: true },
    { key: "client_secret", name: "Client secret", required: true },
  ],
  resources: {
    one_view_report: {
      name: "OneView (Consumer Credit Report)",
      configKeys: [
        ...defaultConfigKeys,
        {
          key: "credit_report_models",
          name: "Credit report models",
          hint: `The list of models that can be requested in addition to the main credit report`,
          required: false,
          type: "multiple_selection",
          elements: creditReportModels,
        },
        {
          key: "optional_feature_codes",
          name: "Optional feature codes",
          hint: `The list of products that can be requested in addition to the main report, such as an MLA (Military Lending Act) report`,
          required: false,
          type: "multiple_selection",
          elements: [
            {
              key: "B",
              value: "Military Lending Act (MLA) report",
            },
          ],
        },
      ],
    },
    scores_and_attributes: {
      name: "Scores And Attributes",
      configKeys: [
        {
          key: "organization_code",
          name: "Organization code",
          hint: `The organization code is provided during the Equifax onboarding process`,
          required: true,
          type: "text",
        },
        {
          key: "orchestration_code",
          name: "Orchestration code",
          hint: `The decision type which specifies the orchestration of the application. Provided during the Equifax onboarding process.`,
          required: true,
          type: "text",
        },
        {
          key: "customer_code",
          name: "Customer code",
          hint: `Assigned customer code. Use "IAPI" unless directed otherwise. Up to 4 characters, defaults to "IAPI"`,
          required: false,
          type: "text",
        },
        {
          key: "credit_report_models",
          name: "Credit report models",
          hint: `The list of models that can be requested in addition to the main credit report`,
          required: false,
          type: "multiple_selection",
          elements: creditReportModels,
        },
      ],
    },
    mla_report: {
      name: "Military Lending Act (MLA) Report",
      configKeys: [
        // TODO: adjust fields when we have more info (INT-990)
        ...defaultConfigKeys,
      ],
    },
  },
});
