import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowT } from "src/api/flowTypes";
import { DecideDecisionStatus } from "src/api/types";
import { FlowVersionStatus } from "src/clients/flow-api";

export const mockFlow: FlowT = {
  created_at: "2022-12-21T08:01:20.196290+00:00",
  updated_at: "2022-12-21T09:19:58.000031+00:00",
  charts: [],
  chart_order: [],
  meta: {
    description: "Staging test",
  },
  name: "Consumer Load Underwriting",
  slug: "consumer-load-underwriting",
  workspace_id: "c60396a8-a47a-4521-a4a2-60b321278db8",
  flow_folder_id: null,
  review_configuration: {
    requires_review: false,
    require_all_reviewers: false,
    default_reviewer_list: [],
  },
  id: "4909ea33-efdd-4894-bdc4-5ee0bf29566a",
  default_version: "f0482da1-e244-4c82-9789-99229a9e9a30",
  versions: [
    {
      created_at: "2023-03-27T15:35:58.150682+00:00",
      updated_at: "2023-03-27T16:58:01.741122+00:00",
      meta: {
        release_note: "",
        created_by_id: "82c5c633-8d27-448e-9b78-125e4c027ef4",
      },
      etag: "00000064",

      id: "328ebb23-2a57-4481-8557-7f98024070d8",
      name: "rule-to-decision",
      status: FlowVersionStatus.DRAFT,
      child_flows: [],
      parent_flows: [],
      review: null,
    },
  ],
  traffic_policies: [],
};

export const mockDecisions: DecisionHistoryRecordV2[] = [
  {
    id: "26a22e1e-8e41-45e7-a87a-1baf902e8df0",
    flow: {
      id: "4909ea33-efdd-4894-bdc4-5ee0bf29566a",
      slug: "john-testing",
      version_id: "d52bd12f-ac9f-4fe6-ac6a-8c7237f7ffa2",
      version_name: "delay",
    },
    entity_id: "string",
    is_error: false,
    error: null,
    is_sandbox: true,
    duration_in_milliseconds: 31934,
    start_time: "2024-09-11T14:32:36.218254+00:00",
    end_time: "2024-09-11T14:33:08.153101+00:00",
    masked_fields: [],
    request: {
      data: {},
      metadata: {
        version: "delay",
        entity_id: "string",
      },
    },
    response: {
      data: {},
      metadata: {
        version: "delay",
        entity_id: "string",
      },
      status: DecideDecisionStatus.SUCCESS,
    },
    node_results: {
      nodes: {},
      node_execution_metadata: {},
    },
    external_resources: null,
    parent_flow_id: null,
    parent_flow_version_id: null,
    parent_decision_id: null,
    linked_decisions: null,
    flow_version_etag: "00000031",
    xray_trace_id: null,
    status_code: "408",
  },
  {
    id: "4d1dfbce-0afb-45f7-ad23-22d0e5bbd5fe",
    flow: {
      id: "4909ea33-efdd-4894-bdc4-5ee0bf29566a",
      slug: "john-testing",
      version_id: "d52bd12f-ac9f-4fe6-ac6a-8c7237f7ffa2",
      version_name: "delay",
    },
    entity_id: "string",
    is_error: false,
    error: null,
    is_sandbox: true,
    duration_in_milliseconds: 7167,
    start_time: "2024-09-11T14:31:53.386310+00:00",
    end_time: "2024-09-11T14:32:00.553312+00:00",
    masked_fields: [],
    request: {
      data: {},
      metadata: {
        version: "delay",
        entity_id: "string",
      },
    },
    response: {
      data: {},
      metadata: {
        version: "delay",
        entity_id: "string",
      },
      status: DecideDecisionStatus.SUCCESS,
    },
    node_results: {
      nodes: {},
      node_execution_metadata: {},
    },
    external_resources: null,
    parent_flow_id: null,
    parent_flow_version_id: null,
    parent_decision_id: null,
    linked_decisions: null,
    flow_version_etag: "00000031",
    xray_trace_id: null,
    status_code: "200",
  },
  {
    id: "28fa6f4e-e8dc-4a88-ac94-709200f370ca",
    flow: {
      id: "4909ea33-efdd-4894-bdc4-5ee0bf29566a",
      slug: "john-testing",
      version_id: "d52bd12f-ac9f-4fe6-ac6a-8c7237f7ffa2",
      version_name: "delay",
    },
    entity_id: "string",
    is_error: true,
    error: null,
    is_sandbox: true,
    duration_in_milliseconds: 35753,
    start_time: "2024-09-11T14:23:11.659551+00:00",
    end_time: "2024-09-11T14:23:47.413414+00:00",
    masked_fields: [],
    request: {
      data: {},
      metadata: {
        version: "delay",
        entity_id: "string",
      },
    },
    response: {
      detail: {
        exc_type: "runtime",
        msg: "Error",
      },
      metadata: {
        version: "delay",
        entity_id: "string",
      },
      status: DecideDecisionStatus.ERROR,
    },
    node_results: {
      nodes: {},
      node_execution_metadata: {},
    },
    external_resources: null,
    parent_flow_id: null,
    parent_flow_version_id: null,
    parent_decision_id: null,
    linked_decisions: null,
    flow_version_etag: "00000031",
    xray_trace_id: null,
    status_code: "408",
  },
  {
    id: "ce064fd5-74fe-4181-9be2-0965a9147446",
    flow: {
      id: "4909ea33-efdd-4894-bdc4-5ee0bf29566a",
      slug: "john-testing",
      version_id: "d52bd12f-ac9f-4fe6-ac6a-8c7237f7ffa2",
      version_name: "delay",
    },
    entity_id: "string",
    is_error: false,
    error: null,
    is_sandbox: true,
    duration_in_milliseconds: 23800,
    start_time: "2024-09-11T14:05:19.932715+00:00",
    end_time: "2024-09-11T14:05:43.733701+00:00",
    masked_fields: [],
    request: {
      data: {},
      metadata: {
        version: "delay",
        entity_id: "string",
      },
    },
    response: {
      data: {},
      metadata: {
        version: "delay",
        entity_id: "string",
      },
      status: DecideDecisionStatus.SUCCESS,
    },
    node_results: {
      nodes: {},
      node_execution_metadata: {},
    },
    external_resources: null,
    parent_flow_id: null,
    parent_flow_version_id: null,
    parent_decision_id: null,
    linked_decisions: null,
    flow_version_etag: "00000031",
    xray_trace_id: null,
    status_code: "200",
  },
];
