import { useFormContext, Controller } from "react-hook-form";

import { RedshiftConnectionAuthMethod } from "src/api/connectApi/types";
import { FormItem } from "src/base-components/FormItem";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import { Switch } from "src/base-components/Switch";
import { Tooltip } from "src/base-components/Tooltip";
import { ConfigFieldsetCard } from "src/connections/config/FieldsetCard";
import {
  RedshiftConnectionConfigInputsT,
  Environment,
} from "src/connections/types";

const SSLHelpText: React.FC = () => {
  return (
    <p>
      When enabled, you can choose to verify the database server's CA
      certificate and host name. For AWS IAM authentication, this is required.
    </p>
  );
};

type PropsT = {
  environment: Environment;
  authMethod: RedshiftConnectionAuthMethod;
};

export const RedshiftConnectionSslConfigFields: React.FC<PropsT> = ({
  environment,
  authMethod,
}) => {
  const { control, watch } = useFormContext<RedshiftConnectionConfigInputsT>();

  const hasSslEnabledKey = `${environment}Config.hasSslEnabled` as const;
  const hasSslEnabled = watch(hasSslEnabledKey);

  return (
    <div className="mb-6 last:mb-0">
      <FormItem
        className="flex flex-row items-center justify-between"
        description="Verify the database server's CA certificate and host name"
        gap="xxs"
        helpTooltip={<SSLHelpText />}
        label="Enable SSL/TLS"
      >
        <Controller
          control={control}
          name={hasSslEnabledKey}
          render={(props) =>
            authMethod === "aws_iam" ? (
              <span className="ml-1 mt-1 inline-block">
                <Tooltip
                  align="center"
                  body="AWS IAM authentication requires SSL/TLS to be enabled"
                  placement="top"
                  title=""
                >
                  <Switch
                    disabled={true}
                    enabled={true}
                    onChange={() => {
                      props.field.onChange(!props.field.value);
                    }}
                  />
                </Tooltip>
              </span>
            ) : (
              <Switch
                enabled={props.field.value}
                onChange={() => {
                  props.field.onChange(!props.field.value);
                }}
              />
            )
          }
        />
      </FormItem>
      {hasSslEnabled && (
        <ConfigFieldsetCard>
          <FormItem gap="xxs" label="Verification mode" isRequired>
            <Controller
              control={control}
              name={`${environment}Config.sslConfig.mode`}
              render={(props) => (
                <SimpleRadioGroup
                  orientation="vertical"
                  value={String(props.field.value)}
                  onValueChange={props.field.onChange}
                >
                  <SimpleRadioGroup.Item
                    label="Verify CA certificate"
                    labelClassName="pl-2.5"
                    value="verify-ca"
                  />
                  <SimpleRadioGroup.Item
                    label="Full verification"
                    labelClassName="pl-2.5"
                    value="verify-full"
                  />
                </SimpleRadioGroup>
              )}
            />
          </FormItem>
        </ConfigFieldsetCard>
      )}
    </div>
  );
};
