import { faArrowLeft, faClose } from "@fortawesome/pro-regular-svg-icons";
import {
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  useClose,
} from "@headlessui2/react";
import { isString } from "lodash";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { twJoin, twMerge } from "tailwind-merge";
import { useBoolean } from "usehooks-ts";

import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";

type ModalSize = "sm" | "md" | "lg" | "xl";
export type ModalVariant = "default" | "danger";

type ModalContextProps = {
  size: ModalSize;
  variant: ModalVariant;
  autoFocus: boolean;
};

export type ModalProps = Partial<ModalContextProps> & {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  afterLeave?: () => void;
};

const ModalContext = createContext<ModalContextProps | undefined>(undefined);
const useModalProps = (componentName: string): ModalContextProps => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(
      `The ${componentName} component must be used within a Modal component`,
    );
  }
  return context;
};

const ModalHeader: React.FC<{
  children: React.ReactNode;
  description?: string;
  onClickBack?: () => void;
  icon?: React.ReactNode;
  hideCloseIcon?: boolean;
}> = ({ children, description, onClickBack, icon, hideCloseIcon = false }) => {
  const close = useClose();
  const { variant } = useModalProps("ModalHeader");

  return (
    <div className="px-5 pb-3 pt-4">
      <div className="flex items-center gap-x-2">
        {onClickBack && (
          <Icon
            color="text-gray-500 hover:text-gray-700"
            dataLoc="modal-back-button"
            icon={faArrowLeft}
            size="sm"
            onClick={onClickBack}
          />
        )}
        {icon && <div className="flex items-center justify-center">{icon}</div>}
        <DialogTitle
          className={twJoin(
            "flex-1 font-inter-semibold-16px",
            variant === "danger" ? "text-red-600" : "text-gray-800",
          )}
        >
          {children}
        </DialogTitle>
        {!hideCloseIcon && (
          <div className="flex items-center justify-self-auto">
            <Icon
              color="text-gray-500 hover:text-gray-700"
              dataLoc="modal-close"
              icon={faClose}
              padding={false}
              size="sm"
              onClick={close}
            />
          </div>
        )}
      </div>
      {description && (
        <Description className="mt-0.5 text-gray-500 font-inter-normal-13px">
          {description}
        </Description>
      )}
    </div>
  );
};

const getBorders = (e: HTMLDivElement) => {
  return {
    top: e.scrollTop > 0,
    bottom: e.scrollTop + e.clientHeight < e.scrollHeight,
  };
};

const ModalContent: React.FC<{
  children: React.ReactNode;
  noScroll?: boolean;
}> = ({ children, noScroll = false }) => {
  const { size, autoFocus } = useModalProps("ModalContent");
  const isLgOrXl = size === "lg" || size === "xl";

  const contentRef = useRef<HTMLDivElement>(null);
  const [borders, setBorders] = useState<{ top: boolean; bottom: boolean }>({
    top: false,
    bottom: false,
  });

  useEffect(() => {
    if (contentRef.current) {
      setBorders(getBorders(contentRef.current));
    }
  }, [size]);

  // Focus the first focusable element when the modal opens
  useEffect(() => {
    if (contentRef.current && autoFocus) {
      const focusableElement =
        contentRef.current?.querySelector<HTMLElement>("input, textarea");

      if (focusableElement && "focus" in focusableElement) {
        focusableElement.focus();
      }
    }
  }, [autoFocus]);

  return (
    <div
      ref={contentRef}
      className={twJoin(
        "min-h-0 justify-stretch justify-items-stretch border-t border-white text-gray-800 not-last:border-b",
        "transition-colors duration-150",
        (borders.top || isLgOrXl) && "border-t-gray-200",
        (borders.bottom || isLgOrXl) && "not-last:border-b-gray-200",

        noScroll
          ? "overflow-hidden px-5"
          : "decideScrollbar compensate-scrollbar-gutter p-5 pb-6 pr-1",

        "flex flex-col",
      )}
      data-loc="modal-content"
      onScroll={(e) => {
        if (!isLgOrXl) setBorders(getBorders(e.currentTarget));
      }}
    >
      {children}
    </div>
  );
};

const ModalFooter: React.FC<{
  children?: React.ReactNode;
  secondaryButton?: false | string | React.ReactNode;
  primaryButton?: false | string | React.ReactNode;
}> = ({
  children: tertiaryButtons,
  secondaryButton = "Cancel",
  primaryButton = "Save",
}) => {
  const close = useClose();
  const { variant, autoFocus } = useModalProps("ModalFooter");
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!autoFocus) return;

    const focusWithinModal =
      document.activeElement?.closest("[data-loc='modal']");

    if (!focusWithinModal && footerRef.current) {
      const primaryButton = footerRef.current.querySelector<HTMLButtonElement>(
        "button[type='submit']",
      );
      if (primaryButton) {
        primaryButton.focus();
      }
    }
  }, [autoFocus]);

  return (
    <div
      ref={footerRef}
      className="flex justify-between gap-x-2 px-5 py-4"
      data-loc="modal-footer"
    >
      <div className="flex gap-x-3">{tertiaryButtons}</div>
      <div className="flex gap-x-3">
        {isString(secondaryButton) ? (
          <Button
            aria-label={secondaryButton}
            dataLoc="modal-secondary-button"
            role="button"
            variant="secondary"
            onClick={close}
          >
            {secondaryButton}
          </Button>
        ) : (
          secondaryButton
        )}
        {isString(primaryButton) ? (
          <Button
            aria-label={primaryButton}
            dataLoc="modal-primary-button"
            htmlType="submit"
            role="button"
            variant={variant === "danger" ? "warning" : "primary"}
            onClick={close}
          >
            {primaryButton}
          </Button>
        ) : (
          primaryButton
        )}
      </div>
    </div>
  );
};

/**
 * Figma: https://www.figma.com/design/BO9DWO8PKNGNSI3HhpSobA/branch/dEcG1UbD9lcMuHyXR78uKF/Design-System-v2?node-id=4924-42068&m=dev
 */
export const Modal: React.FC<ModalProps> & {
  Header: typeof ModalHeader;
  Content: typeof ModalContent;
  Footer: typeof ModalFooter;
} = ({
  children,
  open,
  onClose,
  size = "sm",
  variant = "default",
  afterLeave,
  autoFocus = true,
}) => {
  const isNotXL = size !== "xl";

  return (
    <ModalContext.Provider value={{ size, variant, autoFocus }}>
      <Dialog
        className="relative isolate z-50 transition-opacity duration-300 ease-out data-[closed]:opacity-0"
        open={open}
        transition
        onClose={onClose}
        onTransitionEnd={() => {
          if (!open) {
            afterLeave?.();
          }
        }}
      >
        <DialogBackdrop className="fixed inset-0 bg-modal/80" />
        <div className="fixed inset-0 flex items-center justify-center">
          <DialogPanel
            className={twMerge(
              "rounded-lg bg-white shadow-2xl",
              "flex flex-col",

              // Fallback for tiny screens
              "max-h-[calc(100vh-80px)] min-h-[180px] max-w-[calc(100vw-80px)]",

              size === "sm" && "w-120",
              size === "md" && "w-[720px]",
              size === "lg" && "w-[1280px]",
              size === "xl" && "w-[calc(100vw-80px)]",

              isNotXL && "max-h-[720px]",

              // Width change animation
              "transition-all duration-300 ease-out",
              // In-Out animation
              "data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-200 data-[closed]:ease-out",

              // Form modals styling
              "[&>form]:flex [&>form]:min-h-0 [&>form]:flex-1 [&>form]:flex-col",
            )}
            data-loc="modal"
            transition
          >
            {children}
          </DialogPanel>
        </div>
      </Dialog>
    </ModalContext.Provider>
  );
};

Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;

/**
 * This hook expose some useful methods to handle modals
 * isOpen - controls the visibility of the modal
 * isVisible - when modal is open this is always true,
 *             but when `isOpen` turns to `false`, this variable
 *             become `true` only once the modal's close animation is finished.
 *             To make it work, you also need to use `afterLeave` hook,
 *             and pass it to the modal component.
 * If you use `data` argument you must use `afterLeave` hook in modal,
 * to make sure data is cleaned after modal leaves the screen.
 * @returns
 */
export const useModal = <T,>() => {
  const {
    value: isOpen,
    setTrue: setOpenTrue,
    setFalse: setOpenFalse,
  } = useBoolean();
  const {
    value: isVisible,
    setTrue: setVisibleTrue,
    setFalse: setVisibleFalse,
  } = useBoolean();
  const [data, setData] = useState<T | undefined>();

  return {
    isOpen,
    isVisible,
    data,
    openModal: (data?: T) => {
      if (data) setData(data);
      setOpenTrue();
      setVisibleTrue();
    },
    closeModal: setOpenFalse,
    afterLeave: () => {
      setData(undefined);
      setVisibleFalse();
      if (isOpen) setOpenFalse();
    },
  };
};
