/**
 * @returns The position of the node with the anchor point of React-flow (top left) from the given position with the anchor point center center
 */
export const dagreToReactFlow = ({
  xPosition,
  yPosition,
  width,
  height,
  xOffset,
  yOffset,
}: {
  xPosition: number;
  yPosition: number;
  width: number;
  height: number;
  xOffset?: number;
  yOffset?: number;
}) => {
  return {
    x: xPosition - width / 2 + (xOffset ?? 0),
    y: yPosition - height / 2 + (yOffset ?? 0),
  };
};
