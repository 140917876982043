import { useMemo } from "react";
import { twJoin } from "tailwind-merge";

import { StatusFilterKey } from "src/dataTable/TableUtils";

type Props = {
  successCount: number;
  failureCount: number;
  ignoredCount: number;
  mismatchCount?: number;
  onFilterChange: (filterKey: StatusFilterKey) => void;
  currentFilter: StatusFilterKey;
  activeSuccessFilter: "success" | "success_match";
};

/**
 * Calculate percentages rounded and adding up to 100 following the largest
 * remainder method: https://en.wikipedia.org/wiki/Largest_remainder_method
 */
const getRoundedDisplayPercentage = ({
  successCount,
  failureCount,
  ignoredCount,
  mismatchCount,
}: Pick<
  Props,
  "successCount" | "failureCount" | "ignoredCount" | "mismatchCount"
>): {
  successPercent: number;
  failurePercent: number;
  ignoredPercent: number;
  mismatchPercent: number;
} => {
  const totalCount = successCount + failureCount + ignoredCount;
  const fixedMismatchCount = mismatchCount ?? 0;

  let successPercent = (successCount / totalCount) * 100;
  let failurePercent = (failureCount / totalCount) * 100;
  let ignoredPercent = (ignoredCount / totalCount) * 100;
  let mismatchPercent = Math.floor((fixedMismatchCount / totalCount) * 100);

  // If any percentage is below 1 but not 0, we still show 1 percent
  // as to not suggest that there were no results of the given type.
  if (successPercent > 0 && successPercent < 1) successPercent = 1;
  if (failurePercent > 0 && failurePercent < 1) failurePercent = 1;
  if (ignoredPercent > 0 && ignoredPercent < 1) ignoredPercent = 1;
  if (fixedMismatchCount > 0 && mismatchPercent < 1) mismatchPercent = 1;

  // This method gets stuck with some
  // inputs like 7 failed and 1 ignored
  // so we limit the number of iterations to avoid infinite loops
  let iterations = 0;

  while (
    Math.floor(successPercent) +
      Math.floor(failurePercent) +
      Math.floor(ignoredPercent) <
      100 &&
    iterations < 100
  ) {
    if (
      failurePercent % 1 > successPercent % 1 &&
      failurePercent % 1 > ignoredPercent % 1
    ) {
      failurePercent = Math.ceil(failurePercent);
    } else if (
      ignoredPercent % 1 > successPercent % 1 &&
      ignoredPercent % 1 > failurePercent % 1
    ) {
      ignoredPercent = Math.ceil(ignoredPercent);
    } else {
      successPercent = Math.ceil(successPercent);
    }
    iterations++;
  }

  // Note: mismatchPercent is counterintuitive, but backend returns successCount to include mismatches
  // so we need to subtract mismatchPercent from successPercent to get the correct value
  // technically, mismatch is a success, but we want to display it as a separate category here
  return {
    successPercent: Math.floor(successPercent - mismatchPercent),
    failurePercent: Math.floor(failurePercent),
    ignoredPercent: Math.floor(ignoredPercent),
    mismatchPercent,
  };
};

const InfoItem: React.FC<{
  value: number;
  isActive: boolean;
  filter: StatusFilterKey;
  onChangeFilter: Props["onFilterChange"];
  color: string;
  title: string;
}> = ({ value, isActive, filter, onChangeFilter, color, title }) => (
  <div
    className={twJoin(
      "flex-1 py-3.5 pl-5",
      value <= 0 && "cursor-default",
      isActive && "bg-yellow-50",
    )}
    role="button"
    onClick={() => {
      if (value <= 0) return;

      onChangeFilter(isActive ? "all" : filter);
    }}
  >
    <p
      className={twJoin("font-inter-semibold-13px", color)}
      data-loc={`${filter}-percentage-${value}`}
    >
      {value}%
    </p>
    <p className="text-gray-800 font-inter-normal-12px">{title}</p>
  </div>
);

export const RowCountInfo: React.FC<Props> = ({
  onFilterChange,
  currentFilter,
  successCount,
  failureCount,
  ignoredCount,
  mismatchCount,
  activeSuccessFilter,
}) => {
  const { successPercent, failurePercent, ignoredPercent, mismatchPercent } =
    useMemo(
      () =>
        getRoundedDisplayPercentage({
          successCount,
          failureCount,
          ignoredCount,
          mismatchCount,
        }),
      [successCount, failureCount, ignoredCount, mismatchCount],
    );

  return (
    <div className="flex w-full divide-x divide-gray-200 overflow-hidden rounded-lg border border-gray-200">
      <InfoItem
        color="text-green-400"
        filter={activeSuccessFilter}
        isActive={currentFilter === activeSuccessFilter}
        title="Successful"
        value={successPercent}
        onChangeFilter={onFilterChange}
      />
      <InfoItem
        color="text-red-500"
        filter="failure"
        isActive={currentFilter === "failure"}
        title="Failed"
        value={failurePercent}
        onChangeFilter={onFilterChange}
      />
      {mismatchCount !== undefined && (
        <InfoItem
          color="text-yellow-600"
          filter="success_mismatch"
          isActive={currentFilter === "success_mismatch"}
          title="Output mismatch"
          value={mismatchPercent}
          onChangeFilter={onFilterChange}
        />
      )}
      <InfoItem
        color="text-gray-400"
        filter="ignored"
        isActive={currentFilter === "ignored"}
        title="Ignored"
        value={ignoredPercent}
        onChangeFilter={onFilterChange}
      />
    </div>
  );
};
