import { MutationOptions, QueryObserverOptions } from "@tanstack/react-query";
import { useCallback } from "react";

import {
  UploadModelContextT,
  useParsedModelData,
  useUploadModelFile,
} from "src/api/queries";
import { ParsedModelDataSuccessT, UploadModelDataT } from "src/api/types";

type UseUploadModelProps = {
  baseUrl?: string;
  flowSlug: string;
  onMutate: MutationOptions<
    any,
    any,
    UploadModelDataT,
    UploadModelContextT
  >["onMutate"];
  onSuccess: QueryObserverOptions<
    any,
    any,
    ParsedModelDataSuccessT
  >["onSuccess"];
  onError: (context?: UploadModelContextT) => void;
};

/**
 * Uploads a model file and the peridocally checks the status of the parsing.
 * Check while the parsing status is pending.
 *
 * @param baseUrl - Flow router base url
 * @param flowSlug - Flow slug
 * @param onMutate - Hook that is called right before uploading
 * @param onSuccess - Hook that is called when the parsing is successful
 * @param onError - Hook that is called when the parsing is failed
 * @returns An object {
 *   isLoading, // Parsing status is pending
 *   onFileSelect, // Callback to upload a file
 *   data, // Parsed model data
 *   error // Error object
 * }
 */
export const useUploadModel = ({
  onMutate,
  onSuccess,
  onError,
  baseUrl,
  flowSlug,
}: UseUploadModelProps) => {
  const uploadModel = useUploadModelFile({
    onMutate,
    onError: (_err, _variables, context) => {
      onError(context);
    },
  });

  const parsedModel = useParsedModelData(
    baseUrl,
    flowSlug,
    uploadModel.data?.modelId,
    {
      onSuccess: (data) => {
        // Do nothing on pending
        if (data.type === "success") {
          onSuccess?.(data);
        }
      },
      onError: () => {
        onError(uploadModel.context);
      },
    },
  );

  const isModelUploading =
    uploadModel.isLoading ||
    parsedModel.isFetching ||
    (!parsedModel.isError && parsedModel.data?.type === "pending");

  const onFileSelect = useCallback(
    (file: File) => {
      uploadModel.mutate({
        baseUrl: baseUrl!,
        flowSlug: flowSlug!,
        file,
      });
    },
    [baseUrl, flowSlug, uploadModel],
  );

  return {
    isLoading: isModelUploading,
    onFileSelect,
    data: parsedModel.data?.type === "success" ? parsedModel.data : undefined,
    error: uploadModel.error || parsedModel.error,
  };
};
