import { Transition } from "@headlessui/react";
import { Fragment } from "react";

type TransitionWrapperProps = {
  children: React.ReactNode;
  show?: boolean;
};

export const TransitionWrapper: React.FC<TransitionWrapperProps> = ({
  children,
  show,
}) => (
  <Transition
    as={Fragment}
    enter="transition ease-out duration-150"
    enterFrom="transform opacity-0 scale-95"
    enterTo="transform opacity-100 scale-100"
    leave="transition ease-in duration-150"
    leaveFrom="transform opacity-100 scale-100"
    leaveTo="transform opacity-0 scale-95"
    show={show}
  >
    {children}
  </Transition>
);
