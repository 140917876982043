import {
  faCircleCheck,
  faClock,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "src/base-components/Icon";

type PropsT = {
  statusCode: string;
};

type DecisiontStatus = "success" | "pending" | "error";
export const getDecisionStatus = (statusCode: string): DecisiontStatus => {
  if (statusCode === "200") return "success";
  if (statusCode === "202") return "pending";
  return "error";
};

export type DecisionStatusCode =
  (typeof POSSIBLE_DECISION_STATUS_CODES)[number]["key"];
export const POSSIBLE_DECISION_STATUS_CODES = [
  { key: "200", value: "Successful" },
  { key: "202", value: "Pending" },
  { key: "408", value: "Failed: timeout (408)" },
  { key: "409", value: "Failed: output schema (409)" },
  { key: "422", value: "Failed: input schema (422)" },
  { key: "424", value: "Failed: logic (424)" },
  { key: "500", value: "Failed: system (500)" },
] as const;

/**
 * Decision history status column shared between flow level and version level tables.
 */
export const SharedDecisionHistoryStatus: React.FC<PropsT> = ({
  statusCode,
}) => {
  if (statusCode === "200") {
    return (
      <div className="flex items-center whitespace-nowrap">
        <div className="mr-1">
          <Icon color="text-green-500" icon={faCircleCheck} size="xs" />
        </div>
        <span className="capitalize">
          {
            POSSIBLE_DECISION_STATUS_CODES.find(
              (entry) => entry.key === statusCode,
            )?.value
          }
        </span>
      </div>
    );
  }
  if (statusCode === "202") {
    return (
      <div className="flex items-center whitespace-nowrap">
        <div className="mr-1">
          <Icon color="text-indigo-400" icon={faClock} size="xs" />
        </div>
        <span className="capitalize">
          {
            POSSIBLE_DECISION_STATUS_CODES.find(
              (entry) => entry.key === statusCode,
            )?.value
          }
        </span>
      </div>
    );
  }
  return (
    <div className="flex items-center whitespace-nowrap">
      <div className="mr-1">
        <Icon color="text-red-600" icon={faWarning} size="xs" />
      </div>
      <span>
        {POSSIBLE_DECISION_STATUS_CODES.find(
          (entry) => entry.key === statusCode,
        )?.value ?? `Error (${statusCode})`}
      </span>
    </div>
  );
};
