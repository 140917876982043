import { UseQueryResult } from "@tanstack/react-query";
import { useState } from "react";

import { LoadingView } from "src/base-components/LoadingView";
import { toastSuccess } from "src/base-components/Toast/utils";
import {
  FlowFolderListItem,
  FlowFolderListResponse,
} from "src/clients/flow-api";
import { AddFolder } from "src/flowsOverview/v2/AddFolder";
import { Folder } from "src/flowsOverview/v2/Folder";
import { FolderListSkeleton } from "src/flowsOverview/v2/FolderListSkeleton";
import { DeleteFolderModal } from "src/flowsOverview/v2/folderModals/DeleteFolderModal";
import { EditFolderModal } from "src/flowsOverview/v2/folderModals/EditFolderModal";
import {
  useCreateFolder,
  useDeleteFolder,
  useEditFolder,
} from "src/flowsOverview/v2/folderQueries";
import { useCapabilities } from "src/hooks/useCapabilities";
import { useFolderIdSearchParam } from "src/router/SearchParams";
import { getBaseUrl, getUrlToWsDashboard } from "src/router/urls";
import { copyTextToClipboard } from "src/utils/clipboard";
import { wrapWithAxiosResponseErrorHandler } from "src/utils/toastError";

type Props = {
  organizationId: string;
  workspaceId: string;
  folderResult: UseQueryResult<FlowFolderListResponse, Error>;
};

export const FolderList: React.FC<Props> = ({
  organizationId,
  workspaceId,
  folderResult,
}) => {
  const [folderId, setFolderId] = useFolderIdSearchParam();
  const { flowFolders } = useCapabilities();
  const createFolderMutations = useCreateFolder();
  const editFolderMutations = useEditFolder(workspaceId);
  const deleteFolderMutations = useDeleteFolder(workspaceId);

  const [createFolderModalOpen, setCreateFolderModalOpen] =
    useState<boolean>(false);

  const [folderToEdit, setFolderToEdit] = useState<FlowFolderListItem>();
  const [editFolderModalOpen, setEditFolderModalOpen] =
    useState<boolean>(false);

  const [folderToDelete, setFolderToDelete] = useState<FlowFolderListItem>();
  const [deleteFolderModalOpen, setDeleteFolderModalOpen] =
    useState<boolean>(false);

  const onRequestDeleteFolder = (folder: FlowFolderListItem) => {
    setFolderToDelete(folder);
    setDeleteFolderModalOpen(true);
  };

  const onCopyLink = (folder: FlowFolderListItem | undefined) => {
    copyTextToClipboard(
      getBaseUrl() +
        getUrlToWsDashboard({
          orgId: organizationId,
          wsId: workspaceId,
          page: "flows",
          folderId: folder?.id,
        }),
    ).then(() => {
      toastSuccess({
        title: "Link copied to clipboard",
      });
    });
  };

  const onRequestSelect = (folder: FlowFolderListItem | undefined) => {
    setFolderId(folder?.id);
  };

  return (
    <div>
      <ul className="col-span-1 flex flex-col gap-y-3">
        <LoadingView
          queryResult={folderResult}
          renderUpdated={(data: FlowFolderListResponse) => (
            <>
              {data.folders.map((folder) => (
                <Folder
                  key={folder.id}
                  folder={folder}
                  selected={folder.id === folderId}
                  onCopyLink={onCopyLink}
                  onRequestDelete={onRequestDeleteFolder}
                  onRequestEdit={() => {
                    setFolderToEdit(folder);
                    setEditFolderModalOpen(true);
                  }}
                  onRequestSelect={onRequestSelect}
                />
              ))}
              <Folder
                count={data.flows_outside_folders_count}
                folder={undefined}
                selected={folderId === undefined}
                onCopyLink={onCopyLink}
                onRequestDelete={onRequestDeleteFolder}
                onRequestSelect={onRequestSelect}
              />
              {flowFolders.canCreate && (
                <AddFolder
                  onRequestAddFolder={() => {
                    setCreateFolderModalOpen(true);
                  }}
                />
              )}
            </>
          )}
          renderUpdating={() => <FolderListSkeleton />}
        />
      </ul>
      <EditFolderModal
        mode="create"
        open={createFolderModalOpen}
        onClose={() => setCreateFolderModalOpen(false)}
        onConfirm={async (name: string) => {
          await createFolderMutations.mutateAsync({
            name,
            workspace_id: workspaceId,
          });
        }}
      />
      <EditFolderModal
        afterLeave={() => {
          setFolderToEdit(undefined);
        }}
        folder={folderToEdit}
        mode="edit"
        open={editFolderModalOpen}
        onClose={() => setEditFolderModalOpen(false)}
        onConfirm={async (name: string) => {
          if (folderToEdit) {
            await editFolderMutations.mutateAsync({
              folderId: folderToEdit.id,
              flowFolderUpdate: { name },
            });
          }
        }}
      />
      <DeleteFolderModal
        afterLeave={() => {
          setFolderToDelete(undefined);
        }}
        folder={folderToDelete}
        isOpen={deleteFolderModalOpen}
        onClose={() => {
          setDeleteFolderModalOpen(false);
        }}
        onConfirm={wrapWithAxiosResponseErrorHandler(async () => {
          deleteFolderMutations.mutateAsync(folderToDelete?.id!);
          setFolderId(undefined);
          setDeleteFolderModalOpen(false);
        })}
      />
    </div>
  );
};
