import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { AnimatePresence, m } from "framer-motion";
import React, { useRef } from "react";
import { useHover } from "usehooks-ts";

import { DatasetJob } from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { SourceIcon } from "src/datasets/DatasetList/SourceIcon";
import { DatasetStatusPill } from "src/datasets/DatasetStatusPill";
import { separateFilenameExtension } from "src/datasets/utils";

type Props = {
  name: string;
  status: DatasetJob["status"];
  errorMsg?: string;
  onDelete: () => void;
};

export const PendingItem: React.FC<Props> = ({
  name,
  status,
  errorMsg,
  onDelete,
}) => {
  const hoverRef = useRef<HTMLLIElement | null>(null);
  const isHover = useHover<HTMLLIElement>(hoverRef);
  const isFailed = status === "FAILED";

  return (
    <m.li
      ref={hoverRef}
      className="flex items-center justify-between gap-x-2 rounded-lg border border-gray-100 bg-white px-4 py-2 shadow-sm"
      data-loc="dataset-pending-item"
    >
      <div className="flex flex-1 items-center gap-x-1 border border-transparent">
        <SourceIcon source="file" hideTooltip />
        <p className="line-clamp-1 text-gray-800 font-inter-semibold-13px">
          {separateFilenameExtension(name).name}
        </p>
      </div>
      <AnimatePresence>
        <DatasetStatusPill errorMsg={errorMsg} status={status} />
        {isHover && isFailed && (
          <m.div
            key="delete-icon"
            animate={{
              opacity: 1,
              transition: {
                type: "tween",
                ease: "easeOut",
                delay: 0.15,
              },
            }}
            exit={{ opacity: 0, transition: { duration: 0.08 } }}
            initial={{ opacity: 0 }}
            transition={{ type: "tween", ease: "easeOut" }}
            layout
          >
            <Icon
              color="text-gray-500 hover:text-gray-700"
              icon={faTrashCan}
              size="xs"
              onClick={() => onDelete()}
            />
          </m.div>
        )}
      </AnimatePresence>
    </m.li>
  );
};
