import { twJoin } from "tailwind-merge";

type SkeletonProps = {
  variant?: "text" | "circle" | "rectangular" | "rounded" | "rounded-full";
  width?: `w-${number}` | `w-${number}/${number}` | "w-full";
  height?: `h-${number}` | `h-${number}/${number}` | "h-full";
};

/**
 * @deprecated
 * Please do not user this component for skeleton states.
 * Use SkeletonPlaceholder instead.
 */
export const Skeleton: React.FC<SkeletonProps> = ({
  variant = "text",
  width = "w-full",
  height,
}) => (
  <div
    className={twJoin(
      "animate-pulse bg-gray-100",
      // TODO: Implement other variants
      variant === "text" && "before:content-zws",
      variant === "text" && "inline-block rounded",
      variant === "rounded" && "rounded",
      variant === "rounded-full" && "rounded-full",
      width,
      height,
    )}
  />
);
