import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useMemo, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";

import { useUpdateParameters } from "src/api/flowVersionQueries";
import { useAnalyzeFlow } from "src/api/queries";
import { useAcquireVersionResourceLock } from "src/authoringMultiplayerLock/useVersionResourceLock";
import { Button } from "src/base-components/Button";
import { Parameter, ResourceType } from "src/clients/flow-api";
import { DeleteParameterModal } from "src/globalParameters/DeleteParameterModal";
import { EditParameterModal } from "src/globalParameters/EditParameterModal";
import { GlobalParametersCard } from "src/globalParameters/GlobalParametersCard";
import { getParametersUsageFromAnalysisData } from "src/globalParameters/parametersUsage";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { AuthorPageParamsT } from "src/router/urls";
import { useNodeHighlighting } from "src/store/NodeHighlighting";
import { useGraphStore } from "src/store/StoreProvider";
import * as logger from "src/utils/logger";
import { useParamsDecode } from "src/utils/useParamsDecode";

export type ParameterForm = Omit<Parameter, "value">;
export type ParametersFormT = {
  params: ParameterForm[];
};

type AnalyzeProps = { baseUrl: string; flowSlug: string };

type PropsT = {
  params: Parameter[] | undefined;
  versionId: string;
  isReadonly: boolean;
  // if provided the analyze endpoint is used
  analyzeUsage: AnalyzeProps | undefined;
};

export const ParametersList: React.FC<PropsT> = ({
  params,
  versionId,
  analyzeUsage,
  isReadonly,
}) => {
  const { version_id } = useParamsDecode<AuthorPageParamsT>();
  const [selectedParam, setSelectedParam] = useState<number | undefined>(
    undefined,
  );
  const [modalIsOpen, setModalIsOpen] = useState<
    "edit" | "editValue" | "delete" | "add" | undefined
  >(undefined);
  const [isDraggingParameter, setIsDraggingParameter] =
    useState<boolean>(false);
  const { lockedByOtherUser } = useAcquireVersionResourceLock(
    ResourceType.PARAMETER,
    version_id,
    Boolean(modalIsOpen || isDraggingParameter),
  );

  const formMethods = useForm<ParametersFormT>({
    defaultValues: { params: params },
    values: params ? { params: params } : undefined,
  });
  const { clearHighlighting } = useNodeHighlighting();
  const { graphHasChanged } = useGraphStore();
  const [paramsUsageData, setParamsUsageData] =
    useState<Record<string, string[]>>();

  const { mutateAsync } = useAnalyzeFlow();

  const formValues = useWatch({ control: formMethods.control, name: "params" });

  const isImmutable = isReadonly || lockedByOtherUser;

  const { version } = useAuthoringContext();

  useEffect(() => {
    if (analyzeUsage) {
      const setParamsUsage = async () => {
        try {
          const analysisData = await mutateAsync({
            baseUrl: analyzeUsage.baseUrl,
            flowSlug: analyzeUsage.flowSlug,
            versionId: versionId,
          });
          setParamsUsageData(
            analysisData.data.type === "success"
              ? getParametersUsageFromAnalysisData(
                  analysisData.data.params,
                  formValues.map((param) => param.name),
                )
              : undefined,
          );
        } catch (e) {
          logger.error(e);
        }
      };
      setParamsUsage();
    }
  }, [
    formValues,
    analyzeUsage,
    analyzeUsage?.baseUrl,
    analyzeUsage?.flowSlug,
    versionId,
    mutateAsync,
    version.graph?.nodes,
  ]);

  const { fields, append, remove, move, update } = useFieldArray({
    control: formMethods.control,
    name: "params",
  });

  const { mutateAsync: mutateParameters } = useUpdateParameters(
    versionId,
    graphHasChanged,
  );

  const submit = formMethods.handleSubmit(async (data) => {
    try {
      await mutateParameters(data.params);
    } catch (error) {
      formMethods.reset();
      throw error;
    }
  });

  // Also clear the node highlighting on the canvas when the component unmounts
  useEffect(() => {
    return clearHighlighting;
  }, [clearHighlighting]);

  const handleDragEnd = (event: DragEndEvent) => {
    setIsDraggingParameter(false);
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const fieldToMove = fields.findIndex((field) => field.id === active.id);
      const fieldToInsert = fields.findIndex((field) => field.id === over.id);
      if (fieldToMove !== -1 && fieldToInsert !== -1) {
        move(fieldToMove, fieldToInsert);
        submit();
      }
    }
  };

  // This has to be memoized because otherwise the reference to the {} object changes on every render
  const selectedParamObject = useMemo(() => {
    return selectedParam !== undefined ? fields[selectedParam] : {};
  }, [selectedParam, fields]);

  return (
    <FormProvider {...formMethods}>
      {fields.length !== 0 && (
        <DndContext
          onDragEnd={handleDragEnd}
          onDragMove={() => setIsDraggingParameter(true)}
          onDragStart={() => setIsDraggingParameter(true)}
        >
          <SortableContext
            disabled={isImmutable}
            items={fields.map((param) => param.id)}
          >
            <div className="flex w-full flex-col items-center rounded border">
              {fields.map((param, index) => (
                <GlobalParametersCard
                  key={param.id}
                  id={param.id}
                  index={index}
                  parameter={param}
                  readonly={isImmutable}
                  usageData={
                    paramsUsageData ? paramsUsageData[param.name] : undefined
                  }
                  onDelete={() => {
                    setSelectedParam(index);
                    setModalIsOpen("delete");
                  }}
                  onEdit={() => {
                    setSelectedParam(index);
                    setModalIsOpen("edit");
                  }}
                  onEditValue={() => {
                    setSelectedParam(index);
                    setModalIsOpen("editValue");
                  }}
                />
              ))}
            </div>
          </SortableContext>
        </DndContext>
      )}
      <div className="mt-4">
        <Button
          dataLoc="add-parameter-button"
          disabled={isImmutable}
          iconLeft={faPlus}
          size="base"
          variant="secondary"
          onClick={() => {
            setSelectedParam(undefined);
            setModalIsOpen("add");
          }}
        >
          Add Parameter
        </Button>
      </div>
      <DeleteParameterModal
        deleteParameter={async () => {
          remove(selectedParam);
          await submit();
        }}
        isOpen={modalIsOpen === "delete"}
        onClose={() => setModalIsOpen(undefined)}
      />
      <EditParameterModal
        currentParameterNames={fields.map((param) => param.name)}
        defaultValues={selectedParamObject}
        editParameter={async (newParameter) => {
          if (selectedParam !== undefined) {
            update(selectedParam, newParameter);
            await submit();
          } else {
            append(newParameter);
            await submit();
          }
        }}
        focusValueInput={modalIsOpen === "editValue"}
        isAdding={modalIsOpen === "add"}
        isOpen={
          modalIsOpen === "edit" ||
          modalIsOpen === "add" ||
          modalIsOpen === "editValue"
        }
        onClose={() => setModalIsOpen(undefined)}
      />
    </FormProvider>
  );
};
