import { faCircle, faLink } from "@fortawesome/pro-solid-svg-icons";
import { Menu } from "@headlessui/react";
import { AnimatePresence, m } from "framer-motion";
import React, { useMemo, useState } from "react";
import { usePopper } from "react-popper";

import { ExternalLink } from "src/base-components/ExternalLink";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { FlowDbShallow, FlowVersionStatus } from "src/clients/flow-api";
import { groupRelatedFlows } from "src/flow/modals/utils";
import {
  DashboardPageParamsT,
  getBaseUrl,
  getFlowVersionsUrl,
  getUrlToAuthoringPage,
} from "src/router/urls";
import { pluralize } from "src/utils/stringUtils";
import { assertUnreachable } from "src/utils/typeUtils";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const PublishedPillWithIcon: React.FC = () => (
  <Pill dataLoc="version-live-pill" size="base" variant="green">
    <Pill.Icon icon={faCircle} size="xs" />
    <Pill.Text>Published</Pill.Text>
  </Pill>
);

export const PublishedPill: React.FC = () => (
  <Pill size="base" variant="green">
    <Pill.Text>Published</Pill.Text>
  </Pill>
);

export const DraftPill: React.FC = () => (
  <Pill size="base" variant="yellow">
    <Pill.Text>Draft</Pill.Text>
  </Pill>
);

export const ArchivedPill: React.FC = () => (
  <Pill size="base" variant="gray">
    <Pill.Text>Archived</Pill.Text>
  </Pill>
);

export const VersionStatusPill: React.FC<{ status: FlowVersionStatus }> = ({
  status,
}) => {
  switch (status) {
    case FlowVersionStatus.PUBLISHED:
      return <PublishedPillWithIcon />;
    case FlowVersionStatus.ARCHIVED:
      return <ArchivedPill />;
    case FlowVersionStatus.DRAFT:
      return <DraftPill />;
    default:
      assertUnreachable(status);
  }
};

export const SandboxDefaultPill: React.FC = () => (
  <Pill>
    <Pill.Text>Sandbox Default</Pill.Text>
  </Pill>
);

export const ProductionDefaultPill: React.FC = () => (
  <Pill>
    <Pill.Text>Production Default</Pill.Text>
  </Pill>
);

export const RelatedFlowsPill: React.FC<{
  flows: FlowDbShallow[];
  isParent?: boolean;
  includeVersions?: boolean;
  countOnly?: boolean;
}> = ({
  flows,
  includeVersions = false,
  isParent = false,
  countOnly = false,
}) => {
  const { wsId, orgId } = useParamsDecode<DashboardPageParamsT>();
  const [isOpen, setOpen] = useState<boolean>(false);
  const linkClassName =
    "font-inter-normal-13px text-gray-800 flex items-center gap-x-2";
  const itemClassName = "px-4 py-2.5 text-left hover:bg-gray-50 cursor-pointer";

  const [flowsOrder, groupedFlows] = useMemo(
    () => groupRelatedFlows(flows),
    [flows],
  );

  const [ref, setRef] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(ref, popperElement, {
    placement: "bottom-start",
  });

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <Menu
      as="div"
      className="relative"
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Menu.Button ref={setRef} as="div">
        <Pill hovered={isOpen} variant="outlined-white">
          <Pill.Icon icon={faLink} size="md" />
          <Pill.Text>
            {countOnly
              ? flowsOrder.length
              : `${isParent ? "Calling" : "Called by"}
                ${pluralize(
                  flowsOrder.length,
                  isParent ? "Child Flow" : "Parent Flow",
                )}`}
          </Pill.Text>
        </Pill>
      </Menu.Button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <Menu.Items
            as={m.ul}
            {...attributes.popper}
            ref={setPopperElement}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                type: "tween",
                ease: "easeOut",
                duration: 0.15,
              },
            }}
            className="z-50 mt-6 w-62 cursor-default rounded-lg bg-white py-2 opacity-100 shadow-lg transition-opacity"
            exit={{ opacity: 0, scale: 0.95, transition: { duration: 0.08 } }}
            initial={{ opacity: 0, scale: 0.95 }}
            style={styles.popper}
            static
          >
            <Menu.Item
              as="li"
              className="px-4 py-2.5 text-left"
              onClick={stopPropagation}
            >
              <span className="text-gray-800 font-inter-semibold-13px">
                {isParent ? "Child Flows" : "Parent Flows"}
              </span>
            </Menu.Item>
            {flowsOrder.map((flowId) => [
              <Menu.Item
                key={flowId}
                as="li"
                className={itemClassName}
                onClick={stopPropagation}
              >
                <ExternalLink
                  className={linkClassName}
                  href={getBaseUrl() + getFlowVersionsUrl(orgId, wsId, flowId)}
                >
                  {groupedFlows[flowId]?.name}
                </ExternalLink>
              </Menu.Item>,
              includeVersions &&
                groupedFlows[flowId]?.versions?.map((version) => (
                  <Menu.Item
                    key={version.id}
                    as="li"
                    className={itemClassName}
                    onClick={stopPropagation}
                  >
                    <ExternalLink
                      className={linkClassName}
                      href={
                        getBaseUrl() +
                        getUrlToAuthoringPage(orgId, wsId, flowId, version.id)
                      }
                    >
                      <Icon
                        color={
                          version.status === FlowVersionStatus.PUBLISHED
                            ? "text-green-500"
                            : "text-yellow-500"
                        }
                        icon={faCircle}
                        size="xs"
                      />
                      <span className="font-inter-normal-13px">
                        {version.name}
                      </span>
                    </ExternalLink>
                  </Menu.Item>
                )),
            ])}
          </Menu.Items>
        )}
      </AnimatePresence>
    </Menu>
  );
};
