import axios, { AxiosResponse } from "axios";

import { GenericObjectT } from "src/api/flowTypes";
import { pathJoin } from "src/api/paths";

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
};

const getFlowRouterUrl = (baseUrl: string, url: string) =>
  pathJoin("https://", baseUrl, "/run/api/v1/", url);

export const flowRouterApiGet = <ResponseT>(
  baseUrl: string,
  url: string,
  data: GenericObjectT,
  signal?: AbortSignal,
): Promise<AxiosResponse<ResponseT>> => {
  return axios.get<ResponseT, AxiosResponse<ResponseT>>(
    getFlowRouterUrl(baseUrl, url),
    {
      params: data,
      headers: DEFAULT_HEADERS,
      signal,
    },
  );
};

export const flowRouterApiPost = <ResponseT>(
  baseUrl: string,
  url: string,
  data: GenericObjectT,
): Promise<AxiosResponse<ResponseT>> => {
  return axios.post<ResponseT, AxiosResponse<ResponseT>>(
    getFlowRouterUrl(baseUrl, url),
    data,
    { headers: DEFAULT_HEADERS },
  );
};

export const flowRouterApiPut = <ResponseT>(
  baseUrl: string,
  path: string,
  data: GenericObjectT,
): Promise<AxiosResponse<ResponseT>> => {
  return axios.put<ResponseT, AxiosResponse<ResponseT>>(
    getFlowRouterUrl(baseUrl, path),
    data,
    { headers: DEFAULT_HEADERS },
  );
};
