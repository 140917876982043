import { faCheck, faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { twJoin } from "tailwind-merge";

import { FlowVersionFlowChild } from "src/api/flowTypes";
import { Icon } from "src/base-components/Icon";
import {
  DuplicateVersionOptionsT,
  FromScratchElement,
} from "src/flow/modals/VersionPicker";
import { VersionStatusDotIndicator } from "src/utils/flowVersion";

type PropsT = {
  duplicateVersion: DuplicateVersionOptionsT;
  selected: boolean;
  button?: boolean;
  borderBottom?: boolean;
};

export const VersionPickerDisplay: React.FC<PropsT> = ({
  duplicateVersion,
  selected,
  borderBottom = false,
  button = false,
}) => {
  const renderButtonIcon = () => (
    <>{button && <Icon icon={faChevronDown} size="xs" />}</>
  );

  const renderFromScratchPreview = () => (
    <>
      <div
        className={twJoin(
          "text-gray-800 font-inter-normal-13px",
          selected && "font-inter-semibold-13px",
        )}
      >
        From scratch
      </div>{" "}
      {renderButtonIcon()}
    </>
  );

  const renderVersionPreview = (versionToDuplicate: FlowVersionFlowChild) => (
    <>
      <div className="flex flex-row items-center gap-x-1">
        <VersionStatusDotIndicator version={versionToDuplicate} />
        <div
          className={twJoin(
            "text-gray-800 font-inter-normal-13px",
            selected && "font-inter-semibold-13px",
          )}
        >
          {versionToDuplicate.name}
        </div>
      </div>
      {selected && (
        <div className="mx-2 ml-2">
          <Icon color="text-indigo-600" icon={faCheck} size="sm" />
        </div>
      )}
      {renderButtonIcon()}
    </>
  );

  return (
    <div
      className={twJoin(
        "flex min-h-[2.25rem] w-full flex-row items-center justify-between px-2.5 py-1.5",
        borderBottom && "border-b",
      )}
      data-loc={duplicateVersion}
    >
      {duplicateVersion === FromScratchElement.value
        ? renderFromScratchPreview()
        : renderVersionPreview(duplicateVersion)}
    </div>
  );
};
