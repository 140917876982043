import { Cell } from "@tanstack/react-table";
import React, { isValidElement } from "react";

import { GenericObjectT } from "src/api/flowTypes";
import { DataRow, NodeExecutionMetadata } from "src/api/types";

export type SuccessResultDataAndAuxRow = {
  index: number;
  type: "success" | "success_mismatch" | "success_match";
  data: DataRow;
  auxData: DataRow;
  expectedOutputData?: DataRow;
  expectedOutputKeysMismatch?: string[];
  nodeExecutionMetadata?: NodeExecutionMetadata;
};

export type ResultDataAndAuxRowV2 =
  | SuccessResultDataAndAuxRow
  | {
      index: number;
      type: "ignored";
      data: DataRow;
      auxData: DataRow;
      nodeExecutionMetadata?: NodeExecutionMetadata;
    }
  | {
      index: number;
      type: "failure";
      errorMessage: string;
      errorOriginNodeId: string | undefined;
      data: DataRow;
      auxData: DataRow;
      nodeExecutionMetadata?: NodeExecutionMetadata;
      provenance?: Record<string, GenericObjectT>;
    };

export type ResultsDataStatus = ResultDataAndAuxRowV2["type"];

export type CellContent<T = GenericObjectT> = Cell<T, any>;

export const isReactNode = (c: unknown): c is React.ReactElement =>
  isValidElement(c);
