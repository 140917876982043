import React from "react";

import { BeMappedNode, SplitNodeV2 } from "src/constants/NodeDataTypes";
import { useGraphStore } from "src/store/StoreProvider";

export type SplitNodeDeletionModalContentPropsT = {
  splitNode: SplitNodeV2;
};

export const SplitNodeDeletionModalContent: React.FC<
  SplitNodeDeletionModalContentPropsT
> = ({ splitNode }) => {
  const { getBranchesAndNodes } = useGraphStore();
  const renderDeleteMessage = () => (
    <div className="text-gray-500 font-inter-normal-12px">
      Are you sure you want to delete this node? This cannot be undone. The
      following nodes will also be deleted:
    </div>
  );

  const renderBranchNodes = (branchNodes: BeMappedNode[]) =>
    branchNodes.map((node: BeMappedNode) => (
      <div key={node.id} className="text-gray-500 font-inter-normal-12px">
        • {node.data?.label}
      </div>
    ));

  const renderBranches = (branchesToDelete: BeMappedNode[][]) => (
    <>
      {branchesToDelete.map((branchNodes: BeMappedNode[]) => (
        <div key={branchNodes[0].id} className="my-2">
          <span className="text-gray-800 font-inter-medium-12px">
            {branchNodes[0].data.label}
          </span>
          <>{renderBranchNodes(branchNodes.slice(1))}</>
        </div>
      ))}
    </>
  );

  return (
    <div className="flex flex-col">
      {renderDeleteMessage()}
      <div className="decideScrollbar mt-2 max-h-52 overflow-y-auto">
        {renderBranches(getBranchesAndNodes(splitNode, true))}
      </div>
    </div>
  );
};
