import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { Menu } from "@headlessui/react";
import React, { ReactNode, useRef, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { twJoin } from "tailwind-merge";
import { useOnClickOutside } from "usehooks-ts";

import { LOCK_NODE_EDITOR_CLASSNAME } from "src/authoringMultiplayerLock/constants";
import { Icon } from "src/base-components/Icon";
import { TransitionWrapper } from "src/base-components/TransitionWrapper";

type MenuItemProps = {
  children: ReactNode;
  onClick: () => void;
  icon: IconProp;
};

const MenuItem: React.FC<MenuItemProps> = ({ children, icon, onClick }) => {
  return (
    <Menu.Item>
      {({ close }) => (
        <button
          className="flex gap-2 px-4 py-2 pr-9 font-inter-normal-13px hover:bg-gray-100 ui-active:bg-gray-100"
          onClick={() => {
            onClick();
            close();
          }}
        >
          <Icon color="text-gray-500" icon={icon} size="2xs" />
          <div className="shrink-0">{children}</div>
        </button>
      )}
    </Menu.Item>
  );
};

type SubComponents = {
  Item: typeof MenuItem;
};

type ContextMenuProps = {
  readonly?: boolean;
  children: ReactNode;
  button: "vertical" | "horizontal";
  buttonProps?: Record<any, any>;
  show: boolean;
};

export const ContextMenu: React.FC<ContextMenuProps> & SubComponents = ({
  children,
  button,
  buttonProps,
  show,
}) => {
  const [isOpen, setOpen] = useState(false);
  const pointerPosition = useRef({ x: 0, y: 0 });
  const referenceElement = useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] =
    useState<Nullable<HTMLDivElement>>(null);
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement.current,
    popperElement,
    {
      strategy: "fixed",
      placement: button === "vertical" ? "bottom-start" : "bottom",
      modifiers: [
        { name: "offset", options: { offset: [0, 4] } },
        { name: "hide" },
      ],
    },
  );

  useOnClickOutside(referenceElement, () => {
    setOpen(false);
  });

  const handlePointerDown: React.PointerEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        buttonProps?.onPointerDown?.(e);
        setOpen(false);
        pointerPosition.current = {
          x: Math.trunc(e.pageX),
          y: Math.trunc(e.pageY),
        };
      },
      [buttonProps, setOpen],
    );

  const handlePointerUp: React.PointerEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        const [clickX, clickY] = [Math.trunc(e.pageX), Math.trunc(e.pageY)];
        if (
          clickX !== pointerPosition.current.x ||
          clickY !== pointerPosition.current.y
        ) {
          return;
        }

        setOpen(true);
      },
      [pointerPosition, setOpen],
    );

  return (
    <Menu ref={referenceElement} as="div">
      <Menu.Button
        {...buttonProps}
        className={twJoin(
          "relative z-50 m-auto flex rounded border border-gray-300 bg-white opacity-0 shadow-sm transition-opacity duration-300 hover:opacity-100",
          button === "horizontal" && "px-1 py-0.5",
          button === "vertical" && "px-0.5 py-1",
          (show || isOpen) && "opacity-100",
        )}
        disabled={isOpen}
        tabIndex={-1}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
      >
        <div className="flex items-center">
          <Icon
            color={twJoin(
              "p-0 text-2xs text-gray-500",
              button === "horizontal" && "rotate-90",
            )}
            icon={faGripDotsVertical}
            size="2xs"
          />
        </div>
      </Menu.Button>
      {ReactDOM.createPortal(
        <TransitionWrapper show={isOpen}>
          <Menu.Items
            ref={setPopperElement}
            className={twJoin(
              "z-50 flex w-max flex-col rounded-lg border bg-white py-2 shadow-lg",
              LOCK_NODE_EDITOR_CLASSNAME,
            )}
            style={popperStyles.popper}
            static
            {...popperAttributes.popper}
          >
            {children}
          </Menu.Items>
        </TransitionWrapper>,
        // TODO: Works for now, but should be replaced with a more robust solution
        document.body as HTMLElement,
      )}
    </Menu>
  );
};

ContextMenu.Item = MenuItem;
