import React, { useMemo } from "react";

import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { useDatasets } from "src/datasets/api/queries";
import { RunFlowButton } from "src/flowContainer/RunFlowButton/RunFlowButton";
import { useAuthoringContext } from "src/router/routerContextHooks";

type WrapperPropsT = {
  flow: FlowT;
  version: FlowVersionT;
};

export const RunFlowButtonWrapper: React.FC<WrapperPropsT> = ({
  flow,
  version,
}) => {
  const { workspace } = useAuthoringContext();
  const datasets = useDatasets({
    flowId: flow.id,
    baseUrl: workspace.base_url,
  });

  const testRunDatasets = useMemo(
    () =>
      datasets.data
        ? datasets.data.filter((d) => !d.purpose || d.purpose === "test_run")
        : [],
    [datasets.data],
  );

  return (
    <RunFlowButton
      key={version.id}
      datasets={testRunDatasets}
      flow={flow}
      isLoading={datasets.isLoading}
      version={version}
    />
  );
};
