import { observer } from "mobx-react-lite";
import React from "react";

import { Operation } from "src/api/flowVersionUpdateIndex";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { NodeT } from "src/constants/NodeDataTypes";
import { getCreatableNodes } from "src/nodeAdding/PasteNodesCard";
import { useGraphStore } from "src/store/StoreProvider";

export const DeleteNodesModal: React.FC = observer(() => {
  const {
    deleteMultipleConnectedNodes,
    selectedNodes,
    isDeleteNodesModalVisible,
    setIsDeleteNodesModalVisible,
  } = useGraphStore();
  const onConfirmDelete = async () => {
    if (selectedNodes) {
      try {
        await deleteMultipleConnectedNodes(
          selectedNodes,
          Operation.DELETE_NODES,
        );
        setIsDeleteNodesModalVisible(false);
      } catch (err) {
        setIsDeleteNodesModalVisible(false);
      }
    }
  };
  return (
    <ConfirmationModal
      confirmationButtonClassname="w-[6.475rem]"
      confirmationButtonText="Yes, delete"
      open={isDeleteNodesModalVisible}
      title="Delete selected Nodes"
      onClose={() => setIsDeleteNodesModalVisible(false)}
      onConfirm={onConfirmDelete}
    >
      <div className="mb-6 mt-4">
        <p className="text-gray-500 font-inter-normal-12px">
          Are you sure you want to delete the Nodes? The following Nodes will be
          deleted:
        </p>
        <div className="decideScrollbar max-h-52 overflow-y-auto">
          {getCreatableNodes(selectedNodes).map((node: NodeT) => (
            <div key={node.id} className="my-2">
              <span className="text-gray-800 font-inter-medium-12px">
                • {node.data.label}
              </span>
            </div>
          ))}
        </div>
      </div>
    </ConfirmationModal>
  );
});
