import React from "react";
import { useWatch } from "react-hook-form";

import { Pill } from "src/base-components/Pill";
import { FormShape } from "src/scorecardNode/types";
import { formatWeight, isValidWeight } from "src/scorecardNode/utils";

const Parenthesis = ({ children }: { children: React.ReactNode }) => (
  <>
    <span>(</span>
    {children}
    <span>)</span>
  </>
);

const FactorExpression = ({
  weight,
  name,
  equalWeights,
}: {
  weight: string;
  name: string;
  equalWeights: boolean;
}) => {
  const Wrapper = equalWeights ? React.Fragment : Parenthesis;
  const formattedWeight = formatWeight(weight);
  const multiplier = !equalWeights && (
    <>
      <Pill size="sm" variant="gray">
        <Pill.Text fontType="code">{formattedWeight}</Pill.Text>
      </Pill>
      <span>*</span>
    </>
  );

  return (
    <Wrapper>
      {multiplier}
      <Pill size="sm" variant="gray">
        <Pill.Text fontType="code">{name}</Pill.Text>
      </Pill>
    </Wrapper>
  );
};

export const FactorsBreakdown: React.FC = () => {
  const factors = useWatch<FormShape, "factors">({
    name: "factors",
  });
  const equalWeights = useWatch<FormShape, "equal_weights">({
    name: "equal_weights",
  });
  const isEmpty = factors.length === 0;

  const ExpressionsWrapper = equalWeights ? Parenthesis : React.Fragment;
  const factorExpressions = factors.map(
    ({ id, name, weight }, index) =>
      (equalWeights || isValidWeight(weight.value)) && (
        <React.Fragment key={id}>
          {index !== 0 && <span>+</span>}
          <FactorExpression
            equalWeights={equalWeights}
            name={name}
            weight={weight.value}
          />
        </React.Fragment>
      ),
  );

  return (
    <div className="flex flex-wrap gap-x-1 font-mono text-xs text-gray-400">
      <span>=</span>
      {isEmpty ? (
        "0"
      ) : (
        <ExpressionsWrapper>{factorExpressions}</ExpressionsWrapper>
      )}
      {!isEmpty && equalWeights && (
        <>
          <span>/</span>
          <span>{factors.length}</span>
        </>
      )}
    </div>
  );
};
