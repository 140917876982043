export const DEFAULT_METADATA_MAPPING = [
  {
    db_field: null,
    flow_input_field: "entity_id",
  },
  {
    db_field: null,
    flow_input_field: "version",
  },
];

export const DELETED_VERSION_NAME = "Deleted version";
