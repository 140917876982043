import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { capitalize } from "lodash";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Dataset,
  DesiredType,
  DesiredTypes,
  ExtendedDatasetColumnGroups,
} from "src/api/types";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Input } from "src/base-components/Input";
import { Modal } from "src/base-components/Modal";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { Select } from "src/base-components/Select";

type Props = {
  dataset?: Dataset;
  open: boolean;
  onClose: () => void;
  onAddColumn: (
    name: string,
    group: ExtendedDatasetColumnGroups,
    desiredType?: DesiredType,
  ) => Promise<void>;
};

type AdditionalFieldInputs = {
  name: string;
  desiredType: DesiredType;
};

const validateFieldName = (name: string) => {
  if (/^\d/.test(name)) {
    return "Field name can't start with numbers";
  }

  if (/\s/.test(name)) {
    return "Field name can't contain spaces";
  }
};

export const AddAdditionalFieldModal: React.FC<Props> = ({
  open,
  onClose,
  onAddColumn,
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<AdditionalFieldInputs>({
    mode: "onChange",
    defaultValues: { desiredType: "string" },
  });

  const onSubmit = (data: AdditionalFieldInputs) => {
    onAddColumn(data.name.trim(), "additional_columns", data.desiredType);
    onClose();
  };

  return (
    <Modal
      className="w-80 overflow-visible"
      headerClassName="pb-6 border-b border-gray-100"
      open={open}
      title="Add additional field"
      withOverlay={false}
      zIndex="z-30"
      closeIcon
      onClose={onClose}
    >
      <div className="flex flex-col">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="relative my-3 min-h-0 flex-1 px-5 py-2">
            <div className="mb-2 text-gray-800 font-inter-semibold-13px">
              Field name <RequiredAsterisk />
            </div>
            <Input
              autoComplete="off"
              errored={!!errors.name}
              placeholder="field_name"
              fullWidth
              {...register("name", {
                required: true,
                validate: validateFieldName,
              })}
            />
            <ErrorHint>{errors.name?.message}</ErrorHint>
            <div className="mb-2 mt-6 text-gray-800 font-inter-semibold-13px">
              Field type <RequiredAsterisk />
            </div>
            <Controller
              control={control}
              name="desiredType"
              render={({ field }) => (
                <Select
                  multiple={false}
                  options={DesiredTypes.map((type) => ({
                    key: type,
                    value: capitalize(type),
                  }))}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="mt-auto flex items-center justify-end gap-x-3 border-t border-gray-100 px-5 py-4">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button htmlType="submit" iconLeft={faPlus} loading={isSubmitting}>
              Add field
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
