import { isString } from "lodash";

const translations = {
  true: "True",
  false: "False",
  null: "None",
} as const;

const isTranslationKey = (value: unknown): value is keyof typeof translations =>
  isString(value) && translations.hasOwnProperty(value);

export const speakPythonPrimitive = <T>(value: T): T => {
  if (isTranslationKey(value)) {
    return translations[value] as T;
  }

  return value;
};

const NULL_UUID = "C8C82A19-DFDF-43B2-BF03-552D3A2B0895" as const;
const TRUE_UUID = "772799A9-EEFF-40FE-86BF-149415DAD7CC" as const;
const FALSE_UUID = "375C256C-321F-4D6D-9CD5-5239FCF9FAAA" as const;

const applyPrimitiveUuids = (param: any) => {
  if (param === null) return NULL_UUID;
  if (param === true) return TRUE_UUID;
  if (param === false) return FALSE_UUID;
  return param;
};

const placePrimitiveUuidsInObject = (o: any) => {
  if (o && typeof o === "object") {
    Object.entries(o).forEach(([key, value]) => {
      placePrimitiveUuidsInObject(value);
      o[key] = applyPrimitiveUuids(value);
    });
  }
};

export const objectToSpeakPythonString = (o: object) => {
  placePrimitiveUuidsInObject(o);
  const stringifiedObject = JSON.stringify(o);
  return resolvePrimitiveUuids(stringifiedObject);
};

export const stringifiedObjectSpeakPython = (stringObject: string): string => {
  const parsedObject = JSON.parse(stringObject);
  return objectToSpeakPythonString(parsedObject);
};

export const jsonStringSpeakPython = (input: string): string => {
  const parsedInput = JSON.parse(input);
  if (parsedInput && typeof parsedInput === "object") {
    return objectToSpeakPythonString(parsedInput);
  } else return speakPythonPrimitive(input);
};

const resolvePrimitiveUuids = (stringifiedObject: string): string => {
  return stringifiedObject
    .replaceAll(`"${NULL_UUID}"`, "None")
    .replaceAll(`"${TRUE_UUID}"`, "True")
    .replaceAll(`"${FALSE_UUID}"`, "False");
};
