import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { ColumnDef, Cell } from "@tanstack/react-table";
import React, { useMemo, useState } from "react";

import { startsWithInternalPrefix } from "src/api/constants";
import { ApiKeyActions } from "src/apiKeys/ApiKeyActions";
import { CreateApiKeyModal } from "src/apiKeys/CreateApiKeyModal";
import { DeleteApiKeyModal } from "src/apiKeys/DeleteApiKeyModal";
import { EditApiKeyModal } from "src/apiKeys/EditApiKeyModal";
import { KeyType } from "src/apiKeys/KeyType";
import { ShowApiKeyModal } from "src/apiKeys/ShowApiKeyModal";
import { useWorkspaceApiKeys } from "src/apiKeys/api/queries";
import EmptyCube from "src/assets/empty-state-cube.svg?react";
import { LoadingView } from "src/base-components/LoadingView";
import { Pill } from "src/base-components/Pill";
import { TableComp as Table } from "src/base-components/Table";
import { Tooltip } from "src/base-components/Tooltip";
import {
  ApiKeyType,
  WorkspaceServiceAccount,
  WorkspaceServiceAccountWithKey,
} from "src/clients/taktile-api";
import { HeaderCell } from "src/dataTable/HeaderCell";
import { RawCell } from "src/dataTable/RawCell";
import { SubHeader } from "src/flow/SubHeader";
import { useCapabilities } from "src/hooks/useCapabilities";
import { DashboardContent } from "src/layout/DashboardContent";
import { dateFromNow, formatDate } from "src/utils/datetime";

const renderDateInfo = <T extends WorkspaceServiceAccount>(
  cell: Cell<T, string>,
) => {
  const isoDate = cell.getValue();
  const fromNow = isoDate ? dateFromNow(isoDate) : "Never";
  const detailDate = isoDate
    ? formatDate(isoDate, "dd MMM yy HH:mm:ss")
    : "Never used";

  return (
    <Tooltip align="center" placement="top" title={detailDate}>
      {/* @ts-ignore */}
      <RawCell<ApiKeyT> cell={{ ...cell, getValue: () => fromNow }} />
    </Tooltip>
  );
};

const renderNoApiKeys = () => (
  <div className="flex h-[19.875rem] w-[57.25rem] flex-row items-center justify-center">
    <div className="flex flex-row">
      <EmptyCube className="mr-5" />
      <div className="text-gray-800">
        <div className="pt-1.5 font-inter-semibold-14px">
          No API keys created
        </div>
        <div className="font-inter-normal-13px">
          Please create an API key to integrate <br /> Taktile Decide with your
          system.
        </div>
      </div>
    </div>
  </div>
);

const Header: React.FC<{ header?: string }> = ({ header }) => (
  <HeaderCell className="py-2.5 pt-4.5">{header}</HeaderCell>
);

type PropsT = {
  organizationId: string;
  workspaceId: string;
};

type CallbackWithApiKey = (apiKey: WorkspaceServiceAccount) => void;

const buildColumns = (
  onEdit: CallbackWithApiKey,
  onDelete: CallbackWithApiKey,
): ColumnDef<WorkspaceServiceAccount>[] => {
  const columns: ColumnDef<WorkspaceServiceAccount>[] = [
    {
      id: "keyName",
      header: () => <Header header="Key name" />,
      accessorFn: (row) => row.name,
      cell: ({ cell }) => {
        const isInternalKey = startsWithInternalPrefix(cell.row.original.name);
        const wrapper = (
          <span className="inline-flex items-center gap-2">
            {isInternalKey && (
              <Pill variant="red">
                <Pill.Text>Internal</Pill.Text>
              </Pill>
            )}
            {cell.renderValue<string>()}
          </span>
        );
        return (
          <RawCell<WorkspaceServiceAccount>
            cell={wrapper}
            classNameOverrides="max-w-0"
          />
        );
      },
    },
    {
      id: "keyTypes",
      header: () => <Header header="Key permissions" />,
      accessorFn: (row) => row.key_types,
      cell: ({ cell }) => <KeyType value={cell.getValue() as ApiKeyType[]} />,
    },
    {
      id: "createdBy",
      header: () => <Header header="Created by" />,
      accessorFn: (row) => row.sponsored_by_username ?? "",
      cell: ({ cell }) => <RawCell<WorkspaceServiceAccount> cell={cell} />,
    },
    {
      id: "createdAt",
      header: () => <Header header="Created at" />,
      accessorFn: (row) => row.created_at,
      cell: ({ cell }) =>
        renderDateInfo(cell as Cell<WorkspaceServiceAccount, string>),
    },
    {
      id: "lastUsed",
      header: () => <Header header="Last used" />,
      accessorFn: (row) => row.last_used_at ?? "",
      cell: ({ cell }) =>
        renderDateInfo(cell as Cell<WorkspaceServiceAccount, string>),
    },
    {
      id: "actions",
      header: () => <Header />,
      cell: (cell) => {
        const apiKey = cell.row.original;
        return (
          <ApiKeyActions
            onDeleteClick={() => onDelete(apiKey)}
            onEditClick={() => onEdit(apiKey)}
          />
        );
      },
    },
  ];

  return columns;
};

export const ApiKeyOverview: React.FC<PropsT> = ({
  organizationId,
  workspaceId,
}) => {
  const { apiKeys } = useCapabilities();
  const workspaceApiKeysResult = useWorkspaceApiKeys(
    organizationId,
    workspaceId,
  );

  const [keyToEdit, setKeyToEdit] = useState<WorkspaceServiceAccount>();
  const [keyToDelete, setKeyToDelete] = useState<WorkspaceServiceAccount>();
  const [apiKeyModalOpen, setApiKeyModalOpen] = useState(false);
  const [createdApiKey, setCreatedApiKey] =
    useState<WorkspaceServiceAccountWithKey>();
  const columns = useMemo(
    () => buildColumns(setKeyToEdit, setKeyToDelete),
    [setKeyToEdit, setKeyToDelete],
  );

  const renderUpdated = (data: WorkspaceServiceAccount[]) => (
    <div className="flex flex-row justify-center">
      {data.length > 0 ? (
        <Table
          columns={columns}
          data={data}
          frameClassName="px-4 shadow-sm rounded-lg bg-white w-full pb-2"
          rowClassName="hover:bg-gray-50 last:border-b-0"
        />
      ) : (
        renderNoApiKeys()
      )}
    </div>
  );

  return (
    <DashboardContent
      Header={
        <SubHeader title="API keys">
          {apiKeys.canAccess && (
            <SubHeader.Button
              icon={faPlus}
              tooltip="Create API key"
              onClick={() => setApiKeyModalOpen(true)}
            />
          )}
        </SubHeader>
      }
    >
      <LoadingView
        queryResult={workspaceApiKeysResult}
        renderUpdated={renderUpdated}
      />
      <EditApiKeyModal
        keyToEdit={keyToEdit}
        organizationId={organizationId}
        workspaceId={workspaceId}
        onClose={() => setKeyToEdit(undefined)}
      />
      <DeleteApiKeyModal
        keyToDelete={keyToDelete}
        organizationId={organizationId}
        workspaceId={workspaceId}
        onClose={() => setKeyToDelete(undefined)}
      />
      <CreateApiKeyModal
        open={apiKeyModalOpen}
        organizationId={organizationId}
        workspaceId={workspaceId}
        onApiKey={(apiKey: WorkspaceServiceAccountWithKey) =>
          setCreatedApiKey(apiKey)
        }
        onClose={() => setApiKeyModalOpen(false)}
      />
      <ShowApiKeyModal
        createdApiKey={createdApiKey}
        onUnmount={() => setCreatedApiKey(undefined)}
      />
    </DashboardContent>
  );
};
