import * as cronjsMatcher from "@datasert/cronjs-matcher";
import { CronExpr, parse as parseCronExpr } from "@datasert/cronjs-parser";

import { FlowVersionT } from "src/api/flowTypes";
import { toastFailure } from "src/base-components/Toast/utils";
import { ConnectionDataSourcesValues } from "src/baseConnectionNode/types";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { DatasetMockableNodes } from "src/constants/NodeDataTypes";
import { Job } from "src/jobs/types";
import { queryClient } from "src/queryClient";
import { GraphTransform } from "src/utils/GraphUtils";
import * as logger from "src/utils/logger";
import {
  isCustomConnectionNode,
  isDatabaseConnectionNode,
  isDatasetMockableNode,
  isInboundWebhookConnectionNode,
  isPreconditionError,
} from "src/utils/predicates";
import { getLocalTimezone } from "src/utils/timezones";
import { assertUnreachable } from "src/utils/typeUtils";

const WEEKDAYS_TO_INDEX = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0,
} as const;

export const WEEKDAYS = Object.keys(WEEKDAYS_TO_INDEX) as Weekday[];

type Weekday = keyof typeof WEEKDAYS_TO_INDEX;

type HourSchedule = {
  every: "hour";
  minute: number;
  timezone: string;
};

type DaySchedule = {
  every: "day";
  minute: number;
  hour: number;
  timezone: string;
};

type WeekSchedule = {
  every: "week";
  minute: number;
  hour: number;
  weekDays: Record<Weekday, boolean>;
  timezone: string;
};

type MonthSchedule = {
  every: "month";
  minute: number;
  hour: number;
  monthDays: (number | "L")[];
  timezone: string;
};

export type ScheduleForm =
  | HourSchedule
  | DaySchedule
  | WeekSchedule
  | MonthSchedule;

export const formatSchedule = (schedule: ScheduleForm): string => {
  switch (schedule.every) {
    case "hour":
      return `${schedule.minute} * * * ?`;
    case "day":
      return `${schedule.minute} ${schedule.hour} * * ?`;
    case "week":
      const weekDays = Object.entries(schedule.weekDays)
        .filter(([_weekday, isEnabled]) => isEnabled)
        .map(
          ([weekday]) =>
            WEEKDAYS_TO_INDEX[weekday as keyof typeof WEEKDAYS_TO_INDEX],
        )
        .join(",");
      return `${schedule.minute} ${schedule.hour} ? * ${weekDays}`;
    case "month":
      return `${schedule.minute} ${schedule.hour} ${schedule.monthDays.join(
        ",",
      )} * ?`;
    default:
      assertUnreachable(schedule);
      return "";
  }
};

const getEvery = (expr: CronExpr): ScheduleForm["every"] => {
  if (
    expr.month.all &&
    expr.day_of_week.omit &&
    (expr.day_of_month.values?.length || expr.day_of_month.lastDay) &&
    expr.hour.values?.length &&
    expr.minute.values?.length
  )
    return "month";

  if (
    expr.month.all &&
    expr.day_of_month.omit &&
    expr.day_of_week.values?.length &&
    expr.hour.values?.length &&
    expr.minute.values?.length
  )
    return "week";

  if (
    expr.month.all &&
    (expr.day_of_month.all || expr.day_of_week.all) &&
    expr.hour.values?.length &&
    expr.minute.values?.length
  )
    return "day";

  if (
    expr.month.all &&
    (expr.day_of_month.all || expr.day_of_week.all) &&
    expr.hour.all &&
    expr.minute.values?.length
  )
    return "hour";

  throw new Error("Unsupported schedule");
};

export const parseSchedule = (
  schedule: string,
  timezone: string = getLocalTimezone(),
): ScheduleForm => {
  const {
    expressions: [expr],
  } = parseCronExpr(schedule);

  const every = getEvery(expr);

  const minute = expr.minute.values?.at(0) ?? 0;

  if (every === "hour") {
    return { every, minute, timezone };
  }

  const hour = expr.hour.values?.at(0) ?? 0;

  if (every === "day") {
    return { every, minute, hour, timezone };
  }

  const weekDays = Object.entries(WEEKDAYS_TO_INDEX).reduce(
    (acc, [weekday, index]) => {
      acc[weekday as Weekday] =
        expr.day_of_week.values?.includes(index) ?? false;
      return acc;
    },
    {} as Record<Weekday, boolean>,
  );

  if (every === "week") {
    return {
      every,
      minute,
      hour,
      weekDays,
      timezone,
    };
  }

  const monthDays: MonthSchedule["monthDays"] = expr.day_of_month.values ?? [];
  if (expr.day_of_month.lastDay) {
    monthDays.push("L");
  }

  // every month
  return {
    every,
    hour,
    minute,
    monthDays,
    timezone,
  };
};

export const getFutureMatches = (schedule: string, timezone: string) => {
  return cronjsMatcher.getFutureMatches(schedule, {
    matchCount: 1,
    timezone,
  })[0];
};

export const isJobRunnable = (job?: Job) =>
  !!(
    job?.active_source &&
    (job?.flow_version_id || job?.active_traffic_policy)
  );

export const handlePreconditionError = async (
  workspace: WorkspaceDataplane,
  job: Job,
  error: any,
) => {
  if (isPreconditionError(error)) {
    await queryClient.invalidateQueries(["job", workspace.base_url, job.id]);
    toastFailure({
      title: "Failed to update this Job",
      description:
        "Somebody has changed the Job, check the updated configuration and try again",
    });
    return true;
  }
  return false;
};

export const getMockableNode = (
  version: FlowVersionT,
): DatasetMockableNodes[] => {
  if (!version.graph) {
    logger.warn("Flow version graph is missing");
    return [];
  }

  return version.graph.nodes
    .map(GraphTransform.nodeMapper)
    .filter(isDatasetMockableNode);
};

export const getLiveIntegrationNodes = (version: FlowVersionT): string[] => {
  if (!version.graph) return [];
  return version.graph.nodes
    .map(GraphTransform.nodeMapper)
    .filter((node) =>
      isCustomConnectionNode(node) ||
      isDatabaseConnectionNode(node) ||
      isInboundWebhookConnectionNode(node)
        ? node.data.config.environments_config?.sandbox_mode_data_source ===
          ConnectionDataSourcesValues.production
        : false,
    )
    .map((node) => node.data.label);
};
