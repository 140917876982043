import { v4 as uuid } from "uuid";

import {
  ConnectionConfiguration,
  ConnectionConfigurationCreateT,
  ConnectionCreateT,
  ConnectionT,
  ResourceConfigT,
} from "src/api/connectApi/types";
import {
  filterSecretsByEnvironment,
  filterUpdatedSecrets,
  findSecret,
  getDefaultSecret,
  markSecrets,
  setSecretEnv,
} from "src/connections/model/common";
import {
  InboundWebhookConnectionConfigInputsT,
  InboundWebhookConnectionConfigT,
  InboundWebhookResourceConfigT,
  KeyValuePairT,
} from "src/connections/types";
import { assertUnreachable } from "src/utils/typeUtils";

export const isManualReviewNodeConnection = (connection: ConnectionT) =>
  connection.provider === "webhook" &&
  connection.resource_configs.length === 1 &&
  connection.resource_configs[0].resource === "review_event";

export const getInboundWebhookResourceConfigDefaultValues =
  (): InboundWebhookResourceConfigT => ({
    id: uuid(),
    resource: "event",
    name: "",
    correlationIDPath: "",
    activation: null,
  });

export const getInboundWebhookConnectionConfigDefaultValues = (
  env: string | null,
): InboundWebhookConnectionConfigT => ({
  webhookAuthMethod: "api_key",
  webhookNoAuthConfig: {},
  webhookBasicAuthConfig: {
    login: getDefaultSecret("login", env),
    password: getDefaultSecret("password", env),
  },
  webhookApiKeyConfig: {
    header: null,
    prefix: null,
    apiKey: getDefaultSecret("api_key", env),
  },
  webhookHMACConfig: {
    hashAlgorithm: "sha1",
    signatureEncoding: "hex",
    header: "",
    signingKey: getDefaultSecret("signing_key", env),
  },
  webhookURL: null,
  allowTestInvocations: false,
});

export const getInboundWebhookConnectionInputsDefaultValues =
  (): InboundWebhookConnectionConfigInputsT => ({
    name: "",
    dataRetention: {
      value: 0,
      unit: "days",
    },
    enableNonProdConfigs: false,
    productionConfig: getInboundWebhookConnectionConfigDefaultValues(null),
    sandboxConfig: getInboundWebhookConnectionConfigDefaultValues("sandbox"),
    customSuccessStatusCode: null,
    resourceConfigs: [getInboundWebhookResourceConfigDefaultValues()],
  });

export const convertBEResourceToInboundWebhookResourceInputs = (
  resource: ResourceConfigT,
) =>
  ({
    id: resource.id,
    name: resource.name,
    resource: resource.resource,
    correlationIDPath: resource.configuration.correlation_id_path,
    activation: resource.configuration.activation,
  }) as InboundWebhookResourceConfigT;

const convertBEConnectionConfigurationToInboundWebhookConnectionConfig = (
  config: ConnectionConfiguration,
  envSecrets: KeyValuePairT[],
  env: string | null,
): InboundWebhookConnectionConfigT => {
  const webhookAuthSettings = config.webhook_auth_settings!;
  const convertedConfig = {
    ...getInboundWebhookConnectionConfigDefaultValues(env),
    webhookURL: config.webhook_url ?? null,
    webhookAuthMethod: webhookAuthSettings.auth_method,
    allowTestInvocations: config.allow_test_invocations ?? false,
  };

  switch (webhookAuthSettings.auth_method) {
    case "no_auth":
      return convertedConfig;
    case "basic":
      return {
        ...convertedConfig,
        webhookBasicAuthConfig: {
          login:
            findSecret(envSecrets, "login") ?? getDefaultSecret("login", env),
          password:
            findSecret(envSecrets, "password") ??
            getDefaultSecret("password", env),
        },
      };
    case "api_key":
      return {
        ...convertedConfig,
        webhookApiKeyConfig: {
          header: webhookAuthSettings.header,
          prefix: webhookAuthSettings.prefix,
          apiKey:
            findSecret(envSecrets, "api_key") ??
            getDefaultSecret("api_key", env),
        },
      };
    case "hmac":
      return {
        ...convertedConfig,
        webhookHMACConfig: {
          hashAlgorithm: webhookAuthSettings.hash_algorithm,
          signatureEncoding: webhookAuthSettings.signature_encoding,
          header: webhookAuthSettings.header,
          signingKey:
            findSecret(envSecrets, "signing_key") ??
            getDefaultSecret("signing_key", env),
        },
      };
  }
};

/**
 * Converts BE inbound webhook connection configuration to FE format.
 *
 * @param {ConnectionT} connection - The inbound webhook connection object in BE format.
 * @returns {InboundWebhookConnectionConfigInputsT} - The converted inbound webhook connection object in FE format suitable to use with components.
 * @throws {Error} - Throws an error if the provider of the connection is not "webhook".
 */
export const convertBEConnectionToInboundWebhookConnectionConfigInputs = (
  connection: ConnectionT,
): InboundWebhookConnectionConfigInputsT => {
  if (connection.provider !== "webhook")
    throw new Error(
      `Invalid connection type: ${connection.provider} for Inbound Webhook converter`,
    );

  const defaultValues = getInboundWebhookConnectionInputsDefaultValues();
  const nonProdEnvConfigs = connection.non_prod_env_configs;

  const allSecrets = markSecrets(connection.secrets);
  const prodSecrets = filterSecretsByEnvironment(allSecrets, null);
  const sandboxSecrets = filterSecretsByEnvironment(allSecrets, "sandbox");

  const isSandboxEnabled =
    connection.enable_non_prod_configs &&
    nonProdEnvConfigs &&
    nonProdEnvConfigs["sandbox"];
  return {
    ...defaultValues,
    name: connection.name,
    customSuccessStatusCode:
      connection.configuration.custom_success_status_code ?? null,
    dataRetention: connection.data_retention ?? defaultValues.dataRetention,
    enableNonProdConfigs: connection.enable_non_prod_configs,
    productionConfig:
      convertBEConnectionConfigurationToInboundWebhookConnectionConfig(
        connection.configuration,
        prodSecrets,
        null,
      ),
    sandboxConfig: isSandboxEnabled
      ? convertBEConnectionConfigurationToInboundWebhookConnectionConfig(
          nonProdEnvConfigs["sandbox"],
          sandboxSecrets,
          "sandbox",
        )
      : getInboundWebhookConnectionConfigDefaultValues("sandbox"),

    resourceConfigs: connection.resource_configs.map(
      convertBEResourceToInboundWebhookResourceInputs,
    ),
  };
};

const convertInboundWebhookAuthSettingsToBEConfig = (
  config: InboundWebhookConnectionConfigT,
) => {
  const { webhookAuthMethod, webhookApiKeyConfig, webhookHMACConfig } = config;

  const base = {
    auth_method: webhookAuthMethod,
  };

  // Stripping the secrets from the BE config format
  switch (webhookAuthMethod) {
    case "basic":
      return base;
    case "no_auth":
      return base;
    case "api_key":
      return {
        ...base,
        header: webhookApiKeyConfig.header,
        prefix: webhookApiKeyConfig.prefix,
      };
    case "hmac":
      return {
        ...base,
        hash_algorithm: webhookHMACConfig.hashAlgorithm,
        signature_encoding: webhookHMACConfig.signatureEncoding,
        header: webhookHMACConfig.header,
      };
    default:
      return assertUnreachable(webhookAuthMethod);
  }
};

const extractSecretsFromInboundWebhookConnectionConfig = (
  config: InboundWebhookConnectionConfigT,
  env: string | null,
): KeyValuePairT[] => {
  const {
    webhookAuthMethod,
    webhookBasicAuthConfig,
    webhookApiKeyConfig,
    webhookHMACConfig,
  } = config;

  switch (webhookAuthMethod) {
    case "basic":
      return [
        setSecretEnv(webhookBasicAuthConfig.login, env),
        setSecretEnv(webhookBasicAuthConfig.password, env),
      ];
    case "api_key":
      return [setSecretEnv(webhookApiKeyConfig.apiKey, env)];
    case "hmac":
      return [setSecretEnv(webhookHMACConfig.signingKey, env)];
    default:
      return [];
  }
};

const extractSecretsFromInboundWebhookConnectionConfigInputs = (
  config: InboundWebhookConnectionConfigInputsT,
): KeyValuePairT[] => {
  const secrets = extractSecretsFromInboundWebhookConnectionConfig(
    config.productionConfig,
    null,
  );
  // Only include non-prod secrets if non-prod configs are enabled
  if (config.enableNonProdConfigs && config.sandboxConfig) {
    secrets.push(
      ...extractSecretsFromInboundWebhookConnectionConfig(
        config.sandboxConfig,
        "sandbox",
      ),
    );
  }
  // Removing un-changed secrets, so that they are not sent to the backend
  return filterUpdatedSecrets(secrets);
};

const convertInboundWebhookInputConfigurationToCreateConnection = (
  configuration: InboundWebhookConnectionConfigT,
  custom_success_status_code: number | null,
): ConnectionConfigurationCreateT => ({
  webhook_auth_settings:
    convertInboundWebhookAuthSettingsToBEConfig(configuration),
  allow_test_invocations: configuration.allowTestInvocations,
  custom_success_status_code,
});

const convertInboundWebhookActivationInputsToActivation = (
  activation: InboundWebhookResourceConfigT["activation"],
) => {
  // We make sure that activation without clauses is converted to null
  if (activation == null) return null;
  if (activation.clauses && activation.clauses.length === 0) return null;
  return activation;
};

const inboundWebhookConnectionInputsToResources = (
  configConnectionForm: InboundWebhookConnectionConfigInputsT,
) =>
  configConnectionForm.resourceConfigs.map((resourceConfig) => {
    return {
      id: resourceConfig.id,
      name: resourceConfig.name,
      resource: resourceConfig.resource,
      configuration: {
        correlation_id_path: resourceConfig.correlationIDPath,
        activation: convertInboundWebhookActivationInputsToActivation(
          resourceConfig.activation,
        ),
      },
      has_raw_response_enabled: false,
      created_at: "",
      updated_at: "",
    };
  });

/**
 * Converts FE inbound webhook connection configuration to BE format.
 * @param {InboundWebhookConnectionConfigInputsT} inputs - The inbound webhook connection object in FE format.
 * @returns {ConnectionCreateT} - The inbound webhook connection object in BE format suitable for sending POST request.
 */
export const convertInboundWebhookConnectionConfigInputsToBEConnection = (
  inputs: InboundWebhookConnectionConfigInputsT,
): ConnectionCreateT => {
  const secrets =
    extractSecretsFromInboundWebhookConnectionConfigInputs(inputs);

  return {
    name: inputs.name,
    is_sandbox: false,
    provider: "webhook",
    secrets,
    data_retention: inputs.dataRetention,
    configuration: convertInboundWebhookInputConfigurationToCreateConnection(
      inputs.productionConfig,
      inputs.customSuccessStatusCode,
    ),
    enable_non_prod_configs: inputs.enableNonProdConfigs,
    non_prod_env_configs:
      inputs.enableNonProdConfigs && inputs.sandboxConfig
        ? {
            sandbox: convertInboundWebhookInputConfigurationToCreateConnection(
              inputs.sandboxConfig,
              inputs.customSuccessStatusCode,
            ),
          }
        : {},
    resource_configs: inboundWebhookConnectionInputsToResources(inputs),
  };
};
