import { faWavePulse } from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "src/base-components/Icon";
import { assertUnreachable } from "src/utils/typeUtils";

type Props = {
  type:
    | "no_version_selected"
    | "no_rows_matching"
    | "timeout"
    | "unknown_error";
};

export const EmptyState: React.FC<Props> = ({ type }) => {
  let title: string = "";
  let description: string = "";

  switch (type) {
    case "no_rows_matching":
      title = "No results found";
      description =
        "Try adjusting the search or filter to find what you are looking for";
      break;
    case "no_version_selected":
      title = "Select a version";
      description = "Select a published version to get started";
      break;
    case "timeout":
      title = "Too many decisions";
      description = "Try adjusting the filters to match fewer decisions";
      break;
    case "unknown_error":
      title = "Something went wrong";
      description = "We've been notified about it";
      break;
    default:
      assertUnreachable(type);
  }

  return (
    <div className="flex h-full w-full items-center justify-center rounded-md text-center">
      <div className="flex w-64 flex-col items-center pb-8 pt-8">
        <div className="mb-5 flex h-[56px] w-[56px] items-center justify-center rounded-full border-2 border-indigo-200 p-1.5">
          <Icon color="text-indigo-500" icon={faWavePulse} size="2xl" />
        </div>
        <div className="mb-2 text-gray-800 font-inter-semibold-13px">
          {title}
        </div>
        <div className="text-center text-gray-500 font-inter-normal-12px">
          {description}
        </div>
      </div>
    </div>
  );
};
