import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { Controller, useFormContext } from "react-hook-form";

import { Card } from "src/base-components/Card";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Switch } from "src/base-components/Switch";
import { ClauseBlock } from "src/clauseBlock/ClauseBlock";
import { LoopNodeForm } from "src/parentFlowNodes/loopNode/LoopNodeEditor";
import {
  defaultOutputListName,
  defaultOutputSchemaFieldName,
} from "src/parentFlowNodes/loopNode/constants";

export const TruncatedPill: React.FC<{ text: string }> = ({ text }) => (
  <div className="mb-1 inline-block max-w-full">
    <Pill size="sm" variant="green" fullWidth>
      <Pill.Text>{text}</Pill.Text>
    </Pill>
  </div>
);

export const AdvancedSettings: React.FC<{
  immutable: boolean;
  firstOutputFieldName: string | undefined;
}> = ({ immutable, firstOutputFieldName }) => {
  const formMethods = useFormContext<LoopNodeForm>();
  const breakConditionIsEnabled =
    formMethods.getValues().break_condition.enabled;

  const outputLocation =
    formMethods.watch("output_destination_list_expression.value") ||
    defaultOutputListName;
  const firstOutputField = firstOutputFieldName ?? defaultOutputSchemaFieldName;

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="overflow-hidden">
          <p className="text-gray-800 font-inter-semibold-13px">
            Set a break condition
          </p>
          <div className="mt-0.5 text-gray-500 font-inter-normal-12px">
            Conditions defined here are checked at the end of each loop
            iteration after the latest outputs have been appended to{" "}
            <TruncatedPill text={`data.${outputLocation}`} />
          </div>
        </div>

        <Switch
          dataLoc="toggle-break-condition"
          disabled={immutable}
          enabled={breakConditionIsEnabled}
          onChange={(checked) => {
            formMethods.setValue("break_condition.enabled", checked);
          }}
        />
      </div>

      {breakConditionIsEnabled && (
        <>
          <Card className="mt-3">
            <Controller
              control={formMethods.control}
              name="break_condition"
              render={({ field }) => (
                <ClauseBlock
                  clauses={field.value.clauses}
                  fieldPlaceholder={`data.${defaultOutputListName}[index].`}
                  immutable={immutable}
                  junction={field.value.junction}
                  onChange={(value) => {
                    formMethods.setValue(
                      "break_condition.clauses",
                      value.clauses,
                    );
                    formMethods.setValue(
                      "break_condition.junction",
                      value.junction,
                    );
                  }}
                />
              )}
            />
            <div className="mt-3 border-t border-gray-200 pt-3">
              <p className="text-gray-600 font-inter-medium-12px">
                Then break loop
              </p>
            </div>
          </Card>
          <div className="mt-4 flex flex-row gap-x-1">
            <Icon color="text-gray-500" icon={faInfoCircle} />
            <div className="overflow-hidden text-gray-500 font-inter-normal-12px">
              If <TruncatedPill text={`data.${defaultOutputListName}`} /> is the
              output location of your loop and{" "}
              <TruncatedPill text={firstOutputField} /> is a field returned by
              the subflow,{" "}
              <TruncatedPill
                text={`data.${defaultOutputListName}[index].${firstOutputField}`}
              />{" "}
              refers to the latest iteration of{" "}
              <TruncatedPill text={firstOutputField} />.
            </div>
          </div>
        </>
      )}
    </>
  );
};
