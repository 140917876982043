import {
  Tab,
  TabGroup,
  TabList,
  TabPanels,
  TabPanel,
} from "@headlessui2/react";
import { twMerge } from "tailwind-merge";

export type TabT = {
  key: string;
  label: React.ReactNode;
  content: React.ReactNode;
  disabled?: boolean;
};

type PropsT = {
  tabs: TabT[];
  panelClassName?: string;
  panelsClassName?: string;
  tabClassName?: string;
  tabListClassName?: string;
  onChange?: (key: string) => void;
  defaultActiveKey?: string;
  containerClassName?: string;
  unmountPanels?: boolean;
};

export const Tabs: React.FC<PropsT> = ({
  tabs,
  panelClassName,
  panelsClassName,
  containerClassName,
  tabClassName = "px-3",
  tabListClassName,
  defaultActiveKey,
  unmountPanels = true,
  onChange,
}) => {
  const handleTabChange = (key: string) => {
    if (onChange) {
      onChange(key);
    }
  };

  const defaultActiveIndex = tabs.findIndex(
    (tab) => tab.key === defaultActiveKey,
  );

  return (
    <TabGroup
      as="div"
      className={containerClassName}
      defaultIndex={defaultActiveIndex !== -1 ? defaultActiveIndex : undefined}
    >
      <TabList className={tabListClassName}>
        {tabs.map((tab) => (
          <Tab
            key={tab.key}
            className={({ selected }) =>
              twMerge(
                "relative py-2 text-gray-800 font-inter-semibold-13px focus:outline-none",
                selected &&
                  "after:absolute after:bottom-0 after:left-0 after:right-0 after:block after:h-1 after:translate-y-1/2 after:rounded-full after:bg-indigo-500",
                tab.disabled && "opacity-60",
                tabClassName,
              )
            }
            data-loc={tab.key}
            disabled={tab.disabled}
            onClick={() => handleTabChange(tab.key)}
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>
      <TabPanels className={panelsClassName}>
        {({ selectedIndex }) => (
          <>
            {tabs.map((tab, i) => (
              <TabPanel
                key={tab.key}
                as="div"
                className={panelClassName}
                data-loc={`${tab.key}-panel`}
                hidden={selectedIndex !== i}
                unmount={unmountPanels}
              >
                {tab.content}
              </TabPanel>
            ))}
          </>
        )}
      </TabPanels>
    </TabGroup>
  );
};
