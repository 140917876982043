import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  InputMappingGroupT,
  InputMappingT,
  IntegrationResourceT,
  OutputMappingT,
} from "src/integrationNode/types";

const getDefaultAddressFields = (): { [key: string]: InputMappingT } => ({
  house_name: {
    id: uuidV4(),
    type: "text",
    displayName: "House name",
    assignedTo: "",
    hint: `The house name field should contain all "text" before the street name (for example: "Flat 9 Steeple View Aparts 433"). If the address contains just a "normal" house number, then that is what should appear in the "house name" field. Maximum length is 32 characters.`,
    rules: {
      required: true,
    },
  },
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
});

const getCurrentAddress = (): InputMappingGroupT => ({
  getDefaultElements: getDefaultAddressFields,
  elements: getDefaultAddressFields(),
  rules: {
    required: true,
  },
  displayName: "Current address",
});

const getPreviousAddress = (): InputMappingGroupT => ({
  getDefaultElements: getDefaultAddressFields,
  displayName: "Previous address",
});

const getBasicApplicantFields = (): { [key: string]: InputMappingT } => ({
  title: {
    id: uuidV4(),
    type: "text",
    displayName: "Title",
    assignedTo: "",
  },
  first_name: {
    id: uuidV4(),
    type: "text",
    displayName: "First name",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  middle_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Middle name",
    assignedTo: "",
  },
  last_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Last name",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  date_of_birth: {
    id: uuidV4(),
    type: "text",
    displayName: "Date of birth",
    assignedTo: "",
    hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20".`,
  },
  country_of_residence: {
    id: uuidV4(),
    type: "text",
    displayName: "Country of residence",
    assignedTo: "",
    hint: `The full name of the current country of residence. If not provided, "United Kingdom" will be used as the default value.`,
  },
});

const getDefaultPrimaryApplicant = (): InputMappingGroupT => ({
  getDefaultElements: getBasicApplicantFields,
  elements: getBasicApplicantFields(),
  rules: {
    required: true,
  },
  displayName: "Primary applicant",
});

const getBankAccountFields = (): { [key: string]: InputMappingT } => ({
  account_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Account number",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  bank_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Sort code",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `6-digit number which is used to identify a specific bank and local branch. Must adhere to the "dd-dd-dd" format where "d" is a digit, e.g. "12-34-56"`,
  },
});

const getDefaultBankAccount = (): InputMappingGroupT => ({
  getDefaultElements: getBankAccountFields,
  displayName: "Bank account",
});

const getEmploymentFields = (): { [key: string]: InputMappingT } => ({
  annual_salary: {
    id: uuidV4(),
    type: "text",
    displayName: "Annual salary",
    assignedTo: "",
    hint: "Amount in £(GBP), rounded to the nearest whole number.",
    rules: {
      required: true,
    },
  },
});

const getDefaultEmployment = (): InputMappingGroupT => ({
  getDefaultElements: getEmploymentFields,
  displayName: "Current employment",
});

const getEquifaxUKInsights = (): { [key: string]: OutputMappingT } => ({
  retrieval_datetime: getNamedOutputMapping("Retrieval date-time (UTC)"),
  address_match_status: {
    ...getNamedOutputMapping("Address match status"),
    hint: `Tells you if Equifax was able to find the requested address in its database. The possible values are: "singleMatch", "noMatch", and "multipleMatch". 
    "singleMatch" means that the supplied address matches a single address on the Equifax database. Once an address has been matched, the required data retrieval activities will be performed for the inquiry.
    "noMatch" occurs when Equifax is unable to match the supplied address to any address on its database. When no match occurs, no consumer data will be returned for the inquiry.
    "multipleMatch" occurs when the supplied address matches more than one address on the Equifax database. When a multiple match occurs, no consumer data will be returned for the inquiry.`,
  },
  previous_address_match_status: {
    ...getNamedOutputMapping("Previous address match status"),
    hint: `Tells you if Equifax was able to find the requested previous address in its database. The possible values are: "singleMatch", "noMatch", and "multipleMatch". 
    "singleMatch" means that the supplied address matches a single address on the Equifax database. Once an address has been matched, the required data retrieval activities will be performed for the inquiry.
    "noMatch" occurs when Equifax is unable to match the supplied address to any address on its database. When no match occurs, no consumer data will be returned for the inquiry.
    "multipleMatch" occurs when the supplied address matches more than one address on the Equifax database. When a multiple match occurs, no consumer data will be returned for the inquiry.`,
  },
});

export const getDefaultEquifaxUKGateway = (): IntegrationResourceT => ({
  providerResource: { provider: "equifax_uk", resource: "gateway" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      client_reference: {
        id: uuidV4(),
        type: "text",
        displayName: "Client reference",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: "Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.",
      },
    },
    grouped: {
      primary_applicant: getDefaultPrimaryApplicant(),
      current_address: getCurrentAddress(),
      previous_address: getPreviousAddress(),
      banking_info: getDefaultBankAccount(),
      current_employment: getDefaultEmployment(),
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getEquifaxUKInsights(),
    },
  },
  config: defaultResourceConfig,
});
