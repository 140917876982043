import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { useMemo, useState } from "react";
import { twJoin } from "tailwind-merge";

import { useWorkspaceUsers } from "src/api/taktile/queries";
import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { TextEditor } from "src/base-components/TextEditor/TextEditor";
import { Tooltip } from "src/base-components/Tooltip";
import { ReviewDb } from "src/clients/flow-api";
import { RightPanes, useRightPane } from "src/flowContainer/hooks/useRightPane";
import {
  UserAvatarWithName,
  UserSentenceList,
} from "src/flowsOverview/FlowReviewConfigPreview";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { formatDate, shortDateFromNow } from "src/utils/datetime";

type Props = {
  review: ReviewDb;
  hasNextRow: boolean;
  isReopened: boolean;
};

export const ShortDateFromNow: React.FC<{ date: string }> = ({ date }) => (
  <Tooltip placement="top" title={formatDate(date)} asChild>
    <span>{shortDateFromNow(date)}</span>
  </Tooltip>
);

const maxCollapsedDescriptionLength = 250;

export const ReviewLogEntry: React.FC<Props> = ({
  review,
  hasNextRow,
  isReopened,
}) => {
  const { orgId, workspace } = useAuthoringContext();
  const { data: users } = useWorkspaceUsers(orgId, workspace.id);
  const { setRightPane } = useRightPane();
  const [isCollapsed, setCollapsed] = useState(
    review.description.length > maxCollapsedDescriptionLength,
  );
  const hasCollapsibleContent =
    review.description.length > maxCollapsedDescriptionLength;
  const description = useMemo(
    () =>
      isCollapsed
        ? `${review.description.slice(0, maxCollapsedDescriptionLength)}...`
        : review.description,
    [review.description, isCollapsed],
  );

  const requestee = users?.find((u) => u.id === review.requestee_id);
  const reviewersIds = Object.keys(review.reviewers_status).sort();

  const reviewers = reviewersIds
    .map((id) => users?.find((u) => u.id === id))
    .filter((r): r is Exclude<typeof r, undefined> => Boolean(r));

  return (
    <div className="flex gap-x-4 px-5 pt-4">
      <div className="flex flex-shrink-0 flex-col items-center">
        <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-md bg-indigo-50">
          <Icon color="text-indigo-500" icon={faEye} size="2xs" />
        </div>
        {hasNextRow && (
          <div className="mt-1 flex-grow">
            <div className="h-[calc(100%+4px)] w-px border-r border-r-gray-100" />
          </div>
        )}
      </div>
      <div className="flex flex-grow flex-col gap-y-2">
        <div className="flex gap-x-1">
          <div className="flex flex-wrap gap-1 text-gray-600 font-inter-normal-12px">
            {requestee && <UserAvatarWithName user={requestee} />}
            <p> requested a review from </p>
            <UserSentenceList users={reviewers} />
          </div>
          <div className="ml-auto flex gap-x-1 text-gray-600 font-inter-normal-12px">
            {" "}
            <ShortDateFromNow
              date={
                isReopened
                  ? review.request_status_changed_at
                  : review.created_at
              }
            />
          </div>
        </div>
        <div className="flex w-full flex-grow flex-col gap-y-3">
          <div className="w-full rounded-lg bg-gray-50 px-3 py-2">
            <TextEditor
              placeholder="No guideline added"
              value={description}
              readOnly
            />
            {hasCollapsibleContent && (
              <span
                className="text-indigo-700 font-inter-normal-12px"
                role="button"
                onClick={() => setCollapsed((v) => !v)}
              >
                {isCollapsed ? "See more" : "See less"}
              </span>
            )}
          </div>
        </div>
        <div
          className={twJoin(
            "border-b-gray-100 pb-3",
            hasNextRow && "border-b",
            !isReopened && "mt-1",
          )}
        >
          {!isReopened && (
            <Button
              size="sm"
              variant="secondary"
              onClick={() => setRightPane(RightPanes.ChangeHistory)}
            >
              View change history
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
