import Fuse from "fuse.js";
import { useCallback, useMemo } from "react";

export const useFuseSearch = <V>(items: V[], options: Fuse.IFuseOptions<V>) => {
  const fuse = useMemo(() => {
    return new Fuse<V>(items, options);
  }, [items, options]);

  return useCallback(
    (query: string): V[] => {
      return query ? fuse.search(query).map(({ item }) => item) : items;
    },
    [fuse, items],
  );
};
