import { useVersionResourceLock } from "src/authoringMultiplayerLock/useVersionResourceLock";
import { ResourceType } from "src/clients/flow-api";
import {
  NavIconContainer,
  NavIconContainerProps,
} from "src/flowContainer/FlowSideBar/NavIconContainer";
import { CanvasLock } from "src/multiplayer/CanvasLock";
import { LeftPaneOptions } from "src/router/SearchParams";

type LockableNavIconContainerProps = {
  lockResourceType: ResourceType;
  currentOpenPane: Nullable<LeftPaneOptions>;
  flowVersionId: string;
} & NavIconContainerProps;

/** Allows to display canvas lock for flow sidebar buttons if editor is currently locked. */
export const LockableNavIconContainer: React.FC<
  LockableNavIconContainerProps
> = ({ lockResourceType, currentOpenPane, flowVersionId, ...restOfProps }) => {
  const { lockOwner, lockedByOtherUser } = useVersionResourceLock(
    lockResourceType,
    flowVersionId,
  );
  return (
    <div className="relative">
      <NavIconContainer {...restOfProps} />
      {lockOwner && lockedByOtherUser && !currentOpenPane && (
        <CanvasLock lockOwner={lockOwner} resource="sidebar" />
      )}
    </div>
  );
};
