import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  InputMappingGroupT,
  InputMappingT,
  IntegrationResourceT,
} from "src/integrationNode/types";

const getDefaultClearPersonSearchAddressFields = (): {
  [key: string]: InputMappingT;
} => ({
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street address",
    assignedTo: "",
    hint: `Only permitted characters are: a-z, A-Z, 0-9, and #-'./`,
    rules: {
      required: true,
    },
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    hint: `Only permitted characters are: a-z, A-Z, 0-9, and #-'./`,
    rules: {
      required: true,
    },
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    hint: `A two-letter code for the U.S. state or territory of the address (e.g., "CA" for California)`,
    rules: {
      required: true,
    },
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    hint: `The 5-digit postal or ZIP code of the address, formatted as a simple string of integers (e.g., '12345')`,
    rules: {
      required: true,
    },
  },
});

const getDefaultClearPersonSearchAddress = (): InputMappingGroupT => ({
  displayName: "Address",
  getDefaultElements: getDefaultClearPersonSearchAddressFields,
  elements: getDefaultClearPersonSearchAddressFields(),
  rules: {
    required: true,
  },
});

const getDefaultClearBusinessSearchAddressFields = (): {
  [key: string]: InputMappingT;
} => ({
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street address",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    hint: 'A two-letter code for the U.S. state or territory of the address (e.g., "CA" for California). Either state or province can be provided, not both.',
  },
  province: {
    id: uuidV4(),
    type: "text",
    displayName: "Province",
    assignedTo: "",
    hint: "Full province name or 2-character Canadian province abbreviation (case insensitive). Either state or province can be provided, not both.",
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    hint: `The 5-digit postal or ZIP code of the address, formatted as a simple string of integers (e.g., '12345')`,
    rules: {
      required: true,
    },
  },
  country: {
    id: uuidV4(),
    type: "text",
    displayName: "Country",
    assignedTo: "",
    hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g, "US" for United States)`,
  },
});

const getDefaultClearBusinessSearchAddress = (): InputMappingGroupT => ({
  displayName: "Address",
  getDefaultElements: getDefaultClearBusinessSearchAddressFields,
  elements: getDefaultClearBusinessSearchAddressFields(),
  rules: {
    required: true,
  },
});

const getDefaultClearBusinessSearchAgentNameFields = (): {
  [key: string]: InputMappingT;
} => ({
  agent_first_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Agent first name",
    assignedTo: "",
    hint: `Officer, agent, or director's first name`,
  },
  agent_middle_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Agent middle name",
    assignedTo: "",
    hint: `Officer, agent, or director's middle name`,
  },
  agent_last_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Agent last name",
    assignedTo: "",
    hint: `Officer, agent, or director's last name`,
  },
});

const getDefaultClearBusinessSearchAgentName = (): InputMappingGroupT => ({
  displayName: "Agent Name",
  getDefaultElements: getDefaultClearBusinessSearchAgentNameFields,
  elements: getDefaultClearBusinessSearchAgentNameFields(),
});

export const getDefaultThomsonReutersClearPersonSearch =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "thomson_reuters",
      resource: "thomson_reuters_clear_person_search",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        first_name: {
          id: uuidV4(),
          type: "text",
          displayName: "First name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        middle_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Middle name",
          assignedTo: "",
        },
        last_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Last name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        date_of_birth: {
          id: uuidV4(),
          type: "text",
          displayName: "Date of birth",
          assignedTo: "",
          hint: `Date of birth, formatted according to ISO: 'YYYY-MM-DD'`,
          rules: {
            required: true,
          },
        },
        social_security_number: {
          id: uuidV4(),
          type: "text",
          displayName: "Social security number",
          assignedTo: "",
          hint: `Either the individual's 9-digit SSN formatted as: 'XXX-XX-XXXX', or the last 4 digits as a simple string of integers: 'XXXX'`,
          rules: {
            required: true,
          },
        },
        drivers_license_number: {
          id: uuidV4(),
          type: "text",
          displayName: "Drivers license number",
          assignedTo: "",
        },
        phone_number: {
          id: uuidV4(),
          type: "text",
          displayName: "Phone number",
          assignedTo: "",
          hint: `Must match either: 10-digit format 'XXX-XXX-XXXX' or 7-digit format 'XXX-XXXX'. Alternatively, any international phone format (maximum 15 digits) is accepted when the definition's Match Logic is set as InternationalPhonePositional.`,
        },
        national_provider_id: {
          id: uuidV4(),
          type: "text",
          displayName: "National provider ID",
          assignedTo: "",
          hint: `The individual's 10-digit NPI formatted as a simple string of 10 digis (e.g., '1234567890')`,
        },
      },
      grouped: {
        address: getDefaultClearPersonSearchAddress(),
      },
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        retrieval_datetime: {
          ...getNamedOutputMapping("Retrieval datetime"),
        },
        entity_id: {
          ...getNamedOutputMapping("Entity ID"),
          hint: "Entity identifier of the person subject. This value can be used for requests to the Risk Inform Person Search resource.",
        },
        total_score: {
          ...getNamedOutputMapping("Total score"),
          hint: "Total match score displayed as a numeric value from 0-100",
        },
        group_id: {
          ...getNamedOutputMapping("Group ID"),
          hint: "The group ID can be used for requests to the Person Report resource",
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultThomsonReutersClearBusinessSearch =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "thomson_reuters",
      resource: "thomson_reuters_clear_business_search",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        business_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Business name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        fein_number: {
          id: uuidV4(),
          type: "text",
          displayName: "FEIN number",
          assignedTo: "",
          hint: `The 9 or 11-digit federal employer identification number issued to the business`,
        },
        corporation_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Corporation ID",
          assignedTo: "",
        },
        duns_number: {
          id: uuidV4(),
          type: "text",
          displayName: "DUNS number",
          assignedTo: "",
          hint: `The 9-digit data universal numbering system number issued to the business`,
        },
        phone_number: {
          id: uuidV4(),
          type: "text",
          displayName: "Phone number",
          assignedTo: "",
          hint: `Must match either: 10-digit format 'XXX-XXX-XXXX' or 7-digit format 'XXX-XXXX'. Alternatively, any international phone format (maximum 15 digits) is accepted when the definition's Match Logic is set as InternationalPhonePositional.`,
        },
        national_provider_id: {
          id: uuidV4(),
          type: "text",
          displayName: "National provider ID",
          assignedTo: "",
          hint: `The individual's 10-digit NPI formatted as a simple string of 10 digis (e.g., '1234567890')`,
        },
      },
      grouped: {
        address: getDefaultClearBusinessSearchAddress(),
        agent_name: getDefaultClearBusinessSearchAgentName(),
      },
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        retrieval_datetime: {
          ...getNamedOutputMapping("Retrieval datetime"),
        },
        entity_id: {
          ...getNamedOutputMapping("Entity ID"),
          hint: "Entity identifier of the business subject. This value can be used for requests to the Risk Inform Business Search resource.",
        },
        total_score: {
          ...getNamedOutputMapping("Total score"),
          hint: "Total match score displayed as a numeric value from 0-100",
        },
        group_id: {
          ...getNamedOutputMapping("Group ID"),
          hint: "The group ID can be used for requests to the Business Report resource",
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultThomsonReutersRiskPersonSearch =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "thomson_reuters",
      resource: "thomson_reuters_risk_person_search",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        entity_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Entity ID",
          assignedTo: "",
          hint: `Entity identifier of the person subject. This value can be obtained from the Clear ID Confirm Person Search resource.`,
          rules: {
            required: true,
          },
        },
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        retrieval_datetime: {
          ...getNamedOutputMapping("Retrieval datetime"),
        },
        entity_id: {
          ...getNamedOutputMapping("Entity ID"),
          hint: "Entity identifier of the person subject",
        },
        total_score: {
          ...getNamedOutputMapping("Total score"),
          hint: "Total risk score. Value will depend on the definition configuration.",
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultThomsonReutersRiskBusinessSearch =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "thomson_reuters",
      resource: "thomson_reuters_risk_business_search",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        entity_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Entity ID",
          assignedTo: "",
          hint: `Entity identifier of the business subject. This value can be obtained from the Clear ID Confirm Business Search resource.`,
          rules: {
            required: true,
          },
        },
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        retrieval_datetime: {
          ...getNamedOutputMapping("Retrieval datetime"),
        },
        entity_id: {
          ...getNamedOutputMapping("Entity ID"),
          hint: "Entity identifier of the business subject",
        },
        total_score: {
          ...getNamedOutputMapping("Total score"),
          hint: "Total risk score. Value will depend on the definition configuration.",
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultThomsonReutersPersonReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "thomson_reuters",
      resource: "thomson_reuters_person_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        group_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Group ID",
          assignedTo: "",
          hint: `Group ID to identify the subject of the report. This value can be obtained from the Clear Person Search resource.`,
          rules: {
            required: true,
          },
        },
        news_record_id: {
          id: uuidV4(),
          type: "text",
          displayName: "News record ID",
          assignedTo: "",
          hint: `Record identifier of news article, obtained from the same result as the group ID`,
        },
        web_analytics_search_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Web analytics search ID",
          assignedTo: "",
          hint: `Record ID of web and social media article, obtained from the same result as the group ID`,
        },
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        retrieval_datetime: {
          ...getNamedOutputMapping("Retrieval datetime"),
        },
        report_id: {
          ...getNamedOutputMapping("Report ID"),
          hint: "The report identifier that can used to retrieve the document",
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultThomsonReutersBusinessReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "thomson_reuters",
      resource: "thomson_reuters_business_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        group_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Group ID",
          assignedTo: "",
          hint: `Group ID to identify the subject of the report. This value can be obtained from the Risk Inform Business Search resource.`,
          rules: {
            required: true,
          },
        },
        news_record_id: {
          id: uuidV4(),
          type: "text",
          displayName: "News record ID",
          assignedTo: "",
          hint: `Record ID of news article, obtained from the same result as the group ID`,
        },
        web_analytics_search_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Web analytics search ID",
          assignedTo: "",
          hint: `Record ID of web and social media article, obtained from the same result as the group ID`,
        },
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        retrieval_datetime: {
          ...getNamedOutputMapping("Retrieval datetime"),
        },
        report_id: {
          ...getNamedOutputMapping("Report ID"),
          hint: "The report identifier that can used to retrieve the document",
        },
      },
    },
    config: defaultResourceConfig,
  });
