import {
  ConnectionResourceTemplateConfigKey,
  makeConnectionTemplate,
} from "src/connections/types";

const MODEL_CODE_CONFIG_KEY: ConnectionResourceTemplateConfigKey = {
  key: "model_code",
  name: "Model code",
  hint: `Only use this when a custom or legacy risk model subcode is configured`,
  required: false,
  type: "text",
};

export const ExperianUSB2BTemplate = makeConnectionTemplate({
  provider: "experian_us_b2b",
  secrets: [
    { key: "username", name: "Username", required: true },
    { key: "password", name: "Password", required: true },
    { key: "client_id", name: "Client ID", required: true },
    { key: "client_secret", name: "Client Secret", required: true },
    { key: "subcode", name: "Subcode", required: true },
  ],
  resources: {
    experian_us_b2b_business_search: {
      name: "Business Search",
      configKeys: [
        {
          key: "include_geographic_data",
          name: "Include geographic data",
          hint: `Include geographic data in the response`,
          required: false,
          type: "switch",
        },
      ],
    },
    experian_us_b2b_small_business_credit_share_scores: {
      name: "Small Business Credit Share Scores",
      configKeys: [MODEL_CODE_CONFIG_KEY],
    },
    experian_us_b2b_small_business_credit_share_profiles_report: {
      name: "Small Business Credit Share Profiles Report",
      configKeys: [
        MODEL_CODE_CONFIG_KEY,
        {
          key: "additional_details",
          name: "Additional details",
          hint: `If enabled, the full SBCS Profile is returned.
             Otherwise, a score-only report is returned.`,
          required: false,
          type: "switch",
        },
      ],
    },
  },
});
