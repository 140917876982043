import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { isEmpty } from "lodash";
import { useState } from "react";

import { Icon } from "src/base-components/Icon";
import { Card } from "src/performance/Card";
import { CardDropdown } from "src/performance/CardDropdown";
import { ErrorOverlay } from "src/performance/ErrorOverlay";
import {
  Legend,
  DIVIDER as LEGEND_DIVIDER,
  type LegendItem,
} from "src/performance/Legend";
import { NoDataOverlay } from "src/performance/NoDataOverlay";
import { NoErrorsOverlay } from "src/performance/NoErrorsOverlay";
import { Plot } from "src/performance/Plot/Plot";
import { barY, layer, lineY, tooltip } from "src/performance/Plot/marks";
import { useErrors } from "src/performance/queries";
import { PerformanceFilters } from "src/performance/types";
import { useFlowContext } from "src/router/routerContextHooks";
import { DECISION_ERROR_STATUS_CODES } from "src/utils/constants";
import { formatNumber } from "src/utils/numbers";

const COLORS = {
  500: "#B91C1C",
  424: "#FBBF24",
  422: "#EF4444",
  409: "#FECACA",
  408: "#F97316",
  403: "#FDE68A",
};

const UNEXPECTED_ERROR_COLOR = "#E5E6EB";

const getErrorColor = (code: string) =>
  code in COLORS
    ? COLORS[code as unknown as keyof typeof COLORS]
    : UNEXPECTED_ERROR_COLOR;

const LEGEND_ITEMS: LegendItem[] = [
  {
    color: getErrorColor("500"),
    name: "Error code 500",
    type: "circle",
  },
  LEGEND_DIVIDER,
  ...DECISION_ERROR_STATUS_CODES.slice(1).map<LegendItem>((errorCode) => ({
    color: getErrorColor(errorCode),
    name: `Error code ${errorCode}`,
    type: "circle",
  })),
  LEGEND_DIVIDER,
  { color: "#4B5163", name: "Error rate", type: "dash" },
];

const NO_DATA_STATE = { by_error: [], error_rate: [] };

export const ErrorsChart = ({ filters }: { filters: PerformanceFilters }) => {
  const { workspace } = useFlowContext();
  const [errorCodeFilter, setErrorCodeFilter] = useState<string>("all");
  const errors = useErrors(workspace.base_url, {
    ...filters,
    errorCodes: errorCodeFilter === "all" ? [] : [errorCodeFilter],
  });

  return (
    <Card>
      <Card.Header>
        Error rate over time
        <CardDropdown
          elements={[
            { key: "all", value: "All error codes" },
            ...DECISION_ERROR_STATUS_CODES.map((errorCode) => ({
              key: errorCode,
              value: `Error code ${errorCode}`,
            })),
          ]}
          renderValue={(item) => (
            <div className="flex items-center gap-x-1.5">
              <span style={{ color: getErrorColor(item.key) }}>
                <Icon icon={faCircle} size="3xs" />
              </span>

              {item.value}
            </div>
          )}
          selected={errorCodeFilter}
          width="w-40"
          onSelect={setErrorCodeFilter}
        />
      </Card.Header>
      <Card.Body>
        {errors.isError && <ErrorOverlay onRetry={errors.refetch} />}
        {errors.isSuccess &&
          isEmpty(errors.data?.by_error) &&
          isEmpty(errors.data?.error_rate) && <NoDataOverlay />}
        {errors.data?.error_rate.length &&
          errors.data.error_rate.every((bin) => bin.value === 0) && (
            <NoErrorsOverlay />
          )}
        <Plot
          data={errors.data ?? NO_DATA_STATE}
          isLoading={errors.isFetching}
          marks={[
            barY(
              {
                x: "bin",
                y: "value",
                fill: (d) => getErrorColor(d.code),
              },
              { select: (d) => d.by_error },
            ),
            layer([
              lineY(
                {
                  x: "bin",
                  y: "value",
                  stroke: "#4B5163",
                },
                {
                  select: (d) => d.error_rate,
                },
              ),
              tooltip(
                {
                  x: "bin",
                  y: "value",
                  title: (d) =>
                    `Error rate: ${formatNumber(d.value, {
                      style: "percent",
                      maximumFractionDigits: 1,
                    })}`,
                  anchor: "top",
                },
                {
                  select: (d) => d.error_rate,
                },
              ),
            ]),
            tooltip(
              {
                x: "bin",
                y: "value",
                title: (d) => `Error code ${d.code}: ${d.value}`,
              },
              { select: (d) => d.by_error, stacked: true },
            ),
          ]}
          timeWindow={filters.timeWindow}
        />
        <Legend items={LEGEND_ITEMS} />
      </Card.Body>
    </Card>
  );
};
