import AU from "src/assets/flags/au.svg?react";
import BH from "src/assets/flags/bh.svg?react";
import BR from "src/assets/flags/br.svg?react";
import CA from "src/assets/flags/ca.svg?react";
import DE from "src/assets/flags/de.svg?react";
import FR from "src/assets/flags/fr.svg?react";
import GB from "src/assets/flags/gb.svg?react";
import HK from "src/assets/flags/hk.svg?react";
import ID from "src/assets/flags/id.svg?react";
import IE from "src/assets/flags/ie.svg?react";
import IN from "src/assets/flags/in.svg?react";
import IT from "src/assets/flags/it.svg?react";
import JP from "src/assets/flags/jp.svg?react";
import KR from "src/assets/flags/kr.svg?react";
import SE from "src/assets/flags/se.svg?react";
import SG from "src/assets/flags/sg.svg?react";
import US from "src/assets/flags/us.svg?react";
import ZA from "src/assets/flags/za.svg?react";
import { DataRetentionUnit } from "src/connections/types";
import { assertUnreachable } from "src/utils/typeUtils";

export const codeToFlag = {
  au: AU,
  bh: BH,
  br: BR,
  ca: CA,
  de: DE,
  fr: FR,
  gb: GB,
  hk: HK,
  id: ID,
  ie: IE,
  in: IN,
  it: IT,
  jp: JP,
  kr: KR,
  se: SE,
  sg: SG,
  us: US,
  za: ZA,
};

// Allow only letters, digits, dashes, underscores, dots and spaces
// A-Za-z - letters
// 0-9 - digits
// \- - dashes
// . - dots
// _ - underscores
// \s - space
const WORKSPACE_NAME_ALLOWED_CHARS = /^[A-Za-z0-9\-_.\s]*$/;
const WORKSPACE_SLUG_ALLOWED_CHARS = /^[A-Za-z0-9-]*$/;

const validations = {
  noLeadingTrailingSpaces: (value: string) => value === value.trim(),
};

export const nameValidations = {
  ...validations,
  lengthRestrictionName: (value: string) =>
    value.length >= 4 && value.length <= 100,
  disallowedCharactersName: (value: string) =>
    WORKSPACE_NAME_ALLOWED_CHARS.test(value),
};

export const slugValidations = {
  ...validations,
  lengthRestrictionSlug: (value: string) =>
    value.length >= 4 && value.length <= 20,
  disallowedCharactersSlug: (value: string) =>
    WORKSPACE_SLUG_ALLOWED_CHARS.test(value),
};

export const getNameErrorMessage = (type: string): string => {
  switch (type) {
    case "required":
      return "This field cannot be empty";
    case "noLeadingTrailingSpaces":
      return "This value cannot have leading or trailing spaces";
    case "disallowedCharactersName":
      return "This value is not valid. Please only use letters, digits, dashes, underscores and dots.";
    case "disallowedCharactersSlug":
      return "This value is not valid. Please only use letters, digits and dashes.";
    case "lengthRestrictionName":
      return "This value has to be between 4 and 100 characters long";
    case "lengthRestrictionSlug":
      return "This value has to be between 4 and 20 characters long";
    case "notAvailableSlug":
      return "This value is already in use";
    default:
      return "";
  }
};

export type RetentionPeriod = {
  value: number;
  unit: DataRetentionUnit;
};

const DAYS_IN_MONTH = 31;
const DAYS_IN_YEAR = 365;

export const retentionPeriodToMinutes = (retentionPeriod: RetentionPeriod) => {
  switch (retentionPeriod.unit) {
    case "minutes":
      return retentionPeriod.value;
    case "hours":
      return retentionPeriod.value * 60;
    case "days":
      return retentionPeriod.value * 60 * 24;
    case "weeks":
      return retentionPeriod.value * 60 * 24 * 7;
    case "months":
      return retentionPeriod.value * 60 * 24 * DAYS_IN_MONTH;
    case "years":
      return retentionPeriod.value * 60 * 24 * DAYS_IN_YEAR;
    default:
      assertUnreachable(retentionPeriod.unit);
      return 0;
  }
};

export const convertPeriodValueToSeconds = (
  value: number,
  unit: DataRetentionUnit,
) => {
  return retentionPeriodToMinutes({ value, unit }) * 60;
};

export const convertSecondsToRetentionPeriodValue = (
  seconds: number,
  unit: DataRetentionUnit,
) => {
  switch (unit) {
    case "minutes":
      return seconds / 60;
    case "hours":
      return seconds / 60 / 60;
    case "days":
      return seconds / 60 / 60 / 24;
    case "weeks":
      return seconds / 60 / 60 / 24 / 7;
    case "months":
      return seconds / 60 / 60 / 24 / DAYS_IN_MONTH;
    case "years":
      return seconds / 60 / 60 / 24 / DAYS_IN_YEAR;
    default:
      assertUnreachable(unit);
      return 0;
  }
};
