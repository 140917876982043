import { faCheck, faFilter } from "@fortawesome/pro-regular-svg-icons";
import { faFilter as faFilterSolid } from "@fortawesome/pro-solid-svg-icons";
import { twJoin } from "tailwind-merge";

import {
  FixedPositionedDropdown,
  FixedPositionedDropdownPropsT,
} from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";

type PropsT = {
  isFiltering: boolean;
  onResetRequest: () => void;
  selected?: string;
  onSelect?: (keys: string) => void;
} & Omit<
  FixedPositionedDropdownPropsT<string>,
  | "renderValue"
  | "renderButtonValue"
  | "buttonClassName"
  | "onSelect"
  | "selected"
>;

export const DecisionHistoryTableFilter: React.FC<PropsT> = ({
  isFiltering,
  onResetRequest,
  ...props
}) => (
  <span className="ml-1 cursor-pointer">
    <FixedPositionedDropdown
      buttonClassName="border-0"
      elements={props.elements}
      multiple={false}
      renderButtonValue={() => (
        <Icon
          color={
            isFiltering
              ? "text-indigo-500"
              : "text-gray-500 hover:text-gray-700"
          }
          icon={isFiltering ? faFilterSolid : faFilter}
          size="2xs"
        />
      )}
      renderHeader={() => (
        <div className="flex w-[240px] cursor-default items-center justify-between px-5 py-3.5">
          <h3 className="font-inter-semibold-13px">Filter by status</h3>
          {isFiltering && (
            <button
              className="font-bold text-indigo-500"
              onClick={onResetRequest}
            >
              Reset
            </button>
          )}
        </div>
      )}
      renderValue={({ value, selected, disabled }) => {
        return (
          <div
            className={twJoin(
              "flex w-[240px] items-center justify-between py-2 pl-5 pr-6",
              disabled && "cursor-default",
            )}
          >
            <span
              className={twJoin("inline-block", disabled && "text-gray-300")}
            >
              {value}
            </span>
            {selected && (
              <div className="inline-block">
                <Icon color="text-indigo-600" icon={faCheck} />
              </div>
            )}
          </div>
        );
      }}
      selected={props.selected}
      onSelect={props.onSelect}
    />
  </span>
);
