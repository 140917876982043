import PlaidIcon from "src/assets/PlaidIcon.svg?react";
import {
  getDefaultPlaidAssetReport,
  getDefaultPlaidFinancialInsightsReport,
  getDefaultPlaidCraBaseReport,
  getDefaultPlaidCraIncomeInsightsReport,
  getDefaultPlaidCraPartnerInsightsReport,
} from "src/connections/connectionConfigs/plaid/plaidResources";
import { PlaidTemplate } from "src/connections/connectionConfigs/plaid/plaidTemplate";
import { ConnectionConfig } from "src/connections/connectionConfigs/types";

export const PLAID_CONFIG: ConnectionConfig<"plaid"> = {
  icon: PlaidIcon,
  template: PlaidTemplate,
  description: "One integration, all of open banking",
  name: "Plaid",
  resources: {
    asset_report: {
      externalLink:
        "https://www.notion.so/taktile/Asset-Report-3816c98d8b644e30b4c77ce6af29d8ba?pvs=4",
      integrationResource: getDefaultPlaidAssetReport,
    },
    financial_insights_report: {
      externalLink:
        "https://www.notion.so/taktile/Financial-Insights-Report-0e530af0478241eba65606eed1e2a574?pvs=4",
      integrationResource: getDefaultPlaidFinancialInsightsReport,
    },
    plaid_cra_base_report: {
      externalLink:
        "https://www.notion.so/taktile/Base-Report-af4998aae2f04d7ab2e7eed219beb75b?pvs=4",
      integrationResource: getDefaultPlaidCraBaseReport,
    },
    plaid_cra_income_insights_report: {
      externalLink:
        "https://www.notion.so/taktile/Income-Insights-Report-74a0702806cf447b95c2f930b702d8a0?pvs=4",
      integrationResource: getDefaultPlaidCraIncomeInsightsReport,
    },
    plaid_cra_partner_insights_report: {
      externalLink:
        "https://www.notion.so/taktile/Partner-Insights-Report-be3fabd39af145e5bfb74561c631271e?pvs=4",
      integrationResource: getDefaultPlaidCraPartnerInsightsReport,
    },
  },
};
