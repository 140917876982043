import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { twJoin } from "tailwind-merge";

import { ManifestIntegrationProvider } from "src/api/connectApi/manifestTypes";
import {
  ConnectionT,
  CustomProviderResourceT,
  IntegrationProviderResourceT,
  ResourceConfigT,
  Resources,
  InboundWebhookProviderResourceT,
  DatabaseProviderResourceT,
  isSQLDatabaseProvider,
  ManifestIntegrationProviderResourceT,
} from "src/api/connectApi/types";
import { Icon } from "src/base-components/Icon";
import { NodeIcon as getNodeIcon } from "src/constants/NodeIcons";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { rightSidePaneWidthClassname } from "src/flowContainer/RightPane";
import { AddNodeCard } from "src/nodeAdding/AddNodeCard";
import { isTemporarilyDisabledResource } from "src/router/featureFlags";
import { AddNodeParamsT } from "src/store/GraphStore";
import { compareStringsAscendingCaseInsensitive } from "src/utils/stringUtils";

export type AddResourcePanePropsT = {
  connection: ConnectionT;
  manifest?: ManifestIntegrationProvider;
  addNode: (params: AddNodeParamsT) => void;
  onBackButtonClick: () => void;
  onCloseButtonClick: () => void;
};

export const AddResourcePane: React.FC<AddResourcePanePropsT> = ({
  connection,
  manifest,
  addNode,
  onCloseButtonClick,
  onBackButtonClick,
}) => {
  const addNodeFromConnection = (
    connection: ConnectionT,
    resourceConfig: ResourceConfigT,
  ) => {
    if (connection.provider === "custom") {
      addNode({
        nodeType: NODE_TYPE.CUSTOM_CONNECTION_NODE,
        providerResource: {
          provider: connection.provider,
          resource: resourceConfig.resource,
        } as CustomProviderResourceT,
        connectionId: connection.id,
        resourceConfigId: resourceConfig.id,
        mediaKey: connection.configuration.media_key as string,
      });
    } else if (isSQLDatabaseProvider(connection.provider)) {
      addNode({
        nodeType: NODE_TYPE.SQL_DATABASE_CONNECTION_NODE,
        providerResource: {
          provider: connection.provider,
          resource: resourceConfig.resource,
        } as DatabaseProviderResourceT,
        connectionId: connection.id,
        resourceConfigId: resourceConfig.id,
      });
    } else if (connection.provider === "webhook") {
      addNode({
        nodeType: NODE_TYPE.WEBHOOK_CONNECTION_NODE,
        providerResource: {
          provider: connection.provider,
          resource: resourceConfig.resource,
        } as InboundWebhookProviderResourceT,
        connectionId: connection.id,
        resourceConfigId: resourceConfig.id,
        mediaKey: connection.configuration.media_key as string,
      });
    } else if (connection.manifest_version) {
      if (!manifest) {
        throw new Error("Manifest is required for manifest connections");
      }
      addNode({
        nodeType: NODE_TYPE.MANIFEST_CONNECTION_NODE,
        providerResource: {
          provider: connection.provider,
          resource: resourceConfig.resource,
          manifest_version: connection.manifest_version,
        } as ManifestIntegrationProviderResourceT,
        connectionId: connection.id,
        resourceConfigId: resourceConfig.id,
        manifest,
      });
    } else {
      addNode({
        nodeType: NODE_TYPE.INTEGRATION_NODE,
        providerResource: {
          provider: connection.provider,
          resource: resourceConfig.resource,
        } as IntegrationProviderResourceT,
        connectionId: connection.id,
        resourceConfigId: resourceConfig.id,
      });
    }
  };

  const getIconFromConnection = (
    connection: ConnectionT,
    resourceConfig: ResourceConfigT,
  ) => {
    if (connection.provider === "custom") {
      return getNodeIcon({
        nodeType: NODE_TYPE.CUSTOM_CONNECTION_NODE,
        provider: connection.provider,
        size: "md",
      });
    } else if (connection.provider === "webhook") {
      return getNodeIcon({
        nodeType: NODE_TYPE.WEBHOOK_CONNECTION_NODE,
        provider: connection.provider,
        size: "md",
      });
    } else {
      // Handling database and integration nodes
      return getNodeIcon({
        nodeType: NODE_TYPE.INTEGRATION_NODE,
        provider: connection.provider,
        resource: resourceConfig.resource,
        size: "md",
      });
    }
  };

  const renderLegacyProviderResourceConfigs = () => {
    return (
      <div>
        {connection.resource_configs
          .filter((resourceConfig) =>
            Resources.includes(resourceConfig.resource),
          )
          .filter((resourceConfig) => resourceConfig.resource !== "probe")
          .filter(
            (resourceConfig) =>
              !isTemporarilyDisabledResource(
                connection.provider,
                resourceConfig.resource,
              ),
          )
          .sort((a, b) =>
            compareStringsAscendingCaseInsensitive(a.name, b.name),
          )
          .map((resourceConfig) => (
            <AddNodeCard
              key={resourceConfig.id}
              description=""
              nodeIcon={getIconFromConnection(connection, resourceConfig)}
              title={resourceConfig.name}
              onClick={() => addNodeFromConnection(connection, resourceConfig)}
            />
          ))}
      </div>
    );
  };

  const renderManifestProviderResourceConfigs = () => {
    return (
      <div>
        {connection.resource_configs
          .sort((a, b) =>
            compareStringsAscendingCaseInsensitive(a.name, b.name),
          )
          .map((resourceConfig) => (
            <AddNodeCard
              key={resourceConfig.id}
              description=""
              nodeIcon={getIconFromConnection(connection, resourceConfig)}
              title={resourceConfig.name}
              onClick={() => addNodeFromConnection(connection, resourceConfig)}
            />
          ))}
      </div>
    );
  };

  const renderResourceConfigs = () => {
    if (connection.manifest_version) {
      return renderManifestProviderResourceConfigs();
    } else {
      return renderLegacyProviderResourceConfigs();
    }
  };

  return (
    <div className={twJoin("bg-white px-4 pt-4", rightSidePaneWidthClassname)}>
      <div className="mb-4 flex flex-row items-center justify-between text-gray-800">
        <div className="flex">
          <Icon
            color="text-gray-500 hover:text-gray-700 hover:cursor-pointer"
            icon={faArrowLeft}
            size="xs"
            onClick={onBackButtonClick}
          />
          <div className="ml-2 font-inter-semibold-16px">Choose a Resource</div>
        </div>
        <Icon
          color="text-gray-500 hover:text-gray-700 hover:cursor-pointer"
          icon={faXmark}
          size="xs"
          onClick={onCloseButtonClick}
        />
      </div>
      {renderResourceConfigs()}
    </div>
  );
};
