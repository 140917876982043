import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import {
  SingleDecisionEnvelope,
  useHistoryDecisionV2,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowT } from "src/api/flowTypes";
import { useFlowBySlug, useWorkspace } from "src/api/queries";
import { DecisionEnvironment } from "src/api/types";
import { LoadingView } from "src/base-components/LoadingView";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { ErrorPage } from "src/router/ErrorPage";
import { DecisionHistoryKeys, URLKeys } from "src/router/SearchParams";
import { LeftPaneOptions } from "src/router/SearchParams";
import { getUrlToAuthoringPage } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

const NavigateToDecision = () => {
  const [searchParams] = useSearchParams();
  const { workspace_id, flow_slug, version_id, decision_id } =
    Object.fromEntries(searchParams.entries());
  const flow = useFlowBySlug(workspace_id, flow_slug, { shallow: true });
  const workspace = useWorkspace(workspace_id);
  const decision = useHistoryDecisionV2({
    baseUrl: workspace.data?.base_url ?? "",
    decisionId: decision_id,
  });

  return (
    <LoadingView
      queryResult={[workspace, flow, decision]}
      renderErrored={(message) => {
        return <ErrorPage message={message} />;
      }}
      renderUpdated={([workspace, flow, decision]: [
        WorkspaceDataplane,
        FlowT,
        SingleDecisionEnvelope,
      ]) => {
        const page = getUrlToAuthoringPage(
          workspace.organization_id,
          workspace.id,
          flow.id,
          version_id,
        );
        const params = new URLSearchParams({
          [URLKeys.DecisionId]: decision_id,
          [URLKeys.LeftPane]: LeftPaneOptions.DecisionHistory,
        });

        if (!decision.isQueryError) {
          const decisionView = decision.decision.is_sandbox
            ? DecisionEnvironment.SANDBOX
            : DecisionEnvironment.LIVE;
          params.set(DecisionHistoryKeys.DecisionHistoryView, decisionView);
        }
        return <Navigate to={`${page}?${params.toString()}`} />;
      }}
    />
  );
};

type DisplayParams = {
  type: "decision";
};

/**
 * These routes must not be changed.
 * Only add new redirects.
 * Routes itself are frozen.
 */
export const DisplayRedirects: React.FC = () => {
  const { type } = useParamsDecode<DisplayParams>();

  // eslint-disable-next-line sonarjs/no-small-switch
  switch (type) {
    case "decision":
      return <NavigateToDecision />;
    default:
      return <ErrorPage message="404: Page not found" />;
  }
};
