import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { BigQuerySQLServiceAccountKey } from "src/connections/config/database/bigquery/BigQuerySQLServiceAccountKey";
import { Environment } from "src/connections/types";

type PropsT = {
  environment: Environment;
};

const Placeholder = `{
  "type": "service_account",
  "project_id": "yourProjectID",
  "private_key_id": "yourPrivateKeyID",
  "private_key": "-----BEGIN PRIVATE KEY-----\n-----END PRIVATE KEY-----",
  ...
}`;

export const BigQuerySQLServiceAccountAuthFieldset: React.FC<PropsT> = ({
  environment,
}) => {
  return (
    <FieldsetCard className="last:mb-0">
      <BigQuerySQLServiceAccountKey
        environment={environment}
        helpText="The service account key is a JSON file that contains the credentials for a service account. The service account represents a Google Cloud project and determines which resources the service account can access."
        placeholder={Placeholder}
        secretConfigField="service_account_key"
        secretName="Service account key"
        isRequired
      />
    </FieldsetCard>
  );
};
