import {
  ConnectionResourceTemplate,
  makeConnectionTemplate,
} from "src/connections/types";

const proofOfInterestConfigKey: ConnectionResourceTemplate["configKeys"][number] =
  {
    key: "proof_of_interest",
    name: "Proof of interest",
    type: "simple_selection",
    required: true,
    elements: [
      { key: "CREDIT_INQUIRY", value: "Credit inquiry" },
      { key: "BUSINESS_RELATIONSHIP", value: "Business relationship" },
      { key: "SOLVENCY_CHECK_CLAIM", value: "Solvency check claim" },
    ],
  };

export const CRIFB2BTemplate = makeConnectionTemplate({
  provider: "crif_b2b",
  secrets: [
    { key: "user", name: "Username", required: true },
    { key: "password", name: "Password", required: true },
  ],
  resources: {
    full_report: {
      name: "Full Report",
      configKeys: [proofOfInterestConfigKey],
    },
    credit_report: {
      name: "Credit Report",
      configKeys: [proofOfInterestConfigKey],
    },
    search_report: {
      name: "Search Report",
      configKeys: [],
    },
  },
});
