import { difference } from "lodash";
import { useRef, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Effect } from "src/clients/flow-api";
import { RuleNodeV2FormT } from "src/ruleNodeV2Editor/types";

type PropsT = {
  onUnregister: (index: number) => void;
  onSetValue: (index: number, value: string) => void;
};

export const useDefaultEffectsValue = ({
  onUnregister,
  onSetValue,
}: PropsT) => {
  const { control } = useFormContext<RuleNodeV2FormT>();

  const value = useWatch({ control, name: "default_effects" });
  const oldValue = useRef<Effect[]>(value);

  // sync branch-effects/output-fields list
  useEffect(() => {
    // if effect was removed
    if (value.length < oldValue.current.length) {
      const oldIds = oldValue.current.map((field) => field.id);
      const newIds = value.map((field) => field.id);

      const deletedIds = difference(oldIds, newIds);
      deletedIds.forEach((id) => {
        const index = oldIds.indexOf(id);

        if (index > -1) {
          onUnregister(index);
        }
      });
    }

    // push updated values to relevant dependants
    value.forEach((field, index) => {
      onSetValue(index, field.target);
    });

    oldValue.current = value;
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return value;
};
