import { isEmpty } from "lodash";

// Allow only letters, digits, dashes, underscores, dots and spaces
// A-Za-z - letters
// 0-9 - digits
// \- - dashes
// . - dots
// _ - underscores
// @ - @ sign
const EMAIL_USERNAME_ALLOWED_CHARS = /^[A-Za-z0-9\-+_.@]*$/;

export const emailUsernameValidations = {
  noLeadingTrailingSpaces: (value: string) => value === value.trim(),
  isNotEmpty: (value: string) => !isEmpty(value),
  disallowedCharactersName: (value: string) =>
    EMAIL_USERNAME_ALLOWED_CHARS.test(value),
};
