import { map } from "lodash";
import React from "react";
import { useForm } from "react-hook-form";

import { FieldErrorsT } from "src/api/types";
import { SubFormBaseProps } from "src/integrationNode/IntegrationNodeEditor";
import { InsightMappingRow } from "src/integrationNode/editorComponents/InsightMappingRow";
import {
  DEFAULT_OUTPUT_BE_NAME,
  IntegrationResourceT,
  OutputMappingsT,
} from "src/integrationNode/types";
import { ManifestIntegrationResourceT } from "src/manifestConnectionNode/types";
import { useSubmitForm } from "src/utils/useSubmitForm";

type PropsT = {
  integrationResource: IntegrationResourceT | ManifestIntegrationResourceT;
  updateMappingOutput: (outputMapping: OutputMappingsT) => void;
  runFieldErrors?: FieldErrorsT;
} & SubFormBaseProps;

export const OutputMappings: React.FC<PropsT> = ({
  integrationResource,
  updateMappingOutput,
  runFieldErrors,
  immutable,
  isReactive,
}) => {
  const formProps = useForm({
    defaultValues: integrationResource.output,
    ...(isReactive && { values: integrationResource.output }),
  });

  useSubmitForm({
    onChange: (data: OutputMappingsT) => {
      updateMappingOutput(data);
    },
    disabled: isReactive,
    previousValues: integrationResource.output,
    watch: formProps.watch,
  });

  const defaultReportMapping =
    integrationResource.output[DEFAULT_OUTPUT_BE_NAME];

  const hasInsights =
    Object.keys(integrationResource.output.insights).length > 0;

  const renderInsights = () => (
    <>
      {map(integrationResource.output.insights, (outputMapping, beName) => (
        <InsightMappingRow
          key={beName}
          displayName={outputMapping.displayName}
          fieldName={`insights.${beName}`}
          formProps={formProps}
          hint={outputMapping.hint}
          immutable={immutable}
          runFieldError={runFieldErrors?.[outputMapping.id]}
        />
      ))}
    </>
  );

  return (
    <>
      <InsightMappingRow
        description="Add the entire API response into the data object"
        displayName={defaultReportMapping.displayName}
        fieldName={DEFAULT_OUTPUT_BE_NAME}
        formProps={formProps}
        immutable={immutable}
        runFieldError={runFieldErrors?.[defaultReportMapping.id]}
      />
      {hasInsights && renderInsights()}
    </>
  );
};
