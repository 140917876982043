import { FieldError, UseFormRegisterReturn } from "react-hook-form";

import { ErrorHint } from "src/base-components/ErrorHint";
import { Input } from "src/base-components/Input";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { getNameErrorMessage } from "src/flow/modals/utils";

export const NameInput: React.FC<{
  error: FieldError | undefined;
  formProps: UseFormRegisterReturn;
  dataLoc?: string;
}> = ({ error, formProps, dataLoc }) => {
  return (
    <>
      <p className="mb-1 text-gray-800 font-inter-semibold-13px">
        Name <RequiredAsterisk />
      </p>
      <Input
        data-loc={dataLoc !== undefined ? dataLoc : "add-flow-name"}
        errored={error !== undefined}
        placeholder="Enter Decision Flow name"
        type="text"
        fullWidth
        {...formProps}
      />
      {error && (
        <ErrorHint className="mb-2 mr-2" height="h-2">
          {getNameErrorMessage(error.type)}
        </ErrorHint>
      )}
    </>
  );
};
