import { Operation } from "src/api/flowVersionUpdateIndex";
import { ChangeLogDb, ReviewCommentStatus } from "src/clients/flow-api";
import { assertUnreachable } from "src/utils/typeUtils";

export const getOperation = (change: ChangeLogDb): Operation => {
  return change.diff
    ? (change.diff.operation as Operation)
    : change.comment
      ? Operation.CREATE_COMMENT
      : Operation.UNKNOWN;
};

export const getChangeAction = (change: ChangeLogDb): string => {
  const operation = getOperation(change);
  switch (operation) {
    case Operation.CREATE_NODE:
    case Operation.CREATE_GROUP:
    case Operation.VERSION_CREATED:
    case Operation.CREATE_SPLIT_NODE:
      return "created";
    case Operation.VERSION_DUPLICATED:
      return "duplicated";
    case Operation.CUT_GROUP:
    case Operation.CUT_NODE:
    case Operation.CUT_NODES:
      return "cut";
    case Operation.PASTE_NODE:
    case Operation.PASTE_NODES:
      return "pasted";
    case Operation.DELETE_GROUP_WITH_NODES:
    case Operation.DELETE_NODE:
    case Operation.DELETE_NODES:
    case Operation.DELETE_SPLIT_NODE:
      return "deleted";
    case Operation.UNGROUP_GROUP:
      return "ungrouped";
    case Operation.EDIT_NODE:
    case Operation.EDIT_PARAMETERS:
    case Operation.EDIT_SCHEMA:
    case Operation.VERSION_METADATA_EDITED:
    case Operation.DELETE_BRANCH:
    case Operation.CREATE_BRANCH:
      return "edited";
    case Operation.PUBLISH:
      return "published";
    case Operation.RENAME_GROUP:
      return "updated group title to";
    case Operation.RENAME_NODE:
      return "updated node title to";
    case Operation.RENAME_BRANCH:
      return "updated branch name to";
    case Operation.VERSION_RESTORED:
      return "restored";
    case Operation.ARCHIVE:
      return "archived";
    case Operation.UNKNOWN:
      return "edited";
    case Operation.VERSION_IMPORTED:
      return "imported";
    case Operation.CREATE_COMMENT: {
      if (change.comment?.reviewer_status) {
        switch (change.comment.reviewer_status) {
          case ReviewCommentStatus.APPROVED:
            return "approved changes";

          case ReviewCommentStatus.COMMENTED:
            return "commented on";

          case ReviewCommentStatus.REQUESTED_CHANGES:
            return "requested changes";

          case ReviewCommentStatus.CANCELLED:
            return "cancelled review request";

          case ReviewCommentStatus.REVIEW_REREQUESTED:
            return "re-requested a review from";

          default:
            assertUnreachable(change.comment.reviewer_status);
            return "";
        }
      } else {
        return "commented on";
      }
    }
    default:
      assertUnreachable(operation);
  }
  return "edited";
};
