import {
  faEye,
  faCircleNotch,
  faCircleInfo,
  faClock,
  faRotateRight,
  faList,
} from "@fortawesome/pro-regular-svg-icons";
import { ColumnDef, CellContext } from "@tanstack/react-table";
import { twJoin } from "tailwind-merge";

import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { Icon } from "src/base-components/Icon";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/base-components/Tooltip";
import { EventType } from "src/clients/flow-api";
import { RawCell } from "src/dataTable/RawCell";
import { ResultCell } from "src/dataTable/ResultCell";
import { formatDate } from "src/utils/datetime";
import {
  classifyDelivery,
  webhookResponse,
} from "src/webhooks/HistorySidebar/webhookUtils";
import {
  OutgoingWebhookResponse,
  webhookTriggerNameMap,
} from "src/webhooks/queries";

const HeaderCell: React.FC<{
  children?: React.ReactNode;
  className: string;
}> = ({ children, className }) => (
  <div
    className={twJoin(
      "py-1.5 pr-4 text-left text-gray-800 font-inter-medium-12px",
      className,
    )}
  >
    {children}
  </div>
);

type Args = {
  selectedRowIsLoading: boolean;
  retryWebhook: (webhook: OutgoingWebhookResponse) => void;
  filterByDecisionId: (decisionId: string) => void;
};

export const getVersionDecisionHistoryColumns = ({
  selectedRowIsLoading,
  retryWebhook,
  filterByDecisionId,
}: Args): ColumnDef<DecisionHistoryRecordV2<OutgoingWebhookResponse>>[] => {
  return [
    {
      id: "decisionId",
      size: 50,
      header: () => (
        <HeaderCell className="flex items-center pl-2">
          <span className="inline-block">ID</span>
          <Tooltip align="center" placement="top" title="Decision ID">
            <div className="ml-1">
              <Icon
                color="text-gray-500 hover:text-gray-700"
                cursorType="pointer"
                icon={faCircleInfo}
                size="2xs"
              />
            </div>
          </Tooltip>
        </HeaderCell>
      ),
      accessorFn: (row) =>
        row.entity_id && (
          <CopyTextIcon dataLoc={`cp-${row.entity_id}`} value={row.entity_id} />
        ),
      cell: ({ cell }) => (
        <RawCell<DecisionHistoryRecordV2<OutgoingWebhookResponse>>
          cell={cell}
          classNameOverrides="max-w-0 pl-1.5"
        />
      ),
    },
    {
      id: "datetime",
      size: 90,
      header: () => <HeaderCell className="w-[100px]">Time</HeaderCell>,
      accessorFn: (row) => formatDate(row.start_time, "dd MMM yy HH:mm:ss"),
      cell: ({ cell }) => (
        <RawCell<DecisionHistoryRecordV2<OutgoingWebhookResponse>>
          cell={cell}
          classNameOverrides="max-w-36 w-36"
        />
      ),
    },
    {
      id: "type",
      size: 100,
      header: () => <HeaderCell className="w-[130px]">Event</HeaderCell>,
      // We default to DECISION_OUTCOME since detail-type
      // was not there when it was the only trigger type
      accessorFn: (row) => {
        const response = webhookResponse(row);
        return response
          ? webhookTriggerNameMap[
              response["detail-type"] ?? EventType.DECISION_OUTCOME
            ]
          : "";
      },
      cell: ({ cell }) => (
        <RawCell<DecisionHistoryRecordV2<OutgoingWebhookResponse>>
          cell={cell}
          classNameOverrides="max-w-[130px] w-[130px]"
        />
      ),
    },
    {
      id: "status",
      size: 150,
      header: () => (
        <HeaderCell className="flex w-auto items-center">
          <span>Status</span>
        </HeaderCell>
      ),
      accessorFn: (row) => {
        const response = webhookResponse(row);
        const status = classifyDelivery(new Date(row.start_time), response);
        return (
          <Tooltip
            activated={status.tooltipBody !== undefined}
            body={status.tooltipBody}
            placement="bottom"
            title={status.tooltipTitle}
            asChild
          >
            <div>
              <Pill dataLoc="delivery-pill" variant={status.pillVariant}>
                <Pill.Icon icon={status.pillIcon}></Pill.Icon>
                <Pill.Text>{status.pillText}</Pill.Text>
                {status.willRetry && <Pill.Icon icon={faClock} />}
              </Pill>
            </div>
          </Tooltip>
        );
      },
      cell: ({
        cell,
      }: CellContext<
        DecisionHistoryRecordV2<OutgoingWebhookResponse>,
        any
      >) => {
        const isSelected = cell.row.getIsSelected();

        const message = (
          <div className="flex grow items-center">
            <span className="mr-auto justify-start">{cell.getValue()}</span>

            {selectedRowIsLoading && isSelected ? (
              <span className="inline-block animate-spin justify-end align-middle text-indigo-400">
                <Icon icon={faCircleNotch} size="sm" />
              </span>
            ) : (
              webhookResponse(cell.row.original) && (
                <span
                  className={twJoin(
                    "ml-auto flex justify-end text-gray-500 group-hover/row:visible",
                    isSelected ? "text-indigo-500" : "invisible",
                  )}
                >
                  <Icon icon={faEye} size="sm" />
                  <EllipsisOptionsDropdown
                    buttonClassName="text-gray-500"
                    buttonDataLoc="actions-menu"
                    elements={[
                      {
                        key: "Retry",
                        action: () =>
                          retryWebhook(webhookResponse(cell.row.original)!),
                        icon: faRotateRight,
                      },
                      // Only allow filtering by decision_id if one is present
                      ...(cell.row.original.entity_id
                        ? [
                            {
                              key: "View all with this Decision ID",
                              action: () =>
                                filterByDecisionId(cell.row.original.entity_id),
                              icon: faList,
                            },
                          ]
                        : []),
                    ]}
                    iconSize="xs"
                    timeoutDuration={0}
                  />
                </span>
              )
            )}
          </div>
        );

        return (
          <ResultCell<DecisionHistoryRecordV2>
            cell={message}
            className="max-w-0 overflow-auto pr-0"
            objectDetailPosition="topRight"
          />
        );
      },
    },
  ];
};
