import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import { Fragment } from "react";

import { InformationPill } from "src/base-components/InformationPill";
import { DatasetContext } from "src/datasets/DatasetTable/types";

export const MissingColumnsCallout: React.FC<{
  columns: string[];
  onAddMissingColumns: () => void;
  context: DatasetContext;
}> = ({ columns, onAddMissingColumns, context }) => {
  const [column, ...restColumns] = columns;
  const manyColumns = restColumns.length > 0;

  return (
    <InformationPill
      action={{ text: "Add missing columns", onClick: onAddMissingColumns }}
      icon={faWarning}
      type="error"
      fullWidth
    >
      <div>
        Add {manyColumns && `${columns.length} `}missing column
        {manyColumns && "s"}{" "}
        {restColumns.map((column, index) => (
          <Fragment key={column}>
            {index > 0 && ", "}
            <strong className="font-inter-medium-12px">{column}</strong>
          </Fragment>
        ))}
        {manyColumns && " and "}
        <strong className="font-inter-medium-12px">{column}</strong> to this
        dataset to be able to{" "}
        {context === "authoring"
          ? "test this version"
          : "test and run this job"}
      </div>
    </InformationPill>
  );
};
