import {
  faCircleNotch,
  faCircleInfo,
} from "@fortawesome/pro-regular-svg-icons";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { twJoin } from "tailwind-merge";

import {
  ConnectionT,
  isSQLDatabaseProvider,
  NotTestableProviders,
} from "src/api/connectApi/types";
import { startsWithInternalPrefix } from "src/api/constants";
import { BetaPill } from "src/base-components/BetaPill";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/base-components/Tooltip";
import { isBetaConnection } from "src/connections/CreateConnectionGrid";
import { ConnectionActions } from "src/connections/ListActions";
import { ProviderIcon } from "src/connections/config/Icon";
import { HeaderCell } from "src/dataTable/HeaderCell";
import { RawCell } from "src/dataTable/RawCell";
import { dateFromNow, formatDate } from "src/utils/datetime";

const Header: React.FC<{ value?: string }> = ({ value }) => (
  <HeaderCell className="py-2.5 pt-4.5">{value}</HeaderCell>
);

type ConnectionColumnsT = {
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onTest: (id: string) => void;
  testingConnection?: ConnectionT;
};

export const getTestableStatus = (connection: ConnectionT) => {
  if (connection.provider === "webhook" || connection.provider === "retool") {
    return "untestable";
  }

  if (connection.provider === "custom") {
    const hasProductionProbeURL = Boolean(
      connection.configuration.probe_url &&
        connection.configuration.probe_url.trim(),
    );

    const hasSandboxProbeURL = Boolean(
      connection.enable_non_prod_configs &&
        connection.non_prod_env_configs?.sandbox?.probe_url &&
        connection.non_prod_env_configs?.sandbox?.probe_url.trim(),
    );

    return hasProductionProbeURL || hasSandboxProbeURL
      ? "testable"
      : "untestable_lacks_probe_url";
  }

  if (connection.configuration.multi_tenant) {
    // Temporary not allowing the multi-tenant connections
    // to be tested while we figure out how exactly it should work
    return "untestable";
  } else {
    return NotTestableProviders.includes(connection.provider)
      ? "untestable"
      : "testable";
  }
};

export const getConnectionType = (connection: ConnectionT) => {
  if (connection.provider === "custom") {
    return "Custom Connection";
  } else if (connection.provider === "webhook") {
    return "Inbound Webhook";
  } else if (isSQLDatabaseProvider(connection.provider)) {
    return "Database";
  } else {
    return "Integration";
  }
};

export const columns: (
  actionHandlers: ConnectionColumnsT,
) => ColumnDef<ConnectionT, React.ReactNode>[] = ({
  onDelete,
  onEdit,
  onTest,
  testingConnection,
}) => [
  {
    id: "id",
    size: 50,
    header: () => (
      <HeaderCell className="flex items-center pr-12 pt-4.5">
        <span>ID</span>
        <Tooltip align="center" placement="top" title="Connection ID">
          <div className="ml-1">
            <Icon
              color="text-gray-500"
              cursorType="pointer"
              icon={faCircleInfo}
              size="2xs"
            />
          </div>
        </Tooltip>
      </HeaderCell>
    ),
    accessorFn: (row) => (
      <CopyTextIcon
        color="hover:text-indigo-500"
        dataLoc="copy-connection-id"
        value={row.id}
      />
    ),
    cell: ({ cell }) => (
      <RawCell<ConnectionT> cell={cell} classNameOverrides="max-w-0" />
    ),
  },
  {
    id: "connections",
    header: () => <Header value="Connection name" />,
    accessorFn: (row) => row.name,
    cell: ({ cell }) => {
      const isInternalConnection = startsWithInternalPrefix(
        cell.row.original.name,
      );
      const wrapper = (
        <span className="inline-flex items-center gap-2">
          <ProviderIcon
            manifestVersion={cell.row.original.manifest_version}
            mediaKey={cell.row.original.configuration.media_key}
            provider={cell.row.original.provider}
            size="sm"
          />
          {isInternalConnection && (
            <Pill variant="red">
              <Pill.Text>Internal</Pill.Text>
            </Pill>
          )}
          <span className="inline-block">{cell.getValue()}</span>
          {isBetaConnection(cell.row.original.provider) && (
            <BetaPill tooltipPlacement="right" />
          )}
        </span>
      );
      return (
        <RawCell<ConnectionT>
          cell={wrapper}
          classNameOverrides="max-w-90 h-5 py-0"
        />
      );
    },
  },
  {
    id: "updated",
    maxSize: 180,
    size: 180,
    header: () => <Header value="Last updated" />,
    accessorFn: (row) => dateFromNow(row.updated_at),
    cell: ({ cell }) => <RawCell<ConnectionT> cell={cell} />,
  },
  {
    id: "created",
    maxSize: 212,
    size: 212,
    header: () => <Header value="Created at" />,
    accessorFn: (row) => formatDate(row.created_at, "MMM d, u 'at' h:m aaa"),
    cell: ({ cell }) => <RawCell<ConnectionT> cell={cell} />,
  },
  {
    id: "type",
    maxSize: 180,
    size: 180,
    header: () => <Header value="Type" />,
    accessorFn: (row) => getConnectionType(row),
    cell: ({ cell }) => {
      const wrapper = (
        <Pill variant="gray">
          <Pill.Text>{cell.getValue()}</Pill.Text>
        </Pill>
      );
      return (
        <RawCell<ConnectionT> cell={wrapper} classNameOverrides="max-w-0" />
      );
    },
  },
  {
    id: "actions",
    maxSize: 100,
    size: 100,
    header: () => <Header />,
    cell: ({ cell }) => {
      const isTestingConnection =
        testingConnection?.id === cell.row.original.id;
      return (
        <RawCell
          cell={
            isTestingConnection ? (
              <span className="flex w-21 items-center">
                <div className="mr-1">
                  <Icon icon={faCircleNotch} spin={true} />
                </div>
                Testing...
              </span>
            ) : (
              <ConnectionActions
                rowId={cell.row.original.id}
                testableStatus={getTestableStatus(cell.row.original)}
                onDelete={() => onDelete(cell.row.original.id)}
                onEdit={() => onEdit(cell.row.original.id)}
                onTest={() => onTest(cell.row.original.id)}
              />
            )
          }
          classNameOverrides={twJoin(
            !isTestingConnection && "invisible",
            "items-right flex max-w-0 justify-end group-hover:visible",
          )}
        />
      );
    },
  },
];
