import { EditorView, ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { useEffect } from "react";

export const useSetDataLoc = (
  dataLoc: string | undefined,
  editor: ReactCodeMirrorRef | null,
) => {
  // For cypress we use data-loc to type into elements. We need to put the data-loc attribute on the correct child of the CodeMirror editor
  useEffect(() => {
    if (dataLoc) {
      editor?.view?.contentDOM.setAttribute("data-loc", dataLoc);
    } else {
      editor?.view?.contentDOM.removeAttribute("data-loc");
    }
  }, [dataLoc, editor?.view?.contentDOM]);

  return {
    onCreateEditor: (view: EditorView) => {
      if (dataLoc) {
        view.contentDOM.setAttribute("data-loc", dataLoc);
      }
    },
  };
};
