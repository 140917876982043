import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  taktileInternalPrefix,
  validateNotStartsWithInternalPrefix,
} from "src/api/constants";
import { useUpdateWorkspaceApiKey } from "src/apiKeys/api/queries";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { ExternalLink } from "src/base-components/ExternalLink";
import { Input } from "src/base-components/Input";
import { Modal } from "src/base-components/Modal";
import { WorkspaceServiceAccount } from "src/clients/taktile-api";
import { DOCS_API_KEYS } from "src/constants/ExternalLinks";
import * as logger from "src/utils/logger";

type ApiKeyInputs = {
  name: string;
};

type PropsT = {
  keyToEdit?: WorkspaceServiceAccount;
  organizationId: string;
  workspaceId: string;
  onClose: () => void;
};

export const EditApiKeyModal: React.FC<PropsT> = ({
  keyToEdit,
  organizationId: orgId,
  workspaceId: wsId,
  onClose,
}) => {
  const updateWorkspaceApiKey = useUpdateWorkspaceApiKey(
    orgId,
    wsId,
    keyToEdit?.id ?? "",
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<ApiKeyInputs>({
    mode: "onChange",
  });

  useEffect(() => {
    if (keyToEdit) {
      reset({
        name: keyToEdit.name,
      });
    }
  }, [keyToEdit, reset]);

  const onConfirm = handleSubmit(async (input: ApiKeyInputs) => {
    if (!keyToEdit) {
      return;
    }

    try {
      await updateWorkspaceApiKey.mutateAsync(input);
    } catch (error) {
      logger.error(`Error when updating ApiKey: ${error}`);
    }
    onClose();
  });

  return (
    <Modal
      className="w-120"
      open={keyToEdit !== undefined}
      title="API key"
      closeIcon
      onClose={onClose}
    >
      <div className="px-6 pb-6">
        <div className="my-4 text-gray-500 font-inter-medium-12px">
          Read more about API keys on our{" "}
          <ExternalLink href={DOCS_API_KEYS}>Documentation</ExternalLink> page.
        </div>
        {Boolean(errors.name?.message) && (
          <ErrorHint>{errors.name?.message}</ErrorHint>
        )}
        <Input
          {...register("name", {
            required: true,
            validate: validateNotStartsWithInternalPrefix(
              `API key name cannot start with ${taktileInternalPrefix}`,
            ),
          })}
          placeholder="Name your API key"
          fullWidth
        />
        <div className="mt-8 flex items-center justify-end gap-2 text-right">
          <span className="w-[5.25rem]">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </span>
          <Button
            disabled={!isEmpty(errors)}
            htmlType="submit"
            loading={isSubmitting}
            variant="primary"
            onClick={onConfirm}
          >
            Save key
          </Button>
        </div>
      </div>
    </Modal>
  );
};
