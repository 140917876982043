import { Edge, MarkerType, XYPosition } from "reactflow";
import { v4 as uuidV4 } from "uuid";

import {
  CustomProviderResourceT,
  InboundWebhookProviderResourceT,
  ManualReviewProviderResourceT,
  DatabaseProviderResourceT,
} from "src/api/connectApi/types";
import { getDefaultEnvironmentsConfig } from "src/baseConnectionNode/defaultValues";
import {
  Junctions,
  DecisionTableField,
  FlowNodeMeta,
  ManualReviewNodeMeta,
  ScorecardFactor,
  Effect as RuleNodeV2EffectT,
  RuleBranch as RuleNodeV2BranchT,
  SplitBranch as SplitNodeV2BranchT,
} from "src/clients/flow-api";
import {
  AssignmentNode,
  BeMappedNode,
  CustomConnectionNode,
  DecisionTableNode,
  DecisionTableNodeDataT,
  FlowNode,
  GroupNode,
  GroupSeparatorNode,
  InboundWebhookConnectionNode,
  DatabaseConnectionNode,
  IntegrationNode,
  ManualReviewNode,
  MLNode,
  MLNodeDataT,
  RuleNodeV2,
  ScorecardNode,
  SimpleNode,
  SplitMergeNode,
  SplitNodeV2,
  LoopNode,
  LoopNodeDataT,
  ManifestConnectionNode,
} from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { CustomConnectionForm } from "src/customConnectionNode/types";
import { DatabaseConnectionForm } from "src/databaseConnectionNode/types";
import {
  getDefaultCase,
  getDefaultFallback,
  getDefaultField,
} from "src/decisionTableNode/defaultValues";
import { EDGE_TYPE } from "src/flowGraph/EdgeTypes";
import { InboundWebhookConnectionForm } from "src/inboundWebhookNode/types";
import { IntegrationResourceT } from "src/integrationNode/types";
import { ManifestIntegrationResourceT } from "src/manifestConnectionNode/types";
import { theme } from "src/styles/theme";

export const edgeMarkerEnd = {
  height: 16,
  width: 24,
  strokeWidth: 1,
  type: MarkerType.ArrowClosed,
  strokeColor: theme.gray300,
  color: theme.gray300,
};

export const DEFAULT_NODE_WIDTH = 256 as const;
export const DEFAULT_NODE_HEIGHT = 58 as const;

// This edge marker (in contrast to the above one) is only used under certain circumstances (decision history entry is shown) as an opt-in and thus we diplay it dynamically, for which we need to load the definition initially
// The marker definition code from below is basically taken from react-flow, just with concrete values filled in
export const HistoricalRunHighlightMarkerEndId =
  "highlight-edge-indigo" as const;
export const HistoricalRunEdgeMarkerDefinitions = () => (
  <svg>
    <defs>
      <marker
        className="react-flow__arrowhead"
        id={HistoricalRunHighlightMarkerEndId}
        markerHeight={edgeMarkerEnd.height}
        markerUnits="strokeWidth"
        markerWidth={edgeMarkerEnd.width}
        orient="auto-start-reverse"
        refX="0"
        refY="0"
        viewBox="-10 -10 20 20"
      >
        <polyline
          className="fill-indigo-500 stroke-indigo-500"
          points="-5,-4 0,0 -5,4 -5,-4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={edgeMarkerEnd.strokeWidth}
        />
      </marker>
    </defs>
  </svg>
);

// This is another custom edge marker that is used for edges higlighted for node adding.
export const NodeAddingHighlightMarkerEndId =
  "highlight-edge-shadow-indigo" as const;
export const NodeAddingEdgeMarkerDefinitions = () => (
  <svg>
    <defs>
      <marker
        className="react-flow__arrowhead"
        id={NodeAddingHighlightMarkerEndId}
        markerHeight={edgeMarkerEnd.height}
        markerUnits="strokeWidth"
        markerWidth={edgeMarkerEnd.width}
        orient="auto-start-reverse"
        refX="0"
        refY="0"
        viewBox="-10 -10 20 20"
      >
        <polyline
          className="fill-indigo-200 stroke-indigo-200"
          points="-5,-4 0,0 -5,4 -5,2 -2,2 -2,-2 -5,-2 -5,-4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={edgeMarkerEnd.strokeWidth + 2}
        />
        <polyline
          className="fill-indigo-500 stroke-indigo-500"
          points="-5,-4 0,0 -5,4 -5,-4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={edgeMarkerEnd.strokeWidth}
        />
      </marker>
    </defs>
  </svg>
);

export const DEFAULT_UNTITLED_NODE_NAME = "Untitled Node";
export const DEFAULT_UNTITLED_GROUP_NAME = "Untitled Group";
export const DEFAULT_INPUT_NODE_NAME = "Input Node";
export const DEFAULT_CODE_NODE_NAME = DEFAULT_UNTITLED_NODE_NAME;
export const DEFAULT_SPLIT_NODE_V2_NAME = DEFAULT_UNTITLED_NODE_NAME;
export const DEFAULT_OUTPUT_NODE_NAME = "Output Node";
export const DEFAULT_RULE_NODE_V2_NAME = DEFAULT_UNTITLED_NODE_NAME;
export const DEFAULT_SPLIT_MERGE_NODE_NAME = "Merge Node";
export const DEFAULT_GROUP_SEPARATOR_NODE_NAME = "Group Separator Node";

// ReactFlow Nodes have to be initialized with a position, which is why we give every node the position [0,0] in the beginning and then reposition them using dagre
export const DEFAULT_NODE_POSITION = { x: 0, y: 0 };

type GetDefaultNodeArgsT = Partial<{
  id: string;
  name: string;
  position: XYPosition;
}>;

export const getDefaultInputNode = ({
  id = uuidV4(),
  name = DEFAULT_INPUT_NODE_NAME,
  position = DEFAULT_NODE_POSITION,
}: GetDefaultNodeArgsT): BeMappedNode => ({
  id,
  data: {
    label: name,
  },
  position,
  type: NODE_TYPE.INPUT_NODE,
  height: 44,
  width: 85,
});

type GetDefaultCodeNodeArgsT = GetDefaultNodeArgsT & Partial<{ src: string }>;
export const getDefaultOutputNode = ({
  id = uuidV4(),
  name = DEFAULT_OUTPUT_NODE_NAME,
  position = DEFAULT_NODE_POSITION,
  src = "",
}: GetDefaultCodeNodeArgsT): SimpleNode => ({
  id,
  data: {
    label: name,
    src,
  },
  position,
  type: NODE_TYPE.OUTPUT_NODE,
  height: 44,
  width: 85,
});

export const getDefaultCodeNode = ({
  id = uuidV4(),
  name = DEFAULT_CODE_NODE_NAME,
  position = DEFAULT_NODE_POSITION,
  src = "",
}: GetDefaultCodeNodeArgsT): SimpleNode => ({
  id,
  data: {
    label: name,
    src,
  },
  position,
  type: NODE_TYPE.CODE_NODE,
  width: DEFAULT_NODE_WIDTH,
  height: DEFAULT_NODE_HEIGHT,
});

type GetDefaultSplitNodeV2ArgsT = GetDefaultNodeArgsT &
  Partial<{ branches: SplitNodeV2BranchT[]; defaultEdgeId: string }>;
export const getDefaultSplitNodeV2 = ({
  id = uuidV4(),
  name = DEFAULT_SPLIT_NODE_V2_NAME,
  position = DEFAULT_NODE_POSITION,
  branches = [],
  defaultEdgeId = "",
}: GetDefaultSplitNodeV2ArgsT): SplitNodeV2 => ({
  id,
  data: {
    label: name,
    branches,
    default_edge_id: defaultEdgeId,
  },
  position,
  type: NODE_TYPE.SPLIT_NODE_V2,
  width: DEFAULT_NODE_WIDTH,
  height: DEFAULT_NODE_HEIGHT,
});

export const getDefaultSplitBranchNodeV2 = ({
  id = uuidV4(),
  name = "",
  position = DEFAULT_NODE_POSITION,
}: GetDefaultNodeArgsT): SimpleNode => ({
  id,
  data: {
    label: name,
    src: "",
  },
  position,
  // An empty Code node is a no-op node
  type: NODE_TYPE.SPLIT_BRANCH_NODE_V2,
  width: 192,
  height: 38,
});

export const getDefaultSplitMergeNode = ({
  id = uuidV4(),
  name = DEFAULT_SPLIT_MERGE_NODE_NAME,
  position = DEFAULT_NODE_POSITION,
}: GetDefaultNodeArgsT): SplitMergeNode => ({
  id,
  data: {
    label: name,
  },
  position,
  type: NODE_TYPE.SPLIT_MERGE_NODE,
  width: 1,
  height: 1,
});

type GetDefaultRuleNodeV2ArgsT = GetDefaultNodeArgsT &
  Partial<{
    branches: RuleNodeV2BranchT[];
    defaultEffects: RuleNodeV2EffectT[];
  }>;
export const getDefaultRuleNodeV2 = ({
  id = uuidV4(),
  name = DEFAULT_RULE_NODE_V2_NAME,
  position = DEFAULT_NODE_POSITION,
  branches = [],
  defaultEffects = [],
}: GetDefaultRuleNodeV2ArgsT): RuleNodeV2 => ({
  id,
  data: {
    label: name,
    branches,
    default_effects: defaultEffects,
  },
  position,
  type: NODE_TYPE.RULE_NODE_V2,
  width: DEFAULT_NODE_WIDTH,
  height: DEFAULT_NODE_HEIGHT,
});

type GetDefaultIntegrationNodeArgsT = GetDefaultNodeArgsT & {
  integrationResource: IntegrationResourceT;
};
export const getDefaultIntegrationNode = ({
  id = uuidV4(),
  name = DEFAULT_UNTITLED_NODE_NAME,
  position = DEFAULT_NODE_POSITION,
  integrationResource,
}: GetDefaultIntegrationNodeArgsT): IntegrationNode => ({
  id,
  data: {
    label: name,
    ...integrationResource,
  },
  position,
  type: NODE_TYPE.INTEGRATION_NODE,
  width: DEFAULT_NODE_WIDTH,
  height: DEFAULT_NODE_HEIGHT,
});

type GetDefaultManifestConnectionNodeArgsT = GetDefaultNodeArgsT & {
  manifestIntegrationResource: ManifestIntegrationResourceT;
};
export const getDefaultManifestConnectionNode = ({
  id = uuidV4(),
  name = DEFAULT_UNTITLED_NODE_NAME,
  position = DEFAULT_NODE_POSITION,
  manifestIntegrationResource,
}: GetDefaultManifestConnectionNodeArgsT): ManifestConnectionNode => ({
  id,
  data: {
    label: name,
    ...manifestIntegrationResource,
  },
  position,
  type: NODE_TYPE.MANIFEST_CONNECTION_NODE,
  width: DEFAULT_NODE_WIDTH,
  height: DEFAULT_NODE_HEIGHT,
});

type GetDefaultMlNodeArgsT = GetDefaultNodeArgsT & {
  data?: Omit<MLNodeDataT, "label">;
};
export const getDefaultMlNode = ({
  id = uuidV4(),
  name = DEFAULT_UNTITLED_NODE_NAME,
  position = DEFAULT_NODE_POSITION,
  data = {
    src: "",
    model_id: "",
    model_filename: "",
  },
}: GetDefaultMlNodeArgsT): MLNode => ({
  id,
  data: {
    label: name,
    ...data,
  },
  position,
  type: NODE_TYPE.ML_NODE,
  width: DEFAULT_NODE_WIDTH,
  height: DEFAULT_NODE_HEIGHT,
});

type GetDefaultDecisionTableNodeArgsT = GetDefaultNodeArgsT & {
  data?: Partial<DecisionTableNodeDataT>;
};
export const getDefaultDecisionTableNode = ({
  id = uuidV4(),
  name = DEFAULT_UNTITLED_NODE_NAME,
  position = DEFAULT_NODE_POSITION,
  data = {},
}: GetDefaultDecisionTableNodeArgsT): DecisionTableNode => {
  const predicateFields = data.predicate_fields ?? [getDefaultField()];
  const effectFields = data.effect_fields ?? [getDefaultField()];
  const cases = data.cases ?? [
    getDefaultCase(predicateFields, effectFields, { name: "" }),
  ];
  const fallback = data.fallback ?? getDefaultFallback(effectFields);

  return {
    id,
    data: {
      label: name,
      predicate_fields: predicateFields,
      effect_fields: effectFields,
      cases: cases,
      fallback,
    },
    position,
    type: NODE_TYPE.DECISION_TABLE_NODE,
    width: DEFAULT_NODE_WIDTH,
    height: DEFAULT_NODE_HEIGHT,
  };
};

type GetDefaultAssignmentNodeArgsT = GetDefaultNodeArgsT &
  Partial<{
    default_effects: RuleNodeV2EffectT[];
  }>;

export const getDefaultAssignmentNode = ({
  id = uuidV4(),
  name = DEFAULT_UNTITLED_NODE_NAME,
  position = DEFAULT_NODE_POSITION,
  default_effects = [],
}: GetDefaultAssignmentNodeArgsT): AssignmentNode => {
  return {
    id,
    data: {
      label: name,
      default_effects: default_effects,
    },
    position,
    type: NODE_TYPE.ASSIGNMENT_NODE,
    width: DEFAULT_NODE_WIDTH,
    height: DEFAULT_NODE_HEIGHT,
  };
};

type GetDefaultScorecardNodeArgsT = GetDefaultNodeArgsT &
  Partial<{
    assign_field: DecisionTableField;
    extract_scores: boolean;
    equal_weights: boolean;
    factors: ScorecardFactor[];
  }>;

export const getDefaultScorecardNode = ({
  id = uuidV4(),
  name = DEFAULT_UNTITLED_NODE_NAME,
  position = DEFAULT_NODE_POSITION,
  assign_field = getDefaultField(),
  extract_scores = false,
  equal_weights = false,
  factors = [],
}: GetDefaultScorecardNodeArgsT): ScorecardNode => {
  return {
    id,
    data: {
      label: name,
      assign_field: assign_field,
      extract_scores: extract_scores,
      equal_weights: equal_weights,
      factors: factors,
    },
    position,
    type: NODE_TYPE.SCORECARD_NODE,
    width: DEFAULT_NODE_WIDTH,
    height: DEFAULT_NODE_HEIGHT,
  };
};

type GetDefaultGroupNodeArgsT = GetDefaultNodeArgsT & {
  expanded?: boolean;
  childrenIDs: string[];
  sinkChildId: string;
  sourceChildId: string;
};

export const COLLAPSED_GROUP_HEIGHT = 58 as const;
export const COLLAPSED_GROUP_WIDTH = 256 as const;

export const getDefaultGroupNode = ({
  id = uuidV4(),
  name = DEFAULT_UNTITLED_GROUP_NAME,
  position = DEFAULT_NODE_POSITION,
  expanded = false,
  childrenIDs,
  sinkChildId,
  sourceChildId,
}: GetDefaultGroupNodeArgsT): GroupNode => {
  return {
    id,
    data: {
      label: name,
      childrenIDs,
      uiState: {
        expanded: expanded,
        preSeparatorNode: getDefaultGroupSeparatorNode({
          type: NODE_TYPE.GROUP_PRE_SEPARATOR_NODE,
          parentNodeId: id,
        }),
        postSeparatorNode: getDefaultGroupSeparatorNode({
          type: NODE_TYPE.GROUP_POST_SEPARATOR_NODE,
          parentNodeId: id,
        }),
        incomingEdgeId: uuidV4(),
        outgoingEdgeId: uuidV4(),
      },
      sinkChildId,
      sourceChildId,
    },
    position,
    type: NODE_TYPE.GROUP_NODE,
    width: COLLAPSED_GROUP_WIDTH,
    height: COLLAPSED_GROUP_HEIGHT,
  };
};

export const getDefaultGroupSeparatorNode = <
  nodeType extends
    | NODE_TYPE.GROUP_PRE_SEPARATOR_NODE
    | NODE_TYPE.GROUP_POST_SEPARATOR_NODE,
>({
  id = uuidV4(),
  name = DEFAULT_GROUP_SEPARATOR_NODE_NAME,
  position = DEFAULT_NODE_POSITION,
  parentNodeId,
  type,
}: GetDefaultNodeArgsT & {
  type: nodeType;
  parentNodeId: string;
}): GroupSeparatorNode & { type: nodeType } => ({
  id,
  data: {
    label: name,
    incomingEdgeId: uuidV4(),
    outgoingEdgeId: uuidV4(),
  },
  parentNode: parentNodeId,
  position,
  type,
  width: 4,
  height: type === NODE_TYPE.GROUP_PRE_SEPARATOR_NODE ? 31 : 21,
});

type GetDefaultCustomConnectionNodeArgsT = GetDefaultNodeArgsT & {
  providerResource: CustomProviderResourceT;
  connectionId: string;
  resourceConfigId: string;
  mediaKey: string | null;
  optionals?: CustomConnectionForm;
};
export const getDefaultCustomConnectionNode = ({
  providerResource,
  connectionId,
  resourceConfigId,
  mediaKey,
  optionals = {
    verb: "get",
    path: { id: uuidV4(), expression: "" },
    params: [],
    headers: [],
    body: {
      id: uuidV4(),
      value: {
        selected: "key_values",
        key_values: [],
      },
    },
    response: { id: uuidV4(), mapped_to: "", active: true },
    config: {
      environments_config: getDefaultEnvironmentsConfig(),
      testing: {
        use_cached_reports: false,
        // Deprecated, kept for backwards compatibility
        use_fallback_live_connection: false,
      },
      retry_config: {
        active: false,
      },
      caching: {
        active: false,
        max_age_seconds: "86400",
      },
      timeout: {
        active: false,
        timeout_seconds: "15",
      },
      error: {
        allow_4xx: false,
        allow_5xx: false,
      },
      has_raw_requests_enabled_in_node: false,
    },
  },
  position = DEFAULT_NODE_POSITION,
  name = DEFAULT_UNTITLED_NODE_NAME,
  id = uuidV4(),
}: GetDefaultCustomConnectionNodeArgsT): CustomConnectionNode => {
  return {
    id,
    data: {
      label: name,
      providerResource,
      connectionId,
      resourceConfigId,
      mediaKey,
      ...optionals,
    },
    position,
    type: NODE_TYPE.CUSTOM_CONNECTION_NODE,
  };
};

type GetDefaultInboundWebhookConnectionNodeArgsT = GetDefaultNodeArgsT & {
  providerResource: InboundWebhookProviderResourceT;
  connectionId: string;
  resourceConfigId: string;
  mediaKey: string | null;
  optionals?: InboundWebhookConnectionForm;
};
export const getDefaultInboundWebhookConnectionNode = ({
  providerResource,
  connectionId,
  resourceConfigId,
  mediaKey,
  optionals = {
    correlation_id: { id: uuidV4(), expression: "" },
    response: { id: uuidV4(), mapped_to: "", active: true },
    config: {
      caching: {
        active: false,
        max_age_seconds: "86400",
      },
      environments_config: getDefaultEnvironmentsConfig(),
    },
  },
  position = DEFAULT_NODE_POSITION,
  name = DEFAULT_UNTITLED_NODE_NAME,
  id = uuidV4(),
}: GetDefaultInboundWebhookConnectionNodeArgsT): InboundWebhookConnectionNode => ({
  id,
  data: {
    label: name,
    providerResource,
    connectionId,
    resourceConfigId,
    mediaKey,
    ...optionals,
  },
  position,
  type: NODE_TYPE.WEBHOOK_CONNECTION_NODE,
});

type GetDefaultDatabaseConnectionNodeArgsT = GetDefaultNodeArgsT & {
  providerResource: DatabaseProviderResourceT;
  connectionId: string;
  resourceConfigId: string;
  optionals?: DatabaseConnectionForm;
};
export const getDefaultDatabaseConnectionNode = ({
  providerResource,
  connectionId,
  resourceConfigId,
  optionals = {
    variables: [
      {
        id_key: uuidV4(),
        id_expression: uuidV4(),
        key: "",
        expression: "",
        id_type: uuidV4(),
        type_: null,
      },
    ],
    query: { id: uuidV4(), expression: "" },
    response: {
      default: { id: uuidV4(), mapped_to: "", active: true },
      num_records_affected: { id: uuidV4(), mapped_to: "", active: false },
    },
    config: {
      environments_config: getDefaultEnvironmentsConfig(),
      timeout: {
        active: false,
        timeout_seconds: "15",
      },
      error: {
        allow_client_errors: false,
        allow_server_errors: false,
      },
    },
  },
  position = DEFAULT_NODE_POSITION,
  name = DEFAULT_UNTITLED_NODE_NAME,
  id = uuidV4(),
}: GetDefaultDatabaseConnectionNodeArgsT): DatabaseConnectionNode => ({
  id,
  data: {
    label: name,
    providerResource,
    connectionId,
    resourceConfigId,
    ...optionals,
  },
  position,
  type: NODE_TYPE.SQL_DATABASE_CONNECTION_NODE,
});

type GetDefaultFlowNodeArgs = GetDefaultNodeArgsT &
  Partial<{
    inputMappings: FlowNodeMeta["input_mappings"];
    outputMappings: FlowNodeMeta["output_mappings"];
    childFlowVersionId: string;
    childFlowId: string;
    childFlowSlug: string;
    childFlowVersionKnownEtag: string;
    isStale: boolean;
  }>;

export const getDefaultFlowNode = ({
  id = uuidV4(),
  name = DEFAULT_UNTITLED_NODE_NAME,
  position = DEFAULT_NODE_POSITION,
  inputMappings = {},
  outputMappings = {},
  isStale = false,
  childFlowVersionKnownEtag,
  childFlowVersionId,
  childFlowId,
  childFlowSlug,
}: GetDefaultFlowNodeArgs): FlowNode => {
  return {
    id,
    data: {
      label: name,
      child_flow_version_id: childFlowVersionId,
      child_flow_version_known_etag: childFlowVersionKnownEtag,
      is_stale: isStale,
      child_flow_id: childFlowId,
      child_flow_slug: childFlowSlug,
      input_mappings: inputMappings,
      output_mappings: outputMappings,
    },
    position,
    type: NODE_TYPE.FLOW_NODE,
    width: DEFAULT_NODE_WIDTH,
    height: DEFAULT_NODE_HEIGHT,
  };
};

type GetDefaultLoopNodeArgs = GetDefaultNodeArgsT & Partial<LoopNodeDataT>;

export const getDefaultLoopNode = ({
  id = uuidV4(),
  name = DEFAULT_UNTITLED_NODE_NAME,
  position = DEFAULT_NODE_POSITION,
  input_mappings = {},
  child_flow_version_known_etag,
  child_flow_version_id,
  child_flow_id,
  child_flow_slug,
  break_condition = {
    clauses: [],
    enabled: false,
    junction: Junctions.AND,
    id: uuidV4(),
  },
  target_list_expression = {
    id: uuidV4(),
    value: "",
  },
  output_destination_list_expression = {
    id: uuidV4(),
    value: "",
  },
}: GetDefaultLoopNodeArgs): LoopNode => {
  return {
    id,
    data: {
      label: name,
      child_flow_version_id: child_flow_version_id,
      child_flow_version_known_etag: child_flow_version_known_etag,
      child_flow_id: child_flow_id,
      child_flow_slug: child_flow_slug,
      input_mappings: input_mappings,
      break_condition: break_condition,
      target_list_expression,
      output_destination_list_expression,
    },
    position,
    type: NODE_TYPE.LOOP_NODE,
    width: DEFAULT_NODE_WIDTH,
    height: DEFAULT_NODE_HEIGHT,
  };
};

export const getDefaultManualReviewNode = ({
  id = uuidV4(),
  name = DEFAULT_UNTITLED_NODE_NAME,
  position = DEFAULT_NODE_POSITION,
  highlights = [],
  response_form = { description: "", fields: [] },
  providerResource,
  connection_id,
  resource_config_id,
  response_metadata_key,
  reviewer_assign_strategy,
  reviewer_assign_strategy_v2,
}: GetDefaultNodeArgsT &
  Omit<
    ManualReviewNodeMeta,
    "created_by_id" | "updated_by_id" | "provider_resource"
  > & {
    providerResource: ManualReviewProviderResourceT;
  }): ManualReviewNode => {
  return {
    id,
    data: {
      label: name,
      highlights: highlights,
      response_form,
      providerResource,
      connection_id,
      resource_config_id,
      response_metadata_key,
      reviewer_assign_strategy,
      reviewer_assign_strategy_v2,
    },
    position,
    type: NODE_TYPE.REVIEW_CONNECTION_NODE,
    width: DEFAULT_NODE_WIDTH,
    height: DEFAULT_NODE_HEIGHT,
  };
};

type GetDefaultEdgeArgsT = { sourceId: string; targetId: string; id?: string };

export const getDefaultFlowEdge = ({
  sourceId,
  targetId,
  id = uuidV4(),
}: GetDefaultEdgeArgsT): Edge => ({
  id,
  source: sourceId,
  target: targetId,
  type: EDGE_TYPE.FLOW_EDGE,
  markerEnd: edgeMarkerEnd,
});

export const getDefaultGroupEdge = ({
  sourceId,
  targetId,
  id = uuidV4(),
  originalEdgeId,
  insideGroup,
}: GetDefaultEdgeArgsT & {
  // To be able to properly add nodes at group edges, they are required to have fixed ids.
  id: string;
  originalEdgeId: string;
  insideGroup: boolean;
}): Edge => ({
  id,
  source: sourceId,
  target: targetId,
  data: {
    originalEdgeId,
    insideGroup,
  },
  // For now group edges exactly look like flow edges and they almost exactly behave that way except that if they get clicked the originalEdgeId gets passed to setClickedEdgeID, which is why for now we keep this as one edge type
  type: EDGE_TYPE.GROUP_EDGE,
  markerEnd: edgeMarkerEnd,
});

export const getDefaultSplitEdge = ({
  sourceId,
  targetId,
  id = uuidV4(),
}: GetDefaultEdgeArgsT): Edge => ({
  id,
  source: sourceId,
  target: targetId,
  type: EDGE_TYPE.SPLIT_EDGE,
  markerEnd: edgeMarkerEnd,
});
