import {
  faCheck,
  faClock,
  faExclamationCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { groupBy } from "lodash";
import { twJoin } from "tailwind-merge";

import { useOrganizationUser } from "src/api/taktile/queries";
import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/base-components/Tooltip";
import {
  UserAvatar,
  BorderColor,
  UserAvatarLoading,
} from "src/base-components/UserAvatar/UserAvatar";
import {
  ReviewDb,
  ReviewReviewerDb,
  ReviewerReviewStatus,
} from "src/clients/flow-api";
import { OrganizationUser } from "src/clients/taktile-api";
import { DashboardPageParamsT } from "src/router/urls";
import { sentanceList } from "src/utils/stringUtils";
import { assertUnreachable } from "src/utils/typeUtils";
import { useParamsDecode } from "src/utils/useParamsDecode";
import { getUserName } from "src/utils/user";

const iconForStatus = (status: ReviewerReviewStatus) => {
  const icon = (status: ReviewerReviewStatus) => {
    switch (status) {
      case ReviewerReviewStatus.PENDING:
      case ReviewerReviewStatus.COMMENTED:
        return faClock;
      case ReviewerReviewStatus.APPROVED:
        return faCheck;
      case ReviewerReviewStatus.REQUESTED_CHANGES:
        return faExclamationCircle;
      default:
        return assertUnreachable(status);
    }
  };

  const color = (status: ReviewerReviewStatus) => {
    switch (status) {
      case ReviewerReviewStatus.PENDING:
      case ReviewerReviewStatus.COMMENTED:
        return "text-indigo-400";
      case ReviewerReviewStatus.APPROVED:
        return "text-green-400";
      case ReviewerReviewStatus.REQUESTED_CHANGES:
        return "text-yellow-400";
      default:
        return assertUnreachable(status);
    }
  };

  return <Icon color={color(status)} icon={icon(status)} size="xs" />;
};

const flavourForStatus = (status: ReviewerReviewStatus): string => {
  switch (status) {
    case ReviewerReviewStatus.PENDING:
    case ReviewerReviewStatus.COMMENTED:
      return "Pending review from";
    case ReviewerReviewStatus.APPROVED:
      return "Approved by";
    case ReviewerReviewStatus.REQUESTED_CHANGES:
      return "Changes requested by";
    default:
      return assertUnreachable(status);
  }
};

export const ReviewerAvatar: React.FC<{
  orgId: string;
  userId: string;
  borderColor?: BorderColor;
}> = ({ orgId, userId, borderColor }) => {
  const workspaceUser = useOrganizationUser(orgId, userId);
  return (
    <LoadingView
      queryResult={workspaceUser}
      renderErrored={() => <></>}
      renderUpdated={(user: OrganizationUser) => {
        return (
          <div className="inline-block pr-1">
            <UserAvatar borderColor={borderColor} size="xs" user={user!} />
          </div>
        );
      }}
      renderUpdating={() => (
        <div className="inline-block pr-1">
          <UserAvatarLoading size="sm" />
        </div>
      )}
    />
  );
};

export const UserTip: React.FC<{
  orgId: string;
  userId: string;
}> = ({ orgId, userId }) => {
  const { data: user } = useOrganizationUser(orgId, userId);
  return (
    <span>
      <span className="relative top-1">
        <ReviewerAvatar orgId={orgId} userId={userId} />
      </span>

      <b className="font-inter-medium-12px">{user && getUserName(user)}</b>
    </span>
  );
};

type ReviewProcessPillProps = {
  orgId: string;
  reviewers: ReviewReviewerDb[];
  status: ReviewerReviewStatus;
};

export const ReviewProcessPill: React.FC<ReviewProcessPillProps> = ({
  orgId,
  reviewers,
  status,
}) => {
  return (
    <Tooltip
      body={
        <div className="flex">
          <div className="w-6">{iconForStatus(status)}</div>
          <div className="w-60">
            <span className="pr-1">{flavourForStatus(status)}</span>
            {sentanceList(
              reviewers.map((reviewer) => (
                <UserTip
                  key={reviewer.reviewer_id}
                  orgId={orgId}
                  userId={reviewer.reviewer_id}
                />
              )),
            )}
          </div>
        </div>
      }
      placement="bottom"
      title={undefined}
    >
      <div className="-ml-2">
        <Pill variant="outlined-white">
          {iconForStatus(status)}
          {reviewers.map((reviewer, i) => (
            <div
              key={reviewer.reviewer_id}
              className={twJoin(["relative top-0.5", i > 0 ? "-ml-2" : ""])}
            >
              <ReviewerAvatar
                borderColor="border-white"
                orgId={orgId}
                userId={reviewer.reviewer_id}
              />
            </div>
          ))}
        </Pill>
      </div>
    </Tooltip>
  );
};

type ReviewProcessPillsProps = {
  review: ReviewDb;
};

export const ReviewProcessPills: React.FC<ReviewProcessPillsProps> = ({
  review,
}) => {
  const { orgId } = useParamsDecode<DashboardPageParamsT>();

  return (
    <>
      {Object.entries(
        groupBy(
          review.reviewers_status,
          (reviewer) => reviewer.reviewer_status,
        ),
      ).map(([status, reviewers]) => (
        <ReviewProcessPill
          key={status}
          orgId={orgId}
          reviewers={reviewers}
          status={status as ReviewerReviewStatus}
        />
      ))}
    </>
  );
};
