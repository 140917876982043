import { BeMappedNode } from "src/constants/NodeDataTypes";
import {
  getBaseUrl,
  getUrlToAuthoringPage,
  getUrlToHistoricDecisionTrace,
} from "src/router/urls";
import { isParentNode } from "src/utils/predicates";
import { useHistorySelectedDecision } from "src/versionDecisionHistory/historySelectedDecisionStore";
import { useDecisionHistoryPaneEnv } from "src/versionDecisionHistory/useDecisionHistoryPaneEnv";

export const useGetChildVersionLink = () => {
  const decision = useHistorySelectedDecision();
  const { selectedEnv: decisionEnv } = useDecisionHistoryPaneEnv();
  return ({
    orgId,
    workspaceId,
    flowId,
    versionId,
  }: {
    orgId: string;
    workspaceId: string;
    flowId: string;
    versionId: string;
  }) => {
    if (decision === null) {
      return (
        getBaseUrl() +
        getUrlToAuthoringPage(orgId, workspaceId, flowId, versionId)
      );
    }
    return getUrlToHistoricDecisionTrace({
      orgId,
      wsId: workspaceId,
      decisionId: decision?.id,
      decisionEnv: decisionEnv,
      flowId: flowId,
      versionId: versionId,
    });
  };
};

export const useChildVersionLinkFromNode = (
  selectedNode: BeMappedNode,
  orgId: string,
  wsId: string,
) => {
  const getChildFlowLink = useGetChildVersionLink();

  if (!isParentNode(selectedNode)) return undefined;
  if (
    !(
      selectedNode.data.child_flow_id && selectedNode.data.child_flow_version_id
    )
  )
    return undefined;
  return getChildFlowLink({
    orgId,
    workspaceId: wsId,
    flowId: selectedNode.data.child_flow_id,
    versionId: selectedNode.data.child_flow_version_id,
  });
};
