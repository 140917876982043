// Quick and dirty random string saved in the sessionStorage.
// Thereby it is constant in a tab session, persisting through page reloads.
// It is used to lock forms against concurrent writes from other tabs by the same user.
// https://stackoverflow.com/a/8084248
// https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage

const SESSION_ID_KEY = "sessionId" as const;

const getSessionId = () => {
  const currentId = sessionStorage.getItem(SESSION_ID_KEY);
  if (currentId) {
    return currentId;
  } else {
    const newSessionId = (Math.random() + 1).toString(36).substring(7);
    sessionStorage.setItem(SESSION_ID_KEY, newSessionId);
    return newSessionId;
  }
};

export const CURRENT_SESSION_ID = getSessionId();
