import { CellContext, HeaderContext } from "@tanstack/react-table";
import { Dispatch, memo, useCallback, useEffect, useMemo, useRef } from "react";
import { twJoin } from "tailwind-merge";

import { TableCellWrapper } from "src/base-components/EditorTable/TableCellWrapper";
import { HEADER_ROW_ID } from "src/base-components/EditorTable/hooks/useSelectionHandler";
import {
  useIsRowHovered,
  useIsColHovered,
  useCellIsEditing,
  useCellIsSelected,
  useTableNavActions,
  useCellIsReadOnly,
  useCellError,
} from "src/base-components/EditorTable/stores";
import {
  EditorCellProps,
  EditorColumnDef,
  RowBase,
} from "src/base-components/EditorTable/types";
import { coordinateToKey } from "src/base-components/EditorTable/utils";
import { DecisionTableAction } from "src/decisionTableNode/useDecisionTableActionsReducer";

type Props<T extends RowBase<T>, V> = {
  columnDef: EditorColumnDef<T>;
  renderer: React.FC<EditorCellProps<T, V>>;
  value: V;
  dispatch?: Dispatch<DecisionTableAction>;
  dataLoc?: string;
  readonly: boolean;
  resizable?: boolean;
  isResizing?: boolean;
  width?: number;
  resizeHandler?: (event: unknown) => void;
  context: CellContext<T, V> | HeaderContext<T, V>;
  onHeaderChange?: (key: keyof T, value: V) => void;
};

const TableCellComp = <T extends RowBase<T>, V>({
  columnDef,
  value,
  renderer: RendererComp,
  context,
  dataLoc,
  readonly: readonlyProp,
  resizable,
  resizeHandler,
  isResizing,
  width,
  onHeaderChange,
}: Props<T, V>): JSX.Element => {
  const ref = useRef<HTMLTableCellElement>(null);
  const column = context.column.columnDef.meta?.customColumn;
  const contextRef = useRef(context);
  contextRef.current = context;

  const { onChange, dispatch } = context.table.options.meta || {};

  const isHighlighted = Boolean(column?.isHighlighted);
  const isLegend = Boolean(column?.isLegend);

  const row = "row" in context ? context.row.id : HEADER_ROW_ID;
  const col = columnDef.key;
  const cellId = coordinateToKey(row, col);

  const { setHoveredCellIndex } = useTableNavActions();
  const isRowHovered = useIsRowHovered(row);
  const isColumnHovered = useIsColHovered(col);
  const isSelected = useCellIsSelected(cellId);
  const isEditing = useCellIsEditing(cellId);
  const { setEditingCell, setSelectedCell } = useTableNavActions();
  const isReadonly = useCellIsReadOnly(cellId);
  const error = useCellError(cellId);

  const readonly = readonlyProp || isReadonly;
  const state = useMemo(
    () => ({
      isRowHovered,
      readonly,
      isColumnHovered,
      isEditing,
      isSelected,
      isHighlighted,
    }),
    [
      isRowHovered,
      readonly,
      isColumnHovered,
      isEditing,
      isSelected,
      isHighlighted,
    ],
  );

  const onChangeHandler = useCallback(
    (value: V) => {
      const currentContext = contextRef.current;
      if (onHeaderChange) {
        onHeaderChange(columnDef.key, value);
      } else if ("row" in currentContext && onChange) {
        onChange(currentContext.row.index, {
          ...currentContext.row.original,
          [columnDef.key]: value,
        });
      }
    },
    [onHeaderChange, onChange, columnDef.key],
  );

  useEffect(() => {
    if (ref.current && isSelected) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
        block: "nearest",
      });
    }
  }, [isSelected]);

  const rowSpan =
    columnDef.isCommon && "row" in context && context.row.depth === 0
      ? context.row.subRows.length + 1
      : 1;

  if (columnDef.isCommon && "row" in context && context.row.depth > 0) {
    return <></>;
  }

  return (
    <td
      ref={ref}
      className={twJoin(
        "relative h-10 overflow-visible border text-xs",
        readonly && "cursor-not-allowed bg-gray-100",
        isLegend
          ? "bg-gray-50 text-gray-800 font-inter-normal-12px"
          : "font-mono text-indigo-600",
        isHighlighted ? "border-green-100 bg-green-50" : "border-gray-100",
        !isLegend && !isHighlighted && !readonly && "bg-white",
        row === HEADER_ROW_ID && "border-y-0",
      )}
      rowSpan={rowSpan}
      style={{
        width,
      }}
      onClick={(e) => {
        if (e.isDefaultPrevented()) {
          setSelectedCell(cellId);
        } else {
          setEditingCell(cellId);
        }
      }}
      onMouseOver={() => setHoveredCellIndex(row, col)}
    >
      <TableCellWrapper
        className={twJoin(
          !isSelected && error && "outline outline-red-600",
          isSelected && "outline outline-indigo-600",
        )}
        dataLoc={dataLoc}
        error={error}
      >
        <RendererComp
          cellId={cellId}
          cellRef={ref}
          columnDef={columnDef}
          context={context}
          dispatch={dispatch}
          state={state}
          value={value}
          onChange={onChangeHandler}
        />
        {resizable && (
          <div
            className={twJoin(
              "transform-colors absolute -bottom-px -top-px right-0 w-1.5 translate-x-1/2",
              "cursor-col-resize select-none bg-indigo-500 opacity-0 duration-150 hover:opacity-100",
              isResizing && "opacity-100",
            )}
            onMouseDown={resizeHandler}
            onTouchStart={resizeHandler}
          />
        )}
      </TableCellWrapper>
    </td>
  );
};

export const TableCell = memo(TableCellComp) as typeof TableCellComp;
