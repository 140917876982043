import {
  faCircleNotch,
  faEdit,
  faPlugCircleBolt,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/base-components/Tooltip";
import { EventAttributeEnv, EventFilter, Webhook } from "src/clients/flow-api";
import { RawCell } from "src/dataTable/RawCell";
import { EndpointDetails } from "src/webhooks/WebhooksTable/EndpointDetails";
import { webhookTriggerNameMap } from "src/webhooks/queries";

export const cellClassNames = "text-gray-800 px-6 py-4";

const HeaderCell: React.FC<{ content: string }> = ({ content }) => (
  <div className="px-6 py-4 text-left text-gray-800 font-inter-medium-12px">
    {content}
  </div>
);

const includesSandboxEvent = (events: EventFilter[]) =>
  Boolean(
    events.find((event) => {
      return event.condition.decision_env?.includes(EventAttributeEnv.SANDBOX);
    }),
  );

const includesProdEvent = (events: EventFilter[]) =>
  Boolean(
    events.find((event) => {
      return event.condition.decision_env?.includes(EventAttributeEnv.LIVE);
    }),
  );

const getListOfTriggersForWebhook = (webhook: Webhook) => {
  const triggers = new Set(
    webhook.pattern.map((p) => webhookTriggerNameMap[p.type]),
  );
  const formatter = new Intl.ListFormat("en", {
    style: "narrow",
    type: "conjunction",
  });

  return formatter.format(triggers);
};

export const getOutgoingWebhooksColumns = (
  onDelete: (toEdit: Webhook) => void,
  onEdit: (toEdit: Webhook) => void,
  onTest: (webhookKey: string) => Promise<void>,
  webhookKeyBeingTested: string | undefined,
): ColumnDef<Webhook, string>[] => [
  {
    id: "name",
    header: () => <HeaderCell content="Webhooks" />,
    accessorFn: (row) => row.name,
    cell: ({ cell }) => (
      <RawCell<Webhook>
        cell={cell}
        className={`${cellClassNames} h-full border-l-4 border-l-transparent font-inter-semibold-13px group-[.is-selected]:border-l-indigo-400`}
      />
    ),
  },
  {
    id: "url",
    header: () => <HeaderCell content="Endpoint URL" />,
    cell: ({ row: { original } }) => <EndpointDetails webhook={original} />,
  },
  {
    id: "events",
    header: () => <HeaderCell content="Events" />,
    cell: ({ row: { original } }) => (
      <div className={twJoin(cellClassNames, "font-inter-normal-12px")}>
        {getListOfTriggersForWebhook(original)}
      </div>
    ),
  },
  {
    id: "actions",
    header: () => <HeaderCell content="" />,
    cell: ({ row }) => (
      <div
        className={`${cellClassNames} flex items-center justify-end gap-x-1.5`}
      >
        {includesProdEvent(row.original.pattern) && (
          <Pill dataLoc="webhook-live" variant="outlined-white">
            <Pill.Text>Live</Pill.Text>
          </Pill>
        )}
        {includesSandboxEvent(row.original.pattern) && (
          <Pill dataLoc="webhook-sandbox" variant="outlined-white">
            <Pill.Text>Sandbox</Pill.Text>
          </Pill>
        )}
        {row.original.active ? (
          <Pill dataLoc="webhook-active" variant="green">
            <Pill.Icon icon={faCircle} />
            <Pill.Text>Active</Pill.Text>
          </Pill>
        ) : (
          <Pill dataLoc="webhook-inactive" variant="gray">
            <Pill.Text>Inactive</Pill.Text>
          </Pill>
        )}
        {webhookKeyBeingTested === row.original.key ? (
          <Icon color="text-gray-500" icon={faCircleNotch} size="xs" spin />
        ) : (
          <Tooltip placement="top" title="Test" asChild>
            <Icon
              color="text-gray-500"
              dataLoc="webhook-test"
              icon={faPlugCircleBolt}
              size="xs"
              onClick={(e) => {
                onTest(row.original.key);
                e.stopPropagation();
              }}
            />
          </Tooltip>
        )}
        <Tooltip placement="top" title="Edit" asChild>
          <Icon
            color="text-gray-500"
            dataLoc="webhook-edit"
            icon={faEdit}
            size="xs"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(row.original);
            }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Delete" asChild>
          <Icon
            color="text-gray-500"
            dataLoc="webhook-delete"
            icon={faTrashCan}
            size="xs"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(row.original);
            }}
          />
        </Tooltip>
      </div>
    ),
  },
];
