import { faBoxArchive, faCheck } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import { useInterval } from "usehooks-ts";

import { FlowVersionT } from "src/api/flowTypes";
import { Pill } from "src/base-components/Pill";
import {
  etag_including_comments,
  useLoadChangeHistory,
} from "src/changeHistory/queries";
import { FlowVersionStatus } from "src/clients/flow-api";
import { UserNameInfo } from "src/flowContainer/versionEditedInfo/UsernameInfo";
import { useCurrentUserId } from "src/store/AuthStore";
import { dateFromNow } from "src/utils/datetime";
import { assertUnreachable } from "src/utils/typeUtils";

type Props = { flowVersion: FlowVersionT };

const getStatusPrefix = (status: FlowVersionStatus) => {
  switch (status) {
    case FlowVersionStatus.DRAFT:
      return "Updated";
    case FlowVersionStatus.PUBLISHED:
      return "Published";
    case FlowVersionStatus.ARCHIVED:
      return "Archived";
    default:
      assertUnreachable(status);
  }
};

const getPillColor = (status: FlowVersionStatus) => {
  switch (status) {
    case FlowVersionStatus.DRAFT:
      return "indigo-light";
    case FlowVersionStatus.PUBLISHED:
      return "green";
    case FlowVersionStatus.ARCHIVED:
      return "gray";
    default:
      assertUnreachable(status);
  }
};

const UpdatedByInfo = ({ updatedBy }: { updatedBy?: string }) => {
  const userId = useCurrentUserId();

  if (updatedBy === undefined) return null;
  if (userId === updatedBy) return <> by you</>;
  return (
    <>
      by <UserNameInfo userId={updatedBy} />
    </>
  );
};

export const LastUpdatedInfo: React.FC<Props> = ({ flowVersion }) => {
  const changeHistory = useLoadChangeHistory(
    flowVersion.id,
    etag_including_comments(flowVersion.etag),
  );
  const latestChange = changeHistory.data?.pages[0][0];
  const [updatedTimeFromNow, setUpdatedTimeFromNow] = useState<
    string | undefined
  >(latestChange ? dateFromNow(latestChange?.updated_at) : undefined);
  // Because dateFromNow changes even when no props change, we need to update it in a regular interval
  useInterval(() => {
    setUpdatedTimeFromNow(
      latestChange ? dateFromNow(latestChange?.updated_at) : undefined,
    );
  }, 30 * 1_000);

  useEffect(() => {
    setUpdatedTimeFromNow(
      latestChange ? dateFromNow(latestChange?.updated_at) : undefined,
    );
  }, [latestChange]);

  return (
    <Pill
      dataLoc="last-updated-info"
      variant={getPillColor(flowVersion.status)}
    >
      <Pill.Icon
        icon={
          flowVersion.status === FlowVersionStatus.ARCHIVED
            ? faBoxArchive
            : faCheck
        }
      />
      <Pill.Text fontType="text">
        {getStatusPrefix(flowVersion.status)} {updatedTimeFromNow}{" "}
        <UpdatedByInfo updatedBy={latestChange?.authored_by} />
      </Pill.Text>
    </Pill>
  );
};
