import React from "react";
import colors from "tailwindcss/colors";

import { NodeTestRunResult } from "src/api/types";

type WorkingData = {
  value: number;
  colorHexCode: string;
  start_value?: number;
  end_value?: number;
  start_degrees?: number;
  end_degrees?: number;
};

type Data = { value: number; colorHexCode: string };

const toCssString = (data: WorkingData[]): string => {
  const total_value = data.reduce((a, b) => a + b.value, 0);
  const convertToDegrees = (num: number) => {
    return Math.round((num / total_value) * 360);
  };

  //calculate start and end degrees
  data.reduce<WorkingData[]>((items, item, index, array) => {
    items.push({ ...item });
    item.start_value = array[index - 1]?.end_value
      ? array[index - 1].end_value
      : 0;
    item.end_value = item.start_value!! + item.value;
    item.start_degrees = convertToDegrees(item.start_value!!);
    item.end_degrees = convertToDegrees(item.end_value);

    return items;
  }, []);

  return data
    .map((chart) => {
      const { colorHexCode, start_degrees, end_degrees } = chart;
      return ` ${colorHexCode} ${start_degrees || 0}deg ${end_degrees || 0}deg`;
    })
    .join();
};

export const ResultOverviewDonut: React.FC<{
  nodeTestResult: NodeTestRunResult;
}> = ({ nodeTestResult }) => {
  const fixedMismatchedCount =
    nodeTestResult.expected_output_mismatch_count ?? 0;

  const data: Data[] = [
    {
      value: nodeTestResult.success_count - fixedMismatchedCount,
      colorHexCode: colors.green[400],
    },
    {
      value: nodeTestResult.failure_count,
      colorHexCode: colors.red[500],
    },
    {
      value: nodeTestResult.ignored_count,
      colorHexCode: colors.gray[200],
    },
    {
      value: fixedMismatchedCount,
      colorHexCode: colors.yellow[400],
    },
  ];

  const css_string = toCssString(data);
  return (
    <div className="ml-2 h-4 w-4" data-loc="test-run-overview">
      <svg style={{ borderRadius: "100%" }} viewBox="0 0 100 100">
        <foreignObject height="100" width="100" x="0" y="0">
          <div
            className="h-full w-full"
            style={{
              background: `conic-gradient(${css_string})`,
            }}
          />
        </foreignObject>
        <circle cx="50" cy="50" fill="white" r="36" />
      </svg>
    </div>
  );
};
