import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { get } from "lodash";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Icon } from "src/base-components/Icon";
import { SchemaField } from "src/parentFlowNodes/SchemaField";
import { FlowNodeForm } from "src/parentFlowNodes/flowNode/types";
import { LoopNodeForm } from "src/parentFlowNodes/loopNode/LoopNodeEditor";
import { buildFieldNameToIdMap } from "src/parentFlowNodes/utils";
import { PropertyUIT } from "src/schema/utils";

type SchemaPropertyProps = {
  field: PropertyUIT;
  isOutput: boolean;
  fieldError: boolean;
  readonly: boolean;
  shouldDisplayError: boolean;
  schemaTabUrl: string;
  isLoopNode?: boolean;
};

export const SCHEMA_MAPPING_ARROW_WIDTH = "min-w-[20px]" as const;

export const SchemaProperty: React.FC<SchemaPropertyProps> = ({
  field,
  isOutput,
  fieldError,
  readonly,
  shouldDisplayError,
  schemaTabUrl,
  isLoopNode = false,
}) => {
  const { control, watch, formState } = useFormContext<
    FlowNodeForm | LoopNodeForm
  >();

  const fieldPrefix = isOutput ? "output_mappings" : "input_mappings";
  const values = watch(fieldPrefix);
  const fieldNameToId = buildFieldNameToIdMap(values, isOutput);
  const [fieldId, fieldSuffix] = [
    fieldNameToId[field.fieldName],
    isOutput ? "destination" : "source",
  ];

  const fieldName = `${fieldPrefix}.${fieldId}.${fieldSuffix}` as const;
  const baseDataLoc = `child-flow-version-${isOutput ? "output" : "input"}`;
  const inputDataLoc = `${baseDataLoc}-mapping-${field.fieldName}`;
  const schemaDataLoc = `${baseDataLoc}-schema-${field.fieldName}`;

  return (
    <div
      className={twJoin(
        "flex items-center gap-x-3",
        isOutput && "flex-row-reverse",
      )}
    >
      <div className="w-1/2 min-w-0">
        <Controller
          control={control}
          name={fieldName}
          render={({ field }) => (
            <AutocompleteCodeInput
              dataLoc={inputDataLoc}
              disabled={readonly}
              error={
                get(formState.touchedFields, fieldName) || shouldDisplayError
                  ? fieldError
                  : undefined
              }
              isFieldInLoopIteration={isLoopNode}
              placeholder={
                isOutput ? "field" : isLoopNode ? "item.field" : "data.field"
              }
              prefix={isOutput ? "data." : undefined}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </div>
      <div className={SCHEMA_MAPPING_ARROW_WIDTH}>
        <Icon color="text-gray-500" icon={faArrowRight} size="xs" />
      </div>
      <div className="w-1/2 min-w-0">
        <SchemaField
          dataLoc={schemaDataLoc}
          name={field.fieldName}
          nullable={field.type[1] === "null"}
          required={field.required}
          schemaTabUrl={schemaTabUrl}
          sensitive={field.sensitive}
          type={field.type[0] || "string"}
        />
      </div>
    </div>
  );
};
