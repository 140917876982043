export const pathJoin = (...parts: string[]) => {
  const separator = "/";
  parts = parts.filter((part) => !!part);
  parts = parts.map((part, index) => {
    if (index) {
      part = part.replace(new RegExp("^" + separator), "");
    }
    if (index !== parts.length - 1) {
      part = part.replace(new RegExp(separator + "$"), "");
    }
    return part;
  });
  return parts.join(separator);
};
