/**
 * All the node types we have in the UI and that can be rendered with react-flow
 * Note: They have an N:1 mapping to the NodeTypes mapping from flow-api
 * @enum
 */
export enum NODE_TYPE {
  INPUT_NODE = "input_node",
  CODE_NODE = "code_node",
  OUTPUT_NODE = "output_node",
  SPLIT_BRANCH_NODE_V2 = "split_branch_node_v2",
  SPLIT_MERGE_NODE = "split_merge_node",
  INTEGRATION_NODE = "data_integration_node",
  SPLIT_NODE_V2 = "split_node_v2",
  RULE_NODE_V2 = "rule_node_v2",
  ML_NODE = "ml_node",
  MANIFEST_CONNECTION_NODE = "manifest_connection_node",
  CUSTOM_CONNECTION_NODE = "custom_connection_node",
  WEBHOOK_CONNECTION_NODE = "webhook_connection_node",
  SQL_DATABASE_CONNECTION_NODE = "sql_database_connection_node",
  DECISION_TABLE_NODE = "decision_table_node",
  ASSIGNMENT_NODE = "assignment_node",
  SCORECARD_NODE = "scorecard_node",
  GROUP_NODE = "group_node",
  GROUP_PRE_SEPARATOR_NODE = "group_pre_separator_node",
  GROUP_POST_SEPARATOR_NODE = "group_post_separator_node",
  FLOW_NODE = "flow_node",
  REVIEW_CONNECTION_NODE = "review_connection_node",
  LOOP_NODE = "loop_node",
}

export const BE_MAPPED_NODE_TYPES = [
  NODE_TYPE.INPUT_NODE,
  NODE_TYPE.CODE_NODE,
  NODE_TYPE.OUTPUT_NODE,
  NODE_TYPE.SPLIT_BRANCH_NODE_V2,
  NODE_TYPE.SPLIT_MERGE_NODE,
  NODE_TYPE.INTEGRATION_NODE,
  NODE_TYPE.MANIFEST_CONNECTION_NODE,
  NODE_TYPE.SPLIT_NODE_V2,
  NODE_TYPE.RULE_NODE_V2,
  NODE_TYPE.ML_NODE,
  NODE_TYPE.CUSTOM_CONNECTION_NODE,
  NODE_TYPE.WEBHOOK_CONNECTION_NODE,
  NODE_TYPE.SQL_DATABASE_CONNECTION_NODE,
  NODE_TYPE.DECISION_TABLE_NODE,
  NODE_TYPE.ASSIGNMENT_NODE,
  NODE_TYPE.SCORECARD_NODE,
  NODE_TYPE.FLOW_NODE,
  NODE_TYPE.REVIEW_CONNECTION_NODE,
  NODE_TYPE.LOOP_NODE,
] as const;

export type BeMappedNodeType = (typeof BE_MAPPED_NODE_TYPES)[number];

const UI_ONLY_NODETYPES = [
  NODE_TYPE.GROUP_NODE,
  NODE_TYPE.GROUP_PRE_SEPARATOR_NODE,
  NODE_TYPE.GROUP_POST_SEPARATOR_NODE,
] as const;
export type UiOnlyNodeType = (typeof UI_ONLY_NODETYPES)[number];

/**
 * All the node types that are directly creatable by the user except the connect nodes
 */
export const SIMPLE_CREATABLE_NODE_TYPES = [
  NODE_TYPE.ASSIGNMENT_NODE,
  NODE_TYPE.SPLIT_NODE_V2,
  NODE_TYPE.RULE_NODE_V2,
  NODE_TYPE.SCORECARD_NODE,
  NODE_TYPE.DECISION_TABLE_NODE,
  NODE_TYPE.ML_NODE,
  NODE_TYPE.CODE_NODE,
  NODE_TYPE.FLOW_NODE,
  NODE_TYPE.LOOP_NODE,
] as const;

export type SimpleCreatableNodeTypeT =
  (typeof SIMPLE_CREATABLE_NODE_TYPES)[number];

/**
 * All the node types that are currently deactivated or hidden behind a feature flag
 */
export const INACTIVE_NODE_TYPES: SimpleCreatableNodeTypeT[] = (() => {
  const inactiveNodes: SimpleCreatableNodeTypeT[] = [];
  return inactiveNodes;
})();

/**
 * All the node types that are shown on the graph
 */
export const NAMED_NODE_TYPES = [
  NODE_TYPE.INPUT_NODE,
  NODE_TYPE.CODE_NODE,
  NODE_TYPE.OUTPUT_NODE,
  NODE_TYPE.INTEGRATION_NODE,
  NODE_TYPE.SPLIT_NODE_V2,
  NODE_TYPE.SPLIT_BRANCH_NODE_V2,
  NODE_TYPE.RULE_NODE_V2,
  NODE_TYPE.ML_NODE,
  NODE_TYPE.MANIFEST_CONNECTION_NODE,
  NODE_TYPE.CUSTOM_CONNECTION_NODE,
  NODE_TYPE.WEBHOOK_CONNECTION_NODE,
  NODE_TYPE.SQL_DATABASE_CONNECTION_NODE,
  NODE_TYPE.DECISION_TABLE_NODE,
  NODE_TYPE.ASSIGNMENT_NODE,
  NODE_TYPE.SCORECARD_NODE,
  NODE_TYPE.FLOW_NODE,
  NODE_TYPE.REVIEW_CONNECTION_NODE,
  NODE_TYPE.LOOP_NODE,
] as const;

export type NamedNodeTypeT = (typeof NAMED_NODE_TYPES)[number];

export const FULLSCREEN_NODE_TYPES: NODE_TYPE[] = [
  NODE_TYPE.DECISION_TABLE_NODE,
  NODE_TYPE.SCORECARD_NODE,
];

export const CONNECT_NODE_TYPES = [
  NODE_TYPE.INTEGRATION_NODE,
  NODE_TYPE.CUSTOM_CONNECTION_NODE,
  NODE_TYPE.WEBHOOK_CONNECTION_NODE,
  NODE_TYPE.SQL_DATABASE_CONNECTION_NODE,
  NODE_TYPE.REVIEW_CONNECTION_NODE,
] as const;

export type ConnectNodeTypeT = (typeof CONNECT_NODE_TYPES)[number];

/**
 * All the node types that are directly creatable by the user via the AddNodePane
 */
export const CREATABLE_NODE_TYPES = [
  ...SIMPLE_CREATABLE_NODE_TYPES,
  ...CONNECT_NODE_TYPES,
] as const;

export type CreatableNodeTypeT = (typeof CREATABLE_NODE_TYPES)[number];

/**
 * All the node types that inject a child flow into the parent flow
 */
export const PARENT_FLOW_NODE_TYPES = [
  NODE_TYPE.FLOW_NODE,
  NODE_TYPE.LOOP_NODE,
];
