import { InfiniteData } from "@tanstack/react-query";
import { useCallback, useState } from "react";

import { LoadingView } from "src/base-components/LoadingView";
import {
  CommentDb,
  CommentResourceType,
  FlowVersionStatus,
} from "src/clients/flow-api";
import { CommentFilterBar } from "src/comments/CommentFilterBar";
import { CommentList } from "src/comments/CommentList";
import {
  CommentFiltersNode,
  OnFilterChange,
  getResolved,
  getSearch,
} from "src/comments/CommentPane";
import { useComments } from "src/comments/queries";
import { BeMappedNode } from "src/constants/NodeDataTypes";
import { useFlowVersionReview } from "src/flowReview/api/queries";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { AuthorPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type Props = {
  selectedNode: BeMappedNode;
};

export const NodeComments: React.FC<Props> = ({ selectedNode }) => {
  const { version_id } = useParamsDecode<AuthorPageParamsT>();
  const { data: review } = useFlowVersionReview(version_id);
  const { version } = useAuthoringContext();
  const isDraft = version.status === FlowVersionStatus.DRAFT;

  const [filters, setFilters] = useState<CommentFiltersNode>({
    show: "open",
    filterTerm: "",
  });

  const comments = useComments(
    version_id,
    CommentResourceType.NODE,
    selectedNode.id,
    getResolved(filters),
    getSearch(filters),
  );

  const onFilterChange: OnFilterChange = useCallback(
    (filters) => {
      setFilters((oldFilters) => ({ ...oldFilters, ...filters }));
    },
    [setFilters],
  );

  return (
    <div className="flex flex-1 flex-col px-4" data-loc="node-comments">
      <CommentFilterBar filters={filters} onFilterChange={onFilterChange} />
      <LoadingView
        queryResult={comments}
        renderUpdated={(data: InfiniteData<CommentDb[]>) => (
          <CommentList
            comments={data.pages.flatMap((page) => page)}
            resourceId={selectedNode.id}
            resourceType={CommentResourceType.NODE}
            reviewId={isDraft ? review?.id : undefined}
            onLoadMore={
              comments.hasNextPage ? () => comments.fetchNextPage() : undefined
            }
          />
        )}
      />
    </div>
  );
};
