import React from "react";
import { useForm, FormProvider } from "react-hook-form";

import { FieldErrorsT } from "src/api/types";
import { RuleNodeV2, RuleNodeV2DataT } from "src/constants/NodeDataTypes";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import { AddFieldsCard } from "src/ruleNodeV2Editor/AddFieldsCard";
import { Branches } from "src/ruleNodeV2Editor/Branches";
import { DefaultValuesCard } from "src/ruleNodeV2Editor/DefaultValuesCard";
import { RuleNodeV2FormT } from "src/ruleNodeV2Editor/types";
import { convertFieldErrorsBeToFe } from "src/utils/FieldErrorUtils";
import { useSubmitForm } from "src/utils/useSubmitForm";

type PropsT = {
  selectedNode: RuleNodeV2;
} & NodeEditorBaseProps<RuleNodeV2DataT>;

export const RuleV2Editor: React.FC<PropsT> = ({
  selectedNode,
  immutable,
  onUpdate,
  isReactive,
  displayedError,
}) => {
  const formMethods = useForm<RuleNodeV2FormT>({
    defaultValues: selectedNode.data,
    ...(isReactive && { values: selectedNode.data }),
  });

  useSubmitForm({
    onChange: (newValues) => onUpdate({ newData: newValues }),
    disabled: isReactive,
    previousValues: selectedNode.data,
    watch: formMethods.watch,
  });

  const fieldErrors: FieldErrorsT | undefined = displayedError?.field_errors
    ? convertFieldErrorsBeToFe(displayedError.field_errors, true)
    : undefined;

  return (
    <FormProvider {...formMethods}>
      <div className="space-y-5">
        <AddFieldsCard fieldErrors={fieldErrors} immutable={immutable} />
        <Branches fieldErrors={fieldErrors} immutable={immutable} />
        <DefaultValuesCard fieldErrors={fieldErrors} immutable={immutable} />
      </div>
    </FormProvider>
  );
};
