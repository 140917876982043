import { observer } from "mobx-react-lite";
import React from "react";

import { TestRunErrorSelector } from "src/nodeEditor/TestRunErrorSelector";
import { SchemaHistoricalErrorInformation } from "src/schema/SchemaErrorInformationV2/SchemaHistoricalErrorInformation";
import { useGraphStore } from "src/store/StoreProvider";
import { useNodeRunState } from "src/store/runState/RunState";

export const SchemaErrorInformationV2: React.FC<{
  workspaceUrl: string;
}> = observer(({ workspaceUrl }) => {
  const {
    inputNode,
    outputNode,
    selectedNode,
    displayedErroredRow,
    setDisplayedErroredRow,
    resultsOutdated,
  } = useGraphStore();

  const inputNodeRunState = useNodeRunState(inputNode?.id ?? "");
  const outputNodeRunState = useNodeRunState(outputNode?.id ?? "");
  const selectedNodeRunState = useNodeRunState(selectedNode?.id ?? "");
  if (
    inputNode &&
    outputNode &&
    (inputNodeRunState?.type === "historical-error" ||
      outputNodeRunState?.type === "historical-error")
  ) {
    const failingInput = (() => {
      if (inputNodeRunState?.type === "historical-error") {
        return inputNodeRunState.error.data;
      } else if (outputNodeRunState?.type === "historical-error") {
        return outputNodeRunState?.error.data;
      }
    })();
    return <SchemaHistoricalErrorInformation failingInput={failingInput} />;
  } else if (
    selectedNode &&
    selectedNodeRunState?.type === "test-run" &&
    (selectedNode === inputNode || selectedNode === outputNode)
  ) {
    return (
      <TestRunErrorSelector
        displayedFailure={displayedErroredRow}
        resultsOutdated={resultsOutdated}
        selectedNodeId={selectedNode?.id}
        setDisplayedFailure={setDisplayedErroredRow}
        testResult={selectedNodeRunState.testResult}
        workspaceUrl={workspaceUrl}
      />
    );
  }
  return <></>;
});
