import axios from "axios";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { FlowVersionT } from "src/api/flowTypes";
import { useEditFlowVersion } from "src/api/flowVersionQueries";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Input } from "src/base-components/Input";
import { Modal } from "src/base-components/Modal";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { Textarea } from "src/base-components/Textarea";
import { getNameErrorMessage, nameValidations } from "src/flow/modals/utils";
import * as logger from "src/utils/logger";

type DecisionFlowVersionInputsT = {
  name: string;
  description: string;
};

export type EditFlowVersionModalPropsT = {
  isOpen: boolean;
  onClose: () => void;
  afterLeave: () => void;
  version?: FlowVersionT;
};

export const EditFlowVersionModal: React.FC<EditFlowVersionModalPropsT> = ({
  isOpen,
  onClose,
  version,
  afterLeave,
}) => {
  const [submitError, setSubmitError] = useState<boolean>(false);
  const editVersion = useEditFlowVersion();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
  } = useForm<DecisionFlowVersionInputsT>({ mode: "onChange" });

  useEffect(() => {
    // Everytime the Modal is reopened with a new version we need to reset the form to set the new default value
    if (isOpen) {
      reset({
        name: version?.name,
        description: version?.meta?.release_note,
      });
    }
  }, [reset, version, isOpen]);

  const onModalConfirm = async (
    name: string | undefined,
    description: string | undefined,
  ) => {
    if (version) {
      await editVersion.mutateAsync({
        version,
        name,
        description,
      });
      onClose();
    }
  };

  const onFormConfirm = handleSubmit(
    async (data: DecisionFlowVersionInputsT) => {
      try {
        /**
         * Only update the fields that have changed so that the change history can interprete them correctly
         */
        await onModalConfirm(
          data.name !== version?.name ? data.name : undefined,
          data.description !== version?.meta?.release_note
            ? data.description
            : undefined,
        );
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 409) {
          setError("name", { type: "notAvailable" }, { shouldFocus: true });
        } else {
          logger.error(e);
          setSubmitError(true);
        }
      }
    },
  );

  const renderNameInput = () => (
    <>
      <div className="mt-2">
        <Input
          data-loc="name-input"
          placeholder="Type version name"
          type="text"
          fullWidth
          {...register("name", {
            required: true,
            validate: nameValidations,
          })}
        />
      </div>
    </>
  );

  const renderDescriptionInput = () => (
    <Textarea
      autoComplete="off"
      data-loc="description-input"
      placeholder="Type version description"
      {...register("description")}
    />
  );

  const renderButtons = () => (
    <div className="mb-6 mt-4 flex flex-row items-center justify-end border-t border-gray-200 pr-6 pt-6 text-right">
      {submitError && (
        <div className="mr-2 text-red-600">
          Error submitting the data - please try again
        </div>
      )}
      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
      <span className="ml-2 inline-block w-[9.3rem]">
        <Button
          dataLoc="save"
          disabled={!isEmpty(errors)}
          htmlType="submit"
          loading={isSubmitting}
          variant="primary"
        >
          Save draft version
        </Button>
      </span>
    </div>
  );

  return (
    <Modal
      afterLeave={afterLeave}
      className="h-164 w-120"
      open={isOpen}
      title="Edit version"
      onClose={onClose}
    >
      <form onSubmit={onFormConfirm}>
        <div className="mx-6 mt-5">
          <div className="text-gray-800 font-inter-semibold-13px">
            Version name <RequiredAsterisk />
          </div>
          {errors.name && (
            <ErrorHint>{getNameErrorMessage(errors.name.type)}</ErrorHint>
          )}
          {renderNameInput()}
          <div className="mb-2 mt-5 text-gray-800 font-inter-semibold-13px">
            Version description
          </div>
          {renderDescriptionInput()}
        </div>
        {renderButtons()}
      </form>
    </Modal>
  );
};
