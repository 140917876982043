import { validate as validateUUID } from "uuid";

import { FlowVersionMeta, FlowVersionStatus } from "src/clients/flow-api";
import { User, UserFromId } from "src/flow/User";
import { assertUnreachable } from "src/utils/typeUtils";

type Props = {
  versionMeta: FlowVersionMeta;
  status: FlowVersionStatus;
};

/**
 * Old archived version might have a corrupted archived_by_id or published_by_id fields, so we fall back of the originally saved data
 */
export const LegacyVersionMetaUser: React.FC<Props> = ({
  versionMeta,
  status,
}) => {
  let userId: string | undefined = undefined;
  let userName: string | undefined = undefined;
  let userAvatar: string | undefined = undefined;

  if (status === FlowVersionStatus.PUBLISHED) {
    userId = versionMeta.published_by_id;
    userName = versionMeta.published_by;
    userAvatar = versionMeta.published_by_avatar;
  } else if (status === FlowVersionStatus.ARCHIVED) {
    userId = versionMeta.archived_by_id;
    userName = versionMeta.archived_by;
    userAvatar = versionMeta.archived_by_avatar;
  } else if (status === FlowVersionStatus.DRAFT) {
    userId = versionMeta.created_by_id;
    userName = versionMeta.created_by;
    userAvatar = versionMeta.created_by_avatar;
  } else {
    assertUnreachable(status);
  }

  return userId && validateUUID(userId) ? (
    <UserFromId userId={userId} />
  ) : (
    <User avatar={userAvatar} name={userName || ""} />
  );
};
