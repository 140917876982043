import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  InputMappingGroupT,
  InputMappingT,
  IntegrationResourceT,
  OutputMappingT,
} from "src/integrationNode/types";

const getDefaultConsumerFields = (): { [key: string]: InputMappingT } => ({
  first_name: {
    id: uuidV4(),
    type: "text",
    displayName: "First name",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `1-255 characters`,
  },
  middle_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Middle name",
    assignedTo: "",
    hint: `1-255 characters`,
  },
  last_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Last name",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `1-255 characters`,
  },
  date_of_birth: {
    id: uuidV4(),
    type: "text",
    displayName: "Date of birth",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `Must adhere to the ISO format "YYYY-MM-DD" (e.g., "1960-12-20")`,
  },
  ssn: {
    id: uuidV4(),
    type: "text",
    displayName: "Social security number",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `The individual's 9-digit social security number (SSN), formatted as a simple string of integers (e.g., “123456789”)`,
  },
});

const getBaseAddressFields = (): { [key: string]: InputMappingT } => ({
  street1: {
    id: uuidV4(),
    type: "text",
    displayName: "Street address",
    assignedTo: "",
    hint: `1-255 characters`,
    rules: {
      required: true,
    },
  },
  street2: {
    id: uuidV4(),
    type: "text",
    displayName: "Street address line 2",
    hint: `1-255 characters`,
    assignedTo: "",
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `1-255 characters`,
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `A two-letter code for the U.S. state or territory of the address (e.g., "CA" for California)`,
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `The consumer's 5 or 9-digit ZIP code`,
  },
  country: {
    id: uuidV4(),
    type: "text",
    displayName: "Country",
    assignedTo: "",
    hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g., "US" for USA)`,
  },
});

const getDefaultAddressFields = (): { [key: string]: InputMappingT } => ({
  ...getBaseAddressFields(),
  housing_status: {
    id: uuidV4(),
    type: "text",
    displayName: "Housing status",
    assignedTo: "",
    hint: `The consumer's housing status. One of "Rent", "Own", "Family", "Friend", "Other" (case sensitive).`,
  },
});

const getDefaultAddress = (): InputMappingGroupT => ({
  getDefaultElements: getDefaultAddressFields,
  elements: getDefaultAddressFields(),
  rules: {
    required: true,
  },
  displayName: "Address",
});

const getDefaultContactsFields = (): { [key: string]: InputMappingT } => ({
  home_phone: {
    id: uuidV4(),
    type: "text",
    displayName: "Home phone",
    assignedTo: "",
    hint: `The consumer's home phone number. 10 digits without dashes (e.g., 1234567890).`,
  },
  mobile_phone: {
    id: uuidV4(),
    type: "text",
    displayName: "Cell phone",
    assignedTo: "",
    hint: `The consumer's cell phone number. 10 digits without dashes (e.g., 1234567890).`,
  },
  work_phone: {
    id: uuidV4(),
    type: "text",
    displayName: "Work phone",
    assignedTo: "",
    hint: `The consumer's work phone number. 10 digits without dashes (e.g., 1234567890).`,
  },
  email: {
    id: uuidV4(),
    type: "text",
    displayName: "Email",
    assignedTo: "",
  },
  ip_address: {
    id: uuidV4(),
    type: "text",
    displayName: "IP address",
    assignedTo: "",
    hint: `The consumer's IP address. Must adhere to the IPv4 or IPv6 format.`,
  },
});

const getDefaultContacts = (): InputMappingGroupT => ({
  getDefaultElements: getDefaultContactsFields,
  displayName: "Contacts",
});

const getDefaultDriverLicenseFields = (): { [key: string]: InputMappingT } => ({
  number: {
    id: uuidV4(),
    type: "text",
    displayName: "License number",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `The consumer's driver's license number. 1-255 characters.`,
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "License state",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `A two-letter code for the U.S. state or territory of the driver's license (e.g., "CA" for California)`,
  },
});

const getDefaultDriversLicence = (): InputMappingGroupT => ({
  getDefaultElements: getDefaultDriverLicenseFields,
  displayName: "Driver's license",
});

const getDefaultEmployerFields = (): { [key: string]: InputMappingT } => ({
  name: {
    id: uuidV4(),
    type: "text",
    displayName: "Employer name",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `1-255 characters`,
  },
  ...getBaseAddressFields(),
  pay_period: {
    id: uuidV4(),
    type: "text",
    displayName: "Pay period",
    assignedTo: "",
    hint: `The consumer's pay period. One of "WEEKLY", "BI_WEEKLY", "SEMI_MONTHLY", "MONTHLY" (case sensitive).`,
  },
  next_pay_date: {
    id: uuidV4(),
    type: "text",
    displayName: "Next pay date",
    assignedTo: "",
    hint: `The consumer's next pay date. Must adhere to the ISO format "YYYY-MM-DD" (e.g., "2022-12-20").`,
  },
  monthly_income: {
    id: uuidV4(),
    type: "text",
    displayName: "Monthly income",
    assignedTo: "",
    hint: `The consumer's self-reported monthly gross income in USD (e.g., 2100.42)`,
  },
});

const getDefaultEmployer = (): InputMappingGroupT => ({
  getDefaultElements: getDefaultEmployerFields,
  displayName: "Employer",
});

const getDefaultBankAccountFields = (): { [key: string]: InputMappingT } => ({
  bank_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Bank name",
    assignedTo: "",
    hint: `The consumer's bank name. 1-255 characters.`,
  },
  bank_aba_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Routing number",
    assignedTo: "",
    hint: `The 9-digit ABA number associated with the consumer's bank`,
  },
  account_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Account number",
    assignedTo: "",
    hint: `The consumer's bank account number. 5-17 digits.`,
  },
});

const getDefaultBankAccount = (): InputMappingGroupT => ({
  getDefaultElements: getDefaultBankAccountFields,
  displayName: "Bank account",
});

const getDefaultRequestedLoanAmountFields = (): {
  [key: string]: InputMappingT;
} => ({
  amount: {
    id: uuidV4(),
    type: "text",
    displayName: "Amount",
    assignedTo: "",
    hint: `The amount requested by the consumer`,
  },
});

const getDefaultRequestedLoanAmount = (): InputMappingGroupT => ({
  getDefaultElements: getDefaultRequestedLoanAmountFields,
  displayName: "Requested loan amount",
});

const getDataXCreditReportReportInsights = (): {
  [key: string]: OutputMappingT;
} => ({
  retrieval_datetime: getNamedOutputMapping("Retrieval date-time (UTC)"),
  provider_request_id: getNamedOutputMapping("Provider request ID"),
  track_id: getNamedOutputMapping(
    "Track ID",
    `ID that you've provided in the input extracted from the API response`,
  ),
  no_hit: {
    ...getNamedOutputMapping("No hit indicator"),
    hint: `Boolean value, indicating whether the consumer's credit report could not be found in the bureau database. A "no-hit" status suggests that there is either insufficient information to generate a credit report or the consumer does not have a credit history with the bureau.`,
  },
  is_frozen: {
    ...getNamedOutputMapping("Freeze indicator"),
    hint: `Boolean value, indicating whether the consumer has placed a freeze on their credit report. A credit freeze limits access to the consumer's credit report by requiring the consumer's explicit consent before the credit reporting agency can release the report.`,
  },
  is_initial_fraud: {
    ...getNamedOutputMapping("Initial fraud alert indicator"),
    hint: `Boolean value, indicating whether the consumer has an initial fraud alert placed on their credit report. An initial fraud alert indicates that the consumer might have been or suspects being a fraud victim. Please take extra verification steps before extending credit. This alert is effective for one year and can be renewed by the consumer.`,
  },
  is_extended_fraud: {
    ...getNamedOutputMapping("Extended fraud alert indicator"),
    hint: `Boolean value, indicating whether the consumer has an extended fraud alert on their credit report. An extended fraud alert is set by a consumer who has provided an identity theft report. This requires you to contact the consumer directly or take other steps to confirm their identity before issuing new credit. This alert is effective for seven years.`,
  },
  is_active_duty: {
    ...getNamedOutputMapping("Active duty alert indicator"),
    hint: `Boolean value, indicating whether the consumer has an active duty alert placed on their credit report. An active duty alert indicates that the consumer is on military deployment and at a higher risk of identity theft. Please take extra verification steps before extending credit. This alert is effective for one year and can be renewed by the consumer for the duration of the active duty.`,
  },
});

export const getDefaultDataXCreditReportReport = (): IntegrationResourceT => ({
  groupedGroupsAndLists: true,
  providerResource: {
    provider: "data_x",
    resource: "data_x_credit_report",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      track_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Track ID",
        assignedTo: "",
        hint: "Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.",
      },
      ...getDefaultConsumerFields(),
    },
    grouped: {
      address: getDefaultAddress(),
      contacts: getDefaultContacts(),
      drivers_licence: getDefaultDriversLicence(),
      employer: getDefaultEmployer(),
      bank_account: getDefaultBankAccount(),
      requested_loan_amount: getDefaultRequestedLoanAmount(),
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: getDataXCreditReportReportInsights(),
  },
  config: defaultResourceConfig,
});
