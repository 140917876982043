import { Combobox } from "@headlessui/react";
import Fuse from "fuse.js";
import React, { useMemo, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";

import EmptyCube from "src/assets/empty-state-cube.svg?react";
import { AwsRegionTypeT, AWS_REGIONS } from "src/constants/AwsRegions";
import { RegionComboBoxItem } from "src/layout/WorkspaceFormModal/RegionComboBoxItem";
import { RegionDisplay } from "src/layout/WorkspaceFormModal/RegionDisplay";

type RegionComboBoxProps = {
  inputRef: React.Ref<HTMLInputElement>;
  value: AwsRegionTypeT;
  onChange: (region: AwsRegionTypeT) => void;
  disabled: boolean;
};

const fuse = new Fuse(AWS_REGIONS, {
  keys: ["identifier", "city", "region"],
});

export const RegionComboBox: React.FC<RegionComboBoxProps> = ({
  value: propValue,
  onChange,
  inputRef,
  disabled,
}) => {
  const [query, setQuery] = useState("");
  const buttonRef = useRef<HTMLButtonElement>(null);

  const filteredRegions = useMemo(() => {
    if (query.trim() === "") {
      return AWS_REGIONS;
    }

    return fuse.search(query).map((result) => result.item);
  }, [query]);

  const handleChange = (value: AwsRegionTypeT) => {
    onChange(value);
    setQuery("");
  };

  return (
    <Combobox
      by="identifier"
      disabled={disabled}
      value={propValue}
      onChange={handleChange}
    >
      {({ open, value }) => (
        <div
          className={twJoin(
            "flex w-full flex-col rounded-lg border border-gray-200",

            !disabled && "bg-white focus-within:border-indigo-400",
            disabled && "bg-gray-50",
          )}
          tabIndex={0}
          onFocus={() => !open && buttonRef.current?.click()}
        >
          <Combobox.Input
            ref={inputRef}
            className={twJoin(
              "w-full rounded-lg px-3 py-[5px] text-xs focus:outline-none",
              !open && "hidden",
            )}
            placeholder="Search..."
            value={query}
            onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button
            ref={buttonRef}
            className={twJoin("w-full", open && "hidden")}
          >
            <RegionDisplay awsRegion={value} padded />
          </Combobox.Button>
          <Combobox.Options className="fixed z-50 mt-8 max-h-56 w-full max-w-[485px] overflow-y-auto rounded-lg bg-white py-2 shadow-lg">
            {filteredRegions.length === 0 && query !== "" ? (
              <div className="flex cursor-default items-center gap-x-2 px-4 py-2 text-gray-700">
                <EmptyCube className="h-4 w-4" />
                <span>No matching region found. Please try again.</span>
              </div>
            ) : (
              filteredRegions.map((r) => (
                <RegionComboBoxItem key={r.identifier} region={r} />
              ))
            )}
          </Combobox.Options>
        </div>
      )}
    </Combobox>
  );
};
