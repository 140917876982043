import React, { useState } from "react";

import { useTrafficPolicies } from "src/api/queries";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { toastSuccess } from "src/base-components/Toast/utils";
import { AssembleDatasetModal } from "src/datasets/AssembleDatasetModal/Modal";
import { DatasetList } from "src/datasets/DatasetList/DatasetList";
import { useDatasets, useDeleteDataset } from "src/datasets/api/queries";
import { separateFilenameExtension, useSchemas } from "src/datasets/utils";
import { useSetEditDatasetId } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";
import * as logger from "src/utils/logger";

type DatasetSidePaneProps = {
  onRequestClose: () => void;
};

type ItemMode = "delete";
export const DatasetSidePane: React.FC<DatasetSidePaneProps> = ({
  onRequestClose,
}) => {
  const { workspace, flow, version } = useAuthoringContext();
  const flowTrafficPolicies = useTrafficPolicies(flow.id);
  const deleteDataset = useDeleteDataset(workspace?.base_url);
  const datasetsQuery = useDatasets({
    flowId: flow.id,
    baseUrl: workspace.base_url,
  });

  const [activeMode, setActiveMode] = useState<ItemMode | null>(null);
  const [activeDatasetId, setActiveDatasetId] = useState<string | null>(null);
  const [showAssembleModal, setShowAssembleModal] = useState<boolean>(false);

  const schemas = useSchemas(version);

  const setActive = (id: string, mode: ItemMode) => {
    setActiveDatasetId(id);
    setActiveMode(mode);
  };

  const setEditDatasetId = useSetEditDatasetId();

  const activeDataset =
    activeDatasetId !== null && datasetsQuery.data
      ? datasetsQuery.data.find((d) => d.id === activeDatasetId)
      : null;

  const handleConfirmDelete = async () => {
    if (!activeDataset) {
      return;
    }

    try {
      await deleteDataset.mutateAsync(activeDataset.id);
      toastSuccess({
        title: `Successfully deleted ${
          separateFilenameExtension(activeDataset.name).name
        }!`,
      });

      setActiveMode(null);
      setActiveDatasetId(null);
    } catch (err) {
      logger.error("Error deleting test data.", err);
    }
  };

  return (
    <div className="flex h-full w-128 flex-1 items-center justify-center border-l border-gray-200 bg-white pt-4">
      <div className="align-start flex h-full w-full flex-col justify-start overflow-auto">
        <DatasetList
          datasetsQuery={datasetsQuery}
          schemas={schemas}
          onAssemble={() => setShowAssembleModal(true)}
          onDelete={(dataset) => setActive(dataset.id, "delete")}
          onRequestClose={onRequestClose}
          onSelect={(dataset) => setEditDatasetId(dataset.id)}
        />
      </div>
      <ConfirmationModal
        confirmationButtonClassname="w-[6.475rem]"
        confirmationButtonText="Yes, delete"
        confirmationButtonType="warning"
        open={activeMode === "delete"}
        title={
          activeDataset
            ? `Delete ${separateFilenameExtension(activeDataset.name).name}`
            : ""
        }
        onClose={() => setActiveMode(null)}
        onConfirm={handleConfirmDelete}
      >
        <div className="mb-6 mt-4 text-gray-500 font-inter-normal-12px">
          Are you sure you want to delete this test dataset file? This action
          cannot be undone.
        </div>
      </ConfirmationModal>
      {flowTrafficPolicies.data && (
        <AssembleDatasetModal
          flow={flow}
          open={showAssembleModal}
          trafficPolicies={flowTrafficPolicies.data}
          onClose={() => setShowAssembleModal(false)}
        />
      )}
    </div>
  );
};
