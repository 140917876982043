import { observer } from "mobx-react-lite";
import React from "react";
import { useForm } from "react-hook-form";

import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { Input } from "src/base-components/Input";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { useGraphStore } from "src/store/StoreProvider";

type NameInput = { name: string };

export const CreateGroupModal: React.FC<{}> = observer(() => {
  const { register, handleSubmit, reset } = useForm<NameInput>({
    defaultValues: { name: "" },
  });
  const {
    groupSelectedNodes,
    isCreateGroupModalVisible,
    setIsCreateGroupModalVisible,
  } = useGraphStore();

  const onClose = () => {
    setIsCreateGroupModalVisible(false);
    reset();
  };

  const submitHandler = handleSubmit(async (values) => {
    await groupSelectedNodes(values.name);
    onClose();
  });

  return (
    <ConfirmationModal
      confirmationButtonClassname="w-[7.2rem]"
      confirmationButtonText="Create group"
      open={isCreateGroupModalVisible}
      title="Group details"
      onClose={onClose}
      onConfirm={submitHandler}
    >
      <form className="pb-6" onSubmit={submitHandler}>
        <div className="mb-2 text-gray-800 font-inter-semibold-13px">
          Add group name <RequiredAsterisk />
        </div>
        <Input
          data-loc="name-input"
          placeholder="Untitled group"
          fullWidth
          {...register("name")}
        />
      </form>
    </ConfirmationModal>
  );
});
