import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";

type TitleEditorProps = {
  value: string | undefined;
  placeholder?: string;
  disabled?: boolean;
  onSubmit: (val: string) => void;
  className?: string;
  autofocus?: boolean;
  dataLoc?: string;
};

export const TitleEditor: React.FC<TitleEditorProps> = ({
  value = "",
  placeholder,
  disabled,
  onSubmit,
  className,
  autofocus,
  dataLoc,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [isEditing, setIsEditing] = useState<boolean>(
    Boolean(autofocus) && !disabled,
  );
  const [localValue, setLocalValue] = useState<string>(value);
  const isPlaceholder = !value || value === placeholder;

  const onBlur = () => {
    setIsEditing(false);
    onSubmit(localValue);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onBlur();
    } else if (e.key === "Escape") {
      e.preventDefault();
      setLocalValue(value);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isEditing, inputRef]);

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const enableEditing = () => !disabled && setIsEditing(true);

  return (
    <div
      className={twJoin(
        "group items-center gap-x-1 font-inter-semibold-16px",
        isEditing && "flex",
        !isEditing && "inline-flex max-w-full",
      )}
      data-loc={dataLoc}
    >
      {isEditing && !disabled ? (
        <input
          ref={inputRef}
          className={twJoin(
            "rounded-md border px-1.5",
            "cursor-text outline-none",
            "text-gray-800 placeholder-gray-300",
            "w-full border-indigo-600",
            className,
          )}
          data-loc={`${dataLoc}-input`}
          placeholder={placeholder}
          tabIndex={-1}
          type="text"
          value={localValue}
          onBlur={onBlur}
          onInput={(e) => setLocalValue(e.currentTarget.value)}
          onKeyDown={handleKeyPress}
        />
      ) : (
        <span
          className={twMerge(
            "truncate rounded-md border border-transparent px-1.5",
            !disabled && "hover:bg-gray-100",
            className,
            isPlaceholder ? "text-gray-500" : "text-gray-800",
          )}
          onClick={enableEditing}
        >
          {isPlaceholder ? placeholder : localValue}
        </span>
      )}
      <span
        className={twJoin(
          "hidden cursor-pointer",
          !disabled && !isEditing && "group-hover:inline",
        )}
        data-loc={`${dataLoc}-edit-button`}
        onClick={enableEditing}
      >
        <Icon
          color="text-gray-500"
          dataLoc="edit-node-name"
          icon={faPen}
          size="xs"
        />
      </span>
    </div>
  );
};
