import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  faChartSimple,
  faEdit,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { useMemo } from "react";
import { twJoin } from "tailwind-merge";

import { DEFAULT_CHART_TITLE } from "src/analytics/constants";
import { useAnalyticsPlot } from "src/analytics/hooks";
import {
  AnalyticsKey,
  PlotSpec,
  mapFormStateToPlotSpec,
} from "src/analytics/utils";
import { GenericObjectT } from "src/api/flowTypes";
import { EmptyState } from "src/base-components/EmptyState";
import { Icon } from "src/base-components/Icon";
import { Spinner } from "src/base-components/Spinner";
import { Tooltip } from "src/base-components/Tooltip";
import { Chart } from "src/clients/flow-api";
import { useCapabilities } from "src/hooks/useCapabilities";

export const AnalyticsChartBase: React.FC<{
  data: GenericObjectT[];
  options: PlotSpec;
}> = ({ data, options }) => {
  const { containerRef, error } = useAnalyticsPlot({
    data,
    options,
  });

  return (
    <div className="relative h-full w-full">
      <div
        ref={containerRef}
        className={twJoin("h-full w-full", error && "invisible")}
        data-loc="analytics-plot-container"
      />
      {error && (
        <div className="absolute inset-0">
          <EmptyState
            description={error.description}
            headline={error.headline}
            icon={faChartSimple}
            variant="error"
          />
        </div>
      )}
    </div>
  );
};

type AnalyticsChartProps = {
  chart: Chart;
  data?: GenericObjectT[];
  keys: AnalyticsKey[];
  onDelete: (chart: Chart) => void;
  onEdit: (chart: Chart) => void;
  isLoading?: boolean;
  dataLoc?: string;
};

export const AnalyticsChart = ({
  chart,
  data = [],
  onDelete,
  onEdit,
  isLoading,
  keys,
  dataLoc,
}: AnalyticsChartProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: chart.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { flowCharts } = useCapabilities();
  const thereIsNoData = data.length === 0;
  const chartKeys: string[] = Object.entries(chart.dimensions)
    .map(([_key, { value }]) => value)
    .filter(Boolean);

  const missingChartKeys = chartKeys.filter(
    (key) => !keys.map((k) => k.actualKey).includes(key),
  );
  const missingChartKeysFormatted = new Intl.ListFormat().format(
    missingChartKeys,
  );

  const isInvalidAnalyticsData = !!missingChartKeys.length;

  const isEditingDisabled = thereIsNoData || isInvalidAnalyticsData;

  return (
    <div
      ref={setNodeRef}
      className={twJoin(
        "group relative flex flex-col gap-y-2.5 rounded-lg border border-gray-200 bg-white px-4 pb-2.5 pt-3",
        isDragging && "z-10 shadow-xl",
      )}
      data-loc={dataLoc}
      style={style}
    >
      <div className="flex">
        <div className="flex-1">
          <p className="text-gray-800 font-inter-semibold-13px">
            {chart.title ?? DEFAULT_CHART_TITLE}
          </p>
          {chart.description && (
            <p className="text-gray-500 font-inter-normal-12px">
              {chart.description}
            </p>
          )}
        </div>
        <div className="flex items-start gap-x-1 opacity-0 transition-opacity group-hover:opacity-100">
          {flowCharts.canEdit && (
            <Tooltip
              activated={isEditingDisabled}
              body={
                isInvalidAnalyticsData
                  ? "You need compatible analytics data to edit this chart"
                  : "Initiate a test run to enable editing feature"
              }
              placement="top"
              title=""
              triggerClassName="flex items-start"
              asChild
            >
              <Icon
                color={
                  isEditingDisabled
                    ? "text-gray-400"
                    : "text-gray-500 hover:text-gray-700"
                }
                dataLoc={`${dataLoc}-edit`}
                disabled={isEditingDisabled}
                icon={faEdit}
                size="xs"
                onClick={() => onEdit(chart)}
              />
            </Tooltip>
          )}
          {flowCharts.canDelete && (
            <Icon
              color="text-gray-500 hover:text-gray-700"
              dataLoc={`${dataLoc}-delete`}
              icon={faTrash}
              size="xs"
              onClick={() => onDelete(chart)}
            />
          )}
        </div>
      </div>
      <div className="aspect-video w-full">
        {isLoading ? (
          <div className="flex h-full w-full items-center justify-center">
            <Spinner />
          </div>
        ) : thereIsNoData ? (
          <EmptyState
            description="Initiate a test run to derive insights from your data."
            headline="No analytics data available"
            icon={faChartSimple}
          />
        ) : isInvalidAnalyticsData ? (
          <EmptyState
            description={`Data required for this chart is missing in the most recent test run: ${missingChartKeysFormatted}`}
            headline="Incompatible Data"
            icon={faChartSimple}
            variant="error"
          />
        ) : (
          <ChartRenderer key={chart.id} chart={chart} data={data} keys={keys} />
        )}
      </div>
      <button
        {...attributes}
        {...listeners}
        ref={setActivatorNodeRef}
        className={twJoin(
          "absolute left-0 top-3 hidden h-5 w-[15px] -translate-x-1/2 items-center justify-center rounded border border-gray-200 bg-white group-hover:flex",
          isDragging ? "cursor-grabbing" : "cursor-grab",
        )}
        data-loc={`${dataLoc}-drag`}
      >
        <Icon color="text-gray-500" icon={faGripDotsVertical} size="2xs" />
      </button>
    </div>
  );
};

const ChartRenderer = ({
  chart,
  data,
  keys,
}: {
  chart: Chart;
  data: GenericObjectT[];
  keys: AnalyticsKey[];
}) => {
  const options = useMemo(
    () => mapFormStateToPlotSpec([chart.mark, chart.dimensions], keys),
    [chart, keys],
  );

  return <AnalyticsChartBase key={chart.id} data={data} options={options} />;
};
