import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { DecisionEnvironment } from "src/api/types";
import { DecisionHistoryKeys } from "src/router/SearchParams";

export const useSearchTerm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [term, _setTerm] = useState(
    () => searchParams.get(DecisionHistoryKeys.DecisionId) ?? "",
  );
  const setSearchParamsTerm = useDebouncedCallback((term: string) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      if (term) {
        newParams.set(DecisionHistoryKeys.DecisionId, term);
      } else {
        newParams.delete(DecisionHistoryKeys.DecisionId);
      }
      return newParams;
    });
  }, 500);
  const setTerm = useCallback(
    (term: string) => {
      _setTerm(term);
      setSearchParamsTerm(term);
    },
    [setSearchParamsTerm],
  );

  return [term, setTerm] as const;
};

export const useEnvironment = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const envParam = searchParams.get(DecisionHistoryKeys.DecisionHistoryView) as
    | DecisionEnvironment
    | undefined;
  const decisionEnvironment =
    envParam && Object.values(DecisionEnvironment).includes(envParam)
      ? envParam
      : DecisionEnvironment.LIVE;

  const setDecisionEnvironment = useCallback(
    (env: DecisionEnvironment) => {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.set(DecisionHistoryKeys.DecisionHistoryView, env);
        return newParams;
      });
    },
    [setSearchParams],
  );

  return [decisionEnvironment, setDecisionEnvironment] as const;
};
