import { taktileApiUrl } from "src/api/hosts";
import { pathJoin } from "src/api/paths";
import {
  Configuration,
  GitbookApi,
  LoginApi,
  OrganizationUsersApi,
  OrganizationWsUsersApi,
  OrganizationsApi,
  RolesApi,
  UsersApi,
  OrganizationWsServiceAccountApi,
  OrganizationSimpleRolesApi,
  AuditLogsApi,
} from "src/clients/taktile-api";

export const BACKEND_URL = pathJoin(taktileApiUrl(), "/api/v1");

const configuration = new Configuration({
  // We want to set the apiKey here but cannot do so for now since we would then also set both authorization headers in each call
  basePath: taktileApiUrl(),
});

export const organizationsApi = new OrganizationsApi(configuration);
export const usersApi = new UsersApi(configuration);
export const rolesApi = new RolesApi(configuration);
export const loginApi = new LoginApi(configuration);
export const organizationUsersApi = new OrganizationUsersApi(configuration);
export const organizationWsUsersApi = new OrganizationWsUsersApi(configuration);
export const gitbookAuth = new GitbookApi(configuration);
export const organizationWsServiceAccountApi =
  new OrganizationWsServiceAccountApi(configuration);
export const organizationSimpleRolesApi = new OrganizationSimpleRolesApi(
  configuration,
);
export const auditLogsApi = new AuditLogsApi(configuration);
