import { faEdit, faFileCode, faPlus } from "@fortawesome/pro-regular-svg-icons";
import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";

import { Button } from "src/base-components/Button";
import { FormItem } from "src/base-components/FormItem";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import {
  AvailableEnvironmentPrefixes,
  ConnectionConfigInputsT,
} from "src/connections/types";

type PropsT = {
  environmentPrefix: AvailableEnvironmentPrefixes;
};

type ServiceAccountT = {
  type: string;
  project_id: string;
  private_key_id: string;
  private_key: string;
  client_email: string;
  client_id: string;
  auth_uri: string;
  token_uri: string;
  auth_provider_x509_cert_url: string;
  client_x509_cert_url: string;
  universe_domain: string;
};

type FileSelectorProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filename?: string;
};

const FileSelector = ({ onChange, filename }: FileSelectorProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelectorClick = () => fileInputRef.current?.click();

  const action = filename ? (
    <div
      className="group flex cursor-pointer items-center justify-between rounded border border-gray-100 bg-white px-4 py-3 text-gray-800 shadow-sm font-inter-semibold-13px"
      onClick={handleFileSelectorClick}
    >
      <div className="flex items-center">
        <Icon icon={faFileCode} size="xs" />
        <span className="pl-1">{filename}</span>
      </div>
      <span className="hidden group-hover:inline">
        <Icon color="text-gray-500" icon={faEdit} size="xs" />
      </span>
    </div>
  ) : (
    <Button
      iconLeft={faPlus}
      size="sm"
      variant="secondary"
      onClick={handleFileSelectorClick}
    >
      Upload file
    </Button>
  );

  return (
    <div>
      {action}
      <input
        ref={fileInputRef}
        accept=".json"
        className="hidden"
        name="file"
        type="file"
        onChange={onChange}
      />
    </div>
  );
};
export const GoogleOAuth2Fieldset: React.FC<PropsT> = ({
  environmentPrefix,
}) => {
  const { setValue, register, getValues, watch } =
    useFormContext<ConnectionConfigInputsT>();
  const filename = watch(`${environmentPrefix}googleOAuth2Config.filename`);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result;
      const serviceAccount: ServiceAccountT = JSON.parse(text as string);
      const secrets = getValues(`${environmentPrefix}secrets`);
      if (!secrets) return;

      const jwt_secret_key_index = secrets?.findIndex(
        (s) => s.key === "jwt_secret_key",
      );
      const client_id_index = secrets?.findIndex((s) => s.key === "client_id");
      if (jwt_secret_key_index === -1 || client_id_index === -1) return;

      setValue(
        `${environmentPrefix}secrets.${jwt_secret_key_index}.value`,
        serviceAccount.private_key,
      );
      // we use the client id as transport client email
      setValue(
        `${environmentPrefix}secrets.${client_id_index}.value`,
        serviceAccount.client_email,
      );
      setValue(
        `${environmentPrefix}googleOAuth2Config.authorize_url`,
        serviceAccount.token_uri,
      );
      setValue(`${environmentPrefix}googleOAuth2Config.filename`, file?.name);
    };
    reader.readAsText(file);
  };
  const googleDocsLink = (
    <a
      className="text-indigo-500"
      href="https://developers.google.com/identity/protocols/oauth2/service-account"
      rel="noreferrer"
      target="_blank"
    >
      Learn more from
    </a>
  );

  const uploadHelpText = (
    <p className="font-inter-normal-12px">
      {!filename
        ? "Upload a JSON file containing authentication details. "
        : "To edit authentication details, replace the JSON file below. "}
      {googleDocsLink} Google's documentation.
    </p>
  );
  return (
    <FieldsetCard>
      <FormItem
        description={uploadHelpText}
        gap="xs"
        label="Authentication details"
      >
        <FileSelector filename={filename} onChange={onFileChange} />
      </FormItem>
      <FormItem gap="xs" label="Scope">
        <Input
          placeholder="e.g. https://www.googleapis.com/auth/spreadsheets"
          fullWidth
          {...register(`${environmentPrefix}googleOAuth2Config.scope`)}
        />
      </FormItem>
      <FormItem
        description="The interval (in hours) to refresh the access token"
        gap="xs"
        label="Token refresh interval"
      >
        <Input
          {...register(
            `${environmentPrefix}googleOAuth2Config.token_refresh_interval`,
          )}
          placeholder="e.g. 1 hour"
          fullWidth
        />
      </FormItem>
    </FieldsetCard>
  );
};
