import { create } from "zustand";

import { ConnectionT } from "src/api/connectApi/types";

type ConnectionManagementState = {
  connectionToEdit: ConnectionT | undefined;
  connectionToDelete: ConnectionT | undefined;
  connectionToTest: ConnectionT | undefined;
  modalOpen: boolean;
};

type ConnectionManagementActions = {
  setConnectionToEdit: (connection: ConnectionT | undefined) => void;
  setConnectionToDelete: (connection: ConnectionT | undefined) => void;
  setConnectionToTest: (connection: ConnectionT | undefined) => void;
  setModalOpen: (open: boolean) => void;
};

type ConnectionManagementStore = ZustandStore<
  ConnectionManagementState,
  ConnectionManagementActions
>;

const useConnectionManagementStore = create<ConnectionManagementStore>(
  (set) => ({
    connectionToEdit: undefined,
    connectionToDelete: undefined,
    connectionToTest: undefined,
    modalOpen: false,
    actions: {
      setConnectionToEdit: (connection) => {
        set({ connectionToEdit: connection });
      },
      setConnectionToDelete: (connection) =>
        set({ connectionToDelete: connection }),
      setConnectionToTest: (connection) =>
        set({ connectionToTest: connection }),
      setModalOpen: (open) => set({ modalOpen: open }),
    },
  }),
);

export const useConnectionManagementActions = () =>
  useConnectionManagementStore((state) => state.actions);

export const useConnectionToEdit = () =>
  useConnectionManagementStore((state) => state.connectionToEdit);
export const useConnectionToDelete = () =>
  useConnectionManagementStore((state) => state.connectionToDelete);
export const useConnectionToTest = () =>
  useConnectionManagementStore((state) => state.connectionToTest);
export const useModalOpen = () =>
  useConnectionManagementStore((state) => state.modalOpen);
