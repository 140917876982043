import {
  faDatabase,
  faPlay,
  faSpider,
  faWarning,
  faEmptySet,
} from "@fortawesome/pro-regular-svg-icons";
import { UseMutationResult } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { isValid, parseISO } from "date-fns";
import { capitalize, isBoolean, isNull, times } from "lodash";
import { useMemo } from "react";

import { EmptyState } from "src/base-components/EmptyState";
import { Icon } from "src/base-components/Icon";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { TableComp } from "src/base-components/Table";
import { Tooltip } from "src/base-components/Tooltip";
import {
  JobPreviewArgs,
  JobPreviewResult,
  PreviewErrorResponse,
} from "src/jobs/api/queries";
import { TYPE_ICONS } from "src/utils/constants";
import { formatDate } from "src/utils/datetime";
import {
  objectToSpeakPythonString,
  speakPythonPrimitive,
} from "src/utils/speakPython";

export const SQLResponsePreview: React.FC<{
  previewMutation: UseMutationResult<
    JobPreviewResult,
    AxiosError<PreviewErrorResponse>,
    JobPreviewArgs
  >;
}> = ({ previewMutation }) => {
  const { isIdle, isLoading, isError, data: response, error } = previewMutation;
  const isHandledError = error?.response?.status === 424;
  const errorDetail =
    isHandledError && error?.response?.data.detail
      ? error.response.data.detail
      : ({
          title: "Something went wrong",
          details: "Our team has been notified",
          type: "unknown",
        } as const);

  return (
    <>
      <div className="border-b border-b-gray-100">
        <div className="inline-block">
          <div className="mb-1.5 px-1 text-gray-800 font-inter-normal-13px">
            SQL Response
          </div>
          <div className="-mb-0.5 h-[3px] w-full rounded-full bg-indigo-600" />
        </div>
      </div>
      {isIdle && (
        <div>
          <div className="rounded-xl shadow-sm">
            <EmptyState
              description="SQL response will help you map the columns with the input schema"
              headline="Run Query to see SQL response"
              icon={faPlay}
            />
          </div>
        </div>
      )}
      {isError && (
        <div className="min-h-0 flex-1 rounded-xl shadow-sm">
          <EmptyState
            description={errorDetail.details}
            headline={errorDetail.title}
            icon={
              errorDetail.type === "sql_validation"
                ? faSpider
                : errorDetail.type === "connect_api"
                  ? faDatabase
                  : faWarning
            }
            variant="error"
          />
        </div>
      )}
      {(response || isLoading) && (
        <>
          <SQLPreviewTable isLoading={isLoading} rows={response?.data.result} />
          {response?.data.result?.length === 0 && (
            <div className="rounded-xl shadow-sm">
              <EmptyState
                description="The query was successfully executed but no rows were returned"
                headline="No rows returned"
                icon={faEmptySet}
                variant="default"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

const SQLPreviewTable: React.FC<{
  rows?: JobPreviewResult["data"]["result"];
  isLoading?: boolean;
}> = ({ rows = [], isLoading = false }) => {
  const columns = useMemo(() => getColumns(rows, isLoading), [rows, isLoading]);
  return (
    <div className="max-h-full min-h-0 max-w-full overflow-auto">
      <TableComp
        columns={columns}
        data={rows}
        dataLoc="sql-preview-table"
        frameClassName="w-full border border-gray-100"
        isLoading={isLoading}
      />
    </div>
  );
};

const PreviewHeader: React.FC<{
  children: React.ReactNode;
  icon: React.ReactNode;
}> = ({ children, icon }) => (
  <div className="flex gap-x-0.5 border-r border-r-gray-100 bg-gray-50 py-2 pl-1 pr-2 font-inter-medium-12px">
    {icon}
    <span>{children}</span>
  </div>
);

const columnHelper = createColumnHelper<Record<string, any>>();

const getColumns = (
  data: JobPreviewResult["data"]["result"],
  isLoading: boolean,
) => {
  if (data.length) {
    const types = detectDataTypes(data);
    return Object.keys(data[0]).map((key) =>
      columnHelper.accessor(key, {
        header: ({ header }) => (
          <PreviewHeader
            icon={
              <Tooltip
                align="center"
                placement="top"
                title={
                  types[header.id] !== "any"
                    ? capitalize(types[header.id])
                    : "Unknown"
                }
              >
                <Icon
                  color="text-gray-500"
                  icon={TYPE_ICONS[types[header.id]]}
                  size="2xs"
                />
              </Tooltip>
            }
          >
            {header.id}
          </PreviewHeader>
        ),
        cell: ({ cell }) => (
          <div className="truncate border-r border-r-gray-100 py-2 pl-1.5 pr-2 font-inter-normal-12px">
            {renderValue(cell.getValue(), types[cell.column.id])}
          </div>
        ),
      }),
    );
  }

  if (isLoading)
    return times(5, (i) =>
      columnHelper.accessor(`loading_${i}}`, {
        header: () => (
          <PreviewHeader
            icon={<SkeletonPlaceholder height="h-4" width="w-4" />}
          >
            <SkeletonPlaceholder height="h-4" width="w-16" />
          </PreviewHeader>
        ),
      }),
    );

  return [];
};

const renderValue = (value: any, type: ColumnType) => {
  if (isNull(value) || isBoolean(value))
    return speakPythonPrimitive(String(value));

  if (type === "datetime") {
    return formatDate(value);
  }

  if (type === "array" || type === "object") {
    return objectToSpeakPythonString(value);
  }

  return value;
};

type ColumnType = keyof typeof TYPE_ICONS;

const detectColumnType = (value: unknown): ColumnType => {
  if (typeof value === "number") {
    return "number";
  } else if (typeof value === "boolean") {
    return "boolean";
  } else if (typeof value === "string") {
    return !isValid(parseISO(value)) ? "string" : "datetime";
  } else if (Array.isArray(value)) {
    return "array";
  } else if (typeof value === "object") {
    return "object";
  } else {
    return "any";
  }
};

export const detectDataTypes = (rows: JobPreviewResult["data"]["result"]) => {
  const types: Record<string, ColumnType> = {};
  const fieldsCount = Object.keys(rows.at(0) ?? {}).length;

  for (const row of rows) {
    for (const key in row) {
      if (row[key] !== null && !types[key]) {
        types[key] = detectColumnType(row[key]);
      }

      if (fieldsCount === Object.keys(types).length) {
        // Quit early if all types are detected
        return types;
      }
    }
  }

  return types;
};
