import { create } from "zustand";

type NodeWarningsState = {
  nodeIds: string[];
  isInvalid: (nodeId: string) => boolean;
  addInvalidNode: (nodeId: string) => void;
  removeInvalidNode: (nodeId: string) => void;
};

export const useNodeValidations = create<NodeWarningsState>((set, get) => ({
  nodeIds: [],
  isInvalid: (nodeId: string) => get().nodeIds.includes(nodeId),
  addInvalidNode: (nodeId: string) =>
    set((state) => ({
      nodeIds: state.nodeIds.includes(nodeId)
        ? state.nodeIds
        : [...state.nodeIds, nodeId],
    })),
  removeInvalidNode: (nodeId: string) =>
    set((state) => ({ nodeIds: state.nodeIds.filter((id) => id !== nodeId) })),
}));
