import {
  faCheck,
  faChevronDown,
  faFolder,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";

import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { FlowFolderDb } from "src/clients/flow-api";

export type FolderDropdownSelection =
  | {
      mode: "addFolder";
      folderName: string;
    }
  | {
      mode: "selectFolder";
      folderId: string | null;
    };

type Props = {
  folders: FlowFolderDb[];
  selected: FolderDropdownSelection | undefined;
  onSelect: (selection: FolderDropdownSelection) => void;
};

const DropdownFolderValue: React.FC<{
  name: string;
  selected: boolean;
}> = ({ name, selected }) => (
  <div
    className="flex items-center justify-between px-5 py-3.5 font-inter-normal-12px"
    data-loc={`folder-item-${name}`}
  >
    <div className="flex items-center">
      <Icon color="text-gray-500" icon={faFolder} size="xs" />
      <p className="ml-1 text-gray-800 font-inter-normal-13px">{name}</p>
    </div>
    <div className="flex flex-row items-center">
      {selected && <Icon color="text-indigo-600" icon={faCheck} size="xs" />}
    </div>
  </div>
);

const renderButtonValue = (
  value: FolderDropdownSelection | undefined,
  folders: FlowFolderDb[],
  onSelect: (selection: FolderDropdownSelection) => void,
  setInputRef: (ref: HTMLInputElement | null) => void,
) => {
  return (
    <div className="flex h-8 min-w-0 flex-1 items-center justify-between gap-1 py-1 pl-3 pr-2 text-gray-800 font-inter-normal-12px">
      <Icon color="text-gray-500" icon={faFolder} size="xs" />
      {value?.mode === "addFolder" ? (
        <input
          ref={setInputRef}
          autoComplete="off"
          className="boder-none h-full w-full focus:outline-none"
          data-loc="folder-dropdown-input"
          type="text"
          onChange={(e) => {
            onSelect({ mode: "addFolder", folderName: e.target.value });
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            if (e.key !== "Tab" && e.key !== "Enter") {
              e.stopPropagation();
            }
          }}
        />
      ) : (
        <div
          className={twJoin(
            "min-w-0 grow text-left",
            value !== undefined ? "" : "text-gray-500",
          )}
          data-loc="datasets-dropdown-button-value"
        >
          {folders.find((folder) => folder.id === value?.folderId)?.name ||
            "Select a folder"}
        </div>
      )}
      <div className="flex h-auto items-center">
        <Icon color="text-gray-500" icon={faChevronDown} size="2xs" />
      </div>
    </div>
  );
};

const renderFooter = (
  onSelect: (selection: FolderDropdownSelection) => void,
) => (
  <div
    className="flex items-center justify-between px-5 py-3.5 font-inter-normal-12px"
    data-loc="folder-dropdown-add-folder"
    onClick={() => onSelect({ mode: "addFolder", folderName: "" })}
  >
    <div className="flex items-center">
      <Icon color="text-indigo-600" icon={faPlus} size="xs" />
      <p className="ml-1 text-indigo-600 font-inter-normal-13px"> Add Folder</p>
    </div>
  </div>
);

export const SelectFolderDropdown: React.FC<Props> = ({
  folders,
  selected,
  onSelect,
}) => {
  const [nameInputRef, setNameInputRef] = useState<HTMLInputElement | null>(
    null,
  );
  // When the dropdown switches modes to name input we want to focus the input
  useEffect(() => {
    if (nameInputRef) {
      nameInputRef.focus();
    }
  }, [nameInputRef]);
  return (
    <FixedPositionedDropdown
      buttonDataLoc="folder-dropdown-button"
      elements={
        folders
          ? folders.map((folder) => ({
              key: folder.id,
              value: folder.name,
            }))
          : []
      }
      itemsClassNames="max-h-[500px] overflow-auto"
      renderButtonValue={() =>
        renderButtonValue(selected, folders, onSelect, setNameInputRef)
      }
      renderFooter={() => renderFooter(onSelect)}
      renderValue={(value) => {
        return (
          <DropdownFolderValue name={value.value} selected={value.selected} />
        );
      }}
      selected={
        selected?.mode === "selectFolder" ? selected.folderId : undefined
      }
      itemsPortal
      onSelect={(key) => {
        onSelect({ mode: "selectFolder", folderId: key });
      }}
    />
  );
};
