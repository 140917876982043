import { UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React from "react";

import { Spinner } from "src/base-components/Spinner";
import { ErrorContent } from "src/router/ErrorPage";
import { errorMessage } from "src/utils/stringUtils";

type BasePropsT = {
  displayRefetch?: boolean;
  renderUpdating?: () => React.ReactElement;
  renderErrored?: (message: string) => React.ReactElement;
};

export type LoadingViewQueryResult<DataT> = Pick<
  UseQueryResult<DataT, Error>,
  "data" | "error" | "isLoading" | "isRefetching" | "isError"
>;

type ResultT<
  FirstDataT,
  SecondDataT,
  ThirdDataT,
  T extends
    | FirstDataT
    | [FirstDataT, SecondDataT]
    | [FirstDataT, SecondDataT, ThirdDataT],
> = T extends FirstDataT
  ? LoadingViewQueryResult<FirstDataT>
  : T extends [FirstDataT, SecondDataT]
    ? [LoadingViewQueryResult<FirstDataT>, LoadingViewQueryResult<SecondDataT>]
    : [
        LoadingViewQueryResult<FirstDataT>,
        LoadingViewQueryResult<SecondDataT>,
        LoadingViewQueryResult<ThirdDataT>,
      ];

type PropsT<
  FirstDataT,
  SecondDataT,
  ThirdDataT,
  T extends
    | FirstDataT
    | [FirstDataT, SecondDataT]
    | [FirstDataT, SecondDataT, ThirdDataT],
> = {
  queryResult: ResultT<FirstDataT, SecondDataT, ThirdDataT, T>;
  renderUpdated: (value: T) => React.ReactElement;
} & BasePropsT;

export const defaultRenderErrored = (
  errors: Nullable<Error>[],
): JSX.Element => {
  const messages = [];

  errors.forEach((error) => {
    if (!error) {
      return;
    }

    if (error instanceof AxiosError && error.response) {
      messages.push(`${error.response.statusText}: ${errorMessage(error)}`);
    } else {
      messages.push(errorMessage(error));
    }
  });

  if (messages.length < 1) {
    messages.push("Please try again later.");
  }

  return <ErrorContent message={messages.join(", ")} />;
};

const defaultRenderUpdating = () => {
  return <Spinner />;
};

export const LoadingView = <
  FirstDataT,
  SecondDataT,
  ThirdDataT,
  T extends
    | FirstDataT
    | [FirstDataT, SecondDataT]
    | [FirstDataT, SecondDataT, ThirdDataT],
>(
  props: PropsT<FirstDataT, SecondDataT, ThirdDataT, T>,
): React.ReactElement => {
  const displayRefetch = props.displayRefetch ?? false;
  const renderUpdating = props.renderUpdating ?? defaultRenderUpdating;
  const renderErrored = (errors: Nullable<Error>[]): React.ReactElement => {
    if (props.renderErrored) {
      const message = errors.map((error) => error?.message ?? "").join(" ");
      return props.renderErrored(message);
    }

    return defaultRenderErrored(errors);
  };

  let isLoading, isRefetching, isErrored, errors: Nullable<Error>[];
  if (!Array.isArray(props.queryResult)) {
    isLoading = props.queryResult.isLoading;
    isRefetching = props.queryResult.isRefetching;
    isErrored = props.queryResult.isError;
    errors = [props.queryResult.error];
  } else {
    isLoading = props.queryResult.some((result) => result.isLoading);
    isRefetching = props.queryResult.some((result) => result.isRefetching);
    isErrored = props.queryResult.some((result) => result.isError);
    errors = props.queryResult.map((result) => result.error);
  }

  const getRenderUpdated = () => {
    if (
      !Array.isArray(props.queryResult) &&
      props.queryResult.data !== undefined
    ) {
      return props.renderUpdated(props.queryResult.data as T);
    } else if (
      Array.isArray(props.queryResult) &&
      props.queryResult.every((result) => result?.data !== undefined)
    ) {
      return props.renderUpdated(props.queryResult.map((p) => p.data) as T);
    }
    return <></>;
  };

  try {
    return isLoading || (displayRefetch && isRefetching)
      ? renderUpdating()
      : isErrored
        ? renderErrored(errors)
        : getRenderUpdated();
  } catch (e) {
    return renderErrored([e as Error]);
  }
};
