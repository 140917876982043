/***
 * NOTE: This is used for pages that are only disabled/protected on the front-end side and this component avoids
 * rendering children and triggering any api calls to the backend. In most cases <LoadingView /> is enough as
 * backend rejects the api call and it renders relevant error info.
 ***/
import React from "react";

import { Spinner } from "src/base-components/Spinner";
import { useCapabilities } from "src/hooks/useCapabilities";
import { CapabilitiesT } from "src/hooks/useCapabilities/types";
import { ErrorPage } from "src/router/ErrorPage";

type PropsT = {
  children: React.ReactNode;
  canAccess: (capabilities: CapabilitiesT) => boolean;
};

export const ProtectedPage: React.FC<PropsT> = ({ children, canAccess }) => {
  const capabilities = useCapabilities();

  if (capabilities.isLoading) {
    return <Spinner />;
  }

  if (canAccess(capabilities)) {
    return <>{children}</>;
  }

  return <ErrorPage message="You're not authorized to view this page." />;
};
