export const STATUS_COLUMN_ID = "statusColumn" as const;
export const INDEX_COLUMN_ID = "indexColumn" as const;
export const RESULT_COLUMN_PREFIX = "resultColumn" as const;
export const AUX_DATA_COLUMN_PREFIX = "auxDataColumn" as const;
export const EXPECTED_DATA_COLUMN_PREFIX = "expectedDataColumn" as const;
export const INTERMEDIATE_TABLE_PREFIXES = [
  RESULT_COLUMN_PREFIX,
  AUX_DATA_COLUMN_PREFIX,
  EXPECTED_DATA_COLUMN_PREFIX,
] as const;
export const ALWAYS_VISIBLE_COLUMNS = [
  STATUS_COLUMN_ID,
  INDEX_COLUMN_ID,
] as const;
