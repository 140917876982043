import React from "react";

import { useProviderManifest } from "src/api/connectApi/queries";
import {
  BeMappedNode,
  ManifestConnectionNode,
} from "src/constants/NodeDataTypes";
import { HelpButton } from "src/nodeEditor/HelpButton";
import { useWorkspaceContext } from "src/router/routerContextHooks";

type HelpButtonProps = {
  selectedNode: BeMappedNode;
};

export const ManifestConnectionNodeHelpButton: React.FC<HelpButtonProps> = ({
  selectedNode,
}) => {
  const { workspace } = useWorkspaceContext();
  const manifest = useProviderManifest(
    workspace.base_url,
    (selectedNode as ManifestConnectionNode).data.providerResource.provider,
    (selectedNode as ManifestConnectionNode).data.providerResource
      .manifest_version,
  ).data;

  const resource = (selectedNode as ManifestConnectionNode).data
    .providerResource.resource;

  const helpButtonUrl =
    manifest?.resources[resource].help_center_documentation_link;

  if (!helpButtonUrl) return null;

  return <HelpButton url={helpButtonUrl} />;
};
