import { Extension } from "micromark-extension-gfm";
import { markdownLineEnding } from "micromark-util-character";
import { codes } from "micromark-util-symbol/codes";
import { HtmlExtension, Code } from "micromark-util-types";

type EmptyLineToken = "nbspToBr";

declare module "micromark-util-types" {
  interface TokenTypeMap {
    nbspToBr: EmptyLineToken;
  }
}

export const emptyLinesSyntaxExtension: Extension = {
  document: {
    [codes.ampersand]: {
      tokenize: function (effects, ok, nok) {
        const self = this;
        let size = 0;

        const atLineStart = () =>
          self.previous === null || markdownLineEnding(self.previous);

        const start = (code: Code) => {
          if (code === codes.ampersand && atLineStart()) {
            effects.enter("nbspToBr");
            effects.consume(code);
            size++;
            return inside;
          }
          return nok(code);
        };

        const inside = (code: Code) => {
          if (
            (code === codes.lowercaseN && size === 1) ||
            (code === codes.lowercaseB && size === 2) ||
            (code === codes.lowercaseS && size === 3) ||
            (code === codes.lowercaseP && size === 4) ||
            (code === codes.semicolon && size === 5)
          ) {
            effects.consume(code);
            size++;
            return size === 6 ? end : inside;
          }
          return nok(code);
        };

        const end = (code: Code) => {
          if (markdownLineEnding(code) || code === codes.eof) {
            effects.exit("nbspToBr");
            return ok(code);
          }
          // If the line doesn't end after '&nbsp;', it's not a valid '&nbsp;' line
          effects.exit("nbspToBr");
          return nok(code);
        };
        return start;
      },
      continuation: {
        tokenize: (_effects, ok, _nok) => ok,
      },
      exit: () => {},
    },
  },
};

// Custom HTML extension to convert recognized `&nbsp;` to `<br />`
export const emptyLinesHtmlExtension: HtmlExtension = {
  enter: {
    nbspToBr() {
      this.tag("<p>");
      this.tag("<br />");
    },
  },
  exit: {
    nbspToBr() {
      this.tag("</p>");
      this.lineEndingIfNeeded();
    },
  },
};
