import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

import { FieldErrorsT } from "src/api/types";
import { Card } from "src/base-components/Card";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import { KeyValueArrayEditor } from "src/customConnectionNode/editorComponents/KeyValueArrayEditor";
import { RawBodyEditor } from "src/customConnectionNode/editorComponents/RawBodyEditor";
import {
  BodyOption,
  CustomConnectionForm,
  KeyValuePair,
} from "src/customConnectionNode/types";
import { checkType } from "src/utils/typeUtils";

type PropsT = { immutable: boolean; runFieldErrors: FieldErrorsT | undefined };

export const BodyEditor: React.FC<PropsT> = ({ immutable, runFieldErrors }) => {
  const formProps = useFormContext<CustomConnectionForm>();

  // Using local state for raw and key-value body values so content
  // is preserved when toggling between the two options, and no BE
  // schema changes are needed.
  const [keyValueState, setKeyValueState] = useState<KeyValuePair[]>([]);
  const [rawState, setRawState] = useState("");

  const onSelectedChanged = (selectedValue: BodyOption) => {
    if (selectedValue === "key_values") {
      setRawState(formProps.getValues("body.value.raw"));
      formProps.setValue("body.value", {
        selected: "key_values",
        key_values: keyValueState,
      });
    }
    if (selectedValue === "raw") {
      setKeyValueState(formProps.getValues("body.value.key_values"));
      formProps.setValue("body.value", {
        selected: "raw",
        raw: rawState,
      });
    }
  };

  return (
    <Card className="w-full">
      <SimpleRadioGroup
        className="mb-2"
        disabled={immutable}
        orientation="horizontal"
        value={formProps.getValues("body.value.selected")}
        onValueChange={(value) => onSelectedChanged(value as BodyOption)}
      >
        <SimpleRadioGroup.Item
          label="Key-value"
          labelClassName="pl-2 mr-4"
          value={checkType<BodyOption>("key_values")}
        />
        <SimpleRadioGroup.Item
          label="Raw"
          labelClassName="pl-2"
          value={checkType<BodyOption>("raw")}
        />
      </SimpleRadioGroup>
      {formProps.getValues("body.value.selected") === "key_values" && (
        <KeyValueArrayEditor
          addButtonText="Add key"
          fieldName="body.value.key_values"
          immutable={immutable}
          runFieldErrors={runFieldErrors}
        />
      )}
      {formProps.getValues("body.value.selected") === "raw" && (
        <RawBodyEditor immutable={immutable} runFieldErrors={runFieldErrors} />
      )}
    </Card>
  );
};
