import { ColumnDef } from "@tanstack/react-table";
import { type RefObject } from "react";
import { isMacOs } from "react-device-detect";
import { useOnClickOutside } from "usehooks-ts";

import { DatasetRow } from "src/api/types";
import {
  useKeyboardNav,
  NavKey,
} from "src/base-components/EditorTable/hooks/useKeyboardNav";
import { FLOATING_WINDOW_CLASS } from "src/datasets/DatasetTable/FloatingWindow";
import {
  parseSelection,
  useDatasetEditTableActions,
  useDatasetTableMode,
} from "src/datasets/DatasetTable/stores";

const isColumnDisabled = (
  colId: Nullable<string>,
  columns: ColumnDef<DatasetRow>[],
) => {
  const col = columns.find((col) => col.id === colId);

  return col?.meta?.archetype?.disabled;
};

export const useKeyboardNavigation = (
  tableRef: RefObject<HTMLElement>,
  {
    rowsLength,
    columns,
  }: { rowsLength: number; columns: ColumnDef<DatasetRow>[] },
) => {
  const tableMode = useDatasetTableMode();
  const { enableEditingMode, moveColumn, moveRow, reset } =
    useDatasetEditTableActions();

  const maxRow = rowsLength - 1;
  const columnIds = columns.map((col) => col.id!);

  useKeyboardNav({
    ref: tableRef,
    callback: (event: KeyboardEvent, dir: Nullable<NavKey>) => {
      if (!dir || tableMode === "view") return;

      // Edit mode
      if (tableMode === "edit") {
        switch (dir) {
          case NavKey.TAB:
            event.preventDefault();
            moveColumn(event.shiftKey ? -1 : 1, columnIds);
            break;

          case NavKey.ESC:
            event.preventDefault();
            reset("editingCellId");
            break;

          case NavKey.ENTER:
            const isJsonEditor = (event.target as any)?.dataset?.loc?.includes(
              "pon-editor",
            );
            if (!isJsonEditor || event.altKey) {
              event.preventDefault();
              moveRow(1, maxRow);
              enableEditingMode((state) => {
                const [_, colId] = parseSelection(state.selected);
                return !isColumnDisabled(colId, columns);
              });
            }
            break;
        }
        return;
      }

      // Select mode
      switch (dir) {
        case NavKey.LEFT:
        case NavKey.RIGHT:
          event.preventDefault();
          const delta = dir === NavKey.LEFT ? -1 : 1;
          const isModifierUsed = isMacOs ? event.metaKey : event.ctrlKey;
          moveColumn(delta * (isModifierUsed ? Infinity : 1), columnIds);
          break;

        case NavKey.UP:
        case NavKey.DOWN:
          event.preventDefault();
          moveRow(dir === NavKey.UP ? -1 : 1, maxRow);
          break;

        case NavKey.TAB:
          event.preventDefault();
          moveColumn(event.shiftKey ? -1 : 1, columnIds);
          break;

        case NavKey.ESC:
          event.preventDefault();
          reset();
          break;

        case NavKey.ENTER:
          event.preventDefault();
          enableEditingMode((state) => {
            const [_, colId] = parseSelection(state.selected);
            return !isColumnDisabled(colId, columns);
          });
          break;
      }
    },
  });

  useOnClickOutside(tableRef, (e) => {
    if (!(e.target as HTMLElement).closest(`.${FLOATING_WINDOW_CLASS}`)) {
      return reset();
    }
  });
};
