import { ReactNode } from "react";

type HeaderProps = React.FC<{ children: ReactNode }>;

const Header: HeaderProps = ({ children }) => (
  <div className="flex min-h-[3.5rem] items-center justify-between border-b border-gray-200 px-6 py-3 text-gray-800 font-inter-semibold-14px">
    {children}
  </div>
);

type BodyProps = React.FC<{ children: ReactNode }>;

const Body: BodyProps = ({ children }) => (
  <div className="relative px-6 py-4 text-gray-800">{children}</div>
);

type SubComponents = {
  Header: HeaderProps;
  Body: BodyProps;
};

type CardType = React.FC<{ children: ReactNode }> & SubComponents;

export const Card: CardType = ({ children }) => (
  <div className="flex flex-col overflow-clip rounded-lg border border-gray-200 bg-white">
    {children}
  </div>
);

Card.Header = Header;
Card.Body = Body;
