import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useAuthoringUIActions } from "src/flowContainer/AuthoringUIContext";
import { URLKeys } from "src/router/SearchParams";
import { useGraphStore } from "src/store/StoreProvider";

export const useSyncSelectedNodeToURL = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setSelectedNode, selectedNode } = useGraphStore();
  const { setNodeEditorOpen } = useAuthoringUIActions();
  const hasSelectedNodeIdParam = searchParams.has(URLKeys.SelectedNodeId);
  // When this component is mounted look for a selected node in the url
  useEffect(() => {
    const selectedNodeId = searchParams.get(URLKeys.SelectedNodeId);
    if (selectedNodeId) {
      setSelectedNode(selectedNodeId);
      setNodeEditorOpen(true);
    }
    // We want to run this hook only when the component mounts to avoid rendering loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Sync the selected node to the URL
  useEffect(() => {
    if (selectedNode) {
      setSearchParams((searchParams) => {
        searchParams.set(URLKeys.SelectedNodeId, selectedNode.id);
        return searchParams;
      });
    } else if (hasSelectedNodeIdParam) {
      setSearchParams((searchParams) => {
        searchParams.delete(URLKeys.SelectedNodeId);
        return searchParams;
      });
    }
  }, [hasSelectedNodeIdParam, selectedNode, selectedNode?.id, setSearchParams]); // We dont want to add search params as dependency to avoid rendering loops
};
