import { useFormContext, Controller } from "react-hook-form";

import { SnowflakeConnectionAuthMethod } from "src/api/connectApi/types";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { SimpleDropDown } from "src/base-components/SimpleDropDown";
import { SnowflakeBasicAuthFieldset } from "src/connections/config/database/snowflake/SnowflakeBasicAuthFieldset";
import { SnowflakeKeyPairAuthFieldset } from "src/connections/config/database/snowflake/SnowflakeKeyPairAuthFieldset";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { TestInvocationToggle } from "src/connections/config/shared/TestInvocationToggle";
import { errorProps } from "src/connections/config/shared/errorProps";
import {
  SnowflakeConnectionConfigInputsT,
  Environment,
} from "src/connections/types";

type SnowflakeAuthTypeSelectElement = {
  key: SnowflakeConnectionAuthMethod;
  value: string;
};

const authMethods: SnowflakeAuthTypeSelectElement[] = [
  { key: "key_pair", value: "Key-pair authentication" },
  { key: "basic_auth", value: "Basic authentication" },
];

const renderAuthFields = (
  authMethod: SnowflakeConnectionAuthMethod,
  environment: Environment,
) => {
  if (authMethod === "basic_auth") {
    return <SnowflakeBasicAuthFieldset environment={environment} />;
  } else if (authMethod === "key_pair") {
    return <SnowflakeKeyPairAuthFieldset environment={environment} />;
  }
};

type EnvironmentConfigPropsT = {
  environment: Environment;
  labelsPrefix: string;
};

export const SnowflakeEnvironmentConfig: React.FC<EnvironmentConfigPropsT> = ({
  environment,
  labelsPrefix,
}) => {
  const {
    formState: { errors },
    register,
    watch,
    control,
  } = useFormContext<SnowflakeConnectionConfigInputsT>();
  const envPrefix = `${environment}Config` as const;
  const envErrors = errors[envPrefix];
  const enableNonProdConfigs = watch("enableNonProdConfigs");
  const authMethod = watch(`${envPrefix}.authMethod`);
  return (
    <>
      <FormItem
        gap="sm"
        helpTooltip={
          <div>
            The identifier of the Snowflake account to connect to. When in
            doubt, ask your organization's database administrator.
          </div>
        }
        label={
          labelsPrefix
            ? `${labelsPrefix} account identifier`
            : "Account identifier"
        }
        isRequired
      >
        {isFieldErrored(envErrors, "accountIdentifier") && (
          <ErrorHint>{envErrors?.accountIdentifier?.message}</ErrorHint>
        )}
        <Input
          data-loc={`${environment}-snowflake-connection-account-identifier`}
          placeholder="e.g. ab12345.eu-central-1"
          fullWidth
          {...errorProps(isFieldErrored(envErrors, "accountIdentifier"))}
          {...register(`${envPrefix}.accountIdentifier`, {
            required: "Account identifier is required",
          })}
        />
      </FormItem>
      <FormItem
        gap="sm"
        helpTooltip={
          <div>
            The name of the virtual warehouse to connect to. When in doubt, ask
            your organization's database administrator.
          </div>
        }
        label={
          labelsPrefix ? `${labelsPrefix} warehouse name` : "Warehouse name"
        }
        isRequired
      >
        {isFieldErrored(envErrors, "warehouseName") && (
          <ErrorHint>{envErrors?.warehouseName?.message}</ErrorHint>
        )}
        <Input
          data-loc={`${environment}-snowflake-connection-warehouse-name`}
          placeholder="e.g. EXAMPLE_WAREHOUSE"
          fullWidth
          {...errorProps(isFieldErrored(envErrors, "warehouseName"))}
          {...register(`${envPrefix}.warehouseName`, {
            required: "Warehouse name is required",
          })}
        />
      </FormItem>
      <FormItem
        gap="sm"
        helpTooltip={
          <div>
            The name of the default database to connect to. When in doubt, ask
            your organization's database administrator.
          </div>
        }
        label={labelsPrefix ? `${labelsPrefix} database name` : "Database name"}
        isRequired
      >
        {isFieldErrored(envErrors, "databaseName") && (
          <ErrorHint>{envErrors?.databaseName?.message}</ErrorHint>
        )}
        <Input
          data-loc={`${environment}-snowflake-connection-database-name`}
          placeholder="e.g. EXAMPLE_DATABASE"
          fullWidth
          {...errorProps(isFieldErrored(envErrors, "databaseName"))}
          {...register(`${envPrefix}.databaseName`, {
            required: "Database name is required",
          })}
        />
      </FormItem>
      <FormItem
        gap="sm"
        helpTooltip={
          <div>
            The name of the default database schema. When in doubt, ask your
            organization's database administrator.
          </div>
        }
        label={
          labelsPrefix ? `${labelsPrefix} database schema` : "Database schema"
        }
      >
        {isFieldErrored(envErrors, "databaseSchema") && (
          <ErrorHint>{envErrors?.databaseSchema?.message}</ErrorHint>
        )}
        <Input
          data-loc={`${environment}-snowflake-connection-database-schema`}
          placeholder="e.g. EXAMPLE_SCHEMA"
          fullWidth
          {...errorProps(isFieldErrored(envErrors, "databaseSchema"))}
          {...register(`${envPrefix}.databaseSchema`)}
        />
      </FormItem>
      <FormItem
        gap="sm"
        helpTooltip={
          <div>
            Name of the default role to use. When in doubt, ask your
            organization's database administrator.
          </div>
        }
        label={labelsPrefix ? `${labelsPrefix} user role` : "User role"}
      >
        {isFieldErrored(envErrors, "userRole") && (
          <ErrorHint>{envErrors?.userRole?.message}</ErrorHint>
        )}
        <Input
          data-loc={`${environment}-snowflake-connection-user-role`}
          placeholder="e.g. EXAMPLE_ROLE"
          fullWidth
          {...errorProps(isFieldErrored(envErrors, "userRole"))}
          {...register(`${envPrefix}.userRole`)}
        />
      </FormItem>
      <FormItem
        description="The authentication mechanism required for the database"
        gap="sm"
        label="Select authentication type"
        isRequired
      >
        <Controller
          control={control}
          name={`${envPrefix}.authMethod`}
          render={(props) => (
            <SimpleDropDown
              buttonClassName="pl-3"
              buttonDataLoc={`${environment}-snowflake-connection-auth-type`}
              className="h-8 w-full"
              elements={authMethods}
              itemsClassNames="w-full"
              itemsWidth="w-full"
              placeholder="Select authentication type"
              placement="bottomLeft"
              selectedKey={String(props.field.value)}
              onSelect={(value) => {
                props.field.onChange(value);
              }}
            />
          )}
        />
      </FormItem>
      {renderAuthFields(authMethod, environment)}
      {environment === "production" && (
        <TestInvocationToggle<SnowflakeConnectionConfigInputsT>
          control={control}
          enableNonProdConfigs={enableNonProdConfigs}
          type="database"
        />
      )}
    </>
  );
};
