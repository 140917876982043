import React, { useCallback } from "react";

import { CodeEditor } from "src/base-components/CodeInput/CodeEditor";
import { SimpleNodeDataT } from "src/constants/NodeDataTypes";
import {
  NodeEditorBaseProps,
  NodeEditorCompletionContext,
} from "src/nodeEditor/NodeEditor";

type PropsT = {
  src?: string;
  language: "sql" | "python" | undefined;
  placeholder?: string;
  onChange: (value: string | undefined) => void;
} & Omit<NodeEditorBaseProps<SimpleNodeDataT>, "onUpdate">;

export const CodeNodeEditor: React.FC<PropsT> = ({
  src,
  language,
  onChange,
  immutable,
  isReactive,
  displayedError,
  placeholder,
}) => {
  const onCodeChange = useCallback(
    (codeText: string | undefined) => {
      if (!isReactive && src !== codeText) {
        onChange(codeText ?? "");
      }
    },
    [isReactive, src, onChange],
  );

  const errorHighlightedLine =
    displayedError?.loc_type === "line" &&
    typeof displayedError?.loc === "number"
      ? displayedError?.loc
      : undefined;
  return (
    <NodeEditorCompletionContext.Consumer>
      {({ defaultCompletion }) => {
        return (
          <CodeEditor
            completionExtension={defaultCompletion}
            dataLoc="code-editor"
            disabled={immutable}
            errorHighlightedLine={errorHighlightedLine}
            language={language}
            placeholder={placeholder}
            value={src}
            onChange={onCodeChange}
          />
        );
      }}
    </NodeEditorCompletionContext.Consumer>
  );
};
