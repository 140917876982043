import { DecisionEnvironment } from "src/api/types";

export type JobStatus = "active" | "inactive";

export type JobProbabilisticTrafficPolicyVersionEntry = {
  flow_version_id: string;
  percentage: number;
};

export type JobProbabilisticTrafficPolicy = {
  type: "probabilistic";
  flow_versions: JobProbabilisticTrafficPolicyVersionEntry[];
};
export type JobTrafficPolicy = {
  id: string;
  name: string;
  created_at: string;
  created_by: string;
  policy: JobProbabilisticTrafficPolicy;
  stable_routing: boolean;
};

export type Job = {
  id: string;
  name: string;
  description?: string;
  status: JobStatus;
  flow_id: string;
  flow_version_id?: string;
  schedule?: Nullable<string>;
  schedule_timezone?: Nullable<string>;
  active_source?: JobSource;
  active_traffic_policy?: JobTrafficPolicy;
  active_destination?: JobDestination;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  etag: string;
  last_run_at?: string;
  live_runs_count: number;
  sandbox_runs_count: number;
  configuration?: JobConfiguration;
};

export type JobConfigurationRateLimit = {
  rate_limit: number;
  rate_limit_interval: JobRateLimitInterval;
  execution_concurrency?: null;
};

export type JobConfigurationExecutionConcurrency = {
  execution_concurrency: number;
  rate_limit?: null;
  rate_limit_interval?: null;
};

export type JobConfiguration =
  | JobConfigurationRateLimit
  | JobConfigurationExecutionConcurrency;

export enum JobRateLimitInterval {
  MILLISECOND = "MILLISECOND",
  SECOND = "SECOND",
  MINUTE = "MINUTE",
  HOUR = "HOUR",
}

type JobSourceBase = {
  id: string;
  etag: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  job_id: string;
  name: string;
};

export type DatasetJobSourceConfiguration = {
  type: "dataset";
  dataset_id: string;
};

export type S3BucketDatasetJobSourceConfiguration = {
  type: "s3_bucket_dataset";
  s3_bucket_dataset_id: string;
};

type SQLJobSourceConfigurationMappingEntry = {
  db_field: Nullable<string>;
  flow_input_field: string;
};

export type SQLJobSourceConfiguration = {
  type: "sql";
  connection_id: Nullable<string>;
  query: Nullable<string>;
  mapping: SQLJobSourceConfigurationMappingEntry[];
  metadata_mapping: SQLJobSourceConfigurationMappingEntry[];
  credentials_for_sandbox_run: "sandbox_credentials" | "live_credentials";
};

export type JobSQLSource = JobSourceBase & {
  configuration: SQLJobSourceConfiguration;
};

export type JobDatasetSource = JobSourceBase & {
  configuration: DatasetJobSourceConfiguration;
};

export type JobS3BucketDatasetSource = JobSourceBase & {
  configuration: S3BucketDatasetJobSourceConfiguration;
};

export type JobSource =
  | JobSQLSource
  | JobDatasetSource
  | JobS3BucketDatasetSource;

export type CreateJobDestination = Pick<
  JobDestination,
  "name" | "configuration"
>;

type JobDestinationBase = {
  id: string;
  etag: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  job_id: string;
  name: string;
};

export type JobDBDestinationConfiguration = {
  type: "sql";
  connection_id?: string;
  table_prefix?: string;
  credentials_for_sandbox_run?: "live_credentials" | "sandbox_credentials";
  mapping: {
    db_field: string;
    flow_output_field: string;
  }[];
};

export type JobS3BucketDestinationConfiguration = {
  type: "s3_bucket";
};

export type JobDestinationDB = JobDestinationBase & {
  configuration: JobDBDestinationConfiguration;
};

export type JobDestinationS3Bucket = JobDestinationBase & {
  configuration: JobS3BucketDestinationConfiguration;
};

export type JobDestination = JobDestinationDB | JobDestinationS3Bucket;

export type JobWithVersionsUsed = Job & {
  versionsUsed: { versionName: string; percentage: number }[];
};

export enum JobRunStatusType {
  Completed = "completed",
  Running = "running",
  Failed = "failed",
  Stopped = "stopped",
  Writing = "writing",
}

type CompletedJobRunStatus = {
  type: JobRunStatusType.Completed;
  completed_at?: string;
};

type RunningJobRunStatus = {
  type: JobRunStatusType.Running;
};

type FailedJobRunStatus = {
  type: JobRunStatusType.Failed;
  error_title: string;
  error_details: string;
};

type StoppedJobRunStatus = {
  type: JobRunStatusType.Stopped;
  stopped_by: string;
};

type WritingJobRunStatus = {
  type: JobRunStatusType.Writing;
};

export type JobRunStatus =
  | CompletedJobRunStatus
  | RunningJobRunStatus
  | FailedJobRunStatus
  | StoppedJobRunStatus
  | WritingJobRunStatus;

type ManualJobRunTrigger = {
  type: "manual";
  triggered_by: string;
};

type ScheduleJobRunTrigger = {
  type: "schedule";
  schedule: string;
  service_account_id: string;
};

type JobTrigger = ManualJobRunTrigger | ScheduleJobRunTrigger;

export type JobRun = {
  id: string;
  created_at: string;
  updated_at: string;
  job_id: string;
  job_etag: string;
  flow_version_id?: string;
  traffic_policy?: JobTrafficPolicy;
  source_id: string;
  source_name: string;
  source_etag: string;
  source_type: "dataset";
  destination_id: string;
  destination_name: string;
  destination_etag: string;
  destination_type: JobDestination["configuration"]["type"];
  environment: DecisionEnvironment;
  trigger: JobTrigger;
  status: JobRunStatus;
  total_rows_count: number;
  success_rows_count: number;
  failed_rows_count: number;
  pending_rows_count: number;
};
