import {
  ConnectionResourceTemplate,
  makeConnectionTemplate,
} from "src/connections/types";

const orderReasonConfigKey: ConnectionResourceTemplate["configKeys"][number] = {
  key: "order_reason",
  name: "Order reason",
  type: "simple_selection",
  required: false,
  elements: [
    { key: "6332", value: "Credit decision" },
    { key: "6333", value: "Solvency assessment for intended business" },
    { key: "6334", value: "Solvency assessment for ongoing business" },
    { key: "6335", value: "Debt collection" },
    { key: "6336", value: "Commercial credit insurance" },
    { key: "6337", value: "Insurance contract" },
    { key: "6338", value: "Leasing agreement" },
    { key: "6339", value: "Rental agreement" },
  ],
};
export const DnbTemplate = makeConnectionTemplate({
  provider: "dnb",
  secrets: [
    { key: "customer_key", name: "Customer key", required: true },
    { key: "customer_secret", name: "Customer secret", required: true },
  ],
  resources: {
    identity_resolution: {
      name: "Identity Resolution",
      configKeys: [
        {
          key: "in_language",
          name: "Language",
          type: "simple_selection",
          required: false,
          elements: [
            { key: "auto", value: "Auto" },
            { key: "ja-JP", value: "Japanese" },
            { key: "zh-hans-CN", value: "Simplified Chinese" },
            { key: "zh-hant-TW", value: "Traditional Chinese" },
            { key: "zh-hant-HK", value: "Hong Kong" },
            { key: "ko-hang-KR", value: "Hangul" },
            { key: "en-US", value: "English" },
          ],
        },
      ],
    },
    credit_report: {
      name: "Credit Report",
      configKeys: [orderReasonConfigKey],
    },
    custom_report: {
      name: "Custom Report",
      configKeys: [
        orderReasonConfigKey,
        {
          key: "block_ids",
          name: "Block IDs",
          required: true,
          type: "multiple_selection",
          minItems: 1,
          elements: [
            {
              key: "businessactivityinsight_L1_v1",
              value: "Business activity insights L1",
            },
            {
              key: "businessactivityinsight_L2_v1",
              value: "Business activity insights L2",
            },
            {
              key: "businessactivityinsight_L3_v1",
              value: "Business activity insights L3",
            },
            { key: "companyfinancials_L1_v2", value: "Company financials L1" },
            { key: "companyfinancials_L2_v2", value: "Company financials L2" },
            { key: "companyfinancials_L3_v2", value: "Company financials L3" },
            { key: "companyfinancials_L4_v2", value: "Company financials L4" },
            {
              key: "companyfinancials_thirdparty_v1",
              value: "Company financials third party",
            },
            { key: "companyinfo_L1_v1", value: "Company info L1" },
            { key: "companyinfo_L2_v1", value: "Company info L2" },
            { key: "companyinfo_L3_v1", value: "Company info L3" },
            { key: "companyinfo_L4_v1", value: "Company info L4" },
            {
              key: "companyinfo_advgeoposition_v1",
              value: "Company info advanced geoposition",
            },
            { key: "diversityinsight_L1_v1", value: "Diversity insights L1" },
            { key: "diversityinsight_L2_v1", value: "Diversity insights L2" },
            { key: "diversityinsight_L3_v1", value: "Diversity insights L3" },
            { key: "dtri_L1_v1", value: "Derived trade insights L1" },
            { key: "dtri_L2_v1", value: "Derived trade insights L2" },
            { key: "dtri_L3_v1", value: "Derived trade insights L3" },
            { key: "esginsights_L3_v1", value: "ESG insights" },
            {
              key: "externaldisruptioninsight_L1_v1",
              value: "External disruption insights L1",
            },
            {
              key: "externaldisruptioninsight_L2_v1",
              value: "External disruption insights L2",
            },
            {
              key: "externaldisruptioninsight_L3_v1",
              value: "External disruption insights L3",
            },
            {
              key: "externaldisruptioninsight_L4_v1",
              value: "External disruption insights L4",
            },
            { key: "eventfilings_L1_v1", value: "Filings and events L1" },
            { key: "eventfilings_L2_v1", value: "Filings and events L2" },
            { key: "eventfilings_L3_v1", value: "Filings and events L3" },
            {
              key: "financialstrengthinsight_L1_v1",
              value: "Financial strengths insights L1",
            },
            {
              key: "financialstrengthinsight_L2_v1",
              value: "Financial strengths insights L2",
            },
            {
              key: "financialstrengthinsight_L3_v1",
              value: "Financial strengths insights L3",
            },
            {
              key: "financialstrengthinsight_L4_v1",
              value: "Financial strengths insights L4",
            },
            {
              key: "financialstrengthinsight_L5_v1",
              value: "Financial strengths insights L5",
            },
            {
              key: "globalbusinessranking_L1_v1",
              value: "Global business ranking L1",
            },
            { key: "globalfinancials_L1_v1", value: "Global financials L1" },
            { key: "globalfinancials_L2_v1", value: "Global financials L2" },
            {
              key: "hierarchyconnections_L1_v1",
              value: "Hierarchies and connections L1",
            },
            {
              key: "hierarchyconnections_alternative_v1",
              value: "Hierarchies and connections ALT",
            },
            {
              key: "hierarchyconnections_eli_v1",
              value: "Hierarchies and connections ELI",
            },
            { key: "inquiryinsight_L1_v1", value: "Inquiry insights L1" },
            { key: "inquiryinsight_L2_v1", value: "Inquiry insights L2" },
            { key: "inquiryinsight_L3_v1", value: "Inquiry insights L3" },
            { key: "inquiryinsight_L4_v1", value: "Inquiry insights L4" },
            { key: "ownershipinsight_L1_v1", value: "Ownership insights L1" },
            { key: "paymentinsight_L1_v1", value: "Payment insights L1" },
            { key: "paymentinsight_L2_v1", value: "Payment insights L2" },
            { key: "paymentinsight_L3_v1", value: "Payment insights L3" },
            { key: "paymentinsight_L4_v1", value: "Payment insights L4" },
            {
              key: "principalscontacts_L1_v2",
              value: "Principals and contacts L1",
            },
            {
              key: "principalscontacts_L2_v2",
              value: "Principals and contacts L2",
            },
            {
              key: "principalscontacts_L3_v2",
              value: "Principals and contacts L3",
            },
            {
              key: "principalscontacts_L4_v2",
              value: "Principals and contacts L4",
            },
            {
              key: "salesmarketinginsight_L1_v2",
              value: "Sales marketing insights L1",
            },
            {
              key: "salesmarketinginsight_L2_v2",
              value: "Sales marketing insights L2",
            },
            {
              key: "salesmarketinginsight_L3_v2",
              value: "Sales marketing insights L3",
            },
            {
              key: "salesmarketinginsight_foottraffic_v2",
              value: "Sales marketing insights foottraffic",
            },
            { key: "shippinginsight_L1_v1", value: "Shipping insights L1" },
            {
              key: "supplychainriskindex_L1_v1",
              value: "Supply chain risk index L1",
            },
            {
              key: "thirdpartyriskinsight_L1_v1",
              value: "Third party risk insights L1",
            },
          ],
        },
      ],
    },
  },
});
