import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { useFormContext } from "react-hook-form";

import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Icon } from "src/base-components/Icon";
import { Textarea } from "src/base-components/Textarea";
import { Tooltip } from "src/base-components/Tooltip";
import {
  BigQuerySQLConnectionConfigInputsT,
  BigQuerySQLConnectionConfigT,
  Environment,
} from "src/connections/types";

type SecretPropsT = {
  secretConfigField: keyof BigQuerySQLConnectionConfigT["serviceAccountConfig"];
  secretName: string;
  placeholder: string;
  helpText: string;
  isRequired?: boolean;
  environment: Environment;
};

const validateServiceAccountKey = (value: string | null) => {
  if (!value) {
    return "Service account key is required";
  }
  try {
    const serviceAccount = JSON.parse(value);
    if (serviceAccount.type !== "service_account") {
      return "Invalid service account key type";
    }
  } catch (e) {
    return "Invalid service account key format";
  }
  return true;
};

export const BigQuerySQLServiceAccountKey: React.FC<SecretPropsT> = ({
  secretConfigField,
  secretName,
  placeholder,
  helpText,
  isRequired,
  environment,
}) => {
  const {
    formState: { errors },
    watch,
    setValue,
    register,
  } = useFormContext<BigQuerySQLConnectionConfigInputsT>();

  const secretKey =
    `${environment}Config.serviceAccountConfig.${secretConfigField}` as const;
  const fieldError =
    errors?.[`${environment}Config`]?.["serviceAccountConfig"]?.[
      secretConfigField
    ]?.value;

  const secretRecord = watch(secretKey);

  const enableSecret = () => {
    setValue(secretKey, { ...secretRecord, secret: false, value: null });
  };

  return (
    <FormItem
      gap="xs"
      helpClassName="mt-1"
      helpTooltip={helpText}
      isRequired={isRequired}
      label={secretName}
    >
      {Boolean(fieldError) && <ErrorHint>{fieldError?.message}</ErrorHint>}
      <div className="flex w-full items-start gap-x-2">
        <div className="flex-1">
          <Textarea
            disabled={secretRecord.secret}
            errored={Boolean(fieldError)}
            {...register(`${secretKey}.value`, {
              required: isRequired ? `${secretName} is required` : false,
              validate: validateServiceAccountKey,
            })}
            placeholder={placeholder}
            fullWidth
          />
        </div>
        {secretRecord.secret && (
          <Tooltip
            align="center"
            placement="right"
            title={`Edit ${secretName.toLowerCase()}`}
            asChild
          >
            <Icon
              color="text-gray-500"
              icon={faEdit}
              size="xs"
              onClick={enableSecret}
            />
          </Tooltip>
        )}
      </div>
    </FormItem>
  );
};
