import React, { useCallback, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";

import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { TextEditor } from "src/base-components/TextEditor/TextEditor";
import { Toolbar } from "src/base-components/TextEditor/Toolbar";
import { CommentDb } from "src/clients/flow-api";

type Props = {
  dataLoc?: string;
  onSave: (value: string, review_id?: string) => void;
  autoFocus?: boolean;
  clearOnSave?: boolean;
  isSaving?: boolean;
  onCancel?: () => void;
  comment?: CommentDb;
  buttonText?: string;
  reviewId?: string;
};

export const CommentEditor: React.FC<Props> = ({
  dataLoc,
  comment,
  autoFocus = false,
  clearOnSave = false,
  isSaving = false,
  buttonText = "Save",
  reviewId,
  onCancel,
  onSave,
}) => {
  const [value, setValue] = useState(comment?.content || "");
  const toolbarRef = useRef<HTMLUListElement>(null);
  const [isFocused, setFocused] = useState(false);
  const [addToReview, setAddToReview] = useState(true);

  const handleFocus = useCallback(() => setFocused(true), []);
  const handleBlur = useCallback(() => setFocused(false), []);
  const reviewCheckbox = (
    <Checkbox
      checked={addToReview}
      className="mr-2"
      dataLoc="review-comment-check"
      onChange={(evt) =>
        setAddToReview((evt.target as HTMLInputElement).checked)
      }
    />
  );
  return (
    <div>
      <div
        className={twJoin(
          "flex flex-col rounded border border-gray-200 bg-white p-3",
          isFocused && "border-indigo-600 ring-2 ring-indigo-500/25",
        )}
      >
        <TextEditor
          autoFocus={autoFocus}
          dataLoc={dataLoc}
          placeholder="Add comment here..."
          toolbarRef={toolbarRef}
          value={value}
          onBlur={handleBlur}
          onChange={setValue}
          onFocus={handleFocus}
        />
        <div className="flex justify-between gap-x-3 pt-3">
          <Toolbar ref={toolbarRef} exclude={["heading"]} faded={!isFocused} />
          <div className="flex gap-x-3">
            {onCancel && (
              <Button size="sm" variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button
              dataLoc={`${dataLoc}-save-button`}
              disabled={isSaving}
              loading={isSaving}
              size="sm"
              variant="primary"
              onClick={() => {
                onSave(value, addToReview ? reviewId : undefined);
                clearOnSave && setValue("");
              }}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
      {reviewId && (
        <div className="flex items-center pt-3 text-gray-800 font-inter-normal-13px">
          {reviewCheckbox}
          Add this comment to review process
        </div>
      )}
    </div>
  );
};
