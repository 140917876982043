import { isEqual } from "lodash";
import { useCallback } from "react";

import { DecisionTableForm } from "src/decisionTableNode/DecisionTableNodeEditor";
import {
  addDefaultCase,
  AddDefaultCaseAction,
  addDefaultCondition,
  AddDefaultConditionAction,
  addDefaultResult,
  AddDefaultResultAction,
  DecisionTableActions,
  deleteCase,
  DeleteCaseAction,
  deleteCondition,
  DeleteConditionAction,
  deleteResult,
  DeleteResultAction,
  duplicateCase,
  DuplicateCaseAction,
  duplicateCondition,
  DuplicateConditionAction,
  duplicateResult,
  DuplicateResultAction,
  insertCaseAbove,
  InsertCaseAboveAction,
  insertCaseBelow,
  InsertCaseBelowAction,
  insertConditionLeft,
  InsertConditionLeftAction,
  insertConditionRight,
  InsertConditionRightAction,
  insertResultLeft,
  InsertResultLeftAction,
  insertResultRight,
  InsertResultRightAction,
  MoveCaseAction,
  moveCase,
} from "src/decisionTableNode/actions";
import { assertUnreachable } from "src/utils/typeUtils";

export type DecisionTableAction =
  | InsertCaseAboveAction
  | InsertCaseBelowAction
  | DuplicateCaseAction
  | DeleteCaseAction
  | InsertConditionLeftAction
  | InsertConditionRightAction
  | DuplicateConditionAction
  | DeleteConditionAction
  | InsertResultLeftAction
  | InsertResultRightAction
  | DuplicateResultAction
  | DeleteResultAction
  | AddDefaultCaseAction
  | AddDefaultConditionAction
  | AddDefaultResultAction
  | MoveCaseAction
  | { type: "reset"; payload: DecisionTableForm };

const tableActionsReducer = (
  state: DecisionTableForm,
  action: DecisionTableAction,
) => {
  switch (action.type) {
    case DecisionTableActions.INSERT_CASE_ABOVE:
      return insertCaseAbove(state, action.payload);

    case DecisionTableActions.INSERT_CASE_BELOW:
      return insertCaseBelow(state, action.payload);

    case DecisionTableActions.DUPLICATE_CASE:
      return duplicateCase(state, action.payload);

    case DecisionTableActions.DELETE_CASE:
      return deleteCase(state, action.payload);

    case DecisionTableActions.INSERT_CONDITION_LEFT:
      return insertConditionLeft(state, action.payload);

    case DecisionTableActions.INSERT_CONDITION_RIGHT:
      return insertConditionRight(state, action.payload);

    case DecisionTableActions.DUPLICATE_CONDITION:
      return duplicateCondition(state, action.payload);

    case DecisionTableActions.DELETE_CONDITION:
      return deleteCondition(state, action.payload);

    case DecisionTableActions.INSERT_RESULT_LEFT:
      return insertResultLeft(state, action.payload);

    case DecisionTableActions.INSERT_RESULT_RIGHT:
      return insertResultRight(state, action.payload);

    case DecisionTableActions.DUPLICATE_RESULT:
      return duplicateResult(state, action.payload);

    case DecisionTableActions.DELETE_RESULT:
      return deleteResult(state, action.payload);

    case DecisionTableActions.ADD_DEFAULT_CASE:
      return addDefaultCase(state);

    case DecisionTableActions.ADD_DEFAULT_CONDITION:
      return addDefaultCondition(state);

    case DecisionTableActions.ADD_DEFAULT_RESULT:
      return addDefaultResult(state);

    case DecisionTableActions.MOVE_CASE:
      return moveCase(state, action.payload);

    case "reset":
      return {
        ...state,
        ...action.payload,
      };

    default:
      assertUnreachable(action);
      return state;
  }
};

export const useDecisionTableActionsReducer = (
  nodeData: DecisionTableForm,
  onUpdate: (nodeData: DecisionTableForm) => void,
) => {
  return useCallback(
    (action: DecisionTableAction) => {
      const updatedNode = tableActionsReducer(nodeData, action);

      if (!isEqual(nodeData, updatedNode)) {
        onUpdate(updatedNode);
      }
    },
    [nodeData, onUpdate],
  );
};
