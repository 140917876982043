import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { FormItem } from "src/base-components/FormItem";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";

type TestInvocationForm = {
  productionConfig: {
    allowTestInvocations: boolean;
  };
};

const ToggleText = {
  database: {
    envAwareLabel:
      "Allow authors to hit production environment during testing?",
    defaultLabel: "Allow authors to hit this connection during testing?",
    helpText:
      "This allows Decision Flow authors to call this database in test runs and sandbox Job runs",
  },
  webhook: {
    envAwareLabel:
      "Allow to use production environment in sandbox flow executions?",
    defaultLabel: "Allow to use this connection in sandbox flow executions?",
    helpText:
      "This allows to wait for Inbound Webhook during sandbox flow executions",
  },
};

type TestInvocationTogglePropsT<T extends FieldValues> = {
  enableNonProdConfigs: boolean;
  control: Control<T>;
  type: "database" | "webhook";
};

export const TestInvocationToggle = <T extends TestInvocationForm>({
  enableNonProdConfigs,
  control,
  type,
}: TestInvocationTogglePropsT<T>) => {
  const toggleText = ToggleText[type];

  return (
    <FormItem
      gap="sm"
      label={
        enableNonProdConfigs
          ? toggleText.envAwareLabel
          : toggleText.defaultLabel
      }
      isRequired
    >
      <div className="mb-4 text-gray-500 font-inter-normal-12px">
        {toggleText.helpText}
      </div>
      <Controller
        control={control}
        name={"productionConfig.allowTestInvocations" as Path<T>}
        render={(props) => (
          <SimpleRadioGroup
            orientation="vertical"
            value={String(props.field.value)}
            onValueChange={(value) => {
              props.field.onChange(value === "true");
            }}
          >
            <SimpleRadioGroup.Item
              label="No, do not allow"
              labelClassName="pl-2.5"
              value="false"
            />
            <SimpleRadioGroup.Item
              label="Yes, allow"
              labelClassName="pl-2.5"
              value="true"
            />
          </SimpleRadioGroup>
        )}
      />
    </FormItem>
  );
};
