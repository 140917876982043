import CrsIcon from "src/assets/CrsIcon.svg?react";
import {
  getDefaultCRSBusinessProfileReport,
  getDefaultCRSExperianBusinessSearch,
  getDefaultCRSExperianCreditReport,
  getDefaultCRSIntellisenseBusinessReport,
  getDefaultCRSLexisNexisBridgerSearch,
  getDefaultCrsTransunionCreditReport,
  getDefaultCrsTransunionIMVBasic,
} from "src/connections/connectionConfigs/crs/crsResources";
import { CrsTemplate } from "src/connections/connectionConfigs/crs/crsTemplate";
import { ConnectionConfig } from "src/connections/connectionConfigs/types";

export const CRS_CONFIG: ConnectionConfig<"crs"> = {
  icon: CrsIcon,
  name: "CRS",
  description: "The proven leader in credit API technology",
  template: CrsTemplate,
  resources: {
    experian_business_profile_report: {
      externalLink:
        "https://www.notion.so/taktile/Experian-Business-Profile-Report-53f16396e5364faba161c21db394daeb?pvs=4",
      integrationResource: getDefaultCRSBusinessProfileReport,
    },
    experian_business_search: {
      externalLink:
        "https://www.notion.so/taktile/Experian-How-to-search-for-a-Company-618a6a5b2cd64b6ab0e4b725e7870d75?pvs=4",
      integrationResource: getDefaultCRSExperianBusinessSearch,
    },
    experian_credit_report: {
      externalLink:
        "https://www.notion.so/taktile/Experian-Credit-Report-00b4895da113463299a43d39387fe369?pvs=4",
      integrationResource: getDefaultCRSExperianCreditReport,
    },
    experian_intelliscore_business_report: {
      externalLink:
        "https://www.notion.so/taktile/Experian-Intelliscore-Business-Report-8c4491e7b2034e24a7b3ca91ce047bf3?pvs=4",
      integrationResource: getDefaultCRSIntellisenseBusinessReport,
    },
    transunion_credit_report: {
      externalLink:
        "https://www.notion.so/taktile/TransUnion-Credit-Report-44ea3c880b904c67876b9ba868c98805?pvs=4",
      integrationResource: getDefaultCrsTransunionCreditReport,
    },
    lexisnexis_bridger_search: {
      externalLink:
        "https://www.notion.so/taktile/LexisNexis-Bridger-Search-fe8bb20b37d44d5bb054d20d5398e689?pvs=4",
      integrationResource: getDefaultCRSLexisNexisBridgerSearch,
    },
    transunion_imv_basic: {
      externalLink:
        "https://www.notion.so/taktile/TruValidate-Identity-Manager-Verification-98168def0ed9430fb186097be9fa0155?pvs=4",
      integrationResource: getDefaultCrsTransunionIMVBasic,
    },
  },
};
