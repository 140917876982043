import { micromark } from "micromark";
import { gfm } from "micromark-extension-gfm";
import { gfmAutolinkLiteralHtml } from "micromark-extension-gfm-autolink-literal";
import { gfmFootnoteHtml } from "micromark-extension-gfm-footnote";
import { gfmTagfilterHtml } from "micromark-extension-gfm-tagfilter";
import { combineHtmlExtensions } from "micromark-util-combine-extensions";
import { HtmlExtension } from "micromark-util-types";
import { NodeHtmlMarkdown, TranslatorConfigObject } from "node-html-markdown";

import {
  emptyLinesHtmlExtension,
  emptyLinesSyntaxExtension,
} from "src/base-components/TextEditor/emptyLinesExtension";

const DEFAULT_TRANSLATORS: TranslatorConfigObject = {
  pre: {
    prefix: "\n```\n",
    postfix: "```",
    noEscape: true,
  },
};

const KEEP_EMPTY_LINES_TRANSLATORS: TranslatorConfigObject = {
  ...DEFAULT_TRANSLATORS,
  br: {
    content: "&nbsp;",
    recurse: false,
  },
};

export const gfmStrikethroughHtml: HtmlExtension = {
  enter: {
    strikethrough() {
      this.tag("<s>");
    },
  },
  exit: {
    strikethrough() {
      this.tag("</s>");
    },
  },
};

const gfmHtml = combineHtmlExtensions([
  gfmAutolinkLiteralHtml,
  gfmFootnoteHtml({}),
  gfmStrikethroughHtml,
  gfmTagfilterHtml,
]);

type ToMarkdownOptions = {
  keepEmptyLines?: boolean;
};

export const mdService = {
  toMarkdown: (html: string, options: ToMarkdownOptions = {}) => {
    return NodeHtmlMarkdown.translate(
      html,
      {},
      options.keepEmptyLines
        ? KEEP_EMPTY_LINES_TRANSLATORS
        : DEFAULT_TRANSLATORS,
    ).trim();
  },
  toHtml: (markdown: string) =>
    micromark(markdown, {
      extensions: [gfm(), emptyLinesSyntaxExtension],
      htmlExtensions: [gfmHtml, emptyLinesHtmlExtension],
    }).trim(),
};
