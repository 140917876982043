import React from "react";

import { FlowVersionStatusT } from "src/api/flowTypes";
import { Card } from "src/base-components/Card";
import { VersionCardFooter } from "src/nodeEditor/SelectFlowPane/FlowVersionCard";
import { FlowNodeCurrentChildVersion } from "src/parentFlowNodes/flowNode/FlowNodeEditor";
import { VersionStatusDotIndicator } from "src/utils/flowVersion";

type Props = {
  version: FlowNodeCurrentChildVersion;
  flowName: string;
};

export const SelectedVersion: React.FC<Props> = ({ flowName, version }) => {
  const isPublished = version.status === FlowVersionStatusT.PUBLISHED;
  const date =
    (isPublished ? version.meta.published_at : version.updated_at) ??
    version.updated_at;
  const userId =
    (isPublished ? version.meta.published_by_id : version.meta.updated_by_id) ??
    version.meta.created_by_id ??
    "";

  return (
    <Card className="mb-4 w-full">
      <Card.Header className="mb-3">
        <div className="flex flex-row items-center gap-x-1">
          <VersionStatusDotIndicator size="2xs" version={version} />
          <span
            className="text-gray-800 font-inter-semibold-13px"
            data-loc="version-card-title"
          >
            {`${flowName}, ${version.name}`}
          </span>
        </div>
      </Card.Header>
      <Card.Content>
        <p
          className="text-gray-500 font-inter-normal-12px"
          data-loc="version-card-description"
        >
          {version.meta.release_note || "No description"}
        </p>
        <VersionCardFooter
          date={date}
          isPublished={isPublished}
          userId={userId}
        />
      </Card.Content>
    </Card>
  );
};
