import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef } from "react";

import { useCreateDefaultNodeChart } from "src/analytics/queries";
import { prefetchConnections } from "src/api/connectApi/queries";
import { ChangeHistoryPane } from "src/changeHistory/ChangeHistoryPane";
import { CommentsPane } from "src/comments/CommentPane";
import { FlowNodeDataT, LoopNodeDataT } from "src/constants/NodeDataTypes";
import { RightPanes, useRightPane } from "src/flowContainer/hooks/useRightPane";
import { ReviewProcessPane } from "src/flowReview/ReviewProcessPane";
import { useCanAuthoringEditFlowVersion } from "src/hooks/useCanAuthoringEditFlowVersion";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { AddNodePane } from "src/nodeAdding/AddNodePane";
import { NodeDetailsSidePaneWrapper as NodeDetailsSidePane } from "src/nodeEditor/NodeDetailsSidePane";
import { NodeEditorOptions } from "src/router/SearchParams";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { AddNodeParamsT } from "src/store/GraphStore";
import { useGraphStore } from "src/store/StoreProvider";
import { isParentNode } from "src/utils/predicates";

// Box-border to allow the different side panes to use padding without distorting the commons width
export const rightSidePaneWidthClassname =
  "w-[35vw] min-w-[464px] box-border" as const;

export const rightSidePaneWidthWideClassname =
  "w-[35vw] min-w-[600px] box-border" as const;

export const RightPane: React.FC = observer(() => {
  const {
    clickedVisibleEdgeId,
    setClickedVisibleEdgeId,
    addNode,
    selectedNode,
    nodesArray,
    updateNode,
  } = useGraphStore();
  const { rightPane, setRightPane, setRightPaneTab } = useRightPane();
  const closeCreateNodePane = () => {
    setClickedVisibleEdgeId(null);
  };

  const { workspace, orgId, flow, version } = useAuthoringContext();
  const selectedNodeRef = useRef(selectedNode);
  const updateNodeRef = useRef(updateNode);

  // update refs
  selectedNodeRef.current = selectedNode;
  updateNodeRef.current = updateNode;

  useEffect(() => {
    const workspaceBaseUrl = workspace.base_url;
    if (workspaceBaseUrl) {
      prefetchConnections(workspaceBaseUrl);
    }
  }, [workspace]);

  // closes right pane if a node is selected; as node editor should be displayed
  useEffect(() => {
    if (selectedNode) {
      setRightPane(null);
    }
  }, [selectedNode, setRightPane]);

  const canEdit = useCanAuthoringEditFlowVersion();

  // TODO: Could be improved once we remove mobx
  // Not the best idea to have flow/loop node specific logic here, but it's unreliable to do it at FlowNodeEditor level
  // since it's get destroyed as soon as selectedNode is undefined
  useEffect(() => {
    return () => {
      const currentSelectedNode = selectedNodeRef.current;
      const currentUpdateNode = updateNodeRef.current;
      if (!currentSelectedNode) {
        return;
      }

      if (
        isParentNode(currentSelectedNode) &&
        !currentSelectedNode.data.is_stale &&
        canEdit
      ) {
        currentUpdateNode<FlowNodeDataT | LoopNodeDataT>({
          nodeId: currentSelectedNode.id,
          newData: {
            ...currentSelectedNode.data,
            is_stale: true,
          },
        });
      }
    };
  }, [canEdit, selectedNode?.id]);

  const createDefaultNodeChart = useCreateDefaultNodeChart();

  const handleAddNode = async (params: AddNodeParamsT) => {
    try {
      const nodeId = await addNode(params);
      setRightPaneTab(NodeEditorOptions.Logic);

      if (nodeId && isFeatureFlagEnabled(FEATURE_FLAGS.nodeAnalytics)) {
        createDefaultNodeChart(params.nodeType, nodeId);
      }

      tracker.emit(
        trackingEvents.createNewNode({
          node_type: params.nodeType,
          flow_id: flow.id,
          flow_version_id: version.id,
          organization_id: orgId,
          provider:
            "providerResource" in params
              ? params.providerResource.provider
              : undefined,
          resource:
            "providerResource" in params
              ? params.providerResource.resource
              : undefined,
          connection_id:
            "connectionId" in params ? params.connectionId : undefined,
          resource_config_id:
            "resourceConfigId" in params ? params.resourceConfigId : undefined,
        }),
      );
    } catch (err) {}
  };

  const handleClose = useCallback(() => {
    setRightPane(null);
  }, [setRightPane]);

  if (clickedVisibleEdgeId) {
    return (
      <AddNodePane
        addNode={handleAddNode}
        flow={flow}
        nodes={nodesArray}
        workspace={workspace}
        onCloseButtonClick={closeCreateNodePane}
      />
    );
  }

  switch (rightPane) {
    case RightPanes.Comments:
      return <CommentsPane onClose={handleClose} />;

    case RightPanes.ChangeHistory:
      return <ChangeHistoryPane onClose={handleClose} />;

    case RightPanes.ReviewProcess:
      return <ReviewProcessPane onClose={handleClose} />;

    default:
      return <NodeDetailsSidePane />;
  }
});
