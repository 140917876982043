import { AxiosResponse } from "axios";

import { FlowRouterMlNodeEndpoint } from "src/api/endpoints";
import * as s3Api from "src/api/s3";
import { ParsedModelDataBET, ParsedModelDataErrorT } from "src/api/types";

type UploadedModelDataT = {
  modelId: string;
};

export const uploadModel = async (
  baseUrl: string,
  flowSlug: string,
  file: File,
): Promise<UploadedModelDataT> => {
  const {
    data: { model_id, ...preSignedUrlData },
  } = await FlowRouterMlNodeEndpoint.getSignedUrl(baseUrl, flowSlug);

  await s3Api.uploadFile(preSignedUrlData, file);

  return { modelId: model_id };
};

const isError = (
  response: AxiosResponse<ParsedModelDataBET>,
): response is AxiosResponse<{ result: ParsedModelDataErrorT }> =>
  response.status === 200 && response.data.result.type === "error";

export const getParsedModel = async (
  baseUrl: string,
  flowSlug: string,
  modelId: string,
) => {
  const response = await FlowRouterMlNodeEndpoint.getParsedModelData(
    baseUrl,
    flowSlug,
    modelId,
  );

  if (isError(response)) {
    return Promise.reject(response.data.result.error);
  }

  return response.data.result;
};
