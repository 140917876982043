import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { useFieldArray, useFormContext } from "react-hook-form";

import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Tooltip } from "src/base-components/Tooltip";
import { isSecretFieldRequired } from "src/connections/model/model";
import {
  AvailableEnvironmentPrefixes,
  ConnectionConfigInputsT,
} from "src/connections/types";

type AvailableNames =
  | "oauth2Config.authorize_query_params"
  | "oauth2Config.authorize_headers"
  | "oauth2Config.refresh_query_params"
  | "oauth2Config.refresh_headers"
  | "headers"
  | "secrets";

type Prefixed<T extends string> = `${AvailableEnvironmentPrefixes}${T}`;

export type PropsT = {
  name: Prefixed<AvailableNames>;
  cta: string;
  dataLoc?: string;
  showHeader?: boolean;
  keyPlaceholder?: string;
  valuePlaceholder?: string;
  isSensitive?: boolean;
};

export const KeyValuePairField: React.FC<PropsT> = ({
  name,
  cta,
  dataLoc,
  showHeader = true,
  keyPlaceholder = "Key",
  valuePlaceholder = "Value",
  isSensitive = false,
}) => {
  const { register, control, getValues } =
    useFormContext<ConnectionConfigInputsT>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name,
  });

  return (
    <>
      {showHeader && fields.length > 0 && (
        <div className="flex w-full gap-x-4 pb-1">
          <label className="inline-block w-full font-inter-normal-12px">
            Key
          </label>
          <label className="inline-block w-full font-inter-normal-12px">
            Value
          </label>
          <div className="w-10" />
        </div>
      )}
      {fields.map(
        (field, index) =>
          field.value !== null && (
            <div key={field.id} className="mb-2 flex w-full gap-x-4">
              <Input
                data-loc={`${name}.${index}.key`}
                disabled={field.secret || field.required}
                {...register(`${name}.${index}.key`)}
                placeholder={keyPlaceholder}
                fullWidth
              />
              <Input
                data-loc={`${name}.${index}.value`}
                disabled={field.secret}
                {...register(`${name}.${index}.value`, {
                  required: field.required,
                })}
                placeholder={valuePlaceholder}
                type={isSensitive ? "password" : "text"}
                fullWidth
              />
              <div className="flex">
                <Tooltip
                  activated={field.required}
                  align="center"
                  placement="top"
                  title="Cannot delete required secret"
                  asChild
                >
                  <Icon
                    color={
                      field.required
                        ? "text-gray-200"
                        : "hover:cursor-pointer text-gray-500"
                    }
                    disabled={field.required}
                    icon={faTrashAlt}
                    size="xs"
                    onClick={() => {
                      // If the field is a secret, we don't want to remove it from the array
                      // but instead set the value to null which will trigger a deletion
                      // on the backend side.
                      if (isSensitive) {
                        // Not the best way of inferring the environment,
                        // especially once we support multiple environments,
                        // but it's the simplest implementation for current needs.
                        const isEnvironmentSecret = name.includes(
                          "nonProdEnvConfigs.sandbox.",
                        );
                        const authMethod = getValues(
                          isEnvironmentSecret
                            ? "nonProdEnvConfigs.sandbox.authMethod"
                            : "authMethod",
                        );
                        const fieldIsRequired = isSecretFieldRequired(
                          authMethod,
                          field.key,
                        );

                        const toDelete = fields.findIndex(
                          (f) => f.id === field.id,
                        );

                        if (fieldIsRequired) {
                          // If field is required, we want to reset its value to empty string
                          // so that the users can fill it in again.
                          update(toDelete, {
                            ...field,
                            value: "",
                            secret: false,
                            required: true,
                          });
                        } else {
                          update(toDelete, { ...field, value: null });
                        }
                      } else {
                        remove(index);
                      }
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          ),
      )}
      <Button
        dataLoc={dataLoc}
        iconLeft={faPlus}
        size="base"
        variant="secondary"
        onClick={() => append({ key: "", value: "" })}
      >
        {cta}
      </Button>
    </>
  );
};
