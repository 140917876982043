import { omit } from "lodash";
import { createContext, useContext } from "react";
import { createStore, useStore } from "zustand";
import { immer } from "zustand/middleware/immer";

import {
  HEADER_ROW_ID,
  parseCellId,
} from "src/base-components/EditorTable/hooks/useSelectionHandler";
import {
  CellIndex,
  ErrorCellIndex,
} from "src/base-components/EditorTable/types";
import { coordinateToKey } from "src/base-components/EditorTable/utils";

export type CellId = `${string}_${string}`;

/**
 * Editor table editing/selected store
 */
type EditorTableNavState = {
  editingCell: Nullable<CellId>;
  selectedCell: Nullable<CellId>;
  readonlyCellIds: CellId[];
  hiddenHeaders: boolean;
  errors: Record<string, string>;
  hoveredRow: Nullable<CellIndex<any>["row"]>;
  hoveredCol: Nullable<CellIndex<any>["col"]>;
};

type EditorTableNavActions = {
  setSelectedCell: (cellId: Nullable<CellId>) => void;
  setEditingCell: (cellId: Nullable<CellId>) => void;
  setReadonlyCellIds: (ids: CellId[]) => void;
  setHiddenHeaders: (hidden: boolean) => void;
  setErrors: <T>(errors: ErrorCellIndex<T>[]) => void;
  setError: (key: string, message: string | null) => void;
  setHoveredCellIndex: <T>(
    row: Nullable<CellIndex<T>["row"]>,
    col: Nullable<CellIndex<T>["col"]>,
  ) => void;
};

const canSelect = (cellId: CellId, state: EditorTableNavState) => {
  const [row] = parseCellId(cellId);
  if (state.hiddenHeaders && row === HEADER_ROW_ID) {
    return false;
  }
  return !state.readonlyCellIds.includes(cellId);
};

const mapCoordinatesToKeyErrors = <T>(errors?: ErrorCellIndex<T>[]) => {
  if (errors) {
    return Object.fromEntries(
      (errors ?? []).map(({ row, col, message }) => [
        coordinateToKey(row, col),
        message,
      ]),
    );
  }

  return {};
};

type StoreState = ZustandStore<EditorTableNavState, EditorTableNavActions>;

export const getTableNavStore = () =>
  createStore(
    immer<StoreState>((set) => ({
      editingCell: null,
      selectedCell: null,
      hiddenHeaders: false,
      readonlyCellIds: [],
      errors: {},
      hoveredRow: null,
      hoveredCol: null,
      actions: {
        setSelectedCell: (cellIndex) =>
          set((state) => {
            if (cellIndex && !canSelect(cellIndex, state)) {
              return;
            }
            state.editingCell = null;
            state.selectedCell = cellIndex;
          }),
        setEditingCell: (cellIndex) =>
          set((state) => {
            if (cellIndex && !canSelect(cellIndex, state)) {
              return;
            }
            if (cellIndex) {
              state.errors = omit(state.errors, cellIndex);
            }

            state.selectedCell = cellIndex;
            state.editingCell = cellIndex;
          }),
        setReadonlyCellIds: (ids) =>
          set((state) => {
            state.readonlyCellIds = ids;
          }),
        setHiddenHeaders: (hidden) =>
          set((state) => {
            state.hiddenHeaders = hidden;
          }),
        setErrors: (errors) => {
          set((state) => {
            state.errors = mapCoordinatesToKeyErrors(errors);
          });
        },
        setError: (key: string, message: string | null) => {
          set((state) => {
            if (message) {
              state.errors[key] = message;
            } else {
              state.errors = omit(state.errors, key);
            }
          });
        },
        setHoveredCellIndex: (hoveredRow, hoveredCol) =>
          set({ hoveredRow, hoveredCol }),
      },
    })),
  );

export const StoreContext = createContext<ReturnType<
  typeof getTableNavStore
> | null>(null);

export const useTableNavStore = <U>(selector: (state: StoreState) => U) => {
  const store = useContext(StoreContext);

  if (!store) {
    throw new Error("StoreContext not found!!");
  }
  return useStore(store, selector);
};

export const useTableNavActions = () =>
  useTableNavStore((state) => state.actions);

export const useCellIsEditing = (id: CellId) =>
  useTableNavStore((state) => state.editingCell === id);

export const useCellIsSelected = (id: CellId) =>
  useTableNavStore((state) => id === state.selectedCell);

export const useCellIsReadOnly = (id: CellId) =>
  useTableNavStore((state) => state.readonlyCellIds.includes(id));

export const useCellError = (id: CellId) =>
  useTableNavStore((state) => state.errors[id]);

export const useIsRowHovered = (row: Nullable<string>) =>
  useTableNavStore((state) => state.hoveredRow === row);

export const useIsColHovered = (col: string | number | symbol) =>
  useTableNavStore((state) => state.hoveredCol === col);
