import axios, { AxiosRequestConfig, Axios } from "axios";

import { pathJoin } from "src/api/paths";

const DEFAULT_CONFIG = {
  headers: {
    "Content-Type": "application/json",
  },
};

/**
 * @class ApiInstance
 * @description Abstract wrapper class around axios
 * that allows to define `pathPrefix` parameter
 * which will be inserted between baseURL and url to all requests.
 * @example
 * const api = new ApiInstance({ pathPrefix: "/api/v1" });
 *
 * // will send GET request to /api/v1/test
 * api.get("/test");
 * // will send GET request to https://example.com/api/v1/test
 * api.get("/test", { baseURL: "example.com" });
 * // will send GET request to https://example.com/api/v1/test
 * api.get("/test", { baseURL: "https://example.com" });
 *
 * // will send POST request to /api/v1/test
 * api.post("/test");
 * api.post("/test", { thisIsData: "" });
 * api.post("/test", { thisIsData: "" }, { thisIsConfig: "" });
 */

export class ApiInstance {
  readonly pathPrefix: string;

  constructor({ pathPrefix = "" }) {
    this.pathPrefix = pathPrefix;
  }

  private buildURL(url: string) {
    return pathJoin(this.pathPrefix, url);
  }

  private getConfig({ baseURL, ...config }: AxiosRequestConfig = {}) {
    const preparedBaseURL = baseURL
      ? baseURL.startsWith("http")
        ? baseURL
        : `https://${baseURL}`
      : undefined;

    return {
      ...DEFAULT_CONFIG,
      ...config,
      baseURL: preparedBaseURL,
    };
  }

  get: Axios["get"] = (url, config) =>
    axios.get(this.buildURL(url), this.getConfig(config));

  delete: Axios["delete"] = (url, config) =>
    axios.delete(this.buildURL(url), this.getConfig(config));

  post: Axios["post"] = (url, data, config) =>
    axios.post(this.buildURL(url), data, this.getConfig(config));

  put: Axios["put"] = (url, data, config) =>
    axios.put(this.buildURL(url), data, this.getConfig(config));

  patch: Axios["patch"] = (url, data, config) =>
    axios.patch(this.buildURL(url), data, this.getConfig(config));
}
