import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { faPen, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { useFormContext } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { MonospacedInput } from "src/base-components/MonospacedInput";
import { ReorderHandle } from "src/base-components/ReorderHandle";
import {
  ParameterForm,
  ParametersFormT,
} from "src/globalParameters/GlobalParametersList";
import { HighlightParametersToggleButton } from "src/globalParameters/HighlightParametersToggleButton";

export type GlobalParametersCardPropsT = {
  parameter: ParameterForm;
  index: number;
  id: string;
  onDelete: () => void;
  onEdit: () => void;
  onEditValue: () => void;
  readonly: boolean;
  usageData?: string[];
};

export const GlobalParametersCard: React.FC<GlobalParametersCardPropsT> = ({
  parameter,
  index,
  id,
  readonly,
  onDelete,
  onEdit,
  onEditValue,
  usageData,
}) => {
  const { register } = useFormContext<ParametersFormT>();
  const { listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({
      id: id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const baseDataLoc = `${parameter.name}-card`;

  return (
    <div
      ref={setNodeRef}
      className={twJoin(
        "relative w-full border-b border-gray-200 pb-4",
        isDragging ? "rounded border" : "last:border-b-0",
        !readonly && "hover:bg-gray-50",
      )}
      data-loc={baseDataLoc}
      style={style}
    >
      <div className="mt-4 pl-9 pr-4">
        <div className="mb-2">
          <p
            className={twJoin(
              "mb-0.5 truncate text-gray-800 font-inter-semibold-13px",
              readonly ? "pr-24" : "pr-40",
            )}
            data-loc={`${baseDataLoc}-name`}
          >
            {parameter.name}
          </p>
          {parameter.description && (
            <p
              className="line-clamp-3 w-66 text-gray-500 font-inter-normal-12px"
              data-loc={`${baseDataLoc}-description`}
            >
              {parameter.description}
            </p>
          )}
        </div>
        <div onClick={!readonly ? onEditValue : undefined}>
          <MonospacedInput
            className={twJoin(!readonly && "cursor-pointer")}
            containerClassName="grow"
            data-loc={`${baseDataLoc}-value`}
            formProps={register(`params.${index}.expr`)}
            placeholder="Enter Parameter value here..."
            codeColors
            disabled
          />
        </div>
      </div>
      <ReorderHandle
        className="absolute left-3 top-3 pt-1"
        immutable={readonly}
        listeners={listeners}
      />
      <div className="absolute right-3 top-3 flex h-5 flex-row items-center">
        {usageData !== undefined && (
          <HighlightParametersToggleButton
            parameterName={parameter.name}
            usageData={usageData}
          />
        )}
        <div className="mr-2 mt-1">
          <Icon
            color={twJoin("text-gray-400", !readonly && "hover:text-gray-700")}
            disabled={readonly}
            icon={faPen}
            size="xs"
            onClick={onEdit}
          />
        </div>
        <div className="mr-1 mt-1">
          <Icon
            color={twJoin("text-gray-400", !readonly && "hover:text-gray-700")}
            disabled={readonly}
            icon={faTrashCan}
            size="xs"
            onClick={onDelete}
          />
        </div>
      </div>
    </div>
  );
};
