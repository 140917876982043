import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import { useFormContext } from "react-hook-form";

import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { KeyValuePairField } from "src/connections/config/KeyValuePairField";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import {
  AvailableEnvironmentPrefixes,
  ConnectionConfigInputsT,
} from "src/connections/types";

type PropsT = {
  environmentPrefix: AvailableEnvironmentPrefixes;
};

export const ApiKeyFieldset: React.FC<PropsT> = ({ environmentPrefix }) => {
  const {
    formState: { errors: formErrors },
    setValue,
    register,
    watch,
  } = useFormContext<ConnectionConfigInputsT>();

  // This will have to change once we support multiple environments
  const errors =
    environmentPrefix === ""
      ? formErrors?.apiKeyConfig
      : formErrors.nonProdEnvConfigs?.sandbox?.apiKeyConfig;

  const api_key_as_query_param = watch(
    `${environmentPrefix}apiKeyConfig.api_key_as_query_param`,
  );

  const radioValue = api_key_as_query_param ? "queryParam" : "header";

  const apiKeyMethodChange = (newVal: string) => {
    // Convert string back to boolean and update state
    setValue(
      `${environmentPrefix}apiKeyConfig.api_key_as_query_param`,
      newVal === "queryParam",
    );
  };

  return (
    <>
      <FieldsetCard>
        <FormItem gap="sm">
          <KeyValuePairField
            cta="Add secret"
            dataLoc="add-secret-button"
            name={`${environmentPrefix}secrets`}
            showHeader={false}
            isSensitive
          />
        </FormItem>
      </FieldsetCard>
      <FieldsetCard>
        <FormItem gap="xs" label="Send API key as">
          <label className="font-normal-13px mb-2 flex text-gray-700">
            <SimpleRadioGroup
              className="mr-2"
              orientation="horizontal"
              value={radioValue}
              onValueChange={apiKeyMethodChange}
            >
              <SimpleRadioGroup.Item
                label="Header"
                labelClassName="pl-1.5 mr-8"
                value="header"
              />
              <SimpleRadioGroup.Item
                label="Query parameter"
                labelClassName="pl-1.5"
                value="queryParam"
              />
            </SimpleRadioGroup>
          </label>
        </FormItem>
        <div className="mb-4 h-[1px] w-full bg-gray-200"></div>
        {api_key_as_query_param ? (
          <FormItem
            description="The name of the query parameter used for API key authentication."
            gap="xs"
            label="Parameter name"
            isRequired
          >
            {Boolean(errors?.query_param_name) && (
              <ErrorHint>{errors?.query_param_name?.message}</ErrorHint>
            )}
            <Input
              errored={isFieldErrored(errors, "query_param_name")}
              suffixIcon={
                !!isFieldErrored(errors, "query_param_name")
                  ? { icon: faWarning }
                  : undefined
              }
              fullWidth
              {...register(
                `${environmentPrefix}apiKeyConfig.query_param_name`,
                {
                  required: "Parameter name is required",
                },
              )}
              placeholder="e.g. apiKey"
            />
          </FormItem>
        ) : (
          <>
            <FormItem
              description="The name of the header used to transport the auth token."
              gap="xs"
              label="Transport header"
            >
              <Input
                errored={isFieldErrored(errors, "header")}
                fullWidth
                {...register(`${environmentPrefix}apiKeyConfig.header`)}
                placeholder="e.g. x-api-key"
              />
            </FormItem>
            <FormItem
              description="A prefix to send along with the token in the transport header."
              gap="xs"
              label="Prefix"
            >
              <Input
                errored={isFieldErrored(errors, "prefix")}
                fullWidth
                {...register(`${environmentPrefix}apiKeyConfig.prefix`)}
                placeholder="e.g. Bearer"
              />
            </FormItem>
          </>
        )}
      </FieldsetCard>
    </>
  );
};
