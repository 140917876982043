import React from "react";
import { useSearchParams } from "react-router-dom";

import { EditModal } from "src/datasets/EditModal";
import { useDatasets } from "src/datasets/api/queries";
import { useSchemas } from "src/datasets/utils";
import { FlowSideBar } from "src/flowContainer/FlowSideBar/FlowSideBar";
import { URLKeys, useSetEditDatasetId } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";

const useActiveEditDataset = () => {
  const [searchParams] = useSearchParams();
  const { flow, workspace } = useAuthoringContext();
  const editDatasetId = searchParams.get(URLKeys.EditDatasetId);

  const datasetsQuery = useDatasets({
    flowId: flow.id,
    baseUrl: workspace.base_url,
  });

  return editDatasetId !== null && datasetsQuery.data
    ? datasetsQuery.data.find((d) => d.id === editDatasetId)
    : null;
};

export const AuthoringLayout: React.FC<{
  Header: React.ReactNode;
  children: React.ReactNode;
}> = ({ Header, children }) => {
  const { version } = useAuthoringContext();

  const schemas = useSchemas(version);

  const activeEditDataset = useActiveEditDataset();
  const setEditDatasetId = useSetEditDatasetId();

  return (
    <div className="flex h-full w-full flex-col justify-stretch">
      {Header}
      <div className="flex min-h-0 flex-auto">
        <FlowSideBar />
        <div className="w-full overflow-auto bg-gray-50">{children}</div>
      </div>
      <EditModal
        dataset={activeEditDataset ?? undefined}
        open={!!activeEditDataset}
        schemas={schemas}
        version={version}
        onClose={() => setEditDatasetId(null)}
      />
    </div>
  );
};
