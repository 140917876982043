import React from "react";
import { twJoin } from "tailwind-merge";

import { CRSResourceT, ProviderT, ResourceT } from "src/api/connectApi/types";
import ExperianIcon from "src/assets/ExperianIcon.svg?react";
import LexisNexisIcon from "src/assets/LexisNexisIcon.svg?react";
import TransunionIcon from "src/assets/TransunionIcon.svg?react";
import { ProviderIcon } from "src/connections/config/Icon";
import { assertUnreachable } from "src/utils/typeUtils";

type PropsT = {
  provider: ProviderT | string;
  resource: ResourceT | string;
  style?: React.CSSProperties;
  className?: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
};

export const ResourceIcon: React.FC<PropsT> = ({
  provider,
  resource,
  className,
  size = "md",
}) => {
  const iconClasses = twJoin(
    size === "xs" && "h-[14px] w-[14px]",
    size === "sm" && "h-[20px] w-[20px] rounded-[3px]",
    size === "md" && "h-[24px] w-[24px]",
    size === "lg" && "h-[28px] w-[28px]",
    size === "xl" && "h-[40px] w-[40px]",
    size === "xxl" && "h-[48px] w-[48px]",
    className,
  );
  if (provider === "crs") {
    const crsResource = resource as CRSResourceT;
    switch (crsResource) {
      case "transunion_imv_basic":
      case "transunion_credit_report": {
        return <TransunionIcon className={iconClasses} />;
      }
      case "experian_business_profile_report":
      case "experian_business_search":
      case "experian_credit_report":
      case "experian_intelliscore_business_report": {
        return <ExperianIcon className={iconClasses} />;
      }
      case "lexisnexis_bridger_search": {
        return <LexisNexisIcon className={iconClasses} />;
      }
      default:
        assertUnreachable(crsResource);
        return <></>;
    }
  } else {
    return <ProviderIcon provider={provider} size={size} />;
  }
};
