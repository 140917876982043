import { ColumnDef } from "@tanstack/react-table";
import { twJoin } from "tailwind-merge";

import { TableComp as Table } from "src/base-components/Table";
import { HeaderCell } from "src/dataTable/HeaderCell";
import { RawCell } from "src/dataTable/RawCell";

const movingElement = (
  <div
    className="absolute inset-0 -translate-x-full transform bg-gradient-to-r from-gray-100 via-gray-50 to-gray-100"
    style={{ animation: "shimmer 1s infinite" }}
  />
);
const grayCell = (additionalClassnames?: string) => (
  <RawCell
    cell={
      <div className="relative h-4 w-full overflow-hidden rounded bg-gray-100">
        {movingElement}
      </div>
    }
    className={twJoin("flex h-10 items-center pr-3", additionalClassnames)}
  />
);
const columns: ColumnDef<{}>[] = [
  {
    id: "decision_id",
    size: 43,
    header: () => (
      <HeaderCell className="pl-3">
        <span className="inline-block">ID</span>
      </HeaderCell>
    ),
    cell: () => grayCell("pl-2 w-[36px]"),
  },
  {
    id: "datetime",
    size: 127,
    header: () => <HeaderCell>Time</HeaderCell>,
    cell: () => grayCell("w-[115px]"),
  },
  {
    id: "status_code",
    size: 127,
    header: () => (
      <HeaderCell>
        <span>Status</span>
      </HeaderCell>
    ),
    cell: () => grayCell("w-[115px]"),
  },
];

for (let i = 0; i < 4; i++) {
  columns.push({
    id: i.toString(),
    header: () => <HeaderCell className="h-10"></HeaderCell>,
    cell: () => grayCell(),
  });
}

export const TableSkeleton = () => {
  return (
    <Table
      columns={columns}
      data={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
      frameClassName="overflow-hidden"
      noScroll
    />
  );
};
