import { useEffect } from "react";

import { useUserData } from "src/store/AuthStore";

export const useBootTheena = () => {
  const {
    signed_in_email,
    signed_in_user_id,
    signed_in_full_name,
    hashed_email,
  } = useUserData();

  // Boot the Theena client when the user has signed in
  useEffect(() => {
    if (signed_in_email && hashed_email) {
      setTimeout(() => {
        (window as any).thena.setEmail({
          email: signed_in_email,
          hashedEmail: hashed_email,
        });
      }, 2000);
    }
  }, [signed_in_full_name, signed_in_user_id, signed_in_email, hashed_email]);
};

export const toggleThenaWidget = () => {
  const thenaIframeElement = window.document.getElementById(
    "thena-iframe-element",
  ) as HTMLIFrameElement | null;
  thenaIframeElement?.contentDocument?.dispatchEvent(
    new CustomEvent("TOGGLE_THENA_WIDGET"),
  );
};
