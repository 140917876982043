// Disabling in this file because I want a more comment friendly style

/* eslint-disable sonarjs/prefer-single-boolean-return */
import { debounce } from "lodash";
import { useEffect, useState } from "react";

import { CM_CREATE_EVENT } from "src/base-components/CodeInput/useOnCreateEvent";
import { highlightElement } from "src/changeHistory/DiffViewModal/highlightElement";

/**
 * Compares different properties of two elements to determine if they carry the same data.
 */
const domPropertiesChanged = (before: Element, after: Element) => {
  // Checkbox case
  if (
    "checked" in before &&
    "checked" in after &&
    before.checked !== after.checked
  ) {
    return true;
  }
  // Text input case
  if ("value" in before && "value" in after && before.value !== after.value) {
    return true;
  }
  // headless UI switch (e.g. on integration nodes)
  if (
    ("ariaChecked" in before &&
      "ariaChecked" in after &&
      before.ariaChecked !== after.ariaChecked) ||
    before.getAttribute("aria-checked") !== after.getAttribute("aria-checked")
  ) {
    return true;
  }
  // fontawesome icons
  if (before.getAttribute("data-icon") !== after.getAttribute("data-icon")) {
    return true;
  }
  return false;
};

const hasChanged = (before: Element, after: Element) => {
  if (before.textContent !== after.textContent) {
    return true;
  }
  // Check for differences not findable via textcontent
  const toBeCheckedForDomProps = "input, select, textarea, button";
  const beforeChildInputs = [
    before,
    ...Array.from(before.querySelectorAll(toBeCheckedForDomProps)),
  ];
  const afterChildInputs = [
    after,
    ...Array.from(after.querySelectorAll(toBeCheckedForDomProps)),
  ];
  if (beforeChildInputs.length !== afterChildInputs.length) {
    return true;
  }
  for (let i = 0; i < beforeChildInputs.length; i++) {
    const childInputBefore = beforeChildInputs[i];
    const childInputAfter = afterChildInputs[i];
    if (domPropertiesChanged(childInputBefore, childInputAfter)) {
      return true;
    }
  }
  return false;
};

const isDataLocLeaf = (element: Element) => {
  return element.querySelectorAll("[data-loc]").length === 0;
};

const highlightDiffInLocNesting = (
  beforeNesting: Map<string, Element>,
  afterNesting: Map<string, Element>,
) => {
  for (const [key, beforeElement] of beforeNesting.entries()) {
    const afterElement = afterNesting.get(key);
    if (afterElement) {
      afterNesting.delete(key);
      if (
        isDataLocLeaf(beforeElement) &&
        isDataLocLeaf(afterElement) &&
        hasChanged(beforeElement, afterElement)
      ) {
        highlightElement({
          element: afterElement,
          reason: "changed",
          highlightChildren: true,
        });
      }
    } else {
      highlightElement({
        element: beforeElement,
        reason: "removed",
        highlightChildren: isDataLocLeaf(beforeElement),
      });
    }
  }
  for (const [_key, afterElement] of afterNesting.entries()) {
    highlightElement({
      element: afterElement,
      reason: "added",
      highlightChildren: isDataLocLeaf(afterElement),
    });
  }
};

const getLocNesting = (
  dataLocElements: NodeListOf<Element>,
  containerId: string,
) => {
  const dataLocNodes = new Map<string, Element>();
  Array.from(dataLocElements).forEach((node) => {
    let locPath = [node.getAttribute("data-loc")];
    let current = node.parentElement;
    let currentDataLoc = current?.getAttribute("data-loc");
    while (!(current?.id === containerId)) {
      if (currentDataLoc) {
        locPath.push(currentDataLoc);
      }
      current = current?.parentElement ?? null;
      currentDataLoc = current?.getAttribute("data-loc");
    }
    const pathFlat = locPath.reverse().join(", ");
    dataLocNodes.set(pathFlat, node);
  });
  return dataLocNodes;
};

const applyDiffHighlighting = (
  beforeElementId: string,
  afterElementId: string,
) => {
  const beforeEditor = document.getElementById(beforeElementId);
  const afterEditor = document.getElementById(afterElementId);
  if (beforeEditor && afterEditor) {
    const beforeNesting = getLocNesting(
      beforeEditor.querySelectorAll("[data-loc]"),
      beforeElementId,
    );
    const afterNesting = getLocNesting(
      afterEditor.querySelectorAll("[data-loc]"),
      afterElementId,
    );
    highlightDiffInLocNesting(beforeNesting, afterNesting);
  }
};
export const useDiffHighlighting = (
  beforeElementId: string,
  afterElementId: string,
) => {
  const [codeMirrorLoadCount, setCodeMirrorLoadCount] = useState(0);

  // Apply highlighting on initial render and whenever the code mirror is loaded
  useEffect(() => {
    applyDiffHighlighting(beforeElementId, afterElementId);
  }, [codeMirrorLoadCount, afterElementId, beforeElementId]);

  useEffect(() => {
    const callback = debounce(() => {
      setCodeMirrorLoadCount((count) => count + 1);
    }, 100);

    document.addEventListener(CM_CREATE_EVENT, callback);
    return () => {
      document.removeEventListener(CM_CREATE_EVENT, callback);
    };
  }, []);
};
