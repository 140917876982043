import {
  faChevronLeft,
  faChevronRight,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import React, { MouseEvent } from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/base-components/Tooltip";

type ErrorSelectorProps = {
  setPreviousError: (e: MouseEvent) => void;
  setNextError: (e: MouseEvent) => void;
  displayedFailureArrayIndex: number;
  errors_count: number;
  displayedMessage: string;
  failureCount: number | undefined;
  resultsOutdated?: boolean;
  outdatedTooltipTitle?: string;
  action?: {
    text: string;
    onClick: () => void;
  };
};

export const ErrorSelector: React.FC<ErrorSelectorProps> = ({
  setPreviousError,
  setNextError,
  displayedFailureArrayIndex,
  errors_count,
  displayedMessage,
  failureCount,
  action,
  resultsOutdated = false,
  outdatedTooltipTitle = "Test run again update data",
}) => {
  return (
    <div
      className={twJoin(
        "flex max-h-56 w-full rounded-lg border px-3 font-inter-normal-12px",
        resultsOutdated
          ? "border-orange-200 bg-orange-50 text-orange-600"
          : "border-red-200 bg-red-50 text-red-600",
      )}
      data-loc="error-selector"
    >
      <Tooltip
        activated={resultsOutdated}
        placement="top"
        title={outdatedTooltipTitle}
        triggerClassName="flex-shrink-0"
        asChild
      >
        <div
          className={twJoin(
            "mr-3 flex items-center border-r py-2 pr-3",
            resultsOutdated ? "border-orange-200" : "border-red-200",
          )}
        >
          <button onClick={setPreviousError}>
            <Icon cursorType="pointer" icon={faChevronLeft} size="xs" />
          </button>

          <Icon icon={faWarning} size="xs" />

          <span className="ml-0.5">
            {`${
              displayedFailureArrayIndex > -1
                ? displayedFailureArrayIndex + 1
                : "?"
            } of ${errors_count}${resultsOutdated ? " outdated" : ""} errors`}
          </span>
          <button onClick={setNextError}>
            <Icon cursorType="pointer" icon={faChevronRight} size="xs" />
          </button>
        </div>
      </Tooltip>
      <div className="flex min-w-0 flex-1 items-center justify-between py-2">
        <span className="error-selector-scrollbar max-h-full min-w-0 flex-1 overflow-auto break-words">
          {displayedMessage}
        </span>
        {action && (
          <button
            className="ml-3 whitespace-nowrap underline font-inter-medium-13px"
            onClick={action.onClick}
          >
            {action.text}
          </button>
        )}
        {!action && failureCount && (
          <span className="ml-3 flex-shrink-0 font-bold">
            {failureCount} failures
          </span>
        )}
      </div>
    </div>
  );
};
