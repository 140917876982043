import TransUnionIcon from "src/assets/TransunionIcon.svg?react";
import { getDefaultTransUnionTruVisionModelReport } from "src/connections/connectionConfigs/transUnion/transUnionResources";
import { TransUnionTemplate } from "src/connections/connectionConfigs/transUnion/transUnionTemplate";
import { ConnectionConfig } from "src/connections/connectionConfigs/types";

export const TRANSUNION_CONFIG: ConnectionConfig<"trans_union"> = {
  icon: TransUnionIcon,
  description: "More precisely balance risk and opportunity",
  template: TransUnionTemplate,
  name: "TransUnion",
  resources: {
    tru_vision_model_report: {
      externalLink:
        "https://www.notion.so/taktile/TruVision-Model-Report-c4f78842b95d411cab89e50e96cd5d85",
      integrationResource: getDefaultTransUnionTruVisionModelReport,
    },
  },
};
