import { isEmpty, keyBy } from "lodash";
import { useState } from "react";

import { Card } from "src/performance/Card";
import { CardDropdown } from "src/performance/CardDropdown";
import { ErrorOverlay } from "src/performance/ErrorOverlay";
import { Legend, LegendItem } from "src/performance/Legend";
import { NoDataOverlay } from "src/performance/NoDataOverlay";
import { Plot } from "src/performance/Plot/Plot";
import { lineY, tooltip } from "src/performance/Plot/marks";
import { useLatency } from "src/performance/queries";
import { LatencyFunction, PerformanceFilters } from "src/performance/types";
import { displayLatency } from "src/performance/utils";
import { useFlowContext } from "src/router/routerContextHooks";
import { VersionColors } from "src/utils/getVersionsColors";

export const LatencyChart = ({
  filters,
  colors,
}: {
  filters: PerformanceFilters;
  colors: VersionColors;
}) => {
  const [func, setFunc] = useState<string>("p50");
  const { flow, workspace } = useFlowContext();
  const versionsById = keyBy(flow.versions, "id");

  const latency = useLatency(workspace.base_url, {
    ...filters,
    func: func as LatencyFunction,
  });

  const latencyData =
    latency.data?.total.concat(latency.data?.by_version) ?? [];

  return (
    <Card>
      <Card.Header>
        Duration over time
        <CardDropdown
          elements={[
            { key: "p50", value: "P50" },
            { key: "p90", value: "P90" },
            { key: "p95", value: "P95" },
            { key: "max", value: "Max" },
          ]}
          selected={func}
          width="w-21"
          onSelect={setFunc}
        />
      </Card.Header>
      <Card.Body>
        {latency.isError && <ErrorOverlay onRetry={latency.refetch} />}
        {latency.isSuccess &&
          isEmpty(latency.data?.by_version) &&
          isEmpty(latency.data?.total) && <NoDataOverlay />}
        <Plot
          data={latencyData}
          isLoading={latency.isFetching}
          loadingStyle="line"
          marks={[
            lineY({
              x: "bin",
              y: "value",
              z: "version_id",
              stroke: (d) =>
                d.version_id ? colors[d.version_id]?.dark : "#9C9FAF",
            }),
            tooltip({
              x: "bin",
              y: "value",
              title: (d) =>
                `${
                  versionsById[d.version_id]?.name ?? "All versions"
                }: ${displayLatency(d.value)}`,
              anchor: "top",
            }),
          ]}
          timeWindow={filters.timeWindow}
          y={{ tickFormat: displayLatency }}
        />
        <Legend
          items={[
            { color: "#9C9FAF", name: "All versions", type: "circle" },
            ...filters.versions.map<LegendItem>((versionId) => ({
              color: colors[versionId]?.dark,
              name: versionsById[versionId].name,
              type: "circle",
            })),
          ]}
        />
      </Card.Body>
    </Card>
  );
};
