import React from "react";

export const DashboardContent: React.FC<{
  Header?: React.ReactNode;
  children?: React.ReactNode;
  sidebar?: React.ReactNode;
  contentContainerRef?:
    | React.MutableRefObject<HTMLDivElement>
    | React.RefCallback<HTMLDivElement>;
}> = ({ Header, sidebar, children, contentContainerRef }) => (
  <div className="flex max-h-screen min-h-full max-w-full grow flex-col justify-stretch divide-y divide-gray-200">
    {Header}
    <div className="flex w-full max-w-full grow overflow-hidden">
      {sidebar}
      <div
        ref={contentContainerRef}
        className="w-full max-w-full grow overflow-auto bg-gray-100 px-8 pb-12 pt-8"
      >
        {children}
      </div>
    </div>
  </div>
);
