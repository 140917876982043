import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { v4 } from "uuid";

import { FieldErrorsT } from "src/api/types";
import { Button } from "src/base-components/Button";
import { ClauseRow } from "src/clauseBlock/ClauseRow";
import { Clause, Operators, Junctions } from "src/clients/flow-api";

type PropsT = {
  clauses: Clause[];
  junction: Junctions;
  immutable: boolean;
  prefixClassName?: string;
  junctionWidth?: `w-${number}`;
  fieldPlaceholder?: string;
  valuePlaceholder?: string;
  allowDeletingAllClauses?: boolean;
  onChange?: (value: ClauseFormT) => void;
  fieldErrors?: FieldErrorsT;
};

export type ClauseFormT = {
  junction: Junctions;
  clauses: Clause[];
};

export const ClauseBlock: React.FC<PropsT> = ({
  clauses,
  junction,
  immutable,
  prefixClassName = "w-28",
  junctionWidth = "w-24",
  fieldPlaceholder = "data.field",
  valuePlaceholder = '"comparison value"',
  allowDeletingAllClauses,
  onChange,
  fieldErrors,
}) => {
  const { control, setValue, watch } = useForm<ClauseFormT>({
    defaultValues: { junction, clauses },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "clauses",
  });

  useEffect(() => {
    const { unsubscribe } = watch(({ clauses, junction }) => {
      onChange?.({ clauses, junction } as ClauseFormT);
    });

    return () => unsubscribe();
  }, [onChange, watch]);

  return (
    <>
      {fields.map((clause, index) => (
        <ClauseRow
          key={clause.id_left}
          allowDeletingAllClauses={allowDeletingAllClauses}
          control={control}
          errorLeft={fieldErrors?.[clause.id_left]}
          errorRight={fieldErrors?.[clause.id_right]}
          fieldPlaceholder={fieldPlaceholder}
          immutable={immutable}
          index={index}
          junctionWidth={junctionWidth}
          length={fields.length}
          prefixClassName={prefixClassName}
          setValue={setValue}
          valuePlaceholder={valuePlaceholder}
          onDeleteClause={() => remove(index)}
        />
      ))}
      <span className="mt-2 inline-block">
        <Button
          dataLoc="add-condition"
          disabled={immutable}
          iconLeft={faPlus}
          size="sm"
          variant="secondary"
          onClick={() =>
            append({
              id_left: v4(),
              id_right: v4(),
              left: "",
              right: "",
              operator: Operators.EQ,
            })
          }
        >
          Add condition
        </Button>
      </span>
    </>
  );
};
