import { Menu } from "@headlessui/react";
import { Column, Row } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { twJoin, twMerge } from "tailwind-merge";
import { useEventListener } from "usehooks-ts";

import { DatasetRow } from "src/api/types";
import { Spinner } from "src/base-components/Spinner";
import { RowUpdateFunction } from "src/datasets/DatasetTable/DatasetEditTable";
import {
  useIsEditingCell,
  useIsSelectedCell,
} from "src/datasets/DatasetTable/stores";
import {
  CellId,
  parseJsonOrReturnAsIs,
  ponToJson,
} from "src/datasets/DatasetTable/utils";
import { useResourceSample } from "src/datasets/api/queries";
import { DatasetIntegrationNode } from "src/datasets/utils";
import { useAuthoringContext } from "src/router/routerContextHooks";

type SampleIntegrationResponseCellProps = {
  onChange: RowUpdateFunction;
  row: Row<DatasetRow>;
  column: Column<DatasetRow>;
  integrationNode: DatasetIntegrationNode;
  disabled: boolean;
  cellId: CellId;
};

export const SampleIntegrationResponseCell: React.FC<
  SampleIntegrationResponseCellProps
> = ({ integrationNode, row, column, onChange, disabled, cellId }) => {
  const [menuRef, setMenuRef] = useState<Nullable<HTMLElement>>(null);
  const [itemsRef, setItemsRef] = useState<Nullable<HTMLDivElement>>(null);
  const selected = useIsSelectedCell(cellId);
  const editing = useIsEditingCell(cellId);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    menuRef,
    itemsRef,
    {
      strategy: "fixed",
      placement: "bottom-start",
      modifiers: [{ name: "offset", options: { offset: [8, -4] } }],
    },
  );

  const { workspace } = useAuthoringContext();
  const resourceSample = useResourceSample({
    baseUrl: workspace.base_url!,
    integrationNode,
    enabled: false,
  });

  useEffect(() => {
    if (editing && !resourceSample.data) resourceSample.refetch();
  }, [editing, resourceSample]);

  useEventListener("paste", (e) => {
    if (selected) {
      const clipboardData = e.clipboardData?.getData("text") ?? "";
      // If this is a PON value, then convert it to JSON
      // Otherwise, it will return the same string
      const value = ponToJson(clipboardData);
      onChange(row, column.id, parseJsonOrReturnAsIs(value));
    }
  });

  return (
    <Menu ref={setMenuRef} as="div" className="ml-auto h-full">
      <Menu.Button
        className={twMerge(
          "h-full w-full rounded-none text-gray-500 font-inter-normal-12px",
          "flex items-center bg-yellow-50 px-1.5",
          disabled &&
            "cursor-default outline outline-1 outline-gray-100 group-[:not(.is-empty)]:bg-gray-50",
          selected ? "outline outline-1 outline-indigo-500" : "outline-0",
          !editing && "select-none",
        )}
        data-loc="sample-integration-response-button"
        onClick={(e) => e.preventDefault()}
      >
        {disabled ? "" : "Enter response"}
      </Menu.Button>
      {editing && (
        <Menu.Items
          ref={setItemsRef}
          as="div"
          className="z-50 min-h-[136px] w-[273px] overflow-y-auto rounded-lg bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none"
          static
          {...popperAttributes.popper}
          style={{
            ...popperStyles.popper,
            maxHeight: menuRef
              ? `calc(100vh - ${menuRef.scrollHeight}px)`
              : undefined,
          }}
        >
          <div className="cursor-default select-none px-4 py-1 text-gray-800 font-inter-normal-13px">
            Sample Responses
          </div>
          {resourceSample.data ? (
            resourceSample.data.map((sample) => (
              <Menu.Item
                key={sample.name}
                data-loc={`sample-response-${sample.name}`}
              >
                {({ active }) => (
                  <div
                    className={twJoin(
                      "cursor-pointer px-4 py-2.5 font-medium text-gray-600 font-inter-normal-12px",
                      active && "bg-gray-50",
                    )}
                    onClick={() => onChange(row, column.id, sample.sample)}
                  >
                    {sample.name}
                  </div>
                )}
              </Menu.Item>
            ))
          ) : (
            <Spinner />
          )}
        </Menu.Items>
      )}
    </Menu>
  );
};
