import React from "react";

import { useRemoteIcon } from "src/connections/queries";
import { DashboardPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

interface CustomIconProps {
  mediaKey: string | null;
  className?: string;
}

export const CustomIcon: React.FC<CustomIconProps> = ({
  mediaKey,
  className,
}) => {
  const { wsId } = useParamsDecode<DashboardPageParamsT>();
  const { data: iconSrc, isError, isLoading } = useRemoteIcon(mediaKey, wsId);

  // TODO: in case of error we should show the default icon.
  if (isLoading || !iconSrc || isError) {
    return <div />;
  }

  return (
    <img
      alt="Custom Icon"
      className={className}
      data-loc="custom-icon"
      src={iconSrc}
    />
  );
};
