import { useMemo } from "react";

import {
  EditorColumnGroup,
  RowBase,
} from "src/base-components/EditorTable/types";

export const useColumnOrder = <T extends RowBase<T>>(
  columnGroups: EditorColumnGroup<T>[],
) =>
  useMemo(
    () =>
      columnGroups.flatMap((group) =>
        group.columns.map((column) => column.key as string),
      ),
    [columnGroups],
  );
