import { AxiosInstance } from "axios";

import { authHeader } from "src/api/authHeader";
import { isTaktileRequest } from "src/api/middlewares/utils";
import { useAuthStore } from "src/store/AuthStore";
import {
  calculateTokenExpiration,
  getCurrentUnixTimestamp,
} from "src/utils/calculateTokenExpiration";

export const addAuthorizationHeaderMiddleware = (
  axiosInstance: AxiosInstance,
) => {
  return axiosInstance.interceptors.request.use(
    async (config) => {
      const url = [config.baseURL ?? "", config.url ?? ""].join("");

      if (url && isTaktileRequest(url) && !config.headers?.["Authorization"]) {
        // Poor man`s regex for checking whether we make a call to request a new access token
        const isRefreshRequest = url.endsWith("api/v1/login/refresh");
        if (isRefreshRequest) {
          config.headers.set(authHeader(true));
        } else {
          // Check the expiration of the ID token
          const idTokenHeader = authHeader(false);
          if ("Authorization" in idTokenHeader) {
            const tokenExpiry = calculateTokenExpiration(idTokenHeader);
            // If the token is expiring in 1 minute or less, refresh it.
            if (tokenExpiry - getCurrentUnixTimestamp() < 60) {
              await useAuthStore.getState().actions.refresh();
            }
          }

          config.headers.set(authHeader(false));
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
};
