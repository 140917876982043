import { faFile, faTag } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import * as uuid from "uuid";

import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { Modal } from "src/base-components/Modal";
import { Select } from "src/base-components/Select";
import {
  TAKTILE_TEAM_NOTIFIED,
  toastFailure,
  toastSuccess,
} from "src/base-components/Toast/utils";
import { useDatasets, usePostRows } from "src/datasets/api/queries";
import { useAuthoringContext } from "src/router/routerContextHooks";
import * as logger from "src/utils/logger";

type Props = {
  decisionId: string | null;
  onClose: () => void;
};

export const TestCaseModal: React.FC<Props> = ({ decisionId, onClose }) => {
  const { flow, workspace } = useAuthoringContext();

  const datasetsQuery = useDatasets({
    flowId: flow.id,
    baseUrl: workspace.base_url,
  });

  const testRunDatasets = datasetsQuery.data
    ? datasetsQuery.data.filter((d) => !d.purpose || d.purpose === "test_run")
    : undefined;

  const [selectedDatasetId, setSelectedDatasetId] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (!selectedDatasetId && testRunDatasets?.at(0)) {
      setSelectedDatasetId(testRunDatasets[0].id);
    }
  }, [testRunDatasets, selectedDatasetId]);

  const postRows = usePostRows(
    selectedDatasetId ?? "",
    flow.id,
    workspace.base_url,
  );

  const handleSubmit = async () => {
    if (!decisionId) return;

    try {
      await postRows.mutateAsync({
        source: "decision_id",
        decision_id: decisionId,
        new_row_ids: [uuid.v4()],
      });
      toastSuccess({ title: "Test case successfully added" });

      onClose();
    } catch (e) {
      toastFailure({
        title: "Failed to add test case",
        description: TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(e);
    }
  };

  return (
    <Modal
      className="w-110 overflow-visible"
      headerClassName="px-10"
      open={!!decisionId}
      title="Select a test dataset"
      onClose={onClose}
    >
      <div className="px-10 pb-6">
        {testRunDatasets && selectedDatasetId ? (
          <>
            <p className="mb-2 mt-3 text-gray-500 font-inter-medium-12px">
              Add this test case to an existing test dataset
            </p>
            <Select
              options={testRunDatasets.map((dataset) => ({
                key: dataset.id,
                value: (
                  <div className="flex items-center gap-x-2">
                    <Icon
                      color="text-indigo-500"
                      icon={dataset.source === "file" ? faFile : faTag}
                      padding={false}
                      size="xs"
                    />
                    {dataset.name}
                  </div>
                ),
              }))}
              value={selectedDatasetId}
              onChange={setSelectedDatasetId}
            />
          </>
        ) : (
          <p className="mb-2 mt-3 text-gray-500 font-inter-medium-12px">
            There are no datasets available for this flow
          </p>
        )}
        <div className="mt-6 flex items-center justify-end gap-2">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            dataLoc="save"
            disabled={!selectedDatasetId}
            loading={postRows.isLoading}
            variant="primary"
            onClick={handleSubmit}
          >
            Add to dataset
          </Button>
        </div>
      </div>
    </Modal>
  );
};
