import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import React, { useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { v4 } from "uuid";

import { FieldErrorsT } from "src/api/types";
import { AssignmentRow } from "src/assignmentNode/AssignmentRow";
import { Button } from "src/base-components/Button";
import { Card } from "src/base-components/Card";
import { Effect } from "src/clients/flow-api";
import {
  AssignmentNodeDataT,
  AssignmentNode,
} from "src/constants/NodeDataTypes";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import {
  convertFieldErrorsBeToFe,
  mergeFieldIdAndName,
  RunErrorFieldNames,
} from "src/utils/FieldErrorUtils";
import { useSubmitForm } from "src/utils/useSubmitForm";

type AssignmentNodeFormT = {
  default_effects: Effect[];
};

const usePreviousValues = (
  nodeEffects?: AssignmentNodeDataT["default_effects"],
) => {
  const defaultEffects = useMemo(
    () => [{ id: v4(), value: "", target: "" }],
    [],
  );

  return useMemo(
    () => ({
      default_effects: nodeEffects?.length ? nodeEffects : defaultEffects,
    }),
    [nodeEffects, defaultEffects],
  );
};

type PropsT = {
  selectedNode: AssignmentNode;
} & NodeEditorBaseProps<AssignmentNodeDataT>;

export const AssignmentNodeEditor: React.FC<PropsT> = ({
  selectedNode,
  immutable,
  isReactive,
  onUpdate,
  displayedError,
}) => {
  const previousValues = usePreviousValues(selectedNode.data.default_effects);

  const formMethods = useForm<AssignmentNodeFormT>({
    defaultValues: previousValues,
    ...(isReactive && { values: previousValues }),
  });
  const { control, register, getValues } = formMethods;

  useSubmitForm({
    onChange: (data: AssignmentNodeFormT) => {
      onUpdate({ newData: data });
    },
    disabled: isReactive,
    previousValues: previousValues,
    watch: formMethods.watch,
  });

  const { fields, append, remove, move } = useFieldArray({
    control: control,
    name: "default_effects",
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const fieldToMove = fields.findIndex((field) => field.id === active.id);
      const fieldToInsert = fields.findIndex((field) => field.id === over.id);
      if (fieldToMove !== -1 && fieldToInsert !== -1) {
        move(fieldToMove, fieldToInsert);
      }
    }
  };
  const fieldErrors: FieldErrorsT | undefined = displayedError?.field_errors
    ? convertFieldErrorsBeToFe(displayedError.field_errors, true)
    : undefined;

  return (
    <Card className="w-full space-y-3">
      <span className="text-gray-500 font-inter-medium-12px">Set value of</span>
      <div className="h-px rounded-full bg-gray-200" />
      <div className="space-y-3">
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext
            disabled={immutable}
            items={fields.map((row) => row.id)}
          >
            {fields.map((field, index) => (
              <AssignmentRow
                key={field.id}
                control={control}
                fieldErrorLeft={
                  fieldErrors?.[
                    mergeFieldIdAndName(
                      getValues().default_effects?.at(index)?.id,
                      RunErrorFieldNames.LEFT,
                    )
                  ]
                }
                fieldErrorRight={
                  fieldErrors?.[
                    mergeFieldIdAndName(
                      getValues().default_effects?.at(index)?.id,
                      RunErrorFieldNames.RIGHT,
                    )
                  ]
                }
                id={field.id}
                immutable={immutable}
                index={index}
                register={register}
                onRemove={fields.length > 1 ? () => remove(index) : undefined}
              />
            ))}
          </SortableContext>
        </DndContext>
        <Button
          dataLoc="assignment-node-add-assignment"
          disabled={immutable}
          iconLeft={faPlus}
          size="sm"
          variant="secondary"
          onClick={() => append({ id: v4(), value: "", target: "" })}
        >
          Add field
        </Button>
      </div>
    </Card>
  );
};
