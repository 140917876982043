import { faArrowLeftLong } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { NavLink } from "react-router-dom";

import { Icon } from "src/base-components/Icon";

export const HEADER_HEIGHT = "3.25rem" as const;

type PropsT = {
  previousPageAbsolute: string;
  children: React.ReactNode;
};

export const SingleFlowHeader: React.FC<PropsT> = ({
  previousPageAbsolute,
  children,
}) => {
  return (
    <div className="w-full border-b border-gray-200 bg-white shadow-sm">
      <div className="flex items-center pl-5" style={{ height: HEADER_HEIGHT }}>
        <NavLink data-loc="back-to-versions" to={previousPageAbsolute}>
          <div className="ml-0.5 mr-3">
            <Icon
              color="text-gray-500 hover:text-gray-700"
              dataLoc="back-icon"
              icon={faArrowLeftLong}
              size="xs"
            />
          </div>
        </NavLink>
        {children}
      </div>
    </div>
  );
};
