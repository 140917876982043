import { makeConnectionTemplate } from "src/connections/types";

export const SchufaTemplate = makeConnectionTemplate({
  provider: "schufa",
  secrets: [
    {
      name: "Account ID",
      key: "account_id",
      required: true,
    },
    {
      name: "Password",
      key: "password",
      required: true,
    },
  ],
  resources: {
    b2c_report: {
      name: "B2C Report",
      configKeys: [
        {
          name: "B2C feature code",
          type: "simple_selection",
          key: "schufa_feature_code",
          required: true,
          elements: [
            { key: "A1", value: "(A1) Working capital loan for freelancers" },
            { key: "A2", value: "(A2) Working capital loan for SMEs" },
            {
              key: "A3",
              value: "(A3) Investment loan/leasing for freelancers",
            },
            { key: "A4", value: "(A4) Investment loan/leasing for SMEs" },
            { key: "A5", value: "(A5) Enquiry for freelancers" },
            { key: "A6", value: "(A6) Enquiry for SMEs" },
            { key: "A7", value: "(A7) Debt guarantee for SMEs" },
            { key: "AD", value: "(AD) Enquiry service" },
            {
              key: "AH",
              value:
                "(AH) Commercial enquiry due to delivery of goods or services",
            },
            { key: "AI", value: "(AI) Debt collection" },
            { key: "AU", value: "(AU) Feature check for private individuals" },
            { key: "FU", value: "(FU) Feature check for freelancers" },
            { key: "GU", value: "(GU) Feature check for SMEs" },
            { key: "KK", value: "(KK) Loan conditions" },
            {
              key: "OA",
              value: "(OA) Address request / updating a customer address",
            },
          ],
        },
      ],
    },
    schufa_fraud_pre_check: {
      name: "Fraud Pre-Check",
      configKeys: [],
    },
    b2b_report_cascading: {
      name: "B2B Report Cascading",
      configKeys: [
        {
          name: "B2B feature code",
          type: "simple_selection",
          key: "schufa_feature_code",
          required: true,
          elements: [
            { key: "X1", value: "Credit enquiry" },
            { key: "X2", value: "Initial business contact" },
            { key: "X3", value: "Credit check" },
            { key: "X4", value: "Debt claims" },
          ],
        },
      ],
    },
  },
});
