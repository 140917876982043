import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";

import { Overlay } from "src/performance/Overlay";

export const ErrorOverlay: React.FC<{ onRetry: () => void }> = ({
  onRetry,
}) => (
  <Overlay
    description="An error occurred while loading the data."
    icon={faExclamationTriangle}
    message="Something went wrong"
    onRetry={onRetry}
  />
);
