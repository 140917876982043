import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { Icon } from "src/base-components/Icon";

/**
 * @deprecated please use placeholders instead
 */
export const Spinner: React.FC = () => {
  return (
    <div className="mx-auto my-auto">
      <Icon color="text-gray-500" icon={faCircleNotch} size="2xl" spin />
    </div>
  );
};
