import { FormItem } from "src/base-components/FormItem";
import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { KeyValuePairField } from "src/connections/config/KeyValuePairField";
import { AvailableEnvironmentPrefixes } from "src/connections/types";

type PropsT = {
  environmentPrefix: AvailableEnvironmentPrefixes;
};

export const BasicAuthFieldset: React.FC<PropsT> = ({ environmentPrefix }) => {
  return (
    <>
      <FieldsetCard>
        <FormItem gap="sm">
          <KeyValuePairField
            cta="Add secret"
            dataLoc="add-secret-button"
            name={`${environmentPrefix}secrets`}
            showHeader={false}
            isSensitive
          />
        </FormItem>
      </FieldsetCard>
    </>
  );
};
