import React from "react";
import { twJoin } from "tailwind-merge";

type ErrorHintPropsT = {
  // we only allow certain values so we can ensure these classes are in the tailwind bundle.
  height?: "h-2" | "h-2.5";
  className?: string;
  children: React.ReactNode;
  margin?: "md" | "none";
};

export const ErrorHint: React.FC<ErrorHintPropsT> = ({
  height,
  className,
  children,
  margin = "md",
}) => {
  return (
    <p
      className={twJoin(
        `text-red-600 font-inter-normal-12px`,
        margin === "md" && "mb-2",
        height,
        className,
      )}
    >
      {children}
    </p>
  );
};
