import { FlowT } from "src/api/flowTypes";
import { useEditFlow } from "src/api/queries";
import { EditFlowModalOutputsT } from "src/flowsOverview/EditFlowModal";
import { useCreateFolder } from "src/flowsOverview/v2/folderQueries";

export const useSubmitFlowSettingsUpdate = (
  workspaceId: string,
  flow?: FlowT,
) => {
  const createFolder = useCreateFolder();
  const editFlow = useEditFlow(workspaceId);
  return async ({
    name,
    description,
    folderSelection,
    reviewConfig,
  }: EditFlowModalOutputsT) => {
    let folderId: string | null = null;
    if (folderSelection.mode === "addFolder") {
      const newFolder = (
        await createFolder.mutateAsync({
          name: folderSelection.folderName,
          workspace_id: workspaceId,
        })
      ).data;
      folderId = newFolder.id;
    } else {
      folderId = folderSelection.folderId;
    }
    if (flow) {
      const newMeta = {
        ...flow.meta,
        description: description ?? flow.meta.description,
      };
      await editFlow.mutateAsync({
        flowId: flow.id,
        flow_folder_id: folderId !== undefined ? folderId : null,
        old_folder_id: flow.flow_folder_id,
        name,
        meta: newMeta,
        review_configuration: reviewConfig?.requires_review
          ? reviewConfig
          : {
              requires_review: false,
              default_reviewer_list: [],
              require_all_reviewers: true,
            },
      });
    }
  };
};
