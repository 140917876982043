import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Switch } from "src/base-components/Switch";
import { CommonSchemaEditRowPropsT, SchemaUIT } from "src/schema/utils";

export const SchemaRequiredSwitch: React.FC<CommonSchemaEditRowPropsT> = ({
  rowName,
  disabled,
}) => {
  const { control } = useFormContext<SchemaUIT>();
  return (
    <Controller
      control={control}
      name={`${rowName}.required`}
      render={(props) => (
        <Switch
          dataLoc="schema-field-required"
          disabled={disabled}
          enabled={!!props.field.value}
          onChange={() => {
            props.field.onChange(!props.field.value);
          }}
        />
      )}
    />
  );
};
