import { isEqual } from "lodash";
import { useEffect, useRef } from "react";
import { FieldValues, UseFormWatch } from "react-hook-form";

import * as logger from "src/utils/logger";

/**
 * This hook is used to continuously submit a form when the form values change.
 */
export const useSubmitForm = <T extends FieldValues>({
  onChange,
  disabled,
  previousValues,
  watch,
}: {
  onChange: (newValues: T) => void;
  disabled: boolean;
  previousValues?: T;
  watch: UseFormWatch<T>;
}) => {
  const onSubmit = (formValues: Partial<T>) => {
    const thereAreChanges = !isEqual(formValues, previousValues);

    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (process.env.NODE_ENV !== "production") {
      if (thereAreChanges && disabled) {
        logger.warn(
          "Form values changed but the form submit is disabled. This might happen because one of form dropdowns are rendered in react portal and can not gain focus.",
        );
      }
    }

    if (thereAreChanges && !disabled) {
      // Submit the form even if there are validation errors
      onChange(formValues as T);
    }
  };
  const handleSubmitRef = useRef(onSubmit);
  handleSubmitRef.current = onSubmit;

  useEffect(() => {
    const subscription = watch((formValues) =>
      handleSubmitRef.current(formValues),
    );
    return () => subscription.unsubscribe();
  }, [watch]);
};
