import * as RadioGroup from "@radix-ui/react-radio-group";
import React, { ReactNode } from "react";
import { twJoin } from "tailwind-merge";

type OrientationT = "horizontal" | "vertical";

type PropsT = {
  orientation: OrientationT;
  value?: string;
  onValueChange: (value: string) => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
};
type BaseItemProps = {
  disabled: boolean;
  checked: boolean;
};
type ItemPropsT = {
  value: string;
  label: ReactNode;
  labelClassName?: string;
  disabled?: boolean;
  boldLabel?: boolean;
  dataLoc?: string;
  alignTop?: boolean;
};

type SubComponents = {
  Item: React.FC<ItemPropsT>;
};

const getRadioItemClassName = ({
  disabled,
  alignTop = false,
  ...restOfProps
}: {
  disabled: boolean;
  alignTop?: boolean;
} & (
  | { notInsideRadixComponent?: never }
  | { notInsideRadixComponent: true; checked: boolean }
)): string => {
  const base = "relative h-4 w-4 shrink-0 rounded-lg";
  const disabledClass = "cursor-not-allowed border-gray-300 bg-gray-200";
  const alignTopClass = "mt-0.5";
  if (restOfProps.notInsideRadixComponent) {
    return twJoin(
      base,
      alignTop && alignTopClass,
      disabled && disabledClass,
      !disabled && restOfProps.checked && "bg-indigo-600",
      !disabled && !restOfProps.checked && "border border-gray-300 bg-white",
    );
  } else
    return twJoin(
      base,
      alignTop && alignTopClass,
      disabled && disabledClass,
      !disabled &&
        "radix-state-checked:bg-indigo-600 radix-state-unchecked:border radix-state-unchecked:border-gray-300 radix-state-unchecked:bg-white",
    );
};

const radioIndicatorClassName =
  "absolute top-[0.3125rem] left-[0.3125rem] h-1.5 w-1.5 rounded-md bg-white";

/**
 * An item styled just like the RadioGroup.Item, but without the need to be used inside a RadioGroup.Root
 *  */
export const BaseRadioGroupItem: React.FC<BaseItemProps> = ({
  disabled,
  checked,
}) => {
  return (
    <button
      aria-checked={checked}
      className={getRadioItemClassName({
        disabled,
        checked,
        notInsideRadixComponent: true,
      })}
      role="radio"
      type="button"
    >
      <span className={radioIndicatorClassName} />
    </button>
  );
};

export const SimpleRadioGroup: React.FC<PropsT> & SubComponents = ({
  orientation,
  value,
  onValueChange,
  children,
  className,
  disabled = false,
}) => {
  return (
    <RadioGroup.Root
      className={twJoin(
        "flex",
        orientation === "horizontal" && "items-start space-x-2",
        orientation === "vertical" && "flex-col space-y-2",
        className,
      )}
      disabled={disabled}
      value={value}
      onValueChange={onValueChange}
    >
      {children}
    </RadioGroup.Root>
  );
};

SimpleRadioGroup.Item = ({
  value,
  label,
  labelClassName,
  disabled = false,
  boldLabel = false,
  alignTop = false,
  dataLoc,
}) => {
  return (
    <div
      className={twJoin(
        "flex",
        alignTop ? "items-start" : "items-center",
        disabled && "cursor-not-allowed",
      )}
    >
      <RadioGroup.Item
        className={getRadioItemClassName({ disabled, alignTop })}
        data-loc={dataLoc}
        disabled={disabled}
        id={value}
        value={value}
      >
        <RadioGroup.Indicator className={radioIndicatorClassName} />
      </RadioGroup.Item>
      <label
        className={twJoin(
          boldLabel ? "font-semibold" : "font-inter-normal-12px",
          disabled && "cursor-not-allowed",
          "text-gray-700",
          labelClassName,
        )}
        htmlFor={value}
      >
        {label}
      </label>
    </div>
  );
};
