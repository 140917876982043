import { faChevronDown, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { ButtonHTMLAttributes, ReactNode, useState } from "react";
import { twJoin } from "tailwind-merge";

import {
  FixedPositionedDropdown,
  FixedPositionedDropdownElementT,
} from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { DecideDropzone } from "src/datasets/Dropzone";

const MenuItem: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...rest
}) => (
  <button
    {...rest}
    className="flex w-full items-center gap-x-2 py-3 pl-4 pr-8 text-left text-gray-800 font-inter-normal-12px"
    type="button"
  >
    <Icon color="text-gray-500" icon={faPlus} size="2xs" /> {children}
  </button>
);

type UploadMenuItemProps = {
  children: ReactNode;
  close: () => void;
  onFilesDropped: (files: File[], errors: string[]) => Promise<void>;
};

const UploadMenuItem: React.FC<UploadMenuItemProps> = ({
  children,
  close,
  onFilesDropped,
}) => {
  const handleFilesDropped = async (files: File[], errors: string[]) => {
    await onFilesDropped(files, errors);
    close();
  };

  return (
    <DecideDropzone
      content={({ getInputProps, getRootProps }) => (
        <div {...getRootProps({ onClick: (e) => e.preventDefault() })}>
          <MenuItem data-loc="dataset-upload">
            {children}
            <input {...getInputProps()} />
          </MenuItem>
        </div>
      )}
      onSubmitFiles={handleFilesDropped}
    />
  );
};

const ADD_DATASET_ITEMS_ARCHETYPE: FixedPositionedDropdownElementT<
  string,
  string
>[] = [
  { key: "scratch", value: "From scratch" },
  { key: "upload", value: "Upload file" },
  { key: "divider", value: "", disabled: true },
  { key: "assemble", value: "From historical decisions" },
];

export const AddDatasetDropdown: React.FC<{
  onFilesDropped: (files: File[], errors: string[]) => Promise<void>;
  onAssemble: () => void;
  onCreate: () => void;
  placement?: "bottomRight" | "bottom";
  size?: "base" | "sm";
}> = ({
  onFilesDropped,
  onAssemble,
  onCreate,
  placement = "bottomRight",
  size = "base",
}) => {
  const [disabled, setDisabled] = useState(false);
  const handleCreate = async () => {
    try {
      setDisabled(true);
      await onCreate();
    } finally {
      setDisabled(false);
    }
  };

  return (
    <FixedPositionedDropdown
      dataLoc="create-dataset-dropdown"
      disabled={disabled}
      elements={ADD_DATASET_ITEMS_ARCHETYPE}
      placement={placement}
      renderButtonValue={() => (
        <div
          className={twJoin(
            "flex select-none items-center gap-x-0.5 rounded-md text-gray-800 shadow-sm font-inter-medium-12px",
            disabled && "opacity-50",
            size === "sm" && "px-1.5 py-0.5",
            size === "base" && "px-3 py-1.5",
          )}
        >
          <Icon color="text-gray-500" icon={faPlus} size="2xs" />
          <span>Create test dataset</span>
          <Icon color="text-gray-500" icon={faChevronDown} size="2xs" />
        </div>
      )}
      renderValue={(val, close) => {
        switch (val.key) {
          case "upload":
            return (
              <UploadMenuItem close={close} onFilesDropped={onFilesDropped}>
                {val.value}
              </UploadMenuItem>
            );
          case "assemble":
            return (
              <MenuItem data-loc="assemble-dataset" onClick={onAssemble}>
                {val.value}
              </MenuItem>
            );
          case "scratch":
            return (
              <MenuItem data-loc="create-from-scratch" onClick={handleCreate}>
                {val.value}
              </MenuItem>
            );
          case "divider":
            return <hr className="my-2.5 cursor-default" />;
        }
      }}
      onSelect={(key) => key === "assemble" && onAssemble()}
    />
  );
};
