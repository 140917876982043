import {
  faCheck,
  faChevronDown,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import React, { ReactNode, useState } from "react";

import { useTrafficPolicies } from "src/api/queries";
import { Dataset, DatasetFileUpload } from "src/api/types";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import {
  FixedPositionedDropdown as DropDown,
  RenderValueFn,
  FixedPositionedDropdownElementT as DropDownElementT,
} from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { AssembleDatasetModal } from "src/datasets/AssembleDatasetModal/Modal";
import { ItemActions } from "src/datasets/DatasetList/ItemActions";
import { SourceIcon } from "src/datasets/DatasetList/SourceIcon";
import { DatasetStatusPill } from "src/datasets/DatasetStatusPill";
import { DecideDropzone as Dropzone } from "src/datasets/Dropzone";
import { useCreateDatasetFromScratch } from "src/datasets/hooks/useCreateDatasetFromScratch";
import { separateFilenameExtension } from "src/datasets/utils";
import { useAuthoringContext } from "src/router/routerContextHooks";

type PropsT = {
  datasets: Dataset[];
  uploads: DatasetFileUpload[];
  onSubmitFiles: (files: File[]) => Promise<void>;
  selectedId: string | null;
  setSelected: (id: string) => void;
  onFileDelete: (fileID: string) => Promise<void>;
  dataLoc?: string;
};

const isFileUpload = (data: any): data is DatasetFileUpload =>
  Boolean(data?.file_name);

const DropdownDatasetValue: React.FC<{
  dataset: Dataset;
  selected: boolean;
  onDelete: () => void;
}> = ({ dataset, selected, onDelete }) => (
  <div
    className="group flex items-center justify-between px-5 py-3.5 font-inter-normal-12px"
    data-loc={`datasets-dropdown-${dataset.name}`}
  >
    <div className="flex items-center">
      <SourceIcon source={dataset.source} />
      <p className="ml-1">{separateFilenameExtension(dataset.name).name}</p>
    </div>
    <div className="flex flex-row items-center">
      {selected && (
        <Icon
          color="text-indigo-600 group-hover:hidden"
          icon={faCheck}
          size="xs"
        />
      )}
      <div className="hidden items-center space-x-3 group-hover:flex">
        <ItemActions dataset={dataset} onDelete={onDelete} />
      </div>
    </div>
  </div>
);

const DropdownUploadValue: React.FC<{
  datasetUpload: DatasetFileUpload;
}> = ({ datasetUpload }) => (
  <div
    className="flex cursor-default items-center justify-between px-5 py-3 font-inter-normal-13px"
    data-loc={`datasets-dropdown-${datasetUpload.file_name}`}
  >
    <div className="flex items-center">
      <SourceIcon source="file" />
      <p className="ml-1">
        {separateFilenameExtension(datasetUpload.file_name).name}
      </p>
    </div>
    <div className="flex flex-row items-center">
      <div className="items-center space-x-3">
        <DatasetStatusPill status={datasetUpload.status} />
      </div>
    </div>
  </div>
);

const FooterItem: React.FC<{ children: ReactNode; onClick?: () => void }> = ({
  children,
  onClick,
}) => (
  <div
    className="flex items-center justify-between py-3.5 pl-5 pr-6 text-gray-800 font-inter-normal-12px hover:bg-gray-50"
    onClick={onClick}
  >
    <div className="flex flex-row items-center gap-x-1">
      <Icon color="text-gray-500" icon={faPlus} size="2xs" />
      <p>{children}</p>
    </div>
  </div>
);

export const DatasetsDropdown: React.FC<PropsT> = ({
  datasets,
  uploads,
  onSubmitFiles,
  selectedId,
  setSelected,
  onFileDelete,
  dataLoc,
}) => {
  const [dataToDelete, setDataToDelete] = useState<Dataset | undefined>();
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [assembleDatasetModalOpen, setAssembleDatasetModalOpen] =
    useState(false);

  const { flow } = useAuthoringContext();
  const flowTrafficPolicies = useTrafficPolicies(flow.id);
  const createDatasetFromScratch = useCreateDatasetFromScratch();

  const dropDownElements: DropDownElementT<Dataset>[] = datasets.map(
    (file) => ({
      key: file.id,
      value: file,
    }),
  );
  const uploadElements: DropDownElementT<DatasetFileUpload>[] = uploads.map(
    (file) => ({
      key: file.id,
      value: file,
      disabled: true,
    }),
  );

  const isLoading = !flow || !flowTrafficPolicies.data;

  const renderButtonValue = (value?: Dataset | DatasetFileUpload) => {
    // No way to select a file upload
    // So this line is unreachable
    if (isFileUpload(value)) {
      return <></>;
    }

    return (
      <div className="flex min-w-0 flex-1 items-center justify-between py-1 pl-3 pr-2">
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            {value ? (
              <>
                <SourceIcon source={value.source} hideTooltip />
                <div
                  className="ml-1 min-w-0 grow text-left text-gray-800 font-inter-normal-12px"
                  data-loc="datasets-dropdown-button-value"
                >
                  {separateFilenameExtension(value.name).name}
                </div>
              </>
            ) : (
              <div className="text-gray-500 font-inter-normal-13px">
                No dataset selected
              </div>
            )}
            <div className="flex h-auto items-center">
              <Icon color="text-gray-500" icon={faChevronDown} size="2xs" />
            </div>
          </>
        )}
      </div>
    );
  };

  const renderValue: RenderValueFn<Dataset | DatasetFileUpload> = ({
    value,
    key,
    selected,
  }) => {
    if (isFileUpload(value)) {
      return <DropdownUploadValue datasetUpload={value} />;
    }

    const onDeleteClick = () => {
      setDataToDelete(datasets.find((data) => data.id === key));
      setConfirmationIsOpen(true);
    };

    return (
      <DropdownDatasetValue
        dataset={value}
        selected={selected}
        onDelete={onDeleteClick}
      />
    );
  };

  const renderFooter = () => (
    <div
      className="mt-2.5 border-t border-gray-200 bg-white"
      //stop propagation so that the dropdown is not closed before a file is select for upload.
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <div className="mt-2.5 cursor-default py-2 pl-6 text-gray-800 font-inter-normal-12px">
        Create test dataset
      </div>
      <FooterItem onClick={createDatasetFromScratch}>From scratch</FooterItem>
      <Dropzone
        content={({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} data-loc="datasets-upload">
            <FooterItem>Upload file</FooterItem>
            <input {...getInputProps()} />
          </div>
        )}
        onSubmitFiles={onSubmitFiles}
      />
      <FooterItem onClick={() => setAssembleDatasetModalOpen(true)}>
        From historical decisions
      </FooterItem>
    </div>
  );

  const onDeleteModalConfirm = () => {
    if (dataToDelete) {
      onFileDelete(dataToDelete.id);
    }
    setConfirmationIsOpen(false);
  };

  const onDeleteModalCancel = () => {
    setConfirmationIsOpen(false);
  };

  const elements = [...dropDownElements, ...uploadElements];

  return (
    <>
      <DropDown<Dataset | DatasetFileUpload>
        buttonDataLoc={dataLoc}
        disabled={isLoading}
        elements={elements}
        itemsClassNames="max-h-[min(500px,50vh)] overflow-auto"
        renderButtonValue={renderButtonValue}
        renderFooter={renderFooter}
        renderHeader={() =>
          elements.length === 0 ? (
            <p className="px-5 py-3 text-xs text-gray-500">
              No datasets uploaded yet
            </p>
          ) : undefined
        }
        renderValue={renderValue}
        selected={selectedId ?? undefined}
        listMatchesButtonWidth
        onSelect={setSelected}
      />
      <ConfirmationModal
        confirmationButtonClassname="w-[6.475rem]"
        confirmationButtonText="Yes, delete"
        confirmationButtonType="warning"
        open={confirmationIsOpen}
        title={
          dataToDelete
            ? `Delete ${separateFilenameExtension(dataToDelete.name).name}`
            : ""
        }
        onClose={onDeleteModalCancel}
        onConfirm={onDeleteModalConfirm}
      >
        <div className="mb-6 mt-4 text-gray-500 font-inter-normal-12px">
          Are you sure you want to delete this dataset? This action cannot be
          undone.
        </div>
      </ConfirmationModal>
      {!isLoading && (
        <AssembleDatasetModal
          flow={flow}
          open={assembleDatasetModalOpen}
          trafficPolicies={flowTrafficPolicies.data}
          onClose={() => {
            setAssembleDatasetModalOpen(false);
          }}
        />
      )}
    </>
  );
};
