import { twMerge } from "tailwind-merge";

export const SkeletonLine: React.FC<{ className: string }> = ({
  className,
}) => {
  const movingElement = (
    <div
      className="absolute inset-0 -translate-x-full transform bg-gradient-to-r from-gray-100 via-gray-50 to-gray-100"
      style={{ animation: "shimmer 1s infinite" }}
    ></div>
  );

  return (
    <div
      className={twMerge(
        "relative overflow-hidden rounded bg-gray-100",
        className,
      )}
    >
      {movingElement}
    </div>
  );
};
