import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { InputSchemaTypes, OutputSchemaTypes } from "src/api/flowTypes";
import { FixedSimpleDropdown } from "src/base-components/FixedSimpleDropdown";
import { SCHEMA_TYPE_ICONS } from "src/base-components/TypeIcons";
import { SchemaTypeSelectorProps, SchemaUIT } from "src/schema/utils";

const INPUT_SCHEMA_TYPES_OPTIONS = Object.entries(InputSchemaTypes).map(
  ([key, value]) => ({
    key: key,
    value: value,
    icon: SCHEMA_TYPE_ICONS[value],
  }),
);

const OUTPUT_SCHEMA_TYPES_OPTIONS = Object.entries(OutputSchemaTypes).map(
  ([key, value]) => ({
    key: key,
    value: value,
    icon: SCHEMA_TYPE_ICONS[value],
  }),
);

export const SchemaTypeSelector: React.FC<SchemaTypeSelectorProps> = ({
  rowName,
  disabled,
  index,
  type,
}) => {
  const { control, setValue } = useFormContext<SchemaUIT>();

  return (
    <Controller
      control={control}
      name={`${rowName}.type.0`}
      render={(props) => (
        <div className="w-[140px]">
          <FixedSimpleDropdown
            buttonDataLoc="schema-field-type"
            disabled={disabled}
            elements={
              type === "input"
                ? INPUT_SCHEMA_TYPES_OPTIONS
                : OUTPUT_SCHEMA_TYPES_OPTIONS
            }
            placeholder="Select type"
            placement="bottomLeft"
            selectedKey={props.field.value}
            onSelect={(newType) => {
              if (newType === "enum") {
                if (props.field.value !== "enum") {
                  setValue(`properties.${index}.enum`, [
                    { value: '"value_1"' },
                  ]);
                }
              } else {
                setValue(`properties.${index}.enum`, undefined);
              }
              props.field.onChange(newType);
            }}
          />
        </div>
      )}
    />
  );
};
