import { clamp } from "lodash";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { CellId, parseCellId } from "src/datasets/DatasetTable/utils";

type RowSelection = `${number}_`;
export type Selection = CellId | RowSelection;

/**
 * Dataset Hovered Column Store
 */
type DatasetHoveredColumnState = {
  columnId: Nullable<string>;
};

type DatasetHoveredColumnActions = {
  setColumnId: (columnId: Nullable<string>) => void;
};

const useHoveredColumnStore = create(
  immer<ZustandStore<DatasetHoveredColumnState, DatasetHoveredColumnActions>>(
    (set) => ({
      columnId: null,

      actions: {
        setColumnId: (columnId) => set({ columnId }),
      },
    }),
  ),
);

export const useHoveredColumnActions = () =>
  useHoveredColumnStore((state) => state.actions);
export const useIsColumnHovered = (columnId: string | null) =>
  useHoveredColumnStore((state) => state.columnId === columnId);
export const useIsColumnHoveredLike = (columnId: string) =>
  useHoveredColumnStore((state) => Boolean(state.columnId?.includes(columnId)));

/**
 * Dataset Selected/Editing Cell Store
 */

type DatasetEditTableState = {
  selected: Nullable<Selection>;
  editingCellId: Nullable<Selection>;
};

type DatasetEditTableActions = {
  selectCell: (selection: Selection) => void;
  editCell: (selection: Selection) => void;
  reset: (field?: keyof DatasetEditTableState) => void;
  enableEditingMode: (
    cb?: (state: DatasetEditTableStore & { selected: Selection }) => boolean,
  ) => void;
  moveColumn: (diff: number, columnIds: string[]) => void;
  moveRow: (diff: 1 | -1, rowsCount: number) => void;
};

type DatasetEditTableStore = ZustandStore<
  DatasetEditTableState,
  DatasetEditTableActions
>;

const isSelectionExist = (
  state: DatasetEditTableStore,
): state is DatasetEditTableStore & { selected: Selection } =>
  state.selected !== null;

// Column 0 reserved for row numbers column
const FIRST_COLUMN_INDEX = 1;

const useDatasetEditTableStore = create(
  immer<DatasetEditTableStore>((set) => ({
    selected: null,
    editingCellId: null,

    actions: {
      selectCell: (cellId) =>
        set((state) => {
          if (state.selected !== cellId) {
            state.selected = cellId;
            state.editingCellId = null;
          }
        }),

      editCell: (cellId) =>
        set((state) => {
          state.selected = cellId;
          state.editingCellId = cellId;
        }),

      reset: (field) =>
        field
          ? set({ [field]: null })
          : set({
              selected: null,
              editingCellId: null,
            }),

      enableEditingMode: (predicate) =>
        set((state) => {
          // Enable editing mode only if some cell is selected
          // and predicate is true
          if (isSelectionExist(state) && (!predicate || predicate(state))) {
            state.editingCellId = state.selected;
          }
        }),

      moveColumn: (diff, columnIds) =>
        set((state) => {
          if (state.selected) {
            const [rowIndex, colId] = parseSelection(state.selected);
            const index = columnIds.findIndex((id) => id === colId);
            const nextColumnIndex = clamp(
              index + diff,
              FIRST_COLUMN_INDEX,
              columnIds.length - 1,
            );

            state.selected = stringifySelection(
              rowIndex,
              columnIds[nextColumnIndex]!,
            );
          }
          state.editingCellId = null;
        }),

      moveRow: (diff, rowsCount) =>
        set((state) => {
          if (state.selected) {
            const [rowIndex, colId] = parseSelection(state.selected);

            state.selected = stringifySelection(
              clamp(rowIndex + diff, -1, rowsCount),
              colId,
            );
          }
          state.editingCellId = null;
        }),
    },
  })),
);

export const useDatasetEditTableActions = () =>
  useDatasetEditTableStore((state) => state.actions);
export const useIsSelectedCell = (selection: Selection) =>
  useDatasetEditTableStore((state) => state.selected === selection);
export const useIsEditingCell = (selection: Selection) =>
  useDatasetEditTableStore((state) => state.editingCellId === selection);
export const useDatasetTableMode = () =>
  useDatasetEditTableStore((state) =>
    state.editingCellId ? "edit" : state.selected ? "select" : "view",
  );
export const useIsRowSelected = (rowIndex: number) =>
  useDatasetEditTableStore((state) => state.selected === `${rowIndex}_`);

export const stringifySelection = (
  rowIndex: number,
  colId: Nullable<string> | undefined,
): Selection => `${rowIndex}_${colId || ""}`;

export const parseSelection = (
  selection: Selection,
): [number, string | null] => {
  const [rowIndex, colId] = parseCellId(selection);
  return [rowIndex, colId.length ? colId : null];
};
