import {
  faClose,
  faEdit,
  faSpinner,
  faUndo,
} from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { twJoin } from "tailwind-merge";

import {
  useDeleteOrganizationInvite,
  useUpdateOrganizationUser,
} from "src/api/taktile/mutations";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { Icon } from "src/base-components/Icon";
import { toastSuccess } from "src/base-components/Toast/utils";
import { Tooltip } from "src/base-components/Tooltip";
import { useCurrentUserId } from "src/store/AuthStore";
import { UserRow } from "src/userManagement/types";

type PropsT = {
  user: UserRow;
};

type ConfirmationType = "delete" | "reactivate" | "deactivate";

export const UserActions: React.FC<PropsT> = ({ user }) => {
  const userId = useCurrentUserId();
  const isCurrentUser = userId === user.id;
  const [confirmationType, setConfirmationType] =
    useState<Nullable<ConfirmationType>>(null);
  const [isOpen, setOpen] = useState(false);
  const { id, organization_id: orgId, invite_id: inviteId } = user;
  const { mutateAsync: deleteInvite, isLoading: inviteLoading } =
    useDeleteOrganizationInvite(orgId, inviteId ?? "");
  const { mutateAsync: updateUser, isLoading: orgUserLoading } =
    useUpdateOrganizationUser(orgId, id);
  const isLoading = inviteLoading || orgUserLoading;

  const confirmationText = {
    delete: {
      title: "Delete invite",
      description: "Are you sure you want to delete this invite?",
    },
    deactivate: {
      title: "Deactivate user",
      description:
        "Are you sure you want to deactivate this user? They will not be able to access any workspaces in your organization.",
    },
    reactivate: {
      title: "Reactivate user",
      description:
        "Are you sure you want to reactivate this user? This will give them access to all the projects they were a part of before.",
    },
  };

  const handleAction = (action: ConfirmationType) => {
    if (isLoading) {
      return;
    }
    setConfirmationType(action);
    setOpen(true);
  };

  const handleConfirm = async () => {
    if (user.status === "active") {
      await updateUser({ is_active: false });
      toastSuccess({ title: "User deactivated successfully." });
    }

    if (user.status === "invited") {
      await deleteInvite({});
      toastSuccess({ title: "Invite deleted successfully." });
    }

    if (user.status === "deactivated") {
      await updateUser({ is_active: true });
      toastSuccess({ title: "User reactivated successfully." });
    }
    setOpen(false);
  };

  return (
    <div
      className={twJoin(
        "flex w-full items-center justify-end opacity-0 group-hover:opacity-100",
        (isLoading || confirmationType !== null) && "opacity-100",
      )}
    >
      {user.onEdit && (
        <Tooltip
          align="center"
          placement="top"
          title="Edit permissions"
          asChild
        >
          <Icon
            color="text-gray-500 hover:text-gray-700"
            dataLoc={`edit-permissions-${user.username}`}
            icon={faEdit}
            size="xs"
            onClick={user.onEdit}
          />
        </Tooltip>
      )}
      {!isCurrentUser && user.status === "deactivated" && (
        <Tooltip
          align="center"
          placement="top"
          title={confirmationText["reactivate"].title}
          asChild
        >
          <Icon
            color="text-gray-500 hover:text-gray-700"
            icon={isLoading ? faSpinner : faUndo}
            size="xs"
            spin={isLoading}
            onClick={() => handleAction("reactivate")}
          />
        </Tooltip>
      )}
      {!isCurrentUser && user.status !== "deactivated" && (
        <Tooltip
          align="center"
          placement="top"
          title={
            user.status === "active"
              ? confirmationText["deactivate"].title
              : confirmationText["delete"].title
          }
          asChild
        >
          <Icon
            color="text-gray-500 hover:text-gray-700"
            icon={isLoading ? faSpinner : faClose}
            size="xs"
            spin={isLoading}
            onClick={() =>
              user.status === "active"
                ? handleAction("deactivate")
                : handleAction("delete")
            }
          />
        </Tooltip>
      )}
      <ConfirmationModal
        afterLeave={() => setConfirmationType(null)}
        confirmationButtonClassname="w-[132px]"
        confirmationButtonText={
          confirmationType ? confirmationText[confirmationType].title : ""
        }
        confirmationButtonType={
          confirmationType === "deactivate" ? "warning" : "primary"
        }
        open={isOpen}
        title={confirmationType ? confirmationText[confirmationType].title : ""}
        onClose={() => setOpen(false)}
        onConfirm={handleConfirm}
      >
        <p className="pb-6 pt-4 text-gray-500 font-inter-normal-12px">
          {confirmationType
            ? confirmationText[confirmationType].description
            : ""}
        </p>
      </ConfirmationModal>
    </div>
  );
};
