import {
  faChevronDown,
  faCheck,
  faSpinner,
} from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { twJoin } from "tailwind-merge";

import { RenderValueFn } from "src/base-components/DropDown";
import {
  FixedPositionedDropdown,
  FixedPositionedDropdownPropsT,
} from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { SimpleDropDownPropsT } from "src/base-components/SimpleDropDown";

type Props = Omit<
  SimpleDropDownPropsT,
  "className" | "buttonClassName" | "itemsClassNames"
> &
  Pick<FixedPositionedDropdownPropsT<any>, "placementOffsetX">;

export const FixedSimpleDropdown: React.FC<Props> = ({
  onSelect,
  elements,
  selectedKey,
  placeholder,
  buttonDataLoc,
  placement,
  itemsWidth = "w-56",
  loading = false,
  ...props
}) => {
  const selectedItem = elements.find((element) => element.key === selectedKey);
  const renderValue: RenderValueFn<string> = ({ value, selected, icon }) => (
    <div
      className={twJoin(
        "flex gap-x-2 border-y border-y-transparent px-4 py-3",
        itemsWidth,
      )}
      data-loc={`select-${value}`}
    >
      {icon && <Icon color="text-gray-500" icon={icon} size="xs" />}
      <span>{value}</span>
      {selected && (
        <span className="ml-auto">
          <Icon color="text-indigo-500" icon={faCheck} size="sm" />
        </span>
      )}
    </div>
  );

  const renderButtonValue = (value?: string) => (
    <div className="flex w-full items-center gap-x-1 py-1.5 pl-3 pr-2">
      {selectedItem?.icon && (
        <Icon
          color={props.disabled ? "text-gray-300" : "text-gray-500"}
          icon={selectedItem.icon}
          size="xs"
        />
      )}
      <div
        className={twJoin(
          "min-w-0 grow text-left font-inter-normal-12px",
          props.disabled ? "text-gray-500" : "text-gray-800",
        )}
      >
        {value ? value : placeholder}
      </div>
      {(loading || (elements.length > 0 && !props.disabled)) && (
        <div className="ml-auto flex h-auto items-center">
          <Icon
            color="text-gray-500"
            icon={loading ? faSpinner : faChevronDown}
            size="xs"
            spin={loading}
          />
        </div>
      )}
    </div>
  );

  return (
    <FixedPositionedDropdown
      buttonDataLoc={buttonDataLoc}
      elements={elements}
      placement={placement}
      renderButtonValue={renderButtonValue}
      renderValue={renderValue}
      selected={selectedKey}
      onSelect={onSelect}
      {...props}
    />
  );
};
