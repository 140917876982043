import { useInfiniteQuery, useMutation } from "@tanstack/react-query";

import { auditLogsApi } from "src/api/taktileApi";
import { AuditEventPage } from "src/clients/taktile-api";
import { StrictDateRange } from "src/utils/timeWindow";

export type AuditEvent = AuditEventPage["events"][number];

type AuditLogParams = {
  organizationId: string;
  timeWindow: StrictDateRange;
  pageSize: number;
  actorId?: string;
};

export const useAuditLogsEvents = (params: AuditLogParams) => {
  return useInfiniteQuery<AuditEventPage>({
    queryKey: [params],
    queryFn: async ({ pageParam }) => {
      const response =
        await auditLogsApi.getEventsApiV1AuditLogsEventsOrganizationIdGet(
          params.organizationId,
          params.timeWindow.from.toISOString(),
          params.timeWindow.to.toISOString(),
          params.pageSize,
          pageParam,
          params.actorId,
        );
      return response.data;
    },
    getNextPageParam: (lastPage) => lastPage.next_page_token ?? undefined,
  });
};

export const useCreateAuditLogExport = () => {
  return useMutation({
    mutationFn: async (
      params: Pick<AuditLogParams, "timeWindow" | "actorId" | "organizationId">,
    ) => {
      const response =
        await auditLogsApi.createExportApiV1AuditLogsEventsOrganizationIdExportsPost(
          params.organizationId,
          {
            start_date: params.timeWindow.from.toISOString(),
            end_date: params.timeWindow.to.toISOString(),
            actor_id: params.actorId,
          },
        );
      return response.data;
    },
  });
};
