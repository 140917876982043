import { faCodeCompare } from "@fortawesome/pro-regular-svg-icons";
import { Root } from "@radix-ui/react-accordion";

import {
  EditorAccordionItem as AccordionItem,
  accordionRootClassName,
} from "src/base-components/EditorAccordionItem";
import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/base-components/Tooltip";
import { ChangeHistoryRow } from "src/changeHistory/ChangeHistoryRow";
import { OpenDiffViewParams } from "src/changeHistory/DiffViewModal/GetChangeToCompareAgainst";
import {
  ChangesGroupedByResource,
  getChangeResourceAndIcon,
} from "src/changeHistory/getChangeResourceAndIcon";
import { ChangeLogDb } from "src/clients/flow-api";

type ChangeHistoryGroupedProps = {
  direction?: "descending" | "ascending";
  groupedChanges: ChangesGroupedByResource;
  latestSeenEtag?: string;
  openDiffView?: (params: OpenDiffViewParams) => void;
};

const MiniChangeHistoryList: React.FC<{
  changes: ChangeLogDb[];
  latestSeenEtag?: string;
  openNodeDiffView?: (params: OpenDiffViewParams) => void;
  forNodeId?: string;
}> = ({ changes, latestSeenEtag, openNodeDiffView }) => (
  <ul className="decideScrollbar max-h-full flex-row overflow-y-auto">
    {changes.map((change, index) => (
      <li key={change.etag}>
        <ChangeHistoryRow
          change={change}
          hasNextRow={index !== changes.length - 1}
          latestSeenEtag={latestSeenEtag}
          openNodeDiffView={openNodeDiffView}
          hideChangeIcon
        />
      </li>
    ))}
  </ul>
);

const GroupAccordionItem: React.FC<{
  groupResource:
    | { nodeName: string; nodeIcon: React.ReactNode; nodeId: string }
    | "schema"
    | "parameters";
  changes: ChangeLogDb[];
  openDiffView?: (params: OpenDiffViewParams) => void;
  children: React.ReactNode;
}> = ({ groupResource, changes, openDiffView, children }) => {
  const titleText =
    groupResource === "schema"
      ? "Schema"
      : groupResource === "parameters"
        ? "Parameters"
        : groupResource.nodeName;
  const accordionValue =
    groupResource === "schema" || groupResource === "parameters"
      ? groupResource
      : groupResource.nodeId;
  const icon =
    groupResource === "schema" || groupResource === "parameters"
      ? getChangeResourceAndIcon(changes[0]).icon
      : groupResource.nodeIcon;
  return (
    <AccordionItem
      className="pb-3"
      headerContent={
        <div className="flex grow justify-end pr-4">
          <Tooltip
            align="center"
            placement="top"
            title="Inspect changes"
            asChild
          >
            <Icon
              color="text-gray-500 hover:text-gray-700"
              icon={faCodeCompare}
              size="xs"
              onClick={() => {
                openDiffView?.({
                  change: changes[0],
                  againstChangeBefore: changes[changes.length - 1],
                  forNodeId:
                    groupResource !== "schema" && groupResource !== "parameters"
                      ? groupResource.nodeId
                      : undefined,
                });
              }}
            />
          </Tooltip>
        </div>
      }
      title={
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-2">
            {icon}
            {titleText}
          </div>
        </div>
      }
      value={accordionValue}
    >
      {children}
    </AccordionItem>
  );
};

export const ChangeHistoryGrouped: React.FC<ChangeHistoryGroupedProps> = ({
  groupedChanges,
  latestSeenEtag,
  openDiffView,
}) => {
  if (!groupedChanges) return;
  const nodeIdsAlphabeticallyByName = Object.keys(groupedChanges.nodes).sort(
    (a, b) =>
      groupedChanges.nodes[a].displayName.localeCompare(
        groupedChanges.nodes[b].displayName,
      ),
  );
  return (
    <div className="mt-4 max-h-full overflow-y-auto px-6">
      <Root className={accordionRootClassName} type="multiple">
        {nodeIdsAlphabeticallyByName.map((nodeId) => {
          const nodeInfo = groupedChanges.nodes[nodeId];
          if (!nodeInfo) return null;
          return (
            <GroupAccordionItem
              key={nodeId}
              changes={nodeInfo.changes}
              groupResource={{
                nodeName: nodeInfo.displayName,
                nodeIcon: nodeInfo.displayIcon,
                nodeId: nodeId,
              }}
              openDiffView={openDiffView}
            >
              <MiniChangeHistoryList
                changes={nodeInfo.changes}
                forNodeId={nodeId}
                latestSeenEtag={latestSeenEtag}
                openNodeDiffView={openDiffView}
              />
            </GroupAccordionItem>
          );
        })}
        {groupedChanges?.schema.length > 0 && (
          <GroupAccordionItem
            changes={groupedChanges.schema}
            groupResource="schema"
            openDiffView={openDiffView}
          >
            <MiniChangeHistoryList
              changes={groupedChanges.schema}
              latestSeenEtag={latestSeenEtag}
              openNodeDiffView={openDiffView}
            />
          </GroupAccordionItem>
        )}
        {groupedChanges?.parameter.length > 0 && (
          <GroupAccordionItem
            changes={groupedChanges.parameter}
            groupResource="parameters"
            openDiffView={openDiffView}
          >
            <MiniChangeHistoryList
              changes={groupedChanges.parameter}
              latestSeenEtag={latestSeenEtag}
              openNodeDiffView={openDiffView}
            />
          </GroupAccordionItem>
        )}
      </Root>
    </div>
  );
};
