import { noop } from "lodash";
import { useCallback, useState } from "react";

import { FlowVersionT } from "src/api/flowTypes";
import { DatasetFileUpload, DatasetPurpose } from "src/api/types";
import {
  useDatasetFileUploads,
  useUploadDataset,
} from "src/datasets/api/queries";
import { useInputColumnsFromSchema } from "src/datasets/utils";
import { useFlowContext } from "src/router/routerContextHooks";

type FileUploadWithCallback = DatasetFileUpload & {
  onFinished: (finishedUpload: DatasetFileUpload) => void;
};

export const useDatasetFileUpload = (version?: FlowVersionT) => {
  const [uploads, setUploads] = useState<FileUploadWithCallback[]>([]);

  const { workspace, flow } = useFlowContext();
  const inputColumnsToOverwrite = useInputColumnsFromSchema(version);

  const { mutateAsync: uploadDatasetFile } = useUploadDataset(
    workspace.base_url!,
    flow.id,
    inputColumnsToOverwrite,
  );

  const uploadDataset = useCallback(
    async (args: { file: File; purpose: DatasetPurpose }) => {
      const dataset = await uploadDatasetFile(args);
      const finishedPromise = new Promise<DatasetFileUpload>((resolve) => {
        setUploads((uploads) => [
          ...uploads,
          { ...dataset, onFinished: resolve },
        ]);
      });
      return { ...dataset, finishedPromise };
    },
    [uploadDatasetFile],
  );

  useDatasetFileUploads(workspace.base_url!, uploads, (dataset) => {
    if (dataset.status !== "PENDING") {
      // Notify subscribers that a file upload is finished
      const originalDatasetFileUpload = uploads.find(
        (u) => u.id === dataset.id,
      );
      originalDatasetFileUpload?.onFinished(dataset);
    }

    if (dataset.status === "COMPLETED") {
      setUploads((uploads) =>
        uploads.filter((upload) => upload.id !== dataset.id),
      );
    }

    if (dataset.status === "FAILED") {
      setUploads((uploads) =>
        uploads.map((upload) =>
          upload.id === dataset.id ? { ...dataset, onFinished: noop } : upload,
        ),
      );
    }
  });

  const deleteFromUploads = useCallback((datasetId: string) => {
    setUploads((uploads) =>
      uploads.filter((upload) => upload.id !== datasetId),
    );
  }, []);

  return {
    uploads,
    uploadDataset,
    deleteFromUploads,
  } as const;
};
