import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import {
  FieldErrorsImpl,
  FieldError,
  Merge,
  UseFormRegisterReturn,
} from "react-hook-form";

import { Input } from "src/base-components/Input";
import { Tooltip } from "src/base-components/Tooltip";
import { PropertyUIT } from "src/schema/utils";

type FieldNameErrorT = { title: string; description: string };

const fieldNameErrors: Record<string, FieldNameErrorT> = {
  required: {
    title: "No Name Specified",
    description: "Field name cannot be empty.",
  },
  unique: {
    title: "Duplicate Name",
    description: "Field name must be unique.",
  },
  pattern: {
    title: "Invalid Character",
    description: `Field name cannot have " or \\ as a character.`,
  },
};

export const SchemaNameInput: React.FC<{
  disabled: boolean;
  errorStatus?: Merge<FieldError, FieldErrorsImpl<PropertyUIT>>;
  formProps: UseFormRegisterReturn;
}> = ({ errorStatus, disabled, formProps }) => {
  const nameError = errorStatus?.fieldName
    ? fieldNameErrors[errorStatus.fieldName.type]
    : null;

  const errored = errorStatus?.fieldName !== undefined;

  return (
    <Tooltip
      activated={nameError !== null}
      body={nameError?.description}
      placement="top"
      title={nameError ? nameError.title : ""}
      asChild
    >
      <div className="w-full">
        <Input
          data-loc="schema-field-name"
          disabled={disabled}
          errored={errored}
          fullWidth
          {...formProps}
          suffixIcon={errored ? { icon: faWarning } : undefined}
        />
      </div>
    </Tooltip>
  );
};
