import { FlipProp, IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { createContext, useContext, useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { Tooltip, TooltipContentProps } from "src/base-components/Tooltip";

// Link to the figma file with the variants and sizes: https://www.figma.com/file/M6oWoeOynJBBK0xppg19h9/Decide-Redesign%3A-Deliverables?node-id=2406%3A10113&t=VPSpQ2WFCRjDtNU5-0

type IconProps = {
  icon: IconProp;
  color?: string;
  size?: "xs" | "sm" | "base" | "md";
  padding?: boolean;
  spin?: boolean;
  flip?: FlipProp;
};

type ImageProps = {
  src: string;
  alt?: string;
};

type SubComponents = {
  Text: React.FC<{ children: React.ReactNode; fontType?: "text" | "code" }>;
  Icon: React.FC<IconProps>;
  Image: React.FC<ImageProps>;
};

export type PillVariants =
  | "indigo-light"
  | "red"
  | "gray"
  | "yellow"
  | "green"
  | "white"
  | "indigo"
  | "outlined-white"
  | "outlined-gray"
  | "dark-gray";

type PillProps = {
  onClick?: (event: React.MouseEvent) => void;
  dataLoc?: string;
  children?: React.ReactNode;
  variant?: PillVariants;
  size?: "sm" | "base" | "lg";
  fullWidth?: boolean;
  maxWidth?: boolean;
  hovered?: boolean;
} & Pick<TooltipContentProps, "title" | "body">;

const PillContext = createContext<{ variant: PillVariants }>({
  variant: "indigo-light",
});

export const Pill: React.FC<PillProps> & SubComponents = ({
  children,
  onClick,
  dataLoc,
  variant = "indigo-light",
  size = "base",
  fullWidth = false,
  maxWidth = false,
  title,
  body,
  hovered = false,
}) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const basePill = (
    <div
      className={twMerge(
        "inline-flex h-fit items-center whitespace-nowrap border",
        variant === "indigo-light" &&
          "border-indigo-50 bg-indigo-50 text-indigo-500",
        variant === "red" && "border-red-50 bg-red-50 text-red-500",
        variant === "gray" && "border-gray-100 bg-gray-100 text-gray-600",
        variant === "outlined-gray" &&
          "border-gray-300 bg-gray-100 text-gray-800",
        variant === "yellow" && "border-yellow-50 bg-yellow-50 text-yellow-600",
        variant === "green" && "border-green-50 bg-green-50 text-green-700",
        variant === "outlined-white" &&
          "border-gray-200 bg-white text-gray-600",
        variant === "indigo" && "border-indigo-500 bg-indigo-500 text-white",
        variant === "white" && "border-white bg-white text-gray-600",
        variant === "dark-gray" && "border-gray-700 bg-gray-700 text-gray-50",

        size === "sm" && "rounded px-1",
        size === "base" && "rounded-full px-2 py-px",
        size === "lg" && "h-8 rounded-full px-3 py-1",

        onClick && "cursor-pointer",

        fullWidth && "w-full",
        maxWidth && "max-w-full",
        ((Boolean(title) && isTooltipOpen) || hovered) && "bg-gray-50",
      )}
      data-loc={dataLoc}
      onClick={onClick}
    >
      <PillContext.Provider value={{ variant }}>
        {children}
      </PillContext.Provider>
    </div>
  );

  return title ? (
    <Tooltip
      body={body}
      placement="bottom"
      title={title}
      asChild
      onOpenChange={(isOpen) => setTooltipOpen(isOpen)}
    >
      {basePill}
    </Tooltip>
  ) : (
    basePill
  );
};

const PillIcon: React.FC<IconProps> = ({
  icon,
  size = "base",
  padding,
  spin,
  flip,
  color,
}) => {
  const { variant } = useContext(PillContext);
  return (
    <Icon
      color={
        color ??
        {
          "indigo-light": "text-indigo-400",
          red: "text-red-400",
          gray: "text-gray-500",
          white: "text-gray-400",
          yellow: "text-yellow-400",
          green: "text-green-400",
          indigo: "text-white",
          "outlined-white": "text-gray-500",
          "outlined-gray": "text-gray-800",
          "dark-gray": "text-gray-50",
        }[variant]
      }
      flip={flip}
      icon={icon}
      padding={padding}
      size={
        size === "md"
          ? "xs"
          : size === "base"
            ? "2xs"
            : size === "sm"
              ? "3xs"
              : size === "xs"
                ? "4xs"
                : "2xs"
      }
      spin={spin}
    />
  );
};

const PillImage: React.FC<ImageProps> = ({ src, alt }) => {
  return (
    <img
      alt={alt ?? ""}
      className="aspect-square w-4.5 rounded-full"
      src={src}
    />
  );
};

Pill.Text = ({ children, fontType = "text" }) => {
  return (
    <span
      className={twJoin(
        "min-w-0 truncate",
        fontType === "text" && "mx-1 font-inter-medium-12px",
        fontType === "code" && "font-fira-code",
      )}
    >
      {children}
    </span>
  );
};
Pill.Icon = PillIcon;
Pill.Image = PillImage;
