import { CustomConnectionAuthMethod } from "src/api/connectApi/types";

type ProviderAuthOptionElement = {
  key: CustomConnectionAuthMethod;
  value: string;
};

const NO_AUTH = {
  key: "no_auth",
  value: "No authentication",
} as const;
const BASIC_AUTH = {
  key: "basic",
  value: "Basic authentication",
} as const;
const API_KEY_AUTH_OPTION = {
  key: "api_key",
  value: "API key authentication",
} as const;
const O_AUTH_2 = {
  key: "oauth2",
  value: "OAuth 2.0 authentication",
} as const;
const GOOGLE_OAUTH_2 = {
  key: "google_oauth2",
  value: "Google OAuth 2.0 authentication",
} as const;
const AWS_SIGNATURE = {
  key: "aws_signature",
  value: "AWS signature authentication",
} as const;

export const ccCreationAuthMethods: ProviderAuthOptionElement[] = [
  NO_AUTH,
  BASIC_AUTH,
  API_KEY_AUTH_OPTION,
  O_AUTH_2,
  AWS_SIGNATURE,
  GOOGLE_OAUTH_2,
];

export type OutgoingWebhookConnectionAuthMethod =
  (typeof OUTGOING_WEBHOOK_CONNECTION_AUTH_METHODS)[number]["key"];
export const isOutgoingWebhookConnectionAuthMethod = (
  method: CustomConnectionAuthMethod,
): method is OutgoingWebhookConnectionAuthMethod => {
  return OUTGOING_WEBHOOK_CONNECTION_AUTH_METHODS.some(
    (option) => option.key === method,
  );
};
export const OUTGOING_WEBHOOK_CONNECTION_AUTH_METHODS = [
  NO_AUTH,
  API_KEY_AUTH_OPTION,
  O_AUTH_2,
] as const;
