import React from "react";

type MetadataFieldPropsT = {
  name: string;
  value: string;
  dataLoc: string;
};

export const MetadataField: React.FC<MetadataFieldPropsT> = ({
  name,
  value,
  dataLoc,
}) => {
  return (
    <div className="flex gap-1" data-loc={dataLoc}>
      <div className="w-1/4 min-w-[100px] text-gray-600 font-inter-normal-12px">
        {name}
      </div>
      <div className="w-3/4 text-gray-800 font-inter-normal-13px">{value}</div>
    </div>
  );
};
