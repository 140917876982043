type SourceTargetPosition = {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
};

const NODE_MARGIN = 20;
const SPLIT_NODE_MARGIN = 30;
const HANDLE_MARGIN = 3;

export const getFlowEdgePath = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
}: SourceTargetPosition): {
  verticalPath: string;
  horizontalPath: string | null;
  completePath: string;
} => {
  sourceY -= HANDLE_MARGIN + 1;
  targetY += HANDLE_MARGIN;
  if (Math.abs(targetX - sourceX) <= NODE_MARGIN) {
    // path on same level with arrow down end
    targetX = sourceX;

    return {
      verticalPath: `M ${sourceX} ${sourceY}
    L ${targetX} ${targetY}`,
      horizontalPath: null,
      completePath: `M ${sourceX} ${sourceY}
      L ${targetX} ${targetY}`,
    };
  } else {
    return {
      verticalPath: `M ${sourceX} ${sourceY}
      L ${sourceX} ${targetY}`,
      horizontalPath: `M ${sourceX} ${targetY}
      L ${targetX} ${targetY}`,
      completePath: `M ${sourceX} ${sourceY}
      L ${sourceX} ${targetY}
      L ${targetX} ${targetY}`,
    };
  }
};

export const getSplitEdgePath = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
}: SourceTargetPosition): string => {
  sourceY -= HANDLE_MARGIN + 1;
  targetY += HANDLE_MARGIN;
  if (Math.abs(targetX - sourceX) <= NODE_MARGIN) {
    // path on same level with arrow down end
    targetX = sourceX;
    return `M ${sourceX} ${sourceY}
    L ${targetX} ${targetY}`;
  } else if (targetX > sourceX) {
    // path from left to right with arrow down end
    return `M ${sourceX} ${sourceY}
    L ${sourceX} ${sourceY + SPLIT_NODE_MARGIN}
    M ${sourceX} ${sourceY + SPLIT_NODE_MARGIN}
    L ${targetX} ${sourceY + SPLIT_NODE_MARGIN}
    L ${targetX} ${targetY}`;
  } else {
    // path from right to left with arrow right end
    return `M ${sourceX} ${sourceY}
    L ${sourceX} ${sourceY + SPLIT_NODE_MARGIN}
    M ${sourceX} ${sourceY + SPLIT_NODE_MARGIN}
    L ${targetX} ${sourceY + SPLIT_NODE_MARGIN}
    L ${targetX} ${targetY}`;
  }
};

export const getFlowEdgeCenter = ({
  sourceX,
  sourceY,
  targetX: _targetX,
  targetY,
}: SourceTargetPosition): Array<number> => {
  const edgeCenterX = sourceX;
  const edgeCenterY = (sourceY + targetY) / 2;
  return [edgeCenterX, edgeCenterY];
};
