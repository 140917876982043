import { isEmpty } from "lodash";

import { flowVersionDbToFlowVersionT } from "src/api";
import { NodeTestRunResult } from "src/api/types";
import { FlowVersionDbShallow } from "src/clients/flow-api";
import { useUploadDataset } from "src/datasets/api/queries";
import { useInputColumnsFromSchema } from "src/datasets/utils";
import { usePaginatedResults } from "src/nodeEditor/usePaginatedResults";
import { useAuthoringContext } from "src/router/routerContextHooks";

export const useUploadParentNodeDataToChild = (
  nodeResult: NodeTestRunResult,
  childFlowId: string,
  childVersion: FlowVersionDbShallow | undefined,
) => {
  const { workspace } = useAuthoringContext();
  const { firstPagesData, failureRowsQuery } = usePaginatedResults(
    nodeResult,
    false,
    "all",
  );

  const inputColumnsToOverwrite = useInputColumnsFromSchema(
    childVersion ? flowVersionDbToFlowVersionT(childVersion) : childVersion,
  );
  const { mutateAsync: uploadDatasetFile } = useUploadDataset(
    workspace.base_url!,
    childFlowId,
    inputColumnsToOverwrite,
  );

  const dataReadyForUpload =
    firstPagesData !== undefined && failureRowsQuery !== undefined;

  const uploadDataToChild = async (
    nameWithoutExtension: string,
    onlyFailures: boolean,
  ) => {
    if (dataReadyForUpload) {
      const data = onlyFailures
        ? failureRowsQuery.data?.pages[0].data
        : firstPagesData;

      if (data) {
        // We need the FlatMap here because the flow_nodes_inputs is an array of arrays for loop nodes
        const childNodeInvokingData = data
          .flatMap((resultRow) => resultRow.flow_nodes_inputs)
          .filter((inputs) => inputs && !isEmpty(inputs));
        const tempFile = new File(
          [JSON.stringify(childNodeInvokingData)],
          nameWithoutExtension + ".json",
        );
        await uploadDatasetFile({ file: tempFile, purpose: "test_run" });
      }
    }
  };
  return { dataReadyForUpload, uploadDataToChild };
};
