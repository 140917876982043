import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Switch } from "src/base-components/Switch";
import { CommonSchemaEditRowPropsT, SchemaUIT } from "src/schema/utils";

export const SchemaNullableSwitch: React.FC<CommonSchemaEditRowPropsT> = ({
  rowName,
  disabled,
}) => {
  const { control } = useFormContext<SchemaUIT>();
  return (
    <Controller
      control={control}
      name={`${rowName}.type.1`}
      render={(props) => (
        <Switch
          dataLoc="schema-field-nullable"
          disabled={disabled}
          enabled={props.field.value === "null"}
          onChange={() => {
            props.field.onChange(
              // Calling onChage with `undefined` is not valid in react-hook-form
              // So use `false` instead. But it contradicts JSON schema.
              props.field.value === "null" ? false : "null",
            );
          }}
        />
      )}
    />
  );
};
