import {
  faArrowLeft,
  faArrowRight,
  faClone,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { findIndex } from "lodash";
import { Dispatch } from "react";

import { EditorCellProps } from "src/base-components/EditorTable/types";
import { CellStringEditor } from "src/decisionTableNode/CellStringEditor";
import { ContextMenu } from "src/decisionTableNode/ContextMenu";
import { DecisionTableActions } from "src/decisionTableNode/actions";
import { DecisionTableAction } from "src/decisionTableNode/useDecisionTableActionsReducer";
import { RowShape } from "src/decisionTableNode/utils";

const ResultContextMenu: React.FC<{
  index: number;
  show: boolean;
  dispatch?: Dispatch<DecisionTableAction>;
  isLastItem: boolean;
}> = ({ index, show, dispatch, isLastItem }) => (
  <div className="h-fit -translate-y-1/2">
    <ContextMenu button="horizontal" show={show}>
      <ContextMenu.Item
        icon={faArrowLeft}
        onClick={() =>
          dispatch?.({
            type: DecisionTableActions.INSERT_RESULT_LEFT,
            payload: index,
          })
        }
      >
        Insert Result Left
      </ContextMenu.Item>
      <ContextMenu.Item
        icon={faArrowRight}
        onClick={() =>
          dispatch?.({
            type: DecisionTableActions.INSERT_RESULT_RIGHT,
            payload: index,
          })
        }
      >
        Insert Result Right
      </ContextMenu.Item>
      <ContextMenu.Item
        icon={faClone}
        onClick={() =>
          dispatch?.({
            type: DecisionTableActions.DUPLICATE_RESULT,
            payload: index,
          })
        }
      >
        Duplicate
      </ContextMenu.Item>
      {!isLastItem && (
        <ContextMenu.Item
          icon={faTrashAlt}
          onClick={() =>
            dispatch?.({
              type: DecisionTableActions.DELETE_RESULT,
              payload: index,
            })
          }
        >
          Delete Result
        </ContextMenu.Item>
      )}
    </ContextMenu>
  </div>
);

export const ResultFieldCell: React.FC<EditorCellProps<RowShape>> = ({
  dispatch,
  state,
  ...props
}) => {
  const isLastItem = (props.context.column.parent?.columns.length || 0) <= 1;
  const index = findIndex(
    props.context.column.parent?.columns,
    (c) => c.id === props.context.column.id,
  );

  return (
    <div className="w-full flex-col items-center">
      {!state.readonly && (
        <div className="h-0">
          <ResultContextMenu
            dispatch={dispatch}
            index={index}
            isLastItem={isLastItem}
            show={state.isColumnHovered}
          />
        </div>
      )}
      <CellStringEditor
        {...props}
        placeholder={props.columnDef.header.placeholder}
        state={state}
      />
    </div>
  );
};
