import React from "react";

import {
  InboundWebhookURLField,
  WebhookURLsT,
} from "src/connections/config/inboundWebhook/InboundWebhookURLField";

export const InboundWebhookConnectionCreatedForm: React.FC<WebhookURLsT> = ({
  productionWebhookURL,
  sandboxWebhookURL,
}) => {
  return (
    <InboundWebhookURLField
      productionWebhookURL={productionWebhookURL}
      sandboxWebhookURL={sandboxWebhookURL}
    />
  );
};
