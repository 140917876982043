import { useFormContext } from "react-hook-form";

import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { InboundWebhookSecretField } from "src/connections/config/inboundWebhook/InboundWebhookSecretField";
import { AuthMethodPropsT } from "src/connections/config/inboundWebhook/types";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { InboundWebhookConnectionConfigInputsT } from "src/connections/types";

export const InboundWebhookBasicAuthFieldset: React.FC<AuthMethodPropsT> = ({
  environment,
}) => {
  const {
    formState: { errors },
  } = useFormContext<InboundWebhookConnectionConfigInputsT>();
  const settingsErrors =
    errors?.[`${environment}Config`]?.webhookBasicAuthConfig;
  return (
    <FieldsetCard className="last:mb-0">
      <InboundWebhookSecretField
        dataLoc={`${environment}-webhook-basic-login`}
        environment={environment}
        helpText="Username for the webhook authentication as specified by the provider"
        isErrored={isFieldErrored(settingsErrors?.login, "value")}
        secretConfigField="webhookBasicAuthConfig.login"
        secretName="Username"
        isRequired
      />
      <InboundWebhookSecretField
        dataLoc={`${environment}-webhook-basic-password`}
        environment={environment}
        helpText="Password for the webhook authentication as specified by the provider"
        isErrored={isFieldErrored(settingsErrors?.password, "value")}
        secretConfigField="webhookBasicAuthConfig.password"
        secretName="Password"
        isRequired
      />
    </FieldsetCard>
  );
};
