import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  useCreateConnection,
  useEditConnection,
} from "src/api/connectApi/queries";
import { ConnectionT } from "src/api/connectApi/types";
import {
  taktileInternalPrefix,
  validateNotStartsWithInternalPrefix,
} from "src/api/constants";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { toastFailure, toastSuccess } from "src/base-components/Toast/utils";
import { EnableNonProdConfigsField } from "src/connections/config/EnableNonProdConfigsField";
import { MySQLEnvironmentConfig } from "src/connections/config/database/mysql/MySQLEnvironmentConfig";
import { DatabasePermissionsPill } from "src/connections/config/database/shared/DatabasePermissionsPill";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { EnvironmentTabs } from "src/connections/config/shared/EnvironmentTabs";
import { errorProps } from "src/connections/config/shared/errorProps";
import {
  getMySQLConnectionConfigInputsDefaultValues,
  convertBEConnectionToMySQLConnectionConfigInputs,
  convertMySQLConnectionConfigInputsToBEConnection,
} from "src/connections/model/mysql";
import { MySQLConnectionConfigInputsT } from "src/connections/types";
import { Modal } from "src/design-system/Modal";

type PropsT = {
  onClose: () => void;
  workspaceUrl: string;
  connection?: ConnectionT;
};

export const MySQLConnectionConfigForm: React.FC<PropsT> = ({
  onClose,
  workspaceUrl,
  connection,
}) => {
  const defaultValues = useMemo(() => {
    if (connection) {
      return convertBEConnectionToMySQLConnectionConfigInputs(connection);
    } else {
      return getMySQLConnectionConfigInputsDefaultValues();
    }
  }, [connection]);

  const formMethods = useForm<MySQLConnectionConfigInputsT>({
    defaultValues,
  });
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    watch,
  } = formMethods;

  const enableNonProdConfigs = watch("enableNonProdConfigs");

  const createConnectionMutation = useCreateConnection(workspaceUrl);
  const editConnectionMutation = useEditConnection(
    workspaceUrl,
    connection?.id,
  );

  const onSubmit = async (data: MySQLConnectionConfigInputsT) => {
    const payload = convertMySQLConnectionConfigInputsToBEConnection(data);
    const action = connection ? "updated" : "created";
    try {
      if (connection) {
        await editConnectionMutation.mutateAsync({
          id: connection.id,
          payload,
        });
      } else {
        await createConnectionMutation.mutateAsync(payload);
      }
      toastSuccess({
        title: `Connection ${action}`,
        description: `Connection ${data.name} has been ${action}.`,
        withSidebar: true,
      });
      onClose();
    } catch (e) {
      toastFailure({
        title: "Error",
        description: `The connection could not be ${action}`,
        withSidebar: true,
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <FormItem
            description="A name that describes the new connection"
            gap="sm"
            label="Connection name"
            isRequired
          >
            {Boolean(errors.name) && (
              <ErrorHint>{errors.name?.message}</ErrorHint>
            )}
            <Input
              data-loc="mysql-connection-name"
              placeholder="e.g. MySQL"
              fullWidth
              {...errorProps(isFieldErrored(errors, "name"))}
              {...register("name", {
                required: "Connection name is required",
                validate: validateNotStartsWithInternalPrefix(
                  `Connection name cannot start with ${taktileInternalPrefix}`,
                ),
              })}
            />
          </FormItem>
          <EnableNonProdConfigsField<MySQLConnectionConfigInputsT>
            dataLoc="mysql-connection-env-aware-switch"
            subtitle="You can set different credentials for test runs and sandbox DB queries"
            title="Use separate credentials for non-production DB queries"
          />
          {enableNonProdConfigs ? (
            <EnvironmentTabs
              prodContent={
                <MySQLEnvironmentConfig
                  environment="production"
                  labelsPrefix="Production"
                />
              }
              prodError={Boolean(errors.productionConfig)}
              sandboxContent={
                <MySQLEnvironmentConfig
                  environment="sandbox"
                  labelsPrefix="Sandbox"
                />
              }
              sandboxError={Boolean(errors.sandboxConfig)}
            />
          ) : (
            <MySQLEnvironmentConfig environment="production" labelsPrefix="" />
          )}
          <DatabasePermissionsPill />
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="mysql-connection-save"
              disabled={isSubmitting}
              htmlType="submit"
              loading={isSubmitting}
              size="base"
              variant="primary"
            >
              Save
            </Button>
          }
        ></Modal.Footer>
      </form>
    </FormProvider>
  );
};
