import React, { useRef } from "react";

import {
  StoreContext,
  getTableNavStore,
} from "src/base-components/EditorTable/stores";

/**
 * Store context provider for the editor table. As each table instance
 * requires its own store, this component should be wrapped around outside the table
 * component.
 */
export const EditorTableStoreProvider = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const store = useRef(getTableNavStore());
    return (
      <StoreContext.Provider value={store.current}>
        {children}
      </StoreContext.Provider>
    );
  },
);
