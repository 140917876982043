import { createContext, useContext, useRef } from "react";
import { createStore, useStore } from "zustand";
import { immer } from "zustand/middleware/immer";

import { ResultDataAndAuxRowV2 } from "src/dataTable/types";

/*
 ** This store is not supposed to use for graph related data.
 ** It is only for Authoring Page UI related data.
 */

type AuthoringUIState = {
  selectedResultsRow: ResultDataAndAuxRowV2 | null;
  isNodeEditorOpen: boolean;
};

type AuthoringUIActions = {
  setSelectedResultsRow: (row: ResultDataAndAuxRowV2 | null) => void;
  setNodeEditorOpen: (show: boolean) => void;
};

type AuthoringUIStore = ZustandStore<AuthoringUIState, AuthoringUIActions>;

const createAuthoringUIStore = () => {
  return createStore(
    immer<AuthoringUIStore>((set) => ({
      selectedResultsRow: null,
      isNodeEditorOpen: false,
      actions: {
        setSelectedResultsRow: (row: ResultDataAndAuxRowV2 | null) =>
          set((state) => {
            state.selectedResultsRow = row;
          }),
        setNodeEditorOpen: (show: boolean) =>
          set((state) => {
            state.isNodeEditorOpen = show;
          }),
      },
    })),
  );
};
type AuthoringUIContextType = ReturnType<typeof createAuthoringUIStore>;

const AuthoringUIContext = createContext<AuthoringUIContextType | null>(null);

export const AuthoringUIProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const storeRef = useRef<AuthoringUIContextType>();

  if (!storeRef.current) {
    storeRef.current = createAuthoringUIStore();
  }

  return (
    <AuthoringUIContext.Provider value={storeRef.current}>
      {children}
    </AuthoringUIContext.Provider>
  );
};

const useAuthoringUIStore = () => {
  const store = useContext(AuthoringUIContext);
  if (!store) {
    throw new Error("AuthoringUIContext is not defined");
  }
  return store;
};

export const useAuthoringUIActions = () => {
  const store = useAuthoringUIStore();
  return useStore(store, (state) => state.actions);
};

export const useSelectedResultsRowIndex = () => {
  const store = useAuthoringUIStore();
  return useStore(store, (state) =>
    state.selectedResultsRow ? state.selectedResultsRow.index : null,
  );
};

export const useIsSelectedResultsRow = (index: number) => {
  const store = useAuthoringUIStore();
  return useStore(store, (state) => state.selectedResultsRow?.index === index);
};

export const useEdgeIsPartOfSelectedResultsRow = (
  sourceNodeId?: string,
  targetNodeId?: string,
): boolean => {
  const store = useAuthoringUIStore();
  return useStore(store, (state) => {
    if (!sourceNodeId || !targetNodeId) {
      return false;
    }
    if (!state.selectedResultsRow) {
      return false;
    }
    const executedNodeIds = Object.keys(
      state.selectedResultsRow.nodeExecutionMetadata ?? {},
    );
    return (
      executedNodeIds.includes(sourceNodeId) &&
      executedNodeIds.includes(targetNodeId)
    );
  });
};

export const useSelectedResultsRowNodeExecutionMetadata = () => {
  const store = useAuthoringUIStore();
  return useStore(store, (state) =>
    state.selectedResultsRow
      ? state.selectedResultsRow.nodeExecutionMetadata
      : null,
  );
};
export const useNodeWasExecuted = (nodeId?: string) => {
  const store = useAuthoringUIStore();
  return useStore(store, (state) =>
    Boolean(
      nodeId && state.selectedResultsRow?.nodeExecutionMetadata?.[nodeId],
    ),
  );
};

export const useIsNodeEditorOpen = () => {
  const store = useAuthoringUIStore();
  return useStore(store, (state) => state.isNodeEditorOpen);
};
