import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faChevronDown,
  faSpinner,
} from "@fortawesome/pro-regular-svg-icons";
import { ReactNode } from "react";
import { twJoin } from "tailwind-merge";

import {
  FixedPositionedDropdown as Dropdown,
  FixedPositionedDropdownPropsT,
} from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";

type Props<T> = Pick<
  FixedPositionedDropdownPropsT<T>,
  "elements" | "disabled"
> & {
  onClick: () => void;
  loading?: boolean;
  prefixIcon?: IconProp;
  selected?: string;
  onSelect?: (key: string) => void;
};

export const SplitButton = <T extends ReactNode>({
  elements,
  onClick,
  onSelect,
  disabled,
  selected,
  loading,
  prefixIcon,
}: Props<T>) => {
  const renderButton = (value?: T) => (
    <div
      className={twJoin(
        "flex h-8 items-center rounded-lg font-inter-medium-13px",
        disabled
          ? "cursor-not-allowed bg-gray-100 text-gray-500"
          : "text-gray-800 shadow-sm",
      )}
    >
      <div
        className="flex items-center pl-3"
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled && !loading) onClick();
        }}
      >
        {prefixIcon && (
          <div className="mr-0.5">
            <Icon icon={prefixIcon} size="3xs" />
          </div>
        )}
        {value}
      </div>
      <div>
        <div className="ml-2 flex items-center border-l border-gray-200 pl-2 pr-3">
          <div className="block">
            <Icon
              color="text-gray-500"
              icon={loading ? faSpinner : faChevronDown}
              size="2xs"
              spin={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <Dropdown
      buttonClassName={disabled ? "" : "border"}
      disabled={disabled || loading}
      elements={elements}
      placement="bottomLeft"
      renderButtonValue={renderButton}
      renderValue={({ value, selected, disabled }) => {
        return (
          <div
            className={twJoin(
              "flex items-center justify-between py-3.5 pl-4 pr-5",
              disabled && "cursor-default",
            )}
          >
            <span
              className={twJoin("inline-block", disabled && "text-gray-300")}
            >
              {value}
            </span>
            {selected && (
              <div className="inline-block">
                <Icon color="text-indigo-600" icon={faCheck} />
              </div>
            )}
          </div>
        );
      }}
      selected={selected}
      listMatchesButtonWidth
      onSelect={onSelect}
    />
  );
};
