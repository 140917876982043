import {
  ConnectionResourceTemplate,
  makeConnectionTemplate,
} from "src/connections/types";

const legitimateInterestConfigKey: ConnectionResourceTemplate["configKeys"][number] =
  {
    key: "legitimate_interest",
    name: "Legitimate interest",
    type: "simple_selection",
    required: false,
    hint: `This is only relevant and required if you want to pull credit reports for German companies`,
    elements: [
      { key: "1", value: "Credit decisioning" },
      {
        key: "2",
        value:
          "Credit assessment - future business connection (with credit risk)",
      },
      {
        key: "3",
        value:
          "Credit assessment - existing business connection (with credit risk)",
      },
      { key: "4", value: "Realisation check receivables collection" },
      {
        key: "5",
        value:
          "Purchase contract - intention of signature of purchase contract (with credit risk)",
      },
      { key: "6", value: "Goods Credit Insurance (with credit risk)" },
      {
        key: "7",
        value:
          "Contract - intention of signature of leasing or rent contract (with credit risk)",
      },
      {
        key: "8",
        value:
          "Insurance contract - intention of signature of a insurance contract (with credit risk)",
      },
    ],
  };

export const CreditsafeTemplate = makeConnectionTemplate({
  provider: "creditsafe",
  secrets: [
    { key: "username", name: "Username" },
    { key: "password", name: "Password" },
  ],
  resources: {
    company_search: {
      name: "Company Search",
      configKeys: [],
    },
    company_credit_report: {
      name: "Company Credit Report",
      configKeys: [
        {
          key: "template",
          name: "Template",
          type: "text",
          required: false,
          hint: `Optional parameter to request a templated Company Report. A template adds or reduces sections of the credit report depending on your subscription. Do not fill this out if Creditsafe haven't provided you with a template to use.`,
        },
        {
          key: "include_indicators",
          name: "Include indicator scores",
          type: "switch",
          required: false,
          hint: `Include the following indicator scores in the Credit Report: COVID score, financial strength, estimated turnover`,
        },
        {
          key: "include_pdf_report",
          name: "Include PDF report",
          type: "switch",
          required: false,
          hint: `Include the Base64-encoded PDF report in the response. This will disable the Company Credit Report from being cached.`,
        },
        legitimateInterestConfigKey,
      ],
    },
  },
});
