import { useEffect, useState } from "react";

export const useElementScrolledToTop = (
  scrollContainer: HTMLDivElement | null,
) => {
  const [isScrolledToTop, setIsScrolledToTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainer) {
        setIsScrolledToTop(scrollContainer.scrollTop === 0);
      }
    };
    scrollContainer?.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer?.removeEventListener("scroll", handleScroll);
    };
  }, [scrollContainer]);

  return isScrolledToTop;
};
