import { v4 as uuidV4 } from "uuid";

import {
  DecisionTableCase,
  DecisionTableEffect,
  DecisionTableField,
  DecisionTableOperators,
  DecisionTablePredicate,
} from "src/clients/flow-api/api";

export const getDefaultPredicate = (
  options: Partial<DecisionTablePredicate> = {},
): DecisionTablePredicate => ({
  id: uuidV4(),
  value: "",
  operator: DecisionTableOperators.EQ,
  ...options,
});

export const getDefaultEffect = (
  options: Partial<DecisionTableEffect> = {},
): DecisionTableEffect => ({
  id: options.id ?? uuidV4(),
  value: options.value ?? "",
});

export const getDefaultField = (): DecisionTableField => ({
  id: uuidV4(),
  value: "",
});

export const getDefaultCase = (
  predicateFields: DecisionTableField[],
  effectFields: DecisionTableEffect[],
  options: Partial<DecisionTableCase> = {},
): DecisionTableCase => ({
  id: uuidV4(),
  name: "",
  predicates: predicateFields.reduce<{ [key: string]: DecisionTablePredicate }>(
    (acc, field) => {
      acc[field.id] = getDefaultPredicate();
      return acc;
    },
    {},
  ),
  effects: effectFields.reduce<{ [key: string]: DecisionTableEffect }>(
    (acc, field) => {
      acc[field.id] = getDefaultEffect();
      return acc;
    },
    {},
  ),
  ...options,
});

export const getDefaultFallback = (effectFields: DecisionTableEffect[]) =>
  effectFields.reduce<{ [key: string]: DecisionTableEffect }>((acc, field) => {
    acc[field.id] = getDefaultEffect({ value: "None" });
    return acc;
  }, {});
