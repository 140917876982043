import { RefObject, useCallback, useEffect, useState } from "react";
import { useTimeout } from "usehooks-ts";

import { usePageVisibility } from "src/authoringMultiplayerLock/usePageVisibility";
import { UPDATE_DEBOUNCE_WAIT_TIME } from "src/store/GraphStore";

type Args = {
  refList?: RefObject<any>[];
  considerMarkerClass?: string;
};

/**
 * Resolves the actual focused element when dealing with portal-rendered content.
 *
 * In cases where the active element is inside a HeadlessUI portal, this function
 * attempts to find the corresponding trigger element (e.g., a button) that opened
 * the portal. This allows for correct focus tracking even when interacting with
 * portal content.
 *
 * @param activeElement - The currently active DOM element
 * @returns The referenced trigger element if found within a portal, otherwise returns the original active element
 */
const getReferencedElement = (activeElement: Element | null) => {
  try {
    const portal = document.getElementById("headlessui-portal-root");

    if (portal && portal.contains(activeElement)) {
      // Get the headlessui id of the floating element
      const id = activeElement?.closest("[id^='headlessui-']")?.id;

      if (id) {
        const root = document.getElementById("root");
        // If there is an element that controls this portaled element, return it
        const referencedElement = root?.querySelector(
          `[aria-controls="${id}"]`,
        );

        // If the referenced element is found, return it
        if (referencedElement) {
          return referencedElement;
        }
      }
    }

    // If the referenced element is not found, return the active element
    return activeElement;
  } catch (e) {
    return activeElement;
  }
};

/**
 * Hook to be used when acquiring resource locks on focus.
 * Returned boolean is true when a child of a passed ref is focused or a child of an element with the passed marker class is focused.
 * Bluring out of the child refs gets reflected with a delay to avoid flickering the value when switching focus between inputs.
 * Optionally only considers childs of elements that are marked with the lock-on-focus class.
 */
export const useLockableChildFocused = ({
  refList,
  considerMarkerClass,
}: Args) => {
  const [childHasLockableFocus, direct_setHasFocus] = useState(false);
  const [delayFocusLossOnBlur, setDelayFocusLossOnBlur] = useState<
    number | null
  >(null);
  useTimeout(() => {
    direct_setHasFocus(false);
  }, delayFocusLossOnBlur);

  const setChildHasLockableFocus = useCallback(
    (updatedChildHasLockableFocus: boolean) => {
      setDelayFocusLossOnBlur(null);
      direct_setHasFocus(updatedChildHasLockableFocus);
    },
    [],
  );

  const setDelayedFocusLost = useCallback(() => {
    setDelayFocusLossOnBlur(UPDATE_DEBOUNCE_WAIT_TIME * 2);
  }, []);

  useEffect(() => {
    const handleFocusChange = () => {
      const referencedElement = getReferencedElement(document.activeElement);
      const isLockableChildFocused =
        (!refList ||
          refList.some((ref) => ref.current?.contains(referencedElement))) &&
        (!considerMarkerClass ||
          referencedElement?.closest(`.${considerMarkerClass}`) !== null);
      setChildHasLockableFocus(isLockableChildFocused);
    };
    const handleBlur = () => {
      setDelayedFocusLost();
    };
    window.addEventListener("focus", handleFocusChange, true);
    window.addEventListener("blur", handleBlur, true);
    return () => {
      window.removeEventListener("focus", handleFocusChange, true);
      window.removeEventListener("blur", handleBlur, true);
    };
  }, [
    refList,
    considerMarkerClass,
    setChildHasLockableFocus,
    setDelayedFocusLost,
  ]);

  // When the document is no longer active (e.g. when switching browser tabs) we blur out of the lockable element
  // so that when the user returns they don't automtically reaquire the lock without first polling for
  // updates done in the mean time.
  const pageVisible = usePageVisibility();
  useEffect(() => {
    if (
      !pageVisible &&
      childHasLockableFocus &&
      document.activeElement instanceof HTMLElement
    ) {
      document.activeElement.blur();
    }
  }, [childHasLockableFocus, pageVisible]);

  return childHasLockableFocus;
};
