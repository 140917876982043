import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import { IntegrationResourceT } from "src/integrationNode/types";

export const getDefaultCRIFHighMarkCreditReport = (): IntegrationResourceT => {
  return {
    providerResource: {
      provider: "crif_high_mark",
      resource: "credit_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        first_name: {
          id: uuidV4(),
          type: "text",
          displayName: "First name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        middle_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Middle name",
          assignedTo: "",
        },
        last_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Last name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        date_of_birth: {
          id: uuidV4(),
          type: "text",
          displayName: "Date of birth",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        gender: {
          id: uuidV4(),
          type: "text",
          displayName: "Gender",
          assignedTo: "",
        },
        age: {
          id: uuidV4(),
          type: "text",
          displayName: "Age",
          assignedTo: "",
        },
        marital_status: {
          id: uuidV4(),
          type: "text",
          displayName: "Marital status",
          assignedTo: "",
        },
        phone_number: {
          id: uuidV4(),
          type: "text",
          displayName: "First phone number",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        phone_number_2: {
          id: uuidV4(),
          type: "text",
          displayName: "Second phone number",
          assignedTo: "",
        },
        phone_number_3: {
          id: uuidV4(),
          type: "text",
          displayName: "Third phone number",
          assignedTo: "",
        },
        customer_reference: {
          id: uuidV4(),
          type: "text",
          displayName: "Customer reference",
          assignedTo: "",
        },
        email: {
          id: uuidV4(),
          type: "text",
          displayName: "First email",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        email_2: {
          id: uuidV4(),
          type: "text",
          displayName: "Second email",
          assignedTo: "",
        },
        pan_id: {
          id: uuidV4(),
          type: "text",
          displayName: "PAN ID",
          assignedTo: "",
        },
        dl_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Driver's license ID",
          assignedTo: "",
        },
        voter_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Voter ID",
          assignedTo: "",
        },
        passport_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Passport ID",
          assignedTo: "",
        },
        ration_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Ration card number",
          assignedTo: "",
        },
        u_id: {
          id: uuidV4(),
          type: "text",
          displayName: "UID number",
          assignedTo: "",
        },
        other_id_1: {
          id: uuidV4(),
          type: "text",
          displayName: "First alternative ID",
          assignedTo: "",
        },
        other_id_2: {
          id: uuidV4(),
          type: "text",
          displayName: "Second alternative ID",
          assignedTo: "",
        },
        father_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Name of father",
          assignedTo: "",
        },
        mother_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Name of mother",
          assignedTo: "",
        },
        spouse_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Name of spouse",
          assignedTo: "",
        },
        first_address_line: {
          id: uuidV4(),
          type: "text",
          displayName: "First address line",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        city: {
          id: uuidV4(),
          type: "text",
          displayName: "City",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        village: {
          id: uuidV4(),
          type: "text",
          displayName: "Village",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        state: {
          id: uuidV4(),
          type: "text",
          displayName: "State",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        pin: {
          id: uuidV4(),
          type: "text",
          displayName: "Postal index number (PIN)",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        country: {
          id: uuidV4(),
          type: "text",
          displayName: "Country",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        first_address_line_2: {
          id: uuidV4(),
          type: "text",
          displayName: "Alternative first address line",
          assignedTo: "",
        },
        city_2: {
          id: uuidV4(),
          type: "text",
          displayName: "Alternative city",
          assignedTo: "",
        },
        village_2: {
          id: uuidV4(),
          type: "text",
          displayName: "Alternative village",
          assignedTo: "",
        },
        state_2: {
          id: uuidV4(),
          type: "text",
          displayName: "Alternative state",
          assignedTo: "",
        },
        pin_2: {
          id: uuidV4(),
          type: "text",
          displayName: "Alternative PIN",
          assignedTo: "",
        },
        country_2: {
          id: uuidV4(),
          type: "text",
          displayName: "Alternative country",
          assignedTo: "",
        },
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        retrieval_datetime: getNamedOutputMapping("Retrieval date-time (UTC)"),
      },
    },
    config: defaultResourceConfig,
  };
};
