import React, { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";

import { Button, ButtonVariant } from "src/base-components/Button";
import { Modal, ModalProps } from "src/base-components/Modal";

export type ConfirmationModalPropsT = Pick<ModalProps, "zIndex"> & {
  open: boolean;
  onClose: () => void;
  onConfirm: (() => Promise<void>) | (() => void);
  title: string;
  subtitle?: React.ReactNode;
  headerPadding?: string;
  confirmationButtonText?: string;
  confirmationButtonClassname?: string;
  cancelationButtonText?: string;
  confirmationButtonDisabled?: boolean;
  size?: "small" | "large" | "xl";
  children?: React.ReactNode;
  confirmationButtonType?: ButtonVariant;
  afterLeave?: () => void;
  closeIcon?: boolean;
  showTitleDivider?: boolean;
};

export const ConfirmationModal: React.FC<ConfirmationModalPropsT> = ({
  open,
  onClose,
  title,
  subtitle,
  headerPadding,
  onConfirm,
  children,
  confirmationButtonText = "Confirm",
  confirmationButtonClassname = "",
  cancelationButtonText = "Cancel",
  size = "small",
  confirmationButtonType = "primary",
  afterLeave,
  confirmationButtonDisabled = false,
  zIndex,
  closeIcon,
  showTitleDivider,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(false);
  }, [open]);

  const onConfirmClick = async () => {
    setLoading(true);
    try {
      await onConfirm();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      afterLeave={afterLeave}
      className={twJoin(
        size === "small" && "w-110",
        size === "large" && "w-120",
        size === "xl" && "w-150",
      )}
      closeIcon={closeIcon}
      headerClassName={twJoin(
        headerPadding,
        headerPadding === undefined && size === "small" && "pt-6",
      )}
      open={open}
      showTitleDivider={showTitleDivider}
      subtitle={subtitle}
      title={title}
      zIndex={zIndex}
      onClose={onClose}
    >
      <div className="flex grow flex-col justify-between">
        <div className="px-6">{children}</div>
        <div
          className={twJoin(
            "flex justify-end gap-2 px-6",
            size === "small" && "pb-6",
            size === "large" && "border-t border-gray-200 py-4",
            size === "xl" && "border-t border-gray-200 py-4 pb-4",
          )}
        >
          <Button disabled={loading} variant="secondary" onClick={onClose}>
            {cancelationButtonText}
          </Button>
          <span className={twJoin("inline-block", confirmationButtonClassname)}>
            <Button
              dataLoc="confirm"
              disabled={confirmationButtonDisabled}
              loading={loading}
              variant={confirmationButtonType}
              fullWidth
              onClick={onConfirmClick}
            >
              {confirmationButtonText}
            </Button>
          </span>
        </div>
      </div>
    </Modal>
  );
};
