import React from "react";

type Props = {
  popover: HTMLDivElement;
  popoverOrientation: "toRight" | "toLeft";
};

// If this value is increased the the safe area is more resistend against slow movements.
const safetyWidthPx = 2;

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = React.useState({
    x: 0,
    y: 0,
  });
  React.useEffect(() => {
    const updateMousePosition = (ev: MouseEvent) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };
    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);
  return mousePosition;
};

/**
 * Used to protect open on hover popovers from closing when the mouse is moving towards them.
 * Renders a triangle save area from the popover to the mouse cursor that swallows mouse events.
 * Based on https://www.smashingmagazine.com/2023/08/better-context-menus-safe-triangles/
 */
export const HoverSafeArea: React.FC<Props> = ({
  popover,
  popoverOrientation,
}) => {
  const { x: mouseX, y: mouseY } = useMousePosition();

  const {
    width: submenuWidth,
    height: submenuHeight,
    x: submenuX,
    y: submenuY,
  } = popover.getBoundingClientRect();

  const svgWidth =
    popoverOrientation === "toRight"
      ? submenuX - mouseX + safetyWidthPx
      : mouseX - (submenuX + submenuWidth) + safetyWidthPx;
  const svgHeight = submenuHeight;

  const toRightSavePath = `M 0, ${mouseY - submenuY} 
  L ${svgWidth},${svgHeight}  
  L ${svgWidth},0 z`;
  const toLeftSavePath = `M ${svgWidth}, ${mouseY - submenuY} 
  L 0,0  
  L 0,${svgHeight} z`;
  return (
    <svg
      id="svg-safe-area"
      style={{
        position: "fixed",
        width: svgWidth,
        height: submenuHeight,
        pointerEvents: "none",
        zIndex: 2,
        top: submenuY,
        left:
          popoverOrientation === "toRight"
            ? mouseX - 2
            : submenuX + submenuWidth,
      }}
    >
      {/* Safe Area */}
      <path
        d={popoverOrientation === "toRight" ? toRightSavePath : toLeftSavePath}
        fill="#00000000"
        pointerEvents="auto"
        strokeWidth="0.4"
        // To debug/continue development make the svgs visible!
        //stroke="red"
        //fill="rgba(114,140,89,0.3)"
      />
    </svg>
  );
};
