import {
  ConnectApiVersions,
  connectApiDelete,
  connectApiGet,
  connectApiPatch,
  connectApiPost,
  connectApiPut,
} from "src/api/connectApi/connectApi";
import {
  ManifestIntegrationProvider,
  ManifestsListResponse,
} from "src/api/connectApi/manifestTypes";
import {
  ConnectionCreateT,
  ConnectionT,
  QueryPayload,
  ResourceConfigT,
  ResourceSample,
} from "src/api/connectApi/types";
import { S3PreSignedUrlDataT } from "src/api/s3";
import { ManifestConnectionT } from "src/connections/config/manifest/types";

export class ProviderEndpoint {
  static query = async (
    baseUrl: string | undefined,
    provider: string,
    resource: string,
    payload: QueryPayload,
  ) => {
    if (!baseUrl || !provider || !resource)
      throw new Error("Missing baseUrl, provider or resource");
    const response = connectApiPost<ConnectionT>(
      baseUrl,
      `/providers/${provider}/resources/${resource}`,
      payload,
    );
    return (await response).data;
  };
}

export class ConnectionsEndpoint {
  static all = async (baseUrl?: string) => {
    if (!baseUrl) return [];
    const response = connectApiGet<ConnectionT[]>(baseUrl, `/connections`);
    return (await response).data;
  };

  static get = async (baseUrl: string | undefined, connectionId: string) => {
    if (!baseUrl) throw new Error("No baseUrl provided");
    const response = connectApiGet<ConnectionT>(
      baseUrl,
      `/connections/${connectionId}`,
    );
    return (await response).data;
  };

  static create = async (
    baseUrl: string | undefined,
    data: ConnectionCreateT,
  ) => {
    if (!baseUrl) throw new Error("No baseUrl provided");
    const response = connectApiPost<ConnectionT>(baseUrl, `/connections`, data);
    return (await response).data;
  };

  static createConnectionAndResources = async (
    baseUrl: string,
    data: Pick<
      ConnectionT,
      | "name"
      | "is_sandbox"
      | "secrets"
      | "resource_configs"
      | "provider"
      | "configuration"
      | "data_retention"
    >,
  ) => {
    const response = connectApiPost<ConnectionT>(baseUrl, `/connections`, data);
    return (await response).data;
  };

  static delete = async (baseUrl: string | undefined, connectionId: string) => {
    if (!baseUrl) throw new Error("No baseUrl provided");
    const response = connectApiDelete<ConnectionT>(
      baseUrl,
      `/connections/${connectionId}`,
    );
    return (await response).data;
  };

  static update = async (
    baseUrl: string | undefined,
    connectionId: string,
    data: ConnectionCreateT,
  ) => {
    if (!baseUrl) throw new Error("No baseUrl provided");
    const response = connectApiPatch<ConnectionT>(
      baseUrl,
      `/connections/${connectionId}`,
      data,
    );
    return (await response).data;
  };

  static updateConnectionAndResources = async (
    baseUrl: string,
    connectionId: string,
    data: Pick<
      ConnectionT,
      | "name"
      | "is_sandbox"
      | "secrets"
      | "resource_configs"
      | "provider"
      | "configuration"
      | "data_retention"
    >,
  ) => {
    const response = connectApiPatch<ConnectionT>(
      baseUrl,
      `/connections/${connectionId}`,
      data,
    );
    return (await response).data;
  };

  static test = async (
    baseUrl: string | undefined,
    connectionId: string | undefined,
  ) => {
    if (!baseUrl || !connectionId) throw new Error("No baseUrl provided");
    return connectApiGet<void>(baseUrl, `/connections/${connectionId}/probe`);
  };

  static getSignedUrlLogo = async (
    baseUrl: string | undefined,
    extension: string,
  ): Promise<S3PreSignedUrlDataT> => {
    if (!baseUrl) throw new Error("No baseUrl provided");
    const response = await connectApiGet<S3PreSignedUrlDataT>(
      baseUrl,
      `/media/file_upload_url?extension=${extension}`,
    );
    return {
      url: response.data.url,
      fields: response.data.fields,
    };
  };
}

export class ManifestConnectionsEndpoint {
  static create = async (
    baseUrl: string | undefined,
    data: ManifestConnectionT,
  ) => {
    if (!baseUrl) throw new Error("No baseUrl provided");
    const response = await connectApiPost<ConnectionT>(
      baseUrl,
      `/connections`,
      data,
      { version: ConnectApiVersions.V2 },
    );
    return response.data;
  };

  static update = async (
    baseUrl: string | undefined,
    connectionId: string,
    data: ManifestConnectionT,
  ) => {
    if (!baseUrl) throw new Error("No baseUrl provided");
    const response = await connectApiPut<ConnectionT>(
      baseUrl,
      `/connections/${connectionId}`,
      data,
      { version: ConnectApiVersions.V2 },
    );
    return response.data;
  };
}

export class ManifestsEndpoint {
  static all = async (baseUrl?: string) => {
    if (!baseUrl) throw new Error("No baseUrl provided");
    const response = await connectApiGet<ManifestsListResponse>(
      baseUrl,
      `/manifests`,
      { latest: true },
      { version: ConnectApiVersions.V2 },
    );
    return response.data?.data;
  };

  static get = async (
    provider: string,
    manifest_version?: string,
    baseUrl?: string,
  ) => {
    if (!baseUrl) throw new Error("No baseUrl provided");
    const response = await connectApiGet<ManifestIntegrationProvider>(
      baseUrl,
      `/manifests/${provider}`,
      { manifest_version: manifest_version },
      { version: ConnectApiVersions.V2 },
    );
    return response.data;
  };
}

export class ResourceConfigsEndpoint {
  static get = async (
    baseUrl: string | undefined,
    connectionId: string,
    resourceConfigId: string,
  ) => {
    if (baseUrl === undefined) throw Error("Missing connect base url");
    const response = connectApiGet<ResourceConfigT>(
      baseUrl,
      `/connections/${connectionId}/resources/${resourceConfigId}`,
    );
    return (await response).data;
  };
}

export class ResourceSamplesEndpoint {
  static get = async (baseUrl: string, provider: string, resource: string) => {
    const response = connectApiGet<ResourceSample[]>(
      baseUrl,
      `/providers/${provider}/resources/${resource}/samples`,
    );
    return (await response).data;
  };
}
