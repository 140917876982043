import { StringRenderer } from "src/base-components/EditorTable/StringRenderer";
import { EditorColumnGroup } from "src/base-components/EditorTable/types";
import { CellStringEditor } from "src/scorecardNode/CellStringEditor";
import { ContextConditionEditor } from "src/scorecardNode/ContextConditionEditor";
import { WeightEditor } from "src/scorecardNode/WeightEditor";
import { RowShape } from "src/scorecardNode/types";

const headerConfig = {
  value: "",
  renderer: StringRenderer,
};

export const columnGroups = [
  {
    id: "factor",
    name: "Factor",
    columns: [
      {
        key: "input_field",
        isCommon: true,
        resizable: true,
        width: 120,
        minWidth: 120,
        maxWidth: 320,
        header: headerConfig,
        cell: {
          placeholder: "data.field",
          renderer: (props) => (
            <CellStringEditor
              {...props}
              alignCenter={false}
              prefix="input_field"
              removable={false}
            />
          ),
        },
      },
    ],
  },
  {
    id: "weight",
    name: "Weight",
    columns: [
      {
        key: "weight",
        isCommon: true,
        resizable: true,
        width: 75,
        minWidth: 75,
        maxWidth: 320,
        header: headerConfig,
        cell: {
          renderer: WeightEditor,
        },
      },
    ],
  },
  {
    id: "predicate",
    name: "Value",
    columns: [
      {
        key: "predicate",
        header: headerConfig,
        resizable: true,
        width: 140,
        minWidth: 140,
        maxWidth: 320,
        cell: {
          renderer: ContextConditionEditor,
        },
      },
    ],
  },
  {
    id: "effect",
    name: "Score",
    columns: [
      {
        key: "effect",
        isHighlighted: true,
        resizable: true,
        header: headerConfig,
        width: 140,
        minWidth: 140,
        maxWidth: 320,
        cell: {
          renderer: (props) => <CellStringEditor {...props} prefix="effect" />,
        },
      },
    ],
  },
] as EditorColumnGroup<RowShape>[];
