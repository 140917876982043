import { isEqual } from "lodash";

import { SchemaT } from "src/api/flowTypes";
import { DiffRevisionProps } from "src/changeHistory/DiffViewModal/DiffViewModal";
import { editorWrapperClassName } from "src/changeHistory/DiffViewModal/NodeDiffView";
import { useDiffHighlighting } from "src/changeHistory/DiffViewModal/highlightDiffs";
import { SchemaOptions } from "src/router/SearchParams";
import { SchemaEditor } from "src/schema/SchemaEditor";

type PossibleSchemaTyping = SchemaT | {} | undefined;

export const SchemaDiffView: React.FC<DiffRevisionProps> = ({
  change,
  previouseRevision,
}) => {
  useDiffHighlighting("schema-diff-previous", "schema-diff-after");
  const schemaType = change?.diff?.output_schema
    ? SchemaOptions.Output
    : SchemaOptions.Input;
  const originalSchema =
    schemaType === SchemaOptions.Output
      ? (previouseRevision.output_schema as PossibleSchemaTyping)
      : (previouseRevision.input_schema as PossibleSchemaTyping);
  const changedSchema =
    schemaType === SchemaOptions.Output
      ? (change?.diff?.output_schema as PossibleSchemaTyping)
      : (change?.diff?.input_schema as PossibleSchemaTyping);

  const originalSchemaStrict =
    !originalSchema || isEqual(originalSchema, {})
      ? undefined
      : (originalSchema as SchemaT);

  const changedSchemaStrict =
    !changedSchema || isEqual(changedSchema, {})
      ? undefined
      : (changedSchema as SchemaT);

  return (
    <div className="w-full px-6 pt-4">
      <div className="flex h-full w-full flex-row justify-between gap-x-6">
        <div
          className={editorWrapperClassName}
          data-loc="schema-diff-previous"
          id="schema-diff-previous"
        >
          {originalSchemaStrict && (
            <SchemaEditor
              flowVersionId={change.flow_version_id}
              mutateSchema={() => {}}
              schema={originalSchemaStrict}
              setSchemaInvalid={() => {}}
              type={schemaType}
              immutable
            />
          )}
        </div>
        <div
          className={editorWrapperClassName}
          data-loc="schema-diff-after"
          id="schema-diff-after"
        >
          {changedSchemaStrict && (
            <SchemaEditor
              flowVersionId={change.flow_version_id}
              mutateSchema={() => {}}
              schema={changedSchemaStrict}
              setSchemaInvalid={() => {}}
              type={schemaType}
              immutable
            />
          )}
        </div>
      </div>
    </div>
  );
};
